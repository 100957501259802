import dayjs from 'dayjs';
import baseApi from '../base';
import {
  TAG_STOCKS,
  TAG_STOCK_CALENDAR,
  TAG_STOCK_CHART,
  TAG_STOCK_TECHNICAL,
  TAG_STOCK_COMMODITIES,
  TAG_STOCK_CORP_ACTION,
  TAG_STOCK_GLOBAL_INDEX,
  TAG_STOCK_INFO,
  TAG_STOCK_NEWS,
  TAG_STOCK_THEMATIC,
  TAG_STOCK_TOP,
  TAG_BROKER_SUMMARY,
  TAG_STOCK_DONE_SUMMARY,
  TAG_STOCK_DONE_DETAIL,
  TAG_STOCK_FUNDAMENTAL
} from '../tags';

export const StockApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    searchStock: build.query({
      query: (params = { q: '', page: 1, size: 20 }) => {
        return {
          url: `/stocks`,
          params
        };
      },
      transformResponse: (res) => res?.data || [],
      providesTags: () => {
        return [{ type: TAG_STOCKS, id: 'LIST' }];
      }
    }),
    getStockByCode: build.query({
      query: (code) => {
        return {
          url: `/stocks/${code}`
        };
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, code) => {
        return [{ type: TAG_STOCKS, id: code }];
      }
    }),
    getStockInfoByCode: build.query({
      query: (code) => {
        return {
          url: `/stocks/${code}/info`
        };
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, code) => {
        return [{ type: TAG_STOCK_INFO, id: code }];
      }
    }),
    getStockChartByCode: build.query({
      query: ({ code, query }) => {
        return {
          url: `/stocks/${code}/chart`,
          params: query
        };
      },
      transformResponse: (res) => res || [],
      providesTags: (_result, _error, { code }) => {
        return [{ type: TAG_STOCK_CHART, id: code }];
      }
    }),
    getStockCorpActionByCode: build.query({
      query: (code) => {
        return {
          url: `/stocks/${code}/corporate-action`
        };
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, code) => {
        return [{ type: TAG_STOCK_CORP_ACTION, id: code }];
      }
    }),
    getStockNewsByCode: build.query({
      query: (code) => {
        return {
          url: `/stocks/${code}/news?length=10`
        };
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, code) => {
        return [{ type: TAG_STOCK_NEWS, id: code }];
      }
    }),
    getStockThematics: build.query({
      query: ({ type, keyword }) => {
        return {
          url: `/stocks/thematic?type=${type}&page=1&q=${keyword}`
        };
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, type) => {
        return [{ type: TAG_STOCK_THEMATIC, id: type }];
      }
    }),
    getStockThematicById: build.query({
      query: (id) => {
        return {
          url: `/stocks/thematic/${id}/list`
        };
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, id) => {
        return [{ type: TAG_STOCK_THEMATIC, id: id }];
      }
    }),
    getStockTop: build.query({
      query: ({ type, params = { size: 5, page: 0 } }) => {
        return {
          url: `/stocks/top/${type}`,
          params
        };
      },
      transformResponse: (res) => res.data || {},
      providesTags: () => {
        return [{ type: TAG_STOCK_TOP, id: 'LIST' }];
      }
    }),
    getStockFundamentalByCode: build.query({
      query: ({ params, code }) => {
        return {
          url: `/stocks/${code}/financial-report`,
          params
        };
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, { code }) => {
        return [{ type: TAG_STOCK_FUNDAMENTAL, id: code }];
      }
    }),
    getMultipleFundamentalByCodes: build.query({
      async queryFn(
        { codes = [], periods = '202212' },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        let periodsNegative1 = dayjs(periods, 'YYYYMM').subtract(3, 'month').format('YYYYMM');
        let periodsNegative2 = dayjs(periods, 'YYYYMM').subtract(6, 'month').format('YYYYMM');

        const tryFetchData = async (periods) => {
          let promises = [];

          codes?.map((item) => {
            promises.push(
              fetchWithBQ({
                url: `/stocks/${item?.code}/financial-report`,
                method: 'GET',
                params: { periods }
              })
            );
          });
  
          let data = await Promise.all(promises);
          data = data?.map((res, key) => {
            const appendStock = [{...res?.data[0], stock: codes[key]}];
            return {...res, data: appendStock}
          })

          let countEmptyData = 0;
          data?.forEach((resItem) => {
            if (!resItem?.data?.[0]?.details) countEmptyData += 1;
          });

          return { data, countEmptyData }
        }

        let response = await tryFetchData(periods);

        if (response?.countEmptyData === codes?.length) {
          response = await tryFetchData(periodsNegative1);
        }
        
        if (response?.countEmptyData === codes?.length) {
          response = await tryFetchData(periodsNegative2);
        }

        return {
          data: response?.data || []
        };
      },
      transformResponse: (res) => res || {}
    }),
    getStockScreenerByCodes: build.query({
      async queryFn(
        { codes = [], periods = '202212' },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        let promises = [];

        codes?.map((item) => {
          promises.push(
            fetchWithBQ({
              url: `/stocks/${item?.code}/financial-report`,
              method: 'GET',
              params: { periods }
            })
          );
        });

        const response = await Promise.all(promises);
        let resData = [];

        response?.forEach(({ data }, key) => {
          resData.push({ ...codes[key], ...data?.[0]?.details})
        });

        return {
          data: resData
        };
      },
      transformResponse: (res) => res || {}
    }),
    getStockScreener: build.query({
      query: (body = {
        user_id: '',
        remarks: '',
        name: '',
        index: '', // stock universe (IDX30, IDX80 etc.)
        order: [],
        filter: [],
        metrics: [
          {
            metrics: [
              {
                operator: '', // operator comparison (> >= = <= <)
                param: '',
                paramCompareType: '',
                valueCompare: '',
                condition: '', // condition logical (AND OR)
              }
            ]
          }
        ]
      }) => {
        return {
          url: `/stocks/screener`,
          method: 'POST',
          body
        };
      },
      transformResponse: (res) => res.data || []
    }),
    getMultipleStockByCodes: build.query({
      query: (codes) => {
        return {
          method: 'POST',
          url: `/stocks/get-multiple-id`,
          body: {
            id: codes || []
          }
        };
      },
      providesTags: (_result, _error, codes) => {
        return [{ type: TAG_STOCKS, id: codes.join('') }];
      }
    }),
    getStockCommodities: build.query({
      query: () => {
        return {
          url: `/stocks/commodities`
        };
      },
      transformResponse: (res) => {
        return (
          res?.filter((item) =>
            [
              'wti crude oil',
              'gold future',
              'crude palm oil',
              'newcastle coal future',
              'rotterdam coal future',
              'tin future',
              'copper future'
            ].includes(item?.macro_item?.toLowerCase())
          ) || []
        );
      },
      providesTags: () => {
        return [{ type: TAG_STOCK_COMMODITIES, id: 'LIST' }];
      }
    }),
    getStockCalendar: build.query({
      query: (params = { startDate: '10/01/2021', endDate: '10/31/2022' }) => {
        return {
          url: `/stocks/cpca`,
          params
        };
      },
      transformResponse: (res) => res || [],
      providesTags: () => {
        return [{ type: TAG_STOCK_CALENDAR, id: 'LIST' }];
      }
    }),
    getStockGlobalIndex: build.query({
      query: () => {
        return {
          url: `/stocks/global-index`
        };
      },
      transformResponse: (res) => {
        return (
          res?.filter((item) =>
            [
              'dow jones industrial average',
              'nasdaq composite',
              'nikkei 225',
              's&p 500',
              'shanghai composite index',
              'straits times index'
            ].includes(item?.macro_item?.toLowerCase())
          ) || []
        );
      },
      providesTags: () => {
        return [{ type: TAG_STOCK_GLOBAL_INDEX, id: 'LIST' }];
      }
    }),
    getTechnicalStock: build.query({
      query: (params = { symbol: 'AALI', interval: '1d' }) => {
        return {
          url: `/taapi/stocks/tradingview/getTechnicalData`,
          params
        };
      },
      transformResponse: (res) => {
        return res || {};
      },
      providesTags: (code) => {
        return [{ type: TAG_STOCK_TECHNICAL, id: code }];
      }
    }),
    getBrokerSummary: build.query({
      query: ({ params, symbol }) => {
        return {
          url: `/stocks/broker-summary/${symbol}`,
          params
        };
      },
      transformResponse: (res) => {
        return res || [];
      },
      providesTags: (_result, _error, { symbol }) => {
        return [{ type: TAG_BROKER_SUMMARY, id: symbol }];
      }
    }),
    getBrokerActivity: build.query({
      query: ({ params, brokerSymbol }) => {
        return {
          url: `/stocks/stock-summary/${brokerSymbol}`,
          params
        };
      },
      transformResponse: (res) => {
        return res || [];
      },
      providesTags: (_result, _error, { brokerSymbol }) => {
        return [{ type: TAG_BROKER_SUMMARY, id: brokerSymbol }];
      }
    }),
    getDoneSummaryByCode: build.query({
      query: (symbol) => {
        return {
          url: `/stocks/${symbol}/trades-summary`,
        }
      },
      transformResponse: (res) => res?.map((item) => {
        return { ...item, BLot: (item?.Volume - item?.ReleasedVolume) / 100, BFreq: item?.Frequency - item?.ReleasedFrequency }
      }) || [],
      providesTags: (_result, _error, symbol) => {
        return [{ type: TAG_STOCK_DONE_SUMMARY, id: symbol }];
      },
    }),
    getDoneDetailByCode: build.query({
      query: ({ symbol, params }) => {
        return {
          url: `/stocks/${symbol}/trades`,
          params
        }
      },
      transformResponse: (res) => res || { data: [], totalPage: 0 },
      providesTags: (_result, _error, { symbol }) => {
        return [{ type: TAG_STOCK_DONE_DETAIL, id: symbol }];
      },
    }),
  })
});

export const invalidateStockByCode = (symbol) => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCKS, id: symbol }]);
}

export const invalidateStockCalendar = () => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCK_CALENDAR, id: 'LIST' }]);
}

export const invalidateBrokerSummaryByCode = (symbol) => {
  return StockApi.util.invalidateTags([{ type: TAG_BROKER_SUMMARY, id: symbol }]);
}

export const invalidateStockInfoByCode = (symbol) => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCK_INFO, id: symbol }]);
}

export const invalidateStockCorpActionByCode = (symbol) => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCK_CORP_ACTION, id: symbol }]);
}

export const invalidateStockDoneDetailByCode = (symbol) => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCK_DONE_DETAIL, id: symbol }]);
}

export const invalidateStockDoneSummaryByCode = (symbol) => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCK_DONE_SUMMARY, id: symbol }]);
}

export const invalidateStockFundamentalByCode = (symbol) => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCK_FUNDAMENTAL, id: symbol }]);
}

export const invalidateStockNewsByCode = (symbol) => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCK_NEWS, id: symbol }]);
}

export const invalidateStockTopTrending = () => {
  return StockApi.util.invalidateTags([{ type: TAG_STOCK_TOP, id: 'LIST' }]);
}

export const {
  useSearchStockQuery,
  useLazySearchStockQuery,
  useGetStockByCodeQuery,
  useLazyGetStockByCodeQuery,
  useGetStockInfoByCodeQuery,
  useGetStockChartByCodeQuery,
  useLazyGetStockChartByCodeQuery,
  useGetStockCorpActionByCodeQuery,
  useGetStockNewsByCodeQuery,
  useGetStockThematicsQuery,
  useGetStockThematicByIdQuery,
  useGetStockTopQuery,
  useGetStockFundamentalByCodeQuery,
  useGetMultipleStockByCodesQuery,
  useGetStockCommoditiesQuery,
  useGetStockCalendarQuery,
  useGetStockGlobalIndexQuery,
  useGetMultipleFundamentalByCodesQuery,
  useGetTechnicalStockQuery,
  useGetBrokerSummaryQuery,
  useGetBrokerActivityQuery,
  useGetStockScreenerByCodesQuery,
  useGetStockScreenerQuery,
  useLazyGetStockScreenerQuery,
  useGetDoneDetailByCodeQuery,
  useLazyGetDoneDetailByCodeQuery,
  useGetDoneSummaryByCodeQuery
} = StockApi;
