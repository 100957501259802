import PropTypes from 'prop-types';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputCounter from 'components/input/input-counter';
import InputSelect from 'components/input/input-select';
import InputText from 'components/input/input-text';
import { useFormik } from 'formik';
import { FocusError } from 'focus-formik-error';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetMasterDataBanksQuery, useGetOptionsByTypesQuery } from 'services/rtk-query/master-data';
import { invalidateUserProfile, useGetUserProfileQuery, useUpdateUserMutation } from 'services/rtk-query/user';
import { RDNStep03Validation } from './rdn-step-03.validation';
import { useCreateVerifyAccountStep03Mutation, useCreateVerifyAccountStep04Mutation, useCreateVerifySelfieMutation } from 'services/rtk-query/verify';
import dayjs from 'dayjs';
import Card from 'components/basic/card';
import ListStatus from 'components/basic/list-status';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import ModalDataAman from '../../../../../components/modal/template/modal-data-aman';
import { ModalContext } from 'components/modal/context/modalContext';
import { triggerSegmentEvent } from 'lib/segment';
import { useDispatch } from 'react-redux';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import HeaderOnboarding from '../../header-onboarding';

function RDNStep03OtherInfo({ onClickBack = () => {}, npwpData = {}, selfieImage = '' }) {
  const dispatch = useDispatch();
  const { getErrorMessage } = useGetErrorMessage();
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);

  const { data: userDetail } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const { data: options } = useGetOptionsByTypesQuery(['education', 'source_income', 'occupation', 'purpose']);
  const { data: banks } = useGetMasterDataBanksQuery();

  const [updateUser] = useUpdateUserMutation();
  const [createVerifyAccountStep03] = useCreateVerifyAccountStep03Mutation();
  const [createVerifyAccountStep04] = useCreateVerifyAccountStep04Mutation();
  const [createVerifySelfie] = useCreateVerifySelfieMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      motherName: '',
      educationLevel: '',
      sourceFunds: '',
      occupation: '',
      purposeOfAccount: '',
      monthlyIncome: 3500000,
      imgNpwpUrl: '',
      npwpNo: npwpData?.npwpNo || '',
      npwpRegistrationDate: npwpData?.npwpRegistrationDate || '',
      imgFaceUrl: selfieImage || '',
      bankId: '',
      bankAccountHolder: '',
      bankAccountNo: ''
    },
    validateOnMount: false,
    validationSchema: RDNStep03Validation,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        await createVerifySelfie({ imgFaceUrl: values?.imgFaceUrl });

        await createVerifyAccountStep03({
          educationLevel : values.educationLevel,
          occupation: values?.occupation,
          sourceFunds: values?.sourceFunds,
          monthlyIncome: values?.monthlyIncome,
          purposeOfAccount: values?.purposeOfAccount
        });

        await createVerifyAccountStep04({
          bankId : values?.bankId,
          bankAccountNo: values?.bankAccountNo,
          bankAccountHolder: values?.bankAccountHolder
        });
        
        await updateUser(values).unwrap();
        
        dispatch(invalidateUserProfile());
        navigate('/onboarding/pernyataan-informasi-lainnya')

        triggerSegmentEvent({
          event: 'TR Bank Information Inputted'
        });

        triggerSegmentEvent({
          event: 'TR Additional Data Inputted'
        });
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (userDetail && userDetail?.fillState > 1) {
      navigate('/onboarding/pernyataan-informasi-lainnya');

      formik.setValues({
        npwpNo: userDetail?.npwpNo || '',
        imgFaceUrl: userDetail?.imgFaceUrl || '',
        imgNpwpUrl: userDetail?.imgNpwpUrl || '',
        npwpRegistrationDate: dayjs(userDetail?.npwpRegistrationDate).toDate() || undefined,
        motherName: userDetail?.motherName || '',
        sourceFunds: Number(userDetail?.sourceFunds) || undefined,
        occupation: Number(userDetail?.occupation) || undefined,
        purposeOfAccount: Number(userDetail?.purposeOfAccount) || undefined,
        educationLevel: Number(userDetail?.educationLevel) || undefined,
        monthlyIncome: Number(userDetail?.monthlyIncome) || undefined,
        bankId: Number(userDetail?.bankAccountId) || undefined,
        bankAccountNo: Number(userDetail?.bankAccountNo) || undefined,
        bankAccountHolder: userDetail?.bankAccountHolder || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:py-16 text-white relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto">
          <HeaderOnboarding activeStep={3} />

          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] my-3 text-white">
            Informasi Tambahan
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Dokumen yang kamu unggah tersimpan dan terlindungi dengan aman.
          </p>

          <button
            type="button"
            onClick={() => handleModal(<ModalDataAman />)}
            className="text-sm lg:text-base px-3 py-1 border rounded-3xl border-white mb-6">
            Apakah data saya aman?
          </button>

          <ListStatus
            data={[
              {
                title: 'Selfie dengan KTP',
                subtitle: null,
                status: true
              },
              {
                title: 'Nomor NPWP (opsional)',
                subtitle: null,
                status: true
              },
              {
                title: 'Informasi tambahan',
                subtitle: null,
                status: false
              },
            ]}
          />
        </div>
      </div>

      <div className="p-0 lg:p-10">
        <Card className="lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto py-6 lg:pb-0 relative lg:max-h-[calc(90vh)] overflow-auto mb-24 lg:mb-0">
          <form className='px-6 lg:px-8' autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <FocusError formik={formik} />
            <InputText
              value={formik?.values?.motherName}
              name="motherName"
              label="Nama Gadis Ibu Kandung"
              placeholder="contoh: Jessica Hermawan"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.motherName && formik.errors.motherName ? formik.errors.motherName : ''}
            />

            <InputSelect
              value={formik?.values?.educationLevel}
              label="Pendidikan Terakhir"
              name='educationLevel'
              className="mb-4"
              options={options?.filter(({ type }) => type === 'education')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.educationLevel && formik.errors.educationLevel ? formik.errors.educationLevel : ''}
            />

            <InputSelect
              value={formik?.values?.sourceFunds}
              label="Sumber Pendapatan"
              name='sourceFunds'
              className="mb-4"
              options={options?.filter(({ type }) => type === 'source_income')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.sourceFunds && formik.errors.sourceFunds ? formik.errors.sourceFunds : ''}
            />

            <InputCounter
              value={formik.values.monthlyIncome}
              label="Pendapatan Per Bulan"
              counterStep={1000000}
              onChange={(val) => { formik.setFieldValue('monthlyIncome', val); setTimeout(() => formik.setTouched({ monthlyIncome: true }), 100); }}
              error={formik.touched.monthlyIncome && formik.errors.monthlyIncome ? formik.errors.monthlyIncome : ''}
            />

            <InputSelect
              value={formik?.values?.occupation}
              label="Jenis Pekerjaan"
              name='occupation'
              className="mb-4"
              options={options?.filter(({ type }) => type === 'occupation')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.occupation && formik.errors.occupation ? formik.errors.occupation : ''}
            />

            <InputSelect
              value={formik?.values?.purposeOfAccount}
              label="Tujuan Buka Akun"
              name='purposeOfAccount'
              className="mb-4"
              options={options?.filter(({ type }) => type === 'purpose')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.purposeOfAccount && formik.errors.purposeOfAccount ? formik.errors.purposeOfAccount : ''}
            />

            <hr className='my-5'/>

            <h5 className="my-4 text-sm">Rekening Penarikan Dana</h5>

            <InputSelect
              value={formik?.values?.bankId}
              label="Nama Bank"
              name='bankId'
              className="mb-4"
              options={banks}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.bankId && formik.errors.bankId ? formik.errors.bankId : ''}
            />

            <InputText
              value={formik?.values?.bankAccountHolder}
              name="bankAccountHolder"
              label="Nama Pemilik Rekening"
              placeholder="contoh: Jessica Hermawan"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.bankAccountHolder && formik.errors.bankAccountHolder ? formik.errors.bankAccountHolder : ''}
            />

            <InputText
              value={formik?.values?.bankAccountNo}
              name="bankAccountNo"
              label="Nomor Rekening Bank"
              placeholder="Nomor Rekening Bank Kamu"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.bankAccountNo && formik.errors.bankAccountNo ? formik.errors.bankAccountNo : ''}
            />
            
            <div className="mt-10 fixed lg:sticky w-full bottom-0 left-0 p-6 lg:px-0 bg-white border-t z-10">
              <div className='-mx-2 flex justify-center'>
                <button
                  type="button"
                  onClick={onClickBack}
                  className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 border-2 bg-white border-main-gold-d text-main-gold-d flex px-10 items-center justify-center font-quicksand-semibold font-bold text-xs h-11 mx-2 w-full rounded-[10px] overflow-hidden focus:outline-none`}>
                  Kembali
                </button>

                <button
                  type="submit"
                  disabled={!formik.isValid || isSubmitting}
                  className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 flex px-10 items-center justify-center font-quicksand-semibold font-bold text-xs h-11 mx-2 w-full rounded-[10px] overflow-hidden focus:outline-none ${
                    formik.isValid
                      ? 'bg-main-gold-d text-white'
                      : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                  }`}>
                  {isSubmitting && <SimpleSpinner />} Selanjutnya
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

RDNStep03OtherInfo.propTypes = {
  onClickBack: PropTypes.func,
  npwpData: PropTypes.object,
  selfieImage: PropTypes.string,
};

export default RDNStep03OtherInfo;
