import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { LoginSchema } from './login-form.validation'
import Spinner from 'components/common/spinner'
import IconLock from 'components/svg/icon-lock'
import IconEmail from 'components/svg/icon-email'
import { Link } from 'react-router-dom'
import IconGoogle from 'components/svg/icon-google'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useLazyGetUserMeQuery, useLazyGetUserRolesQuery } from 'services/rtk-query/user'
import { useAuthLoginGoogleMutation, useAuthLoginMutation } from 'services/rtk-query/auth'
import { parseJwt } from 'utils/jwt'
import { triggerSegmentEvent, triggerSegmentIdentify } from 'lib/segment'
import { setAuth } from 'store/auth'
import InputText from 'components/input/input-text'
import IconEyeOff from 'components/svg/icon-eye-off'
import IconEye from 'components/svg/icon-eye'
import { useCookies } from 'react-cookie'
import IconInfo from 'components/svg/icon-info'
import useGetErrorMessage from 'hooks/useGetErrorMessage'
import { useGetMaintenanceModeQuery } from 'services/rtk-query/master-data'
import { useBrowserTabId } from 'hooks/useBrowserTabId'
import useBrowserTabAuth from 'hooks/useBrowserTabAuth'
import Card from 'components/basic/card'
import LogoPinaTrade from 'assets/images/logo/logo-pinatrade.svg';

const LoginForm = ({ className }) => {
  const dispatch = useDispatch();
  const browserTabID = useBrowserTabId();
  const { getErrorMessage } = useGetErrorMessage();
  const { fcmToken } = useBrowserTabAuth();
  const { data: maintenance } = useGetMaintenanceModeQuery();

  const [authLogin] = useAuthLoginMutation();
  const [authLoginGoogle] = useAuthLoginGoogleMutation();
  const [getUserMe] = useLazyGetUserMeQuery();
  const [getUserRoles] = useLazyGetUserRolesQuery();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['g_state' , 'login_session_expired', 'pn_trade_auth_token']);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnMount: true,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      
      try {
        const responseLogin = await authLogin({ ...values, fcmToken: fcmToken || '', devicePlatform: 'web' }).unwrap();
        const { data: responseMe } = await getUserMe(responseLogin?.accessToken);
        const { data: responseRoles } = await getUserRoles(responseLogin?.accessToken);

        const auth = {
          isLogin: true,
          token: responseLogin?.accessToken,
          refreshToken: null,
          user: responseMe,
          roles: responseRoles
        }

        let { birthDate, imgAvatarUrl, ...filteredUserData } = responseMe;
        triggerSegmentIdentify({
          userId: responseMe?.id,
          properties: {
            ...filteredUserData,
            name: responseMe?.firstName + ' ' + responseMe?.lastName,
            email: responseMe?.email,
          }
        });
        
        triggerSegmentEvent({
          event: 'TR Sign In for PINA Trade',
          properties: {
            email: responseMe?.email,
            userid: responseMe?.id,
            method: 'Email'
          }
        });

        dispatch(setAuth({
          ...auth,
          [browserTabID]: auth
        }));
        setCookie('pn_trade_auth_token', auth?.token, { path: '/', maxAge: 999999999 });
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleGoogleOauth = async (accessToken) => {
    setIsSubmitting(true);
    try {
      let { email } = parseJwt(accessToken);
      const responseLogin = await authLoginGoogle({ email: email, password: '', fcmToken: fcmToken || '', idToken: accessToken, devicePlatform: 'web' }).unwrap();
      const { data: responseMe } = await getUserMe(responseLogin?.accessToken);
      const { data: responseRoles } = await getUserRoles(responseLogin?.accessToken);
      
      let { birthDate, imgAvatarUrl, ...filteredUserData } = responseMe;
      triggerSegmentIdentify({
        userId: responseMe?.id,
        properties: {
          ...filteredUserData,
          name: responseMe?.firstName + ' ' + responseMe?.lastName,
          email: responseMe?.email,
        }
      });

      const auth = {
        isLogin: true,
        token: responseLogin?.accessToken,
        refreshToken: null,
        user: responseMe,
        roles: responseRoles
      }
      
      triggerSegmentEvent({
        event: 'TR Sign In for PINA Trade',
        properties: {
          email: responseMe?.email,
          userid: responseMe?.id,
          method: 'Google'
        }
      });

      dispatch(setAuth({
        ...auth,
        [browserTabID]: auth
      }));
      setCookie('pn_trade_auth_token', auth?.token, { path: '/', maxAge: 999999999 });
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={`${className}lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto`}>
      <Card className="w-full py-8">
        <div className="px-6 md:px-10">
          <img
            className="h-8 w-auto mx-auto"
            src={LogoPinaTrade}
            alt="Pina Trade"
          />

          <h2 className='text-lg text-left font-quicksand-semibold my-6 text-main-base-d'>Masuk</h2>

          {
            cookies?.login_session_expired &&
            <div className='p-3 bg-main-light-1-d text-black-base-d font-quicksand-semibold text-sm my-4 rounded-lg flex items-center'>
              <IconInfo className='h-5 w-5 mr-1'/> Sesi login kamu sudah berakhir, silahkan login ulang..
            </div>
          }

          {
            maintenance?.isMaintenance &&
            <div className='p-3 bg-red-base-d text-white font-quicksand-semibold text-sm my-4 rounded-lg flex items-center animate-head-shake'>
              <IconInfo className='h-5 w-5 mr-1'/> {maintenance?.message || 'Sedang ada perbaikan sistem.'}
            </div>
          }

          <form onSubmit={formik.handleSubmit}>
            <div className="mb-5">
              <InputText
                data-qa-id="txtEmailField"
                name="email"
                placeholder="Email PINA Kamu"
                label="Email"
                iconLeft={<IconEmail />}
                className="mb-2"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
              />
            </div>
            <div>
              <InputText
                data-qa-id="txtPasswordField"
                type={!showPassword ? 'password' : 'text'}
                name="password"
                placeholder="Password PINA Kamu"
                label="Password"
                iconLeft={<IconLock />}
                iconRight={!showPassword ? <IconEyeOff /> : <IconEye />}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                onClickRightIcon={() => setShowPassword(!showPassword)}
              />
            </div>

            <div className='text-right'>
              <Link to='/forgot-password' className='text-main-base-d text-sm font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150'>Lupa Password?</Link>
            </div>

            <div className="mb-5 mt-5">
              <button
                data-qa-id="btnLogin"
                type="submit"
                title={maintenance?.isMaintenance ? maintenance?.message || 'Sedang ada perbaikan sistem.' : 'Masuk dengan email'}
                disabled={!formik.isValid || isSubmitting || maintenance?.isMaintenance}
                className={`flex bg-main-base-d text-white disabled:bg-gray-base-l disabled:text-gray-dark-d disabled:opacity-80 disabled:cursor-not-allowed w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden transition-all ease-in-out duration-150 hover:bg-opacity-75 focus:outline-none disabled:bg-opacity-90`}>
                { isSubmitting && <Spinner /> }
                Masuk
              </button>
            </div>

            <div className='flex items-center justify-center border-main-base-d relative mt-8'>
              <span className='block p-2 px-3 text-xs text-gray-light-0-d transform -translate-y-4'>Atau masuk dengan</span>
            </div>

            <div className="mb-5">
              <button
                data-qa-id="btnLogin"
                type="button"
                title={maintenance?.isMaintenance ? maintenance?.message || 'Sedang ada perbaikan sistem.' : 'Masuk dengan google email'}
                onClick={() => {
                  try {
                    setCookie('g_state', true, { path: '/', maxAge: 0 });

                    setTimeout(() => {
                      window?.google?.accounts?.id?.initialize({
                        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
                        callback: ({ credential }) => handleGoogleOauth(credential),
                        native_callback: () => alert(`This browser is doesn't support, for google auth`)
                      });
    
                      window?.google?.accounts?.id?.prompt();
                    }, 100);
                  } catch (error) {
                    console.log(error);
                  }
                }}
                disabled={isSubmitting || maintenance?.isMaintenance}
                className={`flex w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden transition-all ease-in-out duration-100 hover:bg-opacity-75 focus:outline-none disabled:bg-gray-light-2-d disabled:bg-opacity-90 disabled:cursor-not-allowed hover:bg-gray-light-l border-2 border-main-base-d text-main-base-d relative`}>
                {isSubmitting && <Spinner />}
                <IconGoogle className="mr-2" />
                Google
              </button>
            </div>
          </form>

          <div className="flex mb-5 text-black-base-l py-3 px-4 rounded-[10px]">
            <p className="font-quicksand-regular font-bold text-sm flex-auto text-center text-gray-light-0-d">
              Belum Punya Akun PINA?&nbsp;
              <Link
                className="font-quicksand-semibold hover:opacity-75 text-main-base-d transition-all ease-in-out duration-150"
                to={'/register'}
                onClick={() => triggerSegmentEvent({ event: 'TR Daftar Disini Clicked PINA Trade' })}
                >
                Daftar disini
              </Link>
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
}

LoginForm.defaultProps = {
  className: '',
}

export default LoginForm