import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import BlinkText from 'components/basic/text-blink';
import { automatedNumberFormat } from 'utils/format';

function PriceComponent({
  value,
  maxLot,
  barColor,
  position,
  active,
  disabled,
  className,
  onClick = () => {}
}) {
  const _position =
    position === 'LEFT'
      ? 'left-[1px] rounded-r-md'
      : 'right-[1px] rounded-l-md';
  return (
    <button
      type="button"
      onClick={onClick}
      className={`outline-none p-1 py-[4.5px] mb-[3px] text-2xs font-quicksand-semibold flex justify-center items-center w-full relative border rounded-md
      ${disabled ? 'cursor-default' : ''}
      ${active ? 'border-main-base-l' : 'border-transparent'} ${className}`}>
      <BlinkText value={automatedNumberFormat(value)} />
      <RenderIf isTrue={maxLot}>
        <div
          className={`h-[20px] bg-opacity-[15%] absolute top-[0.2px] ${_position} ${barColor}`}
          style={{ width: (value / Number(maxLot || 0)) * 100 + '%' }}></div>
      </RenderIf>
    </button>
  );
}

PriceComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLot: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  barColor: PropTypes.string,
  position: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default PriceComponent;
