import PropTypes from 'prop-types';
import IconCalendar from 'components/svg/icon-calendar';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { addDays, getMonth, getYear, subDays } from 'date-fns';
import { range } from 'lodash';
import DatePicker from 'react-datepicker';
import RenderIf from 'components/basic/render-if';

import 'react-datepicker/dist/react-datepicker.css';
import './css/input-date-picker-simple.css'

import styles from "./css/input-date-picker-simple.module.css";
import { getDay } from 'date-fns';

function InputDatePickerSimple({
  value = '',
  label = '',
  placeholder= '',
  format= 'dd/MM/YYYY',
  onChange = () => {},
  className = 'mb-5',
  error = false,
  showError = true,
  disabled,
  size = 'normal',
  showHeader = true,
  includeDateIntervals = [ { start: subDays(new Date(), 365 * 100), end: addDays(new Date(), 365 * 100) }],
  years = range(1945, getYear(new Date()) + 20, 1),
  filterDate = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  }
}) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return (
    <div className={`${styles[size]} ${className}`}>
      <RenderIf isTrue={label}>
        <label htmlFor={name} className={`${styles['input-date-picker-simple-label']}`}>
          {label}
        </label>
      </RenderIf>

      <div className={`${styles['input-date-picker-simple-wrapper']} dark:bg-transparent dark:border-black-light-1-l ${error && styles['input-date-picker-simple-error']}`}>
        <IconCalendar className={styles['input-date-picker-simple-icon']} />

        <DatePicker
          dateFormat={format}
          className={`${styles['input-date-picker-simple-field']} dark:text-black-light-1-d`}
          withPortal
          filterDate={filterDate}
          portalId="date-picker-root-portal"
          disabled={disabled}
          placeholderText={placeholder}
          includeDateIntervals={includeDateIntervals}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <>
              {placeholder && <h5 className='font-quicksand-semibold my-3 text-base dark:text-black-light-1-d'>{placeholder}</h5>}
              {
                showHeader &&
                <div className='flex justify-between items-center'>
                  <button type='button' className='outline-none appearance-none h-8 w-8 p-2 mx-4 card-widget-gradient dark:border-black-light-1-l dark:text-main-base-l border rounded-lg' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <IconChevronSingle className='transform w-full h-full' />
                  </button>

                  <div>
                    <select
                      className='appearance-none outline-none rounded-lg py-1 px-3 mx-1 w-16 border dark:bg-transparent dark:border-black-light-1-l dark:text-white'
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}>
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      className='appearance-none outline-none rounded-lg py-1 px-3 mx-1 w-32 border dark:bg-transparent dark:border-black-light-1-l dark:text-white'
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }>
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <button type='button' className='outline-none appearance-none h-8 w-8 p-2 mx-4 card-widget-gradient dark:border-black-light-1-l dark:text-main-base-l border rounded-lg' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <IconChevronSingle className='transform -rotate-180 w-full h-full' />
                  </button>
                </div>
              }
            </>
          )}
          selected={value}
          onChange={(date) =>  onChange(date)}
        />
      </div>

      <RenderIf isTrue={error && showError}>
        <p className="text-xs text-red-base-l font-quicksand-regular mt-1">
          {error}
        </p>
      </RenderIf>
    </div>
  );
}


InputDatePickerSimple.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  filterDate: PropTypes.func,
  disabled: PropTypes.bool,
  showHeader: PropTypes.bool,
  showError: PropTypes.bool,
  includeDateIntervals: PropTypes.array,
  size: PropTypes.string,
  years: PropTypes.array,
};

export default InputDatePickerSimple;
