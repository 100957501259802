
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import ForgotPasswordForm from 'components/form/forgot-password-form';
import ResetPasswordWithOTPForm from 'components/form/reset-password-with-otp-form';
import Pineaple from 'components/svg/pineaple';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';

function ForgotPaswordPage() {
  const [token, setToken] = useState(null);
  const [successChangePassword, setSuccessChangePassword] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  return (
    <main>
      <Helmet>
        <title>Lupa Password | Pina Trade</title>
      </Helmet>

      <RenderIf isTrue={!sendEmail}>
        <ForgotPasswordForm
          onSuccess={({ token: _token }) => {
            setToken(_token);
            setSendEmail(true);
          }}
        />
      </RenderIf>

      <RenderIf isTrue={sendEmail && !successChangePassword}>
        <ResetPasswordWithOTPForm token={token} onSuccess={() => { setSuccessChangePassword(true) }} />
      </RenderIf>

      <RenderIf isTrue={successChangePassword}>
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
          <div className="bg-onboarding p-6 lg:px-28 lg:pb-28 text-white flex lg:py-16 relative">
            <img
              className="absolute top-0 left-0 w-full h-full"
              src={BGOnboarding}
              alt="Pina Onboarding"
            />

            <img
              className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
              src={BGOnboardingOrnament}
              alt="Pina Onboarding"
            />

            <div className="relative z-10 max-w-2xl mx-auto text-left">
              <Pineaple className="lg:ml-0 w-5 lg:w-12 h-auto" />
              <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] my-3 text-white">
                Password <br/> Berhasil Diganti
              </h1>

              <p className="text-sm lg:text-xl mb-6">
                Jangan beritahu e-mail atau password baru anda kepada siapapun.
              </p>
            </div>
          </div>

          <div className="p-6 lg:p-16 flex items-center justify-center">
            <Card className="w-full px-8 py-10 lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto">
              <div className='text-center'>
                <Link
                  to="/login"
                  className="font-quicksand-semibold py-3 px-8 bg-main-base-l text-white rounded-lg text-sm h-11 w-full block">
                  Login Pina Sekarang
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </RenderIf>
    </main>
  );
}

export default ForgotPaswordPage;
