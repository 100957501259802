import useDarkMode from 'hooks/useDarkMode';
import { useState } from 'react';

function StockDetailFinancial({ symbol }) {
  const { theme } = useDarkMode();
  const [dataType, setDataType] = useState('annualy');

  const [categories, setCategories] = useState([
    {
      active: true,
      text: 'Tahunan',
      value: 'annualy',
      ['data-qa-id']: 'btnNavFinancialChartAnnualy'
    },
    {
      active: false,
      text: 'Kuartalan',
      value: 'quarterly',
      ['data-qa-id']: 'btnNavFinancialChartQuarterly'
    }
  ]);

  const bgChartColor = theme === 'dark' ? '131103' : 'FFFFFF';
  const iframeIncomeStatement = `${process.env.REACT_APP_WEBVIEW_URL}/chart/stock/financial?code=${symbol}&dataType=${dataType}&financialType=income-statement&htmlBgColor=${bgChartColor}${theme === 'dark' ? '&darkMode=true' : ''}`;
  const iframeBalanceSheet = `${process.env.REACT_APP_WEBVIEW_URL}/chart/stock/financial?code=${symbol}&dataType=${dataType}&financialType=balance-sheet&htmlBgColor=${bgChartColor}${theme === 'dark' ? '&darkMode=true' : ''}`;
  const iframeCashFlow = `${process.env.REACT_APP_WEBVIEW_URL}/chart/stock/cash-flow?code=${symbol}&dataType=${dataType}&financialType=balance-sheet&htmlBgColor=${bgChartColor}${theme === 'dark' ? '&darkMode=true' : ''}`;


  const handleActiveTab = (tab) => {
    let tCategories = [...categories];
    tCategories.forEach((item) => {
      if (item.text === tab.text) {
        item.active = true;
        setDataType(item.value);
      } else {
        item.active = false;
      }
    });

    setCategories(tCategories);
  };

  return (
    <div className="my-3">
      <ul className="my-5 text-xs flex items-center font-quicksand-semibold justify-center">
        {categories.map((item, key) => (
          <li
            key={key}
            data-qa-id={item?.['data-qa-id']}
            onClick={() => handleActiveTab(item)}
            className={`rounded-lg cursor-pointer py-2 px-3 mr-2 border border-main-base-l ${item.active ? 'bg-main-base-l text-white dark:text-black-base-d' : 'card-widget-gradient dark:text-black-light-1-d'}`}>
            {item.text}
          </li>
        ))}
      </ul>

      <div className="grid grid-cols-2 gap-4 dark:text-black-light-1-d">
        <div className='border dark:border-gray-base-d rounded-xl pb-3 overflow-hidden px-3'>
          <h5 className="my-5 text-sm text-center">Income Statement</h5>
          <iframe
            className="w-full h-64"
            src={iframeIncomeStatement}
          />
        </div>

        <div className='border dark:border-gray-base-d rounded-xl pb-3 overflow-hidden px-3'>
          <h5 className="my-5 text-sm text-center">Balance Sheet</h5>
          <iframe
            className="w-full h-64"
            src={iframeBalanceSheet}
          />
        </div>

        <div className='border dark:border-gray-base-d rounded-xl pb-3 overflow-hidden px-3'>
          <h5 className="my-5 text-sm text-center">CashFlow</h5>
          <iframe
            className="w-full h-64"
            src={iframeCashFlow}
          />
        </div>
      </div>
    </div>
  );
}

export default StockDetailFinancial;
