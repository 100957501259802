export const BUDGET_UNALLOCATED = 'unallocated';
export const RISK_PROFILE_KONVENSIONAL = 'konvensional';
export const RISK_PROFILE_SYARIAH = 'syariah';
export const GOAL_TYPE_PENSION = 'pension';

export const ORDER_TYPE = {
  LIMIT: {
    name: 'Limit Order',
    subtitle: 'Beli saham di harga tertentu sesuai keinginan kamu',
    description:
      'Dirancang untuk memberi investor lebih banyak kendali atas harga beli dan jual dari perdagangan mereka. Sebelum menempatkan pesanan pembelian, jumlah harga pembelian maksimum yang dapat terkirim harus dipilih. Harga penjualan minimum yang dapat terkirim, sementara itu, ditunjukkan pada pesanan penjualan.',
    code: 'DAY',
    expiry: 'DAY',
    illustrationName: 'IllustrationLimitOrder',
    id: '0'
  },
  MARKET: {
    name: 'Market Order',
    subtitle: 'Beli saham di harga pasar terbaik saat ini',
    description:
      'Perintah untuk perdagangan beli dan jual paling dasar, di mana broker menerima perintah perdagangan keamanan dan kemudian memprosesnya pada harga pasar saat ini.',
    code: 'MARKET',
    expiry: 'DAY',
    illustrationName: 'IllustrationMarketOrder',
    id: '0'
  },
  AUTO: {
    name: 'Auto Order',
    subtitle:
      'Beli saham berdasarkan syarat harga dan durasi yang kamu tetapkan',
    description:
      'Beli/Jual saham berdasarkan syarat harga dan durasi yang kamu tetapkan dengan tujuan mempermudah kamu mendapatkan saham inceran kamu.',
    code: 'GTD',
    expiry: 'GTD',
    illustrationName: 'IllustrationAutoOrder',
    id: '6'
  }
};

export const ORDER_EXPIRED = {
  NORMAL: {
    id: '0',
    code: 'DAY',
    name: 'Day',
    description: 'Order dengan antrian normal selama Pasar Perdagangan buka',
    orderType: 'DAY,MARKET'
  },
  FILL_OR_KILL: {
    id: '4',
    code: 'FOK',
    name: 'FOK',
    description:
      'Opsi antrian dengan order harus terpenuhi seluruhnya, jika tcodeak terpenuhi maka pesanan dibatalkan',
    orderType: 'MARKET'
  },
  FILL_AND_KILL: {
    id: '3',
    code: 'FAK',
    name: 'FAK',
    description:
      'Opsi antrian dengan order boleh terpenuhi sepenuhnya atau sebagian, sisa order akan masuk antrian normal dengan harga sebagai limit',
    orderType: 'MARKET'
  },
  SESSION: {
    id: 'S',
    code: 'SESSION',
    name: 'Session',
    description:
      'Order dengan antrian mengikuti sesi  aktif sesuai perdagangan bursa.',
    orderType: 'DAY'
  },
  GTD: {
    id: '6',
    code: 'GTD',
    name: 'GTD',
    description:
      'Order dengan antrian mengikuti sesi  aktif sesuai perdagangan bursa.',
    orderType: 'GTD'
  },
  GTC: {
    id: '1',
    code: 'GTC',
    name: 'GTC',
    description:
      'Order dengan antrian mengikuti sesi  aktif sesuai perdagangan bursa.',
    orderType: 'GTC'
  }
};

export const ORDER_COST = {
  buy: 0.0015,
  sell: 0.0025,
  stampCost: 10000,
  stampMinimalInvest: 10000000
};

export const DEFAULT_WORKSPACE = {
  lg: [
    {
      i: 'n9495241691',
      x: 0,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'running-trade',
        symbol: 'UNVR',
        title: 'Running Trade'
      }
    },
    {
      i: 'n9495241692',
      x: 3,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'orderbook',
        symbol: 'BBRI',
        title: 'Orderbook'
      }
    }
  ],
  md: [
    {
      i: 'n9495241691',
      x: 0,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'running-trade',
        symbol: 'ANTM',
        title: 'Running Trade'
      }
    },
    {
      i: 'n9495241692',
      x: 3,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'orderbook',
        symbol: 'BMRI',
        title: 'Orderbook'
      }
    }
  ],
  sm: [
    {
      i: 'n9495241691',
      x: 0,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'running-trade',
        symbol: 'BRPT',
        title: 'Running Trade'
      }
    },
    {
      i: 'n9495241692',
      x: 3,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'orderbook',
        symbol: 'AGII',
        title: 'Orderbook'
      }
    }
  ],
  xs: [
    {
      i: 'n9495241691',
      x: 0,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'running-trade',
        symbol: 'BMRI',
        title: 'Running Trade'
      }
    },
    {
      i: 'n9495241692',
      x: 3,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'orderbook',
        symbol: 'UNVR',
        title: 'Orderbook'
      }
    }
  ],
  xxs: [
    {
      i: 'n9495241691',
      x: 0,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'running-trade',
        symbol: 'TLKM',
        title: 'Running Trade'
      }
    },
    {
      i: 'n9495241692',
      x: 3,
      y: 0,
      w: 3,
      h: 31,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        name: 'orderbook',
        symbol: 'PTBA',
        title: 'Orderbook'
      }
    }
  ]
};

export const BREAKPOINTS = {
  0: 'xxs',
  480: 'xs',
  768: 'sm',
  996: 'md',
  1200: 'lg'
};