export const getMarkStyle = (value) => {
  if (
    ['deviden saham', 'cash dividend', 'interim dividend']?.includes(
      value?.toLowerCase()
    )
  ) {
    return {
      label: 'D',
      shape: 'circle',
      color: 'green',
      minSize: 40,
    };
  }

  if (['rups']?.includes(value?.toLowerCase())) {
    return {
      label: 'R',
      shape: 'earning',
      color: 'orange',
      minSize: 40,
    };
  }

  if (['stock split', 'reverse stock']?.includes(value?.toLowerCase())) {
    return {
      label: 'R',
      shape: 'circle',
      color: 'orange',
      minSize: 40,
    };
  }

  if (
    ['saham bonus', 'warrant', 'right issue']?.includes(value?.toLowerCase())
  ) {
    return {
      label: 'W',
      shape: 'earningUp',
      color: 'blue',
      minSize: 40,
    };
  }

  return {
    label: 'X',
    shape: 'earningUp',
    color: 'blue',
    minSize: 40,
  };
};
