import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import InputStock from 'components/input/input-stock';
import IconClose from 'components/svg/icon-close';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useDispatch, useSelector } from 'react-redux';
import { useGetStockThematicByIdQuery, useGetStockTopQuery } from 'services/rtk-query/stock';

import "components/input/css/custom/customize-input-stock.css";
import { useEffect, useRef, useState } from 'react';
import { setRecentSymbol } from 'store/pina-pro';
import { find } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

function StockSearchForm({
  onClose = () => {},
  onSelect = () => {},
  isMultiple = false,
  header = false
}) {
  const refInputCTN = useRef(null);
  const dispatch = useDispatch();
  const { recentSymbol } = useSelector(({ pinaPro }) => pinaPro);

  const { data: dataTopValue, isLoading: loadingTopValue } = useGetStockTopQuery({ type: 'top-value', params: { size: 6, page: 0 }});
  const { data: dataTopFrequency, isLoading: loadingTopFrequency } = useGetStockTopQuery({ type: 'top-frequency', params: { size: 6, page: 0 }});
  const { data: dataIDX30, isLoading: loadingIDX30 } = useGetStockThematicByIdQuery('6360c4b93e25a27b266bbd77');

  const [stocks, setStocks] = useState([]);

  const handleClickStock = (stock) => {
    if (isMultiple) {
      let _tempStocks = [...stocks];
      if (!stocks.includes(stock?.code)) {
        _tempStocks.push(stock?.code);
        setStocks(_tempStocks);
      }
    } else {
      onSelect(stock);
    }

    if (!find(recentSymbol, ['code' , stock?.code])) {
      dispatch(setRecentSymbol([stock, ...recentSymbol]))
    }
  }

  useEffect(() => {
    setTimeout(() => {
      refInputCTN?.current?.querySelector('input')?.focus();
    }, 500);
  }, [])

  return (
    <Card className="p-4 lg:p-6 pt-16 lg:pt-6 rounded-2xl max-w-xl lg:mx-auto relative dark:bg-black-base-d dark:border dark:border-gray-base-d">
      <button
        type="button"
        onClick={onClose}
        className="appearance-none absolute right-4 top-4 hover:text-main-base-l dark:text-main-base-l hover:opacity-75 flex justify-center items-center transition-all ease-in-out duration-150">
        <IconClose />
      </button>
      
      {header}

      <div ref={refInputCTN} className='mb-6 max-w-[350px] mx-auto'>
        <InputStock
          maxHeight="500px"
          onChange={(stock) => handleClickStock(stock)}
          className="customize-input-stock"
          label={false}
        />
      </div>

      <OverlayScrollbarsComponent
        className={`overlayscrollbars-host-small ${header ? 'max-h-[55vh]' : 'max-h-[unset]'}`}
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        >
        <RenderIf isTrue={recentSymbol?.length > 0}>
          <h5 className="my-3 mt-0 text-xs font-quicksand-semibold dark:text-black-light-1-d">
            Pencarian Terakhir
          </h5>
          <ul className="grid grid-cols-4 lg:grid-cols-6 gap-2 mb-3">
            {recentSymbol?.map((item, key) => (
              <li key={key}>
                  <button onClick={() => handleClickStock({ code: item?.code, name: item?.name })} type='button' className={`${stocks.includes(item?.code) ? 'border-gray-base-d bg-main-base-l text-white' : '' } group flex items-center border hover:bg-main-base-l dark:hover:text-black-base-d dark:border-gray-base-d hover:text-white transition-all ease-in-out duration-150 text-center font-quicksand-semibold px-2 py-[4px] rounded-lg text-2xs cursor-pointer`}>
                    <RenderIf isTrue={!item?.logo}>
                      <div
                        className="rounded-full mr-1 flex-none bg-gray-light-l bg-opacity-50 text-xs flex items-center justify-center font-quicksand-bold"
                        style={{ height: '20px', width: '20px' }}>
                        {item?.code?.charAt(0)}
                      </div>
                    </RenderIf>

                    <RenderIf isTrue={item?.logo}>
                      <img
                        className="rounded-full mr-1 flex-none"
                        style={{ width: '20px', height: '20px' }}
                        width={42}
                        height={42}
                        src={item?.logo}
                      />
                    </RenderIf>

                    <span className='line-clamp-1 dark:text-black-light-1-d dark:group-hover:text-black-base-d'>{item?.code}</span>
                  </button>
              </li>
            ))}
          </ul>
        </RenderIf>

        <h5 className="my-3 mt-0 text-xs font-quicksand-semibold dark:text-black-light-1-d">Top Value</h5>
        <RenderIf isTrue={loadingTopValue}>
          <div className="grid grid-cols-4 lg:grid-cols-6 gap-2">
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
          </div>
        </RenderIf>

        <ul className="grid grid-cols-4 lg:grid-cols-6 gap-2">
          {dataTopValue?.map((item, key) => (
            <li key={key}>
              <button type='button' onClick={() => handleClickStock(item)} className={`${stocks.includes(item?.code) ? 'border-gray-base-d bg-main-base-l text-white' : '' } group flex items-center border hover:bg-main-base-l dark:hover:text-black-base-d dark:border-gray-base-d hover:text-white transition-all ease-in-out duration-150 text-center font-quicksand-semibold px-2 py-[4px] rounded-lg text-2xs cursor-pointer`}>
                <RenderIf isTrue={!item?.logo}>
                  <div
                    className="rounded-full mr-1 flex-none bg-gray-light-l bg-opacity-50 text-xs flex items-center justify-center font-quicksand-bold"
                    style={{ height: '20px', width: '20px' }}>
                    {item?.code?.charAt(0)}
                  </div>
                </RenderIf>

                <RenderIf isTrue={item?.logo}>
                  <img
                    className="rounded-full mr-1 flex-none"
                    style={{ width: '20px', height: '20px' }}
                    width={42}
                    height={42}
                    src={item?.logo}
                  />
                </RenderIf>

                <span className='line-clamp-1 dark:text-black-light-1-d dark:group-hover:text-black-base-d'>{item?.code}</span>
              </button>
            </li>
          ))}
        </ul>

        <h5 className="my-3 text-xs font-quicksand-semibold dark:text-black-light-1-d">Top Frequency</h5>
        <RenderIf isTrue={loadingTopFrequency}>
          <div className="grid grid-cols-4 lg:grid-cols-6 gap-2">
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
          </div>
        </RenderIf>

        <ul className="grid grid-cols-4 lg:grid-cols-6 gap-2">
          {dataTopFrequency?.map((item, key) => (
            <li key={key}>
              <button type='button' onClick={() => handleClickStock(item)} className={`${stocks.includes(item?.code) ? 'border-gray-base-d bg-main-base-l text-white' : '' } group flex items-center border hover:bg-main-base-l dark:hover:text-black-base-d dark:border-gray-base-d hover:text-white transition-all ease-in-out duration-150 text-center font-quicksand-semibold px-2 py-[4px] rounded-lg text-2xs cursor-pointer`}>    
                <RenderIf isTrue={!item?.logo}>
                  <div
                    className="rounded-full mr-1 flex-none bg-gray-light-l bg-opacity-50 text-xs flex items-center justify-center font-quicksand-bold"
                    style={{ height: '20px', width: '20px' }}>
                    {item?.code?.charAt(0)}
                  </div>
                </RenderIf>

                <RenderIf isTrue={item?.logo}>
                  <img
                    className="rounded-full mr-1 flex-none"
                    style={{ width: '20px', height: '20px' }}
                    width={42}
                    height={42}
                    src={item?.logo}
                  />
                </RenderIf>

                <span className='line-clamp-1 dark:text-black-light-1-d dark:group-hover:text-black-base-d'>{item?.code}</span>
              </button>
            </li>
          ))}
        </ul>

        <h5 className="my-3 text-xs font-quicksand-semibold dark:text-black-light-1-d">IDX30</h5>

        <RenderIf isTrue={loadingIDX30}>
          <div className="grid grid-cols-4 lg:grid-cols-6 gap-2">
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
            <SkeletonTheme width="100%" height="2rem" borderRadius="1rem" />
          </div>
        </RenderIf>

        <ul className="grid grid-cols-4 lg:grid-cols-6 gap-2">
          {dataIDX30?.data?.map((item, key) => (
            <li key={key}>
              <button type='button' onClick={() => handleClickStock(item)} className={`${stocks.includes(item?.code) ? 'border-gray-base-d bg-main-base-l text-white' : '' } group flex items-center border hover:bg-main-base-l dark:hover:text-black-base-d dark:border-gray-base-d hover:text-white transition-all ease-in-out duration-150 text-center font-quicksand-semibold px-2 py-[4px] rounded-lg text-2xs cursor-pointer`}>
                <RenderIf isTrue={!item?.logo}>
                  <div
                    className="rounded-full mr-1 flex-none bg-gray-light-l bg-opacity-50 text-xs flex items-center justify-center font-quicksand-bold"
                    style={{ height: '20px', width: '20px' }}>
                    {item?.code?.charAt(0)}
                  </div>
                </RenderIf>

                <RenderIf isTrue={item?.logo}>
                  <img
                    className="rounded-full mr-1 flex-none"
                    style={{ width: '20px', height: '20px' }}
                    width={42}
                    height={42}
                    src={item?.logo}
                  />
                </RenderIf>

                <span className='line-clamp-1 dark:text-black-light-1-d dark:group-hover:text-black-base-d'>{item?.code}</span>
              </button>
            </li>
          ))}
        </ul>
      </OverlayScrollbarsComponent>


      <RenderIf isTrue={isMultiple}>
        <p className='text-right text-gray-light-0-d font-quicksand-semibold mt-8 mb-4 text-sm'>{stocks?.length} Terpilih</p>
        <div className="grid grid-cols-2 gap-4">
          <button onClick={onClose} type='button' className="border-2 border-gray-base-d dark:border-main-base-l disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-main-base-l py-3 px-3 text-xs font-quicksand-semibold rounded-lg w-full mr-0 ml-auto block transition-all ease-in-out duration-100">
            Kembali
          </button>

          <button onClick={() => onSelect(stocks)} disabled={stocks?.length === 0} type='button' className="bg-main-base-l dark:text-black-base-d disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-white py-3 px-3 text-xs font-quicksand-semibold rounded-lg w-full mr-0 ml-auto block transition-all ease-in-out duration-100">
            Selanjutnya
          </button>
        </div>
      </RenderIf>
    </Card>
  );
}

StockSearchForm.propTypes = {
  onClose : PropTypes.func,
  onSelect : PropTypes.func,
  isMultiple : PropTypes.func,
  header : PropTypes.any,
};

export default StockSearchForm;
