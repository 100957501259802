import APIService from 'services/api.service.js';
import historyProvider from './historyProvider.js';
import { dayjsLocal } from 'utils/formatter/date.js';

let _subs = [];
let sseAdvanced = {};
let sseAdvancedVol = {};

export default function stream(idWidget) {
  return {
    // eslint-disable-next-line no-unused-vars
    subscribeBars: async function (symbolInfo, resolution, updateCb, uid, resetCache) {
      const channelString = createChannelString(symbolInfo);
  
      let newSub = {
        channelString,
        uid,
        resolution,
        symbolInfo,
        lastBar: historyProvider(idWidget)?.history?.[symbolInfo.name]?.lastBar,
        listener: updateCb
      };
  
      _subs.push(newSub);
  
      let { data: historyBE } = await APIService.get(`/trade/Intraday/qsx2?code=${symbolInfo?.ticker}&d=-1`);
  
      sseAdvanced[`${symbolInfo?.ticker}-${idWidget}`] = new EventSource(`${process.env.REACT_APP_API_URL}/../../ticker?stock[]=${symbolInfo?.ticker}`);
  
      sseAdvanced[`${symbolInfo?.ticker}-${idWidget}`].onmessage = (e) => {
        const res = JSON.parse(e.data);
  
        if (!isNaN(Number(res[symbolInfo?.ticker]['lastTradedPrice']))) {
          const tradeTime = dayjsLocal().valueOf();
    
          let data = {
            exchange: "IDX",
            ts: tradeTime,
            price: Number(res[symbolInfo?.ticker]['lastTradedPrice']),
            low: historyBE?.[0]?.low,
            high: historyBE?.[0]?.high,
            open: historyBE?.[0]?.open,
          };
    
          const channelString = `0~${symbolInfo?.ticker}`;
          const sub = _subs.find((e) => e.channelString === channelString);
    
          if (sub) {
            if ((tradeTime) < (sub?.lastBar?.time / 1000)) return;
            if (!sseAdvancedVol[`${symbolInfo?.ticker}-${idWidget}`] && historyBE) {
              sseAdvancedVol[`${symbolInfo?.ticker}-${idWidget}`] = historyBE?.[0]?.vol;
            } 
  
            if (historyBE) {;
              data.volume = (Number(res[symbolInfo?.ticker]['tradableLot']) * 100) - Number(sseAdvancedVol[`${symbolInfo?.ticker}-${idWidget}`]);
              // TODO! data volume harusnya diambil dari TICKER, bukan kalkulasi sendiri
            }
  
            const _lastBar = updateBar(data, sub);
            // send the most recent bar back to TV's realtimeUpdate callback
            sub.listener(_lastBar);
            // update our own record of lastBar
            sub.lastBar = _lastBar;
  
            sseAdvancedVol[`${symbolInfo?.ticker}-${idWidget}`] = Number(res[symbolInfo?.ticker]['tradableLot']) * 100;
          }
        } 
      };
    },
    unsubscribeBars: function (uid) {
      let subIndex = _subs.findIndex((e) => e.uid === uid);
      if (subIndex === -1) return;
  
      let sub = _subs[subIndex];
      
      sseAdvanced[`${sub?.symbolInfo?.ticker}-${idWidget}`].onerror = () => {
        sseAdvanced[`${sub?.symbolInfo?.ticker}-${idWidget}`].close();
      };
  
      _subs.splice(subIndex, 1);
    }
  }
}

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
  let lastBar = sub?.lastBar;
  let lastBarSec = lastBar?.time / 1000;
  let _lastBar;

  if (data?.ts > lastBarSec) {
    // create a new candle, use last close as open **PERSONAL CHOICE**
    _lastBar = {
      time: data?.ts,
      open: data?.open || lastBar?.open,
      high: data?.high ? lastBar?.high < data?.high ? data?.high : lastBar?.high : lastBar?.high,
      low: data?.low ? lastBar?.low > data?.low ? data?.low : lastBar?.low : lastBar?.low,
      close: data.price,
      volume: data?.volume
    };
  } else {
    // update lastBar candle!
    if (data?.price < lastBar?.low) {
      lastBar.low = data?.price;
    } else if (data?.price > lastBar?.high) {
      lastBar.high = data?.price;
    }
    
    lastBar.volume += data?.volume;
    lastBar.close = data?.price;
    _lastBar = lastBar;
  }

  return _lastBar;
}

// takes symbolInfo object as input and creates the subscription string to send to CryptoCompare
function createChannelString(symbolInfo) {
  let channel = symbolInfo.name.split(/[:/]/);
  const exchange = channel[0];
  return `0~${exchange}`;
}
