import RenderIf from 'components/basic/render-if';
import useDarkMode from 'hooks/useDarkMode';
import QuickOrderSticky from 'pages/trading/_partials/quick-order-sticky';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import Modal from '../modal';
import TWStockDetail from 'pages/trading/_partials/tw-stock-detail';
import PriceAlertForm from 'components/form/price-alert-form';
import SimpleOrderForm from 'components/form/simple-order-form';
import TVRealtimeChartAdvanced from 'components/chart/tradingview/_partials/tv-realtime-chart-advanced';
import IconRefresh from 'components/svg/icon-refresh';
import IconClose from 'components/svg/icon-close';

import LogoPinaWhite from 'assets/images/logo/pina-white.png';
import LogoPina from 'assets/images/logo/pina.png';
import FastOrderForm from 'components/form/fast-order-form';
import { setRecentSymbol } from 'store/pina-pro';
import { find } from 'lodash';
import StockSearchForm from 'components/form/stock-search-form';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

export default function ModalTradingProvider() {
  const dispatch = useDispatch();
  const { navigate, customNavigate } = useCustomNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { theme } = useDarkMode();
  
  const { user } = useBrowserTabAuth();
  const username = user?.username;
  const id = user?.id;

  const { recentSymbol } = useSelector(({ pinaPro }) => pinaPro);

  const symbolStockDetail = searchParams?.get('stock-detail');
  const symbolPriceAlert = searchParams?.get('price-alert');
  const symbolAdvancedChart = searchParams?.get('advanced-chart');

  const symbolSimpleOrder = searchParams?.get('simple-order');
  const simpleOrderType = searchParams?.get('order-type') || 'SimpleBuy';
  const symbolFastOrder = searchParams?.get('fast-order');
  const price = searchParams?.get('price');

  const showSearchStockForm = searchParams?.get('search-stock');
  const isStackedModal = searchParams?.size > 1;

  return (
    <>
      {/* QUICK ORDER */}
      <QuickOrderSticky />

      {/* ADVANCED CHART */}
      <RenderIf isTrue={symbolAdvancedChart}>
        <Modal size="full" onClose={() => (isStackedModal ? navigate(-1) : customNavigate())}>
          <div className="card-widget-gradient justify-between flex p-4 px-6 rounded-t-xl">
            <RenderIf isTrue={theme === 'dark'}>
              <img
                className="h-6 w-auto"
                src={LogoPinaWhite}
                alt="Pina Indonesia"
              />
            </RenderIf>

            <RenderIf isTrue={theme === 'light'}>
              <img className="h-6 w-auto" src={LogoPina} alt="Pina Indonesia" />
            </RenderIf>

            <div className="flex justify-end flex-none">
              <button
                type="button"
                className="outline-none appearance-none flex items-center mx-4 justify-center hover:text-main-base-l dark:text-main-base-l dark:hover:opacity-75 transition-all duration-200"
                onClick={() => {
                  dispatch(invalidateStockByCode(symbolAdvancedChart));
                }}
                title="Refresh Widget">
                <IconRefresh className="h-5 w-5" />
              </button>

              <button
                type='button'
                onClick={() => (isStackedModal ? navigate(-1) : customNavigate())}
                className="outline-none appearance-none flex items-center justify-center hover:text-main-base-l dark:text-main-base-l dark:hover:opacity-75 transition-all duration-200">
                <IconClose />
              </button>
            </div>
          </div>
          <div
            style={{ height: 'calc(100vh - 3.5rem)' }}
            className="bg-white dark:bg-black-dark-d rounded-b-xl overflow-hidden">
            <TVRealtimeChartAdvanced
              userId={id}
              clientId={username}
              symbol={symbolAdvancedChart}
              interval="1D"
              isRerender
              isAdvancedChart
              isSingle
              overrides={{
                'paneProperties.background': '#131103',
                'paneProperties.backgroundType': 'solid'
              }}
            />
          </div>
        </Modal>
      </RenderIf>

      {/* STOCK DETAIL */}
      <RenderIf isTrue={symbolStockDetail}>
        <Modal size="large" onClose={() => customNavigate()}>
          <TWStockDetail symbol={symbolStockDetail} />
        </Modal>
      </RenderIf>

      {/*  PRICE ALERT */}
      <RenderIf isTrue={symbolPriceAlert}>
        <Modal onClose={() => (isStackedModal ? navigate(-1) : customNavigate())}>
          <PriceAlertForm
            symbol={symbolPriceAlert}
            onClose={() => (isStackedModal ? navigate(-1) : customNavigate())}
          />
        </Modal>
      </RenderIf>

      {/* SIMPLE ORDER */}
      <RenderIf isTrue={symbolSimpleOrder}>
        <Modal onClose={() => (isStackedModal ? navigate(-1) : customNavigate())}>
          <SimpleOrderForm
            symbol={symbolSimpleOrder}
            typeOrder={simpleOrderType}
            initPrice={price}
            onClose={() => (isStackedModal ? navigate(-1) : customNavigate())}
            onSuccess={() => (isStackedModal ? navigate(-1) : customNavigate())}
          />
        </Modal>
      </RenderIf>

      {/* FAST ORDER */}
      <RenderIf isTrue={symbolFastOrder}>
        <Modal size="full" onClose={() => (isStackedModal ? navigate(-1) : customNavigate())}>
          <div className='zoom-2'>
            <FastOrderForm
              symbol={symbolFastOrder}
              onClose={() => (isStackedModal ? navigate(-1) : customNavigate())}
            />
          </div>
        </Modal>
      </RenderIf>

      {/* SEARCH STOCK FORM */}
      <RenderIf isTrue={showSearchStockForm}>
        <Modal onClose={() => customNavigate()}>
          <StockSearchForm
            onClose={() => customNavigate()}
            onSelect={(stock) => {
              navigate(`${pathname}?stock-detail=${stock?.code}`, {
                replace: true
              });

              if (!find(recentSymbol, ['code', stock?.code])) {
                dispatch(setRecentSymbol([stock, ...recentSymbol]));
              }
            }}
          />
        </Modal>
      </RenderIf>
    </>
  );
}
