import Breadcrumb from 'components/basic/breadcrumb';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import { ListMenuNotification } from 'constants/list-profile-menu';

import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import ListMessage from './_partials/list-message';
import ListPriceAlert from './_partials/list-price-alert';

function PageNotification() {
  const navigate = useNavigate();
  const { slug } = useParams();

  return (
    <div>
      <div className="p-4">
        <Helmet>
          <title>Notification | Pina Trade</title>
        </Helmet>

        <h1 className="text-2xl mb-3">Notifications</h1>
        <Breadcrumb
          data={[
            {
              link: '/',
              title: 'Home',
              icon: null
            },
            {
              link: '#',
              title: 'Notifications',
              icon: null
            }
          ]}
        />

        <div className="grid grid-cols-12 gap-4 mt-4">
          <div className="col-span-12 lg:col-span-4">
            <Card className="p-5 pb-3">
              <h5>Notifikasi</h5>

              <div className="-mx-6">
                <ul>
                  {ListMenuNotification.map((item, key) => (
                    <li
                      onClick={() => navigate(item.url, { replace: true })}
                      key={key}
                      className={`${
                        slug === item.slug && 'text-orange-1'
                      } cursor-pointer px-6 font-quicksand-semibold text-sm hover:text-orange-1 transition-all ease-in-out duration-100`}>
                      <div className={`py-4 ${key > 0 && 'border-t'}`}>
                        {item?.text}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Card>
          </div>

          <div className="col-span-12 lg:col-span-8">
            <RenderIf isTrue={slug === ListMenuNotification[0].slug}>
              <ListMessage />
            </RenderIf>

            <RenderIf isTrue={slug === ListMenuNotification[1].slug}>
              <ListPriceAlert />
            </RenderIf>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotification;
