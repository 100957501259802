import { ListParameterScreener } from "constants/list-parameter-screener"

export const findParamStockScreenerByFieldName = (fieldName) => {
  if (!fieldName) return '';
  let findObject = false;

  ListParameterScreener?.forEach(nested1 => {
    if (nested1?.fieldName === fieldName) {
      findObject = nested1;
    }

    nested1?.children?.forEach(nested2 => {
      if (nested2?.fieldName === fieldName) {
        findObject = nested2;
      }

      nested2?.children?.forEach(nested3 => {
        if (nested3?.fieldName === fieldName) {
          findObject = nested3;
        }
      });
    });
  });

  return findObject;
}