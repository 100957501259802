import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import IconBell from 'components/svg/icon-bell';
import IconStar from 'components/svg/icon-star';
import { currency } from 'utils/format';
import { useGetStockInfoByCodeQuery } from 'services/rtk-query/stock';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'components/basic/tooltip';
import { checkCodeNotation } from 'utils/stock/notation';
import {
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation
} from 'services/rtk-query/watchlist';
import {
  useDeletePriceAlertMutation,
  useGetPriceAlertByCodeQuery
} from 'services/rtk-query/price-alert';
import { isEmpty } from 'lodash';
import SkeletonTheme from 'components/basic/skeleton-theme';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconStarDelete from 'components/svg/icon-star-delete';
import IconBellDelete from 'components/svg/icon-bell-delete';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import IconCompare from 'components/svg/icon-compare';
import { useCompareStock } from 'hooks/useCompareStock';
import { triggerSegmentEvent } from 'lib/segment';
import InputStock from 'components/input/input-stock';
import IconSearchPage from 'components/svg/icon-search-page';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useToastr from 'hooks/useToastr';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { getStockRemarkSymbol } from 'utils/stock/remark';
import InputSelect from 'components/input/input-select';
import cn from 'utils/classname';

const Tools = ({
  advancedChart,
  showStockDetail,
  showPriceAlert,
  symbol,
  showCompare,
  showWatchlist
}) => {
  const { customNavigate, navigate } = useCustomNavigate();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { stockDetail } = useRealtimeStock(symbol, false);

  const { data: priceAlert } = useGetPriceAlertByCodeQuery(symbol);
  const { addToCompareStock } = useCompareStock();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();
  const [deletePriceAlert] = useDeletePriceAlertMutation();

  const handleClickWatchlist = async (stock) => {
    triggerSegmentEvent({ event: 'TR Watchlist Clicked' });
    if (!stock?.isWatched) {
      try {
        await createWatchlist({
          stockCode: stock?.id,
          type: 'stock'
        }).unwrap();
        toastr.success(`${stock?.id} berhasil ditambahkan kedalam watchlist`);
      } catch (error) {
        getErrorMessage(error);
      }
    } else {
      try {
        await deleteWatchlist({ stockCode: stock?.id }).unwrap();
        toastr.success(`${stock?.id} berhasil dihapus dari watchlist`);
      } catch (error) {
        getErrorMessage(error);
      }
    }
  };

  const handleClickPriceAlert = async (priceAlert) => {
    if (isEmpty(priceAlert) && !priceAlert?.is_active) {
      customNavigate({ query: { 'price-alert': symbol }, isPushQuery: true });
    } else {
      try {
        await deletePriceAlert(priceAlert).unwrap();
      } catch (error) {
        getErrorMessage(error);
      }
    }
  };

  const getConditionPriceAlert = (priceAlert) => {
    if (priceAlert?.when === 'upper') {
      return stockDetail?.lastTradedPrice > priceAlert?.price;
    }

    if (priceAlert?.when === 'lower') {
      return stockDetail?.lastTradedPrice < priceAlert?.price;
    }

    if (priceAlert?.when === 'equal') {
      return stockDetail?.lastTradedPrice === priceAlert?.price;
    }
  };

  return (
    <span className="flex items-center justify-between my-1 gap-2">
      <RenderIf isTrue={advancedChart}>
        <Tooltip
          isZoomOut
          tooltipHTML={
            <p className="text-2xs font-quicksand-semibold">Tradingview</p>
          }>
          <button
            type="button"
            onClick={() => {
              triggerSegmentEvent({
                event: 'TR Stock Detail Trading View Clicked'
              });
              customNavigate({ query: { 'advanced-chart': symbol } });
            }}
            className="cursor-pointer group">
            <IconChartCandleStick className="h-4 w-4 text-main-base-l" />
          </button>
        </Tooltip>
      </RenderIf>

      <RenderIf isTrue={showStockDetail}>
        <Tooltip
          isZoomOut
          tooltipHTML={
            <p className="text-2xs font-quicksand-semibold">Stock Detail</p>
          }>
          <button
            type="button"
            onClick={() =>
              customNavigate({ query: { 'stock-detail': symbol } })
            }
            className="cursor-pointer group inline-block">
            <IconSearchPage className="h-4 w-4 text-main-base-l" />
          </button>
        </Tooltip>
      </RenderIf>

      <RenderIf isTrue={showPriceAlert}>
        <Tooltip
          isZoomOut
          tooltipHTML={
            <p className="text-2xs font-quicksand-semibold">
              {isEmpty(priceAlert)
                ? 'Price Alert Tidak Aktif'
                : 'Price Alert Aktif'}
            </p>
          }>
          <button
            type="button"
            className={`${
              getConditionPriceAlert(priceAlert) &&
              isBursaOpen &&
              'animate-blink-infinite'
            }`}
            onClick={() => handleClickPriceAlert(priceAlert)}>
            <RenderIf isTrue={isEmpty(priceAlert)}>
              <IconBellDelete className="h-4 w-4 text-main-base-l cursor-pointer" />
            </RenderIf>

            <RenderIf isTrue={!isEmpty(priceAlert)}>
              <IconBell className="h-4 w-4 text-main-base-l cursor-pointer" />
            </RenderIf>
          </button>
        </Tooltip>
      </RenderIf>

      <RenderIf isTrue={showCompare}>
        <Tooltip
          isZoomOut
          tooltipHTML={
            <p className="text-2xs font-quicksand-semibold">Bandingkan</p>
          }>
          <button
            type="button"
            onClick={() => {
              triggerSegmentEvent({ event: 'TR Compare Stock Clicked' });
              addToCompareStock(
                { code: symbol, name: stockDetail?.name },
                () => {
                  navigate(`/compare/fundamental`);
                }
              );
            }}>
            <IconCompare className="h-4 w-4 text-main-base-l cursor-pointer" />
          </button>
        </Tooltip>
      </RenderIf>

      <RenderIf isTrue={showWatchlist}>
        <Tooltip
          isZoomOut
          tooltipHTML={
            <p className="text-2xs font-quicksand-semibold">
              {stockDetail?.isWatched
                ? 'Watchlist Aktif'
                : 'Watchlist Tidak Aktif'}
            </p>
          }>
          <button
            type="button"
            onClick={() => handleClickWatchlist(stockDetail)}>
            <RenderIf isTrue={stockDetail?.isWatched}>
              <IconStar className="h-4 w-4 text-main-base-l cursor-pointer" />
            </RenderIf>

            <RenderIf isTrue={!stockDetail?.isWatched}>
              <IconStarDelete className="h-4 w-4 text-main-base-l cursor-pointer" />
            </RenderIf>
          </button>
        </Tooltip>
      </RenderIf>
    </span>
  );
};

const Price = ({ showPrice, symbol, type }) => {
  const [tempStockDetail, setTempStockDetail] = useState(null);
  const [toggleActive, setToggleActive] = useState(false);
  const { customNavigate } = useCustomNavigate();
  const { stockDetail, isLoadingStock } = useRealtimeStock(symbol, true);

  useEffect(() => {
    if (
      Number(tempStockDetail?.lastTradedPrice) !=
      Number(stockDetail?.lastTradedPrice)
    ) {
      setTempStockDetail(stockDetail);
      setToggleActive(tempStockDetail);

      setTimeout(() => {
        setToggleActive(false);
      }, 2000);
    }
  }, [stockDetail, tempStockDetail]);

  return (
    <div className="flex flex-auto">
      <RenderIf isTrue={showPrice}>
        <div className={`flex items-center justify-end w-full text-right`}>
          <div
            onAnimationEnd={() => setToggleActive(false)}
            onClick={() =>
              customNavigate({ query: { 'simple-order': symbol } })
            }
            className={`cursor-pointer ${
              stockDetail?.score?.type === 'plus'
                ? 'text-green-base-l'
                : stockDetail?.score?.type === 'minus'
                ? 'text-red-base-l'
                : 'text-black-dark-d dark:text-black-light-2-d'
            }
                  ${toggleActive && 'animate-blink'}
                  `}>
            <RenderIf isTrue={isLoadingStock}>
              <SkeletonTheme
                containerClassName="leading-none"
                width="50px"
                height="18px"
                borderRadius="4px"
              />
            </RenderIf>

            <RenderIf isTrue={!isLoadingStock}>
              <span
                className={cn(
                  'text-2xs text-black-light-0-d font-quicksand-regular text-left block w-full mt-1',
                  type === 'LONG' && 'text-right'
                )}>
                Last
              </span>

              <div className={cn(type !== 'LONG' && 'flex items-center gap-1')}>
                <h2
                  title="Last Price"
                  className={`text-base font-quicksand-regular flex items-center justify-between gap-1.5 ${
                    type === 'LONG' && 'text-right !block w-full'
                  } ${
                    stockDetail?.score?.type === 'plus'
                      ? 'text-green-base-l'
                      : stockDetail?.score?.type === 'minus'
                      ? 'text-red-base-l'
                      : 'text-black-dark-d dark:text-black-light-2-d'
                  }`}>
                  {currency(stockDetail?.lastTradedPrice || 0, {
                    removeSymbol: true
                  })}
                </h2>

                <p className="text-2xs">
                  <RenderIf isTrue={stockDetail?.score?.type === 'plus'}>
                    +
                  </RenderIf>
                  {currency(stockDetail?.score?.diff || 0, {
                    removeSymbol: true
                  })}{' '}
                  ({Number(Math.abs(stockDetail?.score?.value || 0)).toFixed(2)}
                  %)
                </p>
              </div>
            </RenderIf>
          </div>
        </div>
      </RenderIf>
    </div>
  );
};

function TVHeader({
  className,
  symbol,
  advancedChart,
  showStockDetail = true,
  showPriceAlert = true,
  showCompare = true,
  showWatchlist = true,
  onChangeSymbol = () => {},
  type,
  showPrice = true,
  showPriceOnly = false
}) {
  const { data: stockInfo, isLoading: isLoadingStockInfo } =
    useGetStockInfoByCodeQuery(symbol);
  const [tempSymbol, setTempSymbol] = useState(symbol);
  const [tempBoardID, setTempBoardID] = useState('RG');

  // const clearSymbol = useMemo(() => {
  //   let zymbol = symbol;
  //   if (tempBoardID !== 'RG') {
  //     zymbol = zymbol.slice(0, -2);
  //   }

  //   return zymbol;
  // }, [symbol, tempBoardID]);

  useEffect(() => {
    setTempSymbol(symbol);
  }, [symbol]);

  useEffect(() => {
    if (symbol?.replaceAll(symbol?.slice(0, -2), '') === 'TN') {
      setTempBoardID('TN');
    } else if (symbol?.replaceAll(symbol?.slice(0, -2), '') === 'NG') {
      setTempBoardID('NG');
    } else {
      setTempBoardID('RG');
    }
  }, [symbol]);

  return (
    <div
      className={`p-3 ${className} ${
        type === 'LONG' && showPrice && 'grid grid-cols-12 gap-4'
      }`}>
      <RenderIf isTrue={!showPriceOnly}>
        <div
          className={`flex items-center ${
            type === 'LONG' ? 'justify-start col-span-6' : 'justify-between'
          }`}>
          <div className="flex items-center w-full">
            <div className="flex-auto w-full text-base font-quicksand-semibold text-main-base-l transition-all ease-in-out duration-150">
              <div className="flex items-center justify-between gap-1 pb-2">
                <div className="flex-auto">
                  <RenderIf isTrue={stockInfo?.name}>
                    <p className="text-2xs rounded-[4px] line-clamp-1 text-black-dark-d dark:text-black-light-1-d">
                      {stockInfo?.name}
                    </p>
                  </RenderIf>

                  <RenderIf isTrue={isLoadingStockInfo}>
                    <SkeletonTheme
                      containerClassName="leading-none mb-2 block"
                      width="100%"
                      height="22px"
                      borderRadius="4px"
                    />
                  </RenderIf>
                </div>

                <RenderIf
                  isTrue={
                    (getStockRemarkSymbol(stockInfo?.remarks2) || [])?.length >
                      0 && !isLoadingStockInfo
                  }>
                  <div className="flex items-center gap-2 flex-none">
                    <button
                      type="button"
                      className="flex items-center text-sm flex-auto">
                      <div className="flex items-center font-quicksand-bold text-2xs px-2 rounded-md">
                        {(getStockRemarkSymbol(stockInfo?.remarks2) || [])?.map(
                          (item, key) => (
                            <div key={key} className="flex items-center">
                              <RenderIf isTrue={key === 0}>
                                <span className="w-2 h-2 bg-orange-1 rounded-full mr-[2px]"></span>
                              </RenderIf>

                              <Tooltip
                                isZoomOut
                                tooltipHTML={
                                  <p className="text-2xs max-w-[250px]">
                                    {checkCodeNotation(item)}
                                  </p>
                                }>
                                <span className="text-black-base-l dark:text-black-light-1-d text-2xs">
                                  {item}
                                </span>
                              </Tooltip>
                            </div>
                          )
                        )}
                      </div>
                    </button>
                  </div>
                </RenderIf>
              </div>

              <div className="flex items-center gap-2 mb-1">
                <div className="w-20 flex-none">
                  <InputSelect
                    value={tempBoardID}
                    label={false}
                    size="small"
                    className="mb-0"
                    options={[
                      {
                        id: 'RG',
                        name: 'RG'
                      },
                      {
                        id: 'TN',
                        name: 'TN'
                      },
                      {
                        id: 'NG',
                        name: 'NG'
                      }
                    ]}
                    onChange={(e) => {
                      let boardId = e.target.value;
                      let currentSymbol = symbol;

                      if (boardId === 'RG') {
                        boardId = '';
                      }

                      if (tempBoardID !== 'RG') {
                        currentSymbol = currentSymbol?.slice(0, -2);
                      }

                      setTempBoardID(e.target.value);
                      onChangeSymbol(`${currentSymbol}${boardId}`);
                    }}
                  />
                </div>

                <div className="flex-auto">
                  <InputStock
                    showRemarkInput={false}
                    size="small"
                    color="gold"
                    placeholder="Simbol"
                    showPriceStock={false}
                    label={false}
                    value={tempSymbol}
                    onChange={(stock) => {
                      let newSymbol = stock?.code;

                      if (tempBoardID !== 'RG') {
                        newSymbol = `${newSymbol}${tempBoardID}`;
                      }

                      setTempSymbol(newSymbol);
                      onChangeSymbol(newSymbol);
                    }}
                  />
                </div>
              </div>

              <span
                className={`flex w-full items-end ${
                  type === 'LONG' && showPrice
                    ? 'justify-start gap-4'
                    : 'justify-between'
                }`}>
                <RenderIf isTrue={type !== 'LONG'}>
                  <div className="flex-none">
                    <Price symbol={symbol} showPrice={showPrice} type={type} />
                  </div>
                </RenderIf>

                {type === 'LONG' ? (
                  <Tools
                    symbol={symbol}
                    showCompare={showCompare}
                    showWatchlist={showWatchlist}
                    advancedChart={advancedChart}
                    showPriceAlert={showPriceAlert}
                    showStockDetail={showStockDetail}
                  />
                ) : (
                  <Tools
                    symbol={symbol}
                    showCompare={showCompare}
                    showWatchlist={showWatchlist}
                    advancedChart={advancedChart}
                    showPriceAlert={showPriceAlert}
                    showStockDetail={showStockDetail}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
      </RenderIf>

      <RenderIf isTrue={showPrice}>
        <div className={`${type === 'LONG' && 'col-span-6'}`}>
          {type === 'LONG' ? (
            <Price symbol={symbol} showPrice={showPrice} type={type} />
          ) : (
            <></>
          )}
        </div>
      </RenderIf>
    </div>
  );
}

TVHeader.propTypes = {
  symbol: PropTypes.string,
  className: PropTypes.string,
  advancedChart: PropTypes.bool,
  showStockDetail: PropTypes.bool,
  showPriceAlert: PropTypes.bool,
  showCompare: PropTypes.bool,
  showWatchlist: PropTypes.bool,
  onChangeSymbol: PropTypes.func,
  type: PropTypes.string,
  showPrice: PropTypes.bool,
  showPriceOnly: PropTypes.bool
};

export default TVHeader;
