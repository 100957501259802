import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { ModalProvider } from './components/modal/context/modalContext';

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <CookiesProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ModalProvider>
                <App />
              </ModalProvider>
            </PersistGate>
          </Provider>
        </CookiesProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  navigator?.serviceWorker
    .register('../firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration?.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}
