/* eslint-disable no-unused-vars */
import {  useEffect, useState } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import TermStockItem from './term-stock-item';
import RenderIf from 'components/basic/render-if';
import { find, isEmpty, isEqual, orderBy, sumBy } from 'lodash';
import { currency } from 'utils/format';
import { useCreatePortfolioTermsMutation, useGetPortfolioTermsQuery, useUpdatePortfolioTermsMutation } from 'services/rtk-query/workspace';
import IconEdit from 'components/svg/icon-edit';
import PortfolioTermsForm from 'components/form/portfolio-terms-form';
import { usePortfolio } from 'hooks/usePortfolio';
import { triggerSegmentEvent } from 'lib/segment';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function GridTermStock() {
  const auth = useBrowserTabAuth();
  const { portfolioList, isLoading: loadingPortfolio } = usePortfolio();
  const { data: portfolioTerms, isFetching: loadingPortfolioTerms } = useGetPortfolioTermsQuery(auth.user.id, { refetchOnMountOrArgChange : true });
  const [createPortfolioTerms] = useCreatePortfolioTermsMutation()
  const [updatePortfolioTerms] = useUpdatePortfolioTermsMutation();

  const [columns, setColumns] = useState({});
  const [firstRender, setFirsRender] = useState(true);
  const [sortingColumns, setSortingColumns] = useState([]);
  const [sortingOrders, setSortingOrders] = useState([]);
  const [toggleActive, setToggleActive] = useState(false);
  const [openPortfolioForm, setOpenPortfolioForm] = useState(false);

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      let _newColumns = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      }

      setColumns(_newColumns);
      await updatePortfolioTerms({ payload: _newColumns, id: portfolioTerms?.id }).unwrap();
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      let _newColumns = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      }

      setColumns(_newColumns);
      await updatePortfolioTerms({ payload: _newColumns, id: portfolioTerms?.id }).unwrap();
    }

    triggerSegmentEvent({ event: 'TR Stock Moved'});
  };

  const handleSorting = async (column, obj) => {
    let tSortingColumns = sortingColumns;
    let tSortingOrders = sortingOrders;

    if (!tSortingColumns.includes(obj)) {
      tSortingColumns[0] = obj;
      tSortingOrders[0] = tSortingOrders?.[0] === 'asc' ? 'desc' : 'asc';
    } else if (tSortingColumns.includes(obj)) {
      tSortingOrders[0] = tSortingOrders[0] === 'asc' ? 'desc' : 'asc';
    }

    setSortingColumns(tSortingColumns);
    setSortingOrders(tSortingOrders);

    let _newColumns = {
      ...columns,
      [column.name]: {
        ...columns[column.name],
        items: orderBy(column?.items, obj, tSortingOrders)
      }
    };

    setColumns(_newColumns);
    await updatePortfolioTerms({ payload: _newColumns, id: portfolioTerms?.id }).unwrap();
  }

  useEffect(() => {
    const fetchData = async () => {
      let _portfolioTerms = {};
      Object?.entries(portfolioTerms?.payload || []).map((obj) => {
        _portfolioTerms[obj[0]] = { ...obj[1] }
        let _items = [];

        _portfolioTerms[obj[0]]?.items?.map((por) => {
          let findPor = find(portfolioList, ['code', por.code]);
          if (findPor) _items.push(findPor);
        })
        _portfolioTerms[obj[0]].items = _items;
      });

      let _list = [];
      portfolioList.map((item) => {
        Object?.entries(portfolioTerms?.payload || []).map((obj) => {
          if (find(_portfolioTerms[obj[0]]?.items || [], ['code', item.code])) {
            _list.push(item?.code);
          }
        });

        Object.keys(_portfolioTerms).map((obj, key) => {
          if (key === 0) {
            if (!_list?.includes(item?.code)) _portfolioTerms[obj]?.items?.push(item);
          }
        })
      })

      if (!portfolioTerms?.id) {
        await createPortfolioTerms({ payload: _portfolioTerms, userId: auth?.user?.id }).unwrap();
      } else {
        if (!isEqual(_portfolioTerms, portfolioTerms?.payload)) {
          await updatePortfolioTerms({ payload: _portfolioTerms, id: portfolioTerms?.id }).unwrap();
        }
      }
      setColumns(_portfolioTerms);
    }

    const clearData = async (defaultTerms) => {
      await updatePortfolioTerms({ payload: defaultTerms, id: portfolioTerms?.id }).unwrap();
    }

    if (!isEmpty(portfolioTerms?.payload) && firstRender && portfolioList?.length > 0 && !loadingPortfolioTerms && !loadingPortfolio) {
      fetchData(); 
      setFirsRender(false);
    }

    if (portfolioList?.length === 0 && portfolioTerms?.payload && !loadingPortfolioTerms && !loadingPortfolio) {
      let _defaultTerms = {};
      Object.keys(portfolioTerms?.payload)?.map((_obj) => {
        _defaultTerms[_obj] = {
          items: [],
          name: portfolioTerms?.payload?.[_obj]?.name,
          title: portfolioTerms?.payload?.[_obj]?.title,
        }
      })

      clearData(_defaultTerms);
    } else if (!loadingPortfolioTerms && !isEmpty(portfolioTerms?.payload) && !portfolioList && !loadingPortfolio) {
      let _defaultTerms = {};
      Object.keys(portfolioTerms?.payload)?.map((_obj) => {
        _defaultTerms[_obj] = {
          items: [],
          name: portfolioTerms?.payload?.[_obj]?.name,
          title: portfolioTerms?.payload?.[_obj]?.title,
        }
      })

      setColumns(_defaultTerms);
    }
  }, [auth, portfolioTerms, createPortfolioTerms, updatePortfolioTerms, firstRender, portfolioList, loadingPortfolioTerms, loadingPortfolio])

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
        <div className="flex w-full">
          <div
            className="grid grid-cols-1 lg:grid-cols-3 gap-3 w-full"
            style={{ minHeight: '250px' }}>
            {Object.entries(columns).map(([columnId, column], index) => {
              let totalInvested = sumBy(column?.items, (i) => i.marketValue);

              return (
                <Droppable key={columnId} droppableId={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div className="flex">
                        <div
                          className="card-widget-gradient rounded-xl flex flex-col w-full p-4"
                          ref={provided.innerRef}
                          {...provided.droppableProps}>
                          <span className="font-quicksand-semibold text-sm flex justify-between mb-3 items-center">

                            <button onClick={() => setOpenPortfolioForm(column)} className='group appearance-none outline-none flex items-center hover:text-main-base-l transition-all ease-in-out duration-150 font-quicksand-semibold text-base'>
                              <span>{column.title}</span>
                              <IconEdit className='h-4 w-4 ml-2 opacity-0 group-hover:opacity-100' />
                            </button>

                            <div className="text-right">
                              <h5 className="font-quicksand-semibold text-lg">
                                {currency(totalInvested || 0, {
                                  removeSymbol: true
                                })}
                              </h5>
                            </div>
                          </span>
                          <div>
                            <div className="flex flex-row justify-between items-center text-2xs text-gray-light-0-d font-quicksand-semibold">
                              <div className='py-3 p-2 border-b border-gray-base-l dark:border-gray-base-d' style={{ width: '20%' }}>#</div>
                              <div onClick={() => handleSorting(column, 'code')} className='py-3 p-2 border-b border-gray-base-l dark:border-gray-base-d' style={{ width: '100%' }}>Stock</div>
                              <div onClick={() => handleSorting(column, 'stockValue')} className='py-3 p-2 border-b border-gray-base-l dark:border-gray-base-d' style={{ width: '100%' }}>Invested</div>
                              <div onClick={() => handleSorting(column, 'marketValue')} className='py-3 p-2 border-b border-gray-base-l dark:border-gray-base-d' style={{ width: '100%' }}>Mkt.Value</div>
                              <div onClick={() => handleSorting(column, 'totalGain')} className='py-3 p-2 border-b border-gray-base-l dark:border-gray-base-d' style={{ width: '100%' }}>POTG/L</div>
                            </div>

                            <RenderIf isTrue={column?.items?.length === 0}>
                              <div className="flex flex-row justify-between items-center text-2xs text-gray-light-0-d">
                                <div className='py-3 p-2' style={{ width: '100%' }}>{portfolioList?.length > 0 ? 'Kamu belum memindahkan stock pilihanmu kesini' : 'Belum ada saham'}</div>
                              </div>
                            </RenderIf>

                            {column?.items.map((item, index) => (
                              <div key={item.id} className={`${toggleActive && 'animate-blink'}`} onAnimationEnd={() => setToggleActive(false)}>
                                <TermStockItem
                                  key={item.id}
                                  item={item}
                                  index={index}
                                  last={index === column?.items?.length - 1}
                                />
                              </div>
                            ))}
                            {provided.placeholder}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Droppable>
              );
            })}
          </div>
        </div>
      </DragDropContext>

      <RenderIf isTrue={openPortfolioForm}>
        <Modal onClose={() => setOpenPortfolioForm(false)}>
          <PortfolioTermsForm
            tempValue={openPortfolioForm}
            columns={columns}
            id={portfolioTerms?.id}
            onClose={() => setOpenPortfolioForm(false)}
            onSuccess={(_columns) => {
              setColumns(_columns);
              setOpenPortfolioForm(false);
            }}
          />
        </Modal>
      </RenderIf>
    </>
  );
}

export default GridTermStock;
