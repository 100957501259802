import Card from 'components/basic/card';
import { Helmet } from 'react-helmet-async';

function SectionSyaratDanKetentuan() {
  return (
    <Card className="p-6">
      <Helmet>
        <title>Syarat & Ketentuan | Pina Trade</title>
      </Helmet>

      <h5 className="mb-5 text-lg">Syarat dan Ketentuan</h5>

      <ul className="list-decimal ml-4 text-sm">
        <li className="mb-3">
          PT Trust Sekuritas akan memberikan User ID dan password kepada Nasabah
          untuk dapat melakukan akses ke dalam Fasilitas Online.
        </li>
        <li className="mb-3">
          Sebelum menggunakan Fasilitas Online, Nasabah berkewajiban untuk
          membaca ketentuan dan tatacara penggunaan yang terdapat dalam Manual
          Book. Manual Book ini dapat diunduh langsung di website
          www.trust.co.id setelah nasabah memasukkan User ID dan password yang
          diberikan oleh PT Trust Sekuritas.
        </li>
        <li className="mb-3">
          Nasabah disarankan untuk mengganti password secara berkala demi
          keamanan. Password yang tersimpan di data kami telah dienkripsi sesuai
          standar keamanan.
        </li>
        <li className="mb-3">
          Nasabah berkewajiban untuk tidak memberikan User ID, Password dan PIN
          kepada pihak lain serta bertanggung jawab secara penuh atas penggunaan
          dan keamanannya.
        </li>
        <li className="mb-3">
          Nasabah bersedia menerima segala kerugian yang mungkin timbul sebagai
          akibat dari kejadian &ldquo;Force Majeure&rdquo; (yaitu kerugian
          secara langsung atau tidak langsung dari suatu keadaan memaksa yang
          terjadi diluar kemampuan kedua Pihak diantaranya larangan pemerintah,
          ketetapan Regulator (Bapepam-LK) dan SRO (BEI, KPEI dan KSEI), perang,
          keadaan serupa perang, blokade, embargo, pemogokan, epidemi,
          karantina, bencana, nasionalisasi, pembatasan mata uang, kebakaran,
          tindakan penguasa, persyaratan dan/atau perubahan suatu Undang-Undang
          atau Peraturan, huru-hara termasuk terorisme dan kejadian tidak
          terduga) kerusakan perangkat lunak, gangguan jaringan dan sistem
          perdagangan, kesalahan dan/atau kegagalan bertransaksi serta
          penyalahgunaan PIN beserta password oleh pihak lain.
        </li>
        <li className="mb-3">
          Selain atas permintaan tertulis dari nasabah untuk menutup Fasilitas
          Online, maka PT Trust Sekuritas pun berhak untuk melakukan pemblokiran
          sementara dan/atau penutupan akses Fasilitas Online tanpa
          pemberitahuan terlebih dahulu. Hal ini akan dilakukan terkait adanya
          indikasi/bukti kuat yang mengarah pada terjadinya penyalahgunaan
          termasuk hacking (pembajakan), pemasukan virus atau sejenisnya,
          perusakan, penggunaan tidak wajar, melakukan kegiatan transaksi yang
          melanggar Undang-Undang Pasar Modal atau bertentangan dengan hukum
          yang berlaku, namun tidak terbatas pada pemeliharaan dan perbaikan
          sistem, pelanggaran terhadap ketentuan yang ada termasuk didalamnya
          penggunaan password dan/atau PIN oleh pihak yang tidak berhak dan
          tidak bertanggung jawab.
        </li>
        <li className="mb-3">
          Segala hal yang terkait dengan pelanggaran ketentuan terutama yang
          bersinggungan dengan ranah hukum maka PT Trust Sekuritas akan
          menindaklanjuti dengan memproses ke pihak yang berwenang.
        </li>
        <li className="mb-3">
          Nasabah akan membebaskan PT Trust Sekuritas dari segala tuntutan dan
          tanggung jawab atas kerugian yang mungkin terjadi karena kegagalan
          koneksi, kegagalan perangkat keras dan perangkat lunak yang digunakan
          dalam mengakses fasilitas Online, termasuk akses fasilitas Online oleh
          pihak lain akibat kelalaian nasabah.
        </li>
        <li className="mb-3">
          Pemohon dengan ini menyatakan bahwa :
          <ul className="list-decimal ml-4">
            <li className="mb-3">
              Seluruh data dan informasi yang dicantumkan dalam Formulir
              Aplikasi Pembukaan Rekening Investor adalah benar, lengkap, dan
              sah, dan Bank diberi hak untuk memeriksa dan melakukan verifikasi
              atas kebenaran, kelengkapan, dan keabsahan dari setiap data dan
              informasi dalam Formulir Aplikasi Pembukaan Rekening Investor ini
            </li>
            <li className="mb-3">
              Pemohon telah diberikan informasi yang cukup dan memadai perihal
              karakteristik produk Rekening Investor yang akan dimanfaatkan oleh
              Pemohon, dan Pemohon mengerti serta memahami segala konsekuensi
              pemanfaatan produk Rekening Investor, termasuk manfaat, risiko,
              dan biaya-biaya (bila ada) yang melekat pada produk Rekening
              Investor
            </li>
            <li className="mb-3">
              Pemohon telah menerima, membaca, mengerti dan menyetujui isi
              Ketentuan Umum dan Persyaratan Pembukaan Rekening Investor
              sebagaimana terlampir dari Formulir Aplikasi Pembukaan Rekening
              Investor dan dengan ini Pemohon menyatakan tunduk dan terikat
              dengan ketentuan-ketentuan tersebut, serta ketentuan lain terkait
              produk/fasilitas Rekening Investor yang berlaku di Bank.
            </li>
          </ul>
        </li>
        <li className="mb-3">
          Pemohon dengan ini memberi kuasa kepada pihak-pihak di bawah ini untuk
          melakukan tindakan-tindakan sebagai berikut :
          <ul className="list-decimal ml-4">
            <li className="mb-3">
              Memberikan kuasa kepada Bank untuk melakukan pemblokiran dan atau
              penutupan rekening, apabila menurut pertimbangan Bank :
              <ul className="list-decimal ml-4">
                <li className="mb-3">
                  Pemohon tidak mematuhi ketentuan Prinsip Mengenal Nasabah
                  (Knowing Your Customer);
                </li>
                <li className="mb-3">
                  Data yang Pemohon berikan kepada Bank tidak benar atau
                  diragukan kebenarannya;
                </li>
                <li className="mb-3">Pemohon menyalahgunakan rekening.</li>
              </ul>
            </li>
            <li className="mb-3">
              Memberikan kuasa kepada PT Trust Sekuritas untuk melakukan
              tindakan-tindakan sebagai berikut :
              <ul className="list-decimal ml-4">
                <li className="mb-3">
                  Memperoleh informasi tentang saldo dan mutasi rekening yang
                  terdapat pada Rekening Investor sehubungan dengan kegiatan
                  penyelesaian transaksi efek Pemohon pada PT Trust Sekuritas
                  melalui sarana perbankan yang disediakan Bank.
                </li>
                <li className="mb-3">
                  Melakukan segala tindakan-tindakan berkenaan dengan
                  pengoperasian Rekening Investor, termasuk memberikan instruksi
                  pemindahbukuan atau pendebitan seluruh dana atau sejumlah dana
                  tertentu yang terdapat dalam Rekening Investor, untuk
                  penyelesaian pembayaran dalam rangka penyelesaian transaksi
                  efek Pemohon.
                </li>
              </ul>
            </li>
            <li className="mb-3">
              Memberikan kuasa kepada Bank untuk memberikan akses kepada PT.
              Kustodian Sentral Efek Indonesia (KSEI) melalui sarana elektronik
              perihal informasi mengenai saldo dan mutasi rekening yang terdapat
              pada Rekening Investor, sehubungan dengan kegiatan penyelesaian
              transaksi efek Investor melalui KSEI.
            </li>
            <li className="mb-3">
              Memberikan kuasa kepada Bank untuk melakukan penutupan rekening
              apabila Rekening Investor tidak aktif dan bersaldo nihil selama
              enam (6) bulan berturut-turut serta berdasarkan data yang ada di
              PT Trust Sekuritas bahwa Pemohon/Nasabah tidak melakukan transaksi
              efek di PT Trust Sekuritas.
            </li>
          </ul>
        </li>
        <li className="mb-3">
          Pemberian akses dan informasi mengenai saldo dan mutasi rekening yang
          terdapat pada Rekening Investor sebagaimana dimaksud angka 10 huruf b,
          c dan d di atas bukanlah merupakan pelanggaran atas ketentuan Rahasia
          Bank sebagaimana diatur dalam Undang-Undang Nomor 7 tahun 1992 tentang
          Perbankan sebagaimana telah diubah dengan Undang-Undang Nomor 10 tahun
          1998, berikut segenap peraturan pelaksanaannya dan
          perubahan-perubahannya.
        </li>
        <li className="mb-3">
          Pemohon menjamin serta membebaskan Bank dari segala kewajiban,
          tuntutan, gugatan dan klaim apapun serta dari pihak manapun, termasuk
          Pemohon sendiri, serta dari segala kerugian dan resiko yang mungkin
          timbul di kemudian hari sehubungan dengan pelaksanaan tindakantindakan
          sebagaimana dimaksud dalam pernyataan dan kuasa Pemohon ini
        </li>
        <li className="mb-3">
          Pernyataan dan kuasa ini tidak dapat ditarik atau dicabut kembali, dan
          oleh karenanya tidak akan berakhir karena sebab apapun termasuk
          sebabsebab sebagaimana dimaksud Pasal 1813, 1814 dan 1816 Kitab
          UndangUndang Hukum Perdata.
        </li>
        <li className="mb-3">
          Pernyataan dan Kuasa ini berlaku sejak tanggal terkirimnya oleh Bank
          dan akan berakhir sejak Bank menerima pencabutan Pernyataan dan Kuasa
          oleh Pemohon melalui PT Trust Sekuritas dengan ketentuan bahwa surat
          pencabutan Pernyataan dan Kuasa tersebut terkirim oleh Bank
          selambatlambatnya 7 (tujuh) hari kerja sebelum pencabutan tersebut
          berlaku efektif dengan ketentuan pencabutan Pernyataan dan Kuasa hanya
          berlaku apabila seluruh kewajiban Pemohon kepada Bank telah
          terselesaikan
        </li>
      </ul>
      <br />

      <p className="text-sm">
        Dengan terkirimnya Formulir Registrasi ini maka Nasabah telah membaca,
        memahami, menerima dan bersedia tunduk kepada seluruh ketentuan yang
        terkait dengan penggunaan fasilitas Online.
      </p>
    </Card>
  );
}

export default SectionSyaratDanKetentuan;
