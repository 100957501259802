import PropTypes from 'prop-types';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputText from 'components/input/input-text';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';

import ListStatus from 'components/basic/list-status';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import Card from 'components/basic/card';
import { ModalContext } from 'components/modal/context/modalContext';
import ModalDataAman from '../../../../../components/modal/template/modal-data-aman';
import { NPWPValidation } from './npwp.validation';
import InputDatePickerSimple from 'components/input/input-date-picker-simple';
import { triggerSegmentEvent } from 'lib/segment';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import HeaderOnboarding from '../../header-onboarding';
import RenderIf from 'components/basic/render-if';
import IconImageRemove from 'components/svg/icon-image-remove';
import IconImage from 'components/svg/icon-image';
import InputCamera from 'components/input/input-camera';
import useToastr from 'hooks/useToastr';
import IconCamera from 'components/svg/icon-camera';
import { useCreateUploadNPWPMutation } from 'services/rtk-query/upload';
import { invalidateUserProfile } from 'services/rtk-query/user';
import cn from 'utils/classname';

function RDNStep03NPWP({ onSuccess = () => {}, onSkip = () => {} }) {
  const { getErrorMessage } = useGetErrorMessage();
  const toastr = useToastr();

  const { handleModal } = useContext(ModalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createUploadNPWP] = useCreateUploadNPWPMutation();
  const [isValidNPWP, setIsValidNPWP] = useState(false);
  const [isRequestUploadNPWP, setIsRequestUploadNPWP] = useState(false);
  const [npwpImage, setNPWPImage] = useState(null);
  const [npwpURL, setNPWPImageURL] = useState(null);
  const [npwpUploadedURL, setNPWPUploadedImageURL] = useState(null);
  const [step, setStep] = useState(1);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const formik = useFormik({
    initialValues: {
      npwpNo: '',
      npwpRegistrationDate: ''
    },
    validateOnMount: false,
    validationSchema: NPWPValidation,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        if (step === 1) {
          handleNPWPUpload();
        } else {
          onSuccess(values);
          triggerSegmentEvent({
            event: 'TR NPWP Data Inputted PINA Trade'
          });
        }
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const handleNPWPUpload = async () => {
    if (npwpImage) {
      setLoadingUpload(true);
      setIsRequestUploadNPWP(false);
      try {
        const formData = new FormData();
        formData.append('file', npwpImage);

        const { data } = await createUploadNPWP(formData);
        if (data?.data?.error) throw new Error(data?.data?.error);
        setIsRequestUploadNPWP(true);
        setNPWPUploadedImageURL(data?.fileUrl);
        setIsValidNPWP(true);
        setStep(2);
        invalidateUserProfile();

        formik.setValues({ ...formik.values, npwpNo: data?.data?.npwp?.replace(/\D/g,'') });
        formik.setFieldTouched('npwpNo', true);

        triggerSegmentEvent({
          event: 'TR NPWP Inputted PINA Trade'
        });
      } catch (error) {
        getErrorMessage(error);
        setIsValidNPWP(false);
      } finally {
        setLoadingUpload(false);
      }
    }
  };

  const handleRequestCamera = async () => {
    triggerSegmentEvent({
      event: 'TR NPWP Uploaded via Camera'
    });

    navigator.permissions.query({ name: 'camera' }).then((res) => {
      if (res.state == 'granted') {
        handleModal(
          <InputCamera
            frameCameraDesktop={ImageFrameNPWP}
            frameCameraMobile={ImageFrameNPWPMobile}
            onSaveImage={async (base64) => {
              const base64Response = await fetch(base64);
              const blob = await base64Response.blob();
              const file = new File([blob], `${username}.png`, {
                type: 'image/png'
              });

              setNPWPImageURL(base64);
              setNPWPImage(file);
            }}
            frameCamera={ImageFrameNPWP}
            header={
              <>
                <h5 className="text-center text-main-gold-d font-quicksand-regular text-xl lg:text-[28px] mb-4">
                  Ambil NPWP Diri
                </h5>

                <p className="text-sm text-center mb-6">
                  Posisikan kamera anda selevel mata kamu dan pastikan muka{' '}
                  <br className="hidden lg:block" />
                  berada di dalam frame yang telah disediakan.
                </p>
              </>
            }
          />
        );
      } else {
        toastr.warn('Please Activate Your Camera Permission!');
      }
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:py-16 text-white relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto">
          <HeaderOnboarding activeStep={4} />

          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] my-3 text-white">
            NPWP (opsional)
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Dokumen yang kamu unggah tersimpan dan terlindungi dengan aman.
          </p>

          <button
            type="button"
            onClick={() => handleModal(<ModalDataAman />)}
            className="text-sm lg:text-base px-3 py-1 border rounded-3xl border-white mb-6">
            Apakah data saya aman?
          </button>

          <ListStatus
            data={[
              {
                title: 'NPWP dengan KTP',
                subtitle: null,
                status: true
              },
              {
                title: 'Nomor NPWP (opsional)',
                subtitle: null,
                status: false
              },
              {
                title: 'Informasi tambahan',
                subtitle: null,
                status: false
              }
            ]}
          />
        </div>
      </div>

      <div className="p-0 lg:px-28 lg:py-10">
        <Card className="lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto py-6 lg:pt-4 relative max-h-[calc(90vh)] overflow-auto mb-24 lg:mb-0">
          <form
            className="px-6 lg:px-8 lg:min-h-[600px]"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            noValidate>
            <RenderIf isTrue={step === 1}>
              <h5 className="text-2xl lg:text-3xl font-quicksand-regular text-main-gold-d mb-6">
                Upload atau Foto NPWP
              </h5>

              <RenderIf isTrue={npwpImage}>
                <div className="mb-36">
                  <div className="border-2 border-main-gold-d rounded-xl flex justify-center aspect-[323/204] items-center overflow-hidden relative">
                    <img
                      className="w-full h-full object-cover object-left-top"
                      src={npwpURL}
                      alt="NPWP"
                    />
                    <div
                      onClick={() => {
                        setNPWPImage(null);
                        setNPWPImageURL(null);
                        setIsRequestUploadNPWP(false);
                        setIsValidNPWP(false);
                      }}
                      className="absolute h-full w-full top-0 left-0 bg-black-base-d bg-opacity-0 hover:bg-opacity-70 opacity-0 hover:opacity-100 transition-all ease-in-out duration-150 cursor-pointer flex justify-center items-center">
                      <div className="text-center">
                        <button
                          type="button"
                          className="bg-main-gold-d flex items-center justify-center rounded-full p-2 mx-auto">
                          <IconImageRemove className="h-6 w-6 lg:h-8 lg:w-8" />
                        </button>
                        <span className="text-main-gold-d text-sm font-quicksand-semibold">
                          Hapus Foto
                        </span>
                      </div>
                    </div>
                  </div>

                  <RenderIf isTrue={!isValidNPWP && isRequestUploadNPWP}>
                    <div className="text-red-base-l mt-4 text-xs">
                      <p className="mb-1">
                        Foto KTP tidak terlihat jelas, pastikan:
                      </p>
                      <ul className="list-disc pl-4">
                        <li>Foto dalam keadaan tajam dan jelas</li>
                        <li>
                          Semua tulisan dalam KTP kamu dapat terlihat jelas
                        </li>
                        <li>Foto KTP didalam kotak kuning</li>
                      </ul>
                    </div>
                  </RenderIf>
                </div>
              </RenderIf>

              <RenderIf isTrue={!npwpImage}>
                <div className="border-2 p-10 border-main-gold-d rounded-xl flex justify-center mb-36 aspect-[323/204] items-center">
                  <div className="hover:opacity-75">
                    <label
                      htmlFor="npwpImageUpload"
                      className="h-10 w-10 lg:h-14 lg:w-14 bg-main-gold-d flex items-center justify-center rounded-full mx-10 cursor-pointer">
                      <IconImage className="text-white h-6 w-6 lg:h-8 lg:w-8" />
                      <input
                        className="opacity-0 h-0 w-0 absolute"
                        id="npwpImageUpload"
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        name="npwpImageUpload"
                        onChange={(e) => {
                          const file = e?.target?.files?.[0];
                          setNPWPImage(file);

                          const reader = new FileReader();
                          reader.onload = function (e) {
                            setNPWPImageURL(e.target.result);
                            triggerSegmentEvent({
                              event: 'TR NPWP Uploaded'
                            });
                          };

                          reader.readAsDataURL(file);
                        }}
                      />
                    </label>
                    <p className="text-center text-sm lg:text-base mt-3 text-main-gold-d">
                      Upload NPWP
                    </p>
                  </div>

                  <div className="hover:opacity-75">
                    <button
                      type="button"
                      onClick={handleRequestCamera}
                      className="h-10 w-10 lg:h-14 lg:w-14 bg-main-gold-d flex items-center justify-center rounded-full mx-10 cursor-pointer">
                      <IconCamera className="text-white h-6 w-6 lg:h-8 lg:w-8" />
                    </button>
                    <p className="text-center text-sm lg:text-base mt-3 text-main-gold-d">
                      Foto NPWP
                    </p>
                  </div>
                </div>
              </RenderIf>
            </RenderIf>

            <RenderIf isTrue={step === 2}>
              <h5 className="mb-4 text-main-gold-d font-quicksand-regular text-2xl">
                Nomor NPWP Kamu
              </h5>

              <InputText
                value={formik?.values?.npwpNo}
                name="npwpNo"
                placeholder="Nomor NPWP Kamu"
                label="Nomor NPWP"
                className="mb-4"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.npwpNo && formik.errors.npwpNo
                    ? formik.errors.npwpNo
                    : ''
                }
              />

              <InputDatePickerSimple
                value={formik.values.npwpRegistrationDate}
                currentDate={null}
                name="npwpRegistrationDate"
                filterDate={() => true}
                format="dd-MM-yyyy"
                label="Tanggal Registrasi NPWP"
                placeholder="Tanggal Registrasi NPWP Kamu"
                onChange={(_date) => {
                  formik.setFieldValue('npwpRegistrationDate', _date);
                }}
                error={
                  formik.touched.npwpRegistrationDate &&
                  formik.errors.npwpRegistrationDate
                    ? formik.errors.npwpRegistrationDate
                    : ''
                }
              />
            </RenderIf>

            <div className={cn('mt-10 fixed lg:relative w-full bottom-0 left-0 p-6 lg:p-0 bg-white border-t lg:border-none lg:mt-20', step === 2 && 'lg:mt-72')}>
              <div className="-mx-2 lg:mx-0 flex lg:block justify-center">
                <button
                  type="submit"
                  disabled={
                    (step === 2 &&
                      (!formik.isValid || !formik.values.npwpNo)) ||
                    (step === 1 && !npwpImage)
                  }
                  className={`hover:bg-opacity-75 transition-all bg-main-gold-d text-white ease-in-out duration-150 order-2 flex px-10 items-center justify-center mb-3 font-quicksand-semibold font-bold text-xs h-11 mx-2 lg:mx-0 w-full rounded-[10px] overflow-hidden focus:outline-none disabled:bg-gray-base-l disabled:text-gray-dark-d disabled:opacity-80 disabled:cursor-not-allowed`}>
                  {(isSubmitting || loadingUpload) && <SimpleSpinner />}
                  Selanjutnya
                </button>

                <RenderIf isTrue={step === 1}>
                  <button
                    type="button"
                    onClick={() => onSkip()}
                    className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 order-1 flex px-10 text-main-gold-d items-center justify-center font-quicksand-semibold font-bold text-xs h-11 mx-2 lg:mx-0 w-full rounded-[10px] overflow-hidden focus:outline-none`}>
                    Nanti Saja
                  </button>
                </RenderIf>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

RDNStep03NPWP.propTypes = {
  onClickBack: PropTypes.func,
  onSuccess: PropTypes.func,
  onSkip: PropTypes.func
};

export default RDNStep03NPWP;
