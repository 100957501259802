import PropTypes from 'prop-types';

function IconPlusSquare({ className }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5.25C9.41421 5.25 9.75 5.58579 9.75 6V8.25H12C12.4142 8.25 12.75 8.58579 12.75 9C12.75 9.41421 12.4142 9.75 12 9.75H9.75V12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12V9.75H6C5.58579 9.75 5.25 9.41421 5.25 9C5.25 8.58579 5.58579 8.25 6 8.25H8.25V6C8.25 5.58579 8.58579 5.25 9 5.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7679 2.25H10.2321C11.0449 2.24999 11.7006 2.24999 12.2315 2.29336C12.7781 2.33803 13.2582 2.43239 13.7025 2.65873C14.4081 3.01825 14.9817 3.59193 15.3413 4.29754C15.5676 4.74175 15.662 5.2219 15.7066 5.76853C15.75 6.29944 15.75 6.95505 15.75 7.76788V10.2321C15.75 11.045 15.75 11.7006 15.7066 12.2315C15.662 12.7781 15.5676 13.2582 15.3413 13.7025C14.9817 14.4081 14.4081 14.9817 13.7025 15.3413C13.2582 15.5676 12.7781 15.662 12.2315 15.7066C11.7006 15.75 11.045 15.75 10.2321 15.75H7.76788C6.95505 15.75 6.29944 15.75 5.76853 15.7066C5.2219 15.662 4.74175 15.5676 4.29754 15.3413C3.59193 14.9817 3.01825 14.4081 2.65873 13.7025C2.43239 13.2582 2.33803 12.7781 2.29336 12.2315C2.24999 11.7006 2.24999 11.0449 2.25 10.2321V7.7679C2.24999 6.95506 2.24999 6.29944 2.29336 5.76853C2.33803 5.2219 2.43239 4.74175 2.65873 4.29754C3.01825 3.59193 3.59193 3.01825 4.29754 2.65873C4.74175 2.43239 5.2219 2.33803 5.76853 2.29336C6.29944 2.24999 6.95506 2.24999 7.7679 2.25ZM5.89068 3.78838C5.4368 3.82547 5.17604 3.8946 4.97852 3.99524C4.55516 4.21095 4.21095 4.55516 3.99524 4.97852C3.8946 5.17604 3.82547 5.4368 3.78838 5.89068C3.75058 6.35331 3.75 6.94755 3.75 7.8V10.2C3.75 11.0525 3.75058 11.6467 3.78838 12.1093C3.82547 12.5632 3.8946 12.824 3.99524 13.0215C4.21095 13.4448 4.55516 13.7891 4.97852 14.0048C5.17604 14.1054 5.4368 14.1745 5.89068 14.2116C6.35331 14.2494 6.94755 14.25 7.8 14.25H10.2C11.0525 14.25 11.6467 14.2494 12.1093 14.2116C12.5632 14.1745 12.824 14.1054 13.0215 14.0048C13.4448 13.7891 13.7891 13.4448 14.0048 13.0215C14.1054 12.824 14.1745 12.5632 14.2116 12.1093C14.2494 11.6467 14.25 11.0525 14.25 10.2V7.8C14.25 6.94755 14.2494 6.35331 14.2116 5.89068C14.1745 5.4368 14.1054 5.17604 14.0048 4.97852C13.7891 4.55516 13.4448 4.21095 13.0215 3.99524C12.824 3.8946 12.5632 3.82547 12.1093 3.78838C11.6467 3.75058 11.0525 3.75 10.2 3.75H7.8C6.94755 3.75 6.35331 3.75058 5.89068 3.78838Z"
      />
    </svg>
  );
}

IconPlusSquare.propTypes = {
  className: PropTypes.string
};

IconPlusSquare.defaultProps = {
  className: ''
};

export default IconPlusSquare;
