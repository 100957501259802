import { dayjsLocal } from 'utils/formatter/date';

const getAllFilterChart = () => {
  let startDate1D = dayjsLocal('08:59', 'HH:mm').valueOf() / 1000;
  let endDate1D = dayjsLocal().add(10, 'minute').valueOf() / 1000;
  let resolution1D = '1';

  if (dayjsLocal().day() === 6) {
    startDate1D = dayjsLocal('08:59', 'HH:mm').subtract(1, 'day').valueOf() / 1000;
    endDate1D = dayjsLocal('16:15', 'HH:mm').subtract(1, 'day').valueOf() / 1000;
  }

  if (dayjsLocal().day() === 0) {
    startDate1D = dayjsLocal('08:59', 'HH:mm').subtract(2, 'day').valueOf() / 1000;
    endDate1D = dayjsLocal('16:15', 'HH:mm').subtract(2, 'day').valueOf() / 1000;
    resolution1D = '1';
  }

  return [
    {
      index: 0,
      text: '1D',
      startDate: startDate1D,
      endDate: endDate1D,
      resolution: resolution1D
    },
    {
      index: 1,
      text: '1M',
      startDate: dayjsLocal().subtract(1, 'month').valueOf() / 1000,
      endDate: dayjsLocal().add(10, 'minute').valueOf() / 1000,
      resolution: '1D'
    },
    {
      index: 2,
      text: '3M',
      startDate: dayjsLocal().subtract(3, 'month').valueOf() / 1000,
      endDate: dayjsLocal().add(10, 'minute').valueOf() / 1000,
      resolution: '1D'
    },
    {
      index: 3,
      text: '6M',
      startDate: dayjsLocal().subtract(6, 'month').valueOf() / 1000,
      endDate: dayjsLocal().add(10, 'minute').valueOf() / 1000,
      resolution: '1D'
    },
    {
      index: 4,
      text: '1Y',
      startDate: dayjsLocal().subtract(1, 'year').valueOf() / 1000,
      endDate: dayjsLocal().add(10, 'minute').valueOf() / 1000,
      resolution: '1D'
    },
    {
      index: 5,
      text: '5Y',
      startDate: dayjsLocal().subtract(5, 'year').valueOf() / 1000,
      endDate: dayjsLocal().add(10, 'minute').valueOf() / 1000,
      resolution: '1D'
    },
    {
      index: 6,
      text: '10Y',
      startDate: dayjsLocal().subtract(10, 'year').valueOf() / 1000,
      endDate: dayjsLocal().add(10, 'minute').valueOf() / 1000,
      resolution: '1W'
    }
  ];
};

export default getAllFilterChart;
