import PropTypes from 'prop-types';

function IconBell({ className, active }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 11C17 9.5 16.5 5 12 5V19H16.5C18.5 19 19.5 16.5 18.5 15C17.5 13.5 17 12.5 17 11Z"
        fill={`${active ? '#AB9753' : 'transparent'}`}
      />
      <path
        d="M6.92187 11C6.92187 9.5 7.42969 5 12 5V19H7.42969C5.39843 19 4.38281 16.5 5.39843 15C6.41406 13.5 6.92187 12.5 6.92187 11Z"
        fill={`${active ? '#AB9753' : 'transparent'}`}
      />
      <path d="M10 20H14C14 21.1046 13.1046 22 12 22C10.8954 22 10 21.1046 10 20Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C11.4477 2 11 2.44772 11 3V4C11 4.02775 11.0011 4.05523 11.0033 4.08241C8.16401 4.55712 6.00004 7.02585 6.00004 10V11.9574C6.00004 12.3881 5.84413 12.8042 5.56112 13.1289L4.91668 13.8682C2.82986 16.2623 4.53019 20 7.70609 20H16.294C19.4699 20 21.1702 16.2623 19.0834 13.8682L18.439 13.1289C18.156 12.8042 18 12.3881 18 11.9574V10C18 7.02582 15.836 4.55707 12.9967 4.08239C12.9989 4.05522 13 4.02774 13 4V3C13 2.44772 12.5523 2 12 2ZM8.00004 10C8.00004 7.79086 9.7909 6 12 6C14.2092 6 16 7.79086 16 10V11.9574C16 12.8712 16.3308 13.7542 16.9313 14.443L17.5758 15.1824C18.5347 16.2825 17.7534 18 16.294 18H7.70609C6.24672 18 5.4654 16.2825 6.42432 15.1824L7.06876 14.443C7.66924 13.7542 8.00004 12.8712 8.00004 11.9574V10Z"
      />
    </svg>
  );
}

IconBell.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool
};

export default IconBell;
