import { useEffect, useState } from "react";
import clsx from "clsx";
import ModalPortal from "./modal-portal";
import { useKeyboardShortcut } from "hooks/useKeyboardShortcut";

export default function Modal({ children, size = 'medium', position = 'center', onClose = () => { } }) {
    const [open, setOpen] = useState(false);

    useKeyboardShortcut({
      key: "Escape",
      onKeyPressed: () => {
        onClose()
      },
    })

    const sizes = {
        small: 'max-w-[400px]',
        medium: 'max-w-2xl',
        large: 'max-w-7xl',
        full: 'max-w-screen'
    }

    const positions = {
        center: 'mx-auto transform -translate-y-1/2 top-1/2 relative',
        bottom: 'absolute bottom-0 left-0'
    }

    const rounded = {
        center: '',
        bottom: 'rounded-b-none max-h-[90vh] overflow-y-hidden'
    }

    const transitions = {
        center: open ? 'translate-y-0 opacity-100 m-4 lg:mx-0' : 'translate-y-[2rem] opacity-0 m-4 lg:mx-0',
        bottom: open ? 'translate-y-0 opacity-100' : '-translate-y-4 opacity-0'
    }

    useEffect(() => {
      setTimeout(() => setOpen(true), 50);
    }, []);

    useEffect(() => {
      return () => {
        const body = document.getElementsByTagName('body');
        const countModal = document.getElementById('modal-root-new');

        setTimeout(() => {
          if (countModal?.childNodes?.length > 0) {
            body?.[0]?.classList.add('overflow-hidden');
          } else {
            body?.[0]?.classList.remove('overflow-hidden');
          }
        }, 200);
      };
    }, []);

    return (
        <ModalPortal>
            <div className="fixed w-screen h-screen z-50 top-0 left-0 bg-[#000000d4]">
                <div onClick={() => onClose()} className="absolute left-0 top-0 w-full h-full"></div>
                <div className="w-full h-full overflow-auto py-5">
                  <div className={clsx(positions[position], sizes[size], 'max-h-screen')}>
                      <div className={clsx('relative rounded-xl transform transition-all ease-linear duration-75', rounded[position], transitions[position])}>
                          {children}
                      </div>
                  </div>
                </div>
            </div>
        </ModalPortal>
    )
}