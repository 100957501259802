import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { currency } from 'utils/format';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IconSorting from 'components/svg/icon-sorting';
import { isEqual, orderBy } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import ImageEmptyState from 'assets/images/mascot/mascot-empty-orderlist.png';
import { usePortfolio } from 'hooks/usePortfolio';
import { useTableSorting } from 'hooks/useTableSorting';
import { getColorStatus } from 'utils/stock/score';
import ActionButton from 'components/basic/action-button';
import useToastr from 'hooks/useToastr';
import {
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistQuery
} from 'services/rtk-query/watchlist';
import IconStarDelete from 'components/svg/icon-star-delete';
import IconStar from 'components/svg/icon-star';
import IconSell from 'components/svg/icon-sell';
import IconSearchPage from 'components/svg/icon-search-page';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import { useCompareStock } from 'hooks/useCompareStock';
import { triggerSegmentEvent } from 'lib/segment';
import IconBuy from 'components/svg/icon-buy';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import BlinkText from 'components/basic/text-blink';
import useCustomNavigate from 'hooks/useCustomNavigate';

function TWPortfolio({ title = 'Portfolio List', isZoomOut = false }) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { navigate, customNavigate } = useCustomNavigate();
  const { data: watchlist } = useGetWatchlistQuery();

  const [deleteWatchlist] = useDeleteWatchlistMutation();
  const [createWatchlist] = useCreateWatchlistMutation();
  const { addToCompareStock } = useCompareStock();

  const {
    totalInvested,
    totalMktValue,
    totalPotGain,
    totalPotGainPercent,
    portfolio,
    isLoading,
    portfolioList
  } = usePortfolio();
  const { data, sortingColumns, sortingOrders, handleSorting, getSorting } =
    useTableSorting(portfolioList, ['totalGain'], ['desc']);

  const [tempData, setTempData] = useState(null);

  useEffect(() => {
    if (!isEqual(orderBy(tempData, sortingColumns, sortingOrders), data)) {
      setTempData(data);
    }
  }, [tempData, data, sortingOrders, sortingColumns]);

  return (
    <div className="p-1 pt-0">
      <RenderIf isTrue={title}>
        <h5 className="mb-3 text-sm dark:text-black-light-1-d">{title}</h5>
      </RenderIf>

      <RenderIf
        isTrue={(portfolio?.stock?.items?.length || 0) === 0 && !isLoading}>
        <div className="text-center p-4 pt-10 lg:p-10 dark:text-black-light-0-d">
          <img
            src={ImageEmptyState}
            alt="empty"
            className="h-14 w-14 mx-auto mb-4"
          />
          <h2 className="text-base lg:text-2xl mb-1 font-quicksand-semibold">
            Belum ada saham
          </h2>
          <p className="text-xs lg:text-sm text-gray-light-0-d max-w-lg mx-auto">
            Mulai tambahkan saham ke portofolio kamu lalu monitor performa
            saham, lihat perubahan nilai, dan analisis kinerja investasimu.
          </p>

          <Link
            title="Lihat Stock Market"
            to="/stock-market"
            className="bg-main-base-l text-white dark:text-black-base-d hover:bg-opacity-75 py-2.5 transition-all ease-linear duration-200 w-72 max-w-full mx-auto block text-sm px-4 rounded-lg mt-4 font-quicksand-semibold">
            Lihat Stock Market
          </Link>
        </div>
      </RenderIf>

      <RenderIf
        isTrue={
          ((portfolio?.stock?.items?.length || 0) > 0 && !isLoading) ||
          isLoading
        }>
        <OverlayScrollbarsComponent
          className="overlayscrollbars-host-small pr-3 pb-3"
          element="span"
          options={{
            resize: 'horizontal',
            scrollbars: {
              autoHide: 'leave',
              autoHideDelay: 50
            }
          }}>
          <table className="text-2xs w-full text-center font-quicksand-semibold">
            <thead className="text-gray-light-0-d">
              <tr>
                <td className="p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d">
                  <span className="flex items-center gap-1 justify-end">
                    No
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'code'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('code')}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    Stock{' '}
                    {sortingColumns?.[0] === 'code' && (
                      <IconSorting
                        type={getSorting('code')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'net'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('balance')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Balance{' '}
                    {sortingColumns?.[0] === 'net' && (
                      <IconSorting
                        type={getSorting('balance')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'net'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('net')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Lot{' '}
                    {sortingColumns?.[0] === 'net' && (
                      <IconSorting
                        type={getSorting('net')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'avgPrice'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('avgPrice')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Avg{' '}
                    {sortingColumns?.[0] === 'avgPrice' && (
                      <IconSorting
                        type={getSorting('avgPrice')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'lastTradedPrice'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('lastTradedPrice')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Price{' '}
                    {sortingColumns?.[0] === 'lastTradedPrice' && (
                      <IconSorting
                        type={getSorting('lastTradedPrice')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'stockValue'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('stockValue')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Invested{' '}
                    {sortingColumns?.[0] === 'stockValue' && (
                      <IconSorting
                        type={getSorting('stockValue')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'marketValue'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('marketValue')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Mkt.Value{' '}
                    {sortingColumns?.[0] === 'marketValue' && (
                      <IconSorting
                        type={getSorting('marketValue')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'totalGain'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('totalGain')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    POTG/L{' '}
                    {sortingColumns?.[0] === 'totalGain' && (
                      <IconSorting
                        type={getSorting('totalGain')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'totalGainPercentage'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('totalGainPercentage')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    POTG/L&nbsp;(%){' '}
                    {sortingColumns?.[0] === 'totalGainPercentage' && (
                      <IconSorting
                        type={getSorting('totalGainPercentage')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'buy'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('buy')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Buy&nbsp;Done{' '}
                    {sortingColumns?.[0] === 'buy' && (
                      <IconSorting
                        type={getSorting('buy')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'sell'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('sell')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Sell&nbsp;Done{' '}
                    {sortingColumns?.[0] === 'sell' && (
                      <IconSorting
                        type={getSorting('sell')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'offer'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('offer')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Sell&nbsp;Open{' '}
                    {sortingColumns?.[0] === 'offer' && (
                      <IconSorting
                        type={getSorting('offer')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
                <td
                  className={`p-1 py-[7px] border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'bid'
                      ? 'text-main-base-l'
                      : 'text-gray-light-0-d'
                  }`}
                  onClick={() => handleSorting('bid')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Buy&nbsp;Open{' '}
                    {sortingColumns?.[0] === 'bid' && (
                      <IconSorting
                        type={getSorting('bid')}
                        className="h-3 w-3"
                      />
                    )}
                  </span>
                </td>
              </tr>
            </thead>
            <tbody className="dark:text-black-light-1-d">
              <RenderIf isTrue={data?.length === 0 && !isLoading}>
                <tr>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                  <td className="p-1 group-last:border-b-0 text-left">-</td>
                </tr>
              </RenderIf>

              <RenderIf isTrue={isLoading}>
                <tr>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-1 group-last:border-b-0 text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                </tr>
              </RenderIf>

              <RenderIf isTrue={!isLoading}>
                {data?.map((item, key) => (
                  <tr key={key} className={`group`}>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-center">
                      {key + 1}
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-left font-quicksand-semibold relative">
                      <button
                        onClick={() =>
                          customNavigate({
                            query: { 'stock-detail': item?.code }
                          })
                        }
                        className="ml-0 flex items-center mx-auto hover:opacity-75 transition-all ease-in-out duration-200 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d p-0 rounded-xl">
                        {item?.code}
                      </button>

                      <ActionButton
                        isZoomOut={isZoomOut}
                        isLast={true}
                        position={`left-0 -bottom-8`}
                        actions={[
                          {
                            title: 'Beli',
                            children: <IconBuy className="h-4 w-4" />,
                            onClick: () =>
                              customNavigate({
                                query: { 'simple-order': item?.code }
                              })
                          },
                          {
                            title: 'Jual',
                            children: <IconSell className="h-4 w-4" />,
                            onClick: () =>
                              customNavigate({
                                query: {
                                  'simple-order': item?.code,
                                  'order-type': 'SimpleSell'
                                }
                              })
                          },
                          {
                            title: 'Stock Detail',
                            children: <IconSearchPage className="h-4 w-4" />,
                            onClick: () => {
                              triggerSegmentEvent({
                                event: 'TR Search Feature Clicked'
                              });
                              customNavigate({
                                query: { 'stock-detail': item?.code }
                              });
                            }
                          },
                          {
                            title: 'Trading View',
                            children: (
                              <IconChartCandleStick className="h-4 w-4" />
                            ),
                            onClick: () => {
                              customNavigate({
                                query: { 'advanced-chart': item?.code }
                              });
                            }
                          },
                          {
                            title: 'Bandingkan',
                            children: <IconCompare className="h-4 w-4" />,
                            onClick: () => {
                              triggerSegmentEvent({
                                event: 'TR Compare Stock Clicked'
                              });
                              addToCompareStock(
                                { code: item?.code, name: item?.name },
                                () => {
                                  navigate(`/compare/fundamental`);
                                }
                              );
                            }
                          },
                          {
                            title: `${
                              watchlist?.find((w) => w?.code === item?.code)
                                ? 'Hapus Watchlist'
                                : 'Tambahkan ke Watchlist'
                            }`,
                            children: watchlist?.find(
                              (w) => w?.code === item?.code
                            ) ? (
                              <IconStarDelete className="h-4 w-4" />
                            ) : (
                              <IconStar className="h-4 w-4" />
                            ),
                            onClick: async () => {
                              triggerSegmentEvent({
                                event: 'TR Watchlist Clicked'
                              });
                              if (
                                watchlist?.find((w) => w?.code === item?.code)
                              ) {
                                try {
                                  await deleteWatchlist({
                                    stockCode: item?.code
                                  }).unwrap();
                                  toastr.success(
                                    `${item?.code} berhasil dihapus dari watchlist`
                                  );
                                } catch (error) {
                                  getErrorMessage(error);
                                }
                              } else {
                                try {
                                  await createWatchlist({
                                    stockCode: item?.code,
                                    type: 'stock'
                                  }).unwrap();
                                  toastr.success(
                                    `${item?.code} berhasil ditambahkan kedalam watchlist`
                                  );
                                } catch (error) {
                                  getErrorMessage(error);
                                }
                              }
                            }
                          }
                        ]}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency(item.balance / 100, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency(item.net / 100, { removeSymbol: true })}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency(item?.avgPrice, { removeSymbol: true })}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency((item?.lastTradedPrice || 0) / 100, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency(item?.stockValue, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency(item?.marketValue, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                    <td
                      className={`p-1 group-first:pt-2.5 group-last:border-b-0 text-right ${getColorStatus(
                        item?.totalGain
                      )}`}>
                      <BlinkText
                        value={currency(item?.totalGain, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                    <td
                      className={`p-1 group-first:pt-2.5 group-last:border-b-0 text-right ${getColorStatus(
                        item?.totalGainPercentage
                      )}`}>
                      <BlinkText
                        value={
                          Number(item?.totalGainPercentage || 0).toFixed(2) +
                          '%'
                        }
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency((item?.buy || 0) / 100, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency(item?.sell / 100, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency(item?.offer / 100, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                    <td className="p-1 group-first:pt-2.5 group-last:border-b-0 text-right">
                      <BlinkText
                        value={currency(item?.bid / 100, {
                          removeSymbol: true
                        })}
                      />
                    </td>
                  </tr>
                ))}
              </RenderIf>

              <tr className="text-2xs font-quicksand-semibold">
                <td className="p-1 py-[7px]" colSpan={6}>
                  Total
                </td>
                <td className="p-1 py-[7px] text-right">
                  <RenderIf isTrue={isLoading}>
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </RenderIf>

                  <RenderIf isTrue={!isLoading}>
                    <BlinkText
                      value={currency(totalInvested, { removeSymbol: true })}
                    />
                  </RenderIf>
                </td>
                <td className="p-1 py-[7px] text-right">
                  <RenderIf isTrue={isLoading}>
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </RenderIf>

                  <RenderIf isTrue={!isLoading}>
                    <BlinkText
                      value={currency(totalMktValue, { removeSymbol: true })}
                    />
                  </RenderIf>
                </td>
                <td
                  className={`p-1 py-[7px] text-right ${
                    totalPotGain < 0
                      ? 'text-red-base-l'
                      : totalPotGain > 0
                      ? 'text-green-base-l'
                      : ''
                  }`}>
                  <RenderIf isTrue={isLoading}>
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </RenderIf>

                  <RenderIf isTrue={!isLoading}>
                    <BlinkText
                      value={currency(totalPotGain, { removeSymbol: true })}
                    />
                  </RenderIf>
                </td>
                <td
                  className={`p-1 py-[7px] text-right ${
                    totalPotGainPercent < 0
                      ? 'text-red-base-l'
                      : totalPotGainPercent > 0
                      ? 'text-green-base-l'
                      : ''
                  }`}>
                  <RenderIf isTrue={isLoading}>
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </RenderIf>

                  <RenderIf isTrue={!isLoading}>
                    <BlinkText value={totalPotGainPercent + '%'} />
                  </RenderIf>
                </td>
                <td className={`p-1 py-[7px] uppercase`} colSpan={13}></td>
              </tr>
            </tbody>
          </table>
        </OverlayScrollbarsComponent>
      </RenderIf>
    </div>
  );
}

TWPortfolio.propTypes = {
  title: PropTypes.any
};

export default TWPortfolio;
