export const DEFAULT_WORKSPACE_2 = [];

export const WORKSPACE_PINA_SCALPING_2 = [
  {
    groupName: 'Orders',
    title: 'Running Trade',
    name: 'running-trade',
    imgSrc: '/static/media/ss-running-trade.cc8b5131d5f0d38471b6.png',
    isPopular: true,
    state: {
      width: '220px',
      height: '380px',
      x: 0,
      y: 0
    },
    description:
      'Fitur yang memungkinkan untuk melacak dan mengelola perdagangan aktif Kamu. Ini mencakup perdagangan yang sedang berjalan atau masih terbuka',
    symbol: 'AALI',
    id: '38b7fc12-37bb-49f0-be73-5c8394e8c564',
    index: 1
  },
  {
    groupName: 'Orders',
    title: 'Orderbook',
    name: 'orderbook',
    imgSrc: '/static/media/ss-orderbook.4220b751c03a66593562.png',
    isPopular: true,
    state: {
      width: '210px',
      height: '380px',
      x: 450,
      y: 0
    },
    description:
      'Pantau aktivitas perdagangan saham secara real-time dan peroleh wawasan tentang tingkat minat dan kebutuhan pasar terhadap saham tertentu',
    symbol: 'BBCA',
    id: '2dd58abb-19d5-48d6-8c92-35b98cca06e4',
    index: 2
  },
  {
    groupName: 'Orders',
    title: 'Orderbook',
    name: 'orderbook',
    imgSrc: '/static/media/ss-orderbook.4220b751c03a66593562.png',
    isPopular: true,
    state: {
      width: '210px',
      height: '380px',
      x: 230,
      y: 0
    },
    description:
      'Pantau aktivitas perdagangan saham secara real-time dan peroleh wawasan tentang tingkat minat dan kebutuhan pasar terhadap saham tertentu',
    symbol: 'GOTO',
    id: 'f2ca1ae3-dd9e-48e8-9c43-36087e7a304c',
    index: 3,
    isConnectSymbol: true
  },
  {
    groupName: 'Orders',
    title: 'Orderbook',
    name: 'orderbook',
    imgSrc: '/static/media/ss-orderbook.4220b751c03a66593562.png',
    isPopular: true,
    state: {
      width: '210px',
      height: '380px',
      x: 670,
      y: 0
    },
    description:
      'Pantau aktivitas perdagangan saham secara real-time dan peroleh wawasan tentang tingkat minat dan kebutuhan pasar terhadap saham tertentu',
    symbol: 'ANTM',
    id: '14b67eeb-c479-4716-a5c7-513993806539',
    index: 4
  },
  {
    groupName: 'Orders',
    title: 'Orderbook',
    name: 'orderbook',
    imgSrc: '/static/media/ss-orderbook.4220b751c03a66593562.png',
    isPopular: true,
    state: {
      width: '210px',
      height: '380px',
      x: 888,
      y: 0
    },
    description:
      'Pantau aktivitas perdagangan saham secara real-time dan peroleh wawasan tentang tingkat minat dan kebutuhan pasar terhadap saham tertentu',
    symbol: 'ADRO',
    id: '26e7224c-4d36-422c-a3d1-152a208cadfc',
    index: 5
  },
  {
    groupName: 'Orders',
    title: 'Fast Order',
    name: 'fast-order',
    imgSrc: '/static/media/ss-fast-buy.64d8c18b556d6c3fc8a4.png',
    isPopular: true,
    state: {
      width: '390px',
      height: '460px',
      x: 0,
      y: 389.99998474121094
    },
    description:
      'Fitur yang memungkinkan untuk melakukan transaksi perdagangan secara cepat dan efisien. Dengan Fast Order, Kamu dapat dengan mudah membeli atau menjual saham dalam waktu singkat',
    symbol: 'GOTO',
    id: 'e3cdb957-35fc-4fb4-9a7a-356724102f72',
    index: 6,
    isConnectSymbol: true
  },
  {
    groupName: 'Info',
    title: 'Broker Summary',
    name: 'bandarmology',
    imgSrc: '/static/media/ss-bandarmology.c15544ee7d53d7dc0795.png',
    isPopular: true,
    state: {
      width: '390px',
      height: '460px',
      x: 399.9999771118164,
      y: 390.00001525878906
    },
    description:
      'Dapatkan informasi tentang aktivitas perdagangan dari broker, termasuk transaksi yang telah dilakukan, keuntungan atau kerugian yang telah dihasilkan.',
    symbol: 'GOTO',
    id: '56a9b6cf-7d54-4047-bc5b-941048345ca3',
    index: 7,
    isConnectSymbol: true
  },
  {
    groupName: 'Orders',
    title: 'Order & Portfolio',
    name: 'bundle-orderlist',
    imgSrc: '/static/media/ss-orderlist-portfolio.b9077aa1a82e53c0905f.png',
    isPopular: true,
    state: {
      width: '400px',
      height: '460px',
      x: 796.9999465942383,
      y: 390.0000305175781
    },
    description:
      'Lihat daftar pesanan yang Kamu tempatkan dalam perdagangan saham, lalu lacak, kelola, dan pantau status pesanan yang Kamu buat.',
    symbol: 'AALI',
    id: '56da847f-dca2-4e03-a77e-6375fedcff91',
    index: 8
  }
];

export const WORKSPACE_PINA_SWING_2 = [
  {
    groupName: 'Info',
    title: 'News',
    name: 'news',
    imgSrc: '/static/media/ss-news.3de13606de6dc634f056.png',
    state: {
      width: '240px',
      height: '464px',
      x: 0,
      y: 0
    },
    description:
      'Informasi terkini tentang peristiwa dan pengumuman yang berhubungan dengan perusahaan, industri, atau faktor-faktor ekonomi yang dapat mempengaruhi harga dan aktivitas perdagangan saham',
    symbol: 'AALI',
    id: '86281a50-a2e4-47e1-9b46-564f177c9a50',
    index: 1,
    isConnectSymbol: true
  },
  {
    groupName: 'Info',
    title: 'Broker Summary',
    name: 'bandarmology',
    imgSrc: '/static/media/ss-bandarmology.c15544ee7d53d7dc0795.png',
    isPopular: true,
    state: {
      width: '400px',
      height: '460px',
      x: 250.0000228881836,
      y: 0
    },
    description:
      'Dapatkan informasi tentang aktivitas perdagangan dari broker, termasuk transaksi yang telah dilakukan, keuntungan atau kerugian yang telah dihasilkan.',
    symbol: 'AALI',
    id: '4411c43d-5979-418b-a3d7-db62acf5043e',
    index: 2,
    isConnectSymbol: true
  },
  {
    groupName: 'Others',
    title: 'Watchlist',
    name: 'watchlist',
    imgSrc: '/static/media/ss-watchlist.88a8e792b4f200d0e44c.png',
    state: {
      width: '240px',
      height: '460px',
      x: 660.0000228881836,
      y: -0.00000762939453125
    },
    description:
      'Kamu dapat dengan mudah melacak dan memantau saham-saham yang menarik minatmu.  Ikuti pergerakan harga, pantau performa saham-saham pilihan, dan memudahkan dalam pengambilan keputusan investasi Kamu',
    symbol: 'AALI',
    id: '820a02bf-00b6-43e8-9a54-9344b53e650e',
    index: 3
  },
  {
    groupName: 'Info',
    title: 'Trading Ideas',
    name: 'trading-ideas',
    imgSrc: '/static/media/ss-trading-ideas.0dc83130cdd2b929f6af.png',
    state: {
      width: '470px',
      height: '460px',
      x: 910.0000228881836,
      y: -0.00000762939453125
    },
    description:
      'Ide-ide dan analisis pasar yang dapat membantu Kamu dalam mengidentifikasi peluang perdagangan yang menarik',
    symbol: 'AALI',
    id: 'd5b4a2db-1a34-433b-9bcb-b26fd3a5f392',
    index: 4
  },
  {
    groupName: 'Orders',
    title: 'Order & Portfolio',
    name: 'bundle-orderlist',
    imgSrc: '/static/media/ss-orderlist-portfolio.b9077aa1a82e53c0905f.png',
    isPopular: true,
    state: {
      width: '900px',
      height: '470px',
      x: 0,
      y: 470.00001525878906
    },
    description:
      'Lihat daftar pesanan yang Kamu tempatkan dalam perdagangan saham, lalu lacak, kelola, dan pantau status pesanan yang Kamu buat.',
    symbol: 'AALI',
    id: '6c1944d2-0c6e-4e69-a28b-d6ea7a91ed19',
    index: 5
  }
];

export const WORKSPACE_PINA_LONGTERM_2 = [
  {
    groupName: 'Others',
    title: 'Watchlist',
    name: 'watchlist',
    imgSrc: '/static/media/ss-watchlist.88a8e792b4f200d0e44c.png',
    state: {
      width: '230px',
      height: '330px',
      x: 0,
      y: 0
    },
    description:
      'Kamu dapat dengan mudah melacak dan memantau saham-saham yang menarik minatmu.  Ikuti pergerakan harga, pantau performa saham-saham pilihan, dan memudahkan dalam pengambilan keputusan investasi Kamu',
    symbol: 'AALI',
    id: '6025e6c7-1544-4a4f-a769-676d850fbf06',
    index: 1
  },
  {
    groupName: 'Charts',
    title: 'Chart Intraday',
    name: 'chart',
    imgSrc: '/static/media/ss-intraday-chart.27fc2b6c9d5a2ba5e318.png',
    state: {
      width: '290px',
      height: '330px',
      x: 240.00000762939453,
      y: 0
    },
    description:
      'Grafik yang menampilkan gambaran visual tentang bagaimana harga saham berfluktuasi sepanjang hari trading.',
    symbol: 'AALI',
    id: '978b1114-ddc4-4121-94da-ec98ffc4dffc',
    index: 2,
    isConnectSymbol: true
  },
  {
    groupName: 'Orders',
    title: 'Quick Order Advanced',
    name: 'order-form-mock-only',
    imgSrc: '/static/media/ss-quick-order.cf70e243896dec9ca099.png',
    isPopular: true,
    state: {
      width: '400px',
      height: '500px',
      x: 540,
      y: 0
    },
    description:
      'Kamu dapat dengan cepat merespon pergerakan pasar atau kesempatan perdagangan yang muncul. Fitur ini memudahkan untuk masuk dan keluar dari posisi perdagangan dengan cepat',
    symbol: 'AALI',
    id: 'cb2ee645-40c0-42ec-b26e-8e36b6bbef09',
    index: 4,
    isConnectSymbol: true
  },
  {
    groupName: 'Orders',
    title: 'Fast Order',
    name: 'fast-order',
    imgSrc: '/static/media/ss-fast-buy.64d8c18b556d6c3fc8a4.png',
    isPopular: true,
    state: {
      width: '530px',
      height: '460px',
      x: 0.00000762939453125,
      y: 340.00002217292786
    },
    description:
      'Fitur yang memungkinkan untuk melakukan transaksi perdagangan secara cepat dan efisien. Dengan Fast Order, Kamu dapat dengan mudah membeli atau menjual saham dalam waktu singkat',
    symbol: 'AALI',
    id: 'ae11adbd-9f28-4e5d-a16e-df6725222b5f',
    index: 5,
    isConnectSymbol: true
  },
  {
    groupName: 'Orders',
    title: 'Order & Portfolio',
    name: 'bundle-orderlist',
    imgSrc: '/static/media/ss-orderlist-portfolio.b9077aa1a82e53c0905f.png',
    isPopular: true,
    state: {
      width: '620px',
      height: '470px',
      x: 537,
      y: 510
    },
    description:
      'Lihat daftar pesanan yang Kamu tempatkan dalam perdagangan saham, lalu lacak, kelola, dan pantau status pesanan yang Kamu buat.',
    symbol: 'AALI',
    id: '2ee10506-ab35-4bed-a5e0-9bd5b72ed510',
    index: 6
  },
  {
    groupName: 'Others',
    title: 'Info Cash',
    name: 'info-cash',
    state: {
      width: '210px',
      height: '250px',
      x: 945,
      y: 0
    },
    imgSrc: '/static/media/ss-info-cash.cfe096ad803fb370dd29.png',
    description:
      'Lihat informasi tentang saldo kas dan transaksi keuangan Kamu, dapatkan gambaran lengkap tentang keuangan Kamu, termasuk jumlah uang yang tersedia, transaksi masuk dan keluar, serta riwayat transaksi lainnya.',
    symbol: 'AALI',
    id: '07ef424d-08a1-4009-ad49-0aacb552eb36',
    index: 7
  }
];

export const DEFAULT_WORKSPACE = {
  lg: [],
  md: [],
  sm: [],
  xs: [],
  xxs: []
};

export const WORKSPACE_PINA_SCALPING = {
  lg: [
    {
      w: 4,
      h: 40,
      x: 0,
      y: 0,
      i: 'n2344503540',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'UNVR', name: 'running-trade', title: 'Running Trade' }
    },
    {
      w: 4,
      h: 40,
      x: 4,
      y: 0,
      i: 'n2344555040',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBCA', name: 'orderbook', title: 'Orderbook' }
    },
    {
      w: 4,
      h: 40,
      x: 8,
      y: 0,
      i: 'n2344599210',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBNI', name: 'orderbook', title: 'Orderbook' }
    },
    {
      w: 4,
      h: 40,
      x: 12,
      y: 0,
      i: 'n2344639360',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBRI', name: 'orderbook', title: 'Orderbook' }
    },
    {
      w: 4,
      h: 40,
      x: 16,
      y: 0,
      i: 'n2344680350',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'GOTO', name: 'orderbook', title: 'Orderbook' }
    },
    {
      w: 8,
      h: 48,
      x: 0,
      y: 40,
      i: 'n2345035390',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'UNTR', name: 'fast-order', title: 'Fast Order' }
    },
    {
      w: 6,
      h: 48,
      x: 8,
      y: 40,
      i: 'n2345289750',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'ANTM', name: 'bandarmology', title: 'Broker Summary' }
    },
    {
      w: 6,
      h: 48,
      x: 14,
      y: 40,
      i: 'n2345337900',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'INCO',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio',
        settings: { activeTabIndex: 2 }
      }
    }
  ],
  md: [
    {
      w: 4,
      h: 80,
      x: 0,
      y: 0,
      i: 'n2344503540',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'UNVR',
        name: 'running-trade',
        title: 'Running Trade'
      }
    },
    {
      w: 4,
      h: 40,
      x: 4,
      y: 0,
      i: 'n2344555040',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'BBCA',
        name: 'orderbook',
        title: 'Orderbook'
      }
    },
    {
      w: 4,
      h: 40,
      x: 8,
      y: 0,
      i: 'n2344599210',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'BBNI',
        name: 'orderbook',
        title: 'Orderbook'
      }
    },
    {
      w: 4,
      h: 40,
      x: 8,
      y: 40,
      i: 'n2344639360',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'BBRI',
        name: 'orderbook',
        title: 'Orderbook'
      }
    },
    {
      w: 4,
      h: 40,
      x: 4,
      y: 40,
      i: 'n2344680350',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'GOTO',
        name: 'orderbook',
        title: 'Orderbook'
      }
    },
    {
      w: 6,
      h: 48,
      x: 0,
      y: 80,
      i: 'n2345035390',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'UNTR',
        name: 'fast-order',
        title: 'Fast Order'
      }
    },
    {
      w: 6,
      h: 48,
      x: 6,
      y: 80,
      i: 'n2345289750',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'ANTM',
        name: 'bandarmology',
        title: 'Broker Summary'
      }
    },
    {
      w: 12,
      h: 30,
      x: 0,
      y: 128,
      i: 'n2352683840',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'BMRI',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      }
    }
  ],
  sm: [
    {
      w: 4,
      h: 38,
      x: 0,
      y: 0,
      i: 'n2342488210',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'AKRA',
        name: 'running-trade',
        title: 'Running Trade'
      }
    },
    {
      w: 4,
      h: 40,
      x: 0,
      y: 78,
      i: 'n2344555040',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'BBCA',
        name: 'orderbook',
        title: 'Orderbook'
      }
    },
    {
      w: 4,
      h: 40,
      x: 0,
      y: 38,
      i: 'n2344599210',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'BBNI',
        name: 'orderbook',
        title: 'Orderbook'
      }
    },
    {
      w: 5,
      h: 39,
      x: 4,
      y: 38,
      i: 'n2344639360',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'BBRI',
        name: 'orderbook',
        title: 'Orderbook'
      }
    },
    {
      w: 5,
      h: 38,
      x: 4,
      y: 0,
      i: 'n2344680350',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'GOTO',
        name: 'orderbook',
        title: 'Orderbook'
      }
    },
    {
      w: 5,
      h: 41,
      x: 4,
      y: 77,
      i: 'n2345035390',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'UNTR',
        name: 'fast-order',
        title: 'Fast Order'
      }
    },
    {
      w: 5,
      h: 45,
      x: 0,
      y: 118,
      i: 'n2345289750',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'ANTM',
        name: 'bandarmology',
        title: 'Broker Summary'
      }
    },
    {
      w: 4,
      h: 45,
      x: 5,
      y: 118,
      i: 'n2345337910',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'INCO',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      }
    }
  ],
  xs: [
    {
      i: 'n2342488210',
      x: 0,
      y: 999999,
      w: 3,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'AKRA', name: 'running-trade', title: 'Running Trade' },
      static: false
    },
    {
      i: 'n2344503540',
      x: 4,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'UNVR', name: 'running-trade', title: 'Running Trade' },
      static: false
    },
    {
      i: 'n2344555040',
      x: 2,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBCA', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2344599210',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBNI', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2344639360',
      x: 4,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBRI', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2344680350',
      x: 2,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'GOTO', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2345035390',
      x: 0,
      y: 999999,
      w: 4,
      h: 53,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'UNTR', name: 'fast-order', title: 'Fast Order' },
      static: false
    },
    {
      i: 'n2345289750',
      x: 4,
      y: 999999,
      w: 4,
      h: 45,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'ANTM', name: 'bandarmology', title: 'Broker Summary' },
      static: false
    },
    {
      i: 'n2345337910',
      x: 2,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'INCO',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      },
      static: false
    }
  ],
  xxs: [
    {
      i: 'n2342488210',
      x: 0,
      y: 999999,
      w: 3,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'AKRA', name: 'running-trade', title: 'Running Trade' },
      static: false
    },
    {
      i: 'n2343491050',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'AMRT', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2344503540',
      x: 2,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'UNVR', name: 'running-trade', title: 'Running Trade' },
      static: false
    },
    {
      i: 'n2344555040',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBCA', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2344599210',
      x: 1,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBNI', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2344639360',
      x: 2,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'BBRI', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2344680350',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'GOTO', name: 'orderbook', title: 'Orderbook' },
      static: false
    },
    {
      i: 'n2345035390',
      x: 1,
      y: 999999,
      w: 4,
      h: 53,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'UNTR', name: 'fast-order', title: 'Fast Order' },
      static: false
    },
    {
      i: 'n2345289750',
      x: 2,
      y: 999999,
      w: 4,
      h: 45,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'ANTM', name: 'bandarmology', title: 'Broker Summary' },
      static: false
    },
    {
      i: 'n2345337910',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'INCO',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      },
      static: false
    }
  ]
};

export const WORKSPACE_PINA_SWING = {
  lg: [
    {
      w: 5,
      h: 46,
      x: 0,
      y: 0,
      i: 'n2368955100',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'news', title: 'News' }
    },
    {
      w: 10,
      h: 46,
      x: 5,
      y: 0,
      i: 'n2368955101',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'bandarmology', title: 'Broker Summary' }
    },
    {
      w: 5,
      h: 46,
      x: 15,
      y: 0,
      i: 'n2368955102',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'watchlist', title: 'Watchlist' }
    },
    {
      w: 10,
      h: 37,
      x: 0,
      y: 46,
      i: 'n2368955103',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'trading-ideas', title: 'Trading Ideas' }
    },
    {
      w: 10,
      h: 37,
      x: 10,
      y: 46,
      i: 'n2368955104',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'KLBF',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      }
    }
  ],
  md: [
    {
      w: 5,
      h: 46,
      x: 0,
      y: 0,
      i: 'n2368955100',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'news', title: 'News' }
    },
    {
      w: 10,
      h: 46,
      x: 2,
      y: 46,
      i: 'n2368955101',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'bandarmology', title: 'Broker Summary' }
    },
    {
      w: 5,
      h: 46,
      x: 7,
      y: 92,
      i: 'n2368955102',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'watchlist', title: 'Watchlist' }
    },
    {
      w: 10,
      h: 37,
      x: 0,
      y: 138,
      i: 'n2368955103',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'trading-ideas', title: 'Trading Ideas' }
    },
    {
      w: 10,
      h: 37,
      x: 2,
      y: 175,
      i: 'n2368955104',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'KLBF',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      }
    }
  ],
  sm: [
    {
      w: 4,
      h: 46,
      x: 0,
      y: 0,
      i: 'n2368955100',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'news', title: 'News' }
    },
    {
      w: 4,
      h: 45,
      x: 4,
      y: 0,
      i: 'n2368955101',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'bandarmology', title: 'Broker Summary' }
    },
    {
      w: 4,
      h: 40,
      x: 5,
      y: 45,
      i: 'n2368955102',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'watchlist', title: 'Watchlist' }
    },
    {
      w: 4,
      h: 40,
      x: 5,
      y: 85,
      i: 'n2368955103',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'trading-ideas', title: 'Trading Ideas' }
    },
    {
      w: 4,
      h: 40,
      x: 5,
      y: 125,
      i: 'n2368955104',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'KLBF',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      }
    }
  ],
  xs: [
    {
      i: 'n2368955100',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'news', title: 'News' },
      static: false
    },
    {
      i: 'n2368955101',
      x: 4,
      y: 999999,
      w: 4,
      h: 45,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'bandarmology', title: 'Broker Summary' },
      static: false
    },
    {
      i: 'n2368955102',
      x: 2,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'watchlist', title: 'Watchlist' },
      static: false
    },
    {
      i: 'n2368955103',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'trading-ideas', title: 'Trading Ideas' },
      static: false
    },
    {
      i: 'n2368955104',
      x: 4,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'KLBF',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      },
      static: false
    }
  ],
  xxs: [
    {
      i: 'n2368955100',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'news', title: 'News' },
      static: false
    },
    {
      i: 'n2368955101',
      x: 1,
      y: 999999,
      w: 4,
      h: 45,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'bandarmology', title: 'Broker Summary' },
      static: false
    },
    {
      i: 'n2368955102',
      x: 2,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'watchlist', title: 'Watchlist' },
      static: false
    },
    {
      i: 'n2368955103',
      x: 0,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'trading-ideas', title: 'Trading Ideas' },
      static: false
    },
    {
      i: 'n2368955104',
      x: 1,
      y: 999999,
      w: 4,
      h: 40,
      minW: 4,
      minH: 16,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'KLBF',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      },
      static: false
    }
  ]
};

export const WORKSPACE_PINA_LONGTERM = {
  lg: [
    {
      w: 5,
      h: 40,
      x: 0,
      y: 0,
      i: 'n8157296820',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'watchlist', title: 'Watchlist' }
    },
    {
      w: 5,
      h: 40,
      x: 5,
      y: 0,
      i: 'n8157362640',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'chart', title: 'Chart Intraday' }
    },
    {
      w: 5,
      h: 40,
      x: 10,
      y: 0,
      i: 'n8157443790',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'orderbook', title: 'Orderbook' }
    },
    {
      w: 5,
      h: 40,
      x: 15,
      y: 0,
      i: 'n8157552140',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'order-form', title: 'Quick Order', symbol: 'BBCA' }
    },
    {
      w: 8,
      h: 48,
      x: 0,
      y: 40,
      i: 'n8157849490',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'fast-order', title: 'Fast Order', symbol: 'BBCA' }
    },
    {
      w: 8,
      h: 48,
      x: 8,
      y: 40,
      i: 'n8158078810',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'bundle-orderlist', title: 'Order & Portfolio' }
    },
    {
      w: 4,
      h: 29,
      x: 16,
      y: 40,
      i: 'n8158243810',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'info-cash', title: 'Info Cash' }
    }
  ],
  md: [
    {
      w: 4,
      h: 45,
      x: 0,
      y: 0,
      i: 'n2368955100',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'news', title: 'News' }
    },
    {
      w: 8,
      h: 45,
      x: 4,
      y: 0,
      i: 'n2368955101',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'bandarmology', title: 'Broker Summary' }
    },
    {
      w: 4,
      h: 37,
      x: 0,
      y: 45,
      i: 'n2368955102',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'watchlist', title: 'Watchlist' }
    },
    {
      w: 8,
      h: 37,
      x: 4,
      y: 45,
      i: 'n2368955103',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'trading-ideas', title: 'Trading Ideas' }
    },
    {
      w: 12,
      h: 34,
      x: 0,
      y: 82,
      i: 'n2368955104',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'KLBF',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      }
    }
  ],
  sm: [
    {
      w: 4,
      h: 45,
      x: 0,
      y: 0,
      i: 'n2368955100',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'news', title: 'News' }
    },
    {
      w: 5,
      h: 44,
      x: 4,
      y: 0,
      i: 'n2368955101',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'bandarmology', title: 'Broker Summary' }
    },
    {
      w: 4,
      h: 37,
      x: 0,
      y: 45,
      i: 'n2368955102',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'watchlist', title: 'Watchlist' }
    },
    {
      w: 5,
      h: 38,
      x: 4,
      y: 44,
      i: 'n2368955103',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { symbol: 'KLBF', name: 'trading-ideas', title: 'Trading Ideas' }
    },
    {
      w: 9,
      h: 29,
      x: 0,
      y: 82,
      i: 'n2368955104',
      minW: 4,
      minH: 16,
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: {
        symbol: 'KLBF',
        name: 'bundle-orderlist',
        title: 'Order & Portfolio'
      }
    }
  ],
  xs: [
    {
      w: 3,
      h: 40,
      x: 0,
      y: 0,
      i: 'n8157296820',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'watchlist', title: 'Watchlist' },
      minW: 4,
      minH: 16
    },
    {
      w: 3,
      h: 40,
      x: 3,
      y: 0,
      i: 'n8157362640',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'chart', title: 'Chart Intraday' },
      minW: 4,
      minH: 16
    },
    {
      w: 3,
      h: 40,
      x: 6,
      y: 0,
      i: 'n8157443790',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'orderbook', title: 'Orderbook' },
      minW: 4,
      minH: 16
    },
    {
      w: 3,
      h: 40,
      x: 9,
      y: 0,
      i: 'n8157552140',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'order-form', title: 'Quick Order', symbol: 'BBCA' },
      minW: 4,
      minH: 16
    },
    {
      w: 6,
      h: 52,
      x: 0,
      y: 40,
      i: 'n8157849490',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'fast-order', title: 'Fast Order', symbol: 'BBCA' },
      minW: 4,
      minH: 16
    },
    {
      w: 6,
      h: 23,
      x: 6,
      y: 40,
      i: 'n8158078810',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'bundle-orderlist', title: 'Order & Portfolio' },
      minW: 4,
      minH: 16
    },
    {
      w: 6,
      h: 29,
      x: 6,
      y: 63,
      i: 'n8158243810',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'info-cash', title: 'Info Cash' },
      minW: 4,
      minH: 16
    }
  ],
  xxs: [
    {
      w: 3,
      h: 40,
      x: 0,
      y: 0,
      i: 'n8157296820',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'watchlist', title: 'Watchlist' },
      minW: 4,
      minH: 16
    },
    {
      w: 3,
      h: 40,
      x: 3,
      y: 0,
      i: 'n8157362640',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'chart', title: 'Chart Intraday' },
      minW: 4,
      minH: 16
    },
    {
      w: 3,
      h: 40,
      x: 6,
      y: 0,
      i: 'n8157443790',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'orderbook', title: 'Orderbook' },
      minW: 4,
      minH: 16
    },
    {
      w: 3,
      h: 40,
      x: 9,
      y: 0,
      i: 'n8157552140',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'order-form', title: 'Quick Order', symbol: 'BBCA' },
      minW: 4,
      minH: 16
    },
    {
      w: 6,
      h: 52,
      x: 0,
      y: 40,
      i: 'n8157849490',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'fast-order', title: 'Fast Order', symbol: 'BBCA' },
      minW: 4,
      minH: 16
    },
    {
      w: 6,
      h: 23,
      x: 6,
      y: 40,
      i: 'n8158078810',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'bundle-orderlist', title: 'Order & Portfolio' },
      minW: 4,
      minH: 16
    },
    {
      w: 6,
      h: 29,
      x: 6,
      y: 63,
      i: 'n8158243810',
      moved: false,
      static: false,
      resizeHandles: ['ne', 'se', 'nw', 'sw'],
      widget: { name: 'info-cash', title: 'Info Cash' },
      minW: 4,
      minH: 16
    }
  ]
};

