import MarketStatus from 'components/header/_partials/market-status';
import IconDarkMode from 'components/svg/icon-dark-mode';
import IconLightMode from 'components/svg/icon-light-mode';
import useDarkMode from 'hooks/useDarkMode';
import useFontSize from 'hooks/useFontSize';
import cn from 'utils/classname';

export default function SettingsBar() {
  const { toggleTheme, theme } = useDarkMode();
  const { changeFontSize, fontSize } = useFontSize();
  return (
    <div className="fixed right-0 bottom-0 z-50 w-full lg:w-[calc(100vw-80px)] p-1.5 px-4 card-widget-gradient">
      <div className="flex items-center gap-3">
        <button
          onClick={toggleTheme}
          type="button"
          className="appearance-none focus-within:ring-0 text-main-base-d">
          {theme === 'dark' ? (
            <IconDarkMode className="h-4 w-4" />
          ) : (
            <IconLightMode className="h-4 w-4" />
          )}
        </button>

        <button
          onClick={() => changeFontSize('trade-font-small')}
          type="button"
          className={cn('appearance-none focus-within:ring-0 text-black-light-0-d flex items-center justify-center',
            fontSize === 'trade-font-small' && 'text-main-base-d'
          )}>
          <span className="aspect-square block font-quicksand-semibold text-2xs text-[10px]">A</span>
        </button>

        <button
          onClick={() => changeFontSize('trade-font-normal')}
          type="button"
          className={cn('appearance-none focus-within:ring-0 text-black-light-0-d flex items-center justify-center',
            fontSize === 'trade-font-normal' && 'text-main-base-d'
          )}>
          <span className="aspect-square block font-quicksand-semibold text-xs">A</span>
        </button>

        <button
          onClick={() => changeFontSize('trade-font-large')}
          type="button"
          className={cn('appearance-none focus-within:ring-0 text-black-light-0-d flex items-center justify-center',
            fontSize === 'trade-font-large' && 'text-main-base-d'
          )}>
          <span className="aspect-square block font-quicksand-semibold">A</span>
        </button>

        <MarketStatus />
      </div>
    </div>
  );
}
