import { object, number, string } from 'yup';

export const priceAlertSchema = object().shape({
  price: number()
    .test('price', 'Price Alert harus diisi', function (value) {
      return value > 0;
    })
    .required('Required'),
  when: string().required('Kondisi Price Alert harus diisi')
});
