import LoadingPina from 'assets/images/loading/pina.gif'

const LoadingPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen min-w-full">
        <div className="flex flex-col items-center">
          <img src={LoadingPina} alt="Loading" className="h-24 w-auto" />
          <p className="text-sm text-black-base-l my-2">Loading..</p>
        </div>
    </div>
  )
}

export default LoadingPage