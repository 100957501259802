/* eslint-disable react/prop-types */
import RenderIf from 'components/basic/render-if';
import dayjs from 'dayjs';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetStockCorpActionByCodeQuery } from 'services/rtk-query/stock';
import { automatedNumberFormat } from 'utils/format';
import { useEffect } from 'react';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import useElementSize from 'hooks/useElementSize';
import cn from 'utils/classname';

function TWCorpActionRHUPS({
  symbol,
  twoColumns = false,
  maxHeight = '350px'
}) {
  const isBrowserWakeUp = useBrowserWakeUp();
  const { data, isFetching, refetch } = useGetStockCorpActionByCodeQuery(
    symbol,
    { refetchOnMountOrArgChange: true }
  );

  const { ref, dimensions } = useElementSize();
  const { width } = dimensions ?? {};

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  const RightIssue = (props) => {
    return (
      <div className="py-3 border-b border-gray-light-l dark:border-gray-base-d">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Ratio
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {automatedNumberFormat(props?.new_ratio || 0)}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Price
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {automatedNumberFormat(props?.price || 0)}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Cumulative Date
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.cum_date).format('DD MMM YYYY')}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Ex Date
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.ex_date).format('DD MMM YYYY')}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Recording Date
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.rec_date).format('DD MMM YYYY')}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Trading Start
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.trading_start).format('DD MMM YYYY')}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Trading End
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.trading_end).format('DD MMM YYYY')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const Rups = (props) => {
    return (
      <div className="py-3 border-b border-gray-light-l dark:border-gray-base-d">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Tanggal
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.DateAndTime).format('DD MMM YYYY')}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Jam
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.DateAndTime).format('hh:mm')}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Alamat
            </span>
            <span className="flex-grow font-quicksand-regular text-right text-xs dark:text-black-light-1-d">
              {props?.Place || '-'}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const StockSplit = (props) => {
    return (
      <div className="py-3 border-b border-gray-light-l dark:border-gray-base-d">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Ratio
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {automatedNumberFormat(props?.new_ratio || 0)}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Cumulative Date
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.cum_date).format('DD MMM YYYY')}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Ex Date
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.ex_date).format('DD MMM YYYY')}
            </span>
          </div>

          <div className="flex items-center">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular w-1/3">
              Recording Date
            </span>
            <span className="flex-grow font-quicksand-semibold text-right text-xs dark:text-black-light-1-d">
              {dayjs(props?.rec_date).format('DD MMM YYYY')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        style={{ maxHeight: maxHeight }}>
        <div
          ref={ref}
          className={cn(
            'grid grid-cols-1 gap-4',
            width >= 400 && width < 600 && 'grid-cols-2',
            width >= 600 && 'grid-cols-3',
          )}>
          <RenderIf isTrue={data?.['Right Issue']?.length > 0 || isFetching}>
            <div>
              <div className="p-3 bg-gray-light-l dark:bg-black-base-d dark:text-black-light-1-d mb-3">
                <h5 className="text-xs">Right Issue</h5>
              </div>

              <RenderIf isTrue={isFetching}>
                <div className="p-3 pt-0">
                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="150px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="100px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>
                </div>
              </RenderIf>

              <RenderIf
                isTrue={data?.['Right Issue']?.length > 0 && !isFetching}>
                <div
                  className={`px-3 -mt-3 overflow-auto grid gap-3 ${
                    twoColumns ? 'md:grid-cols-2' : 'grid-cols-1'
                  }`}>
                  {data?.['Right Issue']?.map((item, key) => (
                    <div key={key}>
                      <RightIssue {...item} />
                    </div>
                  ))}
                </div>
              </RenderIf>
            </div>
          </RenderIf>

          <RenderIf isTrue={data?.RUPS?.length > 0 || isFetching}>
            <div>
              <div className="p-3 bg-gray-light-l dark:bg-black-base-d dark:text-black-light-1-d mb-3">
                <h5 className="text-xs">RHUPS</h5>
              </div>

              <RenderIf isTrue={isFetching}>
                <div className="p-3 pt-0">
                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="150px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="100px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>
                </div>
              </RenderIf>

              <RenderIf isTrue={data?.RUPS?.length > 0 && !isFetching}>
                <div
                  className={`px-3 -mt-3 overflow-auto grid gap-3 ${
                    twoColumns ? 'md:grid-cols-2' : 'grid-cols-1'
                  }`}>
                  {data?.RUPS?.map((item, key) => (
                    <div key={key}>
                      <Rups {...item} />
                    </div>
                  ))}
                </div>
              </RenderIf>
            </div>
          </RenderIf>

          <RenderIf isTrue={data?.['Stock Split']?.length > 0 || isFetching}>
            <div>
              <div className="p-3 bg-gray-light-l dark:bg-black-base-d dark:text-black-light-1-d mb-3">
                <h5 className="text-xs">Stock Split</h5>
              </div>

              <RenderIf isTrue={isFetching}>
                <div className="p-3 pt-0">
                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="150px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="100px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>

                  <div className="flex justify-between mb-2">
                    <SkeletonTheme
                      width="120px"
                      height="1rem"
                      borderRadius="6px"
                    />
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </div>
                </div>
              </RenderIf>

              <RenderIf
                isTrue={data?.['Stock Split']?.length > 0 && !isFetching}>
                <div
                  className={`px-3 -mt-3 overflow-auto grid gap-3 ${
                    twoColumns ? 'md:grid-cols-2' : 'grid-cols-1'
                  }`}>
                  {data?.['Stock Split']?.map((item, key) => (
                    <div key={key}>
                      <StockSplit {...item} />
                    </div>
                  ))}
                </div>
              </RenderIf>
            </div>
          </RenderIf>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}

TWCorpActionRHUPS.propTypes = {
  symbol: PropTypes.string,
  twoColumns: PropTypes.bool
};

export default TWCorpActionRHUPS;
