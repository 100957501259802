import { triggerSegmentEvent } from 'lib/segment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSetTheme, setTheme } from 'store/pina-pro';

const useDarkMode = () => {
  const dispatch = useDispatch();
  const { theme, isSetTheme } = useSelector(({ pinaPro }) => pinaPro);
  const [prefersDarkMode, setPrefersDarkMode] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches);

  const toggleTheme = () => {
    dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'));
    dispatch(setIsSetTheme());
    triggerSegmentEvent({ event: 'TR Toggle Theme Mode', properties: { type: theme === 'dark' ? 'light' : 'dark' }})
  }
  
  const resetTheme = () => {
    dispatch(setTheme('light'));
    dispatch(setIsSetTheme());
  }

  const removeDarkMode = () => {
    document.documentElement.classList.remove('dark');
  }

  const handleDarkModePrefferedChange = () => {
    const doesMatch = window.matchMedia("(prefers-color-scheme: dark)").matches
    setPrefersDarkMode(doesMatch)
  }

  useEffect(() => {
    if (isSetTheme) {
      if (theme === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }, [theme, isSetTheme]);

  useEffect(() => {
    if (!isSetTheme) {
      if (prefersDarkMode) {
        document.documentElement.classList.add('dark');
        dispatch(setTheme('dark'));
      } else {
        document.documentElement.classList.remove('dark');
        dispatch(setTheme('light'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetTheme, prefersDarkMode]);

  useEffect(() => {
    if (!isSetTheme) {
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleDarkModePrefferedChange)
  
      return () => {
        window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handleDarkModePrefferedChange)
      }
    }
  }, [isSetTheme]);

  return {
    theme,
    toggleTheme,
    resetTheme,
    removeDarkMode
  };
};

export default useDarkMode;
