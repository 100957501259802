import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import InputCounter from 'components/input/input-counter';
import InputSelect from 'components/input/input-select';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { find, isEmpty, uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { invalidateAllOrder, useCreateOrderAmendMutation } from 'services/rtk-query/trade';
import { currency, generateOrderId } from 'utils/format';
import { marginPlusShare } from 'utils/formula/margin';
import { getOrderStatus } from 'utils/stock/order';
import { StockAmendSchema } from './stock-amend.form.validation';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmationStockAmend from '../_partials/modal-confirmation-stock-amend';
import { triggerSegmentEvent } from 'lib/segment';
import { invalidateUserNotification } from 'services/rtk-query/user';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import useToastr from 'hooks/useToastr';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputCheckBox from 'components/input/input-check-box';
import { setQuickOrderSettingsConfirmation } from 'store/settings';
import IconSort from 'components/svg/icon-sort';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import useBrowserTabTrading from 'hooks/useBrowserTabTrading';

function OrderFocusAmendForm({
  buyingPower,
  user,
  orderList = [],
  isSticky,
  pickPrice,
  initValues,
  portFolio = {},
  analyticEventName = 'TR Amend from Widget'
}) {
  const refInputPrice = useRef(null);
  const refInputLot = useRef(null);
  const toastr = useToastr();
  const { dealerUseClientId } = useBrowserTabAuth();
  const quickOrderConfirmation = useSelector((state) => state?.settings?.quickOrderSettings?.confirmation);
  const { isLoginPin } = useBrowserTabTrading();
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();
  const [createOrderAmend] = useCreateOrderAmendMutation();

  const [optionsOrderList, setOptionsOrderList] = useState([]);
  const [maxLot, setMaxLot] = useState((initValues?.qty / 100) || 0);
  const [isNotRealAccount, setIsNotRealAccount] = useState(false);
  const [openModalConfirmationAmend, setOpenModalConfirmationAmend] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let options = [];
    orderList?.map((item) => {
      if (
        !['amend', 'match', 'reject', 'withdraw'].includes(
          getOrderStatus(item?.state)
        )
      ) {
        options.push({
          id: item.id,
          name:
            item?.stID +
            ' / ' +
            item?.side +
            ' / ' +
            item?.qty / 100 +
            ' Lot / ' +
            item?.price +
            ' Price / ' +
            dayjs.unix(item?.dtSave / 1000).format('DD-MM-YYYY HH:mm')
        });
      }
    });

    setOptionsOrderList(options);
  }, [orderList]);

  const formik = useFormik({
    initialValues: {
      lotType: true,
      amount: 0,
      buyingPower: buyingPower + (initValues?.qty * initValues?.price || 0),
      orderNumber: generateOrderId(dealerUseClientId || user?.username, 'A'),
      code: initValues?.stID || null,
      lot: initValues?.qty / 100 || 0,
      price: initValues?.price || 0,
      id: initValues?.id || undefined,
      side: initValues?.side || null,
      initialPrice: initValues?.price || 0
    },
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: StockAmendSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      let payloads = {
        orderID: values?.id,
        clOrdID: generateOrderId(dealerUseClientId || user?.username, 'A'),
        price: values?.price,
        qty: values?.lot
      };
      const { success, msg, err } = await createOrderAmend(payloads).unwrap();

      if (!success) {
        let error = new Error();
        Object.assign(error, { msg, code: err });
        throw error;
      }

      triggerSegmentEvent({
        event: analyticEventName,
        properties: {
          total_amount: Number(values?.lot) * Number(values?.price) * 100,
        }
      });

      setOpenModalConfirmationAmend(false);
      toastr.success('Order telah terkirim');
      formik.setValues({
        ...formik.values,
        id: undefined,
        lot: 0,
        amount: 0,
        orderNumber: generateOrderId(dealerUseClientId || user?.username, 'A'),
      });

      setTimeout(() => formik.setErrors({}), 300);
      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (val, obj) => {
    formik.setFieldValue(obj, val);
  };

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();

    if (!user?.realAccount) return setIsNotRealAccount(true);

    if (isEmpty(errors)) {
      if (!quickOrderConfirmation) {
        return setOpenModalConfirmationAmend(true);
      }
      
      return formik.submitForm();
    }
  };

  useEffect(() => {
    if (Number(pickPrice) != formik.values.price && pickPrice) {
      formik.setFieldValue('price', Number(pickPrice));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickPrice]);

  useEffect(() => {
    if (initValues?.id != formik?.values?.id && !isEmpty(initValues) && user) {
      formik.setValues({
        ...formik.values,
        id: initValues.id,
        side: initValues.side,
        price: initValues.price,
        initialPrice: initValues.price,
        lot: initValues.qty / 100,
        code: initValues.stID,
        buyingPower: initValues.qty * initValues.price,
        orderNumber: generateOrderId(dealerUseClientId || user?.username, 'A')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValues, dealerUseClientId, user]);

  return (
    <form
      className="mt-3"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleConfirmation();
      }}
      noValidate>
      <div className="px-4">
        <RenderIf isTrue={formik?.values?.side === 'B'}>
          <div className='flex justify-between'>
            <span className="text-xs text-gray-light-0-d block font-quicksand-semibold">
              Limit
            </span>
            <h5 className="mb-3 dark:text-black-light-1-d">
              {currency(formik.values.buyingPower || buyingPower || 0, { removeSymbol: true })}
            </h5>
          </div>
        </RenderIf>

        <RenderIf isTrue={formik?.values?.side === 'S'}>
          <div className='flex justify-between'>
            <span className="text-xs text-gray-light-0-d block font-quicksand-semibold">
              Lot tersedia
            </span>
            <h5 className="mb-3 dark:text-black-light-1-d">
              {currency(maxLot || 0, { removeSymbol: true })}
            </h5>
          </div>
        </RenderIf>
        
        <input type="hidden" value={formik.values.buyingPower} />
        <div className="relative">
          <InputSelect
            isAutoFocus={isLoginPin}
            size='small'
            value={formik?.values?.id}
            name="id"
            label={<span className='text-2xs text-gray-light-0-d font-quicksand-semibold'>Pilih Order</span>}
            className="mb-2 text-2xs text-gray-light-0-d"
            options={optionsOrderList}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              if (e.target.value) {
                const selectOrder = find(orderList, { id: Number(e.target.value)});

                formik.setValues({
                  ...formik.values,
                  orderNumber: generateOrderId(dealerUseClientId || user?.username, 'A'),
                  buyingPower: buyingPower + selectOrder?.qty * selectOrder?.price,
                  price: selectOrder?.price,
                  initialPrice: selectOrder?.price,
                  code: selectOrder?.stID,
                  side: selectOrder?.side,
                  lot: selectOrder?.qty / 100
                })

                const portfolio = find(portFolio?.stock?.items, { stID: selectOrder?.stID });
                setMaxLot(portfolio?.balance / 100);
                setTimeout(() => formik.setErrors({}), 100);
              }
              formik.handleChange(e);
            }}
            onFocus={() => dispatch(invalidateAllOrder())}
            error={formik.errors.id ? formik.errors.id : ''}
            showError={false}
          />
        </div>

        <InputCounter
          ref={refInputPrice}
          size='small'
          onBlur={formik.handleBlur}
          data-qa-id="txtStockBuyPriceField"
          value={formik.values.price}
          name="price"
          className='my-2'
          label={<span className='text-2xs text-gray-light-0-d'>Harga Per Saham</span>}
          counterStep={marginPlusShare(formik.values.price)}
          onChange={(val) => {
            handleChange(val, 'price');
            setTimeout(() => formik.setTouched({ price: true }), 100);
          }}
          error={formik.errors.price ? formik.errors.price : ''}
          showError={false}
        />

        <InputCounter
          ref={refInputLot}
          size='small'
          onBlur={formik.handleBlur}
          data-qa-id="txtStockBuyLotField"
          name="lot"
          value={formik.values.lotType ? formik.values.lot : formik.values.amount}
          value2={formik.values.lotType ? formik.values.amount : formik.values.lot}
          className='my-3'
          label={
            <span className='text-2xs text-gray-light-0-d'>
              <button tabIndex={-1} onClick={() => formik.setFieldValue('lotType', !formik.values.lotType)} type='button' className='flex items-center hover:text-main-base-l'>
                <IconSort className='h-4 w-4 mr-1 transform rotate-90 text-main-base-l' /> {formik.values.lotType ? 'Jumlah Lot' : 'Amount'}
              </button>
            </span>}
          label2={<span className='text-2xs text-gray-light-0-d'>{formik.values.lotType ? 'Amount' : 'Lot'}</span>}
          showShortcut={formik.values.lotType}
          counterStep={formik.values.lotType ? 1 : formik.values.price * 100}
          maxValue={formik?.values?.side === 'S' ? maxLot : 9999999999}
          max={formik?.values?.side === 'S' ? formik.values.lotType ? maxLot : maxLot * 100 * formik.values.price : 9999999999}
          onChange={(val) => {
            if (formik.values.lotType) {
              handleChange(val, 'lot');
              handleChange(Number(val) * formik.values.price * 100 , 'amount');
            } else {
              handleChange(val, 'amount');
              handleChange(Math.floor(Number(val) / (formik.values.price * 100)) , 'lot');
            }
            setTimeout(() => formik.setTouched({ lot: true }), 100);
          }}
          error={formik.errors.lot ? formik.errors.lot : ''}
          showError={false}
        />

        <div className="flex border-y dark:border-gray-base-d py-2 items-center">
          <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
            Total Transaksi
          </span>
          <h5 className="flex-grow text-right text-sm dark:text-[#D9DEE2]">
            {currency(
              parseInt(formik.values.lot) * parseInt(formik.values.price) * 100
            )}
          </h5>
        </div>

        {/* <RenderIf isTrue={!formik?.isValid}>
          <div className="font-quicksand-semibold py-3 px-4 flex items-center text-red-base-l text-xs rounded-xl my-3 bg-red-base-l bg-opacity-10">
            <IconWarning className="h-5 w-5 mr-1 flex-none" />
            {Object.keys(formik?.errors)?.map((key, idx) => (
              <RenderIf key={key} isTrue={idx === 0}>
                <span
                  key={key}
                  className="flex items-center w-full flex-auto">
                  {idx === 0 && formik?.errors[key]}
                </span>
              </RenderIf>
            ))}
          </div>
        </RenderIf> */}
      </div>

      <div
        className={`py-3 mt-1 ${
          !isSticky ? 'relative px-4' : 'sticky bottom-0 card-widget-gradient px-4'
        }`}>
        <button
          data-qa-id="btnStockBuySubmit"
          disabled={!formik.isValid && user?.realAccount || isSubmiting}
          onClick={handleConfirmation}
          type="button"
          className="bg-main-gold-d hover:bg-opacity-75 disabled:bg-opacity-[30%] text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full mr-0 ml-auto flex justify-center items-center transition-all ease-in-out duration-100">
          {isSubmiting && <SimpleSpinner />}
          Amend {formik.values.lot ? formik.values.lot + ' Lot' : ''} {formik.values.code ? <>{formik.values.code} @ {formik.values.price}</> : ''}
        </button>

        <div className='mt-4'>
          <InputCheckBox
            id={`${uniqueId('confirmation-')}`}
            value={quickOrderConfirmation}
            name="confirmation"
            checked={quickOrderConfirmation}
            label={<span className='text-xs font-quicksand-regular text-black-base-l transform dark:text-black-light-0-d'>Confirm Order</span>}
            onChange={() => {
              dispatch(setQuickOrderSettingsConfirmation(!quickOrderConfirmation))
            }}
            onBlur={formik.handleBlur}
            className='mb-0'
          />
        </div>
      </div>

      <RenderIf isTrue={isNotRealAccount}>
        <Modal onClose={() => setIsNotRealAccount(false)}>
          <ModalExploreExpired
            showClose
            eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
            title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
            description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
            onClose={() => setIsNotRealAccount(false)}
          />
        </Modal>
      </RenderIf>
      
      <RenderIf isTrue={openModalConfirmationAmend}>
        <Modal onClose={() => setOpenModalConfirmationAmend(false)}>
          <ModalConfirmationStockAmend
            isSubmitting={isSubmiting}
            data={formik?.values}
            onSubmit={() => formik.submitForm()}
            onCancel={() => setOpenModalConfirmationAmend(false)}
          />
        </Modal>
      </RenderIf>
    </form>
  );
}

OrderFocusAmendForm.propTypes = {
  buyingPower: PropTypes.number,
  pickPrice: PropTypes.any,
  user: PropTypes.object,
  orderList: PropTypes.array,
  isBursaOpen: PropTypes.bool,
  isSticky: PropTypes.bool,
  initValues: PropTypes.object,
  portFolio: PropTypes.object,
  analyticEventName: PropTypes.string,
};

export default OrderFocusAmendForm;
