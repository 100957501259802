// eslint-disable-next-line react/prop-types
function IconLineChart({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C4.55228 3 5 3.44772 5 4V17C5 18.1046 5.89543 19 7 19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21H7C4.79086 21 3 19.2091 3 17V4C3 3.44772 3.44772 3 4 3Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3162 3.05134C20.8402 3.22599 21.1233 3.79231 20.9487 4.31625L18.972 10.2463C18.2668 12.362 15.5816 12.9958 14.0046 11.4189L12.5811 9.99538C12.0555 9.46973 11.1604 9.68102 10.9253 10.3863L8.94868 16.3163C8.77404 16.8402 8.20772 17.1234 7.68377 16.9487C7.15983 16.7741 6.87667 16.2077 7.05132 15.6838L9.02798 9.7538C9.73322 7.6381 12.4184 7.00422 13.9954 8.58117L15.4189 10.0047C15.9445 10.5303 16.8396 10.319 17.0747 9.6138L19.0513 3.6838C19.226 3.15986 19.7923 2.8767 20.3162 3.05134Z"
      />
    </svg>
  );
}

export default IconLineChart;
