import PropTypes from 'prop-types';
import InputText from 'components/input/input-text';
import { ORDER_COST } from 'constants';
import { find } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetPortfolioListQuery } from 'services/rtk-query/trade';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import { setFastOrderSettingsBySymbol } from 'store/settings';
import { automatedNumberFormat, currency } from 'utils/format';
import RenderIf from 'components/basic/render-if';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { getStampCostBuy } from 'utils/calculation';

function OrderSettingsDesktop({
  symbol,
  price,
  settings = { activeLotIndex: 0, lots: [] },
}) {
  const { activeLotIndex, lots } = settings;

  const dispatch = useDispatch();
  const [editLotIndex, setEditLotIndex] = useState(false);

  const { data: trading, isLoading: loadingBuyingPower } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { data: portFolio, isLoading: loadingPortfolio } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const portfolio = find(portFolio?.stock?.items, { stID: symbol });

  return (
    <div className="flex-none w-[90px] pl-3 sticky right-0 card-widget-gradient pt-2">
      <div className="border-b dark:border-gray-base-d pb-1">
        <h5 className="text-2xs font-quicksand-regular text-gray-light-0-d mb-[2px]">Limit</h5>
        <p className="font-quicksand-semibold text-xs dark:text-black-light-1-d">
          <RenderIf isTrue={loadingBuyingPower}>
            <SkeletonTheme containerClassName="leading-none" width="50%" height="1rem" borderRadius="4px" />
          </RenderIf>

          <RenderIf isTrue={!loadingBuyingPower}>
            {currency(trading?.buyingpower || 0, { removeSymbol: true })}
          </RenderIf>
        </p>
      </div>

      <div className="border-b dark:border-gray-base-d py-1">
        <RenderIf isTrue={loadingBuyingPower}>
          <SkeletonTheme containerClassName="leading-none" width="50%" height="1rem" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!loadingBuyingPower}>
          <p className="font-quicksand-semibold text-xs dark:text-black-light-1-d">
            <small className='text-2xs text-black-light-0-d font-quicksand-regular'>Max Buy</small> <br/>
            {Math.floor(
              (trading?.buyingpower - getStampCostBuy(trading?.buyingpower)) / (price * 100 + ORDER_COST?.buy * price * 100)
            ) || 0}{' '}
            Lot
          </p>
        </RenderIf>
      </div>

      <div className="border-b dark:border-gray-base-d py-1">
        <RenderIf isTrue={loadingPortfolio}>
          <SkeletonTheme containerClassName="leading-none" width="50%" height="1rem" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!loadingPortfolio}>
          <p className="font-quicksand-semibold text-xs dark:text-black-light-1-d">
            <small className='text-2xs text-black-light-0-d font-quicksand-regular'>Max Sell</small> <br/>
            {automatedNumberFormat(portfolio?.net / 100 || 0)} Lot
          </p>
        </RenderIf>
      </div>

      <div className="pt-1">
        <h5 className="text-2xs font-quicksand-regular text-gray-light-0-d">Set Lot</h5>

        <ul className="mt-2">
          {lots?.map((item, key) => (
            <li
              key={key}
              className="hover:opacity-75"
              title={`${key != 0 ? 'Klik 2x untuk edit' : 'Maximal Buy/Sell'}`}
              onClick={() =>
                dispatch(
                  setFastOrderSettingsBySymbol({
                    symbol,
                    settings: { ...settings, activeLotIndex: key }
                  })
                )
              }
              onDoubleClick={() => setEditLotIndex(key)}>
              <InputText
                autoSelect={editLotIndex === key}
                readOnly={editLotIndex !== key || key === 0}
                value={item}
                name={`lot${key}`}
                className={`customize-input-text--fast-order customize-input-text--fast-order-settings ${
                  key === activeLotIndex && 'active'
                } py-[1px]`}
                onChange={(e) => {
                  dispatch(
                    setFastOrderSettingsBySymbol({
                      symbol,
                      settings: {
                        ...settings,
                        lots: lots?.map((a, b) => {
                          if (b === key) return e.target.value;
                          return a;
                        })
                      }
                    })
                  );
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

OrderSettingsDesktop.propTypes = {
  symbol: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  settings: PropTypes.object,
};

export default OrderSettingsDesktop;
