import PropTypes from 'prop-types';

function IconChevronCircle({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 2.66536C5.05416 2.66536 2.66634 5.05318 2.66634 7.9987C2.66634 10.9442 5.05416 13.332 7.99967 13.332C10.9452 13.332 13.333 10.9442 13.333 7.9987C13.333 5.05318 10.9452 2.66536 7.99967 2.66536ZM1.33301 7.9987C1.33301 4.3168 4.31778 1.33203 7.99967 1.33203C11.6816 1.33203 14.6663 4.3168 14.6663 7.9987C14.6663 11.6806 11.6816 14.6654 7.99967 14.6654C4.31778 14.6654 1.33301 11.6806 1.33301 7.9987Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52827 6.86323C5.78862 6.60288 6.21073 6.60288 6.47108 6.86323L7.99967 8.39183L9.52827 6.86323C9.78862 6.60288 10.2107 6.60288 10.4711 6.86323C10.7314 7.12358 10.7314 7.54569 10.4711 7.80604L8.47108 9.80604C8.21073 10.0664 7.78862 10.0664 7.52827 9.80604L5.52827 7.80604C5.26792 7.54569 5.26792 7.12358 5.52827 6.86323Z"
      />
    </svg>
  );
}

IconChevronCircle.propTypes = {
  className: PropTypes.string
};

export default IconChevronCircle;
