import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import InputRange from './input-range';
import IconMinus from 'components/svg/icon-minus';
import IconPlus from 'components/svg/icon-plus';
import IconRotateLeft from 'components/svg/icon-rotate-left';
import IconRotateRight from 'components/svg/icon-rotate-right';
import Card from 'components/basic/card';
import useDarkMode from 'hooks/useDarkMode';

function InputCropImage({
  onSubmit,
  onCancel,
  fileName,
  defaultImage,
  defaultOptions,
  header,
  isSubmitting
}) {
  const { theme } = useDarkMode();
  const ref = useRef(null);

  const [options, setOptions] = useState({
    image: defaultImage,
    ...defaultOptions
  });

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setOptions({ ...options, scale });
  };

  const handleRotate = (e) => {
    const rotate = parseFloat(e.target.value);
    setOptions({ ...options, rotate });
  };

  const handlePositionChange = (position) => {
    setOptions({ ...options, position });
  };

  const handleSubmit = () => {
    if (ref) {
      const canvas = ref.current.getImage();
      canvas.toBlob((blob) => {
        let file = new File([blob], `${fileName}.jpg`, { type: 'image/jpeg' });
        onSubmit(file);
      }, 'image/jpeg');
    }
  };

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  return (
    <Card className="p-4 lg:p-8 lg:w-4/12 max-w-lg lg:min-w-[450px] lg:mx-auto dark:text-black-light-1-d dark:border dark:border-gray-base-d mx-6">
      {header}

      <div className="py-5">
        <AvatarEditor
          ref={ref}
          scale={parseFloat(options.scale)}
          width={options.width}
          height={options.height}
          position={options.position}
          onPositionChange={handlePositionChange}
          rotate={parseFloat(options.rotate)}
          borderRadius={options.width / (100 / options.borderRadius)}
          image={options.image}
          color={theme === 'dark' ? [0, 0, 0, 0.8] : [255, 255, 255, 0.8]}
          className="mx-auto rounded-xl w-full h-auto"
        />

        <h5 className='my-4 font-quicksand-semibold text-sm'>Crop Foto</h5>

        <div className="flex justify-between mt-6 items-center">
          <a
            role="button"
            onClick={() =>setOptions({ ...options, scale: options?.scale - 0.1 })}>
            <IconMinus className="text-main-base-l h-6 w-6" />
          </a>

          <div className="w-full px-5 flex items-center transform -translate-y-1">
            <InputRange
              min={options.allowZoomOut ? '0.1' : '1'}
              max="2"
              step="0.01"
              value={options?.scale}
              className="w-full"
              color="gold"
              onChange={handleScale}
            />
          </div>

          <a
            role="button"
            onClick={() =>setOptions({ ...options, scale: options?.scale + 0.1 })}>
            <IconPlus className="text-main-base-l h-6 w-6" />
          </a>
        </div>

        <div className="flex justify-between mt-6">
          <a
            role="button"
            onClick={() =>setOptions({ ...options, rotate: options?.rotate - 15 })}>
            <IconRotateLeft className="text-main-base-l h-6 w-6" />
          </a>

          <div className="w-full px-5 flex items-center transform -translate-y-1">
            <InputRange
              name="rotate"
              type="range"
              onChange={handleRotate}
              min={-270}
              max="270"
              step="0.01"
              className="w-full"
              value={options?.rotate}
              color="gold"
            />
          </div>

          <a
            role="button"
            onClick={() =>setOptions({ ...options, rotate: options?.rotate + 0.1 })}>
            <IconRotateRight className="text-main-base-l h-6 w-6" />
          </a>
        </div>
      </div>

      <div className="flex justify-end">
        <a
          role="button"
          onClick={onCancel}
          className="cursor-pointer hover:opacity-75 dark:bg-black-base-d flex mt-5 mr-3 px-10 w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden focus:outline-none bg-white text-main-base-l border-2 border-main-base-l">
          Kembali
        </a>

        <a
          role="button"
          onClick={handleSubmit}
          className="cursor-pointer hover:opacity-75 flex mt-5 px-10 w-full items-center justify-center font-quicksand-semibold font-bold text-sm dark:text-black-base-d h-11 rounded-[10px] overflow-hidden focus:outline-none bg-main-base-l text-white">
          {isSubmitting && <Spinner />}
          Simpan
        </a>
      </div>
    </Card>
  );
}

InputCropImage.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultImage: PropTypes.any,
  fileName: PropTypes.string,
  defaultOptions: PropTypes.object,
  header: PropTypes.node,
  isSubmitting: PropTypes.bool,
};

InputCropImage.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  fileName: 'avatar',
  isSubmitting: false,
  loading: false,
  defaultOptions: {
    disableBoundaryChecks: false,
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 350,
    preview: null,
    width: 350,
    height: 350
  },
  header:
    <div className="border-b pb-3 dark:border-gray-base-d">
      <h5 className="text-lg">Atur Foto</h5>
    </div>
};

export default InputCropImage;
