import RenderIf from 'components/basic/render-if';
import PropTypes from 'prop-types';
import { currency } from 'utils/format';
import { ORDER_COST } from 'constants';
import Spinner from 'components/common/spinner';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getStampCostSell } from 'utils/calculation';
import useFocus from 'hooks/useFocus';

function ModalConfirmationStockSell({
  isSubmitting,
  data = {},
  onSubmit = () => {},
  onCancel = () => {}
}) {
  const [refFocus, setSubmitFocus] = useFocus();
  const [refCancelFocus, setCancelFocus] = useFocus();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setSubmitFocus(), 100);
  }, [open]);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div className="max-w-md mx-auto zoom-2">
      <div
        className={`bg-white dark:bg-black-dark-d rounded-lg dark:border dark:border-gray-base-d dark:text-black-light-1-d p-6 z-10 relative transform`}
        style={{ transform: 'translateY(-3px)' }}>
        <div className="text-center pb-2 border-b dark:border-gray-base-d">
          <h5>Mohon cek kembali order kamu</h5>
          <p className="text-xs text-gray-light-0-d font-quicksand-semibold">
            No Order: {data?.orderNumber || data?.clOrdID}
          </p>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Kode Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {data?.code}
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Tipe Transaksi
          </span>
          <span className="flex-grow text-right font-quicksand-bold text-green-base-l">
            Sell
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jenis Order
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {data.OrderType}
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Order Expiry
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {data.expiry}
          </span>
        </div>

        <RenderIf isTrue={data?.expiryDate}>
          <div className="flex my-1 items-center">
            <span className="flex-none text-gray-light-0-d text-sm font-gotham-medium">
              Expiry Date
            </span>
            <span className="flex-grow text-right font-gotham-bold">
              {dayjs(data?.expiryDate).format('DD MMM YYYY')}
            </span>
          </div>
        </RenderIf>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Harga Per Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.price, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jumlah Lot
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.lot, { removeSymbol: true })}
          </span>
        </div>

        <RenderIf isTrue={data?.lot > 50000}>
          <div className="my-1">
            <p className="text-xs p-2 bg-main-light-1-d rounded-md">
              Order diatas 50 ribu Lot akan otomatis dipecah oleh sistem.
            </p>
          </div>
        </RenderIf>

        <RenderIf isTrue={data?.isSplitOrder}>
          <div className="border rounded-md p-3 my-1 dark:border-gray-base-d">
            <div className="flex mb-1 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Split Mode
              </span>
              <span className="flex-grow text-right font-quicksand-bold uppercase">
                {data.splitMode}
              </span>
            </div>

            <div className="flex my-1 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Split Time
              </span>
              <span className="flex-grow text-right font-quicksand-bold">
                {data.splitTime} Waktu
              </span>
            </div>

            <div className="flex mt-1 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Split Order
              </span>
              <span className="flex-grow text-right font-quicksand-bold">
                {data.split.replaceAll(';', ', ')}
              </span>
            </div>
          </div>
        </RenderIf>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Biaya ({ORDER_COST?.sell * 100}%)
          </span>
          <span className="flex-grow text-right font-quicksand-bold text-gray-light-0-d">
            {currency(
              Math.round(ORDER_COST?.sell * data?.price * data?.lot * 100),
              { removeSymbol: true }
            )}
          </span>
        </div>

        <RenderIf isTrue={getStampCostSell(data?.price * data?.lot * 100) > 0}>
          <div className="flex my-1 items-center">
            <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
              Biaya Materai
            </span>
            <span className="flex-grow text-right font-quicksand-bold">
              {currency(Math.ceil(ORDER_COST?.stampCost), {
                removeSymbol: true
              })}
            </span>
          </div>
        </RenderIf>

        <div className="flex my-1 items-center border-y py-2 dark:border-gray-base-d">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jumlah Investasi (-Biaya)
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(
              data?.price * data?.lot * 100 -
                Math.round(ORDER_COST?.sell * data?.price * data?.lot) -
                getStampCostSell(data?.price * data?.lot * 100),
              {
                removeSymbol: true
              }
            )}
          </span>
        </div>

        <div className="pt-3 mt-1">
          <div className="grid grid-cols-12 gap-4">
            <button
              data-qa-id="btnStockBuyFinishConfirmation"
              type="button"
              ref={refCancelFocus}
              onClick={onCancel}
              disabled={isSubmitting}
              onKeyDown={(event) => {
                if (event.key === 'ArrowRight') {
                  event.preventDefault();
                  setSubmitFocus();
                }
              }}
              className="col-span-6 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75">
              Batal
            </button>

            <button
              data-qa-id="btnStockSellSubmitConfirmation"
              type="button"
              onClick={onSubmit}
              ref={refFocus}
              onKeyDown={(event) => {
                if (event.key === 'ArrowLeft') {
                  event.preventDefault();
                  setCancelFocus();
                }
              }}
              disabled={isSubmitting}
              className="col-span-6 bg-green-base-d text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75">
              {isSubmitting && <Spinner />}
              Konfirmasi
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ModalConfirmationStockSell.propTypes = {
  data: PropTypes.object,
  isFinished: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default ModalConfirmationStockSell;
