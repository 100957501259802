import InputSelect from 'components/input/input-select';
import { useEffect, useState } from 'react';

import 'components/input/css/custom/customize-input-select.css';
import { getMonth, getYear } from 'date-fns';
import { orderBy, range } from 'lodash';
import { getQuarterOfYear } from 'utils/formatter/date';
import dayjs from 'dayjs';

function CompareFilter({ defaultPeriods, onChangeFilter = () => {} }) {
  const [periods, setPeriods] = useState(defaultPeriods);
  const [options, setOptions] = useState({ yearly: [], quarterly: [] });

  const handleChangeYear = (_year) => {
    const newPeriods = String(_year) == String(getYear(new Date())) ? getQuarterOfYear(new Date()) :  _year + '12';
    setPeriods(newPeriods)
    onChangeFilter(newPeriods)
  };

  const handleChangeQuarter = (_quarter) => {
    setPeriods(_quarter);
    onChangeFilter(_quarter);
  };

  useEffect(() => {
    let _yearsOptions = [];
    let _quarterlyOptions = [
      {
        id: getYear(dayjs(periods, 'YYYYMM').toDate()) + '03',
        name: 'Q1',
        disabled: dayjs((`01/01/${periods?.substring(0, 4)}`), 'DD/MM/YYYY').valueOf() >= dayjs().valueOf()
      },
      {
        id: getYear(dayjs(periods, 'YYYYMM').toDate()) + '06',
        name: 'Q2',
        disabled: dayjs((`01/01/${periods?.substring(0, 4)}`), 'DD/MM/YYYY').add(3, 'months').valueOf() >= dayjs().valueOf()
      },
      {
        id: getYear(dayjs(periods, 'YYYYMM').toDate()) + '09',
        name: 'Q3',
        disabled: dayjs((`01/01/${periods?.substring(0, 4)}`), 'DD/MM/YYYY').add(6, 'months').valueOf() >= dayjs().valueOf()
      },
      {
        id: getYear(dayjs(periods, 'YYYYMM').toDate()) + '12',
        name: 'Q4',
        disabled: dayjs((`01/01/${periods?.substring(0, 4)}`), 'DD/MM/YYYY').add(9, 'months').valueOf() >= dayjs().valueOf()
      }
    ];

    const years = range(2012, getYear(new Date()) + 1, 1);

    years?.forEach((year) => {
      _yearsOptions.push({
        id: String(year),
        name: String(year)
      })
    });

    setOptions({
      yearly: orderBy(_yearsOptions, ['name'], ['desc']),
      quarterly: _quarterlyOptions
    });
  }, [periods]);

  return (
    <div className="mb-4 dark:text-black-light-1-d">
      <h5 className="font-quicksand-semibold text-sm mb-1">Periods</h5>

      <div className="flex items-center gap-4">
          <div className="max-w-[120px] relative z-10 ml-0">
            <InputSelect
              value={dayjs(periods, 'YYYYMM').format('YYYY')}
              label=""
              className="customize-input-select--small"
              options={options?.yearly}
              onChange={(e) => handleChangeYear(e.target.value)}
            />
          </div>

          <ul className="flex -mx-1 py-2">
            {options?.quarterly?.map((item, key) => (
              <li key={key}>
                <button
                  onClick={() => handleChangeQuarter(item?.id)}
                  disabled={item?.disabled}
                  type="button"
                  className={`mx-1 border disabled:bg-gray-light-l dark:disabled:bg-gray-base-d disabled:text-gray-light-0-d disabled:border-gray-base-l dark:disabled:border-gray-base-d dark:border-black-light-1-l text-xs font-quicksand-semibold px-3 py-[6px] rounded-xl transition-all ease-linear duration-150 hover:opacity-75 ${
                    item?.id === periods
                      ? 'bg-main-base-l text-white dark:text-black-base-d !border-main-base-l'
                      : 'card-widget-gradient dark:text-black-light-1-d'
                  }`}>
                  {item?.name}
                </button>
              </li>
            ))}
          </ul>
      </div>
    </div>
  );
}



export default CompareFilter;
