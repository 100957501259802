import RenderIf from 'components/basic/render-if';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import TVFilter from '../../../components/chart/tradingview/_partials/tv-filter';
import TVHeader from '../../../components/chart/tradingview/_partials/tv-header';
import TVLegend from '../../../components/chart/tradingview/_partials/tv-legend';
import { triggerSegmentEvent } from 'lib/segment';
import TVRealtimeChartSimple from '../../../components/chart/tradingview/_partials/tv-realtime-chart-simple';
import getAllFilterChart from 'utils/chart/filter';
import TWOrderBook from 'pages/trading/_partials/tw-orderbook';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

window.countRender = {};

function TWIntradayChart(props) {
  const { customNavigate } = useCustomNavigate();
  const filters = getAllFilterChart();
  const [filter, setFilter] = useState(props?.defaultFilter);

  useEffect(() => {
    setFilter(props?.defaultFilter);
  }, [props?.defaultFilter]);

  const ButtonOrder = () => {
    return (
      <div className="text-2xs px-3 font-quicksand-semibold mt-2">
        <div className="grid grid-cols-2 gap-2">
          <button
            title="Simple Buy"
            onClick={() => {
              customNavigate({ query: { 'simple-order': props?.symbol } });
              triggerSegmentEvent({ event: props?.simpleBuyAnalyticEventName });
            }}
            className="h-full hover:opacity-75 text-center p-2 block w-full hover:bg-opacity-75 transition-all ease-in-out duration-150 text-main-base-l border border-main-base-l overflow-hidden rounded-lg">
            Simple Order
          </button>
          <button
            title="Multi Buy"
            onClick={() => {
              customNavigate({ query: { 'fast-order': props?.symbol } });
              triggerSegmentEvent({ event: props?.multiBuyAnalyticEventName });
            }}
            className="h-full text-center p-2 block w-full border-r hover:bg-opacity-75 transition-all ease-in-out duration-150 text-main-base-l border border-main-base-l rounded-lg">
            Fast Order
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`h-full relative ${
        !props?.isCardLess && 'card-widget-gradient rounded-xl '
      } ${props?.className}`}>
      <div>
        <RenderIf isTrue={props?.views?.customHeader}>
          <TVHeader
            showLogo
            advancedChart
            showStockDetail={props?.showStockDetail}
            type={props?.typeHeader}
            showPriceOnly={props?.showPriceOnly}
            symbol={props?.symbol}
            onChangeSymbol={(newSymbol) => props?.onChangeSymbol(newSymbol)}
            onClickAdvancedChart={(symbol) =>
              props?.onClickAdvancedChart(symbol)
            }
            className={`${!props?.views?.customFilter && 'pb-0 pt-0'}`}
          />
        </RenderIf>

        <RenderIf isTrue={props?.views?.chart}>
          <RenderIf isTrue={props?.views?.customFilter}>
            <TVFilter
              className={`${props?.typeHeader === 'LONG' ? '-mt-1' : '-mt-3'}`}
              size={props?.filterSize}
              isLoading={false}
              defaultFilter={filter}
              handleChartFilter={(fltr) => setFilter(fltr?.text)}
            />
          </RenderIf>

          <RenderIf
            isTrue={props?.views?.customLegend && !props?.views?.orderBook}>
            <TVLegend symbol={props?.symbol} />
          </RenderIf>

          <div
            className="grid relative overflow-hidden min-h-[200px]"
            style={{ height: props?.containerHeight }}>
            <TVRealtimeChartSimple
              isRerender={props?.isRerender}
              filter={filter}
              idWidget={props?.idWidget}
              symbol={props?.symbol}
              interval={
                filter === '1D'
                  ? 1
                  : filters?.find((item) => item?.text === filter)?.resolution
              }
              disabled_features={[
                'left_toolbar',
                'header_widget',
                'control_bar',
                'timeframes_toolbar',
                'legend_widget',
                'use_localstorage_for_settings'
              ]}
            />
          </div>
        </RenderIf>
      </div>

      <div>
        <RenderIf isTrue={props?.views?.buyOrSell}>
          <ButtonOrder />
        </RenderIf>

        <RenderIf isTrue={props?.views?.orderBook}>
          <div className="pt-2 px-3 h-full">
            <TWOrderBook
              isExpandRow
              rows={props?.rows}
              maxHeight={props?.maxHeight}
              isShowChartOrderbook
              symbol={props?.symbol}
              widgetSettings={props?.widgetSettings}
              onChangeWidgetSettings={props?.onChangeWidgetSettings}
              onClickPrice={(price) =>
                customNavigate({
                  query: { 'simple-order': props?.symbol, price }
                })
              }
            />
          </div>
        </RenderIf>
      </div>
    </div>
  );
}

TWIntradayChart.propTypes = {
  className: PropTypes.string,
  isCardLess: PropTypes.bool,
  isRerender: PropTypes.bool,
  views: PropTypes.object,
  defaultFilter: PropTypes.string,
  onClickAdvancedChart: PropTypes.func,
  onChangeSymbol: PropTypes.func,
  onClickSimpleBuy: PropTypes.func,
  onClickSimpleSell: PropTypes.func,
  onClickFastBuy: PropTypes.func,
  onClickFastSell: PropTypes.func,
  widgetSettings: PropTypes.object,
  onChangeWidgetSettings: PropTypes.func,
  filterSize: PropTypes.string,
  typeHeader: PropTypes.string,
  showStockDetail: PropTypes.bool,
  containerHeight: PropTypes.any,
  symbol: PropTypes.string,
  showPriceOnly: PropTypes.bool,
  simpleBuyAnalyticEventName: PropTypes.string,
  simpleSellAnalyticEventName: PropTypes.string,
  multiBuyAnalyticEventName: PropTypes.string,
  multiSellAnalyticEventName: PropTypes.string,
  simpleBuyAnalyticSubmitEventName: PropTypes.string,
  multiBuyAnalyticSubmitEventName: PropTypes.string,
  simpleSellAnalyticSubmitEventName: PropTypes.string,
  multiSellAnalyticSubmitEventName: PropTypes.string
};

TWIntradayChart.defaultProps = {
  className: '',
  idWidget: 'global',
  isCardLess: false,
  isRerender: false,
  showPriceOnly: false,
  views: {
    orderBook: true,
    buyOrSell: true,
    customFilter: true,
    customLegend: true,
    customHeader: true,
    chart: true
  },
  defaultFilter: '1D',
  filterSize: 'small',
  onClickAdvancedChart: () => {},
  onChangeSymbol: () => {},
  onClickSimpleBuy: () => {},
  onClickSimpleSell: () => {},
  onClickFastBuy: () => {},
  onClickFastSell: () => {},
  onChangeWidgetSettings: () => {},
  widgetSettings: {},
  containerHeight: '260px',
  symbol: 'BBCA',
  typeHeader: '',
  showStockDetail: true,
  simpleBuyAnalyticEventName: 'TR Buy Stock from Simple Buy',
  simpleSellAnalyticEventName: 'TR Sell Stock from Simple Buy',
  multiBuyAnalyticEventName: 'TR Multi Buy Clicked',
  multiSellAnalyticEventName: 'TR Multi Sell Clicked',
  simpleBuyAnalyticSubmitEventName:
    'TR Buy Stock from Simple Buy (Stock Screener)',
  multiBuyAnalyticSubmitEventName:
    'TR Buy Stock from Multi Buy (Stock Screener)',
  simpleSellAnalyticSubmitEventName:
    'TR Sell Stock from Simple Sell (Stock Screener)',
  multiSellAnalyticSubmitEventName:
    'TR Sell Stock from Multi Sell (Stock Screener)'
};

export default TWIntradayChart;
