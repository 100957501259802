import SimpleSpinner from 'components/common/spinner/spinner';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { RDNStepInitializationValidation } from './rdn-step-initialization.validation';
import { FocusError } from 'focus-formik-error';
import { useGetUserProfileQuery } from 'services/rtk-query/user';
import Card from 'components/basic/card';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import RenderIf from 'components/basic/render-if';
import { useNavigate } from 'react-router-dom';
import { triggerSegmentEvent, triggerSegmentIdentify } from 'lib/segment';
import BenefitPinaTrade from '../benefit-pina-trade';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function RDNStepInitialization() {
  const navigate = useNavigate();
  const { user } = useBrowserTabAuth();
  const id = user?.id;
  const { data: userDetail } = useGetUserProfileQuery(null, {
    refetchOnMountOrArgChange: true
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      mode: ''
    },
    validateOnMount: false,
    validationSchema: RDNStepInitializationValidation,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setTimeout(() => {
        if (values.mode === 'CREATE_RDN') {
          triggerSegmentIdentify({
            userId: id,
            properties: {
              membershipType: 'Create RDN'
            }
          });

          triggerSegmentEvent({
            event: 'TR User Select Membership Type',
            properties: {
              type: 'Create RDN'
            }
          });
          navigate('/onboarding/informasi-pribadi');
        } else {
          triggerSegmentIdentify({
            userId: id,
            properties: {
              membershipType: 'Free Trial'
            }
          });

          triggerSegmentEvent({
            event: 'TR User Select Membership Type',
            properties: {
              type: 'Free Trial'
            }
          });
          navigate('/');
        }
        setIsSubmitting(false);
      }, 500);
    }
  });

  useEffect(() => {
    if (userDetail && userDetail?.isKtpVerified) navigate('/onboarding/informasi-pribadi');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail])

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:py-16 text-white relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto">

          <h1 className="font-quicksand-bold text-[28px] lg:text-[52px] leading-[32px] lg:leading-[60px] mb-3 text-white">
            Buat RDN Sekarang atau Mulai explore Pina Trade!
          </h1>

          <BenefitPinaTrade />
        </div>
      </div>

      <div className="p-0 lg:p-16">
        <Card className="px-6 lg:px-8 w-full lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto py-8 mb-24 lg:mb-0">
          <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <FocusError formik={formik} />

            <button
              type="button"
              onClick={() => {
                formik.setFieldValue('mode', 'CREATE_RDN');
              }}
              className="border rounded-lg p-3 mb-4 text-left hover:opacity-75 transition-all ease-linear duration-150">
              <div className="flex mb-1">
                <span className="border-2 mt-1 border-main-gold-d h-4 w-4 rounded-full flex items-center justify-center mr-2 flex-none">
                  {formik.values.mode === 'CREATE_RDN' && (
                    <span className="h-2 w-2 bg-main-gold-d rounded-full"></span>
                  )}
                </span>

                <h5 className="text-main-gold-d flex-auto">Buat RDN Sekarang</h5>
              </div>

              <p className="text-gray-light-0-d text-sm">
                Buka rekening investasi sekarang dan dapatkan benefit menjadi
                member PINA Trade
              </p>
            </button>

            <button
              type="button"
              onClick={() => {
                formik.setFieldValue('mode', 'SKIP_RDN');
              }}
              className="border rounded-lg p-3 mb-2 text-left hover:opacity-75 transition-all ease-linear duration-150">
              <div className="flex mb-1 items-center">
                <span className="border-2 mt-1 border-main-gold-d h-4 w-4 rounded-full flex items-center justify-center mr-2 flex-none">
                  {formik.values.mode === 'SKIP_RDN' && (
                    <span className="h-2 w-2 bg-main-gold-d rounded-full"></span>
                  )}
                </span>

                <h5 className="text-main-gold-d flex-auto">
                  Mulai explore PINA Trade{' '}
                  <br className='lg:hidden'/>
                  <span className="font-quicksand-regular">
                    (14 hari trial)
                  </span>
                </h5>
              </div>

              <p className="text-gray-light-0-d text-sm">
                Mulai jelajahi Pina Trade nikmati pengalaman trading yang mudah
                dan canggih dengan berbagai fitur* yang kami sediakan selama 14
                hari.
              </p>
            </button>

            <p className="text-gray-light-0-d text-xs">
              *Sebagian fitur tidak tersedia jika RDN belum dibuat
            </p>

            <RenderIf isTrue={!formik?.isValid}>
              <div className="font-quicksand-medium flex items-center text-red-base-l text-xs rounded-xl my-3">
                {Object.keys(formik?.errors)?.map((key, idx) => (
                  <RenderIf key={key} isTrue={idx === 0}>
                    <span
                      key={key}
                      className="flex items-center w-full flex-auto">
                      {idx === 0 && formik?.errors[key]}
                    </span>
                  </RenderIf>
                ))}
              </div>
            </RenderIf>

            <div className="lg:mt-48 fixed lg:relative w-full bottom-0 left-0 p-6 lg:p-0 bg-white border-t lg:border-none">
              <div className="-mx-2 flex justify-center">
                <button
                  id="btnSubmit"
                  type="submit"
                  disabled={!formik.isValid || isSubmitting}
                  className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 flex px-10 mx-2 w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden focus:outline-none ${
                    formik.isValid
                      ? 'bg-main-gold-d text-white'
                      : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                  }`}>
                  {isSubmitting && <SimpleSpinner />}
                  Selanjutnya
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default RDNStepInitialization;
