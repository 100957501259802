import PropTypes from 'prop-types';
import Card from 'components/basic/card';

import MascotFirstTimeRDN from 'assets/images/mascot/mascot-first-time-rdn.jpeg';
import { useGetUserProfileQuery } from 'services/rtk-query/user';
import { formatAccountNumber } from 'utils/formatter/bank';

function ModalFirstTimeRDN({ onClose = () => {} }) {
  const { data: userDetail } = useGetUserProfileQuery();

  return (
    <Card className="p-7 max-w-[400px] md:mx-auto relative mx-6 dark:border dark:border-gray-base-d dark:text-black-light-1-d">
      <div className="text-center">
        <img
          src={MascotFirstTimeRDN}
          alt="first time rdn"
          className="w-full h-auto aspect-square mb-4"
        />

        <p className="text-gray-light-0-d text-base mb-2">Selamat</p>
        <h5 className="text-2xl text-center font-quicksand-semibold">
          Proses RDN kamu telah selesai
        </h5>

        <p className="text-gray-light-0-d text-base mb-2">
          Untuk mulai berinvestasi kamu bisa transfer dana ke rekening kamu di
        </p>

        <h5 className="font-quicksand-semibold text-base">
          Bank BNI : (009) {formatAccountNumber(userDetail?.rdnbankAccountNo)}
        </h5>
      </div>

      <button
        type="button"
        onClick={onClose}
        className="px-4 py-3 dark:text-black-base-d text-center text-white bg-main-base-l rounded-xl w-full mt-6 text-sm hover:opacity-75 transition-all ease-in-out duration-150 font-quicksand-semibold">
        Mulai Menjelejah PINA Trade
      </button>
    </Card>
  );
}

ModalFirstTimeRDN.propTypes = {
  onClose: PropTypes.func
};

export default ModalFirstTimeRDN;
