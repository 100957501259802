import { useNavigate } from "react-router-dom";

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen min-w-full flex flex-col items-center justify-center">
      <h1 className="font-quicksand-regular font-normal text-lg md:text-xl text-main-base-l">
        <span className="font-bold">404</span>: Halaman Tidak Ditemukan
      </h1>

      <button
        type="button"
        className="bg-main-base-l font-quicksand-semibold font-bold text-sm px-4 py-2 text-white rounded-lg overflow-hidden my-2 hover:opacity-75 transition-all ease-linear duration-150"
        onClick={() => navigate("/", { replace: true })}
      >
        Halaman Utama
      </button>
    </div>
  )
}

export default Page404