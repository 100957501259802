import PropTypes from 'prop-types';
import TVHeader from 'components/chart/tradingview/_partials/tv-header';
import OrderForm from './_partials/order-form';
import { useDispatch } from 'react-redux';
import { invalidateAllOrder, invalidateIntraDataOrderBookByCode } from 'services/rtk-query/trade';
import { useEffect, useState } from 'react';
import IconClose from 'components/svg/icon-close';
import RenderIf from 'components/basic/render-if';
import TVHeaderMobile from './_partials/tv-header-mobile';
import { isMobile } from 'react-device-detect';
import { invalidateStockByCode } from 'services/rtk-query/stock';

function FastOrderForm({ symbol, isInlineForm, onChangeSymbol = () => {}, onCancel = () => {}, onSuccess = () => {}, onClose = () => {}, advancedChart = true, showStockDetail = true }) {
  const dispatch = useDispatch();
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [tempSymbol, setTempSymbol] = useState(symbol);

  const handleChangeSymbol = (newSymbol) => {
    setTempSymbol(newSymbol);
    onChangeSymbol(newSymbol);
  }

  useEffect(() => {
    setTempSymbol(symbol);
  }, [symbol]);

  return (
    <div className={`${!isInlineForm && 'py-5'}`}>
      <div className={`lg:mx-auto relative ${isInlineForm ? 'px-3' : 'px-4 max-w-2xl lg:px-6 card-widget-gradient py-8 pt-10 rounded-2xl dark:border dark:border-gray-base-d'}`}>
        <div className='absolute right-3 top-3 flex -mx-1 items-center'>
          <RenderIf isTrue={!isInlineForm}>
            <button
              onClick={onClose}
              type="button"
              className="hover:text-main-base-l mx-1 dark:text-main-base-l hover:opacity-75">
              <IconClose />
            </button>
          </RenderIf>
        </div>

        <div className="flex justify-end items-center">
          <TVHeader
            className="py-2 border-b dark:border-gray-base-d flex-auto pl-0"
            symbol={tempSymbol}
            onChangeSymbol={handleChangeSymbol}
            type="LONG"
            showLogo
            advancedChart={!isMobile && advancedChart}
            showCompare={!isMobile}
            showWatchlist={!isMobile}
            showStockDetail={!isMobile && showStockDetail}
            showPriceAlert={!isMobile}
          />

          <RenderIf isTrue={!isInlineForm}>
            <div className="flex-none -mt-2 pl-4 border-l dark:border-gray-base-d h-full border-b py-3 pb-[11px] lg:pb-[13px] mr-0 ml-auto w-[91px]">
              <button
                type="button"
                disabled={isRefreshed}
                onClick={() => {
                  setIsRefreshed(true);

                  setTimeout(() => {
                    dispatch(invalidateAllOrder());
                    dispatch(invalidateStockByCode(tempSymbol));
                    dispatch(invalidateIntraDataOrderBookByCode(tempSymbol));
                    setIsRefreshed(false);
                  }, 500);
                }}
                className="text-main-base-l border-main-base-l border px-2 py-1 rounded-md text-xs hover:opacity-75 transition-all ease-linear duration-100 font-quicksand-semibold disabled:bg-gray-light-l disabled:text-gray-light-0-d disabled:border-gray-light-0-d">
                Refresh
              </button>
            </div>
          </RenderIf>
        </div>

        <TVHeaderMobile symbol={tempSymbol} />
        <OrderForm symbol={tempSymbol} onCancel={onCancel} onSuccess={onSuccess} />
      </div>
    </div>
  );
}

FastOrderForm.propTypes = {
  symbol: PropTypes.string,
  isInlineForm: PropTypes.bool,
  onChangeSymbol: PropTypes.func,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default FastOrderForm;
