import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { currency } from 'utils/format';
import Spinner from 'components/common/spinner';
import useFocus from 'hooks/useFocus';

function ModalConfirmationStockWithdraw({
  isSubmitting,
  data = {},
  onSubmit = () => {},
  onCancel = () => {}
}) {
  const [refFocus, setSubmitFocus] = useFocus();
  const [refCancelFocus, setCancelFocus] = useFocus();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setSubmitFocus(), 100);
  }, [open]);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div className="max-w-md mx-auto zoom-2">
      <div
        className="bg-white dark:bg-black-dark-d rounded-lg dark:border dark:border-gray-base-d dark:text-black-light-1-d p-6 z-10 relative transform"
        style={{ transform: 'translateY(-3px)' }}>
        <div className="text-center pb-2 border-b dark:border-gray-base-d">
          <h5>Mohon cek kembali order kamu</h5>
          <p className="text-xs text-gray-light-0-d font-quicksand-semibold">
            No Order: {data?.orderNumber}
          </p>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Kode Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {data?.code}
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Tipe Transaksi
          </span>
          <span className="flex-grow text-right font-quicksand-bold text-orange-2">
            Withdraw
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Harga Per Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.price || 0, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jumlah Lot
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.lot || 0, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex my-1 items-center border-y py-2 dark:border-gray-base-d">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jumlah Investasi
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.price * data?.lot * 100, { removeSymbol: true })}
          </span>
        </div>

        <div className="pt-3 mt-1">
          <div className="grid grid-cols-12 gap-4">
            <button
              data-qa-id="btnStockBuyFinishConfirmation"
              type="button"
              ref={refCancelFocus}
              onClick={onCancel}
              disabled={isSubmitting}
              onKeyDown={(event) => {
                if (event.key === 'ArrowRight') {
                  event.preventDefault();
                  setSubmitFocus();
                }
              }}
              className="col-span-4 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75">
              Batal
            </button>

            <button
              type="button"
              ref={refFocus}
              onKeyDown={(event) => {
                if (event.key === 'ArrowLeft') {
                  event.preventDefault();
                  setCancelFocus();
                }
              }}
              onClick={onSubmit}
              disabled={isSubmitting}
              className={`col-span-8 bg-orange-2 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75`}>
              {isSubmitting && <Spinner />}
              Withdraw
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ModalConfirmationStockWithdraw.propTypes = {
  data: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default ModalConfirmationStockWithdraw;
