import { object, string } from 'yup';

export const ResetPasswordWithOTPSchema = object().shape({
  otp: string()
    .min(6, 'OTP minimal 6 karakter')
    .required('OTP tidak boleh kosong'),
  newPassword: string()
    .min(6, 'Password minimal 6 karakter')
    .required('Password tidak boleh kosong'),
  confirmPassword: string()
    .min(6, 'Password minimal 6 karakter')
    .test(
      'confirmPassword',
      'Konfirmasi Password tidak sesuai',
      (value, { parent }) => parent?.newPassword === value
    )
    .required('Password tidak boleh kosong')
});
