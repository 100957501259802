import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';

export default function Draggable({
  children,
  id,
  zIndex = 0,
  defaultState = {
    width: 200,
    height: 200,
    x: 0,
    y: 0
  },
  onChangeWidgetState = () => {},
  onChangeWidgetZIndex = () => {},
}) {
  const refEl = useRef();
  const refElBlocker = useRef();
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    setState(defaultState);
  }, [defaultState]);

  const toggleActiveDraggable = (opacity = true) => {
    onChangeWidgetZIndex()
    if (opacity) {
      refEl?.current?.classList?.add('opacity-50');
      refElBlocker?.current?.classList?.remove('hidden');
    }
  };

  return (
    <div ref={refEl} id={id} className="relative draggable-pina" style={{ zIndex: zIndex }} onClick={() => toggleActiveDraggable(false)}>
      <Rnd
        className="card-widget-gradient rounded-md border border-black-light-0-d/30"
        bounds=".container-workspace"
        dragGrid={[5, 5]}
        size={{ width: state.width, height: state.height }}
        position={{ x: state.x, y: state.y }}
        resizeGrid={[5, 5]}
        onDragStart={() => toggleActiveDraggable()}
        allowAnyClick={false}
        dragHandleClassName='drag-handler-widget'
        onDragStop={(e, d) => {
          let tempState = { ...state, x: d.x, y: d.y };

          if (!isEqual(tempState, state)) {
            setState(tempState);
            onChangeWidgetState(tempState);
          }

          refEl?.current?.classList?.remove('opacity-50');
          refElBlocker?.current?.classList?.add('hidden');
        }}
        onResizeStart={() => toggleActiveDraggable()}
        onResizeStop={(e, direction, ref, delta, position) => {
          let tempState = {
            ...state,
            width: ref.style.width,
            height: ref.style.height,
            ...position
          };

          if (!isEqual(tempState, state)) {
            setState(tempState);
            onChangeWidgetState(tempState);
          }

          refEl?.current?.classList?.remove('opacity-50');
          refElBlocker?.current?.classList?.add('hidden');
        }}>
        <div className="card-widget-gradient rounded-md h-full relative">
          <span ref={refElBlocker} className='absolute h-[calc(100%-35px)] bottom-0 left-0 w-full z-10 hidden'></span>
          {children}
        </div>
      </Rnd>
    </div>
  );
}
