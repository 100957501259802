import { automatedNumberFormat } from 'utils/format';
import SectionDeposit from './deposit.section';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import SectionWithdrawHistory from './withdraw-history.section';
import { useNavigate, useParams } from 'react-router-dom';
import SkeletonTheme from 'components/basic/skeleton-theme';
import IconHistory from 'components/svg/icon-history';
import IconDeposit from 'components/svg/icon-deposit';
import CashWithdrawForm from 'components/form/cash-withdraw-form';
import IconWithdraw from 'components/svg/icon-withdraw';
import IconWallet from 'components/svg/icon-wallet';
import IconMoney from 'components/svg/icon-money';
import IconATM from 'components/svg/icon-atm';
import { isEmpty } from 'lodash';
import ImageEmptyState from 'assets/images/mascot/mascot-empty-orderlist.png';
import RenderIf from 'components/basic/render-if';
import useDarkMode from 'hooks/useDarkMode';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function SaldoSummary({ trading, isLoading, slug }) {
  if (!['deposit', 'cash-withdrawal'].includes(slug)) return <></>;
  return (
    <div className="grid gap-4 lg:gap-0 lg:grid-cols-3 card-widget-gradient p-4 rounded-xl mb-4">
      <RenderIf isTrue={slug === 'deposit'}>
        <div className="flex pl-0 lg:px-4 lg:border-r dark:border-gray-base-d">
          <div className="mr-2">
            <span className="bg-main-base-l rounded-full p-2 block text-white">
              <IconWallet className="h-4 w-4 lg:h-6 lg:w-6" />
            </span>
          </div>
          <div>
            <p className="text-gray-light-0-d text-xs">Saldo Rekening (Rp)</p>
            <h5 className="text-base lg:text-2xl font-quicksand-semibold">
              {isLoading ? (
                <SkeletonTheme
                  height="1.2rem"
                  width="100px"
                  borderRadius="8px"
                />
              ) : (
                automatedNumberFormat(Math.floor(trading?.cashAvailable) || 0, 999999999999999)
              )}
            </h5>
          </div>
        </div>

        <div className="flex lg:px-4">
          <div className="mr-2">
            <span className="bg-main-base-l rounded-full p-2 block text-white">
              <IconMoney className="h-4 w-4 lg:h-6 lg:w-6" />
            </span>
          </div>
          <div>
            <p className="text-gray-light-0-d text-xs">Saldo Dalam Proses Settlement (Rp)</p>
            <h5 className="text-base lg:text-2xl font-quicksand-semibold">
              {isLoading ? (
                <SkeletonTheme
                  height="1.2rem"
                  width="100px"
                  borderRadius="8px"
                />
              ) : automatedNumberFormat(
                Math.abs(trading?.cashAvailable - trading?.buyingpower) || 0, 999999999999999
              )}
            </h5>
          </div>
        </div>
      </RenderIf>

      <RenderIf isTrue={slug === 'cash-withdrawal'}>
        <div className="flex pl-0 lg:px-4 lg:border-r dark:border-gray-base-d">
          <div className="mr-2">
            <span className="bg-main-base-l rounded-full p-2 block text-white">
              <IconWallet className="h-4 w-4 lg:h-6 lg:w-6" />
            </span>
          </div>
          <div>
            <p className="text-gray-light-0-d text-xs">Saldo Bisa Ditarik (Rp)</p>
            <h5 className="text-base lg:text-2xl font-quicksand-semibold">
              {isLoading ? (
                <SkeletonTheme
                  height="1.2rem"
                  width="100px"
                  borderRadius="8px"
                />
              ) : (
                automatedNumberFormat(Math.floor(trading?.withdrawableCash) || 0, 999999999999999)
              )}
            </h5>
          </div>
        </div>

        <div className="flex lg:px-4 dark:border-gray-base-d">
          <div className="mr-2">
            <span className="bg-main-base-l rounded-full p-2 block text-white">
              <IconATM className="h-4 w-4 lg:h-6 lg:w-6" />
            </span>
          </div>
          <div>
            <p className="text-gray-light-0-d text-xs">
              Saldo Dalam Proses Settlement (Rp)
            </p>
            <h5 className="text-base lg:text-2xl font-quicksand-semibold">
              {isLoading ? (
                <SkeletonTheme
                  height="1.2rem"
                  width="100px"
                  borderRadius="8px"
                />
              ) : (
                automatedNumberFormat(
                  Math.floor(Math.abs(trading?.cashAvailable - trading?.withdrawableCash)) || 0, 999999999999999
                )
              )}
            </h5>
          </div>
        </div>
      </RenderIf>
    </div>
  );
}

function SectionProfileSaldo() {
  const { slug } = useParams();
  const { user } = useBrowserTabAuth();
  const { removeDarkMode } = useDarkMode();
  const navigate = useNavigate();
  const { data: trading, isLoading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });

  return (
    <div className="dark:text-black-light-1-d">
      <RenderIf
        isTrue={
          !isEmpty(trading) || (user?.realAccountActive && !trading?.isTopup)
        }>
        <ul className="font-quicksand-semibold grid gap-4 lg:gap-0 lg:flex -mx-2 text-xs lg:text-sm mb-4">
          <li className="mx-2">
            <button
              type="button"
              onClick={() => navigate('/profile/deposit')}
              className={`group py-[8px] lg:py-[10px] px-4 flex border rounded-xl hover:bg-main-base-l hover:text-white dark:hover:text-main-base-d transition-all ease-in-out duration-200 ${
                slug === 'deposit'
                  ? 'bg-main-base-l text-white dark:!text-black-base-d dark:border-main-base-l'
                  : 'bg-gray-light-l border-main-base-l dark:bg-black-base-d dark:text-black-light-1-d'
              }`}>
              <IconDeposit
                className={`mr-2 flex-none group-hover:text-white dark:group-hover:text-main-base-d ${
                  slug === 'deposit'
                    ? 'dark:!text-black-base-d'
                    : 'dark:text-main-base-l'
                }`}
              />
              Top-up Saldo
            </button>
          </li>
          <li className="mx-2">
            <button
              type="button"
              onClick={() => navigate('/profile/cash-withdrawal')}
              className={`group py-[8px] lg:py-[10px] px-4 flex border rounded-xl hover:bg-main-base-l hover:text-white dark:hover:text-main-base-d transition-all ease-in-out duration-200 ${
                slug === 'cash-withdrawal'
                  ? 'bg-main-base-l text-white dark:!text-black-base-d dark:border-main-base-l'
                  : 'bg-gray-light-l border-main-base-l dark:bg-black-base-d dark:text-black-light-1-d'
              }`}>
              <IconWithdraw
                className={`mr-2 flex-none group-hover:text-white dark:group-hover:text-main-base-d ${
                  slug === 'cash-withdrawal'
                    ? 'dark:!text-black-base-d'
                    : 'dark:text-main-base-l'
                }`}
              />
              Tarik Saldo
            </button>
          </li>
          <li className="mx-2">
            <button
              type="button"
              onClick={() => navigate('/profile/cash-history')}
              className={`group py-[8px] lg:py-[10px] px-4 flex border rounded-xl hover:bg-main-base-l hover:text-white dark:hover:text-main-base-d transition-all ease-in-out duration-200 ${
                slug === 'cash-history'
                  ? 'bg-main-base-l text-white dark:!text-black-base-d dark:border-main-base-l'
                  : 'bg-gray-light-l border-main-base-l dark:bg-black-base-d dark:text-black-light-1-d'
              }`}>
              <IconHistory
                className={`mr-2 flex-none group-hover:text-white dark:group-hover:text-main-base-d ${
                  slug === 'cash-history'
                    ? 'dark:!text-black-base-d'
                    : 'dark:text-main-base-l'
                }`}
              />
              Riwayat Saldo Rekening
            </button>
          </li>
        </ul>

        <SaldoSummary trading={trading} isLoading={isLoading} slug={slug} />

        {slug === 'deposit' && <SectionDeposit />}
        {slug === 'cash-withdrawal' && <CashWithdrawForm />}
        {slug === 'cash-history' && <SectionWithdrawHistory />}
      </RenderIf>

      <RenderIf isTrue={isEmpty(trading) && !user?.realAccountActive}>
        <SaldoSummary trading={trading} isLoading={isLoading} slug={slug} />

        <div className="w-full flex items-center justify-center min-h-[300px] card-widget-gradient rounded-lg dark:text-black-light-1-d">
          <div className="text-center p-10">
            <img
              src={ImageEmptyState}
              alt="empty"
              className="h-14 w-14 mx-auto mb-4"
            />

            <h2 className="text-2xl mb-1 font-quicksand-semibold">
              Anda belum mempunyai RDN
            </h2>

            <button
              title="Tambah Workspace Baru"
              type="button"
              onClick={() => {
                navigate('/onboarding/phone-number');
                removeDarkMode();
              }}
              className="mt-6 p-3 bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d w-64 mx-auto hover:bg-opacity-75 disabled:bg-opacity-100 text-white font-quicksand-semibold text-sm flex items-center rounded-lg justify-center hover:opacity-75 transition-all ease-in-out duration-200">
              Buat RDN Sekarang
            </button>
          </div>
        </div>
      </RenderIf>
    </div>
  );
}

export default SectionProfileSaldo;
