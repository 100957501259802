import PropTypes from 'prop-types';

function IconFilter({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9952 3.33333H4.00189C3.43417 3.33333 3.12625 3.99766 3.49318 4.43088L6.03507 7.43194C6.44301 7.91356 6.66689 8.52428 6.66689 9.15545V11.9985C6.66689 12.4847 7.17049 12.8074 7.61216 12.6042L8.94549 11.9909C9.18201 11.8821 9.33356 11.6456 9.33356 11.3852V9.15501C9.33356 8.52437 9.55706 7.91414 9.96437 7.43268L12.5041 4.43058C12.8707 3.99729 12.5627 3.33333 11.9952 3.33333ZM4.00189 2H11.9952C13.6978 2 14.6218 3.99188 13.5221 5.29175L10.9823 8.29384C10.7786 8.53457 10.6669 8.83969 10.6669 9.15501V11.3852C10.6669 12.1662 10.2122 12.8758 9.5027 13.2022L8.16937 13.8155C6.84435 14.425 5.33356 13.457 5.33356 11.9985V9.15545C5.33356 8.83986 5.22162 8.53451 5.01765 8.29369L2.47576 5.29264C1.37496 3.99299 2.29871 2 4.00189 2Z"
      />
    </svg>
  );
}

IconFilter.propTypes = {
  className: PropTypes.string
};

export default IconFilter;
