import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Card from 'components/basic/card';
import InputCounter from 'components/input/input-counter';
import TVHeader from 'components/chart/tradingview/_partials/tv-header';
import InputSelect from 'components/input/input-select';
import { useEffect, useState } from 'react';
import { priceAlertSchema } from './price-alert.validation';
import RenderIf from 'components/basic/render-if';
import { useGetStockByCodeQuery } from 'services/rtk-query/stock';
import {
  useCreatePriceAlertMutation,
  useUpdatePriceAlertByIdMutation
} from 'services/rtk-query/price-alert';
import { isEmpty } from 'lodash';
import IconWarning from 'components/svg/icon-warning';
import IconClose from 'components/svg/icon-close';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useToastr from 'hooks/useToastr';
import { marginPlusShare } from 'utils/formula/margin';

function PriceAlertForm({ symbol, initialValues = {}, onSuccess = () => {}, onClose = () => {}}) {
  const [tempSymbol, setTempSymbol] = useState(symbol);
  const [] = useState()
  const { getErrorMessage } = useGetErrorMessage();
  const { customNavigate } = useCustomNavigate();
  const [createPriceAlert] = useCreatePriceAlertMutation();
  const [updatePriceAlert] = useUpdatePriceAlertByIdMutation();
  const toastr = useToastr();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: stockDetail } = useGetStockByCodeQuery(tempSymbol);

  const formik = useFormik({
    initialValues: {
      when: initialValues?.when || 'equal',
      price: initialValues?.price || 0
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: priceAlertSchema,
    onSubmit: (values) => {
      handleSubmitPriceAlert(values);
    }
  });

  const handleSubmitPriceAlert = async () => {
    setIsSubmitting(true);
    try {
      if (isEmpty(initialValues)) {
        await createPriceAlert({
          code: tempSymbol,
          price: formik?.values?.price,
          type: formik?.values?.when,
          is_active: true
        }).unwrap();

        toastr.success('Price alert berhasil dipasang');
      } else {
        await updatePriceAlert({
          body: {
            code: tempSymbol,
            price: formik?.values?.price,
            type: formik?.values?.when,
            is_active: true
          },
          id: initialValues?.id
        }).unwrap();

        toastr.success('Price alert berhasil diupdate');
      }

      customNavigate();
      onSuccess();
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  useEffect(() => {
    if (stockDetail && isEmpty(initialValues))
      formik.setFieldValue('price', stockDetail?.lastTradedPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockDetail]);

  return (
    <>
      <form className='max-w-lg mx-auto' autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
        <Card className="p-8 relative dark:border dark:border-gray-base-d dark:text-black-light-1-d">
          <button type='button' className='absolute top-4 right-4 text-black-base-l hover:text-main-base-l dark:text-main-base-l hover:opacity-75' onClick={onClose}>
            <IconClose className='h-6 w-6'/>
          </button>
          <h5 className="mb-4 text-lg">Atur Price Alert</h5>

          <div className="-mx-3 -mt-3">
            <TVHeader showLogo advancedChart symbol={tempSymbol} showPriceAlert={false} onChangeSymbol={(newSymbol) => setTempSymbol(newSymbol)} />
          </div>

          <InputCounter
            isAutoFocus
            data-qa-id="txtStockBuyLotField"
            value={formik.values.price}
            name="price"
            label={<span className='text-sm text-gray-light-0-d'>Target Harga</span>}
            onChange={(val) => {
              formik.setFieldValue('price', val);
              setTimeout(() => formik.setTouched({ price: true }), 100);
            }}
            counterStep={marginPlusShare(formik.values.price)}
            error={formik.errors.price ? formik.errors.price : ''}
            showError={false}
          />

          <InputSelect
            value={formik?.values?.when}
            name="when"
            label={<span className='text-sm'>Kondisi Harga</span>}
            className="mb-4 text-2xs text-gray-light-0-d"
            options={[
              {
                id: 'lower',
                name: 'Ketika harga kurang dari price alert'
              },
              {
                id: 'equal',
                name: 'Ketika harga sama dengan price alert'
              },
              {
                id: 'upper',
                name: 'Ketika harga lebih dari price alert'
              }
            ]}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.errors.when ? formik.errors.when : ''}
            showError={false}
          />

          <RenderIf isTrue={!formik?.isValid}>
            <div className="font-quicksand-semibold py-3 px-4 flex items-center text-red-base-l text-xs rounded-xl my-3 bg-red-base-l bg-opacity-10">
              <IconWarning className="h-5 w-5 mr-1 flex-none" />
              {Object.keys(formik?.errors)?.map((key, idx) => (
                <RenderIf key={key} isTrue={idx === 0}>
                  <span
                    key={key}
                    className="flex items-center w-full flex-auto">
                    {idx === 0 && formik?.errors[key]}
                  </span>
                </RenderIf>
              ))}
            </div>
          </RenderIf>

          <div className="pt-3 mt-1 relative flex justify-end">
            <button
              data-qa-id="btnStockBuySubmit"
              disabled={!formik.isValid || isSubmitting}
              type="submit"
              className="bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center transition-all ease-in-out duration-100">
              {isSubmitting && <Spinner />}
              Simpan
            </button>
          </div>
        </Card>
      </form>
    </>
  );
}

PriceAlertForm.propTypes = {
  symbol: PropTypes.string,
  initialValues: PropTypes.object
};

export default PriceAlertForm;
