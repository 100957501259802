import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import IconClose from 'components/svg/icon-close';
import { useState } from 'react';
import { LIST_BROKER } from 'constants/list-broker';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

function ModalListBroker({ onClose = () => {} }) {
  const [activeFilter, setActiveFilter] = useState('All');

  const bgcColors = {
    Lokal: 'bg-[#AB58FF]',
    Asing: 'bg-[#FB9825]',
    Pemerintah: 'bg-[#0C9955]'
  };

  const textColors = {
    Lokal: 'text-[#AB58FF]',
    Asing: 'text-[#FB9825]',
    Pemerintah: 'text-[#0C9955]'
  };

  const listFilter = [
    {
      id: 'All',
      name: 'Semua'
    },
    {
      id: 'Lokal',
      name: 'Lokal'
    },
    {
      id: 'Asing',
      name: 'Foreign'
    },
    {
      id: 'Pemerintah',
      name: 'BUMN'
    }
  ];

  return (
    <Card className="p-7 max-w-md md:mx-auto relative mx-6">
      <button
        onClick={onClose}
        type="button"
        className="hover:text-main-base-l dark:text-main-base-l dark:hover:opacity-75 absolute top-4 right-4">
        <IconClose className="h-6 w-6" />
      </button>
      <h5 className="mb-4 -mt-3 dark:text-black-light-1-d">Kode Broker</h5>

      <div className="flex gap-4 font-quicksand-semibold">
        {listFilter?.map((item, key) => (
          <button
            key={key}
            type="button"
            onClick={() => setActiveFilter(item?.id)}
            className={`rounded-lg px-3 py-[6px] text-xs outline-none appearance-none border border-main-base-l
            ${
              activeFilter === item?.id
                ? 'bg-main-base-l text-white dark:text-black-base-d'
                : 'card-widget-gradient text-gray-light-0-d dark:text-black-light-1-d'
            }`}>
            {item?.name}
          </button>
        ))}
      </div>

      <div className="flex justify-between text-xs text-gray-light-0-d dark:text-black-light-1-d my-4">
        <div className="flex items-center">
          <span
            className={`h-[6px] w-[6px] block rounded-full mr-1 ${bgcColors?.Lokal}`}></span>
          <span>Lokal</span>
        </div>
        <div className="flex items-center">
          <span
            className={`h-[6px] w-[6px] block rounded-full mr-1 ${bgcColors?.Asing}`}></span>
          <span>Asing</span>
        </div>
        <div className="flex items-center">
          <span
            className={`h-[6px] w-[6px] block rounded-full mr-1 ${bgcColors?.Pemerintah}`}></span>
          <span>BUMN</span>
        </div>
      </div>

      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small max-h-[70vh]"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        >
        <ul className="text-sm">
          {LIST_BROKER?.filter(
            (item) => item?.group === activeFilter || activeFilter === 'All'
          )?.map((item, key) => (
            <li key={key} className="mb-2">
              <b className={`${textColors[item?.group]} mr-3`}>{item?.code}</b>
              <span className='dark:text-black-light-1-d'>{item?.name}</span>
            </li>
          ))}
        </ul>
      </OverlayScrollbarsComponent>
    </Card>
  );
}

ModalListBroker.propTypes = {
  onClose: PropTypes.func
};

export default ModalListBroker;
