import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import InputSelect from 'components/input/input-select';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { find, isEmpty, uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { invalidateAllOrder, useCreateOrderWithdrawMutation } from 'services/rtk-query/trade';
import { currency } from 'utils/format';
import { getOrderStatus } from 'utils/stock/order';
import { StockWithdrawSchema } from './stock-withdraw.form.validation';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmationStockWithdraw from '../_partials/modal-confirmation-stock-withdraw';
import { triggerSegmentEvent } from 'lib/segment';
import IconWarning from 'components/svg/icon-warning';
import { invalidateUserNotification } from 'services/rtk-query/user';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import useToastr from 'hooks/useToastr';
import InputCheckBox from 'components/input/input-check-box';
import SimpleSpinner from 'components/common/spinner/spinner';
import { setQuickOrderSettingsConfirmation } from 'store/settings';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import useBrowserTabTrading from 'hooks/useBrowserTabTrading';

function OrderFocusWithdrawForm({
  orderList,
  isSticky,
  initValues,
  analyticEventName = 'TR Withdraw from Widget',
}) {
  const toastr = useToastr();
  const quickOrderConfirmation = useSelector((state) => state?.settings?.quickOrderSettings?.confirmation);
  const { isLoginPin } = useBrowserTabTrading();
  const { user } = useBrowserTabAuth();
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();
  const [createOrderWithdraw] = useCreateOrderWithdrawMutation();

  const [optionsOrderList, setOptionsOrderList] = useState([]);
  const [isNotRealAccount, setIsNotRealAccount] = useState(false);
  const [openModalConfirmationWithdraw, setOpenModalConfirmationWithdraw] = useState(false);
  const [isSubmiting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      orderNumber: initValues?.clOID || null,
      code: initValues?.stID || null,
      lot: initValues?.qty / 100 || 0,
      price: initValues?.price || 0,
      id: initValues?.id || undefined
    },
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: StockWithdrawSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();

    if (!user?.realAccount) return setIsNotRealAccount(true);

    if (isEmpty(errors)) {
      if (!quickOrderConfirmation) {
        return setOpenModalConfirmationWithdraw(true);
      } else {
        return formik.submitForm();
      }
    }
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      let payloads = {
        orderID: values?.id,
        clOrdID: values?.orderNumber
      };
      const { success, msg, err } = await createOrderWithdraw(
        payloads
      ).unwrap();

      if (!success) {
        let error = new Error();
        Object.assign(error, { msg, code: err });
        throw error;
      }

      setOpenModalConfirmationWithdraw(false);

      triggerSegmentEvent({
        event: analyticEventName,
        properties: {
          total_amount: Number(values?.lot) * Number(values?.price) * 100,
        }
      });

      toastr.success('Order telah terkirim');
      formik.setValues({...formik.values, id: undefined});
      
      setTimeout(() => formik.setErrors({}), 300);
      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    let options = [];
    orderList?.map((item) => {
      if (
        !['amend', 'match', 'reject', 'withdraw'].includes(
          getOrderStatus(item?.state)
        )
      ) {
        options.push({
          id: item.id,
          name:
            item?.stID +
            ' / ' +
            item?.side +
            ' / ' +
            dayjs.unix(item?.dtSave / 1000).format('DD MMM YYYY') +
            ' / ' +
            item?.qty / 100 +
            ' Qty ' +
            ' / ' +
            item?.price
        });
      }
    });
    
    setOptionsOrderList(options);
  }, [orderList]);


  useEffect(() => {
    if (initValues?.id != formik?.values?.id && !isEmpty(initValues)) {
      formik.setValues({
        ...formik.values,
        id: initValues.id, 
        side: initValues.side, 
        price: initValues.price, 
        lot: initValues.qty / 100, 
        code: initValues.stID, 
        orderNumber: initValues.clOID, 
      })

      setTimeout(() => {
        formik.setErrors({})
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValues]);

  return (
    <form
      className="mt-3"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleConfirmation();
      }}
      noValidate
      >
      <div className="relative px-4">
        <InputSelect
          isAutoFocus={isLoginPin}
          size='small'
          value={formik?.values?.id}
          name="id"
          label={<span className='text-2xs font-quicksand-semibold text-gray-light-0-d'>Pilih Order</span>}
          className="mb-4 text-2xs text-gray-light-0-d"
          options={optionsOrderList}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            if (e.target.value) {
              const selectOrder = find(orderList, { id: Number(e.target.value) });

              formik.setValues({
                ...formik.values,
                price: selectOrder?.price,
                code: selectOrder?.stID,
                lot: selectOrder?.qty / 100,
                orderNumber: selectOrder?.clOID
              })
              setTimeout(() => formik.setErrors({}), 100);
            }
            formik.handleChange(e);
          }}
          onFocus={() => dispatch(invalidateAllOrder())}
          error={formik.errors.id ? formik.errors.id : ''}
          showError={false}
        />

        <div className="flex border-y dark:border-gray-base-d py-2 items-center">
          <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
            Jumlah Investasi
          </span>
          <h5 className="flex-grow text-right text-sm dark:text-[#D9DEE2]">
            {currency(
              (formik?.values?.lot || 0) * (formik?.values?.price || 0) * 100
            )}
          </h5>
        </div>

        {/* <RenderIf isTrue={!formik?.isValid}>
          <div className="font-quicksand-semibold py-3 px-4 flex items-center text-red-base-l text-xs rounded-xl my-3 bg-red-base-l bg-opacity-10">
            <IconWarning className="h-5 w-5 mr-1 flex-none" />
            {Object.keys(formik?.errors)?.map((key, idx) => (
              <RenderIf key={key} isTrue={idx === 0}>
                <span
                  key={key}
                  className="flex items-center w-full flex-auto">
                  {idx === 0 && formik?.errors[key]}
                </span>
              </RenderIf>
            ))}
          </div>
        </RenderIf> */}
      </div>

      <div
        className={`py-3 mt-1 ${
          !isSticky ? 'relative px-4' : 'sticky bottom-0 card-widget-gradient px-4'
        }`}>
        <button
          data-qa-id="btnStockSellSubmit"
          type="button"
          onClick={handleConfirmation}
          disabled={!formik.isValid && user?.realAccount || isSubmiting}
          className="bg-orange-2 disabled:bg-opacity-[30%] disabled:text-black-light-0-d hover:bg-opacity-75 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full mr-0 ml-auto flex items-center justify-center transition-all ease-in-out duration-100">
          {isSubmiting && <SimpleSpinner />}
          Withdraw {formik.values.lot ? formik.values.lot + ' Lot' : ''} {formik.values.code ? <>{formik.values.code} @ {formik.values.price}</> : ''}
        </button>

        <div className='mt-4'>
          <InputCheckBox
            id={`${uniqueId('confirmation-')}`}
            value={quickOrderConfirmation}
            name="confirmation"
            checked={quickOrderConfirmation}
            label={<span className='text-xs font-quicksand-regular text-black-base-l transform dark:text-black-light-0-d'>Confirm Order</span>}
            onChange={() => {
              dispatch(setQuickOrderSettingsConfirmation(!quickOrderConfirmation))
            }}
            onBlur={formik.handleBlur}
            className='mb-0'
          />
        </div>
      </div>

      <RenderIf isTrue={isNotRealAccount}>
        <Modal onClose={() => setIsNotRealAccount(false)}>
          <ModalExploreExpired
            showClose
            eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
            title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
            description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
            onClose={() => setIsNotRealAccount(false)}
          />
        </Modal>
      </RenderIf>
      
      <RenderIf isTrue={openModalConfirmationWithdraw}>
        <Modal onCLose={() => setOpenModalConfirmationWithdraw(false)}>
          <ModalConfirmationStockWithdraw
            isSubmitting={isSubmiting}
            data={formik?.values}
            onSubmit={() => formik.submitForm()}
            onCancel={() => setOpenModalConfirmationWithdraw(false)}
          />
        </Modal>
      </RenderIf>
    </form>
  );
}

OrderFocusWithdrawForm.propTypes = {
  orderList: PropTypes.array,
  initValues: PropTypes.object,
  isBursaOpen: PropTypes.bool,
  isSticky: PropTypes.bool,
  analyticEventName: PropTypes.string,
};

export default OrderFocusWithdrawForm;
