import PropTypes from 'prop-types';
import RenderIf from './render-if';
import { Link } from 'react-router-dom';

function Breadcrumb({ data }) {
  return (
    <ul className="flex items-center">
      {data.map((item, key) => (
        <li key={key} className="group">
          <Link
            to={item.link}
            className="flex items-center">
            <RenderIf isTrue={item.icon}>
              <div
                className="mr-2"
                dangerouslySetInnerHTML={{ __html: item.icon }}
              />
            </RenderIf>

            <h5 className="text-sm group-first:font-quicksand-semibold font-quicksand-medium group-first:text-main-base-l text-gray-light-0-d dark:text-black-light-1-d">
              {item.title}
            </h5>

            <RenderIf isTrue={key < data.length - 1}>
              <span className="mx-1 font-quicksand-semibold text-main-base-l">{'/'}</span>
            </RenderIf>
          </Link>
        </li>
      ))}
    </ul>
  );
}

Breadcrumb.propTypes = {
  data: PropTypes.array
};

export default Breadcrumb;
