import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

function SparkLineChart({
  series = [],
  colors = [],
  annotations = {},
  max = 9999999,
  min = 0
}) {
  const chartData = {
    series: series,
    options: {
      annotations,
      chart: {
        type: 'line',
        sparkline: {
          enabled: true
        },
        animations: {
          enabled: false
        }
      },
      stroke: {
        show: true,
        lineCap: 'butt',
        width: 1,
        curve: 'straight'
      },
      colors: colors,
      tooltip: {
        enabled: false
      },
      fill: {
        type: 'solid'
      },
      yaxis: {
        show: false,
        max: max,
        min: min
      }
    }
  };

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height="100%"
    />
  );
}

SparkLineChart.propTypes = {
  fsLoaded: PropTypes.bool,
  series: PropTypes.array,
  colors: PropTypes.array,
  annotations: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number
};

export default SparkLineChart;
