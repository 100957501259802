/* eslint-disable no-undef */
import axios from 'axios';
import { getCookie } from 'utils/cookie';

const redirect = (uri, dur = 200) => {
  const replace = window?.location?.replace?.bind(window?.location);
  setTimeout(function () {
    replace(uri);
  }, dur);
};


const APIService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000 // TODO: remove
});

APIService.defaults.headers.common['x-api-key'] = process.env.REACT_APP_API_TOKEN;
let token = getCookie('pn_trade_auth_token');
if (token) {
  APIService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

APIService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error?.code === 'ERR_NETWORK') {
      // NO INTERNET CONNECTION!
      // alert('No Internet Connection!');
      return Promise.reject(error);
    } else if (
      error?.response &&
      error?.response.status === 401 &&
      !error?.config._retry
    ) {
      console.log('error.request', error.request)
      console.log('error.response.headers', error.response.headers)

      try {
        let token = getCookie('pn_trade_auth_token');
        if (!token) {
          // alert('Token not exists!');
          // redirect('/logout');
          return Promise.reject(new Error('Token not exists!'));
        }

        error.config.headers.Authorization = `Bearer ${token}`;
        error.config.baseURL = undefined;

        // return retry(error?.config);
        return Promise.reject(error);
      } catch (e) {
        // REQUEST NEW TOKEN ERROR!
        redirect('/logout');
        // alert('Failed to request a new token!');
        return Promise.reject(e);
      }
    } else if (error?.response) {
      const err = error?.response?.data
        ? error?.response.data
        : error?.response;

      return Promise.reject(err);
    } else if (error?.request && Object.entries(error?.request).length) {
      return Promise.reject(error?.request);
    } else {
      const err = error?.message ? error?.message : error;
      return Promise.reject(err);
    }
  }
);

export default APIService;
