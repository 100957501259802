import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useRef, useState } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './css/react-grid.css';

import RenderIf from 'components/basic/render-if';
import DynamicModule from 'pages/trading/_partials/dynamic-module';
import { find, isEqual, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { invalidateWatchlist } from 'services/rtk-query/watchlist';
import {
  invalidateBrokerSummaryByCode,
  invalidateStockByCode,
  invalidateStockCalendar,
  invalidateStockCorpActionByCode,
  invalidateStockDoneDetailByCode,
  invalidateStockDoneSummaryByCode,
  invalidateStockFundamentalByCode,
  invalidateStockInfoByCode,
  invalidateStockNewsByCode,
  invalidateStockTopTrending
} from 'services/rtk-query/stock';
import {
  useDeleteWorkSpaceByIdMutation,
  useGetAllWorkSpaceByUserIdQuery,
  useUpdateWorkSpaceByIdMutation,
  useUpdateWorkSpaceByIdSkipInvalidateMutation
} from 'services/rtk-query/workspace';
import WorkSpaceForm from 'components/form/workspace-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import IconPlusSquare from 'components/svg/icon-plus-square';
import TabWorkspace from './_partials/tab-worksace';
import { ModalContext } from 'components/modal/context/modalContext';
import { triggerSegmentEvent } from 'lib/segment';

import ImageEmptyState from 'assets/images/mascot/mascot-empty-orderlist.png';
import SkeletonTheme from 'components/basic/skeleton-theme';
import ModalDialog from 'components/modal/components/modal-dialog';
import { useCreateUserCoachMarkMutation } from 'services/rtk-query/user';
import { CoachMark } from 'react-coach-mark';
import IconGrab from 'components/svg/icon-grab';
import IconClose from 'components/svg/icon-close';
import WorkSpacePresetForm from 'components/form/workspace-preset-form';
import { useCoachMark } from 'hooks/useCoachmark';
import ModalFirstTimeWorkspace from 'components/modal/template/modal-first-time-workspace';
import { setCoachMarkWorkspace } from 'store/pina-pro';
import { PinaTradeWorkspaceAfterCreated } from 'constants/coachmark';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import { isMobile } from 'react-device-detect';
import IconChevronCircle from 'components/svg/icon-chevron-circle';
import useOuterClick from 'hooks/useOuterClick';
import IconRefresh from 'components/svg/icon-refresh';
import {
  invalidateAllOrder,
  invalidateIntraDataOrderBookByCode,
  invalidateOrderListHistory
} from 'services/rtk-query/trade';
import { invalidateTreeMap } from 'services/rtk-query/master-data';
import { invalidateBuyingPower } from 'services/rtk-query/trading';
import { invalidateTradingIdeas } from 'services/rtk-query/classroom';
import IconLIST_WIDGET from 'components/svg/icon-widget-list';
import ModalWidgetFast from './_partials/modal-widget-fast';
import Modal from 'components/modal/modal';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import ModalHelp from 'components/modal/template/modal-help';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import LIST_WIDGET from 'constants/list-widget';
import IconWidgetList from 'components/svg/icon-widget-list';
import cn from 'utils/classname';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function PinaProCustomWorkspace() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { getErrorMessage } = useGetErrorMessage();
  const addWidgetRef = useRef(null);
  const manageWidgetRef = useRef(null);

  const { handleModal, handleModalLoading } = useContext(ModalContext);

  const { coachMarkWorkspace } = useSelector(({ pinaPro }) => pinaPro);
  const { user } = useBrowserTabAuth();

  const [createUserCoachMark] = useCreateUserCoachMarkMutation();
  const [updateWorkspace] = useUpdateWorkSpaceByIdMutation();
  const [updateWorkspaceLazy] = useUpdateWorkSpaceByIdSkipInvalidateMutation();
  const [deleteWorkspace] = useDeleteWorkSpaceByIdMutation();
  const {
    data: workspaces,
    isLoading: isLoadingWorkspaces,
    isFetching,
    isError: errorGetWorkspace
  } = useGetAllWorkSpaceByUserIdQuery(user?.id, {
    refetchOnMountOrArgChange: true
  });

  const { cmWorkspaceInit, cmWorkspaceCreated } = useCoachMark();

  const symbols = ['AALI'];
  const [activeWorkspace, setActiveWorkspace] = useState({});
  const [tempWorkspace, setTempWorkspace] = useState([]);
  const [isDraggable, setIsDraggable] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [showSwipeId, setShowSwipeId] = useState(false);
  const [openListHelp, setOpenListHelp] = useState(false);

  const [openRemoveWorkspace, setOpenRemoveWorkspace] = useState(false);
  const [isSubmittingRemoveWorkspace, setIsSubmittingRemoveWorkspace] =
    useState(false);
  const [isCreateEmptyWorkspace, setIsCreateEmptyWorkspace] = useState(false);
  const [localCoachMarkList, setLocalCoachMarkList] = useState([]);
  const [layouts, setLayouts] = useState({
    lg: [],
    md: [],
    sm: [],
    xs: [],
    xxs: []
  });

  const [breakpoint] = useState('lg');

  const refSwipeContainer = useOuterClick(() => setShowSwipeId(false));

  const breakpoints = {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0
  };
  const cols = { lg: 24, md: 12, sm: 9, xs: 3, xxs: 3 };

  const defaultCoachmark = [
    {
      activate: true,
      reference: addWidgetRef,
      tooltip: { position: isMobile ? 'bottom' : 'bottom' },
      component: (
        <div className="block p-2 max-w-[280px]">
          <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">
            Tambah Widget Baru
          </h5>
          <p className="text-gray-light-0-d text-xs mb-2">
            Tambah widget atau komponen yang Kamu butuhkan untuk membuat
            workspace yang sesuai dengan gaya tradingmu
          </p>

          <div className="flex justify-between">
            <button
              onClick={() => dispatch(setCoachMarkWorkspace(1))}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l mr-0 text-sm block font-quicksand-semibold">
              Kembali
            </button>
            <button
              onClick={() => dispatch(setCoachMarkWorkspace(3))}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l ml-auto mr-0 text-sm block font-quicksand-semibold">
              Selanjutnya
            </button>
          </div>
        </div>
      )
    },
    {
      activate: true,
      reference: manageWidgetRef,
      tooltip: { position: isMobile ? 'bottom' : 'bottom' },
      component: (
        <div className="block p-2 max-w-[280px]">
          <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">
            Atur Widget
          </h5>
          <p className="text-gray-light-0-d text-xs mb-2">
            Gunakan button ini untuk menambah widget sesuai dengan kebutuhan dan
            gaya tradingmu
          </p>

          <div className="flex justify-between">
            <button
              onClick={() => dispatch(setCoachMarkWorkspace(2))}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l mr-0 text-sm block font-quicksand-semibold">
              Kembali
            </button>
            <button
              onClick={async () => {
                await createUserCoachMark({
                  category: PinaTradeWorkspaceAfterCreated,
                  userId: user?.id
                }).unwrap();
                dispatch(setCoachMarkWorkspace(4));
              }}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l ml-auto mr-0 text-sm block font-quicksand-semibold">
              Tutup
            </button>
          </div>
        </div>
      )
    }
  ];

  const handleCustomizeLayout = async () => {
    let tLayouts = { ...layouts };
    Object.keys(layouts).map((breakpoint) => {
      let newLayout = [];
      layouts[breakpoint].map((lay) => {
        newLayout.push({ ...lay, static: false });
      });

      tLayouts = {
        ...tLayouts,
        [breakpoint]: newLayout
      };
    });

    const _activeWorkspace = {
      ...activeWorkspace,
      layout: JSON.stringify(tLayouts)
    };

    let _workspaces = [];
    tempWorkspace.forEach((item) => {
      if (item?.slug != activeWorkspace?.slug) {
        let countUnsavedWidget = 0;
        let __layout = JSON.parse(item?.layout);
        Object?.keys(__layout)?.map((breakpoint) => {
          __layout?.[breakpoint]?.map((lay) => {
            if (lay?.static === false) {
              countUnsavedWidget++;
            }
          });
        });

        _workspaces.push({ ...item, unsaved: countUnsavedWidget > 0 });
      } else {
        _workspaces.push(_activeWorkspace);
      }
    });

    if (_activeWorkspace?.unsaved && !_activeWorkspace?.id) {
      // DO NOTHING!
    } else {
      try {
        handleModalLoading(true);
        if (!errorGetWorkspace) {
          await updateWorkspace({
            body: _activeWorkspace,
            id: _activeWorkspace?.id
          }).unwrap();
        }
        triggerSegmentEvent({ event: 'TR Workspace Saved' });
      } catch (error) {
        getErrorMessage(error);
      } finally {
        handleModal(false);
        handleModalLoading(false);
      }
    }
  };

  const handleRemoveWidget = (cLay) => {
    let tLayouts = { ...layouts };
    Object.keys(layouts).map((breakpoint) => {
      let newLayout = [];
      layouts[breakpoint].map((lay) => {
        if (lay.i != cLay.i) {
          newLayout.push(lay);
        }
      });

      tLayouts = {
        ...tLayouts,
        [breakpoint]: newLayout
      };
    });

    let _activeWorkspace = {
      ...activeWorkspace,
      layout: JSON.stringify(tLayouts)
    };

    let _workspaces = [];
    tempWorkspace.forEach((item) => {
      if (item?.slug != activeWorkspace?.slug) {
        _workspaces.push(item);
      } else {
        _workspaces.push(_activeWorkspace);
      }
    });

    if (!errorGetWorkspace) {
      updateWorkspace({
        body: _activeWorkspace,
        id: _activeWorkspace?.id
      }).unwrap();
    }
  };

  const handleAddWidgets = (_widgets, initWorkspace) => {
    let widgets = orderBy(_widgets, 'w', 'asc');
    let tLayouts = { ...window.layouts };
    let _pickSymbol =
      symbols[Math.floor(Math.random() * (symbols?.length - 0 + 1) + 0)];

    Object.keys(layouts).map((breakpoint) => {
      let newLayout = [];
      tLayouts[breakpoint].map((lay) =>
        newLayout.push({ ...lay, static: false })
      );

      widgets?.map((widget, key) => {
        newLayout.push({
          i: 'n' + Date.now().toString().slice(4, 13) + key,
          x: (newLayout?.length * 4) % (cols[breakpoint] || 12),
          y: 999999,
          w: widget?.w || 4,
          h: widget?.h || 25,
          minW: widget?.minW || 4,
          minH: widget?.minH || 16,
          resizeHandles: ['ne', 'se', 'nw', 'sw'],
          widget: {
            symbol: _pickSymbol,
            name: widget?.name,
            title: widget?.title,
            settings: widget?.settings || {}
          }
        });

        tLayouts = { ...tLayouts, [breakpoint]: newLayout };

        triggerSegmentEvent({
          event: 'TR Widget Added to Workspace',
          properties: {
            widget_name: widget?.title
          }
        });
      });
    });

    let _activeWorkspace = {
      ...window?.activeWorkspace,
      layout: JSON.stringify(tLayouts)
    };
    if (initWorkspace) {
      _activeWorkspace = { ...initWorkspace, layout: JSON.stringify(tLayouts) };
    }

    const _workspaces = [];

    window?.tempWorkspace.forEach((item) => {
      if (item?.slug != window?.activeWorkspace?.slug) {
        _workspaces.push(item);
      } else {
        _workspaces.push({ ..._activeWorkspace, unsaved: true });
      }
    });

    _widgets?.map((widget) => {
      triggerSegmentEvent({
        event: 'TR Widget Added to Workspace',
        properties: {
          widget_name: widget?.title
        }
      });
    });

    if (!errorGetWorkspace) {
      updateWorkspace({
        body: _activeWorkspace,
        id: _activeWorkspace?.id
      }).unwrap();
    }

    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleLayoutChange = (updatedLayout, updatedLayouts) => {
    let tLayouts = { ...layouts };

    Object.keys(updatedLayouts)?.map((breakpoint) => {
      if (updatedLayouts[breakpoint].length > 0) {
        let newLayout = [];
        updatedLayouts[breakpoint].map((item, key) => {
          if (layouts?.[breakpoint]?.[key]?.i === item.i) {
            newLayout.push({
              ...item,
              widget: layouts?.[breakpoint][key]?.widget
            });
          }
        });

        tLayouts = { ...tLayouts, [breakpoint]: newLayout };
      }
    });

    if (tLayouts?.[breakpoint]?.length > 0) {
      let _activeWorkspace = {
        ...activeWorkspace,
        layout: JSON.stringify(tLayouts)
      };
      if (!errorGetWorkspace) {
        updateWorkspace({
          body: _activeWorkspace,
          id: _activeWorkspace?.id
        }).unwrap();
      }
    }
  };

  const handlePickWidget = (widget, cLay) => {
    let tLayouts = { ...layouts };
    let _pickSymbol =
      symbols[Math.floor(Math.random() * (symbols?.length - 0 + 1) + 0)];

    Object.keys(layouts).map((breakpoint) => {
      let updateLayout = find(layouts[breakpoint], (lay) => lay?.i === cLay?.i);
      updateLayout = {
        ...updateLayout,
        w: widget?.w,
        h: widget?.h,
        minW: widget?.minW || 4,
        minH: widget?.minH || 16,
        static: false,
        resizeHandles: ['ne', 'se', 'nw', 'sw'],
        widget: {
          ...widget,
          symbol: updateLayout?.widget?.symbol || _pickSymbol
        }
      };

      let newLayout = [];
      layouts[breakpoint].map((lay) => {
        if (lay.i === cLay.i) {
          newLayout.push(updateLayout);
        } else {
          newLayout.push(lay);
        }
      });

      tLayouts = {
        ...tLayouts,
        [breakpoint]: newLayout
      };
    });

    let _activeWorkspace = {
      ...activeWorkspace,
      unsaved: true,
      layout: JSON.stringify(tLayouts)
    };
    let _workspaces = [];

    tempWorkspace.forEach((item) => {
      if (item?.slug != activeWorkspace?.slug) {
        _workspaces.push(item);
      } else {
        _workspaces.push(_activeWorkspace);
      }
    });

    if (!errorGetWorkspace) {
      updateWorkspace({
        body: _activeWorkspace,
        id: _activeWorkspace?.id
      }).unwrap();
    }
  };

  const handleRemoveWorkspace = async (_workspace) => {
    setOpenRemoveWorkspace(_workspace);
  };

  const handleChangeSymbol = async (item, newSymbol, isLazySave = false) => {
    let tLayouts = { ...window?.layouts };

    Object.keys(tLayouts)?.map((breakpoint) => {
      let newLayout = [];
      tLayouts[breakpoint]?.map((layout) => {
        if (
          item?.i === layout?.i ||
          (layout?.widget?.settings?.isConnectWidget &&
            item?.widget?.settings?.isConnectWidget)
        ) {
          newLayout.push({
            ...layout,
            static: false,
            widget: {
              ...layout?.widget,
              symbol: newSymbol
            }
          });
        } else {
          newLayout.push({ ...layout, static: false });
        }
      });

      tLayouts = { ...tLayouts, [breakpoint]: newLayout };
    });

    if (activeWorkspace?.id) {
      const _activeWorkspace = {
        ...activeWorkspace,
        layout: JSON.stringify(tLayouts)
      };
      if (!isLazySave) {
        if (!errorGetWorkspace) {
          updateWorkspace({
            body: _activeWorkspace,
            id: _activeWorkspace?.id
          }).unwrap();
        }
      } else {
        updateWorkspaceLazy({
          body: _activeWorkspace,
          id: _activeWorkspace?.id
        }).unwrap();
      }
      triggerSegmentEvent({ event: 'TR Workspace Saved' });
    }
  };

  const handleChangeWidgetSettings = async (item, newWidgetSettings) => {
    let tLayouts = { ...window?.layouts };
    Object.keys(tLayouts)?.map((breakpoint) => {
      let newLayout = [];
      const findConnectedSymbol = tLayouts[breakpoint]?.find(
        (layout) =>
          layout?.widget?.settings?.isConnectWidget &&
          newWidgetSettings?.isConnectWidget
      )?.widget?.symbol;

      tLayouts[breakpoint]?.map((layout) => {
        if (item?.i === layout?.i) {
          newLayout.push({
            ...layout,
            static: false,
            widget: {
              ...layout?.widget,
              settings: newWidgetSettings,
              symbol: findConnectedSymbol || item?.widget?.symbol
            }
          });
        } else {
          newLayout.push({ ...layout, static: false });
        }
      });

      tLayouts = { ...tLayouts, [breakpoint]: newLayout };
    });

    if (window?.activeWorkspace?.id) {
      const _activeWorkspace = {
        ...window?.activeWorkspace,
        layout: JSON.stringify(tLayouts)
      };
      if (!errorGetWorkspace) {
        updateWorkspace({
          body: _activeWorkspace,
          id: _activeWorkspace?.id
        }).unwrap();
      }
      triggerSegmentEvent({ event: 'TR Workspace Saved' });
    }
  };

  const handleSwipeToUpGridMobile = (_widget) => {
    let tempLayout = orderBy(layouts?.[breakpoint], ['y'], ['asc']);
    let swipeWidget = {
      a: null,
      b: null
    };

    tempLayout = tempLayout?.map((widget, key) => {
      if (widget?.i === _widget?.i && key != 0) {
        swipeWidget.a = tempLayout?.[key - 1];
        swipeWidget.b = widget;
        return { ...widget, y: tempLayout?.[key - 1]?.y || 0 };
      }
      return widget;
    });

    tempLayout = tempLayout?.map((widget) => {
      if (swipeWidget?.a?.i === widget?.i) {
        return { ...widget, y: swipeWidget?.b?.y || 0 };
      }
      return widget;
    });

    if (swipeWidget?.a) {
      const _activeWorkspace = {
        ...activeWorkspace,
        layout: JSON.stringify({ ...layouts, tempLayout })
      };
      if (!errorGetWorkspace) {
        updateWorkspace({
          body: _activeWorkspace,
          id: _activeWorkspace?.id
        }).unwrap();
      }
      setTimeout(
        () =>
          refSwipeContainer?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          }),
        500
      );
    }
  };

  const handleSwipeToBottomGridMobile = (_widget) => {
    let tempLayout = orderBy(layouts?.[breakpoint], ['y'], ['asc']);
    let swipeWidget = {
      a: null,
      b: null
    };

    tempLayout = tempLayout?.map((widget, key) => {
      if (widget?.i === _widget?.i && key <= tempLayout?.length - 1) {
        swipeWidget.a = tempLayout?.[key + 1];
        swipeWidget.b = widget;
        return { ...widget, y: tempLayout?.[key + 1]?.y || 0 };
      }
      return widget;
    });

    tempLayout = tempLayout?.map((widget) => {
      if (swipeWidget?.a?.i === widget?.i) {
        return { ...widget, y: swipeWidget?.b?.y || 0 };
      }
      return widget;
    });

    if (swipeWidget?.a) {
      const _activeWorkspace = {
        ...activeWorkspace,
        layout: JSON.stringify({ ...layouts, tempLayout })
      };
      if (!errorGetWorkspace) {
        updateWorkspace({
          body: _activeWorkspace,
          id: _activeWorkspace?.id
        }).unwrap();
      }
      setTimeout(
        () =>
          refSwipeContainer?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          }),
        500
      );
    }
  };

  const handleRefreshWidget = (item) => {
    const module = item?.widget?.name;
    if (module === 'watchlist') {
      dispatch(invalidateWatchlist());
    }

    if (module === 'advanced-chart') {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
    }

    if (
      [
        'bundle-orderlist',
        'trade-list',
        'order-list',
        'portfolio',
        'order-form'
      ]?.includes(module)
    ) {
      dispatch(invalidateAllOrder());
    }

    if (['order-form-orderbook', 'order-form-mock-only']?.includes(module)) {
      dispatch(invalidateAllOrder());
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateIntraDataOrderBookByCode(item?.widget?.symbol));
    }

    if (
      [
        'fast-order-buy',
        'fast-order-sell',
        'multi-order',
        'fast-order'
      ].includes(module)
    ) {
      dispatch(invalidateAllOrder());
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateIntraDataOrderBookByCode(item?.widget?.symbol));
    }

    if (module === 'calendar') {
      dispatch(invalidateStockCalendar());
    }

    if (module === 'trading-ideas') {
      dispatch(invalidateTradingIdeas());
    }

    if (module === 'top-tranding') {
      dispatch(invalidateStockTopTrending());
    }

    if (module === 'bandarmology') {
      dispatch(invalidateBrokerSummaryByCode(item?.widget?.symbol));
    }

    if (['chart-orderbook', 'orderbook'].includes(module)) {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateIntraDataOrderBookByCode(item?.widget?.symbol));
    }

    if (module === 'chart') {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
    }

    if (module === 'company-profile') {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateStockInfoByCode(item?.widget?.symbol));
    }

    if (module === 'corp-action') {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateStockCorpActionByCode(item?.widget?.symbol));
    }

    if (module === 'done-detail') {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateStockDoneDetailByCode(item?.widget?.symbol));
    }

    if (module === 'done-summary') {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateStockDoneSummaryByCode(item?.widget?.symbol));
    }

    if (module === 'fundamental') {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateStockFundamentalByCode(item?.widget?.symbol));
    }

    if (module === 'news') {
      dispatch(invalidateStockByCode(item?.widget?.symbol));
      dispatch(invalidateStockNewsByCode(item?.widget?.symbol));
    }

    if (module === 'order-list-history') {
      dispatch(invalidateOrderListHistory());
    }

    if (module === 'chart-heatmap') {
      dispatch(invalidateTreeMap('previousLastTradedPrice'));
    }

    if (module === 'IHSG') {
      dispatch(invalidateStockByCode('COMPOSITE'));
    }

    if (module === 'info-cash') {
      dispatch(invalidateBuyingPower());
    }
  };

  useEffect(() => {
    if (workspaces?.length > 0 && slug) {
      const _activeWorkspace = find(
        tempWorkspace?.length === 0 ? workspaces : tempWorkspace,
        ['slug', slug]
      );

      if (_activeWorkspace) {
        setActiveWorkspace(_activeWorkspace);
        setLayouts(JSON.parse(_activeWorkspace?.layout));
        setFirstRender(false);
      }
    } else {
      setFirstRender(false);
    }
  }, [workspaces, tempWorkspace, slug]);

  useEffect(() => {
    if (workspaces?.length === 0 && tempWorkspace?.length > 0) {
      setTempWorkspace([]);
    } else if (!isEqual(tempWorkspace, workspaces)) {
      setTempWorkspace(workspaces);
    } else {
      setTempWorkspace(tempWorkspace);
    }
  }, [workspaces, tempWorkspace]);

  useEffect(() => {
    if (
      slug === 'new-workspace' &&
      !isLoadingWorkspaces &&
      (tempWorkspace?.length > 0 || workspaces?.length === 0)
    ) {
      triggerSegmentEvent({ event: 'TR New Workspace Added' });
      let _layouts = {
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: []
      };

      handleModal(
        <WorkSpacePresetForm
          layout={JSON.stringify(_layouts)}
          workspaces={workspaces}
          onClose={() => {
            handleModal(false);
            navigate(-1);
          }}
          onSuccess={(response) => {
            if (response?.name === 'My Workspace')
              setIsCreateEmptyWorkspace(true);
            triggerSegmentEvent({ event: 'TR Workspace Saved PINA Trade' });
          }}
        />,
        false,
        false,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    slug,
    isLoadingWorkspaces,
    navigate,
    tempWorkspace,
    layouts,
    activeWorkspace,
    workspaces
  ]);

  useEffect(() => {
    window.layouts = layouts;
  }, [layouts]);

  useEffect(() => {
    window.activeWorkspace = activeWorkspace;
  }, [activeWorkspace]);

  useEffect(() => {
    window.tempWorkspace = tempWorkspace;
  }, [tempWorkspace]);

  useEffect(() => {
    if (cmWorkspaceInit) {
      handleModal(
        <ModalFirstTimeWorkspace
          onPickSelfWorkspace={(_workspace) => {
            handleModal(
              <ModalWidgetFast
                onClose={() => handleModal(false)}
                onPickWidget={(widgets) => {
                  handleAddWidgets(widgets, _workspace);
                }}
              />,
              'right',
              'mx-auto max-w-5xl'
            );
            triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
          }}
        />,
        false,
        'mx-auto max-w-5xl my-5',
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmWorkspaceInit]);

  useEffect(() => {
    if (isCreateEmptyWorkspace) {
      handleModal(
        <ModalWidgetFast
          onClose={() => {
            handleModal(false);
            setIsCreateEmptyWorkspace(false);
          }}
          onPickWidget={(widgets) => {
            handleAddWidgets(widgets);
            setIsCreateEmptyWorkspace(false);
          }}
        />,
        'right',
        'mx-auto max-w-5xl'
      );
    }
  }, [isCreateEmptyWorkspace, layouts]);

  useKeyboardShortcut({
    keys: ['¢', '4'],
    combine: 'altKey',
    onKeyPressed: () => {
      // add orderbook!
      const orderbookWidget = LIST_WIDGET.find(
        (item) => item?.name === 'orderbook'
      );
      handleAddWidgets([orderbookWidget]);
    }
  });

  useKeyboardShortcut({
    keys: ['∞', '5'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderlistWidget = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'bundle-orderlist'
      );
      if (currentOrderlistWidget) {
        handleChangeWidgetSettings(currentOrderlistWidget, {
          ...currentOrderlistWidget?.settings,
          activeTabIndex: 0
        });
      } else {
        const portfolio = LIST_WIDGET.find(
          (item) => item?.name === 'bundle-orderlist'
        );
        handleAddWidgets([{ ...portfolio, settings: { activeTabIndex: 0 } }]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['¶', '7'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderlistWidget = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'bundle-orderlist'
      );
      if (currentOrderlistWidget) {
        handleChangeWidgetSettings(currentOrderlistWidget, {
          ...currentOrderlistWidget?.settings,
          activeTabIndex: 1
        });
      } else {
        const portfolio = LIST_WIDGET.find(
          (item) => item?.name === 'bundle-orderlist'
        );
        handleAddWidgets([{ ...portfolio, settings: { activeTabIndex: 1 } }]);
      }
    }
  });

  useKeyboardShortcut({
    key: 'F5',
    onKeyPressed: () => {
      const currentOrderlistWidget = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'bundle-orderlist'
      );
      if (currentOrderlistWidget) {
        handleChangeWidgetSettings(currentOrderlistWidget, {
          ...currentOrderlistWidget?.settings,
          activeTabIndex: 2
        });
      } else {
        const portfolio = LIST_WIDGET.find(
          (item) => item?.name === 'bundle-orderlist'
        );
        handleAddWidgets([{ ...portfolio, settings: { activeTabIndex: 2 } }]);
      }
    }
  });

  useKeyboardShortcut({
    key: 'F8',
    onKeyPressed: () => {
      // add running trade!
      const runningTradeWidget = LIST_WIDGET.find(
        (item) => item?.name === 'running-trade'
      );
      handleAddWidgets([runningTradeWidget]);
    }
  });

  useKeyboardShortcut({
    key: 'F6',
    onKeyPressed: () => {
      const currentOrderlistWidget = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'bundle-orderlist'
      );
      if (currentOrderlistWidget) {
        handleChangeWidgetSettings(currentOrderlistWidget, {
          ...currentOrderlistWidget?.settings,
          activeTabIndex: 0
        });
      } else {
        const portfolio = LIST_WIDGET.find(
          (item) => item?.name === 'bundle-orderlist'
        );
        handleAddWidgets([{ ...portfolio, settings: { activeTabIndex: 0 } }]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['∫', 'b'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderWidgets = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'order-form-mock-only'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(currentOrderWidgets, {
          ...currentOrderWidgets?.settings,
          initActiveTabIndex: 0
        });
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 0 } }
        ]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['F2'],
    onKeyPressed: () => {
      const currentOrderWidgets = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'order-form-mock-only'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(currentOrderWidgets, {
          ...currentOrderWidgets?.settings,
          initActiveTabIndex: 0
        });
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 0 } }
        ]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['ß', 's'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderWidgets = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'order-form-mock-only'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(currentOrderWidgets, {
          ...currentOrderWidgets?.settings,
          initActiveTabIndex: 1
        });
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 1 } }
        ]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['F4'],
    onKeyPressed: () => {
      const currentOrderWidgets = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'order-form-mock-only'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(currentOrderWidgets, {
          ...currentOrderWidgets?.settings,
          initActiveTabIndex: 1
        });
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 1 } }
        ]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['å', 'a'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderWidgets = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'order-form-mock-only'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(currentOrderWidgets, {
          ...currentOrderWidgets?.settings,
          initActiveTabIndex: 2
        });
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 2 } }
        ]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['F3'],
    onKeyPressed: () => {
      const currentOrderWidgets = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'order-form-mock-only'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(currentOrderWidgets, {
          ...currentOrderWidgets?.settings,
          initActiveTabIndex: 2
        });
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 2 } }
        ]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['∑', 'w'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderWidgets = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'order-form-mock-only'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(currentOrderWidgets, {
          ...currentOrderWidgets?.settings,
          initActiveTabIndex: 3
        });
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 3 } }
        ]);
      }
    }
  });

  useKeyboardShortcut({
    keys: ['F12'],
    onKeyPressed: () => {
      const currentOrderWidgets = window?.layouts?.['lg']?.find(
        (item) => item?.widget?.name === 'order-form-mock-only'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(currentOrderWidgets, {
          ...currentOrderWidgets?.settings,
          initActiveTabIndex: 3
        });
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 3 } }
        ]);
      }
    }
  });

  useKeyboardShortcut({
    key: 'w',
    combine: 'ctrlKey',
    onKeyPressed: () => {
      handleModal(
        <ModalWidgetFast
          onClose={() => handleModal(false)}
          onPickWidget={(widgets) => {
            handleAddWidgets(widgets);
          }}
        />,
        'right',
        'mx-auto max-w-5xl'
      );
      triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
    }
  });

  useKeyboardShortcut({
    keys: ['˙', 'h'],
    combine: 'altKey',
    onKeyPressed: () => {
      setOpenListHelp(true);
    }
  });

  useKeyboardShortcut({
    keys: ['F1'],
    onKeyPressed: () => {
      setOpenListHelp(true);
    }
  });

  if (workspaces?.length > 0 && !slug) {
    return <Navigate to={`/workspace/${workspaces?.[0]?.slug}`} />;
  }

  if (workspaces?.length === 0 && slug && slug != 'new-workspace') {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>Custom Workspace | Pina Trade</title>
      </Helmet>

      <div className={`p-4 pt-0 relative zoom-2`}>
        <div className={`transform py-4 pt-3 z-40 sticky top-[68px]`}>
          <div className="grid gap-4 lg:gap-0 lg:flex justify-between grid-cols-1 lg:grid-cols-none">
            <div className="flex-none w-full lg:w-[75vw]">
              <TabWorkspace
                activeWorkspace={activeWorkspace}
                isLoading={isLoadingWorkspaces || firstRender}
                workspaces={tempWorkspace}
                onRemoveWorkspace={handleRemoveWorkspace}
                onAddWorkspace={() => navigate('/workspace/new-workspace')}
                onEditWorkspace={handleCustomizeLayout}
                onAddWidget={() => {
                  handleModal(
                    <ModalWidgetFast
                      onClose={() => handleModal(false)}
                      onPickWidget={(widgets) => {
                        handleAddWidgets(widgets);
                      }}
                    />,
                    'right',
                    'mx-auto max-w-5xl'
                  );
                  triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
                }}
                onUpdateWorkspaceName={(_workspace) => {
                  handleModal(
                    <WorkSpaceForm
                      layout={_workspace?.layout}
                      workspaces={workspaces}
                      initialValues={_workspace}
                      onClose={() => handleModal(false)}
                      onSuccess={(response) => {
                        const _tempWorkspace = [];
                        tempWorkspace?.map((item) => {
                          if (item?.id === _workspace?.id) {
                            _tempWorkspace.push(response);
                          } else {
                            _tempWorkspace.push(item);
                          }
                        });

                        navigate(`/workspace/${response?.slug}`);
                        setTempWorkspace(_tempWorkspace);
                        triggerSegmentEvent({
                          event: 'TR Workspace Saved PINA Trade'
                        });
                        handleModal(false);
                      }}
                    />
                  );
                }}
                onInitCoachMark={(_coachMarks) =>
                  setLocalCoachMarkList([..._coachMarks, ...defaultCoachmark])
                }
              />
            </div>

            <RenderIf isTrue={slug && slug !== 'new-workspace'}>
              <div className="flex-auto ml-2 lg:flex justify-end hidden items-center">
                <button
                  title="Tambah Widget Baru"
                  type="button"
                  ref={addWidgetRef}
                  onClick={() => {
                    handleModal(
                      <ModalWidgetFast
                        onClose={() => handleModal(false)}
                        onPickWidget={(widgets) => {
                          handleAddWidgets(widgets);
                        }}
                      />,
                      'right',
                      'mx-auto max-w-5xl'
                    );
                    triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
                  }}
                  className="appearance-none text-main-base-d p-1 flex items-center gap-1 group focus-within:ring-0">
                  <IconWidgetList className="h-4 w-4" />
                  <span className={cn('block text-xs w-0 overflow-hidden group-hover:w-[100px] line-clamp-1 transition-all ease-linear duration-150 h-4', coachMarkWorkspace && 'w-[90px]')}>
                    Tambah Widget
                  </span>
                </button>
              </div>
            </RenderIf>
          </div>
        </div>

        <div>
          <RenderIf isTrue={slug}>
            <RenderIf isTrue={isLoadingWorkspaces || firstRender}>
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                <SkeletonTheme
                  containerClassName="leading-none"
                  width="100%"
                  height="400px"
                  borderRadius="16px"
                />
                <SkeletonTheme
                  containerClassName="leading-none"
                  width="100%"
                  height="400px"
                  borderRadius="16px"
                />
                <SkeletonTheme
                  containerClassName="leading-none"
                  width="100%"
                  height="400px"
                  borderRadius="16px"
                />
              </div>
            </RenderIf>

            <RenderIf isTrue={!isLoadingWorkspaces && !firstRender}>
              <RenderIf
                isTrue={
                  layouts?.[breakpoint]?.length > 0 && slug != 'new-workspace'
                }>
                <ResponsiveReactGridLayout
                  autoSize={false}
                  isBounded={false}
                  isDraggable={isDraggable}
                  cols={cols}
                  breakpoints={breakpoints}
                  layouts={layouts}
                  margin={[10, 10]}
                  rowHeight={2}
                  useCSSTransforms={false}
                  resizeHandles={['ne', 'se', 'nw', 'sw']}
                  containerPadding={[0, 0]}
                  onLayoutChange={handleLayoutChange}
                  onDragStart={() => setIsDragged(true)}
                  onDragStop={() => setIsDragged(false)}>
                  {layouts?.[breakpoint]?.map((item, key) => (
                    <div key={item?.i} data-grid={item}>
                      <div
                        className={`h-full w-full card-widget-gradient relative border border-black-light-0-d/30 rounded-md`}>
                        <div
                          ref={key === 0 ? manageWidgetRef : null}
                          className="flex justify-between">
                          <div
                            ref={
                              item?.i === showSwipeId ? refSwipeContainer : null
                            }
                            className="flex items-center relative flex-auto">
                            <span
                              tabIndex={-1}
                              type="button"
                              title="Geser Widget"
                              className="mr-3 p-3 pb-2 cursor-pointer appearance-none outline-none flex w-full items-center gap-2 hover:cursor-grab active:cursor-grabbing transform translate-y-[1px] z-30"
                              onMouseEnter={() =>
                                !isMobile && setIsDraggable(true)
                              }
                              onMouseLeave={() =>
                                !isMobile && setIsDraggable(false)
                              }
                              onClick={() => setShowSwipeId(item?.i)}>
                              <IconGrab className="h-[11px] w-auto text-main-base-l" />
                              <h5 className="m-0 text-2xs font-quicksand-regular dark:text-black-light-0-d line-clamp-1">
                                {item?.widget?.title || 'Empty'}
                              </h5>
                            </span>

                            <RenderIf isTrue={item?.i === showSwipeId}>
                              <ul className="absolute left-0 top-full text-xs font-quicksand-semibold z-20 shadow-pro rounded-xl overflow-hidden lg:hidden transform translate-y-2">
                                <li>
                                  <button
                                    onClick={() =>
                                      handleSwipeToUpGridMobile(item)
                                    }
                                    type="button"
                                    className="flex items-center rounded-t-xl justify-start py-2 px-4 min-w-[150px] bg-white dark:text-black-light-1-d dark:bg-black-base-d dark:hover:bg-black-base-d hover:text-main-base-l hover:bg-gray-light-l border-b dark:border-b-gray-base-d">
                                    <IconChevronCircle className="flex-none mr-2 transform rotate-180" />
                                    Geser Ke Atas
                                  </button>
                                </li>
                                <li>
                                  <button
                                    onClick={() =>
                                      handleSwipeToBottomGridMobile(item)
                                    }
                                    type="button"
                                    className="flex items-center rounded-b-xl justify-start py-2 px-4 min-w-[150px] bg-white dark:text-black-light-1-d dark:bg-black-base-d dark:hover:bg-black-base-d hover:text-main-base-l hover:bg-gray-light-l">
                                    <IconChevronCircle className="flex-none mr-2" />
                                    Geser Ke Bawah
                                  </button>
                                </li>
                              </ul>
                            </RenderIf>
                          </div>

                          <div className="flex items-center flex-none p-3 pb-2 gap-1.5">
                            <button
                              type="button"
                              className="text-main-base-l h-[12px] w-[12px] z-10 appearance-none outline-none hover:opacity-75 transition-all ease-linear duration-100 flex items-center justify-center"
                              onClick={() => {
                                handleChangeWidgetSettings(item, {
                                  ...item?.widget?.settings,
                                  isConnectWidget:
                                    !item?.widget?.settings?.isConnectWidget
                                });
                              }}
                              title={
                                item?.widget?.settings?.isConnectWidget
                                  ? 'Disconnect Widget Symbol'
                                  : 'Connect Widget Symbol'
                              }>
                              <RenderIf
                                isTrue={
                                  item?.widget?.settings?.isConnectWidget
                                }>
                                <span className="h-[12px] w-[12px] bg-green-base-l aspect-square block rounded-full" />
                              </RenderIf>

                              <RenderIf
                                isTrue={
                                  !item?.widget?.settings?.isConnectWidget
                                }>
                                <span className="h-[12px] w-[12px] bg-black-light-1-d dark:bg-black-light-2-d/30 aspect-square block rounded-full" />
                              </RenderIf>
                            </button>

                            <button
                              type="button"
                              className="text-main-base-l z-10 appearance-none outline-none hover:opacity-75 transition-all ease-linear duration-100"
                              onClick={() => handleRefreshWidget(item)}
                              title="Refresh Widget">
                              <IconRefresh className="h-[12px] w-[12px]" />
                            </button>

                            <button
                              type="button"
                              className="text-main-base-l z-10 appearance-none outline-none hover:opacity-75 transition-all ease-linear duration-100"
                              onClick={() => {
                                handleModal(
                                  <ModalWidgetFast
                                    header={
                                      <h5 className="font-quicksand-semibold mb-6 text-base dark:text-black-light-1-d">
                                        Ganti Widget
                                      </h5>
                                    }
                                    onClose={() => handleModal(false)}
                                    isChangeWidget
                                    onPickWidget={(widgets) => {
                                      handlePickWidget(widgets[0], item);
                                      handleModal(false);
                                    }}
                                  />,
                                  'right',
                                  'mx-auto max-w-5xl'
                                );
                                triggerSegmentEvent({
                                  event: 'TR Add Widget Clicked'
                                });
                              }}
                              title="Ganti Widget">
                              <IconLIST_WIDGET className="h-[12px] w-[12px]" />
                            </button>

                            <button
                              type="button"
                              title="Hapus Widget"
                              className="cursor-pointer appearance-none outline-none hover:opacity-75 transition-all ease-linear duration-100"
                              onClick={() => {
                                handleRemoveWidget(item);
                              }}>
                              <IconClose className="h-[12px] w-[12px] text-main-base-l" />
                            </button>
                          </div>
                        </div>

                        <RenderIf isTrue={item?.widget}>
                          <DynamicModule
                            idWidget={item?.i}
                            symbol={item?.widget?.symbol}
                            module={item?.widget?.name}
                            isDragged={isDragged}
                            widgetSettings={item?.widget?.settings}
                            onChangeSymbol={(newSymbol, isLazySave) =>
                              handleChangeSymbol(item, newSymbol, isLazySave)
                            }
                            onChangeWidgetSettings={(newWidgetSettings) =>
                              handleChangeWidgetSettings(
                                item,
                                newWidgetSettings
                              )
                            }
                            onRefresh={() => handleRefreshWidget(item)}
                            onExit={() => handleRemoveWidget(item)}
                          />
                        </RenderIf>

                        <RenderIf isTrue={!item?.widget}>
                          <div
                            className="flex justify-center items-center"
                            style={{ height: 'calc(100% - 3rem)' }}>
                            <button
                              title="Tambah Widget Baru"
                              type="button"
                              onClick={() => {
                                handleModal(
                                  <ModalWidgetFast
                                    onClose={() => handleModal(false)}
                                    onPickWidget={(widgets) => {
                                      handleAddWidgets(widgets);
                                    }}
                                  />,
                                  'right',
                                  'mx-auto max-w-5xl'
                                );
                                triggerSegmentEvent({
                                  event: 'TR Add Widget Clicked'
                                });
                              }}
                              className="p-2 bg-main-base-l text-white font-quicksand-semibold text-xs flex items-center rounded-lg w-36 justify-center hover:opacity-75 transition-all ease-in-out duration-200">
                              <IconPlusSquare className="h-5 w-5 mr-1" />
                              Tambah Widget
                            </button>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  ))}
                </ResponsiveReactGridLayout>
              </RenderIf>

              <RenderIf
                isTrue={
                  layouts?.[breakpoint]?.length === 0 &&
                  !isLoadingWorkspaces &&
                  !isFetching
                }>
                <div
                  className="w-full bg-gray-base-l dark:bg-black-base-d rounded-2xl flex items-center justify-center min-h-[400px]"
                  style={{ height: 'calc(100vh - 210px)' }}>
                  <div className="text-center p-10 dark:text-black-light-0-d">
                    <img
                      src={ImageEmptyState}
                      alt="empty"
                      className="h-14 w-14 mx-auto mb-4"
                    />

                    <h2 className="text-2xl mb-1 font-quicksand-semibold">
                      Tambah widget untuk di workspace ini
                    </h2>

                    <p className="text-sm">
                      Tambahkan widget sesuai kebutuhan kamu
                    </p>

                    <button
                      title="Tambah Widget Baru"
                      type="button"
                      onClick={() => {
                        handleModal(
                          <ModalWidgetFast
                            onClose={() => handleModal(false)}
                            onPickWidget={(widgets) => {
                              handleAddWidgets(widgets);
                            }}
                          />,
                          'right',
                          'mx-auto max-w-5xl'
                        );
                        triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
                      }}
                      className="mt-6 p-3 bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d w-64 mx-auto hover:bg-opacity-75 disabled:bg-opacity-100 text-white font-quicksand-semibold text-sm flex items-center rounded-lg justify-center hover:opacity-75 transition-all ease-in-out duration-200">
                      Tambah Widget
                    </button>
                  </div>
                </div>
              </RenderIf>
            </RenderIf>
          </RenderIf>

          <RenderIf isTrue={!slug}>
            <div
              className="w-full bg-gray-base-l dark:bg-black-base-d rounded-2xl flex items-center justify-center min-h-[400px]"
              style={{ height: 'calc(100vh - 210px)' }}>
              <div className="text-center p-10 dark:text-black-light-0-d">
                <img
                  src={ImageEmptyState}
                  alt="empty"
                  className="h-14 w-14 mx-auto mb-4"
                />

                <h2 className="text-2xl mb-1 font-quicksand-semibold">
                  Belum ada workspace
                </h2>

                <p className="text-sm">Buat workspace baru kamu</p>

                <button
                  title="Tambah Workspace Baru"
                  type="button"
                  onClick={() => navigate('/workspace/new-workspace')}
                  className="mt-6 p-3 bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d w-64 mx-auto hover:bg-opacity-75 disabled:bg-opacity-100 text-white font-quicksand-semibold text-sm flex items-center rounded-lg justify-center hover:opacity-75 transition-all ease-in-out duration-200">
                  Buat Workspace
                </button>
              </div>
            </div>
          </RenderIf>
        </div>
      </div>

      <RenderIf isTrue={cmWorkspaceCreated && layouts[breakpoint]?.length > 0}>
        <CoachMark {...localCoachMarkList[coachMarkWorkspace]} />
      </RenderIf>

      <RenderIf isTrue={openRemoveWorkspace}>
        <Modal onClose={() => setOpenRemoveWorkspace(false)}>
          <ModalDialog
            header={
              <h5 className="font-quicksand-semibold text-base">
                Hapus Workspace
              </h5>
            }
            submitTitle="Hapus"
            backTitle="Batal"
            isSubmitting={isSubmittingRemoveWorkspace}
            onClose={() => setOpenRemoveWorkspace(false)}
            onSubmit={async () => {
              setIsSubmittingRemoveWorkspace(true);
              try {
                if (openRemoveWorkspace?.id) {
                  await deleteWorkspace(openRemoveWorkspace?.id).unwrap();
                }

                const _tempWorkspace = tempWorkspace.filter(
                  (item) => item?.slug !== openRemoveWorkspace?.slug
                );

                if (
                  activeWorkspace?.slug === openRemoveWorkspace?.slug &&
                  _tempWorkspace?.length > 0
                ) {
                  const _activeWorkspace =
                    _tempWorkspace[_tempWorkspace?.length - 1];
                  navigate('/workspace/' + _activeWorkspace?.slug);
                } else if (_tempWorkspace?.length === 0) {
                  navigate('/');
                }

                setIsSubmittingRemoveWorkspace(false);
                setOpenRemoveWorkspace(false);
                triggerSegmentEvent({ event: 'TR Workspace Deleted' });
              } catch (error) {
                getErrorMessage(error);
              }
            }}>
            <p className="font-quicksand-semibold text-sm">
              Apakah kamu yakin ingin menghapus workspace ini?
            </p>
          </ModalDialog>
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openListHelp}>
        <Modal onClose={() => setOpenListHelp(false)}>
          <ModalHelp onClose={() => setOpenListHelp(false)} />
        </Modal>
      </RenderIf>
    </>
  );
}

export default PinaProCustomWorkspace;
