import { createSlice } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

const initialState = {
  sectors: [],
  sectorDetails: [],
  stockScreener: {
    additionalMetrics: [],
    activeScreener: null,
    index: { name: 'IHSG', value: 'all', children: [] },
    group_metrics: [
      {
        id: uniqueId(),
        group_condition: false,
        metrics: [
          {
            id: uniqueId(),
            param: '',
            operator: '=',
            paramCompareType: 'Value',
            valueCompare: '',
            condition: false
          }
        ]
      }
    ]
  }
};

export const stock = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setSectors: (state, action) => {
      return {
        ...state,
        sectors: action.payload
      };
    },
    setSectorDetails: (state, action) => {
      return {
        ...state,
        sectorDetails: action.payload
      };
    },
    setGroupMatrics: (state, { payload }) => {
      state.stockScreener.group_metrics = payload;
    },
    setScreenerIndex: (state, { payload }) => {
      state.stockScreener.index = payload;
    },
    setActiveScreener: (state, { payload }) => {
      state.stockScreener.activeScreener = payload;
    },
    setAdditionalMetrics: (state, { payload }) => {
      state.stockScreener.additionalMetrics = payload;
    },
    resetStockScreener: (state) => {
      state.stockScreener = initialState.stockScreener
    },
  }
});

export const stockPersistConfig = {
  key: 'pn-trade-stock',
  whitelist: ['stockScreener']
};

export const {
  setSectors,
  setSectorDetails,
  setGroupMatrics,
  setScreenerIndex,
  setActiveScreener,
  setAdditionalMetrics,
  resetStockScreener
} = stock.actions;
export default stock.reducer;
