const filterNumber = (_str, _min) => {
  let str = String(_str);
  // get all numbers from string.
  let number = str?.replace(/[^0-9.]/g, '');

  // remove all thousand format.
  number = Number(number?.split('.')?.join(''));

  // set value to 0 if empty string.
  if (isNaN(number)) number = _min;
  return number;
};

export default filterNumber;
