import PropTypes from 'prop-types';

function IconATM({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16146 4H17.8385C18.3657 3.99998 18.8205 3.99997 19.195 4.03057C19.5904 4.06287 19.9836 4.13419 20.362 4.32698C20.9265 4.6146 21.3854 5.07354 21.673 5.63803C21.8658 6.01641 21.9371 6.40963 21.9694 6.80497C22 7.17954 22 7.6343 22 8.16144V9.83857C22 10.3657 22 10.8205 21.9694 11.195C21.9371 11.5904 21.8658 11.9836 21.673 12.362C21.3854 12.9265 20.9265 13.3854 20.362 13.673C19.9836 13.8658 19.5904 13.9371 19.195 13.9694C18.8205 14 18.3657 14 17.8385 14H16V12H17.8C18.3766 12 18.7488 11.9992 19.0322 11.9761C19.3038 11.9539 19.4045 11.9162 19.454 11.891C19.6422 11.7951 19.7951 11.6422 19.891 11.454C19.9162 11.4045 19.9539 11.3038 19.9761 11.0322C19.9992 10.7488 20 10.3766 20 9.8V8.2C20 7.62345 19.9992 7.25117 19.9761 6.96784C19.9539 6.69617 19.9162 6.59546 19.891 6.54601C19.7951 6.35785 19.6422 6.20487 19.454 6.109C19.4045 6.0838 19.3038 6.04612 19.0322 6.02393C18.7488 6.00078 18.3766 6 17.8 6H6.2C5.62345 6 5.25117 6.00078 4.96784 6.02393C4.69617 6.04612 4.59546 6.0838 4.54601 6.109C4.35785 6.20487 4.20487 6.35785 4.109 6.54601C4.0838 6.59546 4.04612 6.69617 4.02393 6.96784C4.00078 7.25117 4 7.62345 4 8.2V9.8C4 10.3766 4.00078 10.7488 4.02393 11.0322C4.04612 11.3038 4.0838 11.4045 4.109 11.454C4.20487 11.6422 4.35785 11.7951 4.54601 11.891C4.59546 11.9162 4.69617 11.9539 4.96784 11.9761C5.25117 11.9992 5.62345 12 6.2 12H8V14H6.16148C5.63432 14 5.17955 14 4.80497 13.9694C4.40963 13.9371 4.01641 13.8658 3.63803 13.673C3.07354 13.3854 2.6146 12.9265 2.32698 12.362C2.13419 11.9836 2.06287 11.5904 2.03057 11.195C1.99997 10.8205 1.99998 10.3657 2 9.83855V8.16146C1.99998 7.63431 1.99997 7.17955 2.03057 6.80497C2.06287 6.40963 2.13419 6.01641 2.32698 5.63803C2.6146 5.07354 3.07354 4.6146 3.63803 4.32698C4.01641 4.13419 4.40963 4.06287 4.80497 4.03057C5.17955 3.99997 5.63431 3.99998 6.16146 4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8H6V9V15.8V15.8385V15.8386C5.99998 16.3657 5.99997 16.8205 6.03057 17.195C6.06287 17.5904 6.13419 17.9836 6.32698 18.362C6.6146 18.9265 7.07354 19.3854 7.63803 19.673C8.01641 19.8658 8.40963 19.9371 8.80497 19.9694C9.17954 20 9.63431 20 10.1614 20H10.1615H10.2H12H13.8H13.8385H13.8386C13.8607 20 13.8827 20 13.9046 20H14V20C14.459 19.9997 14.8593 19.9969 15.195 19.9694C15.5904 19.9371 15.9836 19.8658 16.362 19.673C16.9265 19.3854 17.3854 18.9265 17.673 18.362C17.8658 17.9836 17.9371 17.5904 17.9694 17.195C18 16.8205 18 16.3657 18 15.8386V15.8385V15.8V9V8H17H7ZM14 17.9999C14.4651 17.9995 14.783 17.9964 15.0322 17.9761C15.3038 17.9539 15.4045 17.9162 15.454 17.891C15.6422 17.7951 15.7951 17.6422 15.891 17.454C15.9162 17.4045 15.9539 17.3038 15.9761 17.0322C15.9992 16.7488 16 16.3766 16 15.8V10H14V17.9999ZM12 10H8V15.8C8 16.3766 8.00078 16.7488 8.02393 17.0322C8.04613 17.3038 8.0838 17.4045 8.109 17.454C8.20487 17.6422 8.35785 17.7951 8.54601 17.891C8.59546 17.9162 8.69618 17.9539 8.96784 17.9761C9.25118 17.9992 9.62345 18 10.2 18H12V10Z"
      />
    </svg>
  );
}

IconATM.propTypes = {
  className: PropTypes.string
};

export default IconATM;
