import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import InputSelect from 'components/input/input-select';
import IconPlus from 'components/svg/icon-plus';
import { useEffect, useRef, useState } from 'react';
import InputParameter from './input-parameter';
import InputComparison from './input-comparison';
import IconTrashCan from 'components/svg/icon-trash-can';
import InputRadioSimple from 'components/input/input-radio-simple';
import { map, uniqBy, uniqueId } from 'lodash';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import RenderIf from 'components/basic/render-if';
import InputUniverse from './input-universe';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveScreener, setAdditionalMetrics, setGroupMatrics, setScreenerIndex } from 'store/stock';
import { ScreenerSchema } from './form-screener.validation';
import { useFormik } from 'formik';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import SaveScreenerForm from 'components/form/save-screener-form';
import IconSetting from 'components/svg/icon-setting';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { automatedNumberFormat } from 'utils/format';
import IconFileEdit from 'components/svg/icon-file-edit';
import ModalDialog from 'components/modal/components/modal-dialog';
import { useDeleteUserStockScreenerByIdMutation, useGetUserStockScreenerByUserIdQuery } from 'services/rtk-query/user';
import { useLazyGetStockScreenerQuery } from 'services/rtk-query/stock';
import SimpleSpinner from 'components/common/spinner/spinner';
import { findParamStockScreenerByFieldName } from 'utils/stock/screener';
import { useInView } from 'react-intersection-observer';
import IconClose from 'components/svg/icon-close';
import { isMobile } from 'react-device-detect';
import { CONDITIONAL_OPERATOR } from 'constants/conditional-operator';
import { triggerSegmentEvent } from 'lib/segment';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function FormScreener({ onNewScreener = () => {}, onSuccess = () => {} }) {
  const dispatch = useDispatch();
  const refListScreener = useRef(null);
  const { getErrorMessage } = useGetErrorMessage();
  const { ref, inView } = useInView({ threshold: 0, rootMargin: '-140px'});
  const { user } = useBrowserTabAuth();
  const { group_metrics, index, activeScreener, additionalMetrics } = useSelector(({ stock }) => stock?.stockScreener);
  const { data: userScreeners, isSuccess: successGetUserScreener } = useGetUserStockScreenerByUserIdQuery(user?.id);
  const [getStockScreener] = useLazyGetStockScreenerQuery();
  const [deleteUserStockScreener] = useDeleteUserStockScreenerByIdMutation();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [showListScreener, setShowListScreener] = useState(false);
  const [openSaveScreenerForm, setOpenSaveScreenerForm] = useState(false);
  const [openRemoveScreener, setOpenRemoveScreener] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useOnClickOutside(refListScreener, () => setShowListScreener(false));
  
  const formik = useFormik({
    initialValues: { group_metrics: group_metrics },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: ScreenerSchema,
    onSubmit: async (values) => {
      try {
        setLoadingSubmit(true);

        let _metrics = [];
        values?.group_metrics?.forEach((metric) => {
          metric?.metrics?.forEach((param) => {
            _metrics?.push({ name: param?.param, paramCompare: param?.paramCompare });
            if (param?.paramCompareType === 'Parameter') {
              _metrics?.push({ ...param, name: param?.paramCompare, paramCompare: param?.paramCompare });
            }
          });
        });

        const _filters = map(uniqBy([..._metrics, ...(additionalMetrics || [])], 'name'), 'name')?.filter((item) => item != null);

        let tempPayload = { group_metrics: values?.group_metrics, index  };
        let payloads = {
          index: tempPayload?.index?.value,
          order: [],
          filters: _filters,
          group_metrics: tempPayload?.group_metrics
        }

        const response = await getStockScreener(payloads).unwrap();

        onSuccess({ response, payloads });
        setIsSuccess(true);
        triggerSegmentEvent({ event: 'TR Screener Clicked' });
        triggerSegmentEvent({ event: 'TR Formula Applied to Screener', properties: { params: _filters } });
        
        if (response?.length === 0) throw new Error('Saham tidak ditemukan');
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setLoadingSubmit(false);
      }
    },
  });

  const handleSaveMetrics = async () => {
    let screener = (({ formula, ...object }) => object)(activeScreener || {});
    let payloads = {...screener, group_metrics, index: index?.value, order: [], filters: []  };

    setOpenSaveScreenerForm({...payloads });
    triggerSegmentEvent({ event: 'TR Save Screener' });
    triggerSegmentEvent({ event: 'TR Save Screener Result' });
  };

  const getCountParameter = (group_metrics) => {
    let count = 0;
    group_metrics?.forEach((item) => {
      count += item?.metrics?.length;
    });

    return automatedNumberFormat(count);
  };

  const handleAddMetric = () => {
    let newMetrics = {
      id: uniqueId(),
      group_condition: 'AND',
      metrics: [
        {
          id: uniqueId(),
          param: '',
          paramCompareType: 'Value',
          operator: '=',
          valueCompare: '',
          condition: false
        }
      ]
    };

    if (group_metrics?.length === 0) {
      newMetrics.group_condition = false;
    }

    dispatch(setGroupMatrics([ ...group_metrics, newMetrics ]));
    triggerSegmentEvent({ event: 'TR Parameter Added to Screener' });
  };

  const handleRemoveParam = (metric, paramMetric) => {
    let findMetric = group_metrics?.find((met) => met?.id === metric?.id);
    let removeParams = findMetric?.metrics?.filter(
      (par) => par?.id != paramMetric?.id
    );

    if (removeParams?.length === 0) {
      dispatch(setGroupMatrics(group_metrics?.filter((met) => met?.id !== metric?.id)));
    } else {
      dispatch(
        setGroupMatrics(
          group_metrics?.map((met) => {
            if (met?.id === metric?.id)
              return { ...findMetric, metrics: removeParams };
            return met;
          })
        )
      );
    }
  };

  const handleAddParam = (metric, indexParam) => {
    let findMetric = group_metrics?.find((met) => met?.id === metric?.id);
    let newMetrics = {
      id: uniqueId(),
      param: '',
      paramCompareType: 'Value',
      operator: '=',
      valueCompare: '',
      condition: 'AND'
    }

    let leftMetrics = findMetric?.metrics?.filter((_, index) => index <= indexParam);
    let rightMetrics = findMetric?.metrics?.filter((_, index) => index > indexParam);
    let concateMetrics = [...leftMetrics, newMetrics, ...rightMetrics];
    
    dispatch(
      setGroupMatrics(
        group_metrics?.map((met) => {
          if (met?.id === metric?.id) return { ...findMetric, metrics: concateMetrics };
          return met;
        })
      )
    );

    triggerSegmentEvent({ event: 'TR Screener Parameter Added' });
  };

  const handleChange = (newObj, paramItem, metric) => {
    let findMetric = group_metrics?.find((met) => met?.id === metric?.id);
    let newParams = findMetric?.metrics?.map((par) => {
      if (par?.id === paramItem?.id) return { ...par, ...newObj };
      return par;
    });

    dispatch(
      setGroupMatrics(
        group_metrics?.map((met) => {
          if (met?.id === metric?.id) return { ...findMetric, metrics: newParams };
          return met;
        })
      )
    );
  };

  const handleChangegroup_condition = (value, metric) => {
    dispatch(
      setGroupMatrics(
        group_metrics?.map((met) => {
          if (met?.id === metric?.id) return { ...met, group_condition: value };
  
          return met;
        })
      )
    )
  };

  const isValidForm = () => {
    let _isValid = true;

    group_metrics?.map((metric) => {
      metric?.metrics?.forEach((param) => {
        if (
          !param?.operator ||
          !param?.param ||
          !param?.paramCompareType ||
          (!param?.valueCompare && !param?.paramCompare)
        ) {
          _isValid = false;
        }
      });
    });

    if (group_metrics?.length === 0) _isValid = false;

    return _isValid;
  };

  const handleResetForm = () => {
    setIsSuccess(false);
    dispatch(setAdditionalMetrics([]));
    dispatch(setScreenerIndex({ name: 'IHSG', value: 'all', children: [] }));
    dispatch(setActiveScreener(null));
    dispatch(
      setGroupMatrics([
        {
          id: uniqueId(),
          group_condition: false,
          metrics: [
            {
              id: uniqueId(),
              param: '',
              paramCompareType: 'Value',
              operator: '=',
              valueCompare: '',
              condition: false
            }
          ]
        }
      ])
    )

    onNewScreener({ group_metrics, index });
    setTimeout(() => formik.setErrors({}), 100);
    triggerSegmentEvent({ event: 'TR Create New Screener' });
  }

  useEffect(() => {
    formik.setValues({ group_metrics });

    if (firstRender) {
      setTimeout(() => formik.setErrors({}), 100);
      setFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_metrics]);

  return (
    <div ref={ref}>
      <Card className={`p-6 pb-1 ${inView ? 'opacity-100' : 'opacity-0'}`}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className='flex justify-start lg:justify-between items-center mb-5 flex-col gap-3 lg:flex-row relative z-[31]'>
            <h2 className="text-sm flex-auto w-full lg:w-auto dark:text-black-light-1-d">Screener</h2>

            <div className='flex justify-end flex-none w-full lg:w-auto'>
              <button
                type="button"
                disabled={!formik.isValid || !isValidForm()}
                onClick={handleSaveMetrics}
                className="text-main-base-l hover:opacity-75 disabled:hover:opacity-100 card-widget-gradient disabled:text-black-light-0-d px-4 py-[4px] text-sm font-quicksand-medium flex items-center border-r dark:border-gray-base-d">
                Simpan Screener
              </button>

              <div className='relative'>
                <button
                  type="button"
                  disabled={userScreeners?.length === 0 || !successGetUserScreener}
                  onClick={() => setShowListScreener(!showListScreener)}
                  className="text-main-base-l hover:opacity-75 disabled:hover:opacity-100 card-widget-gradient disabled:text-black-light-0-d px-4 py-[4px] pr-0 text-sm font-quicksand-medium flex items-center justify-between">
                  Screener Kamu

                  <IconChevronSingle className={`h-4 w-4 transform ml-2 ${showListScreener ? 'rotate-90' : '-rotate-90'}`} />
                </button>

                <RenderIf isTrue={showListScreener}>
                  <ul ref={showListScreener ? refListScreener : null } className='absolute right-0 top-full rounded-xl shadow-pro transform translate-y-2'>
                    {userScreeners?.map((screener, key) => (
                      <li key={key} className="flex justify-between min-w-[300px] card-widget-gradient pr-4 border-b dark:border-gray-base-d last:border-b-0 last:rounded-b-xl first:rounded-t-xl hover:bg-gray-light-l dark:hover:bg-black-dark-d transition-all ease-linear duration-150">
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(setGroupMatrics(screener?.formula?.group_metrics))
                            dispatch(setActiveScreener(screener));
                            dispatch(setScreenerIndex({ name: screener?.index === 'all' ? 'IHSG' : screener?.index, value: screener?.index }));
                            setShowListScreener(false);
                            onNewScreener();
                            setTimeout(() => formik.submitForm(), 500);
                          }}
                          className="flex-auto text-left py-2 px-4">
                          <h2 className="text-sm text-main-base-l">
                            {screener?.name}
                          </h2>
                          <p className="text-xs text-gray-light-0-d mb-2">
                            {getCountParameter(screener?.formula?.group_metrics)} Parameter
                          </p>
                          <p className="text-xs text-gray-light-0-d line-clamp-3">
                            {screener?.remarks}
                          </p>
                        </button>
                        <div className="flex-none transform translate-y-3">
                          <Settings 
                            screener={screener} 
                            onRemoveScreener={(sc) => setOpenRemoveScreener(sc)}
                            onEditScreener={(sc) => setOpenSaveScreenerForm(sc)}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </RenderIf>
              </div>
            </div>
          </div>

          <div className="relative z-30">
            <InputUniverse
              value={index?.name}
              onSelect={(value) => dispatch(setScreenerIndex(value))}
            />
          </div>

          <hr className="my-3 dark:border-gray-base-d" />

          <div>
            {formik?.values?.group_metrics?.map((item, k1) => (
              <div key={k1} className="group">
                <RenderIf isTrue={formik?.values?.group_metrics?.length > 1}>
                  <div className="max-w-[130px] -mx-1 group-first:hidden">
                    <InputRadioSimple
                      value={item?.group_condition}
                      size="small"
                      onChange={(e) =>
                        handleChangegroup_condition(e.target.value, item)
                      }
                      name={`metric-${k1}`}
                      options={[
                        { value: 'AND', text: 'Dan' },
                        { value: 'OR', text: 'Atau' }
                      ]}
                    />
                  </div>
                  {/* <p className='font-quicksand-semibold text-xs'>Group Metric {k1 + 1}</p> */}
                </RenderIf>
                {item?.metrics?.map((paramItem, k2) => (
                  <div key={k2} className="grid grid-cols-12 gap-2 my-3">
                    <RenderIf isTrue={k2 > 0}>
                      <div className="col-span-12">
                        <div className="max-w-[130px] -mx-1">
                          <InputRadioSimple
                            value={paramItem?.condition}
                            size="small"
                            className="mb-0"
                            onChange={(e) =>
                              handleChange({ 'condition': e.target.value }, paramItem, item)
                            }
                            name={`param-${k2}`}
                            options={[
                              { value: 'AND', text: 'Dan' },
                              { value: 'OR', text: 'Atau' }
                            ]}
                          />
                        </div>
                      </div>
                    </RenderIf>
                    <div className="col-span-12 lg:col-span-6">
                      <div className="grid grid-cols-12 gap-2">
                        <div className="col-span-8 lg:col-span-10">
                          <InputParameter
                            value={findParamStockScreenerByFieldName(paramItem?.param)?.name}
                            onSelect={(value) =>
                              handleChange({ param: value?.fieldName, paramCompareType: 'Value', valueCompare: '' }, paramItem, item)
                            }
                            error={formik?.errors?.group_metrics?.[k1]?.metrics?.[k2]?.param}
                          />
                        </div>
                        <div className="col-span-4 lg:col-span-2">
                          <InputSelect
                            value={paramItem?.operator}
                            label={false}
                            placeholder="-"
                            className="mb-0"
                            size="small"
                            options={CONDITIONAL_OPERATOR}
                            error={formik?.errors?.group_metrics?.[k1]?.metrics?.[k2]?.operator}
                            onChange={(e) => {
                              handleChange({ 'operator': e?.target?.value }, paramItem, item);
                            }}
                            showError={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-6 flex gap-2 items-center justify-between">
                      <div className="flex items-center flex-auto">
                        <InputComparison
                          paramCompareType={paramItem?.paramCompareType}
                          value={paramItem?.paramCompareType === 'Value' ? paramItem?.valueCompare : paramItem?.paramCompare}
                          valueToCompare={paramItem?.param}
                          onSelect={(value) => {
                            handleChange({ paramCompareType: value?.name, valueCompare: '' }, paramItem, item);
                          }}
                          onChange={(value) => {
                            if (value?.name) {
                              handleChange({ paramCompare: value?.fieldName, valueCompare: '' }, paramItem, item)
                            } else {
                              handleChange({ valueCompare: value, paramCompare: '' }, paramItem, item)
                            }
                          }}
                          error={(formik?.errors?.group_metrics?.[k1]?.metrics?.[k2]?.valueCompare || formik?.errors?.group_metrics?.[k1]?.metrics?.[k2]?.paramCompare)}
                        />
                      </div>

                      <div className="flex-none gap-2 flex justify-center">
                        <button
                          type="button"
                          className="text-main-base-l hover:opacity-75"
                          onClick={() => handleRemoveParam(item, paramItem)}>
                          <IconTrashCan className="h-4 w-4" />
                        </button>

                        <button
                          type="button"
                          className="text-main-base-l hover:opacity-75"
                          onClick={() => handleAddParam(item, k2)}>
                          <IconPlus className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

                <hr className="my-3 border-dashed group-last:border-solid dark:border-gray-base-d" />
              </div>
            ))}
          </div>

          <button
            type="button"
            onClick={handleAddMetric}
            className="text-main-base-l py-[8px] px-4 flex items-center justify-center w-full lg:w-64 ml-auto mr-0 card-widget-gradient border-main-base-l border-2 rounded-lg text-sm font-quicksand-semibold hover:opacity-75 transition-all ease-linear duration-150">
            <IconPlus className="h-4 w-4 mr-1" />
            Tambah Parameter
          </button>

          <div className="-mx-6 border-t px-6 py-4 mt-4 flex justify-end gap-3 dark:border-gray-base-d">
            <RenderIf isTrue={isSuccess}>
              <button
                type="button"
                onClick={handleResetForm}
                className="hover:bg-opacity-75 dark:text-main-base-l disabled:bg-opacity-100 mx-8 w-full lg:w-auto flex items-center justify-center bg-transparent text-main-base-l rounded-lg text-sm font-quicksand-semibold hover:opacity-75 transition-all ease-linear duration-150">
                Buat Baru
              </button>
            </RenderIf>

            <button
              type="submit"
              disabled={!formik.isValid || loadingSubmit || !isValidForm()}
              className="disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d dark:text-black-base-d hover:bg-opacity-75 disabled:bg-opacity-100 py-[10px] px-4 flex items-center justify-center w-full lg:w-64 bg-main-base-l text-white rounded-lg text-sm font-quicksand-semibold hover:opacity-75 transition-all ease-linear duration-150">
                { loadingSubmit && <SimpleSpinner />} Terapkan
            </button>
          </div>
        </form>
      </Card>

      <div style={{ width: isMobile ? 'calc(100% - 2rem)' : `calc(100% - 110px)`}} className={`fixed flex gap-3 items-center justify-between p-4 rounded-xl shadow-pro top-[80px] left-[16px] lg:left-[96px] py-2 card-widget-gradient w-full z-[50] transform transition-all ease-linear ${!inView && isSuccess ? 'h-[56px] opacity-100' : 'h-[0px] opacity-0 invisible'}`}>
        <div className='flex gap-4'>
          <h5 className='text-sm lg:mr-8 dark:text-[#D9DEE2]'>Screener</h5>
          <ul className='flex items-center gap-2 text-xs font-quicksand-semibold overflow-auto max-w-[200px] lg:max-w-[500px]'>
            {formik?.values?.group_metrics?.map((item, k1) => (
              <li key={k1} className='flex items-center gap-2'>
                {item?.metrics?.map((paramItem, k2) => (
                  <div key={k2} className='flex items-center bg-gray-base-l dark:bg-black-base-d dark:text-[#D9DEE2] p-1 px-2 rounded-md min-w-[100px]'>
                    <span className='line-clamp-1'>{findParamStockScreenerByFieldName(paramItem?.param)?.name} {<span className='px-1'>{CONDITIONAL_OPERATOR?.find(o => o?.id === paramItem?.operator)?.name}</span>} {paramItem?.paramCompareType === 'Value' ? automatedNumberFormat(paramItem?.valueCompare) : findParamStockScreenerByFieldName(paramItem?.paramCompare)?.name}</span>
                    <button onClick={() => handleRemoveParam(item, paramItem)} type="button" className="outline-none flex-none ml-1 hover:text-main-base-l hover:opacity-75">
                      <IconClose className='h-3 w-3'/>
                    </button>
                  </div>
                ))}
              </li>
            ))}
          </ul>
        </div>

        <button
          type="button"
          onClick={() => {
            handleAddMetric();
            window.scrollTo({ top: 0, behavior: 'instant' });
          }}
          className="text-main-base-l py-[6px] lg:px-4 flex items-center justify-center w-8 lg:w-48 ml-auto mr-0 card-widget-gradient border-main-base-l border-2 rounded-lg text-xs font-quicksand-semibold hover:opacity-75 transition-all ease-linear duration-150">
          <IconPlus className="h-4 w-4 lg:mr-1" />
          <span className='hidden lg:block'>Tambah Parameter</span>
        </button>
      </div>

      <RenderIf isTrue={openSaveScreenerForm}>
        <Modal onClose={() => setOpenSaveScreenerForm(false)}>
          <SaveScreenerForm
            initialValues={openSaveScreenerForm}
            onSuccess={() => setOpenSaveScreenerForm(false)}
            onClose={() => setOpenSaveScreenerForm(false)}
          />
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openRemoveScreener}>
        <Modal onClose={() => setOpenRemoveScreener(false)}>
          <ModalDialog
            header={
              <h5 className="font-quicksand-semibold text-base">
                Hapus Screener
              </h5>
            }
            isSubmitting={isSubmitting}
            submitTitle="Hapus"
            backTitle="Batal"
            onClose={() => setOpenRemoveScreener(false)}
            onSubmit={async () => {
              setIsSubmitting(true);
              try {
                await deleteUserStockScreener(openRemoveScreener?.id);
                dispatch(setScreenerIndex({ name: 'IHSG', value: 'all', children: [] }));
                dispatch(setActiveScreener(null));
                dispatch(
                  setGroupMatrics([
                    {
                      id: uniqueId(),
                      group_condition: false,
                      metrics: [
                        {
                          id: uniqueId(),
                          param: '',
                          paramCompareType: 'Value',
                          operator: '=',
                          valueCompare: '',
                          condition: false
                        }
                      ]
                    }
                  ])
                )

                onNewScreener();
                setTimeout(() => formik.setErrors({}), 100);
                setOpenRemoveScreener(false);
              } catch (error) {
                getErrorMessage(error);
              } finally {
                setIsSubmitting(false);
              }
            }}>
            <p className="font-quicksand-semibold text-sm">
              Apakah kamu yakin ingin menghapus screener ini?
            </p>
          </ModalDialog>
        </Modal>
      </RenderIf>
    </div>
  );
}

FormScreener.propTypes = {
  onSubmit: PropTypes.func,
  onNewScreener: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default FormScreener;


function Settings({ screener, onRemoveScreener = () => {}, onEditScreener = () => {} }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const refWrapper = useRef(null);
  
  useOnClickOutside(refWrapper, () => setShowDropdown(false));

  return (
    <button
      onClick={() => setShowDropdown(!showDropdown)}
      type="button"
      className="relative group">
      <IconSetting className="h-5 w-5 text-main-base-l group-hover:opacity-75 transition-all ease-linear duration-150" />

      <div
        ref={refWrapper}
        className={`absolute top-0 w-[220px] pr-2 right-full z-10 ${
          showDropdown ? 'block' : 'hidden'
        }`}>
        <ul className={`shadow-pro rounded-xl overflow-hidden text-xs w-full`}>
          <li>
            <button
              onClick={() => {
                triggerSegmentEvent({ event: 'TR Edit Screener' });
                onEditScreener({...screener, ...screener?.formula });
              }}
              type="button"
              className="font-quicksand-semibold flex justify-between w-full items-center py-[6px] px-3 bg-white dark:hover:bg-gray-base-d dark:bg-black-base-d text-black-base-l transition-all ease-linear duration-200 hover:bg-gray-light-l hover:text-main-base-l">
              <span className="flex-auto block text-left dark:text-black-light-1-d">Ubah Nama</span>
              <IconFileEdit className="h-4 w-4 flex-none text-main-base-l" />
            </button>
          </li>
          <li>
            <button
              onClick={() => onRemoveScreener(screener)}
              type="button"
              className="font-quicksand-semibold flex justify-between w-full items-center py-[6px] px-3 bg-white dark:hover:bg-gray-base-d dark:bg-black-base-d transition-all ease-linear duration-200 hover:bg-gray-light-l text-red-base-l hover:text-red-base-l">
              <span className="flex-auto block text-left">Hapus Screener</span>
              <IconTrashCan className="h-4 w-4 flex-none" />
            </button>
          </li>
        </ul>
      </div>
    </button>
  );
}

Settings.propTypes = {
  screener: PropTypes.object,
  onRemoveScreener: PropTypes.func,
};