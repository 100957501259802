import PropTypes from 'prop-types';

function IconMediaPrev({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 4.00354C13.3334 1.80658 10.8257 0.552089 9.06756 1.86956L3.73599 5.86491C2.31263 6.93154 2.31263 9.06621 3.73597 10.1328L9.06754 14.1283C10.8256 15.4458 13.3334 14.1913 13.3334 11.9943V4.00354ZM9.86713 2.93655C10.7462 2.27781 12 2.90506 12 4.00354V11.9943C12 13.0928 10.7462 13.72 9.86712 13.0613L4.53555 9.06586C3.82388 8.53255 3.82388 7.46521 4.53556 6.9319L9.86713 2.93655Z"
      />
    </svg>
  );
}

IconMediaPrev.propTypes = {
  className: PropTypes.string
};

IconMediaPrev.defaultProps = {
  className: ''
};

export default IconMediaPrev;
