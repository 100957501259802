import PropTypes from 'prop-types';
import IconPlus from 'components/svg/icon-plus';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RenderIf from 'components/basic/render-if';
import { setWatchStockList } from 'store/pina-pro';
import { triggerSegmentEvent } from 'lib/segment';
import useWindowSize from 'hooks/useWindowSize';

import 'components/input/css/custom/customize-input-stock.css';
import TVHeader from 'components/chart/tradingview/_partials/tv-header';
import StockSearch from '../_partials/stock-search';
import TWDoneSummary from 'pages/trading/_partials/tw-done-summary';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function PinaProMultiDoneSummary() {
  const ref = useRef();
  const refScroll = useRef();
  const dispatch = useDispatch();
  const screenSize = useWindowSize();

  const { user} = useBrowserTabAuth();
  const { watchStockList } = useSelector(({ pinaPro }) => pinaPro);

  const [addPosition, setAddPosition] = useState(false);
  const [showAddText, setShowAddText] = useState(true);
  const [maxContentWidth, setMaxContentWidth] = useState('100%');

  const handleAddStock = () => {
    dispatch(setWatchStockList([...watchStockList, { code: '', name: '' }]));
    const { viewport } = refScroll.current.osInstance().elements();
    setTimeout(() => viewport.scrollLeft += 350, 100);
  };

  const handlePickStock = (_stock, _indexToReplace) => {
    dispatch(
      setWatchStockList(
        watchStockList?.map((item, key) => {
          if (key === _indexToReplace) return _stock;
          return item;
        })
      )
    );

    triggerSegmentEvent({
      event: 'TR Add Stock Button Clicked',
      userId: user?.id
    });
  };

  const handleClearStock = (_stock, _indexToReplace) => {
    dispatch(
      setWatchStockList(
        watchStockList?.map((item, key) => {
          if (key === _indexToReplace) return { code: '', name: '' };
          return item;
        })
      )
    );

    triggerSegmentEvent({
      event: 'TR Clear Stock Compare Button Clicked',
      userId: user?.id
    });
  };

  const handleRemoveColumn = (_stock, _indexToReplace) => {
    dispatch(
      setWatchStockList(
        watchStockList?.filter((item, key) => key !== _indexToReplace)
      )
    );

    triggerSegmentEvent({
      event: 'TR Remove Column Compare Button Clicked',
      userId: user?.id
    });
  };

  useEffect(() => {
    let widthContainer = ref.current.clientWidth - 48;
    let lengthContent = watchStockList?.length > 2 ? watchStockList?.length : 3;
    let _addPosition = 250 + 16 + lengthContent * 250;

    if (_addPosition + 120 + 16 > widthContainer) {
      _addPosition = 'calc(100% - 52px)';
      setShowAddText(false);
    } else {
      setShowAddText(true);
    }

    if (_addPosition > 0) _addPosition = _addPosition + 'px';

    setAddPosition(_addPosition);
    setMaxContentWidth(widthContainer + 'px');
  }, [watchStockList, screenSize]);

  return (
    <>
      <Helmet>
        <title>Compare Done Summary | Pina Trade</title>
      </Helmet>

      <div ref={ref} className="grid grid-cols-1 relative pr-12">
        <OverlayScrollbarsComponent
          ref={refScroll}
          className="overlayscrollbars-host-small relative"
          element="span"
          options={{
            resize: 'horizontal',
            scrollbars: {
              autoHide: 'leave',
              autoHideDelay: 50
            }
          }}
          >
          <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d">
            <div className="min-w-[100px] lg:min-w-[250px] text-black-base-l left-0 lg:sticky card-widget-gradient border-r dark:border-gray-base-d z-10">
              <div className="p-2 py-3 border-b dark:border-gray-base-d dark:text-black-light-1-d">Symbol</div>
              <div className="p-2 py-3 text-xs dark:text-black-light-1-d">Company Name</div>
            </div>

            <RenderIf
              isTrue={watchStockList?.length > 0}>
              {watchStockList?.map((item, key) => (
                <div className="min-w-[250px] max-w-[250px]" key={key}>
                  <div className="p-2 py-3 border-l dark:border-gray-base-d border-b flex justify-between">
                    <StockSearch
                      item={item}
                      _key={key}
                      onHandlePickStock={(stock) => handlePickStock(stock, key)}
                      onClearStock={() => handleClearStock(item, key)}
                      onRemoveColumn={() => handleRemoveColumn(item, key)}
                    />
                  </div>
                  <div className="p-2 py-3 border-l dark:border-gray-base-d text-xs">
                    <p className="line-clamp-1">{item?.name}</p>
                  </div>
                </div>
              ))}
            </RenderIf>
          </div>

          <div className="flex">
            <Result
              watchStockList={watchStockList}
              maxContentWidth={maxContentWidth}
            />
          </div>
        </OverlayScrollbarsComponent>

        <div className={`ml-4 absolute ${!addPosition && 'opacity-0'}`} style={{ left: addPosition }}>
          <button
            onClick={handleAddStock}
            type="button"
            disabled={watchStockList?.length > 5}
            className="disabled:bg-gray-light-l disabled:text-gray-base-l dark:disabled:bg-gray-base-d text-center bg-main-base-l text-white dark:text-black-base-d dark:disabled:text-black-light-0-d p-2 py-3 text-sm rounded-lg transition-all ease-linear duration-155 hover:bg-opacity-75 min-h-[80px]">
            <IconPlus className="mx-auto h-6 w-6" />
            <span className={`${!showAddText && 'hidden'}`}>
              Tambah&nbsp;Stock
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

function Result({
  watchStockList = [],
  maxContentWidth = '1005',
}) {

  return (
    <div>
      <button
        type="button"
        style={{ maxWidth: maxContentWidth }}
        className="cursor-default flex sticky left-0 font-quicksand-semibold text-base bg-main-base-l text-white dark:text-black-base-d p-3 px-4 rounded-lg justify-between my-3 w-full">
        <span className="left-4 sticky text-xs lg:text-base">Result</span>
      </button>

      <div>
        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d">
          <div className="min-w-[100px] lg:min-w-[250px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky border-r dark:border-gray-base-d card-widget-gradient z-30">
            <div className="p-2 py-3 text-xs"></div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[250px] max-w-[250px] text-black-base-l border-l dark:border-gray-base-d -mt-4 min-h-[300px]`}
                key={key}>
                {item?.code && 
                  <>
                    <TVHeader className='pb-0' symbol={item?.code} showPriceOnly advancedChart />
                    <TWDoneSummary symbol={item?.code} />
                  </>
                  }
              </div>
            ))}
          </RenderIf>
        </div>
      </div>
    </div>
  );
}

Result.propTypes = {
  watchStockList: PropTypes.array,
  maxContentWidth: PropTypes.string,
};

export default PinaProMultiDoneSummary;
