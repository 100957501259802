export const currencyFormat = (
  amount,
  locale = "id-ID",
  options = {
    currency: "IDR",
    currencyDisplay: "symbol",
    currencySign: "standard",
    minimumFractionDigits: 0,
  },
  customOptions = {
    addDotAfterRupiah: false,
    removeSpaceAfterRp: false,
    removeRupiah: true,
  }
) => {
  const opts = {
    style: "currency",
    currency: "IDR",
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    ...options,
  };
  let formatted = new Intl.NumberFormat(locale, opts).format(amount);

  if (customOptions.addDotAfterRupiah) {
    return formatted.replace("Rp", "Rp.");
  }

  if (customOptions.removeSpaceAfterRp) {
    return formatted.replace(/^Rp\s/, "Rp");
  }

  if (customOptions.removeRupiah) {
    formatted = formatted.replace(/\xA0/g,'');
    formatted = formatted.replace('Rp','');
    return formatted.replace(/^Rp\s/, "");
  }

  return formatted;
};

export const formatIndonesianKMBT = (n, kmbt = {k: ' K', m: ' M', b: ' B', t: ' T', qd: ' Qd', qt: ' Qt', st: ' St'}, c = 2) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(c) + `${kmbt.k}`;
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(c) + `${kmbt.m}`;
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(c) + `${kmbt.b}`;
  if (n >= 1e12 && n < 1e15) return +(n / 1e12).toFixed(c) + `${kmbt.t}`;
  if (n >= 1e15 && n < 1e18) return +(n / 1e15).toFixed(c) + `${kmbt.qd}`;
  if (n >= 1e18 && n < 1e21) return +(n / 1e18).toFixed(c) + `${kmbt.qt}`;
  if (n >= 1e21) return +(n / 1e21).toFixed(c) + `${kmbt.st}`;
};


/**
 * @docs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export const currency = (
  _amount,
  _options = {
    locale: "id-ID",
    currency: "IDR",
    currencyDisplay: "symbol",
    currencySign: "standard",
    minimumFractionDigits: 0,
    // custom
    removeSymbol: false,
    removeSpaceAfterSymbol: false,
    addDotAfterSymbol: false,
  },
) => {
  const options = {
    style: "currency",
    locale: "id-ID",
    currency: "IDR",
    currencyDisplay: "symbol",
    currencySign: "standard",
    minimumFractionDigits: 0,
    // custom
    removeSymbol: false,
    removeSpaceAfterSymbol: false,
    addDotAfterSymbol: false,
    ..._options,
  }

  let formatted = new Intl.NumberFormat(options.locale, options).format(Math.abs(_amount));

  if (options.addDotAfterSymbol) {
    formatted = formatted.replace("Rp", "Rp.");
  }

  if (options.removeSpaceAfterSymbol) {
    formatted = formatted.replace(/^Rp\s/, "Rp");
  }

  if (options.removeSymbol) {
    formatted = formatted.replace(/(^Rp\s|-Rp\s)/, "");
  }

  if (options.removeSymbol) {
    formatted = formatted.replace("Rp", "");
  }

  formatted = formatted.replace("Rp", "IDR");
  formatted = formatted.replace("Rp ", "IDR");

  let isNegative = _amount < 0 ?  '-' : '';

  return isNegative + formatted;
};

export const automatedNumberFormat = (value, maxVal = 100000000) => {
  if (!value) return 0;
  let negatif = value < 0 ? '-' : '';
  
  if (Math.abs(value) > maxVal) {
    return negatif + formatIndonesianKMBT(Math.abs(Number(value)).toFixed(2), {k: ' K', m: ' M', b: ' B', t: ' T', qd: ' Qd', qt: ' Qt', st: ' St'}, 2);
  }
  return currency(Number(value).toFixed(2), { removeSymbol: true });
};

export const generateOrderId = (clientId = '', suffix = '', uniqueId = Date.now().toString().slice(6, 13)) => {
  return 'PRO' + clientId.substring(clientId.length - 4, clientId.length) + suffix + uniqueId;
}