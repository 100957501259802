import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import Tabs from 'components/tabs/tabs';
import CashWithdrawalRequestHistory from '../cash-history/_partials/cash-withdrawal-request-history';
import CashDepositHistory from '../cash-history/_partials/cash-deposit-history';
import CashWithdrawHistory from '../cash-history/_partials/cash-withdraw-history';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

function SectionWithdrawHistory() {
  const [activeIndexTab, setActiveIndexTab] = useState(0);
  const [tabs] = useState([
    {
      index: 0,
      text: 'Permintaan Penarikan Dana'
    },
    {
      index: 1,
      text: 'Top-Up Saldo'
    },
    {
      index: 2,
      text: 'Tarik Saldo'
    }
  ]);

  const handleChangeTab = (index) => {
    setActiveIndexTab(index);
  };

  return (
    <Card className="p-6">
      <Helmet>
        <title>Riwayat Saldo Rekening | Pina Trade</title>
      </Helmet>
      <Tabs
        className="grid grid-cols-3 gap-0 font-quicksand-semibold text-xs lg:text-sm mb-4 -mx-6 -mt-2"
        classObject={{
          li: 'hover:text-main-base-l hover:border-main-base-l',
          active: 'border-main-base-l border-b-2 text-main-base-l',
          inactive: 'border-gray-base-l dark:border-gray-base-d dark: border-b'
        }}
        data={tabs}
        onChange={handleChangeTab}
        initialOpenIndex={activeIndexTab}>
        <RenderIf isTrue={activeIndexTab === 0}>
          <CashWithdrawalRequestHistory />
        </RenderIf>

        <RenderIf isTrue={activeIndexTab === 1}>
          <CashDepositHistory />
        </RenderIf>

        <RenderIf isTrue={activeIndexTab === 2}>
          <CashWithdrawHistory />
        </RenderIf>
      </Tabs>
    </Card>
  );
}

export default SectionWithdrawHistory;
