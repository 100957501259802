import PropTypes from 'prop-types';
import RenderIf from "components/basic/render-if";
import { useSelector } from "react-redux";

function ContentEmpty({ isLoading = true }) {
  const { watchStockList } = useSelector(({ pinaPro }) => pinaPro);

  return (
    <RenderIf isTrue={watchStockList?.length < 3 && !isLoading}>
      {Array(3 - watchStockList?.length < 0 ? 0 : 3 - watchStockList?.length)
        .fill(1)
        .map((el, key) => (
          <div className={`min-w-[150px] max-w-[150px] text-black-base-l`} key={key}>
            <div className={`p-2 py-3 text-xs text-right border-l`}>-</div>
          </div>
        ))}
    </RenderIf>
  );
}

ContentEmpty.propTypes = {
  isLoading: PropTypes.bool,
};

export default ContentEmpty;
