export function useBrowserTabId() {
  let value = window.sessionStorage.getItem('unique-id');

  if (!value || !window.name) {
    value = generateUuid();
    window.sessionStorage.setItem('unique-id', value);
  }

  window.name = value;
  return value;
}

export function generateUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
