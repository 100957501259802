import { Helmet } from 'react-helmet-async';
import SectionProfileTrading from './_partials/profile-trading.section';
import SectionProfileDetail from './_partials/profile-detail.section';

function PageProfile() {
  return (
    <div className='dark:text-black-light-1-d zoom-2'>
      <div className="p-4">
        <Helmet>
          <title>Profile | Pina Trade</title>
        </Helmet>

        <h1 className="text-base mb-4">Profile</h1>

        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 lg:col-span-3 order-2 lg:order-1">
            <SectionProfileTrading className="mb-3" />
          </div>
          <div className="col-span-12 lg:col-span-9 order-1 lg:order-2">
            <SectionProfileDetail />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageProfile;
