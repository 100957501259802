import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import { triggerSegmentEvent } from 'lib/segment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { Link, useNavigate } from 'react-router-dom';
import { useGetTradingIdeasQuery } from 'services/rtk-query/classroom';
import Modal from 'components/modal/modal';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import useWindowSize from 'hooks/useWindowSize';
import { range } from 'lodash';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function PinaProIdeas() {
  const navigate = useNavigate();
  const screenSize = useWindowSize();
  const { user } = useBrowserTabAuth();
  const limit = screenSize?.width > 1536 ? 7 : 6;
  const [params, setParams] = useState({ page: 1, limit });
  const {
    data: response,
    isLoading,
    isFetching
  } = useGetTradingIdeasQuery(params);

  const [featured, setFeatured] = useState([]);
  const [lastIdeas, setLastIdeas] = useState([]);
  const [firstRender, setFirsRender] = useState(true);
  const [isNotRealAccount, setIsNotRealAccount] = useState(false);

  useEffect(() => {
    if (!isLoading && response?.data?.length > 0) {
      if (response?.data?.length > 2) {
        setFeatured([response?.data[0], response?.data[1]]);
        setLastIdeas(response?.data?.filter((_, key) => ![0, 1].includes(key)));
      } else {
        setFeatured(response?.data);
      }
    }
  }, [response, isLoading]);

  useEffect(() => {
    if (firstRender) {
      triggerSegmentEvent({ event: 'Trading Ideas Main Page Viewed' });
      setFirsRender(false);
    }
  }, [firstRender]);

  return (
    <div className="p-4 pb-20 zoom-2">
      <Helmet>
        <title>Trading Ideas | Pina Trade</title>
      </Helmet>

      <h1 className="font-quicksand-semibold text-base mb-3 flex items-center dark:text-black-light-1-d">
        Trading Ideas
      </h1>

      <h5 className="text-sm dark:text-black-light-1-d mb-3">Featured</h5>

      <div className="flex flex-col lg:flex-row gap-3 mb-3">
        <div className="lg:w-1/3 flex-none bg-main-base-l p-6 rounded-xl flex items-center text-white relative overflow-hidden">
          <img
            className="absolute top-0 left-0 w-full h-full"
            src={BGOnboarding}
            alt="Pina Onboarding"
          />

          <img
            className="absolute bottom-0 left-0 w-3/4 h-auto"
            src={BGOnboardingOrnament}
            alt="Pina Onboarding"
          />

          <div className="relative z-10">
            <h2 className="text-2xl lg:text-3xl mb-2">Join Komunitas PINA</h2>
            <p className='text-sm lg:text-base'>
              Ikuti update tiap hari tentang analisa tehnical saham pilihan,
              update sentiment pasar, market news, reports, sector trends, stock
              breakdowns, economic data, dan lebih banyak lagi. <br /> Semua
              gratis!
            </p>

            <a
              href="https://chat.whatsapp.com/J2Q5DmTOf3DBYRR1Va2QFo"
              target='_blank'
              className="inline-flex items-center gap-2 font-quicksand-semibold mt-6 hover:opacity-75 transition-all ease-linear duration-200 text-sm lg:text-base">
              <span>Gabung Di Whatsapp</span>
              <IconChevronSingle className="transform -scale-x-100 h-5 w-5" />
            </a>
          </div>
        </div>
        <div className="flex-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <RenderIf isTrue={isLoading}>
              {[0, 1]?.map((item) => (
                <div key={item}>
                  <SkeletonTheme className="rounded-xl mb-3" height="200px" />
                  <div className="flex">
                    <div className="flex flex-none w-[40%]">
                      <SkeletonTheme
                        className="rounded-full flex-none mr-2"
                        width="40px"
                        height="40px"
                      />
                      <div className="flex-auto">
                        <SkeletonTheme
                          className="rounded-md mb-1"
                          width="80%"
                          height="1rem"
                        />
                        <SkeletonTheme
                          className="rounded-md mb-1"
                          width="70%"
                          height="1rem"
                        />
                      </div>
                    </div>
                    <div className="flex-auto">
                      <SkeletonTheme
                        className="rounded-md mb-2"
                        width="80%"
                        height="1rem"
                      />
                      <SkeletonTheme
                        className="rounded-md mb-2"
                        width="70%"
                        height="1rem"
                      />
                      <SkeletonTheme
                        className="rounded-md mb-2"
                        width="90%"
                        height="1rem"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </RenderIf>

            {featured?.map((item, key) => (
              <Link
                to={user?.realAccount ? `/ideas/${item?._id}` : '#'}
                key={key}
                className="h-full"
                onClick={() => {
                  if (!user?.realAccount) setIsNotRealAccount(true);
                  triggerSegmentEvent({
                    event: 'Trading Ideas Clicked',
                    properties: {
                      page_title: item?.title
                    }
                  });
                }}>
                <Card
                  className="p-6 transition-all ease-linear duration-100 hover:opacity-75 cursor-pointer h-full"
                  onClick={() => navigate(`/ideas/${item?._id}`)}>
                  <img
                    src={item?.featured_image?.public_url}
                    className="w-full h-auto aspect-[588/242] object-cover rounded-lg overflow-hidden border dark:border-gray-base-d"
                  />
                  <h3 className="text-main-base-l my-3 text-xl">
                    {item?.title}
                  </h3>
                  <div className="flex">
                    <div className="flex-none w-56">
                      <div className="flex">
                        <div className="flex-none">
                          <RenderIf isTrue={item?.author?.avatar}>
                            <img
                              src={item?.author?.avatar}
                              className="h-6 w-6 rounded-full mr-2 mt-[3px] border dark:border-gray-base-d"
                            />
                          </RenderIf>

                          <RenderIf isTrue={!item?.author?.avatar}>
                            <span className="h-6 w-6 rounded-full mr-2 bg-gray-light-l font-quicksand-semibold uppercase text-2xs border border-gray-light-0-d flex items-center justify-center mt-[3px]">
                              {item?.author?.name?.charAt(0)}
                            </span>
                          </RenderIf>
                        </div>
                        <div>
                          <h5 className="text-sm dark:text-black-light-1-d">
                            {item?.author?.name}
                          </h5>
                          <p className="text-xs text-gray-light-0-d dark:text-black-light-1-d">
                            {item?.author?.title || 'Trader Expert'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex-auto text-xs text-justify text-gray-light-0-d dark:text-black-light-0-d ${
                        user?.realAccount ? 'line-clamp-4' : 'line-clamp-2'
                      }`}>
                      {item?.excerpt}
                    </div>
                  </div>
                </Card>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <hr className="my-6 dark:border-gray-base-d" />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-3 flex-auto">
        {lastIdeas?.map((item, key) => (
          <Card key={key} className="overflow-hidden rounded-xl">
            <Link
              to={user?.realAccount ? `/ideas/${item?._id}` : '#'}
              className="transition-all ease-linear duration-100 hover:opacity-75 cursor-pointer block"
              onClick={() => {
                if (!user?.realAccount) setIsNotRealAccount(true);
                triggerSegmentEvent({
                  event: 'Trading Ideas Clicked',
                  properties: {
                    page_title: item?.title
                  }
                });
              }}>
              <img
                src={item?.featured_image?.public_url}
                className="w-full h-auto aspect-[588/242] object-cover rounded-lg overflow-hidden border dark:border-gray-base-d"
              />

              <div className="px-4 pb-4">
                <h3 className="text-main-base-l my-3 text-sm">{item?.title}</h3>

                <div className="flex mb-3">
                  <div className="flex-none">
                    <RenderIf isTrue={item?.author?.avatar}>
                      <img
                        src={item?.author?.avatar}
                        className="h-6 w-6 rounded-full mr-2 mt-[3px] border dark:border-gray-base-d"
                      />
                    </RenderIf>

                    <RenderIf isTrue={!item?.author?.avatar}>
                      <span className="h-6 w-6 rounded-full mr-2 bg-gray-light-l font-quicksand-semibold uppercase text-2xs border border-gray-light-0-d dark:border-gray-base-d flex items-center justify-center mt-[3px]">
                        {item?.author?.name?.charAt(0)}
                      </span>
                    </RenderIf>
                  </div>
                  <div>
                    <h5 className="text-sm dark:text-black-light-1-d">
                      {item?.author?.name}
                    </h5>
                    <p className="text-xs text-gray-light-0-d dark:text-black-light-1-d">
                      {item?.author?.title || 'Trader Expert'}
                    </p>
                  </div>
                </div>

                <div
                  className={`flex-auto text-xs text-gray-light-0-d dark:text-black-light-0-d ${
                    user?.realAccount ? 'line-clamp-4' : 'line-clamp-2'
                  }`}>
                  {item?.excerpt}
                </div>
              </div>
            </Link>
          </Card>
        ))}

        <RenderIf isTrue={isFetching}>
          {range(limit - 2)?.map((item) => (
            <div key={item}>
              <SkeletonTheme className="rounded-md mb-2" height="120px" />
              <SkeletonTheme
                className="rounded-md mb-2 w-[90%]"
                height="1.2rem"
              />
              <SkeletonTheme
                className="rounded-md mb-2 w-[40%]"
                height="1.2rem"
              />

              <div className="flex flex-none my-4">
                <SkeletonTheme
                  className="rounded-full flex-none mr-2"
                  width="32px"
                  height="32px"
                />
                <div className="flex-auto">
                  <SkeletonTheme
                    className="rounded-md mb-1"
                    width="80%"
                    height="1rem"
                  />
                  <SkeletonTheme
                    className="rounded-md mb-1"
                    width="50%"
                    height="1rem"
                  />
                </div>
              </div>

              <SkeletonTheme
                className="rounded-md mb-2 w-[90%]"
                height="1.2rem"
              />
              <SkeletonTheme
                className="rounded-md mb-2 w-[90%]"
                height="1.2rem"
              />
            </div>
          ))}
        </RenderIf>
      </div>

      <RenderIf isTrue={isNotRealAccount}>
        <Modal onClose={() => setIsNotRealAccount(false)}>
          <ModalExploreExpired
            showClose
            eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
            title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
            description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
            onClose={() => setIsNotRealAccount(false)}
          />
        </Modal>
      </RenderIf>

      <RenderIf
        isTrue={
          response?.pagination?.entries != response?.data?.length &&
          lastIdeas?.length > 0
        }>
        <button
          type="button"
          onClick={() => setParams({ ...params, limit: params?.limit + (limit - 2) })}
          className="border-main-base-l border-2 w-64 text-center text-sm font-quicksand-semibold mx-auto block px-4 py-[8px] rounded-lg my-10 bg-gray-light-l dark:bg-black-dark-d dark:text-main-base-l hover:opacity-75 transition-all ease-linear duration-150">
          Lihat Lebih Banyak
        </button>
      </RenderIf>
    </div>
  );
}

export default PinaProIdeas;
