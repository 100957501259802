import PropTypes from 'prop-types';

function IconStar({ className, active, activeColor = "#AB9753" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9999 3L15.5267 8.1459L21 9.5L17.5 14L18 21L12 18.5L6 21L6.5 14L2.5 9.5L8.47323 8.1459L11.9999 3Z"
        fill={`${active ? activeColor : 'transparent'}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.69907 3.5187C10.5615 1.48872 13.4386 1.48872 14.301 3.5187L15.8586 7.18513L19.7069 7.50745C21.8844 7.68982 22.7965 10.3814 21.1785 11.8499L18.2355 14.521L19.1989 18.9571C19.6855 21.1984 17.1495 22.8574 15.291 21.5135L12 19.134L8.70911 21.5135C6.85053 22.8574 4.31452 21.1984 4.80122 18.9571L5.76453 14.521L2.82155 11.85C1.20347 10.3814 2.11554 7.68982 4.29305 7.50745L8.14146 7.18513L9.69907 3.5187ZM12.4602 4.30071C12.2877 3.89472 11.7123 3.89472 11.5398 4.30071L9.86431 8.24473C9.64672 8.75691 9.16346 9.10653 8.60892 9.15298L4.45998 9.50047C4.02447 9.53694 3.84206 10.0753 4.16567 10.369L7.32252 13.2341C7.71952 13.5944 7.89404 14.1392 7.78027 14.6631L6.75567 19.3815C6.65833 19.8298 7.16553 20.1616 7.53725 19.8928L11.1211 17.3015C11.6457 16.9222 12.3544 16.9222 12.8789 17.3015L16.4628 19.8928C16.8345 20.1616 17.3417 19.8298 17.2444 19.3815L16.2198 14.6631C16.106 14.1392 16.2805 13.5944 16.6775 13.2341L19.8343 10.369C20.1579 10.0753 19.9755 9.53694 19.54 9.50047L15.3911 9.15298C14.8366 9.10653 14.3534 8.75691 14.1358 8.24473L12.4602 4.30071Z"
      />
    </svg>
  );
}

IconStar.propTypes = {
  className: PropTypes.string,
  activeColor: PropTypes.string,
  active: PropTypes.bool
};

export default IconStar;
