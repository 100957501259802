import BlankLayout, { LAYOUT_BLANK } from './blank-layout';
import TradingLayout, { LAYOUT_TRADING } from './trading-layout';
import OnboardingLayout, { LAYOUT_ONBOARDING } from './onboarding-layout';
import AdminLayout, { LAYOUT_ADMIN } from './admin-layout';

const getLayout = (layoutName) => {
  switch (layoutName) {
    case LAYOUT_BLANK:
      return BlankLayout;
    case LAYOUT_TRADING:
      return TradingLayout;
    case LAYOUT_ONBOARDING:
      return OnboardingLayout;
    case LAYOUT_ADMIN:
      return AdminLayout;
    default:
      return LAYOUT_BLANK;
  }
};

export default getLayout;
export { LAYOUT_BLANK, LAYOUT_TRADING, LAYOUT_ONBOARDING, LAYOUT_ADMIN }
