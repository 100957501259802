import PropTypes from 'prop-types';
import { isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';
import 'components/basic/css/custom-calendar.css';
import Card from 'components/basic/card';
import InputSelect from 'components/input/input-select';
import { useGetStockCalendarQuery } from 'services/rtk-query/stock';
import { groupBy, isEmpty } from 'lodash';
import { currency } from 'utils/format';
import Accordion from 'components/basic/accordion-simple/accordion'
import AccordionItem from 'components/basic/accordion-simple/accordion-item'
import { triggerSegmentEvent } from 'lib/segment';
import { dayjsLocal } from 'utils/formatter/date';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import useElementSize from 'hooks/useElementSize';
import cn from 'utils/classname';

function TWStockCalendar({ title = <h2 className='text-sm font-quicksand-semibold px-3 mb-4 dark:text-black-light-1-d'>Kalender Saham</h2>, isCardLess = false}) {
  const isBrowserWakeUp = useBrowserWakeUp();
  const [value, setValue] = useState(new Date());
  const [firstDate, setFirstDate] = useState(dayjsLocal(`${dayjsLocal(value).format('MM')}/01/${dayjsLocal(value).format('YYYY')}`).subtract(1, 'week').toDate());
  const [filter, setFilter] = useState('All');
  const [params, setParams] = useState({ startDate: dayjsLocal(firstDate).format('MM/DD/YYYY'), endDate: dayjsLocal(firstDate).add(2, 'month').format('MM/DD/YYYY')});

  const [datas, setDatas] = useState([]);
  const { data, isFetching, refetch } = useGetStockCalendarQuery(params, { refetchOnMountOrArgChange: true });

  const { ref, dimensions } = useElementSize();
  const { width } = dimensions ?? {};

  const onChange = (_value) => {
    setFirstDate(dayjsLocal(`${dayjsLocal(_value).format('MM')}/01/${dayjsLocal(_value).format('YYYY')}`).subtract(1, 'week').toDate());
    setValue(_value);
    triggerSegmentEvent({ event: 'TR Calender Saham Clicked'});
  };

  useEffect(() => {
    setParams({ startDate: dayjsLocal(firstDate).format('MM/DD/YYYY'), endDate: dayjsLocal(firstDate).add(2, 'month').format('MM/DD/YYYY')});
  }, [firstDate])

  useEffect(() => {
    let _tempDatas = [];

    const groupByMultipleDate = {
      ...groupBy(data, 'ex_date'),
      ...groupBy(data, 'cum_date'),
      ...groupBy(data, 'distribution_date'),
      ...groupBy(data, 'trading_start'),
      ...groupBy(data, 'trading_end'),
      ...groupBy(data, 'maturity_date'),
      ...groupBy(data, 'rec_date'),
      ...groupBy(data, 'split_date')
    }

    if (!isFetching && !isEmpty(groupByMultipleDate)) {
      Object.keys(groupByMultipleDate)?.map((obj) => {
        if (dayjsLocal(obj).isValid()) {
          _tempDatas.push({
            dDate: dayjsLocal(obj).toDate(),
            dividens: groupByMultipleDate[obj]?.filter((i) => ['deviden saham', 'cash dividend', 'interim dividend']?.includes(i?.type?.toLowerCase())),
            rups: groupByMultipleDate[obj]?.filter((i) => ['rups',]?.includes(i?.type?.toLowerCase())),
            stockSplits: groupByMultipleDate[obj]?.filter((i) => ['stock split', 'reverse stock']?.includes(i?.type?.toLowerCase())),
            warrants: groupByMultipleDate[obj]?.filter((i) => ['saham bonus', 'warrant', 'right issue']?.includes(i?.type?.toLowerCase())),
          })
        }
      });

      setDatas(_tempDatas);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  function tileContent({ date, view }) {
    if (view === 'month') {
      if (datas.find((item) => isSameDay(item?.dDate, date))) {
        return (
          <div className="absolute left-0 bottom-[1px] w-full">
            <ul className="w-full flex flex-nowrap justify-center items-center">
              {datas.find((item) => isSameDay(item?.dDate, date) && item?.dividens?.length > 0 && ['All', 'Dividen'].includes(filter)) &&
                (<li className="h-[4px] w-[4px] bg-[#0C42FF] rounded-full block mx-[1px]"></li>)}
              {datas.find((item) => isSameDay(item?.dDate, date) && item?.rups?.length > 0 && ['All', 'RUPS'].includes(filter)) &&
                (<li className="h-[4px] w-[4px] bg-[#AB58FF] rounded-full block mx-[1px]"></li>)}
              {datas.find((item) => isSameDay(item?.dDate, date) && item?.stockSplits?.length > 0 && ['All', 'Stock Split'].includes(filter)) &&
                (<li className="h-[4px] w-[4px] bg-[#FB9825] rounded-full block mx-[1px]"></li>)}
              {datas.find((item) => isSameDay(item?.dDate, date) && item?.warrants?.length > 0 && ['All', 'Warrant'].includes(filter)) &&
                (<li className="h-[4px] w-[4px] bg-[#fb25d0] rounded-full block mx-[1px]"></li>)}
            </ul>
          </div>
        );
      }
    }
  }

  return (
    <Card className={`p-2 py-4 ${isCardLess && 'shadow-none'}`}>
      {title}
      <div className="flex justify-between mb-0 px-3 items-center">
        <div className='flex-none w-full'>
          <InputSelect
            name="periods"
            value={filter}
            label=""
            className="mb-2"
            size="small"
            options={[
              {
                id: 'All',
                name: 'Semua'
              },
              {
                id: 'Dividen',
                name: 'Dividen'
              },
              {
                id: 'RUPS',
                name: 'RUPS'
              },
              {
                id: 'Warrant',
                name: 'Warrant'
              }
            ]}
            onChange={(e) => {
              if (e.target.value) setFilter(e.target.value);
            }}
          />
        </div>
      </div>

      <div ref={ref} className={cn('grid grid-cols-1', width > 600 && 'grid-cols-2')}>
        <div className='px-1'>
          <Calendar
            onChange={onChange}
            value={value}
            tileContent={tileContent}
            showNavigation={true}
            onActiveStartDateChange={(date) => {
              onChange(date?.activeStartDate)
            }}
          />
        </div>

        <div className="px-3">
          <ul className="flex justify-between my-4 font-quicksand-semibold">
            <li className="flex items-center text-xs text-gray-light-0-d dark:text-black-light-1-d">
              <span className="h-[5px] w-[5px] bg-[#0C42FF] flex-none mr-1 rounded-full"></span>
              <span>Dividen</span>
            </li>
            <li className="flex items-center text-xs text-gray-light-0-d dark:text-black-light-1-d">
              <span className="h-[5px] w-[5px] bg-[#AB58FF] flex-none mr-1 rounded-full"></span>
              <span>RUPS</span>
            </li>
            <li className="flex items-center text-xs text-gray-light-0-d dark:text-black-light-1-d">
              <span className="h-[5px] w-[5px] bg-[#FB9825] flex-none mr-1 rounded-full"></span>
              <span>Stock Split</span>
            </li>
            <li className="flex items-center text-xs text-gray-light-0-d dark:text-black-light-1-d">
              <span className="h-[5px] w-[5px] bg-[#fb25d0] flex-none mr-1 rounded-full"></span>
              <span>Warrant</span>
            </li>
          </ul>

          <h5 className="font-quicksand-semibold text-xs mb-4 mt-6 dark:text-black-light-1-d">
            {dayjsLocal(value).format('dddd, DD MMM YYYY')}
          </h5>

          {/* DIVIDEN */}

          {
            datas.find((item) => isSameDay(item?.dDate, value) && item?.dividens?.length > 0 && ['All', 'Dividen'].includes(filter)) &&
            <>
              <div className="flex items-center text-xs font-quicksand-semibold mt-3 dark:text-black-light-1-d">
                <span className="h-[5px] w-[5px] bg-[#0C42FF] flex-none mr-2 rounded-full"></span>
                <span>Dividen</span>
              </div>

              <ul className='py-3 max-h-[300px] overflow-auto -mx-3 px-3'>
                {
                  datas.find((item) => isSameDay(item?.dDate, value) && item?.dividens?.length > 0)?.dividens?.map((item, key) => (
                    <li key={key} className='py-1'>
                      <Accordion className="relative">
                        <AccordionItem
                          expandable={true}
                          expanded={key === 0}
                          className="relative border-b dark:border-gray-base-d pb-3"
                        >
                          <AccordionItem.Slot name="Header">
                            <div className='flex justify-between items-center text-xs group hover:opacity-75 transition-all ease-linear duration-200 hover:text-main-base-l'>
                              <div className='flex-none mr-2'>
                                <span className='py-1 px-2 border rounded-md dark:border-black-light-1-l font-quicksand-semibold dark:text-black-light-1-d'>{item?.code}</span>
                              </div>
                              <div className='text-right dark:text-black-light-1-d'>
                                <span className='block uppercase font-quicksand-semibold'>{item?.type}</span>
                                {item?.cum_date && isSameDay(value, dayjsLocal(item?.cum_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Cum Date)</span></div>}
                                {item?.ex_date && isSameDay(value, dayjsLocal(item?.ex_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Ex Date)</span></div>}
                                {item?.distribution_date && isSameDay(value, dayjsLocal(item?.distribution_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Distribution Date)</span></div>}
                                {item?.trading_start && isSameDay(value, dayjsLocal(item?.trading_start).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Trading Start Date)</span></div>}
                                {item?.trading_end && isSameDay(value, dayjsLocal(item?.trading_end).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Trading End Date)</span></div>}
                                {item?.maturity_date && isSameDay(value, dayjsLocal(item?.maturity_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Maturity Date)</span></div>}
                                {item?.rec_date && isSameDay(value, dayjsLocal(item?.rec_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Rec Date)</span></div>}
                                {item?.split_date && isSameDay(value, dayjsLocal(item?.split_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Split Date)</span></div>}
                              </div>
                            </div>
                          </AccordionItem.Slot>

                          <AccordionItem.Slot name="Content">
                            <div className='font-quicksand-regular text-2xs mt-2 dark:text-black-light-1-d'>
                              {item?.old_ratio && <div className="flex justify-between"><span className='flex-none mr-2'>Old Ratio:</span><span className='block my-1'>{currency(item?.old_ratio, { removeSymbol : true })}</span></div>}
                              {item?.new_ratio && <div className="flex justify-between"><span className='flex-none mr-2'>New Ratio:</span><span className='block my-1'>{currency(item?.new_ratio, { removeSymbol : true })}</span></div>}
                              {item?.price && <div className="flex justify-between"><span className='flex-none mr-2'>Price:</span><span className='block my-1'>{currency(item?.price, { removeSymbol : true })}</span></div>}
                              {item?.description && item?.description != '-' && <p className='text-2xs mt-1'>{item?.description}</p>}
                            </div>
                          </AccordionItem.Slot>
                        </AccordionItem>
                      </Accordion>
                    </li>
                  ))
                }
              </ul>
            </>
          }


          {/* RUPS */}

          {
            datas.find((item) => isSameDay(item?.dDate, value) && item?.rups?.length > 0 && ['All', 'RUPS'].includes(filter)) &&
            <>
              <div className="flex items-center text-xs font-quicksand-semibold mt-3 dark:text-black-light-1-d">
                <span className="h-[5px] w-[5px] bg-[#AB58FF] flex-none mr-2 rounded-full"></span>
                <span>RUPS</span>
              </div>

              <ul className='py-3 max-h-[300px] overflow-auto -mx-3 px-3'>
                {
                  datas.find((item) => isSameDay(item?.dDate, value) && item?.rups?.length > 0)?.rups?.map((item, key) => (
                    <li key={key} className='py-1'>
                      <Accordion className="relative">
                        <AccordionItem
                          expandable={true}
                          expanded={key === 0}
                          className="relative border-b dark:border-gray-base-d pb-3"
                        >
                          <AccordionItem.Slot name="Header">
                            <div className='flex justify-between items-center text-xs group hover:opacity-75 transition-all ease-linear duration-200 hover:text-main-base-l'>
                              <div className='flex-none mr-2'>
                                <span className='py-1 px-2 border rounded-md dark:border-black-light-1-l font-quicksand-semibold dark:text-black-light-1-d'>{item?.code}</span>
                              </div>
                              <div className='text-right dark:text-black-light-1-d'>
                                <span className='block uppercase font-quicksand-semibold'>{item?.type}</span>
                                {item?.cum_date && isSameDay(value, dayjsLocal(item?.cum_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Cum Date)</span></div>}
                                {item?.ex_date && isSameDay(value, dayjsLocal(item?.ex_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Ex Date)</span></div>}
                                {item?.distribution_date && isSameDay(value, dayjsLocal(item?.distribution_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Distribution Date)</span></div>}
                                {item?.trading_start && isSameDay(value, dayjsLocal(item?.trading_start).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Trading Start Date)</span></div>}
                                {item?.trading_end && isSameDay(value, dayjsLocal(item?.trading_end).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Trading End Date)</span></div>}
                                {item?.maturity_date && isSameDay(value, dayjsLocal(item?.maturity_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Maturity Date)</span></div>}
                                {item?.rec_date && isSameDay(value, dayjsLocal(item?.rec_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Rec Date)</span></div>}
                                {item?.split_date && isSameDay(value, dayjsLocal(item?.split_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Split Date)</span></div>}
                              </div>
                            </div>
                          </AccordionItem.Slot>

                          <AccordionItem.Slot name="Content">
                            <div className='font-quicksand-regular text-2xs mt-2 dark:text-black-light-1-d'>
                              {item?.old_ratio && <div className="flex justify-between"><span className='flex-none mr-2'>Old Ratio:</span><span className='block my-1'>{currency(item?.old_ratio, { removeSymbol : true })}</span></div>}
                              {item?.new_ratio && <div className="flex justify-between"><span className='flex-none mr-2'>New Ratio:</span><span className='block my-1'>{currency(item?.new_ratio, { removeSymbol : true })}</span></div>}
                              {item?.price && <div className="flex justify-between"><span className='flex-none mr-2'>Price:</span><span className='block my-1'>{currency(item?.price, { removeSymbol : true })}</span></div>}
                              {item?.description && item?.description != '-' && <p className='text-2xs mt-1'>{item?.description}</p>}
                            </div>
                          </AccordionItem.Slot>
                        </AccordionItem>
                      </Accordion>
                    </li>
                  ))
                }
              </ul>
            </>
          }

          {/* Stock Splits */}

          {
            datas.find((item) => isSameDay(item?.dDate, value) && item?.stockSplits?.length > 0 && ['All', 'Stock Split'].includes(filter)) &&
            <>
              <div className="flex items-center text-xs font-quicksand-semibold mt-3 dark:text-black-light-1-d">
                <span className="h-[5px] w-[5px] bg-[#FB9825] flex-none mr-2 rounded-full"></span>
                <span>Stock Split</span>
              </div>

              <ul className='py-3 max-h-[300px] overflow-auto -mx-3 px-3'>
                {
                  datas.find((item) => isSameDay(item?.dDate, value) && item?.stockSplits?.length > 0)?.stockSplits?.map((item, key) => (
                    <li key={key} className='py-1'>
                      <Accordion className="relative">
                        <AccordionItem
                          expandable={true}
                          expanded={key === 0}
                          className="relative border-b dark:border-gray-base-d pb-3"
                        >
                          <AccordionItem.Slot name="Header">
                            <div className='flex justify-between items-center text-xs group hover:opacity-75 transition-all ease-linear duration-200 hover:text-main-base-l'>
                              <div className='flex-none mr-2'>
                                <span className='py-1 px-2 border rounded-md dark:border-black-light-1-l font-quicksand-semibold dark:text-black-light-1-d'>{item?.code}</span>
                              </div>
                              <div className='text-right dark:text-black-light-1-d'>
                                <span className='block uppercase font-quicksand-semibold'>{item?.type}</span>
                                {item?.cum_date && isSameDay(value, dayjsLocal(item?.cum_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Cum Date)</span></div>}
                                {item?.ex_date && isSameDay(value, dayjsLocal(item?.ex_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Ex Date)</span></div>}
                                {item?.distribution_date && isSameDay(value, dayjsLocal(item?.distribution_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Distribution Date)</span></div>}
                                {item?.trading_start && isSameDay(value, dayjsLocal(item?.trading_start).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Trading Start Date)</span></div>}
                                {item?.trading_end && isSameDay(value, dayjsLocal(item?.trading_end).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Trading End Date)</span></div>}
                                {item?.maturity_date && isSameDay(value, dayjsLocal(item?.maturity_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Maturity Date)</span></div>}
                                {item?.rec_date && isSameDay(value, dayjsLocal(item?.rec_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Rec Date)</span></div>}
                                {item?.split_date && isSameDay(value, dayjsLocal(item?.split_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Split Date)</span></div>}
                              </div>
                            </div>
                          </AccordionItem.Slot>

                          <AccordionItem.Slot name="Content">
                            <div className='font-quicksand-regular text-2xs mt-2 dark:text-black-light-1-d'>
                              {item?.old_ratio && <div className="flex justify-between"><span className='flex-none mr-2'>Old Ratio:</span><span className='block my-1'>{currency(item?.old_ratio, { removeSymbol : true })}</span></div>}
                              {item?.new_ratio && <div className="flex justify-between"><span className='flex-none mr-2'>New Ratio:</span><span className='block my-1'>{currency(item?.new_ratio, { removeSymbol : true })}</span></div>}
                              {item?.price && <div className="flex justify-between"><span className='flex-none mr-2'>Price:</span><span className='block my-1'>{currency(item?.price, { removeSymbol : true })}</span></div>}
                              {item?.description && item?.description != '-' && <p className='text-2xs mt-1'>{item?.description}</p>}
                            </div>
                          </AccordionItem.Slot>
                        </AccordionItem>
                      </Accordion>
                    </li>
                  ))
                }
              </ul>
            </>
          }

          {/* Warrant */}
          
          {
            datas.find((item) => isSameDay(item?.dDate, value) && item?.warrants?.length > 0 && ['All', 'Warrant'].includes(filter)) &&
            <>
              <div className="flex items-center text-xs font-quicksand-semibold mt-3 dark:text-black-light-1-d">
                <span className="h-[5px] w-[5px] bg-[#fb25d0] flex-none mr-2 rounded-full"></span>
                <span>Warrant</span>
              </div>

              <ul className='py-3 max-h-[300px] overflow-auto -mx-3 px-3'>
                {
                  datas.find((item) => isSameDay(item?.dDate, value) && item?.warrants?.length > 0)?.warrants?.map((item, key) => (
                    <li key={key} className='py-1'>
                      <Accordion className="relative">
                        <AccordionItem
                          expandable={true}
                          expanded={key === 0}
                          className="relative border-b dark:border-gray-base-d pb-3"
                        >
                          <AccordionItem.Slot name="Header">
                            <div className='flex justify-between items-center text-xs group hover:opacity-75 transition-all ease-linear duration-200 hover:text-main-base-l'>
                              <div className='flex-none mr-2'>
                                <span className='py-1 px-2 border rounded-md dark:border-black-light-1-l font-quicksand-semibold dark:text-black-light-1-d'>{item?.code}</span>
                              </div>
                              <div className='text-right dark:text-black-light-1-d'>
                                <span className='block uppercase font-quicksand-semibold'>{item?.type}</span>
                                {item?.cum_date && isSameDay(value, dayjsLocal(item?.cum_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Cum Date)</span></div>}
                                {item?.ex_date && isSameDay(value, dayjsLocal(item?.ex_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Ex Date)</span></div>}
                                {item?.distribution_date && isSameDay(value, dayjsLocal(item?.distribution_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Distribution Date)</span></div>}
                                {item?.trading_start && isSameDay(value, dayjsLocal(item?.trading_start).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Trading Start Date)</span></div>}
                                {item?.trading_end && isSameDay(value, dayjsLocal(item?.trading_end).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Trading End Date)</span></div>}
                                {item?.maturity_date && isSameDay(value, dayjsLocal(item?.maturity_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Maturity Date)</span></div>}
                                {item?.rec_date && isSameDay(value, dayjsLocal(item?.rec_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Rec Date)</span></div>}
                                {item?.split_date && isSameDay(value, dayjsLocal(item?.split_date).toDate()) && <div className="flex justify-end"><span className='flex-none'>(Split Date)</span></div>}
                              </div>
                            </div>
                          </AccordionItem.Slot>

                          <AccordionItem.Slot name="Content">
                            <div className='font-quicksand-regular text-2xs mt-2 dark:text-black-light-1-d'>
                              {item?.old_ratio && <div className="flex justify-between"><span className='flex-none mr-2'>Old Ratio:</span><span className='block my-1'>{currency(item?.old_ratio, { removeSymbol : true })}</span></div>}
                              {item?.new_ratio && <div className="flex justify-between"><span className='flex-none mr-2'>New Ratio:</span><span className='block my-1'>{currency(item?.new_ratio, { removeSymbol : true })}</span></div>}
                              {item?.price && <div className="flex justify-between"><span className='flex-none mr-2'>Price:</span><span className='block my-1'>{currency(item?.price, { removeSymbol : true })}</span></div>}
                              {item?.description && item?.description != '-' && <p className='text-2xs mt-1'>{item?.description}</p>}
                            </div>
                          </AccordionItem.Slot>
                        </AccordionItem>
                      </Accordion>
                    </li>
                  ))
                }
              </ul>
            </>
          }
        </div>
      </div>
    </Card>
  );
}

TWStockCalendar.propTypes = {
  title: PropTypes.any,
  isCardLess: PropTypes.bool,
};


export default TWStockCalendar;
