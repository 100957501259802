import RenderIf from 'components/basic/render-if';
import IconEmail from 'components/svg/icon-email';
import IconLineChart from 'components/svg/icon-line-chart';
import { useEffect, useState } from 'react';
import {
  useDeleteAllUserNotificationMutation,
  useGetUserNotificationQuery,
  useUpdateUserNotificationByIdMutation,
} from 'services/rtk-query/user';

import { dateFromNow } from 'utils/formatter/date';
import SkeletonTheme from 'components/basic/skeleton-theme';
import useToastr from 'hooks/useToastr';
import { useDispatch } from 'react-redux';
import { setUnreadNotifLength } from 'store/pina-pro';
import IconSetting from 'components/svg/icon-setting';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import Modal from 'components/modal/modal';
import ModalDialog from 'components/modal/components/modal-dialog';

function ListMessage() {
  const dispatch = useDispatch();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();

  const [isSubmiting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [openModalDeleteAllNotification, setOpenModalDeleteAllNotification] = useState(false);
  const [params, setParams] = useState({
    type: 'general',
    page: 0,
    size: 20,
    tag: ''
  });

  const { data: notifications, isFetching } = useGetUserNotificationQuery(params);
  const [readSingleNotification] = useUpdateUserNotificationByIdMutation();
  const [deleteAlNotification] = useDeleteAllUserNotificationMutation();

  const handleReadSingleMessage = async (uuid) => {
    try {
      setIsSubmitting(true);
      await readSingleNotification(uuid).unwrap();
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteAllMessage = async () => {
    try {
      setIsSubmitting(true);
      await deleteAlNotification();
      dispatch(setUnreadNotifLength(0));
      setData([]);
      toastr.success('Semua pesan berhasil dihapus!');
      setOpenModalDeleteAllNotification(false);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLoadMore = async () => {
    setParams({ ...params, size: params?.size + 20 });
  };

  useEffect(() => {
    setData(notifications?.content);
  }, [notifications]);

  return (
    <>
      <RenderIf isTrue={data?.length === 0 && !isFetching}>
        <p className="text-sm text-center text-gray-light-0-d mb-2">Belum ada notifikasi.</p>
      </RenderIf>

      <ul>
        {data?.map((item, key) => (
          <li
            key={key}
            onClick={() => handleReadSingleMessage(item?.uuid)}
            className="flex items-start border dark:border-gray-base-d rounded-lg hover:bg-gray-light-l dark:hover:bg-black-dark-d cursor-pointer hover:bg-opacity-50 p-4 relative mb-4">
            <span>
              <RenderIf isTrue={item?.iconType === 'inbox'}>
                <IconEmail className="h-6 w-6 text-gray-light-0-d mr-1" />
              </RenderIf>
              <RenderIf isTrue={item?.iconType === 'gear'}>
                <IconSetting className="h-6 w-6 text-gray-light-0-d mr-1" />
              </RenderIf>
              <RenderIf isTrue={!item?.iconType}>
                <IconLineChart className="h-6 w-6 text-gray-light-0-d mr-1" />
              </RenderIf>
            </span>
            <div className="ml-1">
              <div className="flex items-center">
                <h5 className="font-quicksand-semibold text-sm line-clamp-1">
                  {item?.title}
                </h5>
              </div>
              <p className="text-xs font-quicksand-semibold" dangerouslySetInnerHTML={{__html: item?.msg}}></p>
              <span className="flex mt-1 text-xs">
                {dateFromNow(item?.created_at)}
              </span>

            </div>

            <RenderIf isTrue={!item?.read}>
              <span className="h-2 w-2 bg-red-base-l rounded-full absolute top-3 right-3"></span>
            </RenderIf>
          </li>
        ))}
      </ul>

      <RenderIf isTrue={isFetching && data?.length === 0}>
        <div className='my-3 px-6'>
          <SkeletonTheme className='mb-2' width="40%" height="1rem" borderRadius="6px" />
          <SkeletonTheme className='mb-2' width="100%" height="1rem" borderRadius="6px" />
          <SkeletonTheme className='mb-2' width="80%" height="1rem" borderRadius="6px" />
          <SkeletonTheme className='mb-5' width="20%" height="1rem" borderRadius="6px" />

          <SkeletonTheme className='mb-2' width="40%" height="1rem" borderRadius="6px" />
          <SkeletonTheme className='mb-2' width="100%" height="1rem" borderRadius="6px" />
          <SkeletonTheme className='mb-2' width="80%" height="1rem" borderRadius="6px" />
          <SkeletonTheme className='mb-5' width="20%" height="1rem" borderRadius="6px" />
        </div>
      </RenderIf>

      <div className={`sticky left-0 bottom-0 grid gap-4 font-quicksand-semibold text-sm dark:border-gray-base-d card-widget-gradient pt-4 ${notifications?.content?.length > 0 && 'border-t mt-4 pb-6'} ${notifications?.last ? 'grid-cols-1' : 'grid-cols-2'}`}>
        <RenderIf isTrue={notifications?.content?.length > 0}>
          <button
            type="button"
            disabled={isFetching || isSubmiting}
            className="w-full bg-main-base-l border-2 border-main-base-l text-white dark:text-black-base-d py-2 text-xs px-6 rounded-lg hover:opacity-75 transition-all duration-200"
            onClick={() => setOpenModalDeleteAllNotification(true)}>
            Delete All
          </button>
          <RenderIf isTrue={!notifications?.last}>
            <button
              type="button"
              disabled={isFetching || isSubmiting}
              className="w-full border-main-base-l border-2 py-2 dark:text-main-base-l dark:border-main-base-l text-xs px-6 rounded-lg hover:opacity-75 transition-all duration-200"
              onClick={() => handleLoadMore()}>
              Load More
            </button>
          </RenderIf>
        </RenderIf>
      </div>

      <RenderIf isTrue={openModalDeleteAllNotification}>
        <Modal onClose={() => setOpenModalDeleteAllNotification(false)}>
          <ModalDialog
            header={<h5 className="font-quicksand-semibold text-base">Hapus Semua Pesan?</h5>}
            submitTitle="Lanjutkan"
            backTitle="Batal"
            isSubmitting={isSubmiting}
            onClose={() => setOpenModalDeleteAllNotification(false)}
            onSubmit={handleDeleteAllMessage}>
            <p className="font-quicksand-semibold text-sm">Kamu tidak bisa membatalkannya setelah menghapus semua pesan.</p>
          </ModalDialog>
        </Modal>
      </RenderIf>
    </>
  );
}

export default ListMessage;
