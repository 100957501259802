import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { currency } from 'utils/format';
import { getColorStatusOrder, getOrderStatus } from 'utils/stock/order';
import RenderIf from 'components/basic/render-if';

const CardOrderItem = ({ item }) => {
  const currencyFormatter = (v) => currency(v, { removeSymbol: true });
  const sideText = item?.side === 'B' ? 'Pembelian' : 'Penjualan';
  const qty = item?.qty || 0;
  const lot = qty > 0 ? Math.abs(qty) / 100 : 0;
  const investment = qty * item?.price;
  const orderStatus = getOrderStatus(item?.state);
  const colorStatus = getColorStatusOrder(item?.state);

  return (
    <div className="flex flex-col border border-gray-base-l dark:border-gray-base-d rounded-xl	py-5 px-4">
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      <div className="flex flex-row items-start mb-2">
        <div className="w-8 h-8 mr-2 border rounded-full aspect-square flex items-center justify-center bg-black-light-1-d/50 dark:bg-black-light-1-d">
          <RenderIf isTrue={item?.logo}>
            <img src={item?.logo} alt={item?.stID} />
          </RenderIf>
          <RenderIf isTrue={!item?.logo}>
            <span className='text-xs font-quicksand-semibold text-black-base-d'>{item?.stID?.substring(0, 2)}</span>
          </RenderIf>
        </div>
        <div className="flex flex-col justify-start">
          <p className="font-quicksand-semibold font-bold text-xs text-gray-dark-d dark:text-black-light-1-d">
            {item.stID}
          </p>
          <p className="font-quicksand-regular text-xs text-gray-light-0-d">
            {sideText && <span>{sideText}</span>}
            {sideText && <span className="px-1">|</span>}
            <span>{dayjs(item?.dtSave).format('DD MMM YYYY HH:mm')}</span>
          </p>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between bg-gray-light-l dark:bg-black-dark-d py-3 px-4 mb-2 rounded-[8px]">
        <div>
          <p className="font-quicksand-semibold font-normal text-2xs text-gray-light-0-d mb-px">
            Jumlah Investasi
          </p>
          <p className="font-quicksand-semibold font-bold text-xs text-gray-dark-d dark:text-black-light-1-d">
            {currencyFormatter(investment)}
          </p>
        </div>
        <div>
          <div
            className={`border rounded-[4px] p-1 text-center text-2xs uppercase font-quicksand-semibold ${colorStatus}`}>
            {orderStatus}
          </div>
        </div>
      </div>
      <div className="mx-1">
        <div className="flex flex-row items-center justify-between mb-2">
          <p className="font-quicksand-semibold font-normal text-2xs text-gray-light-0-d mb-px text-left">
            Jumlah Lot
          </p>
          <p className="font-quicksand-semibold font-bold text-2xs text-gray-dark-d dark:text-black-light-1-d mb-px text-right">
            {lot}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between">
          <p className="font-quicksand-semibold font-normal text-2xs text-gray-light-0-d mb-px text-left">
            Harga Per Lembar
          </p>
          <p className="font-quicksand-semibold font-bold text-2xs text-gray-dark-d dark:text-black-light-1-d mb-px text-right">
            {currencyFormatter(item.price)}
          </p>
        </div>
      </div>
    </div>
  );
};

CardOrderItem.propTypes = {
  item: PropTypes.object
};

CardOrderItem.defaultProps = {};

export default CardOrderItem;
