import IconUser from 'components/svg/icon-user';

const AdminMenus = [
  {
    name: 'Client',
    slug: 'admin',
    url: '/admin',
    icon: <IconUser className="h-5 w-5" />,
    children: [],
    analyticEventName: 'TR Admin Open Dashboard Page'
  },
];

export default AdminMenus;
