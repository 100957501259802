import PropTypes from 'prop-types';

function IconSearch({ className }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6435 12.6435C13.0015 12.2855 13.5819 12.2855 13.9398 12.6435L18.9815 17.6852C19.3395 18.0431 19.3395 18.6235 18.9815 18.9815C18.6235 19.3395 18.0431 19.3395 17.6852 18.9815L12.6435 13.9398C12.2855 13.5819 12.2855 13.0015 12.6435 12.6435Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 4.58333C6.63536 4.58333 4.58333 6.63536 4.58333 9.16667C4.58333 11.698 6.63536 13.75 9.16667 13.75C11.698 13.75 13.75 11.698 13.75 9.16667C13.75 6.63536 11.698 4.58333 9.16667 4.58333ZM2.75 9.16667C2.75 5.62284 5.62284 2.75 9.16667 2.75C12.7105 2.75 15.5833 5.62284 15.5833 9.16667C15.5833 12.7105 12.7105 15.5833 9.16667 15.5833C5.62284 15.5833 2.75 12.7105 2.75 9.16667Z"
      />
    </svg>
  );
}

IconSearch.propTypes = {
  className: PropTypes.string
};

export default IconSearch;
