export default function IconPinaGreenCircle({ className }) {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="65" cy="65" r="65" fill="#F6F8F3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88 75.0936V53.8698L76.5 64.4822L88 75.0936Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88 96.3173V75.0936L76.5 85.7059L88 96.3173Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0005 75.122V53.8707L53.4991 64.4954L65.0005 75.122Z"
        fill="#53C317"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0004 53.8707V75.122L76.4988 64.4963L65.0004 53.8707Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0001 53.8707V75.122L53.4995 64.4963L42.0001 53.8707Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0001 75.1222V96.3725L53.4995 85.7478L42.0001 75.1222Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4985 85.7492V64.496L65.0002 75.1217L76.4985 85.7492Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.5 64.4818V85.7055L88 75.0936L76.5 64.4818Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4994 85.7492V64.496L42 75.1217L53.4994 85.7492Z"
        fill="#53C317"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4993 64.496V85.7492L65.0007 75.1226L53.4993 64.496Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0005 96.3747V75.1224L53.4991 85.7471L65.0005 96.3747Z"
        fill="#53C317"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0004 75.1222V96.3744L76.4988 85.7497L65.0004 75.1222Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4985 107V85.7477L65.0002 96.3724L76.4985 107Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4985 85.7054V107L88 96.3173L76.4985 85.7054Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4994 107V85.7477L42 96.3724L53.4994 107Z"
        fill="#53C317"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4993 85.7476V107L65.0007 96.3752L53.4993 85.7476Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4982 107H76.498L64.9986 96.3724L53.4982 107Z"
        fill="#53C317"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0004 32.6254V53.8777L76.4988 43.253L65.0004 32.6254Z"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.9984 53.8782V32.6259L53.498 43.2506L64.9984 53.8782Z"
        fill="#018A46"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5003 22V43.2522L65.0007 32.6275L53.5003 22Z"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4994 43.2527V22.0004L42 32.627L53.4994 43.2527Z"
        fill="#018A46"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.5006 22V43.2522L88 32.6275L76.5006 22Z"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4985 43.2527V22.0004L65.0002 32.627L76.4985 43.2527Z"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4985 64.4958V43.2454L65.0002 53.8701L76.4985 64.4958Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4985 43.2453V64.4966L87.9989 53.8709L76.4985 43.2453Z"
        fill="#9FE516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4994 64.4958V43.2454L42 53.8701L53.4994 64.4958Z"
        fill="#53C317"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4993 43.2453V64.4966L65.0007 53.8709L53.4993 43.2453Z"
        fill="#9FE516"
      />
    </svg>
  );
}
