function MascotDeleteAccount() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="30.8706"
        cy="31.9614"
        r="23.1401"
        fill="#FFDC00"
        stroke="#EDB326"
        strokeWidth="3"
      />
      <mask
        id="mask0_2049_70866"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="9"
        y="10"
        width="44"
        height="44">
        <circle cx="30.8706" cy="31.9614" r="21.6401" fill="#FFDC00" />
      </mask>
      <g mask="url(#mask0_2049_70866)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7812 17.5059V31.0472H48.3226L34.7812 17.5059ZM48.3226 31.0472H34.7813L34.7812 44.5886L48.3226 31.0472Z"
          fill="#FFEE84"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7812 -9.57666V3.96466H48.3226L34.7812 -9.57666ZM48.3226 3.96474H34.7813L34.7812 17.5061L48.3226 3.96474Z"
          fill="#FFEE84"
        />
        <path
          d="M34.7812 58.1297V44.5884L48.3226 58.1297H34.7812Z"
          fill="#FFEE84"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.3223 31.0474V44.5887H61.8636L48.3223 31.0474ZM61.8636 44.5887H48.3223L48.3223 58.1301L61.8636 44.5887Z"
          fill="#FFEE84"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.3223 3.96484V17.5062H61.8636L48.3223 3.96484ZM61.8636 17.5064H48.3223L48.3223 31.0477L61.8636 17.5064Z"
          fill="#FFEE84"
        />
      </g>
      <path
        d="M39.669 25.3783C39.669 22.8325 38.6832 20.5277 37.0929 18.8616C35.5001 17.1928 33.3002 16.1602 30.8702 16.1602C28.4402 16.1602 26.2403 17.1928 24.6483 18.8607C23.0571 20.5277 22.0723 22.8316 22.0723 25.3775C22.0723 27.9233 23.058 30.2281 24.6483 31.8942C26.2411 33.563 28.4402 34.5948 30.8702 34.5948C33.3002 34.5948 35.5001 33.5621 37.0921 31.8942C38.6832 30.2281 39.669 27.9242 39.669 25.3783Z"
        fill="#BA8B1A"
      />
      <path
        d="M20.8154 44.251C20.8154 46.0064 24.5861 47.7626 30.8707 47.7626C36.7664 47.7626 40.9259 46.0072 40.9259 44.251C40.9259 40.7393 36.9808 37.2285 30.8707 37.2285C24.5861 37.2285 20.8154 40.7393 20.8154 44.251Z"
        fill="#BA8B1A"
      />
      <circle cx="49.7332" cy="44.0648" r="10.3231" fill="#F16528" />
      <path
        d="M47.517 39.1067C47.4433 37.9543 48.3581 36.979 49.5129 36.979H49.9538C51.1086 36.979 52.0234 37.9543 51.9497 39.1067L51.4619 46.731C51.4035 47.6428 50.647 48.3525 49.7334 48.3525C48.8197 48.3525 48.0632 47.6428 48.0048 46.731L47.517 39.1067Z"
        fill="white"
      />
      <path
        d="M48.2822 50.4712C48.1028 49.8639 48.5579 49.2549 49.1911 49.2549H50.2751C50.9083 49.2549 51.3635 49.8639 51.1841 50.4712C51.0651 50.874 50.6951 51.1505 50.2751 51.1505H49.1911C48.7711 51.1505 48.4012 50.874 48.2822 50.4712Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7871 9.11523V12.2306H55.9025L52.7871 9.11523ZM55.9025 12.2306H52.7871L52.7871 15.346L55.9025 12.2306Z"
        fill="#7BCC29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7871 9.11523V12.2306H49.6718L52.7871 9.11523ZM49.6718 12.2306H52.7871L52.7871 15.346L49.6718 12.2306Z"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.3838 48.5151V51.6305H58.4992L55.3838 48.5151ZM58.4992 51.6305H55.3838L55.3838 54.7459L58.4992 51.6305Z"
        fill="#7BCC29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.3838 48.5151V51.6305H52.2684L55.3838 48.5151ZM52.2684 51.6305H55.3838V54.7459L52.2684 51.6305Z"
        fill="#02B05F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2246 54.3877V55.9454H34.7823L33.2246 54.3877ZM34.7823 55.9454H33.2246L33.2246 57.5031L34.7823 55.9454Z"
        fill="#FF6464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2246 54.3877V55.9454H31.6669L33.2246 54.3877ZM31.6669 55.9454H33.2246L33.2246 57.5031L31.6669 55.9454Z"
        fill="#FF6464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.23047 6V8.07689H11.3074L9.23047 6ZM11.3074 8.07692H9.23047V10.1538L11.3074 8.07692Z"
        fill="#FF6464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.23047 6V8.07689H7.15356L9.23047 6ZM7.15356 8.07692H9.23047L9.23047 10.1538L7.15356 8.07692Z"
        fill="#FF6464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1543 44.4229V48.5766H11.3081L7.1543 44.4229ZM11.3081 48.5767H7.1543L7.1543 52.7305L11.3081 48.5767Z"
        fill="#7BCC29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1543 44.4229V48.5766H3.00048L7.1543 44.4229ZM3.00048 48.5767H7.1543L7.1543 52.7305L3.00048 48.5767Z"
        fill="#02B05F"
      />
    </svg>
  );
}

export default MascotDeleteAccount;
