import ActionList from '../basic/action-list';
import { useEffect, useState } from 'react';
import EmptyPP from '../../assets/images/dummy/photo-profile.webp';
import RenderIf from 'components/basic/render-if';
import LogoTrust from 'assets/images/logo/trust.png';
import { NavLink } from 'react-router-dom';
import LogoPina from 'components/svg/logo-pina';
import { useGetImageQuery } from 'services/rtk-query/image';
import MarketStatus from './_partials/market-status';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function AdminHeader() {
  const { user } = useBrowserTabAuth();

  const [avatarUrl, setAvatarUrl] = useState(EmptyPP);
  const AvPP = user?.firstName?.charAt(0) || '';
  const { data: avatarURL } = useGetImageQuery(
    user?.imgAvatarUrl?.replace('BASE_URL/images/', ''),
    { skip: !user?.imgAvatarUrl }
  );

  useEffect(() => {
    if (avatarURL) setAvatarUrl(avatarURL);
  }, [avatarURL]);

  return (
    <div>
      <div className="h-[70px]"></div>
      <header
        className={`fixed left-0 top-0 w-full z-[45] pl-12 lg:pl-20 `}>
        <div className="flex px-4 py-3">
          <div className="grow flex items-center justify-between">
            <div className="flex-none flex items-center" />

            <div className="flex-none flex items-center ml-3 lg:ml-4">
              <ActionList
                className="right-0 top-8 w-48 text-black-base-l"
                data={[
                  {
                    link: '/logout',
                    title: 'Logout',
                    'data-qa-id': 'linkHeaderLogout'
                  }
                ]}>
                <div
                  data-qa-id="btnHeaderProfile"
                  className="flex flex-none items-center relative">
                  <RenderIf isTrue={user?.imgAvatarUrl}>
                    <div className={`relative`}>
                      <div className="h-8 w-8 overflow-hidden rounded-full border-main-base-l border">
                        <img
                          data-qa-id="imgHeaderProfile"
                          className="h-8 w-8 overflow-hidden rounded-full"
                          src={avatarUrl}
                          alt="Profile"
                        />
                      </div>
                    </div>
                  </RenderIf>

                  <RenderIf isTrue={!user?.imgAvatarUrl}>
                    <div className="rounded-full ml-3 bg-orange-1 flex items-center justify-center relative min-h-[40px] min-w-[40px]">
                      <span className="font-quicksand-bold text-sm uppercase">
                        {AvPP || 'A'}
                      </span>
                    </div>
                  </RenderIf>
                </div>
              </ActionList>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

AdminHeader.propTypes = {};

export default AdminHeader;
