import PropTypes from 'prop-types';
import CardStockItem from 'components/cards/card-stock-item';
import RenderIf from 'components/basic/render-if';
import {  useState } from 'react';
import { useGetStockTopQuery } from 'services/rtk-query/stock';
import SkeletonTheme from 'components/basic/skeleton-theme';
import useCustomNavigate from 'hooks/useCustomNavigate';

function SectionStockRankList({ isCardLess }) {
  const { customNavigate } = useCustomNavigate();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [categories] = useState([
    {
      active: true,
      text: 'Paling Aktif',
      label: 'top-frequency',
      ['data-qa-id']: 'navRankStockTopFrequency'
    },
    {
      active: false,
      text: 'Top&nbsp;%&nbsp;Gainer',
      label: 'top-persen-gainer',
      ['data-qa-id']: 'navRankStockTopGainer'
    },
    {
      active: false,
      text: 'Top Loser',
      label: 'top-persen-loser',
      ['data-qa-id']: 'navRankStockTopLose'
    }
  ]);

  const { data: stockRanks, isLoading } = useGetStockTopQuery({ type: categories[activeTabIndex].label, params: { size: 5, page: 0 } });

  const handleActiveTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  return (
    <section className={`bg-white ${!isCardLess ? 'rounded-xl ' : ''}`}>
      <div className="p-4 pb-0">
        {!isCardLess && <h3 className='font-quicksand-semibold text-lg'>Berdasarkan Ranking</h3>}
        <ul className="mt-3 text-xs grid grid-cols-3 items-center font-quicksand-semibold -mx-4">
          {categories.map((item, key) => (
            <li
              key={key}
              data-qa-id={item?.['data-qa-id']}
              onClick={() => handleActiveTab(key)}
              className={`cursor-pointer py-2 text-center transition-all ease-linear duration-200 hover:bg-opacity-75 border-b-2 px-4 hover:text-main-base-l hover:border-main-base-l ${
                key === activeTabIndex ? 'border-main-base-l text-main-base-l' : 'bg-white border-gray-light-l text-gray-light-0-d'
              }`} dangerouslySetInnerHTML={{__html: item.text }}>
            </li>
          ))}
        </ul>
      </div>

      <div className="pt-3">
        <RenderIf isTrue={isLoading}>
          <div className="p-3">
            {Array(5)
              .fill(1)
              .map((el, key) => (
                <div key={key} className="flex justify-between mb-5">
                  <div className="flex">
                    <SkeletonTheme
                      containerClassName="mr-3"
                      width="40px"
                      height="40px"
                      borderRadius="100%"
                    />

                    <div>
                      <SkeletonTheme
                        containerClassName="mr-1"
                        width="70px"
                        height="16px"
                        borderRadius="6px"
                      />

                      <SkeletonTheme
                        containerClassName="mr-1"
                        width="100px"
                        height="16px"
                        borderRadius="6px"
                      />
                    </div>
                  </div>

                  <div className="text-right">
                    <SkeletonTheme width="40px" height="16px" borderRadius="6px" />

                    <SkeletonTheme width="50px" height="16px" borderRadius="6px" />
                  </div>
                </div>
              ))}
          </div>
        </RenderIf>

        <RenderIf isTrue={stockRanks?.length > 0 && !isLoading}>
          <ul>
            {stockRanks?.map((item, key) => (
              <li key={key}>
                <CardStockItem
                  {...item}
                  isShowChart
                  data-link-qa-id="linkRankListStock"
                  data-img-qa-id="imgRankListStock"
                  isLastItem={key === stockRanks?.length - 1}
                  onClick={() => customNavigate({ query: { 'stock-detail': item?.code }})}
                />
              </li>
            ))}
          </ul>
        </RenderIf>

        <RenderIf isTrue={stockRanks?.length === 0 && !isLoading}>
          <p className="text-sm text-gray-light-0-d px-3">Belum ada data.</p>
        </RenderIf>
      </div>
    </section>
  );
}

SectionStockRankList.propTypes = {
  isCardLess: PropTypes.bool,
};

export default SectionStockRankList;
