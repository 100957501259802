import RenderIf from 'components/basic/render-if';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetStockInfoByCodeQuery } from 'services/rtk-query/stock';

import './css/table.css';
import { useEffect } from 'react';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import useElementSize from 'hooks/useElementSize';
import cn from 'utils/classname';
function TWCompanyProfile({ symbol, maxHeight = '350px' }) {
  const isBrowserWakeUp = useBrowserWakeUp();
  const {
    data: stockInfo,
    isFetching,
    refetch
  } = useGetStockInfoByCodeQuery(symbol, { refetchOnMountOrArgChange: true });

  const { ref, dimensions } = useElementSize();
  const { width } = dimensions ?? {};

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  return (
    <div ref={ref}>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        style={{ maxHeight }}>
        <div
          className={cn(
            'grid gap-4',
            width < 400 && 'grid-cols-1',
            width >= 400 && width < 600 && 'grid-cols-2',
            width >= 600 && width < 800 && 'grid-cols-3',
            width >= 800 && 'grid-cols-4',
          )}>
          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Head Office</h5>
            </div>

            <div className="px-4 pb-3 overflow-auto">
              <RenderIf isTrue={isFetching}>
                <div className="flex mb-1">
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="150px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </div>
                <div className="flex mb-1">
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="180px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </div>
                <div className="flex mb-1">
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="190px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </div>
                <div className="flex mb-1">
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="130px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </div>
                <div className="flex mb-1">
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                  <SkeletonTheme
                    containerClassName="mr-2"
                    width="190px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </div>
              </RenderIf>

              <RenderIf isTrue={!isFetching}>
                <p
                  className="text-xs custom-table dark:text-black-light-1-d"
                  dangerouslySetInnerHTML={{
                    __html: stockInfo?.address || '-'
                  }}
                />
              </RenderIf>
            </div>
          </div>

          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Business Profile</h5>
            </div>

            <div className="px-4 pb-3 overflow-auto">
              <RenderIf isTrue={isFetching}>
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="150px"
                  height="1rem"
                  borderRadius="6px"
                />
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="100%"
                  height="1rem"
                  borderRadius="6px"
                />
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="100%"
                  height="1rem"
                  borderRadius="6px"
                />
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="100%"
                  height="1rem"
                  borderRadius="6px"
                />
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="100%"
                  height="1rem"
                  borderRadius="6px"
                />
              </RenderIf>

              <RenderIf isTrue={!isFetching}>
                <h5 className="text-xs mb-1 dark:text-black-light-1-d">
                  {stockInfo?.name}
                </h5>
                <p
                  className="text-xs custom-table dark:text-black-light-1-d"
                  dangerouslySetInnerHTML={{
                    __html:
                      stockInfo?.description?.replaceAll(
                        '&#58;',
                        '&nbsp;&#58;&nbsp;'
                      ) || '-'
                  }}
                />
              </RenderIf>
            </div>
          </div>

          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Bussiness Product</h5>
            </div>

            <div
              className="px-4 pb-3 overflow-auto"
              style={{ maxHeight: '300px' }}>
              <RenderIf isTrue={isFetching}>
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="150px"
                  height="1rem"
                  borderRadius="6px"
                />
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="100%"
                  height="1rem"
                  borderRadius="6px"
                />
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="100%"
                  height="1rem"
                  borderRadius="6px"
                />
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="100%"
                  height="1rem"
                  borderRadius="6px"
                />
                <SkeletonTheme
                  containerClassName="mb-3"
                  width="100%"
                  height="1rem"
                  borderRadius="6px"
                />
              </RenderIf>

              <RenderIf isTrue={!isFetching}>
                <p
                  className="text-xs custom-table dark:text-black-light-1-d"
                  dangerouslySetInnerHTML={{
                    __html:
                      stockInfo?.business_product?.replaceAll(
                        '&#58;',
                        '&nbsp;&#58;&nbsp;'
                      ) || '-'
                  }}
                />
              </RenderIf>
            </div>
          </div>

          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Directors</h5>
            </div>

            <div
              className="px-4 pb-3 overflow-auto"
              style={{ maxHeight: '300px' }}>
              <p
                className="text-xs custom-table dark:text-black-light-1-d"
                dangerouslySetInnerHTML={{
                  __html:
                    stockInfo?.directors?.replaceAll(
                      '&#58;',
                      '&nbsp;&#58;&nbsp;'
                    ) || '-'
                }}
              />
            </div>
          </div>

          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Other Info</h5>
            </div>

            <div
              className="px-4 pb-3 overflow-auto"
              style={{ maxHeight: '300px' }}>
              <p
                className="text-xs custom-table dark:text-black-light-1-d"
                dangerouslySetInnerHTML={{
                  __html:
                    stockInfo?.other_info?.replaceAll(
                      '&#58;',
                      '&nbsp;&#58;&nbsp;'
                    ) || '-'
                }}
              />
            </div>
          </div>

          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Share Registrar</h5>
            </div>

            <div
              className="px-4 pb-3 overflow-auto"
              style={{ maxHeight: '300px' }}>
              <p
                className="text-xs custom-table dark:text-black-light-1-d"
                dangerouslySetInnerHTML={{
                  __html:
                    stockInfo?.share_registrar?.replaceAll(
                      '&#58;',
                      '&nbsp;&#58;&nbsp;'
                    ) || '-'
                }}
              />
            </div>
          </div>

          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Share Holders</h5>
            </div>

            <div
              className="px-4 pb-3 overflow-auto"
              style={{ maxHeight: '300px' }}>
              <p
                className="text-xs custom-table dark:text-black-light-1-d"
                dangerouslySetInnerHTML={{
                  __html:
                    stockInfo?.shareholders?.replaceAll(
                      '&#58;',
                      '&nbsp;&#58;&nbsp;'
                    ) || '-'
                }}
              />
            </div>
          </div>

          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Subsidiary Afiiliation</h5>
            </div>

            <div
              className="px-4 pb-3 overflow-auto"
              style={{ maxHeight: '300px' }}>
              <p
                className="text-xs custom-table dark:text-black-light-1-d"
                dangerouslySetInnerHTML={{
                  __html:
                    stockInfo?.subsidiary_affiliation?.replaceAll(
                      '&#58;',
                      '&nbsp;&#58;&nbsp;'
                    ) || '-'
                }}
              />
            </div>
          </div>

          <div>
            <div className="p-3 bg-gray-light-l dark:bg-black-dark-d dark:text-black-light-1-d mb-3">
              <h5 className="text-xs">Underwriter</h5>
            </div>

            <div
              className="px-4 pb-3 overflow-auto"
              style={{ maxHeight: '300px' }}>
              <p
                className="text-xs custom-table dark:text-black-light-1-d"
                dangerouslySetInnerHTML={{
                  __html:
                    stockInfo?.underwriter?.replaceAll(
                      '&#58;',
                      '&nbsp;&#58;&nbsp;'
                    ) || '-'
                }}
              />
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}

TWCompanyProfile.propTypes = {
  symbol: PropTypes.string,
  maxHeight: PropTypes.string
};

export default TWCompanyProfile;
