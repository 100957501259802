import PropTypes from 'prop-types';
import Card from 'components/basic/card';

import MascotExpired from 'assets/images/mascot/mascot-feature-expired.png';
import IconClose from 'components/svg/icon-close';
import { Link } from 'react-router-dom';
import RenderIf from 'components/basic/render-if';
import { triggerSegmentEvent } from 'lib/segment';
import useDarkMode from 'hooks/useDarkMode';

function ModalExploreExpired({
  title = 'Waktu Trial Kamu Telah Habis',
  description = 'Yuk buka rekening investasi sekarang dan mulailah menjelajahi berbagai peluang di pasar finansial.',
  showClose = false,
  eventTracking = 'TR Become Member From Time Limit Modal Window Clicked',
  onClose = () => {}
}) {
  const { removeDarkMode } = useDarkMode();

  return (
    <Card className={`p-5 max-w-[400px] md:mx-auto relative mx-6 dark:border dark:border-gray-base-d ${showClose && 'pt-12'}`}>
      <RenderIf isTrue={showClose}>
        <button
          onClick={onClose}
          type="button"
          className="right-4 top-4 absolute hover:opacity-75 transition-all ease-linear duration-150 dark:text-main-base-l">
          <IconClose className="h-6 w-6" />
        </button>
      </RenderIf>

      <div className="text-center">
        <img
          src={MascotExpired}
          alt="first time rdn"
          className="w-full h-auto aspect-[400/250] mb-4"
        />

        <h5 className="text-2xl text-center font-quicksand-semibold mb-2 dark:text-black-light-1-d">
          {title}
        </h5>

        <p className="text-gray-light-0-d text-base mb-2">{description}</p>
      </div>

      <Link
        type="button"
        to="/onboarding/phone-number"
        onClick={() => {
          triggerSegmentEvent({ event: eventTracking });
          removeDarkMode();
          onClose();
        }}
        className="px-4 py-3 text-center block text-white dark:text-black-dark-d bg-main-base-l rounded-xl w-full mt-6 text-sm hover:opacity-75 transition-all ease-in-out duration-150 font-quicksand-semibold">
        Jadi Member PINA & Buat RDN
      </Link>
    </Card>
  );
}

ModalExploreExpired.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showClose: PropTypes.bool,
  eventTracking: PropTypes.string,
  onClose: PropTypes.func
};

export default ModalExploreExpired;
