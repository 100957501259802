import dayjs from 'dayjs';
import { find, orderBy } from 'lodash';
import baseApi from '../base'
import { TAG_ORDERBOOK, TAG_ORDERLIST, TAG_CLIENTS, TAG_TRADELIST, TAG_CLIENT_CASH, TAG_PORTFOLIO, TAG_BUYING_POWER, TAG_USER_NOTIFICATION, TAG_ORDERLIST_HISTORY } from '../tags'
import JSONBigInt from "json-bigint";
import { useBrowserTabId } from 'hooks/useBrowserTabId';

export const TradeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getIntraDataOrderBook: build.query({
      query: (code) => {
        return {
          url: `/trade/Intraday/obx2`,
          params: { code: code, d: '-1' }
        }
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, code) => {
        return [{ type: TAG_ORDERBOOK, id: code }];
      },
    }),
    getIntraDayMinuteOHLC: build.query({
      query: (code = 'AALI') => {
        return {
          url: `/trade/Intraday/MinuteOHLCList2`,
          params: { stock: code, fromHour : 9, fromMinute : 0 }
        }
      },
      transformResponse: (res) => res || [],
    }),
    getIntraDaySingle: build.query({
      query: (code = 'AALI') => {
        return {
          url: `/trade/Intraday/qsx2`,
          params: { code: code, d: '-1' }
        }
      },
      transformResponse: (res) => res?.data || [],
    }),
    getIntraDayComposite: build.query({
      query: (code = 'AALI') => {
        return {
          url: `/trade/Intraday/qix2`,
          params: { code: code, d: '-1' }
        }
      },
      transformResponse: (res) => res?.data || [],
    }),
    getTradeList: build.query({
      async queryFn(clientId, { getState }, _extraOptions, fetchWithBQ) {
        let params = {};
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) params.code = dealerUseClientId || clientId;

        const data = await fetchWithBQ({
          url: `/trade/tradeList`,
          responseHandler: "text",
          params,
        });

        let parseData = JSONBigInt.parse(data?.data);
        parseData = {
          ...parseData,
          arr: parseData?.arr?.map((item) => ({
            ...item,
            mktOID: BigInt(item?.mktOID)?.toString(),
            mktTID: BigInt(item?.mktTID)?.toString(),
          }))
        }

        return {
          data: parseData
        };
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_TRADELIST, id: 'LIST' }];
      },
    }),
    getOrderList: build.query({
      async queryFn(_arg, { getState }, _extraOptions, fetchWithBQ) {
        let params = {};
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) params.code = dealerUseClientId;

        let { data: orderList } = await fetchWithBQ({
          url: '/trade/orderList',
          method: 'GET',
          responseHandler: "text",
          params
        });

        orderList = JSONBigInt.parse(orderList);
        orderList = orderBy((orderList?.arr || []), ['dtSave'], ['desc']);
        orderList = orderList?.map((item) => ({
          ...item,
          mktOID: BigInt(item?.mktOID)?.toString()
        }));

        let stockDetails = [];
        const stockIds = orderList.reduce((acc, c) => {
          if (c?.stID) { acc.push(c.stID) }
          return acc;
        }, []);

        if (stockIds.length) {
          const { data } = await fetchWithBQ({
            url: `/stocks/get-multiple-id?id`,
            method: 'POST',
            body: { id: stockIds },
          });

          stockDetails = data;
        }

        // Map order items
        const items = [];
        let totalBuy = 0;
        let totalSell = 0;
        for (let i = 0; i < orderList.length; i++) {
          const x = orderList[i];
          const item = { ...x,
            openQty: x?.qty - x?.cumQty
          };

          let findLogo = find(stockDetails, ['code', item?.stID]);
          item.logo = findLogo?.logo;

          // Calculate total buy & sell
          const total = item?.qty * item?.price;
          const isAllowedStatus = ['O', 'o', 'M', 'B'].includes(item?.state);

          if (isAllowedStatus) {
            if (item?.side === 'B') {
              totalBuy += total;
            } else if (item?.side === 'S') {
              totalSell += total;
            }
          }

          items.push(item);
        }

        return {
          data: {
            items,
            total_buy: totalBuy,
            total_sell: totalSell,
          }
        };
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_ORDERLIST, id: 'LIST' }];
      },
    }),
    getPortfolioList: build.query({
      async queryFn(_arg, { getState }, _extraOptions, fetchWithBQ) {
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        
        let params = {};
        if (dealerUseClientId) params.code = dealerUseClientId;

        let overAllPortfolio = {};
        const { data } = await fetchWithBQ({
          url: `/trade/clientStocks`,
          method: 'GET',
          params
        });

        const portfolioList = data?.arr;

        let stockCurrentPrice = 0;
        let stockAvgPrice = 0;

        const getStockList = portfolioList?.filter(item => {
          stockCurrentPrice += item.marketValue;
          stockAvgPrice += item.stockValue;
          return item.marketValue > 0;
        }).map(async item => {
          const { data: stockDetail } = await fetchWithBQ(`/stocks/${item.stID}`);
  
          return({
            ...item,
            sLogo: stockDetail?.logo,
            sName: stockDetail?.name,
            code: item.stID,
            totalGain: item.marketValue - item.stockValue,
            totalGainPercentage: ((item.marketValue - item.stockValue) / item.stockValue) * 100,
          })
        });

        const stockList = await Promise.all(getStockList || [])  

        overAllPortfolio = {
          stock: {
            items: stockList,
            currentPrice: stockCurrentPrice,
            avgPrice: stockAvgPrice
          }
        };

        return { data : overAllPortfolio };
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_PORTFOLIO, id: 'LIST' }];
      },
    }),
    getOrderListHistory: build.query({
      async queryFn({ userName, filter } , _queryApi, _extraOptions, fetchWithBQ) {

        const params = {
          d: `${userName}|`,
        }
  
        if (filter?.start_date && filter?.end_date) {
          params.d += `${dayjs(filter.start_date).format('MM/DD/YYYY')}|${dayjs(filter.end_date).format('MM/DD/YYYY')}|AVG`
        } else {
          params.d += `${dayjs().subtract(10, 'year').format('MM/DD/YYYY')}|${dayjs().format('MM/DD/YYYY')}|AVG`
        }

        const { data } = await fetchWithBQ(`/webreport/getStockActivity?${new URLSearchParams(params)}`);
        const stockIds = [];
        let items = [];

        for (const x of data) {
          if (x?.Type === 20) {
            items.push(x);
            stockIds.push(x?.StockID);
          }
        }

        items = items.sort((a, b) => new Date(b?.TradeDate) - new Date(a?.TradeDate));

        const ids = [...new Set(stockIds)];
        if (ids.length > 0) {
          const { data : res } = await fetchWithBQ({
            url: `/stocks/get-multiple-id`,
            method: 'POST',
            body: { id: ids },
          });


          items = items.map((item) => {
            const detail = res.find((x) => x?.code === item?.StockID);
            return {
              ...item,
              logo: detail?.logo,
            }
          })
        }

        return { data : items };
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_ORDERLIST_HISTORY, id: 'LIST' }];
      },
    }),
    getClientCash: build.query({
      async queryFn(clientId, { getState }, _extraOptions, fetchWithBQ) {
        let _clientId = clientId;
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) _clientId = dealerUseClientId;

        const data = await fetchWithBQ({
          url: '/trade/clientCash',
          params: {
            seq: 1000,
            clientID: _clientId,
            code: _clientId,
          }
        });

        return data;
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_CLIENT_CASH, id: 'LIST' }];
      },
    }),
    getTradingLimitBuy: build.query({
      async queryFn(clientId, { getState }, _extraOptions, fetchWithBQ) {
        let _clientId = clientId;
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) _clientId = dealerUseClientId;

        const data = await fetchWithBQ({
          url: '/trade/tradingLimitBuy',
          params: {
            clientID: _clientId,
            code: _clientId,
          }
        });

        return data;
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_CLIENT_CASH, id: 'LIST' }];
      },
    }),
    createOrderBuy: build.mutation({
      async queryFn(params, { getState }, _extraOptions, fetchWithBQ) {
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) params.clientID = dealerUseClientId;

        const data = await fetchWithBQ({
          url: '/trade/orderBuy',
          method: 'POST',
          params
        });

        return data;
      },
      invalidatesTags: [{ type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    }),
    createOrderSell: build.mutation({
      async queryFn(params, { getState }, _extraOptions, fetchWithBQ) {
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) params.clientID = dealerUseClientId;

        const data = await fetchWithBQ({
          url: '/trade/orderSell',
          method: 'POST',
          params
        });

        return data;
      },
      invalidatesTags: [{ type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    }),
    createOrderAmend: build.mutation({
      async queryFn(params, { getState }, _extraOptions, fetchWithBQ) {
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) params.clientID = dealerUseClientId;

        const data = await fetchWithBQ({
          url: '/trade/orderAmend',
          method: 'POST',
          params
        });

        return data;
      },
      invalidatesTags: [{ type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    }),
    createOrderWithdraw: build.mutation({
      async queryFn(params, { getState }, _extraOptions, fetchWithBQ) {
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) params.clientID = dealerUseClientId;

        const data = await fetchWithBQ({
          url: '/trade/withdrawOrder',
          method: 'POST',
          params
        });

        return data;
      },
      invalidatesTags: [{ type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    }),
    createOrderWithdrawWithoutInvalidateTags: build.mutation({
      query: (params) => {
        return {
          url: '/trade/withdrawOrder',
          method: 'POST',
          params
        }
      },
    }),
    getClients: build.query({
      query: () => {
        return {
          url: `/trade/clientDetail`,
        }
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_CLIENTS, id: 'LIST' }];
      },
    }),
  }),
});

export const invalidateOrderList = () => {
  return TradeApi.util.invalidateTags([{ type: TAG_ORDERLIST, id: 'LIST' }]);
}

export const invalidatePortfolio = () => {
  return TradeApi.util.invalidateTags([{ type: TAG_PORTFOLIO, id: 'LIST' }]);
}

export const invalidateAllOrder = () => {
  return TradeApi.util.invalidateTags([{ type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }, { type: TAG_USER_NOTIFICATION, id: 'LIST'}, { type: TAG_CLIENT_CASH, id: 'LIST'}]);
}

export const invalidateIntraDataOrderBookByCode = (symbol) => {
  return TradeApi.util.invalidateTags([{ type: TAG_ORDERBOOK, id: symbol }]);
}

export const invalidateOrderListHistory = () => {
  return TradeApi.util.invalidateTags([{ type: TAG_ORDERLIST_HISTORY, id: 'LIST' }]);
}

export const {
  useGetIntraDataOrderBookQuery,
  useGetIntraDayMinuteOHLCQuery,
  useLazyGetIntraDayMinuteOHLCQuery,
  useLazyGetIntraDaySingleQuery,
  useLazyGetIntraDayCompositeQuery,
  useGetTradeListQuery,
  useGetOrderListQuery,
  useGetPortfolioListQuery,
  useGetOrderListHistoryQuery,
  useGetClientCashQuery,
  useGetTradingLimitBuyQuery,
  useCreateOrderBuyMutation,
  useCreateOrderSellMutation,
  useCreateOrderAmendMutation,
  useCreateOrderWithdrawMutation,
  useCreateOrderWithdrawWithoutInvalidateTagsMutation,
  useGetClientsQuery
} = TradeApi;
