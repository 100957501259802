import clsx from 'clsx';
import { triggerSegmentEvent } from 'lib/segment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function Tabs({
  data = [],
  onChange = () => {},
  initialOpenIndex = 0,
  className = 'grid grid-cols-5 gap-2 font-quicksand-bold',
  children,
  classObject = {
    li: '',
    liActive: '',
    active: 'border-main-base-l border-b-2',
    inactive: 'border-gray-base-l dark:border-gray-base-d border-b',
    span: ''
  },
  style
}) {
  const [activeIndexTab, setActiveIndexTab] = useState(initialOpenIndex);
  const handleChange = (index) => {
    setActiveIndexTab(index);
    onChange(index);
  };

  useEffect(() => {
    setActiveIndexTab(initialOpenIndex);
  }, [initialOpenIndex]);

  return (
    <>
      <ul
        className={`text-sm text-center font-quicksand-semibold ${className}`}
        style={style}>
        {data.map((item, key) => (
          <li
            role="button"
            onClick={() => {
              handleChange(key);
              triggerSegmentEvent({ event: item?.analyticEventName });
            }}
            key={key}
            data-qa-id={item?.['data-qa-id']}
            className={clsx(
              classObject['li'],
              key === activeIndexTab && classObject['liActive']
            )}>
            <button
              type="button"
              className={clsx(
                'py-3 w-full hover:border-main-base-l min-w-[80px] lg:min-w-[unset] cursor-pointer hover:border-b transition-all ease-in-out duration-100',
                key === activeIndexTab
                  ? item?.customActiveClass || classObject['active']
                  : classObject['inactive'],
                item?.customActiveClass
              )}>
              <span className={`${classObject['span']} line-clamp-1`}>
                {item.text}
              </span>
            </button>
          </li>
        ))}
      </ul>

      {children}
    </>
  );
}

Tabs.propTypes = {
  data: PropTypes.array,
  initialOpenIndex: PropTypes.number,
  children: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.any,
  className: PropTypes.string,
  classObject: PropTypes.shape({
    li: PropTypes.string,
    active: PropTypes.string,
    inactive: PropTypes.string
  })
};

export default Tabs;
