import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFontSize } from 'store/settings';

export default function useFontSize() {
  const dispatch = useDispatch();
  const fontSize = useSelector(({ settings }) => settings?.fontSize);

  const toggleFontSize = useCallback(() => {
    if (fontSize === 'trade-font-normal') {
      dispatch(setFontSize('trade-font-large'));
    } else if (fontSize === 'trade-font-large') {
      dispatch(setFontSize('trade-font-small'));
    } else if (fontSize === 'trade-font-small') {
      dispatch(setFontSize('trade-font-normal'));;
    }
  }, [fontSize]);

  const changeFontSize = useCallback((value) => {
    dispatch(setFontSize(value));
  }, []);

  useEffect(() => {
    if (fontSize === 'trade-font-normal') {
      document.documentElement.classList.remove('trade-font-large');
      document.documentElement.classList.remove('trade-font-small');
      document.documentElement.classList.add('trade-font-normal');
    }

    if (fontSize === 'trade-font-large') {
      document.documentElement.classList.remove('trade-font-small');
      document.documentElement.classList.remove('trade-font-normal');
      document.documentElement.classList.add('trade-font-large');
    }

    if (fontSize === 'trade-font-small') {
      document.documentElement.classList.remove('trade-font-large');
      document.documentElement.classList.remove('trade-font-normal');
      document.documentElement.classList.add('trade-font-small');
    }
  }, [fontSize]);

  return { toggleFontSize, changeFontSize, fontSize };
}
