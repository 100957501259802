import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { currency } from 'utils/format';
import { useCreateOrderWithdrawMutation } from 'services/rtk-query/trade';
import { invalidateUserNotification } from 'services/rtk-query/user';
import { useDispatch, useSelector } from 'react-redux';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import InputCheckBox from 'components/input/input-check-box';
import { uniqueId } from 'lodash';
import useToastr from 'hooks/useToastr';
import { setQuickOrderSettingsConfirmation } from 'store/settings';
import useFocus from 'hooks/useFocus';

function StockWithdrawForm({
  isOpen,
  data = {},
  onClose = () => {},
  onSuccess = () => {}
}) {
  const [refFocus, setSubmitFocus] = useFocus();
  const [refCancelFocus, setCancelFocus] = useFocus();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();
  const [createOrderWithdraw] = useCreateOrderWithdrawMutation();
  const quickOrderConfirmation = useSelector(
    (state) => state?.settings?.quickOrderSettings?.confirmation
  );

  const [open, setOpen] = useState(isOpen);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let payloads = {
        orderID: data?.id,
        clOrdID: data?.orderNumber
      };
      const { success, msg, err } = await createOrderWithdraw(
        payloads
      ).unwrap();

      if (!success) {
        let error = new Error();
        Object.assign(error, { msg, code: err });
        throw error;
      }

      toastr.success('Order telah terkirim');
      onSuccess();

      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
      setOpen(false);
    }
  };

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => setSubmitFocus(), 100);
  }, [open]);

  return (
    <div
      className="bg-white max-w-md dark:bg-black-base-d dark:text-black-light-1-d dark:border dark:border-gray-base-d rounded-lg p-6 z-10 relative transform mx-auto zoom-2"
      style={{ transform: 'translateY(-3px)' }}>
      <div className="text-center pb-2 border-b dark:border-gray-base-d">
        <h5>Mohon cek kembali order kamu</h5>
        <p className="text-xs text-gray-light-0-d font-quicksand-semibold">
          No Order: {data?.orderNumber}
        </p>
      </div>

      <div className="flex my-1 items-center">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
          Kode Saham
        </span>
        <span className="flex-grow text-right font-quicksand-bold">
          {data?.code}
        </span>
      </div>

      <div className="flex my-1 items-center">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
          Tipe Transaksi
        </span>
        <span className="flex-grow text-right font-quicksand-bold text-orange-2">
          Withdraw
        </span>
      </div>

      <div className="flex my-1 items-center">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
          Harga Per Saham
        </span>
        <span className="flex-grow text-right font-quicksand-bold">
          {currency(data?.price || 0, { removeSymbol: true })}
        </span>
      </div>

      <div className="flex my-1 items-center">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
          Jumlah Lot
        </span>
        <span className="flex-grow text-right font-quicksand-bold">
          {currency(data?.lot || 0, { removeSymbol: true })}
        </span>
      </div>

      <div className="flex my-1 items-center border-y py-2 dark:border-gray-base-d">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
          Jumlah Investasi
        </span>
        <span className="flex-grow text-right font-quicksand-bold">
          {currency(data?.price * data?.lot * 100, { removeSymbol: true })}
        </span>
      </div>

      <div className="pt-3 mt-1">
        <div className="grid grid-cols-12 gap-4">
          <button
            data-qa-id="btnStockBuyFinishConfirmation"
            type="button"
            onClick={onClose}
            ref={refCancelFocus}
            onKeyDown={(event) => {
              if (event.key === 'ArrowRight') {
                event.preventDefault();
                setSubmitFocus();
              }
            }}
            disabled={isSubmitting}
            className="col-span-4 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75">
            Batal
          </button>

          <button
            ref={refFocus}
            onClick={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === 'ArrowLeft') {
                event.preventDefault();
                setCancelFocus();
              }
            }}
            disabled={isSubmitting}
            className={`col-span-8 text-white bg-orange-2 py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75`}>
            {isSubmitting && <Spinner />}
            Withdraw
          </button>
        </div>

        <div className="mt-4">
          <InputCheckBox
            id={`${uniqueId('confirmation-')}`}
            value={quickOrderConfirmation}
            name="confirmation"
            checked={quickOrderConfirmation}
            label={
              <span className="text-xs font-quicksand-regular text-black-base-l dark:text-black-light-0-d transform">
                Confirm Order
              </span>
            }
            onChange={() => {
              dispatch(
                setQuickOrderSettingsConfirmation(!quickOrderConfirmation)
              );
            }}
            className="mb-0"
          />
        </div>
      </div>
    </div>
  );
}

StockWithdrawForm.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
};

export default StockWithdrawForm;
