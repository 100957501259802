import PageNotification from 'pages/notification';
import PageTradingPlan from 'pages/trading/auto-trading-planner';
import PinaProCompare from 'pages/trading/compare';
import PinaProMultiChartAndOrderBook from 'pages/trading/compare/chart-orderbook';
import PinaProIdeas from 'pages/trading/ideas';
import PinaProIdeasDetail from 'pages/trading/ideas/detail';
import PinaProIdeasProfileDetail from 'pages/trading/ideas/profile';
import PinaProOrderlist from 'pages/trading/orderlist/index';
import PinaProPortfolio from 'pages/trading/portfolio';
import PageTradingSearch from 'pages/trading/stock-market';
import PageTradingSearchIDX from 'pages/trading/stock-market/idx';
import PageTradingSearchSector from 'pages/trading/stock-market/sector';
import PageTradingSearchThematic from 'pages/trading/stock-market/thematic';
import PageStockScreener from 'pages/trading/stock-market/stock-screener';
import PinaProCustomWorkspace from 'pages/trading/workspaces';
import PinaProAbsoluteWorkspace from 'pages/trading/workspaces/absolute-workspace';
import PinaProDefaultWorkspace from 'pages/trading/workspaces/default-workspace';

export const TradingRoutes = [
  {
    path: "/",
    index: false,
    element: PinaProCustomWorkspace,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/absolute-workspace",
    index: false,
    element: PinaProAbsoluteWorkspace,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/default-workspace",
    index: false,
    element: PinaProDefaultWorkspace,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/workspace/:slug",
    index: false,
    element: PinaProCustomWorkspace,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/compare/:type",
    index: false,
    element: PinaProCompare,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/chart-orderbook",
    index: false,
    element: PinaProMultiChartAndOrderBook,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/stock-market",
    index: false,
    element: PageTradingSearch,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/stock-market/:slug",
    index: false,
    element: PageStockScreener,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/stock-market/index/:code",
    index: false,
    element: PageTradingSearchIDX,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/stock-market/thematic/:code",
    index: false,
    element: PageTradingSearchThematic,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/stock-market/sector/:code",
    index: false,
    element: PageTradingSearchSector,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/portfolio",
    index: false,
    element: PinaProPortfolio,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/order",
    index: false,
    element: PinaProOrderlist,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/order/:slug",
    index: false,
    element: PinaProOrderlist,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/notification/:slug",
    index: false,
    element: PageNotification,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/auto-trading-planner",
    index: false,
    element: PageTradingPlan,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/ideas",
    index: false,
    element: PinaProIdeas,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/ideas/:slug",
    index: false,
    element: PinaProIdeasDetail,
    layout: 'trading',
    roles: [],
    auth: true,
  },
  {
    path: "/ideas/profile/:slug",
    index: false,
    element: PinaProIdeasProfileDetail,
    layout: 'trading',
    roles: [],
    auth: true,
  },
];

export default TradingRoutes;
