import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useBrowserWakeUp = () => {
  const browserWakeUp = useSelector(({ pinaPro }) => pinaPro?.browserWakeUp);
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));

    return () => {
      window.removeEventListener('online', () => setIsOnline(true));
      window.removeEventListener('offline', () => setIsOnline(false));
    };
  }, []);

  return isOnline && browserWakeUp;
};

export default useBrowserWakeUp;
