import PropTypes from 'prop-types';
import IconChevronSingle from 'components/svg/icon-chevron-single';

function HeaderOnboarding({ activeStep }) {
  return (
    <>
      <div className="flex justify-between mb-0 lg:mb-10 items-start">
        <div className="flex-none">
          <a
            className="text-sm lg:text-xl hover:opacity-75 flex items-center mb-4"
            href="/">
            <IconChevronSingle className="h-5 w-5 mr-1" />
            Kembali ke Dashboard
          </a>
        </div>
      </div>

      <ul className="flex items-center text-sm">
        {[1, 2, 3, 4, 5, 6]?.map((item) => (
          <li
            key={item}
            className={`mx-1 h-6 w-6 border border-white flex items-center justify-center rounded-full ${item <= activeStep && 'bg-white text-main-base-l'}`}>
            {item}
          </li>
        ))}
      </ul>
    </>
  );
}

HeaderOnboarding.propTypes = {
  activeStep: PropTypes.number
};

export default HeaderOnboarding;
