import RenderIf from 'components/basic/render-if';
import Tabs from 'components/tabs/tabs';
import { useState } from 'react';
import SectionStockIHSG from './section-stock-ihsg';
import SectionStockGlobal from './section-stock-global';
import Card from 'components/basic/card';

function SectionStockIndex() {
  const [activeTabIndex, setActiveIndexTab] = useState(0);

  const tabs = [
    {
      index: 0,
      text: 'IHSG',
      analyticEventName: 'TR IHSG Clicked'
    },
    {
      index: 1,
      text: 'Global',
      analyticEventName: 'TR Index Clicked'
    }
  ];

  return (
    <Card className="p-4">
      <h3 className="flex-none text-sm font-quicksand-semibold dark:text-black-light-1-d">Index</h3>

      <Tabs
        initialOpenIndex={activeTabIndex}
        className="grid grid-cols-2 font-quicksand-semibold text-gray-light-0-d text-xs -mx-4"
        data={tabs}
        onChange={(index) => setActiveIndexTab(index)}>
        <RenderIf isTrue={activeTabIndex === 0}>
          <SectionStockIHSG />
        </RenderIf>

        <RenderIf isTrue={activeTabIndex === 1}>
          <SectionStockGlobal />
        </RenderIf>
      </Tabs>
    </Card>
  );
}

export default SectionStockIndex;
