import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import { useGetOrderListHistoryQuery } from 'services/rtk-query/trade';
import { currency } from 'utils/format';
import { useEffect, useMemo, useState } from 'react';
import OrderDetail from 'pages/trading/_partials/order-detail';
import IconSorting from 'components/svg/icon-sorting';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useTableSorting } from 'hooks/useTableSorting';
import { groupBy, sumBy } from 'lodash';
import clsx from 'clsx';
import { dayjsLocal } from 'utils/formatter/date';
import Modal from 'components/modal/modal';
import InputSelect from 'components/input/input-select';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import useElementSize from 'hooks/useElementSize';
import cn from 'utils/classname';

function TWOrderlistHistory({ title = 'Riwayat Transaksi' }) {
  const { user } = useBrowserTabAuth();
  const [currentFilter, setCurrentFilter] = useState(null);
  const [filterStockID, setFilterStockID] = useState(null);
  const [currentDate, setCurrentDate] = useState(
    dayjsLocal().subtract(1, 'day').format('MM/DD/YYYY')
  );
  const [data, setData] = useState([]);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);

  const { ref, dimensions } = useElementSize();
  const { width } = dimensions ?? {};

  const { data: histories, isFetching } = useGetOrderListHistoryQuery({
    userName: user?.username
  });

  const {
    data: dataTables,
    sortingColumns,
    handleSorting,
    getSorting
  } = useTableSorting(histories, ['TradeDate'], ['desc']);

  const ownedStockID = useMemo(() => {
    const groupByStockID = groupBy(
      histories?.filter(
        (item) =>
          dayjsLocal(item?.TradeDate, 'YYYY-MM-DD').valueOf() >=
          dayjsLocal(currentDate, 'MM/DD/YYYY').valueOf()
      ),
      'StockID'
    );

    return (
      Object.keys(groupByStockID)?.map((objKey) => ({
        id: objKey,
        name: objKey
      })) || []
    );
  }, [histories, currentDate]);

  const dates = [
    {
      value: dayjsLocal().subtract(1, 'day').format('MM/DD/YYYY'),
      text: 'Yesterday',
      format: 'DD MMM YYYY'
    },
    {
      value: dayjsLocal().subtract(7, 'day').format('MM/DD/YYYY'),
      text: '7D',
      format: 'DD MMM YYYY'
    },
    {
      value: dayjsLocal().subtract(1, 'month').format('MM/DD/YYYY'),
      text: '30D',
      format: 'DD MMM YYYY'
    },
    {
      value: dayjsLocal().subtract(3, 'month').format('MM/DD/YYYY'),
      text: '3M',
      format: 'MMMM YYYY'
    },
    {
      value: dayjsLocal().subtract(6, 'month').format('MM/DD/YYYY'),
      text: '6M',
      format: 'MMMM YYYY'
    },
    {
      value: dayjsLocal().subtract(1, 'year').format('MM/DD/YYYY'),
      text: '12M',
      format: 'MMMM YYYY'
    }
  ];

  const profit = useMemo(() => {
    return sumBy(
      data?.filter((o) => o?.Description === 'Sell' && o?.Type === 20),
      'Profit'
    );
  }, [data]);

  useEffect(() => {
    setData(
      dataTables
        ?.filter(
          (item) =>
            dayjsLocal(item?.TradeDate, 'YYYY-MM-DD').valueOf() >=
            dayjsLocal(currentDate, 'MM/DD/YYYY').valueOf()
        )
        ?.filter((item) => {
          if (filterStockID) {
            return item?.StockID === filterStockID;
          }

          return true;
        })
        ?.filter(
          (item) => item?.Description === currentFilter || !currentFilter
        )
        ?.map((item) => ({
          ...item,
          TradeValue: item?.Price * (item?.Long || item?.Short)
        }))
    );
  }, [dataTables, currentDate, currentFilter, filterStockID]);

  return (
    <OverlayScrollbarsComponent
      className="overlayscrollbars-host-small overflow-hidden relative -mx-4 px-4"
      element="span"
      options={{ resize: 'horizontal' }}
      style={{ height: 'calc(100%)' }}>
      <div ref={ref} className={cn('grid grid-cols-12 gap-3', width < 700 && 'grid-cols-1')}>
        <div className={cn(width >= 700 && 'order-2 col-span-4')}>
          <Card className="p-4">
            <h2 className="mb-2 text-sm dark:text-black-light-1-d">
              Realized Profit/Loss
            </h2>

            <p className="text-xs mb-3 dark:text-black-light-1-d">
              {dayjsLocal(currentDate, 'MM/DD/YYYY').format(
                dates?.find((o) => o?.value === currentDate).format
              )}
              &nbsp;-&nbsp;
              {dayjsLocal()
                .subtract(1, 'day')
                .format(dates?.find((o) => o?.value === currentDate).format)}
            </p>

            <h5
              className={clsx(
                'font-quicksand-bold text-lg',
                profit > 0 ? 'text-green-base-d' : 'text-red-base-l'
              )}>
              {currency(profit, { removeSymbol: true })}
            </h5>
          </Card>
        </div>

        <Card
          className={cn('p-4 mb-4', width >= 700 && 'order-1 col-span-8')}>
          {title && (
            <h2 className="mb-2 text-sm dark:text-black-light-1-d">{title}</h2>
          )}

          <div className="flex items-center flex-wrap gap-3 justify-between my-3">
            <ul className="flex text-2xs -mx-1 max-w-full">
              {dates?.map((item, key) => (
                <li
                  key={key}
                  onClick={() => setCurrentDate(item?.value)}
                  className={`border border-gray-light-l dark:border-black-light-1-l text-center ${
                    currentDate === item?.value
                      ? 'bg-main-base-l text-white font-quicksand-semibold dark:text-black-base-d !border-main-base-l'
                      : 'card-widget-gradient text-gray-light-0-d dark:text-black-light-1-d'
                  } font-quicksand-semibold border border-main-base-l py-[4px] cursor-pointer mx-1 rounded-lg px-3 hover:opacity-100 transition-all ease-in-out duration-200`}>
                  {item?.text}
                </li>
              ))}
            </ul>

            <ul className="flex text-2xs -mx-1 max-w-full flex-wrap gap-4">
              <InputSelect
                size="small"
                value={currentFilter}
                name="side"
                label={
                  <span className="text-2xs font-quicksand-semibold text-gray-light-0-d">
                    Side
                  </span>
                }
                className="mb-4 text-2xs text-gray-light-0-d w-[100px]"
                options={[
                  {
                    id: null,
                    name: 'All'
                  },
                  {
                    id: 'Buy',
                    name: 'Buy'
                  },
                  {
                    id: 'Sell',
                    name: 'Sell'
                  }
                ]}
                onChange={(e) => {
                  setCurrentFilter(e.target.value);
                }}
              />

              <InputSelect
                size="small"
                value={filterStockID}
                name="side"
                label={
                  <span className="text-2xs font-quicksand-semibold text-gray-light-0-d">
                    StockID
                  </span>
                }
                className="mb-4 text-2xs text-gray-light-0-d w-[100px]"
                options={[
                  {
                    id: null,
                    name: 'All'
                  },
                  ...ownedStockID
                ]}
                onChange={(e) => {
                  setFilterStockID(e.target.value);
                }}
              />
            </ul>
          </div>

          <OverlayScrollbarsComponent
            className="overlayscrollbars-host-small"
            element="span"
            options={{
              resize: 'horizontal',
              scrollbars: {
                autoHide: 'leave',
                autoHideDelay: 50
              }
            }}>
            <table className="text-2xs w-full text-center dark:text-black-light-1-d">
              <thead className="text-gray-light-0-d border-t border-gray-light-l dark:border-gray-base-d text-center">
                <tr>
                  <td className="p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d text-center">
                    No
                  </td>
                  <td
                    onClick={() => handleSorting('StockID')}
                    className={`p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'StockID' && 'text-main-base-l'
                    }`}>
                    <span className="flex items-center gap-1 cursor-pointer">
                      {sortingColumns?.[0] === 'StockID' && (
                        <IconSorting
                          type={getSorting('StockID')}
                          className="h-3 w-3"
                        />
                      )}
                      Stock
                    </span>
                  </td>
                  <td
                    onClick={() => handleSorting('TradeDate')}
                    className={`p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'TradeDate' && 'text-main-base-l'
                    }`}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'TradeDate' && (
                        <IconSorting
                          type={getSorting('TradeDate')}
                          className="h-3 w-3"
                        />
                      )}
                      Trade&nbsp;Date
                    </span>
                  </td>
                  <td
                    onClick={() => handleSorting('Description')}
                    className={`p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'Description' &&
                      'text-main-base-l'
                    }`}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'Description' && (
                        <IconSorting
                          type={getSorting('Description')}
                          className="h-3 w-3"
                        />
                      )}
                      B/S
                    </span>
                  </td>
                  <td
                    onClick={() => handleSorting('Price')}
                    className={`p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'Price' && 'text-main-base-l'
                    }`}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'Price' && (
                        <IconSorting
                          type={getSorting('Price')}
                          className="h-3 w-3"
                        />
                      )}
                      Price
                    </span>
                  </td>
                  <td
                    onClick={() => handleSorting('lot')}
                    className={`p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'lot' && 'text-main-base-l'
                    }`}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'lot' && (
                        <IconSorting
                          type={getSorting('lot')}
                          className="h-3 w-3"
                        />
                      )}
                      Lot
                    </span>
                  </td>
                  <td
                    onClick={() => handleSorting('TradeValue')}
                    className={`p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'TradeValue' && 'text-main-base-l'
                    }`}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'TradeValue' && (
                        <IconSorting
                          type={getSorting('TradeValue')}
                          className="h-3 w-3"
                        />
                      )}
                      Trade&nbsp;Value
                    </span>
                  </td>
                  <td
                    onClick={() => handleSorting('Profit')}
                    className={`p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'Profit' && 'text-main-base-l'
                    }`}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'Profit' && (
                        <IconSorting
                          type={getSorting('Profit')}
                          className="h-3 w-3"
                        />
                      )}
                      Profit
                    </span>
                  </td>
                  <td
                    onClick={() => handleSorting('StockValue')}
                    className={`p-1 py-1.5 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'StockValue' && 'text-main-base-l'
                    }`}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'StockValue' && (
                        <IconSorting
                          type={getSorting('StockValue')}
                          className="h-3 w-3"
                        />
                      )}
                      Invested
                    </span>
                  </td>
                </tr>
              </thead>
              <tbody className="text-2xs">
                <RenderIf isTrue={data?.length === 0 && !isFetching}>
                  <tr className="group">
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                      -
                    </td>
                  </tr>
                </RenderIf>

                <RenderIf isTrue={isFetching}>
                  {[0, 1, 2]?.map((item) => (
                    <tr key={item} className="group">
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                        <SkeletonTheme
                          width="50px"
                          height="1rem"
                          borderRadius="6px"
                        />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                        <SkeletonTheme
                          width="50px"
                          height="1rem"
                          borderRadius="6px"
                        />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                        <SkeletonTheme
                          width="50px"
                          height="1rem"
                          borderRadius="6px"
                        />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                        <SkeletonTheme
                          width="50px"
                          height="1rem"
                          borderRadius="6px"
                        />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                        <SkeletonTheme
                          width="50px"
                          height="1rem"
                          borderRadius="6px"
                        />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                        <SkeletonTheme
                          width="50px"
                          height="1rem"
                          borderRadius="6px"
                        />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                        <SkeletonTheme
                          width="50px"
                          height="1rem"
                          borderRadius="6px"
                        />
                      </td>
                    </tr>
                  ))}
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <tr
                      className="group cursor-pointer group"
                      key={key}
                      onClick={() => setOpenOrderDetail(item)}>
                      <td className="p-2 border-b group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                        {key + 1}
                      </td>
                      <td className="p-2 border-b group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left font-quicksand-semibold">
                        <div className="inline-flex items-center mx-auto hover:opacity-75 transition-all ease-in-out duration-200">
                          {item?.StockID}
                        </div>
                      </td>
                      <td className="p-2 border-b group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {dayjsLocal(item?.TradeDate, 'YYYY-MM-DD').format(
                          'DD MMM YYYY'
                        )}
                      </td>
                      <td
                        className={` p-2 border-b group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 text-right border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${
                          item?.Description === 'Buy'
                            ? 'text-red-base-l'
                            : 'text-green-base-l'
                        }`}>
                        {item?.Description === 'Buy' ? 'BUY' : 'SELL'}
                      </td>
                      <td className="p-2 border-b group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {currency(item?.Price, { removeSymbol: true })}
                      </td>
                      <RenderIf isTrue={item?.Description === 'Buy'}>
                        <td className="p-2 border-b group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                          {currency(item?.Long / 100, { removeSymbol: true })}{' '}
                          LOT
                        </td>
                      </RenderIf>

                      <RenderIf isTrue={item?.Description === 'Sell'}>
                        <td className="p-2 border-b group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                          {currency(item?.Short / 100, { removeSymbol: true })}
                          &nbsp;LOT
                        </td>
                      </RenderIf>
                      <td className="p-2 border-b group-hover:bg-gray-light-l font-quicksand-semibold dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {currency(item?.TradeValue, { removeSymbol: true })}
                      </td>
                      <td className="p-2 border-b group-hover:bg-gray-light-l font-quicksand-semibold dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {currency(item?.Profit, { removeSymbol: true })}
                      </td>
                      <td className="p-2 border-b group-hover:bg-gray-light-l font-quicksand-semibold dark:group-hover:bg-black-dark-d group-hover:bg-opacity-50 border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {currency(item?.StockValue, { removeSymbol: true })}
                      </td>
                    </tr>
                  ))}
                </RenderIf>
              </tbody>
            </table>
          </OverlayScrollbarsComponent>
        </Card>

        <RenderIf isTrue={openOrderDetail}>
          <Modal size="large" onClose={() => setOpenOrderDetail(false)}>
            <OrderDetail
              data={{
                ...openOrderDetail,
                side: openOrderDetail?.Description === 'Buy' ? 'B' : 'S',
                qty:
                  openOrderDetail?.Description === 'Buy'
                    ? openOrderDetail?.Long
                    : openOrderDetail?.Short,
                price: openOrderDetail?.Price,
                dtSave: dayjsLocal(openOrderDetail?.TradeDate).valueOf(),
                state: 'M'
              }}
              onClose={() => setOpenOrderDetail(false)}
            />
          </Modal>
        </RenderIf>
      </div>
    </OverlayScrollbarsComponent>
  );
}

TWOrderlistHistory.propTypes = {
  title: PropTypes.any,
  isCardLess: PropTypes.bool
};

export default TWOrderlistHistory;
