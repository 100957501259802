import PropTypes from 'prop-types';
import RenderIf from "components/basic/render-if";
import { useSelector } from "react-redux";
import SkeletonTheme from 'components/basic/skeleton-theme';

function SkeletonHeader({ isLoading = true, width = '150px' }) {
  const { watchStockList } = useSelector(({ pinaPro }) => pinaPro);

  return (
    <RenderIf isTrue={isLoading}>
      {Array(watchStockList?.length > 3 ? watchStockList?.length : 3)
      .fill(1)
      .map((el, key) => (
        <div className={`min-w-[${width}] max-w-[${width}]`} key={key}>
          <div className="p-2 py-3 border-l border-b dark:border-gray-base-d flex justify-between">
            <div className="flex items-center text-gray-light-0-d">
              <SkeletonTheme width="80px" height="1rem" borderRadius="6px" />
            </div>

            <SkeletonTheme width="16px" height="16px" borderRadius="4px" />
          </div>
          <div className="p-2 py-3 border-l text-xs dark:border-gray-base-d">
            <p className="line-clamp-1">
              <SkeletonTheme width="80%" height="1rem" borderRadius="6px" />
            </p>
          </div>
        </div>
      ))}
    </RenderIf>
  )
}

SkeletonHeader.propTypes = {
  isLoading: PropTypes.bool,
  width: PropTypes.string,
};

export default SkeletonHeader;