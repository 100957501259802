export const getOpeningAccountStep = (profile = {}) => {
  let step = profile.fillState;
  if (!profile.phoneNo) {
    step = 0;
  } else if (
    !profile.ktpNo
    || !profile.imgKtpUrl
    || !profile.imgFaceUrl
  ) {
    step = 1;
  } else if (!profile.sourceFunds) {
    step = 2;
  } else if (!profile.bankAccountNo) {
    step = 3;
  } else if (!profile.imgSignatureUrl) {
    step = 4;
  }

  return step;
}