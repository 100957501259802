import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useCustomNavigate() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [queryParams, setQueryParams] = useState({});

  const customNavigate = (payload) => {
    const { path, query, isPushQuery } = payload || {};
    const queryString = new URLSearchParams({
      ...(isPushQuery ? queryParams : {}),
      ...query
    }).toString();

    navigate(`${path || pathname}?${queryString}`);
  };

  useEffect(() => {
    const ss = location?.search?.substring(1);

    if (ss) {
      setQueryParams(
        JSON.parse(
          '{"' +
            decodeURI(ss)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        )
      );
    }
  }, [search]);

  return { navigate, customNavigate, queryParams };
}
