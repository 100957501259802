// import { marginPlusShare } from 'utils/formula/margin';
import { ORDER_COST } from 'constants';
import { getStampCostBuy } from 'utils/calculation';
import { object, number, string, date, bool } from 'yup';

export const SmartOrderFormValidation = object().shape({
  buyingPower: number()
    .test(
      'buyingPower',
      'Maaf, trading limit anda tidak mencukupi.',
      function (value, { parent }) {
        if (parent.BotName === 'SmartBuy') {
          return (
            value >=
            parent?.Lot * 100 * parent?.Price +
              Math.ceil(ORDER_COST?.buy * parent?.Price * parent?.Lot) +
              getStampCostBuy(value)
          );
        }

        return true;
      }
    )
    .required('Maaf, trading limit anda tidak mencukupi.'),
  Lot: number()
    .test(
      'Lot',
      'Jumlah Lot yang dimasukan tidak sesuai',
      function (value, { parent }) {
        if (['SmartSell', 'TakeProfit', 'StopLoss'].includes(parent.BotName)) {
          return parent?.Lot <= parent?.portfolio;
        }

        return true;
      }
    )
    .test('Lot', 'Jumlah Lot tidak boleh kosong', function (value) {
      return value > 0;
    })
    .required('Jumlah Lot tidak boleh kosong'),
  LotStop: number()
    .test(
      'LotStop',
      'Jumlah Lot Stop Loss yang dimasukan tidak sesuai',
      function (value, { parent }) {
        if (parent.stopLoss) {
          return parent?.LotStop <= value && value != 0;
        }

        return true;
      }
    )
    .test(
      'LotStop',
      'Jumlah Lot Stop Loss tidak boleh kosong',
      function (value, { parent }) {
        if (parent.stopLoss) {
          return value > 0;
        }

        return true;
      }
    ),
  LotProfit: number()
    .test(
      'LotProfit',
      'Jumlah Lot Take Profit yang dimasukan tidak sesuai',
      function (value, { parent }) {
        if (parent.takeProfit) {
          return parent?.LotProfit <= value && value != 0;
        }

        return true;
      }
    )
    .test(
      'LotProfit',
      'Jumlah Lot Take Profit tidak boleh kosong',
      function (value, { parent }) {
        if (parent.takeProfit) {
          return value > 0;
        }

        return true;
      }
    ),
  portfolio: number()
    .test(
      'portfolio',
      'Maaf, anda belum memiliki Lot di saham ini',
      function (value, { parent }) {
        if (parent.BotName === 'SmartSell') {
          return value > 0;
        }

        return true;
      }
    )
    .required('Maaf, anda belum memiliki Lot di saham ini'),
  BotName: string().required('Mode tidak boleh kosong'),
  StockId: string()
    .test('StockId', 'Stock tidak boleh kosong', (value) => value?.length > 0)
    .required('Stock tidak boleh kosong'),
  BotExpire: date().required('Periode Berakhir tidak boleh kosong'),
  BotExpireStop: date().test(
    'BotExpireStop',
    'Periode Berakhir Stop Loss tidak boleh kosong',
    function (value, { parent }) {
      if (parent?.stopLoss) {
        return value != '' && value != undefined;
      }

      return true;
    }
  ),
  BotExpireProfit: date().test(
    'BotExpireProfit',
    'Periode Berakhir Take Profit tidak boleh kosong',
    function (value, { parent }) {
      if (parent?.takeProfit) {
        return value != '' && value != undefined;
      }

      return true;
    }
  ),
  agreement: bool().oneOf(
    [true],
    'Kamu harus menyetujui syarat dan ketentuan order ini.'
  ),
  PriceRule: number()
    .min(1, 'Syarat harga tidak boleh kosong')
    .required('Syarat Harga tidak boleh kosong'),
  PriceRuleStop: number().test(
    'PriceRuleStop',
    'Syarat harga Stop Loss tidak boleh kosong ',
    function (value, { parent }) {
      if (parent?.stopLoss) {
        return value > 0;
      }

      return true;
    }
  ),
  PriceRuleProfit: number().test(
    'PriceRuleProfit',
    'Syarat harga Take Profit tidak boleh kosong ',
    function (value, { parent }) {
      if (parent?.takeProfit) {
        return value > 0;
      }

      return true;
    }
  ),
  Rule: string().required('Syarat tidak boleh kosong'),
  RuleProfit: string().test(
    'RuleProfit',
    'Syarat Take Profit tidak boleh kosong',
    function (value, { parent }) {
      if (parent?.takeProfit) {
        return value != '' && value != undefined;
      }

      return true;
    }
  ),
  RuleStop: string().test(
    'RuleStop',
    'Syarat Stop Loss tidak boleh kosong',
    function (value, { parent }) {
      if (parent?.stopLoss) {
        return value != '' && value != undefined;
      }

      return true;
    }
  ),
  Price: number()
    // .test('Price', 'Harga terlalu rendah', function (value, { parent }) {
    //   return !(
    //     value <
    //     parent?.PriceRule - marginPlusShare(parent?.PriceRule) * 10
    //   );
    // })
    // .test('Price', 'Harga terlalu tinggi', function (value, { parent }) {
    //   return !(
    //     value >
    //     parent?.PriceRule + marginPlusShare(parent?.PriceRule) * 10
    //   );
    // })
    .min(1, 'Harga tidak boleh kosong')
    .required('Harga tidak boleh kosong'),
  PriceProfit: number().test(
    'PriceProfit',
    'Harga Take Profit tidak boleh kosong',
    function (value, { parent }) {
      if (parent?.takeProfit) {
        return value > 0;
      }

      return true;
    }
  ),
  // .test('PriceProfit', 'Harga Take Profit terlalu tinggi', function (value, { parent }) {
  //   return !(
  //     value >
  //     parent?.PriceRuleProfit + marginPlusShare(parent?.PriceRuleProfit) * 10
  //   );
  // })
  // .test('PriceProfit', 'Harga Take Profit terlalu rendah', function (value, { parent }) {
  //   return !(
  //     value <
  //     parent?.PriceRuleProfit - marginPlusShare(parent?.PriceRuleProfit) * 10
  //   );
  // })
  PriceStop: number().test(
    'PriceStop',
    'Harga Stop Loss tidak boleh kosong',
    function (value, { parent }) {
      if (parent?.stopLoss) {
        return value > 0;
      }

      return true;
    }
  )
  // .test('PriceStop', 'Harga Stop Loss terlalu rendah', function (value, { parent }) {
  //   return !(
  //     value <
  //     parent?.PriceRuleStop - marginPlusShare(parent?.PriceRuleStop) * 10
  //   );
  // })
  // .test('PriceStop', 'Harga Stop Loss terlalu tinggi', function (value, { parent }) {
  //   return !(
  //     value >
  //     parent?.PriceRuleStop + marginPlusShare(parent?.PriceRuleStop) * 10
  //   );
  // })
});
