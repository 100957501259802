import { createSlice } from '@reduxjs/toolkit';
import { useBrowserTabId } from 'hooks/useBrowserTabId';

export const trading = createSlice({
  name: 'trading',
  initialState: {
    data: null,
    isLoginPin: false,
    isRefreshPin: false
  },
  reducers: {
    setLoginPin: (state) => {
      const browserTabID = useBrowserTabId();

      return {
        ...state,
        isLoginPin: true,
        isRefreshPin: true,
        [browserTabID]: {
          ...state?.[browserTabID],
          isLoginPin: true,
          isRefreshPin: true
        }
      };
    },
    resetLoginPin: (state) => {
      const browserTabID = useBrowserTabId();
      return {
        ...state,
        isLoginPin: false,
        [browserTabID]: {
          ...state?.[browserTabID],
          isLoginPin: false
        }
      };
    },
    resetRefreshPin: (state) => {
      const browserTabID = useBrowserTabId();
      return {
        ...state,
        isRefreshPin: false,
        [browserTabID]: {
          ...state?.[browserTabID],
          isRefreshPin: false
        }
      };
    }
  }
});

export const tradingPersistConfig = {
  key: 'pn-trade-trading',
  blacklist: []
};

export const {
  setLoadingTradingPower,
  setLoginPin,
  resetLoginPin,
  resetRefreshPin
} = trading.actions;

export default trading.reducer;
