import PropTypes from 'prop-types';

function IconCheck({ className = 'fill-current text-orange-1' }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00008 2.33335C4.42275 2.33335 2.33341 4.42269 2.33341 7.00002C2.33341 9.57735 4.42275 11.6667 7.00008 11.6667C9.57741 11.6667 11.6667 9.57735 11.6667 7.00002C11.6667 4.42269 9.57741 2.33335 7.00008 2.33335ZM1.16675 7.00002C1.16675 3.77836 3.77842 1.16669 7.00008 1.16669C10.2217 1.16669 12.8334 3.77836 12.8334 7.00002C12.8334 10.2217 10.2217 12.8334 7.00008 12.8334C3.77842 12.8334 1.16675 10.2217 1.16675 7.00002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16256 5.42085C9.39037 5.64866 9.39037 6.01801 9.16256 6.24581L6.82923 8.57915C6.60142 8.80695 6.23207 8.80695 6.00427 8.57915L4.8376 7.41248C4.6098 7.18467 4.6098 6.81533 4.8376 6.58752C5.06541 6.35972 5.43475 6.35972 5.66256 6.58752L6.41675 7.34171L8.3376 5.42085C8.56541 5.19305 8.93475 5.19305 9.16256 5.42085Z"
        fill="currentColor"
      />
    </svg>
  );
}

IconCheck.propTypes = {
  className: PropTypes.string
};

export default IconCheck;
