import baseApi from '../base';

export const AuthApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createSignUp: build.mutation({
      query: (body) => {
        return {
          url: `/auth/signup`,
          method: 'POST',
          body
        };
      }
    }),
    authLogin: build.mutation({
      query: ({ email, password, fcmToken, devicePlatform }) => {
        const deviceId = (process.env.NODE_ENV + '-'+ (window?.navigator?.userAgent || '')?.replace(/[^\w\s]/gi, '')).toUpperCase().replaceAll(' ', '')?.substring(55, 100);

        return {
          url: `/auth/signin`,
          method: 'POST',
          body: { email, password, fcmToken, deviceId, devicePlatform }
        }
      },
      transformResponse: (res) => res?.payloads || null,
    }),
    createSignUpGoogle: build.mutation({
      query: (body) => {
        return {
          url: `/auth/signup/google`,
          method: 'POST',
          body
        };
      }
    }),
    authLoginGoogle: build.mutation({
      query: ({ email, password, fcmToken, devicePlatform, idToken }) => {
        const deviceId = (process.env.NODE_ENV + '-'+ (window?.navigator?.userAgent || '')?.replace(/[^\w\s]/gi, '')).toUpperCase().replaceAll(' ', '')?.substring(55, 100);

        return {
          url: `/auth/signin/google`,
          method: 'POST',
          body: { email, password, fcmToken, deviceId, devicePlatform, idToken }
        }
      },
      transformResponse: (res) => res?.payloads || null,
    }),
  })
});

export const { useCreateSignUpMutation, useAuthLoginMutation, useCreateSignUpGoogleMutation, useAuthLoginGoogleMutation } = AuthApi;
