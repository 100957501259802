import baseApi from '../base';
import { TAG_CLASSROOM_AUTHORS, TAG_TRADING_IDEAS } from '../tags';

export const ClassroomApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTradingIdeas: build.query({
      query: (
        params = {
          page: 1,
          limit: 6
        }
      ) => {
        return {
          url: `/classroom/articles`,
          params: {
            'c[type]': 'trading-ideas',
            'c[status]': 'published',
            'sort[published_at]': 'desc',
            populate:
              'populate=category,featured_image,featured_image_square,author,trading_ideas',
            ...params
          }
        };
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_TRADING_IDEAS, id: 'LIST' }];
      }
    }),
    getDetailTradingIdeas: build.query({
      query: (id = '') => {
        return {
          url: `/classroom/articles/${id}`,
          params: {
            'c[type]': 'trading-ideas',
            'c[status]': 'published',
            'sort[published_at]': 'desc',
            populate:'category,subcategory,tags,featured_image,featured_image_square,banner,author,trading_ideas.image'
          }
        };
      },
      transformResponse: (res) => res?.data || [],
      providesTags: (id) => {
        return [{ type: TAG_TRADING_IDEAS, id: id }];
      }
    }),
    getAuthorById: build.query({
      query: (id = '') => {
        return {
          url: `/classroom/authors/${id}`,
        };
      },
      transformResponse: (res) => res?.data || [],
      providesTags: (id) => {
        return [{ type: TAG_CLASSROOM_AUTHORS, id: id }];
      }
    }),
  })
});

export const invalidateTradingIdeas = () => {
  return ClassroomApi.util.invalidateTags([{ type: TAG_TRADING_IDEAS, id: 'LIST' }]);
}

export const {
  useGetTradingIdeasQuery,
  useGetDetailTradingIdeasQuery,
  useGetAuthorByIdQuery
} = ClassroomApi;
