import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { useEffect, useRef, useState } from 'react';
import InputParameter from './input-parameter';
import { checkFundamentalSuffix } from 'utils/stock/fundamental';
import { currency } from 'utils/format';
import { findParamStockScreenerByFieldName } from 'utils/stock/screener';

function InputComparison({
  paramCompareType,
  value,
  valueToCompare,
  onSelect = () => {},
  onChange = () => {},
  disabled,
  error
}) {
  const refInput = useRef(null);
  const [active, setActive] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const [tempTypeCompare, setTempTypeCompare] = useState(paramCompareType);
  const [isControl, setIsControl] = useState(false);

  const handleFocus = () => {
    refInput?.current?.setSelectionRange(0, 9999999);
  }

  useEffect(() => {
    setTempTypeCompare(paramCompareType || '');
  }, [paramCompareType]);

  useEffect(() => {
    if (tempTypeCompare === 'Value') {
      const suffix = checkFundamentalSuffix(findParamStockScreenerByFieldName(valueToCompare)?.name);
      const divider = suffix === 'B' ? 1000000000 : suffix === '%' ? 0.01 : 1;

      
      if (String(value) === String(value).replaceAll('.', '-')) {
        if (String(value) != '') {
          setTempValue(currency(Number(String(value).replaceAll('.', '')).toFixed(0) / divider, { removeSymbol: true }) || '');
        } else {
          setTempValue('');
        }
      } else {
        const splitText = suffix === '%' ? String(Number(value / divider).toFixed(2)).split('.') : String(value).split('.');
        const normalNumber = splitText[0];
        const decimalNumber = splitText[1];

        let newVal = currency(Number(normalNumber?.replaceAll('.', '')).toFixed(0) / (suffix === '%' ? 1 : divider), { removeSymbol: true }) || '';
        if (decimalNumber) setTempValue(`${newVal},${decimalNumber}`);
        if (!Number(decimalNumber)) setTempValue(currency(Number(normalNumber).toFixed(0) / (suffix === '%' ? 1 : divider), { removeSymbol: true }) || '');
      }
    } else {
      setTempValue(value || '');
    }
  }, [value, tempTypeCompare, valueToCompare]);

  const params = [
    {
      name: 'Value'
    },
    {
      name: 'Parameter'
    },
    // {
    //   name: 'Formula'
    // }
  ];

  return (
    <div className={`border rounded-lg text-xs flex items-center w-full ${error ? 'border-red-base-l' : 'border-gray-base-l dark:border-black-light-1-l'}`}>
      <div className={`w-[50%] relative h-[32px]`}>
        <div className={`border-r flex justify-between w-full pr-2 h-full items-center relative ${error ? 'border-red-base-l' : 'border-gray-base-l dark:border-black-light-1-l'}`}>
          <span className='absolute right-2 top-1/2 -translate-y-1/2 transform border-l pl-[6px] dark:border-black-light-1-l'>
            <IconChevronSingle
              className={` h-4 w-4 text-main-base-l transform flex-none ${
                active ? 'rotate-90' : '-rotate-90'
              }`}
            />
          </span>

          <input
            type="text"
            name="compare"
            disabled={disabled}
            readOnly
            value={tempTypeCompare}
            autoComplete="off"
            onFocus={() => setActive(true)}
            onBlur={() => setTimeout(() => setActive(false), 300)}
            className="appearance-none outline-none z-10 bg-transparent h-full w-full font-quicksand-semibold px-2 pr-6 rounded-lg rounded-r-none disabled:bg-[#bebebe33] cursor-pointer dark:text-black-light-1-d"
            placeholder="None"
          />
        </div>
        <ul
          className={`absolute left-0 top-full bg-white dark:bg-black-dark-d dark:text-black-light-1-d shadow-pro text-xs font-quicksand-semibold rounded-lg rounded-t-none min-w-[120px] ${
            active ? 'block z-30' : 'hidden'
          }`}
          style={{ width: 'calc(100% - 0.5rem)' }}>
          {params?.map((item, key) => (
            <li
              onClick={() => {
                onSelect(item);
                setActive(false);
              }}
              key={key}
              className="py-2 px-2 hover:text-main-base-l cursor-pointer">
              {item?.name}
            </li>
          ))}
        </ul>
      </div>
      <div
        className={`w-[50%] pl-2 flex justify-between relative`}>
        <RenderIf
          isTrue={tempTypeCompare === 'Value' || tempTypeCompare === 'Formula'}>
          <input
            ref={refInput}
            disabled={disabled}
            value={tempValue}
            type="text"
            maxLength={checkFundamentalSuffix(findParamStockScreenerByFieldName(valueToCompare)?.name) === 'B' ? 10 : 20}
            name="compare"
            autoComplete="off"
            onFocus={handleFocus}
            className={`appearance-none outline-none rounded-r-lg h-full w-full font-quicksand-semibold disabled:bg-[#bebebe33] dark:bg-black-base-d dark:text-black-light-1-d ${tempTypeCompare != 'Parameter' ? 'px-2 py-2' : ''}`}
            placeholder="Value"
            onKeyDown={(e) => {
              if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(e?.key)) {
                if (['Control', 'Meta'].includes(e?.key)) {
                  setIsControl(true);
                  setTimeout(() => setIsControl(false), 500);
                }

                if (isControl && ['a', 'v', 'c'].includes(e?.key)) {
                  // do nothing!
                } else {
                  e.preventDefault();
                  return false;
                }
              }
            }}
            onChange={(e) => {
              let filteredValue = String(e.target.value)?.replace(/[^0-9.,]/g, '');
              if (filteredValue === filteredValue.replaceAll(',', '-')) {
                if (filteredValue) {
                  setTempValue(currency(Number(filteredValue.replaceAll('.', '')).toFixed(0), { removeSymbol: true }) || '');
                } else {
                  setTempValue('');
                }
              } else {
                const splitText = filteredValue.split(',');
                const normalNumber = splitText[0];
                const decimalNumber = splitText[1];

                let newVal = currency(Number(normalNumber?.replaceAll('.', '')).toFixed(0), { removeSymbol: true }) || '';
                if (decimalNumber) newVal = `${newVal},${decimalNumber}`;
                if (decimalNumber) {
                  setTempValue(newVal);
                } else {
                  setTempValue(filteredValue);
                }
              }
            }}
            onBlur={(e) => {
              if (String(e.target.value) != '') {
                const suffix = checkFundamentalSuffix(findParamStockScreenerByFieldName(valueToCompare)?.name);
                const multiplier = suffix === 'B' ? 1000000000 : suffix === '%' ? 0.01 : 1;
                const replaceAllDots = e.target.value.replaceAll('.', '');
                const replaceAllCommaWithDots = replaceAllDots.replaceAll(',', '.');
                onChange(String(Number(replaceAllCommaWithDots).toFixed(2) * multiplier));
              } else {
                onChange('');
              }
            }}
          />
        </RenderIf>

        <RenderIf isTrue={tempTypeCompare === 'Parameter'}>
          <InputParameter
            borderLess
            disabled={disabled}
            value={findParamStockScreenerByFieldName(tempValue)?.name}
            valueToCompare={findParamStockScreenerByFieldName(valueToCompare)?.name}
            onSelect={(_value) => onChange(_value)}
          />
        </RenderIf>

        <RenderIf
          isTrue={
            checkFundamentalSuffix(findParamStockScreenerByFieldName(valueToCompare)?.name) && paramCompareType === 'Value'
          }>
          <span className="text-xs font-quicksand-semibold flex-none absolute right-3 top-1/2 transform -translate-y-1/2 dark:text-main-base-l">
            {checkFundamentalSuffix(findParamStockScreenerByFieldName(valueToCompare)?.name)}
          </span>
        </RenderIf>
      </div>
    </div>
  );
}

InputComparison.propTypes = {
  value: PropTypes.any,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  borderLess: PropTypes.bool,
  disabled: PropTypes.bool,
  valueToCompare: PropTypes.any,
  paramCompareType: PropTypes.any,
  error: PropTypes.any,
};

export default InputComparison;
