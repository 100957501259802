import { Children, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';

const AccordionSimple = ({ children, className  }) => {
  return (
    <ul className={className}>
      {
        Children.map(children, (child, idx) => {
          if (!isValidElement(child)) {
            return null;
          }

          return cloneElement(child, {
            key: idx,
          })
        })
      }
    </ul>
  )
}

AccordionSimple.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

AccordionSimple.defaultProps = {
  className: '',
}


export default AccordionSimple