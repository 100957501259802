import ActionList from '../basic/action-list';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import EmptyPP from '../../assets/images/dummy/photo-profile.webp';
import { currency } from 'utils/format';
import RenderIf from 'components/basic/render-if';
// import LogoTrust from 'assets/images/logo/trust.png';
import { Link } from 'react-router-dom';
import { invalidateBuyingPower, useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import { useCreateUserCoachMarkMutation } from 'services/rtk-query/user';
import IconSearch from 'components/svg/icon-search';
import { triggerSegmentEvent } from 'lib/segment';
import ModalFirstTimeRDN from 'components/modal/template/modal-first-time-rdn';
// import LogoPina from "components/svg/logo-pina";
import { isEmpty } from 'lodash';
import { useGetImageQuery } from 'services/rtk-query/image';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useCoachMark } from 'hooks/useCoachmark';
import { PinaTradeFirstTimeExplore, PinaTradeFirstTimeRDN } from 'constants/coachmark';
import ModalFirstTimeExplore from 'components/modal/template/modal-first-time-explore';
import BlinkText from 'components/basic/text-blink';
import Notification from './_partials/notification';
import { ListMenuHeader } from 'constants/list-menu';
// import InputSwitch from 'components/input/input-switch';
import useDarkMode from 'hooks/useDarkMode';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Modal from 'components/modal/modal';
import IconRefresh from 'components/svg/icon-refresh';
import clsx from 'clsx';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function NewTradingHeader() {
  const dispatch = useDispatch();
  const { customNavigate } = useCustomNavigate();
  const { removeDarkMode } = useDarkMode();
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const limitRef = useRef(null);
  const { user } = useBrowserTabAuth();
  const { cmRDN, cmFirstTimeExplore } = useCoachMark();
  const { data: trading, isLoading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true, pollingInterval: isBursaOpen ? 5000 : 36000 });
  const [createUserCoachMark] = useCreateUserCoachMarkMutation();
  const [avatarUrl, setAvatarUrl] = useState(EmptyPP);
  const AvPP = (user?.firstName?.charAt(0) || '');
  const { data: avatarURL} = useGetImageQuery(user?.imgAvatarUrl?.replace('BASE_URL/images/', ''));
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    if (avatarURL) setAvatarUrl(avatarURL);
  }, [avatarURL]);

  return (
    <div>
      <div className="h-[60px]"></div>
      <header className={`fixed left-0 top-0 w-full z-[45] pl-12 lg:pl-20 bg-white dark:bg-[#272a2d]`}>
        <div className="flex px-4 py-2">
          <div className="grow flex items-center justify-between">
            <div className="flex-none flex items-center">
              {/* <NavLink className="hidden md:block" to="/">
                <div className='flex items-center'>
                  <LogoPina className='h-9 w-auto' />
                  <div className='mx-2'>
                    <h5 className='font-quicksand-bold text-2xs mb-1 dark:text-black-light-1-d'>Powered&nbsp;By</h5>
                    <img
                      className="h-4 w-auto"
                      src={LogoTrust}
                      alt="Trust Sekuritas"
                    />
                  </div>
                </div>
              </NavLink> */}
            </div>

            <div className="w-3/4 max-w-lg">
              <div className="relative text-black-base-l">
                <div className={`relative z-30`}>
                  <div className="relative z-10 max-w-md mx-auto">
                    <input
                      data-qa-id="txtSearchStockField"
                      className="appearance-none outline-none card-widget-gradient py-[7px] px-5 pr-10 text-xs rounded-3xl w-full font-quicksand-semibold drop-shadow-sm"
                      placeholder="Ketik simbol saham"
                      onFocus={() => {
                        triggerSegmentEvent({ event: 'TR Search Feature Clicked' });
                        customNavigate({ query: { 'search-stock': true }});
                      }}
                    />

                    <button
                      type="button"
                      data-qa-id="btnSearchStockClear"
                      className={`appearance-none absolute right-4 top-1.5 text-main-base-l flex justify-center items-center`}>
                      <IconSearch className='h-5 w-5' />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-none flex items-center ml-3 lg:ml-4">
              <div className="flex justify-between flex-grow max-w-sm items-center">

                <div ref={limitRef} className='hidden dark:text-black-light-1-d h-[44px] gap-1 lg:flex items-center lg:mr-3'>
                  <div>
                    <a role='button' onClick={() => {
                      dispatch(invalidateBuyingPower());
                      setIsRefresh(true);
                      setTimeout(() => setIsRefresh(false), 500);
                    }} type='button' className={clsx('appearance-none flex-none hover:text-main-base-d flex items-center gap-1')}>
                      <h5 className="font-quicksand-regular text-xs">Limit</h5>
                      <IconRefresh className={clsx('h-[11px] w-[11px]')}/>
                    </a>

                    <Link onClick={() => isEmpty(trading) && removeDarkMode()} to={`${isEmpty(trading) ? '/onboarding/phone-number' : '/profile/deposit'}`} className='hover:text-main-base-l'>                  
                      <RenderIf isTrue={!trading?.isTopup && !isLoading && (!isLoading && !isRefresh)}>
                        <span className='text-main-base-l text-xs font-quicksand-semibold transition-all ease-linear duration-150 hover:opacity-75'>
                          {isEmpty(trading) && !user?.realAccountActive ? 'Buat Akun RDN' : 'Top-up RDN'}
                        </span>
                      </RenderIf>

                      <RenderIf isTrue={isRefresh || isLoading}>
                        <SkeletonTheme height="1rem" width="60px" borderRadius="6px"/>
                      </RenderIf>

                      <RenderIf isTrue={trading?.isTopup && (!isLoading && !isRefresh)}>
                        <p
                          data-qa-id="labelHeaderBuyingPower"
                          className="font-quicksand-semibold text-xs dark:text-main-base-l">
                          {!isLoading ? <BlinkText value={currency(trading?.buyingpower || 0, { removeSymbol: true }) || 0} /> : '*'}
                        </p>
                      </RenderIf>
                    </Link>
                  </div>
                </div>
              </div>

              <Notification />

              <ActionList
                className="right-0 top-8 w-48 text-black-base-l"
                data={ListMenuHeader}>
                <div
                  data-qa-id="btnHeaderProfile"
                  className="flex flex-none items-center relative">
                  <RenderIf isTrue={user?.imgAvatarUrl}>
                    <div className={`relative`}>
                      <div className='h-8 w-8 overflow-hidden rounded-full border-main-base-l border'>
                        <img
                          data-qa-id="imgHeaderProfile"
                          className="h-8 w-8 overflow-hidden rounded-full"
                          src={avatarUrl}
                          alt="Profile"
                        />
                      </div>
                    </div>
                  </RenderIf>

                  <RenderIf isTrue={!user?.imgAvatarUrl}>
                    <div className="rounded-full ml-3 bg-orange-1 flex items-center justify-center relative min-h-[40px] min-w-[40px]">
                      <span className="font-quicksand-bold text-sm uppercase">{AvPP || 'A'}</span>
                    </div>
                  </RenderIf>
                </div>
              </ActionList>
            </div>
          </div>
        </div>
      </header>

      <RenderIf isTrue={cmRDN}>
        <Modal>
          <ModalFirstTimeRDN
            onClose={async () => {
              await createUserCoachMark({ category: PinaTradeFirstTimeRDN , userId: user?.id}).unwrap();
              triggerSegmentEvent({ event: 'TR Menjelajah PINA Trade Clicked' });
            }}
          />
        </Modal>
      </RenderIf>
      
      <RenderIf isTrue={cmFirstTimeExplore}>
        <Modal>
          <ModalFirstTimeExplore
            onClose={async () => {
              await createUserCoachMark({ category: PinaTradeFirstTimeExplore , userId: user?.id}).unwrap();
              triggerSegmentEvent({ event: 'TR Menjelajah PINA Trade Clicked' });
            }}
          />
        </Modal>
      </RenderIf>
    </div>
  );
}

NewTradingHeader.propTypes = {};

export default NewTradingHeader;
