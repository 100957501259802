import PropTypes from 'prop-types';

function IconBuy({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.40379 12.4687L5.40453 12.4696C5.75296 12.8985 6.33366 13.1 7.11607 13.1H8.01339C8.92742 13.1 9.67178 12.9192 10.2379 12.5481L10.2379 12.5481L10.2389 12.5474C10.8174 12.158 11.1 11.539 11.1 10.7132V9.19342C11.1 8.6843 10.9995 8.26507 10.7889 7.94445C10.6236 7.69269 10.3942 7.50754 10.105 7.38767C10.3527 7.25904 10.5489 7.07465 10.691 6.83447C10.877 6.51995 10.9661 6.11706 10.9661 5.6329V4.28684C10.9661 3.46272 10.6935 2.84806 10.1325 2.46757C9.58362 2.08572 8.85168 1.9 7.94643 1.9C7.04104 1.9 6.30507 2.08577 5.7476 2.46713C5.17749 2.84733 4.9 3.46204 4.9 4.28684V10.4526C4.9 11.3576 5.06126 12.0368 5.40379 12.4687L5.40379 12.4687ZM7.39158 8.01974H8.48214C8.95178 8.01974 9.28533 8.1234 9.50088 8.31321C9.714 8.50088 9.82857 8.78781 9.82857 9.19342V10.8579C9.82857 11.1988 9.68549 11.448 9.39282 11.6182C9.09186 11.7931 8.62752 11.8868 7.98661 11.8868H7.11607C6.75264 11.8868 6.51783 11.7784 6.38202 11.5835C6.24773 11.3778 6.17143 11.0081 6.17143 10.4526V4.14211C6.17143 3.79917 6.30964 3.55013 6.58868 3.3809C6.8758 3.20678 7.31935 3.11316 7.93304 3.11316C8.54672 3.11316 8.99027 3.20678 9.27739 3.3809C9.55644 3.55013 9.69464 3.79917 9.69464 4.14211V5.6329C9.69464 6.03851 9.58007 6.32544 9.36695 6.51311C9.1514 6.70292 8.81785 6.80658 8.34821 6.80658H7.39158C7.33055 6.80658 7.27249 6.82123 7.22107 6.85365C7.17 6.88584 7.1311 6.93199 7.10229 6.98648C7.04623 7.0925 7.02372 7.23875 7.02372 7.41316C7.02372 7.58757 7.04623 7.73382 7.10229 7.83984C7.1311 7.89433 7.17 7.94048 7.22107 7.97267C7.27249 8.00509 7.33055 8.01974 7.39158 8.01974Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  );
}

IconBuy.propTypes = {
  className: PropTypes.string
};

export default IconBuy;
