import { object, string, number } from 'yup';

export const ProfilePersonalDataSchema = object().shape({
   // Infromasi Pribadi
   firstName: string().required('Required'),
   lastName: string().required('Required'),
   gender: number().required('Required'),
   birthPlace: string().required('Required'),
   birthDate: string().required('Required'),
   ktpNo: string().required('Required'),
   npwpNo: string().required('Required'),
   motherName: string().required('Required'),

   // Informasi Alamat
   phoneNo: string().required('Required'),
   email: string().required('Required'),
   address: string().required('Required'),
   nationality: string().required('Required'),
   provinceId: number().required('Required'),
   cityId: number().required('Required'),
   kecId: number().required('Required'),
   kelId: number().required('Required'),
   zipCode: number().required('Required'),

   // Informasi Pekerjaan
   occupation: number().required('Required'),
   companyName: string().required('Required'),
   industry: string().required('Required'),
   position: string().required('Required'),
   employmentLength: string().required('Required'),
   companyPhone: string().required('Required'),
   companyAddress: string().required('Required'),
   companyZipCode: string().required('Required'),

   // Informasi Finansial
   sourceFunds: number().required('Required'),
   monthlyIncome: string().required('Required'),
   monthlySpending: string().required('Required'),

   // Informasi Rekening
   bankAccountId: number().required('Required'),
   bankAccountHolder: string().required('Required'),
   bankAccountNo: string().required('Required'),
   rdnbankId: number().required('Required'),
   rdnbankAccountHolder: string().required('Required'),
   rdnbankAccountNo: string().required('Required')
});
