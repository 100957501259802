import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import IconPlus from 'components/svg/icon-plus';
import IconTrashCan from 'components/svg/icon-trash-can';
import useOuterClick from 'hooks/useOuterClick';
import IconSetting from 'components/svg/icon-setting';
import IconFileEdit from 'components/svg/icon-file-edit';
import { setCoachMarkWorkspace } from 'store/pina-pro';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import Modal from 'components/modal/modal';
import ModalDialog from 'components/modal/components/modal-dialog';
import { useDeleteWorkSpaceByIdMutation } from 'services/rtk-query/workspace';
import { triggerSegmentEvent } from 'lib/segment';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import IconStar from 'components/svg/icon-star';
import IconPlusCircle from 'components/svg/icon-plus-circle';

function Dropdown({
  onUpdateWorkspaceName = () => {},
  onClearDropdown = () => {},
  onClickDropdown = () => {},
  onRemoveWorkspace = () => {},
  showCloseButton,
  showDropdown
  // index
}) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const innerRef = useOuterClick((e) => {
    if (!e.target.classList.contains('dropdown-trigger')) {
      onClearDropdown();
    }
  });

  const handleClickDropDown = (e) => {
    let x = e.clientX - 210;
    let y = 50;
    if (x < 16) x = 16;
    setPosition({ x, y });
    onClickDropdown();
  };

  return (
    <div ref={innerRef}>
      <button
        type="button"
        onClick={handleClickDropDown}
        className={`dropdown-trigger appearance-none p-2 py-[5px] flex-none rounded-lg absolute right-0 top-0 ${
          showCloseButton ? 'opacity-100' : 'opacity-0'
        }`}>
        <IconSetting className="h-4 w-4 text-main-base-l dropdown-trigger" />
      </button>

      <div
        style={{ left: position.x + 'px', top: position.y + 'px' }}
        className={`fixed z-10 w-[220px] pt-2 ${
          showDropdown ? 'block' : 'hidden'
        }`}>
        <ul className={`shadow-pro rounded-xl overflow-hidden text-xs w-full`}>
          <li>
            <button
              onClick={() => {
                onUpdateWorkspaceName();
                onClearDropdown();
              }}
              type="button"
              className="font-quicksand-semibold flex justify-between w-full items-center py-[6px] px-3 bg-white dark:bg-black-dark-d dark:text-black-light-1-d text-black-base-l transition-all ease-linear duration-200 hover:bg-gray-light-l dark:hover:bg-gray-base-d hover:text-main-base-l">
              <span className="flex-auto block text-left">Ubah Nama</span>
              <IconFileEdit className="h-4 w-4 flex-none text-main-base-l" />
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                onRemoveWorkspace();
                onClearDropdown();
              }}
              type="button"
              className="font-quicksand-semibold flex justify-between w-full items-center py-[6px] px-3 bg-white dark:bg-black-dark-d transition-all ease-linear duration-200 hover:bg-gray-light-l dark:hover:bg-gray-base-d text-red-base-l hover:text-red-base-l">
              <span className="flex-auto block text-left">Hapus Workspace</span>
              <IconTrashCan className="h-4 w-4 flex-none" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  workspaces: PropTypes.array,
  onRemoveWorkspace: PropTypes.func,
  onAddWorkspace: PropTypes.func,
  onUpdateWorkspaceName: PropTypes.func,
  onSaveWorkspace: PropTypes.func,
  isSavedLayout: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClearDropdown: PropTypes.func,
  onClickDropdown: PropTypes.func,
  showCloseButton: PropTypes.bool,
  showDropdown: PropTypes.bool,
  index: PropTypes.number
};

function TabWorkspace({
  workspaces = [],
  onAddWorkspace = () => {},
  onAddWidget = () => {},
  onUpdateWorkspaceName = () => {},
  onInitCoachMark = () => {},
  onSetEmptyActiveWorkspace = () => {},
  isSavedLayout = true,
  isLoading = true
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();
  const refTab = useRef(null);
  const refAddTab = useRef(null);

  const { getErrorMessage } = useGetErrorMessage();

  const { slug } = useParams();
  const location = useLocation()
  const slugAbsolute = location?.pathname?.replaceAll('/', '')

  const [showCloseButton, setShowCloseButton] = useState(true);
  const [showSettingIndex, setShowSettingIndex] = useState('');
  const [showAddList, setShowAddList] = useState(false);
  const [openRemoveWorkspace, setOpenRemoveWorkspace] = useState(false);
  const [isSubmittingRemoveWorkspace, setIsSubmittingRemoveWorkspace] =
  useState(false);

  const [deleteWorkspace] = useDeleteWorkSpaceByIdMutation();
  
  const refShowAddList = useOuterClick(() => setShowAddList(false));

  useEffect(() => {
    if (ref.current) {
      setShowCloseButton(
        ref.current.clientWidth / (workspaces?.length + 1) > 120
      );
    }
  }, [workspaces]);

  useEffect(() => {
    onInitCoachMark([
      {
        activate: true,
        reference: refTab,
        tooltip: { position: 'bottom' },
        component: (
          <div className="block p-2 max-w-[280px]">
            <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">
              Ubah Nama dan Hapus Workspace
            </h5>
            <p className="text-gray-light-0-d text-xs mb-2">
              Klik icon disamping nama workspacemu untuk menganti nama dan
              menghapus workspace
            </p>

            <button
              onClick={() => dispatch(setCoachMarkWorkspace(1))}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l ml-auto mr-0 text-sm block font-quicksand-semibold">
              Selanjutnya
            </button>
          </div>
        )
      },
      {
        activate: true,
        reference: refAddTab,
        tooltip: { position: 'bottom' },
        component: (
          <div className="block p-2 max-w-[280px]">
            <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">
              Buat Workspace Baru
            </h5>
            <p className="text-gray-light-0-d text-xs mb-2">
              Tambahkan atau buat workspace baru sesuai dengan kebutuhan
              tradingmu
            </p>

            <div className="flex justify-between">
              <button
                onClick={() => dispatch(setCoachMarkWorkspace(0))}
                type="button"
                className="outline-none hover:opacity-75 text-main-base-l mr-0 text-sm block font-quicksand-semibold">
                Kembali
              </button>
              <button
                onClick={() => dispatch(setCoachMarkWorkspace(2))}
                type="button"
                className="outline-none hover:opacity-75 text-main-base-l ml-auto mr-0 text-sm block font-quicksand-semibold">
                Selanjutnya
              </button>
            </div>
          </div>
        )
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="flex max-w-[90vw] overflow-hidden">
        <SkeletonTheme
          containerClassName="mr-1"
          width="180px"
          height="2rem"
          borderRadius="6px"
        />

        <SkeletonTheme
          containerClassName="mr-1"
          width="180px"
          height="2rem"
          borderRadius="6px"
        />

        <SkeletonTheme
          containerClassName="mr-1"
          width="180px"
          height="2rem"
          borderRadius="6px"
        />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <div ref={refShowAddList} className="flex-auto relative">
        <div className="flex relative overflow-x-auto">
          <div
            className={clsx(
              'text-xs transition-all min-w-[150px] lg:min-w-[unset] font-quicksand-semibold ease-out duration-200 relative group flex items-center justify-between',
              slugAbsolute === 'default-workspace'
                ? 'text-black-base-l dark:text-black-light-1-d border-b-main-base-l border-b-2'
                : 'font-quicksand-medium text-gray-light-0-d dark:text-black-light-1-d border-gray-base-l dark:border-gray-base-d'
            )}>
            <Link
              to={`/default-workspace`}
              className="appearance-none outline-none px-3 py-[6px] flex-auto text-left relative group-hover:text-black-base-l dark:group-hover:text-main-base-l flex items-center gap-1">
              <IconStar className='h-3 w-3' />
              <span className="line-clamp-1">
                Watchlist
              </span>
            </Link>
          </div>
          <div
            className={clsx(
              'text-xs transition-all min-w-[150px] lg:min-w-[unset] font-quicksand-semibold ease-out duration-200 relative group flex items-center justify-between',
              slugAbsolute === 'absolute-workspace'
                ? 'text-black-base-l dark:text-black-light-1-d border-b-main-base-l border-b-2'
                : 'font-quicksand-medium text-gray-light-0-d dark:text-black-light-1-d border-gray-base-l dark:border-gray-base-d'
            )}>
            <Link
              to={`/absolute-workspace`}
              className="appearance-none outline-none px-3 py-[6px] flex-auto text-left relative group-hover:text-black-base-l dark:group-hover:text-main-base-l flex items-center gap-1">
              <span className="line-clamp-1">Absolute Workspace</span>
            </Link>
          </div>

          <RenderIf isTrue={workspaces?.length > 0}>
            {workspaces?.map((item, key) => (
              <div
                ref={key === 0 ? refTab : null}
                key={key}
                title={item?.name}
                className={`text-xs transition-all min-w-[150px] lg:min-w-[unset] font-quicksand-semibold ease-out duration-200 relative group flex items-center justify-between ${
                  item?.slug === slug
                    ? 'text-black-base-l dark:text-black-light-1-d border-b-main-base-l border-b-2'
                    : 'font-quicksand-medium text-gray-light-0-d dark:text-black-light-1-d border-gray-base-l dark:border-gray-base-d'
                }`}>
                <Link
                  to={`/workspace/${item?.slug}`}
                  className="appearance-none outline-none px-3 pr-8 py-[6px] flex-auto text-left relative group-hover:text-black-base-l dark:group-hover:text-main-base-l flex items-center gap-1">
                  <span className="line-clamp-1">
                    {item?.name} {!item?.unsaved}
                  </span>
                </Link>

                <RenderIf isTrue={item?.slug === slug}>
                  <Dropdown
                    onRemoveWorkspace={() => setOpenRemoveWorkspace(item)}
                    onClickDropdown={() => setShowSettingIndex(key)}
                    onClearDropdown={() => setShowSettingIndex('')}
                    isSavedLayout={isSavedLayout}
                    showCloseButton={showCloseButton || item?.slug === slug}
                    showDropdown={showSettingIndex === key}
                    index={key}
                    onUpdateWorkspaceName={() => onUpdateWorkspaceName(item)}
                  />
                </RenderIf>
              </div>
            ))}
          </RenderIf>

          <button
            ref={refAddTab}
            title="Buat Workspace Baru"
            type="button"
            onClick={() => {
              if (!isMobile) onAddWorkspace();
              setShowAddList(!showAddList);
            }}
            className="dark:text-main-base-l lg:px-3 py-[6px] font-quicksand-semibold text-xs flex border-gray-base-l dark:bg-[#272a2d] dark:border-gray-base-d items-center justify-center lg:justify-start sticky right-0 text-main-base-l transition-all ease-in-out duration-200">
            <IconPlusCircle className="h-4 w-4" />{' '}
            {/* <span className="hidden lg:block ml-1">Workspace</span> */}
          </button>
        </div>

        <RenderIf isTrue={showAddList}>
          <ul className="absolute right-0 text-xs font-quicksand-semibold rounded-lg shadow-pro overflow-hidden z-10 lg:hidden add-list">
            <li>
              <button
                onClick={onAddWorkspace}
                type="button"
                className="py-2 px-4 bg-white w-full dark:bg-black-dark-d dark:border-gray-base-d hover:text-main-base-l hover:bg-gray-light-l dark:hover:bg-gray-base-d dark:text-black-light-1-d">
                Workspace Baru
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={onAddWidget}
                className="py-2 px-4 bg-white w-full dark:text-black-light-1-d dark:bg-black-dark-d dark:border-gray-base-d hover:text-main-base-l hover:bg-gray-light-l dark:hover:bg-gray-base-d">
                Tambah Widget
              </button>
            </li>
          </ul>
        </RenderIf>
      </div>

      <RenderIf isTrue={openRemoveWorkspace}>
        <Modal onClose={() => setOpenRemoveWorkspace(false)}>
          <ModalDialog
            header={
              <h5 className="font-quicksand-semibold text-base">
                Hapus Workspace
              </h5>
            }
            submitTitle="Hapus"
            backTitle="Batal"
            isSubmitting={isSubmittingRemoveWorkspace}
            onClose={() => setOpenRemoveWorkspace(false)}
            onSubmit={async () => {
              setIsSubmittingRemoveWorkspace(true);
              try {
                if (openRemoveWorkspace?.id) {
                  await deleteWorkspace(openRemoveWorkspace?.id).unwrap();
                }

                const _tempWorkspace = workspaces.filter(
                  (item) => item?.slug !== openRemoveWorkspace?.slug
                );

                triggerSegmentEvent({ event: 'TR Workspace Deleted' });

                if (
                  activeWorkspace?.slug === openRemoveWorkspace?.slug &&
                  _tempWorkspace?.length > 0
                ) {
                  const _activeWorkspace =
                    _tempWorkspace[_tempWorkspace?.length - 1];
                  navigate('/workspace/' + _activeWorkspace?.slug);
                } else if (_tempWorkspace?.length === 0) {
                  onSetEmptyActiveWorkspace();
                  window.location.href = '/'
                }

                setIsSubmittingRemoveWorkspace(false);
                setOpenRemoveWorkspace(false);
              } catch (error) {
                getErrorMessage(error);
              }
            }}>
            <p className="font-quicksand-semibold text-sm">
              Apakah kamu yakin ingin menghapus workspace ini?
            </p>
          </ModalDialog>
        </Modal>
      </RenderIf>
    </div>
  );
}

TabWorkspace.propTypes = {
  workspaces: PropTypes.array,
  onRemoveWorkspace: PropTypes.func,
  onAddWorkspace: PropTypes.func,
  onAddWidget: PropTypes.func,
  onEditWorkspace: PropTypes.func,
  onUpdateWorkspaceName: PropTypes.func,
  onSaveWorkspace: PropTypes.func,
  isSavedLayout: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClearDropdown: PropTypes.func,
  onClickDropdown: PropTypes.func,
  onInitCoachMark: PropTypes.func
};

export default TabWorkspace;
