import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setDealerUseClientId } from 'store/auth';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import { isEmpty } from 'lodash';


const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { isLogin, user, roles, dealerUseClientId } = useBrowserTabAuth()
  const { state, pathname } = useLocation();
  const isAdminRole = !!roles?.find(role => role?.name === 'ROLE_ADMIN') && !user?.realAccountActive;

  if (isAdminRole && pathname !== '/admin') {
    if (!dealerUseClientId) {
      dispatch(setDealerUseClientId('MFK'));
    }
    return <Navigate to="/admin" state={state} replace />
  }

  if (!isAdminRole && pathname === '/admin') {
    return <Navigate to="/" state={state} replace />;
  }

  if (!user?.pinCreated && !isAdminRole && !isEmpty(user)) {
    return <Navigate to="/onboarding/status" state={state} replace />;
  }

  if (!isLogin) {
    return <Navigate to="/login" state={state} replace />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
