import useToastr from "./useToastr";

function useGetErrorMessage() {
  const toastr = useToastr();

  const getErrorMessage = (error) => {
    console.log(error);

    if (error?.msg) {
      return toastr.error(error?.msg);
    }

    if (error?.data?.message) {
      return toastr.error(error?.data?.message);
    }

    if (error?.message) {
      return toastr.error(error?.message);
    }

    return toastr.error('Oops! Something went wrong..');
  }

  return { getErrorMessage }
}

export default useGetErrorMessage;