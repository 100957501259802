import APIService from 'services/api.service';
import { getCookie } from 'utils/cookie';
import { dayjsLocal } from 'utils/formatter/date';

const history = {};

window.countRender = {};
window.historiesChart = {};
window.currentResolution = {};

export default function historyProvider(idWidget) {
  return {
    history: history,
    getBars: async function (
      symbolInfo,
      resolution,
      // eslint-disable-next-line no-unused-vars
      { from, to, countBack, firstDataRequest }
    ) {
      let token = getCookie('pn_trade_auth_token');
      let histories = window.historiesChart?.[`${symbolInfo?.name}-${idWidget}`] || [];
  
      if (token) {
        APIService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const tStockDetail = await APIService.get(`/stocks/${symbolInfo?.name}`);
        
        let intraDayHistories = await APIService.get(`/stocks/${symbolInfo?.name}/chart`, { params: {
          startDate: dayjsLocal().format('MM/DD/YYYY'),
          endDate: dayjsLocal().format('MM/DD/YYYY'),
          dayRecord: 'daily'
        }});
  
        let openOHLCData = {
          "AtHour": 8,
          "AtMinute": 59,
          "Open": tStockDetail?.openPrice,
          "High": tStockDetail?.highTradedPrice,
          "Low": tStockDetail?.lowTradedPrice,
          "Close": tStockDetail?.previousLastTradedPrice,
          "Vol": tStockDetail?.tradableVolume || 0,
          "Freq": tStockDetail?.frequency,
          "Val": tStockDetail?.tradableValue
        }
  
        let hh = openOHLCData?.AtHour;
        let mm = openOHLCData?.AtMinute;
        let time = dayjsLocal(`${hh}:${mm}`, 'HH:mm').toDate();
  
        if (dayjsLocal().day() === 6) time = dayjsLocal(`${hh}:${mm}`, 'HH:mm').subtract(1, 'day').toDate()
        if (dayjsLocal().day() === 0) time = dayjsLocal(`${hh}:${mm}`, 'HH:mm').subtract(2, 'day').toDate()
  
        if (resolution == 1) {
          if (intraDayHistories?.length > 0) {
            intraDayHistories = [{
              bizDate: time,
              low: openOHLCData?.Low,
              high: openOHLCData?.High,
              openPrice: openOHLCData?.Open,
              closePrice: openOHLCData?.Close,
              volume: openOHLCData?.Vol
            }, ...intraDayHistories];
          };
          histories = intraDayHistories;
          window.currentResolution[`${symbolInfo?.name}-${idWidget}`] = 1;
        } else {
          if (window.currentResolution[`${symbolInfo?.name}-${idWidget}`] === 1 || histories?.length === 0) {
            histories = await APIService.get(`/stocks/${symbolInfo?.name}/chart`, { params: {
              startDate: dayjsLocal().subtract(20, 'year').format('MM/DD/YYYY'),
              endDate: dayjsLocal().format('MM/DD/YYYY'),
              dayRecord: 'daily'
            }});
  
            if (intraDayHistories?.length > 0) {
              histories = [...histories, {
                bizDate: time,
                low: openOHLCData?.Low,
                high: openOHLCData?.High,
                openPrice: openOHLCData?.Open,
                closePrice: openOHLCData?.Close,
                // volume: openOHLCData?.Vol
              }];
            }
          }
          
          window.currentResolution[`${symbolInfo?.name}-${idWidget}`] = 2;
        }
      }
      
      window.historiesChart[`${symbolInfo?.name}-${idWidget}`] = histories;
  
      if (!histories || histories?.length === 0) {
        return [];
      }
  
      var bars = histories?.map((el) => {
        return {
          time: resolution == 1 ? dayjsLocal(el?.bizDate).valueOf() : dayjsLocal(el?.bizDate).add(7, 'hour')?.valueOf(),
          low: el.low,
          high: el.high,
          open: el.openPrice,
          close: el.closePrice,
          // volume: el.volume
        };
      });
  
      if (firstDataRequest) {
        var lastBar = bars[bars.length - 1];
        history[symbolInfo.name] = { lastBar: lastBar };
      }
  
      if (window.countRender[`${symbolInfo.name}-${idWidget}`] > 1 && bars?.length > 0) {
        setTimeout(() => {
          window.countRender[`${symbolInfo.name}-${idWidget}`] = 0;
        }, 300);
        return [];  
      }
  
      window.countRender[`${symbolInfo.name}-${idWidget}`] = (window.countRender[`${symbolInfo.name}-${idWidget}`] || 0) + 1;
  
      return bars;
    }
  };
}
