// eslint-disable-next-line react/prop-types
function IconPopUpHide({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4326_30694)">
        <path d="M11.8995 18.8236C11.8995 19.3759 11.4518 19.8236 10.8995 19.8236C10.3472 19.8236 9.8995 19.3759 9.8995 18.8236V15.9952L5.24265 20.652C4.85212 21.0426 4.21896 21.0426 3.82843 20.652C3.43791 20.2615 3.43791 19.6284 3.82843 19.2378L8.48529 14.581H5.65686C5.10458 14.581 4.65686 14.1333 4.65686 13.581C4.65686 13.0287 5.10458 12.581 5.65686 12.581L10.8995 12.581C11.4518 12.581 11.8995 13.0287 11.8995 13.581L11.8995 18.8236Z" />
        <path d="M13.039 5.65685C13.039 5.10457 13.4867 4.65685 14.039 4.65685C14.5913 4.65685 15.039 5.10457 15.039 5.65685V8.48528L19.6958 3.82843C20.0864 3.4379 20.7195 3.4379 21.11 3.82843C21.5006 4.21895 21.5006 4.85212 21.11 5.24264L16.4532 9.89949H19.2816C19.8339 9.89949 20.2816 10.3472 20.2816 10.8995C20.2816 11.4518 19.8339 11.8995 19.2816 11.8995L14.039 11.8995C13.4867 11.8995 13.039 11.4518 13.039 10.8995L13.039 5.65685Z" />
      </g>
      <defs>
        <clipPath id="clip0_4326_30694">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPopUpHide;
