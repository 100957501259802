import PropTypes from 'prop-types';

function IconMenuHome({ className }) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 13.1284V7.87842H3V13.1284C3 14.7853 4.34315 16.1284 6 16.1284H12C13.6569 16.1284 15 14.7853 15 13.1284V7.87842H13.5V13.1284C13.5 13.9568 12.8284 14.6284 12 14.6284H11.25V13.1284C11.25 11.8858 10.2426 10.8784 9 10.8784C7.75736 10.8784 6.75 11.8858 6.75 13.1284V14.6284H6C5.17157 14.6284 4.5 13.9568 4.5 13.1284ZM8.25 14.6284H9.75V13.1284C9.75 12.7142 9.41421 12.3784 9 12.3784C8.58579 12.3784 8.25 12.7142 8.25 13.1284V14.6284Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2608 7.05779C11.6618 7.06982 12.0413 7.24198 12.3145 7.53581C12.4073 7.63563 12.5142 7.71875 12.6357 7.77701C12.7521 7.83276 12.9078 7.87865 13.125 7.87865C13.4124 7.87865 13.6772 7.74174 13.8924 7.46507C14.1124 7.18221 14.25 6.78395 14.25 6.37865C14.25 4.98496 13.1563 4.12881 12.0003 4.12935C11.6899 4.1295 10.7345 4.12925 9.66972 4.12897L9.66721 4.12897C8.16129 4.12858 6.43824 4.12814 6.00089 4.12865C4.84299 4.13002 3.75 4.98583 3.75 6.37865C3.75 6.78395 3.88764 7.18221 4.10764 7.46507C4.32282 7.74174 4.58761 7.87865 4.875 7.87865C5.09222 7.87865 5.24797 7.83275 5.36431 7.77699C5.48588 7.71873 5.59279 7.63559 5.68559 7.53576C5.95872 7.24192 6.33816 7.06974 6.73915 7.05768C7.14014 7.04562 7.52925 7.19468 7.81954 7.47156C8.0205 7.66324 8.36849 7.87856 9.00011 7.87865C9.63165 7.87874 9.97954 7.66345 10.1805 7.47178C10.4707 7.19486 10.8598 7.04576 11.2608 7.05779ZM11.2651 8.60872C11.667 9.01921 12.2715 9.37865 13.125 9.37865C13.9607 9.37865 14.6339 8.95486 15.0764 8.38598C15.5125 7.82523 15.75 7.09854 15.75 6.37865C15.75 4.02939 13.8514 2.62849 11.9997 2.62935C11.6897 2.62949 10.7338 2.62925 9.66759 2.62897C8.16267 2.62858 6.43789 2.62813 5.99911 2.62865C4.14916 2.63084 2.25 4.02864 2.25 6.37865C2.25 7.09854 2.48747 7.82523 2.92361 8.38598C3.36607 8.95486 4.03932 9.37865 4.875 9.37865C5.72866 9.37865 6.33331 9.01904 6.7352 8.60843C6.7519 8.59137 6.76825 8.57422 6.78425 8.557C6.8017 8.57364 6.81947 8.59017 6.83759 8.60658C7.3081 9.03278 8.00803 9.37851 8.99989 9.37865C9.99181 9.37879 10.6918 9.03311 11.1623 8.60686C11.1805 8.59039 11.1984 8.57381 11.2159 8.55712C11.2319 8.57439 11.2483 8.5916 11.2651 8.60872Z"
      />
    </svg>
  );
}

IconMenuHome.propTypes = {
  className: PropTypes.string
};

export default IconMenuHome;
