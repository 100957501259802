import { object, string, array } from 'yup';

export const ScreenerSchema = object().shape({
  group_metrics: array(
    object().shape({
      metrics: array(
        object().shape({
          param: string().required('Required'),
          valueCompare: string().test('valueCompare', 'Required', (value, { parent }) => {
            return value || parent?.paramCompareType != 'Value';
          }),
          paramCompare: string().test('paramCompare', 'Required', (value, { parent }) => {
            if (parent?.paramCompareType === 'Value') {
              return true;
            }
            return value;
          }),
        })
      )
    })
  )
});
