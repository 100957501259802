import Breadcrumb from 'components/basic/breadcrumb';
import { Helmet } from 'react-helmet-async';
import SectionSectorStockList from './_partials/section-sector-stock-list';

function PageTradingSearchSector() {
  return (
    <div>
      <div className="p-4">
        <Helmet>
          <title>Saham Sector | Pina Trade</title>
        </Helmet>

        <Breadcrumb
          data={[
            {
              link: '/stock-market',
              title: 'Stock Market',
              icon: null
            },
            {
              link: '#',
              title: 'Saham Sector',
              icon: null
            }
          ]}
        />

        <div className="grid grid-cols-1">
          <SectionSectorStockList />
        </div>
      </div>
    </div>
  );
}

export default PageTradingSearchSector;
