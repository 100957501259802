import PropTypes from 'prop-types';
import Card from 'components/basic/card';

import IMG_PRESET_PINA from 'assets/images/mascot/preset-pina.png';
import IMG_PRESET_SELF from 'assets/images/mascot/preset-self.png';
import IMG_PRESET_SCALPING from 'assets/images/mascot/preset-scalping.png';
import IMG_PRESET_SWING from 'assets/images/mascot/preset-swing.png';
import IMG_PRESET_LONGTERM from 'assets/images/mascot/preset-longterm.png';

import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';

import { useContext, useEffect, useState } from 'react';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import useToastr from 'hooks/useToastr';
import { useCreateWorkSpaceByUserIdMutation } from 'services/rtk-query/workspace';
import { useNavigate } from 'react-router-dom';
import slugify from 'utils/formatter/slugify';
import SimpleSpinner from 'components/common/spinner/spinner';
import { DEFAULT_WORKSPACE_2, WORKSPACE_PINA_LONGTERM_2, WORKSPACE_PINA_SCALPING_2, WORKSPACE_PINA_SWING_2 } from 'constants/workspace';
import { ModalContext } from '../context/modalContext';
import { useCreateUserCoachMarkMutation } from 'services/rtk-query/user';
import { PinaTradeWorkspaceBeforeCreated } from 'constants/coachmark';
import { triggerSegmentEvent } from 'lib/segment';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function CardWorkspace({ data = [], value, onSelect = () => {} }) {
  const [opens, setOpens] = useState([]);

  return (
    <>
      {data?.map((item, key) => (
        <div key={key}>
          <Card
            className={`border-2 relative hover:border-main-base-l transition-all ease-linear duration-150 cursor-pointer overflow-hidden ${
              value === item?.value ? 'border-main-base-l' : 'border-gray-base-l dark:border-gray-base-d'
            }`}>
            <button
              type="button"
              className="outline-none appearance-none p-4 rounded-lg overflow-hidden"
              onClick={() => onSelect(item)}>
              <span className="border-2 border-main-base-l h-4 w-4 absolute left-5 top-5 lg:right-5 lg:top-5 rounded-full flex items-center justify-center">
                {value === item?.value && (
                  <span className="h-2 w-2 bg-main-base-l rounded-full"></span>
                )}
              </span>

              <img
                src={item?.image}
                className="w-full h-auto aspect-[265/150] mb-2 hidden lg:block dark:rounded-lg"
                alt="workspace pina"
              />

              <h5 className="text-base text-left mb-1 pl-6 lg:pl-0">{item?.title}</h5>

              {item?.subTitle && (
                <p className="text-gray-light-0-d text-2xs text-left my-1 mb-2">
                  {item?.subTitle}
                </p>
              )}

              <p className="text-gray-light-0-d text-xs text-left mb-3 h-16 line-clamp-4">
                {item?.description}
              </p>

              {item?.widgets?.length > 0 && (
                <Accordion>
                  <AccordionItem
                    expandable={false}
                    expanded={opens?.includes(item?.value)}
                    onToggle={() => {
                      if (opens?.includes(item?.value)) {
                        setOpens(opens?.filter((o) => o != item?.value));
                      } else {
                        setOpens([...opens, item?.value]);
                      }
                    }}>
                    <AccordionItem.Slot name="Header">
                      <div className="flex justify-between">
                        <span className="text-main-base-l text-xs font-quicksand-semibold">
                          Widget Workpace
                        </span>
                        <IconChevronSingle
                          className={`h-4 w-4 transform text-main-base-l ${
                            opens?.includes(item?.value)
                              ? 'rotate-90'
                              : '-rotate-90'
                          }`}
                        />
                      </div>
                    </AccordionItem.Slot>
                    <AccordionItem.Slot name="Content">
                      <div className="mt-2">
                        <ul
                          className={`list-disc text-left text-2xs text-gray-dark-d pl-6 bg-gray-light-l dark:text-black-light-1-d dark:bg-black-dark-d p-3 rounded-md`}>
                          {item?.widgets?.map((widgetName, key) => (
                            <li key={key}>{widgetName}</li>
                          ))}
                        </ul>
                      </div>
                    </AccordionItem.Slot>
                  </AccordionItem>
                </Accordion>
              )}
            </button>
          </Card>
        </div>
      ))}
    </>
  );
}

CardWorkspace.propTypes = {
  data: PropTypes.array,
  value: PropTypes.any,
  onSelect: PropTypes.func,
};

function ModalFirstTimeWorkspace2({ onPickSelfWorkspace = () => {} }) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const navigate = useNavigate();
  const { user } = useBrowserTabAuth();

  const  { handleModal } = useContext(ModalContext);

  const [modeWorkspace, setModeWorkspace] = useState(null);
  const [workspacePina, setWorkspacePina] = useState(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [createWorkspace] = useCreateWorkSpaceByUserIdMutation();
  const [createUserCoachMark] = useCreateUserCoachMarkMutation();

  const listModeWorkspace = [
    {
      title: 'Pakai Workspace Dari PINA',
      description:
        'PINA sudah merancang Workspace sesuai dengan gaya tradingmu. Workspace ini mencakup komponen-komponen dasar yang membantu Kamu memantau dan mengelola perdagangan sesuai dengan gaya tradingmu',
      image: IMG_PRESET_PINA,
      value: 'WORKSPACE_PINA'
    },
    {
      title: 'Buat Workspacemu Sendiri',
      description:
        'Dengan kustomisasi Workspace Kamu dapat menciptakan tampilan yang disesuaikan dengan kebutuhan dan preferensi tradingmu. Optimalkan pengalaman perdagangan Kamu dan dapatkan pemantauan dan analisis yang lebih efektif.',
      image: IMG_PRESET_SELF,
      value: 'WORKSPACE_SELF',
      layout: DEFAULT_WORKSPACE_2
    }
  ];

  const listWorkspacePina = [
    {
      title: 'Day/Scalping Workspace',
      subTitle: 'Maksimalkan potensi keuntungan cepat dan tepat',
      description:
        'Workspace dirancang khusus untuk mendukung trader dalam gaya trading yang mengandalkan pergerakan harga yang cepat dan volatilitas pasar.',
      image: IMG_PRESET_SCALPING,
      value: 'WORKSPACE_PINA_SCALPING_2',
      widgets: [
        'Running Trade',
        'Orderbook',
        'Fast Order',
        'Broker Summary',
        'Orderlist, Tradelist, Portfolio'
      ],
      layout: WORKSPACE_PINA_SCALPING_2
    },
    {
      title: 'Swing Workspace',
      subTitle: 'Maksimalkan peluang dan potensi keuntungan',
      description:
        'Workspace ini membantu menganalisis pergerakan harga jangka menengah dan mengidentifikasi peluang trading yang berpotensi menguntungkan.',
      image: IMG_PRESET_SWING,
      value: 'WORKSPACE_PINA_SWING_2',
      widgets: [
        'News',
        'Broker Summary',
        'Watchlist',
        'Trading Ideas',
        'Orderlist, Tradelist, Portfolio'
      ],
      layout: WORKSPACE_PINA_SWING_2
    },
    {
      title: 'Longterm Workspace',
      subTitle: 'Pantau kinerja portofolio Kamu dengan lebih efektif',
      description:
        'Workspace ini memberikan Kamu alat dan informasi yang diperlukan untuk mengawasi investasi jangka panjang ',
      image: IMG_PRESET_LONGTERM,
      value: 'WORKSPACE_PINA_LONGTERM_2',
      widgets: [
        'Watchlist',
        'Chart & Orderbook',
        'Quick Order',
        'Orderlist, Tradelist, Portfolio',
        'Info Cash'
      ],
      layout: WORKSPACE_PINA_LONGTERM_2
    }
  ];

  const handlePickWorkspace = async () => {
    try {
      setLoading(true);
      const title = workspacePina?.title === 'Buat Workspacemu Sendiri' ? 'My Workspace' : workspacePina?.title;
      const slug = slugify(title);
      const payload = { name: title, slug: slug, is_active: true, unsaved: true, layout: JSON.stringify(workspacePina?.layout) };
      await createWorkspace({ body: payload, userId: user?.id }).unwrap();
      await createUserCoachMark({ category: PinaTradeWorkspaceBeforeCreated , userId: user?.id}).unwrap();

      triggerSegmentEvent({
        event: 'TR Preset Workspace Selected',
        properties: { workspaceType: workspacePina?.title }
      });

      navigate(`/workspace/${slug}`);
      toastr.dismiss();
      handleModal(false);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePickModeWorkspace = async () => {
    if (modeWorkspace?.value != "WORKSPACE_SELF") {
      triggerSegmentEvent({
        event: 'TR Workspace Option Selected',
        properties: { workspaceOption: 'Self' }
      });
      return setStep(step + 1);
    }

    try {
      setLoading(true);
      const slug = slugify(modeWorkspace?.title);
      const payload = { name: modeWorkspace?.title, slug: slug, is_active: true, unsaved: true, layout: JSON.stringify(modeWorkspace?.layout) };
      const { data } = await createWorkspace({ body: payload, userId: user?.id }).unwrap();
      await createUserCoachMark({ category: PinaTradeWorkspaceBeforeCreated , userId: user?.id}).unwrap();
      navigate(`/workspace/${slug}`);
      toastr.dismiss();
      onPickSelfWorkspace(data);
      
      triggerSegmentEvent({
        event: 'TR Workspace Option Selected',
        properties: { workspaceOption: 'Preset' }
      });
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (step === 0 && modeWorkspace) {
      setIsValid(true);
    } else if (step === 1 && workspacePina) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [step, modeWorkspace, workspacePina]);

  if (step === 1) {
    return (
      <Card className="p-6 lg:p-8 max-w-4xl md:mx-auto relative mx-6 lg:pt-10 mt-12 dark:border dark:border-gray-base-d dark:text-black-light-1-d">
        <div className="mb-8">
          <h5 className="text-xl lg:text-center font-quicksand-semibold mb-2 dark:text-black-light-1-d">
            Pilih Workspace Kamu
          </h5>

          <p className="text-gray-light-0-d text-sm mb-6 lg:text-center">
            Pilih dan atur Workspace yang sesuai dengan gaya Tradingmu
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 text-left">
            <CardWorkspace
              data={listWorkspacePina}
              value={workspacePina?.value}
              onSelect={(item) => setWorkspacePina(item)}
            />
          </div>
        </div>

        <div className="flex justify-end flex-col lg:flex-row">
          <button
            type="button"
            disabled={loading}
            onClick={() => {
              triggerSegmentEvent({ event: 'TR Preset Workspace Selected' });
              setStep(step - 1);
            }}
            className="px-4 py-3 flex items-center justify-center outline-none text-center text-main-base-l bg-transparent w-full max-w-[150px] mx-auto lg:mr-0 lg:ml-auto mt-2 text-sm hover:opacity-75 transition-all ease-in-out duration-150 font-quicksand-semibold">
            Kembali
          </button>

          <button
            type="button"
            disabled={!isValid || loading}
            onClick={handlePickWorkspace}
            className="px-4 py-3 flex items-center justify-center outline-none text-center text-white bg-main-base-l dark:text-black-base-d rounded-xl w-full max-w-[300px] lg:mr-0 lg:ml-[1rem] mt-2 text-sm hover:opacity-75 transition-all ease-in-out duration-150 font-quicksand-semibold disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100">
            { loading && <SimpleSpinner />} Selanjutnya
          </button>
        </div>
      </Card>
    );
  }

  return (
    <Card className="p-6 lg:p-8 max-w-xl md:mx-auto relative mx-6 lg:pt-10 mt-12 dark:border dark:border-gray-base-d dark:text-black-light-1-d">
      <div className="mb-8">
        <h5 className="text-xl lg:text-center font-quicksand-semibold mb-2">
          Selamat Datang di Workspace PINA Trade!
        </h5>

        <p className="text-gray-light-0-d text-xs lg:text-sm mb-6 lg:text-center">
          Workspace adalah ruang kerja untuk Kamu sebagai trader atau investor
          untuk mengelola portofolio, mengakses data pasar secara real-time,
          melakukan analisis, dan menjalankan transaksi dengan cepat sesuai
          dengan gaya tradingmu.
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 text-left">
          <CardWorkspace
            data={listModeWorkspace}
            value={modeWorkspace?.value}
            onSelect={(item) => setModeWorkspace(item)}
          />
        </div>
      </div>

      <button
        type="button"
        disabled={!isValid || loading}
        onClick={handlePickModeWorkspace}
        className="px-4 py-3 flex items-center justify-center outline-none text-center text-white bg-main-base-l dark:text-black-base-d rounded-xl w-full max-w-[300px] mx-auto mt-2 text-sm hover:opacity-75 transition-all ease-in-out duration-150 font-quicksand-semibold disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100">
        { loading && <SimpleSpinner />} Selanjutnya
      </button>
    </Card>
  );
}

ModalFirstTimeWorkspace2.propTypes = {
  onPickSelfWorkspace: PropTypes.func
};

export default ModalFirstTimeWorkspace2;
