import TradingRoutes from './trading.routes'
import PageProfile from 'pages/profile/index'
import PageDeposit from 'pages/profile/deposit'
import PageAccountSettings from 'pages/profile/account-settings'
import PageSecuritySettings from 'pages/profile/security-settings'
import OnboardingPage from 'pages/onboarding'
import ForgotPaswordPage from 'pages/forgot-password'
import LoginPage from 'pages/login/index'
import RegisterPage from 'pages/register'
import LogoutPage from 'pages/logout'
import AdminRoutes from './admin.routes'

export const routes = [
  {
    path: "/profile",
    index: false,
    element: PageProfile,
    layout: 'trading',
    auth: true,
  },
  {
    path: "/profile/:slug",
    index: false,
    element: PageDeposit,
    layout: 'trading',
    auth: true,
  },
  {
    path: "/profile/account-settings/:slug",
    index: false,
    element: PageAccountSettings,
    layout: 'trading',
    auth: true,
  },
  {
    path: "/profile/security-settings/:slug",
    index: false,
    element: PageSecuritySettings,
    layout: 'trading',
    auth: true,
  },
  {
    path: "/onboarding/:slug",
    index: false,
    element: OnboardingPage,
    layout: 'onboarding',
    auth: true,
    onboarding: true
  },
  {
    path: "/forgot-password",
    index: false,
    element: ForgotPaswordPage,
    layout: 'onboarding',
    auth: false,
  },
  {
    path: "/login",
    index: false,
    element: LoginPage,
    layout: 'onboarding',
    auth: false,
  },
  {
    path: "/register",
    index: false,
    element: RegisterPage,
    layout: 'onboarding',
    auth: false,
  },
  {
    path: "/logout",
    index: false,
    element: LogoutPage,
    layout: 'blank',
    auth: false,
  },
  ...TradingRoutes,
  ...AdminRoutes
]

export default routes