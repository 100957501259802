import PropTypes from 'prop-types';

function IconWidgetList({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33335 5.33334C7.33335 4.96515 7.63183 4.66667 8.00002 4.66667H10.6667C11.0349 4.66667 11.3334 4.96515 11.3334 5.33334C11.3334 5.70153 11.0349 6.00001 10.6667 6.00001H8.00002C7.63183 6.00001 7.33335 5.70153 7.33335 5.33334ZM8.00002 7.33334C7.63183 7.33334 7.33335 7.63182 7.33335 8C7.33335 8.36819 7.63183 8.66667 8.00002 8.66667H10.6667C11.0349 8.66667 11.3334 8.36819 11.3334 8C11.3334 7.63182 11.0349 7.33334 10.6667 7.33334H8.00002ZM8.00002 10C7.63183 10 7.33335 10.2985 7.33335 10.6667C7.33335 11.0349 7.63183 11.3333 8.00002 11.3333H10.6667C11.0349 11.3333 11.3334 11.0349 11.3334 10.6667C11.3334 10.2985 11.0349 10 10.6667 10H8.00002ZM5.33335 11.3333C5.70154 11.3333 6.00002 11.0349 6.00002 10.6667C6.00002 10.2985 5.70154 10 5.33335 10C4.96516 10 4.66669 10.2985 4.66669 10.6667C4.66669 11.0349 4.96516 11.3333 5.33335 11.3333ZM6.00002 8C6.00002 8.36819 5.70154 8.66667 5.33335 8.66667C4.96516 8.66667 4.66669 8.36819 4.66669 8C4.66669 7.63182 4.96516 7.33334 5.33335 7.33334C5.70154 7.33334 6.00002 7.63182 6.00002 8ZM5.33335 6.00001C5.70154 6.00001 6.00002 5.70153 6.00002 5.33334C6.00002 4.96515 5.70154 4.66667 5.33335 4.66667C4.96516 4.66667 4.66669 4.96515 4.66669 5.33334C4.66669 5.70153 4.96516 6.00001 5.33335 6.00001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9048 2H9.09521C9.81773 1.99999 10.4005 1.99999 10.8724 2.03855C11.3583 2.07825 11.7851 2.16212 12.18 2.36331C12.8072 2.68289 13.3171 3.19283 13.6367 3.82003C13.8379 4.21489 13.9218 4.64169 13.9615 5.12758C14 5.5995 14 6.18226 14 6.90478V9.09522C14 9.81774 14 10.4005 13.9615 10.8724C13.9218 11.3583 13.8379 11.7851 13.6367 12.18C13.3171 12.8072 12.8072 13.3171 12.18 13.6367C11.7851 13.8379 11.3583 13.9218 10.8724 13.9615C10.4005 14 9.81774 14 9.09522 14H6.90478C6.18226 14 5.5995 14 5.12758 13.9615C4.64168 13.9218 4.21489 13.8379 3.82003 13.6367C3.19283 13.3171 2.68289 12.8072 2.36331 12.18C2.16212 11.7851 2.07825 11.3583 2.03855 10.8724C1.99999 10.4005 1.99999 9.81773 2 9.09521V6.9048C1.99999 6.18227 1.99999 5.5995 2.03855 5.12758C2.07825 4.64168 2.16212 4.21489 2.36331 3.82003C2.68289 3.19283 3.19283 2.68289 3.82003 2.36331C4.21489 2.16212 4.64169 2.07825 5.12758 2.03855C5.5995 1.99999 6.18227 1.99999 6.9048 2ZM5.23616 3.36745C4.83272 3.40041 4.60092 3.46186 4.42535 3.55132C4.04903 3.74307 3.74307 4.04903 3.55132 4.42535C3.46186 4.60092 3.40041 4.83272 3.36745 5.23616C3.33385 5.64739 3.33333 6.1756 3.33333 6.93333V9.06667C3.33333 9.8244 3.33385 10.3526 3.36745 10.7638C3.40041 11.1673 3.46186 11.3991 3.55132 11.5746C3.74307 11.951 4.04903 12.2569 4.42535 12.4487C4.60092 12.5381 4.83272 12.5996 5.23616 12.6326C5.64739 12.6661 6.1756 12.6667 6.93333 12.6667H9.06667C9.8244 12.6667 10.3526 12.6661 10.7638 12.6326C11.1673 12.5996 11.3991 12.5381 11.5746 12.4487C11.951 12.2569 12.2569 11.951 12.4487 11.5746C12.5381 11.3991 12.5996 11.1673 12.6326 10.7638C12.6661 10.3526 12.6667 9.82441 12.6667 9.06667V6.93333C12.6667 6.1756 12.6661 5.64739 12.6326 5.23616C12.5996 4.83272 12.5381 4.60092 12.4487 4.42535C12.2569 4.04903 11.951 3.74307 11.5746 3.55132C11.3991 3.46186 11.1673 3.40041 10.7638 3.36745C10.3526 3.33385 9.82441 3.33333 9.06667 3.33333H6.93333C6.1756 3.33333 5.64739 3.33385 5.23616 3.36745Z"
        fill="currentColor"
      />
    </svg>
  );
}

IconWidgetList.propTypes = {
  className: PropTypes.string
};

export default IconWidgetList;
