/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { useEffect, useState } from 'react';
import TransitionModal from 'components/basic/transition-modal';
import MascotDeleteAccount from 'components/svg/mascot/mascot-warning';

function DeleteAccountConfirmation({
  isOpen,
  onClose = () => {},
  isSubmitting,
  isFinished
}) {
  const [open, setOpen] = useState(isOpen);

  const toggleScroll = (isOpen) => {
    const el = document.getElementsByTagName('body');
    if (isOpen) {
      el[0].classList.add('overflow-hidden');
    } else {
      el[0].classList.remove('overflow-hidden');
    }
  };

  const handleCloseConfirmation = () => {
    if (isSubmitting) return false;
    setOpen(false);
    onClose();
  };

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
    toggleScroll(isOpen);
  }, [isOpen]);

  return (
    <div
      className={`fixed w-full h-full bg-black-base-d bg-opacity-80 left-0 top-0 z-50 ${
        open ? 'block' : 'hidden'
      }`}>
      <div
        className="fixed w-full h-full left-0 top-0"
        onClick={handleCloseConfirmation}></div>
      <TransitionModal isOpen={open} size="mx-auto max-w-md my-5">
        <div
          className="bg-white dark:bg-black-dark-d rounded-lg dark:border dark:border-gray-base-d dark:text-black-light-1-d p-6 z-10 relative mt-20 transform"
          style={{ transform: 'translateY(-3px)' }}>
          <MascotDeleteAccount />
          <h5 className="text-base mt-3">Konfirmasi Penghapusan Akun</h5>
          <p>
            Setelah kamu menyetujui, hal ini tidak dapat dibatalkan dan kamu
            perlu melakukan registrasi ulang. Apakah kamu yakin untuk
            melanjutkan?
          </p>

          <div className="pt-3 mt-1">
            <RenderIf isTrue={isFinished}>
              <button
                data-qa-id="btnStockBuyFinishConfirmation"
                type="button"
                onClick={handleCloseConfirmation}
                className="bg-main-base-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75">
                Selesai
              </button>
            </RenderIf>

            <RenderIf isTrue={!isFinished}>
              <button
                data-qa-id="btnDeleteAccountCancelConfirmation"
                type="button"
                onClick={handleCloseConfirmation}
                disabled={isSubmitting}
                className="bg-main-base-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75 mb-3">
                {isSubmitting && <Spinner />}
                Tidak, batalkan
              </button>

              <button
                data-qa-id="btnDeleteAccountPreSubmitConfirmation"
                type="submit"
                disabled={isSubmitting}
                className="bg-white border-2 border-main-base-l py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:border-opacity-75">
                {isSubmitting && <Spinner />}
                Ya, lanjutkan
              </button>
            </RenderIf>
          </div>
        </div>
      </TransitionModal>
    </div>
  );
}

DeleteAccountConfirmation.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isFinished: PropTypes.bool,
  onClose: PropTypes.func
};

export default DeleteAccountConfirmation;
