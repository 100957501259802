import PropTypes from 'prop-types';
import Card from "components/basic/card";
import IconLock from "components/svg/icon-lock";
import { PinSchema } from "./pin.validation";
import { useFormik } from "formik";
import InputPin from "components/input/input-pin";
import InputNumpad from "components/input/input-numpad";
import RenderIf from "components/basic/render-if";
import SimpleSpinner from "components/common/spinner/spinner";
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

function PinForm({
  isSubmitting,
  isCardless = false,
  showNumpad = true,
  id ="pin-form",
  onSubmit = () => {},
  header = (
    <h5 className="my-4 text-2xl text-main-base-l font-quicksand-semibold text-center">
      Masukkan PIN
    </h5>
  )
}) {

 
  if (isCardless) return <InputPinForm onSubmit={onSubmit} id={id} showNumpad={showNumpad} />

  return (
    <Card className="lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto py-4 relative max-h-[calc(90vh)] overflow-auto">
      <div className="px-10">
        {header}

        <div className="flex justify-center my-6">
          <RenderIf isTrue={isSubmitting}>
            <SimpleSpinner className="text-main-base-l h-8 w-8"/>
          </RenderIf>

          <RenderIf isTrue={!isSubmitting}>
            <IconLock className="text-main-base-l h-8 w-8" />
          </RenderIf>
        </div>

        <InputPinForm onSubmit={onSubmit} id={id} showNumpad={showNumpad} />
      </div>
    </Card>
  );
}


PinForm.propTypes = {
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  header: PropTypes.node,
  isCardless: PropTypes.bool,
  showNumpad: PropTypes.bool,
  id: PropTypes.string,
};

export default PinForm;


const InputPinForm = ({ onSubmit, id, showNumpad = true }) => {
  const [firstRender, setFirstRender] = useState(true);
  const formik = useFormik({
    initialValues: {
      digitOne: '',
      digitTwo: '',
      digitThree: '',
      digitFour: ''
    },
    validateOnMount: true,
    validationSchema: PinSchema,
    onSubmit: (values) => {
      handleSubmit(`${values.digitOne}${values.digitTwo}${values.digitThree}${values.digitFour}`);
    }
  });

  const handleSubmit = (_pin) => {
    onSubmit(_pin, () => {
      setTimeout(() => {
        formik.setValues({digitOne: '', digitTwo: '', digitThree: '', digitFour: ''});
        let nextEl = document.getElementById('digitOne' + id);
        nextEl.focus();
      }, 300);
    });
  }

  const handleChange = (e, index) => {
    let isBackSpace = false;
    if (e.target.value === '') {
      index = index - 1;
      isBackSpace = true;
    }

    let nextEl = null;
    if (isBackSpace) {
      if (index === 0) {
        nextEl = document.getElementById('digitOne' + id);
      } else if (index === 1) {
        nextEl = document.getElementById('digitTwo' + id);
      } else if (index === 2) {
        nextEl = document.getElementById('digitThree' + id);
      }
    } else {
      if (index === 0) {
        nextEl = document.getElementById('digitTwo' + id);
      } else if (index === 1) {
        nextEl = document.getElementById('digitThree' + id);
      } else if (index === 2) {
        nextEl = document.getElementById('digitFour' + id);
      }
    }

    if (nextEl) {
      nextEl.focus();
    }

    if (index === 3) {
      handleSubmit(`${formik.values.digitOne}${formik.values.digitTwo}${formik.values.digitThree}${e.target.value}`)
    }

    formik.handleChange(e);
  };

  const handleKeyDown = (e, index) => {
    let nextEl = null;

    if (e.key === 'Enter' && formik.isValid) {
      return handleSubmit(`${formik.values.digitOne}${formik.values.digitTwo}${formik.values.digitThree}${formik.values.digitFour}`);
    }

    if (e.key === 'Backspace' && e.target.value === '') {
      if (index === 1) {
        nextEl = document.getElementById('digitOne' + id);
      } else if (index === 2) {
        nextEl = document.getElementById('digitTwo' + id);
      } else if (index === 3) {
        nextEl = document.getElementById('digitThree' + id);
      }
    }

    if (nextEl) {
      nextEl.focus();
    }

    if (e.target.value.length > 0 && e.key != 'Backspace') {
      if (index === 0) {
        nextEl = document.getElementById('digitTwo' + id);
      } else if (index === 1) {
        nextEl = document.getElementById('digitThree' + id);
      } else if (index === 2) {
        nextEl = document.getElementById('digitFour' + id);
      }

      if (nextEl) {
        nextEl.focus();
      }

      e.preventDefault();
    }

    const patt = /^\d+\.?\d*$/;
    if (!patt.test(e.key) && e.key != 'Backspace') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (firstRender) {
      let nextEl = document.getElementById('digitOne' + id);
      nextEl.focus();
      setFirstRender(false);
    }
  }, [firstRender, id])

  return (
    <form className="mt-5 w-full" onSubmit={formik.handleSubmit}>
      <div className="max-w-[320px] mx-auto">
        <div className="grid grid-cols-4 mb-5">
          <InputPin
            data-qa-id="txtPinField"
            id={`digitOne${id}`}
            name="digitOne"
            className="text-xl text-center"
            value={formik.values.digitOne}
            onBlur={formik.handleBlur}
            onKeyDown={(e) => handleKeyDown(e, 0)}
            onChange={(e) => handleChange(e, 0)}
            autoComplete={false}
            pattern="[0-9]*"
            inputMode={isMobile ? 'none' : 'numeric'}
          />
          <InputPin
            data-qa-id="txtPinField"
            id={`digitTwo${id}`}
            name="digitTwo"
            className="text-xl text-center"
            value={formik.values.digitTwo}
            onBlur={formik.handleBlur}
            onKeyDown={(e) => handleKeyDown(e, 1)}
            autoComplete={false}
            pattern="[0-9]*"
            inputMode={isMobile ? 'none' : 'numeric'}
            onChange={(e) => handleChange(e, 1)}
          />
          <InputPin
            data-qa-id="txtPinField"
            id={`digitThree${id}`}
            name="digitThree"
            className="text-xl text-center"
            value={formik.values.digitThree}
            onBlur={formik.handleBlur}
            onKeyDown={(e) => handleKeyDown(e, 2)}
            autoComplete={false}
            pattern="[0-9]*"
            inputMode={isMobile ? 'none' : 'numeric'}
            onChange={(e) => handleChange(e, 2)}
          />
          <InputPin
            data-qa-id="txtPinField"
            id={`digitFour${id}`}
            name="digitFour"
            className="text-xl text-center"
            value={formik.values.digitFour}
            onBlur={formik.handleBlur}
            onKeyDown={(e) => handleKeyDown(e, 3)}
            autoComplete={false}
            pattern="[0-9]*"
            inputMode={isMobile ? 'none' : 'numeric'}
            onChange={(e) => handleChange(e, 3)}
          />
        </div>

        {
          showNumpad &&
          <InputNumpad
            onPress={(number) => {
              if (number === 'backspace') {
                if (formik.values.digitFour) {
                  formik.setFieldValue('digitFour', '');
                } else if (formik.values.digitThree) {
                  formik.setFieldValue('digitThree', '');
                } else if (formik.values.digitTwo) {
                  formik.setFieldValue('digitTwo', '');
                } else if (formik.values.digitOne) {
                  formik.setFieldValue('digitOne', '');
                }
              } else {
                if (!formik.values.digitOne) {
                  formik.setFieldValue('digitOne', number);
                } else if (!formik.values.digitTwo) {
                  formik.setFieldValue('digitTwo', number);
                } else if (!formik.values.digitThree) {
                  formik.setFieldValue('digitThree', number);
                } else if (!formik.values.digitFour) {
                  formik.setFieldValue('digitFour', number);
                  handleSubmit(`${formik?.values.digitOne}${formik?.values.digitTwo}${formik?.values.digitThree}${number}`);
                }
              }
            }}
          />
        }

      </div>
    </form>
  )
}

InputPinForm.propTypes = {
  onSubmit: PropTypes.func,
  showNumpad: PropTypes.bool,
  id: PropTypes.string,
};
