export const ListParameterScreener = [
  {
    name: 'Basic Price Ratio',
    children: [
      {
        name: 'Market Cap',
        children: [],
        fieldName: 'market_cap'
      },
      {
        name: 'Income Statement',
        children: [
          {
            name: 'Revenue',
            children: [],
            fieldName: 'total_sales'
          },
          {
            name: 'Gross Profit',
            children: [],
            fieldName: 'gross_profit'
          },
          {
            name: 'EBITDA',
            children: [],
            fieldName: 'ebitda',
          },
          {
            name: 'Net Income',
            children: [],
            fieldName: 'net_income'
          },
          {
            name: 'EPS',
            children: [],
            fieldName: 'eps'
          }
        ]
      },
      {
        name: 'Balance Sheet',
        fieldName: '',
        children: [
          {
            name: 'Cash Ratio',
            children: [],
            fieldName: 'cash_ratio'
          },
          {
            name: 'Total Asset',
            children: [],
            fieldName: 'total_assets'
          },
          {
            name: 'Total Liabilities',
            children: [],
            fieldName: 'total_liabilities'
          },
          {
            name: 'Total Equity',
            children: [],
            fieldName: 'total_equity'
          }
        ]
      },
      {
        name: 'Cash Flow',
        fieldName: '',
        children: [
          {
            name: 'Cash From Financing',
            children: [],
            fieldName: 'cash_from_financing_act'
          },
          {
            name: 'Cash From Investing',
            children: [],
            fieldName: 'cash_from_investing_act'
          },
          {
            name: 'Cash From Operating',
            children: [],
            fieldName: 'cash_from_operating_act'
          }
        ]
      },
      {
        name: 'Growth',
        fieldName: '',
        children: [
          {
            name: 'Revenue (Annual YoY)',
            children: [],
            fieldName: 'sales_growth'
          },
          {
            name: 'Net Income (Annual YoY)',
            children: [],
            fieldName: 'net_income_growth'
          },
          {
            name: 'EPS (Annual YoY)',
            children: [],
            fieldName: 'current_eps'
          }
        ]
      },
      {
        name: 'Price Performance',
        fieldName: '',
        children: [
          {
            name: '1W Price Return',
            children: [],
            fieldName: 'one_w_price_return'
          },
          {
            name: '1M Price Return',
            children: [],
            fieldName: 'one_m_price'
          },
          {
            name: '3M Price Return',
            children: [],
            fieldName: 'three_m_price_return'
          },
          {
            name: '6M Price Return',
            children: [],
            fieldName: 'six_m_price_return'
          },
          {
            name: '1Y Price Return',
            children: [],
            fieldName: 'one_y_price'
          },
          {
            name: '3Y Price Return',
            children: [],
            fieldName: 'three_y_price'
          },
          {
            name: '5Y Price Return',
            children: [],
            fieldName: 'five_y_price'
          },
          {
            name: '10Y Price Return',
            children: [],
            fieldName: 'ten_y_price'
          },
          {
            name: '52W High',
            children: [],
            fieldName: 'fifty_two_high'
          },
          {
            name: '52W Low',
            children: [],
            fieldName: 'fifty_two_low'
          }
        ]
      }
    ]
  },
  {
    name: 'Fundamental',
    children: [
      {
        name: 'PER',
        children: [],
        fieldName: 'per'
      },
      {
        name: 'PBV',
        children: [],
        fieldName: 'pbv'
      },
      {
        name: 'RoA',
        children: [],
        fieldName: 'roa'
      },
      {
        name: 'RoE',
        children: [],
        fieldName: 'roe_equity_to_parent_entity'
      },
      {
        name: 'DER',
        children: [],
        fieldName: 'der_equity_to_parent_entity'
      },
      {
        name: 'Dividend Yield',
        children: [],
        fieldName: 'dividen_yield'
      },
      {
        name: 'Book Value/Share',
        children: [],
        fieldName: 'book_value_per_share'
      },
      {
        name: 'Dividend Payout Ratio',
        children: [],
        fieldName: 'payout_ratio'
      },
      {
        name: 'Dividend Per Share',
        children: [],
        fieldName: 'dps'
      },
      {
        name: 'Gross Profit Margin',
        children: [],
        fieldName: 'gross_profit_margin'
      },
      {
        name: 'Net Profit Margin',
        children: [],
        fieldName: 'net_profit_margin'
      },
      {
        name: 'Operating Profit Margin',
        children: [],
        fieldName: 'operating_profit_margin'
      },
      {
        name: 'Price Cashflow Ratio',
        children: [],
        fieldName: 'price_cash_flow_ratio'
      },
      {
        name: 'Price Sales Ratio',
        children: [],
        fieldName: 'price_sales_ratio'
      },
      {
        name: 'Revenue Per Share',
        children: [],
        fieldName: 'revenue_per_share'
      }
    ]
  },
  {
    name: 'Technical',
    children: [
      {
        name: 'RSI',
        children: [],
        fieldName: 'rsi'
      },
      {
        name: 'MACD',
        children: [],
        fieldName: 'macd'
      },
      {
        name: 'EMA-5',
        children: [],
        fieldName: 'ema_5'
      },
      {
        name: 'EMA-10',
        children: [],
        fieldName: 'ema_10'
      },
      {
        name: 'EMA-20',
        children: [],
        fieldName: 'ema_20'
      },
      {
        name: 'EMA-50',
        children: [],
        fieldName: 'ema_50'
      },
      {
        name: 'EMA-100',
        children: [],
        fieldName: 'ema_100'
      },
      {
        name: 'EMA-200',
        children: [],
        fieldName: 'ema_200'
      },
      {
        name: 'SMA-5',
        children: [],
        fieldName: 'sma_5'
      },
      {
        name: 'SMA-10',
        children: [],
        fieldName: 'sma_10'
      },
      {
        name: 'SMA-20',
        children: [],
        fieldName: 'sma_20'
      },
      {
        name: 'SMA-50',
        children: [],
        fieldName: 'sma_50'
      },
      {
        name: 'SMA-100',
        children: [],
        fieldName: 'sma_100'
      },
      {
        name: 'SMA-200',
        children: [],
        fieldName: 'sma_200'
      }
    ]
  },
  {
    name: 'Bandarmology',
    children: [
      {
        name: 'Foreign Flow',
        children: [],
        fieldName: 'foreign_flow'
      },
      {
        name: 'Foreign Flow MA 20',
        children: [],
        fieldName: 'foreign_flow_20'
      },
      {
        name: 'Foreign Flow MA 50',
        children: [],
        fieldName: 'foreign_flow_50'
      },
      {
        name: 'Net Foreign Buy / Sell',
        children: [],
        fieldName: 'net_foreign_buy_sell'
      },
      {
        name: 'Net Foreign Buy / Sell MA 10',
        children: [],
        fieldName: 'net_foreign_buy_sell_10'
      },
      {
        name: 'Net Foreign Buy / Sell MA 20',
        children: [],
        fieldName: 'net_foreign_buy_sell_20'
      },
      {
        name: 'EV to EBITDA',
        children: [],
        fieldName: 'ev_ebitda'
      }
    ]
  }
];
