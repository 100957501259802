import { useBrowserTabId } from "hooks/useBrowserTabId";
import useCheckConnection from "hooks/useCheckConnection";
import useToastr from "hooks/useToastr";
import { useEffect } from "react";

export default function BrowserIdentifier() {
  const browserTabID = useBrowserTabId();
  const isOnline = useCheckConnection();
  const toastr = useToastr();

  console.log('browserTabID', browserTabID);

  useEffect(() => {
    if (!isOnline)
      toastr.error('You are offline! Please check your internet connection!');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  return <></>;
}