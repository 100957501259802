import 'react-toastify/dist/ReactToastify.min.css'
import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import getLayout from 'components/layout'
import LoadingPage from 'components/loading/loading-page'
import routes from 'routes'
import AuthGuard from 'routes/guard/auth'
import GuestGuard from 'routes/guard/guest'
import Page404 from 'pages/404'
import OnboardingGuard from 'routes/guard/onboarding'
import { triggerHotjarInit } from 'lib/hotjar'
import { checkBrowserSupport } from 'utils/check-browser-support/check-user-agent'
import CheckUserProvider from 'components/template/check-user-provider'
import SessionTrading from 'lib/session'
import StockProvider from 'lib/stock'
import BrowserIdentifier from 'lib/browser'

const FirebaseMessaging = React.lazy(() => import('./lib/firebase/firebase-messaging'))

const App = () => {
  useEffect(() => {
    triggerHotjarInit();
  }, [])

  return (
    <React.Suspense fallback={<LoadingPage />}>
      {checkBrowserSupport() && <FirebaseMessaging />}

      <StockProvider />
      <SessionTrading />
      <BrowserIdentifier />

      <Routes>
        {
          routes.map((route, idx) => {
            const Layout = getLayout(route.layout);
            const Element = route.element;
            const meta = route?.meta || {};

            if (route.auth && route?.onboarding) {
              return (
                <Route
                  key={idx}
                  index={route.index}
                  path={route.index ? undefined : route.path}
                  element={
                    <OnboardingGuard>
                      <CheckUserProvider />
                      <Layout headerProps={meta.layout?.header}>
                        <Element />
                      </Layout>
                    </OnboardingGuard>
                  }
                />
              )
            }

            if (route.auth && !route?.onboarding) {
              return (
                <Route
                  key={idx}
                  index={route.index}
                  path={route.index ? undefined : route.path}
                  element={
                    <AuthGuard>
                      <CheckUserProvider />
                      <Layout headerProps={meta.layout?.header}>
                        <Element />
                      </Layout>
                    </AuthGuard>
                  }
                />
              )
            }

            return (
              <Route
                key={idx}
                index={route.index}
                path={route.index ? undefined : route.path}
                element={
                  <GuestGuard>
                    <Layout>
                      <Element />
                    </Layout>
                  </GuestGuard>
                }
              />
            )
          })
        }

        <Route path="*" element={<Page404 />} />
      </Routes>

      <ToastContainer
        containerId="general-toast-container"
        theme="pina"
        autoClose={5000}
        limit={5}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={false}
        closeOnClick={true}
        rtl={false}
        draggable={false}
        pauseOnHover
        pauseOnFocusLoss
      />
    </React.Suspense>
  )
}

export default App
