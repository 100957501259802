import PropTypes from 'prop-types';
import InputCounter from 'components/input/input-counter';
import { useFormik } from 'formik';
import { useState } from 'react';
import { currency, generateOrderId } from 'utils/format';
import StockAmendConfirmation from './modal-confirmation';
import { StockAmendSchema } from './stock-amend.form.validation';
import RenderIf from 'components/basic/render-if';
import { find, isEmpty, uniqueId } from 'lodash';
import { marginPlusShare } from 'utils/formula/margin';
import { useCreateOrderAmendMutation, useGetPortfolioListQuery } from 'services/rtk-query/trade';
import IconWarning from 'components/svg/icon-warning';
import { useDispatch, useSelector } from 'react-redux';
import { invalidateUserNotification } from 'services/rtk-query/user';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useToastr from 'hooks/useToastr';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputCheckBox from 'components/input/input-check-box';
import { setQuickOrderSettingsConfirmation } from 'store/settings';
import IconSort from 'components/svg/icon-sort';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function StockAmendForm({
  buyingPower,
  title = true,
  orderDetail,
  user,
  onClickBackButton = () => {},
  onSuccess = () => {}
}) {
  const toastr = useToastr();
  const { dealerUseClientId } = useBrowserTabAuth();
  const quickOrderConfirmation = useSelector((state) => state?.settings?.quickOrderSettings?.confirmation);
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();
  const { data: portFolio } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const portfolio = find(portFolio?.stock?.items, { stID: orderDetail?.stID });

  const [createOrderAmend] = useCreateOrderAmendMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isOpenModalConfirmation, setOpenModalConfirmation] = useState(false);

  const formik = useFormik({
    initialValues: {
      lotType: true,
      amount: orderDetail?.qty * orderDetail?.price || 0,
      buyingPower: buyingPower + (orderDetail?.qty * orderDetail?.price || 0),
      orderNumber: generateOrderId(dealerUseClientId || user?.username, 'A'),
      code: orderDetail?.stID,
      lot: orderDetail?.qty / 100 || 0,
      price: orderDetail?.price,
      side: orderDetail?.side,
      initialPrice: orderDetail?.price || 0
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: StockAmendSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      let payloads = {
        orderID: orderDetail?.id,
        clOrdID: values?.orderNumber,
        price: values?.price,
        qty: values?.lot
      };

      const { success, msg, err } = await createOrderAmend(payloads).unwrap();

      if (!success) {
        let error = new Error();
        Object.assign(error, { msg, code: err });
        throw error;
      }

      formik.resetForm();
      toastr.success('Order telah terkirim');
      setOpenModalConfirmation(false);
      onSuccess()
      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (val, obj) => {
    formik.setFieldValue(obj, val);
  };

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      if (!quickOrderConfirmation) {
        setIsFinished(false);
        setIsSubmitting(false);
        setOpenModalConfirmation(true);
      } else {
        return formik.submitForm();
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      onKeyDown={(e) => e.key === 'Enter' && handleConfirmation()}
      noValidate>
      {title && <h5 className="mb-2">Amend</h5>}

      <RenderIf isTrue={orderDetail?.side === 'B'}>
        <div className='flex justify-between'>
          <span className="text-xs text-gray-light-0-d block font-quicksand-semibold">
            Limit
          </span>
          <h5 className='text-sm'>{currency(formik.values.buyingPower, { removeSymbol: true })}</h5>
        </div>
      </RenderIf>

      <RenderIf isTrue={orderDetail?.side === 'S'}>
        <div className='flex justify-between'>
          <span className="text-xs text-gray-light-0-d block font-quicksand-semibold">
            Lot tersedia
          </span>
          <h5 className='text-sm'>{currency(portfolio?.balance / 100, { removeSymbol: true })}</h5>
        </div>
      </RenderIf>

      <InputCounter
        isAutoFocus
        size='small'
        data-qa-id="txtStockBuyPriceField"
        value={formik.values.price}
        name="price"
        className='my-3'
        label={<span className='text-2xs text-gray-light-0-d'>Harga Per Saham</span>}
        counterStep={marginPlusShare(formik.values.price)}
        onChange={(val) => {
          handleChange(val, 'price');
          setTimeout(() => formik.setTouched({ price: true }), 100);
        }}
        error={formik.errors.price ? formik.errors.price : ''}
        showError={false}
      />

      <InputCounter
        size='small'
        data-qa-id="txtStockBuyLotField"
        value={formik.values.lotType ? formik.values.lot : formik.values.amount}
        value2={formik.values.lotType ? formik.values.amount : formik.values.lot}
        name="lot"
        className='my-3'
        counterStep={formik.values.lotType ? 1 : formik.values.price * 100}
        max={orderDetail?.side === 'S' ? formik.values.lotType ? portfolio?.balance / 100 : portfolio?.balance * formik.values.price : 9999999999}
        maxValue={orderDetail?.side === 'S' ? portfolio?.balance / 100 : 9999999999}
        label={
          <span className='text-2xs text-gray-light-0-d'>
            <button tabIndex={-1} onClick={() => formik.setFieldValue('lotType', !formik.values.lotType)} type='button' className='flex items-center hover:text-main-base-l'>
              <IconSort className='h-4 w-4 mr-1 transform rotate-90 text-main-base-l' /> {formik.values.lotType ? 'Jumlah Lot' : 'Amount'}
            </button>
          </span>}
        label2={<span className='text-2xs text-gray-light-0-d'>{formik.values.lotType ? 'Amount' : 'Lot'}</span>}
        showShortcut={formik.values.lotType}
        showValue2
        onChange={(val) => {
          if (formik.values.lotType) {
            handleChange(val, 'lot');
            handleChange(Number(val) * formik.values.price * 100 , 'amount');
          } else {
            handleChange(val, 'amount');
            handleChange(Math.floor(Number(val) / (formik.values.price * 100)) , 'lot');
          }
          setTimeout(() => formik.setTouched({ lot: true, amount: true }), 100);
        }}
        error={formik.errors.lot ? formik.errors.lot : ''}
        showError={false}
      />

      <div className="flex border-y py-2 items-center dark:border-gray-base-d">
        <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
          Total Pembelian
        </span>
        <h5 className="flex-grow text-right text-sm">
          {currency(
            parseInt(formik.values.lot) * parseInt(formik.values.price) * 100
          )}
        </h5>
      </div>

      <RenderIf isTrue={!formik?.isValid}>
        <div className="font-quicksand-semibold py-3 px-4 flex items-center text-red-base-l text-xs rounded-xl my-3 bg-red-base-l bg-opacity-10">
          <IconWarning className="h-5 w-5 mr-1 flex-none" />
          {Object.keys(formik?.errors)?.map((key, idx) => (
            <RenderIf key={key} isTrue={idx === 0}>
              <span
                key={key}
                className="flex items-center w-full flex-auto">
                {idx === 0 && formik?.errors[key]}
              </span>
            </RenderIf>
          ))}
        </div>
      </RenderIf>

      <div className="pt-3 mt-1 relative grid grid-cols-12 gap-3">
        <button
          data-qa-id="btnStockBuySubmit"
          onClick={() => onClickBackButton()}
          type="button"
          className={`col-span-4 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75`}>
          Batal
        </button>

        <button
          data-qa-id="btnStockBuySubmit"
          disabled={!formik.isValid || isSubmitting}
          onClick={handleConfirmation}
          type="button"
          className="col-span-8 bg-main-gold-d disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex items-center justify-center transition-all ease-in-out duration-100">
          {isSubmitting && <SimpleSpinner />}
          Amend
        </button>
      </div>

      <div className='mt-4'>
        <InputCheckBox
          id={`${uniqueId('confirmation-')}`}
          value={quickOrderConfirmation}
          name="confirmation"
          checked={quickOrderConfirmation}
          label={<span className='text-xs font-quicksand-regular text-black-base-l dark:text-black-light-0-d transform'>Confirm Order</span>}
          onChange={() => {
            dispatch(setQuickOrderSettingsConfirmation(!quickOrderConfirmation))
          }}
          onBlur={formik.handleBlur}
          className='mb-0'
        />
      </div>

      <RenderIf isTrue={isOpenModalConfirmation}>
        <Modal 
          onClose={() => {
            if (isFinished) onSuccess();
            setOpenModalConfirmation(false)
          }}
        >
          <StockAmendConfirmation
            isOpen={isOpenModalConfirmation}
            isSubmitting={isSubmitting}
            isFinished={isFinished}
            onClose={() => {
              if (isFinished) onSuccess();
              setOpenModalConfirmation(false)
            }}
            data={formik?.values}
            onSubmit={() => formik.submitForm()}
          />
        </Modal>
      </RenderIf>
    </form>
  );
}

StockAmendForm.propTypes = {
  user: PropTypes.object,
  orderDetail: PropTypes.object,
  buyingPower: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClickBackButton: PropTypes.func,
  onSuccess: PropTypes.func
};

export default StockAmendForm;
