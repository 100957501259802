import {
  CloseSession,
  FridaySessions,
  IepIevSessions,
  NormalSessions
} from 'constants/bursa-session';
import { useEffect, useState } from 'react';
import { setBursaOpen, setShowIepIev } from 'store/pina-pro';
import { dayjsUTCLocal } from 'utils/formatter/date';

const { useDispatch } = require('react-redux');

function MarketStatus() {
  const dispatch = useDispatch();
  const [time, setTime] = useState(dayjsUTCLocal().format('HH:mm:ss'));

  const sessions =
    dayjsUTCLocal().day() === 5
      ? FridaySessions
      : ![0, 6].includes(dayjsUTCLocal().day())
      ? NormalSessions
      : [];

  const activeBursaSession =
    sessions?.find((session) => {
      const timeMs = dayjsUTCLocal(time, 'HH:mm:ss')?.valueOf();
      const startTimeMs = dayjsUTCLocal(
        session?.startTime,
        'HH:mm:ss'
      ).valueOf();
      const endTimeMs = dayjsUTCLocal(session?.endTime, 'HH:mm:ss').valueOf();

      return timeMs >= startTimeMs && timeMs <= endTimeMs;
    }) || CloseSession;

  const activeIepIev = IepIevSessions?.find((session) => {
    const timeMs = dayjsUTCLocal(time, 'HH:mm:ss')?.valueOf();
    const startTimeMs = dayjsUTCLocal(session?.startTime, 'HH:mm:ss').valueOf();
    const endTimeMs = dayjsUTCLocal(session?.endTime, 'HH:mm:ss').valueOf();

    return timeMs >= startTimeMs && timeMs <= endTimeMs;
  });

  useEffect(() => {
    const intervalId = setInterval(
      () => setTime(dayjsUTCLocal().format('HH:mm:ss')),
      1000
    );
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    dispatch(setBursaOpen(activeBursaSession?.status === 'played'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBursaSession]);

  useEffect(() => {
    dispatch(setShowIepIev(!!activeIepIev));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIepIev]);

  return (
    <div className="flex items-center mr-4 px-4 dark:text-black-light-1-d gap-2 zoom-2">
      <p className="font-quicksand-semibold text-2xs text-[10px] flex items-center">
        {' '}
        {time} WIB
      </p>

      <h5
        className="font-quicksand-regular text-2xs text-[10px] items-center hidden lg:flex"
        style={{ color: activeBursaSession?.color }}>
        <span
          style={{ background: activeBursaSession?.color }}
          className={`${
            activeBursaSession?.status === 'stopped'
              ? 'rounded-sm h-1'
              : 'rounded-full h-2 animate-pulse'
          } w-2 mr-1`}></span>
        <span className="line-clamp-1">{activeBursaSession?.name}</span>
      </h5>
    </div>
  );
}

export default MarketStatus;
