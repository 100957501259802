export const checkFundamentalSuffix = (name) => {
  if (
    [
      'Revenue',
      'Gross Profit',
      'EBITDA',
      'Net Income',
      'Total Asset',
      'Total Liabilities',
      'Total Equity',
      'Market Cap',
      'Net Income',
      'Cash From Financing',
      'Cash From Investing',
      'Cash From Operating',
    ].includes(name)
  ) {
    return 'B';
  }

  if (
    [
      '1W Price Return',
      '1M Price Return',
      '3M Price Return',
      '6M Price Return',
      '1Y Price Return',
      '3Y Price Return',
      '5Y Price Return',
      '10Y Price Return',
      'Cash Ratio',
      'Dividend Yield',
      'Revenue (Annual YoY)',
      'Net Income (Annual YoY)',
      'RoE',
      'RoA',
      'DER',
      'Gross Profit Margin',
      'Dividend Payout Ratio',
      'Operating Profit Margin',
      'Net Profit Margin'
    ].includes(name)
  ) {
    return '%';
  }

  if (
    ['PBV', 'PER', 'Price Cashflow Ratio', 'Price Sales Ratio'].includes(name)
  ) {
    return 'x';
  }

  if (
    [
      '52W High',
      '52W Low',
      'EMA-5',
      'EMA-10',
      'EMA-20',
      'EMA-50',
      'EMA-100',
      'EMA-200',
      'SMA-5',
      'SMA-10',
      'SMA-20',
      'SMA-50',
      'SMA-100',
      'SMA-200'
    ].includes(name)
  ) {
    return 'IDR';
  }

  return false;
};

export const checkMultiplier = (name) => {
  if (
    [
      'Cash Ratio',
      'Revenue (Annual YoY)',
      'Net Income (Annual YoY)',
      'RoE',
      'RoA',
      'DER',
      'Gross Profit Margin',
      'Operating Profit Margin',
      'Net Profit Margin'
    ].includes(name)
  ) {
    return 100;
  }

  return 1;
};
