import PropTypes from 'prop-types';

function IconBellDelete({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20H14C14 21.1046 13.1046 22 12 22C10.8954 22 10 21.1046 10 20Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8763 6.87622C6.32039 7.78611 6.00004 8.85562 6.00004 9.99994V11.9573C6.00004 12.388 5.84413 12.8042 5.56112 13.1288L4.91668 13.8682C2.82986 16.2622 4.53019 19.9999 7.70609 19.9999H16.294C17.3663 19.9999 18.2704 19.5738 18.9173 18.9172L17.501 17.5009C17.2036 17.8035 16.7876 17.9999 16.294 17.9999H7.70609C6.24672 17.9999 5.4654 16.2824 6.42432 15.1823L7.06876 14.443C7.66924 13.7541 8.00004 12.8711 8.00004 11.9573V9.99994C8.00004 9.41301 8.12646 8.8556 8.35354 8.35346L6.8763 6.87622Z"
      />
      <path d="M12 2C11.4477 2 11 2.44772 11 3V4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6H12C14.2091 6 16 7.79086 16 10V11C16 11.5523 16.4477 12 17 12C17.5523 12 18 11.5523 18 11V10C18 7.02584 15.836 4.5571 12.9967 4.0824C12.9989 4.05522 13 4.02774 13 4V3C13 2.44772 12.5523 2 12 2Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      />
    </svg>
  );
}

IconBellDelete.propTypes = {
  className: PropTypes.string
};

export default IconBellDelete;
