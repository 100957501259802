import PropTypes from 'prop-types';
import useOuterClick from 'hooks/useOuterClick';
import { useEffect, useRef, useState } from 'react';
import CardStockItem from 'components/cards/card-stock-item';
import { useSearchStockQuery } from 'services/rtk-query/stock';
import { filter } from 'lodash';
import RenderIf from 'components/basic/render-if';

import styles from "./css/input-stock-multiple.module.css";
import IconSearch from 'components/svg/icon-search';
import SimpleSpinner from 'components/common/spinner/spinner';
import IconClose from 'components/svg/icon-close';

function InputStockMultiple({
  value = [],
  onChange,
  onBlur = () => {},
  className,
  placeholder = 'Ketik simbol',
  disabled,
  defaultStocks = [],
  label = 'Stock',
  iconRight = false,
  maxHeight = '300px',
  color = '',
  onLoading = () => {}
}) {
  const refInput = useRef(null);  
  const [showListStock, setShowListStock] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [currentStocks, setCurrentStocks] = useState(value);
  const [isFocus, setIsFocus] = useState(false);
  const [widthInput, setWidthInput] = useState('w-full');

  const { data: responseSearch, isFetching } = useSearchStockQuery({ q: keyword, size: 20, code_only: true, all_query: true }, { refetchOnMountOrArgChange : keyword, skip: keyword?.length < 2 });

  const innerRef = useOuterClick(() => {
    setTimeout(() => {
      setShowListStock(false);
      setIsFocus(false);
    }, 200);
  });

  const handleFocus = () => {
    if (disabled) return false
    setShowListStock(true);
    refInput?.current?.focus();
    refInput?.current?.setSelectionRange(0, 9999999);
    setIsFocus(true);
  }

  const handleRemoveFilterStock = (pickStock) => {
    let _currentStocks = currentStocks?.filter(item => item?.code != pickStock?.code);
    setCurrentStocks();
    onChange(_currentStocks);
    if (_currentStocks?.length === 0) {
      setKeyword('');
    }
  }

  const handleAddStock = (pickStock) => {
    let _currentStocks = currentStocks;
    if (!currentStocks?.find((item) => item?.code === pickStock?.code)) {
      _currentStocks = [..._currentStocks, pickStock]
      setCurrentStocks(_currentStocks);
    }

    setShowListStock(false);
    setKeyword("");
    handleFocus();
    onChange(_currentStocks);
  }

  useEffect(() => {
    if (defaultStocks?.length > 0) {
      if (keyword?.length > 2) {
        setStocks(filter(defaultStocks, (item) => item?.code?.toLowerCase().indexOf(keyword?.toLowerCase()) !== -1 || item?.name?.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ))
      } else {
        setStocks(defaultStocks);
      }
    }
  }, [defaultStocks, keyword]);

  useEffect(() => {
    if (value != currentStocks) setCurrentStocks(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setStocks(responseSearch || []);
  }, [responseSearch]);

  useEffect(() => {
    onLoading(isFetching);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    if (currentStocks?.length === 0) {
      setWidthInput('w-full')
    } else if (isFocus && currentStocks?.length > 0) {
      setWidthInput('w-[64px]')
    } else if (!isFocus && currentStocks?.length > 0) {
      setWidthInput('w-[16px]')
    }
  }, [isFocus, currentStocks]);

  return (
    <div ref={innerRef} className={`${styles['input-stock']} ${styles[color]} ${className}`}>
      {label && <label className={styles['input-stock--label']}>{label}</label>}

      <div className={`group wrapper ${styles['input-stock--wrapper']} dark:bg-transparent ${disabled  && styles['disabled']}`}>

        <RenderIf isTrue={iconRight}>{iconRight}</RenderIf>
        
        <RenderIf isTrue={!iconRight}>
          {
            !isFetching ? <IconSearch className={`right-2 absolute w-4 h-4 transform -translate-y-1/2 top-1/2 text-main-base-l`} /> : <SimpleSpinner className='right-2 absolute h-4 w-4 text-main-base-l top-2' />
          }
        </RenderIf>

        <div className='flex px-1 py-0 pt-[1px]'>
          <ul className="flex flex-row flex-wrap -mx-1 text-[8px] leading-4 font-quicksand-semibold mt-1 hover:cursor-text w-full" onClick={handleFocus}>
            {currentStocks?.map((item, key) => (
              <li key={key} className="mx-1 mb-1">
                <span className="p-2 py-[2px] border dark:border-black-light-1-l rounded-xl inline-flex text-main-base-l items-center h-full">
                  {item?.code}
                  <span
                    className="ml-1 h-full border-l dark:border-black-light-1-l pl-1 cursor-pointer hover:text-main-base-l flex items-center justify-center"
                    onClick={() => handleRemoveFilterStock(item)}
                    >
                    <IconClose className="h-3 w-3" />
                  </span>
                </span>
              </li>
            ))}

            <li>
              <input
                ref={refInput}
                value={keyword}
                placeholder={currentStocks?.length === 0 ? placeholder : ''}
                disabled={disabled}
                className={`relative appearance-none outline-none p-2 py-1 focus:ring-0 pb-2 font-quicksand-semibold bg-transparent text-xs dark:text-black-light-1-d ${widthInput}`}
                onFocus={handleFocus}
                onChange={(e) => {
                  setKeyword(e.target.value);
                  setShowListStock((e.target.value ? true : false) || defaultStocks?.length > 0);
                }}
                onKeyUp={(e) => {
                  setIsFocus(true);
                  if (e.key === 'Enter' && e.target?.value?.length > 2 && stocks?.length > 0) {
                    const find = stocks?.find((item) => item?.code?.toLowerCase().indexOf(keyword?.toLowerCase()) !== -1);
                    handleAddStock(find || stocks[0]);
                  }
                }}
                onBlur={onBlur}
              />
            </li>
          </ul>

        </div>

      </div>

      {
        showListStock && stocks?.length > 0 && 
        <div className='absolute top-full w-full left-0 pt-2 z-10 min-w-[250px] input-stock--list'>
          <div className="bg-white shadow-pro rounded-lg overflow-hidden">
            <ul
              className="overflow-auto"
              style={{ maxHeight: stocks?.length > 0 && showListStock ? maxHeight : '0px'}}>
              {stocks?.map((item, key) => (
                <li key={key}>
                  <CardStockItem
                    {...item}
                    value={Number(item?.value)}
                    onClick={() => handleAddStock(item)}
                    data-link-qa-id="linkSearchStock"
                    data-img-qa-id="imgSearchStock"
                    actionButton={false}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      }

    </div>
  );
}

InputStockMultiple.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onLoading: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showPriceStock: PropTypes.bool,
  defaultStocks: PropTypes.array,
  label: PropTypes.any,
  iconRight: PropTypes.any,
  maxHeight: PropTypes.string,
  color: PropTypes.string,
};

export default InputStockMultiple;
