import PropTypes from 'prop-types';
import InputDatePickerSimple from 'components/input/input-date-picker-simple';
import InputSelect from 'components/input/input-select';
import { subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { automatedNumberFormat } from 'utils/format';
import IconSearch from 'components/svg/icon-search';
import { LIST_BROKER } from 'constants/list-broker';
import { dayjsLocal } from 'utils/formatter/date';
import { useGetBrokerActivityQuery } from 'services/rtk-query/stock';
import { orderBy, range, sumBy } from 'lodash';
import SkeletonTheme from 'components/basic/skeleton-theme';
import RenderIf from 'components/basic/render-if';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { getYear } from 'date-fns';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import useCustomNavigate from 'hooks/useCustomNavigate';

function TWBrokerActivity({
  broker = {},
  symbol = '',
  onChangeBroker = () => {},
  maxHeight = '270px'
}) {
  const { customNavigate } = useCustomNavigate();
  const isBrowserWakeUp = useBrowserWakeUp();

  const [investor, setInvestor] = useState('All');
  const [showNet, setShowNet] = useState(true);
  const [datas, setDatas] = useState({
    buyer: [],
    seller: []
  });

  const [summary, setSummary] = useState({
    tVal: 0,
    tFVal: 0,
    tVol: 0,
    tAvg: 0
  });

  const [params, setParams] = useState({
    params: {
      startDate: dayjsLocal().day() === 6 ? dayjsLocal().subtract(2, 'day').format('MM/DD/YYYY') : dayjsLocal().day() === 0 || dayjsLocal().day() === 1 ? dayjsLocal().subtract(3, 'day').format('MM/DD/YYYY') : dayjsLocal().subtract(1, 'day').format('MM/DD/YYYY'),
      endDate: dayjsLocal().day() === 6 ? dayjsLocal().subtract(2, 'day').format('MM/DD/YYYY') : dayjsLocal().day() === 0 || dayjsLocal().day() === 1 ? dayjsLocal().subtract(3, 'day').format('MM/DD/YYYY') : dayjsLocal().subtract(1, 'day').format('MM/DD/YYYY')
    },
    broker
  });

  const { data, isFetching, refetch, isUninitialized } = useGetBrokerActivityQuery(
    { ...params, brokerSymbol: params?.broker?.code },
    {
      skip: !params?.broker?.code
    }
  );

  const colors = {
    Lokal: 'text-[#AB58FF]',
    Asing: 'text-[#FB9825]',
    Pemerintah: 'text-[#0C9955]'
  };

  const options = LIST_BROKER.map((item) => {
    return {
      ...item,
      customName: (
        <p className="flex items-center">
          <b className={`text-xs ${colors[item?.group]}`}>{item?.code}</b>
          &nbsp;-&nbsp;
          <span>{item?.name}</span>
        </p>
      )
    };
  });

  useEffect(() => {
    if (data?.length > 0) {
      let buyer = [];
      let seller = [];

      if (showNet) {
        if (investor === 'All') {
          buyer = data
            ?.map((item) => {
              return {
                stockID: item?.stockID,
                bVal: (item?.buyDomesticVal + item?.buyForeignVal) - (item?.sellDomesticVal + item?.sellForeignVal),
                bVol: ((item?.buyDomesticVol + item?.buyForeignVol) / 100) - ((item?.sellDomesticVol + item?.sellForeignVol) / 100),
                bAvg: (item?.buyDomesticVal || item?.buyForeignVal) / (item?.buyDomesticVol || item?.buyForeignVol)
              };
            })
            ?.filter((item) => item?.bVal > 0);
  
          seller = data
            ?.map((item) => {
              return {
                stockID: item?.stockID,
                sVal: (item?.sellDomesticVal + item?.sellForeignVal) - (item?.buyDomesticVal + item?.buyForeignVal),
                sVol: ((item?.sellDomesticVol + item?.sellForeignVol) / 100) - ((item?.buyDomesticVol + item?.buyForeignVol) / 100),
                sAvg: (item?.sellDomesticVal || item?.sellForeignVal) / (item?.sellDomesticVol || item?.sellForeignVol)
              };
            })
            ?.filter((item) => item?.sVal > 0);
        }
  
        if (investor === 'Domestic') {
          buyer = data
            ?.map((item) => {
              if (item?.buyDomesticVal > 0) {
                return {
                  stockID: item?.stockID,
                  bVal: item?.buyDomesticVal - item?.sellDomesticVal,
                  bVol: (item?.buyDomesticVol / 100) - (item?.sellDomesticVol / 100),
                  bAvg: item?.buyDomesticVal / item?.buyDomesticVol
                };
              }
            })
            ?.filter((item) => item?.bVal > 0);
  
          seller = data
            ?.map((item) => {
              if (item?.sellDomesticVal > 0) {
                return {
                  stockID: item?.stockID,
                  sVal: item?.sellDomesticVal - item?.buyDomesticVal,
                  sVol: (item?.sellDomesticVol / 100) - (item?.buyDomesticVol / 100),
                  sAvg: item?.sellDomesticVal / item?.sellDomesticVol
                };
              }
            })
            ?.filter((item) => item?.sVal > 0);
        }
  
        if (investor === 'Foreign') {
          buyer = data
            ?.map((item) => {
              if (item?.buyForeignVal > 0) {
                return {
                  stockID: item?.stockID,
                  bVal: item?.buyForeignVal - item?.sellForeignVal,
                  bVol: (item?.buyForeignVol / 100) - (item?.sellForeignVol / 100),
                  bAvg: item?.buyForeignVal / item?.buyForeignVol
                };
              }
            })
            ?.filter((item) => item?.bVal > 0);
  
          seller = data
            ?.map((item) => {
              if (item?.sellForeignVal > 0) {
                return {
                  stockID: item?.stockID,
                  sVal: item?.sellForeignVal - item?.buyForeignVal,
                  sVol: (item?.sellForeignVol / 100) - (item?.buyForeignVol / 100),
                  sAvg: item?.sellForeignVal / item?.sellForeignVol
                };
              }
            })
            ?.filter((item) => item?.sVal > 0);
        }
      } else {
        if (investor === 'All') {
          buyer = data
            ?.map((item) => {
              return {
                stockID: item?.stockID,
                bVal: item?.buyDomesticVal + item?.buyForeignVal,
                bVol: (item?.buyDomesticVol + item?.buyForeignVol) / 100,
                bAvg: (item?.buyDomesticVal || item?.buyForeignVal) / (item?.buyDomesticVol || item?.buyForeignVol)
              };
            })
            ?.filter(
              (item) => item?.bVal > 0 && (symbol === item?.stockID || !symbol)
            );
  
          seller = data
            ?.map((item) => {
              return {
                stockID: item?.stockID,
                sVal: item?.sellDomesticVal + item?.sellForeignVal,
                sVol: (item?.sellDomesticVol + item?.sellForeignVol) / 100,
                sAvg: (item?.sellDomesticVal || item?.sellForeignVal) / (item?.sellDomesticVol || item?.sellForeignVol)
              };
            })
            ?.filter(
              (item) => item?.sVal > 0 && (symbol === item?.stockID || !symbol)
            );
        }
  
        if (investor === 'Domestic') {
          buyer = data
            ?.map((item) => {
              if (item?.buyDomesticVal > 0) {
                return {
                  stockID: item?.stockID,
                  bVal: item?.buyDomesticVal,
                  bVol: item?.buyDomesticVol / 100,
                  bAvg: item?.buyDomesticVal / item?.buyDomesticVol
                };
              }
            })
            ?.filter(
              (item) => item?.bVal > 0 && (symbol === item?.stockID || !symbol)
            );
  
          seller = data
            ?.map((item) => {
              if (item?.sellDomesticVal > 0) {
                return {
                  stockID: item?.stockID,
                  sVal: item?.sellDomesticVal,
                  sVol: item?.sellDomesticVol / 100,
                  sAvg: item?.sellDomesticVal / item?.sellDomesticVol
                };
              }
            })
            ?.filter(
              (item) => item?.sVal > 0 && (symbol === item?.stockID || !symbol)
            );
        }
  
        if (investor === 'Foreign') {
          buyer = data
            ?.map((item) => {
              if (item?.buyForeignVal > 0) {
                return {
                  stockID: item?.stockID,
                  bVal: item?.buyForeignVal,
                  bVol: item?.buyForeignVol / 100,
                  bAvg: item?.buyForeignVal / item?.buyForeignVol
                };
              }
            })
            ?.filter(
              (item) => item?.bVal > 0 && (symbol === item?.stockID || !symbol)
            );
  
          seller = data
            ?.map((item) => {
              if (item?.sellForeignVal > 0) {
                return {
                  stockID: item?.stockID,
                  sVal: item?.sellForeignVal,
                  sVol: item?.sellForeignVol / 100,
                  sAvg: item?.sellForeignVal / item?.sellForeignVol
                };
              }
            })
            ?.filter(
              (item) => item?.sVal > 0 && (symbol === item?.stockID || !symbol)
            );
        }
      }

      setDatas({
        buyer: orderBy(buyer, ['bVal'], ['desc']),
        seller: orderBy(seller, ['sVal'], ['desc'])
      });

      setSummary({
        tVal: sumBy(buyer, 'bVal') + sumBy(seller, 'sVal'),
        tVol: sumBy(buyer, 'bVol') + sumBy(seller, 'sVol'),
        tFVal: sumBy(data, 'sellForeignVal') + sumBy(data, 'buyForeignVal'),
        tAvg: (sumBy(buyer, 'bAvg') + sumBy(seller, 'sAvg')) / (buyer?.length + seller?.length)
      });
    }
  }, [data, investor, symbol, showNet]);

  useEffect(() => {
    setParams({
      params: {
        startDate: dayjsLocal().day() === 6 ? dayjsLocal().subtract(2, 'day').format('MM/DD/YYYY') : dayjsLocal().day() === 0 || dayjsLocal().day() === 1 ? dayjsLocal().subtract(3, 'day').format('MM/DD/YYYY') : dayjsLocal().subtract(1, 'day').format('MM/DD/YYYY'),
        endDate: dayjsLocal().day() === 6 ? dayjsLocal().subtract(2, 'day').format('MM/DD/YYYY') : dayjsLocal().day() === 0 || dayjsLocal().day() === 1 ? dayjsLocal().subtract(3, 'day').format('MM/DD/YYYY') : dayjsLocal().subtract(1, 'day').format('MM/DD/YYYY')
      },
      broker
    })
  }, [broker]);

  useEffect(() => {
    if (isBrowserWakeUp && !isUninitialized) refetch();
  }, [isBrowserWakeUp, isUninitialized]);

  return (
    <div className="p-3 h-[calc(100%-2rem)]">
      <InputSelect
        name="broker"
        value={params?.broker?.id}
        label=""
        className="mb-3 text-xs text-gray-light-0-d w-full customize-input-select--round has-icon-left"
        options={options}
        placeholder="Cari Broker"
        onChange={(e) => {
          setParams({
            ...params,
            broker: LIST_BROKER?.find((item) => item?.id == e.target.value)
          });
          onChangeBroker(
            LIST_BROKER?.find((item) => item?.id == e.target.value)
          );
        }}
        iconRight={<IconSearch className="h-5 w-5 text-main-base-l flex-none" />}
        iconLeft={
          <b className={`text-xs flex items-center justify-center ${colors[params?.broker?.group]}`}>
            {params?.broker?.code || 'XX'}
          </b>
        }
      />

      <div className="grid lg:grid-cols-2 gap-3 mb-3 -mt-3">
        <div className="grid grid-cols-2 gap-3">
          <InputDatePickerSimple
            value={dayjsLocal(params?.params?.startDate, 'MM/DD/YYYY').toDate()}
            name="startDate"
            size="small"
            years={range(2000, getYear(new Date()) + 1, 1)}
            format="dd MMM yyyy"
            label={<span className="text-2xs text-gray-light-0-d">Dari</span>}
            placeholder="Dari Tanggal"
            onChange={(_date) =>
              setParams({
                ...params,
                params: {
                  ...params?.params,
                  startDate: dayjsLocal(_date)?.format('MM/DD/YYYY')
                }
              })
            }
            showError={false}
            className="mb-0"
            includeDateIntervals={[
              {
                start: subDays(new Date(), 365 * 100),
                end: new Date()
              }
            ]}
          />

          <InputDatePickerSimple
            value={dayjsLocal(params?.params?.endDate, 'MM/DD/YYYY').toDate()}
            name="endDate"
            size="small"
            years={range(2000, getYear(new Date()) + 1, 1)}
            format="dd MMM yyyy"
            label={<span className="text-2xs text-gray-light-0-d">Sampai</span>}
            placeholder="Sampai Tanggal"
            onChange={(_date) =>
              setParams({
                ...params,
                params: {
                  ...params?.params,
                  endDate: dayjsLocal(_date)?.format('MM/DD/YYYY')
                }
              })
            }
            showError={false}
            className="mb-0"
            includeDateIntervals={[
              {
                start: dayjsLocal(params?.params?.startDate, 'MM/DD/YYYY').toDate(),
                end: new Date()
              }
            ]}
          />
        </div>
        <div className="flex justify-end gap-2">
          <div className={`flex-auto w-[100px] max-w-full flex items-center`}>
            <InputSelect
              name="investor"
              value={investor}
              size="small"
              label={
                <span className="text-xs text-gray-light-0-d dark:text-black-light-0-d hidden lg:block h-6">&nbsp;</span>
              }
              className="mb-0 text-xs text-gray-light-0-d w-full"
              options={[
                {
                  id: 'All',
                  name: 'All Investor'
                },
                {
                  id: 'Domestic',
                  name: 'Domestic'
                },
                {
                  id: 'Foreign',
                  name: 'Foreign'
                }
              ]}
              onChange={(e) => setInvestor(e.target.value)}
            />
          </div>

          <div className='flex items-center flex-none w-[64px]'>
            <button
              onClick={() => {
                setShowNet(!showNet);
              }}
              className={`relative mt-1 lg:mt-7 border dark:border-black-light-1-l ${showNet ? 'bg-main-base-l dark:bg-black-base-d text-white' : 'card-widget-gradient text-main-base-l dark:text-black-light-1-d'} rounded-3xl px-1 py-[6px] flex items-center transition-all ease-linear duration-150 hover:opacity-75`}
              type="button"
              title={showNet ? 'Gross' : 'Net'}
              >
              <span className={`p-1 h-4 w-4 ${!showNet ? 'bg-main-base-l': 'bg-white dark:bg-main-base-l'} rounded-full inline-flex justify-center items-center transition-all ease-linear duration-150 absolute transform left-0 ${!showNet ? 'translate-x-1' : 'translate-x-[30px]'}`}></span>
              <span className={`text-2xs font-quicksand-semibold transition-all ease-linear duration-150 ${!showNet ? 'pl-5 pr-1 ' : 'pl-1 pr-5' }`}>
                {!showNet ? 'Gross' : 'Net'}
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 py-2 border-y text-2xs dark:border-gray-base-d">
        <div className="flex justify-around text-center">
          <div>
            <p className="text-gray-light-0-d dark:text-black-light-0-d mb-1">T.&nbsp;Val</p>
            <h5 className='dark:text-black-light-1-d'>{automatedNumberFormat(summary?.tVal, 9999)}</h5>
          </div>
          <div>
            <p className="text-gray-light-0-d dark:text-black-light-0-d mb-1">F.&nbsp;NVal</p>
            <h5 className='dark:text-black-light-1-d'>{automatedNumberFormat(summary?.tFVal, 9999)}</h5>
          </div>
        </div>
        <div className="flex justify-around text-center">
          <div>
            <p className="text-gray-light-0-d dark:text-black-light-0-d mb-1">T.&nbsp;Lot</p>
            <h5 className='dark:text-black-light-1-d'>{automatedNumberFormat(summary?.tVol, 9999)}</h5>
          </div>
          <div>
            <p className="text-gray-light-0-d dark:text-black-light-0-d mb-1">AvgP</p>
            <h5 className='dark:text-black-light-1-d'>{automatedNumberFormat(summary?.tAvg, 9999)}</h5>
          </div>
        </div>
      </div>

      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small -mx-3 px-3"
        style={{ maxHeight: 'calc(100% - 10rem)' }}
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}>
        <table className="text-2xs w-full text-right relative">
          <thead>
            <tr className="sticky top-0 card-widget-gradient text-gray-light-0-d dark:text-black-light-0-d">
              <td className="p-2">Buyer</td>
              <td className="p-2">B.&nbsp;Val</td>
              <td className="p-2">B.&nbsp;Lot</td>
              <td className="p-2">B.&nbsp;Avg</td>
              <td className="p-2">Seller</td>
              <td className="p-2">S.&nbsp;Val</td>
              <td className="p-2">S.&nbsp;Lot</td>
              <td className="p-2">S.&nbsp;Avg</td>
            </tr>
          </thead>

          <tbody className="text-2xs font-quicksand-semibold">
            <RenderIf
              isTrue={
                !isFetching &&
                (datas?.buyer?.length > 0 || datas?.seller?.length > 0)
              }>
              <tr>
                <td colSpan="4" valign="baseline">
                  <table className="w-full">
                    <tbody>
                      {datas?.buyer?.map((item, key) => (
                        <tr key={key} className="group">
                          <td className="w-[25%]">
                            <button
                              type='button'
                              onClick={() => customNavigate({ query: { 'stock-detail': item?.stockID }})}
                              className={`w-full p-1 line-clamp-1 block hover:opacity-75 dark:text-black-light-1-d ${
                                colors[
                                  LIST_BROKER?.find(
                                    (_item) => _item?.code == item?.stockID
                                  )?.group
                                ]
                              }`}>
                              {item?.stockID}
                            </button>
                          </td>
                          <td className="w-[25%]">
                            <span className="w-full p-1 line-clamp-1 block text-green-base-l">
                              {automatedNumberFormat(item?.bVal, 9999)}
                            </span>
                          </td>
                          <td className="w-[25%]">
                            <span className="w-full p-1 line-clamp-1 block text-green-base-l">
                              {automatedNumberFormat(item?.bVol, 9999)}
                            </span>
                          </td>
                          <td className="w-[25%]">
                            <span className="w-full p-1 line-clamp-1 block text-green-base-l">
                              {automatedNumberFormat(item?.bAvg, 9999)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
                <td colSpan="4" valign="baseline">
                  <table className="w-full">
                    <tbody>
                      {datas?.seller?.map((item, key) => (
                        <tr key={key} className="group">
                          <td className="w-[25%]">
                            <button
                              type='button'
                              onClick={() => customNavigate({ query: { 'stock-detail': item?.stockID }})}
                              className={`w-full p-1 line-clamp-1 block hover:opacity-75 dark:text-black-light-1-d ${
                                colors[
                                  LIST_BROKER?.find(
                                    (_item) => _item?.code == item?.stockID
                                  )?.group
                                ]
                              }`}>
                              {item?.stockID}
                            </button>
                          </td>
                          <td className="w-[25%]">
                            <span className="w-full p-1 line-clamp-1 block text-red-base-l">
                              {automatedNumberFormat(item?.sVal, 9999)}
                            </span>
                          </td>
                          <td className="w-[25%]">
                            <span className="w-full p-1 line-clamp-1 block text-red-base-l">
                              {automatedNumberFormat(item?.sVol, 9999)}
                            </span>
                          </td>
                          <td className="w-[25%]">
                            <span className="w-full p-1 line-clamp-1 block text-red-base-l">
                              {automatedNumberFormat(item?.sAvg, 9999)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            </RenderIf>

            <RenderIf
              isTrue={
                isFetching ||
                (datas?.buyer?.length === 0 && datas?.seller?.length === 0)
              }>
              {(symbol ? [0] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]).map((key) => (
                <tr key={key} className="group dark:text-black-light-1-d">
                  <td>
                    <span className="w-full p-1 line-clamp-1 block">
                      {isFetching ? <SkeletonTheme  className="h-[1rem]" /> : <>-</>}
                    </span>
                  </td>
                  <td>
                    <span className="w-full p-1 line-clamp-1 block">
                      {isFetching ? <SkeletonTheme  className="h-[1rem]" /> : <>-</>}
                    </span>
                  </td>
                  <td>
                    <span className="w-full p-1 line-clamp-1 block">
                      {isFetching ? <SkeletonTheme  className="h-[1rem]" /> : <>-</>}
                    </span>
                  </td>
                  <td>
                    <span className="w-full p-1 line-clamp-1 block">
                      {isFetching ? <SkeletonTheme  className="h-[1rem]" /> : <>-</>}
                    </span>
                  </td>
                  <td>
                    <span className="w-full p-1 line-clamp-1 block">
                      {isFetching ? <SkeletonTheme  className="h-[1rem]" /> : <>-</>}
                    </span>
                  </td>
                  <td>
                    <span className="w-full p-1 line-clamp-1 block">
                      {isFetching ? <SkeletonTheme  className="h-[1rem]" /> : <>-</>}
                    </span>
                  </td>
                  <td>
                    <span className="w-full p-1 line-clamp-1 block">
                      {isFetching ? <SkeletonTheme  className="h-[1rem]" /> : <>-</>}
                    </span>
                  </td>
                  <td>
                    <span className="w-full p-1 line-clamp-1 block">
                      {isFetching ? <SkeletonTheme  className="h-[1rem]" /> : <>-</>}
                    </span>
                  </td>
                </tr>
              ))}
            </RenderIf>
          </tbody>
        </table>
      </OverlayScrollbarsComponent>
    </div>
  );
}

TWBrokerActivity.propTypes = {
  symbol: PropTypes.string,
  broker: PropTypes.object,
  onChangeBroker: PropTypes.func,
  maxHeight: PropTypes.string
};

export default TWBrokerActivity;
