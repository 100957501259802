import PropTypes from 'prop-types';
import CardStockItem from 'components/cards/card-stock-item';
import RenderIf from 'components/basic/render-if';
import {
  invalidateWatchlist,
  useCreateWatchlistMutation,
  useGetWatchlistQuery
} from 'services/rtk-query/watchlist';
import { useEffect, useState } from 'react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetStockThematicByIdQuery } from 'services/rtk-query/stock';
import { useSelector } from 'react-redux';
import IconPlus from 'components/svg/icon-plus';
import StockSearchForm from 'components/form/stock-search-form';
import useToastr from 'hooks/useToastr';
import { triggerSegmentEvent } from 'lib/segment';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import Modal from 'components/modal/modal';

function TWWatchlist({
  activeSymbol,
  onClickStock,
  isCardLess = false,
  isZoomOut = false,
  onClickBuy,
  title = '',
  showAddWatchlist = true
}) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { data, isFetching: isFetchingWatch } = useGetWatchlistQuery();
  const { data: dataIDX30, isFetching: loadingIDX30 } =
    useGetStockThematicByIdQuery('6360c4b93e25a27b266bbd77');
  const [createWatchlist] = useCreateWatchlistMutation();

  const [dataLive, setDataLive] = useState([]);
  const [showSearchStockForm, setShowSearchStockForm] = useState(false);

  const handleAddWatchlist = async (stocks) => {
    try {
      const promises = [];
      stocks?.map((code) => {
        promises.push(
          createWatchlist({ stockCode: code, type: 'stock' }).unwrap()
        );
      });
      await Promise.all(promises);

      toastr.success(`Berhasil menambahkan watchlist`);

      stocks?.map((code) => {
        triggerSegmentEvent({
          event: 'TR Stock Added to Watchlist',
          properties: {
            stock_name: code
          }
        });
      });

      setShowSearchStockForm(false);
    } catch (error) {
      getErrorMessage(error);
    }
  };

  useEffect(() => {
    if (data?.length > 0) setDataLive(data);
  }, [data]);

  useEffect(() => {
    if (data?.length === 0 && dataIDX30?.data?.length > 0)
      setDataLive([dataIDX30?.data[0], dataIDX30?.data[1], dataIDX30?.data[2]]);
  }, [dataIDX30, data]);

  useEffect(() => {
    if (!isBursaOpen && dataLive?.length > 0) invalidateWatchlist();
  }, [isBursaOpen, dataLive]);

  if (isFetchingWatch || loadingIDX30) {
    return (
      <section className={`${!isCardLess && 'card-widget-gradient rounded-xl '}`}>
        <div className="p-3 pb-0">
          <SkeletonTheme
            containerClassName="mr-1"
            width="200px"
            height="1.5rem"
            borderRadius="6px"
          />
        </div>

        <div className="p-3">
          {Array(5)
            .fill(1)
            .map((el, key) => (
              <div key={key} className="flex justify-between mb-5">
                <div className="flex">
                  <SkeletonTheme
                    containerClassName="mr-3"
                    width="40px"
                    height="40px"
                    borderRadius="100%"
                  />

                  <div>
                    <SkeletonTheme
                      containerClassName="mr-1"
                      width="70px"
                      height="16px"
                      borderRadius="6px"
                    />

                    <SkeletonTheme
                      containerClassName="mr-1"
                      width="100px"
                      height="16px"
                      borderRadius="6px"
                    />
                  </div>
                </div>

                <div className="text-right">
                  <SkeletonTheme
                    width="40px"
                    height="16px"
                    borderRadius="6px"
                  />

                  <SkeletonTheme
                    width="50px"
                    height="16px"
                    borderRadius="6px"
                  />
                </div>
              </div>
            ))}
        </div>
      </section>
    );
  }

  return (
    <section className={`${!isCardLess && 'card-widget-gradient rounded-xl '}`}>
      <div className="p-4 pb-0 flex justify-between items-center z-20 relative">
        {title && <h3 className="text-sm font-quicksand-semibold dark:text-main-base-d">{title}</h3>}

        <RenderIf isTrue={showAddWatchlist}>
          <button
            type="button"
            className="relative flex justify-end items-center text-main-base-l text-sm font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150"
            onClick={() => setShowSearchStockForm(true)}>
            <IconPlus className="h-4 w-4 mr-1" /> Tambah
          </button>
        </RenderIf>
      </div>

      <div className={`${!isCardLess && 'pt-3'}`}>
        <RenderIf
          isTrue={dataLive?.length > 0 && !isFetchingWatch && !loadingIDX30}>
          <ul>
            {dataLive?.map((item, key) => (
              <li key={key}>
                <CardStockItem
                  {...item}
                  isZoomOut={isZoomOut}
                  isActive={activeSymbol === item.code}
                  logo={item?.logo_url ? item?.logo_url : item?.logo}
                  data-link-qa-id="linkWatchlistStock"
                  data-img-qa-id="imgWatchlistStock"
                  isLastItem={key === dataLive?.length - 1}
                  onClick={onClickStock}
                  onClickBuy={onClickBuy}
                />
              </li>
            ))}
          </ul>
        </RenderIf>

        <RenderIf
          isTrue={dataLive.length === 0 && !isFetchingWatch && !loadingIDX30}>
          <p className="text-sm px-4 pb-3 text-gray-light-0-d">
            Belum ada watchlist saham.
          </p>
        </RenderIf>
      </div>

      <RenderIf isTrue={showSearchStockForm}>
        <Modal onClose={() => setShowSearchStockForm(false)}>
          <StockSearchForm
            isMultiple
            header={
              <h1 className="font-quicksand-semibold text-base mb-8 text-main-base-l dark:text-black-light-1-d">
                Tambah Watchlist
              </h1>
            }
            onClose={() => setShowSearchStockForm(false)}
            onSelect={handleAddWatchlist}
          />
        </Modal>
      </RenderIf>
    </section>
  );
}

TWWatchlist.propTypes = {
  data: PropTypes.array,
  mutualFunds: PropTypes.array,
  activeSymbol: PropTypes.any,
  onClickStock: PropTypes.func,
  onClickBuy: PropTypes.func,
  isCardLess: PropTypes.bool,
  showAddWatchlist: PropTypes.bool,
  title: PropTypes.string
};

export default TWWatchlist;
