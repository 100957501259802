import clsx from 'clsx';
import RenderIf from 'components/basic/render-if';
import IconSorting from 'components/svg/icon-sorting';
import { sumBy } from 'lodash';
import { currency } from 'utils/format';
import ReturnTextValue from './done-detail-return-value';
import cn from 'utils/classname';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export default function DoneDetailGrouping({
  sortBy = {},
  data = [],
  handleSorting = () => false,
  getSorting = () => false
}) {
  return (
    <>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        style={{ maxHeight: 'calc(100%)' }}>
        {data?.map((item, k) => (
          <table
            key={k}
            className={`w-full text-center font-quicksand-regular text-2xs border-gray-base-l dark:border-gray-base-d mb-0 border-t`}>
            <thead className="text-gray-light-0-d dark:text-black-light-0-d">
              <tr className="border-b text-left dark:border-gray-base-d">
                <td
                  className={`p-1 py-1.5 cursor-pointer h-[30.58px] w-[10%]`}
                  onClick={() => handleSorting('stID')}>
                  <span
                    className={`flex items-center gap-1 ${
                      sortBy?.s === 'stID' && 'text-main-base-d'
                    }`}>
                    <RenderIf isTrue={k === 0}>
                      <IconSorting
                        type={getSorting('stID')}
                        className={cn(
                          'h-3 w-3 hidden',
                          sortBy?.s === 'stID' && 'block'
                        )}
                      />
                      StockID
                    </RenderIf>
                  </span>
                </td>

                <td
                  className="p-1 py-1.5 cursor-pointer h-[30.58px] w-[5%]"
                  onClick={() => handleSorting('side')}>
                  <span
                    className={`flex items-center gap-1 ${
                      sortBy?.s === 'side' && 'text-main-base-d'
                    }`}>
                    <RenderIf isTrue={k === 0}>
                      <IconSorting
                        type={getSorting('side')}
                        className={cn(
                          'h-3 w-3 hidden',
                          sortBy?.s === 'side' && 'block'
                        )}
                      />
                      B/S
                    </RenderIf>
                  </span>
                </td>

                <td
                  className="p-1 py-1.5 cursor-pointer h-[30.58px] w-[15%]"
                  onClick={() => handleSorting('price')}>
                  <span
                    className={`flex items-center gap-1 justify-end ${
                      sortBy?.s === 'price' && 'text-main-base-d'
                    }`}>
                    <RenderIf isTrue={k === 0}>
                      <IconSorting
                        type={getSorting('price')}
                        className={cn(
                          'h-3 w-3 hidden',
                          sortBy?.s === 'price' && 'block'
                        )}
                      />
                      Average&nbsp;Price
                    </RenderIf>
                  </span>
                </td>

                <td
                  className="p-1 py-1.5 cursor-pointer h-[30.58px] w-[17.5%]"
                  onClick={() => handleSorting('qty')}>
                  <span
                    className={`flex items-center gap-1 justify-end ${
                      sortBy?.s === 'qty' && 'text-main-base-d'
                    }`}>
                    <RenderIf isTrue={k === 0}>
                      <IconSorting
                        type={getSorting('qty')}
                        className={cn(
                          'h-3 w-3 hidden',
                          sortBy?.s === 'qty' && 'block'
                        )}
                      />
                      LOT
                    </RenderIf>
                  </span>
                </td>
                <td
                  className="p-1 py-1.5 cursor-pointer h-[30.58px] w-[17.5%]"
                  onClick={() => handleSorting('tradeValue')}>
                  <span
                    className={`flex items-center gap-1 justify-end ${
                      sortBy?.s === 'tradeValue' && 'text-main-base-d'
                    }`}>
                    <RenderIf isTrue={k === 0}>
                      <IconSorting
                        type={getSorting('tradeValue')}
                        className={cn(
                          'h-3 w-3 hidden',
                          sortBy?.s === 'tradeValue' && 'block'
                        )}
                      />
                      Trade&nbsp;Value
                    </RenderIf>
                  </span>
                </td>

                <td
                  className="p-1 py-1.5 cursor-pointer h-[30.58px] w-[17.5%]"
                  onClick={() => handleSorting('amount')}>
                  <span
                    className={`flex items-center gap-1 justify-end ${
                      sortBy?.s === 'amount' && 'text-main-base-d'
                    }`}>
                    <RenderIf isTrue={k === 0}>
                      <IconSorting
                        type={getSorting('amount')}
                        className={cn(
                          'h-3 w-3 hidden',
                          sortBy?.s === 'amount' && 'block'
                        )}
                      />
                      Amount
                    </RenderIf>
                  </span>
                </td>
              </tr>
            </thead>

            <tbody className="dark:text-black-light-1-d">
              {item?.listBySide?.map((item2, key) => (
                <tr key={key} className={clsx('text-left group')}>
                  <td className="p-1 group-first:pt-2 font-quicksand-semibold text-black-base-d dark:text-black-light-1-d">
                    {item?.stID}
                  </td>

                  <td className="p-1 group-first:pt-2">
                    {item2?.side === 'B' ? (
                      <span className="text-red-base-l">BUY</span>
                    ) : (
                      <span className="text-green-base-l">SELL</span>
                    )}
                  </td>

                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold">
                    {currency(item2?.averagePrice, { removeSymbol: true })}
                  </td>

                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold">
                    {currency(item2?.lot, { removeSymbol: true })} LOT
                  </td>

                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold">
                    {currency(item2?.value, {
                      removeSymbol: true
                    })}
                  </td>

                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold">
                    {currency(item2?.amount, {
                      removeSymbol: true
                    })}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  className="p-1 t py-2 text-right font-quicksand-semibold"
                  colSpan={3}>
                  Nett Of {item?.stID}
                </td>
                <td className="p-1 py-2 text-right font-quicksand-semibold">
                  <ReturnTextValue
                    reverseColor
                    suffix=" LOT"
                    buyValue={
                      sumBy(
                        item?.listSingle?.filter((tr) => tr?.side === 'B'),
                        'qty'
                      ) / 100
                    }
                    sellValue={
                      sumBy(
                        item?.listSingle?.filter((tr) => tr?.side === 'S'),
                        'qty'
                      ) / 100
                    }
                  />
                </td>
                <td className="p-1 py-2 text-right font-quicksand-semibold">
                  <ReturnTextValue
                    prefix=""
                    buyValue={sumBy(
                      item?.listSingle?.filter((tr) => tr?.side === 'B'),
                      'tradeValue'
                    )}
                    sellValue={sumBy(
                      item?.listSingle?.filter((tr) => tr?.side === 'S'),
                      'tradeValue'
                    )}
                  />
                </td>
                <td className="p-1 py-2 text-right font-quicksand-semibold">
                  <ReturnTextValue
                    prefix=""
                    buyValue={sumBy(
                      item?.listSingle?.filter((tr) => tr?.side === 'B'),
                      'amount'
                    )}
                    sellValue={sumBy(
                      item?.listSingle?.filter((tr) => tr?.side === 'S'),
                      'amount'
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ))}
      </OverlayScrollbarsComponent>
    </>
  );
}
