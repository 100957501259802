import PropTypes from 'prop-types';
import styles from './css/tooltip.module.css';
import { useState } from 'react';
import { Portal } from 'hooks/usePortal';

function Tooltip({ children, tooltipHTML, position = "bottom-left", isZoomOut = false }) {
  const [coords, setCoords] = useState({ left: 0, top: 0, right: 'unset' });
  const [showTooltip, setShowTooltip] = useState(false);

  const mouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect();

    if (position === 'bottom-right') {
      setCoords({
        left: rect.right,
        top: rect.top + rect.height
      });
    }

    if (position === 'bottom-left') {
      setCoords({
        left: rect.left + rect.width / 2,
        top: rect.top + rect.height
      });
    }

    setShowTooltip(true);
  };

  const mouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className="contents">
      {children}
      {showTooltip && (
        <Portal isZoomOut={isZoomOut}>
          <div
            style={{ left: coords?.left, top: coords?.top, right: coords?.right }}
            className={`${styles['tooltip']} ${styles[position]} dark:bg-black-dark-d dark:text-white`}>
            <span>{tooltipHTML}</span>
          </div>
        </Portal>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  children: PropTypes.any,
  tooltipHTML: PropTypes.any,
  position: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
};

export default Tooltip;
