import { useEffect, useRef, useState } from 'react';

const useElementSize = () => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setDimensions(entries[0].contentRect);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      ref.current && observer.unobserve(ref.current);
    };
  }, []);

  return { dimensions, ref };
};

export default useElementSize;
