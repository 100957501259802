// eslint-disable-next-line react/prop-types
function IconPopUpShow({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4326_30691)">
        <path d="M3.53556 14.7023C3.53556 14.15 3.98327 13.7023 4.53556 13.7023C5.08784 13.7023 5.53556 14.15 5.53556 14.7023V17.5307L10.1924 12.8738C10.5829 12.4833 11.2161 12.4833 11.6066 12.8738C11.9971 13.2644 11.9971 13.8975 11.6066 14.2881L6.94977 18.9449H9.7782C10.3305 18.9449 10.7782 19.3926 10.7782 19.9449C10.7782 20.4972 10.3305 20.9449 9.7782 20.9449L4.53556 20.9449C3.98327 20.9449 3.53556 20.4972 3.53556 19.9449L3.53556 14.7023Z" />
        <path d="M21.4029 9.7782C21.4029 10.3305 20.9552 10.7782 20.4029 10.7782C19.8506 10.7782 19.4029 10.3305 19.4029 9.7782V6.94978L14.7461 11.6066C14.3555 11.9972 13.7224 11.9972 13.3319 11.6066C12.9413 11.2161 12.9413 10.5829 13.3319 10.1924L17.9887 5.53556H15.1603C14.608 5.53556 14.1603 5.08785 14.1603 4.53556C14.1603 3.98328 14.608 3.53556 15.1603 3.53556L20.4029 3.53556C20.9552 3.53556 21.4029 3.98328 21.4029 4.53556L21.4029 9.7782Z" />
      </g>
      <defs>
        <clipPath id="clip0_4326_30691">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPopUpShow;
