import PropTypes from 'prop-types';

function IconEmail({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46591 3.33334H13.5347C14.2056 3.33333 14.7592 3.33332 15.2102 3.37017C15.6786 3.40844 16.1092 3.49058 16.5136 3.69666C17.1408 4.01623 17.6508 4.52617 17.9704 5.15338C18.1764 5.55782 18.2586 5.98841 18.2968 6.45682C18.3337 6.90781 18.3337 7.46144 18.3337 8.13224V11.8678C18.3337 12.5386 18.3337 13.0922 18.2968 13.5432C18.2586 14.0116 18.1764 14.4422 17.9704 14.8466C17.6508 15.4739 17.1408 15.9838 16.5136 16.3034C16.1092 16.5094 15.6786 16.5916 15.2102 16.6299C14.7592 16.6667 14.2055 16.6667 13.5347 16.6667H6.46593C5.79511 16.6667 5.24147 16.6667 4.79047 16.6299C4.32206 16.5916 3.89147 16.5094 3.48702 16.3034C2.85982 15.9838 2.34988 15.4739 2.0303 14.8466C1.82423 14.4422 1.74209 14.0116 1.70382 13.5432C1.66697 13.0922 1.66698 12.5386 1.66699 11.8678V8.13226C1.66698 7.46145 1.66697 6.90782 1.70382 6.45682C1.74209 5.98841 1.82423 5.55782 2.0303 5.15338C2.34988 4.52617 2.85982 4.01623 3.48702 3.69666C3.89147 3.49058 4.32206 3.40844 4.79047 3.37017C5.24147 3.33332 5.7951 3.33333 6.46591 3.33334ZM4.92619 5.0313C4.56085 5.06115 4.37401 5.11526 4.24368 5.18167C3.93007 5.34146 3.6751 5.59642 3.51532 5.91003C3.44891 6.04036 3.3948 6.2272 3.36495 6.59254C3.33431 6.96762 3.33366 7.45286 3.33366 8.16668V11.8333C3.33366 12.5472 3.33431 13.0324 3.36495 13.4075C3.3948 13.7728 3.44891 13.9597 3.51532 14.09C3.6751 14.4036 3.93007 14.6586 4.24368 14.8184C4.37401 14.8848 4.56085 14.9389 4.92619 14.9687C5.30127 14.9994 5.78651 15 6.50033 15H13.5003C14.2141 15 14.6994 14.9994 15.0745 14.9687C15.4398 14.9389 15.6266 14.8848 15.757 14.8184C16.0706 14.6586 16.3256 14.4036 16.4853 14.09C16.5517 13.9597 16.6059 13.7728 16.6357 13.4075C16.6663 13.0324 16.667 12.5472 16.667 11.8333V8.16668C16.667 7.45286 16.6663 6.96762 16.6357 6.59254C16.6059 6.2272 16.5517 6.04036 16.4853 5.91003C16.3256 5.59642 16.0706 5.34146 15.757 5.18167C15.6266 5.11526 15.4398 5.06115 15.0745 5.0313C14.6994 5.00066 14.2141 5.00001 13.5003 5.00001H6.50033C5.78651 5.00001 5.30127 5.00066 4.92619 5.0313Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14623 7.0286C5.40658 6.64908 5.9253 6.55247 6.30482 6.81282L9.52867 9.02439C9.81274 9.21926 10.1874 9.21926 10.4715 9.02439L13.6953 6.81282C14.0749 6.55247 14.5936 6.64908 14.8539 7.0286C15.1143 7.40812 15.0177 7.92684 14.6382 8.18719L11.4143 10.3988C10.5621 10.9834 9.43805 10.9834 8.58586 10.3988L5.362 8.18719C4.98248 7.92684 4.88588 7.40812 5.14623 7.0286Z"
      />
    </svg>
  );
}

IconEmail.propTypes = {
  className: PropTypes.string
};

export default IconEmail;
