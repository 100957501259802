import PropTypes from 'prop-types';

function IconCalendar({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63099 2.5H11.369C12.2722 2.49999 13.0006 2.49999 13.5905 2.54818C14.1979 2.59781 14.7314 2.70265 15.225 2.95414C16.009 3.35361 16.6464 3.99103 17.0459 4.77504C17.2973 5.26862 17.4022 5.80211 17.4518 6.40948C17.5 6.99938 17.5 7.72783 17.5 8.63097V11.369C17.5 12.2722 17.5 13.0006 17.4518 13.5905C17.4022 14.1979 17.2973 14.7314 17.0459 15.225C16.6464 16.009 16.009 16.6464 15.225 17.0459C14.7314 17.2973 14.1979 17.4022 13.5905 17.4518C13.0006 17.5 12.2722 17.5 11.369 17.5H8.63097C7.72783 17.5 6.99937 17.5 6.40948 17.4518C5.80211 17.4022 5.26862 17.2973 4.77504 17.0459C3.99103 16.6464 3.35361 16.009 2.95414 15.225C2.70265 14.7314 2.59781 14.1979 2.54818 13.5905C2.49999 13.0006 2.49999 12.2722 2.5 11.369V8.63099C2.49999 7.72784 2.49999 6.99938 2.54818 6.40948C2.59781 5.80211 2.70265 5.26862 2.95414 4.77504C3.35361 3.99103 3.99103 3.35361 4.77504 2.95414C5.26862 2.70265 5.80211 2.59781 6.40948 2.54818C6.99938 2.49999 7.72784 2.49999 8.63099 2.5ZM6.5452 4.20931C6.04089 4.25052 5.75115 4.32733 5.53169 4.43915C5.06129 4.67883 4.67883 5.06129 4.43915 5.53169C4.32733 5.75115 4.25052 6.04089 4.20931 6.5452C4.16732 7.05924 4.16667 7.7195 4.16667 8.66667V11.3333C4.16667 12.2805 4.16732 12.9408 4.20931 13.4548C4.25052 13.9591 4.32733 14.2488 4.43915 14.4683C4.67883 14.9387 5.06129 15.3212 5.53169 15.5609C5.75115 15.6727 6.04089 15.7495 6.5452 15.7907C7.05924 15.8327 7.7195 15.8333 8.66667 15.8333H11.3333C12.2805 15.8333 12.9408 15.8327 13.4548 15.7907C13.9591 15.7495 14.2488 15.6727 14.4683 15.5609C14.9387 15.3212 15.3212 14.9387 15.5609 14.4683C15.6727 14.2488 15.7495 13.9591 15.7907 13.4548C15.8327 12.9408 15.8333 12.2805 15.8333 11.3333V8.66667C15.8333 7.7195 15.8327 7.05924 15.7907 6.5452C15.7495 6.04089 15.6727 5.75115 15.5609 5.53169C15.3212 5.06129 14.9387 4.67883 14.4683 4.43915C14.2488 4.32733 13.9591 4.25052 13.4548 4.20931C12.9408 4.16732 12.2805 4.16667 11.3333 4.16667H8.66667C7.7195 4.16667 7.05924 4.16732 6.5452 4.20931Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50001 10C7.50001 10.4603 7.12691 10.8334 6.66668 10.8334C6.20644 10.8334 5.83334 10.4603 5.83334 10C5.83334 9.5398 6.20644 9.16671 6.66668 9.16671C7.12691 9.16671 7.50001 9.5398 7.50001 10ZM10.8333 10C10.8333 10.4603 10.4602 10.8334 10 10.8334C9.53977 10.8334 9.16668 10.4603 9.16668 10C9.16668 9.5398 9.53977 9.16671 10 9.16671C10.4602 9.16671 10.8333 9.5398 10.8333 10ZM13.3333 10.8334C13.7936 10.8334 14.1667 10.4603 14.1667 10C14.1667 9.5398 13.7936 9.16671 13.3333 9.16671C12.8731 9.16671 12.5 9.5398 12.5 10C12.5 10.4603 12.8731 10.8334 13.3333 10.8334ZM7.50001 13.3334C7.50001 13.7936 7.12691 14.1667 6.66668 14.1667C6.20644 14.1667 5.83334 13.7936 5.83334 13.3334C5.83334 12.8731 6.20644 12.5 6.66668 12.5C7.12691 12.5 7.50001 12.8731 7.50001 13.3334ZM10 14.1667C10.4602 14.1667 10.8333 13.7936 10.8333 13.3334C10.8333 12.8731 10.4602 12.5 10 12.5C9.53977 12.5 9.16668 12.8731 9.16668 13.3334C9.16668 13.7936 9.53977 14.1667 10 14.1667ZM13.3333 14.1667C13.7936 14.1667 14.1667 13.7936 14.1667 13.3334C14.1667 12.8731 13.7936 12.5 13.3333 12.5C12.8731 12.5 12.5 12.8731 12.5 13.3334C12.5 13.7936 12.8731 14.1667 13.3333 14.1667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50001 6.66671C7.50001 7.12694 7.12691 7.50004 6.66668 7.50004C6.20644 7.50004 5.83334 7.12694 5.83334 6.66671C5.83334 6.20647 6.20644 5.83337 6.66668 5.83337C7.12691 5.83337 7.50001 6.20647 7.50001 6.66671ZM10.8333 6.66671C10.8333 7.12694 10.4602 7.50004 10 7.50004C9.53977 7.50004 9.16668 7.12694 9.16668 6.66671C9.16668 6.20647 9.53977 5.83337 10 5.83337C10.4602 5.83337 10.8333 6.20647 10.8333 6.66671ZM13.3333 7.50004C13.7936 7.50004 14.1667 7.12694 14.1667 6.66671C14.1667 6.20647 13.7936 5.83337 13.3333 5.83337C12.8731 5.83337 12.5 6.20647 12.5 6.66671C12.5 7.12694 12.8731 7.50004 13.3333 7.50004ZM7.50001 10C7.50001 10.4603 7.12691 10.8334 6.66668 10.8334C6.20644 10.8334 5.83334 10.4603 5.83334 10C5.83334 9.5398 6.20644 9.16671 6.66668 9.16671C7.12691 9.16671 7.50001 9.5398 7.50001 10ZM10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 10 9.16671C9.53977 9.16671 9.16668 9.5398 9.16668 10C9.16668 10.4603 9.53977 10.8334 10 10.8334ZM13.3333 10.8334C13.7936 10.8334 14.1667 10.4603 14.1667 10C14.1667 9.5398 13.7936 9.16671 13.3333 9.16671C12.8731 9.16671 12.5 9.5398 12.5 10C12.5 10.4603 12.8731 10.8334 13.3333 10.8334Z"
        fill="currentColor"
      />
    </svg>
  );
}

IconCalendar.propTypes = {
  className: PropTypes.string
};

IconCalendar.defaultProps = {
  className: ''
};

export default IconCalendar;
