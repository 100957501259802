import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import InputText from 'components/input/input-text';
import IconClose from 'components/svg/icon-close';
import { useFormik } from 'formik';
import useToastr from 'hooks/useToastr';
import { useState } from 'react';
import { SaveScreenerSchema } from './save-screener-form.validation';
import SimpleSpinner from 'components/common/spinner/spinner';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import InputTextArea from 'components/input/input-text-area';
import { useCreateUserStockScreenerMutation, useUpdateUserStockScreenerByIdMutation } from 'services/rtk-query/user';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function SaveScreenerForm({
  onClose = () => {},
  onSuccess = () => {},
  initialValues
}) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useBrowserTabAuth();

  const [createUserScreener] = useCreateUserStockScreenerMutation();
  const [updateUserScreener] = useUpdateUserStockScreenerByIdMutation();

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      name: initialValues?.name,
      remarks: initialValues?.remarks,
      user_id: user?.id
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: SaveScreenerSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        if (values?.id) {
          // Update Screener
          await updateUserScreener(values);
          toastr?.success('Screener berhasil di update');
        } else {
          // Create New Screener
          await createUserScreener(values);
          toastr?.success('Screener berhasil di simpan');
        }

        onSuccess();
      } catch (error) {
        getErrorMessage(error);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
      <Card className="p-6 relative max-w-lg mx-auto mt-20 dark:border dark:border-gray-base-d">
        <div className="mb-6">
          <h5 className="text-sm dark:text-black-light-1-d">{formik?.values?.id ? 'Update Screener' : 'Simpan Screener'}</h5>

          <button
            onClick={onClose}
            type="button"
            className="absolute right-4 top-4 hover:text-main-base-l dark:text-main-base-l hover:opacity-75">
            <IconClose />
          </button>
        </div>

        <InputText
          value={formik?.values?.name}
          name="name"
          placeholder="Beri nama screener saham kamu"
          label=""
          className="text-sm"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.errors.name ? formik.errors.name : ''}
          maxLength="30"
        />

        <span className="text-main-base-l text-xs">Maks. 30 Karakter</span>

        <InputTextArea
          className="my-4"
          name="remarks"
          placeholder="Tambah Catatan (Opsional)"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          rows={6}
          value={formik?.values?.remarks}
        />

        <div className="pt-4 mt-1 relative">
          <button
            type="submit"
            disabled={isLoading || !formik.isValid}
            className="bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-64 mr-0 ml-auto flex justify-center items-center transition-all ease-in-out duration-100">
            {isLoading && <SimpleSpinner />} Simpan
          </button>
        </div>
      </Card>
    </form>
  );
}

SaveScreenerForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  layout: PropTypes.string,
  initialValues: PropTypes.object,
  workspaces: PropTypes.array
};

export default SaveScreenerForm;
