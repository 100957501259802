import PropTypes from 'prop-types';

function IconChartCandleStick({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V5C16 5.55228 16.4477 6 17 6C17.5523 6 18 5.55228 18 5V3ZM8 6C8 5.44772 7.55228 5 7 5C6.44772 5 6 5.44772 6 6V12C6 12.5523 6.44772 13 7 13C7.55228 13 8 12.5523 8 12V6ZM7 16C7.55228 16 8 16.4477 8 17V21C8 21.5523 7.55228 22 7 22C6.44772 22 6 21.5523 6 21V17C6 16.4477 6.44772 16 7 16ZM18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12V18C16 18.5523 16.4477 19 17 19C17.5523 19 18 18.5523 18 18V12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7V10C18 10.5523 17.5523 11 17 11C16.4477 11 16 10.5523 16 10V7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7ZM14 7C14 5.34315 15.3431 4 17 4C18.6569 4 20 5.34315 20 7V10C20 11.6569 18.6569 13 17 13C15.3431 13 14 11.6569 14 10V7ZM8 14V15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15V14C6 13.4477 6.44772 13 7 13C7.55228 13 8 13.4477 8 14ZM4 14C4 12.3431 5.34315 11 7 11C8.65685 11 10 12.3431 10 14V15C10 16.6569 8.65685 18 7 18C5.34315 18 4 16.6569 4 15V14Z"
      />
    </svg>
  );
}

IconChartCandleStick.propTypes = {
  className: PropTypes.string
};

export default IconChartCandleStick;
