import SectionProfileTrading from '../_partials/profile-trading.section';
import SectionProfileSaldo from '../_partials/profile-saldo.section';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'components/basic/breadcrumb';
import TWInfoCash from 'pages/trading/_partials/tw-info-cash';

function PageDeposit() {
  const { slug } = useParams();

  return (
    <div className='zoom-2'>
      <div className="p-4">
        <Breadcrumb
          data={[
            {
              link: '/profile',
              title: 'Profile',
              icon: null
            },
            {
              link: '#',
              title: slug === 'deposit' ? 'Top-up Saldo' : slug === 'cash-withdrawal' ? 'Tarik Saldo' : slug === 'cash-history' ? 'Riwayat Saldo Rekening' : '',
              icon: null
            }
          ]}
        />

        <div className="grid grid-cols-12 gap-3 mt-3">
          <div className="col-span-12 lg:col-span-3">
            <SectionProfileTrading className="mb-3" />
            <TWInfoCash/>
          </div>

          <div className="col-span-12 lg:col-span-9">
            <SectionProfileSaldo />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageDeposit;
