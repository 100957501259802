import PropTypes from 'prop-types';

function RenderIf({ children, isTrue }) {
  return isTrue ? children : null;
}

RenderIf.propTypes = {
  children: PropTypes.any,
  isTrue: PropTypes.any
};

export default RenderIf;
