export const LIST_SYMBOL = [
  {
    symbol: 'AALI',
    name: 'Astra Agro Lestari Tbk.',
    full_name: 'Astra Agro Lestari Tbk.',
    description: 'Astra Agro Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AALI-R',
    name: 'HMETD Astra Agro Lestari',
    full_name: 'HMETD Astra Agro Lestari',
    description: 'HMETD Astra Agro Lestari',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ABBA',
    name: 'Mahaka Media Tbk.',
    full_name: 'Mahaka Media Tbk.',
    description: 'Mahaka Media Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ABDA',
    name: 'Asuransi Bina Dana Arta Tbk.',
    full_name: 'Asuransi Bina Dana Arta Tbk.',
    description: 'Asuransi Bina Dana Arta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ABMM',
    name: 'ABM Investama Tbk.',
    full_name: 'ABM Investama Tbk.',
    description: 'ABM Investama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ACES',
    name: 'Ace Hardware Indonesia Tbk.',
    full_name: 'Ace Hardware Indonesia Tbk.',
    description: 'Ace Hardware Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ACST',
    name: 'Acset Indonusa Tbk.',
    full_name: 'Acset Indonusa Tbk.',
    description: 'Acset Indonusa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ACST-R',
    name: 'HMETD Acset Indonusa',
    full_name: 'HMETD Acset Indonusa',
    description: 'HMETD Acset Indonusa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ADCP',
    name: 'Adhi Commuter Properti Tbk.',
    full_name: 'Adhi Commuter Properti Tbk.',
    description: 'Adhi Commuter Properti Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ADES',
    name: 'Akasha Wira International Tbk.',
    full_name: 'Akasha Wira International Tbk.',
    description: 'Akasha Wira International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ADHI',
    name: 'Adhi Karya (Persero) Tbk.',
    full_name: 'Adhi Karya (Persero) Tbk.',
    description: 'Adhi Karya (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ADHI-R',
    name: 'HMETD Adhi Karya ',
    full_name: 'HMETD Adhi Karya ',
    description: 'HMETD Adhi Karya ',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ADMF',
    name: 'Adira Dinamika Multi Finance Tbk.',
    full_name: 'Adira Dinamika Multi Finance Tbk.',
    description: 'Adira Dinamika Multi Finance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ADMG',
    name: 'Polychem Indonesia Tbk',
    full_name: 'Polychem Indonesia Tbk',
    description: 'Polychem Indonesia Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ADMR',
    name: 'Adaro Minerals Indonesia Tbk.',
    full_name: 'Adaro Minerals Indonesia Tbk.',
    description: 'Adaro Minerals Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ADRO',
    name: 'Adaro Energy Indonesia Tbk.',
    full_name: 'Adaro Energy Indonesia Tbk.',
    description: 'Adaro Energy Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AGAR',
    name: 'Asia Sejahtera Mina Tbk.',
    full_name: 'Asia Sejahtera Mina Tbk.',
    description: 'Asia Sejahtera Mina Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AGII',
    name: 'Samator Indo Gas Tbk.',
    full_name: 'Samator Indo Gas Tbk.',
    description: 'Samator Indo Gas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AGRO',
    name: 'Bank Raya Indonesia Tbk.',
    full_name: 'Bank Raya Indonesia Tbk.',
    description: 'Bank Raya Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AGRO-R',
    name: 'HMETD Bank Rakyat Indonesia Agroniaga Tb',
    full_name: 'HMETD Bank Rakyat Indonesia Agroniaga Tb',
    description: 'HMETD Bank Rakyat Indonesia Agroniaga Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AGRO-W',
    name: 'Waran Seri II Bank Rakyat Indonesia Agro',
    full_name: 'Waran Seri II Bank Rakyat Indonesia Agro',
    description: 'Waran Seri II Bank Rakyat Indonesia Agro',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AGRS',
    name: 'Bank IBK Indonesia Tbk.',
    full_name: 'Bank IBK Indonesia Tbk.',
    description: 'Bank IBK Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AGRS-R',
    name: 'HMETD Bank IBK Indonesia',
    full_name: 'HMETD Bank IBK Indonesia',
    description: 'HMETD Bank IBK Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AHAP',
    name: 'Asuransi Harta Aman Pratama Tbk.',
    full_name: 'Asuransi Harta Aman Pratama Tbk.',
    description: 'Asuransi Harta Aman Pratama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AHAP-R',
    name: 'HMETD Asuransi Harta Aman Pratama',
    full_name: 'HMETD Asuransi Harta Aman Pratama',
    description: 'HMETD Asuransi Harta Aman Pratama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AIMS',
    name: 'Akbar Indo Makmur Stimec Tbk',
    full_name: 'Akbar Indo Makmur Stimec Tbk',
    description: 'Akbar Indo Makmur Stimec Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AISA',
    name: 'FKS Food Sejahtera Tbk.',
    full_name: 'FKS Food Sejahtera Tbk.',
    description: 'FKS Food Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AKKU',
    name: 'Anugerah Kagum Karya Utama Tbk.',
    full_name: 'Anugerah Kagum Karya Utama Tbk.',
    description: 'Anugerah Kagum Karya Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AKKU-R',
    name: 'HMETD Anugerah Kagum Karya Utama',
    full_name: 'HMETD Anugerah Kagum Karya Utama',
    description: 'HMETD Anugerah Kagum Karya Utama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AKPI',
    name: 'Argha Karya Prima Industry Tbk.',
    full_name: 'Argha Karya Prima Industry Tbk.',
    description: 'Argha Karya Prima Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AKRA',
    name: 'AKR Corporindo Tbk.',
    full_name: 'AKR Corporindo Tbk.',
    description: 'AKR Corporindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AKSI',
    name: 'Mineral Sumberdaya Mandiri Tbk.',
    full_name: 'Mineral Sumberdaya Mandiri Tbk.',
    description: 'Mineral Sumberdaya Mandiri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ALDO',
    name: 'Alkindo Naratama Tbk.',
    full_name: 'Alkindo Naratama Tbk.',
    description: 'Alkindo Naratama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ALDO-R',
    name: 'HMETD Alkindo Naratama',
    full_name: 'HMETD Alkindo Naratama',
    description: 'HMETD Alkindo Naratama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ALKA',
    name: 'Alakasa Industrindo Tbk',
    full_name: 'Alakasa Industrindo Tbk',
    description: 'Alakasa Industrindo Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ALMI',
    name: 'Alumindo Light Metal Industry Tbk.',
    full_name: 'Alumindo Light Metal Industry Tbk.',
    description: 'Alumindo Light Metal Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ALTO',
    name: 'Tri Banyan Tirta Tbk.',
    full_name: 'Tri Banyan Tirta Tbk.',
    description: 'Tri Banyan Tirta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ALTO-W',
    name: 'Waran Seri I Tri Banyan Tirta',
    full_name: 'Waran Seri I Tri Banyan Tirta',
    description: 'Waran Seri I Tri Banyan Tirta',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMAG',
    name: 'Asuransi Multi Artha Guna Tbk.',
    full_name: 'Asuransi Multi Artha Guna Tbk.',
    description: 'Asuransi Multi Artha Guna Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMAG-W',
    name: 'Waran Seri II Asuransi Mutli Artha Guna',
    full_name: 'Waran Seri II Asuransi Mutli Artha Guna',
    description: 'Waran Seri II Asuransi Mutli Artha Guna',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMAN',
    name: 'Makmur Berkah Amanda Tbk.',
    full_name: 'Makmur Berkah Amanda Tbk.',
    description: 'Makmur Berkah Amanda Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMAR',
    name: 'Bank Amar Indonesia Tbk.',
    full_name: 'Bank Amar Indonesia Tbk.',
    description: 'Bank Amar Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMFG',
    name: 'Asahimas Flat Glass Tbk.',
    full_name: 'Asahimas Flat Glass Tbk.',
    description: 'Asahimas Flat Glass Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMIN',
    name: 'Ateliers Mecaniques D Indonesie Tbk.',
    full_name: 'Ateliers Mecaniques D Indonesie Tbk.',
    description: 'Ateliers Mecaniques D Indonesie Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMMS',
    name: 'Agung Menjangan Mas Tbk.',
    full_name: 'Agung Menjangan Mas Tbk.',
    description: 'Agung Menjangan Mas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMOR',
    name: 'Ashmore Asset Management Indonesia Tbk.',
    full_name: 'Ashmore Asset Management Indonesia Tbk.',
    description: 'Ashmore Asset Management Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AMRT',
    name: 'Sumber Alfaria Trijaya Tbk.',
    full_name: 'Sumber Alfaria Trijaya Tbk.',
    description: 'Sumber Alfaria Trijaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ANDI',
    name: 'Andira Agro Tbk.',
    full_name: 'Andira Agro Tbk.',
    description: 'Andira Agro Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ANJT',
    name: 'Austindo Nusantara Jaya Tbk.',
    full_name: 'Austindo Nusantara Jaya Tbk.',
    description: 'Austindo Nusantara Jaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ANTM',
    name: 'Aneka Tambang Tbk.',
    full_name: 'Aneka Tambang Tbk.',
    description: 'Aneka Tambang Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ANTM-R',
    name: 'HMETD Aneka Tambang ',
    full_name: 'HMETD Aneka Tambang ',
    description: 'HMETD Aneka Tambang ',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'APEX',
    name: 'Apexindo Pratama Duta Tbk.',
    full_name: 'Apexindo Pratama Duta Tbk.',
    description: 'Apexindo Pratama Duta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'APIC',
    name: 'Pacific Strategic Financial Tbk.',
    full_name: 'Pacific Strategic Financial Tbk.',
    description: 'Pacific Strategic Financial Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'APIC-R',
    name: 'HMETD Pacific Strategic Financial',
    full_name: 'HMETD Pacific Strategic Financial',
    description: 'HMETD Pacific Strategic Financial',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'APIC-W',
    name: 'Waran Seri II Pacific Strategic Financia',
    full_name: 'Waran Seri II Pacific Strategic Financia',
    description: 'Waran Seri II Pacific Strategic Financia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'APII',
    name: 'Arita Prima Indonesia Tbk.',
    full_name: 'Arita Prima Indonesia Tbk.',
    description: 'Arita Prima Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'APLI',
    name: 'Asiaplast Industries Tbk.',
    full_name: 'Asiaplast Industries Tbk.',
    description: 'Asiaplast Industries Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'APLN',
    name: 'Agung Podomoro Land Tbk.',
    full_name: 'Agung Podomoro Land Tbk.',
    description: 'Agung Podomoro Land Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'APLN-R',
    name: 'HMETD Agung Podomoro Land',
    full_name: 'HMETD Agung Podomoro Land',
    description: 'HMETD Agung Podomoro Land',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARCI',
    name: 'Archi Indonesia Tbk.',
    full_name: 'Archi Indonesia Tbk.',
    description: 'Archi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARGO',
    name: 'Argo Pantes Tbk',
    full_name: 'Argo Pantes Tbk',
    description: 'Argo Pantes Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARII',
    name: 'Atlas Resources Tbk.',
    full_name: 'Atlas Resources Tbk.',
    description: 'Atlas Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARKA',
    name: 'Arkha Jayanti Persada Tbk.',
    full_name: 'Arkha Jayanti Persada Tbk.',
    description: 'Arkha Jayanti Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARKO',
    name: 'Arkora Hydro Tbk.',
    full_name: 'Arkora Hydro Tbk.',
    description: 'Arkora Hydro Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARMY',
    name: 'Armidian Karyatama Tbk.',
    full_name: 'Armidian Karyatama Tbk.',
    description: 'Armidian Karyatama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARNA',
    name: 'Arwana Citramulia Tbk.',
    full_name: 'Arwana Citramulia Tbk.',
    description: 'Arwana Citramulia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARTA',
    name: 'Arthavest Tbk',
    full_name: 'Arthavest Tbk',
    description: 'Arthavest Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARTI',
    name: 'Ratu Prabu Energi Tbk',
    full_name: 'Ratu Prabu Energi Tbk',
    description: 'Ratu Prabu Energi Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARTO',
    name: 'Bank Jago Tbk.',
    full_name: 'Bank Jago Tbk.',
    description: 'Bank Jago Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ARTO-R',
    name: 'HMETD Bank Jago',
    full_name: 'HMETD Bank Jago',
    description: 'HMETD Bank Jago',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASBI',
    name: 'Asuransi Bintang Tbk.',
    full_name: 'Asuransi Bintang Tbk.',
    description: 'Asuransi Bintang Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASDM',
    name: 'Asuransi Dayin Mitra Tbk.',
    full_name: 'Asuransi Dayin Mitra Tbk.',
    description: 'Asuransi Dayin Mitra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASGR',
    name: 'Astra Graphia Tbk.',
    full_name: 'Astra Graphia Tbk.',
    description: 'Astra Graphia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASHA',
    name: 'Cilacap Samudera Fishing Industry Tbk.',
    full_name: 'Cilacap Samudera Fishing Industry Tbk.',
    description: 'Cilacap Samudera Fishing Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASII',
    name: 'Astra International Tbk.',
    full_name: 'Astra International Tbk.',
    description: 'Astra International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASJT',
    name: 'Asuransi Jasa Tania Tbk.',
    full_name: 'Asuransi Jasa Tania Tbk.',
    description: 'Asuransi Jasa Tania Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASJT-R',
    name: 'HMETD Asuransi Jasa Tania',
    full_name: 'HMETD Asuransi Jasa Tania',
    description: 'HMETD Asuransi Jasa Tania',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASLC',
    name: 'Autopedia Sukses Lestari Tbk.',
    full_name: 'Autopedia Sukses Lestari Tbk.',
    description: 'Autopedia Sukses Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASMI',
    name: 'Asuransi Maximus Graha Persada Tbk.',
    full_name: 'Asuransi Maximus Graha Persada Tbk.',
    description: 'Asuransi Maximus Graha Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASMI-W',
    name: 'Waran Seri I Asuransi Kresna Mitra',
    full_name: 'Waran Seri I Asuransi Kresna Mitra',
    description: 'Waran Seri I Asuransi Kresna Mitra',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASPI',
    name: 'Andalan Sakti Primaindo Tbk.',
    full_name: 'Andalan Sakti Primaindo Tbk.',
    description: 'Andalan Sakti Primaindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASPI-W',
    name: 'Waran Seri I Andalan Sakti Primaindo',
    full_name: 'Waran Seri I Andalan Sakti Primaindo',
    description: 'Waran Seri I Andalan Sakti Primaindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASRI',
    name: 'Alam Sutera Realty Tbk.',
    full_name: 'Alam Sutera Realty Tbk.',
    description: 'Alam Sutera Realty Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASRM',
    name: 'Asuransi Ramayana Tbk.',
    full_name: 'Asuransi Ramayana Tbk.',
    description: 'Asuransi Ramayana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASSA',
    name: 'Adi Sarana Armada Tbk.',
    full_name: 'Adi Sarana Armada Tbk.',
    description: 'Adi Sarana Armada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ASSA-R',
    name: 'HMETD Adi Sarana Armada',
    full_name: 'HMETD Adi Sarana Armada',
    description: 'HMETD Adi Sarana Armada',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ATAP',
    name: 'Trimitra Prawara Goldland Tbk.',
    full_name: 'Trimitra Prawara Goldland Tbk.',
    description: 'Trimitra Prawara Goldland Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ATIC',
    name: 'Anabatic Technologies Tbk.',
    full_name: 'Anabatic Technologies Tbk.',
    description: 'Anabatic Technologies Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ATIC-R',
    name: 'HMETD Anabatic Technologies',
    full_name: 'HMETD Anabatic Technologies',
    description: 'HMETD Anabatic Technologies',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AUTO',
    name: 'Astra Otoparts Tbk.',
    full_name: 'Astra Otoparts Tbk.',
    description: 'Astra Otoparts Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AVIA',
    name: 'Avia Avian Tbk.',
    full_name: 'Avia Avian Tbk.',
    description: 'Avia Avian Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AWAN',
    name: 'Era Digital Media Tbk.',
    full_name: 'Era Digital Media Tbk.',
    description: 'Era Digital Media Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AXIO',
    name: 'Tera Data Indonusa Tbk.',
    full_name: 'Tera Data Indonusa Tbk.',
    description: 'Tera Data Indonusa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AXIO',
    name: 'Tera Data Indonusa Tbk.',
    full_name: 'Tera Data Indonusa Tbk.',
    description: 'Tera Data Indonusa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AYLS',
    name: 'Agro Yasa Lestari Tbk.',
    full_name: 'Agro Yasa Lestari Tbk.',
    description: 'Agro Yasa Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'AYLS-W',
    name: 'Waran Seri I Agro Yasa Lestari',
    full_name: 'Waran Seri I Agro Yasa Lestari',
    description: 'Waran Seri I Agro Yasa Lestari',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BABP',
    name: 'Bank MNC Internasional Tbk.',
    full_name: 'Bank MNC Internasional Tbk.',
    description: 'Bank MNC Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BABP-R',
    name: 'HMETD Bank MNC Internasional',
    full_name: 'HMETD Bank MNC Internasional',
    description: 'HMETD Bank MNC Internasional',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BABP-W',
    name: 'Waran Seri II Bank MNC Internasional',
    full_name: 'Waran Seri II Bank MNC Internasional',
    description: 'Waran Seri II Bank MNC Internasional',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BACA',
    name: 'Bank Capital Indonesia Tbk.',
    full_name: 'Bank Capital Indonesia Tbk.',
    description: 'Bank Capital Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BACA-R',
    name: 'HMETD Bank Capital Indonesia',
    full_name: 'HMETD Bank Capital Indonesia',
    description: 'HMETD Bank Capital Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BACA-W',
    name: 'Waran Seri II Bank Capital Indonesia',
    full_name: 'Waran Seri II Bank Capital Indonesia',
    description: 'Waran Seri II Bank Capital Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BAJA',
    name: 'Saranacentral Bajatama Tbk.',
    full_name: 'Saranacentral Bajatama Tbk.',
    description: 'Saranacentral Bajatama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BALI',
    name: 'Bali Towerindo Sentra Tbk.',
    full_name: 'Bali Towerindo Sentra Tbk.',
    description: 'Bali Towerindo Sentra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BALI-W',
    name: 'Waran Seri I Bali Towerindo Sentra',
    full_name: 'Waran Seri I Bali Towerindo Sentra',
    description: 'Waran Seri I Bali Towerindo Sentra',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BANK',
    name: 'Bank Aladin Syariah Tbk.',
    full_name: 'Bank Aladin Syariah Tbk.',
    description: 'Bank Aladin Syariah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BANK-W',
    name: 'Waran Seri I Bank Aladin Syariah',
    full_name: 'Waran Seri I Bank Aladin Syariah',
    description: 'Waran Seri I Bank Aladin Syariah',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BAPA',
    name: 'Bekasi Asri Pemula Tbk.',
    full_name: 'Bekasi Asri Pemula Tbk.',
    description: 'Bekasi Asri Pemula Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BAPI',
    name: 'Bhakti Agung Propertindo Tbk.',
    full_name: 'Bhakti Agung Propertindo Tbk.',
    description: 'Bhakti Agung Propertindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BAPI-W',
    name: 'Waran Seri I Bhakti Agung Propertindo Tb',
    full_name: 'Waran Seri I Bhakti Agung Propertindo Tb',
    description: 'Waran Seri I Bhakti Agung Propertindo Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BATA',
    name: 'Sepatu Bata Tbk.',
    full_name: 'Sepatu Bata Tbk.',
    description: 'Sepatu Bata Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BAUT',
    name: 'Mitra Angkasa Sejahtera Tbk.',
    full_name: 'Mitra Angkasa Sejahtera Tbk.',
    description: 'Mitra Angkasa Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BAYU',
    name: 'Bayu Buana Tbk',
    full_name: 'Bayu Buana Tbk',
    description: 'Bayu Buana Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBCA',
    name: 'Bank Central Asia Tbk.',
    full_name: 'Bank Central Asia Tbk.',
    description: 'Bank Central Asia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBHI',
    name: 'Allo Bank Indonesia Tbk.',
    full_name: 'Allo Bank Indonesia Tbk.',
    description: 'Allo Bank Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBHI-R',
    name: 'HMETD Allo Bank Indonesia',
    full_name: 'HMETD Allo Bank Indonesia',
    description: 'HMETD Allo Bank Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBKP',
    name: 'Bank KB Bukopin Tbk.',
    full_name: 'Bank KB Bukopin Tbk.',
    description: 'Bank KB Bukopin Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBKP-R',
    name: 'HMETD Bank Bukopin',
    full_name: 'HMETD Bank Bukopin',
    description: 'HMETD Bank Bukopin',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBLD',
    name: 'Buana Finance Tbk.',
    full_name: 'Buana Finance Tbk.',
    description: 'Buana Finance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBMD',
    name: 'Bank Mestika Dharma Tbk.',
    full_name: 'Bank Mestika Dharma Tbk.',
    description: 'Bank Mestika Dharma Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBNI',
    name: 'Bank Negara Indonesia (Persero) Tbk.',
    full_name: 'Bank Negara Indonesia (Persero) Tbk.',
    description: 'Bank Negara Indonesia (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBNP-R',
    name: 'HMETD Bank Nusantara Parahyangan',
    full_name: 'HMETD Bank Nusantara Parahyangan',
    description: 'HMETD Bank Nusantara Parahyangan',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBRI',
    name: 'Bank Rakyat Indonesia (Persero) Tbk.',
    full_name: 'Bank Rakyat Indonesia (Persero) Tbk.',
    description: 'Bank Rakyat Indonesia (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBRI-R',
    name: 'HMETD Bank Rakyat Indonesia  Tb',
    full_name: 'HMETD Bank Rakyat Indonesia  Tb',
    description: 'HMETD Bank Rakyat Indonesia  Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBRM',
    name: 'Pelayaran Nasional Bina Buana Raya Tbk.',
    full_name: 'Pelayaran Nasional Bina Buana Raya Tbk.',
    description: 'Pelayaran Nasional Bina Buana Raya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBSI',
    name: 'Krom Bank Indonesia Tbk.',
    full_name: 'Krom Bank Indonesia Tbk.',
    description: 'Krom Bank Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBSI-R',
    name: 'HMETD Bank Bisnis Internasional',
    full_name: 'HMETD Bank Bisnis Internasional',
    description: 'HMETD Bank Bisnis Internasional',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBSS',
    name: 'Bumi Benowo Sukses Sejahtera Tbk.',
    full_name: 'Bumi Benowo Sukses Sejahtera Tbk.',
    description: 'Bumi Benowo Sukses Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBSS-W',
    name: 'Waran Seri I Bumi Benowo Sukses Sejahter',
    full_name: 'Waran Seri I Bumi Benowo Sukses Sejahter',
    description: 'Waran Seri I Bumi Benowo Sukses Sejahter',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBTN',
    name: 'Bank Tabungan Negara (Persero) Tbk.',
    full_name: 'Bank Tabungan Negara (Persero) Tbk.',
    description: 'Bank Tabungan Negara (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBYB',
    name: 'Bank Neo Commerce Tbk.',
    full_name: 'Bank Neo Commerce Tbk.',
    description: 'Bank Neo Commerce Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBYB-R',
    name: 'HMETD Bank Neo Commerce',
    full_name: 'HMETD Bank Neo Commerce',
    description: 'HMETD Bank Neo Commerce',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BBYB-W',
    name: 'Waran Seri I Bank Yudha Bhakti',
    full_name: 'Waran Seri I Bank Yudha Bhakti',
    description: 'Waran Seri I Bank Yudha Bhakti',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BCAP',
    name: 'MNC Kapital Indonesia Tbk.',
    full_name: 'MNC Kapital Indonesia Tbk.',
    description: 'MNC Kapital Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BCAP-R',
    name: 'HMETD MNC Kapital Indonesia',
    full_name: 'HMETD MNC Kapital Indonesia',
    description: 'HMETD MNC Kapital Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BCAP-W',
    name: 'Waran Seri I MNC Kapital Indonesia',
    full_name: 'Waran Seri I MNC Kapital Indonesia',
    description: 'Waran Seri I MNC Kapital Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BCIC',
    name: 'Bank JTrust Indonesia Tbk.',
    full_name: 'Bank JTrust Indonesia Tbk.',
    description: 'Bank JTrust Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BCIP',
    name: 'Bumi Citra Permai Tbk.',
    full_name: 'Bumi Citra Permai Tbk.',
    description: 'Bumi Citra Permai Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BDKR',
    name: 'Berdikari Pondasi Perkasa Tbk.',
    full_name: 'Berdikari Pondasi Perkasa Tbk.',
    description: 'Berdikari Pondasi Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BDMN',
    name: 'Bank Danamon Indonesia Tbk.',
    full_name: 'Bank Danamon Indonesia Tbk.',
    description: 'Bank Danamon Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BEBS',
    name: 'Berkah Beton Sadaya Tbk.',
    full_name: 'Berkah Beton Sadaya Tbk.',
    description: 'Berkah Beton Sadaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BEEF',
    name: 'Estika Tata Tiara Tbk.',
    full_name: 'Estika Tata Tiara Tbk.',
    description: 'Estika Tata Tiara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BEEF-W',
    name: 'Waran Seri I Estika Tata Tiara',
    full_name: 'Waran Seri I Estika Tata Tiara',
    description: 'Waran Seri I Estika Tata Tiara',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BEER',
    name: 'Jobubu Jarum Minahasa Tbk.',
    full_name: 'Jobubu Jarum Minahasa Tbk.',
    description: 'Jobubu Jarum Minahasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BEKS',
    name: 'Bank Pembangunan Daerah Banten Tbk.',
    full_name: 'Bank Pembangunan Daerah Banten Tbk.',
    description: 'Bank Pembangunan Daerah Banten Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BEKS-R',
    name: 'HMETD Bank Pembangunan Daerah Banten',
    full_name: 'HMETD Bank Pembangunan Daerah Banten',
    description: 'HMETD Bank Pembangunan Daerah Banten',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BELI',
    name: 'Global Digital Niaga Tbk.',
    full_name: 'Global Digital Niaga Tbk.',
    description: 'Global Digital Niaga Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BELL',
    name: 'Trisula Textile Industries Tbk.',
    full_name: 'Trisula Textile Industries Tbk.',
    description: 'Trisula Textile Industries Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BESS',
    name: 'Batulicin Nusantara Maritim Tbk.',
    full_name: 'Batulicin Nusantara Maritim Tbk.',
    description: 'Batulicin Nusantara Maritim Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BESS-W',
    name: 'Waran Seri I Batulicin Nusantara Maritim',
    full_name: 'Waran Seri I Batulicin Nusantara Maritim',
    description: 'Waran Seri I Batulicin Nusantara Maritim',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BEST',
    name: 'Bekasi Fajar Industrial Estate Tbk.',
    full_name: 'Bekasi Fajar Industrial Estate Tbk.',
    description: 'Bekasi Fajar Industrial Estate Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BEST-W',
    name: 'Waran Seri I Bekasi Fajar Industrial Est',
    full_name: 'Waran Seri I Bekasi Fajar Industrial Est',
    description: 'Waran Seri I Bekasi Fajar Industrial Est',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BFIN',
    name: 'BFI Finance  Indonesia Tbk.',
    full_name: 'BFI Finance  Indonesia Tbk.',
    description: 'BFI Finance  Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BGTG',
    name: 'Bank Ganesha Tbk.',
    full_name: 'Bank Ganesha Tbk.',
    description: 'Bank Ganesha Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BHAT',
    name: 'Bhakti Multi Artha Tbk.',
    full_name: 'Bhakti Multi Artha Tbk.',
    description: 'Bhakti Multi Artha Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BHIT',
    name: 'MNC Asia Holding Tbk.',
    full_name: 'MNC Asia Holding Tbk.',
    description: 'MNC Asia Holding Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BHIT-R',
    name: 'HMETD MNC Investama',
    full_name: 'HMETD MNC Investama',
    description: 'HMETD MNC Investama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIKA',
    name: 'Binakarya Jaya Abadi Tbk.',
    full_name: 'Binakarya Jaya Abadi Tbk.',
    description: 'Binakarya Jaya Abadi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIKE',
    name: 'Sepeda Bersama Indonesia Tbk.',
    full_name: 'Sepeda Bersama Indonesia Tbk.',
    description: 'Sepeda Bersama Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIMA',
    name: 'Primarindo Asia Infrastructure Tbk.',
    full_name: 'Primarindo Asia Infrastructure Tbk.',
    description: 'Primarindo Asia Infrastructure Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BINA',
    name: 'Bank Ina Perdana Tbk.',
    full_name: 'Bank Ina Perdana Tbk.',
    description: 'Bank Ina Perdana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BINA-R',
    name: 'HMETD Bank Ina Perdana',
    full_name: 'HMETD Bank Ina Perdana',
    description: 'HMETD Bank Ina Perdana',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BINO',
    name: 'Perma Plasindo Tbk.',
    full_name: 'Perma Plasindo Tbk.',
    description: 'Perma Plasindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIPI',
    name: 'Astrindo Nusantara Infrastruktur Tbk.',
    full_name: 'Astrindo Nusantara Infrastruktur Tbk.',
    description: 'Astrindo Nusantara Infrastruktur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIPI-R',
    name: 'HMETD Astrindo Nusantara Infrastruktur T',
    full_name: 'HMETD Astrindo Nusantara Infrastruktur T',
    description: 'HMETD Astrindo Nusantara Infrastruktur T',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIPI-W',
    name: 'Waran Seri II Astrindo Nusantara Infrast',
    full_name: 'Waran Seri II Astrindo Nusantara Infrast',
    description: 'Waran Seri II Astrindo Nusantara Infrast',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIPP',
    name: 'Bhuwanatala Indah Permai Tbk.',
    full_name: 'Bhuwanatala Indah Permai Tbk.',
    description: 'Bhuwanatala Indah Permai Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIPP-W',
    name: 'Waran Seri III Bhuwanatala Indah Permai',
    full_name: 'Waran Seri III Bhuwanatala Indah Permai',
    description: 'Waran Seri III Bhuwanatala Indah Permai',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BIRD',
    name: 'Blue Bird Tbk.',
    full_name: 'Blue Bird Tbk.',
    description: 'Blue Bird Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BISI',
    name: 'BISI International Tbk.',
    full_name: 'BISI International Tbk.',
    description: 'BISI International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BJBR',
    name: 'Bank Pembangunan Daerah Jawa Barat dan Banten Tbk.',
    full_name: 'Bank Pembangunan Daerah Jawa Barat dan Banten Tbk.',
    description: 'Bank Pembangunan Daerah Jawa Barat dan Banten Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BJTM',
    name: 'Bank Pembangunan Daerah Jawa Timur Tbk.',
    full_name: 'Bank Pembangunan Daerah Jawa Timur Tbk.',
    description: 'Bank Pembangunan Daerah Jawa Timur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BKDP',
    name: 'Bukit Darmo Property Tbk',
    full_name: 'Bukit Darmo Property Tbk',
    description: 'Bukit Darmo Property Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BKSL',
    name: 'Sentul City Tbk.',
    full_name: 'Sentul City Tbk.',
    description: 'Sentul City Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BKSL-R',
    name: 'HMETD Sentul City',
    full_name: 'HMETD Sentul City',
    description: 'HMETD Sentul City',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BKSL-W',
    name: 'Waran Seri I Sentul City',
    full_name: 'Waran Seri I Sentul City',
    description: 'Waran Seri I Sentul City',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BKSW',
    name: 'Bank QNB Indonesia Tbk.',
    full_name: 'Bank QNB Indonesia Tbk.',
    description: 'Bank QNB Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BKSW-R',
    name: 'HMETD Bank QNB Indonesia',
    full_name: 'HMETD Bank QNB Indonesia',
    description: 'HMETD Bank QNB Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BLTA',
    name: 'Berlian Laju Tanker Tbk',
    full_name: 'Berlian Laju Tanker Tbk',
    description: 'Berlian Laju Tanker Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BLTZ',
    name: 'Graha Layar Prima Tbk.',
    full_name: 'Graha Layar Prima Tbk.',
    description: 'Graha Layar Prima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BLTZ-R',
    name: 'HMETD Graha Layar Prima',
    full_name: 'HMETD Graha Layar Prima',
    description: 'HMETD Graha Layar Prima',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BLUE',
    name: 'Berkah Prima Perkasa Tbk.',
    full_name: 'Berkah Prima Perkasa Tbk.',
    description: 'Berkah Prima Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BMAS',
    name: 'Bank Maspion Indonesia Tbk.',
    full_name: 'Bank Maspion Indonesia Tbk.',
    description: 'Bank Maspion Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BMAS-R',
    name: 'HMETD Bank Maspion Indonesia',
    full_name: 'HMETD Bank Maspion Indonesia',
    description: 'HMETD Bank Maspion Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BMBL',
    name: 'Lavender Bina Cendikia Tbk.',
    full_name: 'Lavender Bina Cendikia Tbk.',
    description: 'Lavender Bina Cendikia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BMHS',
    name: 'Bundamedik Tbk.',
    full_name: 'Bundamedik Tbk.',
    description: 'Bundamedik Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BMRI',
    name: 'Bank Mandiri (Persero) Tbk.',
    full_name: 'Bank Mandiri (Persero) Tbk.',
    description: 'Bank Mandiri (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BMSR',
    name: 'Bintang Mitra Semestaraya Tbk',
    full_name: 'Bintang Mitra Semestaraya Tbk',
    description: 'Bintang Mitra Semestaraya Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BMSR-W',
    name: 'Waran Seri I Bintang Mitra Sem',
    full_name: 'Waran Seri I Bintang Mitra Sem',
    description: 'Waran Seri I Bintang Mitra Sem',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BMTR',
    name: 'Global Mediacom Tbk.',
    full_name: 'Global Mediacom Tbk.',
    description: 'Global Mediacom Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BNBA',
    name: 'Bank Bumi Arta Tbk.',
    full_name: 'Bank Bumi Arta Tbk.',
    description: 'Bank Bumi Arta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BNBR',
    name: 'Bakrie & Brothers Tbk',
    full_name: 'Bakrie & Brothers Tbk',
    description: 'Bakrie & Brothers Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BNGA',
    name: 'Bank CIMB Niaga Tbk.',
    full_name: 'Bank CIMB Niaga Tbk.',
    description: 'Bank CIMB Niaga Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BNII',
    name: 'Bank Maybank Indonesia Tbk.',
    full_name: 'Bank Maybank Indonesia Tbk.',
    description: 'Bank Maybank Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BNII-R',
    name: 'HMETD Bank Maybank Indonesia',
    full_name: 'HMETD Bank Maybank Indonesia',
    description: 'HMETD Bank Maybank Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BNLI',
    name: 'Bank Permata Tbk.',
    full_name: 'Bank Permata Tbk.',
    description: 'Bank Permata Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BNLI-R',
    name: 'HMETD Bank Permata',
    full_name: 'HMETD Bank Permata',
    description: 'HMETD Bank Permata',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BOBA',
    name: 'Formosa Ingredient Factory Tbk.',
    full_name: 'Formosa Ingredient Factory Tbk.',
    description: 'Formosa Ingredient Factory Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BOGA',
    name: 'Bintang Oto Global Tbk.',
    full_name: 'Bintang Oto Global Tbk.',
    description: 'Bintang Oto Global Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BOGA-W',
    name: 'Waran Seri I Bintang Oto Global',
    full_name: 'Waran Seri I Bintang Oto Global',
    description: 'Waran Seri I Bintang Oto Global',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BOLA',
    name: 'Bali Bintang Sejahtera Tbk.',
    full_name: 'Bali Bintang Sejahtera Tbk.',
    description: 'Bali Bintang Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BOLT',
    name: 'Garuda Metalindo Tbk.',
    full_name: 'Garuda Metalindo Tbk.',
    description: 'Garuda Metalindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BOSS',
    name: 'Borneo Olah Sarana Sukses Tbk.',
    full_name: 'Borneo Olah Sarana Sukses Tbk.',
    description: 'Borneo Olah Sarana Sukses Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BPFI',
    name: 'Woori Finance Indonesia Tbk.',
    full_name: 'Woori Finance Indonesia Tbk.',
    description: 'Woori Finance Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BPFI-R',
    name: 'HMETD Batavia Prosperindo Finance',
    full_name: 'HMETD Batavia Prosperindo Finance',
    description: 'HMETD Batavia Prosperindo Finance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BPII',
    name: 'Batavia Prosperindo Internasional Tbk.',
    full_name: 'Batavia Prosperindo Internasional Tbk.',
    description: 'Batavia Prosperindo Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BPTR',
    name: 'Batavia Prosperindo Trans Tbk.',
    full_name: 'Batavia Prosperindo Trans Tbk.',
    description: 'Batavia Prosperindo Trans Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRAM',
    name: 'Indo Kordsa Tbk.',
    full_name: 'Indo Kordsa Tbk.',
    description: 'Indo Kordsa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRIS',
    name: 'Bank Syariah Indonesia Tbk.',
    full_name: 'Bank Syariah Indonesia Tbk.',
    description: 'Bank Syariah Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRMS',
    name: 'Bumi Resources Minerals Tbk.',
    full_name: 'Bumi Resources Minerals Tbk.',
    description: 'Bumi Resources Minerals Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRMS-R',
    name: 'HMETD Bumi Resources Minerals',
    full_name: 'HMETD Bumi Resources Minerals',
    description: 'HMETD Bumi Resources Minerals',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRMS-W',
    name: 'Waran Seri II Bumi Resources Minerals Tb',
    full_name: 'Waran Seri II Bumi Resources Minerals Tb',
    description: 'Waran Seri II Bumi Resources Minerals Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRNA',
    name: 'Berlina Tbk.',
    full_name: 'Berlina Tbk.',
    description: 'Berlina Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRNA-R',
    name: 'HMETD Berlina',
    full_name: 'HMETD Berlina',
    description: 'HMETD Berlina',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRPT',
    name: 'Barito Pacific Tbk.',
    full_name: 'Barito Pacific Tbk.',
    description: 'Barito Pacific Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRPT-R',
    name: 'HMETD Barito Pacific',
    full_name: 'HMETD Barito Pacific',
    description: 'HMETD Barito Pacific',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BRPT-W',
    name: 'Waran Seri I Barito Pacific',
    full_name: 'Waran Seri I Barito Pacific',
    description: 'Waran Seri I Barito Pacific',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSBK',
    name: 'Wulandari Bangun Laksana Tbk.',
    full_name: 'Wulandari Bangun Laksana Tbk.',
    description: 'Wulandari Bangun Laksana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSDE',
    name: 'Bumi Serpong Damai Tbk.',
    full_name: 'Bumi Serpong Damai Tbk.',
    description: 'Bumi Serpong Damai Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSIM',
    name: 'Bank Sinarmas Tbk.',
    full_name: 'Bank Sinarmas Tbk.',
    description: 'Bank Sinarmas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSIM-R',
    name: 'HMETD Bank Sinarmas',
    full_name: 'HMETD Bank Sinarmas',
    description: 'HMETD Bank Sinarmas',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSIM-W',
    name: 'Waran Seri I Bank Sinarmas',
    full_name: 'Waran Seri I Bank Sinarmas',
    description: 'Waran Seri I Bank Sinarmas',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSML',
    name: 'Bintang Samudera Mandiri Lines Tbk.',
    full_name: 'Bintang Samudera Mandiri Lines Tbk.',
    description: 'Bintang Samudera Mandiri Lines Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSSR',
    name: 'Baramulti Suksessarana Tbk.',
    full_name: 'Baramulti Suksessarana Tbk.',
    description: 'Baramulti Suksessarana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSWD',
    name: 'Bank Of India Indonesia Tbk.',
    full_name: 'Bank Of India Indonesia Tbk.',
    description: 'Bank Of India Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BSWD-R',
    name: 'HMETD Bank Of India Indonesia',
    full_name: 'HMETD Bank Of India Indonesia',
    description: 'HMETD Bank Of India Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BTEK',
    name: 'Bumi Teknokultura Unggul Tbk',
    full_name: 'Bumi Teknokultura Unggul Tbk',
    description: 'Bumi Teknokultura Unggul Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BTEK-R',
    name: 'HMETD Bumi Teknokultura Unggul',
    full_name: 'HMETD Bumi Teknokultura Unggul',
    description: 'HMETD Bumi Teknokultura Unggul',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BTEL',
    name: 'Bakrie Telecom Tbk.',
    full_name: 'Bakrie Telecom Tbk.',
    description: 'Bakrie Telecom Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BTON',
    name: 'Betonjaya Manunggal Tbk.',
    full_name: 'Betonjaya Manunggal Tbk.',
    description: 'Betonjaya Manunggal Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BTPN',
    name: 'Bank BTPN Tbk.',
    full_name: 'Bank BTPN Tbk.',
    description: 'Bank BTPN Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BTPS',
    name: 'Bank BTPN Syariah Tbk.',
    full_name: 'Bank BTPN Syariah Tbk.',
    description: 'Bank BTPN Syariah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BUAH',
    name: 'Segar Kumala Indonesia Tbk.',
    full_name: 'Segar Kumala Indonesia Tbk.',
    description: 'Segar Kumala Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BUDI',
    name: 'Budi Starch & Sweetener Tbk.',
    full_name: 'Budi Starch & Sweetener Tbk.',
    description: 'Budi Starch & Sweetener Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BUKA',
    name: 'Bukalapak.com Tbk.',
    full_name: 'Bukalapak.com Tbk.',
    description: 'Bukalapak.com Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BUKK',
    name: 'Bukaka Teknik Utama Tbk.',
    full_name: 'Bukaka Teknik Utama Tbk.',
    description: 'Bukaka Teknik Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BULL',
    name: 'Buana Lintas Lautan Tbk.',
    full_name: 'Buana Lintas Lautan Tbk.',
    description: 'Buana Lintas Lautan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BULL-R',
    name: 'HMETD Buana Lintas Lautan',
    full_name: 'HMETD Buana Lintas Lautan',
    description: 'HMETD Buana Lintas Lautan',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BULL-W',
    name: 'Waran Seri II Buana Lintas Lautan',
    full_name: 'Waran Seri II Buana Lintas Lautan',
    description: 'Waran Seri II Buana Lintas Lautan',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BUMI',
    name: 'Bumi Resources Tbk.',
    full_name: 'Bumi Resources Tbk.',
    description: 'Bumi Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BUMI-R',
    name: 'HMETD Seri A Bumi Resources',
    full_name: 'HMETD Seri A Bumi Resources',
    description: 'HMETD Seri A Bumi Resources',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BUVA',
    name: 'Bukit Uluwatu Villa Tbk.',
    full_name: 'Bukit Uluwatu Villa Tbk.',
    description: 'Bukit Uluwatu Villa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BVIC',
    name: 'Bank Victoria International Tbk.',
    full_name: 'Bank Victoria International Tbk.',
    description: 'Bank Victoria International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BWPT',
    name: 'Eagle High Plantations Tbk.',
    full_name: 'Eagle High Plantations Tbk.',
    description: 'Eagle High Plantations Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BYAN',
    name: 'Bayan Resources Tbk.',
    full_name: 'Bayan Resources Tbk.',
    description: 'Bayan Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CAKK',
    name: 'Cahayaputra Asa Keramik Tbk.',
    full_name: 'Cahayaputra Asa Keramik Tbk.',
    description: 'Cahayaputra Asa Keramik Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CAKK-W',
    name: 'Waran Seri I Cahayaputra Asa Keramik',
    full_name: 'Waran Seri I Cahayaputra Asa Keramik',
    description: 'Waran Seri I Cahayaputra Asa Keramik',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CAMP',
    name: 'Campina Ice Cream Industry Tbk.',
    full_name: 'Campina Ice Cream Industry Tbk.',
    description: 'Campina Ice Cream Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CANI',
    name: 'Capitol Nusantara Indonesia Tbk.',
    full_name: 'Capitol Nusantara Indonesia Tbk.',
    description: 'Capitol Nusantara Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CARE',
    name: 'Metro Healthcare Indonesia Tbk.',
    full_name: 'Metro Healthcare Indonesia Tbk.',
    description: 'Metro Healthcare Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CARS',
    name: 'Industri dan Perdagangan Bintraco Dharma Tbk.',
    full_name: 'Industri dan Perdagangan Bintraco Dharma Tbk.',
    description: 'Industri dan Perdagangan Bintraco Dharma Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CASA',
    name: 'Capital Financial Indonesia Tbk.',
    full_name: 'Capital Financial Indonesia Tbk.',
    description: 'Capital Financial Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CASA-R',
    name: 'HMETD Capital Financial Indonesia',
    full_name: 'HMETD Capital Financial Indonesia',
    description: 'HMETD Capital Financial Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CASA-W',
    name: 'Waran Seri I Capital Financial Indonesia',
    full_name: 'Waran Seri I Capital Financial Indonesia',
    description: 'Waran Seri I Capital Financial Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CASH',
    name: 'Cashlez Worldwide Indonesia Tbk.',
    full_name: 'Cashlez Worldwide Indonesia Tbk.',
    description: 'Cashlez Worldwide Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CASH-W',
    name: 'Waran Seri I Cashlez Worldwide Indonesia',
    full_name: 'Waran Seri I Cashlez Worldwide Indonesia',
    description: 'Waran Seri I Cashlez Worldwide Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CASS',
    name: 'Cardig Aero Services Tbk.',
    full_name: 'Cardig Aero Services Tbk.',
    description: 'Cardig Aero Services Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CBMF',
    name: 'Cahaya Bintang Medan Tbk.',
    full_name: 'Cahaya Bintang Medan Tbk.',
    description: 'Cahaya Bintang Medan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CBPE',
    name: 'Citra Buana Prasida Tbk.',
    full_name: 'Citra Buana Prasida Tbk.',
    description: 'Citra Buana Prasida Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CBRE',
    name: 'Cakra Buana Resources Energi Tbk.',
    full_name: 'Cakra Buana Resources Energi Tbk.',
    description: 'Cakra Buana Resources Energi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CBUT',
    name: 'Citra Borneo Utama Tbk.',
    full_name: 'Citra Borneo Utama Tbk.',
    description: 'Citra Borneo Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CCSI',
    name: 'Communication Cable Systems Indonesia Tbk.',
    full_name: 'Communication Cable Systems Indonesia Tbk.',
    description: 'Communication Cable Systems Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CEKA',
    name: 'Wilmar Cahaya Indonesia Tbk.',
    full_name: 'Wilmar Cahaya Indonesia Tbk.',
    description: 'Wilmar Cahaya Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CENT',
    name: 'Centratama Telekomunikasi Indonesia Tbk.',
    full_name: 'Centratama Telekomunikasi Indonesia Tbk.',
    description: 'Centratama Telekomunikasi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CENT-R',
    name: 'HMETD Centratama Telekomunikasi Indonesi',
    full_name: 'HMETD Centratama Telekomunikasi Indonesi',
    description: 'HMETD Centratama Telekomunikasi Indonesi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CFIN',
    name: 'Clipan Finance Indonesia Tbk.',
    full_name: 'Clipan Finance Indonesia Tbk.',
    description: 'Clipan Finance Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CFIN-W',
    name: 'Waran Seri V Clipan Finance Indonesia Tb',
    full_name: 'Waran Seri V Clipan Finance Indonesia Tb',
    description: 'Waran Seri V Clipan Finance Indonesia Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CHEM',
    name: 'Chemstar Indonesia Tbk.',
    full_name: 'Chemstar Indonesia Tbk.',
    description: 'Chemstar Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CHIP',
    name: 'Pelita Teknologi Global Tbk.',
    full_name: 'Pelita Teknologi Global Tbk.',
    description: 'Pelita Teknologi Global Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CINT',
    name: 'Chitose Internasional Tbk.',
    full_name: 'Chitose Internasional Tbk.',
    description: 'Chitose Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CITA',
    name: 'Cita Mineral Investindo Tbk.',
    full_name: 'Cita Mineral Investindo Tbk.',
    description: 'Cita Mineral Investindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CITA-R',
    name: 'HMETD Cita Mineral Investindo',
    full_name: 'HMETD Cita Mineral Investindo',
    description: 'HMETD Cita Mineral Investindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CITY',
    name: 'Natura City Developments Tbk.',
    full_name: 'Natura City Developments Tbk.',
    description: 'Natura City Developments Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CITY-W',
    name: 'Waran Seri I Natura City Developments Tb',
    full_name: 'Waran Seri I Natura City Developments Tb',
    description: 'Waran Seri I Natura City Developments Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CLAY',
    name: 'Citra Putra Realty Tbk.',
    full_name: 'Citra Putra Realty Tbk.',
    description: 'Citra Putra Realty Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CLEO',
    name: 'Sariguna Primatirta Tbk.',
    full_name: 'Sariguna Primatirta Tbk.',
    description: 'Sariguna Primatirta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CLPI',
    name: 'Colorpak Indonesia Tbk.',
    full_name: 'Colorpak Indonesia Tbk.',
    description: 'Colorpak Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CMNP',
    name: 'Citra Marga Nusaphala Persada Tbk.',
    full_name: 'Citra Marga Nusaphala Persada Tbk.',
    description: 'Citra Marga Nusaphala Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CMNP-R',
    name: 'HMETD Citra Marga Nusaphala Persada',
    full_name: 'HMETD Citra Marga Nusaphala Persada',
    description: 'HMETD Citra Marga Nusaphala Persada',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CMNP-W',
    name: 'Waran Seri I Citra Marga Nusaphala Persa',
    full_name: 'Waran Seri I Citra Marga Nusaphala Persa',
    description: 'Waran Seri I Citra Marga Nusaphala Persa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CMNT',
    name: 'Cemindo Gemilang Tbk.',
    full_name: 'Cemindo Gemilang Tbk.',
    description: 'Cemindo Gemilang Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CMPP',
    name: 'AirAsia Indonesia Tbk.',
    full_name: 'AirAsia Indonesia Tbk.',
    description: 'AirAsia Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CMPP-R',
    name: 'HMETD Rimau Multi Putra Pratama',
    full_name: 'HMETD Rimau Multi Putra Pratama',
    description: 'HMETD Rimau Multi Putra Pratama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CMRY',
    name: 'Cisarua Mountain Dairy Tbk.',
    full_name: 'Cisarua Mountain Dairy Tbk.',
    description: 'Cisarua Mountain Dairy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CNKO',
    name: 'Exploitasi Energi Indonesia Tbk.',
    full_name: 'Exploitasi Energi Indonesia Tbk.',
    description: 'Exploitasi Energi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CNTB',
    name: 'Saham Seri B ( Centex Tbk )',
    full_name: 'Saham Seri B ( Centex Tbk )',
    description: 'Saham Seri B ( Centex Tbk )',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CNTX',
    name: 'Century Textile Industry Tbk.',
    full_name: 'Century Textile Industry Tbk.',
    description: 'Century Textile Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'COAL',
    name: 'Black Diamond Resources Tbk.',
    full_name: 'Black Diamond Resources Tbk.',
    description: 'Black Diamond Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'COCO',
    name: 'Wahana Interfood Nusantara Tbk.',
    full_name: 'Wahana Interfood Nusantara Tbk.',
    description: 'Wahana Interfood Nusantara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'COCO-W',
    name: 'Waran Seri I Wahana Interfood Nusantara',
    full_name: 'Waran Seri I Wahana Interfood Nusantara',
    description: 'Waran Seri I Wahana Interfood Nusantara',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'COWL',
    name: 'Cowell Development Tbk.',
    full_name: 'Cowell Development Tbk.',
    description: 'Cowell Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CPIN',
    name: 'Charoen Pokphand Indonesia Tbk',
    full_name: 'Charoen Pokphand Indonesia Tbk',
    description: 'Charoen Pokphand Indonesia Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CPRI',
    name: 'Capri Nusa Satu Properti Tbk.',
    full_name: 'Capri Nusa Satu Properti Tbk.',
    description: 'Capri Nusa Satu Properti Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CPRI-W',
    name: 'Waran Seri I Capri Nusa Satu Properti Tb',
    full_name: 'Waran Seri I Capri Nusa Satu Properti Tb',
    description: 'Waran Seri I Capri Nusa Satu Properti Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CPRO',
    name: 'Central Proteina Prima Tbk.',
    full_name: 'Central Proteina Prima Tbk.',
    description: 'Central Proteina Prima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CRAB',
    name: 'Toba Surimi Industries Tbk.',
    full_name: 'Toba Surimi Industries Tbk.',
    description: 'Toba Surimi Industries Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CSAP',
    name: 'Catur Sentosa Adiprana Tbk.',
    full_name: 'Catur Sentosa Adiprana Tbk.',
    description: 'Catur Sentosa Adiprana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CSAP-R',
    name: 'HMETD Catur Sentosa Adiprana',
    full_name: 'HMETD Catur Sentosa Adiprana',
    description: 'HMETD Catur Sentosa Adiprana',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CSIS',
    name: 'Cahayasakti Investindo Sukses Tbk.',
    full_name: 'Cahayasakti Investindo Sukses Tbk.',
    description: 'Cahayasakti Investindo Sukses Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CSMI',
    name: 'Cipta Selera Murni Tbk.',
    full_name: 'Cipta Selera Murni Tbk.',
    description: 'Cipta Selera Murni Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CSRA',
    name: 'Cisadane Sawit Raya Tbk.',
    full_name: 'Cisadane Sawit Raya Tbk.',
    description: 'Cisadane Sawit Raya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CTBN',
    name: 'Citra Tubindo Tbk.',
    full_name: 'Citra Tubindo Tbk.',
    description: 'Citra Tubindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CTRA',
    name: 'Ciputra Development Tbk.',
    full_name: 'Ciputra Development Tbk.',
    description: 'Ciputra Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CTTH',
    name: 'Citatah Tbk.',
    full_name: 'Citatah Tbk.',
    description: 'Citatah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'CUAN',
    name: 'Petrindo Jaya Kreasi Tbk.',
    full_name: 'Petrindo Jaya Kreasi Tbk.',
    description: 'Petrindo Jaya Kreasi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DADA',
    name: 'Diamond Citra Propertindo Tbk.',
    full_name: 'Diamond Citra Propertindo Tbk.',
    description: 'Diamond Citra Propertindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DADA-W',
    name: 'Waran Seri I Diamond Citra Propertindo T',
    full_name: 'Waran Seri I Diamond Citra Propertindo T',
    description: 'Waran Seri I Diamond Citra Propertindo T',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DART',
    name: 'Duta Anggada Realty Tbk.',
    full_name: 'Duta Anggada Realty Tbk.',
    description: 'Duta Anggada Realty Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DAYA',
    name: 'Duta Intidaya Tbk.',
    full_name: 'Duta Intidaya Tbk.',
    description: 'Duta Intidaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DAYA-R',
    name: 'HMETD Duta Intidaya',
    full_name: 'HMETD Duta Intidaya',
    description: 'HMETD Duta Intidaya',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DCII',
    name: 'DCI Indonesia Tbk.',
    full_name: 'DCI Indonesia Tbk.',
    description: 'DCI Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DEAL',
    name: 'Dewata Freightinternational Tbk.',
    full_name: 'Dewata Freightinternational Tbk.',
    description: 'Dewata Freightinternational Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DEAL-W',
    name: 'Waran Seri I Dewata Freightinternational',
    full_name: 'Waran Seri I Dewata Freightinternational',
    description: 'Waran Seri I Dewata Freightinternational',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DEFI',
    name: 'Danasupra Erapacific Tbk.',
    full_name: 'Danasupra Erapacific Tbk.',
    description: 'Danasupra Erapacific Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DEFI-R',
    name: 'HMETD Danasupra Erapacific',
    full_name: 'HMETD Danasupra Erapacific',
    description: 'HMETD Danasupra Erapacific',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DEPO',
    name: 'Caturkarda Depo Bangunan Tbk.',
    full_name: 'Caturkarda Depo Bangunan Tbk.',
    description: 'Caturkarda Depo Bangunan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DEWA',
    name: 'Darma Henwa Tbk',
    full_name: 'Darma Henwa Tbk',
    description: 'Darma Henwa Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DEWI',
    name: 'Dewi Shri Farmindo Tbk.',
    full_name: 'Dewi Shri Farmindo Tbk.',
    description: 'Dewi Shri Farmindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DFAM',
    name: 'Dafam Property Indonesia Tbk.',
    full_name: 'Dafam Property Indonesia Tbk.',
    description: 'Dafam Property Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DFAM-W',
    name: 'Waran Seri I Dafam Property Indonesia Tb',
    full_name: 'Waran Seri I Dafam Property Indonesia Tb',
    description: 'Waran Seri I Dafam Property Indonesia Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DGIK',
    name: 'Nusa Konstruksi Enjiniring Tbk.',
    full_name: 'Nusa Konstruksi Enjiniring Tbk.',
    description: 'Nusa Konstruksi Enjiniring Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DGNS',
    name: 'Diagnos Laboratorium Utama Tbk.',
    full_name: 'Diagnos Laboratorium Utama Tbk.',
    description: 'Diagnos Laboratorium Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DIGI',
    name: 'Arkadia Digital Media Tbk.',
    full_name: 'Arkadia Digital Media Tbk.',
    description: 'Arkadia Digital Media Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DILD',
    name: 'Intiland Development Tbk.',
    full_name: 'Intiland Development Tbk.',
    description: 'Intiland Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DIVA',
    name: 'Distribusi Voucher Nusantara Tbk.',
    full_name: 'Distribusi Voucher Nusantara Tbk.',
    description: 'Distribusi Voucher Nusantara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DKFT',
    name: 'Central Omega Resources Tbk.',
    full_name: 'Central Omega Resources Tbk.',
    description: 'Central Omega Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DKFT-W',
    name: 'Waran Seri I Central Omega Resources',
    full_name: 'Waran Seri I Central Omega Resources',
    description: 'Waran Seri I Central Omega Resources',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DLTA',
    name: 'Delta Djakarta Tbk.',
    full_name: 'Delta Djakarta Tbk.',
    description: 'Delta Djakarta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DMAS',
    name: 'Puradelta Lestari Tbk.',
    full_name: 'Puradelta Lestari Tbk.',
    description: 'Puradelta Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DMMX',
    name: 'Digital Mediatama Maxima Tbk.',
    full_name: 'Digital Mediatama Maxima Tbk.',
    description: 'Digital Mediatama Maxima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DMND',
    name: 'Diamond Food Indonesia Tbk.',
    full_name: 'Diamond Food Indonesia Tbk.',
    description: 'Diamond Food Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DNAR',
    name: 'Bank Oke Indonesia Tbk.',
    full_name: 'Bank Oke Indonesia Tbk.',
    description: 'Bank Oke Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DNAR-R',
    name: 'HMETD Bank Oke Indonesia',
    full_name: 'HMETD Bank Oke Indonesia',
    description: 'HMETD Bank Oke Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DNET',
    name: 'Indoritel Makmur Internasional Tbk.',
    full_name: 'Indoritel Makmur Internasional Tbk.',
    description: 'Indoritel Makmur Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DOID',
    name: 'Delta Dunia Makmur Tbk.',
    full_name: 'Delta Dunia Makmur Tbk.',
    description: 'Delta Dunia Makmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DOOH',
    name: 'Era Media Sejahtera Tbk.',
    full_name: 'Era Media Sejahtera Tbk.',
    description: 'Era Media Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DPNS',
    name: 'Duta Pertiwi Nusantara Tbk.',
    full_name: 'Duta Pertiwi Nusantara Tbk.',
    description: 'Duta Pertiwi Nusantara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DPUM',
    name: 'Dua Putra Utama Makmur Tbk.',
    full_name: 'Dua Putra Utama Makmur Tbk.',
    description: 'Dua Putra Utama Makmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DRMA',
    name: 'Dharma Polimetal Tbk.',
    full_name: 'Dharma Polimetal Tbk.',
    description: 'Dharma Polimetal Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DSFI',
    name: 'Dharma Samudera Fishing Industries Tbk.',
    full_name: 'Dharma Samudera Fishing Industries Tbk.',
    description: 'Dharma Samudera Fishing Industries Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DSNG',
    name: 'Dharma Satya Nusantara Tbk.',
    full_name: 'Dharma Satya Nusantara Tbk.',
    description: 'Dharma Satya Nusantara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DSSA',
    name: 'Dian Swastatika Sentosa Tbk',
    full_name: 'Dian Swastatika Sentosa Tbk',
    description: 'Dian Swastatika Sentosa Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DUCK',
    name: 'Jaya Bersama Indo Tbk.',
    full_name: 'Jaya Bersama Indo Tbk.',
    description: 'Jaya Bersama Indo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DUTI',
    name: 'Duta Pertiwi Tbk',
    full_name: 'Duta Pertiwi Tbk',
    description: 'Duta Pertiwi Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DVLA',
    name: 'Darya-Varia Laboratoria Tbk.',
    full_name: 'Darya-Varia Laboratoria Tbk.',
    description: 'Darya-Varia Laboratoria Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DWGL',
    name: 'Dwi Guna Laksana Tbk.',
    full_name: 'Dwi Guna Laksana Tbk.',
    description: 'Dwi Guna Laksana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DWGL-W',
    name: 'Waran Seri I Dwi Guna Laksana',
    full_name: 'Waran Seri I Dwi Guna Laksana',
    description: 'Waran Seri I Dwi Guna Laksana',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'DYAN',
    name: 'Dyandra Media International Tbk.',
    full_name: 'Dyandra Media International Tbk.',
    description: 'Dyandra Media International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EAST',
    name: 'Eastparc Hotel Tbk.',
    full_name: 'Eastparc Hotel Tbk.',
    description: 'Eastparc Hotel Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EAST-W',
    name: 'Waran Seri I Eastparc Hotel',
    full_name: 'Waran Seri I Eastparc Hotel',
    description: 'Waran Seri I Eastparc Hotel',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ECII',
    name: 'Electronic City Indonesia Tbk.',
    full_name: 'Electronic City Indonesia Tbk.',
    description: 'Electronic City Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EDGE',
    name: 'Indointernet Tbk.',
    full_name: 'Indointernet Tbk.',
    description: 'Indointernet Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EKAD',
    name: 'Ekadharma International Tbk.',
    full_name: 'Ekadharma International Tbk.',
    description: 'Ekadharma International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ELIT',
    name: 'Data Sinergitama Jaya Tbk.',
    full_name: 'Data Sinergitama Jaya Tbk.',
    description: 'Data Sinergitama Jaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ELPI',
    name: 'Pelayaran Nasional Ekalya Purnamasari Tbk.',
    full_name: 'Pelayaran Nasional Ekalya Purnamasari Tbk.',
    description: 'Pelayaran Nasional Ekalya Purnamasari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ELSA',
    name: 'Elnusa Tbk.',
    full_name: 'Elnusa Tbk.',
    description: 'Elnusa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ELTY',
    name: 'Bakrieland Development Tbk.',
    full_name: 'Bakrieland Development Tbk.',
    description: 'Bakrieland Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EMDE',
    name: 'Megapolitan Developments Tbk.',
    full_name: 'Megapolitan Developments Tbk.',
    description: 'Megapolitan Developments Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EMTK',
    name: 'Elang Mahkota Teknologi Tbk.',
    full_name: 'Elang Mahkota Teknologi Tbk.',
    description: 'Elang Mahkota Teknologi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ENAK',
    name: 'Champ Resto Indonesia Tbk.',
    full_name: 'Champ Resto Indonesia Tbk.',
    description: 'Champ Resto Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ENRG',
    name: 'Energi Mega Persada Tbk.',
    full_name: 'Energi Mega Persada Tbk.',
    description: 'Energi Mega Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ENRG-R',
    name: 'HMETD Energi Mega Persada',
    full_name: 'HMETD Energi Mega Persada',
    description: 'HMETD Energi Mega Persada',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ENVY',
    name: 'Envy Technologies Indonesia Tbk.',
    full_name: 'Envy Technologies Indonesia Tbk.',
    description: 'Envy Technologies Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ENZO',
    name: 'Morenzo Abadi Perkasa Tbk.',
    full_name: 'Morenzo Abadi Perkasa Tbk.',
    description: 'Morenzo Abadi Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ENZO-W',
    name: 'Waran Seri I Morenzo Abadi Perkasa',
    full_name: 'Waran Seri I Morenzo Abadi Perkasa',
    description: 'Waran Seri I Morenzo Abadi Perkasa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EPAC',
    name: 'Megalestari Epack Sentosaraya Tbk.',
    full_name: 'Megalestari Epack Sentosaraya Tbk.',
    description: 'Megalestari Epack Sentosaraya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EPMT',
    name: 'Enseval Putera Megatrading Tbk.',
    full_name: 'Enseval Putera Megatrading Tbk.',
    description: 'Enseval Putera Megatrading Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ERAA',
    name: 'Erajaya Swasembada Tbk.',
    full_name: 'Erajaya Swasembada Tbk.',
    description: 'Erajaya Swasembada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ERTX',
    name: 'Eratex Djaja Tbk.',
    full_name: 'Eratex Djaja Tbk.',
    description: 'Eratex Djaja Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ESIP',
    name: 'Sinergi Inti Plastindo Tbk.',
    full_name: 'Sinergi Inti Plastindo Tbk.',
    description: 'Sinergi Inti Plastindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ESIP-W',
    name: 'Waran Seri I Sinergi Inti Plastindo',
    full_name: 'Waran Seri I Sinergi Inti Plastindo',
    description: 'Waran Seri I Sinergi Inti Plastindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ESSA',
    name: 'Surya Esa Perkasa Tbk.',
    full_name: 'Surya Esa Perkasa Tbk.',
    description: 'Surya Esa Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ESSA-R',
    name: 'HMETD Surya Esa Perkasa',
    full_name: 'HMETD Surya Esa Perkasa',
    description: 'HMETD Surya Esa Perkasa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ESTA',
    name: 'Esta Multi Usaha Tbk.',
    full_name: 'Esta Multi Usaha Tbk.',
    description: 'Esta Multi Usaha Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ESTI',
    name: 'Ever Shine Tex Tbk.',
    full_name: 'Ever Shine Tex Tbk.',
    description: 'Ever Shine Tex Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ETWA',
    name: 'Eterindo Wahanatama Tbk',
    full_name: 'Eterindo Wahanatama Tbk',
    description: 'Eterindo Wahanatama Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EURO',
    name: 'Estee Gold Feet Tbk.',
    full_name: 'Estee Gold Feet Tbk.',
    description: 'Estee Gold Feet Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EXCL',
    name: 'XL Axiata Tbk.',
    full_name: 'XL Axiata Tbk.',
    description: 'XL Axiata Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'EXCL-R',
    name: 'HMETD XL Axiata',
    full_name: 'HMETD XL Axiata',
    description: 'HMETD XL Axiata',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FAPA',
    name: 'FAP Agri Tbk.',
    full_name: 'FAP Agri Tbk.',
    description: 'FAP Agri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FAST',
    name: 'Fast Food Indonesia Tbk.',
    full_name: 'Fast Food Indonesia Tbk.',
    description: 'Fast Food Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FASW',
    name: 'Fajar Surya Wisesa Tbk.',
    full_name: 'Fajar Surya Wisesa Tbk.',
    description: 'Fajar Surya Wisesa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FILM',
    name: 'MD Pictures Tbk.',
    full_name: 'MD Pictures Tbk.',
    description: 'MD Pictures Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FIMP',
    name: 'Fimperkasa Utama Tbk.',
    full_name: 'Fimperkasa Utama Tbk.',
    description: 'Fimperkasa Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FIMP-W',
    name: 'Waran Seri I Fimperkasa Utama',
    full_name: 'Waran Seri I Fimperkasa Utama',
    description: 'Waran Seri I Fimperkasa Utama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FINN',
    name: 'PT First Indo American Leasing Tbk.',
    full_name: 'PT First Indo American Leasing Tbk.',
    description: 'PT First Indo American Leasing Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FIRE',
    name: 'Alfa Energi Investama Tbk.',
    full_name: 'Alfa Energi Investama Tbk.',
    description: 'Alfa Energi Investama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FIRE-W',
    name: 'Waran Seri I Alfa Energi Investama',
    full_name: 'Waran Seri I Alfa Energi Investama',
    description: 'Waran Seri I Alfa Energi Investama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FISH',
    name: 'FKS Multi Agro Tbk.',
    full_name: 'FKS Multi Agro Tbk.',
    description: 'FKS Multi Agro Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FITT',
    name: 'Hotel Fitra International Tbk.',
    full_name: 'Hotel Fitra International Tbk.',
    description: 'Hotel Fitra International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FITT-W',
    name: 'Waran Seri I Hotel Fitra International T',
    full_name: 'Waran Seri I Hotel Fitra International T',
    description: 'Waran Seri I Hotel Fitra International T',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FLMC',
    name: 'Falmaco Nonwoven Industri Tbk.',
    full_name: 'Falmaco Nonwoven Industri Tbk.',
    description: 'Falmaco Nonwoven Industri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FMII',
    name: 'Fortune Mate Indonesia Tbk',
    full_name: 'Fortune Mate Indonesia Tbk',
    description: 'Fortune Mate Indonesia Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FOOD',
    name: 'Sentra Food Indonesia Tbk.',
    full_name: 'Sentra Food Indonesia Tbk.',
    description: 'Sentra Food Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FORU',
    name: 'Fortune Indonesia Tbk',
    full_name: 'Fortune Indonesia Tbk',
    description: 'Fortune Indonesia Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FORZ',
    name: 'Forza Land Indonesia Tbk.',
    full_name: 'Forza Land Indonesia Tbk.',
    description: 'Forza Land Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FORZ-W',
    name: 'Waran Seri I Forza Land Indonesia',
    full_name: 'Waran Seri I Forza Land Indonesia',
    description: 'Waran Seri I Forza Land Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FPNI',
    name: 'Lotte Chemical Titan Tbk.',
    full_name: 'Lotte Chemical Titan Tbk.',
    description: 'Lotte Chemical Titan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FREN',
    name: 'Smartfren Telecom Tbk.',
    full_name: 'Smartfren Telecom Tbk.',
    description: 'Smartfren Telecom Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FREN-R',
    name: 'HMETD Smartfren Telecom',
    full_name: 'HMETD Smartfren Telecom',
    description: 'HMETD Smartfren Telecom',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FREN-W',
    name: 'Waran Seri II Smartfren Telecom',
    full_name: 'Waran Seri II Smartfren Telecom',
    description: 'Waran Seri II Smartfren Telecom',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FUJI',
    name: 'Fuji Finance Indonesia Tbk.',
    full_name: 'Fuji Finance Indonesia Tbk.',
    description: 'Fuji Finance Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FUTR',
    name: 'Lini Imaji Kreasi Ekosistem Tbk.',
    full_name: 'Lini Imaji Kreasi Ekosistem Tbk.',
    description: 'Lini Imaji Kreasi Ekosistem Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'FWCT',
    name: 'Wijaya Cahaya Timber Tbk.',
    full_name: 'Wijaya Cahaya Timber Tbk.',
    description: 'Wijaya Cahaya Timber Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GAMA',
    name: 'Aksara Global Development Tbk.',
    full_name: 'Aksara Global Development Tbk.',
    description: 'Aksara Global Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GAMA-W',
    name: 'Waran Seri I Gading Development',
    full_name: 'Waran Seri I Gading Development',
    description: 'Waran Seri I Gading Development',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GDST',
    name: 'Gunawan Dianjaya Steel Tbk.',
    full_name: 'Gunawan Dianjaya Steel Tbk.',
    description: 'Gunawan Dianjaya Steel Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GDYR',
    name: 'Goodyear Indonesia Tbk.',
    full_name: 'Goodyear Indonesia Tbk.',
    description: 'Goodyear Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GEMA',
    name: 'Gema Grahasarana Tbk.',
    full_name: 'Gema Grahasarana Tbk.',
    description: 'Gema Grahasarana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GEMS',
    name: 'Golden Energy Mines Tbk.',
    full_name: 'Golden Energy Mines Tbk.',
    description: 'Golden Energy Mines Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GGRM',
    name: 'Gudang Garam Tbk.',
    full_name: 'Gudang Garam Tbk.',
    description: 'Gudang Garam Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GGRP',
    name: 'Gunung Raja Paksi Tbk.',
    full_name: 'Gunung Raja Paksi Tbk.',
    description: 'Gunung Raja Paksi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GHON',
    name: 'Gihon Telekomunikasi Indonesia Tbk.',
    full_name: 'Gihon Telekomunikasi Indonesia Tbk.',
    description: 'Gihon Telekomunikasi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GIAA',
    name: 'Garuda Indonesia (Persero) Tbk.',
    full_name: 'Garuda Indonesia (Persero) Tbk.',
    description: 'Garuda Indonesia (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GJTL',
    name: 'Gajah Tunggal Tbk.',
    full_name: 'Gajah Tunggal Tbk.',
    description: 'Gajah Tunggal Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GLOB',
    name: 'Globe Kita Terang Tbk.',
    full_name: 'Globe Kita Terang Tbk.',
    description: 'Globe Kita Terang Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GLVA',
    name: 'Galva Technologies Tbk.',
    full_name: 'Galva Technologies Tbk.',
    description: 'Galva Technologies Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GMFI',
    name: 'Garuda Maintenance Facility Aero Asia Tbk.',
    full_name: 'Garuda Maintenance Facility Aero Asia Tbk.',
    description: 'Garuda Maintenance Facility Aero Asia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GMTD',
    name: 'Gowa Makassar Tourism Development Tbk.',
    full_name: 'Gowa Makassar Tourism Development Tbk.',
    description: 'Gowa Makassar Tourism Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GOLD',
    name: 'Visi Telekomunikasi Infrastruktur Tbk.',
    full_name: 'Visi Telekomunikasi Infrastruktur Tbk.',
    description: 'Visi Telekomunikasi Infrastruktur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GOLD-R',
    name: 'HMETD Visi Telekomunikasi Infrastruktur',
    full_name: 'HMETD Visi Telekomunikasi Infrastruktur',
    description: 'HMETD Visi Telekomunikasi Infrastruktur',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GOLL',
    name: 'Golden Plantation Tbk.',
    full_name: 'Golden Plantation Tbk.',
    description: 'Golden Plantation Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GOLL-W',
    name: 'Waran Seri I Golden Plantation',
    full_name: 'Waran Seri I Golden Plantation',
    description: 'Waran Seri I Golden Plantation',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GOOD',
    name: 'Garudafood Putra Putri Jaya Tbk.',
    full_name: 'Garudafood Putra Putri Jaya Tbk.',
    description: 'Garudafood Putra Putri Jaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GOTO',
    name: 'GoTo Gojek Tokopedia Tbk.',
    full_name: 'GoTo Gojek Tokopedia Tbk.',
    description: 'GoTo Gojek Tokopedia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GPRA',
    name: 'Perdana Gapuraprima Tbk.',
    full_name: 'Perdana Gapuraprima Tbk.',
    description: 'Perdana Gapuraprima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GPSO',
    name: 'Geoprima Solusi Tbk.',
    full_name: 'Geoprima Solusi Tbk.',
    description: 'Geoprima Solusi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GPSO-W',
    name: 'Waran Seri I Geoprima Solusi',
    full_name: 'Waran Seri I Geoprima Solusi',
    description: 'Waran Seri I Geoprima Solusi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GREN',
    name: 'Evergreen Invesco Tbk',
    full_name: 'Evergreen Invesco Tbk',
    description: 'Evergreen Invesco Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GREN-W',
    name: 'Waran Seri I Evergreen Invesco',
    full_name: 'Waran Seri I Evergreen Invesco',
    description: 'Waran Seri I Evergreen Invesco',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GSMF',
    name: 'Equity Development Investment Tbk.',
    full_name: 'Equity Development Investment Tbk.',
    description: 'Equity Development Investment Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GSMF-R',
    name: 'HMETD Equity Development Investment',
    full_name: 'HMETD Equity Development Investment',
    description: 'HMETD Equity Development Investment',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GTBO',
    name: 'Garda Tujuh Buana Tbk',
    full_name: 'Garda Tujuh Buana Tbk',
    description: 'Garda Tujuh Buana Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GTRA',
    name: 'Grahaprima Suksesmandiri Tbk.',
    full_name: 'Grahaprima Suksesmandiri Tbk.',
    description: 'Grahaprima Suksesmandiri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GTSI',
    name: 'GTS Internasional Tbk.',
    full_name: 'GTS Internasional Tbk.',
    description: 'GTS Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GULA',
    name: 'Aman Agrindo Tbk.',
    full_name: 'Aman Agrindo Tbk.',
    description: 'Aman Agrindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GWSA',
    name: 'Greenwood Sejahtera Tbk.',
    full_name: 'Greenwood Sejahtera Tbk.',
    description: 'Greenwood Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'GZCO',
    name: 'Gozco Plantations Tbk.',
    full_name: 'Gozco Plantations Tbk.',
    description: 'Gozco Plantations Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HADE',
    name: 'Himalaya Energi Perkasa Tbk.',
    full_name: 'Himalaya Energi Perkasa Tbk.',
    description: 'Himalaya Energi Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HAIS',
    name: 'Hasnur Internasional Shipping Tbk.',
    full_name: 'Hasnur Internasional Shipping Tbk.',
    description: 'Hasnur Internasional Shipping Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HAJJ',
    name: 'Arsy Buana Travelindo Tbk.',
    full_name: 'Arsy Buana Travelindo Tbk.',
    description: 'Arsy Buana Travelindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HALO',
    name: 'Haloni Jane Tbk.',
    full_name: 'Haloni Jane Tbk.',
    description: 'Haloni Jane Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HATM',
    name: 'Habco Trans Maritima Tbk.',
    full_name: 'Habco Trans Maritima Tbk.',
    description: 'Habco Trans Maritima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HDFA',
    name: 'Radana Bhaskara Finance Tbk.',
    full_name: 'Radana Bhaskara Finance Tbk.',
    description: 'Radana Bhaskara Finance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HDFA-R',
    name: 'HMETD Radana Bhaskara Finance',
    full_name: 'HMETD Radana Bhaskara Finance',
    description: 'HMETD Radana Bhaskara Finance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HDIT',
    name: 'Hensel Davest Indonesia Tbk.',
    full_name: 'Hensel Davest Indonesia Tbk.',
    description: 'Hensel Davest Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HDTX',
    name: 'Panasia Indo Resources Tbk.',
    full_name: 'Panasia Indo Resources Tbk.',
    description: 'Panasia Indo Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HEAL',
    name: 'Medikaloka Hermina Tbk.',
    full_name: 'Medikaloka Hermina Tbk.',
    description: 'Medikaloka Hermina Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HELI',
    name: 'Jaya Trishindo Tbk.',
    full_name: 'Jaya Trishindo Tbk.',
    description: 'Jaya Trishindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HELI-W',
    name: 'Waran Seri I Jaya Trishindo',
    full_name: 'Waran Seri I Jaya Trishindo',
    description: 'Waran Seri I Jaya Trishindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HERO',
    name: 'Hero Supermarket Tbk.',
    full_name: 'Hero Supermarket Tbk.',
    description: 'Hero Supermarket Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HEXA',
    name: 'Hexindo Adiperkasa Tbk.',
    full_name: 'Hexindo Adiperkasa Tbk.',
    description: 'Hexindo Adiperkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HILL',
    name: 'Hillcon Tbk.',
    full_name: 'Hillcon Tbk.',
    description: 'Hillcon Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HITS',
    name: 'Humpuss Intermoda Transportasi Tbk.',
    full_name: 'Humpuss Intermoda Transportasi Tbk.',
    description: 'Humpuss Intermoda Transportasi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HKMU',
    name: 'HK Metals Utama Tbk.',
    full_name: 'HK Metals Utama Tbk.',
    description: 'HK Metals Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HMSP',
    name: 'H.M. Sampoerna Tbk.',
    full_name: 'H.M. Sampoerna Tbk.',
    description: 'H.M. Sampoerna Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HMSP-R',
    name: 'HMETD HM Sampoerna.',
    full_name: 'HMETD HM Sampoerna.',
    description: 'HMETD HM Sampoerna.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOKI',
    name: 'Buyung Poetra Sembada Tbk.',
    full_name: 'Buyung Poetra Sembada Tbk.',
    description: 'Buyung Poetra Sembada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOKI-W',
    name: 'Waran Seri I Buyung Poetra Sembada',
    full_name: 'Waran Seri I Buyung Poetra Sembada',
    description: 'Waran Seri I Buyung Poetra Sembada',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOME',
    name: 'Hotel Mandarine Regency Tbk.',
    full_name: 'Hotel Mandarine Regency Tbk.',
    description: 'Hotel Mandarine Regency Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOME-R',
    name: 'HMETD Hotel Mandarine Regency',
    full_name: 'HMETD Hotel Mandarine Regency',
    description: 'HMETD Hotel Mandarine Regency',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOME-W',
    name: 'Waran Seri II Hotel Mandarine Regency Tb',
    full_name: 'Waran Seri II Hotel Mandarine Regency Tb',
    description: 'Waran Seri II Hotel Mandarine Regency Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOMI',
    name: 'Grand House Mulia Tbk.',
    full_name: 'Grand House Mulia Tbk.',
    description: 'Grand House Mulia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOPE',
    name: 'Harapan Duta Pertiwi Tbk.',
    full_name: 'Harapan Duta Pertiwi Tbk.',
    description: 'Harapan Duta Pertiwi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOPE-W',
    name: 'Waran Seri I Harapan Duta Pertiwi',
    full_name: 'Waran Seri I Harapan Duta Pertiwi',
    description: 'Waran Seri I Harapan Duta Pertiwi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOTL',
    name: 'Saraswati Griya Lestari Tbk.',
    full_name: 'Saraswati Griya Lestari Tbk.',
    description: 'Saraswati Griya Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HOTL-W',
    name: 'Waran Seri I Saraswati Griya Lestari',
    full_name: 'Waran Seri I Saraswati Griya Lestari',
    description: 'Waran Seri I Saraswati Griya Lestari',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HRME',
    name: 'Menteng Heritage Realty Tbk.',
    full_name: 'Menteng Heritage Realty Tbk.',
    description: 'Menteng Heritage Realty Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HRTA',
    name: 'Hartadinata Abadi Tbk.',
    full_name: 'Hartadinata Abadi Tbk.',
    description: 'Hartadinata Abadi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'HRUM',
    name: 'Harum Energy Tbk.',
    full_name: 'Harum Energy Tbk.',
    description: 'Harum Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IATA',
    name: 'MNC Energy Investments Tbk.',
    full_name: 'MNC Energy Investments Tbk.',
    description: 'MNC Energy Investments Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IBFN',
    name: 'Intan Baru Prana Tbk.',
    full_name: 'Intan Baru Prana Tbk.',
    description: 'Intan Baru Prana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IBFN-R',
    name: 'HMETD Intan Baruprana Finance',
    full_name: 'HMETD Intan Baruprana Finance',
    description: 'HMETD Intan Baruprana Finance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IBFN-W',
    name: 'Waran Seri I Intan Baruprana Finance',
    full_name: 'Waran Seri I Intan Baruprana Finance',
    description: 'Waran Seri I Intan Baruprana Finance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IBOS',
    name: 'Indo Boga Sukses Tbk.',
    full_name: 'Indo Boga Sukses Tbk.',
    description: 'Indo Boga Sukses Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IBST',
    name: 'Inti Bangun Sejahtera Tbk.',
    full_name: 'Inti Bangun Sejahtera Tbk.',
    description: 'Inti Bangun Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ICBP',
    name: 'Indofood CBP Sukses Makmur Tbk.',
    full_name: 'Indofood CBP Sukses Makmur Tbk.',
    description: 'Indofood CBP Sukses Makmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ICON',
    name: 'Island Concepts Indonesia Tbk.',
    full_name: 'Island Concepts Indonesia Tbk.',
    description: 'Island Concepts Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDEA',
    name: 'Idea Indonesia Akademi Tbk.',
    full_name: 'Idea Indonesia Akademi Tbk.',
    description: 'Idea Indonesia Akademi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDPR',
    name: 'Indonesia Pondasi Raya Tbk.',
    full_name: 'Indonesia Pondasi Raya Tbk.',
    description: 'Indonesia Pondasi Raya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IFII',
    name: 'Indonesia Fibreboard Industry Tbk.',
    full_name: 'Indonesia Fibreboard Industry Tbk.',
    description: 'Indonesia Fibreboard Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IFSH',
    name: 'Ifishdeco Tbk.',
    full_name: 'Ifishdeco Tbk.',
    description: 'Ifishdeco Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IGAR',
    name: 'Champion Pacific Indonesia Tbk.',
    full_name: 'Champion Pacific Indonesia Tbk.',
    description: 'Champion Pacific Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IIKP',
    name: 'Inti Agri Resources Tbk',
    full_name: 'Inti Agri Resources Tbk',
    description: 'Inti Agri Resources Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IKAI',
    name: 'Intikeramik Alamasri Industri Tbk.',
    full_name: 'Intikeramik Alamasri Industri Tbk.',
    description: 'Intikeramik Alamasri Industri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IKAI-R',
    name: 'HMETD Intikeramik Alamasri Industri',
    full_name: 'HMETD Intikeramik Alamasri Industri',
    description: 'HMETD Intikeramik Alamasri Industri',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IKAN',
    name: 'Era Mandiri Cemerlang Tbk.',
    full_name: 'Era Mandiri Cemerlang Tbk.',
    description: 'Era Mandiri Cemerlang Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IKBI',
    name: 'Sumi Indo Kabel Tbk.',
    full_name: 'Sumi Indo Kabel Tbk.',
    description: 'Sumi Indo Kabel Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IMAS',
    name: 'Indomobil Sukses Internasional Tbk.',
    full_name: 'Indomobil Sukses Internasional Tbk.',
    description: 'Indomobil Sukses Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IMAS-R',
    name: 'HMETD Indomobil Sukses Internasional',
    full_name: 'HMETD Indomobil Sukses Internasional',
    description: 'HMETD Indomobil Sukses Internasional',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IMJS',
    name: 'Indomobil Multi Jasa Tbk.',
    full_name: 'Indomobil Multi Jasa Tbk.',
    description: 'Indomobil Multi Jasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IMJS-R',
    name: 'HMETD Indomobil Multi Jasa',
    full_name: 'HMETD Indomobil Multi Jasa',
    description: 'HMETD Indomobil Multi Jasa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IMPC',
    name: 'Impack Pratama Industri Tbk.',
    full_name: 'Impack Pratama Industri Tbk.',
    description: 'Impack Pratama Industri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INAF',
    name: 'Indofarma Tbk.',
    full_name: 'Indofarma Tbk.',
    description: 'Indofarma Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INAI',
    name: 'Indal Aluminium Industry Tbk.',
    full_name: 'Indal Aluminium Industry Tbk.',
    description: 'Indal Aluminium Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INCF',
    name: 'Indo Komoditi Korpora Tbk.',
    full_name: 'Indo Komoditi Korpora Tbk.',
    description: 'Indo Komoditi Korpora Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INCI',
    name: 'Intanwijaya Internasional Tbk',
    full_name: 'Intanwijaya Internasional Tbk',
    description: 'Intanwijaya Internasional Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INCO',
    name: 'Vale Indonesia Tbk.',
    full_name: 'Vale Indonesia Tbk.',
    description: 'Vale Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INDF',
    name: 'Indofood Sukses Makmur Tbk.',
    full_name: 'Indofood Sukses Makmur Tbk.',
    description: 'Indofood Sukses Makmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INDO',
    name: 'Royalindo Investa Wijaya Tbk.',
    full_name: 'Royalindo Investa Wijaya Tbk.',
    description: 'Royalindo Investa Wijaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INDO-W',
    name: 'Waran Seri I Royalindo Investa Wijaya Tb',
    full_name: 'Waran Seri I Royalindo Investa Wijaya Tb',
    description: 'Waran Seri I Royalindo Investa Wijaya Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INDR',
    name: 'Indo-Rama Synthetics Tbk.',
    full_name: 'Indo-Rama Synthetics Tbk.',
    description: 'Indo-Rama Synthetics Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INDS',
    name: 'Indospring Tbk.',
    full_name: 'Indospring Tbk.',
    description: 'Indospring Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INDX',
    name: 'Tanah Laut Tbk',
    full_name: 'Tanah Laut Tbk',
    description: 'Tanah Laut Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INDY',
    name: 'Indika Energy Tbk.',
    full_name: 'Indika Energy Tbk.',
    description: 'Indika Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INET-R',
    name: 'HMETD INET Lestari Jaya',
    full_name: 'HMETD INET Lestari Jaya',
    description: 'HMETD INET Lestari Jaya',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INET-W',
    name: 'Waran Seri XV INET Lestari Jaya',
    full_name: 'Waran Seri XV INET Lestari Jaya',
    description: 'Waran Seri XV INET Lestari Jaya',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INKP',
    name: 'Indah Kiat Pulp & Paper Tbk.',
    full_name: 'Indah Kiat Pulp & Paper Tbk.',
    description: 'Indah Kiat Pulp & Paper Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INOV',
    name: 'Inocycle Technology Group Tbk.',
    full_name: 'Inocycle Technology Group Tbk.',
    description: 'Inocycle Technology Group Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INPC',
    name: 'Bank Artha Graha Internasional Tbk.',
    full_name: 'Bank Artha Graha Internasional Tbk.',
    description: 'Bank Artha Graha Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INPC-R',
    name: 'HMETD Bank Artha Graha Internasional',
    full_name: 'HMETD Bank Artha Graha Internasional',
    description: 'HMETD Bank Artha Graha Internasional',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INPC-W',
    name: 'Waran Seri I Bank Artha Graha Internasio',
    full_name: 'Waran Seri I Bank Artha Graha Internasio',
    description: 'Waran Seri I Bank Artha Graha Internasio',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INPP',
    name: 'Indonesian Paradise Property Tbk.',
    full_name: 'Indonesian Paradise Property Tbk.',
    description: 'Indonesian Paradise Property Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INPS',
    name: 'Indah Prakasa Sentosa Tbk.',
    full_name: 'Indah Prakasa Sentosa Tbk.',
    description: 'Indah Prakasa Sentosa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INRU',
    name: 'Toba Pulp Lestari Tbk.',
    full_name: 'Toba Pulp Lestari Tbk.',
    description: 'Toba Pulp Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INTA',
    name: 'Intraco Penta Tbk.',
    full_name: 'Intraco Penta Tbk.',
    description: 'Intraco Penta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INTA-R',
    name: 'HMETD Intraco Penta',
    full_name: 'HMETD Intraco Penta',
    description: 'HMETD Intraco Penta',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INTA-W',
    name: 'Waran Seri I Intraco Penta',
    full_name: 'Waran Seri I Intraco Penta',
    description: 'Waran Seri I Intraco Penta',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INTD',
    name: 'Inter Delta Tbk',
    full_name: 'Inter Delta Tbk',
    description: 'Inter Delta Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INTP',
    name: 'Indocement Tunggal Prakarsa Tbk.',
    full_name: 'Indocement Tunggal Prakarsa Tbk.',
    description: 'Indocement Tunggal Prakarsa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INVS-W',
    name: 'Waran Seri I Inovisi Infracom',
    full_name: 'Waran Seri I Inovisi Infracom',
    description: 'Waran Seri I Inovisi Infracom',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IPAC',
    name: 'Era Graharealty Tbk.',
    full_name: 'Era Graharealty Tbk.',
    description: 'Era Graharealty Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IPCC',
    name: 'Indonesia Kendaraan Terminal Tbk.',
    full_name: 'Indonesia Kendaraan Terminal Tbk.',
    description: 'Indonesia Kendaraan Terminal Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IPCM',
    name: 'Jasa Armada Indonesia Tbk.',
    full_name: 'Jasa Armada Indonesia Tbk.',
    description: 'Jasa Armada Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IPOL',
    name: 'Indopoly Swakarsa Industry Tbk.',
    full_name: 'Indopoly Swakarsa Industry Tbk.',
    description: 'Indopoly Swakarsa Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IPOL-W',
    name: 'Waran Seri I Indopoly Swakarsa Industry',
    full_name: 'Waran Seri I Indopoly Swakarsa Industry',
    description: 'Waran Seri I Indopoly Swakarsa Industry',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IPPE',
    name: 'Indo Pureco Pratama Tbk.',
    full_name: 'Indo Pureco Pratama Tbk.',
    description: 'Indo Pureco Pratama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IPTV',
    name: 'MNC Vision Networks Tbk.',
    full_name: 'MNC Vision Networks Tbk.',
    description: 'MNC Vision Networks Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IPTV-W',
    name: 'Waran Seri I MNC Vision Networks',
    full_name: 'Waran Seri I MNC Vision Networks',
    description: 'Waran Seri I MNC Vision Networks',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IRRA',
    name: 'Itama Ranoraya Tbk.',
    full_name: 'Itama Ranoraya Tbk.',
    description: 'Itama Ranoraya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IRSX',
    name: 'Aviana Sinar Abadi Tbk.',
    full_name: 'Aviana Sinar Abadi Tbk.',
    description: 'Aviana Sinar Abadi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ISAP',
    name: 'Isra Presisi Indonesia Tbk.',
    full_name: 'Isra Presisi Indonesia Tbk.',
    description: 'Isra Presisi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ISAT',
    name: 'Indosat Tbk.',
    full_name: 'Indosat Tbk.',
    description: 'Indosat Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ISSP',
    name: 'Steel Pipe Industry of Indonesia Tbk.',
    full_name: 'Steel Pipe Industry of Indonesia Tbk.',
    description: 'Steel Pipe Industry of Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ITIC',
    name: 'Indonesian Tobacco Tbk.',
    full_name: 'Indonesian Tobacco Tbk.',
    description: 'Indonesian Tobacco Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ITMA',
    name: 'Sumber Energi Andalan Tbk.',
    full_name: 'Sumber Energi Andalan Tbk.',
    description: 'Sumber Energi Andalan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ITMA-R',
    name: 'HMETD Sumber Energi Andalan',
    full_name: 'HMETD Sumber Energi Andalan',
    description: 'HMETD Sumber Energi Andalan',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ITMA-W',
    name: 'Waran Seri I Sumber Energi Andalan',
    full_name: 'Waran Seri I Sumber Energi Andalan',
    description: 'Waran Seri I Sumber Energi Andalan',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ITMG',
    name: 'Indo Tambangraya Megah Tbk.',
    full_name: 'Indo Tambangraya Megah Tbk.',
    description: 'Indo Tambangraya Megah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JARR',
    name: 'Jhonlin Agro Raya Tbk.',
    full_name: 'Jhonlin Agro Raya Tbk.',
    description: 'Jhonlin Agro Raya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JAST',
    name: 'Jasnita Telekomindo Tbk.',
    full_name: 'Jasnita Telekomindo Tbk.',
    description: 'Jasnita Telekomindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JATI',
    name: 'Informasi Teknologi Indonesia Tbk.',
    full_name: 'Informasi Teknologi Indonesia Tbk.',
    description: 'Informasi Teknologi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JAWA',
    name: 'Jaya Agra Wattie Tbk.',
    full_name: 'Jaya Agra Wattie Tbk.',
    description: 'Jaya Agra Wattie Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JAYA',
    name: 'Armada Berjaya Trans Tbk.',
    full_name: 'Armada Berjaya Trans Tbk.',
    description: 'Armada Berjaya Trans Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JAYA-W',
    name: 'Waran Seri I Armada Berjaya Trans',
    full_name: 'Waran Seri I Armada Berjaya Trans',
    description: 'Waran Seri I Armada Berjaya Trans',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JECC',
    name: 'Jembo Cable Company Tbk.',
    full_name: 'Jembo Cable Company Tbk.',
    description: 'Jembo Cable Company Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JGLE',
    name: 'Graha Andrasentra Propertindo Tbk.',
    full_name: 'Graha Andrasentra Propertindo Tbk.',
    description: 'Graha Andrasentra Propertindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JIHD',
    name: 'Jakarta International Hotels & Development Tbk.',
    full_name: 'Jakarta International Hotels & Development Tbk.',
    description: 'Jakarta International Hotels & Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JKON',
    name: 'Jaya Konstruksi Manggala Pratama Tbk.',
    full_name: 'Jaya Konstruksi Manggala Pratama Tbk.',
    description: 'Jaya Konstruksi Manggala Pratama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JKSW',
    name: 'Jakarta Kyoei Steel Works Tbk.',
    full_name: 'Jakarta Kyoei Steel Works Tbk.',
    description: 'Jakarta Kyoei Steel Works Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JMAS',
    name: 'Asuransi Jiwa Syariah Jasa Mitra Abadi Tbk.',
    full_name: 'Asuransi Jiwa Syariah Jasa Mitra Abadi Tbk.',
    description: 'Asuransi Jiwa Syariah Jasa Mitra Abadi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JPFA',
    name: 'Japfa Comfeed Indonesia Tbk.',
    full_name: 'Japfa Comfeed Indonesia Tbk.',
    description: 'Japfa Comfeed Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JRPT',
    name: 'Jaya Real Property Tbk.',
    full_name: 'Jaya Real Property Tbk.',
    description: 'Jaya Real Property Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JSKY',
    name: 'Sky Energy Indonesia Tbk.',
    full_name: 'Sky Energy Indonesia Tbk.',
    description: 'Sky Energy Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JSMR',
    name: 'Jasa Marga (Persero) Tbk.',
    full_name: 'Jasa Marga (Persero) Tbk.',
    description: 'Jasa Marga (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JSMR-R',
    name: 'HMETD Jasa Marga ',
    full_name: 'HMETD Jasa Marga ',
    description: 'HMETD Jasa Marga ',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JSPT',
    name: 'Jakarta Setiabudi Internasional Tbk.',
    full_name: 'Jakarta Setiabudi Internasional Tbk.',
    description: 'Jakarta Setiabudi Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JTPE',
    name: 'Jasuindo Tiga Perkasa Tbk.',
    full_name: 'Jasuindo Tiga Perkasa Tbk.',
    description: 'Jasuindo Tiga Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KAEF',
    name: 'Kimia Farma Tbk.',
    full_name: 'Kimia Farma Tbk.',
    description: 'Kimia Farma Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KARW',
    name: 'ICTSI Jasa Prima Tbk.',
    full_name: 'ICTSI Jasa Prima Tbk.',
    description: 'ICTSI Jasa Prima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KAYU',
    name: 'Darmi Bersaudara Tbk.',
    full_name: 'Darmi Bersaudara Tbk.',
    description: 'Darmi Bersaudara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KBAG',
    name: 'Karya Bersama Anugerah Tbk.',
    full_name: 'Karya Bersama Anugerah Tbk.',
    description: 'Karya Bersama Anugerah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KBAG-W',
    name: 'Waran Seri I Karya Bersama Anugerah',
    full_name: 'Waran Seri I Karya Bersama Anugerah',
    description: 'Waran Seri I Karya Bersama Anugerah',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KBLI',
    name: 'KMI Wire & Cable Tbk.',
    full_name: 'KMI Wire & Cable Tbk.',
    description: 'KMI Wire & Cable Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KBLM',
    name: 'Kabelindo Murni Tbk.',
    full_name: 'Kabelindo Murni Tbk.',
    description: 'Kabelindo Murni Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KBLV',
    name: 'First Media Tbk.',
    full_name: 'First Media Tbk.',
    description: 'First Media Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KBRI',
    name: 'Kertas Basuki Rachmat Indonesia Tbk.',
    full_name: 'Kertas Basuki Rachmat Indonesia Tbk.',
    description: 'Kertas Basuki Rachmat Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KDSI',
    name: 'Kedawung Setia Industrial Tbk.',
    full_name: 'Kedawung Setia Industrial Tbk.',
    description: 'Kedawung Setia Industrial Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KDTN',
    name: 'Puri Sentul Permai Tbk.',
    full_name: 'Puri Sentul Permai Tbk.',
    description: 'Puri Sentul Permai Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KEEN',
    name: 'Kencana Energi Lestari Tbk.',
    full_name: 'Kencana Energi Lestari Tbk.',
    description: 'Kencana Energi Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KEJU',
    name: 'Mulia Boga Raya Tbk.',
    full_name: 'Mulia Boga Raya Tbk.',
    description: 'Mulia Boga Raya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KETR',
    name: 'Ketrosden Triasmitra Tbk.',
    full_name: 'Ketrosden Triasmitra Tbk.',
    description: 'Ketrosden Triasmitra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KIAS',
    name: 'Keramika Indonesia Assosiasi Tbk.',
    full_name: 'Keramika Indonesia Assosiasi Tbk.',
    description: 'Keramika Indonesia Assosiasi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KICI',
    name: 'Kedaung Indah Can Tbk',
    full_name: 'Kedaung Indah Can Tbk',
    description: 'Kedaung Indah Can Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KIJA',
    name: 'Kawasan Industri Jababeka Tbk.',
    full_name: 'Kawasan Industri Jababeka Tbk.',
    description: 'Kawasan Industri Jababeka Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KING',
    name: 'Hoffmen Cleanindo Tbk.',
    full_name: 'Hoffmen Cleanindo Tbk.',
    description: 'Hoffmen Cleanindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KINO',
    name: 'Kino Indonesia Tbk.',
    full_name: 'Kino Indonesia Tbk.',
    description: 'Kino Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KIOS',
    name: 'Kioson Komersial Indonesia Tbk.',
    full_name: 'Kioson Komersial Indonesia Tbk.',
    description: 'Kioson Komersial Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KIOS-W',
    name: 'Waran Seri I Kioson Komersial Indonesia',
    full_name: 'Waran Seri I Kioson Komersial Indonesia',
    description: 'Waran Seri I Kioson Komersial Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KJEN',
    name: 'Krida Jaringan Nusantara Tbk.',
    full_name: 'Krida Jaringan Nusantara Tbk.',
    description: 'Krida Jaringan Nusantara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KKES',
    name: 'Kusuma Kemindo Sentosa Tbk.',
    full_name: 'Kusuma Kemindo Sentosa Tbk.',
    description: 'Kusuma Kemindo Sentosa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KKGI',
    name: 'Resource Alam Indonesia Tbk.',
    full_name: 'Resource Alam Indonesia Tbk.',
    description: 'Resource Alam Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KLBF',
    name: 'Kalbe Farma Tbk.',
    full_name: 'Kalbe Farma Tbk.',
    description: 'Kalbe Farma Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KLIN',
    name: 'Klinko Karya Imaji Tbk.',
    full_name: 'Klinko Karya Imaji Tbk.',
    description: 'Klinko Karya Imaji Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KMDS',
    name: 'Kurniamitra Duta Sentosa Tbk.',
    full_name: 'Kurniamitra Duta Sentosa Tbk.',
    description: 'Kurniamitra Duta Sentosa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KMTR',
    name: 'Kirana Megatara Tbk.',
    full_name: 'Kirana Megatara Tbk.',
    description: 'Kirana Megatara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KMTR-R',
    name: 'HMETD Kirana Megatara',
    full_name: 'HMETD Kirana Megatara',
    description: 'HMETD Kirana Megatara',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KOBX',
    name: 'Kobexindo Tractors Tbk.',
    full_name: 'Kobexindo Tractors Tbk.',
    description: 'Kobexindo Tractors Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KOIN',
    name: 'Kokoh Inti Arebama Tbk',
    full_name: 'Kokoh Inti Arebama Tbk',
    description: 'Kokoh Inti Arebama Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KONI',
    name: 'Perdana Bangun Pusaka Tbk',
    full_name: 'Perdana Bangun Pusaka Tbk',
    description: 'Perdana Bangun Pusaka Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KOPI',
    name: 'Mitra Energi Persada Tbk.',
    full_name: 'Mitra Energi Persada Tbk.',
    description: 'Mitra Energi Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KOTA',
    name: 'DMS Propertindo Tbk.',
    full_name: 'DMS Propertindo Tbk.',
    description: 'DMS Propertindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KOTA-W',
    name: 'Waran Seri I DMS Propertindo',
    full_name: 'Waran Seri I DMS Propertindo',
    description: 'Waran Seri I DMS Propertindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KPAL',
    name: 'Steadfast Marine Tbk.',
    full_name: 'Steadfast Marine Tbk.',
    description: 'Steadfast Marine Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KPAL-W',
    name: 'Waran Seri I Steadfast Marine',
    full_name: 'Waran Seri I Steadfast Marine',
    description: 'Waran Seri I Steadfast Marine',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KPAS',
    name: 'Cottonindo Ariesta Tbk.',
    full_name: 'Cottonindo Ariesta Tbk.',
    description: 'Cottonindo Ariesta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KPAS-W',
    name: 'Waran Seri I Cottonindo Ariesta',
    full_name: 'Waran Seri I Cottonindo Ariesta',
    description: 'Waran Seri I Cottonindo Ariesta',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KPIG',
    name: 'MNC Land Tbk.',
    full_name: 'MNC Land Tbk.',
    description: 'MNC Land Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KRAH',
    name: 'Grand Kartech Tbk.',
    full_name: 'Grand Kartech Tbk.',
    description: 'Grand Kartech Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KRAS',
    name: 'Krakatau Steel (Persero) Tbk.',
    full_name: 'Krakatau Steel (Persero) Tbk.',
    description: 'Krakatau Steel (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KRAS-R',
    name: 'HMETD Krakatau Steel ',
    full_name: 'HMETD Krakatau Steel ',
    description: 'HMETD Krakatau Steel ',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KREN',
    name: 'Kresna Graha Investama Tbk.',
    full_name: 'Kresna Graha Investama Tbk.',
    description: 'Kresna Graha Investama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KREN-W',
    name: 'Waran Seri II Kresna Graha Sekurindo',
    full_name: 'Waran Seri II Kresna Graha Sekurindo',
    description: 'Waran Seri II Kresna Graha Sekurindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KRYA',
    name: 'Bangun Karya Perkasa Jaya Tbk.',
    full_name: 'Bangun Karya Perkasa Jaya Tbk.',
    description: 'Bangun Karya Perkasa Jaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KUAS',
    name: 'Ace Oldfields Tbk.',
    full_name: 'Ace Oldfields Tbk.',
    description: 'Ace Oldfields Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KUAS-W',
    name: 'Waran Seri I Ace Oldfields',
    full_name: 'Waran Seri I Ace Oldfields',
    description: 'Waran Seri I Ace Oldfields',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LABA',
    name: 'Ladangbaja Murni Tbk.',
    full_name: 'Ladangbaja Murni Tbk.',
    description: 'Ladangbaja Murni Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LABA-W',
    name: 'Waran Seri I Ladangbaja Murni',
    full_name: 'Waran Seri I Ladangbaja Murni',
    description: 'Waran Seri I Ladangbaja Murni',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LAJU',
    name: 'Jasa Berdikari Logistics Tbk.',
    full_name: 'Jasa Berdikari Logistics Tbk.',
    description: 'Jasa Berdikari Logistics Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LAND',
    name: 'Trimitra Propertindo Tbk.',
    full_name: 'Trimitra Propertindo Tbk.',
    description: 'Trimitra Propertindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LAPD',
    name: 'Leyand International Tbk.',
    full_name: 'Leyand International Tbk.',
    description: 'Leyand International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LCGP',
    name: 'Eureka Prima Jakarta Tbk.',
    full_name: 'Eureka Prima Jakarta Tbk.',
    description: 'Eureka Prima Jakarta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LCGP-W',
    name: 'Waran Seri I Laguna Cipta Griya',
    full_name: 'Waran Seri I Laguna Cipta Griya',
    description: 'Waran Seri I Laguna Cipta Griya',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LCKM',
    name: 'LCK Global Kedaton Tbk.',
    full_name: 'LCK Global Kedaton Tbk.',
    description: 'LCK Global Kedaton Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LEAD',
    name: 'Logindo Samudramakmur Tbk.',
    full_name: 'Logindo Samudramakmur Tbk.',
    description: 'Logindo Samudramakmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LEAD-R',
    name: 'HMETD Logindo Samudramakmur',
    full_name: 'HMETD Logindo Samudramakmur',
    description: 'HMETD Logindo Samudramakmur',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LFLO',
    name: 'Imago Mulia Persada Tbk.',
    full_name: 'Imago Mulia Persada Tbk.',
    description: 'Imago Mulia Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LFLO-W',
    name: 'Waran Seri I Imago Mulia Persada',
    full_name: 'Waran Seri I Imago Mulia Persada',
    description: 'Waran Seri I Imago Mulia Persada',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LIFE',
    name: 'Asuransi Jiwa Sinarmas MSIG Tbk.',
    full_name: 'Asuransi Jiwa Sinarmas MSIG Tbk.',
    description: 'Asuransi Jiwa Sinarmas MSIG Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LINK',
    name: 'Link Net Tbk.',
    full_name: 'Link Net Tbk.',
    description: 'Link Net Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LION',
    name: 'Lion Metal Works Tbk.',
    full_name: 'Lion Metal Works Tbk.',
    description: 'Lion Metal Works Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LMAS',
    name: 'Limas Indonesia Makmur Tbk',
    full_name: 'Limas Indonesia Makmur Tbk',
    description: 'Limas Indonesia Makmur Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LMPI',
    name: 'Langgeng Makmur Industri Tbk.',
    full_name: 'Langgeng Makmur Industri Tbk.',
    description: 'Langgeng Makmur Industri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LMSH',
    name: 'Lionmesh Prima Tbk.',
    full_name: 'Lionmesh Prima Tbk.',
    description: 'Lionmesh Prima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPCK',
    name: 'Lippo Cikarang Tbk',
    full_name: 'Lippo Cikarang Tbk',
    description: 'Lippo Cikarang Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPCK-R',
    name: 'HMETD Lippo Cikarang',
    full_name: 'HMETD Lippo Cikarang',
    description: 'HMETD Lippo Cikarang',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPGI',
    name: 'Lippo General Insurance Tbk.',
    full_name: 'Lippo General Insurance Tbk.',
    description: 'Lippo General Insurance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPIN',
    name: 'Multi Prima Sejahtera Tbk',
    full_name: 'Multi Prima Sejahtera Tbk',
    description: 'Multi Prima Sejahtera Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPKR',
    name: 'Lippo Karawaci Tbk.',
    full_name: 'Lippo Karawaci Tbk.',
    description: 'Lippo Karawaci Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPKR-R',
    name: 'HMETD Lippo Karawaci',
    full_name: 'HMETD Lippo Karawaci',
    description: 'HMETD Lippo Karawaci',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPLI',
    name: 'Star Pacific Tbk',
    full_name: 'Star Pacific Tbk',
    description: 'Star Pacific Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPPF',
    name: 'Matahari Department Store Tbk.',
    full_name: 'Matahari Department Store Tbk.',
    description: 'Matahari Department Store Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LPPS',
    name: 'Lenox Pasifik Investama Tbk.',
    full_name: 'Lenox Pasifik Investama Tbk.',
    description: 'Lenox Pasifik Investama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LRNA',
    name: 'Eka Sari Lorena Transport Tbk.',
    full_name: 'Eka Sari Lorena Transport Tbk.',
    description: 'Eka Sari Lorena Transport Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LRNA-W',
    name: 'Waran Seri I Eka Sari Lorena Transport T',
    full_name: 'Waran Seri I Eka Sari Lorena Transport T',
    description: 'Waran Seri I Eka Sari Lorena Transport T',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LSIP',
    name: 'PP London Sumatra Indonesia Tbk.',
    full_name: 'PP London Sumatra Indonesia Tbk.',
    description: 'PP London Sumatra Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LTLS',
    name: 'Lautan Luas Tbk.',
    full_name: 'Lautan Luas Tbk.',
    description: 'Lautan Luas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LUCK',
    name: 'Sentral Mitra Informatika Tbk.',
    full_name: 'Sentral Mitra Informatika Tbk.',
    description: 'Sentral Mitra Informatika Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LUCY',
    name: 'Lima Dua Lima Tiga Tbk.',
    full_name: 'Lima Dua Lima Tiga Tbk.',
    description: 'Lima Dua Lima Tiga Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LUCY-W',
    name: 'Waran Seri I Lima Dua Lima Tiga',
    full_name: 'Waran Seri I Lima Dua Lima Tiga',
    description: 'Waran Seri I Lima Dua Lima Tiga',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MABA',
    name: 'Marga Abhinaya Abadi Tbk.',
    full_name: 'Marga Abhinaya Abadi Tbk.',
    description: 'Marga Abhinaya Abadi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MABA-R',
    name: 'HMETD Marga Abhinaya Abadi',
    full_name: 'HMETD Marga Abhinaya Abadi',
    description: 'HMETD Marga Abhinaya Abadi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAGP',
    name: 'Multi Agro Gemilang Plantation Tbk.',
    full_name: 'Multi Agro Gemilang Plantation Tbk.',
    description: 'Multi Agro Gemilang Plantation Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAGP-W',
    name: 'Waran Seri I Multi Agro Gemilang Plantat',
    full_name: 'Waran Seri I Multi Agro Gemilang Plantat',
    description: 'Waran Seri I Multi Agro Gemilang Plantat',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAIN',
    name: 'Malindo Feedmill Tbk.',
    full_name: 'Malindo Feedmill Tbk.',
    description: 'Malindo Feedmill Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAIN-R',
    name: 'HMETD Malindo Feedmill',
    full_name: 'HMETD Malindo Feedmill',
    description: 'HMETD Malindo Feedmill',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAMI',
    name: 'Mas Murni Indonesia Tbk',
    full_name: 'Mas Murni Indonesia Tbk',
    description: 'Mas Murni Indonesia Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAMI-R',
    name: 'HMETD Mas Murni Indonesia',
    full_name: 'HMETD Mas Murni Indonesia',
    description: 'HMETD Mas Murni Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAMI-W',
    name: 'Waran Seri I Mas Murni Indonesia',
    full_name: 'Waran Seri I Mas Murni Indonesia',
    description: 'Waran Seri I Mas Murni Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAPA',
    name: 'Map Aktif Adiperkasa Tbk.',
    full_name: 'Map Aktif Adiperkasa Tbk.',
    description: 'Map Aktif Adiperkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAPB',
    name: 'MAP Boga Adiperkasa Tbk.',
    full_name: 'MAP Boga Adiperkasa Tbk.',
    description: 'MAP Boga Adiperkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAPI',
    name: 'Mitra Adiperkasa Tbk.',
    full_name: 'Mitra Adiperkasa Tbk.',
    description: 'Mitra Adiperkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MARI',
    name: 'Mahaka Radio Integra Tbk.',
    full_name: 'Mahaka Radio Integra Tbk.',
    description: 'Mahaka Radio Integra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MARK',
    name: 'Mark Dynamics Indonesia Tbk.',
    full_name: 'Mark Dynamics Indonesia Tbk.',
    description: 'Mark Dynamics Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MASA',
    name: 'Multistrada Arah Sarana Tbk.',
    full_name: 'Multistrada Arah Sarana Tbk.',
    description: 'Multistrada Arah Sarana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MASB',
    name: 'Bank Multiarta Sentosa Tbk.',
    full_name: 'Bank Multiarta Sentosa Tbk.',
    description: 'Bank Multiarta Sentosa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MASB-W',
    name: 'Waran Seri I Bank Multiarta Sentosa',
    full_name: 'Waran Seri I Bank Multiarta Sentosa',
    description: 'Waran Seri I Bank Multiarta Sentosa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAYA',
    name: 'Bank Mayapada Internasional Tbk.',
    full_name: 'Bank Mayapada Internasional Tbk.',
    description: 'Bank Mayapada Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MAYA-R',
    name: 'HMETD Bank Mayapada Internasional',
    full_name: 'HMETD Bank Mayapada Internasional',
    description: 'HMETD Bank Mayapada Internasional',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MBAP',
    name: 'Mitrabara Adiperdana Tbk.',
    full_name: 'Mitrabara Adiperdana Tbk.',
    description: 'Mitrabara Adiperdana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MBMA',
    name: 'Merdeka Battery Materials Tbk.',
    full_name: 'Merdeka Battery Materials Tbk.',
    description: 'Merdeka Battery Materials Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MBSS',
    name: 'Mitrabahtera Segara Sejati Tbk.',
    full_name: 'Mitrabahtera Segara Sejati Tbk.',
    description: 'Mitrabahtera Segara Sejati Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MBTO',
    name: 'Martina Berto Tbk.',
    full_name: 'Martina Berto Tbk.',
    description: 'Martina Berto Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MCAS',
    name: 'M Cash Integrasi Tbk.',
    full_name: 'M Cash Integrasi Tbk.',
    description: 'M Cash Integrasi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MCOL',
    name: 'Prima Andalan Mandiri Tbk.',
    full_name: 'Prima Andalan Mandiri Tbk.',
    description: 'Prima Andalan Mandiri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MCOR',
    name: 'Bank China Construction Bank Indonesia Tbk.',
    full_name: 'Bank China Construction Bank Indonesia Tbk.',
    description: 'Bank China Construction Bank Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MCOR-R',
    name: 'HMETD Bank China Construction Bank Indon',
    full_name: 'HMETD Bank China Construction Bank Indon',
    description: 'HMETD Bank China Construction Bank Indon',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MCOR-W',
    name: 'Waran Seri I Bank Windu Kentjana Interna',
    full_name: 'Waran Seri I Bank Windu Kentjana Interna',
    description: 'Waran Seri I Bank Windu Kentjana Interna',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MDIA',
    name: 'Intermedia Capital Tbk.',
    full_name: 'Intermedia Capital Tbk.',
    description: 'Intermedia Capital Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MDKA',
    name: 'Merdeka Copper Gold Tbk.',
    full_name: 'Merdeka Copper Gold Tbk.',
    description: 'Merdeka Copper Gold Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MDKA-R',
    name: 'HMETD Merdeka Copper Gold',
    full_name: 'HMETD Merdeka Copper Gold',
    description: 'HMETD Merdeka Copper Gold',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MDKI',
    name: 'Emdeki Utama Tbk.',
    full_name: 'Emdeki Utama Tbk.',
    description: 'Emdeki Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MDLN',
    name: 'Modernland Realty Tbk.',
    full_name: 'Modernland Realty Tbk.',
    description: 'Modernland Realty Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MDRN',
    name: 'Modern Internasional Tbk.',
    full_name: 'Modern Internasional Tbk.',
    description: 'Modern Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MEDC',
    name: 'Medco Energi Internasional Tbk.',
    full_name: 'Medco Energi Internasional Tbk.',
    description: 'Medco Energi Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MEDC-R',
    name: 'HMETD Medco Energi Internasional',
    full_name: 'HMETD Medco Energi Internasional',
    description: 'HMETD Medco Energi Internasional',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MEDC-W',
    name: 'Waran Seri I Medco Energi Internasional',
    full_name: 'Waran Seri I Medco Energi Internasional',
    description: 'Waran Seri I Medco Energi Internasional',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MEDS',
    name: 'Hetzer Medical Indonesia Tbk.',
    full_name: 'Hetzer Medical Indonesia Tbk.',
    description: 'Hetzer Medical Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MEDS',
    name: 'Hetzer Medical Indonesia Tbk',
    full_name: 'Hetzer Medical Indonesia Tbk',
    description: 'Hetzer Medical Indonesia Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MEGA',
    name: 'Bank Mega Tbk.',
    full_name: 'Bank Mega Tbk.',
    description: 'Bank Mega Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MENN',
    name: 'Menn Teknologi Indonesia Tbk.',
    full_name: 'Menn Teknologi Indonesia Tbk.',
    description: 'Menn Teknologi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MERK',
    name: 'Merck Tbk.',
    full_name: 'Merck Tbk.',
    description: 'Merck Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'META',
    name: 'Nusantara Infrastructure Tbk.',
    full_name: 'Nusantara Infrastructure Tbk.',
    description: 'Nusantara Infrastructure Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'META-R',
    name: 'HMETD Nusantara Infrastructure',
    full_name: 'HMETD Nusantara Infrastructure',
    description: 'HMETD Nusantara Infrastructure',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'META-W',
    name: 'Waran Seri I Nusantara Insfrastructure T',
    full_name: 'Waran Seri I Nusantara Insfrastructure T',
    description: 'Waran Seri I Nusantara Insfrastructure T',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MFIN',
    name: 'Mandala Multifinance Tbk.',
    full_name: 'Mandala Multifinance Tbk.',
    description: 'Mandala Multifinance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MFMI',
    name: 'Multifiling Mitra Indonesia Tbk.',
    full_name: 'Multifiling Mitra Indonesia Tbk.',
    description: 'Multifiling Mitra Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MGLV',
    name: 'Panca Anugrah Wisesa Tbk.',
    full_name: 'Panca Anugrah Wisesa Tbk.',
    description: 'Panca Anugrah Wisesa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MGLV-W',
    name: 'Waran Seri I Panca Anugrah Wisesa',
    full_name: 'Waran Seri I Panca Anugrah Wisesa',
    description: 'Waran Seri I Panca Anugrah Wisesa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MGNA',
    name: 'Magna Investama Mandiri Tbk.',
    full_name: 'Magna Investama Mandiri Tbk.',
    description: 'Magna Investama Mandiri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MGNA-W',
    name: 'Waran Seri I Magna Finance',
    full_name: 'Waran Seri I Magna Finance',
    description: 'Waran Seri I Magna Finance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MGRO',
    name: 'Mahkota Group Tbk.',
    full_name: 'Mahkota Group Tbk.',
    description: 'Mahkota Group Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MICE',
    name: 'Multi Indocitra Tbk.',
    full_name: 'Multi Indocitra Tbk.',
    description: 'Multi Indocitra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MIDI',
    name: 'Midi Utama Indonesia Tbk.',
    full_name: 'Midi Utama Indonesia Tbk.',
    description: 'Midi Utama Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MIKA',
    name: 'Mitra Keluarga Karyasehat Tbk.',
    full_name: 'Mitra Keluarga Karyasehat Tbk.',
    description: 'Mitra Keluarga Karyasehat Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MINA',
    name: 'Sanurhasta Mitra Tbk.',
    full_name: 'Sanurhasta Mitra Tbk.',
    description: 'Sanurhasta Mitra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MIRA',
    name: 'Mitra International Resources Tbk.',
    full_name: 'Mitra International Resources Tbk.',
    description: 'Mitra International Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MITI',
    name: 'Mitra Investindo Tbk.',
    full_name: 'Mitra Investindo Tbk.',
    description: 'Mitra Investindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MITI-R',
    name: 'HMETD Mitra Investindo',
    full_name: 'HMETD Mitra Investindo',
    description: 'HMETD Mitra Investindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MKNT',
    name: 'Mitra Komunikasi Nusantara Tbk.',
    full_name: 'Mitra Komunikasi Nusantara Tbk.',
    description: 'Mitra Komunikasi Nusantara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MKPI',
    name: 'Metropolitan Kentjana Tbk.',
    full_name: 'Metropolitan Kentjana Tbk.',
    description: 'Metropolitan Kentjana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MKTR',
    name: 'Menthobi Karyatama Raya Tbk.',
    full_name: 'Menthobi Karyatama Raya Tbk.',
    description: 'Menthobi Karyatama Raya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MLBI',
    name: 'Multi Bintang Indonesia Tbk.',
    full_name: 'Multi Bintang Indonesia Tbk.',
    description: 'Multi Bintang Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MLIA',
    name: 'Mulia Industrindo Tbk',
    full_name: 'Mulia Industrindo Tbk',
    description: 'Mulia Industrindo Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MLPL',
    name: 'Multipolar Tbk.',
    full_name: 'Multipolar Tbk.',
    description: 'Multipolar Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MLPL-R',
    name: 'HMETD Multipolar',
    full_name: 'HMETD Multipolar',
    description: 'HMETD Multipolar',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MLPL-W',
    name: 'Waran Seri II Multipolar',
    full_name: 'Waran Seri II Multipolar',
    description: 'Waran Seri II Multipolar',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MLPT',
    name: 'Multipolar Technology Tbk.',
    full_name: 'Multipolar Technology Tbk.',
    description: 'Multipolar Technology Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MMIX',
    name: 'Multi Medika Internasional Tbk.',
    full_name: 'Multi Medika Internasional Tbk.',
    description: 'Multi Medika Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MMLP',
    name: 'Mega Manunggal Property Tbk.',
    full_name: 'Mega Manunggal Property Tbk.',
    description: 'Mega Manunggal Property Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MMLP-R',
    name: 'HMETD Mega Manunggal Property',
    full_name: 'HMETD Mega Manunggal Property',
    description: 'HMETD Mega Manunggal Property',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MNCN',
    name: 'Media Nusantara Citra Tbk.',
    full_name: 'Media Nusantara Citra Tbk.',
    description: 'Media Nusantara Citra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MOLI',
    name: 'Madusari Murni Indah Tbk.',
    full_name: 'Madusari Murni Indah Tbk.',
    description: 'Madusari Murni Indah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MORA',
    name: 'Mora Telematika Indonesia Tbk.',
    full_name: 'Mora Telematika Indonesia Tbk.',
    description: 'Mora Telematika Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MPMX',
    name: 'Mitra Pinasthika Mustika Tbk.',
    full_name: 'Mitra Pinasthika Mustika Tbk.',
    description: 'Mitra Pinasthika Mustika Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MPOW',
    name: 'Megapower Makmur Tbk.',
    full_name: 'Megapower Makmur Tbk.',
    description: 'Megapower Makmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MPPA',
    name: 'Matahari Putra Prima Tbk.',
    full_name: 'Matahari Putra Prima Tbk.',
    description: 'Matahari Putra Prima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MPPA-R',
    name: 'HMETD Matahari Putra Prima',
    full_name: 'HMETD Matahari Putra Prima',
    description: 'HMETD Matahari Putra Prima',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MPRO',
    name: 'Maha Properti Indonesia Tbk.',
    full_name: 'Maha Properti Indonesia Tbk.',
    description: 'Maha Properti Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MPXL',
    name: 'MPX Logistics International Tbk.',
    full_name: 'MPX Logistics International Tbk.',
    description: 'MPX Logistics International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MRAT',
    name: 'Mustika Ratu Tbk.',
    full_name: 'Mustika Ratu Tbk.',
    description: 'Mustika Ratu Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MREI',
    name: 'Maskapai Reasuransi Indonesia Tbk.',
    full_name: 'Maskapai Reasuransi Indonesia Tbk.',
    description: 'Maskapai Reasuransi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MREI-R',
    name: 'HMETD Maskapai Reasuransi Indonesia',
    full_name: 'HMETD Maskapai Reasuransi Indonesia',
    description: 'HMETD Maskapai Reasuransi Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MSIN',
    name: 'MNC Digital Entertainment Tbk.',
    full_name: 'MNC Digital Entertainment Tbk.',
    description: 'MNC Digital Entertainment Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MSKY',
    name: 'MNC Sky Vision Tbk.',
    full_name: 'MNC Sky Vision Tbk.',
    description: 'MNC Sky Vision Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MSKY-R',
    name: 'HMETD MNC Sky Vision',
    full_name: 'HMETD MNC Sky Vision',
    description: 'HMETD MNC Sky Vision',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTDL',
    name: 'Metrodata Electronics Tbk.',
    full_name: 'Metrodata Electronics Tbk.',
    description: 'Metrodata Electronics Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTEL',
    name: 'Dayamitra Telekomunikasi Tbk.',
    full_name: 'Dayamitra Telekomunikasi Tbk.',
    description: 'Dayamitra Telekomunikasi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTFN',
    name: 'Capitalinc Investment Tbk.',
    full_name: 'Capitalinc Investment Tbk.',
    description: 'Capitalinc Investment Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTLA',
    name: 'Metropolitan Land Tbk.',
    full_name: 'Metropolitan Land Tbk.',
    description: 'Metropolitan Land Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTMH',
    name: 'Murni Sadar Tbk.',
    full_name: 'Murni Sadar Tbk.',
    description: 'Murni Sadar Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTPS',
    name: 'Meta Epsi Tbk.',
    full_name: 'Meta Epsi Tbk.',
    description: 'Meta Epsi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTPS-W',
    name: 'Waran Seri I Meta Epsi',
    full_name: 'Waran Seri I Meta Epsi',
    description: 'Waran Seri I Meta Epsi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTRA',
    name: 'Mitra Pemuda Tbk.',
    full_name: 'Mitra Pemuda Tbk.',
    description: 'Mitra Pemuda Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTSM',
    name: 'Metro Realty Tbk.',
    full_name: 'Metro Realty Tbk.',
    description: 'Metro Realty Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MTWI',
    name: 'Malacca Trust Wuwungan Insurance Tbk.',
    full_name: 'Malacca Trust Wuwungan Insurance Tbk.',
    description: 'Malacca Trust Wuwungan Insurance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MYOH',
    name: 'Samindo Resources Tbk.',
    full_name: 'Samindo Resources Tbk.',
    description: 'Samindo Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MYOR',
    name: 'Mayora Indah Tbk.',
    full_name: 'Mayora Indah Tbk.',
    description: 'Mayora Indah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MYRX',
    name: 'Hanson International Tbk.',
    full_name: 'Hanson International Tbk.',
    description: 'Hanson International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MYTX',
    name: 'Asia Pacific Investama Tbk.',
    full_name: 'Asia Pacific Investama Tbk.',
    description: 'Asia Pacific Investama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MYTX-R',
    name: 'HMETD Asia Pacific Investama',
    full_name: 'HMETD Asia Pacific Investama',
    description: 'HMETD Asia Pacific Investama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NANO',
    name: 'Nanotech Indonesia Global Tbk.',
    full_name: 'Nanotech Indonesia Global Tbk.',
    description: 'Nanotech Indonesia Global Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NASA',
    name: 'Andalan Perkasa Abadi Tbk.',
    full_name: 'Andalan Perkasa Abadi Tbk.',
    description: 'Andalan Perkasa Abadi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NASA-W',
    name: 'Waran Seri I Ayana Land International Tb',
    full_name: 'Waran Seri I Ayana Land International Tb',
    description: 'Waran Seri I Ayana Land International Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NASI',
    name: 'Wahana Inti Makmur Tbk.',
    full_name: 'Wahana Inti Makmur Tbk.',
    description: 'Wahana Inti Makmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NATO',
    name: 'Surya Permata Andalan Tbk.',
    full_name: 'Surya Permata Andalan Tbk.',
    description: 'Surya Permata Andalan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NATO-W',
    name: 'Waran Seri I Surya Permata Andalan',
    full_name: 'Waran Seri I Surya Permata Andalan',
    description: 'Waran Seri I Surya Permata Andalan',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NAYZ',
    name: 'Hassana Boga Sejahtera Tbk.',
    full_name: 'Hassana Boga Sejahtera Tbk.',
    description: 'Hassana Boga Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NCKL',
    name: 'Trimegah Bangun Persada Tbk.',
    full_name: 'Trimegah Bangun Persada Tbk.',
    description: 'Trimegah Bangun Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NELY',
    name: 'Pelayaran Nelly Dwi Putri Tbk.',
    full_name: 'Pelayaran Nelly Dwi Putri Tbk.',
    description: 'Pelayaran Nelly Dwi Putri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NETV',
    name: 'Net Visi Media Tbk.',
    full_name: 'Net Visi Media Tbk.',
    description: 'Net Visi Media Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NFCX',
    name: 'NFC Indonesia Tbk.',
    full_name: 'NFC Indonesia Tbk.',
    description: 'NFC Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NICK',
    name: 'Charnic Capital Tbk.',
    full_name: 'Charnic Capital Tbk.',
    description: 'Charnic Capital Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NICL',
    name: 'PAM Mineral Tbk.',
    full_name: 'PAM Mineral Tbk.',
    description: 'PAM Mineral Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NICL-W',
    name: 'Waran Seri I PAM Mineral',
    full_name: 'Waran Seri I PAM Mineral',
    description: 'Waran Seri I PAM Mineral',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NIKL',
    name: 'Pelat Timah Nusantara Tbk.',
    full_name: 'Pelat Timah Nusantara Tbk.',
    description: 'Pelat Timah Nusantara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NINE',
    name: 'Techno9 Indonesia Tbk.',
    full_name: 'Techno9 Indonesia Tbk.',
    description: 'Techno9 Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NIPS',
    name: 'Nipress Tbk.',
    full_name: 'Nipress Tbk.',
    description: 'Nipress Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NIRO',
    name: 'City Retail Developments Tbk.',
    full_name: 'City Retail Developments Tbk.',
    description: 'City Retail Developments Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NIRO-W',
    name: 'Waran Seri I Nirvana Development',
    full_name: 'Waran Seri I Nirvana Development',
    description: 'Waran Seri I Nirvana Development',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NISP',
    name: 'Bank OCBC NISP Tbk.',
    full_name: 'Bank OCBC NISP Tbk.',
    description: 'Bank OCBC NISP Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NOBU',
    name: 'Bank Nationalnobu Tbk.',
    full_name: 'Bank Nationalnobu Tbk.',
    description: 'Bank Nationalnobu Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NPGF',
    name: 'Nusa Palapa Gemilang Tbk.',
    full_name: 'Nusa Palapa Gemilang Tbk.',
    description: 'Nusa Palapa Gemilang Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NRCA',
    name: 'Nusa Raya Cipta Tbk.',
    full_name: 'Nusa Raya Cipta Tbk.',
    description: 'Nusa Raya Cipta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NRCA-W',
    name: 'Waran Seri I Nusa Raya Cipta',
    full_name: 'Waran Seri I Nusa Raya Cipta',
    description: 'Waran Seri I Nusa Raya Cipta',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NSSS',
    name: 'Nusantara Sawit Sejahtera Tbk.',
    full_name: 'Nusantara Sawit Sejahtera Tbk.',
    description: 'Nusantara Sawit Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NTBK',
    name: 'Nusatama Berkah Tbk.',
    full_name: 'Nusatama Berkah Tbk.',
    description: 'Nusatama Berkah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NUSA',
    name: 'Sinergi Megah Internusa Tbk.',
    full_name: 'Sinergi Megah Internusa Tbk.',
    description: 'Sinergi Megah Internusa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NUSA-W',
    name: 'Waran Seri I Sinergi Megah Internusa',
    full_name: 'Waran Seri I Sinergi Megah Internusa',
    description: 'Waran Seri I Sinergi Megah Internusa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NZIA',
    name: 'Nusantara Almazia Tbk.',
    full_name: 'Nusantara Almazia Tbk.',
    description: 'Nusantara Almazia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'NZIA-W',
    name: 'Waran Seri I Nusantara Almazia',
    full_name: 'Waran Seri I Nusantara Almazia',
    description: 'Waran Seri I Nusantara Almazia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OASA',
    name: 'Maharaksa Biru Energi Tbk.',
    full_name: 'Maharaksa Biru Energi Tbk.',
    description: 'Maharaksa Biru Energi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OBMD',
    name: 'OBM Drilchem Tbk.',
    full_name: 'OBM Drilchem Tbk.',
    description: 'OBM Drilchem Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OCAP',
    name: 'Onix Capital Tbk.',
    full_name: 'Onix Capital Tbk.',
    description: 'Onix Capital Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OILS',
    name: 'Indo Oil Perkasa Tbk.',
    full_name: 'Indo Oil Perkasa Tbk.',
    description: 'Indo Oil Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OILS-W',
    name: 'Waran Seri I Indo Oil Perkasa',
    full_name: 'Waran Seri I Indo Oil Perkasa',
    description: 'Waran Seri I Indo Oil Perkasa',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OKAS',
    name: 'Ancora Indonesia Resources Tbk.',
    full_name: 'Ancora Indonesia Resources Tbk.',
    description: 'Ancora Indonesia Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OLIV',
    name: 'Oscar Mitra Sukses Sejahtera Tbk.',
    full_name: 'Oscar Mitra Sukses Sejahtera Tbk.',
    description: 'Oscar Mitra Sukses Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OMED',
    name: 'Jayamas Medica Industri Tbk.',
    full_name: 'Jayamas Medica Industri Tbk.',
    description: 'Jayamas Medica Industri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OMRE',
    name: 'Indonesia Prima Property Tbk',
    full_name: 'Indonesia Prima Property Tbk',
    description: 'Indonesia Prima Property Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'OPMS',
    name: 'Optima Prima Metal Sinergi Tbk.',
    full_name: 'Optima Prima Metal Sinergi Tbk.',
    description: 'Optima Prima Metal Sinergi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PACK',
    name: 'Solusi Kemasan Digital Tbk.',
    full_name: 'Solusi Kemasan Digital Tbk.',
    description: 'Solusi Kemasan Digital Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PADA',
    name: 'Personel Alih Daya Tbk.',
    full_name: 'Personel Alih Daya Tbk.',
    description: 'Personel Alih Daya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PADI',
    name: 'Minna Padi Investama Sekuritas Tbk.',
    full_name: 'Minna Padi Investama Sekuritas Tbk.',
    description: 'Minna Padi Investama Sekuritas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PADI-W',
    name: 'Waran Seri I Minna Padi Investama',
    full_name: 'Waran Seri I Minna Padi Investama',
    description: 'Waran Seri I Minna Padi Investama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PALM',
    name: 'Provident Investasi Bersama Tbk.',
    full_name: 'Provident Investasi Bersama Tbk.',
    description: 'Provident Investasi Bersama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PAMG',
    name: 'Bima Sakti Pertiwi Tbk.',
    full_name: 'Bima Sakti Pertiwi Tbk.',
    description: 'Bima Sakti Pertiwi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PANI',
    name: 'Pratama Abadi Nusa Industri Tbk.',
    full_name: 'Pratama Abadi Nusa Industri Tbk.',
    description: 'Pratama Abadi Nusa Industri Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PANR',
    name: 'Panorama Sentrawisata Tbk.',
    full_name: 'Panorama Sentrawisata Tbk.',
    description: 'Panorama Sentrawisata Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PANS',
    name: 'Panin Sekuritas Tbk.',
    full_name: 'Panin Sekuritas Tbk.',
    description: 'Panin Sekuritas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PBID',
    name: 'Panca Budi Idaman Tbk.',
    full_name: 'Panca Budi Idaman Tbk.',
    description: 'Panca Budi Idaman Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PBRX',
    name: 'Pan Brothers Tbk.',
    full_name: 'Pan Brothers Tbk.',
    description: 'Pan Brothers Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PBSA',
    name: 'Paramita Bangun Sarana Tbk.',
    full_name: 'Paramita Bangun Sarana Tbk.',
    description: 'Paramita Bangun Sarana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PCAR',
    name: 'Prima Cakrawala Abadi Tbk.',
    full_name: 'Prima Cakrawala Abadi Tbk.',
    description: 'Prima Cakrawala Abadi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PDES',
    name: 'Destinasi Tirta Nusantara Tbk',
    full_name: 'Destinasi Tirta Nusantara Tbk',
    description: 'Destinasi Tirta Nusantara Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PDPP',
    name: 'Primadaya Plastisindo Tbk.',
    full_name: 'Primadaya Plastisindo Tbk.',
    description: 'Primadaya Plastisindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PEGE',
    name: 'Panca Global Kapital Tbk.',
    full_name: 'Panca Global Kapital Tbk.',
    description: 'Panca Global Kapital Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PEGE-R',
    name: 'HMETD Panca Global Kapital',
    full_name: 'HMETD Panca Global Kapital',
    description: 'HMETD Panca Global Kapital',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PEHA',
    name: 'Phapros Tbk.',
    full_name: 'Phapros Tbk.',
    description: 'Phapros Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PEVE',
    name: 'Penta Valent Tbk.',
    full_name: 'Penta Valent Tbk.',
    description: 'Penta Valent Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PGAS',
    name: 'Perusahaan Gas Negara Tbk.',
    full_name: 'Perusahaan Gas Negara Tbk.',
    description: 'Perusahaan Gas Negara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PGEO',
    name: 'Pertamina Geothermal Energy Tbk.',
    full_name: 'Pertamina Geothermal Energy Tbk.',
    description: 'Pertamina Geothermal Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PGJO',
    name: 'Tourindo Guide Indonesia Tbk.',
    full_name: 'Tourindo Guide Indonesia Tbk.',
    description: 'Tourindo Guide Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PGJO-R',
    name: 'HMETD Tourindo Guide Indonesia',
    full_name: 'HMETD Tourindo Guide Indonesia',
    description: 'HMETD Tourindo Guide Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PGLI',
    name: 'Pembangunan Graha Lestari Indah Tbk.',
    full_name: 'Pembangunan Graha Lestari Indah Tbk.',
    description: 'Pembangunan Graha Lestari Indah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PGUN',
    name: 'Pradiksi Gunatama Tbk.',
    full_name: 'Pradiksi Gunatama Tbk.',
    description: 'Pradiksi Gunatama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PICO',
    name: 'Pelangi Indah Canindo Tbk',
    full_name: 'Pelangi Indah Canindo Tbk',
    description: 'Pelangi Indah Canindo Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PIPA',
    name: 'Multi Makmur Lemindo Tbk.',
    full_name: 'Multi Makmur Lemindo Tbk.',
    description: 'Multi Makmur Lemindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PJAA',
    name: 'Pembangunan Jaya Ancol Tbk.',
    full_name: 'Pembangunan Jaya Ancol Tbk.',
    description: 'Pembangunan Jaya Ancol Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PKPK',
    name: 'Perdana Karya Perkasa Tbk',
    full_name: 'Perdana Karya Perkasa Tbk',
    description: 'Perdana Karya Perkasa Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PLAN',
    name: 'Planet Properindo Jaya Tbk.',
    full_name: 'Planet Properindo Jaya Tbk.',
    description: 'Planet Properindo Jaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PLAN-W',
    name: 'Waran Seri I Planet Properindo Jaya',
    full_name: 'Waran Seri I Planet Properindo Jaya',
    description: 'Waran Seri I Planet Properindo Jaya',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PLAS',
    name: 'Polaris Investama Tbk',
    full_name: 'Polaris Investama Tbk',
    description: 'Polaris Investama Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PLIN',
    name: 'Plaza Indonesia Realty Tbk.',
    full_name: 'Plaza Indonesia Realty Tbk.',
    description: 'Plaza Indonesia Realty Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PMJS',
    name: 'Putra Mandiri Jembar Tbk.',
    full_name: 'Putra Mandiri Jembar Tbk.',
    description: 'Putra Mandiri Jembar Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PMMP',
    name: 'Panca Mitra Multiperdana Tbk.',
    full_name: 'Panca Mitra Multiperdana Tbk.',
    description: 'Panca Mitra Multiperdana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNBN',
    name: 'Bank Pan Indonesia Tbk',
    full_name: 'Bank Pan Indonesia Tbk',
    description: 'Bank Pan Indonesia Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNBS',
    name: 'Bank Panin Dubai Syariah Tbk.',
    full_name: 'Bank Panin Dubai Syariah Tbk.',
    description: 'Bank Panin Dubai Syariah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNBS-R',
    name: 'HMETD Bank Panin Dubai Syariah',
    full_name: 'HMETD Bank Panin Dubai Syariah',
    description: 'HMETD Bank Panin Dubai Syariah',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNBS-W',
    name: 'Waran Seri I Bank Panin Syariah',
    full_name: 'Waran Seri I Bank Panin Syariah',
    description: 'Waran Seri I Bank Panin Syariah',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNGO',
    name: 'Pinago Utama Tbk.',
    full_name: 'Pinago Utama Tbk.',
    description: 'Pinago Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNIN',
    name: 'Paninvest Tbk.',
    full_name: 'Paninvest Tbk.',
    description: 'Paninvest Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNLF',
    name: 'Panin Financial Tbk.',
    full_name: 'Panin Financial Tbk.',
    description: 'Panin Financial Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNLF-W',
    name: 'Waran Seri V Panin Financial',
    full_name: 'Waran Seri V Panin Financial',
    description: 'Waran Seri V Panin Financial',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PNSE',
    name: 'Pudjiadi & Sons Tbk.',
    full_name: 'Pudjiadi & Sons Tbk.',
    description: 'Pudjiadi & Sons Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POLA',
    name: 'Pool Advista Finance Tbk.',
    full_name: 'Pool Advista Finance Tbk.',
    description: 'Pool Advista Finance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POLA-W',
    name: 'Waran Seri I Pool Advista Finance',
    full_name: 'Waran Seri I Pool Advista Finance',
    description: 'Waran Seri I Pool Advista Finance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POLI',
    name: 'Pollux Hotels Group Tbk.',
    full_name: 'Pollux Hotels Group Tbk.',
    description: 'Pollux Hotels Group Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POLL',
    name: 'Pollux Properties Indonesia Tbk.',
    full_name: 'Pollux Properties Indonesia Tbk.',
    description: 'Pollux Properties Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POLU',
    name: 'Golden Flower Tbk.',
    full_name: 'Golden Flower Tbk.',
    description: 'Golden Flower Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POLY',
    name: 'Asia Pacific Fibers Tbk',
    full_name: 'Asia Pacific Fibers Tbk',
    description: 'Asia Pacific Fibers Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POOL',
    name: 'Pool Advista Indonesia Tbk.',
    full_name: 'Pool Advista Indonesia Tbk.',
    description: 'Pool Advista Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POOL-R',
    name: 'HMETD Pool Advista Indonesia',
    full_name: 'HMETD Pool Advista Indonesia',
    description: 'HMETD Pool Advista Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POOL-W',
    name: 'Waran Seri II Pool Advista Indonesia',
    full_name: 'Waran Seri II Pool Advista Indonesia',
    description: 'Waran Seri II Pool Advista Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PORT',
    name: 'Nusantara Pelabuhan Handal Tbk.',
    full_name: 'Nusantara Pelabuhan Handal Tbk.',
    description: 'Nusantara Pelabuhan Handal Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POSA',
    name: 'Bliss Properti Indonesia Tbk.',
    full_name: 'Bliss Properti Indonesia Tbk.',
    description: 'Bliss Properti Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POSA-W',
    name: 'Waran Seri I Bliss Properti Indonesia Tb',
    full_name: 'Waran Seri I Bliss Properti Indonesia Tb',
    description: 'Waran Seri I Bliss Properti Indonesia Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'POWR',
    name: 'Cikarang Listrindo Tbk.',
    full_name: 'Cikarang Listrindo Tbk.',
    description: 'Cikarang Listrindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PPGL',
    name: 'Prima Globalindo Logistik Tbk.',
    full_name: 'Prima Globalindo Logistik Tbk.',
    description: 'Prima Globalindo Logistik Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PPGL-W',
    name: 'Waran Seri I Prima Globalindo Logistik T',
    full_name: 'Waran Seri I Prima Globalindo Logistik T',
    description: 'Waran Seri I Prima Globalindo Logistik T',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PPRE',
    name: 'PP Presisi Tbk.',
    full_name: 'PP Presisi Tbk.',
    description: 'PP Presisi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PPRO',
    name: 'PP Properti Tbk.',
    full_name: 'PP Properti Tbk.',
    description: 'PP Properti Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PPRO-R',
    name: 'HMETD PP Properti',
    full_name: 'HMETD PP Properti',
    description: 'HMETD PP Properti',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PRAS',
    name: 'Prima Alloy Steel Universal Tbk.',
    full_name: 'Prima Alloy Steel Universal Tbk.',
    description: 'Prima Alloy Steel Universal Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PRAY',
    name: 'Famon Awal Bros Sedaya Tbk.',
    full_name: 'Famon Awal Bros Sedaya Tbk.',
    description: 'Famon Awal Bros Sedaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PRDA',
    name: 'Prodia Widyahusada Tbk.',
    full_name: 'Prodia Widyahusada Tbk.',
    description: 'Prodia Widyahusada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PRIM',
    name: 'Royal Prima Tbk.',
    full_name: 'Royal Prima Tbk.',
    description: 'Royal Prima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PRIM-W',
    name: 'Waran Seri I Royal Prima',
    full_name: 'Waran Seri I Royal Prima',
    description: 'Waran Seri I Royal Prima',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PSAB',
    name: 'J Resources Asia Pasifik Tbk.',
    full_name: 'J Resources Asia Pasifik Tbk.',
    description: 'J Resources Asia Pasifik Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PSDN',
    name: 'Prasidha Aneka Niaga Tbk',
    full_name: 'Prasidha Aneka Niaga Tbk',
    description: 'Prasidha Aneka Niaga Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PSGO',
    name: 'Palma Serasih Tbk.',
    full_name: 'Palma Serasih Tbk.',
    description: 'Palma Serasih Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PSKT',
    name: 'Red Planet Indonesia Tbk.',
    full_name: 'Red Planet Indonesia Tbk.',
    description: 'Red Planet Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PSKT-R',
    name: 'HMETD Red Planet Indonesia',
    full_name: 'HMETD Red Planet Indonesia',
    description: 'HMETD Red Planet Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PSKT-W',
    name: 'Waran Seri I Red Planet Indonesia',
    full_name: 'Waran Seri I Red Planet Indonesia',
    description: 'Waran Seri I Red Planet Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PSSI',
    name: 'IMC Pelita Logistik Tbk.',
    full_name: 'IMC Pelita Logistik Tbk.',
    description: 'IMC Pelita Logistik Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTBA',
    name: 'Bukit Asam Tbk.',
    full_name: 'Bukit Asam Tbk.',
    description: 'Bukit Asam Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTDU',
    name: 'Djasa Ubersakti Tbk.',
    full_name: 'Djasa Ubersakti Tbk.',
    description: 'Djasa Ubersakti Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTIS',
    name: 'Indo Straits Tbk.',
    full_name: 'Indo Straits Tbk.',
    description: 'Indo Straits Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTMP',
    name: 'Mitra Pack Tbk.',
    full_name: 'Mitra Pack Tbk.',
    description: 'Mitra Pack Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTPP',
    name: 'PP (Persero) Tbk.',
    full_name: 'PP (Persero) Tbk.',
    description: 'PP (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTPP-R',
    name: 'HMETD PP ',
    full_name: 'HMETD PP ',
    description: 'HMETD PP ',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTPW',
    name: 'Pratama Widya Tbk.',
    full_name: 'Pratama Widya Tbk.',
    description: 'Pratama Widya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTRO',
    name: 'Petrosea Tbk.',
    full_name: 'Petrosea Tbk.',
    description: 'Petrosea Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTSN',
    name: 'Sat Nusapersada Tbk',
    full_name: 'Sat Nusapersada Tbk',
    description: 'Sat Nusapersada Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PTSP',
    name: 'Pioneerindo Gourmet International Tbk.',
    full_name: 'Pioneerindo Gourmet International Tbk.',
    description: 'Pioneerindo Gourmet International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PUDP',
    name: 'Pudjiadi Prestige Tbk.',
    full_name: 'Pudjiadi Prestige Tbk.',
    description: 'Pudjiadi Prestige Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PURA',
    name: 'Putra Rajawali Kencana Tbk.',
    full_name: 'Putra Rajawali Kencana Tbk.',
    description: 'Putra Rajawali Kencana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PURA-W',
    name: 'Waran Seri I Putra Rajawali Kencana',
    full_name: 'Waran Seri I Putra Rajawali Kencana',
    description: 'Waran Seri I Putra Rajawali Kencana',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PURE',
    name: 'Trinitan Metals and Minerals Tbk.',
    full_name: 'Trinitan Metals and Minerals Tbk.',
    description: 'Trinitan Metals and Minerals Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PURE-W',
    name: 'Waran Seri I Trinitan Metals and Mineral',
    full_name: 'Waran Seri I Trinitan Metals and Mineral',
    description: 'Waran Seri I Trinitan Metals and Mineral',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PURI',
    name: 'Puri Global Sukses Tbk.',
    full_name: 'Puri Global Sukses Tbk.',
    description: 'Puri Global Sukses Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PWON',
    name: 'Pakuwon Jati Tbk.',
    full_name: 'Pakuwon Jati Tbk.',
    description: 'Pakuwon Jati Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PYFA',
    name: 'Pyridam Farma Tbk',
    full_name: 'Pyridam Farma Tbk',
    description: 'Pyridam Farma Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PZZA',
    name: 'Sarimelati Kencana Tbk.',
    full_name: 'Sarimelati Kencana Tbk.',
    description: 'Sarimelati Kencana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RAAM',
    name: 'Tripar Multivision Plus Tbk.',
    full_name: 'Tripar Multivision Plus Tbk.',
    description: 'Tripar Multivision Plus Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RAFI',
    name: 'Sari Kreasi Boga Tbk.',
    full_name: 'Sari Kreasi Boga Tbk.',
    description: 'Sari Kreasi Boga Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RAJA',
    name: 'Rukun Raharja Tbk.',
    full_name: 'Rukun Raharja Tbk.',
    description: 'Rukun Raharja Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RALS',
    name: 'Ramayana Lestari Sentosa Tbk.',
    full_name: 'Ramayana Lestari Sentosa Tbk.',
    description: 'Ramayana Lestari Sentosa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RANC',
    name: 'Supra Boga Lestari Tbk.',
    full_name: 'Supra Boga Lestari Tbk.',
    description: 'Supra Boga Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RBMS',
    name: 'Ristia Bintang Mahkotasejati Tbk.',
    full_name: 'Ristia Bintang Mahkotasejati Tbk.',
    description: 'Ristia Bintang Mahkotasejati Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RBMS-R',
    name: 'HMETD Ristia Bintang Mahkotasejati',
    full_name: 'HMETD Ristia Bintang Mahkotasejati',
    description: 'HMETD Ristia Bintang Mahkotasejati',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RCCC',
    name: 'Utama Radar Cahaya Tbk.',
    full_name: 'Utama Radar Cahaya Tbk.',
    description: 'Utama Radar Cahaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RDTX',
    name: 'Roda Vivatex Tbk',
    full_name: 'Roda Vivatex Tbk',
    description: 'Roda Vivatex Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'REAL',
    name: 'Repower Asia Indonesia Tbk.',
    full_name: 'Repower Asia Indonesia Tbk.',
    description: 'Repower Asia Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'REAL-W',
    name: 'Waran Seri I Repower Asia Indonesia',
    full_name: 'Waran Seri I Repower Asia Indonesia',
    description: 'Waran Seri I Repower Asia Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RELI',
    name: 'Reliance Sekuritas Indonesia Tbk.',
    full_name: 'Reliance Sekuritas Indonesia Tbk.',
    description: 'Reliance Sekuritas Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RICY',
    name: 'Ricky Putra Globalindo Tbk',
    full_name: 'Ricky Putra Globalindo Tbk',
    description: 'Ricky Putra Globalindo Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RIGS',
    name: 'Rig Tenders Indonesia Tbk.',
    full_name: 'Rig Tenders Indonesia Tbk.',
    description: 'Rig Tenders Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RIMO',
    name: 'Rimo International Lestari Tbk',
    full_name: 'Rimo International Lestari Tbk',
    description: 'Rimo International Lestari Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RIMO-R',
    name: 'HMETD Rimo International Lestari',
    full_name: 'HMETD Rimo International Lestari',
    description: 'HMETD Rimo International Lestari',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RISE',
    name: 'Jaya Sukses Makmur Sentosa Tbk.',
    full_name: 'Jaya Sukses Makmur Sentosa Tbk.',
    description: 'Jaya Sukses Makmur Sentosa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RMBA',
    name: 'Bentoel Internasional Investama Tbk.',
    full_name: 'Bentoel Internasional Investama Tbk.',
    description: 'Bentoel Internasional Investama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RMBA-R',
    name: 'HMETD Bentoel Internasional Investama Tb',
    full_name: 'HMETD Bentoel Internasional Investama Tb',
    description: 'HMETD Bentoel Internasional Investama Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RMKE',
    name: 'RMK Energy Tbk.',
    full_name: 'RMK Energy Tbk.',
    description: 'RMK Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ROCK',
    name: 'Rockfields Properti Indonesia Tbk.',
    full_name: 'Rockfields Properti Indonesia Tbk.',
    description: 'Rockfields Properti Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RODA',
    name: 'Pikko Land Development Tbk.',
    full_name: 'Pikko Land Development Tbk.',
    description: 'Pikko Land Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RONY',
    name: 'Aesler Grup Internasional Tbk.',
    full_name: 'Aesler Grup Internasional Tbk.',
    description: 'Aesler Grup Internasional Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ROTI',
    name: 'Nippon Indosari Corpindo Tbk.',
    full_name: 'Nippon Indosari Corpindo Tbk.',
    description: 'Nippon Indosari Corpindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ROTI-R',
    name: 'HMETD Nippon Indosari Corpindo',
    full_name: 'HMETD Nippon Indosari Corpindo',
    description: 'HMETD Nippon Indosari Corpindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RSGK',
    name: 'Kedoya Adyaraya Tbk.',
    full_name: 'Kedoya Adyaraya Tbk.',
    description: 'Kedoya Adyaraya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RUIS',
    name: 'Radiant Utama Interinsco Tbk.',
    full_name: 'Radiant Utama Interinsco Tbk.',
    description: 'Radiant Utama Interinsco Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'RUNS',
    name: 'Global Sukses Solusi Tbk.',
    full_name: 'Global Sukses Solusi Tbk.',
    description: 'Global Sukses Solusi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SAFE',
    name: 'Steady Safe Tbk',
    full_name: 'Steady Safe Tbk',
    description: 'Steady Safe Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SAGE',
    name: 'Saptausaha Gemilangindah Tbk.',
    full_name: 'Saptausaha Gemilangindah Tbk.',
    description: 'Saptausaha Gemilangindah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SAME',
    name: 'Sarana Meditama Metropolitan Tbk.',
    full_name: 'Sarana Meditama Metropolitan Tbk.',
    description: 'Sarana Meditama Metropolitan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SAME-R',
    name: 'HMETD Sarana Meditama Metropolitan',
    full_name: 'HMETD Sarana Meditama Metropolitan',
    description: 'HMETD Sarana Meditama Metropolitan',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SAMF',
    name: 'Saraswanti Anugerah Makmur Tbk.',
    full_name: 'Saraswanti Anugerah Makmur Tbk.',
    description: 'Saraswanti Anugerah Makmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SAPX',
    name: 'Satria Antaran Prima Tbk.',
    full_name: 'Satria Antaran Prima Tbk.',
    description: 'Satria Antaran Prima Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SATU',
    name: 'Kota Satu Properti Tbk.',
    full_name: 'Kota Satu Properti Tbk.',
    description: 'Kota Satu Properti Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SBAT',
    name: 'Sejahtera Bintang Abadi Textile Tbk.',
    full_name: 'Sejahtera Bintang Abadi Textile Tbk.',
    description: 'Sejahtera Bintang Abadi Textile Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SBAT-W',
    name: 'Waran Seri I Sejahtera Bintang Abadi Tex',
    full_name: 'Waran Seri I Sejahtera Bintang Abadi Tex',
    description: 'Waran Seri I Sejahtera Bintang Abadi Tex',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SBMA',
    name: 'Surya Biru Murni Acetylene Tbk.',
    full_name: 'Surya Biru Murni Acetylene Tbk.',
    description: 'Surya Biru Murni Acetylene Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SBMA-W',
    name: 'Waran Seri I Surya Biru Murni Acetylene',
    full_name: 'Waran Seri I Surya Biru Murni Acetylene',
    description: 'Waran Seri I Surya Biru Murni Acetylene',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SCCO',
    name: 'Supreme Cable Manufacturing & Commerce Tbk.',
    full_name: 'Supreme Cable Manufacturing & Commerce Tbk.',
    description: 'Supreme Cable Manufacturing & Commerce Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SCMA',
    name: 'Surya Citra Media Tbk.',
    full_name: 'Surya Citra Media Tbk.',
    description: 'Surya Citra Media Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SCNP',
    name: 'Selaras Citra Nusantara Perkasa Tbk.',
    full_name: 'Selaras Citra Nusantara Perkasa Tbk.',
    description: 'Selaras Citra Nusantara Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SCPI',
    name: 'Organon Pharma Indonesia Tbk.',
    full_name: 'Organon Pharma Indonesia Tbk.',
    description: 'Organon Pharma Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SDMU',
    name: 'Sidomulyo Selaras Tbk.',
    full_name: 'Sidomulyo Selaras Tbk.',
    description: 'Sidomulyo Selaras Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SDPC',
    name: 'Millennium Pharmacon International Tbk.',
    full_name: 'Millennium Pharmacon International Tbk.',
    description: 'Millennium Pharmacon International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SDPC-R',
    name: 'HMETD Millenium Pharmacon International',
    full_name: 'HMETD Millenium Pharmacon International',
    description: 'HMETD Millenium Pharmacon International',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SDRA',
    name: 'Bank Woori Saudara Indonesia 1906 Tbk.',
    full_name: 'Bank Woori Saudara Indonesia 1906 Tbk.',
    description: 'Bank Woori Saudara Indonesia 1906 Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SDRA-R',
    name: 'HMETD Bank Woori Saudara Indonesia 1906',
    full_name: 'HMETD Bank Woori Saudara Indonesia 1906',
    description: 'HMETD Bank Woori Saudara Indonesia 1906',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SEMA',
    name: 'Semacom Integrated Tbk.',
    full_name: 'Semacom Integrated Tbk.',
    description: 'Semacom Integrated Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SFAN',
    name: 'Surya Fajar Capital Tbk.',
    full_name: 'Surya Fajar Capital Tbk.',
    description: 'Surya Fajar Capital Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SFAN-W',
    name: 'Waran Seri I Surya Fajar Capital',
    full_name: 'Waran Seri I Surya Fajar Capital',
    description: 'Waran Seri I Surya Fajar Capital',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SGER',
    name: 'Sumber Global Energy Tbk.',
    full_name: 'Sumber Global Energy Tbk.',
    description: 'Sumber Global Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SGER-W',
    name: 'Waran Seri I Sumber Global Energy',
    full_name: 'Waran Seri I Sumber Global Energy',
    description: 'Waran Seri I Sumber Global Energy',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SGRO',
    name: 'Sampoerna Agro Tbk.',
    full_name: 'Sampoerna Agro Tbk.',
    description: 'Sampoerna Agro Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SHID',
    name: 'Hotel Sahid Jaya International Tbk.',
    full_name: 'Hotel Sahid Jaya International Tbk.',
    description: 'Hotel Sahid Jaya International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SHIP',
    name: 'Sillo Maritime Perdana Tbk.',
    full_name: 'Sillo Maritime Perdana Tbk.',
    description: 'Sillo Maritime Perdana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SICO',
    name: 'Sigma Energy Compressindo Tbk.',
    full_name: 'Sigma Energy Compressindo Tbk.',
    description: 'Sigma Energy Compressindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SIDO',
    name: 'Industri Jamu dan Farmasi Sido Muncul Tbk.',
    full_name: 'Industri Jamu dan Farmasi Sido Muncul Tbk.',
    description: 'Industri Jamu dan Farmasi Sido Muncul Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SILO',
    name: 'Siloam International Hospitals Tbk.',
    full_name: 'Siloam International Hospitals Tbk.',
    description: 'Siloam International Hospitals Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SILO-R',
    name: 'HMETD Siloam International Hospitals',
    full_name: 'HMETD Siloam International Hospitals',
    description: 'HMETD Siloam International Hospitals',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SIMA',
    name: 'Siwani Makmur Tbk',
    full_name: 'Siwani Makmur Tbk',
    description: 'Siwani Makmur Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SIMP',
    name: 'Salim Ivomas Pratama Tbk.',
    full_name: 'Salim Ivomas Pratama Tbk.',
    description: 'Salim Ivomas Pratama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SINI',
    name: 'Singaraja Putra Tbk.',
    full_name: 'Singaraja Putra Tbk.',
    description: 'Singaraja Putra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SINI-W',
    name: 'Waran Seri I Singaraja Putra',
    full_name: 'Waran Seri I Singaraja Putra',
    description: 'Waran Seri I Singaraja Putra',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SIPD',
    name: 'Sreeya Sewu Indonesia Tbk.',
    full_name: 'Sreeya Sewu Indonesia Tbk.',
    description: 'Sreeya Sewu Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SIPD-R',
    name: 'HMETD Sierad Produce',
    full_name: 'HMETD Sierad Produce',
    description: 'HMETD Sierad Produce',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SKBM',
    name: 'Sekar Bumi Tbk.',
    full_name: 'Sekar Bumi Tbk.',
    description: 'Sekar Bumi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SKBM-R',
    name: 'HMETD Sekar Bumi',
    full_name: 'HMETD Sekar Bumi',
    description: 'HMETD Sekar Bumi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SKLT',
    name: 'Sekar Laut Tbk.',
    full_name: 'Sekar Laut Tbk.',
    description: 'Sekar Laut Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SKRN',
    name: 'Superkrane Mitra Utama Tbk.',
    full_name: 'Superkrane Mitra Utama Tbk.',
    description: 'Superkrane Mitra Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SKYB',
    name: 'Northcliff Citranusa Indonesia Tbk.',
    full_name: 'Northcliff Citranusa Indonesia Tbk.',
    description: 'Northcliff Citranusa Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SLIS',
    name: 'Gaya Abadi Sempurna Tbk.',
    full_name: 'Gaya Abadi Sempurna Tbk.',
    description: 'Gaya Abadi Sempurna Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMAR',
    name: 'Smart Tbk.',
    full_name: 'Smart Tbk.',
    description: 'Smart Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMBR',
    name: 'Semen Baturaja Tbk.',
    full_name: 'Semen Baturaja Tbk.',
    description: 'Semen Baturaja Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMCB',
    name: 'Solusi Bangun Indonesia Tbk.',
    full_name: 'Solusi Bangun Indonesia Tbk.',
    description: 'Solusi Bangun Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMCB-R',
    name: 'HMETD Solusi Bangun Indonesia',
    full_name: 'HMETD Solusi Bangun Indonesia',
    description: 'HMETD Solusi Bangun Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMDM',
    name: 'Suryamas Dutamakmur Tbk.',
    full_name: 'Suryamas Dutamakmur Tbk.',
    description: 'Suryamas Dutamakmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMDR',
    name: 'Samudera Indonesia  Tbk.',
    full_name: 'Samudera Indonesia  Tbk.',
    description: 'Samudera Indonesia  Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMGR',
    name: 'Semen Indonesia (Persero) Tbk.',
    full_name: 'Semen Indonesia (Persero) Tbk.',
    description: 'Semen Indonesia (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMIL',
    name: 'Sarana Mitra Luas Tbk.',
    full_name: 'Sarana Mitra Luas Tbk.',
    description: 'Sarana Mitra Luas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMKL',
    name: 'Satyamitra Kemas Lestari Tbk.',
    full_name: 'Satyamitra Kemas Lestari Tbk.',
    description: 'Satyamitra Kemas Lestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMKL-W',
    name: 'Waran Seri I Satyamitra Kemas Lestari Tb',
    full_name: 'Waran Seri I Satyamitra Kemas Lestari Tb',
    description: 'Waran Seri I Satyamitra Kemas Lestari Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMKM',
    name: 'Sumber Mas Konstruksi Tbk.',
    full_name: 'Sumber Mas Konstruksi Tbk.',
    description: 'Sumber Mas Konstruksi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMMA',
    name: 'Sinarmas Multiartha Tbk.',
    full_name: 'Sinarmas Multiartha Tbk.',
    description: 'Sinarmas Multiartha Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMMT',
    name: 'Golden Eagle Energy Tbk.',
    full_name: 'Golden Eagle Energy Tbk.',
    description: 'Golden Eagle Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMRA',
    name: 'Summarecon Agung Tbk.',
    full_name: 'Summarecon Agung Tbk.',
    description: 'Summarecon Agung Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMRA-R',
    name: 'HMETD Summarecon Agung',
    full_name: 'HMETD Summarecon Agung',
    description: 'HMETD Summarecon Agung',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMRU',
    name: 'SMR Utama Tbk.',
    full_name: 'SMR Utama Tbk.',
    description: 'SMR Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMRU-W',
    name: 'Waran Seri I SMR Utama',
    full_name: 'Waran Seri I SMR Utama',
    description: 'Waran Seri I SMR Utama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SMSM',
    name: 'Selamat Sempurna Tbk.',
    full_name: 'Selamat Sempurna Tbk.',
    description: 'Selamat Sempurna Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SNLK',
    name: 'Sunter Lakeside Hotel Tbk.',
    full_name: 'Sunter Lakeside Hotel Tbk.',
    description: 'Sunter Lakeside Hotel Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOCI',
    name: 'Soechi Lines Tbk.',
    full_name: 'Soechi Lines Tbk.',
    description: 'Soechi Lines Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOFA',
    name: 'Boston Furniture Industries Tbk.',
    full_name: 'Boston Furniture Industries Tbk.',
    description: 'Boston Furniture Industries Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOFA-W',
    name: 'Waran Seri I Boston Furniture Industries',
    full_name: 'Waran Seri I Boston Furniture Industries',
    description: 'Waran Seri I Boston Furniture Industries',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOHO',
    name: 'Soho Global Health Tbk.',
    full_name: 'Soho Global Health Tbk.',
    description: 'Soho Global Health Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SONA',
    name: 'Sona Topas Tourism Industry Tbk.',
    full_name: 'Sona Topas Tourism Industry Tbk.',
    description: 'Sona Topas Tourism Industry Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOSS',
    name: 'Shield On Service Tbk.',
    full_name: 'Shield On Service Tbk.',
    description: 'Shield On Service Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOSS-W',
    name: 'Waran Seri I Shield On Service',
    full_name: 'Waran Seri I Shield On Service',
    description: 'Waran Seri I Shield On Service',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOTS',
    name: 'Satria Mega Kencana Tbk.',
    full_name: 'Satria Mega Kencana Tbk.',
    description: 'Satria Mega Kencana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOTS-W',
    name: 'Waran Seri I Satria Mega Kencana',
    full_name: 'Waran Seri I Satria Mega Kencana',
    description: 'Waran Seri I Satria Mega Kencana',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SOUL',
    name: 'Mitra Tirta Buwana Tbk.',
    full_name: 'Mitra Tirta Buwana Tbk.',
    description: 'Mitra Tirta Buwana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SPMA',
    name: 'Suparma Tbk.',
    full_name: 'Suparma Tbk.',
    description: 'Suparma Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SPTO',
    name: 'Surya Pertiwi Tbk.',
    full_name: 'Surya Pertiwi Tbk.',
    description: 'Surya Pertiwi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SQMI',
    name: 'Wilton Makmur Indonesia Tbk.',
    full_name: 'Wilton Makmur Indonesia Tbk.',
    description: 'Wilton Makmur Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SQMI-R',
    name: 'HMETD Renuka Coalindo',
    full_name: 'HMETD Renuka Coalindo',
    description: 'HMETD Renuka Coalindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SRAJ',
    name: 'Sejahteraraya Anugrahjaya Tbk.',
    full_name: 'Sejahteraraya Anugrahjaya Tbk.',
    description: 'Sejahteraraya Anugrahjaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SRAJ-R',
    name: 'HMETD Sejahteraraya Anugrahjaya',
    full_name: 'HMETD Sejahteraraya Anugrahjaya',
    description: 'HMETD Sejahteraraya Anugrahjaya',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SRIL',
    name: 'Sri Rejeki Isman Tbk.',
    full_name: 'Sri Rejeki Isman Tbk.',
    description: 'Sri Rejeki Isman Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SRSN',
    name: 'Indo Acidatama Tbk',
    full_name: 'Indo Acidatama Tbk',
    description: 'Indo Acidatama Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SRTG',
    name: 'Saratoga Investama Sedaya Tbk.',
    full_name: 'Saratoga Investama Sedaya Tbk.',
    description: 'Saratoga Investama Sedaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SSIA',
    name: 'Surya Semesta Internusa Tbk.',
    full_name: 'Surya Semesta Internusa Tbk.',
    description: 'Surya Semesta Internusa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SSMS',
    name: 'Sawit Sumbermas Sarana Tbk.',
    full_name: 'Sawit Sumbermas Sarana Tbk.',
    description: 'Sawit Sumbermas Sarana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SSTM',
    name: 'Sunson Textile Manufacture Tbk',
    full_name: 'Sunson Textile Manufacture Tbk',
    description: 'Sunson Textile Manufacture Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'STAA',
    name: 'Sumber Tani Agung Resources Tbk.',
    full_name: 'Sumber Tani Agung Resources Tbk.',
    description: 'Sumber Tani Agung Resources Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'STAR',
    name: 'Buana Artha Anugerah Tbk.',
    full_name: 'Buana Artha Anugerah Tbk.',
    description: 'Buana Artha Anugerah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'STAR-W',
    name: 'Waran Seri I  Star Petrochem',
    full_name: 'Waran Seri I  Star Petrochem',
    description: 'Waran Seri I  Star Petrochem',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'STTP',
    name: 'Siantar Top Tbk.',
    full_name: 'Siantar Top Tbk.',
    description: 'Siantar Top Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SUGI',
    name: 'Sugih Energy Tbk.',
    full_name: 'Sugih Energy Tbk.',
    description: 'Sugih Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SUGI-W',
    name: 'Waran Seri II Sugih Energy',
    full_name: 'Waran Seri II Sugih Energy',
    description: 'Waran Seri II Sugih Energy',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SULI',
    name: 'SLJ Global Tbk.',
    full_name: 'SLJ Global Tbk.',
    description: 'SLJ Global Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SUNI',
    name: 'Sunindo Pratama Tbk.',
    full_name: 'Sunindo Pratama Tbk.',
    description: 'Sunindo Pratama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SUPR',
    name: 'Solusi Tunas Pratama Tbk.',
    full_name: 'Solusi Tunas Pratama Tbk.',
    description: 'Solusi Tunas Pratama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SUPR-W',
    name: 'Waran Seri I Solusi Tunas Pratama',
    full_name: 'Waran Seri I Solusi Tunas Pratama',
    description: 'Waran Seri I Solusi Tunas Pratama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SURE',
    name: 'Super Energy Tbk.',
    full_name: 'Super Energy Tbk.',
    description: 'Super Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SWAT',
    name: 'Sriwahana Adityakarta Tbk.',
    full_name: 'Sriwahana Adityakarta Tbk.',
    description: 'Sriwahana Adityakarta Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SWID',
    name: 'Saraswanti Indoland Development Tbk.',
    full_name: 'Saraswanti Indoland Development Tbk.',
    description: 'Saraswanti Indoland Development Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TALF',
    name: 'Tunas Alfin Tbk.',
    full_name: 'Tunas Alfin Tbk.',
    description: 'Tunas Alfin Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TAMA',
    name: 'Lancartama Sejati Tbk.',
    full_name: 'Lancartama Sejati Tbk.',
    description: 'Lancartama Sejati Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TAMA-W',
    name: 'Waran Seri I Lancartama Sejati',
    full_name: 'Waran Seri I Lancartama Sejati',
    description: 'Waran Seri I Lancartama Sejati',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TAMU',
    name: 'Pelayaran Tamarin Samudra Tbk.',
    full_name: 'Pelayaran Tamarin Samudra Tbk.',
    description: 'Pelayaran Tamarin Samudra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TAPG',
    name: 'Triputra Agro Persada Tbk.',
    full_name: 'Triputra Agro Persada Tbk.',
    description: 'Triputra Agro Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TARA',
    name: 'Agung Semesta Sejahtera Tbk.',
    full_name: 'Agung Semesta Sejahtera Tbk.',
    description: 'Agung Semesta Sejahtera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TARA-W',
    name: 'Waran Seri I Sitara Propertindo',
    full_name: 'Waran Seri I Sitara Propertindo',
    description: 'Waran Seri I Sitara Propertindo',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TAXI',
    name: 'Express Transindo Utama Tbk.',
    full_name: 'Express Transindo Utama Tbk.',
    description: 'Express Transindo Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TAYS',
    name: 'Jaya Swarasa Agung Tbk.',
    full_name: 'Jaya Swarasa Agung Tbk.',
    description: 'Jaya Swarasa Agung Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TBIG',
    name: 'Tower Bersama Infrastructure Tbk.',
    full_name: 'Tower Bersama Infrastructure Tbk.',
    description: 'Tower Bersama Infrastructure Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TBLA',
    name: 'Tunas Baru Lampung Tbk.',
    full_name: 'Tunas Baru Lampung Tbk.',
    description: 'Tunas Baru Lampung Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TBMS',
    name: 'Tembaga Mulia Semanan Tbk.',
    full_name: 'Tembaga Mulia Semanan Tbk.',
    description: 'Tembaga Mulia Semanan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TCID',
    name: 'Mandom Indonesia Tbk.',
    full_name: 'Mandom Indonesia Tbk.',
    description: 'Mandom Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TCPI',
    name: 'Transcoal Pacific Tbk.',
    full_name: 'Transcoal Pacific Tbk.',
    description: 'Transcoal Pacific Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TDPM',
    name: 'Tridomain Performance Materials Tbk.',
    full_name: 'Tridomain Performance Materials Tbk.',
    description: 'Tridomain Performance Materials Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TEBE',
    name: 'Dana Brata Luhur Tbk.',
    full_name: 'Dana Brata Luhur Tbk.',
    description: 'Dana Brata Luhur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TECH',
    name: 'Indosterling Technomedia Tbk.',
    full_name: 'Indosterling Technomedia Tbk.',
    description: 'Indosterling Technomedia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TELE',
    name: 'Omni Inovasi Indonesia Tbk.',
    full_name: 'Omni Inovasi Indonesia Tbk.',
    description: 'Omni Inovasi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TELE-W',
    name: 'Waran Seri I Tiphone Mobile Indonesia Tb',
    full_name: 'Waran Seri I Tiphone Mobile Indonesia Tb',
    description: 'Waran Seri I Tiphone Mobile Indonesia Tb',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TFAS',
    name: 'Telefast Indonesia Tbk.',
    full_name: 'Telefast Indonesia Tbk.',
    description: 'Telefast Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TFCO',
    name: 'Tifico Fiber Indonesia Tbk.',
    full_name: 'Tifico Fiber Indonesia Tbk.',
    description: 'Tifico Fiber Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TGKA',
    name: 'Tigaraksa Satria Tbk.',
    full_name: 'Tigaraksa Satria Tbk.',
    description: 'Tigaraksa Satria Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TGRA',
    name: 'Terregra Asia Energy Tbk.',
    full_name: 'Terregra Asia Energy Tbk.',
    description: 'Terregra Asia Energy Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TIFA',
    name: 'KDB Tifa Finance Tbk.',
    full_name: 'KDB Tifa Finance Tbk.',
    description: 'KDB Tifa Finance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TIFA-R',
    name: 'HMETD KDB Tifa Finance',
    full_name: 'HMETD KDB Tifa Finance',
    description: 'HMETD KDB Tifa Finance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TINS',
    name: 'Timah Tbk.',
    full_name: 'Timah Tbk.',
    description: 'Timah Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TIRA',
    name: 'Tira Austenite Tbk',
    full_name: 'Tira Austenite Tbk',
    description: 'Tira Austenite Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TIRT',
    name: 'Tirta Mahakam Resources Tbk',
    full_name: 'Tirta Mahakam Resources Tbk',
    description: 'Tirta Mahakam Resources Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TKIM',
    name: 'Pabrik Kertas Tjiwi Kimia Tbk.',
    full_name: 'Pabrik Kertas Tjiwi Kimia Tbk.',
    description: 'Pabrik Kertas Tjiwi Kimia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TKIM-W',
    name: 'Waran Seri II Pabrik Kertas Tjiwi Kimia',
    full_name: 'Waran Seri II Pabrik Kertas Tjiwi Kimia',
    description: 'Waran Seri II Pabrik Kertas Tjiwi Kimia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TLDN',
    name: 'Teladan Prima Agro Tbk.',
    full_name: 'Teladan Prima Agro Tbk.',
    description: 'Teladan Prima Agro Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TLKM',
    name: 'Telkom Indonesia (Persero) Tbk.',
    full_name: 'Telkom Indonesia (Persero) Tbk.',
    description: 'Telkom Indonesia (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TMAS',
    name: 'Temas Tbk.',
    full_name: 'Temas Tbk.',
    description: 'Temas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TMPO',
    name: 'Tempo Intimedia Tbk.',
    full_name: 'Tempo Intimedia Tbk.',
    description: 'Tempo Intimedia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TMPO-R',
    name: 'HMETD Tempo Intimedia',
    full_name: 'HMETD Tempo Intimedia',
    description: 'HMETD Tempo Intimedia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TNCA',
    name: 'Trimuda Nuansa Citra Tbk.',
    full_name: 'Trimuda Nuansa Citra Tbk.',
    description: 'Trimuda Nuansa Citra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TOBA',
    name: 'TBS Energi Utama Tbk.',
    full_name: 'TBS Energi Utama Tbk.',
    description: 'TBS Energi Utama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TOOL',
    name: 'Rohartindo Nusantara Luas Tbk.',
    full_name: 'Rohartindo Nusantara Luas Tbk.',
    description: 'Rohartindo Nusantara Luas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TOPS',
    name: 'Totalindo Eka Persada Tbk.',
    full_name: 'Totalindo Eka Persada Tbk.',
    description: 'Totalindo Eka Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TOTL',
    name: 'Total Bangun Persada Tbk.',
    full_name: 'Total Bangun Persada Tbk.',
    description: 'Total Bangun Persada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TOTO',
    name: 'Surya Toto Indonesia Tbk.',
    full_name: 'Surya Toto Indonesia Tbk.',
    description: 'Surya Toto Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TOWR',
    name: 'Sarana Menara Nusantara Tbk.',
    full_name: 'Sarana Menara Nusantara Tbk.',
    description: 'Sarana Menara Nusantara Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TOYS',
    name: 'Sunindo Adipersada Tbk.',
    full_name: 'Sunindo Adipersada Tbk.',
    description: 'Sunindo Adipersada Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TOYS-W',
    name: 'Waran Seri I Sunindo Adipersada',
    full_name: 'Waran Seri I Sunindo Adipersada',
    description: 'Waran Seri I Sunindo Adipersada',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TPIA',
    name: 'Chandra Asri Petrochemical Tbk.',
    full_name: 'Chandra Asri Petrochemical Tbk.',
    description: 'Chandra Asri Petrochemical Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TPIA-R',
    name: 'HMETD Chandra Asri Petrochemical',
    full_name: 'HMETD Chandra Asri Petrochemical',
    description: 'HMETD Chandra Asri Petrochemical',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TPMA',
    name: 'Trans Power Marine Tbk.',
    full_name: 'Trans Power Marine Tbk.',
    description: 'Trans Power Marine Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRAM',
    name: 'Trada Alam Minera Tbk.',
    full_name: 'Trada Alam Minera Tbk.',
    description: 'Trada Alam Minera Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRAM-R',
    name: 'HMETD Trada Alam Minera',
    full_name: 'HMETD Trada Alam Minera',
    description: 'HMETD Trada Alam Minera',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRAM-W',
    name: 'Waran Seri II Trada Alam Minera',
    full_name: 'Waran Seri II Trada Alam Minera',
    description: 'Waran Seri II Trada Alam Minera',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRGU',
    name: 'Cerestar Indonesia Tbk.',
    full_name: 'Cerestar Indonesia Tbk.',
    description: 'Cerestar Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRIL',
    name: 'Triwira Insanlestari Tbk.',
    full_name: 'Triwira Insanlestari Tbk.',
    description: 'Triwira Insanlestari Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRIM',
    name: 'Trimegah Sekuritas Indonesia Tbk.',
    full_name: 'Trimegah Sekuritas Indonesia Tbk.',
    description: 'Trimegah Sekuritas Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRIN',
    name: 'Perintis Triniti Properti Tbk.',
    full_name: 'Perintis Triniti Properti Tbk.',
    description: 'Perintis Triniti Properti Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRIN-W',
    name: 'Waran Seri I Perintis Triniti Properti T',
    full_name: 'Waran Seri I Perintis Triniti Properti T',
    description: 'Waran Seri I Perintis Triniti Properti T',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRIO',
    name: 'Trikomsel Oke Tbk.',
    full_name: 'Trikomsel Oke Tbk.',
    description: 'Trikomsel Oke Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRIS',
    name: 'Trisula International Tbk.',
    full_name: 'Trisula International Tbk.',
    description: 'Trisula International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRIS-R',
    name: 'HMETD Trisula International',
    full_name: 'HMETD Trisula International',
    description: 'HMETD Trisula International',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRIS-W',
    name: 'Waran Seri I Trisula International',
    full_name: 'Waran Seri I Trisula International',
    description: 'Waran Seri I Trisula International',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRJA',
    name: 'Transkon Jaya Tbk.',
    full_name: 'Transkon Jaya Tbk.',
    description: 'Transkon Jaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRON',
    name: 'Teknologi Karya Digital Nusa Tbk.',
    full_name: 'Teknologi Karya Digital Nusa Tbk.',
    description: 'Teknologi Karya Digital Nusa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRST',
    name: 'Trias Sentosa Tbk.',
    full_name: 'Trias Sentosa Tbk.',
    description: 'Trias Sentosa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRUE',
    name: 'Triniti Dinamik Tbk.',
    full_name: 'Triniti Dinamik Tbk.',
    description: 'Triniti Dinamik Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRUE-W',
    name: 'Waran Seri I Triniti Dinamik',
    full_name: 'Waran Seri I Triniti Dinamik',
    description: 'Waran Seri I Triniti Dinamik',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRUK',
    name: 'Guna Timur Raya Tbk.',
    full_name: 'Guna Timur Raya Tbk.',
    description: 'Guna Timur Raya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TRUS',
    name: 'Trust Finance Indonesia Tbk',
    full_name: 'Trust Finance Indonesia Tbk',
    description: 'Trust Finance Indonesia Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TSPC',
    name: 'Tempo Scan Pacific Tbk.',
    full_name: 'Tempo Scan Pacific Tbk.',
    description: 'Tempo Scan Pacific Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TUGU',
    name: 'Asuransi Tugu Pratama Indonesia Tbk.',
    full_name: 'Asuransi Tugu Pratama Indonesia Tbk.',
    description: 'Asuransi Tugu Pratama Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TURI',
    name: 'Tunas Ridean Tbk.',
    full_name: 'Tunas Ridean Tbk.',
    description: 'Tunas Ridean Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'TYRE',
    name: 'King Tire Indonesia Tbk.',
    full_name: 'King Tire Indonesia Tbk.',
    description: 'King Tire Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UANG',
    name: 'Pakuan Tbk.',
    full_name: 'Pakuan Tbk.',
    description: 'Pakuan Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UCID',
    name: 'Uni-Charm Indonesia Tbk.',
    full_name: 'Uni-Charm Indonesia Tbk.',
    description: 'Uni-Charm Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UFOE',
    name: 'Damai Sejahtera Abadi Tbk.',
    full_name: 'Damai Sejahtera Abadi Tbk.',
    description: 'Damai Sejahtera Abadi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ULTJ',
    name: 'Ultra Jaya Milk Industry & Trading Company, Tbk.',
    full_name: 'Ultra Jaya Milk Industry & Trading Company, Tbk.',
    description: 'Ultra Jaya Milk Industry & Trading Company, Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UNIC',
    name: 'Unggul Indah Cahaya Tbk.',
    full_name: 'Unggul Indah Cahaya Tbk.',
    description: 'Unggul Indah Cahaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UNIQ',
    name: 'Ulima Nitra Tbk.',
    full_name: 'Ulima Nitra Tbk.',
    description: 'Ulima Nitra Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UNIT',
    name: 'Nusantara Inti Corpora Tbk',
    full_name: 'Nusantara Inti Corpora Tbk',
    description: 'Nusantara Inti Corpora Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UNSP',
    name: 'Bakrie Sumatera Plantations Tbk.',
    full_name: 'Bakrie Sumatera Plantations Tbk.',
    description: 'Bakrie Sumatera Plantations Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UNTR',
    name: 'United Tractors Tbk.',
    full_name: 'United Tractors Tbk.',
    description: 'United Tractors Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UNVR',
    name: 'Unilever Indonesia Tbk.',
    full_name: 'Unilever Indonesia Tbk.',
    description: 'Unilever Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'URBN',
    name: 'Urban Jakarta Propertindo Tbk.',
    full_name: 'Urban Jakarta Propertindo Tbk.',
    description: 'Urban Jakarta Propertindo Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'URBN-W',
    name: 'Waran Seri I Urban Jakarta Propertindo T',
    full_name: 'Waran Seri I Urban Jakarta Propertindo T',
    description: 'Waran Seri I Urban Jakarta Propertindo T',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UVCR',
    name: 'Trimegah Karya Pratama Tbk.',
    full_name: 'Trimegah Karya Pratama Tbk.',
    description: 'Trimegah Karya Pratama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'UVCR-W',
    name: 'Waran Seri I Trimegah Karya Pratama',
    full_name: 'Waran Seri I Trimegah Karya Pratama',
    description: 'Waran Seri I Trimegah Karya Pratama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VAST',
    name: 'Vastland Indonesia Tbk.',
    full_name: 'Vastland Indonesia Tbk.',
    description: 'Vastland Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VICI',
    name: 'Victoria Care Indonesia Tbk.',
    full_name: 'Victoria Care Indonesia Tbk.',
    description: 'Victoria Care Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VICO',
    name: 'Victoria Investama Tbk.',
    full_name: 'Victoria Investama Tbk.',
    description: 'Victoria Investama Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VICO-W',
    name: 'Waran Seri I Victoria Investama',
    full_name: 'Waran Seri I Victoria Investama',
    description: 'Waran Seri I Victoria Investama',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VINS',
    name: 'Victoria Insurance Tbk.',
    full_name: 'Victoria Insurance Tbk.',
    description: 'Victoria Insurance Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VINS-W',
    name: 'Waran Seri I Victoria Insurance',
    full_name: 'Waran Seri I Victoria Insurance',
    description: 'Waran Seri I Victoria Insurance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VIVA',
    name: 'Visi Media Asia Tbk.',
    full_name: 'Visi Media Asia Tbk.',
    description: 'Visi Media Asia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VIVA-W',
    name: 'Waran Seri I Visi Media Asia',
    full_name: 'Waran Seri I Visi Media Asia',
    description: 'Waran Seri I Visi Media Asia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VKTR',
    name: 'VKTR Teknologi Mobilitas Tbk',
    full_name: 'VKTR Teknologi Mobilitas Tbk',
    description: 'VKTR Teknologi Mobilitas Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VOKS',
    name: 'Voksel Electric Tbk.',
    full_name: 'Voksel Electric Tbk.',
    description: 'Voksel Electric Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VRNA',
    name: 'Mizuho Leasing Indonesia Tbk.',
    full_name: 'Mizuho Leasing Indonesia Tbk.',
    description: 'Mizuho Leasing Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VRNA-R',
    name: 'HMETD Verena Multi Finance',
    full_name: 'HMETD Verena Multi Finance',
    description: 'HMETD Verena Multi Finance',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'VTNY',
    name: 'Venteny Fortuna International Tbk.',
    full_name: 'Venteny Fortuna International Tbk.',
    description: 'Venteny Fortuna International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WAPO',
    name: 'Wahana Pronatural Tbk.',
    full_name: 'Wahana Pronatural Tbk.',
    description: 'Wahana Pronatural Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WAPO-R',
    name: 'HMETD Wahana Pronatural',
    full_name: 'HMETD Wahana Pronatural',
    description: 'HMETD Wahana Pronatural',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WEGE',
    name: 'Wijaya Karya Bangunan Gedung Tbk.',
    full_name: 'Wijaya Karya Bangunan Gedung Tbk.',
    description: 'Wijaya Karya Bangunan Gedung Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WEHA',
    name: 'WEHA Transportasi Indonesia Tbk.',
    full_name: 'WEHA Transportasi Indonesia Tbk.',
    description: 'WEHA Transportasi Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WEHA-W',
    name: 'Waran Seri II WEHA Transportasi Indonesi',
    full_name: 'Waran Seri II WEHA Transportasi Indonesi',
    description: 'Waran Seri II WEHA Transportasi Indonesi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WGSH',
    name: 'Wira Global Solusi Tbk.',
    full_name: 'Wira Global Solusi Tbk.',
    description: 'Wira Global Solusi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WICO',
    name: 'Wicaksana Overseas International Tbk.',
    full_name: 'Wicaksana Overseas International Tbk.',
    description: 'Wicaksana Overseas International Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WIFI',
    name: 'Solusi Sinergi Digital Tbk.',
    full_name: 'Solusi Sinergi Digital Tbk.',
    description: 'Solusi Sinergi Digital Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WIFI-W',
    name: 'Waran Seri I Solusi Sinergi Digital',
    full_name: 'Waran Seri I Solusi Sinergi Digital',
    description: 'Waran Seri I Solusi Sinergi Digital',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WIIM',
    name: 'Wismilak Inti Makmur Tbk.',
    full_name: 'Wismilak Inti Makmur Tbk.',
    description: 'Wismilak Inti Makmur Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WIKA',
    name: 'Wijaya Karya (Persero) Tbk.',
    full_name: 'Wijaya Karya (Persero) Tbk.',
    description: 'Wijaya Karya (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WIKA-R',
    name: 'HMETD Wijaya Karya ',
    full_name: 'HMETD Wijaya Karya ',
    description: 'HMETD Wijaya Karya ',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WINE',
    name: 'Hatten Bali Tbk.',
    full_name: 'Hatten Bali Tbk.',
    description: 'Hatten Bali Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WINR',
    name: 'Winner Nusantara Jaya Tbk.',
    full_name: 'Winner Nusantara Jaya Tbk.',
    description: 'Winner Nusantara Jaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WINS',
    name: 'Wintermar Offshore Marine Tbk.',
    full_name: 'Wintermar Offshore Marine Tbk.',
    description: 'Wintermar Offshore Marine Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WIRG',
    name: 'WIR ASIA Tbk.',
    full_name: 'WIR ASIA Tbk.',
    description: 'WIR ASIA Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WMPP',
    name: 'Widodo Makmur Perkasa Tbk.',
    full_name: 'Widodo Makmur Perkasa Tbk.',
    description: 'Widodo Makmur Perkasa Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WMUU',
    name: 'Widodo Makmur Unggas Tbk.',
    full_name: 'Widodo Makmur Unggas Tbk.',
    description: 'Widodo Makmur Unggas Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WOMF',
    name: 'Wahana Ottomitra Multiartha Tbk.',
    full_name: 'Wahana Ottomitra Multiartha Tbk.',
    description: 'Wahana Ottomitra Multiartha Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WOOD',
    name: 'Integra Indocabinet Tbk.',
    full_name: 'Integra Indocabinet Tbk.',
    description: 'Integra Indocabinet Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WOWS',
    name: 'Ginting Jaya Energi Tbk.',
    full_name: 'Ginting Jaya Energi Tbk.',
    description: 'Ginting Jaya Energi Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WSBP',
    name: 'Waskita Beton Precast Tbk.',
    full_name: 'Waskita Beton Precast Tbk.',
    description: 'Waskita Beton Precast Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WSKT',
    name: 'Waskita Karya (Persero) Tbk.',
    full_name: 'Waskita Karya (Persero) Tbk.',
    description: 'Waskita Karya (Persero) Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'WTON',
    name: 'Wijaya Karya Beton Tbk.',
    full_name: 'Wijaya Karya Beton Tbk.',
    description: 'Wijaya Karya Beton Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XAFA',
    name: 'Reksa Dana Avrist ETF Fixed Rate Bond I',
    full_name: 'Reksa Dana Avrist ETF Fixed Rate Bond I',
    description: 'Reksa Dana Avrist ETF Fixed Rate Bond I',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XAQA',
    name: 'Reksa Dana Ashmore ETF LQ45 ALPHA',
    full_name: 'Reksa Dana Ashmore ETF LQ45 ALPHA',
    description: 'Reksa Dana Ashmore ETF LQ45 ALPHA',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XASG',
    name: 'Reksa Dana Indeks Aurora ETF FTSE Indone',
    full_name: 'Reksa Dana Indeks Aurora ETF FTSE Indone',
    description: 'Reksa Dana Indeks Aurora ETF FTSE Indone',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XBES',
    name: 'Reksa Dana Indeks BNI-AM ETF MSCI ESG Le',
    full_name: 'Reksa Dana Indeks BNI-AM ETF MSCI ESG Le',
    description: 'Reksa Dana Indeks BNI-AM ETF MSCI ESG Le',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XBID',
    name: 'Reksa Dana Indeks Batavia IDX30 ETF',
    full_name: 'Reksa Dana Indeks Batavia IDX30 ETF',
    description: 'Reksa Dana Indeks Batavia IDX30 ETF',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XBIG',
    name: 'Reksa Dana Indeks BNP Paribas IDX Growth',
    full_name: 'Reksa Dana Indeks BNP Paribas IDX Growth',
    description: 'Reksa Dana Indeks BNP Paribas IDX Growth',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XBIN',
    name: 'Reksa Dana Indeks Bahana ETF Bisnis - 27',
    full_name: 'Reksa Dana Indeks Bahana ETF Bisnis - 27',
    description: 'Reksa Dana Indeks Bahana ETF Bisnis - 27',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XBLQ',
    name: 'Reksa Dana Batavia Smart Liquid ETF',
    full_name: 'Reksa Dana Batavia Smart Liquid ETF',
    description: 'Reksa Dana Batavia Smart Liquid ETF',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XBNI',
    name: 'Reksa Dana Indeks BNI-AM Nusantara ETF M',
    full_name: 'Reksa Dana Indeks BNI-AM Nusantara ETF M',
    description: 'Reksa Dana Indeks BNI-AM Nusantara ETF M',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XBSK',
    name: 'Reksa Dana Indeks Batavia SRI-KEHATI ETF',
    full_name: 'Reksa Dana Indeks Batavia SRI-KEHATI ETF',
    description: 'Reksa Dana Indeks Batavia SRI-KEHATI ETF',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XCEG',
    name: 'Reksa Dana Indeks Capital ETF IDX ESG Leaders               ',
    full_name: 'Reksa Dana Indeks Capital ETF IDX ESG Leaders               ',
    description: 'Reksa Dana Indeks Capital ETF IDX ESG Leaders               ',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XCID',
    name: 'DIRE Ciptadana Properti Ritel Indonesia',
    full_name: 'DIRE Ciptadana Properti Ritel Indonesia',
    description: 'DIRE Ciptadana Properti Ritel Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XCIS',
    name: 'DIRE Ciptadana Properti Perhotelan Padja',
    full_name: 'DIRE Ciptadana Properti Perhotelan Padja',
    description: 'DIRE Ciptadana Properti Perhotelan Padja',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XCLQ',
    name: 'Reksa Dana Indeks Cipta ETF Index LQ45',
    full_name: 'Reksa Dana Indeks Cipta ETF Index LQ45',
    description: 'Reksa Dana Indeks Cipta ETF Index LQ45',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XDIF',
    name: 'Reksa Dana Danareksa ETF Indonesia Top 4',
    full_name: 'Reksa Dana Danareksa ETF Indonesia Top 4',
    description: 'Reksa Dana Danareksa ETF Indonesia Top 4',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XDSG',
    name: 'Reksa Dana Indeks Danareksa ETF MSCI Ind',
    full_name: 'Reksa Dana Indeks Danareksa ETF MSCI Ind',
    description: 'Reksa Dana Indeks Danareksa ETF MSCI Ind',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XFES',
    name: 'Reksa Dana Indeks FWD Asset IDX ESG Lead',
    full_name: 'Reksa Dana Indeks FWD Asset IDX ESG Lead',
    description: 'Reksa Dana Indeks FWD Asset IDX ESG Lead',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIFE',
    name: 'Reksa Dana Indeks Premier ETF FTSE Indon',
    full_name: 'Reksa Dana Indeks Premier ETF FTSE Indon',
    description: 'Reksa Dana Indeks Premier ETF FTSE Indon',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIHD',
    name: 'Reksa Dana Indeks Premier ETF IDX High D',
    full_name: 'Reksa Dana Indeks Premier ETF IDX High D',
    description: 'Reksa Dana Indeks Premier ETF IDX High D',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIIC',
    name: 'Reksa Dana Premier ETF Indonesia Consume',
    full_name: 'Reksa Dana Premier ETF Indonesia Consume',
    description: 'Reksa Dana Premier ETF Indonesia Consume',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIID',
    name: 'Reksa Dana Indeks Premier ETF Index IDX3',
    full_name: 'Reksa Dana Indeks Premier ETF Index IDX3',
    description: 'Reksa Dana Indeks Premier ETF Index IDX3',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIIF',
    name: 'Reksa Dana Premier ETF Indonesia Financi',
    full_name: 'Reksa Dana Premier ETF Indonesia Financi',
    description: 'Reksa Dana Premier ETF Indonesia Financi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIIT',
    name: 'Reksa Dana Premier ETF IDX30',
    full_name: 'Reksa Dana Premier ETF IDX30',
    description: 'Reksa Dana Premier ETF IDX30',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIJI',
    name: 'Reksa Dana Syariah Premier ETF JII',
    full_name: 'Reksa Dana Syariah Premier ETF JII',
    description: 'Reksa Dana Syariah Premier ETF JII',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XILV',
    name: 'RDI Insight ETF FTSE Indonesia Low Volat',
    full_name: 'RDI Insight ETF FTSE Indonesia Low Volat',
    description: 'RDI Insight ETF FTSE Indonesia Low Volat',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIML',
    name: 'Reksa Dana Indeks Premier ETF MSCI Indon',
    full_name: 'Reksa Dana Indeks Premier ETF MSCI Indon',
    description: 'Reksa Dana Indeks Premier ETF MSCI Indon',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XIPI',
    name: 'Reksa Dana Indeks Premier ETF PEFINDO i-',
    full_name: 'Reksa Dana Indeks Premier ETF PEFINDO i-',
    description: 'Reksa Dana Indeks Premier ETF PEFINDO i-',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XISB',
    name: 'Reksa Dana Premier ETF Indonesia Soverei',
    full_name: 'Reksa Dana Premier ETF Indonesia Soverei',
    description: 'Reksa Dana Premier ETF Indonesia Soverei',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XISC',
    name: 'Reksa Dana Premier ETF Indonesia Stated-',
    full_name: 'Reksa Dana Premier ETF Indonesia Stated-',
    description: 'Reksa Dana Premier ETF Indonesia Stated-',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XISI',
    name: 'Premier ETF SMinfra18',
    full_name: 'Premier ETF SMinfra18',
    description: 'Premier ETF SMinfra18',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XISR',
    name: 'Premier ETF SRI-KEHATI',
    full_name: 'Premier ETF SRI-KEHATI',
    description: 'Premier ETF SRI-KEHATI',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XKIV',
    name: 'Reksa Dana Indeks KISI IDX Value30 ETF',
    full_name: 'Reksa Dana Indeks KISI IDX Value30 ETF',
    description: 'Reksa Dana Indeks KISI IDX Value30 ETF',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XKMS',
    name: 'Reksa Dana Indeks KISI MSCI Indonesia ET',
    full_name: 'Reksa Dana Indeks KISI MSCI Indonesia ET',
    description: 'Reksa Dana Indeks KISI MSCI Indonesia ET',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XMIG',
    name: 'Reksa Dana Indeks Majoris Pefindo I-Grad',
    full_name: 'Reksa Dana Indeks Majoris Pefindo I-Grad',
    description: 'Reksa Dana Indeks Majoris Pefindo I-Grad',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XMJM',
    name: 'DINFRA TOLL ROAD MANDIRI-001',
    full_name: 'DINFRA TOLL ROAD MANDIRI-001',
    description: 'DINFRA TOLL ROAD MANDIRI-001',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XMTS',
    name: 'Reksa Dana ETF MNC36 Likuid',
    full_name: 'Reksa Dana ETF MNC36 Likuid',
    description: 'Reksa Dana ETF MNC36 Likuid',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XNVE',
    name: 'Reksa Dana Indeks Nusadana ETF IDX Value',
    full_name: 'Reksa Dana Indeks Nusadana ETF IDX Value',
    description: 'Reksa Dana Indeks Nusadana ETF IDX Value',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPCR',
    name: 'Reksa Dana PNM ETF CORE LQ45',
    full_name: 'Reksa Dana PNM ETF CORE LQ45',
    description: 'Reksa Dana PNM ETF CORE LQ45',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPDV',
    name: 'Reksa Dana Pinnacle Core High Dividend E',
    full_name: 'Reksa Dana Pinnacle Core High Dividend E',
    description: 'Reksa Dana Pinnacle Core High Dividend E',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPES',
    name: 'Reksa Dana Syariah KIK Pinnacle Enhanced',
    full_name: 'Reksa Dana Syariah KIK Pinnacle Enhanced',
    description: 'Reksa Dana Syariah KIK Pinnacle Enhanced',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPFT',
    name: 'Reksa Dana Indeks Pinnacle FTSE Indonesi',
    full_name: 'Reksa Dana Indeks Pinnacle FTSE Indonesi',
    description: 'Reksa Dana Indeks Pinnacle FTSE Indonesi',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPID',
    name: 'Reksa Dana Indeks Pinnacle IDX30 ETF',
    full_name: 'Reksa Dana Indeks Pinnacle IDX30 ETF',
    description: 'Reksa Dana Indeks Pinnacle IDX30 ETF',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPLC',
    name: 'Reksa Dana Pinnacle Indonesia Large-Cap',
    full_name: 'Reksa Dana Pinnacle Indonesia Large-Cap',
    description: 'Reksa Dana Pinnacle Indonesia Large-Cap',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPLQ',
    name: 'Reksa Dana Pinnacle Enhanced Liquid ETF',
    full_name: 'Reksa Dana Pinnacle Enhanced Liquid ETF',
    description: 'Reksa Dana Pinnacle Enhanced Liquid ETF',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPMI',
    name: 'ETF Phillip MSCI Indonesia Equity Index',
    full_name: 'ETF Phillip MSCI Indonesia Equity Index',
    description: 'ETF Phillip MSCI Indonesia Equity Index',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPSG',
    name: 'Reksa Dana Pinnacle Indonesia ESG ETF',
    full_name: 'Reksa Dana Pinnacle Indonesia ESG ETF',
    description: 'Reksa Dana Pinnacle Indonesia ESG ETF',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XPTD',
    name: 'Reksa Dana Indeks Panin ETF IDX30 Dinami',
    full_name: 'Reksa Dana Indeks Panin ETF IDX30 Dinami',
    description: 'Reksa Dana Indeks Panin ETF IDX30 Dinami',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XSBC',
    name: 'Reksa Dana Indeks Simas ETF IDX30',
    full_name: 'Reksa Dana Indeks Simas ETF IDX30',
    description: 'Reksa Dana Indeks Simas ETF IDX30',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XSMU',
    name: 'RD Syailendra ETF MSCI Indonesia ESG Uni',
    full_name: 'RD Syailendra ETF MSCI Indonesia ESG Uni',
    description: 'RD Syailendra ETF MSCI Indonesia ESG Uni',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XSPI',
    name: 'DIRE Simas Plaza Indonesia',
    full_name: 'DIRE Simas Plaza Indonesia',
    description: 'DIRE Simas Plaza Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XSRI',
    name: 'Reksa Dana Indeks STAR ETF SRI-KEHATI',
    full_name: 'Reksa Dana Indeks STAR ETF SRI-KEHATI',
    description: 'Reksa Dana Indeks STAR ETF SRI-KEHATI',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XSSI',
    name: 'Reksa Dana Syariah Indeks Simas ETF JII',
    full_name: 'Reksa Dana Syariah Indeks Simas ETF JII',
    description: 'Reksa Dana Syariah Indeks Simas ETF JII',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'XSSK',
    name: 'Reksa Dana Indeks SAM ETF SRI-KEHATI',
    full_name: 'Reksa Dana Indeks SAM ETF SRI-KEHATI',
    description: 'Reksa Dana Indeks SAM ETF SRI-KEHATI',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'YELO',
    name: 'Yelooo Integra Datanet Tbk.',
    full_name: 'Yelooo Integra Datanet Tbk.',
    description: 'Yelooo Integra Datanet Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'YELO-W',
    name: 'Waran Seri I Yelooo Integra Datanet',
    full_name: 'Waran Seri I Yelooo Integra Datanet',
    description: 'Waran Seri I Yelooo Integra Datanet',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'YPAS',
    name: 'Yanaprima Hastapersada Tbk',
    full_name: 'Yanaprima Hastapersada Tbk',
    description: 'Yanaprima Hastapersada Tbk',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'YULE',
    name: 'Yulie Sekuritas Indonesia Tbk.',
    full_name: 'Yulie Sekuritas Indonesia Tbk.',
    description: 'Yulie Sekuritas Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'YULE-R',
    name: 'HMETD Yulie Sekuritas Indonesia',
    full_name: 'HMETD Yulie Sekuritas Indonesia',
    description: 'HMETD Yulie Sekuritas Indonesia',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ZATA',
    name: 'Bersama Zatta Jaya Tbk.',
    full_name: 'Bersama Zatta Jaya Tbk.',
    description: 'Bersama Zatta Jaya Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ZBRA',
    name: 'Dosni Roha Indonesia Tbk.',
    full_name: 'Dosni Roha Indonesia Tbk.',
    description: 'Dosni Roha Indonesia Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ZBRA-R',
    name: 'HMETD Zebra Nusantara',
    full_name: 'HMETD Zebra Nusantara',
    description: 'HMETD Zebra Nusantara',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ZINC',
    name: 'Kapuas Prima Coal Tbk.',
    full_name: 'Kapuas Prima Coal Tbk.',
    description: 'Kapuas Prima Coal Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ZONE',
    name: 'Mega Perintis Tbk.',
    full_name: 'Mega Perintis Tbk.',
    description: 'Mega Perintis Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ZYRX',
    name: 'Zyrexindo Mandiri Buana Tbk.',
    full_name: 'Zyrexindo Mandiri Buana Tbk.',
    description: 'Zyrexindo Mandiri Buana Tbk.',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ZYRX-W',
    name: 'Waran Seri I Zyrexindo Mandiri Buana',
    full_name: 'Waran Seri I Zyrexindo Mandiri Buana',
    description: 'Waran Seri I Zyrexindo Mandiri Buana',
    exchange: 'IDX',
    type: 'stock'
  }
];

export const LIST_SYMBOL_IDX = [
  {
    symbol: 'COMPOSITE',
    name: 'COMPOSITE',
    full_name: 'COMPOSITE',
    description: 'COMPOSITE',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'BISNIS27',
    name: 'BISNIS27',
    full_name: 'BISNIS27',
    description: 'BISNIS27',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ESGQKEHATI',
    name: 'ESGQKEHATI',
    full_name: 'ESGQKEHATI',
    description: 'ESGQKEHATI',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ESGSKEHATI',
    name: 'ESGSKEHATI',
    full_name: 'ESGSKEHATI',
    description: 'ESGSKEHATI',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDX30',
    name: 'IDX30',
    full_name: 'IDX30',
    description: 'IDX30',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDX80',
    name: 'IDX80',
    full_name: 'IDX80',
    description: 'IDX80',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXBUMN20',
    name: 'IDXBUMN20',
    full_name: 'IDXBUMN20',
    description: 'IDXBUMN20',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXESGL',
    name: 'IDXESGL',
    full_name: 'IDXESGL',
    description: 'IDXESGL',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXG30',
    name: 'IDXG30',
    full_name: 'IDXG30',
    description: 'IDXG30',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXHIGHDIV',
    name: 'IDXHIGHDIV',
    full_name: 'IDXHIGHDIV',
    description: 'IDXHIGHDIV',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXMESBUMN',
    name: 'IDXMESBUMN',
    full_name: 'IDXMESBUMN',
    description: 'IDXMESBUMN',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXQ30',
    name: 'IDXQ30',
    full_name: 'IDXQ30',
    description: 'IDXQ30',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXSMC-COM',
    name: 'IDXSMC-COM',
    full_name: 'IDXSMC-COM',
    description: 'IDXSMC-COM',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXSMC-LIQ',
    name: 'IDXSMC-LIQ',
    full_name: 'IDXSMC-LIQ',
    description: 'IDXSMC-LIQ',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXV30',
    name: 'IDXV30',
    full_name: 'IDXV30',
    description: 'IDXV30',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IGRADE',
    name: 'IGRADE',
    full_name: 'IGRADE',
    description: 'IGRADE',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INFOBANK15',
    name: 'INFOBANK15',
    full_name: 'INFOBANK15',
    description: 'INFOBANK15',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'INVESTOR33',
    name: 'INVESTOR33',
    full_name: 'INVESTOR33',
    description: 'INVESTOR33',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'ISSI',
    name: 'ISSI',
    full_name: 'ISSI',
    description: 'ISSI',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JII',
    name: 'JII',
    full_name: 'JII',
    description: 'JII',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'JII70',
    name: 'JII70',
    full_name: 'JII70',
    description: 'JII70',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'KOMPAS100',
    name: 'KOMPAS100',
    full_name: 'KOMPAS100',
    description: 'KOMPAS100',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'LQ45',
    name: 'LQ45',
    full_name: 'LQ45',
    description: 'LQ45',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'MNC36',
    name: 'MNC36',
    full_name: 'MNC36',
    description: 'MNC36',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'PEFINDO25',
    name: 'PEFINDO25',
    full_name: 'PEFINDO25',
    description: 'PEFINDO25',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SM18',
    name: 'SM18',
    full_name: 'SM18',
    description: 'SM18',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'SRIKEHATI',
    name: 'SRIKEHATI',
    full_name: 'SRIKEHATI',
    description: 'SRIKEHATI',
    exchange: 'IDX',
    type: 'stock'
  },
  {
    symbol: 'IDXSHAGROW',
    name: 'IDXSHAGROW',
    full_name: 'IDXSHAGROW',
    description: 'IDXSHAGROW',
    exchange: 'IDX',
    type: 'stock'
  }
];
