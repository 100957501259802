import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import useDebounce from 'hooks/useDebounce';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import TWBundleOrderlist from 'pages/trading/_partials/tw-bundle-orderlist';
import TWInfoCash from 'pages/trading/_partials/tw-info-cash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetDetailClientQuery } from 'services/rtk-query/user';
import { setDealerUseClientId } from 'store/auth';
import { currency } from 'utils/format';

function AdminPage() {
  const refInput = useRef();
  const dispatch = useDispatch();
  const { getErrorMessage } = useGetErrorMessage();
  const { dealerUseClientId } = useBrowserTabAuth();
  const [clientID, setClientID] = useState(dealerUseClientId);

  const clientIDDebounce = useDebounce(clientID, 500);

  const { data: detailClient, isError } = useGetDetailClientQuery(clientIDDebounce, {
    skip: !clientID,
  });

  const specialUsers = useMemo(() => {
    return [
      detailClient?.isMarginUser && 'Margin User',
      detailClient?.isTrustUser && 'Trust User'
    ]?.filter((item) => item);
  }, [detailClient]);

  useEffect(() => {
    if (isError) {
      getErrorMessage({ msg: 'Client ID not found' });
    }
  }, [isError]);

  return (
    <div className="p-4 zoom-2">
      <div className="flex gap-4">
        <div className="flex-none min-w-[300px] flex flex-col gap-4">
          <Card className="p-4">
            <div>
              <label className="dark:text-black-light-1-d text-sm block mb-1 font-quicksand-medium">
                Username / Client ID
              </label>
              <div className="relative flex-none min-w-[300px] flex items-center justify-between gap-2">
                <div className="flex-auto">
                  <input
                    ref={refInput}
                    value={clientID?.toUpperCase()}
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && clientID) {
                        dispatch(setDealerUseClientId(clientID));
                        window.location.reload();
                      }
                    }}
                    onFocus={() => {
                      refInput?.current?.setSelectionRange(0, 9999999);
                    }}
                    placeholder="Masukkan Username / Client ID"
                    className="appearance-none outline-none flex-auto dark:text-black-light-1-d dark:bg-black-base-d py-[10px] px-5 pr-10 text-xs rounded-lg w-full font-quicksand-semibold border border-main-base-l"
                    onChange={(e) =>
                      setClientID(e.target.value?.toLocaleUpperCase())
                    }
                  />
                </div>

                <button
                  type="button"
                  onClick={() => {
                    if (clientID) {
                      dispatch(setDealerUseClientId(clientID));
                      window.location.reload();
                    }
                  }}
                  className="flex-none bg-main-base-l text-white dark:text-black-base-d py-2 px-3 text-sm font-quicksand-semibold rounded-lg flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75 appearance-none">
                  Cari
                </button>
              </div>
            </div>
          </Card>

          <TWInfoCash title="" />

          <Card className="dark:text-black-light-1-d p-4 text-xs flex flex-col gap-2">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between">
                    <span className="dark:text-black-light-0-d">ClientID</span>
                    <span className="font-quicksand-semibold">
                      {detailClient?.userName || '-'}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="dark:text-black-light-0-d">Name</span>
                    <span className="font-quicksand-semibold">
                      {[
                        detailClient?.firstName,
                        detailClient?.midName,
                        detailClient?.lastName
                      ]?.join(' ')}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between">
                    <span className="dark:text-black-light-0-d">SID</span>
                    <span className="font-quicksand-semibold">
                      {detailClient?.sid || '-'}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="dark:text-black-light-0-d">SRE</span>
                    <span className="font-quicksand-semibold">
                      {detailClient?.sre || '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <RenderIf isTrue={specialUsers?.length}>
              <div className="flex items-center gap-2">
                {specialUsers?.map((item) => (
                  <span className="border py-1 px-2 text-2xs rounded-md border-main-base-d text-main-base-d">
                    {item}
                  </span>
                ))}
              </div>
            </RenderIf>

            <span className="dark:border-black-light-0-d/50 border-t-[1px] my-2" />

            <p className="font-quicksand-semibold">RDN Account Info</p>
            <div className="flex justify-between">
              <span className="dark:text-black-light-0-d">Account Name</span>
              <span className="font-quicksand-semibold">
                {detailClient?.rdnAccountInfo?.bankAccountName || '-'}
              </span>
            </div>

            <div className="flex justify-between">
              <span className="dark:text-black-light-0-d">Account Number</span>
              <span className="font-quicksand-semibold">
                {detailClient?.rdnAccountInfo?.bankAccountNo || '-'}
              </span>
            </div>

            <div className="flex justify-between">
              <span className="dark:text-black-light-0-d">Bank</span>
              <span className="font-quicksand-semibold">
                {detailClient?.rdnAccountInfo?.bankName || '-'}
              </span>
            </div>

            <div className="flex justify-between">
              <span className="dark:text-black-light-0-d">Cash Available</span>
              <span className="font-quicksand-semibold">
                {currency(detailClient?.rdnAccountInfo?.cashAvailable || 0, {
                  removeSymbol: true
                })}
              </span>
            </div>

            <span className="dark:border-black-light-0-d/50 border-t-[1px] my-2" />

            <p className="font-quicksand-semibold">Withdrawal Account Info</p>
            <div className="flex justify-between">
              <span className="dark:text-black-light-0-d">Account Name</span>
              <span className="font-quicksand-semibold">
                {detailClient?.withdrawalAccountInfo?.bankAccountName || '-'}
              </span>
            </div>

            <div className="flex justify-between">
              <span className="dark:text-black-light-0-d">Account Number</span>
              <span className="font-quicksand-semibold">
                {detailClient?.withdrawalAccountInfo?.bankAccountNo || '-'}
              </span>
            </div>

            <div className="flex justify-between">
              <span className="dark:text-black-light-0-d">Bank</span>
              <span className="font-quicksand-semibold">
                {detailClient?.withdrawalAccountInfo?.bankName || '-'}
              </span>
            </div>

            <div className="flex justify-between">
              <span className="dark:text-black-light-0-d">Cash Available</span>
              <span className="font-quicksand-semibold">
                {currency(
                  detailClient?.withdrawalAccountInfo?.cashAvailable || 0,
                  {
                    removeSymbol: true
                  }
                )}
              </span>
            </div>
          </Card>
        </div>
        <div className="flex-auto flex flex-col gap-4 overflow-hidden">
          <Card className="overflow-hidden pt-4 min-h-[405px]">
            <OverlayScrollbarsComponent
              className="overlayscrollbars-host-small overflow-hidden relative"
              element="span"
              options={{ resize: 'horizontal' }}
              style={{ maxHeight: 'calc(100vh - 64px)' }}>
              <TWBundleOrderlist isInline isZoomOut />
            </OverlayScrollbarsComponent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
