import baseApi from '../base'
import { TAG_USERS, TAG_USER_NOTIFICATION, TAG_USER_COACHMARKS, TAG_USER_STOCK_SCREENER_LIST, TAG_CLIENT } from '../tags'

export const UserApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserMe: build.query({
      async queryFn(accessToken, _queryApi, _extraOptions, fetchWithBQ) {
        const responseMe = await fetchWithBQ({
          url: `/user/me`,
          headers: {
            Authorization: 'Bearer ' + accessToken
          }
        });

        const responseProfile = await fetchWithBQ({
          url: `/user/profile`,
          headers: {
            Authorization: 'Bearer ' + accessToken
          }
        });

        return { data: { ...responseMe?.data, ...responseProfile?.data?.payloads }};
      },
      transformResponse: (res) => res || {}
    }),
    getUserRoles: build.query({
      query: (accessToken) => {
        return {
          url: `/user/roles`,
          headers: {
            Authorization: 'Bearer ' + accessToken
          }
        }
      },
      transformResponse: (res) => res?.payloads || [],
    }),
    getDetailClient: build.query({
      query: (clientID) => {
        return {
          url: `/user/${clientID}/client`,
        }
      },
      transformResponse: (res) => res?.payloads || {},
      providesTags: (_result, _error, clientID) => {
        return [{ type: TAG_CLIENT, id: clientID }];
      },
    }),
    getUserById: build.query({
      query: (userId) => {
        return {
          url: `/user/${userId}/detail`,
        }
      },
      transformResponse: (res) => res?.payloads || {},
      providesTags: (_result, _error, userId) => {
        return [{ type: TAG_USERS, id: userId }];
      },
    }),
    searchUserByEmail: build.query({
      query: (args = {}) => {
        const { email = '', page = 1, size = 20 } = args;

        return {
          url: `user/?&page=${page}&total=1&limit=${size}&totalRecord=0&keyword=${email}&inRole=&isRealAccount=&isDeleted=`,
        }
      },
    }),
    getUserProfile: build.query({
      query: () => {
        return {
          url: `/user/profile`,
        }
      },
      transformResponse: (res) => res?.payloads || [],
      providesTags: () => {
        return [{ type: TAG_USERS, id: 'LIST' }];
      },
    }),
    getUserNotification: build.query({
      query: (params = { type: 'general', page: 0, size: 20, tag: '' }) => {
        return {
          url: `/user/notification-type`,
          params
        }
      },
      transformResponse: (res) => res?.payloads || [],
      providesTags: () => {
        return [{ type: TAG_USER_NOTIFICATION, id: 'LIST' }];
      },
    }),
    updateUserNotificationById: build.mutation({
      query: (id) => {
        return {
          url: `/user/notification/${id}`,
          method: 'PUT',
          body: { isRead: true }
        }
      },
      invalidatesTags: [{ type: TAG_USER_NOTIFICATION, id: 'LIST' }],
    }),
    updateUserNotificationReadAll: build.mutation({
      query: () => {
        return {
          url: `/user/notification/mark-as-read`,
          method: 'GET'
        }
      },
      invalidatesTags: [{ type: TAG_USER_NOTIFICATION, id: 'LIST' }],
    }),
    deleteAllUserNotification: build.mutation({
      query: () => {
        return {
          url: `/user/notification/delete-all`,
          method: 'GET'
        }
      },
      invalidatesTags: [{ type: TAG_USER_NOTIFICATION, id: 'LIST' }],
    }),
    deleteUser: build.mutation({
      query: (body) => {
        return {
          url: `/user/delete`,
          method: 'POST',
          body
        }
      }
    }),
    updateUser: build.mutation({
      query: (body) => {
        return {
          url: `/user/update`,
          method: 'POST',
          body
        }
      }
    }),
    updateUserById: build.mutation({
      query: ({ body = {}, userId = ''}) => {
        return {
          url: `/user/${userId}/update`,
          method: 'POST',
          body
        }
      }
    }),
    createForgotPassword: build.mutation({
      query: (body) => {
        return {
          url: `/user/reset-password-request-otp`,
          method: 'POST',
          body
        }
      }
    }),
    createResetPassword: build.mutation({
      query: (body) => {
        return {
          url: `/user/reset-password-otp`,
          method: 'POST',
          body
        }
      }
    }),
    createPIN: build.mutation({
      query: (body = { pin: '' }) => {
        return {
          url: `/user/pin`,
          method: 'POST',
          body
        }
      }
    }),

    // USER COACHMARK
    getUserCoachMarkById: build.query({
      query: (userId) => {
        return {
          url: `/user/coachmark/${userId}`,
        }
      },
      transformResponse: (res) => res || [],
      providesTags: (_result, _error, userId) => {
        return [{ type: TAG_USER_COACHMARKS, id: userId }];
      },
    }),
    createUserCoachMark: build.mutation({
      query: (body = { category: '', userId: '' }) => {
        return {
          url: `/user/coachmark`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: (result, error, { userId }) => {
        return [
          { type: TAG_USER_COACHMARKS, id: userId },
        ];
      }
    }),
    createResetPIN: build.mutation({
      query: (body = { email: '', password: '' }) => {
        return {
          url: `/user/reset-pin`,
          method: 'POST',
          body
        }
      },
    }),

    // USER SCREENER
    getUserStockScreenerByUserId: build.query({
      query: (userId = '') => {
        return {
          url: `/stocks/screener/${userId}`,
        }
      },
      transformResponse: (res) => res || [],
      providesTags: () => {
        return [{ type: TAG_USER_STOCK_SCREENER_LIST, id: 'LIST' }];
      },
    }),
    createUserStockScreener: build.mutation({
      query: (
        body = {
          user_id: '',
          remarks: '',
          name: '',
          index: '', // stock universe (IDX30, IDX80 etc.)
          order: [],
          filter: [],
          metrics: [
            {
              metrics: [
                {
                  operator: '', // operator comparison (> >= = <= <)
                  param: '',
                  paramCompareType: '',
                  valueCompare: '',
                  condition: '', // condition logical (AND OR)
                }
              ]
            }
          ]
        }
      ) => {
        return {
          url: `/stocks/screener/create`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: [{ type: TAG_USER_STOCK_SCREENER_LIST, id: 'LIST' }],
    }),
    updateUserStockScreenerById: build.mutation({
      query: (body = {
        id: '',
        user_id: '',
        remarks: '',
        name: '',
        index: '', // stock universe (IDX30, IDX80 etc.)
        order: [],
        filter: [],
        metrics: [
          {
            metrics: [
              {
                operator: '', // operator comparison (> >= = <= <)
                param: '',
                paramCompareType: '',
                valueCompare: '',
                condition: '' // condition logical (AND OR)
              }
            ]
          }
        ]
      }) => {
        return {
          url: `/stocks/screener/${body?.id}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: [{ type: TAG_USER_STOCK_SCREENER_LIST, id: 'LIST' }]
    }),
    deleteUserStockScreenerById: build.mutation({
      query: (screenerId = '') => {
        return {
          url: `/stocks/screener/${screenerId}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: [{ type: TAG_USER_STOCK_SCREENER_LIST, id: 'LIST' }]
    }),
  }),
});

export const invalidateUserNotification = () => {
  return UserApi.util.invalidateTags([{ type: TAG_USER_NOTIFICATION, id: 'LIST' }]);
}

export const invalidateUserProfile = (userId = 'LIST') => {
  return UserApi.util.invalidateTags([{ type: TAG_USERS, id: userId }]);
}

export const {
  useGetDetailClientQuery,
  useGetUserMeQuery,
  useLazyGetUserMeQuery,
  useLazyGetUserRolesQuery,
  useGetUserByIdQuery,
  useSearchUserByEmailQuery,
  useLazySearchUserByEmailQuery,
  useGetUserProfileQuery,
  useGetUserNotificationQuery,
  useUpdateUserNotificationByIdMutation,
  useUpdateUserNotificationReadAllMutation,
  useDeleteAllUserNotificationMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdateUserByIdMutation,
  useCreateForgotPasswordMutation,
  useCreateResetPasswordMutation,
  useCreatePINMutation,
  useGetUserCoachMarkByIdQuery,
  useCreateUserCoachMarkMutation,
  useCreateResetPINMutation,
  useGetUserStockScreenerByUserIdQuery,
  useCreateUserStockScreenerMutation,
  useUpdateUserStockScreenerByIdMutation,
  useDeleteUserStockScreenerByIdMutation
} = UserApi;
