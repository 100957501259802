// eslint-disable-next-line react/prop-types
function IconListBox({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M14 6C13.4477 6 13 6.44772 13 7C13 7.55228 13.4477 8 14 8H20C20.5523 8 21 7.55228 21 7C21 6.44772 20.5523 6 20 6H14Z" />
      <path d="M14 16C13.4477 16 13 16.4477 13 17C13 17.5523 13.4477 18 14 18H20C20.5523 18 21 17.5523 21 17C21 16.4477 20.5523 16 20 16H14Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03199 3H6.96802C6.52939 2.99999 6.15087 2.99998 5.83762 3.02135C5.50779 3.04386 5.17788 3.09336 4.85195 3.22836C4.11687 3.53285 3.53285 4.11687 3.22836 4.85195C3.09336 5.17788 3.04386 5.50779 3.02135 5.83762C2.99998 6.15087 2.99999 6.52934 3 6.96797V7.03198C2.99999 7.47061 2.99998 7.84914 3.02135 8.16238C3.04386 8.49221 3.09336 8.82212 3.22836 9.14805C3.53285 9.88314 4.11687 10.4672 4.85195 10.7716C5.17788 10.9066 5.50779 10.9561 5.83762 10.9787C6.15088 11 6.52936 11 6.96801 11H7.03199C7.47064 11 7.84913 11 8.16238 10.9787C8.49221 10.9561 8.82212 10.9066 9.14805 10.7716C9.88314 10.4672 10.4672 9.88314 10.7716 9.14805C10.9066 8.82212 10.9561 8.49221 10.9787 8.16238C11 7.84913 11 7.47065 11 7.03201V6.96804C11 6.5294 11 6.15087 10.9787 5.83762C10.9561 5.50779 10.9066 5.17788 10.7716 4.85195C10.4672 4.11687 9.88314 3.53285 9.14805 3.22836C8.82212 3.09336 8.49221 3.04386 8.16238 3.02135C7.84914 2.99998 7.47061 2.99999 7.03199 3ZM5.61732 5.07612C5.65893 5.05889 5.74576 5.03227 5.97376 5.01671C6.21074 5.00054 6.52038 5 7 5C7.47963 5 7.78926 5.00054 8.02624 5.01671C8.25424 5.03227 8.34107 5.05889 8.38269 5.07612C8.62771 5.17762 8.82239 5.37229 8.92388 5.61732C8.94112 5.65893 8.96774 5.74576 8.98329 5.97376C8.99946 6.21074 9 6.52038 9 7C9 7.47963 8.99946 7.78926 8.98329 8.02624C8.96774 8.25424 8.94112 8.34107 8.92388 8.38268C8.82239 8.62771 8.62771 8.82239 8.38269 8.92388C8.34107 8.94112 8.25424 8.96773 8.02624 8.98329C7.78926 8.99946 7.47963 9 7 9C6.52038 9 6.21074 8.99946 5.97376 8.98329C5.74576 8.96773 5.65893 8.94112 5.61732 8.92388C5.37229 8.82239 5.17762 8.62771 5.07612 8.38268C5.05889 8.34107 5.03227 8.25424 5.01671 8.02624C5.00054 7.78926 5 7.47963 5 7C5 6.52038 5.00054 6.21074 5.01671 5.97376C5.03227 5.74576 5.05889 5.65893 5.07612 5.61732C5.17762 5.37229 5.37229 5.17762 5.61732 5.07612Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03199 13H6.96802C6.52939 13 6.15087 13 5.83762 13.0214C5.50779 13.0439 5.17788 13.0934 4.85195 13.2284C4.11687 13.5328 3.53285 14.1169 3.22836 14.852C3.09336 15.1779 3.04386 15.5078 3.02135 15.8376C2.99998 16.1509 2.99999 16.5293 3 16.968V17.032C2.99999 17.4706 2.99998 17.8491 3.02135 18.1624C3.04386 18.4922 3.09336 18.8221 3.22836 19.1481C3.53285 19.8831 4.11687 20.4672 4.85195 20.7716C5.17788 20.9066 5.50779 20.9561 5.83762 20.9787C6.15088 21 6.52936 21 6.96801 21H7.03199C7.47064 21 7.84913 21 8.16238 20.9787C8.49221 20.9561 8.82212 20.9066 9.14805 20.7716C9.88314 20.4672 10.4672 19.8831 10.7716 19.1481C10.9066 18.8221 10.9561 18.4922 10.9787 18.1624C11 17.8491 11 17.4707 11 17.032V16.968C11 16.5294 11 16.1509 10.9787 15.8376C10.9561 15.5078 10.9066 15.1779 10.7716 14.852C10.4672 14.1169 9.88314 13.5328 9.14805 13.2284C8.82212 13.0934 8.49221 13.0439 8.16238 13.0214C7.84914 13 7.47062 13 7.03199 13ZM5.61732 15.0761C5.65893 15.0589 5.74576 15.0323 5.97376 15.0167C6.21074 15.0005 6.52038 15 7 15C7.47963 15 7.78926 15.0005 8.02624 15.0167C8.25424 15.0323 8.34107 15.0589 8.38269 15.0761C8.62771 15.1776 8.82239 15.3723 8.92388 15.6173C8.94112 15.6589 8.96774 15.7458 8.98329 15.9738C8.99946 16.2107 9 16.5204 9 17C9 17.4796 8.99946 17.7893 8.98329 18.0262C8.96774 18.2542 8.94112 18.3411 8.92388 18.3827C8.82239 18.6277 8.62771 18.8224 8.38269 18.9239C8.34107 18.9411 8.25424 18.9677 8.02624 18.9833C7.78926 18.9995 7.47963 19 7 19C6.52038 19 6.21074 18.9995 5.97376 18.9833C5.74576 18.9677 5.65893 18.9411 5.61732 18.9239C5.37229 18.8224 5.17762 18.6277 5.07612 18.3827C5.05889 18.3411 5.03227 18.2542 5.01671 18.0262C5.00054 17.7893 5 17.4796 5 17C5 16.5204 5.00054 16.2107 5.01671 15.9738C5.03227 15.7458 5.05889 15.6589 5.07612 15.6173C5.17762 15.3723 5.37229 15.1776 5.61732 15.0761Z"
      />
    </svg>
  );
}

export default IconListBox;
