// import IconATM from 'components/svg/icon-atm';
// import IconChat from 'components/svg/icon-chat';
import IconDeposit from 'components/svg/icon-deposit';
import IconHistory from 'components/svg/icon-history';
// import IconLock from 'components/svg/icon-lock';
import IconUser from 'components/svg/icon-user';
import IconWithdraw from 'components/svg/icon-withdraw';
import ReactDOMServer from 'react-dom/server';

export const ListMenuProfile = [
  {
    icon: ReactDOMServer.renderToString(<IconUser className="mr-3" />),
    text: 'Pengaturan Akun',
    url: '/profile/account-settings/data-pribadi'
  },
  // {
  //   icon: ReactDOMServer.renderToString(<IconLock className="mr-3" />),
  //   text: 'Pengaturan Keamanan',
  //   url: '/profile/security-settings/atur-perangkat'
  // },
  // {
  //   icon: ReactDOMServer.renderToString(<IconATM className="mr-3" />),
  //   text: 'Atur Rekening Bank'
  // },
  {
    icon: ReactDOMServer.renderToString(<IconDeposit className="mr-3" />),
    text: 'Top-up Dana Saldo Rekening',
    url: '/profile/deposit'
  },
  {
    icon: ReactDOMServer.renderToString(<IconWithdraw className="mr-3" />),
    text: 'Tarik Dana Saldo Rekening',
    url: '/profile/cash-withdrawal'
  },
  {
    icon: ReactDOMServer.renderToString(<IconHistory className="mr-3" />),
    text: 'Riwayat Saldo Rekening',
    url: '/profile/cash-history'
  },
  // {
  //   icon: ReactDOMServer.renderToString(<IconChat className="mr-3" />),
  //   text: 'Bantuan'
  // }
];

export const ListMenuAccountSettings = [
  {
    id: 0,
    text: 'Data Pribadi',
    slug: 'data-pribadi',
    url: '/profile/account-settings/data-pribadi'
  },
  {
    id: 1,
    text: 'Syarat dan Ketentuan',
    slug: 'syarat-dan-ketentuan',
    url: '/profile/account-settings/syarat-dan-ketentuan'
  },
  {
    id: 2,
    text: 'Kebijakan Privasi',
    slug: 'kebijakan-privasi',
    url: '/profile/account-settings/kebijakan-privasi'
  },
  {
    id: 3,
    text: 'Hapus Akun',
    slug: 'hapus-akun',
    url: '/profile/account-settings/hapus-akun'
  }
]

export const ListMenuNotification = [
  {
    id: 0,
    text: 'Pesan',
    slug: 'message',
    url: '/notification/message'
  },
  {
    id: 1,
    text: 'Price Alert',
    slug: 'price-alert',
    url: '/notification/price-alert'
  },
]

export const ListMenuSecuritySettings = [
  {
    id: 0,
    text: 'Ganti Password',
    slug: 'ganti-password',
    url: '/profile/security-settings/ganti-password'
  },
  {
    id: 1,
    text: 'Ganti PIN',
    slug: 'ganti-pin',
    url: '/profile/security-settings/ganti-pin'
  },
  {
    id: 2,
    text: 'Touch ID / Face ID',
    slug: null,
  },
  {
    id: 3,
    text: 'Perangkat yang Terhubung',
    slug: 'atur-perangkat',
    url: '/profile/security-settings/atur-perangkat'
  }
]

