import PropTypes from 'prop-types';

function IconSync({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10 2C5.58172 2 2 5.58172 2 10C2 10.5523 1.55228 11 1 11C0.447715 11 0 10.5523 0 10C0 4.47715 4.47715 0 10 0C13.023 0 15.6875 1.34038 17.5 3.44947L17.5 2C17.5 1.44772 17.9477 1 18.5 1C19.0523 1 19.5 1.44771 19.5 2L19.5 6C19.5 6.26572 19.3942 6.52052 19.2061 6.70814C19.0179 6.89576 18.7628 7.00077 18.4971 7L18.0557 6.9987C18.017 7.00084 17.9783 7.00071 17.9399 6.99836L14.4971 6.98828C13.9448 6.98666 13.4984 6.53763 13.5 5.98535C13.5016 5.43307 13.9506 4.98667 14.5029 4.98829L16.1822 4.99321C14.7288 3.1635 12.5171 2 10 2Z" />
      <path d="M10 18C14.4183 18 18 14.4183 18 10C18 9.44771 18.4477 9 19 9C19.5523 9 20 9.44771 20 10C20 15.5228 15.5228 20 10 20C6.97702 20 4.31255 18.6596 2.5 16.5505L2.5 18C2.5 18.5523 2.05229 19 1.5 19C0.947718 19 0.500003 18.5523 0.500003 18L0.5 14C0.5 13.7343 0.605759 13.4795 0.79393 13.2919C0.9821 13.1042 1.23721 12.9992 1.50293 13L1.94428 13.0013C1.983 12.9992 2.02166 12.9993 2.06007 13.0016L5.50293 13.0117C6.05521 13.0133 6.50161 13.4624 6.5 14.0146C6.49838 14.5669 6.04935 15.0133 5.49707 15.0117L3.81776 15.0068C5.27119 16.8365 7.48295 18 10 18Z" />
    </svg>
  );
}

IconSync.propTypes = {
  className: PropTypes.string
};

export default IconSync;
