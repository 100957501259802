import dayjs from 'dayjs';
import baseApi from '../base';
import { TAG_BUYING_POWER, TAG_DEPOSIT, TAG_ORDERLIST, TAG_SMART_ORDER_LIST, TAG_NABUNG_RUTIN_LIST, TAG_TRADELIST, TAG_CLIENT_CASH, TAG_PORTFOLIO } from '../tags';
import { useBrowserTabId } from 'hooks/useBrowserTabId';

export const TradingAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBuyingPower: build.query({
      async queryFn(clientId, { getState }, _extraOptions, fetchWithBQ) {
        let params = {};
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        if (dealerUseClientId) params.clientId = dealerUseClientId;

        const { data } = await fetchWithBQ({
          url: '/trading/buyingpower',
          params
        });

        return { data: data?.payloads };
      },
      transformResponse: (res) => res?.payloads || {},
      providesTags: () => {
        return [{ type: TAG_BUYING_POWER, id: 'LIST' }];
      }
    }),
    getDeposit: build.query({
      query: () => {
        return {
          url: `/trading/deposit`
        };
      },
      transformResponse: (res) => res?.payloads || {},
      providesTags: () => {
        return [{ type: TAG_DEPOSIT, id: 'LIST' }];
      }
    }),
    getWithdraw: build.query({
      query: () => {
        return {
          url: `/trading/withdraw`
        };
      },
      transformResponse: (res) => res?.payloads || {},
      providesTags: () => {
        return [{ type: TAG_DEPOSIT, id: 'LIST' }];
      }
    }),
    createWithdrawCash: build.mutation({
      query: (body) => {
        return {
          // url: '/trading/withdrawApprove',
          url: '/trading/withdraw',
          method: 'POST',
          body
        }
      },
      invalidatesTags: [{ type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    }),
    loginPIN: build.mutation({
      query: (body) => {
        return {
          url: '/trading/login',
          method: 'POST',
          body
        }
      },
    }),
    getAlgoBOT: build.query({
      async queryFn(clientID, { getState }, _extraOptions, fetchWithBQ) {
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        let params = {}
        params.clientID = dealerUseClientId || clientID;

        const { data } = await fetchWithBQ({
          url: '/trading/algo/GetBotList',
          method: 'GET',
          params
        });

        const resJson = JSON.parse(data?.payloads || '[]');
        const datas = resJson?.map(item => {
          let _Condition = '';
          let _State = item?.State;

          if (item?.State === 'NonActive') {
            _State = 'Tidak Aktif'
          }
      
          if (item?.State === 'Deleted') {
            _State = 'Dihapus'
          }

          if (dayjs().valueOf() > dayjs(item?.BotExpire, 'DD-MMM-YYYY HH:mm:ss').valueOf() && item?.State === 'Active') {
            _State = 'Berakhir Tanpa Match';
          }

          if (dayjs(item?.BotExpire, 'DD-MMM-YYYY HH:mm:ss').valueOf() > dayjs().valueOf() && item?.State != 'Active' && item?.State != 'Deleted') {
            _State = 'Terkirim';
          }

          if (dayjs(item?.BotExpire, 'DD-MMM-YYYY HH:mm:ss').valueOf() > dayjs().valueOf() && item?.State === 'Active') {
            _State = 'Aktif';
          }

          let _priceFire = item?.PriceFire;
          let _matchUpper = `Q.${item?.StockId}.56>=`;
          let _matchUpperOld = `Q.${item?.StockId}.56>`;
          if (item?.Rule?.match(_matchUpper)) {
            _Condition = '≥';
            let _ruleArr = item?.Rule?.split('&&');
            _priceFire = _ruleArr[0].replace(_matchUpper, '');
          } else if (item?.Rule?.match(_matchUpperOld)) {
            _Condition = '>';
            let _ruleArr = item?.Rule?.split('&&');
            _priceFire = _ruleArr[0].replace(_matchUpperOld, '');
          }
          
          let _matchLower = `Q.${item?.StockId}.56<=`;
          let _matchLowerOld = `Q.${item?.StockId}.56<`;

          if (item?.Rule?.match(_matchLower)) {
            _Condition = '≤';
            let _ruleArr = item?.Rule?.split('&&');
            _priceFire = _ruleArr[0].replace(_matchLower, '');
          } else if (item?.Rule?.match(_matchLowerOld)) {
            _Condition = '<';
            let _ruleArr = item?.Rule?.split('&&');
            _priceFire = _ruleArr[0].replace(_matchLowerOld, '');
          }

          let _orderType = '';
          if (item?.BotName?.match('StopLoss')) {
            _orderType = 'Stop Loss'
          } else if (item?.BotName?.match('SmartBuy')) {
            _orderType = 'Smart Buy'
          } else if (item?.BotName?.match('TakeProfit')) {
            _orderType = 'Take Profit'
          }

          return {...item, Condition: _Condition, OrderType: _orderType, State: _State, PriceFire: _priceFire }
        });

        return { data: datas };
      },
      providesTags: () => {
        return [{ type: TAG_SMART_ORDER_LIST, id: 'LIST' }];
      }
    }),
    createAlgoBOT: build.mutation({
      query: (body) => {
        return {
          url: `/trading/algo/CreateBot`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: [{ type: TAG_SMART_ORDER_LIST, id: 'LIST' }, { type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_TRADELIST, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    }),
    // createAlgoBOT: build.mutation({
    //   query: (params) => {
    //     let _params = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
    //     return {
    //       url: `/trading/algo/CreateBot?${_params}`,
    //       method: 'GET',
    //     }
    //   },
    //   invalidatesTags: [{ type: TAG_SMART_ORDER_LIST, id: 'LIST' }, { type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_TRADELIST, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    // }),
    deleteAlgoBOT: build.mutation({
      query: (params) => {
        let _params = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
        return {
          url: `/trading/algo/DeleteBot?${_params}`,
          method: 'GET',
        }
      },
      invalidatesTags: [{ type: TAG_SMART_ORDER_LIST, id: 'LIST' }, { type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_TRADELIST, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    }),
    getSplitOrder: build.query({
      query: (params) => {
        return {
          url: `/trading/split`,
          params
        };
      },
      transformResponse: (res) => res?.payloads || [],
    }),
    createAlgoNabungRutin: build.mutation({
      query: (body = { AlgoName: '', ClientID: '', Cost: '', Type: '', Date: '', StockID: '', EntryBy: '', AlgoExpired: ''}) => {
        return {
          url: `/trading/strategy/CreateAlgo`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: [{ type: TAG_NABUNG_RUTIN_LIST, id: 'LIST' }, { type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_TRADELIST, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }],
    }),
    getAlgoNabungRutin: build.query({
      async queryFn(clientID, { getState }, _extraOptions, fetchWithBQ) {
        const browserTabID = useBrowserTabId();
        const dealerUseClientId = getState()?.auth?.[browserTabID]?.dealerUseClientId;
        let params = {}
        params.clientID = dealerUseClientId || clientID;

        const { data } = await fetchWithBQ({
          url: '/trading/strategy/GetAlgoList',
          method: 'GET',
          params
        });

        return { data: data?.payloads ? JSON.parse(data?.payloads) : [] };
      },
      providesTags: () => {
        return [{ type: TAG_NABUNG_RUTIN_LIST, id: 'LIST' }];
      }
    }),
    deleteAlgoNabungRutin: build.mutation({
      query: (params = { clientID: '', algoID: '', userID: ''}) => {
        return {
          url: `/trading/strategy/DeleteAlgo`,
          method: 'GET',
          params
        }
      },
      invalidatesTags: [{ type: TAG_NABUNG_RUTIN_LIST, id: 'LIST' }],
    }),
  })
});

export const invalidateSmartOrderList = () => {
  return TradingAPI.util.invalidateTags([{ type: TAG_SMART_ORDER_LIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }]);
}

export const invalidateNabungRutin = () => {
  return TradingAPI.util.invalidateTags([{ type: TAG_NABUNG_RUTIN_LIST, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_BUYING_POWER, id: 'LIST' }]);
}

export const invalidateBuyingPower = () => {
  return TradingAPI.util.invalidateTags([{ type: TAG_BUYING_POWER, id: 'LIST' }, { type: TAG_PORTFOLIO, id: 'LIST' }, { type: TAG_ORDERLIST, id: 'LIST' }, { type: TAG_CLIENT_CASH, id: 'LIST' }]);
}

export const {
  useGetBuyingPowerQuery,
  useGetDepositQuery,
  useGetWithdrawQuery,
  useCreateWithdrawCashMutation,
  useLoginPINMutation,
  useCreateAlgoBOTMutation,
  useGetAlgoBOTQuery,
  useDeleteAlgoBOTMutation,
  useLazyGetSplitOrderQuery,
  useCreateAlgoNabungRutinMutation,
  useGetAlgoNabungRutinQuery,
  useDeleteAlgoNabungRutinMutation
} = TradingAPI;
