// eslint-disable-next-line react/prop-types
function IconEyeOff({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M5.97061 5.97058C3.91622 7.51593 2.78472 9.58416 2.26984 10.7407C1.91137 11.5458 1.91189 12.4556 2.27016 13.2602C3.13949 15.2125 5.89161 20.0001 12.0004 20.0001C14.5418 20.0001 16.5261 19.1607 18.0298 18.0297L16.598 16.5979C15.435 17.4108 13.9345 18.0001 12.0004 18.0001C7.14513 18.0001 4.89019 14.2275 4.09721 12.4466C3.96927 12.1593 3.96927 11.8409 4.09694 11.5541C4.55783 10.5189 5.55495 8.73212 7.30857 7.46899C7.33982 7.44648 7.36939 7.4225 7.39724 7.39721L5.97061 5.97058Z" />
      <path d="M9.1716 9.17163C8.44888 9.89446 8 10.8954 8 12.0001C8 14.2092 9.79086 16.0001 12 16.0001C13.1047 16.0001 14.1056 15.5512 14.8284 14.8285L13.4142 13.4143C13.0513 13.7774 12.5522 14.0001 12 14.0001C10.8954 14.0001 10 13.1046 10 12.0001C10 11.4478 10.2227 10.9488 10.5858 10.5858L9.1716 9.17163Z" />
      <path d="M12.0003 4C18.1092 4 20.8611 8.78782 21.7302 10.7401C22.0885 11.5448 22.0885 12.4552 21.7302 13.2599C21.5746 13.6096 21.3658 14.0352 21.0963 14.5009C20.8197 14.9789 20.208 15.1421 19.7299 14.8655C19.2519 14.5889 19.0887 13.9772 19.3653 13.4991C19.5948 13.1025 19.7722 12.7407 19.9031 12.4465C20.0309 12.1595 20.0309 11.8405 19.9031 11.5535C19.1103 9.77274 16.8556 6 12.0003 6L11 6.00001C10.4477 6.00001 10 5.5523 10 5.00002C9.99999 4.44773 10.4477 4.00001 11 4.00001L12.0003 4Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      />
    </svg>
  );
}

export default IconEyeOff;
