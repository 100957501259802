import useElementSize from 'hooks/useElementSize';
import TWIntradayChart from './tw-intraday-chart';
import cn from 'utils/classname';

export default function TWChartAndOrderbook({
  onChangeWidgetSettings,
  onChangeSymbol,
  idWidget,
  symbol,
  widgetSettings
}) {
  const { ref, dimensions } = useElementSize();
  const { width } = dimensions ?? {};

  return (
    <div
      ref={ref}
      className={cn(
        'grid grid-cols-2 gap-4 h-full',
        width < 350 && 'grid-cols-1'
      )}>
      <TWIntradayChart
        isCardLess
        idWidget={idWidget}
        symbol={symbol}
        views={{
          orderBook: false,
          buyOrSell: true,
          customFilter: true,
          customLegend: true,
          customHeader: true,
          chart: true
        }}
        containerHeight="calc(100% - 5rem)"
        onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
        widgetSettings={widgetSettings}
        onChangeWidgetSettings={onChangeWidgetSettings}
      />

      <TWIntradayChart
        isCardLess
        idWidget={idWidget}
        symbol={symbol}
        views={{
          orderBook: true,
          buyOrSell: false,
          customFilter: false,
          customLegend: true,
          customHeader: true,
          chart: false
        }}
        isRerender
        onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
        widgetSettings={widgetSettings}
        onChangeWidgetSettings={onChangeWidgetSettings}
      />
    </div>
  );
}
