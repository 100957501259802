import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { currency } from 'utils/format';
import useFocus from 'hooks/useFocus';

function StockAmendConfirmation({
  isOpen,
  isSubmitting,
  data = {},
  onClose = () => {},
  onSubmit = () => {},
}) {
  const [refFocus, setSubmitFocus] = useFocus();
  const [refCancelFocus, setCancelFocus] = useFocus();
  const [open, setOpen] = useState(isOpen);

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => setSubmitFocus(), 100);
  }, [open]);

  return (
    <div
      className="bg-white max-w-md dark:bg-black-dark-d rounded-lg dark:border dark:border-gray-base-d dark:text-black-light-1-d p-6 z-10 relative transform mx-4 lg:mx-auto zoom-2"
      style={{ transform: 'translateY(-3px)' }}>
      <div className="text-center pb-2 border-b dark:border-gray-base-d">
        <h5>Mohon cek kembali order kamu</h5>
        <p className="text-xs text-gray-light-0-d font-quicksand-medium">
          No Order: {data?.orderNumber}
        </p>
      </div>

      <div className="flex my-1 items-center">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-medium">
          Kode Saham
        </span>
        <span className="flex-grow text-right font-quicksand-bold">
          {data?.code}
        </span>
      </div>

      <div className="flex my-1 items-center">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-medium">
          Tipe Transaksi
        </span>
        <span className="flex-grow text-right font-quicksand-bold text-main-gold-d">
          Amend
        </span>
      </div>

      <div className="flex my-1 items-center">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-medium">
          Harga Per Saham
        </span>
        <span className="flex-grow text-right font-quicksand-bold">
          {currency(data?.price || 0, { removeSymbol: true })}
        </span>
      </div>

      <div className="flex my-1 items-center">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-medium">
          Jumlah Lot
        </span>
        <span className="flex-grow text-right font-quicksand-bold">
          {currency(data?.lot || 0, { removeSymbol: true })}
        </span>
      </div>

      <div className="flex my-1 items-center border-y py-2 dark:border-gray-base-d">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-medium">
          Jumlah Investasi (+Biaya)
        </span>
        <span className="flex-grow text-right font-quicksand-bold">
          {currency(data?.price * data?.lot * 100, { removeSymbol: true })}
        </span>
      </div>

      <div className="pt-3 mt-1">
        <div className="grid grid-cols-12 gap-4">
          <button
            data-qa-id="btnStockBuyFinishConfirmation"
            type="button"
            ref={refCancelFocus}
            onClick={onClose}
            onKeyDown={(event) => {
              if (event.key === 'ArrowRight') {
                event.preventDefault();
                setSubmitFocus();
              }
            }}
            className="col-span-6 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-medium rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75">
            Batal
          </button>

          <button
            ref={refFocus}
            data-qa-id="btnStockBuySubmitConfirmation"
            type="button"
            disabled={isSubmitting}
            onClick={onSubmit}
            onKeyDown={(event) => {
              if (event.key === 'ArrowLeft') {
                event.preventDefault();
                setCancelFocus();
              }
            }}
            className="col-span-6 bg-main-gold-d text-white py-3 px-3 text-sm font-quicksand-medium rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75">
            {isSubmitting && <Spinner />}
            Konfirmasi
          </button>
        </div>
      </div>
    </div>
  );
}

StockAmendConfirmation.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default StockAmendConfirmation;
