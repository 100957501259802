export const TAG_GOALS = 'Goals';
export const TAG_GOAL_CATEGORIES = 'GoalCategories';
export const TAG_RISK_PROFILES = 'RiskProfiles';
export const TAG_CFP_CLIENTS = 'CFPClients';
export const TAG_CFP_ACTION_PLANS = 'CFPActionPlans';
export const TAG_CFP_WATCHLIST = 'CFPWatchlist';
export const TAG_CFP_CONSULTATION_HISTORY = 'CFPConsultationHistory';
export const TAG_MUTUAL_FUNDS = 'MutualFunds';
export const TAG_STOCKS = 'Stocks';
export const TAG_STOCK_INFO = 'StockInfo';
export const TAG_STOCK_CHART = 'StockChart';
export const TAG_STOCK_CORP_ACTION = 'StockCorpAction';
export const TAG_STOCK_NEWS = 'StockNews';
export const TAG_STOCK_THEMATIC = 'StockThematic';
export const TAG_STOCK_TOP = 'StockTop';
export const TAG_STOCK_FUNDAMENTAL = 'StockFundamental';
export const TAG_STOCK_COMMODITIES = 'StockCommodities';
export const TAG_STOCK_CALENDAR = 'StockCalendar';
export const TAG_STOCK_GLOBAL_INDEX = 'StockGlobalIndex';
export const TAG_STOCK_TREEMAP = 'StockTreemap';
export const TAG_WATCHLIST = 'WatchList';
export const TAG_ORDERBOOK = 'OrderBook';
export const TAG_USERS = 'Users';
export const TAG_CLIENT = 'Client';
export const TAG_USER_COACHMARKS = 'UserCoachmarks';
export const TAG_TRADELIST = 'TradeList';
export const TAG_PORTFOLIO = 'TradeList';
export const TAG_CLIENTS = 'TradeClients';
export const TAG_PORTFOLIO_CHART = 'PortfolioChart';
export const TAG_PORTFOLIO_CHART_PER_STOCK = 'PortfolioChartPerStock';
export const TAG_ORDERLIST = 'OrderList';
export const TAG_ORDERLIST_HISTORY = 'OrderListHistory';
export const TAG_CLIENT_CASH = 'ClientCash';
export const TAG_BUYING_POWER = 'BuyingPower';
export const TAG_DEPOSIT = 'Deposit';
export const TAG_PRICE_ALERTS = 'PriceAlert';
export const TAG_USER_NOTIFICATION = 'UserNotifications';
export const TAG_USER_STOCK_SCREENER_LIST = 'UserStockScreenerList';
export const TAG_WORKSPACE = 'UserWorkspace';
export const TAG_PORTFOLIO_TERMS = 'PortfolioTerms';
export const TAG_STOCK_DONE_SUMMARY = 'StockDoneSummary';
export const TAG_STOCK_DONE_DETAIL = 'StockDoneDetail';
export const TAG_SMART_ORDER_LIST = 'SmartOrderList';
export const TAG_PROVINCES = 'ProvincesList';
export const TAG_CITIES = 'CitiesList';
export const TAG_DISTRICTS = 'DistrictsList';
export const TAG_VILLAGES = 'VillagesList';
export const TAG_BANKS = 'Banks';
export const TAG_OPENING_ACCOUNT = 'OpeningAccount';
export const TAG_NABUNG_RUTIN_LIST = 'NabungRutinList';
export const TAG_STOCK_TECHNICAL = 'StockTechnical';
export const TAG_TRADING_IDEAS = 'TradingIdeas';
export const TAG_CLASSROOM_AUTHORS = 'ClassroomAuthors';
export const TAG_BROKER_SUMMARY = 'BrokerSummary';
export const TAG_TRADINGVIEW_LIST = 'TradingviewList';

const TAGS = [
  TAG_GOALS,
  TAG_GOAL_CATEGORIES,
  TAG_RISK_PROFILES,
  TAG_CFP_CLIENTS,
  TAG_CFP_ACTION_PLANS,
  TAG_CFP_WATCHLIST,
  TAG_CFP_CONSULTATION_HISTORY,
  TAG_MUTUAL_FUNDS,
  TAG_STOCKS,
  TAG_STOCK_INFO,
  TAG_STOCK_CORP_ACTION,
  TAG_STOCK_NEWS,
  TAG_STOCK_THEMATIC,
  TAG_STOCK_TOP,
  TAG_STOCK_COMMODITIES,
  TAG_STOCK_CALENDAR,
  TAG_STOCK_GLOBAL_INDEX,
  TAG_STOCK_TREEMAP,
  TAG_WATCHLIST,
  TAG_ORDERBOOK,
  TAG_STOCK_CHART,
  TAG_USERS,
  TAG_CLIENT,
  TAG_TRADELIST,
  TAG_PORTFOLIO,
  TAG_PORTFOLIO_CHART,
  TAG_PORTFOLIO_CHART_PER_STOCK,
  TAG_ORDERLIST,
  TAG_ORDERLIST_HISTORY,
  TAG_CLIENT_CASH,
  TAG_BUYING_POWER,
  TAG_DEPOSIT,
  TAG_PRICE_ALERTS,
  TAG_WORKSPACE,
  TAG_USER_NOTIFICATION,
  TAG_USER_STOCK_SCREENER_LIST,
  TAG_STOCK_DONE_SUMMARY,
  TAG_SMART_ORDER_LIST,
  TAG_PORTFOLIO_TERMS,
  TAG_PROVINCES,
  TAG_CITIES,
  TAG_DISTRICTS,
  TAG_VILLAGES,
  TAG_BANKS,
  TAG_OPENING_ACCOUNT,
  TAG_NABUNG_RUTIN_LIST,
  TAG_USER_COACHMARKS,
  TAG_STOCK_TECHNICAL,
  TAG_TRADING_IDEAS,
  TAG_CLASSROOM_AUTHORS,
  TAG_BROKER_SUMMARY,
  TAG_STOCK_FUNDAMENTAL,
  TAG_STOCK_DONE_DETAIL,
  TAG_TRADINGVIEW_LIST,
  TAG_CLIENTS
];

export default TAGS;
