// import { marginPlusShare } from 'utils/formula/margin';
import { ORDER_COST } from 'constants';
import { getStampCostBuy } from 'utils/calculation';
import { object, number } from 'yup';

export const StockAmendSchema = object().shape({
  amount: number()
    .test('amount', 'Jumlah amount tidak cukup', function (value, { parent }) {
      if (!parent.lotType) {
        return Math.floor(value / (parent.price * 100)) > 0;
      }

      return true;
    })
    .required('Jumlah amount harus diisi'),
  lot: number().min(1, 'Jumlah lot harus diisi.').required('Required'),
  price: number().min(1, 'Harga saham harus diisi.').required('Required'),
  id: number()
    .test('id', 'Order harus dipilih', function (value) {
      return value;
    })
    .required('Required'),
  buyingPower: number()
    .test(
      'buyingPower',
      'Maaf, saldo anda tidak mencukupi.',
      function (value, { parent }) {
        return (
          value >=
            parent?.lot * 100 * parent?.price +
              Math.ceil(ORDER_COST?.buy * parent?.price * parent?.lot) +
              getStampCostBuy(value) || parent?.side === 'S'
        );
      }
    )
    .required('Maaf, saldo anda tidak mencukupi.')
});
