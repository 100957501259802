import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import Tabs from 'components/tabs/tabs';
import TWOrderlist from './tw-orderlist';
import TWPortfolio from './tw-portfolio';
import { useEffect, useState } from 'react';
import TWOrderlistDoneDetail from './tw-orderlist-done-detail/tw-orderlist-done-detail';

function RenderWidget({ activeIndex, isZoomOut, isInline }) {
  if (activeIndex === 0) {
    return <TWOrderlist title={false} isZoomOut={isZoomOut} isInline={isInline} />;
  }

  if (activeIndex === 1) {
    return (
      <div className="px-1 h-[calc(100%-42px)]">
        <TWOrderlistDoneDetail title={false} isZoomOut={isZoomOut} isInline={isInline} />
      </div>
    );
  }

  if (activeIndex === 2) {
    return <TWPortfolio title={false} isZoomOut={isZoomOut} isInline={isInline} />
  }

  return <></>;
}

function TWBundleOrderlist({
  onChangeWidgetSettings = () => {},
  widgetSettings = {},
  isZoomOut = false,
  isInline = false
}) {
  const [activeTabIndex, setActiveIndexTab] = useState(
    widgetSettings?.activeTabIndex || 0
  );

  const [tabs] = useState([
    {
      index: 0,
      text: 'Day Order List'
    },
    {
      index: 1,
      text: 'Day Done Detail'
    },
    {
      index: 2,
      text: 'Portfolio'
    }
  ]);

  useEffect(() => {
    if (!isInline) {
      setActiveIndexTab(widgetSettings?.activeTabIndex || 0);
    }
  }, [widgetSettings, isInline]);

  return (
    <div className="h-full">
      <Tabs
        initialOpenIndex={activeTabIndex}
        className="grid grid-cols-3 font-quicksand-semibold text-xs text-gray-light-0-d sticky top-0 left-0 z-20"
        classObject={{
          li: 'hover:border-main-base-l',
          active: 'border-main-base-l border-b-2',
          inactive: 'border-gray-base-l border-b dark:border-gray-base-d'
        }}
        data={tabs}
        onChange={(index) => {
          if (isInline) setActiveIndexTab(index);
          onChangeWidgetSettings({ activeTabIndex: index });
        }}>
        <RenderWidget activeIndex={activeTabIndex} isZoomOut={isZoomOut} isInline={isInline} />
      </Tabs>
    </div>
  );
}

TWBundleOrderlist.propTypes = {
  onChangeWidgetSettings: PropTypes.func,
  widgetSettings: PropTypes.object
};

export default TWBundleOrderlist;
