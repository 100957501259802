import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import RenderIf from 'components/basic/render-if';
import RDNStep01 from './_partials/rdn-step-01';
import RDNStep02 from './_partials/rdn-step-02';
import RDNStep03 from './_partials/rdn-step-03';
import RDNStep04 from './_partials/rdn-step-04';
import RDNStep05 from './_partials/rdn-step-05';
import RDNStepStatus from './_partials/rdn-step-status';
import RDNCreatePin from './_partials/rdn-create-pin';
import RDNStepInitialization from './_partials/rdn-step-initialization';
import { isMobile } from 'react-device-detect';

function OnboardingPage() {
  const { slug } = useParams();

  const stepper = [
    {
      id: 0,
      text: 'Buat RDN atau Explore Dulu',
      slug: 'initialization',
      component: <RDNStepInitialization />
    },
    {
      id: 0,
      text: 'Pendaftaran Akun & RDN',
      slug: 'phone-number',
      component: <RDNStep01 />
    },
    {
      id: 1,
      text: 'Informasi Pribadi',
      slug: 'informasi-pribadi',
      component: <RDNStep02 />
    },
    {
      id: 2,
      text: 'Informasi Tambahan',
      slug: 'informasi-tambahan',
      component: <RDNStep03 />
    },
    {
      id: 3,
      text: 'Pernyataan dan Informasi Lainnya',
      slug: 'pernyataan-informasi-lainnya',
      component: <RDNStep04 />
    },
    {
      id: 4,
      text: 'Syarat & Ketentuan',
      slug: 'syarat-dan-ketentuan',
      component: <RDNStep05 />
    },
    {
      id: 5,
      text: 'Buat PIN',
      slug: 'create-pin',
      component: <RDNCreatePin />
    }
  ];

  useEffect(() => {
    if (!isMobile && slug != 'status') {
      window.onbeforeunload = function () {
        return 'Please press the Logout button to logout.';
      };
    }
  }, [slug]);

  return (
    <div>
      <Helmet>
        <title>Buat Akun RDN | Pina Trade</title>
      </Helmet>

      {stepper?.map((item, key) => (
        <RenderIf key={key} isTrue={slug === item?.slug}>
          {item?.component}
        </RenderIf>
      ))}

      <RenderIf isTrue={slug === 'status'}>
        <RDNStepStatus />
      </RenderIf>
    </div>
  );
}

export default OnboardingPage;
