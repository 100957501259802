import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import IconClose from 'components/svg/icon-close';

function ModalAgreementSmartOrder({ onClose = () => {} }) {
  return (
    <Card className="p-6 lg:p-10 max-w-2xl lg:mx-auto relative mx-6 dark:border dark:border-gray-base-d dark:text-black-light-1-d">
      <button
        onClick={() => onClose(false)}
        type="button"
        className="right-4 top-4 absolute hover:opacity-75 dark:text-main-base-d">
        <IconClose className="h-6 w-6" />
      </button>

      <h5 className="text-base font-quicksand-regular mb-6 text-center">
        ADDENDUM <br />
        PERJANJIAN PEMBUKAAN REKENING EFEK ANTARA <br />
        PT TRUST SEKURITAS,Tbk DENGAN NASABAH
      </h5>

      <div className="h-full text-left  item-center self-center p-5 text-sm">
        <p>
          Perjanjian pembukaan rekening efek (<q>Perjanjian</q>) ini merupakan
          satu kesatuan dan tidak terpisahkan dengan Formulir dan Persyaratan
          dan Ketentuan Perdagangan Efek yang dibuat dan ditandatangani oleh dan
          antara :
        </p>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <h1>
              <strong>PT TRUST SEKURITAS</strong>, suatu Perseroan Terbatas yang
              didirikan sacara sah berdasarkan hukum Negara Republik Indonesia,
              berkantor pusat di Jakarta, untuk selanjutnya disebut “Perusahaan”
            </h1>
            <br />
          </li>
          <li data-list-text="2.">
            <p>
              Nasabah yang datanya tercantum dalam Formulir, untuk selanjutnya
              disebut “Nasabah”
            </p>
          </li>
        </ol>
        <br />
        <p>
          Perusahaan dan Nasabah selanjutnya disebut sebagai “Para Pihak”
          sepakat untuk mengikatkan diri dalam Perjanjian dengan ketentuan dan
          persyaratan sebagai berikut :
        </p>
        <br />
        <h1>
          <strong>Pasal I</strong>
        </h1>
        <h1>
          <strong>Definisi dalam Perjanjian ini yang dimaksud :</strong>
        </h1>
        <br />
        <h1>
          <strong>Bank Pembayaran</strong> adalah Bank umum yang ditunjuk oleh
          KSEI untuk melakukan penyimpanan dana dan untuk melaksanakan setiap
          pembayaran terkait dengan penyelesaian transaksi Efek pada Perusahaan.
        </h1>
        <br />
        <h1>
          <strong>Batas Transaksi </strong>
          <i>(Trading Limit) </i>adalah jumlah maksimum nilai transaksi yang
          dapat dilakukan oleh Nasabah yang besarnya ditentukan dari waktu ke
          waktu oleh Perusahaan sesuai dengan kebijakan Perusahaan.
        </h1>
        <br />
        <h1>
          <strong>Bursa Efek </strong>adalah pihak yang menyelenggarakan dan
          menyediakan sistem dan/atau sarana untuk mempertemukan penawaran jual
          dan beli Efek Pihak-pihak lain dengan tujuan memperdagangkan efek di
          antara mereka.
        </h1>
        <br />
        <h1>
          <strong>Fasilitas Marjin</strong> adalah fasilitas yang diberikan
          Perusahaan kepada Nasabah untuk membiayai transaksi Efek bagi
          kepentingan Nasabah sesuai dengan syarat dan ketentuan Pembiayaan
          Penyelesaian Transaksi Efek (Persyaratan Perjanjian Marjin) dan
          peraturan perundang-undangan Pasar Modal yang berlaku.
        </h1>
        <br />
        <h1>
          <strong>Denda Short Selling</strong> adalah denda yang dikenakan oleh
          Perusahaan kepada Nasabah apabila Nasabah melakukan Short Selling
          tanpa persetujuan Perusahaan yaitu minimal sebesar yang dikenakan oleh
          KPEI kepada Perusahaan serta segala biaya lainnya dan risiko yang
          mungkin timbul dari tindakan tersebut.
        </h1>
        <br />
        <h1>
          <strong>Efek</strong> adalah surat (-surat) berharga, yaitu surat
          pengakuan hutang, surat berharga komersial, saham, obligasi, tanda
          bukti hutang, unit penyertaan kontrak investasi kolektif, kontrak
          berjangka atas efek dan setiap derivatif dari efek.
        </h1>
        <br />
        <h1>
          <strong>Formulir</strong> adalah isian informasi yang diberikan oleh
          Nasabah kepada Perusahaan sehubungan dengan pembukaan dan
          pengoperasian rekening Nasabah.
        </h1>
        <br />
        <h1>
          <strong>Hari Bursa</strong> adalah hari diselenggarakannya perdagangan
          efek di Bursa, mulai dari Senin sampai Jumat, kecuali hari tersebut
          merupakan hari libur nasional atau dinyatakan sebagai hari libur bursa
          oleh Bursa Efek.
        </h1>
        <br />
        <h1>
          <strong>Jaminan</strong> <i>(Collateral) </i>adalah uang dan/atau efek
          yang harus diserahkan Nasabah kepada Perusahaan untuk kepentingan
          transaksi efek guna menjamin penyelesaian kewajiban Nasabah kepada
          Perusahan sehubungan dengan pelaksanaan Transaksi Efek Nasabah.
        </h1>
        <br />
        <h1>
          <strong>
            Konfirmasi Transaksi Efek (<i>Trade Confirmation</i>)
          </strong>{' '}
          adalah konfirmasi yang disampaikan oleh Perusahaan kepada Nasabah
        </h1>

        <p>
          mengenai transaksi (jual atau beli) Efek yang dilakukan oleh Nasabah
          pada hari bersangkutan.
        </p>
        <br />
        <h1>
          <strong>Kewajiban</strong> adalah jumlah pokok nilai terutang baik
          karena pembelian maupun karena sebab lainnya ditambah dengan komisi,
          biaya transaksi, denda, pinalti, pajak dan lainnya yang menyebabkan
          Nasabah memiliki Saldo Negatif.
        </h1>
        <br />
        <h1>
          <strong>Kustodian</strong> adalah Perusahaan atau pihak lain yang
          memberikan jasa penitipan Efek dan harta lain yang berkaitan dengan
          Efek, termasuk menerima dividen, bunga dan hak-hak lain, menyelesaikan
          transaksi Efek dan mewakili pemegang Rekening yang menjadi nasabahnya.
        </h1>
        <br />
        <h1>
          <strong>Laporan Rekening</strong> adalah laporan yang disampaikan
          Perusahaan kepada Nasabah mengenai ringkasan transaksi Nasabah selama
          satu bulan berjalan, saldo dana atau kewajiban Nasabah dan saldo Efek
          Nasabah pada posisi akhir bulan yang bersangkutan.
        </h1>
        <br />
        <h1>
          <strong>Laporan Rekening Efek (Client Statement)</strong> adalah
          laporan yang disampaikan Perusahaan kepada Nasabah mengenai ringkasan
          transaksi Nasabah selama satu bulan berjalan dan/atau periode tertentu
          yang berisikan saldo uang tunai atau kewajiban Nasabah.
        </h1>
        <br />
        <h1>
          <strong>Laporan Posisi Saldo Efek (Client Portfolio)</strong> adalah
          laporan yang disampaikan Perusahaan kepada Nasabah mengenai ringkasan
          posisi saldo Efek yang dimiliki oleh Nasabah pada akhir bulan atau
          tanggal tertentu dan/ atau posisi Efek akhir hari (h-1) dari tanggal
          pencetakan laporan tersebut.
        </h1>
        <br />
        <h1>
          <strong>Lembaga Kliring dan Penjaminan (”LKP”)</strong> adalah pihak
          yang menyelenggarakan jasa kliring dan penjaminan penyelesaian
          transaksi bursa.
        </h1>
        <br />
        <h1>
          <strong>Lembaga Penyimpanan dan Penyelesaian (”LPP”)</strong>
          adalah pihak yang menyelenggarakan kegiatan Kustodian Sentral bagi
        </h1>
        <p>Bank Kustodian, Perusahaan Efek, dan pihak lain.</p>
        <br />
        <p>
          <b>Nasabah </b>adalah perorangan atau badan hukum atau bentuk usaha
          atau organisasi lainnya yang diatur oleh peraturan perundang-undangan
          dapat bertindak dan diizinkan untuk membuka Rekening Efek yang telah
          mengisi Formulir Pembukaan Rekening yang merupakan satu kesatuan dan
          bagian yang tidak terpisahkan dengan Perjanjian ini baik yang memiliki
          Rekening maupun <i>Walk in Customer</i>.
        </p>
        <br />
        <h1>
          <b>Otoritas Jasa Keuangan</b> adalah Badan Pengawas Pasar Modal dan
          Lembaga Keuangan atau OJK adalah Otoritas Jasa Keuangan berdasarkan
          Undang-undang No. 21 Tahun 2011 berikut perubahan serta penggantinya.
        </h1>
        <br />
        <h1>
          <b>Pasar Modal</b> adalah kegiatan yang bersangkutan dengan penawaran
          umum dan perdagangan efek, perusahaan publik yang berkaitan dengan
          Efek yang diterbitkannya, serta lembaga dan profesi yang berkaitan
          dengan efek.
        </h1>
        <br />
        <h1>
          <b>Penyedia Jasa Keuangan</b> yang selanjutnya disingkat PJK adalah
          PJK di sektor perbankan, PJK di sektor Pasar Modal, PJK di Industri
          Keuangan Non Bank.
        </h1>
        <br />
        <h1>
          <b>PJK di Sektor Perbankan</b> adalah bank umum, termasuk kantor
          cabang dari bank yang berkedudukan di luar negeri, bank umum syariah,
          bank perkreditan rakyat yang selanjutnya disebut BPR, dan bank
          pembiayaan rakyat syariah yang selanjutnya disebut BPRS sebagaimana
          diatur dalam ketentuan peraturan perundang- undangan di bidang
          Perbankan.
        </h1>
        <br />
        <h1>
          <b>PJK di Sektor Pasar Modal</b> adalah perusahaan Efek yang melakukan
          kegiatan usaha sebagai penjamin emisi efek, perantara pedagang efek,
          dan/atau manajer investasi, serta bank umum yang menjalankan fungsi
          kustodian sebagaimana dimaksud dalam ketentuan peraturan
          perundang-undangan di bidang Pasar Modal.
        </h1>
        <br />
        <h1>
          <b>PJK di Sektor Industri Keuangan Non Bank</b> adalah perusahaan
          asuransi, perusahaan asuransi syariah, perusahaan pialang asuransi,
          dana pensiun lembaga keuangan (DPLK), perusahaan pembiayaan,
          perusahaan modal ventura (PMV), perusahaan pembiayaan infrastruktur,
          lembaga pembiayaan ekspor Indonesia (LPEI), perusahaan pegadaian,
          lembaga keuangan mikro (LKM), dan penyelenggara layanan pinjam
          meminjam uang berbasis teknologi informasi sebagaimana dimaksud dalam
          ketentuan peraturan perundang-undangan di Industri Keuangan Non Bank.
        </h1>
        <h1>
          <b>Rekening Dana Nasabah</b> adalah rekening atas nama Nasabah yang
          terdapat pada Bank yang ditunjuk oleh Perusahaan dalam rangka
          penerimaan, penyimpanan dana, dan pembayaran transaksi Nasabah.
        </h1>
        <br />
        <h1>
          <b>Rekening (Efek) Nasabah</b> adalah satu atau lebih atau seluruh
          rekening (-rekening) yang dibuka atas nama Nasabah di Perusahaan,
          termasuk tetapi tidak terbatas pada Rekening Efek Reguler dan/atau
          Rekening Efek Marjin.
        </h1>
        <h1>
          <b>Rekening Nasabah Tidak Aktif</b>(<i>Dormant Account</i>) adalah
          Rekening Nasabah yang tidak mempunyai Efek dan dana serta tidak
          terdapat mutasi Efek dan/atau dana dalam jangka waktu 6 (enam) bulan
          berturut-turut terhitung sejak tanggal pencatatan terakhir saldo Efek
          dan/atau dana.
        </h1>
        <br />
        <h1>
          <b>Rekening Efek Margin</b> adalah Rekening Nasabah yang diberikan
          fasilitas oleh Perusahaan berupa transaksi pembelian Efek untuk
          kepenting Nasabah yang dibiayai oleh Perusahaan dengan syarat dan
          ketentuan yang akan diatur tersendiri oleh Perusahaan dan Nasabah.
        </h1>
        <br />
        <h1>
          <b>Saldo Debet</b> adalah saldo dalam Rekening Nasabah yang
          menunjukkan kewajiban Nasabah kepada Perusahaan.
        </h1>
        <br />
        <h1>
          <b>Saldo Kredit</b> adalah saldo dalam Rekening Nasabah yang
          menunjukkan kewajiban Perusahaan kepada Nasabah.
        </h1>
        <br />
        <h1>
          <b>Saldo Negatif</b> adalah apabila posisi kewajiban serah dana
          Nasabah pada tanggal penyelesaian lebih besar dibandingkan dengan
          saldo dana yang dijaminkan dan saldo dana pada Rekening Dana Nasabah
          yang bersangkutan.
        </h1>
        <br />
        <p>
          <b>SID </b>adalah <i>Single Investor Identity </i>yang wajib dimiliki
          oleh setiap Nasabah yang dikeluarkan oleh KSEI dalam bentuk kartu
          AKses (Acuan Kepemilikan Sekuritas) yang berfungsi untuk mengetahui
          berapa aset dan dana yang dimilikinya setiap saat.
        </p>
        <br />
        <h1>
          <b>Sub Rekening Efek </b>adalah rekening yang dibuka pada LPP atau
          Kustodian atas nama Nasabah yang digunakan untuk pengadministrasian
          dan pengelolaan Efek Nasabah.
        </h1>
        <br />
        <h1>
          <b>Surat Pesanan</b> adalah surat yang memuat instruksi Nasabah kepada
          Perusahaan untuk melaksanakan transaksi jual dan/atau beli Efek.
        </h1>
        <br />
        <h1>
          <b>Tanggal Jatuh Tempo</b> adalah tanggal penyelesaian sebagaimana
          tercantum dalam Konfirmasi Transaksi Efek yang berisi batas waktu
          Nasabah atau Perusahaan wajib untuk menyelesaikan kewajibannya.
        </h1>
        <br />
        <h1>
          <b>Transaksi Efek Nasabah</b> adalah seluruh transaksi pembelian
          dan/atau penjualan Efek oleh Nasabah melalui Perusahaan, termasuk
          tetapi tidak terbatas pada transaksi pada Rekening Efek Regular
          dan/atau Rekening Efek Marjin
        </h1>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 2</b>
        </h1>
        <h1>
          <b>Pembukaan Rekening dan Pemberian Kuasa</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>Dengan tunduk sepenuhnya pada peraturan Perundang-</p>
            <p>
              undangan yang berlaku di bidang Pasar Modal, Nasabah dengan ini
              mengakui dan menyetujui untuk membuka rekening (-rekening)
              transaksi atas nama Nasabah pada Perusahaan untuk kepentingan
              kegiatan Transaksi Efek Nasabah yang akan dilakukan oleh dan/atau
              atas dasar perintah dari Nasabah sesuai dengan syarat dan
              ketentuan dalam Perjanjian ini berikut tambahan dan lampirannya
              (apabila ada).
            </p>
            <br />
          </li>
          <li data-list-text="2.">
            <p>
              Apabila Nasabah bertindak untuk dan atas nama pihak lain untuk
              membuka Rekening Nasabah, Nasabah wajib menyediakan kepada
              Perusahaan dokumen-dokumen pendukung beserta rincian hubungan
              hukum, keterlibatan, dan kewenangan untuk bertindak untuk dan atas
              nama pihak lain yang terkait.
            </p>
            <br />
          </li>
          <li data-list-text="3.">
            <p>
              Apabila Nasabah merupakan PJK di dalam negeri dan luar negeri yang
              bertindak untuk dan atas nama pihak lain, maka Nasabah wajib
              menyampaikan kepada Perusahaan berupa pernyataan tertulis bahwa
              Nasabah telah memperoleh, memeriksa dan mengidentifikasi dokumen
              pendukung dari investor Nasabah sesuai yang diminta oleh peraturan
              dan bersedia untuk memberikan informasi dan dokumen- dokumen
              pendukung jika dibutuhkan oleh Perusahaan.
            </p>
            <br />
          </li>
          <li data-list-text="4.">
            <p>
              Nasabah membuka Sub Rekening Efek untuk menampung Efek atas nama
              Nasabah pada KSEI bagi yang secara peraturan diwajibkan. Pembukaan
              Sub Rekening Efek pada KSEI dilakukan dengan memberikan kuasa
              kepada Perusahaan sesuai dengan ketentuan yang ditetapkan oleh
              KSEI. Efek yang dimiliki Nasabah wajib disimpan secara terpisah
              oleh Perusahaan dalam Sub Rekening Efek untuk masing-masing
              Nasabah atas nama Nasabah tersebut.
            </p>
            <br />
          </li>
          <li data-list-text="5.">
            <p>
              Dalam hal Nasabah belum memiliki SID, maka pembukaan Sub Rekening
              Efek akan disertai dengan pembuatan SID di KSEI yang dilakukan
              oleh Perusahaan untuk kepentingan Nasabah.
            </p>
          </li>
          <br />
          <li data-list-text="6.">
            <p>
              Nasabah wajib membuka Rekening Dana Nasabah atas nama Nasabah bagi
              yang diwajibkan oleh peraturan perundang-undangan pada Bank yang
              telah bekerja sama dengan Perusahaan dengan persyaratan yang
              ditetapkan oleh Bank tersebut. Perusahaan akan menggunakan
              Rekening Dana Nasabah untuk penerimaan dana dan pembayaran
              transaksi Efek yang Rekening Dana Bank tersebut terpisah satu sama
              lain antara Rekening Perusahaan dan Rekening milik Nasabah
              lainnya.
            </p>
          </li>
          <br />
          <li data-list-text="7.">
            <p>
              Nasabah dengan ini mengakui dan menyetujui bahwa dengan
              ditandatanganinya Perjanjian ini Nasabah berkewajiban untuk
              menyerahkan kepada Perusahaan semua dokumen pendukung dan hal
              lainnya yang wajib dipenuhi Nasabah sesuai dengan peraturan
              perundang- undangan yang berlaku, Perjanjian ini dan Persyaratan
              dan Ketentuan (i) Transaksi Efek pada Rekening Efek Regular
              dan/atau (ii) Pembiayaan Penyelesaian Transaksi Efek dengan
              Fasilitas Margin dan atau (iii) Sub Rekening di KSEI dan/atau (iv)
              Identitas Nasabah Tunggal (SID) dan/atau Acuan Kepemilikan
              Sekuritas (AKSes) dan/atau(v) Rekening Dana Nasabah di Bank yang
              telah melakukan kerjasama dengan Perusahaan dan/atau (vii) Produk
              lainnya yang akan dikeluarkan oleh Perusahaan di kemudian hari;
              yang akan dipilih dan disetujui oleh Nasabah dengan menandatangani
              Persyaratan dan Ketentuan tersebut, dan Nasabah menyadari bahwa
              Perusahaan berhak untuk menolak membuka Rekening Nasabah tanpa
              pemberitahuan terlebih dahulu.
            </p>
          </li>
          <br />
          <li data-list-text="8.">
            <p>
              Setiap perubahan yang berhubungan dengan keterangan dalam Formulir
              termasuk kelengkapan dokumen yang dipersyaratkan wajib
              diberitahukan kepada Perusahan
            </p>
            <p>
              secara tertulis selambat-lambatnya 3 (tiga) Hari Bursa setelah
              terjadinya perubahan.
            </p>
            <br />
          </li>
          <li data-list-text="9.">
            <p>
              Nasabah dengan ini mengakui dan menyetujui bahwa dengan
              ditandatanganinya Perjanjian ini Nasabah memberi kuasa kepada
              Perusahaan untuk melakukan setiap tindakan untuk kepentingan
              Nasabah sehubungan dengan Transaksi Efek sesuai dengan peraturan
              yang berlaku di Indonesia ter
            </p>
            <ol className="list-decimal ml-4 ml-8">
              <li data-list-text="1)">
                <p>
                  menjadi perantara dalam setiap transaksi Efek yang dilakukan
                  oleh Nasabah di Bursa Efek Indonesia melalui Perusahaan sesuai
                  dengan syarat-syarat dan kondisi yang ditetapkan oleh
                  Perusahaan dan ketentuan yang berlaku di pasar modal pada saat
                  transaksi dilaksanakan;
                </p>
              </li>
              <li data-list-text="2)">
                <p>
                  membuka dan memelihara Rekening (-rekening) Nasabah pada
                  Perusahaan untuk tujuan pembelian, pembayaran Efek yang
                  dibeli, pendaftaran Efek yang dibeli, penjualan, penukaran,
                  penyerahan, penerimaan terkait transaksi Efek dan pengumpulan
                  dana terkait dengan transaksi Efek tersebut;
                </p>
              </li>
              <li data-list-text="3)">
                <p>
                  membuka, memelihara dan mengoperasikan Sub Rekening Efek
                  beserta dengan Efek yang terdapat pada Sub Rekening Efek
                  tersebut atas nama dan untuk kepentingan Nasabah kepada LPP
                  dan dengan kewajiban bagi Perusahaan untuk melaksanakan kuasa
                  pembukaan Sub Rekening Efek ini sesuai dengan ketentuan
                  peraturan perundang-undangan yang berlaku;
                </p>
              </li>
              <li data-list-text="4)">
                <p>
                  melakukan pembuatan nomor tunggal identitas pemodal (single
                  investor identification) dan Kartu AKses untuk Nasabah pada
                  KSEI;
                </p>
              </li>
              <li data-list-text="5)">
                <p>
                  menerima atau menyerahkan sejumlah uang dan/atau Efek yang
                  ditransaksikan dari atau kepada Kustodian Nasabah apabila
                  Nasabah memiliki jasa Kustodian tersendiri, atas nama dan
                  untuk kepentingan Nasabah;
                </p>
              </li>
              <li data-list-text="6)">
                <p>
                  membuka, mengoperasikan, melakukan
                  pendebetan/pemindahbukuan/transfer atas Rekening Dana untuk
                  pembayaran, pelunasan atas transaksi Efek beserta komisi,
                  biaya jasa perusahaan Efek, pengeluaran biaya bea, ganti rugi,
                  denda, penalti, provisi, pajak, biaya layanan jasa sub
                  rekening, biaya penitipan, biaya lainnya serta penyelesaian
                  segala kewajiban Nasabah kepada Perusahaan maupun pihak ketiga
                  lainnya yang terkait serta mengkreditkan setiap hasil
                  penjualan kepada Rekening Dana;
                </p>
              </li>
              <li data-list-text="7)">
                <p>
                  mengkreditkan setiap hasil penjualan, penukaran atau
                  penyerahan Efek atas nama Nasabah ke Rekening Dana Nasabah
                  (kecuali diperintahkan lain oleh Nasabah);
                </p>
              </li>
              <li data-list-text="8)">
                <p>
                  memberikan dan/atau menerima data termasuk mutasi dan/atau
                  saldo dana yang ada dalam Rekening Dana yang ada di Bank
                  Pembayaran kepada KSEI;
                </p>
              </li>
              <li data-list-text="9)">
                <p>
                  menyerahkan dana dan/atau Efek Nasabah yang berada pada
                  Perusahaan ke rekening jaminan Perusahaan atau sub rekening
                  jaminan Nasabah di LKP dan/atau LPP sepanjang dana dan/atau
                  Efek tersebut dicatat atas nama Nasabah sehubungan dengan
                  jaminan penyelesaian transaksi;
                </p>
              </li>
              <li data-list-text="10)">
                <p>
                  menandatangani surat pesanan dalam hal perintah dilakukan
                  secara lisan.
                </p>
              </li>
              <li data-list-text="11)">
                <p>
                  membuat dan menandatangani dokumen-dokumen,
                  perjanjian-perjanjian atau melakukan perbuatan hukum lainnya
                  dengan pihak lain atas nama dan untuk kepentingan Nasabah
                  sehubungan dengan pelaksanaan Perjanjian ini oleh Perusahaan,
                  termasuk akan tetapi tidak terbatas pada pemindahan Efek (
                  <i>transfer</i>), registrasi dan lain sebagainya;
                </p>
              </li>
              <li data-list-text="12)">
                <p>menerima dan mengumpulkan:</p>
                <ol>
                  <li data-list-text="i.">
                    <p>
                      bunga yang didistribusikan atau dibayarkan atas Efek milik
                      Nasabah yang disimpan di Perusahaan; atau
                    </p>
                  </li>
                  <li data-list-text="ii.">
                    <p>
                      Efek, uang atau harta lain yang berasal dari atau
                      ditawarkan sebagai bonus, dividen, pengembalian
                      (pembayaran kembali), pemecahan atau penggabungan Efek,
                      pelaksanaan opsi atau lainnya sehubungan dengan Efek dan
                      mengkreditkan ke Rekening Nasabah (kecuali diperintahkan
                      lain oleh Nasabah).
                    </p>
                  </li>
                </ol>
              </li>
              <li data-list-text="13)">
                <p>
                  menggunakan Efek dalam Rekening Efek Nasabah sebagai jaminan
                  atas pinjaman dari bank atau lembaga keuangan lainnya atau
                  melakukan penjualan Efek secara paksa (force sell) tanpa
                  persetujuan Nasabah dalam hal dana dalam Rekening Efek Nasabah
                  menunjukkan saldo negatif untuk menyeimbangkan kembali saldo
                  tersebut;
                </p>
              </li>
              <li data-list-text="14)">
                <p>
                  menyesuaikan, menggabungkan, melikuidasi atau menutup (tanpa
                  harus menyebutkan suatu alasan apapun juga) Rekening Nasabah
                  dan melaksanakan kompensasi atau perjumpaan hak dan/atau
                  kewajiban atau memindahkan jumlah kredit pada Rekening Nasabah
                  tersebut untuk setiap pelunasan atas kewajiban atau hutang
                  Nasabah kepada Perusahaan. Dalam hal penyesuaian,
                  penggabungan, likuidasi, kompensasi atau perjumpaan hak
                  dan/atau kewajiban atau pemindahan jumlah kredit tersebut
                  diatas memerlukan atau mengharuskan konversi mata uang dari
                  dana yang ada dalam rekening Nasabah tersebut, maka konversi
                  tersebut dilaksanakan dengan kurs konversi yang ditetapkan
                  oleh Perusahaan pada hari dilaksanakannya penyesuaian,
                  penggabungan, likuidasi, kompensasi atau perjumpaan hak
                  dan/atau kewajiban atau pemindahan jumlah kredit tersebut.
                </p>
              </li>
              <li data-list-text="15)">
                <p>
                  Menarik Dana dari Rekening Dana Nasabah dan/atau mencairkan
                  atau menjual Jaminan dan/atau Efek yang ada pada Sub Rekening
                  Nasabah (dan memperhitungkan hasil penjualan tersebut untuk
                  kepentingan Nasabah dan Perusahaan) segera atau pada waktu
                  terbaik menurut kebijakan Perusahaan sebagai akibat Nasabah
                  melakukan cidera janji termasuk tetapi tidak terbatas karena
                  gagal bayar, gagal serah, penurunan nilai Jaminan sampai pada
                  tingkat tertentu sebagaimana dipersyaratkan oleh Perusahaan
                  yang akan digunakan untuk pelunasan seluruh atau sebagian
                  hutang atau Kewajiban Nasabah kepada Perusahaan;
                </p>
              </li>
              <li data-list-text="16)">
                <p>
                  atas kebijakan Perusahaan, memberikan atau mencabut sebagian
                  atau seluruh kuasa yang diberikannya kepada pihak lain, agen
                  atau substitusi untuk atau atas nama Perusahaan sehubungan
                  dengan semua atau setiap dari kuasa yang terkandung di dalam
                  surat kuasa yang diberikan kepada pihak lain tersebut dengan
                  syarat dan ketentuan sebagaimana dianggap layak oleh
                  Perusahaan untuk kepentingan Nasabah;
                </p>
              </li>
              <li data-list-text="17)">
                <p>
                  melakukan tindakan-tindakan lain yang dianggap perlu oleh
                  Perusahaan terkait dengan pembukaan rekening serta transaksi
                  Efek atas nama Nasabah agar ketentuan-ketentuan dalam
                  Perjanjian dapat dilaksanakan dengan sebaik-baiknya;
                </p>
              </li>
              <li data-list-text="18)">
                <p>
                  menghadap kepada lembaga atau instansi atau pejabat pemerintah
                  maupun swasta, berbicara, memberikan, dan menerima keterangan
                  atau penjelasan terkait Efek dan/atau Dana Nasabah, membuat,
                  menyuruh membuat semua surat atau akta yang diperlukan,
                  menandatangani semua surat atau akta tersebut, melakukan atau
                  menerima dan memberikan tanda terima pembayaran, menempatkan
                  atau menarik dana dari Rekening Dana, melakukan atau menerima
                  penyerahan, menempatkan atau memindahkan atau menarik Efek
                  dari Rekening Efek
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          Nasabah dari segala tindakan hukum lain yang diperlukan dalam rangka
          pelaksanaan keperluan tersebut diatas;
        </p>
        <p>
          Semua hal tersebut diatas beserta segala akibat dan kewajiban yang
          timbul adalah atas tanggungan dan risiko Nasabah. Kuasa yang diberikan
          oleh Nasabah kepada Perusahaan untuk melakukan hal-hal tersebut diatas
          tidak diberikan dalam Surat Kuasa yang terpisah dan Kuasa yang
          diberikan merupakan satu kesatuan dan bagian yang tidak terpisahkan
          dari Perjanjian ini dan karenanya tidak dapat dicabut dan tidak akan
          berakhir karena sebab- sebab yang tercantum dalam ketentuan Pasal
          1813, 1814, dan 1816 Kitab Undang-undang Hukum Perdata Indonesia
          dan/atau karena sebab-sebab apapun lainnya.
        </p>
        <br />
        <br />
        <br />
        <h1>
          <strong>Pasal 3</strong>
        </h1>
        <h1>
          <strong>Instruksi dan Transaksi</strong>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Untuk setiap transaksi Efek yang dilakukan atas rekening Nasabah,
              penyelesaian transaksi dan perintah yang diberikan oleh Nasabah
              kepada Perusahaan selalu terikat dan tunduk pada Perjanjian ini,
              peraturan perundangan yang berlaku berikut dengan perubahannya,
              kebiasaan, kelaziman yang berlaku di juridiksi hukum Indonesia,
              Pasar Modal, dan Bursa efek dimana transaksi tersebut dilakukan.
              Apabila Nasabah melanggar ketentuan yang di atur dalam pasal ini,
              maka Nasabah menyatakan bahwa Perusahaan tidak akan bertanggung
              jawab untuk menanggung kerugian yang diderita oleh Nasabah yang
              disebabkan oleh hal-hal yang telah disebutkan diatas, melainkan
              kerugian akan menjadi tanggung jawab Nasabah sepenuhnya termasuk
              pula kerugian yang disebabkan oleh karena perubahan kebijakan
              pemerintah Republik Indonesia dan/atau lembaga yang berwenang di
              Pasar Modal menurut ketentuan peraturan perundang- undangan yang
              berlaku.
            </p>
            <br />
          </li>
          <li data-list-text="2.">
            <p>
              Nasabah menyetujui bahwa Perusahaan sewaktu-waktu dapat meminta
              dana atau Efek dari Nasabah, sebelum melaksanakan setiap transaksi
              Efek apabila diperlukan.
            </p>
            <br />
          </li>
          <li data-list-text="3.">
            <p>
              Setiap perintah Nasabah dapat disampaikan kepada pegawai
              Perusahaan yang berwenang dan/atau melalui sistem Komputer di
              Perusahaan, secara lisan dan/atau tertulis dalam bentuk surat
              pesanan, melalui media elektronik dan/atau media komunikasi lain
              untuk perdagangan Efek termasuk tetapi tidak terbatas pada
              perdagangan efek jarak jauh (<i>remote trading</i>), perdagangan
              efek secara on-line (<i>on-line trading</i>), perdagangan Efek
              melalui internet (<i>internet trading</i>), perdagangan efek
              secara mobile (<i>mobile trading</i>), dan/atau melalui sistem
              elektronik yang telah ditentukan oleh Perusahaan tidak dibatasi
              oleh batas-batas wilayah dan/atau negara.
            </p>
            <br />
          </li>
          <li data-list-text="4.">
            <p>
              Jika perintah dan/atau pesanan nasabah disampaikan secara lisan,
              Perusahaan berhak meminta kepada Nasabah untuk mengirimkan
              perintah atau surat pesanan secara tertulis, baik atas transaksi
              yang belum maupun yang sudah dilaksanakan dan Nasabah wajib untuk
              memberikan surat pesanan tersebut.
            </p>
            <br />
          </li>
          <li data-list-text="5.">
            <p>
              Berkenaan dengan perintah dan/atau pesanan Nasabah yang
              disampaikan secara tertulis maupun lisan melalui media elektronik
              atau media komunikasi lain dan/atau sistem elektronik yang
              ditentukan oleh Perusahaan, Nasabah dengan ini menyatakan
              bertanggung jawab atas keabsahan dan kebenaran data, perintah dan
              atau pesanan Nasabah yang disampaikan dan ditujukan ke
            </p>
            <p>
              Perusahaan sebagaimana data yang diterima dalam sistem Komputer
              dan/atau direkam dalam sistem rekaman Perusahaan dan karenanya
              diakui oleh Nasabah tanpa diperlukan tanda tangan Nasabah dan
              merupakan satu- satunya bukti tertulis yang sah dan mengikat
              Nasabah dan Perusahaan.
            </p>
            <br />
          </li>
          <li data-list-text="6.">
            <p>
              Perusahaan berdasarkan kebijakan sendiri dapat menentukan suatu
              transaksi Efek yang diperintahkan oleh Nasabah untuk rekening
              Nasabah atau atas nama Nasabah melalui Bursa Efek atau perusahaan
              efek lain, dilaksanakan secara manual dan/atau melalui media
              elektronik dan/atau media komunikasi lain untuk perdagangan Efek
              termasuk tapi tidak terbatas pada perdagangan efek jarak jauh (
              <i>remote trading</i>), perdagangan efek secara on-line (
              <i>on-line trading</i>), perdagangan efek secara mobile (
              <i>mobile trading</i>) dan/atau sistem elektronik lain yang
              ditentukan oleh Perusahaan.
            </p>
            <br />
          </li>
          <li data-list-text="7.">
            <p>
              Perusahaan akan bertindak sesuai Instruksi dari pihak yang
              mewakili Nasabah yang telah diberi kuasa untuk itu, tanpa adanya
              kewajiban lanjutan dari Perusahaan untuk memeriksa kebenaran
              dan/atau keabsahan dari keterangan atau Instruksi tersebut. Dengan
              ditandatanganinya Perjanjian ini, Nasabah melepaskan Perusahaan
              dari kerugian yang timbul akibat adanya kesalahpahaman, kekeliruan
              atau Instruksi yang tidak sah dari pihak yang tidak berwenang, dan
              kerugian tersebut akan menjadi beban dan tanggung jawab Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="8.">
            <p>
              Nasabah memahami bahwa setiap permintaan pembatalan atau perubahan
              Instruksi dapat saja tidak berhasil, dan atas hal tersebut,
              Nasabah sepakat dan setuju bahwa Perusahaan tidak memiliki
              kewajiban akibat gagalnya pembatalan atau perubahan Instruksi
              Nasabah. Nasabah memahami bahwa apabila suatu Instruksi tidak
              dapat dibatalkan atau diubah maka Nasabah tetap berkewajiban untuk
              melaksanakan hak dan/atau kewajiban yang timbul dari pelaksanaan
              Instruksi tersebut. Nasabah juga memahami bahwa usaha untuk
              merubah atau membatalkan suatu Instruksi dapat berakibat pada
              berulangnya pemberian Instruksi yang mengakibatkan eksekusi ganda
              atas suatu Instruksi. Dalam hal terjadi eksekusi ganda atas suatu
              Instruksi dan sistem Perusahaan tidak dapat mencegah hal tersebut,
              maka Nasabah bersedia melaksanakan semua hak dan kewajiban yang
              timbul atas Transaksi tersebut.
            </p>
            <br />
          </li>
          <li data-list-text="9.">
            <p>
              Apabila Perusahaan tidak dapat atau gagal melaksanakan Instruksi
              dari Nasabah karena situasi atau kondisi pasar tidak memungkinkan
              untuk melaksanakan Instruksi Nasabah, atau karena terjadi force
              majeure sebagaimana diatur dalam Pasal 14 ayat (1) Perjanjian ini,
              maka Perusahaan tidak bertanggung jawab atas segala kerugian yang
              mungkin timbul dan Nasabah dengan ini membebaskan Perusahaan dari
              segala tuntutan hukum yang mungkin timbul sebagai akibat dari
              tidak dapat dilaksanakannya Instruksi Nasabah tersebut.
            </p>
            <br />
          </li>
          <li data-list-text="10.">
            <p>
              Perusahaan berhak menolak atau membatasi setiap Instruksi Nasabah,
              apabila:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  instruksi tersebut mengakibatkan terlampauinya limit transaksi
                  Nasabah yang telah ditetapkan Perusahaan;
                </p>
              </li>
              <li data-list-text="b.">
                <p>saldo Rekening Efek dan/atau saldo Rekening Dana negatif;</p>
              </li>
              <li data-list-text="c.">
                <p>
                  adanya kewajiban Nasabah yang sudah jatuh tempo dan belum
                  diselesaikan;
                </p>
              </li>
              <li data-list-text="d.">
                <p>
                  pada saat penyampaian Instruksi dalam kondisi tertentu seperti
                  terjadinya perubahan pasar yang sangat cepat dan volume
                  perdagangan yang cukup banyak, tidak ada atau tidak cukup
                  wewenang, tidak praktis untuk
                </p>
                <p>
                  dilaksanakan, bertentangan dengan Undang-undang atau peraturan
                  yang berlaku lainnya, dan menurut pertimbangan Perusahaan
                  transaksi Efek tersebut dapat menimbulkan risiko atau kerugian
                  bagi Perusahaan.
                </p>
                <br />
              </li>
            </ol>
          </li>
          <li data-list-text="11.">
            <p>
              Nasabah dengan ini menyetujui bahwa Efek dan dana dalam Rekening
              Efek dan Rekening Dana, dan seluruh kekayaan lain Nasabah yang
              berada dalam kekuasaan dan pengawasan Perusahaan maupun Efek dan
              dana yang akan diterima oleh Nasabah dari waktu ke waktu (“
              <b>Kekayaan Nasabah</b>”) akan digunakan sebagai jaminan
              penyelesaian kewajiban Nasabah yang telah jatuh tempo terhadap
              Perusahaan demi kepentingan Nasabah untuk pelunasan seluruh atau
              sebagian dari beban hutang dan kewajiban lain Nasabah kepada
              Perusahaan yang telah jatuh tempo. Perusahaan berhak sepenuhnya
              menentukan jenis Efek berikut nilainya yang dapat dijadikan
              jaminan. Perusahaan berhak sewaktu-waktu dan tanpa pemberitahuan
              terlebih dahulu kepada Nasabah, menahan, menjual, menggunakan atau
              melepaskan semua Kekayaan Nasabah selama masih terdapat kewajiban
              yang telah jatuh tempo, tanggung jawab atau hutang Nasabah kepada
              Perusahaan, baik dalam keadaan jatuh waktu, gagal bayar, masa
              penagihan atau menjelang pelunasan. Mengenai seluruh hal dalam
              ayat ini, Perusahaan tidak berkewajiban mempertanggungjawabkan
              mengenai harga jual yang diperoleh atau proses pelepasan tersebut
              di atas.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 4</b>
        </h1>
        <h1>
          <b>Penyerahan dan Penarikan Efek dan/atau Dana</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Sebelum melakukan transaksi jual, Nasabah wajib terlebih dahulu
              memiliki Efek yang akan dijual melalui Perusahaan baik dalam
              Rekening Nasabah ataupun pada rekening yang berada pada Kustodian
              Nasabah. Penjualan yang akan mengakibatkan posisi short hanya
              dapat dilakukan apabila telah mendapat persetujuan terlebih dahulu
              secara tertulis dari Perusahaan.
            </p>
          </li>
          <br />
          <li data-list-text="2.">
            <p>
              Nasabah tidak berhak melakukan transaksi short selling tanpa
              persetujuan tertulis dari Perusahaan. Nasabah yang tidak dapat
              melakukan penyerahan Efek maka dianggap sebagai short selling dan
              Perusahaan akan mengenakan denda Short Selling. Dalam hal ini,
              Nasabah juga memberikan kuasa kepada Perusahaan untuk membeli
              kembali Efek tersebut demi kepentingan Perusahaan.
            </p>
          </li>
          <br />
          <li data-list-text="3.">
            <p>
              Pemindahbukuan dana dan/atau Efek tidak dapat dilaksanakan apabila
              terdapat kewajiban yang belum diselesaikan atau setelah dilakukan
              pemindahbukuan menyebabkan terjadinya saldo negatif pada Rekening
              Efek Nasabah atau Rekening Dana dan/atau terjadi perbedaan data
              antara Instruksi Nasabah dengan data yang telah didaftarkan kepada
              Perusahaan.
            </p>
          </li>
          <br />
          <li data-list-text="4.">
            <p>
              Nasabah menyetujui bahwa Efek dapat diambil atau ditransfer jika
              memenuhi kriteria sebagai berikut:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  Efek merupakan Efek yang telah dilunasi dan tidak dijaminkan;
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  bukan merupakan jaminan dari kewajiban lain Nasabah kepada
                  Perusahaan;
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  dilengkapi dengan surat Instruksi penarikan atau kuasa
                  transfer Efek lengkap dengan dokumen pendukung yang
                  disampaikan sesuai prosedur berlaku;
                </p>
              </li>
              <li data-list-text="d.">
                <p>
                  setiap pemindahbukuan Efek ke rekening Nasabah bukan milik
                  Nasabah harus melalui mekanisme crossing di Bursa dan biaya
                  yang timbul akibat transaksi crossing ini menjadi beban
                  Nasabah.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li data-list-text="5.">
            <p>
              Dana dapat diambil atau ditransfer apabila memenuhi kriteria
              sebagai berikut:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  dana sudah jatuh tempo dan in good fund pada Rekening Dana;
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  tidak menjadi jaminan kewajiban lain Nasabah terhadap
                  Perusahaan;
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  pemindahbukuan dana hanya dapat dilaksanakan pada rekening
                  bank sebagaimana tercantum dalam Formulir dan catatan
                  Perusahaan, kecuali apabila Nasabah telah mengajukan
                  permohonan kepada Perusahaan selambat- lambatnya 3 (tiga) hari
                  bursa sebelum Instruksi pengambilan dilaksanakan;
                </p>
              </li>
              <li data-list-text="d.">
                <p>
                  pemindahbukuan dana sebagaimana tercantum dalam huruf c dapat
                  langsung dilakukan setelah dana tersebut diterima Rekening
                  Dana Nasabah apabila nasabah memilih kirim hasil pada
                  Formulir;
                </p>
              </li>
              <li data-list-text="e.">
                <p>
                  apabila Nasabah memilih dalam Formulir untuk dilakukan simpan
                  hasil pada Rekening Dana Nasabah, maka Nasabah wajib
                  melengkapi dengan surat Instruksi penarikan atau kuasa
                  transfer dana lengkap dengan dokumen pendukung yang
                  disampaikan sesuai prosedur yang berlaku.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 5</b>
        </h1>
        <h1>
          <b>Pembayaran Transaksi</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Perusahaan akan menempatkan dana yang dimiliki Nasabah ke dalam
              Rekening Dana Nasabah dan mempergunakan Rekening Dana Nasabah
              sebagai rekening untuk penerimaan dana dan pembayaran hasil
              transaksi Efek.
            </p>
            <br />
          </li>
          <li data-list-text="2.">
            <p>
              Atas pembukaan Rekening Nasabah dan setiap Transaksi yang
              dilakukan, selain harga pembelian Efek, Nasabah wajib membayar
              biaya-biaya sebagai berikut:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  komisi transaksi dalam jumlah presentase tertentu dari nilai
                  Transaksi atau komisi minimum dalam jumlah yang ditentukan
                  oleh Perusahaan, tergantung mana yang lebih besar;
                </p>
              </li>
              <li data-list-text="b.">
                <p>pajak;</p>
              </li>
              <li data-list-text="c.">
                <p>bunga atau denda (bila ada);</p>
              </li>
              <li data-list-text="d.">
                <p>
                  biaya-biaya lainnya berupa biaya administrasi, korespondensi,
                  meterai, layanan jasa dan/atau biaya penitipan yang dikenakan
                  sehubungan dengan transaksi Efek yang dilakukan atas nama
                  rekening Nasabah, pembukaan, pemeliharaan dan pengoperasian
                  sub rekening Efek.
                </p>
                <br />
              </li>
            </ol>
          </li>
          <li data-list-text="3.">
            <p>
              Besar biaya-biaya tersebut dalam ayat (2) Pasal ini akan
              ditentukan oleh Perusahaan dan diberitahukan kepada Nasabah serta
              dapat diubah setiap saat oleh Perusahaan dengan pemberitahuan
              tertulis kepada Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="4.">
            <p>
              Perusahaan berhak membebankan biaya-biaya tersebut dalam ayat (2)
              Pasal ini pada Rekening Dana Nasabah atau Jaminan yang diserahkan
              oleh Nasabah dan/atau pada pembayaran yang akan diterima oleh
              Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="5.">
            <p>
              Dalam memperhitungkan hak dan kewajiban Nasabah yang melakukan
              Transaksi Efek Nasabah untuk hari yang sama, Perusahaan akan
              melakukan penjumpaan (offsetting) transaksi beli dan jual Nasabah
              baik untuk Efek-Efek yang sama maupun berbeda dalam rangka
              memperhitungkan hak dan kewajiban penyelesaian uang atau Efek
              Nasabah terhadap Perusahaan;
            </p>
            <br />
          </li>
          <li data-list-text="6.">
            <p>
              Pembayaran dari Nasabah untuk transaksi pembelian Efek harus sudah
              efektif dalam Rekening Dana Nasabah selambat-lambatnya pukul 15:00
              WIB pada Tanggal Jatuh Tempo atau waktu lainnya yang ditentukan
              oleh Perusahaan. Apabila sampai dengan waktu tersebut, Perusahaan
              belum menerima pembayaran atas transaksi
            </p>
            <p>
              Nasabah, Perusahaan berhak membebankan biaya transaksi Nasabah
              pada Jaminan/rekening Nasabah tanpa perlu persetujuan terlebih
              dahulu dari Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="7.">
            <p>
              Apabila sampai dengan batas waktu yang telah ditetapkan
              sebagaimana dalam ayat (6) Pasal ini, pembayaran dari Nasabah
              belum diterima oleh Perusahaan atau terdapat saldo negatif pada
              Rekening Nasabah, maka Perusahaan:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  pada 1 (satu) hari setelah Tanggal Jatuh Tempo, Perusahaan
                  akan menginformasikan kepada Nasabah mengenai posisi saldo
                  dana negatif pada Rekening Nasabah dan meminta Nasabah untuk
                  menutupi saldo negatif tersebut. Perusahaan berhak melakukan
                  suspensi atau larangan pembelian Efek kepada Nasabah;
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  berhak menggunakan Efek dalam Sub Rekening Efek Nasabah
                  sebagai berikut:
                </p>
                <ol>
                  <li data-list-text="1)">
                    <p>
                      menggunakan Efek milik Nasabah sebagai jaminan atas kredit
                      bank atau lembaga jasa keuangan lainnya sejak setelah
                      lewatnya waktu jatuh tempo;
                    </p>
                  </li>
                  <li data-list-text="2)">
                    <p>
                      melakukan Forced Sell sebesar nilai yang diperlukan atas
                      kewajiban Nasabah tanpa adanya pemberitahuan kepada
                      Nasabah yang dapat dilakukan setelah lewatnya 2 (dua) hari
                      sejak Tanggal Jatuh Tempo paling lambat sebelum sesi I
                      (satu) berakhir pada tanggal tersebut.
                    </p>
                    <br />
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li data-list-text="8.">
            <p>
              Untuk penjualan sebagian atau seluruh Efek dalam Rekening Efek
              Nasabah (Forced Sell) dilakukan dengan ketentuan sebagai berikut:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  Nilai sahamnya yang paling besar pertama untuk menutupi saldo
                  penyelesaian Transaksi atau saldo negatif Nasabah dan
                  mempunyai bid untuk dijual; dan/atau
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  Nilai sahamnya yang paling besar kedua untuk menutupi saldo
                  penyelesaian Transaksi atau saldo negatif Nasabah dan
                  mempunyai bid untuk dijual; atau
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  Berdasarkan pertimbangan Perusahaan dan situasi keadaan pasar
                  pada saat dilakukan forced sell.
                </p>
                <br />
              </li>
            </ol>
          </li>
          <li data-list-text="9.">
            <p>
              Dalam hal terjadi keadaan dalam ayat (7) dan (8) Pasal ini,
              Perusahaan tidak bertanggung jawab untuk melakukan pembayaran atau
              pengiriman berkaitan dengan segala transaksi yang telah jatuh
              tempo dan berhak untuk tidak menerima instruksi dari Nasabah
              kecuali instruksi tersebut digunakan untuk memenuhi kewajiban
              Nasabah kepada Perusahaan.
            </p>
            <br />
          </li>
          <li data-list-text="10.">
            <p>
              Apabila hasil penjualan Efek dan hasil dari Jaminan Efek
              sebagaimana disebutkan dalam ayat (7) dan (8) Pasal ini tidak
              mencukupi untuk pelunasan kewajiban Nasabah, maka Perusahaan akan
              mengirimkan surat penagihan hutang atas status kewajiban Nasabah
              sekaligus meminta Nasabah untuk melunasi kewajiban yang masih
              tersisa.
            </p>
            <br />
          </li>
          <li data-list-text="11.">
            <p>
              Nasabah mengerti dan menyetujui segala tindakan Perusahaan
              sebagaimana disebutkan dalam ayat (7) dan
            </p>
            <p>
              (8) Pasal ini tersebut di atas dan segala beban biaya dan/atau
              bunga yang berkaitan dengan Forced Sell dan/atau pembiayaan bank
              dan/atau lembaga keuangan tersebut akan menjadi tanggung jawab
              Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="12.">
            <p>
              Atas kelalaian yang dilakukan Nasabah untuk melakukan pembayaran
              terhadap Perusahaan, Nasabah menyetujui tidak akan memintakan
              pertanggungjawaban dalam bentuk apapun juga sehubungan dengan
              segala tindakan yang dilakukan atas Efek dan dana milik Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="13.">
            <p>
              Apabila Nasabah tidak dapat melunasi kewajiban yang tersisa atau
              Nasabah melakukan keterlambatan
            </p>
          </li>
        </ol>
        <p>
          pembayaran lebih dari 3 (tiga) kali berturut-turut dalam 3 (tiga)
          Transaksi dalam waktu 1 (satu) bulan, maka Perusahaan berhak
          mengakhiri Perjanjian ini dan menutup Rekening Efek Nasabah di
          Perusahaan. Pengakhiran Perjanjian dan penutupan Rekening Efek
          tersebut tidak menghilangkan kewajiban Nasabah yang masih tersisa
          kepada Perusahaan.
        </p>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 6 Konfirmasi</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Perusahaan akan mengirimkan kepada Nasabah Laporan Rekening
              Nasabah yang terdiri dari:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  Laporan Konfirmasi Transaksi (Trade Confirmation) pada Hari
                  Bursa yang sama dengan pelaksanaan transaksi; dan
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  Laporan Posisi Saldo Efek(Client Portfolio) dan Laporan
                  Rekening Efek (Client Statement) setiap bulannya,
                  selambat-lambatnya hari kesepuluh pada bulan berikutnya.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li data-list-text="2.">
            <p>
              Apabila Nasabah keberatan atau tidak menyetujui isi Laporan
              Rekening Nasabah tersebut maka Nasabah harus memberitahukannya
              kepada Perusahaan secara tertulis selambat-lambatnya pukul 10.00
              WIB pada 1 (satu) Hari Bursa setelah dikirimnya Laporan Rekening
              Nasabah tersebut oleh Perusahaan. Jika dalam waktu tersebut tidak
              terdapat sanggahan dan/atau keberatan dari Nasabah, maka Nasabah
              dianggap telah menyetujui isi Laporan Rekening Nasabah tersebut.
              Apabila Perusahaan melakukan koreksi atas Laporan Rekening Nasabah
              maka Perusahaan akan membuat dan memberitahukan suatu konfirmasi
              transaksi atau pemberitahuan terbaru kepada Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="3.">
            <p>
              Nasabah berhak sewaktu-waktu meminta laporan dan/atau menguji
              kesesuaian antara Saldo Rekening Efek Nasabah dalam konfirmasi
              transaksi dimaksud/pembukuan Perusahaan dengan saldo Efek Nasabah
              dalam Sub Rekening Efek Nasabah. Segala biaya yang timbul terkait
              permintaan laporan dan/atau uji kesesuaian saldo dimaksud, yang
              dibebankan oleh Perusahaan maupun KSEI atau KPEI, sepenuhnya
              menjadi beban dan tanggung jawab Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="4.">
            <p>
              Nasabah dengan ini menyadari sepenuhnya bahwa setiap dokumen yang
              dikirimkan atau dicetak secara elektronik tidak memerlukan tanda
              tangan pejabat yang berwenang di Perusahaan.
            </p>
            <br />
          </li>
          <li data-list-text="5.">
            <p>
              Setiap korespondensi yang dilakukan oleh Perusahaan dan Nasabah
              akan dilakukan sesuai dengan ketentuan Pemberitahuan sebagaimana
              diatur dalam Pasal 13 Perjanjian ini.
            </p>
          </li>
          <br />
          <li data-list-text="6.">
            <p>
              Perusahaan berhak setiap saat untuk mengungkapkan dan/atau
              memberikan identitas, data transaksi dan aktivitas transaksi
              Nasabah kepada pihak yang berwenang berdasarkan hukum atau badan
              pemerintah yang berwenang tanpa harus memberitahukan dan meminta
              persetujuan terlebih dahulu kepada Nasabah.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 7</b>
        </h1>
        <h1>
          <b>Transaksi Margin dan/atau Short Selling</b>
        </h1>
        <br />
        <p>
          Nasabah tidak berhak melakukan transaksi Marjin atau transaksi Short
          Selling tanpa persetujuan tertulis dari Perusahaan. Dalam hal Nasabah
          tidak memenuhi permintaan pemenuhan jaminan sebagaimana diatur dalam
          Perjanjian Pembiayaan Transaksi Efek Nasabah, Perusahaan berhak untuk
          setiap saat tanpa memberikan alasan atau pemberitahuan atau memperoleh
          persetujuan terlebih dahulu dari Nasabah, menjual atau membeli Efek
          atau mengambil
        </p>
        <p>
          tindakan lain yang disepakati dengan Nasabah dalam Perjanjian
          Pembiayaan Transaksi Efek Nasabah.
        </p>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 8</b>
        </h1>
        <h1>
          <b>Pembekuan dan Pengaktifan Kembali Rekening Nasabah</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Dalam hal Rekening Nasabah tidak aktif (<i>dormant account)</i>,
              maka Nasabah setuju dan memberikan kewenangan penuh kepada
              Perusahaan untuk:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  menutup Rekening Efek Nasabah tersebut tanpa pemberitahuan
                  terlebih dahulu kepada Nasabah. Dengan penutupan Rekening Efek
                  tersebut, Nasabah tetap wajib memenuhi semua kewajibannya
                  kepada Perusahaan (jika ada);
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  dikenakan biaya pemeliharaan sesuai dengan biaya yang
                  ditagihkan oleh KSEI.
                </p>
                <br />
              </li>
            </ol>
          </li>
          <li data-list-text="2.">
            <p>
              Nasabah setuju bahwa Perusahaan mempunyai hak sepenuhnya untuk
              memblokir, membatasi perdagangan, pembayaran, pemindahan, atau
              tidak melakukan apapun pada Rekening Nasabah sampai dengan
              penutupan Rekening Nasabah disebabkan namun tidak terbatas
              terhadap hal-hal berikut ini:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  transaksi yang dilakukan Nasabah diindikasikan termasuk dalam
                  kategori tindak pidana pasar modal;
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  transaksi yang dilakukan Nasabah diindikasikan termasuk dalam
                  kategori tindak pidana pencucian uang;
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  sumber dana yang digunakan untuk transaksi berasal dari
                  perbuatan melanggar hukum atau tindak pidana korupsi;
                </p>
              </li>
              <li data-list-text="d.">
                <p>
                  calon Nasabah atau Nasabah terdapat dalam daftar terduga
                  teroris dan organisasi teroris;
                </p>
              </li>
              <li data-list-text="e.">
                <p>
                  Nasabah berbentuk <i>shell bank </i>atau bank umum atau bank
                  umum syariah yang mengizinkan rekeningnya digunakan oleh
                  <i>sheel bank</i>;
                </p>
              </li>
              <li data-list-text="f.">
                <p>
                  hasil transaksi yang diperoleh Nasabah digunakan untuk
                  membiayai tindak pidana terorisme;
                </p>
              </li>
              <li data-list-text="g.">
                <p>
                  pernyataan dan jaminan Nasabah sebagaimana tercantum dalam
                  Pasal 10 ayat (2) ternyata tidak benar;
                </p>
              </li>
              <li data-list-text="h.">
                <p>
                  tidak dapat memenuhi dokumen dan informasi yang dipersyaratkan
                  oleh Perusahaan yang digunakan untuk mengidentifikasi dan
                  memverifikasi profil Nasabah;
                </p>
              </li>
              <li data-list-text="i.">
                <p>
                  informasi yang diberikan diragukan kebenarannya atau ternyata
                  tidak benar;
                </p>
              </li>
              <li data-list-text="j.">
                <p>
                  diketahui dan/atau patut diduga menggunakan dokumen palsu
                  dalam membuka Rekening Nasabah dan melakukan hubungan hukum
                  selanjutnya dengan Perusahaan;
                </p>
              </li>
              <li data-list-text="k.">
                <p>
                  terdapat perintah atau keputusan dari pejabat atau instansi
                  yang berwenang atas pemblokiran Rekening Nasabah;
                </p>
              </li>
              <li data-list-text="l.">
                <p>
                  dalam hal Nasabah tidak aktif melakukan transaksi di
                  Perusahaan dalam jangka waktu 6 (enam) bulan berturut-turut
                  dan tidak terdapat saldo Efek maupun dana pada Rekening
                  Nasabah;
                </p>
              </li>
              <li data-list-text="m.">
                <p>
                  perilaku dari Nasabah yang diindikasikan dapat merugikan
                  kepentingan Perusahaan.
                </p>
                <br />
              </li>
            </ol>
          </li>
          <li data-list-text="3.">
            <p>
              Rekening Nasabah yang telah diblokir atau dibekukan dapat
              diaktifkan kembali, apabila Nasabah mengajukan permohonan kepada
              Perusahaan sesuai prosedur dan disertai dengan pemenuhan
              persyaratan yang ditetapkan Perusahaan dan/atau terdapat perintah
              atau keputusan dari pejabat/instansi yang berwenang untuk mencabut
              pemblokiran dan/atau pembekuan atas Rekening Nasabah tersebut.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 9</b>
        </h1>
        <h1>
          <b>
            Penerima Manfaat (<i>Beneficiary</i>) PJK
          </b>
        </h1>
        <br />
        <p>
          Khususnya untuk Nasabah yang membeli atau menjual Efek untuk dan atas
          nama PJK di sektor Perbankan, PJK di sektor Pasar Modal, PJK di Sektor
          Industri Keuangan Non Bank, Nasabah menjamin ketersediaan dari dana
          dan/atau Efek untuk tujuan penyelesaian transaksi Efek sebelum Nasabah
          memberikan perintah untuk membeli atau menjual setiap Efek Nasabah.
          Jika Nasabah tidak dapat menyediakan dana dan/atau Efek, maka Nasabah
          setuju untuk menanggung dan mengganti segala kerugian yang muncul dari
          kesalahan dalam menyelesaikan transaksi pada hari penyelesaian.
        </p>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 10</b>
        </h1>
        <h1>
          <b>Pernyataan dan Jaminan</b>
        </h1>
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>Perusahaan menjamin dan menyatakan bahwa:</p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  Dana dan/atau Efek Nasabah dalam Rekening Nasabah hanya akan
                  digunakan untuk kepentingan Nasabah semata-mata dalam rangka
                  pelaksanaan Transaksi Nasabah yang pelaksanaannya dilakukan
                  oleh Perusahaan sesuai dengan instruksi dari Nasabah yang
                  bersangkutan;
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  Perusahaan akan berusaha dengan sebaik-baiknya untuk
                  mengadministrasikan dengan tata kelola secara benar dan baik
                  Rekening Nasabah;
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  Efek atau dana yang dicatatkan pada Rekening Efek Nasabah akan
                  disimpan pada masing-masing Sub Rekening Efek dan Rekening
                  Dana milik masing-masing Nasabah.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li data-list-text="2.">
            <p>Nasabah menjamin dan menyatakan bahwa:</p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  semua data dan informasi yang disampaikan dalam Formulir dan
                  perubahan (-perubahan)nya adalah lengkap, tepat dan akurat;
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  telah membaca dan memahami sepenuhnya isi dari Perjanjian ini
                  dan setuju untuk tunduk dan terikat pada ketentuan-ketentuan
                  dan syarat -syarat Perjanjian ini;
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  telah cukup umur dan cakap serta memiliki kewenangan penuh
                  menurut hukum dan peraturan perundang- undangan yang berlaku
                  untuk melaksanakan segala kewajiban dan tanggung jawabnya dan
                  melakukan perbuatan hukum sebagaimana dimaksud dalam
                  Perjanjian ini;
                </p>
              </li>
              <li data-list-text="d.">
                <p>
                  tidak ada pihak lainnya yang berhak atau mempunyai kepentingan
                  atas rekening (-rekening) yang dibuka, dipelihara dan
                  dioperasikan oleh Perusahaan berdasarkan perjanjian ini;
                </p>
              </li>
              <li data-list-text="e.">
                <p>
                  telah mendapat persetujuan tertulis dari pihak-pihak yang
                  berwenang berdasarkan peraturan perundang- undangan yang
                  berlaku, atau perjanjian, Nasabah dengan pihak ketiga lainnya
                  (bilamana diperlukan). Jika diminta atau diperlukan oleh
                  Perusahaan, persetujuan tersebut akan diberikan kepada
                  Perusahaan;
                </p>
              </li>
              <li data-list-text="f.">
                <p>
                  Apabila Nasabah menunjuk pihak ketiga sebagai pihak yang
                  dikuasakan untuk mewakili Nasabah, maka atas pengangkatan
                  dan/atau pemberhentian kuasa tersebut harus diberitahukan
                  kepada Perusahaan secara tertulis. Nasabah dengan ini
                  membebaskan Perusahaan dari segala risiko dan tanggung jawab
                  atas Transaksi Efek yang dilakukan oleh Perusahaan sebelum
                  pemberitahuan tentang perubahan atau pemberhentian kuasanya
                  diterima oleh Perusahaan;
                </p>
              </li>
              <li data-list-text="g.">
                <p>
                  Nasabah bukan seorang yang pernah dinyatakan pailit oleh
                  Pengadilan Negeri atau seorang yang pernah terlibat dalam
                  bidang kejahatan di bidang keuangan, terorisme, atau orang
                  yang sedang menjalani hukuman atas tindak pidana tersebut baik
                  dalam wilayah hukum
                </p>
                <p>Negara Republik Indonesia maupuh wilayah hukum lainnya;</p>
              </li>
              <li data-list-text="h.">
                <p>
                  Nasabah tidak akan menggunakan pelayanan perdagangan Efek yang
                  diberikan oleh Perusahaan sebagai sarana untuk melakukan
                  tindakan yang dapat dikategorikan melanggar hukum, termasuk
                  namun tidak terbatas dengan tujuan tindak pidana pencucian
                  uang (money laundering) dan pembiayaan tindak pidana
                  terorisme;
                </p>
              </li>
              <li data-list-text="i.">
                <p>
                  seluruh dana dan Efek yang digunakan untuk menyelesaikan
                  transaksi Efek tidak berasal dari dana dan untuk tujuan
                  perbuatan melanggar hukum termasuk tindak pidana pencucian
                  uang (money laundering) dan terorisme;
                </p>
              </li>
              <li data-list-text="j.">
                <p>
                  Nasabah tidak akan melakukan transaksi Efek dengan maksud
                  untuk mengelabui, mengaburkan, atau menghindari pelaporan
                  kepada Pusat Pelaporan Analisis Transaksi Keuangan (PPATK)
                  berdasarkan ketentuan yang berlaku;
                </p>
              </li>
              <li data-list-text="k.">
                <p>
                  Perusahaan berwenang penuh untuk menyampaikan informasi
                  mengenai Nasabah beserta aktifitas Rekening Efek Nasabah
                  kepada karyawan Perusahaan untuk keperluan operasional
                  Perusahaan, pihak yang berwenang, kepentingan Pemeriksaan
                  dan/atau penyidikan oleh BEI, KPEI, KSEI, Otoritas Pasar
                  Modal, PPATK, dinas perpajakan atau oleh instansi penegak
                  hukum atau instansi lainnya baik di dalam wilayah Indonesia
                  maupun di luar Indonesia yang mempunyai kewenangan atas dasar
                  peraturan perundang-undangan yang berlaku, tanpa perlu
                  menyampaikan pemberitahuan kepada atau meminta persetujuan
                  terlebih dahulu dari Nasabah;
                </p>
              </li>
              <li data-list-text="l.">
                <p>
                  dalam hal instruksi disampaikan secara lisan melalui telepon
                  dan atau faksimili atau alat komunikasi lainnya, maka
                  Perusahaan akan bertindak sesuai dengan instruksi dari pihak
                  yang menyatakan dirinya sebagai Nasabah atau yang telah diberi
                  kuasa untuk itu, akan tetapi tanpa kewajiban bagi Perusahaan
                  untuk meminta kebenaran dan keabsahan keterangan terhadap
                  instruksi tersebut. Risiko adanya kesalahpahaman atau
                  kekeliruan atas instruksi yang diberikan oleh pihak yang tidak
                  berwenang dan/atau tidak bertanggung jawab, akan menjadi
                  risiko dan tanggung jawab sepenuhnya dari Nasabah, termasuk
                  namun tidak terbatas pada risiko dan kerugian maupun tuntutan
                  dan atau gugatan yang mungkin timbul dari pihak manapun juga
                  termasuk Nasabah sendiri atau afiliasinya;
                </p>
              </li>
              <li data-list-text="m.">
                <p>
                  Nasabah menyadari bahwa investasi di pasar modal memiliki
                  risiko finansial. Harga Efek dapat mengalami kenaikan yang
                  akan menguntungkan Nasabah dan dapat pula mengalami penurunan
                  yang mengakibatkan kerugian bagi Nasabah. Selain kenaikan dan
                  penurunan harga Efek yang dimiliki oleh Nasabah, Efek juga
                  dapat kehilangan nilainya dan menjadi tidak berharga. Setiap
                  Efek memiliki tingkat likuiditas yang berbeda yang dapat
                  mengakibatkan perbedaan antara harga penawaran jual dan
                  penawaran beli di pasar. Sebagai akibat dari hal-hal tersebut,
                  Nasabah memahami bahwa setiap saat Nasabah dapat mengalami
                  kerugian dan kehilangan sebagian atau seluruh dana yang
                  diinvestasikan oleh Nasabah di pasar modal dan oleh karenanya
                  Nasabah melepaskan hak menggugat dan/atau menuntut pihak
                  manapun termasuk Perusahaan sebagai akibat kerugian tersebut.
                </p>
              </li>
              <li data-list-text="n.">
                <p>
                  Nasabah dengan ini menyatakan telah mengerti dan memahami
                  sepenuhnya tentang risiko dari transaksi Efek dan bersedia
                  menanggung seluruh risiko atau akibat lain yang mungkin
                  terjadi. Oleh karena itu, setiap Instruksi yang disampaikan
                  kepada Perusahaan adalah semata-mata berdasarkan pengetahuan
                  dan
                </p>
                <p>
                  analisa Nasabah tanpa adanya pengaruh dari Perusahaan, Direksi
                  dan/atau karyawan Perusahaan, termasuk terhadap hasil riset
                  yang dilakukan Perusahaan. Nasabah tidak berhak mengalihkan
                  tanggung jawab atas risiko kerugian tersebut kepada
                  Perusahaan;
                </p>
              </li>
              <li data-list-text="o.">
                <p>
                  Nasabah mengetahui dan menyadari bahwa rekomendasi transaksi
                  atau informasi mengenai kondisi pasar yang diberikan kepada
                  Nasabah oleh Perusahaan tidak dapat diartikan sebagai nasihat
                  untuk digunakan sebagai pedoman dalam pengambilan keputusan
                  investasi Nasabah dan tidak dapat diartikan sebagai penawaran
                  untuk membeli atau penawaran untuk menjual Efek pada harga
                  tertentu. Semua keputusan untuk membeli atau menjual Efek
                  merupakan keputusan investasi dari Nasabah;
                </p>
              </li>
              <li data-list-text="p.">
                <p>
                  Nasabah menyepakati bahwa Hasil Rekaman dapat dipergunakan
                  sebagai pembuktian apabila terjadi perselisihan antara
                  Perusahaan dan Nasabah;
                </p>
              </li>
              <li data-list-text="q.">
                <p>
                  Nasabah bersedia untuk membayar biaya sesuai layanan jasa yang
                  diberikan oleh Perusahaan.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 11</b>
        </h1>
        <h1>
          <b>Nasabah Meninggal Dunia/Pailit/Dibubarkan</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Dalam hal Nasabah meninggal dunia atau dinyatakan pailit atau
              dibubarkan atau diletakkan di bawah pengawasan pihak yang ditunjuk
              oleh instansi yang berwenang, maka Perusahaan sewaktu-waktu berhak
              untuk memblokir Rekening Nasabah dan hanya akan mengalihkan hak
              atas Rekening Nasabah kepada ahli waris atau pengganti haknya atau
              pihak yang secara hukum berhak. Perusahaan berhak mensyaratkan
              dokumen yang dapat diterima oleh Perusahaan yang membuktikan
              kedudukannya sebagai ahli waris atau pengganti hak atau pihak yang
              ditunjuk tersebut.
            </p>
          </li>
          <br />
          <li data-list-text="2.">
            <p>
              Dengan penyerahan kekayaan Rekening Nasabah kepada (para) ahli
              waris atau pelaksana wasiat atau pengganti haknya atau pihak yang
              secara hukum berhak, maka semua hak dan kewajiban akan beralih
              kepada ahli waris dan Perusahaan akan menutup Rekening atas nama
              Nasabah serta Perusahaan dibebaskan sepenuhnya dari semua tanggung
              jawab sehubungan dengan penyerahan kekayaaan Rekening Nasabah
              tersebut.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 12</b>
        </h1>
        <h1>
          <b>Penyimpanan Contoh Tanda Tangan</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Nasabah wajib menyerahkan pada Perusahaan contoh tanda tangan dari
              pihak(-pihak) yang berhak bertindak atas nama Nasabah sebagaimana
              tercantum dalam Formulir, berikut dengan perincian yang lengkap
              atas hak dan kewenangan dari pihak (-pihak) tersebut yang
              digunakan dalam transaksi.
            </p>
            <br />
          </li>
          <li data-list-text="2.">
            <p>
              Dalam hal ditunjuk seorang kuasa atau lebih untuk melakukan
              transaksi Rekening Nasabah, nama dan contoh tanda tangan dari yang
              diberi kuasa harus juga disimpan pada Perusahaan sesuai dengan
              cara sebagaimana tersebut diatas.
            </p>
          </li>
          <br />
          <li data-list-text="3.">
            <p>
              Perusahaan akan menolak setiap media instruksi untuk penarikan
              dana atau Instruksi lainnya yang tanda tangannya tidak sesuai
              dengan contoh tanda tangan dan kewenangan yang ada pada
              Perusahaan. Nasabah melepaskan Perusahaan dari segala tanggung
              jawab maupun tuntutan hukum yang timbul dari pihak manapun
              termasuk dari Nasabah sendiri atas penolakan tersebut.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 13 Pemberitahuan</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Setiap pemberitahuan yang diperlukan dalam Perjanjian ini selain
              daripada penyampaian Instruksi Transaksi Nasabah sebagaimana
              termaktub dalam Pasal 3 Perjanjian ini harus dalam bentuk tertulis
              dan dapat disampaikan secara langsung, atau melalui pos terdaftar,
              atau email, atau faksimili yang ditujukan secara langsung kepada
              Nasabah dengan alamat seperti yang tertulis pada Formulir atau
              alamat dan nomor lain disampaikan secara tertulis dari waktu ke
              waktu. Setiap pemberitahuan dianggap telah diterima apabila:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>diberikan secara langsung, pada waktu disampaikan,</p>
              </li>
              <li data-list-text="b.">
                <p>
                  jika dikirimkan melalui pos terdaftar, 5 (lima) Hari Bursa
                  setelah dikirimkan,
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  jika dikirim melalui email atau faksimili, pada waktu waktu
                  disampaikan.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li data-list-text="2.">
            <p>
              Apabila pemberitahuan yang diberikan oleh Perusahaan ternyata
              tidak diterima oleh Nasabah baik karena adanya perubahan alamat
              maupun akibat dari kesalahan Nasabah dalam pengisian Formulir,
              maka Nasabah menyetujui bahwa pemberitahuan tersebut telah
              diterima sesuai dengan ketentuan ayat (1) Pasal ini.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 14</b>
        </h1>
        <h1>
          <b>Keadaan Memaksa (Force Majeure)</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Yang dimaksud dengan keadaan memaksa (force majeure) adalah
              kejadia-kejadian sebagai berikut :Perusahaan wajib mematuhi
              seluruh kewajiban dan ketentuan yang muncul dari Perjanjian ini,
              namun demikian Nasabah sepakat untuk membebaskan Perusahaan dari
              segala tanggung jawab dan kewajiban dalam hal terjadi salah satu
              dari kejadian di bawah ini:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  terjadinya peristiwa dan/atau keadaan yang di luar kehendak
                  dan kemampuan BEI dan/atau KPEI dan/atau KSEI yang
                  mengakibatkan JATS dan/atau sistem pengendalian risiko KPEI
                  dan/atau KSEI tidak dapat berfungsi sebagaimana mestinya
                  dan/atau terhentinya perdagangan di BEI dan/atau kegagalan
                  dan/atau tidak dapat berfungsinya dan/atau network,
                  terhentinya sistem otoritas perbankan, kegagalan teknis (baik
                  perangkat keras dan/atau perangkat lunak Bursa Efek dan atau
                  sistem pengendalian risiko KPEI dan/atau KSEI) dan keadaan
                  atau peristiwa lain yang sejenis dengan itu, gangguan dalam
                  jaringan komunikasi, gangguan dalam sistem elektronik,
                  demikian pula kejadian-kejadian diluar kuasa manusia seperti
                  kebakaran, bencana alam, banjir, letusan gunung berapi, gempa
                  bumi, angin topan, wabah penyakit, tindakan pemerintah dalam
                  bidang moneter, tindakan pengambilalihan dan atau perampasan
                  oleh Negara, pemogokan buruh, kerusuhan, pemberontakan, perang
                  baik yang diumumkan maupun tidak diumumkan, atau sebab-sebab
                  lain di luar kekuasaan Perusahaan;
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  terjadinya kebangkrutan, ketidakmampuan membayar, atau
                  dilikuidasinya Bank Pembayaran, KPEI, KSEI, Bursa Efek ;
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  terjadinya suspension atau penghentian transaksi atau
                  pengiriman Efek oleh Bursa Efek atau KSEI.
                </p>
                <br />
              </li>
            </ol>
          </li>
          <br />
          <li data-list-text="2.">
            <p>
              Masing-masing pihak tidak akan bertanggung jawab kepada pihak
              lainnya dalam hal tidak terlaksananya atau terjadinya
              keterlambatan atas pelaksanaan kewajiban dari masing-masing pihak
              sebagaimana ditentukan dalam Perjanjian ini yang merupakan akibat
              langsung dari <i>Force Majeure</i>.
            </p>
          </li>
          <br />
          <li data-list-text="3.">
            <p>
              Apabila terjadi
              <i>Force Majeure</i>, maka pihak yang terkena musibah harus
              memberitahukan segera pada kesempatan pertama kepada pihak lainnya
              yang disusul dengan pemberitahuan tertulis selambat-lambatnya
              dalam waktu 2
            </p>
            <p>
              (dua) Hari Bursa sejak saat terjadinya <i>Force Majeure </i>dan
              harus dapat membuktikan jika keterlambatan atau tidak
              terlaksanakannya ketentuan dalam Perjanjian ini adalah sebagai
              akibat langsung dari <i>Force Majeure</i>.
            </p>
            <br />
          </li>
          <li data-list-text="4.">
            <p>
              Bila <i>Force Majeure </i>disetujui dan ternyata jangka waktu
              keterlambatannya atau tidak terlaksananya pelaksanaan kewajiban
              melebihi 7 (tujuh) Hari Bursa, sehingga Para Pihak tidak dapat
              memenuhi kewajibannya sebagaimana ditentukan dalam Perjanjian ini,
              maka pihak yang berkepentingan dapat mengakhiri Perjanjian ini
              secara sepihak dengan cara sebagaimana ditentukan dalam Pasal 16
              Perjanjian ini.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 15</b>
        </h1>
        <h1>
          <b>Penutupan Rekening Efek</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Perusahaan berhak menutup Rekening Efek dalam hal terjadi hal-hal
              sebagai berikut:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  Nasabah tidak dapat melunasi kewajiban yang tersisa atau
                  Nasabah melakukan keterlambatan pembayaran lebih dari 3 (tiga)
                  kali berturut-turut dalam 3 (tiga) Transaksi dalam waktu 1
                  (satu) bulan sebagaimana diatur dalam Pasal 5 ayat (14);
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  Nasabah tidak aktif melakukan transaksi di Perusahaan dalam
                  jangka waktu 6 (enam) bulan berturut-turut dan tidak terdapat
                  saldo Efek maupun dana pada Rekening Nasabah, sebagaimana
                  diatur dalam Pasal 8 ayat (1) Perjanjian;
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  Dalam hal Nasabah tidak aktif melakukan transaksi di
                  Perusahaan dalam kurun waktu tertentu dan/atau Nasabah tidak
                  dapat dihubungi karena terjadinya perubahan alamat dan Nasabah
                  tidak memberitahu pihak Perusahaan atas perubahan alamat
                  tersebut;
                </p>
              </li>
              <li data-list-text="d.">
                <p>
                  Dalam hal Nasabah meninggal dunia atau dinyatakan pailit atau
                  dibubarkan atau diletakkan di bawah pengawasan pihak yang
                  ditunjuk oleh instansi yang berwenang, sebagaimana diatur
                  dalam Pasal 11 Perjanjian;
                </p>
              </li>
              <li data-list-text="e.">
                <p>
                  Dalam hal Nasabah melakukan tindakan-tindakan sebagaimana
                  diatur dalam Pasal 8 ayat (2) Perjanjian;
                </p>
              </li>
              <li data-list-text="f.">
                <p>
                  Dalam hal Nasabah tidak menyetujui perubahan isi Perjanjian
                  ini dan Lampirannya, serta syarat dan ketentuan Transaksi Efek
                  yang berlaku di Perusahaan;
                </p>
              </li>
              <li data-list-text="g.">
                <p>
                  Dalam hal Nasabah mengakhiri Perjanjian ini atas permintaan
                  Nasabah atau persetujuan Perusahaan dan Nasabah untuk
                  mengakhiri Perjanjian ini pada setiap saat secara tertulis.
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text="2.">
            <p>
              Perusahaan menjamin bahwa tidak akan ada Efek dan/atau dana yang
              tersisa ketika Rekening Efek ditutup. Apabila masih ada Efek
              dan/atau dana yang tersisa dalam Rekening Efek, Perusahaan akan
              menjual Efek tersebut dan Perusahaan akan mentransfer dana-dana
              tersebut termasuk dana-dana dari penjualan Efek tersebut kepada
              Nasabah dengan jumlah minimum yang menurut Bank dapat ditransfer
              kepada Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="3.">
            <p>
              Dalam hal Nasabah tidak aktif melakukan transaksi di Perusahaan
              dalam kurun waktu tertentu dan/atau Nasabah tidak dapat dihubungi
              karena terjadinya perubahan alamat dan Nasabah tidak memberitahu
              pihak Perusahaan atas perubahan alamat tersebut atau dikarenakan
              terdapat peristiwa atau tindakan dalam Pasal 8 ayat (2)
              Perjanjian, maka Perusahaan berhak:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>
                  menggunakan dana dan/atau Efek Nasabah pada Perusahaan (jika
                  ada) untuk membayar kewajiban- kewajiban Nasabah pada
                  Perusahaan;
                </p>
              </li>
              <li data-list-text="b.">
                <p>
                  dalam hal tidak terdapat cukup dana dan/atau Efek Nasabah pada
                  Perusahaan untuk memenuhi kewajiban Nasabah kepada Perusahaan,
                  maka Perusahaan akan menutup Rekening Efek Nasabah namun tetap
                </p>
                <p>
                  memperhitungkan dan menagih kewajiban Nasabah pada Perusahaan
                  tersebut,
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  dengan penutupan Rekening Efek Nasabah tersebut, Nasabah tetap
                  wajib memenuhi semua kewajibannya kepada Perusahaan.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li data-list-text="4.">
            <p>
              Nasabah menyetujui bahwa Perusahaan tidak akan bertanggung jawab
              atas segala kerugian Nasabah yang mungkin muncul sehubungan dengan
              segala tindakan Perusahaan sehubungan dengan penutupan Rekening
              Efek dalam keadaan sebagaimana termaktub dalam Pasal ini.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 16</b>
        </h1>
        <h1>
          <b>Jangka Waktu dan Pengakhiran</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Perjanjian ini mulai berlaku sejak tanggal penandatanganan
              Perjanjian ini sampai Rekening Efek Nasabah ditutup, kecuali
              diakhiri sesuai dengan ayat (2) Pasal ini.
            </p>
          </li>
          <br />
          <li data-list-text="2.">
            <p>
              Perjanjian ini dapat diakhiri karena keadaan-keadaan sebagai
              berikut:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }} className="ml-8">
              <li data-list-text="a.">
                <p>permintaan Nasabah atau Perusahaan pada setiap waktu;</p>
              </li>
              <li data-list-text="b.">
                <p>
                  Nasabah tidak menyetujui atas Perubahan isi Perjanjian ini dan
                  Lampirannya, serta syarat dan ketentuan Transaksi Efek yang
                  berlaku di Perusahaan;
                </p>
              </li>
              <li data-list-text="c.">
                <p>
                  diakhiri oleh Perusahaan karena terjadinya hal-hal yang dapat
                  mengakibatkan penutupan Rekening Efek sebagaimana diatur dalam
                  Pasal 15 Perjanjian;
                </p>
              </li>
              <li data-list-text="d.">
                <p>
                  diakhiri dalam hal terjadi force majeure yang terus menerus
                  sebagaimana diatur dalam Pasal 14 Perjanjian;
                </p>
              </li>
              <li data-list-text="e.">
                <p>
                  persetujuan Perusahaan dan Nasabah untuk mengakhiri Perjanjian
                  ini pada setiap saat secara tertulis, dengan menyebutkan
                  tanggal pengakhiran Perjanjian yang diinginkan dan
                  ditandatangani oleh Perusahaan dan Nasabah.
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li data-list-text="3.">
            <p>
              Pihak yang bermaksud untuk mengakhiri Perjanjian karena
              keadaan-keadaan sebagaimana diatur dalam ayat (2) Pasal ini, harus
              menyampaikan pemberitahuan tertulis dari Pihak yang satu kepada
              Pihak lainnya 7 (tujuh) Hari Bursa sebelum tanggal pengakhiran
              yang dimaksud.
            </p>
            <br />
          </li>
          <li data-list-text="4.">
            <p>
              Pada hari pengakhiran Perjanjian, Perusahaan akan memotong dana
              Nasabah dari Rekening Dana dan/atau Perusahaan akan menjual,
              seluruh atau sebagian dari Efek yang ada di Rekening Nasabah, yang
              merupakan jaminan untuk penyelesaian tanggung jawab Nasabah kepada
              Perusahaan, untuk menyelesaikan kewajiban Nasabah yang terutang
              kepada Perusahaan dan/atau pihak lainnya sehubungan dengan
              penyelesaian transaksi Efek Nasabah, dan apabila terdapat dana
              yang tersisa (termasuk setiap dana dari penjualan Efek),
              Perusahaan akan mengembalikan dana tersebut kepada Nasabah dengan
              jumlah minimum yang menurut Bank Pembayaran dapat ditransfer
              kepada Nasabah.
            </p>
            <br />
          </li>
          <li data-list-text="5.">
            <p>
              Setelah dipenuhinya ketentuan ayat (4) Pasal ini, Perusahaan akan
              melakukan penutupan Rekening Nasabah sesuai dengan ketentuan
              penutupan Rekening Efek sebagaimana diatur dalam Pasal 15
              Perjanjian ini.
            </p>
          </li>
          <br />
          <li data-list-text="6.">
            <p>
              Dalam hal Nasabah masih mempunyai kewajiban yang belum
              diselesaikan kepada Perusahaan, akan tetapi tidak ada dana
              dan/atau Efek yang masih tersisa di dalam Rekening Dana dan/atau
              Rekening Nasabah untuk melunasi kewajiban Nasabah tersebut,
              pengakhiran Perjanjian dan penutupan Rekening Efek tidak
            </p>
            <p>
              menghapuskan kewajiban Nasabah kepada Perusahaan dan Perusahaan
              memiliki hak untuk menuntut diselesaikannya kewajiban beserta
              ganti rugi yang timbul atas tidak dilaksanakannya kewajiban
              Nasabah tersebut.
            </p>
            <br />
          </li>
          <li data-list-text="7.">
            <p>
              Para Pihak dengan ini secara tegas melepaskan Pasal 1266 dan 1267
              dari Kitab Undang-undang Hukum Perdata sejauh yang diperlukan
              untuk pengakhiran jasa Perusahaan sesuai dengan ketentuan
              tersebut.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 17</b>
        </h1>
        <h1>
          <b>Hukum yang Berlaku dan Penyelesaian Perselisihan</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Perjanjian ini diatur dan harus ditafsirkan berdasarkan hukum
              negara Republik Indonesia, Perjanjian ini dibuat dalam Bahasa
              Indonesia dan Bahasa Inggris, yang merupakan terjemahan dari
              Bahasa Indonesia. Apabila terjadi perbedaan penafsiran diantara
              keduanya maka yang berlaku adalah penafsiran dalam Bahasa
              Indonesia.
            </p>
            <br />
          </li>
          <li data-list-text="2.">
            <p>
              Dalam hal terjadi perselisihan sehubungan dengan Perjanjian ini
              atau sebagian dari padanya, perselisihan tersebut akan
              diselesaikan secara musyawarah untuk mufakat oleh Para Pihak.
              Apabila penyelesaian secara musyawarah untuk mufakat tersebut
              tidak dapat dicapai dalam waktu 30 (tigapuluh) hari kalender, maka
              Para Pihak sepakat untuk menyelesaikan perselisihan melalui Badan
              Arbitrase Pasar Modal Indonesia (“BAPMI”). Keputusan BAPMI akan
              mengikat secara mutlak dan dalam tingkat terakhir bagi Para Pihak.
            </p>
            <br />
          </li>
          <li data-list-text="3.">
            <p>
              Nasabah dan Perusahaan menyatakan melepaskan haknya untuk
              mengajukan tuntutan, gugatan atau permohonan dalam bentuk dan cara
              apapun kepada instansi peradilan atau lembaga perwasitan lain,
              tentang segala sesuatu yang berhubungan dengan perselisihan atau
              sengketa yang diselesaikan melalui BAPMI, kecuali untuk
              pelaksanaan putusan BAPMI tersebut.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <br />
        <h1>
          <b>Pasal 18</b>
        </h1>
        <h1>
          <b>Ketentuan Lain-lain</b>
        </h1>
        <br />
        <ol className="list-decimal ml-4">
          <li data-list-text="1.">
            <p>
              Perusahaan berhak mengubah, mengakhiri, memperbaiki atau
              menambahkan isi Perjanjian ini dan Lampirannya, serta syarat dan
              ketentuan Transaksi Efek, yang berlaku di Perusahaan. Setiap
              perubahan, perbaikan atau penambahan yang dilakukan terhadap isi
              Perjanjian dan Lampirannya akan diberitahukan dan disampaikan
              kepada Nasabah.
            </p>
          </li>
          <br />
          <li data-list-text="2.">
            <p>
              Nasabah berhak untuk menyatakan keberatan atau tidak menyetujui
              atas Perubahan isi Perjanjian ini dan Lampirannya, serta syarat
              dan ketentuan Transaksi Efek yang berlaku di Perusahaan, dalam
              waktu 30 (tiga puluh) hari sejak pemberitahuan tersebut
              disampaikan kepada Nasabah. Apabila dalam waktu tersebut tidak
              terdapat sanggahan dan/atau keberatan dari Nasabah, maka Nasabah
              dianggap telah menyetujui segala perubahan dan bersedia mematuhi
              dan Lampirannya, serta syarat dan ketentuan Transaksi Efek, yang
              telah diubah tersebut.
            </p>
            <br />
          </li>
          <li data-list-text="3.">
            <p>
              Dalam hal Nasabah tidak menyetujui atas perubahan isi Perjanjian
              ini dan Lampirannya, serta tidak dicapai kesepakatan atas isi
              Perjanjian ini dan Lampirannya, serta syarat dan ketentuan
              Transaksi Efek, maka para pihak setuju untuk mengakhiri Perjanjian
              ini dan dilakukan penutupan rekening efek sesuai dengan ketentuan
              Pasal 15 Perjanjian ini.
            </p>
          </li>
        </ol>
        <br />
        <p>
          Perjanjian ini berlaku dan mengikat Para Pihak sejak Perjanjian ini
          ditandatangani oleh Para Pihak.
        </p>
        <br />
        <br />
        <h1>
          <strong>PT TRUST SEKURITAS</strong>
        </h1>
      </div>

      <div className="pt-6 border-t mt-6 dark:border-gray-base-d">
        <button
          type="button"
          onClick={() => onClose(false)}
          className="px-4 py-3 text-center font-quicksand-semibold text-white dark:text-black-base-d bg-main-base-l rounded-xl w-full text-sm hover:opacity-75 transition-all ease-in-out duration-150">
          Oke, Mengerti
        </button>
      </div>
    </Card>
  );
}

ModalAgreementSmartOrder.propTypes = {
  onClose: PropTypes.func
};

export default ModalAgreementSmartOrder;
