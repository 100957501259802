import baseApi from '../base'
import { TAG_PRICE_ALERTS } from '../tags';

export const PriceAlertApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPriceAlertByCode: build.query({
      query: (code) => {
        return {
          url: `/stocks/price-alert/${code}`,
        }
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, code) => {
        return [{ type: TAG_PRICE_ALERTS, id:  code }];
      }
    }),
    getAllPriceAlert: build.query({
      query: () => {
        return {
          url: `/stocks/price-alert`,
        }
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_PRICE_ALERTS, id:  'LIST' }];
      }
    }),
    createPriceAlert: build.mutation({
      query: (body) => {
        return {
          url: '/stocks/price-alert',
          method: 'POST',
          body
        }
      },
      invalidatesTags: (result, error, { code }) => {
        return [
          { type: TAG_PRICE_ALERTS, id: 'LIST' },
          { type: TAG_PRICE_ALERTS, id: code },
        ];
      }
    }),
    updatePriceAlertById: build.mutation({
      query: ({body, id}) => {
        return {
          url: `/stocks/price-alert/${id}`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: (result, error, { code }) => {
        return [
          { type: TAG_PRICE_ALERTS, id: 'LIST' },
          { type: TAG_PRICE_ALERTS, id: code },
        ];
      }
    }),
    deletePriceAlert: build.mutation({
      query: (stock) => {
        return {
          url: `/stocks/price-alert/${stock?.id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, { code }) => {
        return [
          { type: TAG_PRICE_ALERTS, id: 'LIST' },
          { type: TAG_PRICE_ALERTS, id: code },
        ];
      }
    }),
  }),
});

export const {
  useGetPriceAlertByCodeQuery,
  useCreatePriceAlertMutation,
  useDeletePriceAlertMutation,
  useGetAllPriceAlertQuery,
  useUpdatePriceAlertByIdMutation
} = PriceAlertApi;
