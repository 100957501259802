import PropTypes from 'prop-types';
import TWOrderForm from './tw-order-form';
import TWIntradayChart from './tw-intraday-chart';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import useElementSize from 'hooks/useElementSize';
import cn from 'utils/classname';

function TWOrderFormWithOrderbook({
  symbol,
  widgetSettings,
  idWidget,
  onChangeTab = () => {},
  onChangeWidgetSettings = () => {},
  onChangeStock = () => {}
}) {
  const { ref, dimensions } = useElementSize();
  const { width } = dimensions ?? {};

  return (
    <OverlayScrollbarsComponent
      className="overlayscrollbars-host-small overflow-hidden relative h-full"
      element="span"
      options={{ resize: 'horizontal' }}>
      <div
        ref={ref}
        className={cn('grid grid-cols-2 gap-2', width < 350 && 'grid-cols-1')}>
        <div>
          <TWOrderForm
            isCardLess
            mockOnly
            symbol={symbol}
            initActiveTabIndex={widgetSettings?.initActiveTabIndex || 0}
            onChangeStock={({ code }) => {
              if (!code) return false;
              onChangeStock(code);
            }}
            onChangeTab={(initActiveTabIndex) =>
              onChangeTab(initActiveTabIndex)
            }
          />
        </div>

        <div>
          <TWIntradayChart
            isCardLess
            idWidget={idWidget}
            symbol={symbol}
            // rows={10}
            maxHeight={326}
            views={{
              orderBook: true,
              buyOrSell: false,
              customFilter: false,
              customLegend: true,
              customHeader: true,
              chart: false
            }}
            isRerender
            onChangeSymbol={(newSymbol) => onChangeStock(newSymbol)}
            widgetSettings={widgetSettings}
            onChangeWidgetSettings={onChangeWidgetSettings}
          />
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
}

TWOrderFormWithOrderbook.propTypes = {
  symbol: PropTypes.string,
  initValues: PropTypes.object,
  onChangeStock: PropTypes.func,
  onChangeTab: PropTypes.func,
  initActiveTabIndex: PropTypes.any,
  isCardLess: PropTypes.bool,
  isSticky: PropTypes.bool,
  mockOnly: PropTypes.bool,
  buyAnalyticEventName: PropTypes.string,
  sellAnalyticEventName: PropTypes.string,
  amendAnalyticEventName: PropTypes.string,
  withdrawAnalyticEventName: PropTypes.string
};

export default TWOrderFormWithOrderbook;
