import baseApi from '../base'
import { TAG_OPENING_ACCOUNT } from '../tags';

export const OpeningAccountApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOpeningAccountByUserId: build.query({
      query: (userId) => {
        return {
          url: `/oa/task/${userId}`,
        }
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_OPENING_ACCOUNT, id: 'LIST' }];
      }
    }),
  }),
});

export const {
  useGetOpeningAccountByUserIdQuery,
} = OpeningAccountApi;
