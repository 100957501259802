import RenderIf from 'components/basic/render-if';
import { automatedNumberFormat, currency } from 'utils/format';
import { useEffect, useState } from 'react';
import IconSorting from 'components/svg/icon-sorting';
import { isEqual, orderBy } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { usePortfolio } from 'hooks/usePortfolio';
import { useTableSorting } from 'hooks/useTableSorting';
import { getColorStatus } from 'utils/stock/score';
import ActionButton from 'components/basic/action-button';
import useToastr from 'hooks/useToastr';
import { useDispatch } from 'react-redux';
import { triggerSegmentEvent } from 'lib/segment';
import Tooltip from 'components/basic/tooltip';
import IconInfo from 'components/svg/icon-info';
import IconPlus from 'components/svg/icon-plus';
import {
  invalidateSmartOrderList,
  useDeleteAlgoBOTMutation
} from 'services/rtk-query/trading';
import SmartOrderForm from 'components/form/smart-order-form';
import IconTrashCan from 'components/svg/icon-trash-can';
import IconEye from 'components/svg/icon-eye';
import dayjs from 'dayjs';
import OrderDetail from './order-detail';
import { useGetOrderListQuery } from 'services/rtk-query/trade';
import IconSearchPage from 'components/svg/icon-search-page';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import IconStarDelete from 'components/svg/icon-star-delete';
import IconStar from 'components/svg/icon-star';
import {
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistQuery
} from 'services/rtk-query/watchlist';
import { useCompareStock } from 'hooks/useCompareStock';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import { isMobile } from 'react-device-detect';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Modal from 'components/modal/modal';
import ModalDialog from 'components/modal/components/modal-dialog';

function SmartPortfolioTable({ onClickSmartPortfolio = () => false }) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { navigate, customNavigate } = useCustomNavigate();

  const dispatch = useDispatch();

  const [deleteWatchlist] = useDeleteWatchlistMutation();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteAlgoBOT] = useDeleteAlgoBOTMutation();

  const { addToCompareStock } = useCompareStock();
  const { data: watchlist } = useGetWatchlistQuery();

  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [openDeleteSmartPortfolio, setOpenDeleteSmartPortfolio] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: orderListGeneral } = useGetOrderListQuery(null, {
    refetchOnMountOrArgChange: true
  });
  const {
    totalInvested,
    totalMktValue,
    totalPotGain,
    totalPotGainPercent,
    portfolio,
    isLoading: loadingPortfolio,
    portfolioList,
    errorAlgoBot
  } = usePortfolio();
  const { data, sortingColumns, sortingOrders, handleSorting, getSorting } =
    useTableSorting(portfolioList, ['totalGain'], ['desc']);

  const [tempData, setTempData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const renderDuration = (start, end) => {
    let _string =
      dayjs(start, 'DD-MMM-YYYY HH:mm:ss').format('DD MMM') +
      ' - ' +
      dayjs(end, 'DD-MMM-YYYY HH:mm:ss').format('DD MMM YY');
    let _date = dayjs(end, 'DD-MMM-YYYY HH:mm:ss');

    let suffix = ' Bulan';
    let countDuration = Math.abs(
      _date.diff(dayjs(start, 'DD-MMM-YYYY HH:mm:ss'), 'month')
    );

    if (countDuration === 0) {
      countDuration = Math.abs(
        _date.diff(dayjs(start, 'DD-MMM-YYYY HH:mm:ss'), 'day')
      );
      suffix = ' Hari';
    }

    if (countDuration === 0) {
      countDuration = Math.abs(
        _date.diff(dayjs(start, 'DD-MMM-YYYY HH:mm:ss'), 'hour')
      );
      suffix = ' Jam';
    }

    return _string + ' (' + countDuration + suffix + ')';
  };

  const getColorStatusBot = (State) => {
    let className = 'text-main-base-l';
    if (['Berakhir Tanpa Match', 'reject', 'withdraw'].includes(State)) {
      className = 'text-red-base-l';
    }

    if (['Terkirim', 'amend', 'partial', 'match'].includes(State)) {
      className = 'text-green-base-l';
    }

    if (['Dihapus', 'Tidak Aktif'].includes(State)) {
      className = 'text-gray-light-0-d';
    }

    if (['open', 'basket'].includes(State)) {
      className = 'text-turquoise-2';
    }

    return className;
  };

  const handleDeleteSmartOrder = (BotId) => {
    setOpenDeleteSmartPortfolio(BotId);
  };

  const onClickOrderDetail = ({ RTOrderID }) => {
    let orderDetail = orderListGeneral?.items?.find(
      (item) => item?.id == Number(RTOrderID)
    );

    if (orderDetail) {
      setOpenOrderDetail(orderDetail);
    } else {
      toastr.error('Order tidak dapat ditemukan!');
    }
  };

  useEffect(() => {
    if (!isEqual(orderBy(tempData, sortingColumns, sortingOrders), data)) {
      setTempData(data);
      // setToggleActive(tempData);
    }
  }, [tempData, data, sortingOrders, sortingColumns]);

  useEffect(() => {
    setIsLoading(loadingPortfolio);
  }, [loadingPortfolio]);

  return (
    <>
      <div className="md:flex justify-between items-start mb-3">
        <div className="flex-auto mb-3 lg:mb-0">
          <h1 className="font-quicksand-semibold flex items-center text-sm">
            <span>Smart Portfolio</span>

            <Tooltip
              isZoomOut
              tooltipHTML={
                <div className="max-w-[300px] min-w-[300px]">
                  <h5 className="text-sm font-quicksand-medium text-left mb-1">
                    Smart Portfolio
                  </h5>
                  <p className="text-xs font-quicksand-medium text-left">
                    Gunakan fitur ini untuk membuat strategi pengambilan profit
                    dan penjagaan kerugian saham portofolio yang sudah kamu
                    miliki tanpa ribet.
                  </p>
                </div>
              }
              position="bottom-left">
              <button type="button" className="hover:text-main-base-l mx-1">
                <IconInfo className="h-4 w-4" />
              </button>
            </Tooltip>
          </h1>
        </div>

        <RenderIf
          isTrue={
            (portfolio?.stock?.items?.length || 0) > 0 &&
            !isLoading &&
            !errorAlgoBot?.data?.message
          }>
          <div className="flex-none flex -mx-1">
            <button
              type="button"
              title="Buy"
              disabled={portfolio?.stock?.items?.length === 0}
              onClick={() => {
                onClickSmartPortfolio({
                  stockId: portfolio?.stock?.items?.[0]?.stID,
                  BuySell: 'StopLoss'
                });
                triggerSegmentEvent({ event: 'TR Stop Loss Button Clicked' });
              }}
              className="p-3 py-2 mx-1 flex items-center rounded-lg border-2 border-main-base-l card-widget-gradient text-main-base-l hover:opacity-75 transition-all ease-in-out duration-150 text-xs font-quicksand-semibold">
              <IconPlus className="h-4 w-4 mr-1" /> Stop Loss
            </button>

            <button
              type="button"
              title="Buy"
              disabled={portfolio?.stock?.items?.length === 0}
              onClick={() => {
                onClickSmartPortfolio({
                  stockId: portfolio?.stock?.items?.[0]?.stID,
                  BuySell: 'TakeProfit'
                });
                triggerSegmentEvent({ event: 'TR Take Profit Button Clicked' });
              }}
              className="p-3 py-2 mx-2 flex items-center rounded-lg border-2 border-main-base-l bg-main-base-l dark:text-black-base-d text-white hover:opacity-75 transition-all ease-in-out duration-150 text-xs font-quicksand-semibold">
              <IconPlus className="h-4 w-4 mr-1" /> Take Profit
            </button>
          </div>
        </RenderIf>
      </div>

      <RenderIf
        isTrue={
          (portfolio?.stock?.items?.length || 0) === 0 &&
          !isLoading &&
          !errorAlgoBot?.data?.message
        }>
        <div className="text-center py-3">
          <h2 className="text-2xl mb-1 font-quicksand-semibold">
            Smart portfolio kamu belum aktif
          </h2>
          <p className="text-sm text-gray-light-0-d max-w-lg mx-auto font-quicksand-semibold">
            Smart Portfolio adalah fitur yang Kamu perlukan untuk mengelola dan
            memantau investasi secara lebih efektif. Gunakan fitur ini untuk
            membuat strategi pengambilan profit dan penjagaan kerugian saham
            portofolio yang sudah Kamu miliki tanpa ribet.
          </p>

          <button
            onClick={() => {
              onClickSmartPortfolio({ stockId: 'AALI', BuySell: 'TakeProfit' });
              triggerSegmentEvent({
                event: 'TR Smart Portfolio Button Clicked'
              });
            }}
            type="button"
            className="bg-main-base-l my-4 text-white dark:text-black-base-d hover:opacity-75 transition-all ease-linear duration-150 py-[10px] px-5 w-64 block mx-auto text-sm rounded-lg font-quicksand-semibold">
            Aktifkan Smart Portfolio
          </button>
        </div>
      </RenderIf>

      <RenderIf
        isTrue={
          ((portfolio?.stock?.items?.length || 0) > 0 && !isLoading) ||
          isLoading
        }>
        <OverlayScrollbarsComponent
          style={{
            maxWidth: !isMobile ? 'calc(100%)' : 'calc(100vw - 1rem)'
          }}
          className="overlayscrollbars-host-small"
          element="span"
          options={{
            resize: 'horizontal',
            scrollbars: {
              autoHide: 'leave',
              autoHideDelay: 50
            }
          }}>
          <table className="text-2xs w-full text-center font-quicksand-semibold">
            <thead className="text-gray-light-0-d">
              <tr>
                <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d">
                  <span className="flex items-center gap-1 justify-end">
                    No
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'code' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('code')}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    {sortingColumns?.[0] === 'code' && (
                      <IconSorting
                        type={getSorting('code')}
                        className="h-3 w-3"
                      />
                    )}
                    Stock{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'net' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('net')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    {sortingColumns?.[0] === 'net' && (
                      <IconSorting
                        type={getSorting('net')}
                        className="h-3 w-3"
                      />
                    )}
                    Lot{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'avgPrice' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('avgPrice')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    {sortingColumns?.[0] === 'avgPrice' && (
                      <IconSorting
                        type={getSorting('avgPrice')}
                        className="h-3 w-3"
                      />
                    )}
                    Avg{' '}
                  </span>
                </td>
                {/* <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'lastTradedPrice' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('lastTradedPrice')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'lastTradedPrice' &&
                  }
                  <IconSorting
                    type={getSorting('lastTradedPrice')}
                    className="h-3 w-3"
                  />
                    Price{' '}
                  </span>
                </td> */}
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'stockValue' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('stockValue')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    {sortingColumns?.[0] === 'stockValue' && (
                      <IconSorting
                        type={getSorting('stockValue')}
                        className="h-3 w-3"
                      />
                    )}
                    Invested{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'marketValue' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('marketValue')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    {sortingColumns?.[0] === 'marketValue' && (
                      <IconSorting
                        type={getSorting('marketValue')}
                        className="h-3 w-3"
                      />
                    )}
                    Mkt.Value{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'totalGain' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('totalGain')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    {sortingColumns?.[0] === 'totalGain' && (
                      <IconSorting
                        type={getSorting('totalGain')}
                        className="h-3 w-3"
                      />
                    )}
                    POTG/L{' '}
                  </span>
                </td>
                {/* <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'totalGainPercentage' &&
                    'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('totalGainPercentage')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'totalGainPercentage' &&
                  }
                  <IconSorting
                    type={getSorting('totalGainPercentage')}
                    className="h-3 w-3"
                  />
                    POTG/L&nbsp;(%){' '}
                  </span>
                </td> */}
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l`}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Take&nbsp;Profit
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l`}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    Stop&nbsp;Loss
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <RenderIf isTrue={data?.length === 0 && !isLoading}>
                <tr>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                  <td className="p-2 group-last:border-b-0 text-left">-</td>
                </tr>
              </RenderIf>

              <RenderIf isTrue={isLoading}>
                {[0, 1, 2].map((item) => (
                  <tr key={item}>
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    {/* <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td> */}
                    {/* <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td> */}
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 group-last:border-b-0 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                  </tr>
                ))}
              </RenderIf>

              <RenderIf isTrue={!errorAlgoBot?.data?.message}>
                {data?.map((item, key) => (
                  <tr key={key} className="group">
                    <td className="px-2 group-last:border-b-0 text-center align-top">
                      <span className="block py-2">{key + 1}</span>
                    </td>
                    <td className="px-2 group-last:border-b-0 text-left font-quicksand-semibold align-top">
                      <div className="relative">
                        <button
                          type="button"
                          onClick={() =>
                            customNavigate({
                              query: { 'stock-detail': item?.code }
                            })
                          }
                          className="flex items-center mx-auto hover:opacity-75 transition-all ease-in-out duration-200 py-2 px-1 rounded-xl">
                          <RenderIf isTrue={item?.sLogo}>
                            <img
                              src={item?.sLogo}
                              className="h-4 w-4 rounded-full mr-1 border border-gray-light-l dark:border-gray-base-d"
                            />
                            {item?.code}
                          </RenderIf>

                          <RenderIf isTrue={!item?.sLogo}>
                            <div className="rounded-full mr-1 flex-none bg-gray-light-l bg-opacity-50 text-xs flex items-center justify-center h-4 w-4">
                              {item?.code?.charAt(0)}
                            </div>
                            {item?.code}
                          </RenderIf>
                        </button>

                        <ActionButton
                          isZoomOut
                          isLast={key > data?.length - 4}
                          position={`left-0 -bottom-8`}
                          actions={[
                            {
                              title: 'Stock Detail',
                              children: <IconSearchPage className="h-4 w-4" />,
                              onClick: () => {
                                triggerSegmentEvent({
                                  event: 'TR Search Feature Clicked'
                                });
                                customNavigate({
                                  query: { 'stock-detail': item?.code }
                                });
                              }
                            },
                            {
                              title: 'Trading View',
                              children: (
                                <IconChartCandleStick className="h-4 w-4" />
                              ),
                              onClick: () =>
                                customNavigate({
                                  query: { 'advanced-chart': item?.code }
                                })
                            },
                            {
                              title: 'Bandingkan',
                              children: <IconCompare className="h-4 w-4" />,
                              onClick: () => {
                                triggerSegmentEvent({
                                  event: 'TR Compare Stock Clicked'
                                });
                                addToCompareStock(
                                  { code: item?.code, name: item?.name },
                                  () => {
                                    navigate(`/compare/fundamental`);
                                  }
                                );
                              }
                            },
                            {
                              title: `${
                                watchlist?.find((w) => w?.code === item?.code)
                                  ? 'Hapus Watchlist'
                                  : 'Tambahkan ke Watchlist'
                              }`,
                              children: watchlist?.find(
                                (w) => w?.code === item?.code
                              ) ? (
                                <IconStarDelete className="h-4 w-4" />
                              ) : (
                                <IconStar className="h-4 w-4" />
                              ),
                              onClick: async () => {
                                triggerSegmentEvent({
                                  event: 'TR Watchlist Clicked'
                                });
                                if (
                                  watchlist?.find((w) => w?.code === item?.code)
                                ) {
                                  try {
                                    await deleteWatchlist({
                                      stockCode: item?.code
                                    }).unwrap();
                                    toastr.success(
                                      `${item?.code} berhasil dihapus dari watchlist`
                                    );
                                  } catch (error) {
                                    getErrorMessage(error);
                                  }
                                } else {
                                  try {
                                    await createWatchlist({
                                      stockCode: item?.code,
                                      type: 'stock'
                                    }).unwrap();
                                    toastr.success(
                                      `${item?.code} berhasil ditambahkan kedalam watchlist`
                                    );
                                  } catch (error) {
                                    getErrorMessage(error);
                                  }
                                }
                              }
                            }
                          ]}
                        />
                      </div>
                    </td>
                    <td className="px-2 group-last:border-b-0 text-right align-top">
                      <span className="block py-2">
                        {currency(item.net / 100, { removeSymbol: true })}
                      </span>
                    </td>
                    <td className="px-2 group-last:border-b-0 text-right align-top">
                      <span className="block py-2">
                        {currency(item?.avgPrice, { removeSymbol: true })}
                      </span>
                    </td>
                    {/* <td className="px-2 group-last:border-b-0 text-right align-top">
                      <span className="block py-2">
                        {currency((item?.lastTradedPrice || 0) / 100, {
                          removeSymbol: true
                        })}
                      </span>
                    </td> */}
                    <td className="px-2 group-last:border-b-0 text-right align-top">
                      <span className="block py-2">
                        {currency(item?.stockValue, { removeSymbol: true })}
                      </span>
                    </td>
                    <td className="px-2 group-last:border-b-0 text-right align-top">
                      <span className="block py-2">
                        {currency(item?.marketValue, { removeSymbol: true })}
                      </span>
                    </td>
                    <td
                      className={`px-2 group-last:border-b-0 text-right align-top ${getColorStatus(
                        item?.totalGain
                      )}`}>
                      <div className="flex items-center py-2">
                        <span className="block">
                          {currency(item?.totalGain, { removeSymbol: true })}
                          &nbsp;
                        </span>
                        <span className="block">
                          (
                          {Math.abs(
                            Number(item?.totalGainPercentage || 0).toFixed(2)
                          )}
                          %)
                        </span>
                      </div>
                    </td>
                    {/* <td
                      className={`px-2 group-last:border-b-0 text-left align-top ${getColorStatus(
                        item?.totalGainPercentage
                      )}`}>
                      <span className="block py-2">
                        {Number(item?.totalGainPercentage || 0).toFixed(2)}%
                      </span>
                    </td> */}
                    <td
                      className={`px-2 group-last:border-b-0 text-right align-top`}>
                      <div className="block w-full">
                        <table className="w-full">
                          <tbody>
                            {item?.algoTakeProfit?.map((algo, _key) => (
                              <tr key={_key} className="group">
                                <td className="border-b group-last:border-b-0 dark:border-gray-base-d py-1">
                                  <span>
                                    {automatedNumberFormat(algo?.Lot)} Lot
                                  </span>
                                </td>
                                <td className="border-b group-last:border-b-0 dark:border-gray-base-d py-1">
                                  <span>
                                    IDR&nbsp;
                                    {automatedNumberFormat(algo?.Price)}
                                  </span>
                                </td>
                                <td className="border-b group-last:border-b-0 dark:border-gray-base-d py-1">
                                  <span className="block text-gray-light-0-d font-quicksand-medium">
                                    Trigger <br /> Harga {algo?.Condition}{' '}
                                    {automatedNumberFormat(algo?.PriceFire)}
                                  </span>
                                </td>
                                <td className="border-b group-last:border-b-0 dark:border-gray-base-d py-1">
                                  <p
                                    className={`flex-none mr-2 line-clamp-1 uppercase ${getColorStatusBot(
                                      algo?.State
                                    )}`}>
                                    {algo?.State}
                                  </p>
                                  {algo?.State != 'Dihapus' && (
                                    <span className="block text-gray-light-0-d font-quicksand-medium">
                                      {renderDuration(
                                        algo?.CreateAt,
                                        algo?.BotExpire
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td className="border-b group-last:border-b-0 dark:border-gray-base-d py-1">
                                  <div className="flex gap-2 items-center justify-end">
                                    <button
                                      onClick={() =>
                                        handleDeleteSmartOrder(algo?.BotId)
                                      }
                                      disabled={algo?.State != 'Aktif'}
                                      type="button"
                                      className="p-1 rounded-md border border-main-base-l text-main-base-l hover:bg-main-base-l hover:text-white dark:hover:text-black-base-d transition-all ease-linear duration-150 disabled:bg-gray-light-l dark:disabled:bg-gray-base-d dark:disabled:text-black-dark-d disabled:text-gray-base-l disabled:border-gray-base-l dark:disabled:border-gray-base-d">
                                      <IconTrashCan className="h-4 w-4" />
                                    </button>
                                    <button
                                      onClick={() => onClickOrderDetail(algo)}
                                      disabled={!algo?.RTOrderStatus}
                                      type="button"
                                      className="p-1 rounded-md border border-main-base-l text-main-base-l hover:bg-main-base-l hover:text-white dark:hover:text-black-base-d transition-all ease-linear duration-150 disabled:bg-gray-light-l dark:disabled:bg-gray-base-d dark:disabled:text-black-dark-d disabled:text-gray-base-l disabled:border-gray-base-l dark:disabled:border-gray-base-d">
                                      <IconEye className="h-4 w-4" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <button
                          onClick={() =>
                            onClickSmartPortfolio(
                              { stockId: item?.stID, BuySell: 'TakeProfit' },
                              false
                            )
                          }
                          type="button"
                          className="card-widget-gradient text-main-base-l border hover:opacity-75 border-main-base-l p-[4px] my-2 text-2xs rounded-md flex items-center transition-all ease-in-out duration-200 hover:bg-opacity-75">
                          <IconPlus className="h-3 w-3 mr-1" />{' '}
                          {item?.algoTakeProfit?.length === 0
                            ? 'Aktifkan Take Profit'
                            : 'Tambah Take Profit'}
                        </button>
                      </div>
                    </td>
                    <td
                      className={`px-2 group-last:border-b-0 text-right align-top`}>
                      <div className="block w-full">
                        <table className="w-full">
                          <tbody>
                            {item?.algoStopLoss?.map((algo, _key) => (
                              <tr key={_key} className="group">
                                <td className="border-b group-last:border-b-0 py-1">
                                  <span>
                                    {automatedNumberFormat(algo?.Lot)} Lot
                                  </span>
                                </td>
                                <td className="border-b group-last:border-b-0 py-1">
                                  <span>
                                    IDR&nbsp;
                                    {automatedNumberFormat(algo?.Price)}
                                  </span>
                                </td>
                                <td className="border-b group-last:border-b-0 py-1">
                                  <span className="block text-gray-light-0-d font-quicksand-medium">
                                    Trigger <br /> Harga {algo?.Condition}{' '}
                                    {automatedNumberFormat(algo?.PriceFire)}
                                  </span>
                                </td>
                                <td className="border-b group-last:border-b-0 py-1">
                                  <p
                                    className={`flex-none mr-2 line-clamp-1 uppercase ${getColorStatusBot(
                                      algo?.State
                                    )}`}>
                                    {algo?.State}
                                  </p>
                                  {algo?.State != 'Dihapus' && (
                                    <span className="block text-gray-light-0-d font-quicksand-medium">
                                      {renderDuration(
                                        algo?.CreateAt,
                                        algo?.BotExpire
                                      )}
                                    </span>
                                  )}
                                </td>
                                <td className="border-b group-last:border-b-0 py-1">
                                  <div className="flex gap-2 items-center justify-end">
                                    <button
                                      onClick={() =>
                                        handleDeleteSmartOrder(algo?.BotId)
                                      }
                                      disabled={algo?.State != 'Aktif'}
                                      type="button"
                                      className="p-1 rounded-md border border-main-base-l text-main-base-l hover:bg-main-base-l hover:text-white dark:hover:text-black-base-d transition-all ease-linear duration-150 disabled:bg-gray-light-l dark:disabled:bg-gray-base-d dark:disabled:text-black-dark-d disabled:text-gray-base-l disabled:border-gray-base-l dark:disabled:border-gray-base-d">
                                      <IconTrashCan className="h-4 w-4" />
                                    </button>
                                    <button
                                      onClick={() => onClickOrderDetail(algo)}
                                      disabled={!algo?.RTOrderStatus}
                                      type="button"
                                      className="p-1 rounded-md border border-main-base-l text-main-base-l hover:bg-main-base-l hover:text-white dark:hover:text-black-base-d transition-all ease-linear duration-150 disabled:bg-gray-light-l dark:disabled:bg-gray-base-d dark:disabled:text-black-dark-d disabled:text-gray-base-l disabled:border-gray-base-l dark:disabled:border-gray-base-d">
                                      <IconEye className="h-4 w-4" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <button
                          onClick={() =>
                            onClickSmartPortfolio(
                              { stockId: item?.stID, BuySell: 'StopLoss' },
                              false
                            )
                          }
                          type="button"
                          className="card-widget-gradient ml-auto mr-0 text-main-base-l border hover:opacity-75 border-main-base-l p-[4px] my-2 text-2xs rounded-md flex items-center transition-all ease-in-out duration-200 hover:bg-opacity-75">
                          <IconPlus className="h-3 w-3 mr-1" />{' '}
                          {item?.algoStopLoss?.length === 0
                            ? 'Aktifkan Stop Loss'
                            : 'Tambah Stop Loss'}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}

                <tr className="text-2xs font-quicksand-semibold">
                  <td className="p-2 py-3" colSpan={4}>
                    Total
                  </td>
                  <td className="p-2 py-3 text-left">
                    <RenderIf isTrue={isLoading}>
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </RenderIf>

                    <RenderIf isTrue={!isLoading}>
                      {currency(totalInvested, { removeSymbol: true })}
                    </RenderIf>
                  </td>
                  <td className="p-2 py-3 text-left">
                    <RenderIf isTrue={isLoading}>
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </RenderIf>

                    <RenderIf isTrue={!isLoading}>
                      {currency(totalMktValue, { removeSymbol: true })}
                    </RenderIf>
                  </td>
                  <td
                    className={`p-2 py-3 text-left ${
                      totalPotGain < 0
                        ? 'text-red-base-l'
                        : totalPotGain > 0
                        ? 'text-green-base-l'
                        : ''
                    }`}>
                    <RenderIf isTrue={isLoading}>
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </RenderIf>

                    <RenderIf isTrue={!isLoading}>
                      <div className="flex items-center">
                        <span>
                          {currency(totalPotGain, { removeSymbol: true })}
                        </span>
                        &nbsp;
                        <span>({Math.abs(totalPotGainPercent)}%)</span>
                      </div>
                    </RenderIf>
                  </td>
                  {/* <td
                    className={`p-2 py-3 text-left ${
                      totalPotGainPercent < 0
                        ? 'text-red-base-l'
                        : totalPotGainPercent > 0
                        ? 'text-green-base-l'
                        : ''
                    }`}>
                    <RenderIf isTrue={isLoading}>
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </RenderIf>

                    <RenderIf isTrue={!isLoading}>
                      {totalPotGainPercent}%
                    </RenderIf>
                  </td> */}
                  <td className={`p-2 py-3 uppercase`} colSpan={13}></td>
                </tr>
              </RenderIf>
            </tbody>
          </table>
        </OverlayScrollbarsComponent>
      </RenderIf>

      <RenderIf isTrue={errorAlgoBot?.data?.message && !isLoading}>
        <div className="p-3 bg-main-light-1-d text-black-base-d font-quicksand-semibold text-sm my-4 rounded-lg flex">
          <IconInfo className="h-4 w-4 mr-1 flex-none mt-[2px]" />{' '}
          {errorAlgoBot?.data?.message}
        </div>
      </RenderIf>

      <RenderIf isTrue={openOrderDetail}>
        <Modal size="large" onClose={() => setOpenOrderDetail(false)}>
          <OrderDetail
            data={openOrderDetail}
            amendWithdraw={true}
            onClose={() => setOpenOrderDetail(false)}
          />
        </Modal>
      </RenderIf>

      {/* DELETE FORM */}
      <RenderIf isTrue={openDeleteSmartPortfolio}>
        <Modal onClose={() => setOpenDeleteSmartPortfolio(false)}>
          <ModalDialog
            header={
              <h5 className="font-quicksand-semibold text-base">
                Konfirmasi Penghapusan Order
              </h5>
            }
            submitTitle="Hapus"
            backTitle="Batal"
            isSubmitting={isSubmitting}
            onClose={() => setOpenDeleteSmartPortfolio(false)}
            onSubmit={async () => {
              try {
                setIsSubmitting(true);
                const { success, message } = await deleteAlgoBOT({
                  botID: openDeleteSmartPortfolio?.BotId
                }).unwrap();
                if (!success) throw new Error(message);

                setOpenDeleteSmartPortfolio(false);
                toastr.success('Smart Order berhasil dihapus');
                setTimeout(() => dispatch(invalidateSmartOrderList()), 1000);
              } catch (error) {
                getErrorMessage(error);
              } finally {
                setIsSubmitting(false);
              }
            }}>
            <p className="font-quicksand-semibold text-sm">
              Apakah kamu yakin ingin menghapus order ini?
            </p>
          </ModalDialog>
        </Modal>
      </RenderIf>
    </>
  );
}
function TWOrderlistSmartPortfolio() {
  const [openSmartPortfolioForm, setOpenSmartPortfolioForm] = useState(false);

  return (
    <>
      <SmartPortfolioTable
        onClickSmartPortfolio={(orderDetail, disabledStock, initialForms) =>
          setOpenSmartPortfolioForm({
            orderDetail,
            disabledStock,
            initialForms
          })
        }
      />

      <RenderIf isTrue={openSmartPortfolioForm}>
        <Modal size="large" onClose={() => setOpenSmartPortfolioForm(false)}>
          <SmartOrderForm
            formType="Smart Portfolio"
            initialForms={openSmartPortfolioForm?.initialForms}
            orderDetail={openSmartPortfolioForm?.orderDetail}
            disabledStock={openSmartPortfolioForm?.disabledStock}
            onSuccess={() => setOpenSmartPortfolioForm(false)}
            onClose={() => setOpenSmartPortfolioForm(false)}
          />
        </Modal>
      </RenderIf>
    </>
  );
}

TWOrderlistSmartPortfolio.propTypes = {};

export default TWOrderlistSmartPortfolio;
