import PropTypes from 'prop-types';
import styles from "./css/input-radio-simple.module.css";
function InputRadioSimple({
  options = [],
  onChange = () => {},
  name = 'input-radio-simple',
  value,
  size = 'medium',
  className = 'mb-4'
}) {
  return (
    <div className={`${styles['input-radio-simple']} ${styles[size]} ${className}`}>
      {options?.map((item, key) => (
        <div key={key} className={`${item?.value === value && styles['input-radio-simple--active']}`}>
          <label
            htmlFor={`${name}-${key}`}
            className={`${styles['input-radio-simple--label']}`}>
            <input
              id={`${name}-${key}`}
              type="radio"
              name={name}
              value={item?.value}
              onChange={onChange}
              className="h-0 w-0 absolute opacity-0 appearance-none"
            />
            <div className={`flex items-center dark:text-black-light-1-d ${item?.node && 'mb-3'}`}>
              <span className={`${styles['input-radio-simple--bullet']} ${item?.value === value && 'bg-main-base-l'}`}>
                {item?.value === value && <span className="inline-block bg-white rounded-full h-[8px] w-[8px]"></span>}
              </span>
              {item?.text}
            </div>
            {item?.node}
          </label>
        </div>
      ))}
    </div>
  );
}

InputRadioSimple.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
};

export default InputRadioSimple;
