import RenderIf from 'components/basic/render-if';
import InputSelect from 'components/input/input-select';
import { getYear } from 'date-fns';
import dayjs from 'dayjs';
import { orderBy, range } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetStockFundamentalByCodeQuery } from "services/rtk-query/stock";
import { automatedNumberFormat } from 'utils/format';

function TWFundamental({ symbol, defaultPeriodes = '12M', isSingle = false, maxHeight = '350px' }) {
  const [data, setData] = useState([]);
  const [periods, setPeriods] = useState(range(getYear(new Date()) - 10, getYear(new Date()) + 1, 1)?.map((year) => { return year + '12'}));
  const { data: stockFundamental, isFetching } = useGetStockFundamentalByCodeQuery({code: symbol, params: { periods: periods}}, { refetchOnMountOrArgChange: true });
  const [selectedVal, setSelectedVal] = useState(defaultPeriodes === 'All' ? defaultPeriodes : parseInt(defaultPeriodes) + 'M');

  const pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = '0' + num;
    return num;
  };

  const handleFilter = (e) => {
    let val = e?.target?.value || e;
    let periods = '';

    if (val === 'All') {
      periods = '';
      for (let index = 0; index < 5; index++) {
        periods += `${dayjs().subtract(index, 'year').format('YYYY')}${pad(3 ,2)},${dayjs().subtract(index, 'year').format('YYYY')}${pad(6 ,2)},${dayjs().subtract(index, 'year').format('YYYY')}${pad(12 ,2)}`;
        if (index < 4) {
          periods += ',';
        }
      }
    } else {
      for (let index = 0; index < 5; index++) {
        periods += `${dayjs().subtract(index, 'year').format('YYYY')}${pad(
          parseInt(val),
          2
        )}`;
        if (index < 4) {
          periods += ',';
        }
      }
    }

    setPeriods(periods);
    setSelectedVal(val === 'All' ? val : parseInt(val) + 'M');
  };

  useEffect(() => {
    handleFilter(defaultPeriodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPeriodes])

  useEffect(() => {
    setData(orderBy(stockFundamental, ['period'], ['desc']));
  }, [stockFundamental])

  return (
    <div className="px-3 pb-3">
      <RenderIf isTrue={isSingle}>
        <InputSelect
          name="periods"
          value={selectedVal}
          label={<span className='text-xs text-gray-light-0-d'>Pilih Quarter</span>}
          className="mb-4 text-sm text-gray-light-0-d"
          size='small'
          options={[
            {
              id: '3M',
              name: '3 Month'
            },
            {
              id: '6M',
              name: '6 Month'
            },
            {
              id: '9M',
              name: '9 Month'
            },
            {
              id: '12M',
              name: '12 Month'
            },
            {
              id: 'All',
              name: 'Every Quarter'
            }
          ]}
          onChange={handleFilter}
        />
      </RenderIf>

      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        style={{maxHeight: maxHeight }}>
        <div className="overflow-x-auto block relative rounded-md">
          <table className="text-xs w-full text-center font-quicksand-semibold dark:text-black-light-1-d">
            <thead>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="overflow-auto pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-semibold text-sm bg-main-base-l text-white dark:text-black-base-d">
                  Year
                </td>

                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1" style={{ minWidth: '70px' }}>
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1" style={{ minWidth: '70px' }}>
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1" style={{ minWidth: '70px' }}>
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>
                
                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td
                      key={key}
                      className="px-2 py-1"
                      style={{ minWidth: '70px' }}>
                      {dayjs(item?.period, 'YYYYMM').format('M')}M&nbsp;
                      {dayjs(item?.period, 'YYYYMM').format('YYYY')}
                    </td>
                  ))}
                </RenderIf>
              </tr>
            </thead>
            <tbody>
              {/* KEY INFORMATION */}
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1 font-quicksand-semibold italic">
                    Key&nbsp;Information
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular"></td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Market&nbsp;Cap
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.market_cap)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Price Book Value (PBV)
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.pbv)}x
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Price Earnings Ratio (PER)
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.per)}x
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Return On Assets (ROA) *
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.roa * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Return On Equity (ROE) *
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.roe_equity_to_parent_entity * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Dividend&nbsp;Yield
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.dividen_yield || (item?.details?.dps / item?.details?.harga_saham * 100))}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Earning Per Share (EPS) *
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.earning_per_share)}
                    </td>
                  ))}
                </RenderIf>
              </tr>


              {/* OTHER INFORMATION */}
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1 font-quicksand-semibold italic">
                    Other&nbsp;Information
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular"></td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Account&nbsp;Receivable
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.account_receivable)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Asset&nbsp;Turnover</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.assets_turnover * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Book&nbsp;Value&nbsp;Per&nbsp;Share (BVPS)
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.book_value_per_share)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">BVPS&nbsp;Per&nbsp;Daily</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.bvps_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    BVPS&nbsp;Equity&nbsp;To&nbsp;Parent&nbsp;Entity
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.bvps_equity_to_parent_entity)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    BVPS&nbsp;Equity&nbsp;To&nbsp;Parent&nbsp;Entity&nbsp;Per&nbsp;Daily
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.bvps_equity_to_parent_entity_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Cash&nbsp;Equivalent</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cash_equivalent)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Cash&nbsp;Equivalent&nbsp;Per&nbsp;Share (CEPS)
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cash_equivalent_per_share)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Cash&nbsp;Flow&nbsp;Per&nbsp;Share (CFPS)</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cash_flow_per_share)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Cash&nbsp;From&nbsp;Financing&nbsp;Act
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cash_from_financing_act)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Cash&nbsp;From&nbsp;Investing&nbsp;Act
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cash_from_investing_act)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Cash&nbsp;From&nbsp;Operating&nbsp;Act
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cash_from_operating_act)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Cash&nbsp;Ratio (CR)</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cash_ratio * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">CEPS&nbsp;Per&nbsp;Daily</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.ceps_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">CFPS&nbsp;Per&nbsp;Daily</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cfps_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Cost&nbsp;Of&nbsp;Goods&nbsp;Sold
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.cost_of_goods_sold)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Current&nbsp;Asset</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_asset)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Current&nbsp;BV</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_bv)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Current&nbsp;CEPS</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_ceps)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Current&nbsp;CFPS</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_cfps)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Current&nbsp;EPS</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_eps)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Current&nbsp;EPS&nbsp;TTM
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_eps_ttm)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Current&nbsp;Liabilities
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_liabilities)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Current&nbsp;Navs</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_navs)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Current&nbsp;Ratio</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_ratio * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Current&nbsp;RPS</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.current_rps)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Debt&nbsp;To&nbsp;Equity&nbsp;Ratio
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.debt_to_equity_ratio * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Debt&nbsp;To&nbsp;Parent&nbsp;Entity
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.der_equity_to_parent_entity * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">DPR</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.dpr * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">DPS</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.dps)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Earning&nbsp;Per&nbsp;Share</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.earning_per_share)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Ebit&nbsp;Margin</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.ebit_margin * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              {/* <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">EPS</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.eps)}
                    </td>
                  ))}
                </RenderIf>
              </tr> */}
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">EPS&nbsp;Per&nbsp;Daily</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.eps_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">EPS/Quarter</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.eps_quater)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">EPS/Quarter&nbsp;Per&nbsp;Daily</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.eps_quater_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">EPS/TTM&nbsp;Per&nbsp;Daily</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.eps_ttm_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Equity&nbsp;Multiplier
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.equity_multiplier)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">EV&nbsp;Ebitda</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.ev_ebitda)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Fixed&nbsp;Assset</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.fixed_asset)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Gross&nbsp;Profit</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.gross_profit)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Gross&nbsp;Profit&nbsp;Margin
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.gross_profit_margin * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">ICR</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.icr)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Income&nbsp;Before&nbsp;Tax
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.income_before_tax)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Inventories</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.inventories)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Long&nbsp;Term&nbsp;Debt
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.long_term_debt)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Navs&nbsp;Per&nbsp;Daily</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.navs_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Net&nbsp;Assets&nbsp;Per&nbsp;Share
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.net_assets_per_share)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Net&nbsp;Cashflow&nbsp;Activities
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.net_cash_flow_activities)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Net&nbsp;Income</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.net_income)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Net&nbsp;Income&nbsp;Growth
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.net_income_growth * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Net&nbsp;Profit&nbsp;Margin
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.net_profit_margin * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Operating&nbsp;Expense
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.operating_expense)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Operating&nbsp;Profit
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.operating_profit)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Operating&nbsp;Profit&nbsp;Margin
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.operating_profit_margin * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Opertaion&nbsp;Income&nbsp;Growth
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.opertaion_income_growth * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Other&nbsp;Current&nbsp;Asset
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.other_current_asset)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Other&nbsp;Income&nbsp;Expense
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.other_income_expense)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Outstading&nbsp;Share
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.outstanding_share)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              {/* <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">PBV</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.pbv)}
                    </td>
                  ))}
                </RenderIf>
              </tr> */}
              {/* <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">PER</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.per)}
                    </td>
                  ))}
                </RenderIf>
              </tr> */}
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Price&nbsp;Cashflow&nbsp;Ratio
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.price_cash_flow_ratio * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Price&nbsp;Sales&nbsp;Ratio
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.price_sales_ratio * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Quick&nbsp;Ratio</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.quick_ratio * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Revenue Per Share</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.revenue_per_share)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              {/* <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Roa</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.roa)}
                    </td>
                  ))}
                </RenderIf>
              </tr> */}
              {/* <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Roe</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.roe)}
                    </td>
                  ))}
                </RenderIf>
              </tr> */}
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">RPS&nbsp;Per&nbsp;Daily</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.rps_daily)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Sales/Growth</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.sales_growth * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Subordinasi</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.subordinasi)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Syirkah&nbsp;Temporer&nbsp;Fund
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.syirkah_temporer_fund)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Terbaru&nbsp;Fund</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.tabaru_fund)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Total&nbsp;Asset&nbsp;Growth
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.total_asset_growth * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Total&nbsp;Assets</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.total_assets)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Total&nbsp;Equity</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.total_equity)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Total&nbsp;Equity&nbsp;To&nbsp;Parent&nbsp;Entity
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.total_equity_equity_to_parent_entity)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Total&nbsp;Equity&nbsp;Growth
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.total_equity_growth * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Total&nbsp;Liabilities
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.total_liabilities)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">
                    Total&nbsp;Liabilities&nbsp;Growth
                  </span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.total_liabilities_growth * 100)}%
                    </td>
                  ))}
                </RenderIf>
              </tr>
              <tr>
                <td
                  style={{ maxWidth: '130px' }}
                  className="pl-3 pr-3 py-1 lg:sticky left-0 text-left font-quicksand-regular bg-main-base-l text-white dark:text-black-base-d">
                  <span className="block line-clamp-1">Total&nbsp;Sales</span>
                </td>
                
                <RenderIf isTrue={isFetching}>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="px-2 py-1">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                </RenderIf>

                <RenderIf isTrue={!isFetching}>
                  {data?.map((item, key) => (
                    <td key={key} className="px-2 py-1 font-quicksand-regular">
                      {automatedNumberFormat(item?.details?.total_sales)}
                    </td>
                  ))}
                </RenderIf>
              </tr>
            </tbody>
          </table>
        </div>
      </OverlayScrollbarsComponent>

    </div>
  );
}

TWFundamental.propTypes = {
  symbol: PropTypes.string,
  defaultPeriodes: PropTypes.string,
  isSingle: PropTypes.bool,
  maxHeight: PropTypes.string,
};

export default TWFundamental;
