import PropTypes from 'prop-types';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputDatePickerSimple from 'components/input/input-date-picker-simple';
import InputSelect from 'components/input/input-select';
import InputText from 'components/input/input-text';
import IconScan from 'components/svg/icon-scan';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import {
  useGetMasterDataProvincesQuery,
  useGetOptionsByTypesQuery,
  useLazyGetMasterDataCityByProvinceIdQuery,
  useLazyGetMasterDataDistrictByCityIdQuery,
  useLazyGetMasterDataVillageByDistrictIdQuery
} from 'services/rtk-query/master-data';
import { RDNStep02Validation } from './rdn-step-02.validation';
import { useCreateVerifyKTPMutation } from 'services/rtk-query/verify';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { invalidateUserProfile, useGetUserProfileQuery } from 'services/rtk-query/user';
import { FocusError } from 'focus-formik-error';

import ListStatus from 'components/basic/list-status';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import Card from 'components/basic/card';
import { isEmpty } from 'lodash';
import { ModalContext } from 'components/modal/context/modalContext';
import ModalDataAman from '../../../../../components/modal/template/modal-data-aman';
import { triggerSegmentEvent, triggerSegmentIdentify } from 'lib/segment';
import { useDispatch } from 'react-redux';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import HeaderOnboarding from '../../header-onboarding';
import RenderIf from 'components/basic/render-if';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function RDNStep02DetailKTP({ OCRData, onClickBack = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getErrorMessage } = useGetErrorMessage();
  const { user } = useBrowserTabAuth();

  const { data: userDetail } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const { handleModal } = useContext(ModalContext);

  const { data: options } = useGetOptionsByTypesQuery(['sex', 'martial_status', 'religion']);
  const { data: provinces } = useGetMasterDataProvincesQuery();
  const [getMasterDataCityByProvinceId] = useLazyGetMasterDataCityByProvinceIdQuery();
  const [getMasterDataDistrictByCityId] = useLazyGetMasterDataDistrictByCityIdQuery();
  const [getMasterDataVillageByDistrictId] = useLazyGetMasterDataVillageByDistrictIdQuery();
  const [createVerifyKTP] = useCreateVerifyKTPMutation();

  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      address: '',
      rtrw: '',
      imgKtpUrl: '',
      ktpNo: '',
      ktpIssuer: '',
      fullName: '',
      birthPlace: '',
      birthDate: '',
      gender: '',
      statusKawin: '',
      agama: '',
      province: '',
      city: '',
      kecamatan: '',
      desa: '',
      zipCode: '',
      nationality: '',
      ktpLifetime: '',
      spouseName: ''
    },
    validateOnMount: false,
    validationSchema: RDNStep02Validation,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        let payloads = {
          imgKtpUrl: values?.imgKtpUrl,
          ktpNo: values?.ktpNo,
          ktpIssuer: values?.ktpIssuer,
          fullName: values?.fullName,
          birthDate: dayjs(values?.birthDate).format('DD/MM/YYYY'),
          birthPlace: values?.birthPlace,
          nationality: values?.nationality,
          address: values?.address,
          rtrw: values?.rtrw,
          zipCode: values?.zipCode,
          gender: values?.gender,
          maritalStatus: values?.statusKawin,
          spouseName: values?.spouseName,
          religion: values?.agama,
          provinceId: values?.province,
          cityId: values?.city,
          kecId: values?.kecamatan,
          kelId: values?.desa,
          countryId: 102,
          aliasName: ''
        }

        if (dayjs(values?.ktpLifetime, 'DD/MM/YYYY').isValid()) {
          payloads.ktpIssuedDate = dayjs(values?.ktpLifetime).format('DD/MM/YYYY');
        }

        await createVerifyKTP(payloads).unwrap();
        dispatch(invalidateUserProfile());
        navigate('/onboarding/informasi-tambahan');
        
        triggerSegmentEvent({
          event: 'TR KTP Address Confirmed'
        });

        triggerSegmentEvent({
          event: 'TR KTP Data Confirmed'
        });

        triggerSegmentIdentify({
          userId: user?.id,
          properties: { Name: values?.fullName }
        });
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (userDetail && userDetail?.isKtpVerified) navigate('/onboarding/informasi-tambahan');

    if (userDetail && provinces?.length > 0 && options?.length > 0 && userDetail?.fillState > 1) {

      let fullName = userDetail['firstName'];
      if (userDetail['middleName']) fullName += ' ' + userDetail['middleName']
      if (userDetail['lastName']) fullName += ' ' + userDetail['lastName']

      let _provinceId = '';
      let _cityId = '';
      let kecamatanId = '';
      let desaId = '';

      provinces?.map(async (province) => {
        if (province?.id === Number(userDetail?.provinceId)) {
          _provinceId = province?.id;
          formik.setFieldValue('province', _provinceId);
          // AUTOFILL PROVINCE USER

          const _cities = await getMasterDataCityByProvinceId(_provinceId).unwrap();
          setCities(_cities);

          _cities?.map(async (city) => {
            if (city?.id === Number(userDetail?.cityId)) {
              _cityId = city?.id;
              formik.setFieldValue('city', _cityId);
              // AUTOFILL CITY USER

              const _districts = await getMasterDataDistrictByCityId(_cityId).unwrap();
              setDistricts(_districts);

              _districts?.map(async (kecamatan) => {
                if (kecamatan?.id === Number(userDetail?.kecId)) {
                  kecamatanId = kecamatan?.id;
                  formik.setFieldValue('kecamatan', kecamatanId);
                  // AUTOFILL KECAMATAN USER

                  const _villages = await getMasterDataVillageByDistrictId(kecamatanId).unwrap();
                  setVillages(_villages);

                  _villages?.map(async (village) => {
                    if (village?.id === Number(userDetail?.kelId)) {
                      desaId = village?.id;
                      formik.setFieldValue('desa', desaId);
                      // AUTOFILL DESA/KELURAHAN USER
                    }
                  });
                }
              });
            }
          });
        }
      });

      formik.setValues({
        imgKtpUrl: userDetail?.imgKtpUrl,
        ktpNo: userDetail?.ktpNo || '',
        ktpIssuer: userDetail?.ktpIssuer || '',
        fullName: fullName || '',
        gender: Number(userDetail?.gender) || undefined,
        birthPlace: userDetail?.birthPlace || '',
        address: userDetail?.address || '',
        rtrw: userDetail?.rtrw || '',
        province: userDetail?.provinceId || undefined,
        zipCode: userDetail?.zipCode || '',
        birthDate: userDetail?.birthDate ? dayjs(userDetail?.birthDate, 'YYYY-MM-DD').toDate() : undefined,
        statusKawin: Number(userDetail?.maritalStatus) || undefined,
        spouseName: userDetail?.spouseName || undefined,
        agama: Number(userDetail?.religion) || undefined,
      })

      setTimeout(() => formik.setErrors({}), 300);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail, navigate, provinces, options]);

  useEffect(() => {
    if (!isEmpty(OCRData) && provinces?.length > 0 && options?.length > 0) {
      const { data: initialData } = OCRData; 

      Object.keys(initialData)?.map((obj) => {
        let value = initialData?.[obj];
        if (!value) return;
        let _obj = obj;

        if (_obj === 'birthDate') {
          if (dayjs(value, 'DD-MM-YYYY').isValid()) {
            value = dayjs(value, 'DD-MM-YYYY').toDate();
          } else {
            value = undefined;
          }
        }

        if (['gender', 'statusKawin', 'agama'].includes(_obj)) {
          options?.map((item) => {
            if (item?.name?.toUpperCase() == String(value)?.toUpperCase()) {
              value = item?.id
            }
          })
        }

        if (['firstName', 'middleName', 'lastName'].includes(_obj)) {
          let fullName = initialData['firstName'];
          if (initialData['middleName']) fullName += ' ' + initialData['middleName']
          if (initialData['lastName']) fullName += ' ' + initialData['lastName']

          _obj = 'fullName'
          value = fullName;
        }

        if (_obj === 'province') {
          let _provinceId = '';
          let _cityId = '';
          let kecamatanId = '';
          let desaId = '';

          provinces?.map(async (province) => {
            if (province?.name?.toUpperCase() === String(value).toUpperCase().replace('PROVINSI ', '')) {
              _provinceId = province?.id;
              formik.setFieldValue('province', _provinceId);
              // AUTOFILL PROVINCE USER

              const _cities = await getMasterDataCityByProvinceId(_provinceId).unwrap();
              setCities(_cities);

              _cities?.map(async (city) => {
                if (city?.name?.toUpperCase() === String(initialData['city']).toUpperCase().replace('KABUPATEN ', '').replace('KOTA ', '')) {
                  _cityId = city?.id;
                  formik.setFieldValue('city', _cityId);
                  // AUTOFILL CITY USER

                  const _districts = await getMasterDataDistrictByCityId(_cityId).unwrap();
                  setDistricts(_districts);

                  _districts?.map(async (kecamatan) => {
                    if (kecamatan?.name?.toUpperCase() === String(initialData['kecamatan']).toUpperCase().replace('KECAMATAN ', '')) {
                      kecamatanId = kecamatan?.id;
                      formik.setFieldValue('kecamatan', kecamatanId);
                      // AUTOFILL KECAMATAN USER

                      const _villages = await getMasterDataVillageByDistrictId(kecamatanId).unwrap();
                      setVillages(_villages);

                      _villages?.map(async (village) => {
                        if (village?.name?.toUpperCase() === String(initialData['desa']).toUpperCase().replace('DESA ', '')) {
                          desaId = village?.id;
                          formik.setFieldValue('desa', desaId);
                          // AUTOFILL DESA/KELURAHAN USER
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        } else {
          if (!['kecamatan', 'city', 'province', 'desa'].includes(_obj)) {
            formik.setFieldValue(_obj, value);
          }
        }
      })

      formik.setFieldValue('imgKtpUrl', OCRData?.fileUrl);
      setTimeout(() => formik.setErrors({}), 300);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OCRData, provinces, options])

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:py-16 text-white relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto">
          <HeaderOnboarding activeStep={2} />

          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] my-3 text-white">
            Informasi KTP
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Dokumen yang kamu unggah tersimpan dan terlindungi dengan aman.
          </p>

          <button
            type="button"
            onClick={() => handleModal(<ModalDataAman />)}
            className="text-sm lg:text-base px-3 py-1 border rounded-3xl border-white mb-6">
            Apakah data saya aman?
          </button>

          <ListStatus
            data={[
              {
                title: 'Upload KTP',
                subtitle: null,
                status: true
              },
              {
                title: 'Informasi KTP',
                subtitle: null,
                status: false
              },
              {
                title: 'Selfie dengan KTP',
                subtitle: null,
                status: false
              },
            ]}
          />
        </div>
      </div>

      <div className='p-0 lg:px-28 lg:py-10'>
        <Card className="lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto pt-4 relative lg:max-h-[calc(90vh)] overflow-auto mb-24 lg:mb-0">
          <form className='px-6 lg:px-8' autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <FocusError formik={formik} />
            <div className='border p-3 bg-gray-light-l text-gray-light-0-d dark:bg-black-dark-d my-3 text-xs rounded-xl mx-auto flex'>
              <IconScan className="h-4 w-4 mr-1 flex-none" />
              <span className='flex-auto'>
                Informasi di bawah diambil dengan teknologi Optical Character Recognition
              </span>
            </div>

            <h5 className="my-4 text-sm">Informasi Dokumen</h5>

            <InputText
              value={formik?.values?.ktpNo}
              name="ktpNo"
              placeholder="Nomor KTP Kamu"
              label="Nomor KTP"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.ktpNo ? formik.errors.ktpNo : ''}
            />

            <InputText
              value={formik?.values?.ktpIssuer}
              name="ktpIssuer"
              placeholder="Tempat Pembuatan KTP"
              label="Tempat Pembuatan KTP"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.ktpIssuer ? formik.errors.ktpIssuer : ''}
            />

            <hr className="my-6" />
            <h5 className="mb-4 text-sm">Informasi Pribadi</h5>

            <InputText
              value={formik?.values?.fullName}
              name="fullName"
              placeholder="Nama Lengkap Kamu"
              label="Nama Lengkap"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.fullName ? formik.errors.fullName : ''}
            />

            <InputSelect
              value={formik?.values?.gender}
              label="Jenis Kelamin"
              name='gender'
              className="mb-4"
              options={options?.filter(({ type }) => type === 'sex')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.gender ? formik.errors.gender : ''}
            />

            <InputText
              value={formik?.values?.birthPlace}
              name="birthPlace"
              placeholder="Tempat Lahir Kamu"
              label="Tempat Lahir"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.birthPlace ? formik.errors.birthPlace : ''}
            />

            <InputDatePickerSimple
              value={formik.values.birthDate}
              currentDate={null}
              name="birthDate"
              filterDate={() => true }
              format='dd-MM-yyyy'
              label="Tanggal Lahir"
              placeholder='Pilih Tanggal Lahir'
              onChange={(_date) => {
                formik.setFieldValue('birthDate', _date);
              }}
              error={formik.errors.birthDate ? formik.errors.birthDate : ''}
            />

            <InputSelect
              value={formik?.values?.statusKawin}
              name='statusKawin'
              label="Status Perkawinan"
              className="mb-4"
              options={options?.filter(({ type }) => type === 'martial_status')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.statusKawin ? formik.errors.statusKawin : ''}
            />

            <RenderIf isTrue={Number(formik?.values?.statusKawin) === 53}>
              <InputText
                value={formik?.values?.spouseName}
                name="spouseName"
                placeholder="Nama Pasangan Kamu"
                label="Nama Pasangan"
                className="mb-4"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.errors.spouseName ? formik.errors.spouseName : ''}
              />
            </RenderIf>

            <InputSelect
              value={formik?.values?.agama}
              name='agama'
              label="Agama"
              className="mb-4"
              options={options?.filter(({ type }) => type === 'religion')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.agama ? formik.errors.agama : ''}
            />

            <hr className="my-6" />
            <h5 className="mb-4 text-sm">Tempat Tinggal</h5>

            <InputText
              value={formik?.values?.address}
              name="address"
              placeholder="Alamat Tempat Tinggal Kamu"
              label="Alamat Tempat Tinggal"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.address ? formik.errors.address : ''}
            />

            <InputText
              value={formik?.values?.rtrw}
              name="rtrw"
              placeholder="contoh : 001/002"
              label="RT/RW"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.rtrw ? formik.errors.rtrw : ''}
            />

            <InputSelect
              value={formik?.values?.province}
              name='province'
              label="Provinsi"
              className="mb-4"
              options={provinces}
              onBlur={formik.handleBlur}
              onChange={async (e) => {
                formik.handleChange(e);
                setDistricts([]);
                setVillages([]);
                const _cities = await getMasterDataCityByProvinceId(e.target.value).unwrap();
                setCities(_cities);
              }}
              error={formik.errors.province ? formik.errors.province : ''}
            />

            <InputSelect
              value={formik?.values?.city}
              name='city'
              label="Kota/Kabupaten"
              className="mb-4"
              options={cities}
              disabled={cities?.length === 0}
              onBlur={formik.handleBlur}
              onChange={async (e) => {
                formik.handleChange(e);
                setVillages([]);
                const _districts = await getMasterDataDistrictByCityId(e.target.value).unwrap();
                setDistricts(_districts);
              }}
              error={formik.errors.city ? formik.errors.city : ''}
            />

            <InputSelect
              value={formik?.values?.kecamatan}
              name='kecamatan'
              label="Kecamatan"
              className="mb-4"
              options={districts}
              disabled={districts?.length === 0}
              onBlur={formik.handleBlur}
              onChange={async (e) => {
                formik.handleChange(e);
                const _villages = await getMasterDataVillageByDistrictId(e.target.value).unwrap();
                setVillages(_villages);
              }}
              error={formik.errors.kecamatan ? formik.errors.kecamatan : ''}
            />

            <InputSelect
              value={formik?.values?.desa}
              name='desa'
              label="Desa/Kelurahan"
              className="mb-4"
              options={villages}
              disabled={villages?.length === 0}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.desa ? formik.errors.desa : ''}
            />

            <InputText
              value={formik?.values?.zipCode}
              name="zipCode"
              placeholder="Kode Pos Alamat Kamu"
              label="Kode Pos"
              className="mb-4"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.zipCode ? formik.errors.zipCode : ''}
            />

            <div className="mt-10 fixed lg:sticky bottom-0 bg-white z-10 p-6 lg:p-0 lg:pb-8 lg:pt-4 left-0 w-full border-t">
              <div className='-mx-2 flex justify-center'>
                <button
                  type="button"
                  onClick={onClickBack}
                  className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 border-2 bg-white border-main-gold-d text-main-gold-d flex px-10 items-center justify-center font-quicksand-semibold font-bold text-xs h-11 mx-2 w-full rounded-[10px] overflow-hidden focus:outline-none`}>
                  Kembali
                </button>

                <button
                  type="submit"
                  disabled={!formik.isValid || isSubmitting}
                  className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 flex px-10 items-center justify-center font-quicksand-semibold font-bold text-xs h-11 mx-2 w-full rounded-[10px] overflow-hidden focus:outline-none ${
                    formik.isValid
                      ? 'bg-main-gold-d text-white'
                      : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                  }`}>
                  {isSubmitting && <SimpleSpinner />}
                  Selanjutnya
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

RDNStep02DetailKTP.propTypes = {
  OCRData: PropTypes.object,
  onClickBack: PropTypes.func,
};

export default RDNStep02DetailKTP;
