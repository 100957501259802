import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { widget } from 'assets/library/charting_library';
import useDarkMode from 'hooks/useDarkMode';
import { useGetTradingViewChartsQuery } from 'services/rtk-query/tradingview';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { triggerSegmentEvent } from 'lib/segment';
import DataFeed from './tv-api-chart-advanced';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

let tvAdvancedWidget = {};
let tempSymbol = {};

function TVRealtimeChartAdvanced({
  symbol = 'BBCA',
  interval = '1D',
  libraryPath = '/assets/scripts/trading-view/charting_library/',
  chartsStorageApiVersion = 'light',
  clientId = '',
  userId = '',
  fullscreen = false,
  autosize = true,
  disabled_features = ['use_localstorage_for_settings', 'save_chart_properties_to_local_storage'],
  studiesOverrides = {},
  overrides = {},
  isRerender = false,
  idWidget = 'global',
  onChangeSymbol = () => {},
}) {
  const { customNavigate } = useCustomNavigate();
  const ref = useRef(null);
  const { theme } = useDarkMode();
  const { isLogin } = useBrowserTabAuth();
  const [firstRender, setFirstRender] = useState(false);
  const { isLoadingStock } = useRealtimeStock(symbol, false);
  const { data, isLoading: isLoadingChart } = useGetTradingViewChartsQuery({ client: clientId, user: userId }, { refetchOnMountOrArgChange: true });
  const isLoading = isLoadingStock || isLoadingChart;

  const renderCustomButton = () => {
    const buttonSimpleOrder = tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.createButton();
    const buttonFastOrder = tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.createButton();
    
    const style = {
      simple: {
        dark: 'font-size: 11px; border-radius: 8px; background: #1C1C1E; border: 1px solid #FFAE75; cursor: pointer; color: #FFAE75; height: 70%; display: flex; align-items: center; justify-content: center;',
        light: 'font-size: 11px; border-radius: 8px; background: #FFFFFF; border: 1px solid #FFAE75; cursor: pointer; color: #FFAE75; height: 70%; display: flex; align-items: center; justify-content: center;',
      },
      fast: {
        dark: 'font-size: 11px; border-radius: 8px; background: #1C1C1E; border: 1px solid #FFAE75; cursor: pointer; color: #FFAE75; height: 70%; display: flex; align-items: center; justify-content: center;',
        light: 'font-size: 11px; border-radius: 8px; background: #FFFFFF; border: 1px solid #FFAE75; cursor: pointer; color: #FFAE75; height: 70%; display: flex; align-items: center; justify-content: center;',
      },
    };

    buttonSimpleOrder.setAttribute('title', 'Simple Order');
    buttonSimpleOrder.setAttribute('style', style.simple[theme]);
    buttonSimpleOrder.textContent = 'Simple Order';

    buttonFastOrder.setAttribute('title', 'Fast Order');
    buttonFastOrder.setAttribute('style', style.fast[theme]);
    buttonFastOrder.textContent = 'Fast Order';
    
    buttonSimpleOrder.addEventListener('click', function () {
      customNavigate({ query: { 'simple-order': tempSymbol[symbol] }, isPushQuery: true});
      triggerSegmentEvent({ event: 'TR Simple Buy Clicked' });
    });

    buttonFastOrder.addEventListener('click', function () {
      customNavigate({ query: { 'fast-order': tempSymbol[symbol] }, isPushQuery: true});
      triggerSegmentEvent({ event: 'TR Multi Buy Clicked' });
    });
  };

  let widgetOptions = {
    debug: false,
    symbol: symbol,
    theme,
    datafeed: DataFeed(idWidget),
    interval: interval,
    library_path: libraryPath,
    locale: 'id_ID',
    timezone: 'Asia/Jakarta',
    disabled_features: disabled_features,
    enabled_features: ['study_templates', 'two_character_bar_marks_labels'],
    charts_storage_url: 'https://api.pina.id/api/tradingview',
    charts_storage_api_version: theme === 'dark' ? 'dark' : chartsStorageApiVersion,
    client_id: clientId,
    user_id: userId,
    custom_css_url: '/assets/styles/custom-tradingview-themed.css',
    custom_font_family: "'Quicksand', sans-serif",
    toolbar_bg: theme === 'dark' ? '#1C1C1E' : '#FFFFFF',
    fullscreen: fullscreen,
    autosize: autosize,
    studies_overrides: studiesOverrides,
    overrides: overrides,
    
    load_last_chart: true,
    auto_save_delay: 1,
    loading_screen: { backgroundColor: theme === 'dark' ? '#1C1C1E' : '#FFFFFF' },
    // compare_symbols: [
    //   { symbol: 'DAL', title: 'Delta Air Lines' },
    //   { symbol: 'VZ', title: 'Verizon' },
    // ],
    time_frames: [
      {
        text: '15y',
        resolution: '1W'
      },
      {
        text: '10y',
        resolution: '3D'
      },
      {
        text: '5y',
        resolution: '1D'
      },
      {
        text: '3y',
        resolution: '1D'
      },
      {
        text: '1y',
        resolution: '1D'
      },
      {
        text: '6m',
        resolution: '1D'
      },
      {
        text: '3m',
        resolution: '1D'
      },
      {
        text: '1m',
        resolution: '1D'
      },
      {
        text: '5d',
        resolution: '1D'
      },
      {
        text: '1d',
        resolution: '1'
      }
    ],
    symbol_search_complete: (_symbol, searchResultItem) => {
      return new Promise((resolve) => {
        const findSymbol = window?.LIST_SYMBOL?.find((item) => item?.name === _symbol || item?.symbol === _symbol);
        resolve(searchResultItem || findSymbol);
        if (searchResultItem) {
          tempSymbol[symbol] = searchResultItem?.symbol;
          setTimeout(() => onChangeSymbol(searchResultItem?.symbol), 1000);
        }
      });
    },
    // compare_symbols: [
    //   { symbol: 'PTBA', title: 'Bukit Asam Tbk.' },
    //   { symbol: 'ANTM', title: 'Aneka Tambang Tbk.' },
    // ],
    // eslint-disable-next-line no-unused-vars
    custom_indicators_getter: function (PineJS) {
      return Promise.resolve([
        // {
        //   name: 'Fractal Engulfing Pattern With Allowance',
        //   metainfo: {
        //     _metainfoVersion: 51,
        //     defaults: {
        //       inputs: {
        //         LookBack: 10,
        //         Allowance: 0
        //       }
        //     },
        //     inputs: [
        //       {
        //         id: 'LookBack',
        //         name: 'LookBack',
        //         title: 'Lookback Period',
        //         type: 'integer',
        //         defval: 10,
        //         visible: true,
        //         isHidden: false
        //       },
        //       {
        //         id: 'Allowance',
        //         name: 'Allowance',
        //         title: 'Filter Allowance',
        //         type: 'integer',
        //         defval: 0,
        //         visible: true,
        //         isHidden: false
        //       }
        //     ],
        //     plots: [
        //       {
        //         id: 'plot_0',
        //         type: 'shapes'
        //       },
        //       {
        //         id: 'plot_1',
        //         type: 'shapes'
        //       },
        //     ],
        //     styles: {
        //       plot_0: {
        //         color: 'red',
        //         location: 'AboveBar',
        //         visible: true,
        //         isHidden: false,
        //         text: '',
        //         title: 'Bearish Engulfing',
        //         plottype: 'shape_triangle_down',
        //         size: 'small'
        //       },
        //       plot_1: {
        //         color: 'green',
        //         location: 'BelowBar',
        //         visible: true,
        //         isHidden: false,
        //         text: '',
        //         title: 'Bullish Engulfing',
        //         plottype: 'shape_triangle_up',
        //         size: 'small'
        //       },
        //     },
        //     description: 'Fractal Engulfing Pattern With Allowance',
        //     shortDescription: 'Fractal Engulfing Pattern With Allowance',
        //     is_price_study: false,
        //     linkedToSeries: false,
        //     isCustomIndicator: true,
        //     id: 'Fractal Engulfing Pattern With Allowance',
        //     format: {
        //       type: 'inherit'
        //     }
        //   },
        //   constructor: function () {
        //     this.main = function (context, inputCallback) {
        //       this._context = context;
        //       this._input = inputCallback;
        //       const LookBack = inputCallback(0) || 10;
        //       const Allowance = inputCallback(1);
        //       const PointAllowance = this._context.symbol.minTick * Allowance;
        //       const open = PineJS.Std.open(this._context);
        //       const high = PineJS.Std.high(this._context);
        //       const low = PineJS.Std.low(this._context);
        //       const close = PineJS.Std.close(this._context);
        //       const data = window.barsAdvanced[context.symbol.ticker];
        //       const seriesToCompare = data?.filter((item, key) => key >= this._context.symbol.index && key <= (this._context.symbol.index + LookBack)) ;
        //       const swingHigh = high == maxBy(seriesToCompare, 'high')?.high || data?.[this._context.symbol.index - 1]?.high == maxBy(seriesToCompare, 'high')?.high;
        //       const swingLow = low == minBy(seriesToCompare, 'low')?.low || data?.[this._context.symbol.index - 1]?.low == minBy(seriesToCompare, 'low')?.low;
        //       const Bullish_Engulfing = (data?.[this._context.symbol.index - 1]?.open > data?.[this._context.symbol.index - 1]?.close) && (open <= (data?.[this._context.symbol.index - 1]?.close + PointAllowance)) && (close >= (data?.[this._context.symbol.index - 1]?.open + PointAllowance)) && swingLow;
        //       const Bearish_Engulfing = (data?.[this._context.symbol.index - 1]?.open < data?.[this._context.symbol.index - 1]?.close) && (open >= (data?.[this._context.symbol.index - 1]?.close - PointAllowance)) && (close <= (data?.[this._context.symbol.index - 1]?.open + PointAllowance)) && swingHigh;
        //       return [Bearish_Engulfing ? 1 : 0, Bullish_Engulfing ? 1 : 0];
        //     };
        //   }
        // }
        //     {
        //       name: 'Bar Colorer Demo',
        //       metainfo: {
        //         _metainfoVersion: 51,
        //         id: 'BarColoring@tv-basicstudies-1',
        //         name: 'BarColoring',
        //         description: 'Bar Colorer Demo',
        //         shortDescription: 'Bar Coloring',
        //         isCustomIndicator: true,
        //         is_price_study: true,
        //         format: {
        //           type: 'price',
        //           precision: 4
        //         },
        //         defaults: {
        //           palettes: {
        //             palette_0: {
        //               // palette colors
        //               // change it to the default colors that you prefer,
        //               // but note that the user can change them in the Style tab
        //               // of indicator properties
        //               colors: [{ color: '#FFFF00' }, { color: '#0000FF' }]
        //             }
        //           }
        //         },
        //         inputs: [],
        //         plots: [
        //           {
        //             id: 'plot_0',
        //             // plot type should be set to 'bar_colorer'
        //             type: 'bar_colorer',
        //             // this is the name of the palette that is defined
        //             // in 'palettes' and 'defaults.palettes' sections
        //             palette: 'palette_0'
        //           }
        //         ],
        //         palettes: {
        //           palette_0: {
        //             colors: [{ name: 'Color 0' }, { name: 'Color 1' }],
        //             // the mapping between the values that
        //             // are returned by the script and palette colors
        //             valToIndex: {
        //               100: 0,
        //               200: 1
        //             }
        //           }
        //         }
        //       },
        //       constructor: function () {
        //         this.main = function (context, input) {
        //           this._context = context;
        //           this._input = input;
        //           var valueForColor0 = 100;
        //           var valueForColor1 = 200;
        //           // perform your calculations here and return one of the constants
        //           // that is specified as a key in 'valToIndex' mapping
        //           var result =
        //             (Math.random() * 100) % 2 > 1 // we randomly select one of the color values
        //               ? valueForColor0
        //               : valueForColor1;
        //           return [result];
        //         };
        //       }
        //     },
        //     {
        //       name: 'Custom Styles For Every Point',
        //       metainfo: {
        //         _metainfoVersion: 51,
        //         id: 'CustomStylesForEveryPoint@tv-basicstudies-1',
        //         description: 'Custom Styles For Every Point',
        //         shortDescription: 'Custom Styles For Every Point',
        //         is_price_study: false,
        //         isCustomIndicator: true,
        //         plots: [
        //           {
        //             id: 'plot_0',
        //             type: 'line'
        //           },
        //           {
        //             id: 'plot_1',
        //             type: 'colorer',
        //             target: 'plot_0',
        //             palette: 'paletteId1'
        //           }
        //         ],
        //         palettes: {
        //           paletteId1: {
        //             colors: {
        //               0: {
        //                 name: 'First color'
        //               },
        //               1: {
        //                 name: 'Second color'
        //               }
        //             }
        //           }
        //         },
        //         defaults: {
        //           palettes: {
        //             paletteId1: {
        //               colors: {
        //                 0: {
        //                   color: 'red',
        //                   width: 1,
        //                   style: 0
        //                 },
        //                 1: {
        //                   color: 'blue',
        //                   width: 3,
        //                   style: 1
        //                 }
        //               }
        //             }
        //           },
        //           styles: {},
        //           precision: 4,
        //           inputs: {}
        //         },
        //         styles: {
        //           plot_0: {
        //             title: 'Equity value',
        //             histogramBase: 0
        //           }
        //         },
        //         inputs: [],
        //         format: {
        //           type: 'price',
        //           precision: 4
        //         }
        //       },
        //       constructor: function () {
        //         this.main = function (context, inputCallback) {
        //           this._context = context;
        //           this._input = inputCallback;
        //           const value = Math.random() * 200;
        //           const colorIndex = value > 100 ? 0 : 1;
        //           return [value, colorIndex];
        //         };
        //       }
        //     },
        //     {
        //       name: 'Complex Filled Areas',
        //       metainfo: {
        //         _metainfoVersion: 51,
        //         id: 'ComplexFilledAreas@tv-basicstudies-1',
        //         description: 'Complex Filled Areas',
        //         shortDescription: 'Complex Filled Areas',
        //         is_price_study: false,
        //         isCustomIndicator: true,
        //         plots: [
        //           {
        //             id: 'plot_0',
        //             type: 'line'
        //           },
        //           {
        //             id: 'plot_1',
        //             type: 'line'
        //           },
        //           {
        //             id: 'plot_2',
        //             type: 'colorer',
        //             target: 'filledAreaId1',
        //             palette: 'paletteId1'
        //           }
        //         ],
        //         filledAreas: [
        //           {
        //             id: 'filledAreaId1',
        //             objAId: 'plot_0',
        //             objBId: 'plot_1',
        //             title: 'Filled area between first and second plot',
        //             type: 'plot_plot',
        //             palette: 'paletteId1'
        //           }
        //         ],
        //         palettes: {
        //           paletteId1: {
        //             valToIndex: {
        //               0: 0,
        //               1: 1
        //             },
        //             colors: {
        //               0: {
        //                 name: 'First color'
        //               },
        //               1: {
        //                 name: 'Second color'
        //               }
        //             }
        //           }
        //         },
        //         defaults: {
        //           filledAreasStyle: {
        //             filledAreaId1: {
        //               color: 'yellow',
        //               visible: true,
        //               transparency: 40
        //             }
        //           },
        //           palettes: {
        //             paletteId1: {
        //               colors: {
        //                 0: {
        //                   color: 'red',
        //                   width: 1,
        //                   style: 0
        //                 },
        //                 1: {
        //                   color: 'blue',
        //                   width: 3,
        //                   style: 1
        //                 }
        //               }
        //             }
        //           },
        //           styles: {
        //             plot_0: {
        //               linestyle: 0,
        //               visible: true,
        //               linewidth: 1,
        //               plottype: 2,
        //               trackPrice: true,
        //               color: 'blue'
        //             },
        //             plot_1: {
        //               linestyle: 1,
        //               visible: true,
        //               linewidth: 2,
        //               plottype: 2,
        //               trackPrice: true,
        //               color: 'red'
        //             }
        //           },
        //           precision: 4,
        //           inputs: {}
        //         },
        //         styles: {
        //           plot_0: {
        //             title: 'First plot',
        //             histogramBase: 0
        //           },
        //           plot_1: {
        //             title: 'Second plot',
        //             histogramBase: 0
        //           }
        //         },
        //         inputs: [],
        //         format: {
        //           type: 'price',
        //           precision: 4
        //         }
        //       },
        //       constructor: function () {
        //         this.main = function (context, inputCallback) {
        //           this._context = context;
        //           this._input = inputCallback;
        //           var value = Math.random() * 200 - 100;
        //           var colorIndex = value > 0 ? 0 : 1;
        //           return [0, value, colorIndex];
        //         };
        //       }
        //     },
        //     {
        //       name: 'Mondays',
        //       metainfo: {
        //         _metainfoVersion: 51,
        //         defaults: {
        //           styles: {
        //             plot_0: {
        //               color: '#FF5252',
        //               textColor: '#2196F3',
        //               plottype: 'shape_circle',
        //               location: 'Bottom',
        //               visible: true
        //             }
        //           },
        //           inputs: {}
        //         },
        //         plots: [
        //           {
        //             id: 'plot_0',
        //             type: 'shapes'
        //           }
        //         ],
        //         styles: {
        //           plot_0: {
        //             isHidden: false,
        //             location: 'Bottom',
        //             text: 'Monday',
        //             title: 'Shapes'
        //           }
        //         },
        //         description: 'Mondays',
        //         shortDescription: 'Mondays',
        //         is_price_study: true,
        //         inputs: [],
        //         id: 'Mondays@tv-basicstudies-1',
        //         format: {
        //           type: 'inherit'
        //         }
        //       },
        //       constructor: function () {
        //         this.main = function (context, inputCallback) {
        //           // If we don't have a time, then we cannot determine the day of week
        //           if (isNaN(context.symbol.time)) {
        //             return [NaN];
        //           }
        //           // Check if the day of the week is Monday
        //           const dayofweek = PineJS.Std.dayofweek(context);
        //           const shouldBeShapeVisible = dayofweek === 2;
        //           // 1 is plot value, it'll be displayed in legend of the indicator
        //           // NaN means that there is no value for that plot and shape should be hidden for that bar
        //           const plotValue = shouldBeShapeVisible ? 1 : NaN;
        //           return [plotValue];
        //         };
        //       }
        //     },
        //     {
        //       name: 'Advanced Coloring Candles',
        //       metainfo: {
        //         _metainfoVersion: 51,
        //         id: 'advancedcolouringcandles@tv-basicstudies-1',
        //         name: 'Advanced Coloring Candles',
        //         description: 'Advanced Coloring Candles',
        //         shortDescription: 'Advanced Coloring Candles',
        //         isCustomIndicator: true,
        //         is_price_study: false, // whether the study should appear on the main series pane.
        //         linkedToSeries: true, // whether the study price scale should be the same as the main series one.
        //         format: {
        //           type: 'price',
        //           precision: 2
        //         },
        //         plots: [
        //           {
        //             id: 'plot_open',
        //             type: 'ohlc_open',
        //             target: 'plot_candle'
        //           },
        //           {
        //             id: 'plot_high',
        //             type: 'ohlc_high',
        //             target: 'plot_candle'
        //           },
        //           {
        //             id: 'plot_low',
        //             type: 'ohlc_low',
        //             target: 'plot_candle'
        //           },
        //           {
        //             id: 'plot_close',
        //             type: 'ohlc_close',
        //             target: 'plot_candle'
        //           },
        //           {
        //             id: 'plot_bar_color',
        //             type: 'ohlc_colorer',
        //             palette: 'palette_bar',
        //             target: 'plot_candle'
        //           },
        //           {
        //             id: 'plot_wick_color',
        //             type: 'wick_colorer',
        //             palette: 'palette_wick',
        //             target: 'plot_candle'
        //           },
        //           {
        //             id: 'plot_border_color',
        //             type: 'border_colorer',
        //             palette: 'palette_border',
        //             target: 'plot_candle'
        //           }
        //         ],
        //         palettes: {
        //           palette_bar: {
        //             colors: [{ name: 'Colour One' }, { name: 'Colour Two' }],
        //             valToIndex: {
        //               0: 0,
        //               1: 1
        //             }
        //           },
        //           palette_wick: {
        //             colors: [{ name: 'Colour One' }, { name: 'Colour Two' }],
        //             valToIndex: {
        //               0: 0,
        //               1: 1
        //             }
        //           },
        //           palette_border: {
        //             colors: [{ name: 'Colour One' }, { name: 'Colour Two' }],
        //             valToIndex: {
        //               0: 0,
        //               1: 1
        //             }
        //           }
        //         },
        //         ohlcPlots: {
        //           plot_candle: {
        //             title: 'Candles'
        //           }
        //         },
        //         defaults: {
        //           ohlcPlots: {
        //             plot_candle: {
        //               borderColor: '#000000',
        //               color: '#000000',
        //               drawBorder: true,
        //               drawWick: true,
        //               plottype: 'ohlc_candles',
        //               visible: true,
        //               wickColor: '#000000'
        //             }
        //           },
        //           palettes: {
        //             palette_bar: {
        //               colors: [
        //                 { color: '#1948CC', width: 1, style: 0 },
        //                 { color: '#F47D02', width: 1, style: 0 }
        //               ]
        //             },
        //             palette_wick: {
        //               colors: [{ color: '#0C3299' }, { color: '#E65000' }]
        //             },
        //             palette_border: {
        //               colors: [{ color: '#5B9CF6' }, { color: '#FFB74D' }]
        //             }
        //           },
        //           precision: 2,
        //           inputs: {}
        //         },
        //         styles: {},
        //         inputs: []
        //       },
        //       constructor: function () {
        //         this.main = function (context, inputCallback) {
        //           this._context = context;
        //           this._input = inputCallback;
        //           this._context.select_sym(0);
        //           const o = PineJS.Std.open(this._context);
        //           const h = PineJS.Std.high(this._context);
        //           const l = PineJS.Std.low(this._context);
        //           const c = PineJS.Std.close(this._context);
        //           // Color is determined randomly
        //           const colour = Math.round(Math.random());
        //           return [
        //             o,
        //             h,
        //             l,
        //             c,
        //             colour /*bar*/,
        //             colour /*wick*/,
        //             colour /*border*/
        //           ];
        //         };
        //       }
        //     },
      ]);
    }
  };

  if (theme === 'dark') {
    widgetOptions.overrides = {
      ...widgetOptions.overrides,
      'paneProperties.background': '#1C1C1E',
    }
  } else {
    widgetOptions.overrides = {
      ...widgetOptions.overrides,
      'paneProperties.background': '#FFFFFF',
    }
  }

  useEffect(() => {
    const options = {
      ...widgetOptions,
      container: ref.current
    };

    if (window?.LIST_SYMBOL?.length > 0 && isLogin && !isLoading) {
      setFirstRender(true);
      if (isRerender && !firstRender) {
        setTimeout(() => {
          tvAdvancedWidget[`${symbol}-${idWidget}`] = null;
          window.countAdvancedtRender[`${symbol}-${idWidget}`] = 0;
          tvAdvancedWidget[`${symbol}-${idWidget}`] = new widget(options);

          tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.onChartReady(() => {
            setFirstRender(true);
            console.log('Chart Loaded!');
            tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.subscribe('onAutoSaveNeeded', () => {
              tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.saveChartToServer();
            });

            if (data?.length === 0) {
              tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart()?.createStudy('Volume');
              tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart()?.createStudy('MACD');
              tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart()?.createStudy('Moving Average Exponential', false, false, { length: 26 });
            }

            tvAdvancedWidget?.[`${symbol}-${idWidget}`].setSymbol(symbol, '1D');
            tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.headerReady().then(function() {
              renderCustomButton();
            });
  

            // tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart().createMultipointShape(
            // [{ price: 168, time: Date.UTC(2023, 10, 13) / 1000 }],
            // { shape: 'horizontal_line'}
            // );
          });
        }, 500);
      } else {
        tvAdvancedWidget[`${symbol}-${idWidget}`] = null;
        tvAdvancedWidget[`${symbol}-${idWidget}`] = new widget(options);

        tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.onChartReady(() => {
          setFirstRender(true);
          console.log('Chart Loaded!');
          tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.subscribe('onAutoSaveNeeded', () => {
            tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.saveChartToServer();
          });

          if (data?.length === 0) {
            tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart()?.createStudy('Volume');
            tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart()?.createStudy('MACD');
            tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart()?.createStudy('Moving Average Exponential', false, false, { length: 26 });
          }

          tvAdvancedWidget?.[`${symbol}-${idWidget}`].setSymbol(symbol, '1D');

          // tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart().createMultipointShape(
          //   [{ price: 168, time: Date.UTC(2023, 10, 13) / 1000 }],
          //   { shape: 'horizontal_line'}
          // );

          // console.log(tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.getStudyInputs('MACD'));
          // tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart()?.createStudy('Stochastic', false, false, { in_0: 26 }, {"%d.color" : "#FF0000"});
          // tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart()?.createStudy('Price Channel', true, false, { in_0: 26 }, null, {checkLimit: false, priceScale: 'new-left'});
          tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.headerReady().then(function() {
            // tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.createButton({
            //   useTradingViewStyle: true,
            //   title: 'Buy',
            //   text: 'Buy',
            //   onClick: () => { alert('ok')},
            //   type: 'left'
            // });

            renderCustomButton();
          });

          // tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.createDropdown(
          //     {
          //         title: 'dropdown',
          //         tooltip: 'tooltip for this dropdown',
          //         items: [
          //             {
          //                 title: 'item#1',
          //                 onSelect: () => {console.log('1');},
          //             },
          //             {
          //                 title: 'item#2',
          //                 onSelect: () => {tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.setSymbol('ANTM', '1m');},
          //             },
          //             {
          //                 title: 'item#3',
          //                 onSelect: () => {
          //                     tvAdvancedWidget?.[`${symbol}-${idWidget}`]?.activeChart().createStudy(
          //                         'MACD',
          //                         false,
          //                         false,
          //                         {
          //                             in_0: 14,
          //                             in_1: 30,
          //                             in_3: 'close',
          //                             in_2: 9
          //                         }
          //                     );
          //                 },
          //             }
          //         ],
          //         icon: `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><g fill="none" stroke="currentColor"><circle cx="10" cy="10" r="2.5"/><circle cx="18" cy="18" r="2.5"/><path stroke-linecap="square" d="M17.5 7.5l-7 13"/></g></svg>`,
          //     }
          // ).then(myDropdownApi => {
          //     // Use myDropdownApi if you need to update the dropdown:
          //     // myDropdownApi.applyOptions({
          //     //     title: 'a new title!'
          //     // });

          //     // Or remove the dropdown:
          //     // myDropdownApi.remove();
          // });
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol, isLogin, window?.LIST_SYMBOL, theme, isLoading, data, idWidget]);

  useEffect(() => {
    tempSymbol[symbol] = symbol;
  }, [symbol])

  return <div ref={ref} className="h-full" />;
}

TVRealtimeChartAdvanced.propTypes = {
  symbol: PropTypes.string,
  interval: PropTypes.string,
  libraryPath: PropTypes.string,
  chartsStorageUrl: PropTypes.string,
  chartsStorageApiVersion: PropTypes.string,
  clientId: PropTypes.string,
  userId: PropTypes.string,
  fullscreen: PropTypes.bool,
  autosize: PropTypes.bool,
  disabled_features: PropTypes.array,
  studiesOverrides: PropTypes.object,
  overrides: PropTypes.object,
  isRerender: PropTypes.bool,
  filter: PropTypes.string,
  onChangeSymbol: PropTypes.func,
};

export default TVRealtimeChartAdvanced;
