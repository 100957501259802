import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';
import RenderIf from 'components/basic/render-if';

import IconClose from 'components/svg/icon-close';
import IconPlusSquare from 'components/svg/icon-plus-square';
import IconPopUpHide from 'components/svg/icon-popup-hide';
import IconPopUpShow from 'components/svg/icon-popup-show';
import useWindowSize from 'hooks/useWindowSize';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetWatchlistQuery } from 'services/rtk-query/watchlist';
import { setSymbolQuickOrders } from 'store/pina-pro';
import TWOrderForm from './tw-order-form';
import { triggerSegmentEvent } from 'lib/segment';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';

function QuickOrderSticky() {
  const dispatch = useDispatch();
  const screenSize = useWindowSize();

  const { symbolQuickOrders } = useSelector(({ pinaPro }) => pinaPro);
  const { data: dataWatchlist } = useGetWatchlistQuery();

  const [list, setList] = useState([]);
  const [showAddOrder, setShowAddOrder] = useState(true);
  const [maxWidget, setMaxWidget] = useState(999999);

  const handleChangeFormActive = (activeIndex, listIndex) => {
    let _list = [];
    list.map((item, key) => {
      if (key === listIndex) {
        _list.push({
          ...item,
          activeIndex: activeIndex
        });
      } else {
        _list.push(item);
      }
    });

    setList(_list);
    dispatch(setSymbolQuickOrders(_list));
  };

  const handleChangeFormActive2 = (activeIndex, listIndex) => {
    let _list = [];
    window?.listQuickOrder.map((item, key) => {
      if (key === listIndex) {
        _list.push({
          ...item,
          activeIndex: activeIndex
        });
      } else {
        _list.push(item);
      }
    });

    setList(_list);
    dispatch(setSymbolQuickOrders(_list));
  };

  const handleClose = (listIndex) => {
    let _list = [];
    list?.map((item, key) => {
      if (key != listIndex) {
        _list.push(item);
      }
    });

    setList(_list);
    dispatch(setSymbolQuickOrders(_list));
  };

  const handleMinimize = (listIndex) => {
    let _list = [];
    list?.map((item, key) => {
      if (key != listIndex) {
        _list.push(item);
      } else {
        _list.push({
          ...item,
          active: !item?.active
        });
      }
    });

    setList(_list);
    dispatch(setSymbolQuickOrders(_list));
  };

  const handleMinimize2 = (listIndex) => {
    let _list = [];
    window?.listQuickOrder?.map((item, key) => {
      if (key != listIndex) {
        _list.push(item);
      } else {
        _list.push({
          ...item,
          active: !item?.active
        });
      }
    });

    setList(_list);
    dispatch(setSymbolQuickOrders(_list));
  };

  const handleNewOrderForm = () => {
    if ((list?.length * (340 + 16)) + 340 + 16 >= screenSize?.width) return false;
    
    let _list = [
      ...list,
      {
        ...dataWatchlist[
          Math.floor(Math.random() * (dataWatchlist?.length - 1 - 0 + 1) + 0)
        ],
        active: true,
        activeIndex: 0,
        show: true
      }
    ];

    setList(_list);
    dispatch(setSymbolQuickOrders(_list));

    triggerSegmentEvent({ event: 'TR New Quick Order Clicked' });
  };

  const handleNewOrderForm2 = () => {
    if (!window?.listQuickOrder?.length) {
      if ((window?.listQuickOrder?.length * (340 + 16)) + 340 + 16 >= window?.screenSize?.width) return false;
      
      let _list = [
        ...window?.listQuickOrder,
        {
          code: 'AALI',
          active: true,
          activeIndex: 0,
          show: true
        }
      ];
  
      setList(_list);
      dispatch(setSymbolQuickOrders(_list));
  
      triggerSegmentEvent({ event: 'TR New Quick Order Clicked' });
    } else {
      handleMinimize2(0)
    }
  };

  const handleChangeSymbolOrderForm = (listIndex, stock) => {
    let _list = list?.map((item, index) => {
      if (index === listIndex) {
        return {
          ...stock,
          active: item?.active,
          activeIndex: item?.activeIndex
        }
      }
      return item;
    });

    setList(_list);
    dispatch(setSymbolQuickOrders(_list));
  };

  useKeyboardShortcut({
    keys: ['œ', 'q'],
    combine: 'altKey',
    onKeyPressed: () => {
      handleNewOrderForm2();
    }
  });
  
  useKeyboardShortcut({
    keys: ['∫', 'b'],
    combine: 'altKey',
    onKeyPressed: () => {
      handleChangeFormActive2(0, 0);
    },
  });

  useKeyboardShortcut({
    keys: ['ß', 's'],
    combine: 'altKey',
    onKeyPressed: () => {
      handleChangeFormActive2(1, 0);
    },
  });

  useKeyboardShortcut({
    keys: ['å', 'a'],
    combine: 'altKey',
    onKeyPressed: () => {
      handleChangeFormActive2(2, 0);
    },
  });
  
  useKeyboardShortcut({
    keys: ['∑', 'w'],
    combine: 'altKey',
    onKeyPressed: () => {
      handleChangeFormActive2(3, 0);
    },
  });

  useEffect(() => {
    if (symbolQuickOrders?.length > 0 && list?.length === 0) {
      setList(symbolQuickOrders?.map((item) => {
        return { ...item, active: false }
      }));
    }
  }, [symbolQuickOrders, list]);

  useEffect(() => {
    setMaxWidget(Math.floor(screenSize?.width / (340 + 16)))
    setShowAddOrder((list?.length * (340 + 16)) + 340 + 16 < screenSize?.width);
  }, [screenSize, list])


  useEffect(() => {
    window.listQuickOrder = list;
  }, [list]);

  useEffect(() => {
    window.screenSize = screenSize;
  }, [screenSize]);

  return (
    <>
      <div
        className="fixed bottom-0 right-0 z-50 -mx-1 zoom-2"
        style={{ maxWidth: 'calc(100vw)', bottom: '-2px' }}>
        <Accordion className="justify-end inline-flex items-end px-4 flex-row-reverse">
          {list?.map((item, key) => (
            <AccordionItem
              key={key}
              expandable={false}
              expanded={item.active}
              isChangeHeight={item?.activeIndex}
              className={`mx-1  card-widget-gradient rounded-t-xl absolute bottom-0 border border-gray-base-l dark:border-black-light-1-l ${key > (maxWidget - 1) && 'hidden'}`}
              style={{
                right: 340 * key + 16 + (key > 0 ? key * 16 : 0) + 'px'
              }}>
              <AccordionItem.Slot name="Header">
                <div className="flex justify-between border-b dark:border-gray-base-d items-center bg-gray-light-l dark:bg-black-base-d dark:text-black-light-1-d hover:bg-opacity-50 transition-all ease-in-out duration-200 rounded-t-xl">
                  <h5
                    className="text-xs m-0 flex-auto py-2 pb-1 pl-4"
                    onClick={() => handleMinimize(key)}>
                    <RenderIf isTrue={item?.activeIndex === 0}>Beli</RenderIf>
                    <RenderIf isTrue={item?.activeIndex === 1}>Jual</RenderIf>
                    <RenderIf isTrue={item?.activeIndex === 2}>Amend</RenderIf>
                    <RenderIf isTrue={item?.activeIndex === 3}>
                      Withdraw
                    </RenderIf>{' '}
                    - {item?.code}
                  </h5>
                  <div className="flex items-center flex-none py-2 pb-1 pr-4">
                    <button
                      type="button"
                      className="appearance-none outline-none cursor-pointer p-1 transition-all ease-in-out duration-200 hover:text-main-base-l dark:text-main-base-l hover:opacity-75"
                      onClick={() => handleMinimize(key)}>
                        <RenderIf isTrue={item?.active}>
                          <IconPopUpHide className="h-5 w-5" />
                        </RenderIf>
                        <RenderIf isTrue={!item?.active}>
                          <IconPopUpShow className="h-5 w-5" />
                        </RenderIf>
                    </button>

                    <button
                      type="button"
                      className="appearance-none outline-none cursor-pointer p-1 transition-all ease-in-out duration-200 hover:text-main-base-l dark:text-main-base-l hover:opacity-75"
                      onClick={() => handleClose(key)}>
                      <IconClose className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </AccordionItem.Slot>
              <AccordionItem.Slot name="Content">
                <div className='overflow-auto' style={{ minWidth: '340px', maxHeight: '450px', minHeight: item?.activeIndex != 3  && '450px' }}>
                  <TWOrderForm
                    initActiveTabIndex={item?.activeIndex}
                    symbol={item?.code}
                    isCardLess
                    isSticky
                    buyAnalyticEventName='TR Buy Stock from New Quick Order'
                    sellAnalyticEventName='TR Sell Stock from New Quick Order'
                    amendAnalyticEventName='TR Amend Stock from New Quick Order'
                    withdrawAnalyticEventName='TR Withdraw Stock from New Quick Order'
                    onChangeTab={(indexTab) => handleChangeFormActive(indexTab, key)}
                    onChangeStock={(data) => handleChangeSymbolOrderForm(key, data)}
                  />
                </div>
              </AccordionItem.Slot>
            </AccordionItem>
          ))}

          <RenderIf isTrue={showAddOrder}>
            <button
              type="button"
              onClick={handleNewOrderForm}
              style={{
                minWidth: '170px',
                right: `${list?.length * 340 + 16 + list?.length * 16}px`
              }}
              className="flex justify-between py-2 pb-1 px-4 bg-gray-light-l dark:bg-black-base-d dark:text-black-light-1-d rounded-t-xl items-center border border-gray-base-l dark:border-black-light-1-l  absolute z-10 bottom-0 hover:bg-white dark:hover:bg-black-base-d transition-all ease-in duration-150">
              <a
                role="button"
                className="appearance-none outline-none cursor-pointer p-1">
                <IconPlusSquare className="h-5 w-5 dark:text-main-base-l" />
              </a>
              <h5 className="text-xs m-0">New&nbsp;Quick&nbsp;Order</h5>
            </button>
          </RenderIf>
        </Accordion>
      </div>
    </>
  );
}

export default QuickOrderSticky;
