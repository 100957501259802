import Card from 'components/basic/card';
import ProfilePersonalDataForm from 'components/form/profile-personal-data-form';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetMasterDataBanksQuery, useGetMasterDataProvincesQuery, useGetOptionsByTypesQuery, useLazyGetMasterDataCityByProvinceIdQuery, useLazyGetMasterDataDistrictByCityIdQuery, useLazyGetMasterDataVillageByDistrictIdQuery } from 'services/rtk-query/master-data';
import { useGetUserByIdQuery } from 'services/rtk-query/user';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function SectionDataPribadi() {
  const { user } = useBrowserTabAuth();
  
  const { data: profile, isLoading: lu } = useGetUserByIdQuery(user?.id);
  const { data: provinces, isLoading: lp } = useGetMasterDataProvincesQuery();
  const { data: options, isLoading: lo } = useGetOptionsByTypesQuery(['sex', 'martial_status', 'religion', 'education', 'industry', 'occupation', 'position', 'source_income', 'purpose']);
  const { data: banks, isLoading: lb } = useGetMasterDataBanksQuery();
  const [getMasterDataCityByProvinceId] = useLazyGetMasterDataCityByProvinceIdQuery();
  const [getMasterDataDistrictByCityId] = useLazyGetMasterDataDistrictByCityIdQuery();
  const [getMasterDataVillageByDistrictId] = useLazyGetMasterDataVillageByDistrictIdQuery();

  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);

  const fetchCities = async (_provinceId) => {
    const _cities = await getMasterDataCityByProvinceId(_provinceId).unwrap();
    setCities(_cities);
  }

  const fetchDistricts = async (_cityId) => {
    const _districts = await getMasterDataDistrictByCityId(_cityId).unwrap();
    setDistricts(_districts);
  }

  const fetchVillages = async (_districtId) => {
    const _villages = await getMasterDataVillageByDistrictId(_districtId).unwrap();
    setVillages(_villages);
  }

  useEffect(() => {
    if (profile) {
      fetchCities(profile?.provinceId);
      fetchDistricts(profile?.cityId);
      fetchVillages(profile?.kecId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  if (lu || lp || cities?.length === 0 || districts?.length === 0 || villages?.length === 0 || lo || lb || isEmpty(profile)) {
    return (
      <Card className="p-6">
        <div className='grid grid-cols-2 gap-4'>
          <SkeletonTheme height="2rem" width="90%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="80%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="100%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="60%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="80%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="80%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="60%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="80%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="100%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="60%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="60%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="60%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="80%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="80%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="60%" borderRadius="10px"/>
          <SkeletonTheme height="2rem" width="90%" borderRadius="10px"/>
        </div>
      </Card>
    );
  }

  return (
    <Card className="p-6">
      <Helmet>
        <title>Pengaturan Akun | Pina Trade</title>
      </Helmet>
      <ProfilePersonalDataForm initialValues={profile} master={{ provinces, cities, districts, villages, options, banks }} />
    </Card>
  );
}

export default SectionDataPribadi;
