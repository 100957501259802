import dayjs from 'dayjs';
import { triggerSegmentEvent } from 'lib/segment';
import PropTypes from 'prop-types';

function CardNewsItem({
  link = '',
  title = '',
  source = '',
  publish_date = '',
  image = '',
  ...props
}) {
  return (
    <a
      className="flex items-center py-3 hover:bg-gray-light-0-d dark:hover:bg-black-dark-d hover:bg-opacity-5 transition-all ease-in-out duration-100"
      href={link[0]}
      onClick={() => triggerSegmentEvent({ event: 'TR Stock Detail News Clicked' })}
      data-qa-id={props?.['data-link-qa-id']}
      rel="noreferrer"
      target="_blank">
      <img data-qa-id={props?.['data-img-qa-id']} className="rounded-md mr-4 object-cover w-12 h-12" width={64} height={64} src={image} />
      <div>
        <h5 className="text-xs line-clamp-2 dark:text-black-light-1-d">{title[0]}</h5>
        <p className="text-2xs text-gray-light-0-d">
          {source || 'Pasar Dana'} | {dayjs(publish_date[0]).format('DD MMM YYYY')}
        </p>
      </div>
    </a>
  );
}

CardNewsItem.propTypes = {
  link: PropTypes.array,
  title: PropTypes.array,
  source: PropTypes.string,
  publish_date: PropTypes.array,
  image: PropTypes.string,
  ['data-link-qa-id']: PropTypes.string,
  ['data-img-qa-id']: PropTypes.string,
};

export default CardNewsItem;
