import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import TWOrderForm from './tw-order-form';
import TWRunningTrade from './tw-running-trade';
import TWInfoCash from './tw-info-cash';
import TVCompositehartContainer from 'components/chart/tradingview/TVCompositeChartContainer';
import TWWatchlist from './tw-watchlist';
import TVHeader from 'components/chart/tradingview/_partials/tv-header';
import TWOrderlistHistory from './tw-orderlist-history';
import SectionStockRankList from '../stock-market/_partials/section-stock-rank-list';
import SectionStockTrendingList from '../stock-market/_partials/section-stock-trending-list';
import { triggerSegmentEvent } from 'lib/segment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import TVRealtimeChartAdvanced from 'components/chart/tradingview/_partials/tv-realtime-chart-advanced';
import TWStockCalendar from './tw-stock-calendar';
import TWNews from './tw-news';
import TWDoneSummary from './tw-done-summary';
import TWFundamental from './tw-fundamental';
import TWDoneDetail from './tw-done-detail';
import TWCompanyProfile from './tw-company-profile';
import TWCorpActionRHUPS from './tw-corp-action-rhups';
import TWTradingIdeasFull from './tw-trading-ideas-full';
import TWBandarmology from './tw-bandarmology';
import TWBundleOrderlist from './tw-bundle-orderlist';
import FastOrderForm from 'components/form/fast-order-form';
import useCustomNavigate from 'hooks/useCustomNavigate';
import TWOrderFormWithOrderbook from './tw-order-form-with-orderbook';
import { useRef } from 'react';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import TWChartAndOrderbook from './tw-chart-and-orderbook';
import TWIntradayChart from 'pages/trading/_partials/tw-intraday-chart';

function DynamicModule({
  idWidget,
  module,
  symbol,
  widgetSettings = {},
  onChangeSymbol = () => {},
  onChangeWidgetSettings = () => {}
}) {
  const ref = useRef();
  const { user } = useBrowserTabAuth();
  const username = user?.username;
  const id = user?.id;
  const { customNavigate } = useCustomNavigate();

  return (
    <>
      <OverlayScrollbarsComponent
        ref={ref}
        className="overlayscrollbars-host-small overflow-hidden relative"
        element="span"
        options={{ resize: 'horizontal' }}
        style={{ height: 'calc(100% - 38px)' }}>
        <div className="h-full">
          <RenderIf isTrue={module === 'order-list-history'}>
            <div className="pr-4 h-full">
              <TWOrderlistHistory title="" />
            </div>
          </RenderIf>

          <RenderIf isTrue={module === 'orderbook'}>
            <TWIntradayChart
              isCardLess
              idWidget={idWidget}
              symbol={symbol}
              views={{
                orderBook: true,
                buyOrSell: false,
                customFilter: false,
                customLegend: true,
                customHeader: true,
                chart: false
              }}
              isRerender
              onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
              widgetSettings={widgetSettings}
              onChangeWidgetSettings={onChangeWidgetSettings}
            />
          </RenderIf>

          <RenderIf isTrue={module === 'chart-orderbook'}>
            <TWChartAndOrderbook
              idWidget={idWidget}
              widgetSettings={widgetSettings}
              symbol={symbol}
              onChangeSymbol={onChangeSymbol}
              onChangeWidgetSettings={onChangeWidgetSettings}
            />
          </RenderIf>

          <RenderIf isTrue={module === 'chart'}>
            <TWIntradayChart
              isCardLess
              symbol={symbol}
              views={{
                orderBook: false,
                buyOrSell: true,
                customFilter: true,
                customLegend: true,
                customHeader: true,
                chart: true
              }}
              onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
              containerHeight={'calc(100% - 12.5rem)'}
              isRerender
            />
          </RenderIf>

          <RenderIf isTrue={module === 'order-form'}>
            <OverlayScrollbarsComponent
              className="overlayscrollbars-host-small overflow-hidden relative"
              element="span"
              options={{ resize: 'horizontal' }}
              style={{
                height: 'calc(100% - 40px)'
              }}>
              <TWOrderForm
                isCardLess
                symbol={symbol}
                initActiveTabIndex={widgetSettings?.initActiveTabIndex || 0}
                onChangeStock={({ code }) => {
                  if (!code) return false;
                  onChangeSymbol(code);
                }}
                onChangeTab={(initActiveTabIndex) =>
                  onChangeWidgetSettings({
                    ...widgetSettings,
                    initActiveTabIndex
                  })
                }
              />
            </OverlayScrollbarsComponent>
          </RenderIf>

          <RenderIf
            isTrue={['order-form-orderbook', 'order-form-mock-only'].includes(
              module
            )}>
            <TWOrderFormWithOrderbook
              idWidget={idWidget}
              symbol={symbol}
              widgetSettings={widgetSettings}
              onChangeStock={(code) => {
                if (!code) return false;
                onChangeSymbol(code);
              }}
              onChangeTab={(initActiveTabIndex) =>
                onChangeWidgetSettings({
                  ...widgetSettings,
                  initActiveTabIndex
                })
              }
              onChangeWidgetSettings={(newWidgetSettings) =>
                onChangeWidgetSettings(newWidgetSettings)
              }
            />
          </RenderIf>

          <RenderIf
            isTrue={[
              'fast-order-buy',
              'fast-order-sell',
              'multi-order',
              'fast-order'
            ].includes(module)}>
            <FastOrderForm
              symbol={symbol}
              isInlineForm
              onChangeSymbol={onChangeSymbol}
            />
          </RenderIf>

          <RenderIf isTrue={module === 'running-trade'}>
            <div className="p-4 pt-0 h-full">
              <TWRunningTrade
                isCardLess
                text=""
                symbol={symbol}
                className="p-0"
                widgetSettings={widgetSettings}
                onChangeWidgetSettings={onChangeWidgetSettings}
              />
            </div>
          </RenderIf>

          <RenderIf isTrue={module === 'info-cash'}>
            <TWInfoCash isCardLess title="" />
          </RenderIf>

          <RenderIf isTrue={module === 'IHSG'}>
            <div className="h-[calc(100%-1.15rem)]">
              <TVCompositehartContainer
                isCardLess
                key="COMPOSITE"
                symbol="COMPOSITE"
                height="205"
                isRerender
              />
            </div>
          </RenderIf>

          <RenderIf isTrue={module === 'watchlist'}>
            <TWWatchlist
              isCardLess
              isZoomOut
              title=""
              onClickStock={(symbol) => {
                customNavigate({ query: { 'stock-detail': symbol } });
                triggerSegmentEvent({
                  event: 'TR Watchlist Clicked From Custom Workspace'
                });
              }}
            />
          </RenderIf>

          <RenderIf isTrue={module === 'advanced-chart'}>
            <TVRealtimeChartAdvanced
              userId={id}
              idWidget={idWidget}
              clientId={username}
              symbol={symbol}
              interval="1D"
              isRerender
              isAdvancedChart
              overrides={{
                'paneProperties.background': '#131103',
                'paneProperties.backgroundType': 'solid'
              }}
              onChangeSymbol={(_symbol) => {
                onChangeSymbol(_symbol, true);
              }}
            />
          </RenderIf>

          <RenderIf isTrue={module === 'news'}>
            <div className="sticky top-0 card-widget-gradient">
              <TVHeader
                symbol={symbol}
                onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
                showLogo
                advancedChart
                className="pb-1"
              />
            </div>
            <TWNews symbol={symbol} />
          </RenderIf>

          <RenderIf isTrue={module === 'done-summary'}>
            <div className="sticky top-0 card-widget-gradient z-40">
              <TVHeader
                symbol={symbol}
                onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
                showLogo
                advancedChart
                className="pb-1"
              />
            </div>

            <TWDoneSummary symbol={symbol} />
          </RenderIf>

          <RenderIf isTrue={module === 'done-detail'}>
            <div className="sticky top-0 card-widget-gradient z-10">
              <TVHeader
                symbol={symbol}
                onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
                showLogo
                advancedChart
                className="pb-1"
              />
            </div>

            <TWDoneDetail symbol={symbol} maxHeight="unset" />
          </RenderIf>

          <RenderIf isTrue={module === 'fundamental'}>
            <div className="sticky top-0 card-widget-gradient z-20">
              <TVHeader
                symbol={symbol}
                onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
                showLogo
                advancedChart
                className="pb-1"
              />
            </div>

            <TWFundamental symbol={symbol} isSingle maxHeight="unset" />
          </RenderIf>

          <RenderIf isTrue={module === 'company-profile'}>
            <div className="sticky top-0 card-widget-gradient z-20">
              <TVHeader
                symbol={symbol}
                onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
                showLogo
                advancedChart
                className="pb-1"
              />
            </div>

            <TWCompanyProfile symbol={symbol} maxHeight="unset" />
          </RenderIf>

          <RenderIf isTrue={module === 'corp-action'}>
            <div className="sticky top-0 card-widget-gradient z-20">
              <TVHeader
                symbol={symbol}
                onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
                showLogo
                advancedChart
                className="pb-1"
              />
            </div>

            <TWCorpActionRHUPS symbol={symbol} maxHeight="unset" />
          </RenderIf>

          <RenderIf isTrue={module === 'top-ranking'}>
            <SectionStockRankList isCardLess />
          </RenderIf>

          <RenderIf isTrue={module === 'top-tranding'}>
            <div className="-mt-4">
              <SectionStockTrendingList
                isZoomOut
                isShowChart={false}
                widgetSettings={widgetSettings}
                isCardLess
                onChangeWidgetSettings={onChangeWidgetSettings}
              />
            </div>
          </RenderIf>

          <RenderIf isTrue={module === 'calendar'}>
            <TWStockCalendar title={<></>} isCardLess />
          </RenderIf>

          <RenderIf isTrue={module === 'trading-ideas'}>
            <TWTradingIdeasFull title={<></>} isZoomOut />
          </RenderIf>

          <RenderIf
            isTrue={[
              'bundle-orderlist',
              'trade-list',
              'order-list',
              'portfolio'
            ]?.includes(module)}>
            <TWBundleOrderlist
              isZoomOut
              widgetSettings={widgetSettings}
              onChangeWidgetSettings={onChangeWidgetSettings}
            />
          </RenderIf>

          <RenderIf isTrue={module === 'bandarmology'}>
            <div className="h-[98%]">
              <TWBandarmology
                broker={{ symbol }}
                maxHeight="unset"
                onClickSearch={(symbol) => onChangeSymbol(symbol)}
                widgetSettings={widgetSettings}
                onChangeWidgetSettings={onChangeWidgetSettings}
              />
            </div>
          </RenderIf>
        </div>
      </OverlayScrollbarsComponent>
    </>
  );
}

DynamicModule.propTypes = {
  module: PropTypes.string,
  symbol: PropTypes.string,
  idWidget: PropTypes.string,
  widgetSettings: PropTypes.object,
  onChangeSymbol: PropTypes.func,
  onChangeWidgetSettings: PropTypes.func
};

export default DynamicModule;
