import baseApi from '../base'
import { TAG_STOCKS, TAG_WATCHLIST } from '../tags';

export const WatchlistApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getWatchlist: build.query({
      query: () => {
        return {
          url: `/stocks/watchlist?type[]=stock`,
        }
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_WATCHLIST, id: 'LIST' }];
      }
    }),
    createWatchlist: build.mutation({
      query: (body) => {
        return {
          url: '/stocks/watchlist/add',
          method: 'POST',
          body
        }
      },
      invalidatesTags: (result, error, { stockCode }) => {
        return [
          { type: TAG_WATCHLIST, id: 'LIST' },
          { type: TAG_STOCKS, id: stockCode },
        ];
      }
    }),
    deleteWatchlist: build.mutation({
      query: (body) => {
        return {
          url: `/stocks/watchlist/remove`,
          method: 'POST',
          body
        }
      },
      invalidatesTags:  (result, error, {stockCode}) => {
        return [
          { type: TAG_WATCHLIST, id: 'LIST' },
          { type: TAG_STOCKS, id: stockCode },
        ];
      },
    }),
    updateWatchlistNotesById: build.mutation({
      query: ({ id, body }) => {
        return {
          url: `/stocks/watchlist/${id}`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags:  (result, error, {stockCode}) => {
        return [
          { type: TAG_WATCHLIST, id: 'LIST' },
          { type: TAG_STOCKS, id: stockCode },
        ];
      },
    }),
  }),
});

export const invalidateWatchlist = () => {
  return WatchlistApi.util.invalidateTags([{ type: TAG_WATCHLIST, id: 'LIST' }]);
}

export const {
  useGetWatchlistQuery,
  useLazyGetWatchlistQuery,
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation,
  useUpdateWatchlistNotesByIdMutation
} = WatchlistApi;
