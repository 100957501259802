import Card from 'components/basic/card';
import { triggerSegmentEvent } from 'lib/segment';
import TWBundleOrderlist from 'pages/trading/_partials/tw-bundle-orderlist';
import TWDoneDetail from 'pages/trading/_partials/tw-done-detail';
import TWInfoCash from 'pages/trading/_partials/tw-info-cash';
import TWIntradayChart from 'pages/trading/_partials/tw-intraday-chart';
import TWOrderBook from 'pages/trading/_partials/tw-orderbook';
import { QuickAction } from 'pages/trading/_partials/tw-stock-detail';
import TWWatchlist from 'pages/trading/_partials/tw-watchlist';
import SectionStockDetail from 'pages/trading/search/_partials/section-stock-detail';
import { Helmet } from 'react-helmet-async';
import TabWorkspace from '../_partials/tab-worksace';
import { useGetAllWorkSpaceByUserIdQuery } from 'services/rtk-query/workspace';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetWatchlistQuery } from 'services/rtk-query/watchlist';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function ListWorkspace() {
  const navigate = useNavigate();
  const { user } = useBrowserTabAuth();

  const { data: workspaces, isLoading: isLoadingWorkspaces } =
    useGetAllWorkSpaceByUserIdQuery(user?.id, {
      refetchOnMountOrArgChange: true
    });

  return (
    <div className="flex-none w-full lg:w-[75vw] p-4 pb-0 pt-3">
      <TabWorkspace
        isLoading={isLoadingWorkspaces}
        workspaces={workspaces}
        onRemoveWorkspace={() => false}
        onAddWorkspace={() => navigate('/workspace/new-workspace')}
      />
    </div>
  );
}

function PinaProCustomWorkspace() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data } = useGetWatchlistQuery();

  const symbol = searchParams.get('symbol') || data?.[0]?.code || 'BBCA';

  return (
    <main className="zoom-2">
      <Helmet>
        <title>Default Workspace | Pina Trade</title>
      </Helmet>
      <div>
        <ListWorkspace />
      </div>
      <div className="flex p-4 gap-3 flex-wrap lg:flex-nowrap">
        <div className="flex-none w-full lg:w-[300px]">
          <div className="flex flex-col gap-3">
            <TWWatchlist
              activeSymbol={symbol}
              title="Watchlist"
              onClickStock={(symbol) => {
                navigate(`/default-workspace?symbol=${symbol}`);
                triggerSegmentEvent({
                  event: 'TR Watchlist Clicked From Default Workspace'
                });
              }}
            />

            <Card>
              <TWInfoCash isCardLess title="Info Cash" />
            </Card>
          </div>
        </div>
        <div className="flex-auto">
          <div className="grid grid-cols-1 gap-3">
            <div>
              <QuickAction
                className="lg:hidden"
                showRefreshTool={false}
                symbol={symbol}
              />
              <div>
                <TWIntradayChart
                  symbol={symbol}
                  filterSize="medium"
                  showStockDetail={false}
                  typeHeader="LONG"
                  views={{
                    orderBook: false,
                    buyOrSell: false,
                    customFilter: true,
                    customLegend: true,
                    customHeader: true,
                    chart: true
                  }}
                  onChangeSymbol={(newSymbol) => {
                    navigate(`/default-workspace?symbol=${newSymbol}`);
                    triggerSegmentEvent({ event: 'TR Search Feature Clicked' });
                  }}
                  height={250}
                />
              </div>
            </div>
            <div>
              <Card>
                <TWBundleOrderlist isInline />
              </Card>
            </div>
            <div>
              <SectionStockDetail symbol={symbol} />
            </div>
          </div>
        </div>
        <div className="flex-none w-full lg:w-[300px] flex flex-col gap-3">
          <div>
            <QuickAction
              className="hidden lg:block"
              symbol={symbol}
              showRefreshTool={false}
            />

            <Card className="p-4">
              <h5 className="text-sm font-quicksand-semibold mb-2 dark:text-black-light-1-d">
                Orderbook
              </h5>
              <TWOrderBook
                isExpandRow
                rows={5}
                isShowChartOrderbook
                symbol={symbol}
                onClickPrice={(price) =>
                  customNavigate({
                    query: { 'simple-order': symbol, price: price }
                  })
                }
              />
            </Card>
          </div>

          <div>
            <Card className="p-4 pb-1 mb-3">
              <h5 className="text-sm dark:text-black-light-1-d">
                Done Details
              </h5>

              <div className="-mx-3">
                <TWDoneDetail symbol={symbol} />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PinaProCustomWorkspace;
