import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function ModalPortal({ children }) {
    const [mounted, setMounted] = useState(false);
    const modalRoot = mounted ? document?.querySelector("#modal-root-new") : null;
    const elRef = useRef(null);
    if (!elRef.current && mounted) elRef.current = document.createElement("div");

    useEffect(() => {
        if (modalRoot) {
            const el = elRef?.current;
            modalRoot.appendChild(el);
            return () => {
                modalRoot.removeChild(el);
            };
        }

        setMounted(true);
    }, [modalRoot]);

    if (mounted && elRef.current) return createPortal(children, elRef?.current)
    return <></>
}