import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import { useParams } from 'react-router-dom';
import {
  useGetMultipleStockByCodesQuery,
  useGetStockThematicByIdQuery,
  useGetStockThematicsQuery
} from 'services/rtk-query/stock';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { automatedNumberFormat } from 'utils/format';
import IconSorting from 'components/svg/icon-sorting';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import ActionButton from 'components/basic/action-button';
import IconStarDelete from 'components/svg/icon-star-delete';
import IconStar from 'components/svg/icon-star';
import { useCreateWatchlistMutation, useDeleteWatchlistMutation, useGetWatchlistQuery } from 'services/rtk-query/watchlist';
import useToastr from 'hooks/useToastr';
import InputSelect from 'components/input/input-select';

import "components/input/css/custom/customize-input-select.css";
import { useTableSorting } from 'hooks/useTableSorting';
import { getColorStatus } from 'utils/stock/score';
import IconSearchPage from 'components/svg/icon-search-page';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import { useCompareStock } from 'hooks/useCompareStock';
import { triggerSegmentEvent } from 'lib/segment';
import IconBuy from 'components/svg/icon-buy';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useCustomNavigate from 'hooks/useCustomNavigate';

function SectionIDXStockList() {
  const { navigate, customNavigate } = useCustomNavigate();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { code } = useParams();

  const { data: watchlist } = useGetWatchlistQuery();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();

  const { addToCompareStock } = useCompareStock();
  
  const { data: stocks, isLoading: loadingStocks } = useGetStockThematicByIdQuery(code);
  const { data: idxs, isLoading: loadingIdxs } = useGetStockThematicsQuery({type: 'idx', keyword: ''});

  const [codes, setCodes] = useState([]);
  const [loadingMul, setloadingMul] = useState(true);
  const { data: tempMultiStocks, isLoading: loadingMultipleStock, } = useGetMultipleStockByCodesQuery(codes, { skip: loadingMul });

  const activeIDX = find(idxs, ['_id', code]);

  const [idx, setIDX] = useState(code);
  const [multipleStocks, setMultiStocks] = useState([]);
  const [options, setOptions] = useState([]);

  const { data, sortingColumns, handleSorting, getSorting } = useTableSorting(multipleStocks);

  useEffect(() => {
    let _stocks = [];
    stocks?.data?.map((item) => {
      _stocks.push(item?.code);
    });

    if (_stocks?.length > 0) {
      setCodes(_stocks);
      setloadingMul(false);
    }
  }, [stocks]);

  useEffect(() => {
    if (idxs?.length > 0) {
      setOptions(idxs?.map((item) => { return { id: item?._id, name: item?.name }}));
    }
  }, [idxs]);

  useEffect(() => {
    setMultiStocks(tempMultiStocks?.map((item) => {
      return {
        ...item,
        diff: item?.score?.diff,
        chg: item?.score?.value,
        dividenYield: find(stocks?.data, ['code', item?.code])?.dividenYield,
        per: find(stocks?.data, ['code', item?.code])?.per
      }
    }));
  }, [tempMultiStocks, stocks]);

  return (
    <div className='mt-5 dark:text-black-light-1-d'>
      {
        loadingStocks || loadingMultipleStock || loadingIdxs || loadingMul ?
        <>
          <div className='flex mb-3 items-center'>
            <SkeletonTheme className='mr-3' width="40px" height="40px" borderRadius="100%" />
            <SkeletonTheme className='mr-3' width="150px" height="1.5rem" borderRadius="6px" />
          </div>

          <SkeletonTheme className='mb-2' width="80%" height="1" borderRadius="6px" />
          <SkeletonTheme className='mb-2' width="50%" height="1" borderRadius="6px" />
          <SkeletonTheme className='mb-2' width="30%" height="1" borderRadius="6px" />
          <SkeletonTheme className='mb-2' width="10%" height="1" borderRadius="6px" />
        </>

        :
        <>
          <div className="mb-2">
            <div className='max-w-[320px] relative z-10 customize-input-select'>
              <InputSelect
                value={idx}
                label=""
                className="mb-4"
                options={options}
                onChange={(e) => {
                  setIDX(e.target.value);
                  navigate(`/stock-market/index/${e.target.value}`)
                }}
              />
            </div>
          </div>
          <p className="text-sm lg:max-w-xl text-gray-light-0-d">{activeIDX?.description}</p>
          <h3 className="text-sm my-3">{activeIDX?.total} Saham</h3>
        </>
      }

      <Card className="p-3">
        <div className="">
          <OverlayScrollbarsComponent
            className="overlayscrollbars-host-small"
            element="span"
            options={{
              resize: 'horizontal',
              scrollbars: {
                autoHide: 'leave',
                autoHideDelay: 50
              }
            }}
            >
            <table className="text-2xs w-full text-center font-quicksand-semibold">
              <thead className='text-gray-light-0-d'>
                <tr>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d lg:sticky left-0 card-widget-gradient hover:text-main-base-l min-w-[100px] lg:min-w-[200px] ${sortingColumns?.[0] === 'code' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('code')}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Symbol
                      <IconSorting
                        type={getSorting('code')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d left-24 lg:sticky card-widget-gradient hover:text-main-base-l ${sortingColumns?.[0] === 'value' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('value')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Last
                      <IconSorting
                        type={getSorting('value')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d left-40 lg:sticky card-widget-gradient hover:text-main-base-l ${sortingColumns?.[0] === 'diff' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('diff')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      CHG
                      <IconSorting
                        type={getSorting('diff')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d left-56 lg:sticky card-widget-gradient hover:text-main-base-l ${sortingColumns?.[0] === 'chg' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('chg')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      %CHG
                      <IconSorting
                        type={getSorting('chg')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'dividenYield' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('dividenYield')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Dividen&nbsp;Yield
                      <IconSorting
                        type={getSorting('dividenYield')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'per' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('per')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      PER
                      <IconSorting
                        type={getSorting('per')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'openPrice' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('openPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Open
                      <IconSorting
                        type={getSorting('openPrice')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'closePrice' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('closePrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Close
                      <IconSorting
                        type={getSorting('closePrice')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'highTradedPrice' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('highTradedPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      High
                      <IconSorting
                        type={getSorting('highTradedPrice')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'lowTradedPrice' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('lowTradedPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Low
                      <IconSorting
                        type={getSorting('lowTradedPrice')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'averagePrice' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('averagePrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Avg
                      <IconSorting
                        type={getSorting('averagePrice')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'bidVolume' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('bidVolume')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      BidLot
                      <IconSorting
                        type={getSorting('bidVolume')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'bidPrice' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('bidPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      BID
                      <IconSorting
                        type={getSorting('bidPrice')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'offerPrice' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('offerPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      OFF
                      <IconSorting
                        type={getSorting('offerPrice')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'offerVolume' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('offerVolume')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      OffLot
                      <IconSorting
                        type={getSorting('offerVolume')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'frequency' && 'text-main-base-l'}`}
                    onClick={() => handleSorting('frequency')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Freq
                      <IconSorting
                        type={getSorting('frequency')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'tradableLot' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('tradableLot')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Tr.Lot
                      <IconSorting
                        type={getSorting('tradableLot')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                  <td
                    className={`p-2 py-[6px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'tradableValue' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('tradableValue')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center justify-between cursor-pointer">
                      Tr.Val
                      <IconSorting
                        type={getSorting('tradableValue')}
                        className="ml-1 h-6 w-6"
                      />
                    </span>
                  </td>
                </tr>
              </thead>
              <tbody className="text-2xs">
                <RenderIf isTrue={data?.length === 0 && !loadingStocks && !loadingIdxs && !loadingMultipleStock}>
                  <tr className="group">
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                  </tr>
                </RenderIf>

                <RenderIf isTrue={loadingStocks || loadingIdxs || loadingMultipleStock}>
                  <tr className="group">
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                    </td>
                  </tr>
                </RenderIf>

                <RenderIf isTrue={data?.length > 0 && !loadingStocks && !loadingIdxs && !loadingMultipleStock}>
                  {data?.map((item, key) => (
                    <tr key={key} className="relative group cursor-pointer hover:z-10">
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d card-widget-gradient border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left w-20 lg:sticky left-0 font-quicksand-semibold transition-all ease-in-out duration-150">
                        <div className="flex items-center mx-auto">
                          <RenderIf isTrue={item?.logo}>
                            <img
                              src={item?.logo}
                              className="h-6 w-6 border rounded-full mr-2"
                            />
                            {item?.code}
                          </RenderIf>

                          <RenderIf isTrue={!item?.logo}>
                            <div className="rounded-full mr-1 flex-none bg-gray-light-l bg-opacity-50 text-2xs flex items-center justify-center h-6 w-6">
                              {item?.code.charAt(0)}
                            </div>
                            {item?.code}
                          </RenderIf>
                        </div>
                        <p className='text-gray-light-0-d mt-[1px] font-quicksand-regular text-2xs line-clamp-1' title={find(stocks?.data, ['code', item?.code])?.name}>{find(stocks?.data, ['code', item?.code])?.name}</p>
                        <div className='relative'>
                          <ActionButton isLast={true} position={`left-0 ${key >= data?.length - 1 ? 'bottom-8' : '-bottom-8'}`} actions={[
                            {
                              title: 'Beli',
                              children: (
                                <IconBuy className="h-4 w-4" />
                              ),
                              onClick: () => customNavigate({ query: { 'simple-order': item?.code }})
                            },
                            {
                              title: 'Stock Detail',
                              children: <IconSearchPage className="h-4 w-4" />,
                              onClick: () => {
                                triggerSegmentEvent({ event: 'TR Search Feature Clicked' });
                                customNavigate({ query: { 'stock-detail': item?.code }});
                              }
                            },
                            {
                              title: 'Trading View',
                              children: <IconChartCandleStick className="h-4 w-4" />,
                              onClick: () => {
                                customNavigate({ query: { 'advanced-chart': item?.code }});
                              }
                            },
                            {
                              title: 'Bandingkan',
                              children: <IconCompare className="h-4 w-4" />,
                              onClick: () => {
                                triggerSegmentEvent({ event: 'TR Compare Stock Clicked' });
                                addToCompareStock({ code: item?.code, name: item?.name }, () => {
                                  navigate(`/compare/fundamental`);
                                })
                              }
                            },
                            {
                              title: `${watchlist?.find((w) => w?.code === item?.code) ? 'Hapus Watchlist' : 'Tambahkan ke Watchlist'}`,
                              children: watchlist?.find((w) => w?.code === item?.code) ? <IconStarDelete className="h-4 w-4" /> : <IconStar className="h-4 w-4" />,
                              onClick: async () => {
                                triggerSegmentEvent({ event: 'TR Watchlist Clicked' });
                                if (watchlist?.find((w) => w?.code === item?.code)) {
                                  try {
                                    await deleteWatchlist({ stockCode: item?.code }).unwrap();
                                    toastr.success(`${item?.code} berhasil dihapus dari watchlist`);
                                  } catch (error) {
                                    getErrorMessage(error);
                                  }
                                } else {
                                  try {
                                    await createWatchlist({ stockCode: item?.code, type: 'stock'}).unwrap();
                                    toastr.success(`${item?.code} berhasil ditambahkan kedalam watchlist`);
                                  } catch (error) {
                                    getErrorMessage(error);
                                  }
                                }
                              }
                            }
                          ]}/>
                        </div>
                      </td>
                      <td className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d card-widget-gradient transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left left-24 lg:sticky ${getColorStatus(item?.closePrice - item?.value)}`}>{automatedNumberFormat(item?.value)}</td>
                      <td className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d card-widget-gradient transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left left-40 lg:sticky ${getColorStatus(item?.diff)}`}>{automatedNumberFormat(item?.diff)}</td>
                      <td className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d card-widget-gradient transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left left-56 lg:sticky`}>
                        <div className='flex items-center relative'>
                          <span className={`${getColorStatus(item?.chg)}`}>
                            {automatedNumberFormat(item?.chg)}%
                          </span>
                        </div>
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                        {automatedNumberFormat(item?.dividenYield)}%
                      </td>
                      <td className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left ${getColorStatus(item?.per)}`}>
                        {automatedNumberFormat(item?.per)}x
                      </td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.openPrice)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.closePrice)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.highTradedPrice)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.lowTradedPrice)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.averagePrice)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.bidVolume)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.bidPrice)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.offerPrice)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.offerVolume)}</td>
                      <td className='p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left'>{automatedNumberFormat(item?.frequency)}</td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">{automatedNumberFormat(item?.tradableLot)}</td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">{automatedNumberFormat(item?.tradableValue)}</td>
                    </tr>
                  ))}
                </RenderIf>
              </tbody>
            </table>
          </OverlayScrollbarsComponent>

          <RenderIf isTrue={data?.length === 0 && !loadingIdxs && !loadingMultipleStock && !loadingStocks && !loadingMul}>
            <p className="text-sm text-gray-light-0-d">Belum ada list saham.</p>
          </RenderIf>
        </div>
      </Card>
    </div>
  );
}

export default SectionIDXStockList;
