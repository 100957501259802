import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSSE from './useSSE';
import { clone, isEqual, reverse, uniqBy } from 'lodash';
import { useGetIntraDataOrderBookQuery } from 'services/rtk-query/trade';
import { generateFastOrderbook } from 'utils/stock/orderbook';
import useBrowserWakeUp from './useBrowserWakeUp';

const useRealtimeOrderbookFast = (code, lastTradedPrice) => {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const isBrowserWakeUp = useBrowserWakeUp();
  const resSSEOrderBooks = useSSE(isBursaOpen, code && `ticker/orderbook/${code}`);
  const { data: resOrderBooks, isFetching: loadingOrderBooks, refetch } = useGetIntraDataOrderBookQuery(code, { refetchOnMountOrArgChange: true });
  const [tempCode, setTempCode] = useState(code);

  const [orderBooks, setOrderBooks] = useState({
    list: [],
    totalBidLot: 0,
    totalAskLot: 0,
    firstBidPrice: 0,
    firstAskPrice: 0
  });

  useEffect(() => {
    if (!loadingOrderBooks) {
      setOrderBooks({...generateFastOrderbook(resOrderBooks?.data?.[0], lastTradedPrice), symbol: code});
    };
  }, [resOrderBooks, loadingOrderBooks, lastTradedPrice, code]);

  useEffect(() => {
    if (resSSEOrderBooks?.type === 'B' && resSSEOrderBooks?.code === code) {
      let totalLot = 0;
      const firstData = resSSEOrderBooks?.order_book?.[0];
      const lastData = resSSEOrderBooks?.order_book?.[resSSEOrderBooks?.order_book?.length -1];
      const croppedBidOrderbooks01 = orderBooks?.list?.filter((item) =>  Number(item?.price) > Number(firstData?.price));
      const croppedBidOrderbooks02 = orderBooks?.list?.filter((item) => Number(item?.price) < Number(lastData?.price));

      let newOrderbooks = {
        ...orderBooks,
        list: uniqBy([
          ...croppedBidOrderbooks01,
          ...(resSSEOrderBooks?.order_book?.map((item) => {
            return {
              ...item,
              lot: Number(item?.lot),
              price: Number(item?.price)
            };
          }) || []),
          ...croppedBidOrderbooks02
        ], 'price')
      };

      newOrderbooks?.list?.map((item) => {
        if (Number(item?.price) <= Number(firstData?.price)) {
          totalLot += Number(item?.lot);
        }
      });

      newOrderbooks.totalBidLot = totalLot;
      newOrderbooks.firstBidPrice = Number(firstData?.price);

      if (!isEqual(orderBooks, newOrderbooks)) {
        setOrderBooks(newOrderbooks);
      }
    }

    if (resSSEOrderBooks?.type === 'S' && resSSEOrderBooks?.code === code) {
      let totalLot = 0;
      const reversedOrderbook = reverse(clone(resSSEOrderBooks?.order_book?.map(item => { return { ...item, lot: Number(item?.lot), price: Number(item?.price) }; })));
      const firstData = reversedOrderbook?.[0];
      const lastData = reversedOrderbook?.[reversedOrderbook?.length -1];

      const croppedBidOrderbooks01 = orderBooks?.list?.filter((item) =>  Number(item?.price) > Number(firstData?.price));
      const croppedBidOrderbooks02 = orderBooks?.list?.filter((item) => Number(item?.price) < Number(lastData?.price));

      let newOrderbooks = {
        ...orderBooks,
        list: uniqBy([
          ...croppedBidOrderbooks01,
          ...reversedOrderbook,
          ...croppedBidOrderbooks02
        ], 'price')
      };

      newOrderbooks?.list?.map((item) => {
        if (Number(item?.price) >= Number(lastData?.price)) {
          totalLot += Number(item?.lot);
        }
      });

      newOrderbooks.totalAskLot = totalLot;
      newOrderbooks.firstAskPrice = Number(lastData?.price);

      if (!isEqual(orderBooks, newOrderbooks)) {
        setOrderBooks(newOrderbooks);
      }
    }
  }, [resSSEOrderBooks, orderBooks, code]);

  useEffect(() => {
    if (tempCode != code) setTempCode(code);
  }, [code, tempCode]);

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, []);

  return {
    orderBooks,
    loadingOrderBooks
  };
};

export { useRealtimeOrderbookFast };
