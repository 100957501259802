import PropTypes from 'prop-types';

function IconRotateLeft({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5882_90570)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33984 8.75C2.86155 8.47386 3.0637 7.47819 3.33984 6.9999C6.10127 2.21697 12.2172 0.578223 17.0001 3.33965C21.783 6.10107 23.4218 12.217 20.6604 16.9999C17.8989 21.7828 11.783 23.4216 7.0001 20.6602C6.52181 20.384 6.35793 19.7724 6.63407 19.2941C6.91022 18.8158 7.52181 18.652 8.0001 18.9281C11.8264 21.1372 16.7192 19.8262 18.9283 15.9999C21.1374 12.1736 19.8264 7.28084 16.0001 5.0717C12.1738 2.86256 7.28103 4.17356 5.07189 7.9999C4.79575 8.47819 3.81814 9.02614 3.33984 8.75Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.35952 5.76732C2.73056 5.64504 3.09589 5.85597 3.17551 6.23843L3.64301 8.48399L5.82148 7.76608C6.19252 7.6438 6.55785 7.85473 6.63747 8.23719C6.7171 8.61965 6.48086 9.02883 6.10983 9.1511L3.25954 10.0904C2.8885 10.2127 2.52317 10.0018 2.44355 9.6193L1.83188 6.68123C1.75225 6.29876 1.98849 5.88959 2.35952 5.76732Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5882_90570">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconRotateLeft.propTypes = {
  className: PropTypes.string
};

export default IconRotateLeft;
