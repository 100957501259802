import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import SimpleSpinner from 'components/common/spinner/spinner';
import { sumBy } from 'lodash';
import { useEffect, useState } from 'react';
import { automatedNumberFormat } from 'utils/format';
import useFocus from 'hooks/useFocus';

function ModalConfirmationMultipleWithdraw({
  data = [],
  onSubmit = () => {},
  onClose = () => {},
  isSubmitting
}) {
  const [refFocus, setSubmitFocus] = useFocus();
  const [refCancelFocus, setCancelFocus] = useFocus();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setSubmitFocus(), 100);
  }, [open]);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Card className="p-6 max-w-md mx-auto dark:border dark:border-gray-base-d dark:text-black-light-1-d zoom-2">
      <h5 className="text-center mb-4 border-b pb-2 dark:border-gray-base-d">
        Mohon cek kembali order kamu
      </h5>
      <div className="flex justify-between">
        <p className="text-gray-light-0-d text-sm font-quicksand-semibold">
          Tipe Transaksi
        </p>
        <h6 className="text-orange-2 font-quicksand-bold">Withdraw</h6>
      </div>

      <table className="w-full border mt-4 text-sm dark:border-gray-base-d">
        <thead className="font-quicksand-semibold">
          <tr className="border-b dark:border-gray-base-d">
            <td className="text-center py-1">Saham</td>
            <td className="text-center py-1">Harga Saham</td>
            <td className="text-center py-1">Jumlah LOT</td>
            <td className="text-center py-1">Subtotal</td>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, key) => (
            <tr key={key} className="border-b dark:border-gray-base-d">
              <td className="text-center py-1">
                {item?.stID}
              </td>
              <td className="text-center py-1">
                {automatedNumberFormat(item?.price)}
              </td>
              <td className="text-center py-1">
                {automatedNumberFormat(item?.qty / 100)}
              </td>
              <td className="text-center py-1 font-quicksand-semibold">
                {automatedNumberFormat(item?.qty * item?.price, {
                  removeSymbol: true
                })}
              </td>
            </tr>
          ))}
          <tr className="border-b font-quicksand-semibold dark:border-gray-base-d">
            <td className="text-center py-2" colSpan={2}>
              Total
            </td>
            <td className="text-center py-2">
              {automatedNumberFormat(sumBy(data, 'qty') / 100)}
            </td>
            <td className="text-center py-2">
              {automatedNumberFormat(sumBy(data, (i) => i?.qty * i?.price))}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="flex my-3 items-center border-y py-2 dark:border-gray-base-d">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
          Jumlah Investasi
        </span>

        <span className="flex-grow text-right font-quicksand-bold text-sm">
          IDR {automatedNumberFormat(sumBy(data, (i) => i?.qty * i?.price))}
        </span>
      </div>

      <div className="pt-3 mt-1">
        <div className="grid grid-cols-12 gap-4">
          <button
            data-qa-id="btnStockBuyFinishConfirmation"
            type="button"
            ref={refCancelFocus}
            onClick={onClose}
            onKeyDown={(event) => {
              if (event.key === 'ArrowRight') {
                event.preventDefault();
                setSubmitFocus();
              }
            }}
            className="col-span-4 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75">
            Batal
          </button>

          <button
            type="button"
            ref={refFocus}
            onClick={onSubmit}
            onKeyDown={(event) => {
              if (event.key === 'ArrowLeft') {
                event.preventDefault();
                setCancelFocus();
              }
            }}
            disabled={isSubmitting}
            className={`col-span-8 bg-orange-2 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75`}>
            {isSubmitting && <SimpleSpinner />}
            Withdraw
          </button>
        </div>
      </div>
    </Card>
  );
}

ModalConfirmationMultipleWithdraw.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalConfirmationMultipleWithdraw;
