import AdminPage from 'pages/admin';

export const AdminRoutes = [
  {
    path: "/admin",
    index: false,
    element: AdminPage,
    layout: 'admin',
    roles: ['ROLE_ADMIN'],
    auth: true,
  },
];

export default AdminRoutes;
