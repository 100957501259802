// eslint-disable-next-line react/prop-types
function IconCopy({ className }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1615 4.49902H17.8385C18.3657 4.49901 18.8205 4.49899 19.195 4.52959C19.5904 4.5619 19.9836 4.63321 20.362 4.82601C20.9265 5.11363 21.3854 5.57257 21.673 6.13705C21.8658 6.51543 21.9371 6.90865 21.9694 7.304C22 7.67857 22 8.13334 22 8.6605V12.3375C22 12.8647 22 13.3195 21.9694 13.6941C21.9371 14.0894 21.8658 14.4826 21.673 14.861C21.3854 15.4255 20.9265 15.8844 20.362 16.172C19.9836 16.3648 19.5904 16.4362 19.195 16.4685C18.8205 16.4991 18.3657 16.499 17.8385 16.499H16V14.499H17.8C18.3766 14.499 18.7488 14.4982 19.0322 14.4751C19.3038 14.4529 19.4045 14.4152 19.454 14.39C19.6422 14.2942 19.7951 14.1412 19.891 13.953C19.9162 13.9036 19.9539 13.8029 19.9761 13.5312C19.9992 13.2479 20 12.8756 20 12.299V8.69903C20 8.12247 19.9992 7.7502 19.9761 7.46686C19.9539 7.1952 19.9162 7.09448 19.891 7.04503C19.7951 6.85687 19.6422 6.70389 19.454 6.60802C19.4045 6.58283 19.3038 6.54515 19.0322 6.52295C18.7488 6.4998 18.3766 6.49903 17.8 6.49903H10.2C9.62345 6.49903 9.25117 6.4998 8.96784 6.52295C8.69617 6.54515 8.59546 6.58283 8.54601 6.60802C8.35785 6.70389 8.20487 6.85687 8.109 7.04503C8.0838 7.09448 8.04612 7.1952 8.02393 7.46686C8.00078 7.7502 8 8.12247 8 8.69903V9.49903H6L6 8.66048C5.99998 8.13333 5.99997 7.67857 6.03057 7.304C6.06287 6.90865 6.13419 6.51543 6.32698 6.13705C6.6146 5.57257 7.07354 5.11363 7.63803 4.82601C8.01641 4.63321 8.40963 4.5619 8.80497 4.52959C9.17955 4.49899 9.63431 4.49901 10.1615 4.49902Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16146 8.49902H13.8385C14.3657 8.49901 14.8205 8.49899 15.195 8.52959C15.5904 8.5619 15.9836 8.63321 16.362 8.82601C16.9265 9.11363 17.3854 9.57257 17.673 10.1371C17.8658 10.5154 17.9371 10.9087 17.9694 11.304C18 11.6786 18 12.1333 18 12.6605V16.3375C18 16.8647 18 17.3195 17.9694 17.6941C17.9371 18.0894 17.8658 18.4826 17.673 18.861C17.3854 19.4255 16.9265 19.8844 16.362 20.172C15.9836 20.3648 15.5904 20.4362 15.195 20.4685C14.8205 20.4991 14.3657 20.499 13.8385 20.499H6.16148C5.63432 20.499 5.17955 20.4991 4.80497 20.4685C4.40963 20.4362 4.01641 20.3648 3.63803 20.172C3.07354 19.8844 2.6146 19.4255 2.32698 18.861C2.13419 18.4826 2.06287 18.0894 2.03057 17.6941C1.99997 17.3195 1.99998 16.8647 2 16.3376V12.6605C1.99998 12.1333 1.99997 11.6786 2.03057 11.304C2.06287 10.9087 2.13419 10.5154 2.32698 10.1371C2.6146 9.57257 3.07354 9.11363 3.63803 8.82601C4.01641 8.63321 4.40963 8.5619 4.80497 8.52959C5.17955 8.49899 5.63431 8.49901 6.16146 8.49902ZM4.96784 10.523C4.69617 10.5451 4.59546 10.5828 4.54601 10.608C4.35785 10.7039 4.20487 10.8569 4.109 11.045C4.0838 11.0945 4.04612 11.1952 4.02393 11.4669C4.00078 11.7502 4 12.1225 4 12.699V16.299C4 16.8756 4.00078 17.2479 4.02393 17.5312C4.04612 17.8029 4.0838 17.9036 4.109 17.953C4.20487 18.1412 4.35785 18.2942 4.54601 18.39C4.59546 18.4152 4.69617 18.4529 4.96784 18.4751C5.25117 18.4982 5.62345 18.499 6.2 18.499H13.8C14.3766 18.499 14.7488 18.4982 15.0322 18.4751C15.3038 18.4529 15.4045 18.4152 15.454 18.39C15.6422 18.2942 15.7951 18.1412 15.891 17.953C15.9162 17.9036 15.9539 17.8029 15.9761 17.5312C15.9992 17.2479 16 16.8756 16 16.299V12.699C16 12.1225 15.9992 11.7502 15.9761 11.4669C15.9539 11.1952 15.9162 11.0945 15.891 11.045C15.7951 10.8569 15.6422 10.7039 15.454 10.608C15.4045 10.5828 15.3038 10.5451 15.0322 10.523C14.7488 10.4998 14.3766 10.499 13.8 10.499H6.2C5.62345 10.499 5.25117 10.4998 4.96784 10.523Z"
      />
    </svg>
  );
}

export default IconCopy;
