import PropTypes from 'prop-types';

function IconMoney({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10C2 10.5523 2.44772 11 3 11H4C6.76142 11 9 8.76142 9 6V5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5V6C7 7.65685 5.65685 9 4 9H3C2.44772 9 2 9.44772 2 10ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM2 14C2 13.4477 2.44772 13 3 13H4C6.76142 13 9 15.2386 9 18V19C9 19.5523 8.55228 20 8 20C7.44772 20 7 19.5523 7 19V18C7 16.3431 5.65685 15 4 15H3C2.44772 15 2 14.5523 2 14ZM21 13C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H20C18.3431 15 17 16.3431 17 18V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V18C15 15.2386 17.2386 13 20 13H21ZM21 11C21.5523 11 22 10.5523 22 10C22 9.44772 21.5523 9 21 9H20C18.3431 9 17 7.65685 17 6V5C17 4.44772 16.5523 4 16 4C15.4477 4 15 4.44772 15 5V6C15 8.76142 17.2386 11 20 11H21Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7587 4H16.2413C17.0463 3.99999 17.7106 3.99998 18.2518 4.04419C18.8139 4.09012 19.3306 4.18868 19.816 4.43597C20.5686 4.81947 21.1805 5.43139 21.564 6.18404C21.8113 6.66937 21.9099 7.18608 21.9558 7.74817C22 8.28936 22 8.95372 22 9.75868V14.2413C22 15.0463 22 15.7106 21.9558 16.2518C21.9099 16.8139 21.8113 17.3306 21.564 17.816C21.1805 18.5686 20.5686 19.1805 19.816 19.564C19.3306 19.8113 18.8139 19.9099 18.2518 19.9558C17.7106 20 17.0463 20 16.2413 20H7.75872C6.95374 20 6.28937 20 5.74817 19.9558C5.18608 19.9099 4.66937 19.8113 4.18404 19.564C3.43139 19.1805 2.81947 18.5686 2.43597 17.816C2.18868 17.3306 2.09012 16.8139 2.04419 16.2518C1.99998 15.7106 1.99999 15.0463 2 14.2413V9.7587C1.99999 8.95373 1.99998 8.28937 2.04419 7.74818C2.09012 7.18608 2.18868 6.66937 2.43597 6.18404C2.81947 5.43139 3.43139 4.81947 4.18404 4.43597C4.66937 4.18868 5.18608 4.09012 5.74817 4.04419C6.28937 3.99998 6.95373 3.99999 7.7587 4ZM5.91104 6.03755C5.47262 6.07337 5.24842 6.1383 5.09202 6.21799C4.71569 6.40973 4.40973 6.7157 4.21799 7.09202C4.1383 7.24842 4.07337 7.47262 4.03755 7.91104C4.00078 8.36113 4 8.94342 4 9.8V14.2C4 15.0566 4.00078 15.6389 4.03755 16.089C4.07337 16.5274 4.1383 16.7516 4.21799 16.908C4.40973 17.2843 4.71569 17.5903 5.09202 17.782C5.24842 17.8617 5.47262 17.9266 5.91104 17.9624C6.36113 17.9992 6.94342 18 7.8 18H16.2C17.0566 18 17.6389 17.9992 18.089 17.9624C18.5274 17.9266 18.7516 17.8617 18.908 17.782C19.2843 17.5903 19.5903 17.2843 19.782 16.908C19.8617 16.7516 19.9266 16.5274 19.9624 16.089C19.9992 15.6389 20 15.0566 20 14.2V9.8C20 8.94342 19.9992 8.36113 19.9624 7.91104C19.9266 7.47262 19.8617 7.24842 19.782 7.09202C19.5903 6.7157 19.2843 6.40973 18.908 6.21799C18.7516 6.1383 18.5274 6.07337 18.089 6.03755C17.6389 6.00078 17.0566 6 16.2 6H7.8C6.94342 6 6.36113 6.00078 5.91104 6.03755Z"
      />
    </svg>
  );
}

IconMoney.propTypes = {
  className: PropTypes.string
};

export default IconMoney;
