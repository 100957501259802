// import { marginPlusShare } from 'utils/formula/margin';
import { ORDER_COST } from 'constants';
import { getStampCostBuy } from 'utils/calculation';
import { object, number, string } from 'yup';

export const StockAmendSchema = object().shape({
  amount: number()
    .test('amount', 'Jumlah amount tidak cukup', function (value, { parent }) {
      if (!parent.lotType) {
        return Math.floor(value / (parent.price * 100)) > 0;
      }

      return true;
    })
    .required('Jumlah amount harus diisi'),
  lot: number()
    .min(1, 'Jumlah lot harus diisi.')
    .required('Jumlah lot harus diisi.'),
  price: number()
    // .test('price', 'Harga terlalu rendah', function (value, { parent }) {
    //   return !(
    //     value <
    //     parent?.initialPrice - marginPlusShare(parent?.initialPrice) * 10
    //   );
    // })
    // .test('price', 'Harga terlalu tinggi', function (value, { parent }) {
    //   return !(
    //     value >
    //     parent?.initialPrice + marginPlusShare(parent?.initialPrice) * 10
    //   );
    // })
    .min(1, 'Harga saham harus diisi.')
    .required('Harga saham harus diisi.'),
  code: string()
    .test('code', 'Order harus dipilih', function (value) {
      return value;
    })
    .required('Order harus dipilih'),
  buyingPower: number()
    .test(
      'buyingPower',
      'Maaf, saldo anda tidak mencukupi.',
      function (value, { parent }) {
        return (
          value >=
            parent?.lot * 100 * parent?.price +
              Math.ceil(ORDER_COST?.buy * parent?.price * parent?.lot) +
              getStampCostBuy(value) || parent?.side === 'S'
        );
      }
    )
    .required('Maaf, saldo anda tidak mencukupi.')
});
