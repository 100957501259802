import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputText from 'components/input/input-text';
import IconClose from 'components/svg/icon-close';
import IconEmail from 'components/svg/icon-email';
import IconEye from 'components/svg/icon-eye';
import IconEyeOff from 'components/svg/icon-eye-off';
import IconLock from 'components/svg/icon-lock';
import { useFormik } from 'formik';
import useToastr from 'hooks/useToastr';
import { triggerSegmentEvent } from 'lib/segment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginPinSchema } from './login-pin-form.validation';
import RenderIf from 'components/basic/render-if';
import PinForm from '../pin-form';
import {
  useCreatePINMutation,
  useCreateResetPINMutation
} from 'services/rtk-query/user';
import { setAuth } from 'store/auth';
import { setLoginPin } from 'store/trading';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function ForgotPinForm({ onClose = () => {} }) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();
  const auth = useBrowserTabAuth();
  const browserTabID = useBrowserTabAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRequestChangePin, setIsRequestChangePin] = useState(false);

  const [confirmationPIN, setConfirmationPIN] = useState(null);

  const [resetPIN] = useCreateResetPINMutation();
  const [createPin] = useCreatePINMutation();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnMount: true,
    validationSchema: LoginPinSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);

      try {
        await resetPIN(values).unwrap();

        triggerSegmentEvent({
          event: 'TR Forgot PIN Request',
          properties: {
            email: values?.email
          }
        });

        setIsRequestChangePin(true);
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const handleSubmitChangePIN = async (_pin, resetPin) => {
    if (_pin != confirmationPIN) {
      resetPin();
      return toastr.error('Konfirmasi PIN tidak sesuai!');
    }

    setIsSubmitting(true);
    try {
      await createPin({ pin: _pin });
      const newAuth = {
        ...auth,
        user: { ...auth.user, realAccountActive: true, pinCreated: true }
      }

      dispatch(setAuth({
        ...newAuth,
        [browserTabID]: newAuth
      }));

      dispatch(setLoginPin());
      toastr.success('Berhasil membuat PIN');
      onClose();
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center dark:text-black-light-1-d">
      <Card className="p-6 pt-8 max-w-md w-[448px] mx-auto relative -mt-16 dark:border dark:border-gray-base-d">
        <button
          onClick={onClose}
          type="button"
          className="absolute right-3 top-3 hover:text-main-base-l dark:text-main-base-d">
          <IconClose className="h-6 w-6" />
        </button>

        <RenderIf isTrue={!isRequestChangePin}>
          <h5 className="text-center text-2xl mb-2">Lupa PIN</h5>
          <p className="text-center text-sm text-gray-light-0-d">
            Silakan masukkan alamat email dan password yang terkait dengan
            akunmu
          </p>
          <form className="mt-6" onSubmit={formik.handleSubmit} noValidate>
            <div className="mb-5">
              <InputText
                data-qa-id="txtEmailField"
                name="email"
                placeholder="Email PINA Kamu"
                label="Email"
                iconLeft={<IconEmail />}
                className="mb-2"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ''
                }
              />
            </div>

            <div>
              <InputText
                data-qa-id="txtPasswordField"
                type={!showPassword ? 'password' : 'text'}
                name="password"
                placeholder="Password PINA Kamu"
                label="Password"
                iconLeft={<IconLock />}
                iconRight={!showPassword ? <IconEyeOff /> : <IconEye />}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : ''
                }
                onClickRightIcon={() => setShowPassword(!showPassword)}
              />
            </div>

            <button
              type="submit"
              disabled={!formik.isValid || isSubmitting}
              className={`mt-8 mb-4 flex bg-main-base-l text-white disabled:bg-gray-base-l disabled:text-gray-dark-d dark:text-black-base-d disabled:opacity-80 disabled:cursor-not-allowed w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden transition-all ease-in-out duration-150 hover:bg-opacity-75 focus:outline-none disabled:bg-opacity-90`}>
              {isSubmitting && <SimpleSpinner />}
              Kirim
            </button>
          </form>
        </RenderIf>

        <RenderIf isTrue={isRequestChangePin && !confirmationPIN}>
          <h5 className="text-center text-2xl mb-2">Pasang PIN</h5>
          <p className="text-center text-sm text-gray-light-0-d">
            Masukkan 4 digit pin keamanan akun anda.
          </p>

          <p className="text-center flex justify-center text-main-base-l my-3">
            {isSubmitting ? (
              <SimpleSpinner className="h-8 w-8" />
            ) : (
              <IconLock className="h-8 w-8" />
            )}
          </p>

          <div className="my-6 mx-auto">
            <PinForm
              isCardless
              showNumpad={false}
              onSubmit={(_pin) => setConfirmationPIN(_pin)}
              isSubmitting={isSubmitting}
              id="new-pin"
            />
          </div>
        </RenderIf>

        <RenderIf isTrue={confirmationPIN}>
          <h5 className="text-center text-2xl mb-2">Konfirmasi PIN</h5>
          <p className="text-center text-sm text-gray-light-0-d">
            Masukkan kembali 4 digit pin keamanan akun anda.
          </p>

          <p className="text-center flex justify-center text-main-base-l my-3">
            {isSubmitting ? (
              <SimpleSpinner className="h-8 w-8" />
            ) : (
              <IconLock className="h-8 w-8" />
            )}
          </p>

          <div className="my-6 mx-auto">
            <PinForm
              isCardless
              showNumpad={false}
              onSubmit={handleSubmitChangePIN}
              isSubmitting={isSubmitting}
              id="new-pin-confirm"
            />
          </div>
        </RenderIf>
      </Card>
    </div>
  );
}

ForgotPinForm.propTypes = {
  onClose: PropTypes.func
};

export default ForgotPinForm;
