import PropTypes from 'prop-types';

function IconCamera({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.53006 4.16667C8.21441 4.16667 7.92586 4.345 7.7847 4.62732L7.18169 5.83333H5.83333C4.91286 5.83333 4.16667 6.57953 4.16667 7.5V14.1667C4.16667 15.0871 4.91286 15.8333 5.83333 15.8333H14.1667C15.0871 15.8333 15.8333 15.0871 15.8333 14.1667V7.5C15.8333 6.57953 15.0871 5.83333 14.1667 5.83333H12.8183L12.2153 4.62732C12.0741 4.345 11.7856 4.16667 11.4699 4.16667H8.53006ZM6.29399 3.88197C6.71747 3.03501 7.58313 2.5 8.53006 2.5H11.4699C12.4169 2.5 13.2825 3.03501 13.706 3.88197L13.8484 4.16667H14.1667C16.0076 4.16667 17.5 5.65905 17.5 7.5V14.1667C17.5 16.0076 16.0076 17.5 14.1667 17.5H5.83333C3.99238 17.5 2.5 16.0076 2.5 14.1667V7.5C2.5 5.65905 3.99238 4.16667 5.83333 4.16667H6.15164L6.29399 3.88197Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12.5C10.9205 12.5 11.6667 11.7538 11.6667 10.8333C11.6667 9.91286 10.9205 9.16667 10 9.16667C9.07955 9.16667 8.33335 9.91286 8.33335 10.8333C8.33335 11.7538 9.07955 12.5 10 12.5ZM10 14.1667C11.841 14.1667 13.3334 12.6743 13.3334 10.8333C13.3334 8.99238 11.841 7.5 10 7.5C8.15907 7.5 6.66669 8.99238 6.66669 10.8333C6.66669 12.6743 8.15907 14.1667 10 14.1667Z"
      />
    </svg>
  );
}

IconCamera.propTypes = {
  className: PropTypes.string
};

IconCamera.defaultProps = {
  className: ''
};

export default IconCamera;
