import RenderIf from 'components/basic/render-if';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputCheckBox from 'components/input/input-check-box';
import InputRadioSimple from 'components/input/input-radio-simple';
import InputText from 'components/input/input-text';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { RDNStep04Validation } from './rdn-step-04.validation';
import { useNavigate } from 'react-router-dom';
import { invalidateUserProfile, useGetUserProfileQuery, useUpdateUserMutation } from 'services/rtk-query/user';
import { isEmpty } from 'lodash';
import Card from 'components/basic/card';
import ListStatus from 'components/basic/list-status';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import ModalDataAman from '../../../../components/modal/template/modal-data-aman';
import { ModalContext } from 'components/modal/context/modalContext';
import { FocusError } from 'focus-formik-error';
import { triggerSegmentEvent, triggerSegmentIdentify } from 'lib/segment';
import { useDispatch } from 'react-redux';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import HeaderOnboarding from '../header-onboarding';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';


function RDNStep04() {
  const dispatch = useDispatch();
  const { getErrorMessage } = useGetErrorMessage();
  const navigate = useNavigate();
  const { user } = useBrowserTabAuth();
  const { handleModal } = useContext(ModalContext);

  const { data: userDetail } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const [updateUser] = useUpdateUserMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      isUserInvesting: false,
      userInvestment: {
        hasStock: false,
        hasObligation: false,
        hasDerivative: false,
        hasMutualFunds: false,
        hasOtherInvestment: false,
        otherInvestment: '',
      },
      hasSidNumber: false,
      sidNumber: '',
      hasFivePercentStock: false,
      fivePercentStockName: '',
      isPolitician: false,
      isMonetaryInstitutionRelative: false,
      isRelativePolitician: false,
      isPublicCompanyRelative: false,
    },
    validateOnMount: false,
    validationSchema: RDNStep04Validation,
    onSubmit: async (values) => {
      setIsSubmitting(true);

      try {
        await updateUser({ kseiForm: values }).unwrap();
        dispatch(invalidateUserProfile());

        navigate('/onboarding/syarat-dan-ketentuan');

        triggerSegmentEvent({
          event: 'TR Miscellaneous Info Inputted'
        });

        triggerSegmentIdentify({
          userId: user?.id,
          properties: { ...values }
        });
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (!isEmpty(userDetail?.kseiForm) && !window.backRDN) {
      navigate('/onboarding/syarat-dan-ketentuan');
      formik.setValues({ ...formik.initialValues, ...userDetail?.kseiForm })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail])

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:py-10 text-white relative">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto">
          <HeaderOnboarding activeStep={5} />

          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] my-3 text-white">
            Informasi lainnya
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Dokumen yang kamu unggah tersimpan dan terlindungi dengan aman.
          </p>

          <button
            type="button"
            onClick={() => handleModal(<ModalDataAman />)}
            className="text-sm lg:text-base px-3 py-1 border rounded-3xl border-white mb-6">
            Apakah data saya aman?
          </button>

          <ListStatus
            data={[
              {
                title: 'Selfie dengan KTP',
                subtitle: null,
                status: true
              },
              {
                title: 'Nomor NPWP (opsional)',
                subtitle: null,
                status: true
              },
              {
                title: 'Informasi tambahan',
                subtitle: null,
                status: true
              },
              {
                title: 'Informasi lainnya',
                subtitle: null,
                status: false
              },
            ]}
          />
        </div>
      </div>

      <div className="p-0 lg:p-10">
        <Card className="lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto pt-8 relative lg:max-h-[calc(90vh)] overflow-auto mb-24 lg:mb-0">
          <form className='px-6 lg:px-8' autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <FocusError formik={formik} />
            <h5 className="text-xl lg:text-2xl mb-4 text-main-gold-d font-quicksand-regular">Pernyataan dan Informasi Lainnya</h5>

            <label className="font-quicksand-regular text-sm mb-1 block">
              Pernah melakukan Investasi sebelumnya?
            </label>

            <InputRadioSimple
              value={formik.values.isUserInvesting}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('isUserInvesting', (/true/).test(e.target.value))
              }}
              name="isUserInvesting"
              options={[
                { value: true, text: 'Ya' },
                { value: false, text: 'Tidak' }
              ]}
            />

            <RenderIf isTrue={formik?.errors?.isUserInvesting}>
              <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-2 mb-4 block">
                {formik?.errors?.isUserInvesting}
              </span>
            </RenderIf>

            <RenderIf isTrue={formik?.values?.isUserInvesting}>
              <label className="font-quicksand-regular text-sm mb-1 block">
                Jika ya, Investasi apa yang pernah dilakukan?
              </label>

              <div className="grid grid-cols-2 gap-4 mb-4">
                <div
                  className={`border rounded-xl col-span-1 ${
                    formik?.values?.userInvestment?.hasStock && formik?.values?.userInvestment?.hasStock != ''
                      ? 'border-main-gold-d'
                      : ''
                  }`}>
                  <InputCheckBox
                    id={`hasStock`}
                    value={formik?.values?.userInvestment?.hasStock}
                    name="userInvestment.hasStock"
                    className="mb-0 py-3 w-full flex items-center ml-4"
                    label={<span className="font-quicksand-semibold text-sm">Saham</span>}
                    isError={formik?.errors?.userInvestment?.hasStock}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div
                  className={`border rounded-xl col-span-1 ${
                    formik?.values?.userInvestment?.hasObligation && formik?.values?.userInvestment?.hasObligation != ''
                      ? 'border-main-gold-d'
                      : ''
                  }`}>
                  <InputCheckBox
                    id={`hasObligation`}
                    value={formik?.values?.userInvestment?.hasObligation}
                    name="userInvestment.hasObligation"
                    className="mb-0 py-3 w-full flex items-center ml-4"
                    label={<span className="font-quicksand-semibold text-sm">Obligasi</span>}
                    isError={formik?.errors?.userInvestment?.hasObligation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div
                  className={`border rounded-xl col-span-1 ${
                    formik?.values?.userInvestment?.hasDerivative && formik?.values?.userInvestment?.hasDerivative != ''
                      ? 'border-main-gold-d'
                      : ''
                  }`}>
                  <InputCheckBox
                    id={`hasDerivative`}
                    value={formik?.values?.userInvestment?.hasDerivative}
                    name="userInvestment.hasDerivative"
                    className="mb-0 py-3 w-full flex items-center ml-4"
                    label={
                      <span className="font-quicksand-semibold text-sm">Derivatif</span>
                    }
                    isError={formik?.errors?.userInvestment?.hasDerivative}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div
                  className={`border rounded-xl col-span-1 ${
                    formik?.values?.userInvestment?.hasMutualFunds && formik?.values?.userInvestment?.hasMutualFunds != ''
                      ? 'border-main-gold-d'
                      : ''
                  }`}>
                  <InputCheckBox
                    id={`hasMutualFunds`}
                    value={formik?.values?.userInvestment?.hasMutualFunds}
                    name="userInvestment.hasMutualFunds"
                    className="mb-0 py-3 w-full flex items-center ml-4"
                    label={
                      <span className="font-quicksand-semibold text-sm">Reksa Dana</span>
                    }
                    isError={formik?.errors?.userInvestment?.hasMutualFunds}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div
                  className={`border rounded-xl col-span-2 ${
                    formik?.values?.userInvestment?.hasOtherInvestment && formik?.values?.userInvestment?.hasOtherInvestment != ''
                      ? 'border-main-gold-d'
                      : ''
                  }`}>
                  <InputCheckBox
                    id={`hasOtherInvestment`}
                    value={formik?.values?.userInvestment?.hasOtherInvestment}
                    name="userInvestment.hasOtherInvestment"
                    className="py-3 w-full flex items-center ml-4"
                    label={<span className="font-quicksand-semibold text-sm">Lainnya</span>}
                    isError={formik?.errors?.userInvestment?.hasOtherInvestment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <RenderIf isTrue={formik?.values?.userInvestment?.hasOtherInvestment}>
                    <div className="px-4">
                      <InputText
                        value={formik?.values?.userInvestment?.otherInvestment}
                        name="userInvestment.otherInvestment"
                        placeholder="Sebutkan Investasi lainnya"
                        classObject={{
                          label: 'text-xs font-quicksand-semibold',
                          'input-wrapper': 'rounded-xl',
                          input: 'rounded-xl'
                        }}
                        className="mb-4"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />

                      <RenderIf isTrue={formik?.errors?.userInvestment?.otherInvestment}>
                        <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-2 block mb-3">
                          {formik?.errors?.userInvestment?.otherInvestment}
                        </span>
                      </RenderIf>
                    </div>
                  </RenderIf>
                </div>
              </div>
            </RenderIf>

            <label className="font-quicksand-regular text-sm mb-1 block">
              Apakah Anda telah memiliki Rekening Efek di Perusahaan Efek lain?
            </label>

            <InputRadioSimple
              value={formik.values.hasSidNumber}
              onChange={(e) => {
                formik.handleChange(e);
                if (!(/true/).test(e.target.value)) formik.setFieldValue('sidNumber', '');
                formik.setFieldValue('hasSidNumber', (/true/).test(e.target.value));
              }}
              name="hasSidNumber"
              options={[
                {
                  value: true,
                  text: 'Ya',
                  node: formik.values.hasSidNumber && (
                    <>
                      <InputText
                        value={formik?.values?.sidNumber}
                        name="sidNumber"
                        placeholder="Sebutkan (beserta dengan SID)"
                        classObject={{
                          label: 'text-xs font-quicksand-semibold',
                          'input-wrapper': 'rounded-xl',
                          input: 'rounded-xl'
                        }}
                        className="mb-4"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />

                      <RenderIf isTrue={formik?.errors?.sidNumber}>
                        <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-1 block">
                          {formik?.errors?.sidNumber}
                        </span>
                      </RenderIf>
                    </>
                  )
                },
                { value: false, text: 'Tidak' }
              ]}
            />

            <RenderIf isTrue={formik?.errors?.hasSidNumber}>
              <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-2 mb-4 block">
                {formik?.errors?.hasSidNumber}
              </span>
            </RenderIf>

            <label className="font-quicksand-regular text-sm mb-1 block">
              Apakah Anda memiliki 5% atau lebih saham suatu Perusahaan Publik?
            </label>

            <InputRadioSimple
              value={formik.values.hasFivePercentStock}
              onChange={(e) => {
                formik.handleChange(e);
                if (!(/true/).test(e.target.value)) formik.setFieldValue('fivePercentStockName', '');
                formik.setFieldValue('hasFivePercentStock', (/true/).test(e.target.value))
              }}
              name="hasFivePercentStock"
              options={[
                {
                  value: true,
                  text: 'Ya',
                  node: formik.values.hasFivePercentStock && (
                    <>
                      <InputText
                        value={formik?.values?.fivePercentStockName}
                        name="fivePercentStockName"
                        placeholder="Sebutkan Nama Perusahaan Publik"
                        classObject={{
                          label: 'text-xs font-quicksand-semibold',
                          'input-wrapper': 'rounded-xl',
                          input: 'rounded-xl'
                        }}
                        className="mb-3"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />

                      <RenderIf isTrue={formik?.errors?.fivePercentStockName}>
                        <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-1 block">
                          {formik?.errors?.fivePercentStockName}
                        </span>
                      </RenderIf>
                    </>
                  )
                },
                { value: false, text: 'Tidak' }
              ]}
            />

            <RenderIf isTrue={formik?.errors?.hasFivePercentStock}>
              <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-2 mb-4 block">
                {formik?.errors?.hasFivePercentStock}
              </span>
            </RenderIf>

            <label className="font-quicksand-regular text-sm mb-1 block">
              Apakah Anda sedang menduduki/akan menduduki/sedang dicalonkan untuk
              suatu posisi publik/politis?
            </label>

            <InputRadioSimple
              value={formik.values.isPolitician}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('isPolitician', (/true/).test(e.target.value))
              }}
              name="isPolitician"
              options={[
                { value: true, text: 'Ya' },
                { value: false, text: 'Tidak' }
              ]}
            />

            <RenderIf isTrue={formik?.errors?.isPolitician}>
              <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-2 mb-4 block">
                {formik?.errors?.isPolitician}
              </span>
            </RenderIf>

            <label className="font-quicksand-regular text-sm mb-1 block">
              Apakah Anda atau anggota keluarga Anda bekerja pada Perusahaan Efek,
              Bursa Efek, Perusahaan yang diatur oleh Bursa Efek/OJK d/h BAPEPAM,
              Bank, Asuransi atau Lembaga Keuangan sejenisnya?
            </label>

            <InputRadioSimple
              value={formik.values.isMonetaryInstitutionRelative}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('isMonetaryInstitutionRelative', (/true/).test(e.target.value))
              }}
              name="isMonetaryInstitutionRelative"
              options={[
                { value: true, text: 'Ya' },
                { value: false, text: 'Tidak' }
              ]}
            />

            <RenderIf isTrue={formik?.errors?.isMonetaryInstitutionRelative}>
              <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-2 mb-4 block">
                {formik?.errors?.isMonetaryInstitutionRelative}
              </span>
            </RenderIf>

            <label className="font-quicksand-regular text-sm mb-1 block">
              Apakah Anda atau anggota keluarga Anda seorang karyawan, Direktur yang
              mempunyai pengendalian pada suatu Perusahaan Publik?
            </label>

            <InputRadioSimple
              value={formik.values.isPublicCompanyRelative}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('isPublicCompanyRelative', (/true/).test(e.target.value))
              }}
              name="isPublicCompanyRelative"
              options={[
                { value: true, text: 'Ya' },
                { value: false, text: 'Tidak' }
              ]}
            />

            <RenderIf isTrue={formik?.errors?.isPublicCompanyRelative}>
              <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-2 mb-4 block">
                {formik?.errors?.isPublicCompanyRelative}
              </span>
            </RenderIf>

            <label className="font-quicksand-regular text-sm mb-1 block">
              Apakah anggota keluarga Anda sekarang/sebelumnya/sedang menduduki/akan
              menduduki/sedang dicalonkan untuk suatu posisi publik/politis?
            </label>

            <InputRadioSimple
              value={formik.values.isRelativePolitician}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('isRelativePolitician', (/true/).test(e.target.value))
              }}
              name="isRelativePolitician"
              options={[
                { value: true, text: 'Ya' },
                { value: false, text: 'Tidak' }
              ]}
            />

            <RenderIf isTrue={formik?.errors?.isRelativePolitician}>
              <span className="text-2xs font-quicksand-semibold text-red-base-l -mt-2 mb-4 block">
                {formik?.errors?.isRelativePolitician}
              </span>
            </RenderIf>

            <div className="mt-10 fixed lg:sticky w-full bottom-0 left-0 p-6 lg:px-0 bg-white border-t z-10">
              <div className='-mx-2 flex justify-center'>
                <button
                  type="button"
                  onClick={() => {
                    window.backRDN = true;
                    navigate('/onboarding/informasi-tambahan');
                  }}
                  className={`border-2 bg-white border-main-gold-d text-main-gold-d flex px-10 items-center justify-center font-quicksand-semibold font-bold text-xs h-11 mx-2 w-full rounded-[10px] overflow-hidden focus:outline-none`}>
                  Kembali
                </button>

                <button
                  type="submit"
                  disabled={!formik.isValid}
                  className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 flex px-10 items-center justify-center font-quicksand-semibold font-bold text-xs h-11 mx-2 w-full rounded-[10px] overflow-hidden focus:outline-none ${
                    formik.isValid
                      ? 'bg-main-gold-d text-white'
                      : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                  }`}>
                  {isSubmitting && <SimpleSpinner />}
                  Selanjutnya
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default RDNStep04;
