import PropTypes from 'prop-types';
import { useState } from 'react';
import styles from './css/header.module.css';

function IconMenu({ onToggle = () => {} }) {
  const [open, setOpen] = useState(true);

  const toggle = () => {
    setOpen(!open);
    onToggle();
  };

  return (
    <div
      onClick={toggle}
      className={`${styles.menus} ${!open ? styles['menus-open'] : ''}`}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

IconMenu.propTypes = {
  onToggle: PropTypes.func
};

export default IconMenu;
