import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LoadingPage from 'components/loading/loading-page';
import { reset } from 'store/auth';
import { resetLoginPin, resetRefreshPin } from 'store/trading';
import { useNavigate } from 'react-router-dom';
import { enhanceBaseApi } from 'services/rtk-query/base';
import { resetStockScreener } from 'store/stock';
import useDarkMode from 'hooks/useDarkMode';
import { useCookies } from 'react-cookie';

function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { removeDarkMode } = useDarkMode();
  const [cookies, setCookie] = useCookies(['pn_trade_auth_token']);

  useEffect(() => {
    navigator?.serviceWorker?.getRegistrations()?.then(function(registrations) {
      for(let registration of registrations) {
        registration?.unregister();
      } 
    });

    setCookie('pn_trade_auth_token', false, { path: '/', maxAge: 0 });
    removeDarkMode();
    dispatch(reset());
    dispatch(resetLoginPin());
    dispatch(resetRefreshPin());
    dispatch(resetStockScreener());
    dispatch(enhanceBaseApi?.util?.resetApiState());
    navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />
}

export default LogoutPage;
