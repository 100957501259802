import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { useState } from 'react';

function BenefitPinaTrade() {
  const [showDetail, setShowDetail] = useState(false);

  const title = 'Benefit menjadi member PINA Trade';
  const list = [
    'Dapatkan akses ke 100+ Workshop dan Webinar',
    'Akses replay semua video Webinar & Workshop tanpa batas',
    'Kesempatan berdiskusi langsung dengan Trader Saham berpengalaman saat Webinar & Workshop',
    'Dapatkan Informasi dan update Trading Signal setiap minggu',
    'Full Access Platform Trading Saham seperti: Custom Workspace, Portfolio Manager, Auto Trading hingga Smart Oder',
    'Full Access Tools Analisa Saham dengan fitur: Advance Chart (real-time) dan Compare Stock'
  ];

  return (
    <>
      <Accordion className="lg:hidden">
        <AccordionItem
          expandable={false}
          expanded={showDetail}
          onToggle={() => setShowDetail(!showDetail)}>
          <AccordionItem.Slot name="Header">
            <div className="flex justify-between items-center">
              <h5 className="text-base mb-1">{title}</h5>
              <IconChevronSingle
                className={`h-5 w-5 transform text-white ${
                  showDetail ? 'rotate-90' : '-rotate-90'
                }`}
              />
            </div>
          </AccordionItem.Slot>

          <AccordionItem.Slot name="Content">
            <div className="bg-[#665800] p-3 py-4 lg:p-4 rounded-xl bg-opacity-75 mt-2">
              <ul className="text-xs lg:text-sm list-disc pl-4">
                {list?.map((item, key) => (
                  <li key={key} className="py-[2px]">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </AccordionItem.Slot>
        </AccordionItem>
      </Accordion>

      <div className="mt-4 bg-[#665800] p-4 rounded-xl bg-opacity-75 hidden lg:block">
        <h5 className="text-sm lg:text-base mb-1">{title}</h5>
        <ul className="text-2xs lg:text-sm list-disc pl-4">
          {list?.map((item, key) => (
            <li key={key} className="py-[2px]">
              {item}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default BenefitPinaTrade;
