import RenderIf from 'components/basic/render-if';
import { useNavigate } from 'react-router-dom';
import { useGetStockThematicsQuery } from 'services/rtk-query/stock';
import IconSorting from 'components/svg/icon-sorting';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import IconTriangle from 'components/svg/icon-triangle';
import { useTableSorting } from 'hooks/useTableSorting';
import { automatedNumberFormat } from 'utils/format';
import { getColorStatus } from 'utils/stock/score';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import { useEffect } from 'react';

function SectionStockIHSG() {
  const isBrowserWakeUp = useBrowserWakeUp();
  const navigate = useNavigate();
  const {
    data: tempIDXList,
    isLoading,
    refetch
  } = useGetStockThematicsQuery({ type: 'idx', keyword: '' });
  const { data, sortingColumns, handleSorting, getSorting } =
    useTableSorting(tempIDXList, ['return'], ['desc']);

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  return (
    <>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small max-h-[500px]"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}>
        <table className="text-2xs w-full text-center font-quicksand-semibold">
          <thead>
            <tr className="text-gray-light-0-d dark:text-black-light-0-d">
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l sticky left-0 card-widget-gradient ${
                  sortingColumns?.[0] === 'name' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('name')}
                style={{ minWidth: '100px' }}>
                <span className="flex items-center gap-1 cursor-pointer">
                  {sortingColumns?.[0] === 'name' && (
                    <IconSorting
                      type={getSorting('name')}
                      className="h-3 w-3"
                    />
                  )}
                  Name
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'return' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'return' && (
                    <IconSorting
                      type={getSorting('return')}
                      className="h-3 w-3"
                    />
                  )}
                  %CHG
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'last' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('last')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'last' && (
                    <IconSorting
                      type={getSorting('last')}
                      className="h-3 w-3"
                    />
                  )}
                  Last
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'chg' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('chg')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'chg' && (
                    <IconSorting type={getSorting('chg')} className="h-3 w-3" />
                  )}
                  CHG
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'open' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('open')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'open' && (
                    <IconSorting
                      type={getSorting('open')}
                      className="h-3 w-3"
                    />
                  )}
                  Open
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'low' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('low')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'low' && (
                    <IconSorting type={getSorting('low')} className="h-3 w-3" />
                  )}
                  Low
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'high' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('high')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'high' && (
                    <IconSorting
                      type={getSorting('high')}
                      className="h-3 w-3"
                    />
                  )}
                  High
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'frequency' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('frequency')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'frequency' && (
                    <IconSorting
                      type={getSorting('frequency')}
                      className="h-3 w-3"
                    />
                  )}
                  Freq
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'volume' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('volume')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'volume' && (
                    <IconSorting
                      type={getSorting('volume')}
                      className="h-3 w-3"
                    />
                  )}
                  Volume
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'marketCap' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('marketCap')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'marketCap' && (
                    <IconSorting
                      type={getSorting('marketCap')}
                      className="h-3 w-3"
                    />
                  )}
                  M.Cap
                </span>
              </td>
            </tr>
          </thead>
          <tbody className="text-2xs dark:text-black-light-1-d">
            <RenderIf isTrue={data?.length === 0 && !isLoading}>
              <tr className="group">
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
              </tr>
            </RenderIf>

            <RenderIf isTrue={isLoading}>
              {Array(5)
                .fill(1)
                .map((el, key) => (
                  <tr key={key} className="group">
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                  </tr>
                ))}
            </RenderIf>

            {data?.map((item, key) => (
              <tr
                key={key}
                className="group hover:cursor-pointer"
                onClick={() => {
                  navigate(`/stock-market/index/${item?._id}`);
                }}>
                <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d sticky left-0 card-widget-gradient transition-all ease-in-out duration-150 p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 font-quicksand-semibold">
                  <div className="flex items-center text-xs">{item?.name}</div>
                  <div className="font-quicksand-regular mt-1">
                    <div className="flex items-center text-2xs">
                      <div className="flex items-center mr-2">
                        <IconTriangle className="h-2 w-2 text-green-base-l flex-none mr-1" />
                        <span>{item?.score?.up}</span>
                      </div>
                      <div className="flex items-center mr-2">
                        <IconTriangle className="h-2 w-2 text-red-base-l flex-none mr-1 transform rotate-180" />
                        <span>{item?.score?.down}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.return
                  )}`}>
                  {automatedNumberFormat(item?.return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.open - item?.last
                  )}`}>
                  {automatedNumberFormat(item?.last)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.chg
                  )}`}>
                  {automatedNumberFormat(item?.chg)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.open)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.low)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.high)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.frequency)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.volume)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.marketCap)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </OverlayScrollbarsComponent>

      <RenderIf isTrue={tempIDXList?.length === 0 && !isLoading}>
        <p className="text-sm text-gray-light-0-d">Belum ada data.</p>
      </RenderIf>
    </>
  );
}

export default SectionStockIHSG;
