import useDarkMode from 'hooks/useDarkMode';
import Skeleton from 'react-loading-skeleton';

export default function SkeletonTheme({ ...props }) {
  const { theme } = useDarkMode();
  let customProperties = { ...props };

  if (theme === 'dark') {
    customProperties = {
      ...customProperties,
      baseColor: '#151619',
      highlightColor: '#444444'
    };
  }

  return <Skeleton {...customProperties} />;
}
