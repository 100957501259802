import RenderIf from 'components/basic/render-if';
import IconImage from 'components/svg/icon-image';
import IconTrashCan from 'components/svg/icon-trash-can';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

function InputSignature({ onSignEnd = () => {}, onSelectFile = () => {}, onClear = () => {}, name }) {
  const ref = useRef(null);
  const [signature, setSignature] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [signatureImageURL, setSignatureImageURL] = useState(null);

  const clear = () => {
    ref?.current?.clear();
    setSignature(null);
    setSignatureImage(null);
    setSignatureImageURL(null);
    onClear()
  }

  return (
    <>
      <div className="border border-main-base-l rounded-xl overflow-hidden bg-gray-light-l">
        <RenderIf isTrue={!signatureImage}>
          <SignatureCanvas
            ref={ref}
            canvasProps={{ width: 436, height: 222, className: '' }}
            dotSize={4}
            onEnd={() => {
              const base64 = ref.current.getTrimmedCanvas().toDataURL('image/png');
              setSignature(base64);
              onSignEnd(base64);
            }}
          />
        </RenderIf>

        <RenderIf isTrue={signatureImage && !signature}>
          <img src={signatureImageURL} className='aspect-[436/222] block object-cover' />
        </RenderIf>
      </div>

      <div className='mt-4'>
        <RenderIf isTrue={signature || signatureImageURL}>
          <button onClick={clear} type='button' className='w-full py-2 text-xs px-3 flex justify-center items-center rounded-xl border-main-base-l border-2 text-main-base-l hover:opacity-75 transition-all ease-linear duration-150'>
            <IconTrashCan className="h-5 w-5 mr-1"/> Hapus
          </button>
        </RenderIf>

        <RenderIf isTrue={!signature && !signatureImageURL}>
          <label htmlFor={name} className='w-full py-2 cursor-pointer relative text-xs px-3 flex justify-center items-center rounded-xl border-main-base-l border-2 text-main-base-l hover:opacity-75 transition-all ease-linear duration-150'>
            <IconImage className="h-5 w-5 mr-1"/> Upload Image

            <input
              className="opacity-0 h-0 w-0 absolute"
              id={name}
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              name={name}
              onChange={(e) => {
                const file = e?.target?.files?.[0];
                if (file) {
                  onSelectFile(e);
                  setSignatureImage(file);
  
                  const reader = new FileReader();
                  reader.onload = function (e) {
                    setSignatureImageURL(e.target.result);
                  };
  
                  reader.readAsDataURL(file);
                }}
                }
            />
          </label>
        </RenderIf>
      </div>
    </>
  );
}

InputSignature.propTypes = {
  onSignEnd: PropTypes.func,
  onSelectFile: PropTypes.func,
  onClear: PropTypes.func,
  name: PropTypes.string,
};

export default InputSignature;
