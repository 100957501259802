import baseApi from '../base';

export const UploadApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createUploadKTP: build.mutation({
      query: (body = new FormData()) => {
        return {
          url: `/upload/ktp`,
          method: 'POST',
          body
        };
      },
      transformResponse: (res) => res?.payloads || null,
    }),
    createUploadNPWP: build.mutation({
      query: (body = new FormData()) => {
        return {
          url: `/upload/npwp`,
          method: 'POST',
          body
        };
      },
      transformResponse: (res) => res?.payloads || null,
    }),
    createUploadSelfie: build.mutation({
      query: (body = new FormData()) => {
        return {
          url: `/upload/selfie`,
          method: 'POST',
          body
        };
      },
      transformResponse: (res) => res?.payloads || null,
    }),
    createUploadSignature: build.mutation({
      query: (body = new FormData()) => {
        return {
          url: `/upload/signature`,
          method: 'POST',
          body
        };
      },
      transformResponse: (res) => res?.payloads || null,
    }),
    createUploadAvatar: build.mutation({
      query: (body = new FormData()) => {
        return {
          url: `/upload/avatar`,
          method: 'POST',
          body
        };
      },
      transformResponse: (res) => res?.payloads || null,
    }),
  })
});

export const {
  useCreateUploadKTPMutation,
  useCreateUploadNPWPMutation,
  useCreateUploadSelfieMutation,
  useCreateUploadSignatureMutation,
  useCreateUploadAvatarMutation
} = UploadApi;
