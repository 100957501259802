import RenderIf from 'components/basic/render-if';
import Tabs from 'components/tabs/tabs';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
// import PinaProMultiChart from './chart';
import PinaProMultiChartAndOrderBook from './chart-orderbook';
// import PinaProMultiCompanyProfile from './company-profile';
// import PinaProMultiCorpActionRHUPS from './corp-action-rhups';
import PinaProMultiDoneDetail from './done-detail';
import PinaProMultiDoneSummary from './done-summary';
import PinaProMultiFundamental from './fundamental';
import PinaProMultiNews from './news';
// import PinaProMultiOrderBook from './orderbook';
import { triggerSegmentEvent } from 'lib/segment';
import Card from 'components/basic/card';

function PinaProCompare() {
  const { type } = useParams();
  const navigate = useNavigate();

  const [activeTabIndex, setActiveIndexTab] = useState(0);
  const [firstRender, setFirsRender] = useState(true);

  const [tabs] = useState([
    {
      index: 0,
      text: 'Fundamental',
      slug: 'fundamental',
      ['data-qa-id']: 'btnNavTradingSell',
      analyticEventName: 'TR Fundamental Tab Clicked'
    },
    {
      index: 1,
      text: 'Chart & Orderbook',
      slug: 'chart-orderbook',
      ['data-qa-id']: 'btnNavTradingSell',
      analyticEventName: 'TR Chart & Orderbook Tab Clicked'
    },
    {
      index: 2,
      text: 'News',
      slug: 'news',
      ['data-qa-id']: 'btnNavTradingSell',
      analyticEventName: 'TR News Tab Clicked'
    },
    {
      index: 3,
      text: 'Done Summary',
      slug: 'done-summary',
      ['data-qa-id']: 'btnNavTradingSell',
      analyticEventName: 'TR Done Summary Tab Clicked'
    },
    {
      index: 4,
      text: 'Done Detail',
      slug: 'done-detail',
      ['data-qa-id']: 'btnNavTradingSell',
      analyticEventName: 'TR Done Detail Tab Clicked'
    }
  ]);

  const handleActiveTab = (index) => {
    let activeTab = find(tabs, ['index', index]);
    navigate(`/compare/${activeTab?.slug}`);
  };

  useEffect(() => {
    if (type) {
      let activeTab = find(tabs, ['slug', type]);
      setActiveIndexTab(activeTab?.index);
    }
  }, [type, tabs]);
  
  useEffect(() => {
    if (firstRender) {
      triggerSegmentEvent({ event: 'TR Compare Stock Screen Viewed' });
      setFirsRender(false);
    }
  }, [firstRender])

  return (
    <div className="p-4 dark:text-black-light-1-d zoom-2">
      <Helmet>
        <title>Compare Chart | Pina Trade</title>
      </Helmet>

      <h1 className='font-quicksand-semibold text-base mb-4'>Compare Stock</h1>
      <Card className='p-4 pt-2'>
        <Tabs
          initialOpenIndex={activeTabIndex}
          className="overflow-auto max-w-[80vw] lg:max-w-none flex lg:grid lg:grid-cols-5 font-quicksand-medium text-xs lg:text-sm mb-5 text-gray-light-0-d"
          data={tabs}
          onChange={handleActiveTab}>
          <RenderIf isTrue={activeTabIndex === 0}>
            <PinaProMultiFundamental />
          </RenderIf>

          <RenderIf isTrue={activeTabIndex === 1}>
            <PinaProMultiChartAndOrderBook />
          </RenderIf>

          <RenderIf isTrue={activeTabIndex === 2}>
            <PinaProMultiNews />
          </RenderIf>

          <RenderIf isTrue={activeTabIndex === 3}>
            <PinaProMultiDoneSummary />
          </RenderIf>

          <RenderIf isTrue={activeTabIndex === 4}>
            <PinaProMultiDoneDetail />
          </RenderIf>
        </Tabs>
      </Card>
    </div>
  );
}

export default PinaProCompare;
