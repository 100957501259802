import PropTypes from 'prop-types';

function IconMenuProfile({ className }) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 12.3783C5.50736 12.3783 4.5 13.3857 4.5 14.6283C4.5 15.0425 4.83579 15.3783 5.25 15.3783H12.75C13.1642 15.3783 13.5 15.0425 13.5 14.6283C13.5 13.3857 12.4926 12.3783 11.25 12.3783H6.75ZM3 14.6283C3 12.5572 4.67893 10.8783 6.75 10.8783H11.25C13.3211 10.8783 15 12.5572 15 14.6283C15 15.8709 13.9926 16.8783 12.75 16.8783H5.25C4.00736 16.8783 3 15.8709 3 14.6283Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.3783C7.75736 3.3783 6.75 4.38566 6.75 5.6283C6.75 6.87094 7.75736 7.8783 9 7.8783C10.2426 7.8783 11.25 6.87094 11.25 5.6283C11.25 4.38566 10.2426 3.3783 9 3.3783ZM5.25 5.6283C5.25 3.55723 6.92893 1.8783 9 1.8783C11.0711 1.8783 12.75 3.55723 12.75 5.6283C12.75 7.69936 11.0711 9.3783 9 9.3783C6.92893 9.3783 5.25 7.69936 5.25 5.6283Z"
      />
    </svg>
  );
}

IconMenuProfile.propTypes = {
  className: PropTypes.string
};

export default IconMenuProfile;
