import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import { useFormik } from 'formik';
import { ResetPasswordWithOTPSchema } from './reset-password-with-otp-form.validation';
import { useCreateResetPasswordMutation } from 'services/rtk-query/user';
import { useState } from 'react';
import IconLock from 'components/svg/icon-lock';
import { triggerSegmentEvent } from 'lib/segment';
import Pineaple from 'components/svg/pineaple';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import InputText from 'components/input/input-text';
import useGetErrorMessage from 'hooks/useGetErrorMessage';

function ResetPasswordWithOTPForm({ onSuccess = () => {}, token = '' }) {
  const { getErrorMessage } = useGetErrorMessage();
  const [createResetPassword] = useCreateResetPasswordMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      token: token,
      confirmPassword: '',
      newPassword: '',
      otp: ''
    },
    validateOnMount: false,
    validationSchema: ResetPasswordWithOTPSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true)
      try {
        const data = await createResetPassword(values).unwrap();
        onSuccess(data)

        triggerSegmentEvent({
          event: 'TR Reset Password PinaPro',
          properties: {
            email: values?.email
          }
        });
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsSubmitting(false)
      }
    }
  });

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen pb-20 lg:pb-0">
      <div className="bg-onboarding p-6 lg:px-28 lg:pb-28 text-white flex lg:py-16 relative">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto text-left">
          <Pineaple className="lg:ml-0 w-5 lg:w-12 h-auto" />
          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] my-3 text-white">
            Cek Email dan Masukkan Password Baru
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Kami telah mengirimkan Kode OTP ke email kamu. Masukan Kode OTP untuk melakukan konfirmasi penggantian password.
          </p>
        </div>
      </div>

      <div className="p-0 lg:p-16">
        <Card className="w-full px-6 lg:px-8 py-4 lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto">
          <form className="mt-5" onSubmit={formik.handleSubmit}>
            <h2 className='text-xl lg:text-2xl text-center lg:text-left font-quicksand-semibold mb-6 text-main-base-l'>Masukkan Kode OTP</h2>
            <div className='mb-5'>
              <InputText
                data-qa-id="txtOTPPasswordField"
                name="otp"
                type="password"
                placeholder="6 Digit Kode Konfirmasi"
                label="OTP"
                iconRight={<IconLock />}
                className="mb-2"
                value={formik.values.otp}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.otp && formik.errors.otp ? formik.errors.otp : ''}
              />
            </div>

            <div className='mb-5'>
              <InputText
                data-qa-id="txtPasswordField"
                name="newPassword"
                type="password"
                placeholder="Password Baru"
                label="Password Baru"
                iconRight={<IconLock />}
                className="mb-2"
                value={formik.values.newPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && formik.errors.newPassword ? formik.errors.newPassword : ''}
              />
            </div>

            <div className='mb-5'>
              <InputText
                data-qa-id="txtConfirmPasswordField"
                name="confirmPassword"
                type="password"
                placeholder="Konfirmasi Password Baru"
                label="Konfirmasi Password Baru"
                iconRight={<IconLock />}
                className="mb-2"
                value={formik.values.confirmPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : ''}
              />
            </div>

            <div className="mt-10 lg:mt-64 fixed lg:relative w-full bottom-0 left-0 p-6 lg:p-0 bg-white border-t lg:border-none">
              <button
                type="submit"
                disabled={!formik.isValid}
                className={`transition-all ease-in-out duration-150 hover:bg-opacity-75 h-11 flex w-full items-center justify-center font-quicksand-semibold font-bold text-sm rounded-[10px] overflow-hidden focus:outline-none ${
                  formik.isValid
                    ? 'bg-main-base-l text-white'
                    : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                }`}>
                {isSubmitting && <Spinner />}
                Ganti Password
              </button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

ResetPasswordWithOTPForm.propTypes = {
  token: PropTypes.string,
  onSuccess: PropTypes.func.isRequired
};

ResetPasswordWithOTPForm.defaultProps = {
  token: '',
  onSuccess: () => {}
};

export default ResetPasswordWithOTPForm;
