import PropTypes from 'prop-types'
import { useLocation, Navigate } from 'react-router-dom'
import { ROLE_USER, ROLE_PRO_USER } from 'constants/roles'


const RedirectBasedOnRole = ({ roles = [] }) => {
  const { state } = useLocation();

  const switcher = () => {
    if (roles?.some(role => role?.name === ROLE_PRO_USER)) {
      return <Navigate to={'/'} replace={true} state={state} />
    }

    if (roles?.some(role => role?.name === ROLE_USER)) {
      return <Navigate to={'/'} replace={true} state={state} />
    }

    return <Navigate to={'/logout'} replace={true} />
  }

  return switcher();
}

RedirectBasedOnRole.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default RedirectBasedOnRole;
