import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import CardOrderItem from 'components/cards/card-order-item';
import { currency } from 'utils/format';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import IconClose from 'components/svg/icon-close';
import dayjs from 'dayjs';
import RenderIf from 'components/basic/render-if';
import { ORDER_EXPIRED } from 'constants';
import StockAmendForm from 'components/form/stock-amend-form';
import { getOrderStatus, getColorStatusOrder } from 'utils/stock/order';
import StockWithdrawForm from 'components/form/stock-withdraw-form';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import IconCheck from 'components/svg/icon-check';
import Modal from 'components/modal/modal';
import { useGetOrderListQuery } from 'services/rtk-query/trade';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function OrderDetail({ data = {}, onClose = () => {}, amendWithdraw = false, type }) {
  const { user } = useBrowserTabAuth();
  const { data: trading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { data: orderList } = useGetOrderListQuery(null, { refetchOnMountOrArgChange: true });

  data = orderList?.items?.find((order) => order?.id === data?.id);
  
  const [open, setOpen] = useState(!isEmpty(data));
  const [isAmend, setIsAmend] = useState(type === 'AMEND');
  const [isWithdraw, setIsWithdraw] = useState(type === 'WITHDRAW');

  const sideText = data?.side === 'B' ? 'Beli' : 'Jual';
  const qty = data?.qty || 0;
  const lot = qty > 0 ? Math.abs(qty) / 100 : 0;
  const investment = qty * data?.price;
  const buyOrSell = data?.side;
  const ordersExpire = Object.values(ORDER_EXPIRED).find(({ id }) => id === data?.expiry)?.name ||'';
  const orderStatus = getOrderStatus(data?.state);
  const colorStatus = getColorStatusOrder(data?.state);

  const steps = [
    {
      // Delivered
      isFinished:
        data?.state === 'M' || // Match
        data?.state === 'P' || // Partial
        data?.state === 'O' || // Open
        data?.state === 'R' || // Reject
        data?.state === 'W' || // Withdraw
        data?.state === 'A' || // Amend
        data?.state === 'S' || // Saved
        data?.state === 'E' || // Need Approval
        data?.state === 'B' || // Basket
        data?.state === 'D',
      title: 'Pesanan sedang dikirim',
      subtitle: `${dayjs
        .unix(data?.dtSave / 1000 || null)
        .format('DD MMM YYYY')}`
    },
    {
      isFinished:
        data?.state === 'M' ||
        data?.state === 'P' ||
        data?.state === 'O' ||
        data?.state === 'R' ||
        data?.state === 'W' ||
        data?.state === 'A',
      title:
        buyOrSell === 'WC'
          ? 'Pesanan dana dalam proses pemeriksaan'
          : 'Pesanan telah terkirim Bursa',
      subtitle: ''
    },
    {
      isFinished: data?.state === 'M' || data?.state === 'P',
      title:
        buyOrSell === 'WC'
          ? 'Penarikan dana disetujui'
          : 'Pesanan saham berhasil',
      subtitle: ''
    }
  ];

  const handleClose = () => {
    setOpen(false);
    setIsAmend(false);
    setIsWithdraw(false);
    onClose();
  };

  useEffect(() => {
    if (!isEmpty(data)) setOpen(true);
  }, [data]);

  if (!open) return '';

  return (
    <Card className="mx-auto p-4 lg:p-8 relative overflow-hidden max-w-4xl dark:text-black-light-1-d dark:border dark:border-gray-base-d zoom-2">
      <h5 className="mb-4 text-sm">Detail Transaksi</h5>
      <a
        onClick={handleClose}
        className="absolute top-6 right-6"
        role="button"
        aria-label="close">
        <IconClose className="hover:text-main-base-l transition-all ease-in-out duration-150 dark:text-main-base-l hover:opacity-75" />
      </a>

      <div className="grid grid-cols-12 gap-6">
        <div className="p-4 lg:p-6 border dark:border-gray-base-d rounded-lg col-span-12 lg:col-span-7">
          <CardOrderItem item={data} />

          <div className="border-t dark:border-gray-base-d mt-6 pt-6">
            <h5 className='text-sm'>Status</h5>

            <ul>
              {steps.map((item, key) => (
                <li
                  key={key}
                  className="flex items-center font-quicksand-semibold py-2 border-b dark:border-gray-base-d last:border-b-0">
                  <IconCheck
                    className={`mr-2 h-6 w-6 ${
                      item?.isFinished
                        ? 'text-green-base-l'
                        : 'text-gray-light-0-d opacity-50'
                    }`}
                  />
                  <div>
                    <p className='text-sm'>{item?.title}</p>
                    <RenderIf isTrue={item?.subtitle != '' && item?.subtitle}>
                      <p className="text-xs font-quicksand-semibold text-gray-light-0-d">
                        {item?.subtitle}
                      </p>
                    </RenderIf>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="col-span-12 lg:col-span-5">
          <RenderIf isTrue={!isAmend}>
            <div className="p-4 lg:p-6 border rounded-lg dark:border-gray-base-d">
              <h5 className='text-sm'>Detail Order</h5>

              <RenderIf isTrue={amendWithdraw}>
                <div className="flex my-2 items-center">
                  <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                    No. Pemesanan
                  </span>
                  <span className="flex-grow text-right text-sm font-quicksand-bold">
                    {data?.clOID}
                  </span>
                </div>
              </RenderIf>

              <div className="flex my-2 items-center">
                <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                  Antrian di Sekuritas
                </span>
                <span className="flex-grow text-right text-sm font-quicksand-bold">
                  {data?.id}
                </span>
              </div>

              <div className="flex my-2 items-center">
                <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                  Antrian di IDX
                </span>
                <span className="flex-grow text-right text-sm font-quicksand-bold">
                  {data?.mktOID || '-'}
                </span>
              </div>

              <div className="flex my-2 items-center">
                <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                  Tipe Transaksi
                </span>
                <span className="flex-grow text-right text-sm font-quicksand-bold">
                  {sideText}
                </span>
              </div>

              <RenderIf isTrue={amendWithdraw}>
                <div className="flex my-2 items-center">
                  <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                    Opsi Antrian Order
                  </span>
                  <span className="flex-grow text-right text-sm font-quicksand-bold">
                    {ordersExpire}
                  </span>
                </div>

                <RenderIf isTrue={data?.expiryDate}>
                  <div className="flex my-2 items-center">
                    <span className="flex-none text-gray-light-0-d text-xs font-gotham-medium">
                      Expiry Date
                    </span>
                    <span className="flex-grow text-right text-sm font-gotham-bold">
                      {dayjs.unix(data?.expiryDate / 1000).format('DD MMM YYYY')}
                    </span>
                  </div>
                </RenderIf>

                <div className="flex my-2 items-center">
                  <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                    Berita
                  </span>
                  <span
                    className={`flex-grow text-right font-quicksand-bold text-2xs border-none ${colorStatus}`}>
                    {data?.lastText || '-'}
                  </span>
                </div>
              </RenderIf>

              <div className="flex my-2 items-center">
                <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                  Harga Per Saham
                </span>
                <span className="flex-grow text-right text-sm font-quicksand-bold">
                  {currency(data?.price, { removeSymbol: false })}
                </span>
              </div>

              <div className="flex my-2 items-center">
                <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                  Jumlah Lot
                </span>
                <span className="flex-grow text-right text-sm font-quicksand-bold">
                  {currency(lot, { removeSymbol: true })} Lot
                </span>
              </div>

              <RenderIf isTrue={amendWithdraw}>
                <div className="flex my-2 items-center">
                  <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                    Lot Selesai
                  </span>
                  <span className="flex-grow text-right text-sm font-quicksand-bold">
                    {currency(data?.cumQty / 100, { removeSymbol: true })} Lot
                  </span>
                </div>
              </RenderIf>

              <div className="flex my-2 items-center">
                <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                  Jumlah Dipesan
                </span>
                <span className="flex-grow text-right text-sm font-quicksand-bold">
                  {currency(investment, { removeSymbol: false })}
                </span>
              </div>

              <RenderIf isTrue={amendWithdraw}>
                <div className="flex my-2 items-center">
                  <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                    Waktu Pemesanan
                  </span>
                  <span className="flex-grow text-right text-sm font-quicksand-bold">
                    {dayjs(data?.dtSave).format('DD MMM YYYY - HH:mm:ss')}
                  </span>
                </div>
              </RenderIf>

              <RenderIf isTrue={!amendWithdraw}>
                <div className="flex my-2 items-center">
                  <span className="flex-none text-gray-light-0-d text-xs font-quicksand-semibold">
                    Waktu Pemesanan
                  </span>
                  <span className="flex-grow text-right text-sm font-quicksand-bold">
                    {dayjs(data?.dtSave).format('DD MMM YYYY')}
                  </span>
                </div>
              </RenderIf>

              <RenderIf isTrue={!amendWithdraw}>
                <a
                  href="/portfolio"
                  className="mt-6 bg-main-base-l text-white dark:text-black-base-d py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75">
                  Lihat Portfolio
                </a>
              </RenderIf>

              <RenderIf isTrue={amendWithdraw}>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => setIsAmend(true)}
                    type="button"
                    aria-label="amend"
                    disabled={['amend', 'match', 'reject', 'withdraw'].includes(orderStatus)}
                    className={`mt-6 bg-main-gold-d text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75 disabled:bg-gray-base-l disabled:text-gray-light-0-d disabled:bg-opacity-75 ${['amend', 'match', 'reject', 'withdraw'].includes(orderStatus) && 'bg-opacity-30'}`}>
                    Amend
                  </button>

                  <button
                    onClick={() => setIsWithdraw(true)}
                    disabled={['amend', 'match', 'reject', 'withdraw'].includes(
                      orderStatus
                    )}
                    className={`mt-6 bg-orange-2 dark:disabled:text-gray-base-d text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 disabled:bg-opacity-75 disabled:border-gray-base-l disabled:text-gray-base-l hover:bg-opacity-75 ${
                      ['amend', 'match', 'reject', 'withdraw'].includes(
                        orderStatus
                      ) && 'bg-opacity-30'
                    }`}>
                    Withdraw
                  </button>
                </div>
              </RenderIf>
            </div>
          </RenderIf>

          <RenderIf isTrue={isAmend}>
            <div className="p-4 lg:p-6 border rounded-lg dark:border-gray-base-d">
              <RenderIf isTrue={!isEmpty(data)}>
                <StockAmendForm
                  buyingPower={trading?.buyingpower}
                  orderDetail={data}
                  user={user}
                  onClickBackButton={() => setIsAmend(false)}
                  onSuccess={handleClose}
                />
              </RenderIf>
            </div>
          </RenderIf>

          <RenderIf isTrue={isWithdraw}>
            <Modal onClose={() => setIsWithdraw(false)}>
              <StockWithdrawForm
                isOpen={isWithdraw}
                onClose={() => setIsWithdraw(false)}
                data={{
                  id: data?.id,
                  code: data?.stID,
                  orderNumber: data?.clOID,
                  price: data?.price,
                  lot: Math.abs(data?.qty / 100)
                }}
                onSuccess={handleClose}
              />
            </Modal>
          </RenderIf>

        </div>
      </div>
    </Card>
  );
}

OrderDetail.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  amendWithdraw: PropTypes.bool,
  type: PropTypes.string,
};

export default OrderDetail;
