import Card from 'components/basic/card';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetOpeningAccountByUserIdQuery } from 'services/rtk-query/opening-account';
import { useGetUserProfileQuery } from 'services/rtk-query/user';
import { getOpeningAccountStep } from 'utils/opening-account';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import ListStatus from 'components/basic/list-status';
import { setAuth } from 'store/auth';
import { triggerSegmentEvent } from 'lib/segment';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import { useBrowserTabId } from 'hooks/useBrowserTabId';

function RDNStepStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useBrowserTabAuth();
  const browserTabID = useBrowserTabId();

  const { data: userDetail, isSuccess } = useGetUserProfileQuery(null, {
    refetchOnMountOrArgChange: true
  });
  const { data: oaStatus } = useGetOpeningAccountByUserIdQuery(auth?.user?.id);

  const handleCheckStatusRDN = () => {
    if (userDetail?.realAccount && auth?.user?.pinCreated) {
      const newAuth = {
        ...auth,
        user: { ...auth.user, realAccountActive: true, pinCreated: true }
      };

      dispatch(
        setAuth({
          ...newAuth,
          [browserTabID]: newAuth
        })
      );

      triggerSegmentEvent({
        event: 'TR Go to Dashboard Clicked'
      });
      return (window.location.href = '/');
    }

    navigate('/logout');
  };

  useEffect(() => {
    if (userDetail?.fillState < 5 && auth?.user?.pinCreated && isSuccess) {
      navigate('/onboarding/informasi-pribadi');
    } else if (!auth?.user?.pinCreated && isSuccess) {
      navigate('/onboarding/phone-number');
    }
  }, [userDetail, navigate, isSuccess, auth]);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
        <div className="bg-onboarding p-6 lg:px-28 lg:py-16 text-white relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
          <img
            className="absolute top-0 left-0 w-full h-full"
            src={BGOnboarding}
            alt="Pina Onboarding"
          />

          <img
            className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
            src={BGOnboardingOrnament}
            alt="Pina Onboarding"
          />

          <div className="relative z-10 max-w-2xl mx-auto">
            <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] mb-3 text-white">
              Pembukaan Rekening Investasi diproses dalam 1x24 jam
            </h1>

            <p className="text-sm lg:text-xl mb-6">
              PINA akan mengabari jika RDN kamu sudah selesai, melalui email ke{' '}
              <a className="hover:underline" href="mailto:cs@pina.id">
                cs@pina.id
              </a>{' '}
              dan Whatsapp ke nomor{' '}
              <a
                className="hover:underline"
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/628119832023">
                0811 9832 023
              </a>
            </p>
          </div>
        </div>

        <div className="p-0 lg:p-16">
          <Card className="lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto p-6 lg:p-8 relative lg:max-h-[calc(90vh)] overflow-auto">
            <div className="mx-auto">
              <h5 className="text-main-gold-d font-quicksand-regular text-2xl mb-6">
                Status Rekening <br /> Investasi kamu
              </h5>

              <ListStatus
                color="gold"
                verticalOnly
                data={[
                  {
                    title: (
                      <span className="text-gray-light-0-d">Buat PIN</span>
                    ),
                    subtitle: null,
                    status: userDetail?.pinCreated
                  },
                  {
                    title: (
                      <span className="text-gray-light-0-d">
                        Mengisi informasi data diri
                      </span>
                    ),
                    subtitle: null,
                    status:
                      getOpeningAccountStep(userDetail) >= 5 ||
                      userDetail?.realAccount
                  },
                  {
                    title: (
                      <span className="text-gray-light-0-d">
                        Permohonan Pembukaan Rekening Investasi terkirim
                      </span>
                    ),
                    subtitle: oaStatus
                      ? dayjs(oaStatus?.openingAccount?.createdAt).format(
                          'DD MMM YYYY HH:mm'
                        )
                      : '',
                    status: oaStatus || userDetail?.realAccount
                  },
                  {
                    title: (
                      <span className="text-gray-light-0-d">
                        Pembuatan nomor investor
                      </span>
                    ),
                    status:
                      oaStatus?.openingAccountTaskProgress?.foundSidSreBoDone ||
                      userDetail?.realAccount,
                    subtitle: null
                  },
                  {
                    title: (
                      <span className="text-gray-light-0-d">
                        Pembuatan rekening dana nasabah
                      </span>
                    ),
                    status:
                      oaStatus?.openingAccount?.sid ||
                      oaStatus?.openingAccount?.sre ||
                      userDetail?.realAccount,
                    subtitle: null
                  },
                  {
                    title: (
                      <span className="text-gray-light-0-d">
                        Rekening Investasi Aktif
                      </span>
                    ),
                    subtitle: 'Setelah verifikasi selesai (Max. 1x24 jam)',
                    status: userDetail?.realAccount
                  }
                ]}
              />

              <div className="mt-10 flex justify-center">
                <button
                  type="button"
                  onClick={handleCheckStatusRDN}
                  className={`flex px-10 w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden focus:outline-none bg-main-gold-d text-white cursor-pointer appearance-none hover:opacity-75 transition-all ease-linear duration-100`}>
                  Oke, Saya mengerti
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default RDNStepStatus;
