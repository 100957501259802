import RenderIf from 'components/basic/render-if';
import ModalDialog from 'components/modal/components/modal-dialog';
import Modal from 'components/modal/modal';
import IconBell from 'components/svg/icon-bell';
import Tabs from 'components/tabs/tabs';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ListMessage from 'pages/notification/_partials/list-message';
import ListPriceAlert from 'pages/notification/_partials/list-price-alert';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  invalidateUserNotification,
  useGetUserNotificationQuery,
  useUpdateUserNotificationReadAllMutation
} from 'services/rtk-query/user';
import { setUnreadNotifLength } from 'store/pina-pro';

function Notification() {
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();

  const { unreadNotifLength } = useSelector(({ pinaPro }) => pinaPro);
  const [activeTabIndex, setActiveIndexTab] = useState(0);
  const [animationActive, setAnimationActive] = useState(false);
  const [openModalReadAllConfirmation, setOpenModalReadAllConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: notifications } = useGetUserNotificationQuery({
    type: 'general',
    page: 0,
    size: 20,
    tag: ''
  });
  const [readAllNotification] = useUpdateUserNotificationReadAllMutation();

  const handleReadAllMessage = async () => {
    try {
      setIsSubmitting(true);
      await readAllNotification().unwrap();
      setOpenModalReadAllConfirmation(false);
      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (notifications?.content?.length > 0) {
      dispatch(setUnreadNotifLength(notifications?.unreaded));
      setAnimationActive(true);
      setTimeout(() => setAnimationActive(false), 2000);
    }
  }, [notifications, dispatch]);

  return (
    <div className="relative mx-3 lg:mx-4 flex items-center group dark:text-black-light-1-d">
      <button
        type="button"
        className={`text-main-base-l hover:opacity-75 relative ${
          unreadNotifLength > 0 && animationActive && 'animate-head-shake'
        }`}>
        <IconBell className="h-5 w-5" />
        {unreadNotifLength > 0 && (
          <span className="absolute top-0 right-0 h-2 w-2 bg-red-base-l text-white font-quicksand-semibold justify-center items-center flex rounded-full text-2xs"></span>
        )}
      </button>

      <div
        style={{ maxWidth: 'calc(100vw - 2rem)' }}
        className="fixed lg:absolute w-[400px] pt-5 right-4 lg:right-0 hidden group-hover:block top-12 lg:top-full">
        <div className="shadow-pro rounded-xl card-widget-gradient p-6 pb-0 border dark:border-gray-base-d">
          <div className="flex justify-between items-center">
            <h5 className="text-sm">Notifikasi</h5>

            <RenderIf isTrue={unreadNotifLength > 0}>
              <a
                onClick={() => setOpenModalReadAllConfirmation(true)}
                role="button"
                className="text-right text-main-base-l hover:opacity-75 text-xs font-quicksand-semibold">
                Tandai Sudah Baca
              </a>
            </RenderIf>
          </div>

          <Tabs
            initialOpenIndex={activeTabIndex}
            className="grid grid-cols-2 font-quicksand-semibold text-gray-light-0-d text-sm -mx-6 my-3"
            data={[
              {
                index: 0,
                text: 'Semua Transaksi'
              },
              {
                index: 1,
                text: 'Price Alerts'
              }
            ]}
            onChange={(index) => setActiveIndexTab(index)}></Tabs>

          <div
            className="-mx-6 overflow-auto"
            style={{ maxHeight: 'calc(100vh - 16rem)' }}>
            <div className="px-6 relative">
              {activeTabIndex === 0 && <ListMessage />}
              {activeTabIndex === 1 && <ListPriceAlert />}
            </div>
          </div>
        </div>
      </div>

      <RenderIf isTrue={openModalReadAllConfirmation}>
        <Modal onClose={() => setOpenModalReadAllConfirmation(false)}>
          <ModalDialog
            header={<h5 className="font-quicksand-semibold text-base">Tandai baca semua pesan?</h5>}
            submitTitle="Lanjutkan"
            backTitle="Batal"
            isSubmitting={isSubmitting}
            onClose={() => setOpenModalReadAllConfirmation(false)}
            onSubmit={handleReadAllMessage}>
            <p className="font-quicksand-semibold text-sm">Seluruh pesan akan menjadi sudah dibaca dan tidak dapat dibatalkan.</p>
          </ModalDialog>
        </Modal>
      </RenderIf>
    </div>
  );
}

export default Notification;
