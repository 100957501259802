import LoginForm from 'components/form/login-form';
import { Helmet } from 'react-helmet-async';

function LoginPage() {
  return (
    <>
      <Helmet>
        <title>Sign In | Pina Trade</title>
      </Helmet>
      <div className="flex items-center justify-center lg:min-h-screen bg-white lg:bg-transparent">
        <div className="p-0 lg:p-10">
          <LoginForm />
        </div>
      </div>
    </>
  );
}

export default LoginPage;
