import PropTypes from 'prop-types';
import { useGetStockByCodeQuery, useGetStockInfoByCodeQuery } from 'services/rtk-query/stock';
import { automatedNumberFormat, currency } from 'utils/format';

function StockDetailStatistic({ symbol }) {
  const { data: stockDetail } = useGetStockByCodeQuery(symbol, { refetchOnMountOrArgChange: true });
  const { data: stockDetailInfo } = useGetStockInfoByCodeQuery(symbol, { refetchOnMountOrArgChange: true });
  
  const General = () => {
    return (
      <div className="mb-3">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Market Cap
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetail?.marketCap || stockDetailInfo?.market_cap || 0)}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Dividend Yield
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.dividen_yield || stockDetail?.financial_report?.dividen_yield || 0)
              )}
              %
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Price Earnings Ratio (PER) *
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.per || 0)}x
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Price Sales Ratio (PSR) *
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.financial_report?.price_sales_ratio || 0)}x
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Price Book Value (PBV)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.financial_report?.pbv || 0)}x
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Price Cash Flow Ratio (PCFR) *
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.financial_report?.price_cash_flow_ratio || 0)}x
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              52W High
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {currency(stockDetail?.['52w_high'] || 0, { removeSymbol: true })}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              52W Low
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {currency(stockDetail?.['52w_low'] || 0, { removeSymbol: true })}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const Earning = () => {
    return (
      <div className="mb-3">
        <h5 className="text-xs mb-1">Earning</h5>
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Dividend Per Share (DPS)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                stockDetailInfo?.financial_report?.dps || 0
              )}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Earning Per Share (EPS) *
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                stockDetailInfo?.earning_per_share || 0
              )}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Revenue Per Share (RPS) *
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                stockDetailInfo?.financial_report?.revenue_per_share || 0
              )}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Book Value Per Share (BVPS)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                stockDetailInfo?.financial_report?.bvps_equity_to_parent_entity || 0
              )}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Cash Flow Per Share (CFPS) *
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                stockDetailInfo?.financial_report?.cash_flow_per_share || 0
              )}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Cash Equiv. Per Share (CEPS)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                stockDetailInfo?.financial_report?.cash_equivalent_per_share || 0
              )}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Net Assets Per Share (NAVS)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                stockDetailInfo?.financial_report?.net_assets_per_share || 0
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const Fundamental = () => {
    return (
      <div className="mb-3">
        <h5 className="text-xs mb-1">Fundamental</h5>
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Cash Equivalent
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.cash_equivalent || 0)}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Total Assets
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.total_assets || 0)}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Total Liabilitas
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                stockDetailInfo?.total_liabilities || 0
              )}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Total Equity
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.financial_report?.total_equity_equity_to_parent_entity || 0)}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Total Sales
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.total_sales || 0)}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Cash Flow
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.financial_report?.cash_from_operating_act || 0)}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Net Income
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(stockDetailInfo?.net_income || 0)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const Profitabilitas = () => {
    return (
      <div className="mb-3">
        <h5 className="text-xs mb-1">Profitabilitas</h5>
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Dividend Payout Ratio (DPR)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.financial_report?.dpr || 0) * 100
              )}
              %
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Gross Profit Margin (GPM)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.gross_profit_margin || 0) * 100
              )}
              %
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Operating Profit Margin (OPM)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.operating_profit_margin || 0) * 100
              )}
              %
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Net Profit Margin (NPM)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.net_profit_margin || 0) * 100
              )}
              %
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Earnings Before <br/> Interest & Tax Margin (EBITM)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.financial_report?.ebit_margin || 0) * 100
              )}
              %
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Return On Assets (ROA) *
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat((stockDetailInfo?.roa || 0) * 100)}%
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Return On Equity (ROE) *
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat((stockDetailInfo?.financial_report?.roe_equity_to_parent_entity || 0) * 100)}%
            </span>
          </div>
        </div>
      </div>
    );
  };

  const Solvabilitas = () => {
    return (
      <div className="mb-3">
        <h5 className="text-xs mb-1">Solvabilitas</h5>
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Debt Equity Ratio (DER)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.financial_report?.der_equity_to_parent_entity || 0) * 100
              )}%
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Cash Ratio (CR)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.financial_report?.cash_ratio || 0) * 100
              )}%
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Quick Ratio (QR)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.financial_report?.quick_ratio || 0) * 100
              )}%
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="flex-none text-gray-light-0-d dark:text-black-light-0-d text-xs font-quicksand-regular">
              Current Ratio (CRR)
            </span>
            <span className="flex-auto font-quicksand-semibold text-right text-xs">
              {automatedNumberFormat(
                (stockDetailInfo?.financial_report?.current_ratio || 0) * 100
              )}%
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-3 dark:text-black-light-1-d">
      <div>
        <General />
        <Fundamental />
      </div>
      <div>
        <Earning />
        <Profitabilitas />
        <Solvabilitas />
      </div>
    </div>
  );
}

StockDetailStatistic.propTypes = {
  symbol: PropTypes.string
};

export default StockDetailStatistic;
