export const checkBrowserSupport = () => {
  var ua = navigator.userAgent || navigator.vendor;
  var isInstagram = ua.indexOf('Instagram') > -1 ? true : false;
  var isWhatsapp = ua.indexOf('Whatsapp') > -1 ? true : false;
  var isFacebook = ua.indexOf('FB') > -1 ? true : false;

  if (isInstagram || isFacebook || isWhatsapp) {
    if (/iPad|iPhone|iPod/.test(ua)) {
      return false;
    }
  }

  return true;
};