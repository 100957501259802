import RenderIf from 'components/basic/render-if';
import { useState } from 'react';
import RDNStep02UploadKTP from './upload-ktp';
import RDNStep02DetailKTP from './detail-ktp';

function RDNStep02() {
  const [OCRData, setOCRData] = useState(null);

  return (
    <>
      <RenderIf isTrue={!OCRData}>
        <RDNStep02UploadKTP onSuccess={(OCRData) => setOCRData(OCRData)} />
      </RenderIf>

      <RenderIf isTrue={OCRData}>
        <RDNStep02DetailKTP
          OCRData={OCRData}
          onClickBack={() => setOCRData(null)}
        />
      </RenderIf>
    </>
  );
}

export default RDNStep02;
