import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { useGetTradeListQuery } from 'services/rtk-query/trade';
import { useEffect, useMemo, useState } from 'react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import ImageEmptyState from 'assets/images/mascot/mascot-empty-orderlist.png';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import { groupBy, sumBy } from 'lodash';
import { orderBy } from 'lodash';
import { ORDER_COST } from 'constants';
import DoneDetailGrouping from './done-detail-grouping';
import InputCheckBox from 'components/input/input-check-box';
import DoneDetailAll from './done-detail-all';
import DoneDetailGroupingPrice from './done-detail-grouping-price';
import ReturnTextValue from './done-detail-return-value';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function TWOrderlistDoneDetail({ title = 'Trade List' }) {
  const isBrowserWakeUp = useBrowserWakeUp();
  const { user } = useBrowserTabAuth();
  const {
    data: tradeList,
    isLoading,
    refetch
  } = useGetTradeListQuery(user?.username, { refetchOnMountOrArgChange: true });

  const [data, setData] = useState({});
  const [sortBy, setSortBy] = useState({ s: 'time', o: 'asc' });

  const [groupingBy, setGroupingBy] = useState('ungrouping');

  const handleSorting = (field) => {
    setSortBy({ s: field, o: sortBy?.o === 'asc' ? 'desc' : 'asc' });
  };

  const getSorting = (field) => {
    if (sortBy?.o === 'asc' && sortBy?.s === field) return 1;
    if (sortBy?.o === 'desc' && sortBy?.s === field) return 2;
    return null;
  };

  const groupingByCode = useMemo(() => {
    return Object?.keys(data)?.map((obj) => {
      const groupBySide = groupBy(data[obj], 'side');

      return {
        stID: obj,
        listBySide: Object?.keys(groupBySide)?.map((objSide) => {
          const groupByPrice = groupBy(groupBySide[objSide], 'price');

          let total = 0;
          let totalLot = sumBy(groupBySide[objSide], 'qty');

          groupBySide[objSide]?.forEach((tr) => {
            total += tr?.price * tr?.qty;
          });

          const amount =
            objSide === 'B'
              ? total + ORDER_COST.buy * total
              : total - ORDER_COST.sell * total;

          return {
            stID: obj,
            side: objSide,
            averagePrice: total / totalLot,
            lot: totalLot / 100,
            value: total,
            amount,
            list: groupBySide[objSide],
            listByPrice: Object?.keys(groupByPrice)?.map((objPrice) => {
              let total = 0;
              let totalLot = sumBy(groupByPrice[objPrice], 'qty');

              groupByPrice[objPrice]?.forEach((tr) => {
                total += tr?.price * tr?.qty;
              });

              const amount =
                objSide === 'B'
                  ? total + ORDER_COST.buy * total
                  : total - ORDER_COST.sell * total;

              return {
                stID: obj,
                side: objSide,
                price: objPrice,
                lot: totalLot / 100,
                value: total,
                amount,
                list: groupByPrice[objPrice]
              };
            })
          };
        }),
        listSingle: data[obj]
      };
    });
  }, [data]);

  const tradeListBuy = useMemo(() => {
    return tradeList?.arr
      ?.filter((item) => item?.side === 'B')
      .map((item) => {
        const tradeValue = item?.price * item?.qty;
        const amount = tradeValue + ORDER_COST.buy * tradeValue;

        return {
          ...item,
          tradeValue,
          amount
        };
      });
  }, [tradeList]);

  const tradeListSell = useMemo(() => {
    return tradeList?.arr
      ?.filter((item) => item?.side === 'S')
      .map((item) => {
        const tradeValue = item?.price * item?.qty;
        const amount = tradeValue - ORDER_COST.sell * tradeValue;

        return {
          ...item,
          tradeValue,
          amount
        };
      });
  }, [tradeList]);

  useEffect(() => {
    if (tradeList?.arr?.length > 0) {
      setData(
        groupBy(
          orderBy(
            tradeList?.arr?.map((item) => {
              const tradeValue = item?.price * item?.qty;
              const amount =
                item?.side === 'B'
                  ? tradeValue + ORDER_COST.buy * tradeValue
                  : tradeValue - ORDER_COST.sell * tradeValue;

              return { ...item, tradeValue, amount };
            }),
            [sortBy?.s],
            [sortBy?.o]
          ),
          'stID'
        )
      );
    }
  }, [tradeList, sortBy]);

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  return (
    <div className="p-1 -mt-3 dark:text-black-light-1-d h-full">
      <RenderIf isTrue={title}>
        <h5 className="mb-3 text-sm">{title}</h5>
      </RenderIf>

      <RenderIf isTrue={(tradeList?.arr?.length || 0) === 0 && !isLoading}>
        <div className="text-center p-10 dark:text-black-light-0-d">
          <img
            src={ImageEmptyState}
            alt="empty"
            className="h-14 w-14 mx-auto mb-4"
          />
          <h2 className="text-base lg:text-2xl mb-1 font-quicksand-semibold">
            Belum ada order saham yang match
          </h2>
          <p className="text-xs lg:text-sm">
            Mulai transaksi dan lihat status order yang match disini
          </p>
        </div>
      </RenderIf>

      <RenderIf
        isTrue={((tradeList?.arr?.length || 0) > 0 && !isLoading) || isLoading}>
        <RenderIf isTrue={isLoading}>
          <table className="w-full">
            {[1, 2].map((item) => (
              <tr key={item}>
                <td className="p-1">
                  <SkeletonTheme
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </td>
                <td className="p-1">
                  <SkeletonTheme
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </td>
                <td className="p-1">
                  <SkeletonTheme
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </td>
                <td className="p-1">
                  <SkeletonTheme
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </td>
                <td className="p-1">
                  <SkeletonTheme
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </td>
                <td className="p-1">
                  <SkeletonTheme
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </td>
                <td className="p-1">
                  <SkeletonTheme
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </td>
                <td className="p-1">
                  <SkeletonTheme
                    width="50px"
                    height="1rem"
                    borderRadius="6px"
                  />
                </td>
              </tr>
            ))}
          </table>
        </RenderIf>

        <RenderIf isTrue={!isLoading}>
          <div className="flex items-center text-xs my-6 gap-4">
            <InputCheckBox
              label={
                <span className="font-quicksand-regular mt-[3px] dark:text-black-light-0-d">
                  Ungrouping
                </span>
              }
              className="mb-0"
              id={`ungrouping`}
              name={`ungrouping`}
              value={'ungrouping'}
              checked={groupingBy === 'ungrouping'}
              onChange={(e) => setGroupingBy(e.target.value)}
            />

            <InputCheckBox
              label={
                <span className="font-quicksand-regular mt-[3px] dark:text-black-light-0-d">
                  Group By Side
                </span>
              }
              className="mb-0"
              id={`grouping-by-side`}
              name={`grouping-by-side`}
              value={'grouping-by-side'}
              checked={groupingBy === 'grouping-by-side'}
              onChange={(e) => setGroupingBy(e.target.value)}
            />

            <InputCheckBox
              label={
                <span className="font-quicksand-regular mt-[3px] dark:text-black-light-0-d">
                  Group By Price
                </span>
              }
              className="mb-0"
              id={`grouping-by-price`}
              name={`grouping-by-price`}
              value={'grouping-by-price'}
              checked={groupingBy === 'grouping-by-price'}
              onChange={(e) => setGroupingBy(e.target.value)}
            />
          </div>

          <RenderIf isTrue={groupingBy === 'ungrouping'}>
            <div className="h-[calc(100%-120px)]">
              <DoneDetailAll
                sortBy={sortBy}
                data={groupingByCode}
                getSorting={getSorting}
                handleSorting={handleSorting}
              />

              <table className="w-full text-center font-quicksand-regular text-2xs border-gray-base-l dark:border-gray-base-d mb-0 mt-5 border-t">
                <tbody className="dark:text-black-light-1-d">
                  <tr className="border-b text-left dark:border-gray-base-d">
                    <td className="p-1 font-quicksand-semibold text-black-base-d dark:text-black-light-1-d">
                      &nbsp;
                    </td>
                    <td className="p-1 font-quicksand-semibold text-black-base-d dark:text-black-light-1-d">
                      &nbsp;
                    </td>
                    <td className="p-1">&nbsp;</td>
                    <td className="p-1 text-right font-quicksand-semibold">
                      Nett Total
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold">
                      <p>LOT</p>
                      <ReturnTextValue
                        suffix=""
                        reverseColor
                        buyValue={sumBy(tradeListBuy, 'qty') / 100}
                        sellValue={sumBy(tradeListSell, 'qty') / 100}
                      />
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold">
                      <p>Value</p>
                      <ReturnTextValue
                        prefix=""
                        buyValue={sumBy(tradeListBuy, 'tradeValue')}
                        sellValue={sumBy(tradeListSell, 'tradeValue')}
                      />
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold">
                      <p>Amount</p>
                      <ReturnTextValue
                        prefix=""
                        buyValue={sumBy(tradeListBuy, 'amount')}
                        sellValue={sumBy(tradeListSell, 'amount')}
                      />
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold text-transparent">
                      &nsbp;
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold text-transparent">
                      &nsbp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </RenderIf>

          <RenderIf isTrue={groupingBy === 'grouping-by-side'}>
            <div className="h-[calc(100%-120px)]">
              <DoneDetailGrouping
                sortBy={sortBy}
                data={groupingByCode}
                getSorting={getSorting}
                handleSorting={handleSorting}
              />

              <table className="w-full text-center font-quicksand-regular text-2xs border-gray-base-l dark:border-gray-base-d mb-0 mt-5 border-t">
                <tbody className="dark:text-black-light-1-d">
                  <tr className="border-b text-left dark:border-gray-base-d">
                    <td className="p-1 w-[10%] font-quicksand-semibold text-black-base-d dark:text-black-light-1-d">
                      &nbsp;
                    </td>
                    <td className="p-1 w-[5%] font-quicksand-semibold text-black-base-d dark:text-black-light-1-d">
                      &nbsp;
                    </td>
                    <td className="p-1 w-[15%] text-right font-quicksand-semibold">
                      Nett Total
                    </td>
                    <td className="p-1 w-[17.5%] text-right font-quicksand-semibold">
                      <p>LOT</p>
                      <ReturnTextValue
                        suffix=""
                        reverseColor
                        buyValue={sumBy(tradeListBuy, 'qty') / 100}
                        sellValue={sumBy(tradeListSell, 'qty') / 100}
                      />
                    </td>
                    <td className="p-1 w-[17.5%] text-right font-quicksand-semibold">
                      <p>Value</p>
                      <ReturnTextValue
                        prefix=""
                        buyValue={sumBy(tradeListBuy, 'tradeValue')}
                        sellValue={sumBy(tradeListSell, 'tradeValue')}
                      />
                    </td>
                    <td className="p-1 w-[17.5%] text-right font-quicksand-semibold">
                      <p>Amount</p>
                      <ReturnTextValue
                        prefix=""
                        buyValue={sumBy(tradeListBuy, 'amount')}
                        sellValue={sumBy(tradeListSell, 'amount')}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </RenderIf>

          <RenderIf isTrue={groupingBy === 'grouping-by-price'}>
            <div className="h-[calc(100%-120px)]">
              <DoneDetailGroupingPrice
                sortBy={sortBy}
                data={groupingByCode}
                getSorting={getSorting}
                handleSorting={handleSorting}
              />

              <table className="w-full text-center font-quicksand-regular text-2xs border-gray-base-l dark:border-gray-base-d mb-0 mt-5 border-t">
                <tbody className="dark:text-black-light-1-d">
                  <tr className="border-b text-left dark:border-gray-base-d">
                    <td className="p-1 font-quicksand-semibold text-black-base-d dark:text-black-light-1-d">
                      &nbsp;
                    </td>
                    <td className="p-1 font-quicksand-semibold text-black-base-d dark:text-black-light-1-d">
                      &nbsp;
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold">
                      Nett Total
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold">
                      <ReturnTextValue
                        suffix=" LOT"
                        reverseColor
                        buyValue={sumBy(tradeListBuy, 'qty') / 100}
                        sellValue={sumBy(tradeListSell, 'qty') / 100}
                      />
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold">
                      <ReturnTextValue
                        prefix=""
                        buyValue={sumBy(tradeListBuy, 'tradeValue')}
                        sellValue={sumBy(tradeListSell, 'tradeValue')}
                      />
                    </td>
                    <td className="p-1 text-right font-quicksand-semibold">
                      <ReturnTextValue
                        prefix=""
                        buyValue={sumBy(tradeListBuy, 'amount')}
                        sellValue={sumBy(tradeListSell, 'amount')}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </RenderIf>
        </RenderIf>
      </RenderIf>
    </div>
  );
}

TWOrderlistDoneDetail.propTypes = {
  title: PropTypes.any
};

export default TWOrderlistDoneDetail;
