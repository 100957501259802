import { useDispatch, useSelector } from "react-redux";
import { setWatchStockList } from "store/pina-pro";

const useCompareStock = () => {
  const dispatch = useDispatch();
  const watchStockList = useSelector(({ pinaPro }) => pinaPro?.watchStockList);

  const addToCompareStock = (_stock, callback = () => {}) => {
    let _tempWatchStockList = [];
    let isFound = false;
    
    const findExistingStock = watchStockList?.find(item => item?.code === _stock?.code);

    if (findExistingStock) {
      return callback();
    }
    
    watchStockList?.map((item) => {
      if (!item?.code && !isFound) {
        _tempWatchStockList.push({ code: _stock?.code, name: _stock?.name });
        isFound = true;
      } else {
        _tempWatchStockList.push(item);
      }
    });

    if (!isFound) {
      _tempWatchStockList.push({ code: _stock?.code, name: _stock?.name });
    }

    if (_tempWatchStockList?.length > 6) {
      _tempWatchStockList = _tempWatchStockList.filter((item, key) => key != 0);
    }

    dispatch(setWatchStockList(_tempWatchStockList));
    callback();
  }

  return { addToCompareStock }
}

export { useCompareStock }