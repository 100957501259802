import PropTypes from 'prop-types';
function IconEllipsisVertical({ className }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}>
      <circle cx="9" cy="4" r="1.5" />
      <circle cx="9" cy="9" r="1.5" />
      <circle cx="9" cy="14" r="1.5" />
    </svg>
  );
}

IconEllipsisVertical.propTypes = {
  className: PropTypes.string
};
export default IconEllipsisVertical;
