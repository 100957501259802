import PropTypes from 'prop-types';

function IconUser({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 13.3334C6.11928 13.3334 4.99999 14.4526 4.99999 15.8334C4.99999 16.2936 5.37309 16.6667 5.83333 16.6667H14.1667C14.6269 16.6667 15 16.2936 15 15.8334C15 14.4526 13.8807 13.3334 12.5 13.3334H7.5ZM3.33333 15.8334C3.33333 13.5322 5.19881 11.6667 7.5 11.6667H12.5C14.8012 11.6667 16.6667 13.5322 16.6667 15.8334C16.6667 17.2141 15.5474 18.3334 14.1667 18.3334H5.83333C4.45262 18.3334 3.33333 17.2141 3.33333 15.8334Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.33335C8.61928 3.33335 7.49999 4.45264 7.49999 5.83335C7.49999 7.21407 8.61928 8.33335 10 8.33335C11.3807 8.33335 12.5 7.21407 12.5 5.83335C12.5 4.45264 11.3807 3.33335 10 3.33335ZM5.83333 5.83335C5.83333 3.53217 7.69881 1.66669 10 1.66669C12.3012 1.66669 14.1667 3.53217 14.1667 5.83335C14.1667 8.13454 12.3012 10 10 10C7.69881 10 5.83333 8.13454 5.83333 5.83335Z"
      />
    </svg>
  );
}

IconUser.propTypes = {
  className: PropTypes.string
};

IconUser.defaultProps = {
  className: ''
};

export default IconUser;
