import Breadcrumb from 'components/basic/breadcrumb';
import { Helmet } from 'react-helmet-async';
import SectionIDXStockList from './_partials/section-idx-stock-list';

function PageTradingSearchIDX() {
  return (
    <div>
      <div className="p-4">
        <Helmet>
          <title>Saham Index | Pina Trade</title>
        </Helmet>

        <Breadcrumb
          data={[
            {
              link: '/stock-market',
              title: 'Stock Market',
              icon: null
            },
            {
              link: '#',
              title: 'Saham Index',
              icon: null
            }
          ]}
        />

        <div className="grid grid-cols-1">
          <SectionIDXStockList />
        </div>
      </div>
    </div>
  );
}

export default PageTradingSearchIDX;
