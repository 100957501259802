import PropTypes from 'prop-types';
import useOuterClick from 'hooks/useOuterClick';
import { useEffect, useMemo, useRef, useState } from 'react';
import CardStockItem from 'components/cards/card-stock-item';
import { useGetStockInfoByCodeQuery } from 'services/rtk-query/stock';
import { currency } from 'utils/format';
import RenderIf from 'components/basic/render-if';

import styles from './css/input-stock.module.css';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import IconSearch from 'components/svg/icon-search';
import { getStockRemarkSymbol } from 'utils/stock/remark';
import { checkCodeNotation } from 'utils/stock/notation';
import Tooltip from 'components/basic/tooltip';
import { orderBy } from 'lodash';
import cn from 'utils/classname';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import IconTriangle from 'components/svg/icon-triangle';

function PriceStock({ code }) {
  const { stockDetail } = useRealtimeStock(code);
  const [toggleActive, setToggleActive] = useState(false);
  const [tempStockDetail, setTempStockDetail] = useState(null);

  useEffect(() => {
    if (
      Number(tempStockDetail?.lastTradedPrice) !=
      Number(stockDetail?.lastTradedPrice)
    ) {
      setTempStockDetail(stockDetail);
      setToggleActive(tempStockDetail);
    }
  }, [stockDetail, tempStockDetail]);

  if (!code) return '';

  return (
    <div
      className={`${toggleActive && 'animate-blink'} ${styles['price-stock']}`}
      onAnimationEnd={() => {
        setToggleActive(false);
      }}>
      <span className="text-sm font-quicksand-semibold text-right mb-0 leading-4 dark:text-black-light-1-d flex items-center gap-1">
        {currency(stockDetail?.lastTradedPrice || 0, { removeSymbol: true })}
        <span
          className={`text-2xs block ${
            stockDetail?.score?.type === 'plus'
              ? 'text-green-base-l'
              : stockDetail?.score?.type === 'minus'
              ? 'text-red-base-l'
              : 'text-gray-light-0-d'
          }`}>
          <RenderIf isTrue={stockDetail?.score?.type === 'plus'}>
            <IconTriangle className="h-3 w-3" />
          </RenderIf>
          <RenderIf isTrue={stockDetail?.score?.type === 'minus'}>
            <IconTriangle className="h-3 w-3 transform rotate-180" />
          </RenderIf>
        </span>
      </span>
    </div>
  );
}

PriceStock.propTypes = {
  code: PropTypes.string
};

function StockRemark({ symbol }) {
  const { data: stockInfo, isLoading: isLoadingStockInfo } =
    useGetStockInfoByCodeQuery(symbol, { refetchOnMountOrArgChange: true });

  return (
    <RenderIf
      isTrue={
        (getStockRemarkSymbol(stockInfo?.remarks2) || [])?.length > 0 &&
        !isLoadingStockInfo
      }>
      <div className="flex items-center font-quicksand-bold text-2xs p-1 px-2 rounded-md -mt-1">
        {(getStockRemarkSymbol(stockInfo?.remarks2) || [])?.map((item, key) => (
          <div key={key} className="flex items-center">
            <RenderIf isTrue={key === 0}>
              <span className="w-2 h-2 bg-orange-1 rounded-full mr-[2px]"></span>
            </RenderIf>

            <Tooltip
              tooltipHTML={
                <p className="text-2xs max-w-[250px]">
                  {checkCodeNotation(item)}
                </p>
              }>
              <span className="text-black-base-l dark:text-black-light-1-d">
                {item}
              </span>
            </Tooltip>
          </div>
        ))}
      </div>
    </RenderIf>
  );
}

function InputStock({
  value = '',
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  className,
  placeholder = 'SIMBOL',
  disabled,
  defaultStocks = [],
  excludeStocks = [],
  label = 'Stock',
  showPriceStock = true,
  iconRight = false,
  maxHeight = '300px',
  isClearable = false,
  size = 'normal',
  color = '',
  showRemarkInput = true,
  onLoading = () => {}
}) {
  const refInput = useRef(null);
  const [showListStock, setShowListStock] = useState(false);
  const [keyword, setKeyword] = useState(value);

  const stocks = useMemo(() => {
    let results = orderBy(window?.LIST_SYMBOL, 'symbol')
      ?.filter(
        (item) =>
          item?.symbol.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
      )
      ?.filter((item, key) => key < 10);

    if (!results?.length) {
      results = orderBy(window?.LIST_SYMBOL, 'symbol')
        ?.filter(
          (item) =>
            item?.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
        )
        ?.filter((item, key) => key < 10);
    }

    return results;
  }, [window.LIST_SYMBOL, keyword]);

  const innerRef = useOuterClick(() => {
    if (value != keyword && !isClearable) {
      setKeyword(value);
      const find = stocks?.find(
        (item) => item?.code?.toLowerCase().indexOf(value?.toLowerCase()) !== -1
      );
      if (find) {
        onChange(find);
      }
    }
    setShowListStock(false);
  });

  const handleFocus = (e) => {
    if (disabled) return false;
    refInput?.current?.setSelectionRange(0, 9999999);
    setShowListStock(true);
    onFocus(e);
  };

  useEffect(() => {
    if (value != keyword) setKeyword(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      ref={innerRef}
      className={`${styles['input-stock']} ${className} ${styles[size]} ${styles[color]}`}>
      <div className="flex items-center justify-between gap-1">
        {label && (
          <label className={styles['input-stock--label']}>{label}</label>
        )}
        {keyword && value && showRemarkInput && <StockRemark symbol={value} />}
      </div>

      <div
        className={`group wrapper ${
          styles['input-stock--wrapper']
        } dark:border-black-light-1-l ${disabled && styles['disabled']}`}>
        {showPriceStock && keyword && (
          <div className="absolute w-full left-0 top-0 h-full p-2 flex justify-end pr-8 items-center group-focus-within:opacity-0">
            <PriceStock code={value} />
          </div>
        )}

        <RenderIf isTrue={iconRight}>{iconRight}</RenderIf>

        <RenderIf isTrue={!iconRight}>
          <IconSearch
            className={`right-2 absolute w-5 h-5 transform -translate-y-1/2 top-1/2 text-main-base-l`}
          />
        </RenderIf>

        <input
          ref={refInput}
          value={keyword?.toUpperCase()}
          placeholder={placeholder}
          disabled={disabled}
          className="relative appearance-none outline-none p-3 font-quicksand-semibold w-full bg-transparent text-black-dark-d pr-8 rounded-lg dark:text-black-light-1-d"
          onFocus={handleFocus}
          onDoubleClick={() => {
            refInput?.current?.focus();
            refInput?.current?.setSelectionRange(0, 9999999);
          }}
          onChange={async (e) => {
            setKeyword(e.target.value);
            setShowListStock(
              (e.target.value ? true : false) || defaultStocks?.length > 0
            );
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
            if (
              e.key === 'Tab' &&
              e.target?.value?.length > 3 &&
              stocks?.length > 0
            ) {
              const find = stocks?.find(
                (item) =>
                  item?.code?.toLowerCase().indexOf(keyword?.toLowerCase()) !==
                  -1
              );
              if (find) {
                onChange(find);
                setKeyword(find?.code);
              } else {
                onChange(stocks[0]);
                setKeyword(stocks[0]?.code);
              }

              setShowListStock(false);
              refInput?.current?.blur();
            }
          }}
          onKeyUp={(e) => {
            if (
              e.key === 'Enter' &&
              e.target?.value?.length > 2 &&
              stocks?.length > 0
            ) {
              const find = stocks?.find(
                (item) =>
                  item?.code?.toLowerCase().indexOf(keyword?.toLowerCase()) !==
                  -1
              );
              if (find) {
                onChange(find);
                setKeyword(find?.code);
              } else {
                onChange(stocks[0]);
                setKeyword(stocks[0]?.code);
              }

              setShowListStock(false);
              refInput?.current?.blur();
            }
          }}
          onBlur={onBlur}
        />
      </div>

      {showListStock && stocks?.length > 0 && (
        <div className="absolute top-full w-full right-0 pt-2 z-20 min-w-[240px] input-stock--list">
          <div className="card-widget-gradient shadow-pro rounded-lg overflow-hidden">
            <OverlayScrollbarsComponent
              className={cn('overlayscrollbars-host-small')}
              element="span"
              options={{
                resize: 'horizontal',
                scrollbars: {
                  autoHide: 'leave',
                  autoHideDelay: 50
                }
              }}
              style={{
                maxHeight:
                  stocks?.length > 0 && showListStock ? maxHeight : '0px'
              }}>
              <ul>
                {stocks?.map((item, key) => (
                  <li key={key}>
                    <CardStockItem
                      {...item}
                      showPriceStock={false}
                      value={Number(item?.value)}
                      onClick={() => {
                        onChange(item);
                        setShowListStock(false);
                        setKeyword(item?.code);
                      }}
                      isRealtime={true}
                      data-link-qa-id="linkSearchStock"
                      data-img-qa-id="imgSearchStock"
                      actionButton={false}
                      onBlur={() => {
                        if (key === stocks?.length - 1) {
                          setShowListStock(false);
                        }
                      }}
                    />
                  </li>
                ))}
              </ul>
            </OverlayScrollbarsComponent>
          </div>
        </div>
      )}
    </div>
  );
}

InputStock.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onLoading: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  showPriceStock: PropTypes.bool,
  defaultStocks: PropTypes.array,
  excludeStocks: PropTypes.array,
  label: PropTypes.any,
  iconRight: PropTypes.any,
  maxHeight: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string
};

export default InputStock;
