import baseApi from '../base';

export const VerifyApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createRequestPhoneCall: build.mutation({
      query: (body = { msisdn: '', gateway: '' }) => {
        return {
          url: `/verify/phone/v3/asynccall`,
          method: 'POST',
          body
        };
      },
      transformResponse: (res) => res?.payloads || null,
    }),
    createRequestPhoneSMS: build.mutation({
      query: (body = { msisdn: '' }) => {
        return {
          url: `/communication/otp`,
          method: 'POST',
          body
        };
      }
    }),
    createVerifyPhoneSMS: build.mutation({
      query: (body = { msisdn: '', otp: '' }) => {
        return {
          url: `/communication/otp/verify`,
          method: 'POST',
          body
        };
      }
    }),
    createVerifyKTP: build.mutation({
      query: (body) => {
        return {
          url: `/verify/ktp`,
          method: 'POST',
          body
        };
      }
    }),
    createVerifySelfie: build.mutation({
      query: (body = { imgFaceUrl : ''}) => {
        return {
          url: `/verify/selfie`,
          method: 'POST',
          body
        };
      }
    }),
    createVerifyNPWP: build.mutation({
      query: (body = { imgNpwpUrl : '', npwpNo: '', npwpRegistrationDate: '' }) => {
        return {
          url: `/verify/npwp`,
          method: 'POST',
          body
        };
      }
    }),
    createVerifyAccountStep01: build.mutation({
      query: (body = { phoneNo : '', motherName: '', isNonFatca: 1}) => {
        return {
          url: `/verify/basic`,
          method: 'POST',
          body
        };
      }
    }),
    createVerifyAccountStep03: build.mutation({
      query: (body = { educationLevel : 0, occupation: '', sourceFunds: 0, monthlyIncome: 0, purposeOfAccount: 0 }) => {
        return {
          url: `/verify/otherinfo`,
          method: 'POST',
          body
        };
      }
    }),
    createVerifyAccountStep04: build.mutation({
      query: (body = { bankId : 0, bankAccountNo: '', bankAccountHolder: '' }) => {
        return {
          url: `/verify/bank`,
          method: 'POST',
          body
        };
      }
    }),
    createVerifyAccountStep05: build.mutation({
      query: (body = { isAgreement : true }) => {
        return {
          url: `/verify/final`,
          method: 'POST',
          body
        };
      }
    }),
  })
});

export const {
  useCreateRequestPhoneCallMutation,
  useCreateRequestPhoneSMSMutation,
  useCreateVerifyPhoneSMSMutation,
  useCreateVerifyKTPMutation,
  useCreateVerifyAccountStep01Mutation,
  useCreateVerifySelfieMutation,
  useCreateVerifyNPWPMutation,
  useCreateVerifyAccountStep03Mutation,
  useCreateVerifyAccountStep04Mutation,
  useCreateVerifyAccountStep05Mutation
} = VerifyApi;
