import PropTypes from 'prop-types';
import CardStockItem from 'components/cards/card-stock-item';
import RenderIf from 'components/basic/render-if';
import { useEffect, useState } from 'react';
import { useGetStockTopQuery } from 'services/rtk-query/stock';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { triggerSegmentEvent } from 'lib/segment';
import IconBuy from 'components/svg/icon-buy';
import IconSearchPage from 'components/svg/icon-search-page';
import IconCompare from 'components/svg/icon-compare';
import { useCompareStock } from 'hooks/useCompareStock';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import { useCreateWatchlistMutation, useDeleteWatchlistMutation, useGetWatchlistQuery } from 'services/rtk-query/watchlist';
import IconStarDelete from 'components/svg/icon-star-delete';
import IconStar from 'components/svg/icon-star';
import useToastr from 'hooks/useToastr';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useCustomNavigate from 'hooks/useCustomNavigate';

let categories = [
  {
    active: true,
    text: 'Top Value',
    label: 'top-value',
    analyticEventName: 'TR Saham Trending: Top Value Tab Clicked'
  },
  {
    active: false,
    text: 'Top Gainer',
    label: 'top-gainer',
    analyticEventName: 'TR Saham Trending: Top Gainer Tab Clicked'
  },
  {
    active: false,
    text: 'Top Volume',
    label: 'top-volume',
    analyticEventName: 'TR Saham Trending: Top Volume Tab Clicked'
  },
]

function SectionStockTrendingList({ isCardLess, isZoomOut = false, isShowChart = true, widgetSettings = {}, onChangeWidgetSettings = () => {} }) {
  const { navigate, customNavigate } = useCustomNavigate();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { addToCompareStock } = useCompareStock();
  const [activeTabIndex, setActiveTabIndex] = useState(widgetSettings?.activeTabIndex || 0);
  const [dataLive, setDataLive] = useState([]);
  const [data, setData] = useState([]);

  const { data: watchlist } = useGetWatchlistQuery();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();
  const { data: dataTopValue, isLoading: isLoadingValue } = useGetStockTopQuery({ type: 'top-value', params: { size: 5, page: 0 }});
  const { data: dataTopGainer, isLoading: isLoadingGainer } = useGetStockTopQuery({ type: 'top-gainer', params: { size: 5, page: 0 }});
  const { data: dataTopVolume, isLoading: isLoadingVolume } = useGetStockTopQuery({ type: 'top-volume', params: { size: 5, page: 0 }});

  const handleActiveTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
    onChangeWidgetSettings({ activeTabIndex: tabIndex });

    if (tabIndex === 0) {
      setData(dataTopValue);
    }

    if (tabIndex === 1) {
      setData(dataTopGainer);
    }

    if (tabIndex === 2) {
      setData(dataTopVolume);
    }
  };

  useEffect(() => {
    if (activeTabIndex === 0 && !isLoadingValue) {
      setData(dataTopValue);
    }

    if (activeTabIndex === 1 && !isLoadingGainer) {
      setData(dataTopGainer);
    }

    if (activeTabIndex === 2 && !isLoadingVolume) {
      setData(dataTopVolume);
    }
  }, [activeTabIndex, dataTopValue, dataTopGainer, dataTopVolume, isLoadingValue, isLoadingGainer, isLoadingVolume]);

  useEffect(() => {
    if (data?.length > 0) setDataLive(data);
  }, [data]);
  
  return (
    <section className={`card-widget-gradient ${!isCardLess ? 'rounded-xl ' : ''}`}>
      <div className="p-4 pb-0">
        {!isCardLess && <h3 className="font-quicksand-semibold text-sm dark:text-black-light-1-d">Saham Trending</h3>}

        <ul className="mt-3 text-xs grid grid-cols-3 items-center font-quicksand-semibold -mx-4">
          {categories.map((item, key) => (
            <li
              key={key}
              data-qa-id={item?.['data-qa-id']}
              onClick={() => {
                handleActiveTab(key);
                triggerSegmentEvent({ event: item?.analyticEventName })
              }}
              className={`cursor-pointer py-2 text-center transition-all ease-linear duration-200 hover:bg-opacity-75 px-4 hover:text-main-base-l hover:border-main-base-l ${
                key === activeTabIndex ? 'border-main-base-l text-main-base-l border-b-2' : 'border-gray-light-l dark:border-gray-base-d text-gray-light-0-d border-b'
              }`}>
              {item.text}
            </li>
          ))}
        </ul>
      </div>

      <div className="pt-3">
        <RenderIf isTrue={isLoadingValue || isLoadingGainer || isLoadingVolume}>
          <div className="p-3">
            {Array(5)
              .fill(1)
              .map((el, key) => (
                <div key={key} className="flex justify-between mb-5">
                  <div className="flex">
                    <SkeletonTheme 
                      containerClassName="mr-3"
                      width="40px"
                      height="40px"
                      borderRadius="100%"
                    />

                    <div>
                      <SkeletonTheme 
                        containerClassName="mr-1"
                        width="70px"
                        height="16px"
                        borderRadius="6px"
                      />

                      <SkeletonTheme 
                        containerClassName="mr-1"
                        width="100px"
                        height="16px"
                        borderRadius="6px"
                      />
                    </div>
                  </div>

                  <div className="text-right">
                    <SkeletonTheme  width="40px" height="16px" borderRadius="6px" />

                    <SkeletonTheme  width="50px" height="16px" borderRadius="6px" />
                  </div>
                </div>
              ))}
          </div>
        </RenderIf>

        <RenderIf isTrue={dataLive?.length > 0 && !isLoadingValue && !isLoadingGainer && !isLoadingVolume}>
          <ul>
            {dataLive?.map((item, key) => (
              <li key={key}>
                <CardStockItem
                  {...item}
                  isShowChart={isShowChart}
                  isZoomOut={isZoomOut}
                  data-link-qa-id="linkTrendingListStock"
                  data-img-qa-id="imgTrendingListStock"
                  isLastItem={key === dataLive?.length - 1}
                  onClick={() => customNavigate({ query: { 'stock-detail': item?.code }})}
                  actions={[
                    {
                      title: 'Beli',
                      children: (
                        <IconBuy className="h-4 w-4" />
                      ),
                      onClick: () => customNavigate({ query: { 'simple-order': item?.code }})
                    },
                    {
                      title: 'Stock Detail',
                      children: <IconSearchPage className="h-4 w-4" />,
                      onClick: () => {
                        triggerSegmentEvent({ event: 'TR Search Feature Clicked' });
                        customNavigate({ query: { 'stock-detail': item?.code }});
                      }
                    },
                    {
                      title: 'Bandingkan',
                      children: <IconCompare className="h-4 w-4" />,
                      onClick: () => {
                        triggerSegmentEvent({ event: 'TR Compare Stock Clicked' });
                        addToCompareStock({ code: item?.code, name: name }, () => {
                          navigate(`/compare/fundamental`);
                        })
                      }
                    },
                    {
                      title: 'Trading View',
                      children: <IconChartCandleStick className="h-4 w-4" />,
                      onClick: () => customNavigate({ query: { 'advanced-chart': item?.code }})
                    },
                    {
                      title: `${watchlist?.find((w) => w?.code === item?.code) ? 'Hapus Watchlist' : 'Tambahkan ke Watchlist'}`,
                      children: watchlist?.find((w) => w?.code === item?.code) ? <IconStarDelete className="h-4 w-4" /> : <IconStar className="h-4 w-4" />,
                      onClick: async () => {
                        triggerSegmentEvent({ event: 'TR Watchlist Clicked' });
                        if (watchlist?.find((w) => w?.code === item?.code)) {
                          try {
                            await deleteWatchlist({ stockCode: item?.code }).unwrap();
                            toastr.success(`${item?.code} berhasil dihapus dari watchlist`);
                          } catch (error) {
                            getErrorMessage(error);
                          }
                        } else {
                          try {
                            await createWatchlist({ stockCode: item?.code, type: 'stock'}).unwrap();
                            toastr.success(`${item?.code} berhasil ditambahkan kedalam watchlist`);
                          } catch (error) {
                            getErrorMessage(error);
                          }
                        }
                      }
                    }
                  ]}
                />
              </li>
            ))}
          </ul>
        </RenderIf>

        <RenderIf isTrue={dataLive?.length === 0 && !isLoadingValue && !isLoadingGainer && !isLoadingVolume}>
          <p className="text-sm text-gray-light-0-d px-3 pb-3">Belum ada data.</p>
        </RenderIf>
      </div>
    </section>
  );
}

SectionStockTrendingList.propTypes = {
  isCardLess: PropTypes.bool,
  onChangeWidgetSettings: PropTypes.func,
  widgetSettings: PropTypes.object,
};

export default SectionStockTrendingList;