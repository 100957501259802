import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import TAGS from './tags'
import { resetLoginPin } from 'store/trading';
import { useBrowserTabId } from 'hooks/useBrowserTabId';

const MAX_RETRIES = 1;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  timeout: 60000,
  prepareHeaders: (headers, { getState }) => {
    const browserTabID = useBrowserTabId()
    const token = getState()?.auth?.[browserTabID]?.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    headers.set('X-API-KEY', process.env.REACT_APP_API_TOKEN);

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && [402].includes(result.error.status)) {
    api?.dispatch(resetLoginPin())
  }

  if (result.error && [401].includes(result.error.status)) {
    // TODO : Refresh Token
    window.location.href = '/logout';
    // try to get a new token
    // const refreshResult = await baseQuery('/auth/refresh-token', api, extraOptions)
    // if (refreshResult.data) {
    // store the new token
    // api.dispatch(tokenReceived(refreshResult.data))
    // retry the initial query
    // result = await baseQuery(args, api, extraOptions)
    // } else {
    // api.dispatch(loggedOut())
    // }
  }
  return result
}

const baseQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: MAX_RETRIES });

const baseApi = createApi({
  baseQuery: baseQueryWithRetry,
  tagTypes: [...TAGS],
  endpoints: () => ({}),
});

const enhanceBaseApi = baseApi.enhanceEndpoints({
  endpoints: () => ({}),
});


export { baseApi as default, enhanceBaseApi };
