import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import dayjs from 'dayjs';
import {
  useCreateOrderAmendMutation,
  useCreateOrderWithdrawMutation,
  useGetOrderListQuery
} from 'services/rtk-query/trade';
import { currency, generateOrderId } from 'utils/format';
import {
  getColorStatusOrder,
  getOrderStatus,
  getWithdrawStatus
} from 'utils/stock/order';
import { useEffect, useMemo, useState } from 'react';
import IconSorting from 'components/svg/icon-sorting';
import { filter, groupBy, sumBy } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import OrderDetail from './order-detail';
import { ORDER_EXPIRED } from 'constants';
import ActionButton from 'components/basic/action-button';
import StockWithdrawForm from 'components/form/stock-withdraw-form';
import ImageEmptyState from 'assets/images/mascot/mascot-empty-orderlist.png';
import IconSearchPage from 'components/svg/icon-search-page';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import IconStar from 'components/svg/icon-star';
import IconStarDelete from 'components/svg/icon-star-delete';
import {
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistQuery
} from 'services/rtk-query/watchlist';
import { useDispatch, useSelector } from 'react-redux';
import useToastr from 'hooks/useToastr';
import IconAmend from 'components/svg/icon-amend';
import { useTableSorting } from 'hooks/useTableSorting';
import IconEye from 'components/svg/icon-eye';
import { useCompareStock } from 'hooks/useCompareStock';
import { triggerSegmentEvent } from 'lib/segment';
import IconBuy from 'components/svg/icon-buy';
import IconWithdrawOrder from 'components/svg/icon-withdraw-order';
import InputCheckBox from 'components/input/input-check-box';
import ModalConfirmationMultipleWithdraw from 'components/form/_partials/modal-confirmation-multiple-withdraw';
import { invalidateUserNotification } from 'services/rtk-query/user';
import ModalConfirmationMultipleAmend from 'components/form/_partials/modal-confirmation-multiple-amend';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import BlinkText from 'components/basic/text-blink';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import Modal from 'components/modal/modal';
import useCustomNavigate from 'hooks/useCustomNavigate';
import InputSelect from 'components/input/input-select';
import Tooltip from 'components/basic/tooltip';
import IconInfo from 'components/svg/icon-info';
import clsx from 'clsx';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import InputText from 'components/input/input-text';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import cn from 'utils/classname';

function TWOrderlist({
  title = 'Order List',
  showQueue = false,
  isZoomOut = false,
  isInline = false
}) {
  const isBrowserWakeUp = useBrowserWakeUp();
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { user, dealerUseClientId } = useBrowserTabAuth();
  const {
    data: orderList,
    isLoading,
    refetch
  } = useGetOrderListQuery(null, {
    refetchOnMountOrArgChange: true,
    pollingInterval: isBursaOpen ? 5000 : 36000
  });

  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { navigate, customNavigate } = useCustomNavigate();
  const dispatch = useDispatch();

  const { data: watchlist } = useGetWatchlistQuery();

  const [deleteWatchlist] = useDeleteWatchlistMutation();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [createOrderWithdraw] = useCreateOrderWithdrawMutation();
  const [createOrderAmend] = useCreateOrderAmendMutation();

  const { addToCompareStock } = useCompareStock();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [
    openModalConfirmationMultipleWithdraw,
    setOpenModalConfirmationMultipleWithdraw
  ] = useState(false);
  const [
    openModalConfirmationMultipleAmend,
    setOpenModalConfirmationMultipleAmend
  ] = useState(false);
  const [openModalWithdrawForm, setOpenModalWithdrawForm] = useState(false);

  const [currentFilter, setCurrentFilter] = useState(['B', 'M', 'O']);
  const [filterSide, setFilterSide] = useState('All');
  const [filterStockID, setFilterStockID] = useState('All');
  const [filterPrice, setFilterPrice] = useState('All');

  const tempData = useMemo(() => {
    if (orderList?.items?.length > 0) {
      return orderList?.items
        ?.filter((item) => {
          if (filterSide !== 'All') {
            return item?.side === filterSide;
          }

          return true;
        })
        ?.filter((item) => {
          if (filterStockID !== 'All') {
            return item?.stID === filterStockID;
          }

          return true;
        })
        ?.filter((item) => {
          if (filterPrice !== 'All') {
            return item?.price === Number(filterPrice);
          }

          return true;
        })
        ?.map((item) => {
          return {
            ...item,
            orderValue: Number(item?.qty) * Number(item?.price),
            tradeValue: Number(item?.cumQty) * Number(item?.avgPrice)
          };
        })
        ?.filter(
          (item) =>
            (currentFilter?.includes(item?.state) ||
              (currentFilter?.includes('O') && item?.state === 'P') ||
              currentFilter?.includes(item?.expiry) ||
              currentFilter?.includes(item?.stID)) &&
            item?.side != 'WC'
        );
    }

    return [];
  }, [orderList, currentFilter, filterSide, filterStockID, filterPrice]);

  const { data, sortingColumns, handleSorting, getSorting } = useTableSorting(
    tempData,
    ['dtSave'],
    ['desc']
  );

  const [selectedOrder, setSelectedOrder] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const ownedStockID = useMemo(() => {
    const groupByStockID = groupBy(
      orderList?.items?.filter(
        (item) => item?.side === filterSide || filterSide === 'All'
      ),
      'stID'
    );

    return (
      Object.keys(groupByStockID)?.map((objKey) => ({
        id: objKey,
        name: objKey
      })) || []
    );
  }, [orderList, filterSide]);

  const ownedPriceStockID = useMemo(() => {
    const groupByStockID = groupBy(
      orderList?.items?.filter(
        (item) =>
          (item?.stID === filterStockID || filterStockID === 'All') &&
          (item?.side === filterSide || filterSide === 'All')
      ),
      'price'
    );

    return (
      Object.keys(groupByStockID)?.map((objKey) => ({
        id: objKey,
        name: objKey
      })) || []
    );
  }, [orderList, filterStockID, filterSide]);

  const listFilterStatus = useMemo(() => {
    return [
      // {
      //   value: null,
      //   text: 'All'
      // },
      // {
      //   value: 'A',
      //   text: 'Amend'
      // },
      {
        value: 'B',
        text: 'Basket'
      },
      {
        value: 'M',
        text: 'Match'
      },
      {
        value: 'O',
        text: 'Open'
      },
      {
        value: 'R',
        text: 'Reject'
      },
      // {
      //   value: 'W',
      //   text: 'Withdraw'
      // },
      {
        value: '0',
        text: 'Day'
      },
      {
        value: '1',
        text: 'GTC'
      },
      {
        value: '6',
        text: 'GTD'
      }
    ];
  }, []);

  const totalQTYOpenMatch = useMemo(() => {
    return (
      sumBy(
        data?.filter((item) => ['P', 'O', 'B', 'M']?.includes(item?.state)),
        'qty'
      ) / 100
    );
  }, [data]);

  const totalValue = useMemo(() => {
    return sumBy(data, 'orderValue');
  }, [data]);

  const totalValueOpenMatch = useMemo(() => {
    return sumBy(
      data?.filter((item) => ['P', 'O', 'B', 'M']?.includes(item?.state)),
      'orderValue'
    );
  }, [data]);

  const openQty = useMemo(() => {
    return (
      sumBy(
        data?.filter((item) => ['P', 'O', 'B']?.includes(item?.state)),
        'openQty'
      ) / 100
    );
  }, [data]);

  const buyOpenQty = useMemo(() => {
    return (
      sumBy(
        data
          ?.filter((item) => ['P', 'O', 'B']?.includes(item?.state))
          ?.filter((item) => item?.side === 'B'),
        'openQty'
      ) / 100
    );
  }, [data]);

  const sellOpenQty = useMemo(() => {
    return (
      sumBy(
        data
          ?.filter((item) => ['P', 'O', 'B']?.includes(item?.state))
          ?.filter((item) => item?.side === 'S'),
        'openQty'
      ) / 100
    );
  }, [data]);

  const openValue = useMemo(() => {
    let tempData = data
      ?.filter((item) => ['P', 'O', 'B']?.includes(item?.state))
      ?.map((item) => ({
        ...item,
        openOrderValue: item?.orderValue - item?.tradeValue
      }));

    return sumBy(tempData, 'openOrderValue');
  }, [data]);

  const buyOpenValue = useMemo(() => {
    let tempData = data
      ?.filter((item) => ['P', 'O', 'B']?.includes(item?.state))
      ?.filter((item) => item?.side === 'B')
      ?.map((item) => ({
        ...item,
        openOrderValue: item?.orderValue - item?.tradeValue
      }));

    return sumBy(tempData, 'openOrderValue');
  }, [data]);

  const sellOpenValue = useMemo(() => {
    let tempData = data
      ?.filter((item) => ['P', 'O', 'B']?.includes(item?.state))
      ?.filter((item) => item?.side === 'S')
      ?.map((item) => ({
        ...item,
        openOrderValue: item?.orderValue - item?.tradeValue
      }));

    return sumBy(tempData, 'openOrderValue');
  }, [data]);

  const doneQty = useMemo(() => {
    return sumBy(data, 'cumQty') / 100;
  }, [data]);

  const buyDoneQty = useMemo(() => {
    return (
      sumBy(
        data?.filter((item) => item?.side === 'B'),
        'cumQty'
      ) / 100
    );
  }, [data]);

  const sellDoneQty = useMemo(() => {
    return (
      sumBy(
        data?.filter((item) => item?.side === 'S'),
        'cumQty'
      ) / 100
    );
  }, [data]);

  const doneValue = useMemo(() => {
    return sumBy(data, 'tradeValue');
  }, [data]);

  const buyDoneValue = useMemo(() => {
    return sumBy(
      data?.filter((item) => item?.side === 'B'),
      'tradeValue'
    );
  }, [data]);

  const sellDoneValue = useMemo(() => {
    return sumBy(
      data?.filter((item) => item?.side === 'S'),
      'tradeValue'
    );
  }, [data]);

  const netBuySellDone = useMemo(() => {
    return sellDoneValue - buyDoneValue;
  }, [buyDoneValue, sellDoneValue]);

  const getOrderType = (expiryId) => {
    return (
      Object.values(ORDER_EXPIRED).find(({ id }) => id === expiryId)?.name || ''
    );
  };

  const handleSelectedOrder = (e, item) => {
    const { checked } = e.target;
    setSelectedOrder([...selectedOrder, item]);
    if (!checked) {
      setSelectedOrder(selectedOrder.filter((order) => order?.id !== item?.id));
    }
  };

  const handleSubmitMultipleWithdraw = async () => {
    try {
      setIsSubmitting(true);
      const promises = [];

      selectedOrder?.map(async (item) => {
        let payloads = {
          orderID: item?.id,
          clOrdID: item?.clOID
        };

        triggerSegmentEvent({
          event: 'TR Withdraw Stock from Multi Withdraw',
          properties: {
            total_amount: Number(item?.price) * Number(item?.qty) * 100,
            stock_name: item?.stID
          }
        });

        promises.push(createOrderWithdraw(payloads).unwrap());
      });

      await Promise.all(promises);

      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
      toastr.success('Order Withdraw telah terkirim');
      setOpenModalConfirmationMultipleWithdraw(false);
      setSelectedOrder([]);
      setCheckedAll(false);
    } catch ({ data }) {
      console.log(data);
      toastr.error(data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitMultipleAmend = async (editedOrder) => {
    try {
      setIsSubmitting(true);
      const promises = [];

      editedOrder?.map(async (item, key) => {
        let payloads = {
          orderID: item?.id,
          clOrdID: generateOrderId(
            dealerUseClientId || user?.username,
            'A',
            Date.now().toString().slice(6, 12) + key
          ),
          price: item?.price,
          qty: item?.qty / 100
        };

        triggerSegmentEvent({
          event: 'TR Amend Stock from Multi Amend',
          properties: {
            total_amount: Number(item?.price) * Number(item?.qty) * 100,
            stock_name: item?.stID
          }
        });

        promises.push(createOrderAmend(payloads).unwrap());
      });

      await Promise.all(promises);

      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
      toastr.success('Order Amend telah terkirim');
      setOpenModalConfirmationMultipleAmend(false);
      setSelectedOrder([]);
    } catch ({ data }) {
      console.log(data);
      toastr.error(data?.message || data?.msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  useEffect(() => {
    window.checkedAll = checkedAll;
  }, [checkedAll]);

  useEffect(() => {
    window.orderlistData = data;
  }, [data]);

  useKeyboardShortcut({
    key: ' ',
    combine: 'ctrlKey',
    onKeyPressed: () => {
      let orders = [];
      if (!window.checkedAll) {
        window.orderlistData?.map((item) => {
          if (
            !['amend', 'match', 'reject', 'withdraw'].includes(
              getOrderStatus(item?.state)
            )
          ) {
            orders.push(item);
          }
        });
      }

      setSelectedOrder(orders);
      setCheckedAll(!window.checkedAll);
    }
  });

  useKeyboardShortcut({
    keys: ['Dead', 'e'],
    combine: 'altKey',
    onKeyPressed: () => {
      setCurrentFilter(['B', 'M', 'O']);
      setFilterSide('All');
      setFilterStockID('All');
      setFilterPrice('All');
    }
  });

  return (
    <div
      className="p-2 relative h-[calc(100%-44px)]"
      style={{
        height: selectedOrder?.length > 0 ? 'calc(100% - 1.8rem)' : ''
      }}>
      <RenderIf isTrue={title}>
        <span className="dark:text-black-light-1-d font-quicksand-semibold text-sm">
          {title}
        </span>
      </RenderIf>

      <RenderIf isTrue={(orderList?.items?.length || 0) > 0}>
        <div className="flex items-start justify-between mt-2">
          <ul className="flex text-2xs max-w-full gap-4 p-2 mt-3 flex-wrap flex-auto">
            {listFilterStatus?.map((order, key) => (
              <div key={key} className="flex items-center gap-2">
                <InputCheckBox
                  label={
                    <span className="font-quicksand-regular mt-[3px] dark:text-black-light-0-d">
                      {order?.text}
                    </span>
                  }
                  className="mb-0"
                  id={`filter-c-${key}`}
                  name={`filter-c-${key}`}
                  value={order.value}
                  checked={currentFilter.includes(order.value)}
                  onChange={(e) => {
                    if (currentFilter?.find((v) => v === e.target.value)) {
                      setCurrentFilter(
                        currentFilter?.filter((v) => v != e.target.value)
                      );
                    } else {
                      setCurrentFilter([...currentFilter, e.target.value]);
                    }
                  }}
                />
              </div>
            ))}
          </ul>

          <div className="flex-none z-10 flex items-center gap-4 flex-wrap">
            <InputSelect
              size="small-2"
              value={filterSide}
              name="side"
              label={
                <span className="text-2xs font-quicksand-semibold text-gray-light-0-d">
                  Side
                </span>
              }
              className="mb-4 text-2xs text-gray-light-0-d w-[100px]"
              options={[
                {
                  id: 'All',
                  name: 'All'
                },
                {
                  id: 'B',
                  name: 'Buy'
                },
                {
                  id: 'S',
                  name: 'Sell'
                }
              ]}
              onChange={(e) => {
                setFilterSide(e.target.value);
              }}
            />

            <InputSelect
              size="small-2"
              value={filterStockID}
              name="side"
              label={
                <span className="text-2xs font-quicksand-semibold text-gray-light-0-d">
                  StockID
                </span>
              }
              className="mb-4 text-2xs text-gray-light-0-d w-[100px]"
              options={[
                {
                  id: 'All',
                  name: 'All'
                },
                ...ownedStockID
              ]}
              onChange={(e) => {
                setFilterStockID(e.target.value);
              }}
            />

            <InputSelect
              size="small-2"
              value={filterPrice}
              name="side"
              label={
                <span className="text-2xs font-quicksand-semibold text-gray-light-0-d">
                  Price
                </span>
              }
              className="mb-4 text-2xs text-gray-light-0-d w-[100px]"
              options={[
                {
                  id: 'All',
                  name: 'All'
                },
                ...ownedPriceStockID
              ]}
              onChange={(e) => {
                setFilterPrice(e.target.value);
              }}
            />
          </div>
        </div>
      </RenderIf>

      <RenderIf isTrue={(tempData?.length || 0) === 0 && !isLoading}>
        <div className="text-center p-4 pt-10 lg:p-10 dark:text-black-light-0-d">
          <img
            src={ImageEmptyState}
            alt="empty"
            className="h-14 w-14 mx-auto mb-4"
          />
          <h2 className="text-base lg:text-2xl mb-1 font-quicksand-semibold">
            Belum ada order saham
          </h2>
          <p className="text-xs lg:text-sm">
            Mulai transaksi dan lihat statusnya disini
          </p>
        </div>
      </RenderIf>

      <RenderIf
        isTrue={((tempData?.length || 0) > 0 && !isLoading) || isLoading}>
        <OverlayScrollbarsComponent
          className={clsx(
            'overlayscrollbars-host-small max-w-[85vw] md:max-w-[calc(100vw-10rem)] min-h-[200px]'
          )}
          element="span"
          options={{
            resize: 'horizontal',
            scrollbars: {
              autoHide: 'leave',
              autoHideDelay: 50
            }
          }}
          style={{
            maxHeight: 'calc(100% - 200px)'
          }}>
          <table className="text-2xs w-full text-center tracking-wider">
            <thead className="font-quicksand-regular text-gray-light-0-d dark:text-black-light-0-d">
              <tr>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l`}
                  style={{ width: '24px' }}>
                  <span className="flex items-center justify-center cursor-pointer">
                    <InputCheckBox
                      id="check-all"
                      className="mb-0"
                      name="check-all"
                      checked={
                        checkedAll ||
                        (data?.length === selectedOrder?.length && !isLoading)
                      }
                      disabled={
                        data?.filter((item) =>
                          ['basket', 'open'].includes(
                            getOrderStatus(item?.state)
                          )
                        )?.length === 0
                      }
                      onChange={() => {
                        let orders = [];
                        if (!checkedAll) {
                          data?.map((item) => {
                            if (
                              ![
                                'amend',
                                'match',
                                'reject',
                                'withdraw'
                              ].includes(getOrderStatus(item?.state))
                            ) {
                              orders.push(item);
                            }
                          });
                        }

                        setSelectedOrder(orders);
                        setCheckedAll(!checkedAll);
                      }}
                    />
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'stID' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('stID')}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    <IconSorting
                      type={getSorting('stID')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('stID') && 'block'
                      )}
                    />
                    Stock
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'expiry' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('expiry')}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    <IconSorting
                      type={getSorting('expiry')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('expiry') && 'block'
                      )}
                    />
                    Type
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'side' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('side')}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    <IconSorting
                      type={getSorting('side')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('side') && 'block'
                      )}
                    />
                    B/S
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'qty' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('qty')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    <IconSorting
                      type={getSorting('qty')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('qty') && 'block'
                      )}
                    />
                    Qty
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'price' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('price')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    <IconSorting
                      type={getSorting('price')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('price') && 'block'
                      )}
                    />
                    Price
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'orderValue' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('orderValue')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    <IconSorting
                      type={getSorting('orderValue')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('orderValue') && 'block'
                      )}
                    />
                    Value
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'state' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('state')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    <IconSorting
                      type={getSorting('state')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('state') && 'block'
                      )}
                    />
                    Status
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'dtSave' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('dtSave')}>
                  <span className="flex items-center justify-end gap-1 cursor-pointer">
                    <IconSorting
                      type={getSorting('dtSave')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('dtSave') && 'block'
                      )}
                    />
                    Time
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'avgPrice' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('avgPrice')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    <IconSorting
                      type={getSorting('avgPrice')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('avgPrice') && 'block'
                      )}
                    />
                    AvgPrice
                  </span>
                </td>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'tradeValue' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('tradeValue')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    <IconSorting
                      type={getSorting('tradeValue')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('tradeValue') && 'block'
                      )}
                    />
                    Trade&nbsp;Value
                  </span>
                </td>
                <RenderIf isTrue={showQueue}>
                  <td
                    className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'id' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('id')}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      <IconSorting
                        type={getSorting('id')}
                        className={cn(
                          'h-3 w-3 hidden',
                          sortingColumns?.includes('id') && 'block'
                        )}
                      />
                      Q.&nbsp;Sekuritas
                    </span>
                  </td>

                  <td
                    className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'mktOID' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('mktOID')}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      <IconSorting
                        type={getSorting('mktOID')}
                        className={cn(
                          'h-3 w-3 hidden',
                          sortingColumns?.includes('mktOID') && 'block'
                        )}
                      />
                      Q.&nbsp;IDX
                    </span>
                  </td>
                </RenderIf>
                <td
                  className={`py-2 px-1 border-b border-gray-base-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'lastText' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('lastText')}>
                  <span className="flex items-center gap-1 justify-end cursor-pointer">
                    <IconSorting
                      type={getSorting('lastText')}
                      className={cn(
                        'h-3 w-3 hidden',
                        sortingColumns?.includes('lastText') && 'block'
                      )}
                    />
                    Info
                  </span>
                </td>
              </tr>
            </thead>
            <tbody className="font-quicksand-regular dark:text-black-light-1-d">
              <RenderIf isTrue={data.length === 0 && !isLoading}>
                <tr>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                  <td className="p-2 text-left">-</td>
                </tr>
              </RenderIf>

              <RenderIf isTrue={isLoading}>
                {[0, 1, 2].map((item) => (
                  <tr key={item}>
                    <td className="p-2 text-center">
                      <SkeletonTheme
                        width="24px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                  </tr>
                ))}
              </RenderIf>

              <RenderIf isTrue={!isLoading}>
                {data?.map((item, key) => (
                  <tr
                    key={key}
                    className="group cursor-pointer font-quicksand-regular">
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-center">
                      <InputCheckBox
                        className="mb-0"
                        id={`list-${key}`}
                        name={`list-${key}`}
                        value={item?.id}
                        disabled={[
                          'amend',
                          'match',
                          'reject',
                          'withdraw'
                        ].includes(getOrderStatus(item?.state))}
                        checked={
                          selectedOrder?.find(
                            (order) => order?.id === item?.id
                          ) || false
                        }
                        onChange={(e) => handleSelectedOrder(e, item)}
                      />
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left font-quicksand-semibold relative">
                      <RenderIf
                        isTrue={item?.side === 'B' || item?.side === 'S'}>
                        <div className="flex h-full items-center mx-auto hover:opacity-75 transition-all ease-in-out duration-200 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d">
                          {item?.stID}
                        </div>
                      </RenderIf>

                      <RenderIf isTrue={item?.side === 'WC'}>-</RenderIf>

                      <RenderIf isTrue={selectedOrder?.length === 0}>
                        <ActionButton
                          isZoomOut={isZoomOut}
                          isLast={key > data?.length - 1}
                          position={cn('left-0 -bottom-8', key === data?.length - 1 && 'bottom-5')}
                          actions={[
                            {
                              title: 'Beli',
                              children: <IconBuy className="h-4 w-4" />,
                              onClick: () =>
                                customNavigate({
                                  query: { 'simple-order': item?.stID }
                                })
                            },
                            {
                              title: 'Amend',
                              disabled: [
                                'amend',
                                'match',
                                'reject',
                                'withdraw'
                              ].includes(getOrderStatus(item?.state)),
                              children: <IconAmend className="h-4 w-4" />,
                              onClick: () => {
                                if (
                                  ![
                                    'amend',
                                    'match',
                                    'reject',
                                    'withdraw'
                                  ].includes(getOrderStatus(item?.state)) &&
                                  selectedOrder?.length < 2
                                ) {
                                  setOpenOrderDetail({
                                    detail: item,
                                    type: 'AMEND'
                                  });
                                }
                              }
                            },
                            {
                              title: 'Withdraw',
                              disabled: [
                                'amend',
                                'match',
                                'reject',
                                'withdraw'
                              ].includes(getOrderStatus(item?.state)),
                              children: (
                                <IconWithdrawOrder className="h-4 w-4" />
                              ),
                              onClick: () => {
                                if (
                                  ![
                                    'amend',
                                    'match',
                                    'reject',
                                    'withdraw'
                                  ].includes(getOrderStatus(item?.state)) &&
                                  selectedOrder?.length < 2
                                ) {
                                  setOpenModalWithdrawForm(item);
                                }
                              }
                            },
                            {
                              title: 'Order Detail',
                              children: <IconEye className="h-4 w-4" />,
                              onClick: () =>
                                setOpenOrderDetail({ detail: item })
                            },
                            {
                              title: 'Stock Detail',
                              children: <IconSearchPage className="h-4 w-4" />,
                              onClick: () => {
                                customNavigate({
                                  query: { 'stock-detail': item?.stID }
                                });
                                triggerSegmentEvent({
                                  event: 'TR Search Feature Clicked'
                                });
                              }
                            },
                            {
                              title: 'Trading View',
                              children: (
                                <IconChartCandleStick className="h-4 w-4" />
                              ),
                              onClick: () => {
                                customNavigate({
                                  query: { 'advanced-chart': item?.stID }
                                });
                              }
                            },
                            {
                              title: 'Bandingkan',
                              children: <IconCompare className="h-4 w-4" />,
                              onClick: () => {
                                triggerSegmentEvent({
                                  event: 'TR Compare Stock Clicked'
                                });
                                addToCompareStock(
                                  { code: item?.stID, name: item?.name },
                                  () => {
                                    navigate(`/compare/fundamental`);
                                  }
                                );
                              }
                            },
                            {
                              title: `${
                                watchlist?.find((w) => w?.code === item?.stID)
                                  ? 'Hapus Watchlist'
                                  : 'Tambahkan ke Watchlist'
                              }`,
                              children: watchlist?.find(
                                (w) => w?.code === item?.stID
                              ) ? (
                                <IconStarDelete className="h-4 w-4" />
                              ) : (
                                <IconStar className="h-4 w-4" />
                              ),
                              onClick: async () => {
                                triggerSegmentEvent({
                                  event: 'TR Watchlist Clicked'
                                });
                                if (
                                  watchlist?.find((w) => w?.code === item?.stID)
                                ) {
                                  try {
                                    await deleteWatchlist({
                                      stockCode: item?.stID
                                    }).unwrap();
                                    toastr.success(
                                      `${item?.code} berhasil dihapus dari watchlist`
                                    );
                                  } catch (error) {
                                    getErrorMessage(error);
                                  }
                                } else {
                                  try {
                                    await createWatchlist({
                                      stockCode: item?.stID,
                                      type: 'stock'
                                    }).unwrap();
                                    toastr.success(
                                      `${item?.stID} berhasil ditambahkan kedalam watchlist`
                                    );
                                  } catch (error) {
                                    getErrorMessage(error);
                                  }
                                }
                              }
                            }
                          ]}
                        />
                      </RenderIf>
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left">
                      {getOrderType(item?.expiry)}
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left">
                      <BlinkText
                        className={
                          item?.side === 'B'
                            ? 'text-red-base-l'
                            : item?.side === 'WC'
                            ? 'text-main-base-l'
                            : 'text-green-base-l'
                        }
                        value={
                          item?.side === 'B'
                            ? 'BUY'
                            : item?.side === 'WC'
                            ? 'Withdraw Cash'
                            : 'SELL'
                        }
                      />
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right">
                      {currency(item?.openQty / 100, { removeSymbol: true })}/
                      {currency(item?.cumQty / 100, { removeSymbol: true })}&nbsp;LOT
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right">
                      <RenderIf
                        isTrue={item?.side === 'B' || item?.side === 'S'}>
                        {currency(item?.price, { removeSymbol: true })}
                      </RenderIf>
                      <RenderIf isTrue={item?.side === 'WC'}>-</RenderIf>
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right font-quicksand-semibold">
                      <RenderIf
                        isTrue={item?.side === 'B' || item?.side === 'S'}>
                        {currency(item?.orderValue, { removeSymbol: true })}
                      </RenderIf>
                    </td>
                    <td
                      className={`transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right uppercase`}>
                      <Tooltip
                        isZoomOut={isZoomOut}
                        position="bottom-left"
                        tooltipHTML={
                          <p className="text-2xs font-quicksand-semibold">
                            {item?.lastText || 'Belum ada berita'}
                          </p>
                        }>
                        <span className="flex gap-0 group-first:pt-1 items-center justify-end">
                          <IconInfo
                            className={clsx(
                              'flex-none w-3 h-3',
                              getColorStatusOrder(item?.state)
                            )}
                          />
                          <BlinkText
                            className={clsx(
                              'p-1 opacity-75 inline-block rounded-md',
                              getColorStatusOrder(item?.state)
                            )}
                            value={
                              item?.side === 'WC'
                                ? getWithdrawStatus(item?.state)
                                : getOrderStatus(item?.state)
                            }
                          />
                        </span>
                      </Tooltip>
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right">
                      {dayjs.unix(item?.dtSave / 1000).format('HH:mm:ss')}
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right">
                      {currency(item?.avgPrice, { removeSymbol: true })}
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right font-quicksand-semibold">
                      {currency(item?.tradeValue, { removeSymbol: true })}
                    </td>

                    <RenderIf isTrue={showQueue}>
                      <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right font-quicksand-semibold">
                        {item?.id || '-'}
                      </td>
                      <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right font-quicksand-semibold">
                        {item?.mktOID || '-'}
                      </td>
                    </RenderIf>

                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right font-quicksand-semibold">
                      <Tooltip
                        isZoomOut={isZoomOut}
                        position="bottom-right"
                        tooltipHTML={
                          <p className="text-2xs font-quicksand-semibold min-w-[200px]">
                            {item?.lastText || 'Belum ada berita'}
                          </p>
                        }>
                        <span className="line-clamp-1">
                          {item?.lastText || 'Belum ada berita'}
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                ))}

                <tr className="group cursor-pointer font-quicksand-regular">
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-center">
                    &nbsp;
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left font-quicksand-semibold relative">
                    &nbsp;
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left">
                    &nbsp;
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left font-quicksand-semibold">
                    Total
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right">
                    {currency((sumBy(data, 'openQty') || 0) / 100, {
                      removeSymbol: true
                    })}
                    /
                    {currency((sumBy(data, 'cumQty') || 0) / 100, {
                      removeSymbol: true
                    })}&nbsp;LOT
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left">
                    &nbsp;
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right font-quicksand-semibold">
                    {currency(totalValue, { removeSymbol: true })}
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left uppercase">
                    &nbsp;
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left">
                    &nbsp;
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left">
                    &nbsp;
                  </td>
                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-right font-quicksand-semibold">
                    {currency(sumBy(data, 'tradeValue') || 0, {
                      removeSymbol: true
                    })}
                  </td>

                  <RenderIf isTrue={showQueue}>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left font-quicksand-semibold">
                      &nbsp;
                    </td>
                    <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left font-quicksand-semibold">
                      &nbsp;
                    </td>
                  </RenderIf>

                  <td className="transition-all ease-in-out duration-100 p-0 group-first:pt-1 text-left font-quicksand-semibold">
                    &nbsp;
                  </td>
                </tr>
              </RenderIf>
            </tbody>
          </table>
        </OverlayScrollbarsComponent>

        <div
          className={cn(
            'p-2 px-4 font-quicksand-medium dark:text-black-light-1-d text-xs mt-4 absolute bottom-4 left-0',
            isInline && 'relative'
          )}>
          <p className="mb-3">
            Menampilkan {data?.length} order dari total (
            {orderList?.items?.length} order)
          </p>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <div className="flex justify-between gap-2">
                <div className="flex-none flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <span className="flex-none">Open</span>
                    <div className="flex-auto">
                      <InputText
                        value={currency(openQty, { removeSymbol: true })}
                        inputSize="small"
                        className="w-[80px]"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="flex-none">Done</span>
                    <InputText
                      value={currency(doneQty, { removeSymbol: true })}
                      inputSize="small"
                      className="w-[80px]"
                      readOnly
                    />
                  </div>
                </div>

                <div className="flex-auto text-right flex flex-col gap-2">
                  <span className="block">
                    <InputText
                      value={currency(openValue, { removeSymbol: true })}
                      inputSize="small"
                      className="mb-0"
                      readOnly
                    />
                  </span>
                  <span className="block">
                    <InputText
                      value={currency(doneValue, { removeSymbol: true })}
                      inputSize="small"
                      className="mb-0"
                      readOnly
                    />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-between gap-2">
                <div className="flex-none flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <span className="flex-none">Buy (O)</span>
                    <div className="flex-auto">
                      <InputText
                        value={currency(buyOpenQty, { removeSymbol: true })}
                        inputSize="small"
                        className="w-[80px]"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="flex-none">Buy (M)</span>
                    <InputText
                      value={currency(buyDoneQty, { removeSymbol: true })}
                      inputSize="small"
                      className="w-[80px]"
                      readOnly
                    />
                  </div>
                </div>

                <div className="flex-auto text-right flex flex-col gap-2">
                  <span className="block">
                    <InputText
                      value={currency(buyOpenValue, { removeSymbol: true })}
                      inputSize="small"
                      className="mb-0"
                      readOnly
                    />
                  </span>
                  <span className="block">
                    <InputText
                      value={currency(buyDoneValue, { removeSymbol: true })}
                      inputSize="small"
                      className="mb-0"
                      readOnly
                    />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-between gap-2">
                <div className="flex-none flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <span className="flex-none">Sell (O)</span>
                    <div className="flex-auto">
                      <InputText
                        value={currency(sellOpenQty, { removeSymbol: true })}
                        inputSize="small"
                        className="w-[80px]"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="flex-none">Sell (M)</span>
                    <InputText
                      value={currency(sellDoneQty, { removeSymbol: true })}
                      inputSize="small"
                      className="w-[80px]"
                      readOnly
                    />
                  </div>
                </div>

                <div className="flex-auto text-right flex flex-col gap-2">
                  <span className="block">
                    <InputText
                      value={currency(sellOpenValue, { removeSymbol: true })}
                      inputSize="small"
                      className="mb-0"
                      readOnly
                    />
                  </span>
                  <span className="block">
                    <InputText
                      value={currency(sellDoneValue, { removeSymbol: true })}
                      inputSize="small"
                      className="mb-0"
                      readOnly
                    />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-between gap-2">
                <div className="flex-none flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <span className="flex-none">Total</span>
                    <div className="flex-auto">
                      <InputText
                        value={currency(totalQTYOpenMatch, {
                          removeSymbol: true
                        })}
                        inputSize="small"
                        className="w-[80px]"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="flex-none">Nett Buy Sell</span>
                  </div>
                </div>

                <div className="flex-auto text-right flex flex-col gap-2">
                  <span className="block">
                    <InputText
                      value={currency(totalValueOpenMatch, {
                        removeSymbol: true
                      })}
                      inputSize="small"
                      className="mb-0"
                      readOnly
                    />
                  </span>
                  <span className="block">
                    <InputText
                      value={currency(netBuySellDone, { removeSymbol: true })}
                      inputSize="small"
                      className="mb-0"
                      readOnly
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RenderIf>

      <RenderIf isTrue={selectedOrder?.length > 0}>
        <div className="py-6 px-4 border-t dark:border-gray-base-d flex justify-end gap-4 absolute w-full bottom-0 left-0 card-widget-gradient">
          <button
            data-qa-id="btnStockBuySubmit"
            disabled={selectedOrder?.length === 0}
            onClick={() => {
              setSelectedOrder([]);
              setCheckedAll(false);
            }}
            type="button"
            className="bg-transparent disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-main-base-l py-[8px] px-3 text-xs font-quicksand-semibold rounded-lg w-16 block transition-all ease-in-out duration-100">
            Batalkan
          </button>

          <button
            data-qa-id="btnStockBuySubmit"
            disabled={selectedOrder?.length === 0}
            onClick={() => setOpenModalConfirmationMultipleAmend(selectedOrder)}
            type="button"
            className="bg-white border-main-base-l border-2 disabled:bg-[#D9DEE2] dark:bg-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-main-base-l py-[8px] px-3 text-xs font-quicksand-semibold rounded-lg w-32 block transition-all ease-in-out duration-100">
            Amend ({selectedOrder?.length})
          </button>

          <button
            data-qa-id="btnStockBuySubmit"
            disabled={selectedOrder?.length === 0}
            onClick={() =>
              setOpenModalConfirmationMultipleWithdraw(selectedOrder)
            }
            type="button"
            className="bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-white py-[8px] px-3 text-xs font-quicksand-semibold rounded-lg w-32 block transition-all ease-in-out duration-100">
            Withdraw ({selectedOrder?.length})
          </button>
        </div>
      </RenderIf>

      {/* ORDER DETAIL */}
      <RenderIf isTrue={openOrderDetail}>
        <Modal
          size="large"
          onClose={() => {
            setSelectedOrder([]);
            setOpenOrderDetail(false);
          }}>
          <OrderDetail
            data={openOrderDetail?.detail}
            amendWithdraw={true}
            type={openOrderDetail?.type}
            onClose={() => {
              setSelectedOrder([]);
              setOpenOrderDetail(false);
            }}
          />
        </Modal>
      </RenderIf>

      {/* MODAL CONFIRMATION MULTIPLE WITHDRAW */}
      <RenderIf isTrue={openModalConfirmationMultipleWithdraw}>
        <Modal
          onClose={() => {
            setOpenModalConfirmationMultipleWithdraw(false);
            setCheckedAll(false);
            setSelectedOrder([]);
          }}>
          <ModalConfirmationMultipleWithdraw
            isSubmitting={isSubmitting}
            data={openModalConfirmationMultipleWithdraw}
            onClose={() => {
              setOpenModalConfirmationMultipleWithdraw(false);
              setCheckedAll(false);
              setSelectedOrder([]);
            }}
            onSubmit={handleSubmitMultipleWithdraw}
          />
        </Modal>
      </RenderIf>

      {/* MODAL CONFIRMATION MULTIPLE AMEND */}
      <RenderIf isTrue={openModalConfirmationMultipleAmend}>
        <Modal
          onClose={() => {
            setOpenModalConfirmationMultipleAmend(false);
            setCheckedAll(false);
            setSelectedOrder([]);
          }}>
          <ModalConfirmationMultipleAmend
            isSubmitting={isSubmitting}
            data={openModalConfirmationMultipleAmend}
            onClose={() => {
              setOpenModalConfirmationMultipleAmend(false);
              setCheckedAll(false);
              setSelectedOrder([]);
            }}
            onSubmit={(editedData) => handleSubmitMultipleAmend(editedData)}
          />
        </Modal>
      </RenderIf>

      {/* MODAL CONFIRMATION WITHDRAW FORM */}
      <RenderIf isTrue={openModalWithdrawForm}>
        <Modal
          onClose={() => {
            setOpenModalWithdrawForm(false);
            setSelectedOrder([]);
          }}>
          <StockWithdrawForm
            isOpen={true}
            onClose={() => {
              setOpenModalWithdrawForm(false);
              setSelectedOrder([]);
            }}
            data={{
              id: openModalWithdrawForm?.id,
              code: openModalWithdrawForm?.stID,
              orderNumber: openModalWithdrawForm?.clOID,
              price: openModalWithdrawForm?.price,
              lot: Math.abs(openModalWithdrawForm?.qty / 100)
            }}
            onSuccess={() => setOpenModalWithdrawForm(false)}
          />
        </Modal>
      </RenderIf>
    </div>
  );
}

TWOrderlist.propTypes = {
  title: PropTypes.any
};

export default TWOrderlist;
