import IconSorting from 'components/svg/icon-sorting';
import { sumBy } from 'lodash';
import { currency } from 'utils/format';
import ReturnTextValue from './done-detail-return-value';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import RenderIf from 'components/basic/render-if';
import cn from 'utils/classname';

export default function DoneDetailGroupingPrice({
  sortBy = {},
  data = [],
  handleSorting = () => {},
  getSorting = () => {}
}) {
  return (
    <div className='h-full'>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        style={{ maxHeight: 'calc(100%)' }}>
        {data?.map((groupByCode, kGroup) => (
          <div key={kGroup} className="mb-4">
            {groupByCode?.listBySide?.map((itemTR, key) => (
              <table
                className={`w-full text-center font-quicksand-regular text-2xs mb-3 border-gray-base-l dark:border-gray-base-d first:border-t`}>
                <thead className="text-gray-light-0-d dark:text-black-light-0-d">
                  <tr className="border-b text-left dark:border-gray-base-d">
                    <td
                      className="p-1 py-1.5 h-[30px] cursor-pointer w-[10%]"
                      onClick={() => handleSorting('stID')}>
                      <RenderIf isTrue={key === 0}>
                        <span
                          className={`flex items-center gap-1 ${
                            sortBy?.s === 'stID' && 'text-main-base-d'
                          }`}>
                          <IconSorting
                            type={getSorting('stID')}
                            className={cn('h-3 w-3 hidden', sortBy?.s === 'stID' && 'block')}
                          />
                          StockID
                        </span>
                      </RenderIf>
                    </td>

                    <td
                      className="p-1 py-1.5 h-[30px] cursor-pointer w-[5%]"
                      onClick={() => handleSorting('side')}>
                      <RenderIf isTrue={key === 0}>
                        <span
                          className={`flex items-center gap-1 cursor-pointer ${
                            sortBy?.s === 'side' && 'text-main-base-d'
                          }`}>
                          <IconSorting
                            type={getSorting('side')}
                            className={cn('h-3 w-3 hidden', sortBy?.s === 'side' && 'block')}
                          />
                          B/S
                        </span>
                      </RenderIf>
                    </td>

                    <td
                      className="p-1 py-1.5 h-[30px] cursor-pointer w-[15%]"
                      onClick={() => handleSorting('price')}>
                      <RenderIf isTrue={key === 0}>
                        <span
                          className={`flex items-center gap-1 justify-end cursor-pointer ${
                            sortBy?.s === 'price' && 'text-main-base-d'
                          }`}>
                          <IconSorting
                            type={getSorting('price')}
                            className={cn('h-3 w-3 hidden', sortBy?.s === 'price' && 'block')}
                          />
                          Price
                        </span>
                      </RenderIf>
                    </td>

                    <td
                      className="p-1 py-1.5 h-[30px] cursor-pointer w-[23.3%]"
                      onClick={() => handleSorting('qty')}>
                      <RenderIf isTrue={key === 0}>
                        <span
                          className={`flex items-center gap-1 justify-end cursor-pointer ${
                            sortBy?.s === 'qty' && 'text-main-base-d'
                          }`}>
                          <IconSorting
                            type={getSorting('qty')}
                            className={cn('h-3 w-3 hidden', sortBy?.s === 'qty' && 'block')}
                          />
                          LOT
                        </span>
                      </RenderIf>
                    </td>

                    <td
                      className="p-1 py-1.5 h-[30px] cursor-pointer w-[23.3%]"
                      onClick={() => handleSorting('tradeValue')}>
                      <RenderIf isTrue={key === 0}>
                        <span
                          className={`flex items-center gap-1 justify-end cursor-pointer ${
                            sortBy?.s === 'tradeValue' && 'text-main-base-d'
                          }`}>
                          <IconSorting
                            type={getSorting('tradeValue')}
                            className={cn('h-3 w-3 hidden', sortBy?.s === 'tradeValue' && 'block')}
                          />
                          Trade&nbsp;Value
                        </span>
                      </RenderIf>
                    </td>

                    <td
                      className="p-1 py-1.5 h-[30px] cursor-pointer w-[23.3%]"
                      onClick={() => handleSorting('amount')}>
                      <RenderIf isTrue={key === 0}>
                        <span
                          className={`flex items-center gap-1 justify-end cursor-pointer ${
                            sortBy?.s === 'amount' && 'text-main-base-d'
                          }`}>
                          <IconSorting
                            type={getSorting('amount')}
                            className={cn('h-3 w-3 hidden', sortBy?.s === 'amount' && 'block')}
                          />
                          Amount
                        </span>
                      </RenderIf>
                    </td>
                  </tr>
                </thead>

                <tbody className="dark:text-black-light-1-d">
                  {itemTR?.listByPrice?.map((item, key) => {
                    return (
                      <tr key={key} className="text-left">
                        <td className="p-1 font-quicksand-semibold">
                          {item?.stID}
                        </td>

                        <td className="p-1">
                          {item?.side === 'B' ? (
                            <span className="text-red-base-l">BUY</span>
                          ) : (
                            <span className="text-green-base-l">SELL</span>
                          )}
                        </td>

                        <td className="p-1 text-right">
                          {currency(item?.price, {
                            removeSymbol: true
                          })}
                        </td>

                        <td className="p-1 text-right">
                          {currency(item?.lot, {
                            removeSymbol: true
                          })}{' '}
                          LOT
                        </td>

                        <td className="p-1 font-quicksand-semibold text-right">
                          {currency(item?.value, {
                            removeSymbol: true
                          })}
                        </td>

                        <td className="p-1 font-quicksand-semibold text-right">
                          {currency(item?.amount, {
                            removeSymbol: true
                          })}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td className="p-1 py-1.5 font-quicksand-semibold text-right border-t border-gray-base-l dark:border-gray-base-d"></td>
                    <td className="p-1 py-1.5 font-quicksand-semibold text-left border-t border-gray-base-l dark:border-gray-base-d">
                      #Summary
                    </td>
                    <td className="p-1 py-1.5 font-quicksand-semibold text-right border-t border-gray-base-l dark:border-gray-base-d">
                      (Avg. Price)
                      {currency(itemTR?.averagePrice, {
                        removeSymbol: true
                      })}
                    </td>
                    <td className="p-1 py-1.5 font-quicksand-semibold text-right border-t border-gray-base-l dark:border-gray-base-d">
                      {currency(itemTR?.lot, {
                        removeSymbol: true
                      })}
                      &nbsp;LOT
                    </td>
                    <td className="p-1 py-1.5 font-quicksand-semibold text-right border-t border-gray-base-l dark:border-gray-base-d">
                      {currency(itemTR?.value, {
                        removeSymbol: true
                      })}
                    </td>
                    <td className="p-1 py-1.5 font-quicksand-semibold text-right border-t border-gray-base-l dark:border-gray-base-d">
                      {currency(itemTR?.amount, {
                        removeSymbol: true
                      })}
                    </td>
                  </tr>

                  {key === groupByCode?.listBySide?.length - 1 && (
                    <tr>
                      <td
                        className="p-1 py-1.5 text-right font-quicksand-semibold"
                        colSpan={3}>
                        Nett Of {groupByCode?.stID}
                      </td>
                      <td className="p-1 py-1.5 text-right font-quicksand-semibold">
                        <ReturnTextValue
                          reverseColor
                          suffix=" LOT"
                          buyValue={
                            sumBy(
                              groupByCode?.listSingle?.filter(
                                (tr) => tr?.side === 'B'
                              ),
                              'qty'
                            ) / 100
                          }
                          sellValue={
                            sumBy(
                              groupByCode?.listSingle?.filter(
                                (tr) => tr?.side === 'S'
                              ),
                              'qty'
                            ) / 100
                          }
                        />
                      </td>
                      <td className="p-1 py-1.5 text-right font-quicksand-semibold">
                        <ReturnTextValue
                          prefix=""
                          buyValue={sumBy(
                            groupByCode?.listSingle?.filter(
                              (tr) => tr?.side === 'B'
                            ),
                            'tradeValue'
                          )}
                          sellValue={sumBy(
                            groupByCode?.listSingle?.filter(
                              (tr) => tr?.side === 'S'
                            ),
                            'tradeValue'
                          )}
                        />
                      </td>
                      <td className="p-1 py-1.5 text-right font-quicksand-semibold">
                        <ReturnTextValue
                          prefix=""
                          buyValue={sumBy(
                            groupByCode?.listSingle?.filter(
                              (tr) => tr?.side === 'B'
                            ),
                            'amount'
                          )}
                          sellValue={sumBy(
                            groupByCode?.listSingle?.filter(
                              (tr) => tr?.side === 'S'
                            ),
                            'amount'
                          )}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ))}
          </div>
        ))}
      </OverlayScrollbarsComponent>
    </div>
  );
}
