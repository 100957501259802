import RenderIf from 'components/basic/render-if';
import CardNewsItem from 'components/cards/card-news-item';
import PropTypes from 'prop-types';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetStockNewsByCodeQuery } from 'services/rtk-query/stock';

function TWNews({ symbol }) {
  const { data, isFetching } = useGetStockNewsByCodeQuery(symbol);

  return (
    <div className="px-3 pb-3 h-full">
      <RenderIf isTrue={data?.length > 0 && !isFetching}>
        <ul>
          {data?.map((item, key) => (
            <li
              key={key}
              className={`${
                key < data.length - 1 && 'border-b dark:border-gray-base-d'
              }`}>
              <CardNewsItem
                {...item}
                data-link-qa-id="linkStockRSSNews"
                data-img-qa-id="imgStockRSSNews"
              />
            </li>
          ))}
        </ul>
      </RenderIf>

      <RenderIf isTrue={isFetching}>
        {[0, 1, 2, 3, 4]?.map((item) => (
          <div key={item} className="flex mb-4">
            <div className="flex-none mr-4">
              <SkeletonTheme width="64px" height="64px" borderRadius="6px" />
            </div>
            <div className="flex-auto">
              <SkeletonTheme width="100%" height="1rem" borderRadius="6px" />
              <SkeletonTheme width="100%" height="1rem" borderRadius="6px" />
              <SkeletonTheme width="200px" height="1rem" borderRadius="6px" />
            </div>
          </div>
        ))}
      </RenderIf>

      <RenderIf isTrue={data?.length === 0 && !isFetching}>
        <p className="text-sm text-gray-light-0-d">Belum ada berita baru.</p>
      </RenderIf>
    </div>
  );
}

TWNews.propTypes = {
  symbol: PropTypes.string
};

export default TWNews;
