export const CloseSession = {
  name: 'Market Close',
  color: '#FD4F06',
  status: 'stopped'
};

export const IepIevSessions = [
  {
    name: 'IEP/IEV Pre Opening',
    startTime: '07:00:00',
    endTime: '19:00:00'
  },
  {
    name: 'IEP/IEV Pre Closing',
    startTime: '15:50:00',
    endTime: '16:15:00'
  }
];

export const NormalSessions = [
  {
    name: 'Pre Opening',
    startTime: '08:45:00',
    endTime: '08:59:59',
    color: '#FCB813',
    status: 'stopped'
  },
  {
    name: 'Market Open 1',
    startTime: '09:00:00',
    endTime: '12:00:00',
    color: '#7BCC29',
    status: 'played'
  },
  {
    name: 'Lunch Break',
    startTime: '12:00:01',
    endTime: '13:29:59',
    color: '#9E9E9E',
    status: 'stopped'
  },
  {
    name: 'Market Open 2',
    startTime: '13:30:00',
    endTime: '15:49:59',
    color: '#7BCC29',
    status: 'played'
  },
  {
    name: 'Random Closing',
    startTime: '15:58:00',
    endTime: '16:00:59',
    color: '#FCB813',
    status: 'played'
  },
  {
    name: 'Pre Closing',
    startTime: '15:50:00',
    endTime: '16:00:59',
    color: '#FCB813',
    status: 'played'
  },
  {
    name: 'Post Closing',
    startTime: '16:01:00',
    endTime: '16:15:00',
    color: '#FCB813',
    status: 'played'
  }
];

export const FridaySessions = [
  {
    name: 'Pre Opening',
    startTime: '08:45:00',
    endTime: '08:59:59',
    color: '#FCB813',
    status: 'stopped'
  },
  {
    name: 'Market Open 1',
    startTime: '09:00:00',
    endTime: '11:30:00',
    color: '#7BCC29',
    status: 'played'
  },
  {
    name: 'Lunch Break',
    startTime: '11:30:01',
    endTime: '13:59:59',
    color: '#9E9E9E',
    status: 'stopped'
  },
  {
    name: 'Market Open 2',
    startTime: '14:00:00',
    endTime: '15:49:59',
    color: '#7BCC29',
    status: 'played'
  },
  {
    name: 'Random Closing',
    startTime: '15:58:00',
    endTime: '16:00',
    color: '#FCB813',
    status: 'played'
  },
  {
    name: 'Pre Closing',
    startTime: '15:50:00',
    endTime: '16:00:59',
    color: '#FCB813',
    status: 'played'
  },
  {
    name: 'Post Closing',
    startTime: '16:01:00',
    endTime: '16:15:00',
    color: '#FCB813',
    status: 'played'
  }
];
