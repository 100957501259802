import { reverse } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OrderbookChart from '../orderbook-chart';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { useRealtimeOrderbook } from 'hooks/useRealtimeOrderbook';

function TVChartOrderbook({ symbol, rows = 99, onClickPrice = () => {} }) {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { stockDetail } = useRealtimeStock(symbol);
  const { orderBooks } = useRealtimeOrderbook(symbol, isBursaOpen ? stockDetail?.bidPrice : stockDetail?.lastTradedPrice);

  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let _series = [
      {
        name: 'Bid',
        data: []
      },
      {
        name: 'Ask',
        data: []
      }
    ];

    let _categories = [];
    let yBidData = 0;
    let orderbooksBid = reverse(orderBooks?.bid);

    reverse(orderBooks?.bid)?.map((bid, key) => {
      if (bid?.price) {
        yBidData += Number(bid?.lot);
        _series[0].data.push(null);
        _series[1].data.push(null);
        _categories.push(bid?.price);
      }
      if (key === (orderBooks?.bid?.length - 1)) {
        _series[0].data.push(0);
        _series[1].data.push(0);
        _categories.push(0);
      }
    });

    orderbooksBid?.map((bid, key) => {
      if (bid?.lot) {
        _series[0].data[key] = yBidData;
        yBidData -= Number(bid?.lot);
      }
    });
    
    let yAskData = 0;
    orderBooks?.ask?.map((ask) => {
      if (ask?.price) {
        yAskData += Number(ask?.lot);
        _series[1].data.push(yAskData);
        _series[0].data.push(null);
        _categories.push(ask?.price);
      }
    });

    setSeries(_series);
    setCategories(_categories.sort(function(a, b){return a - b}));
  }, [orderBooks]);

  return (<OrderbookChart series={series} categories={categories} totalBidLot={orderBooks?.totalBidLot} totalAskLot={orderBooks?.totalAskLot} onClickPrice={onClickPrice} />);
}

TVChartOrderbook.propTypes = {
  symbol: PropTypes.string,
  rows: PropTypes.number,
  onClickPrice: PropTypes.func,
};

export default TVChartOrderbook;
