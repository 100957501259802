export const LIST_SHORTCUT = [
  {
    id: 1,
    code: 'F1 / ALT + H',
    name: 'Help',
    group: 'Order'
  },
  {
    id: 2,
    code: 'F2 / ALT + B',
    name: 'Buy',
    group: 'Order'
  },
  {
    id: 3,
    code: 'F3 / ALT + A',
    name: 'Amend',
    group: 'Order'
  },
  {
    id: 4,
    code: 'F4 / ALT + S',
    name: 'Sell',
    group: 'Order'
  },
  {
    id: 5,
    code: 'F5',
    name: 'Client Portfolio',
    group: 'Order'
  },
  {
    id: 6,
    code: 'F6',
    name: 'Client Position',
    group: 'Order'
  },
  // {
  //   id: 7,
  //   code: 'F7',
  //   name: 'JSX Index',
  //   group: 'Order'
  // },
  {
    id: 8,
    code: 'F8',
    name: 'Running Trade',
    group: 'Order'
  },
  // {
  //   id: 9,
  //   code: 'F10 / CTRL + F',
  //   name: 'Fast Filter',
  //   group: 'Order'
  // },
  // {
  //   id: 10,
  //   code: 'F11 / ALT + F',
  //   name: 'Advanced Filter',
  //   group: 'Order'
  // },
  {
    id: 12,
    code: 'F12 / ALT + W',
    name: 'Withdraw',
    group: 'Order'
  },
  // {
  //   id: 13,
  //   code: 'CTRL + G',
  //   name: 'GTC Confirm',
  //   group: 'Order'
  // },
  // {
  //   id: 14,
  //   code: '[Space]',
  //   name: 'Select Order',
  //   group: 'Order'
  // },
  {
    id: 15,
    code: 'CTRL + Space',
    name: 'Select All Order',
    group: 'Order'
  },
  {
    id: 16,
    code: 'CTRL + W',
    name: 'Show Widget List',
    group: 'Order'
  },
  {
    id: 17,
    code: 'ESC',
    name: 'Menutup Pop Up',
    group: 'Order'
  },
  // {
  //   id: 16,
  //   code: 'PgUp',
  //   name: 'Up Price',
  //   group: 'Order'
  // },
  // {
  //   id: 16,
  //   code: 'PgDown',
  //   name: 'Down Price',
  //   group: 'Order'
  // },
  // {
  //   id: 17,
  //   code: 'ALT + Y',
  //   name: 'Advertising Buy',
  //   group: 'General'
  // },
  // {
  //   id: 18,
  //   code: 'ALT + L',
  //   name: 'Advertising Sell',
  //   group: 'General'
  // },
  // {
  //   id: 19,
  //   code: 'ALT + D',
  //   name: 'Input Neg Deal',
  //   group: 'General'
  // },
  // {
  //   id: 20,
  //   code: 'ALT + C',
  //   name: 'Confirm Neg Deal',
  //   group: 'General'
  // },
  // {
  //   id: 21,
  //   code: 'ALT + I',
  //   name: 'Split',
  //   group: 'Order'
  // },
  {
    id: 22,
    code: 'ALT + E',
    name: 'Reset Filter Widget',
    group: 'General'
  },
  // {
  //   id: 23,
  //   code: 'ALT + N',
  //   name: 'Resend',
  //   group: 'General'
  // },
  {
    id: 24,
    code: 'ALT + R',
    name: 'Refresh Widget',
    group: 'General'
  },
  {
    id: 25,
    code: 'ALT + X',
    name: 'Exit Widget',
    group: 'General'
  },
  {
    id: 26,
    code: 'ALT + Q',
    name: 'Quick Order',
    group: 'General'
  },
  // {
  //   id: 27,
  //   code: 'ALT + 0',
  //   name: 'Limit',
  //   group: 'General'
  // },
  // {
  //   id: 28,
  //   code: 'ALT + 1',
  //   name: 'All Order',
  //   group: 'General'
  // },
  {
    id: 29,
    code: 'ALT + 4',
    name: 'Order Book',
    group: 'General'
  },
  {
    id: 30,
    code: 'ALT + 5',
    name: 'Order View',
    group: 'General'
  },
  // {
  //   id: 31,
  //   code: 'ALT + 6',
  //   name: 'Neg Deal View',
  //   group: 'General'
  // },
  {
    id: 32,
    code: 'ALT + 7',
    name: 'Trade View',
    group: 'General'
  },
  // {
  //   id: 33,
  //   code: 'ALT + 8',
  //   name: 'Stock View',
  //   group: 'General'
  // },
  // {
  //   id: 34,
  //   code: 'ALT + 9',
  //   name: 'Indices',
  //   group: 'General'
  // },
  {
    id: 35,
    code: '[Open]',
    name: 'Order Already Open',
    group: 'Status'
  },
  {
    id: 36,
    code: '[Basket]',
    name: 'Order Wait To Send',
    group: 'Status'
  },
  {
    id: 37,
    code: '[Match]',
    name: 'Order Full Match',
    group: 'Status'
  },
  {
    id: 38,
    code: '[Partial]',
    name: 'Order Partial Match',
    group: 'Status'
  },
  {
    id: 39,
    code: '[Amend]',
    name: 'Order Already Amended',
    group: 'Status'
  },
  {
    id: 40,
    code: '[Withdraw]',
    name: 'Order Already Withdrawn',
    group: 'Status'
  },
  // {
  //   id: 41,
  //   code: 'a',
  //   name: 'Order wait Amend Reply',
  //   group: 'Status'
  // },
  // {
  //   id: 42,
  //   code: 'w',
  //   name: 'Order wait Withdraw Reply',
  //   group: 'Status'
  // },
  {
    id: 43,
    code: '[Reject]',
    name: 'Order Rejected / Cancelled',
    group: 'Status'
  },
  // {
  //   id: 44,
  //   code: 'r',
  //   name: 'Order will be re-Send',
  //   group: 'Status'
  // },
  // {
  //   id: 45,
  //   code: 'S',
  //   name: 'Care wait Ack / Reject',
  //   group: 'Status'
  // },
  // {
  //   id: 46,
  //   code: 'C',
  //   name: 'Care Ack-ed',
  //   group: 'Status'
  // },
  // {
  //   id: 47,
  //   code: 'G',
  //   name: 'Care Partial Filled',
  //   group: 'Status'
  // },
  // {
  //   id: 48,
  //   code: 'F',
  //   name: 'Care Full Filled',
  //   group: 'Status'
  // },
  // {
  //   id: 49,
  //   code: 'T',
  //   name: 'Done For The Day',
  //   group: 'Status'
  // },
];
