import PropTypes from 'prop-types';
import Card from '../../../components/basic/card';
import ProfileForm from 'components/form/profile-form';
import { useEffect, useState } from 'react';
import { useGetImageQuery } from 'services/rtk-query/image';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function SectionProfileDetail() {
  const { user, token } = useBrowserTabAuth();
  
  const [avatarUrl, setAvatarUrl] = useState(null);
  const AvPP = (user?.firstName?.charAt(0) || '');

  const { data: avatarURL} = useGetImageQuery(user?.imgAvatarUrl?.replace('BASE_URL/images/', ''));

  useEffect(() => {
    if (avatarURL) setAvatarUrl(avatarURL);
  }, [avatarURL]);

  return (
    <Card>
      <div className="flex items-center p-5 mb-3">
        <div className="flex grow">
          <h5>Atur Profile</h5>
        </div>
      </div>

      <ProfileForm
        initialValues={{
          email: user?.email?.toUpperCase(),
          phoneNo: user?.phoneNo,
          fullName: user?.firstName ? `${user?.firstName?.toUpperCase() || ''} ${user?.lastName?.toUpperCase() || ''}`: 'Anonymouse',
          image: avatarUrl,
          realAccount: user?.realAccount,
          token: token,
          AvPP: AvPP || 'A'
        }}
      />
    </Card>
  );
}

SectionProfileDetail.propTypes = {
  className: PropTypes.string
};

export default SectionProfileDetail;
