import PropTypes from 'prop-types';

function IconLock({ className }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66663 5.98412C6.66663 4.14317 8.15901 2.65079 9.99996 2.65079C11.8409 2.65079 13.3333 4.14317 13.3333 5.98412V10.1508H11.6666V5.98412C11.6666 5.06365 10.9204 4.31745 9.99996 4.31745C9.07948 4.31745 8.33329 5.06365 8.33329 5.98412V10.1508H6.66663V5.98412Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.13229 8.48413H11.8678C12.5386 8.48412 13.0922 8.48411 13.5432 8.52096C14.0116 8.55923 14.4422 8.64137 14.8467 8.84744C15.4739 9.16702 15.9838 9.67696 16.3034 10.3042C16.5095 10.7086 16.5916 11.1392 16.6299 11.6076C16.6667 12.0586 16.6667 12.6123 16.6667 13.2831V14.5185C16.6667 15.1893 16.6667 15.743 16.6299 16.194C16.5916 16.6624 16.5095 17.093 16.3034 17.4974C15.9838 18.1246 15.4739 18.6346 14.8467 18.9542C14.4422 19.1602 14.0116 19.2424 13.5432 19.2806C13.0922 19.3175 12.5386 19.3175 11.8678 19.3175H8.13231C7.46149 19.3175 6.90785 19.3175 6.45685 19.2806C5.98844 19.2424 5.55785 19.1602 5.15341 18.9542C4.5262 18.6346 4.01626 18.1246 3.69669 17.4974C3.49061 17.093 3.40847 16.6624 3.3702 16.194C3.33336 15.743 3.33336 15.1894 3.33337 14.5185V13.283C3.33336 12.6122 3.33336 12.0586 3.3702 11.6076C3.40847 11.1392 3.49061 10.7086 3.69669 10.3042C4.01626 9.67696 4.5262 9.16702 5.15341 8.84744C5.55785 8.64137 5.98844 8.55923 6.45685 8.52096C6.90785 8.48411 7.46148 8.48412 8.13229 8.48413ZM6.59257 10.1821C6.22723 10.2119 6.04039 10.266 5.91006 10.3325C5.59645 10.4922 5.34149 10.7472 5.1817 11.0608C5.11529 11.1911 5.06118 11.378 5.03133 11.7433C5.00069 12.1184 5.00004 12.6036 5.00004 13.3175V14.4841C5.00004 15.1979 5.00069 15.6832 5.03133 16.0583C5.06118 16.4236 5.11529 16.6104 5.1817 16.7408C5.34149 17.0544 5.59645 17.3094 5.91006 17.4691C6.04039 17.5356 6.22723 17.5897 6.59257 17.6195C6.96765 17.6502 7.45289 17.6508 8.16671 17.6508H11.8334C12.5472 17.6508 13.0324 17.6502 13.4075 17.6195C13.7729 17.5897 13.9597 17.5356 14.09 17.4691C14.4036 17.3094 14.6586 17.0544 14.8184 16.7408C14.8848 16.6104 14.9389 16.4236 14.9687 16.0583C14.9994 15.6832 15 15.1979 15 14.4841V13.3175C15 12.6036 14.9994 12.1184 14.9687 11.7433C14.9389 11.378 14.8848 11.1911 14.8184 11.0608C14.6586 10.7472 14.4036 10.4922 14.09 10.3325C13.9597 10.266 13.7729 10.2119 13.4075 10.1821C13.0324 10.1514 12.5472 10.1508 11.8334 10.1508H8.16671C7.45289 10.1508 6.96765 10.1514 6.59257 10.1821Z"
      />
      <path d="M9.16663 14.3175C9.16663 13.8572 9.53972 13.4841 9.99996 13.4841C10.4602 13.4841 10.8333 13.8572 10.8333 14.3175V15.1508C10.8333 15.611 10.4602 15.9841 9.99996 15.9841C9.53972 15.9841 9.16663 15.611 9.16663 15.1508V14.3175Z" />
    </svg>
  );
}

IconLock.propTypes = {
  className: PropTypes.string
};

export default IconLock;
