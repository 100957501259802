import PropTypes from 'prop-types';
import InputCounter from 'components/input/input-counter';
import { useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { automatedNumberFormat, currency, generateOrderId } from 'utils/format';
import { StockSellSchema } from './order-focus-sell.form.validation';
import RenderIf from 'components/basic/render-if';
import { isEmpty, uniqueId } from 'lodash';
import { marginPlusShare } from 'utils/formula/margin';
import InputStock from 'components/input/input-stock';
import { invalidateAllOrder, useCreateOrderSellMutation, useGetPortfolioListQuery } from 'services/rtk-query/trade';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmationStockSell from '../_partials/modal-confirmation-stock-sell';
import InputRadio from 'components/input/input-radio';
import InputSwitch from 'components/input/input-switch';
import { useLazyGetSplitOrderQuery } from 'services/rtk-query/trading';
import { triggerSegmentEvent } from 'lib/segment';
import InputDatePickerSimple from 'components/input/input-date-picker-simple';
import { addDays, subDays } from 'date-fns';
import { invalidateUserNotification } from 'services/rtk-query/user';
import { dayjsLocal } from 'utils/formatter/date';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputCheckBox from 'components/input/input-check-box';
import useToastr from 'hooks/useToastr';
import { setQuickOrderSettingsConfirmation } from 'store/settings';
import IconSort from 'components/svg/icon-sort';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import useBrowserTabTrading from 'hooks/useBrowserTabTrading';

function OrderFocusSellForm({
  stockDetail,
  user,
  isSticky,
  mockOnly = false,
  defaultSplitOrder = false,
  portfolio = { lot: 0 },
  onChangeStock = () => {},
  analyticEventName = 'TR Sell Stock from Widget'
}) {
  const refInputPrice = useRef(null);
  const refInputLot = useRef(null);
  const refInputSplitLot = useRef(null);
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();
  const { dealerUseClientId } = useBrowserTabAuth();
  const quickOrderConfirmation = useSelector((state) => state?.settings?.quickOrderSettings?.confirmation);
  const { isLoginPin } = useBrowserTabTrading();
  const { data: portFolio } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });

  const [createOrderSell] = useCreateOrderSellMutation();
  const [getSplitOrder] = useLazyGetSplitOrderQuery();

  const [isNotRealAccount, setIsNotRealAccount] = useState(false);
  const [openModalConfirmationSell, setOpenModalConfirmationSell] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [tempStockDetail, setTempStockDetail] = useState(stockDetail);

  const clearRGStockID = useMemo(() => {
    let zymbol = stockDetail?.id;
    let isTN = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'TN';
    let isNG = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'NG';

    if (isTN || isNG) {
      zymbol = zymbol?.slice(0, -2)
    }

    return zymbol;
  }, [stockDetail]);

  const clearBoardID = useMemo(() => {
    let boardID = 'RG';
    let zymbol = stockDetail?.id;
    
    let isTN = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'TN';
    let isNG = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'NG';

    if (isTN) boardID = 'TN';
    if (isNG) boardID = 'NG';

    return boardID;
  }, [stockDetail]);

  const formik = useFormik({
    initialValues: {
      lotType: true,
      amount: 0,
      mockOnly: mockOnly,
      orderNumber: generateOrderId(dealerUseClientId || user?.username, 'S'),
      code: stockDetail?.id,
      lot: 0,
      OrderType: 'LimitOrder',
      expiry: 'DAY',
      split: '',
      splitTime: 1,
      splitMode: 'normal',
      isSplitOrder: defaultSplitOrder,
      portfolio: portfolio?.lot || 0,
      price: stockDetail?.lastTradedPrice,
      expiryDate: dayjsLocal().add(1, 'day').toDate(),
    },
    validateOnMount: false,
    validationSchema: StockSellSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const findStockDetail = portFolio?.stock?.items?.find(porto => porto?.stID === stockDetail?.id);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      let payloads = {
        clientID: user?.username,
        stockID: clearRGStockID,
        clOrdID: values?.orderNumber,
        price: values?.price,
        qty: values?.lot,
        expiry: values?.expiry,
        boardID: clearBoardID,
      };

      if (values.splitTime > 1) payloads.split = await buildSplitOrder();

      if (values.OrderType === 'MarketOrder') payloads.price = 'MARKET';

      if (values.expiry === 'GTD') {
        const date1 = dayjsLocal(values?.expiryDate)
        const date2 = dayjsLocal()
        payloads.expiryAmount = Math.abs(date1.diff(date2, 'day')) + 1;
      }

      const { success, msg, err } = await createOrderSell(payloads).unwrap();

      if (!success) {
        let error = new Error();
        Object.assign(error, { msg, code: err });
        throw error;
      }

      triggerSegmentEvent({
        event: analyticEventName,
        properties: {
          stock_name: stockDetail?.id,
          total_amount: Number(values?.lot) * Number(values?.price) * 100,
          lot: values?.lot,
        }
      });

      setOpenModalConfirmationSell(false);
      toastr.success('Order telah terkirim');
      let sisaLot = (findStockDetail?.net / 100) - values?.lot
      formik.setValues({
        ...formik.values,
        lot: 0,
        amount: 0,
        portfolio: sisaLot < 0 ? 0 : sisaLot,
        orderNumber: generateOrderId(dealerUseClientId || user?.username, 'S')
      });
      
      refInputPrice?.current?.focus()
      setTimeout(() => formik.setErrors({}), 300);
      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (val, obj) => {
    formik.setFieldValue(obj, val);
  };

  const buildSplitOrder = async () => {
    let _splitString = '';
    let _splitArr = [];

    if (formik.values.splitTime > 1 || formik.values.lot > 50000) {
      if (formik.values.splitMode === 'random') {
        let { data } =  await getSplitOrder({ order: formik.values.lot, split: formik.values.splitTime });
        _splitArr = data?.arraySplit;
      } else {
        let _splitCount = Math.floor(formik.values.lot / formik.values.splitTime);
  
        for (let index = 0; index < formik.values.splitTime; index++) {
          _splitArr.push(_splitCount);
        }
  
        if (_splitCount * formik.values.splitTime < formik.values.lot) {
          _splitArr[_splitArr.length - 1] = _splitArr[_splitArr.length - 1] + (formik.values.lot - (_splitCount * formik.values.splitTime));
        }
      }
    }

    _splitArr?.forEach((val, key) => {
      _splitString += `${val}`;
      if (key < (_splitArr?.length - 1)) {
        _splitString += `;`;
      }
    });
    
    return _splitString;
  }

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();

    if (!user?.realAccount) return setIsNotRealAccount(true);

    if (isEmpty(errors)) {
      if (!quickOrderConfirmation) {
        let _orderNumber = generateOrderId(dealerUseClientId || user?.username, 'S');
        let _split = await buildSplitOrder();

        formik.setFieldValue('split', _split);    
        formik.setFieldValue('orderNumber', _orderNumber);

        return setOpenModalConfirmationSell({...formik?.values, orderNumber: _orderNumber, split: _split, isSplitOrder: _split ? true : false });
      }
      
      formik.submitForm();
    }
  };

  useEffect(() => {
    if (formik.values.code != stockDetail?.id && findStockDetail && user) {
      formik.setValues({
        ...formik.values,
        code: stockDetail?.id,
        price: stockDetail?.lastTradedPrice,
        portfolio: (findStockDetail?.net / 100) || 0,
        orderNumber: generateOrderId(dealerUseClientId || user?.username, 'S'),
      })

      setTimeout(() => {
        formik.setErrors({})
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockDetail, findStockDetail, dealerUseClientId, user]);

  useEffect(() => {
    if (findStockDetail && user) {
      formik.setValues({
        ...formik.values,
        code: stockDetail?.id,
        portfolio: (findStockDetail?.net / 100) || 0,
        orderNumber: generateOrderId(dealerUseClientId || user?.username, 'S'),
      })

      setTimeout(() => {
        formik.setErrors({})
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findStockDetail, dealerUseClientId, user]);

  useEffect(() => {
    if (stockDetail?.id !== tempStockDetail?.id && stockDetail?.id) {
      formik.setValues({
        ...formik.values,
        code: stockDetail?.id,
        price: stockDetail?.lastTradedPrice,
      });

      setTimeout(() => {
        formik.setErrors({})
      }, 300);

      setTempStockDetail(stockDetail);
      setTimeout(() => {
        refInputPrice.current.focus()
        refInputPrice?.current?.setSelectionRange(0, 9999999);
      }, 100);
    }
  }, [stockDetail, tempStockDetail]);

  return (
    <form
      className="mt-3"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !openModalConfirmationSell) handleConfirmation();
      }}
      noValidate>
      <div className="px-4">
        <div className='flex justify-between'>
          <span className="text-xs text-gray-light-0-d block font-quicksand-regular">
            Lot tersedia
          </span>

          <h5 className="font-quicksand-semibold mb-2 text-sm dark:text-black-light-1-d">
            {currency((findStockDetail?.net / 100) || 0, { removeSymbol: true })} Lot
          </h5>
        </div>

        <RenderIf isTrue={mockOnly}>
          <InputRadio
            value={formik.values.OrderType}
            name="OrderType"
            className='mb-2'
            size='p-1.5 py-[4px] first:rounded-l-lg last:rounded-r-lg border'
            options={[
              {
                value: 'LimitOrder',
                title: '',
                activeClass: 'border-main-base-d dark:text-white',
                node: <div className="text-2xs leading-4 font-quicksand-semibold line-clamp-1">Lm. Order</div>
              },
              {
                value: 'MarketOrder',
                title: '',
                activeClass: 'border-main-base-d dark:text-white',
                node: <div className="text-2xs leading-4 font-quicksand-semibold line-clamp-1">Mk. Order</div>
              },
              {
                value: 'GTC/GTD',
                title: '',
                activeClass: 'border-main-base-d dark:text-white',
                node: <div className="text-2xs leading-4 font-quicksand-semibold line-clamp-1">GTC/GTD</div>
              },
            ]}
            onChange={(e) => {
              formik.handleChange(e);
              if (e.target.value === 'MarketOrder') {
                formik.setFieldValue('price', stockDetail?.lastTradedPrice);
                formik.setFieldValue('expiry', 'DAY');
              }

              if (e.target.value === 'LimitOrder') {
                formik.setFieldValue('expiry', 'DAY');
              }

              if (e.target.value === 'GTC/GTD') {
                formik.setFieldValue('expiry', 'GTD');
                formik.setFieldValue('splitTime', 1);
              }
            }}
          />

          <RenderIf isTrue={formik.values.OrderType === 'MarketOrder'}>
            <InputRadio
              label={<span className='text-2xs text-gray-light-0-d'>Opsi Antrian Order</span>}
              value={formik.values.expiry}
              name="expiry"
              size='p-1.5 py-[4px] first:rounded-l-lg last:rounded-r-lg border'
              className='my-2'
              options={[
                {
                  value: 'DAY',
                  title: '',
                  activeClass: 'border-main-base-d dark:text-white',
                  node: <div className="text-2xs leading-4 font-quicksand-semibold">Day</div>
                },
                {
                  value: 'FOK',
                  title: '',
                  activeClass: 'border-main-base-d dark:text-white',
                  node: <div className="text-2xs leading-4 font-quicksand-semibold">FOK</div>
                },
                {
                  value: 'FAK',
                  title: '',
                  activeClass: 'border-main-base-d dark:text-white',
                  node: <div className="text-2xs leading-4 font-quicksand-semibold">FAK</div>
                },
              ]}
              onChange={formik.handleChange}
            />
          </RenderIf>

          <RenderIf isTrue={formik.values.OrderType === 'GTC/GTD'}>
            <InputRadio
              label={<span className='text-2xs text-gray-light-0-d'>Opsi Antrian Order</span>}
              value={formik.values.expiry}
              name="expiry"
              size='p-1.5 py-[4px] first:rounded-l-lg last:rounded-r-lg border'
              className='my-2'
              options={[
                {
                  value: 'GTC',
                  title: '',
                  activeClass: 'border-main-base-d dark:text-white',
                  node: <div className="text-2xs leading-4 font-quicksand-semibold">GTC</div>
                },
                {
                  value: 'GTD',
                  title: '',
                  activeClass: 'border-main-base-d dark:text-white',
                  node: <div className="text-2xs leading-4 font-quicksand-semibold">GTD</div>
                },
              ]}
              onChange={(e) => {
                if (e.target.value === 'GTC') {
                  formik.setFieldValue('expiryDate', dayjsLocal().add(1, 'year').toDate());
                }

                if (e.target.value === 'GTD') {
                  formik.setFieldValue('expiryDate', dayjsLocal().add(1, 'day').toDate());
                }
                formik.handleChange(e);
              }}
            />

            <p className='text-xs p-2 px-3 bg-gray-light-l text-gray-light-0-d dark:bg-black-dark-d rounded-md mb-3'>
              {formik.values.expiry === 'GTC' ? '* GTC otomatis berakhir 1 tahun' : '* Periode berakhir maksimal 1 tahun'}
            </p>
          </RenderIf>

          <RenderIf isTrue={formik.values.expiry === 'GTD'}>
            <InputDatePickerSimple
              value={formik.values.expiryDate}
              name="expiryDate"
              format='dd/MM/yyyy'
              className='-mt-3 mb-3'
              label={<span className='text-2xs text-gray-light-0-d'>Expiry Date</span>}
              placeholder='Pilih Expiry Date'
              onChange={(_date) => {
                formik.setFieldValue('expiryDate', _date);
              }}
              error={formik.errors.expiryDate ? formik.errors.expiryDate : ''}
              showError={false}
              includeDateIntervals={[ { start: subDays(new Date(), 1), end: addDays(new Date(), 365) }]}
            />
          </RenderIf>
        </RenderIf>

        <InputStock size='small' isClearable value={formik.values.code} onFocus={() => dispatch(invalidateAllOrder())} onChange={onChangeStock} />

        <RenderIf isTrue={['LimitOrder', 'GTC/GTD'].includes(formik.values.OrderType)}>
          <InputCounter
            ref={refInputPrice}
            isAutoFocus={isLoginPin}
            size='small'
            onBlur={formik.handleBlur}
            data-qa-id="txtStockSellPriceField"
            value={formik.values.price}
            className='my-2'
            label={<span className='text-2xs text-gray-light-0-d'>Harga Per Saham</span>}
            counterStep={marginPlusShare(formik.values.price)}
            onChange={(val) => {
              handleChange(val, 'price');
              setTimeout(() => formik.setTouched({ price: true }), 100);
            }}
            error={formik.errors.price ? formik.errors.price : ''}
            showError={false}
          />
        </RenderIf>

        <InputCounter
          ref={refInputLot}
          size='small'
          onBlur={formik.handleBlur}
          data-qa-id="txtStockSellLotField"
          value={formik.values.lotType ? formik.values.lot : formik.values.amount}
          value2={formik.values.lotType ? formik.values.amount : formik.values.lot}
          label={
            <span className='text-2xs text-gray-light-0-d'>
              <button tabIndex={-1} onClick={() => formik.setFieldValue('lotType', !formik.values.lotType)} type='button' className='flex items-center hover:text-main-base-l'>
                <IconSort className='h-4 w-4 mr-1 transform rotate-90 text-main-base-l' /> {formik.values.lotType ? 'Jumlah Lot' : 'Amount'}
              </button>
            </span>}
          label2={<span className='text-2xs text-gray-light-0-d'>{formik.values.lotType ? 'Amount' : 'Lot'}</span>}
          showShortcut={formik.values.lotType}
          counterStep={formik.values.lotType ? 1 : formik.values.price * 100}
          maxValue={(findStockDetail?.net / 100)}
          className='my-3'
          onChange={(val) => {
            if (formik.values.lotType) {
              handleChange(val, 'lot');
              handleChange(Number(val) * formik.values.price * 100 , 'amount');
            } else {
              handleChange(val, 'amount');
              handleChange(Math.floor(Number(val) / (formik.values.price * 100)) , 'lot');
            }
            // setDefaultPercent(null);
            setTimeout(() => formik.setTouched({ lot: true }), 100);
          }}
          error={formik.errors.lot || formik.errors.amount || formik.errors.portfolio ? formik.errors.lot || formik.errors.portfolio : ''}
          showError={false}
        />

        <RenderIf isTrue={mockOnly}>
          <RenderIf isTrue={['LimitOrder', 'MarketOrder'].includes(formik.values.OrderType)}>
            <div className='grid grid-cols-12 w-full mb-4 gap-4'>
              <div className='col-span-6'>
                <InputCounter
                  ref={refInputSplitLot}
                  size="small"
                  onBlur={formik.handleBlur}
                  id="txtStockBuySplitField"
                  data-qa-id="txtStockBuySplitField"
                  value={formik.values.splitTime}
                  name="splitTime"
                  max={50}
                  min={1}
                  label={<span className='text-2xs text-gray-light-0-d'>Split Time</span>}
                  className="-mt-1"
                  onChange={(val) => {
                    handleChange(val, 'splitTime');
                    setTimeout(() => formik.setTouched({ splitTime: true }), 100);
                  }}
                  error={formik.errors.splitTime ? formik.errors.splitTime : ''}
                  showError={false}
                />
              </div>

              <div className='col-span-6 flex items-center justify-end'>
                <div className='h-full'>
                  <label className="block font-quicksand-regular text-xs dark:text-gray-light-0-d mb-1">
                    Random LOT
                  </label>
                  <div className='flex items-center justify-end'>
                    <InputSwitch
                      name="splitMode"
                      size="small"
                      color='gold'
                      value={formik.values.splitMode === 'random'}
                      onChange={(event) => {
                        handleChange(event.target.checked ? 'random' : 'normal', 'splitMode')
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </RenderIf>
        </RenderIf>

        <div className="flex border-y dark:border-gray-base-d py-2 items-center">
          <span className="flex-none text-xs text-gray-light-0-d font-quicksand-regular">
            Total Penjualan
          </span>
          <h5 className="flex-grow text-right text-sm dark:text-black-light-1-d">
            {currency(formik.values.lot * formik.values.price * 100 || 0)}
          </h5>
        </div>

        {/* <RenderIf isTrue={!formik?.isValid}>
          <div className="font-quicksand-semibold py-3 px-4 flex items-center text-red-base-l text-xs rounded-xl my-3 bg-red-base-l bg-opacity-10">
            <IconWarning className="h-5 w-5 mr-1 flex-none" />
            {Object.keys(formik?.errors)?.map((key, idx) => (
              <RenderIf key={key} isTrue={idx === 0}>
                <span
                  key={key}
                  className="flex items-center w-full flex-auto">
                  {idx === 0 && formik?.errors[key]}
                </span>
              </RenderIf>
            ))}
          </div>
        </RenderIf> */}
      </div>

      <div
        className={`py-3 mt-1 ${
          !isSticky ? 'relative px-4' : 'sticky bottom-0 card-widget-gradient px-4'
        }`}>
        <button
          type="button"
          disabled={!formik.isValid && user?.realAccount || isSubmitting}
          onClick={handleConfirmation}
          
          className="bg-green-base-d hover:bg-opacity-75 disabled:bg-opacity-[30%] text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full mr-0 ml-auto flex items-center justify-center transition-all ease-in-out duration-100">
          {isSubmitting && <SimpleSpinner />}
          Jual {formik.values.lot ? automatedNumberFormat(formik.values.lot) + ' Lot' : ''} {formik.values.code} @{automatedNumberFormat(formik.values.price)}
          {
            mockOnly && (
              <>
                {formik.values.OrderType === 'LimitOrder' && ' LMT'}
                {formik.values.OrderType === 'MarketOrder' && ' MKT'}
                {formik.values.expiry === 'GTC' && ' GTC'}
                {formik.values.expiry === 'GTD' && ' GTD'}
              </>
            )
          }
        </button>

        <div className='mt-4'>
          <InputCheckBox
            id={`${uniqueId('confirmation-')}`}
            value={quickOrderConfirmation}
            name="confirmation"
            checked={quickOrderConfirmation}
            label={<span className='text-xs font-quicksand-regular text-black-base-l transform dark:text-black-light-0-d'>Confirm Order</span>}
            onChange={() => {
              dispatch(setQuickOrderSettingsConfirmation(!quickOrderConfirmation))
            }}
            onBlur={formik.handleBlur}
            className='mb-0'
          />
        </div>
      </div>

      <RenderIf isTrue={isNotRealAccount}>
        <Modal onClose={() => setIsNotRealAccount(false)}>
          <ModalExploreExpired
            showClose
            eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
            title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
            description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
            onClose={() => setIsNotRealAccount(false)}
          />
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openModalConfirmationSell}>
        <Modal onClose={() => setOpenModalConfirmationSell(false)}>
          <ModalConfirmationStockSell
            isSubmitting={isSubmitting}
            data={openModalConfirmationSell}
            onSubmit={() => formik.submitForm()}
            onCancel={() => setOpenModalConfirmationSell(false)}
          />
        </Modal>
      </RenderIf>

    </form>
  );
}

OrderFocusSellForm.propTypes = {
  user: PropTypes.object,
  // pickPrice: PropTypes.any,
  portfolio: PropTypes.object,
  stockDetail: PropTypes.object,
  isBursaOpen: PropTypes.bool,
  isSticky: PropTypes.bool,
  mockOnly: PropTypes.bool,
  onChangeStock: PropTypes.func,
  analyticEventName: PropTypes.string,
};

export default OrderFocusSellForm;
