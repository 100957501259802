import PropTypes from 'prop-types';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import OrderSettingsDesktop from './order-settings-desktop';
import OrderbookTable from './orderbook-table';
import InputCheckBox from 'components/input/input-check-box';
import { sumBy, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setFastOrderSettingsBySymbol } from 'store/settings';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_FAST_ORDER_SETTINGS } from 'constants/settings';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useToastr from 'hooks/useToastr';
import { invalidateAllOrder, useCreateOrderBuyMutation, useCreateOrderSellMutation, useCreateOrderWithdrawMutation, useCreateOrderWithdrawWithoutInvalidateTagsMutation, useGetOrderListQuery } from 'services/rtk-query/trade';
import { triggerSegmentEvent } from 'lib/segment';
import ModalConfirmationStockWithdraw from 'components/form/_partials/modal-confirmation-stock-withdraw';
import ModalConfirmationStockBuy from 'components/form/_partials/modal-confirmation-stock-buy';
import ModalConfirmationStockSell from 'components/form/_partials/modal-confirmation-stock-sell';
import { getOrderStatus } from 'utils/stock/order';
import StockWithdrawConfirmation from 'components/form/multi-order-form/multi-buy-form/modal-confirmation-withdraw';
import OrderSettingsMobile from './order-settings-mobile';
import { usePortfolio } from 'hooks/usePortfolio';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import RenderIf from 'components/basic/render-if';
import Modal from 'components/modal/modal';
import { generateOrderId } from 'utils/format';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function OrderForm({ symbol, onCancel = () => {}, onSuccess = () => {} }) {
  const toastr = useToastr();
  const dispatch = useDispatch();
  const { getErrorMessage } = useGetErrorMessage();

  const { user, dealerUseClientId } = useBrowserTabAuth();
  const fastOrderSettings = useSelector((state) => state?.settings?.fastOrderSettings?.[symbol]);
  const settings = fastOrderSettings ? {...fastOrderSettings, lots: ['Max Buy/Sell', ...(fastOrderSettings?.lots?.filter(item => item != 'Max Buy/Sell') || [])]} : DEFAULT_FAST_ORDER_SETTINGS;

  const { portfolioList } = usePortfolio();
  const { data: trading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { stockDetail } = useRealtimeStock(symbol, false);
  const { data: resOrderlist } = useGetOrderListQuery(null, { refetchOnMountOrArgChange: true });
  const orderlistBuy = (resOrderlist?.items || [])?.filter((item) => !['amend', 'match', 'reject', 'withdraw'].includes(getOrderStatus(item?.state)) && item?.side === 'B' && item?.stID === symbol);
  const orderlistSell = (resOrderlist?.items || [])?.filter((item) => !['amend', 'match', 'reject', 'withdraw'].includes(getOrderStatus(item?.state)) && item?.side === 'S' && item?.stID === symbol);

  const [createOrderBuy] = useCreateOrderBuyMutation();
  const [createOrderSell] = useCreateOrderSellMutation();
  const [createOrderWithdraw] = useCreateOrderWithdrawMutation();
  const [createOrderWithdrawWithoutInvalidateTags] = useCreateOrderWithdrawWithoutInvalidateTagsMutation();
  
  const [activePrice, setActivePrice] = useState(stockDetail?.lastTradedPrice);
  const [openConfirmationStockBuy, setOpenConfirmationStockBuy] = useState(false);
  const [openConfirmationStockSell, setOpenConfirmationStockSell] = useState(false);
  const [openConfirmationStockWithdraw, setOpenConfirmationStockWithdraw] = useState(false);
  const [openConfirmationStockWithdrawAll, setOpenConfirmationStockWithdrawAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearRGStockID = useMemo(() => {
    let zymbol = stockDetail?.id;
    let isTN = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'TN';
    let isNG = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'NG';

    if (isTN || isNG) {
      zymbol = zymbol?.slice(0, -2)
    }

    return zymbol;
  }, [stockDetail]);

  const clearBoardID = useMemo(() => {
    let boardID = 'RG';
    let zymbol = stockDetail?.id;
    
    let isTN = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'TN';
    let isNG = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'NG';

    if (isTN) boardID = 'TN';
    if (isNG) boardID = 'NG';

    return boardID;
  }, [stockDetail]);

  const handleBuyAction = async (payload, isConfirmation = true, ordersToWithdraw = [], resetForm = () => {}) => {
    let payloads = {
      clientID: user?.username,
      stockID: clearRGStockID,
      clOrdID: generateOrderId(dealerUseClientId || user?.username, 'B'),
      price: payload?.price,
      qty: payload?.lot,
      expiry: 'DAY',
      boardID: clearBoardID,
      split: '',
      OrderType: 'LimitOrder',
    };

    let tempPayload = {...payloads, split: '', OrderType: 'LimitOrder', code: symbol, lot: payload?.lot };

    if (!settings?.confirmation && isConfirmation) {
      return setOpenConfirmationStockBuy({ ordersToWithdraw, tempPayload, resetForm });
    }

    setIsSubmitting(true);

    try {
      if (ordersToWithdraw?.length > 0) {
        const promises = [];
        let _totalAmount = 0;
        ordersToWithdraw?.map(async (item) => {
          let payloads = {
            orderID: item?.id,
            clOrdID: item?.clOID
          };
  
          _totalAmount +=  Number(item?.price) * Number(item?.qty) * 100;
          promises.push(createOrderWithdrawWithoutInvalidateTags(payloads).unwrap());
        });
  
        await Promise.all(promises);

        triggerSegmentEvent({
          event: 'TR Withdraw Stock from Fast Order',
          properties: {
            total_amount: _totalAmount,
            stock_name: symbol,
          }
        });
      }
      
      const { success, msg, err } = await createOrderBuy(payloads).unwrap();

      if (!success) {
        let error = new Error();
        Object.assign(error, { msg, code: err });
        throw error;
      }

      triggerSegmentEvent({
        event: 'TR Buy Stock from Fast Order',
        properties: {
          stock_name: stockDetail?.id,
          total_amount: Number(payload?.lot) * Number(payload?.price) * 100,
          lot: payload?.lot,
        }
      });

      toastr.success('Order beli telah terkirim');
      setOpenConfirmationStockBuy(false);
      onSuccess();
    } catch (error) {
      getErrorMessage(error);
    } finally {
      dispatch(invalidateAllOrder());
      setIsSubmitting(false);
    }
  };

  const handleSellAction = async (payload, isConfirmation = true, ordersToWithdraw = [], resetForm = () => {}) => {
    let payloads = {
      clientID: user?.username,
      stockID: clearRGStockID,
      clOrdID: generateOrderId(dealerUseClientId || user?.username, 'S'),
      price: payload?.price,
      qty: payload?.lot,
      expiry: 'DAY',
      boardID: clearBoardID,
    };

    let tempPayload = {...payloads, split: '', OrderType: 'LimitOrder', code: symbol, lot: payload?.lot };

    if (!settings?.confirmation && isConfirmation) {
      return setOpenConfirmationStockSell({ ordersToWithdraw, tempPayload, resetForm });
    }

    setIsSubmitting(true);

    try {
      if (ordersToWithdraw?.length > 0) {
        const promises = [];
        let _totalAmount = 0;
        ordersToWithdraw?.map(async (item) => {
          let payloads = {
            orderID: item?.id,
            clOrdID: item?.clOID
          };
  
          _totalAmount +=  Number(item?.price) * Number(item?.qty) * 100;
          promises.push(createOrderWithdrawWithoutInvalidateTags(payloads).unwrap());
        });
  
        await Promise.all(promises);

        triggerSegmentEvent({
          event: 'TR Withdraw Stock from Fast Order',
          properties: {
            total_amount: _totalAmount,
            stock_name: symbol,
          }
        });
      }

      const { success, msg, err } = await createOrderSell(payloads).unwrap();

      if (!success) {
        let error = new Error();
        Object.assign(error, { msg, code: err });
        throw error;
      }

      triggerSegmentEvent({
        event: 'TR Sell Stock from Fast Order',
        properties: {
          stock_name: stockDetail?.id,
          total_amount: Number(payload?.lot) * Number(payload?.price) * 100,
          lot: payload?.lot,
        }
      });

      toastr.success('Order jual telah terkirim');
      setOpenConfirmationStockSell(false);
      onSuccess();
    } catch (error) {
      getErrorMessage(error);
    } finally {
      dispatch(invalidateAllOrder());
      setIsSubmitting(false);
    }
  };

  const handleEditAction = async (payload, resetForm = () => {}) => {
    const ordersToWithdraw = (resOrderlist?.items || [])?.filter((item) => !['amend', 'match', 'reject', 'withdraw'].includes(getOrderStatus(item?.state)) && item?.side === payload?.side && item?.price === payload?.price);
    
    if (payload?.side === 'B') {
      const findAllOrderByPrice = orderlistBuy?.filter(( o => o?.price === payload?.price));
      const sumPriceActiveOrder = (sumBy(findAllOrderByPrice, 'qty')) * payload?.price;

      if (payload?.lot * payload?.price * 100 > sumPriceActiveOrder + trading?.buyingpower) {
        resetForm();
        return toastr?.error('Trading limit tidak mencukupi');
      }

      handleBuyAction(payload, true, ordersToWithdraw, resetForm);
    }

    if (payload?.side === 'S') {
      const findAllOrderByPrice = orderlistSell?.filter(( o => o?.price === payload?.price));
      const sumLotActiveOrder = sumBy(findAllOrderByPrice, 'qty') / 100;
      const netPorto = portfolioList?.find( p => p?.stID === payload?.stID)?.net / 100;

      if (payload?.lot > netPorto + sumLotActiveOrder) {
        resetForm();
        return toastr?.error('Lot tidak mencukupi');
      }

      handleSellAction(payload, true, ordersToWithdraw, resetForm);
    }
  };

  const handleDragEndOrderIndexAction = async (payload, resetForm = () => {}) => {
    const sourceOrders = (resOrderlist?.items || [])?.filter((item) => !['amend', 'match', 'reject', 'withdraw'].includes(getOrderStatus(item?.state)) && item?.side === payload?.sourceOrder?.side && item?.price === payload?.sourceOrder?.price);
    const destinationOrders = (resOrderlist?.items || [])?.filter((item) => !['amend', 'match', 'reject', 'withdraw'].includes(getOrderStatus(item?.state)) && item?.side === payload?.destinationOrder?.side && item?.price === payload?.destinationOrder?.price);
    const ordersToWithdraw = [...sourceOrders, ...destinationOrders];
    const newOrder = {...sourceOrders?.[0], lot: payload?.lot, price: payload?.destinationOrder?.price };

    if (newOrder.side === 'B') {
      handleBuyAction(newOrder, true, ordersToWithdraw, resetForm);
    }

    if (newOrder.side === 'S') {
      handleSellAction(newOrder, true, ordersToWithdraw, resetForm);
    }
  }

  const handleWithdrawAction = async (order, isConfirmation = true) => {
    if (!settings?.confirmation && isConfirmation) {
      return setOpenConfirmationStockWithdraw({ order });
    }

    setIsSubmitting(true);

    try {
      const orders = (resOrderlist?.items || [])?.filter((item) => !['amend', 'match', 'reject', 'withdraw'].includes(getOrderStatus(item?.state)) && item?.side === order?.side && item?.price === order?.price);
      const promises = [];
      let _totalAmount = 0;

      orders?.map(async (item) => {
        let payloads = {
          orderID: item?.id,
          clOrdID: item?.clOID
        };

        _totalAmount +=  Number(item?.price) * Number(item?.qty) * 100;
        promises.push(createOrderWithdraw(payloads).unwrap());
      });

      await Promise.all(promises);

      triggerSegmentEvent({
        event: 'TR Withdraw Stock from Fast Order',
        properties: {
          total_amount: _totalAmount,
          stock_name: symbol,
        }
      });

      toastr.success('Order withdraw telah terkirim'); 
      setOpenConfirmationStockWithdraw(false);
      onSuccess();
    } catch (error) {
      getErrorMessage(error);
    } finally {
      dispatch(invalidateAllOrder());
      setIsSubmitting(false);
    }
  };

  const handleWithdrawAll = async (orders, isConfirmation = true) => {
    if (!settings?.confirmation && isConfirmation) {
      return setOpenConfirmationStockWithdrawAll({ orders });
    }

    setIsSubmitting(true);

    try {
      const promises = [];
      let _totalAmount = 0;

      orders?.map(async (item) => {
        let payloads = {
          orderID: item?.id,
          clOrdID: item?.clOID
        };

        _totalAmount +=  Number(item?.price) * Number(item?.qty) * 100;
        promises.push(createOrderWithdraw(payloads).unwrap());
      });

      await Promise.all(promises);

      triggerSegmentEvent({
        event: 'TR Withdraw All Buy Stock from Fast Order',
        properties: {
          total_amount: _totalAmount,
          stock_name: symbol,
        }
      });

      toastr.success('Order withdraw telah terkirim');
      setOpenConfirmationStockWithdrawAll(false);
      onSuccess();
    } catch (error) {
      getErrorMessage(error);
    } finally {
      dispatch(invalidateAllOrder());
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (!fastOrderSettings)
      dispatch(setFastOrderSettingsBySymbol({ symbol, DEFAULT_FAST_ORDER_SETTINGS }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fastOrderSettings, symbol]);

  useEffect(() => {
    setActivePrice(stockDetail?.lastTradedPrice);
  }, [stockDetail]);

  return (
    <>
      <form autoComplete="off" noValidate>
        <div className="lg:hidden">
          <OrderSettingsMobile
            symbol={symbol}
            price={activePrice}
            settings={settings}
            onClickMaxBuy={(lot) => handleBuyAction({ lot, price: activePrice })}
            onClickMaxSell={(lot) => handleSellAction({ lot, price: activePrice })}
          />
        </div>
        <div className="flex justify-between w-full relative overflow-auto mb-2 lg:mb-0">
          <div className="flex-auto">
            <OrderbookTable
              initPrice={activePrice}
              symbol={symbol}
              settings={settings}
              orderlistBuy={orderlistBuy}
              orderlistSell={orderlistSell}
              onBuyAction={handleBuyAction}
              onSellAction={handleSellAction}
              onDragEndOrderIndexAction={handleDragEndOrderIndexAction}
              onEditAction={handleEditAction}
              onWithdrawAction={handleWithdrawAction}
              onClickPrice={setActivePrice}
              onWithdrawAllBuyAction={() => handleWithdrawAll(orderlistBuy)}
              onWithdrawAllSellAction={() => handleWithdrawAll(orderlistSell)}
            />
          </div>

          <div className="hidden lg:block">
            <OrderSettingsDesktop
              symbol={symbol}
              price={activePrice}
              settings={settings}
              onClickMaxBuy={(lot) => handleBuyAction({ lot, price: activePrice })}
              onClickMaxSell={(lot) => handleSellAction({ lot, price: activePrice })}
            />
          </div>
        </div>

        <div className="hidden lg:flex justify-between w-full relative overflow-auto">
          <div className="flex text-2xs text-gray-light-0-d gap-2 mt-1 py-3 border-t dark:border-gray-base-d w-full">
            <div className='flex gap-2 flex-auto pr-4'>
              <div className="flex-auto">
                <button
                  type="button"
                  title='Withdraw semua order Beli'
                  disabled={orderlistBuy?.length === 0 || isSubmitting}
                  onClick={() => handleWithdrawAll(orderlistBuy)}
                  className="p-2 py-1 bg-orange-2 rounded-md outline-none text-white dark:text-black-base-d font-quicksand-medium text-xs w-full transition-all ease-linear duration-100 hover:bg-opacity-75 disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d disabled:bg-opacity-100">
                  W
                </button>
              </div>

              <div className="flex justify-center">
                <div className="w-[72px] mt-[2px]"></div>
                <div className="px-2 w-[72px]"></div>
                <div className="w-[72px] mt-[2px]"></div>
              </div>

              <div className="flex-auto">
                <button
                  type="button"
                  title='Withdraw semua order Jual'
                  disabled={orderlistSell?.length === 0 || isSubmitting}
                  onClick={() => handleWithdrawAll(orderlistSell)}
                  className="p-2 py-1 bg-orange-2 rounded-md outline-none text-white dark:text-black-base-d font-quicksand-medium text-xs w-full transition-all ease-linear duration-100 hover:bg-opacity-75 disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d disabled:bg-opacity-100">
                  W
                </button>
              </div>
            </div>

            <div className="flex-none w-[82px] sticky right-0 card-widget-gradient"></div>
          </div>
        </div>

        <InputCheckBox
          id={`${uniqueId('confirmation-')}`}
          value={settings?.confirmation}
          name="confirmation"
          checked={settings?.confirmation}
          label={
            <span className="text-xs font-quicksand-regular text-black-base-l dark:text-black-light-0-d transform translate-y-[2px]">
              Confirm Order
            </span>
          }
          onChange={() =>
            dispatch(
              setFastOrderSettingsBySymbol({
                symbol,
                settings: { ...settings, confirmation: !settings?.confirmation }
              })
            )
          }
          className="mb-0"
        />
      </form>

      {/* MODAL CONFIRMATION BUY */}
      <RenderIf isTrue={openConfirmationStockBuy}>
        <Modal
          onClose={() => {
            openConfirmationStockBuy?.resetForm();
            setOpenConfirmationStockBuy(false);
          }}
        >
          <ModalConfirmationStockBuy
            isSubmitting={isSubmitting}
            onSubmit={() => handleBuyAction(openConfirmationStockBuy?.tempPayload, false, openConfirmationStockBuy?.ordersToWithdraw)}
            onCancel={() => {
              openConfirmationStockBuy?.resetForm();
              setOpenConfirmationStockBuy(false);
            }}
            data={openConfirmationStockBuy?.tempPayload}
          />
        </Modal>
      </RenderIf>

      {/* MODAL CONFIRMATION SELL */}
      <RenderIf isTrue={openConfirmationStockSell}>
        <Modal
          onClose={() => {
            openConfirmationStockSell?.resetForm();
            setOpenConfirmationStockSell(false);
          }}
        >
          <ModalConfirmationStockSell
            isSubmitting={isSubmitting}
            onSubmit={() => handleSellAction(openConfirmationStockSell?.tempPayload, false, openConfirmationStockSell?.ordersToWithdraw)}
            onCancel={() => {
              openConfirmationStockSell?.resetForm();
              setOpenConfirmationStockSell(false);
            }}
            data={openConfirmationStockSell?.tempPayload}
          />
        </Modal>
      </RenderIf>

      {/* MODAL CONFIRMATION WITHDRAW SINGLE */}
      <RenderIf isTrue={openConfirmationStockWithdraw}>
        <Modal onClose={() => setOpenConfirmationStockWithdraw(false)}>
          <ModalConfirmationStockWithdraw
            isSubmitting={isSubmitting}
            onSubmit={() => handleWithdrawAction(openConfirmationStockWithdraw?.order, false)}
            onCancel={() => setOpenConfirmationStockWithdraw(false)}
            data={{
              orderNumber: openConfirmationStockWithdraw?.order?.clOID,
              code: openConfirmationStockWithdraw?.order?.stID,
              lot: openConfirmationStockWithdraw?.order?.qty / 100 || 0,
              price: openConfirmationStockWithdraw?.order?.price || 0,
              id: openConfirmationStockWithdraw?.order?.id
            }}
          />
        </Modal>
      </RenderIf>

      {/* MODAL CONFIRMATION WITHDRAW MULTIPLE */}
      <RenderIf isTrue={openConfirmationStockWithdrawAll}>
        <Modal onClose={() => setOpenConfirmationStockWithdrawAll(false)}>
          <StockWithdrawConfirmation
            symbol={symbol}
            isOpen={true}
            isMultiple={true}
            isSubmitting={isSubmitting}
            onSubmit={() => handleWithdrawAll(openConfirmationStockWithdrawAll?.orders, false)}
            onClose={() => setOpenConfirmationStockWithdrawAll(false)}
            data={openConfirmationStockWithdrawAll?.orders}
          />
        </Modal>
      </RenderIf>
    </>
  );
}

OrderForm.propTypes = {
  symbol: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default OrderForm;
