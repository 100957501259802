import Breadcrumb from 'components/basic/breadcrumb';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import { ListMenuAccountSettings } from 'constants/list-profile-menu';

import { useNavigate, useParams } from 'react-router-dom';
import SectionDataPribadi from './_partials/data-pribadi.section';
import SectionHapusAkun from './_partials/hapus-akun.section';
import SectionKebijakanPrivasi from './_partials/kebijakan-privasi.section';
import SectionSyaratDanKetentuan from './_partials/syarat-dan-ketentuan.section';

function PageAccountSettings() {
  const navigate = useNavigate();
  const { slug } = useParams();

  return (
    <div>
      <div className="p-4">
        <Breadcrumb
          data={[
            {
              link: '/profile',
              title: 'Profile',
              icon: null
            },
            {
              link: '#',
              title: 'Pengaturan Akun',
              icon: null
            }
          ]}
        />

        <div className="grid grid-cols-12 gap-4 mt-4">
          <div className="col-span-12 lg:col-span-4">
            <Card className="p-5 pb-3">
              <h5>Pengaturan Akun</h5>
              <div className="-mx-6">
                <ul>
                  {ListMenuAccountSettings.map((item, key) => (
                    <li
                      onClick={() => navigate(item.url, { replace: true })}
                      key={key}
                      className={`${
                        slug === item.slug && 'text-main-base-l'
                      } cursor-pointer px-6 font-quicksand-semibold text-sm hover:text-main-base-l transition-all ease-in-out duration-100`}>
                      <div className={`py-4 ${key > 0 && 'border-t'}`}>
                        {item?.text}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Card>
          </div>

          <div className="col-span-12 lg:col-span-8">
            <RenderIf isTrue={slug === 'data-pribadi'}>
              <SectionDataPribadi />
            </RenderIf>

            <RenderIf isTrue={slug === 'syarat-dan-ketentuan'}>
              <SectionSyaratDanKetentuan />
            </RenderIf>

            <RenderIf isTrue={slug === 'kebijakan-privasi'}>
              <SectionKebijakanPrivasi />
            </RenderIf>

            <RenderIf isTrue={slug === 'hapus-akun'}>
              <SectionHapusAkun />
            </RenderIf>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageAccountSettings;
