import PropTypes from 'prop-types';
function Pineaple({ className }) {
  return (
    <svg
      width="48"
      height="98"
      viewBox="0 0 48 98"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9995 61.2632V37.2617L36.001 49.2614L23.9995 61.2632Z"
        fill="#FCB813"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000244141 61.2632V37.2617L12.0017 49.2614L0.000244141 61.2632Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9996 37.2617V61.2632L36.0002 49.2625L47.9996 37.2617Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0006 37.2617V61.2632L12.0012 49.2625L24.0006 37.2617Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9996 61.2656V85.266L36.0002 73.2663L47.9996 61.2656Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0006 61.2656V85.266L12.0012 73.2663L24.0006 61.2656Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0007 73.2692V49.2656L48.0001 61.2664L36.0007 73.2692Z"
        fill="#FCB813"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0015 73.2692V49.2656L24.0008 61.2664L12.0015 73.2692Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0005 49.2656V73.2692L23.999 61.2674L36.0005 49.2656Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0015 49.2656V73.2692L0 61.2674L12.0015 49.2656Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9995 85.2682V61.2656L36.001 73.2653L23.9995 85.2682Z"
        fill="#FCB813"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000244141 85.2682V61.2656L12.0017 73.2653L0.000244141 85.2682Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0007 97.2682V73.2656L48.0001 85.2653L36.0007 97.2682Z"
        fill="#FCB813"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0015 97.2682V73.2656L24.0008 85.2653L12.0015 97.2682Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0005 73.2656V97.2681L23.999 85.2685L36.0005 73.2656Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0015 73.2656V97.2681L0 85.2685L12.0015 73.2656Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0017 97.2685H12.002L24.0013 85.2656L36.0017 97.2685Z"
        fill="#FCB813"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9995 13.2656V37.2681L12.0012 25.2685L23.9995 13.2656Z"
        fill="#76B0B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0017 37.2682V13.2656L36.0021 25.2653L24.0017 37.2682Z"
        fill="#5F9DA9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9994 1.26562V25.2681L23.999 13.2685L35.9994 1.26562Z"
        fill="#76B0B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0007 25.2682V1.26562L48.0001 13.2674L36.0007 25.2682Z"
        fill="#5F9DA9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9994 1.26562V25.2681L0 13.2685L11.9994 1.26562Z"
        fill="#76B0B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0015 25.2682V1.26562L23.9997 13.2674L12.0015 25.2682Z"
        fill="#76B0B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0007 49.2621V25.2617L48.0001 37.2614L36.0007 49.2621Z"
        fill="#FCB813"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0015 49.2621V25.2617L24.0008 37.2614L12.0015 49.2621Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0005 25.2617V49.2632L23.999 37.2624L36.0005 25.2617Z"
        fill="#FFDC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0015 25.2617V49.2632L0 37.2624L12.0015 25.2617Z"
        fill="#FFDC00"
      />
    </svg>
  );
}

Pineaple.propTypes = {
  className: PropTypes.string
};

export default Pineaple;
