import PropTypes from 'prop-types';

function IconTriangle({ className }) {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8L1.00921 8C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z" />
    </svg>
  );
}

IconTriangle.propTypes = {
  className: PropTypes.string
};

export default IconTriangle;
