import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import InputText from 'components/input/input-text';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { findIndex, sumBy, uniqueId } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import 'components/input/css/custom/customize-input-text.css';
import IconGrab from 'components/svg/icon-grab';
import IconTrashCan from 'components/svg/icon-trash-can';
import { ModalContext } from 'components/modal/context/modalContext';
import SimpleSpinner from 'components/common/spinner/spinner';
import RenderIf from 'components/basic/render-if';
import SkeletonTheme from 'components/basic/skeleton-theme';

function InputFastOrder({
  symbol,
  listOrderbook = [],
  activeOrders = [],
  settings = { lots: [], activeLotIndex: 0 },
  onOrderAction = () => {},
  onWithdrawAction = () => {},
  onDragEndOrderIndexAction = () => {},
  onEditAction = () => {},
  onClickPrice = () => {},
  checkMaxValue = () => {},
  onErrorAction = () => {},
  type = 'buy',
  initPrice,
}) {
  const { modalLoading } = useContext(ModalContext);
  const { stockDetail } = useRealtimeStock(symbol, false);
  const [activeInput, setActiveInput] = useState(null);
  const [items, setItems] = useState([]);
  const [tempItems, setTempItems] = useState([]);
  const [isDoubleClick, setIsDoubleClick] = useState(false);
  const [isHitEnter, setIsHitEnter] = useState(false);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    let destinationIndex = result?.destination?.index;
    let sourceIndex = result?.source?.index;

    if (result?.combine) {
      destinationIndex = findIndex(items, (item) => item?.id === result?.combine?.draggableId);
    }

    if (!destinationIndex && destinationIndex != 0 || !activeOrders?.[sourceIndex]) return;

    const newItems = reorder(
      items,
      sourceIndex,
      destinationIndex
    );

    if (activeOrders?.[sourceIndex]?.[0] && !activeOrders?.[destinationIndex]?.[0]) {
      let findOrderSource = activeOrders?.[sourceIndex]?.[0];
      let sumOrderQty = sumBy(activeOrders?.[sourceIndex]?.filter(or => Number(or?.price) === Number(findOrderSource?.price)), 'qty') / 100;

      const payload = { sourceOrder: findOrderSource, destinationOrder: {...findOrderSource, price: listOrderbook?.[destinationIndex]?.price }, lot: sumOrderQty };
      onDragEndOrderIndexAction(payload, () => setItems(items));
    }

    if (activeOrders?.[sourceIndex]?.[0] && activeOrders?.[destinationIndex]?.[0]) {
      let findOrderSource = activeOrders?.[sourceIndex]?.[0];
      let findOrderDestination = activeOrders?.[destinationIndex]?.[0];

      let sumSourceOrderQty = sumBy(activeOrders?.[sourceIndex]?.filter(or => Number(or?.price) === Number(findOrderSource?.price)), 'qty') / 100;
      let sumDestinationOrderQty = sumBy(activeOrders?.[destinationIndex]?.filter(or => Number(or?.price) === Number(findOrderDestination?.price)), 'qty') / 100;
      let newLot = sumSourceOrderQty + sumDestinationOrderQty;

      const payload = { sourceOrder: findOrderSource, destinationOrder: findOrderDestination, lot: newLot };
      onDragEndOrderIndexAction(payload, () => setItems(items));
    }

    setTempItems(items);
    setItems(newItems);
  };

  useEffect(() => {
    if (listOrderbook?.length > 0 && stockDetail) {
      let inputs = [];
      listOrderbook?.map((ob) => {
        const filterActiveOrder = activeOrders?.filter(ao => Number(ao?.[0]?.price) === Number(ob?.price));
        inputs.push({ id: type + uniqueId(), value: sumBy(filterActiveOrder?.[0], 'qty') / 100 });
      });
      setItems(inputs);
      setTempItems(inputs);
    }
  }, [listOrderbook, stockDetail, type, activeOrders]);

  useEffect(() => {
    setTimeout(() => {
      const elsDropItem = document.querySelectorAll('.fast-order-input-container');
      elsDropItem?.forEach(el => {
        el?.setAttribute('tabindex', -1);
      });
    }, 1000);
  }, [listOrderbook, activeOrders]);

  return (
    <div>
      <RenderIf isTrue={listOrderbook?.length === 0}>
        {Array(20)
        .fill(1)
        .map((el, key) => (
          <div key={key} className="my-1">
            <SkeletonTheme containerClassName="leading-none" width="100%" height="1.2rem" borderRadius="4px" />
          </div>
        ))}  
      </RenderIf>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" isCombineEnabled  direction='vertical'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div
                      className='fast-order-input-container'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <div className="flex items-center relative">
                        {type === 'buy' && (
                          <button tabIndex={-1} type='button' title="Geser" className='outline-none'>
                            <IconGrab className="h-3 w-auto flex-none mr-1 text-main-base-l cursor-grab" />
                          </button>
                        )}

                        {type === 'sell' && activeOrders?.[index]?.[0] && (
                          <button
                            type="button"
                            title='Withdraw'
                            onClick={() => {
                              onWithdrawAction(activeOrders?.[index]?.[0]);
                              setActiveInput(index);
                            }}
                            disabled={!activeOrders?.[index]?.[0] || modalLoading}
                            className="outline-none hover:opacity-75 disabled:opacity-30 transition-all ease-linear duration-100">
                            <IconTrashCan className="h-4 w-4 flex-none mr-1 text-main-base-l" />
                          </button>
                        )}

                        {modalLoading && activeInput === index && (
                          <button
                            type="button"
                            className="outline-none absolute left-1/2 transform -translate-x-1/2 bg-gray-light-l dark:bg-black-base-d rounded-full z-10">
                            <SimpleSpinner className="h-4 w-4 text-main-base-l dark:text-black-light-1-d flex-none" />
                          </button>
                        )}

                        <div
                          className="hover:opacity-75 flex-auto"
                          title={type === 'buy' ? 'Klik 2x untuk beli' : 'Klik 2x untuk jual'}
                          onClick={() => {
                            setActiveInput(index);
                            onClickPrice(listOrderbook?.[index]?.price);
                          }}
                          onDoubleClick={() => {
                            let newVal = Number(settings?.lots?.[settings?.activeLotIndex]);
                            let maxValBuy = checkMaxValue(listOrderbook?.[index]?.price);
                            let maxValSell = checkMaxValue(newVal);

                            if (isNaN(newVal) && type === 'buy') newVal = maxValBuy;
                            if (isNaN(newVal) && type === 'sell') newVal = maxValSell;

                            setIsDoubleClick(true);
                            
                            if (newVal === 0 || ((maxValBuy < newVal) && type === 'buy') || ((maxValSell < newVal) && type === 'sell')) {
                              setActiveInput(null);
                              onErrorAction();
                              return false;
                            }

                            let payload = { price: listOrderbook?.[index]?.price, lot: newVal };

                            onOrderAction(payload, true, [], () => setItems(items));
                            onClickPrice(listOrderbook?.[index]?.price);
                            setItems(items?.map((kVal, kInput) => {
                              if (kInput === index) return { ...kVal, value: Number(item?.value) + Number(newVal) };
                                return kVal;
                              })
                            );
                          }}>
                          <InputText
                            autoSelect
                            readOnly={activeInput !== index}
                            value={item?.value}
                            disabled={activeInput === index && modalLoading}
                            name={`${type}${index}`}
                            className={`customize-input-text--fast-order ${initPrice === listOrderbook?.[index]?.price && 'focus'} ${(index === activeInput || item?.value) && 'active'} py-[1px]`}
                            onBeforeInput={() => setIsHitEnter(false)}
                            onKeyDown={(e) => {
                              if (e?.key === 'Enter') {
                                setIsHitEnter(true);
                                if (isDoubleClick) {
                                  setIsDoubleClick(false);
                                  return false;
                                }
  
                                if (activeOrders?.[index]?.[0] && Number(e.target.value || 0) === 0) {
                                  onWithdrawAction(activeOrders?.[index]?.[0]);
                                } else {
                                  if (Number(e.target.value || 0) === 0 || item?.value === tempItems?.[index]?.value) {
                                    setActiveInput(null);
                                    return false;
                                  }
                                  
                                  if (activeOrders?.[index]?.[0] && Number(e.target.value || 0) && item?.value) {
                                    const payload = {...activeOrders?.[index]?.[0] || [], price: listOrderbook?.[index]?.price, lot: Number(e.target.value || 0) };
                                    onEditAction(payload, () => setItems(tempItems));
                                  } else {
                                    let newVal = Number(e.target.value);
                                    let maxValBuy = checkMaxValue(listOrderbook?.[index]?.price);
                                    let maxValSell = checkMaxValue(newVal);
        
                                    if (newVal === 0 || ((maxValBuy < newVal) && type === 'buy') || ((maxValSell < newVal) && type === 'sell')) {
                                      setActiveInput(null);
                                      onErrorAction();
                                      setItems(tempItems);
                                      return false;
                                    }
  
                                    onOrderAction({ price: listOrderbook?.[index]?.price, lot: Number(e.target.value) }, true, [], () => setItems(tempItems));
                                  }
                                }
                                setActiveInput(null);

                                return false;
                              }
                              if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Enter', 'Tab'].includes(e?.key)) e.preventDefault();
                            }}
                            onBlur={(e) => {
                              if (isDoubleClick || isHitEnter) {
                                setIsDoubleClick(false);
                                return false;
                              }

                              if (activeOrders?.[index]?.[0] && Number(e.target.value || 0) === 0) {
                                onWithdrawAction(activeOrders?.[index]?.[0]);
                              } else {
                                if (Number(e.target.value || 0) === 0 || item?.value === tempItems?.[index]?.value) {
                                  setActiveInput(null);
                                  return false;
                                }
                                
                                if (activeOrders?.[index]?.[0] && Number(e.target.value || 0) && item?.value) {
                                  const payload = {...activeOrders?.[index]?.[0] || [], price: listOrderbook?.[index]?.price, lot: Number(e.target.value || 0) };
                                  onEditAction(payload, () => setItems(tempItems));
                                } else {
                                  let newVal = Number(e.target.value);
                                  let maxValBuy = checkMaxValue(listOrderbook?.[index]?.price);
                                  let maxValSell = checkMaxValue(newVal);
      
                                  if (newVal === 0 || ((maxValBuy < newVal) && type === 'buy') || ((maxValSell < newVal) && type === 'sell')) {
                                    setActiveInput(null);
                                    onErrorAction();
                                    setItems(tempItems);
                                    return false;
                                  }

                                  onOrderAction({ price: listOrderbook?.[index]?.price, lot: Number(e.target.value) }, true, [], () => setItems(tempItems));
                                }
                              }
                              setActiveInput(null);
                            }}
                            onChange={(e) => {
                              let newVal = Number(e.target.value);

                              setTempItems(items);
                              setIsDoubleClick(false);
                              setItems(
                                items?.map((kVal, kInput) => {
                                  if (kInput === index)
                                    return {
                                      ...kVal,
                                      value: newVal
                                    };
                                  return kVal;
                                })
                              );
                            }}
                            onFocus={() => {
                              setActiveInput(index);
                              onClickPrice(listOrderbook?.[index]?.price);
                            }}
                          />
                        </div>

                        {type === 'sell' && (
                          <button tabIndex={-1} type='button' title="Geser" className='outline-none'>
                            <IconGrab className="h-3 w-auto flex-none ml-1 text-main-base-l cursor-grab" />
                          </button>
                        )}

                        {type === 'buy' && activeOrders?.[index]?.[0] && (
                          <button
                            type="button"
                            title='Withdraw'
                            onClick={() => {
                              onWithdrawAction(activeOrders?.[index]?.[0]);
                              setActiveInput(index);
                            }}
                            disabled={!activeOrders?.[index]?.[0] || modalLoading}
                            className="outline-none hover:opacity-75 disabled:opacity-30 transition-all ease-linear duration-100">
                            <IconTrashCan className="h-4 w-4 flex-none ml-1 text-main-base-l" />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

InputFastOrder.propTypes = {
  symbol: PropTypes.string,
  initPrice: PropTypes.number,
  listOrderbook: PropTypes.array,
  activeOrders: PropTypes.array,
  settings: PropTypes.object,
  onOrderAction: PropTypes.func,
  onWithdrawAction: PropTypes.func,
  onDragEndOrderIndexAction: PropTypes.func,
  onEditAction: PropTypes.func,
  onClickPrice: PropTypes.func,
  checkMaxValue: PropTypes.func,
  onErrorAction: PropTypes.func,
  type: PropTypes.string
};

export default InputFastOrder;
