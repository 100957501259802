// import { marginPlusShare } from 'utils/formula/margin';
import { ORDER_COST } from 'constants';
import { getStampCostBuy } from 'utils/calculation';
import { object, number, string, date, bool } from 'yup';

export const SmartOrderFormValidation = object().shape({
  buyingPower: number()
    .test(
      'buyingPower',
      'Maaf, trading limit anda tidak mencukupi.',
      function (value, { parent }) {
        if (parent.BotName === 'SmartBuy') {
          return (
            value >=
            parent?.Lot * 100 * parent?.Price +
              Math.ceil(ORDER_COST?.buy * parent?.Price * parent?.Lot) +
              getStampCostBuy(value)
          );
        }

        return true;
      }
    )
    .required('Required'),
  amount: number()
    .test('amount', 'Jumlah amount tidak cukup', function (value, { parent }) {
      if (!parent.lotType) {
        return Math.floor(value / (parent.Price * 100)) > 0;
      }

      return true;
    })
    .required('Jumlah amount harus diisi'),
  Lot: number()
    .test(
      'Lot',
      'Jumlah Lot yang dimasukan tidak sesuai',
      function (value, { parent }) {
        if (['SmartSell', 'TakeProfit', 'StopLoss'].includes(parent.BotName)) {
          return parent?.Lot <= parent?.portfolio;
        }

        return true;
      }
    )
    .test('Lot', 'Jumlah Lot tidak boleh kosong', function (value) {
      return value > 0;
    })
    .required('Required'),
  portfolio: number()
    .test(
      'portfolio',
      'Maaf, anda belum memiliki Lot di saham ini',
      function (value, { parent }) {
        if (parent.BotName === 'SmartSell') {
          return value > 0;
        }

        return true;
      }
    )
    .required('Required'),
  BotName: string().required('Mode tidak boleh kosong'),
  StockId: string()
    .test('StockId', 'Stock tidak boleh kosong', (value) => value?.length > 0)
    .required('Stock tidak boleh kosong'),
  BotExpire: date().required('Periode Berakhir tidak boleh kosong'),
  agreement: bool().oneOf(
    [true],
    'Kamu harus menyetujui syarat dan ketentuan order ini.'
  ),
  PriceRule: number()
    .min(1, 'Syarat harga tidak boleh kosong')
    .required('Syarat Harga tidak boleh kosong'),
  Rule: string().required('Syarat tidak boleh kosong'),
  Price: number()
    .min(1, 'Harga tidak boleh kosong')
    .required('Harga tidak boleh kosong')
});
