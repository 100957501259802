import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import useBrowserTabAuth from 'hooks/useBrowserTabAuth'


const OnboardingGuard = ({ children }) => {
  const { isLogin } = useBrowserTabAuth()
  const { state } = useLocation();

  if (!isLogin) {
    return <Navigate to="/login" state={state} replace />;
  }

  return children;
}

OnboardingGuard.propTypes = {
  children: PropTypes.node,
};

export default OnboardingGuard;
