import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import dayjs from 'dayjs';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetClientCashQuery } from 'services/rtk-query/trade';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import { automatedNumberFormat } from 'utils/format';
import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function TWInfoCash({ title = 'Client Cash', isCardLess = false, className = "" }) {
  const { user } = useBrowserTabAuth();

  const { data: trading, isLoading: loadingBuyingPower } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { data, isLoading: loadingClientCash } = useGetClientCashQuery(user?.username);

  const [showDetail, setShowDetail] = useState(false);

  return (
    <div
      className={`${!isCardLess && 'bg-white rounded-xl dark:bg-black-base-d'} p-4 dark:text-black-light-1-d ${className}`}>
      {title && <h5 className="mb-2 font-quicksand-semibold text-sm">{title}</h5>}
      <div className="grid grid-cols-2 gap-3">
        <div className="col-span-1">
          <div className="flex text-xs font-quicksand-semibold mb-2">
            <span className="font-quicksand-regular dark:text-black-light-0-d flex-none">Cash</span>
            <span className="text-right flex-auto">
              <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
              </RenderIf>
              <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash && trading?.isTopup}>
                <Link to="/profile/deposit" className='hover:text-main-base-l'>{automatedNumberFormat(trading?.cashAvailable || 0)}</Link>
              </RenderIf>

              <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash && !trading?.isTopup}>
                <Link to="/profile/deposit" className='text-main-base-l'>Top-up RDN</Link>
              </RenderIf>
            </span>
          </div>

          <div className="flex text-xs font-quicksand-semibold mb-2">
            <span className="flex-none font-quicksand-regular dark:text-black-light-0-d">Buy</span>
            <span className="flex-auto text-right">
              <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
              </RenderIf>
              <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                {automatedNumberFormat(data?.arr?.[0]?.buy || 0)}
              </RenderIf>
            </span>
          </div>
        </div>
        

        <div className="col-span-1">
          <div className="flex text-xs font-quicksand-semibold mb-2">
            <span className="flex-none font-quicksand-regular dark:text-black-light-0-d">NetCash</span>
            <span className="flex-auto text-right">
              <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
              </RenderIf>
              <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                {automatedNumberFormat(data?.arr?.[0]?.netCash || 0)}
              </RenderIf>
            </span>
          </div>

          <div className="flex text-xs font-quicksand-semibold mb-2">
            <span className="flex-none font-quicksand-regular dark:text-black-light-0-d">Sell</span>
            <span className="flex-auto text-right">
              <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
              </RenderIf>
              <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                {automatedNumberFormat(data?.arr?.[0]?.sell || 0)}
              </RenderIf>
            </span>
          </div>
        </div>

        <div className="col-span-2">
          <Accordion>
            <AccordionItem
              expandable={false}
              expanded={showDetail}
              onToggle={() => setShowDetail(!showDetail)}
              >
                <AccordionItem.Slot name="Header">
                  <div className='flex justify-between mb-3'>
                    <span className='text-main-base-l text-xs font-quicksand-semibold'>Lihat Detail</span>
                    <IconChevronSingle className={`h-4 w-4 transform text-main-base-l ${showDetail ? 'rotate-90' : '-rotate-90'}`} />
                  </div>
                </AccordionItem.Slot>

                <AccordionItem.Slot name="Content">
                  <>
                    <div className="grid grid-cols-2 gap-4 text-xs font-quicksand-semibold mb-2">
                      <span className="font-quicksand-regular dark:text-black-light-0-d">Bid</span>
                      <span className="text-right">
                        <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                          <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                        </RenderIf>
                        <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                          {automatedNumberFormat(data?.arr?.[0]?.bid || 0)}
                        </RenderIf>
                      </span>
                    </div>

                    <div className="grid grid-cols-2 gap-4 text-xs font-quicksand-semibold mb-2">
                      <span className="font-quicksand-regular dark:text-black-light-0-d">Offer</span>
                      <span className="text-right">
                        <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                          <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                        </RenderIf>
                        <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                          {automatedNumberFormat(data?.arr?.[0]?.offer || 0)}
                        </RenderIf>
                      </span>
                    </div>

                    <div className="grid grid-cols-2 gap-4 text-xs font-quicksand-semibold mb-2">
                      <span className="font-quicksand-regular dark:text-black-light-0-d">Stock Value</span>
                      <span className="text-right">
                        <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                          <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                        </RenderIf>
                        <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                          {automatedNumberFormat(data?.arr?.[0]?.stockValue || 0, {
                            removeSymbol: true
                          })}
                        </RenderIf>
                      </span>
                    </div>

                    <div className="grid grid-cols-2 gap-4 text-xs font-quicksand-semibold mb-2">
                      <span className="font-quicksand-regular dark:text-black-light-0-d">LqValue</span>
                      <span className="text-right">
                        <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                          <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                        </RenderIf>
                        <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                          {automatedNumberFormat(data?.arr?.[0]?.lqValue || 0)}
                        </RenderIf>
                      </span>
                    </div>

                    <div className="grid grid-cols-2 gap-4 text-xs font-quicksand-semibold mb-2">
                      <span className="font-quicksand-regular dark:text-black-light-0-d">Limit</span>
                      <span className="text-right">
                        <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                          <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                        </RenderIf>
                        <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                          {automatedNumberFormat(trading?.buyingpower || 0, {
                            removeSymbol: true
                          })}
                        </RenderIf>
                      </span>
                    </div>
                  </>
                </AccordionItem.Slot>
            </AccordionItem>
          </Accordion>
        </div>
      </div>

      <div className="overflow-x-auto flex flex-nowrap -mx-4">
        <table className="text-2xs w-full text-center dark:text-black-light-1-d">
          <thead className='dark:text-black-light-0-d'>
            <tr>
              <td className="border-y border-gray-light-l dark:border-gray-base-d p-3">Label</td>
              <td className="border-y border-gray-light-l dark:border-gray-base-d p-3">
                T+0 <br /> <span>{dayjs().format('DD MMM YY')}</span>
              </td>
              <td className="border-y border-gray-light-l dark:border-gray-base-d p-3">
                T+1 <br />{' '}
                <span>
                  {/*  Friday */}
                  <RenderIf isTrue={dayjs().day() >= 5}>
                    {dayjs().add(3, 'day').format('DD MMM YY')}
                  </RenderIf>

                  <RenderIf isTrue={dayjs().day() > 0 && dayjs().day() < 5}>
                    {dayjs().add(1, 'day').format('DD MMM YY')}
                  </RenderIf>
                </span>
              </td>
              <td className="border-y border-gray-light-l dark:border-gray-base-d p-3">
                T+2 <br />{' '}
                <span>
                  {/*  Thursday */}
                  <RenderIf isTrue={dayjs().day() >= 4}>
                    {dayjs().add(4, 'day').format('DD MMM YY')}
                  </RenderIf>

                  <RenderIf isTrue={dayjs().day() > 0 && dayjs().day() < 4}>
                    {dayjs().add(2, 'day').format('DD MMM YY')}
                  </RenderIf>
                </span>
              </td>
            </tr>
          </thead>
          <tbody className='font-quicksand-semibold'>
            <tr>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">AP</td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.apToday || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.apPlus1 || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.apPlus2 || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">AR</td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.arToday || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.arPlus1 || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.arPlus2 || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
            </tr>
            <tr>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">DUE</td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(
                    data?.arr?.[0]?.apToday - data?.arr?.[0]?.arToday || 0,
                
                  )}
                </RenderIf>
              </td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(
                    data?.arr?.[0]?.apPlus1 - data?.arr?.[0]?.arPlus1 || 0,
                
                  )}
                </RenderIf>
              </td>
              <td className="border-b border-gray-light-l dark:border-gray-base-d p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(
                    data?.arr?.[0]?.apPlus2 - data?.arr?.[0]?.arPlus2 || 0,
                
                  )}
                </RenderIf>
              </td>
            </tr>
            <tr>
              <td className="p-2">Balance</td>
              <td className="p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.balanceToday || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
              <td className="p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.balanceTPlus1 || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
              <td className="p-2">
                <RenderIf isTrue={loadingBuyingPower || loadingClientCash}>
                  <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                </RenderIf>
                <RenderIf isTrue={!loadingBuyingPower && !loadingClientCash}>
                  {automatedNumberFormat(data?.arr?.[0]?.balanceTPlus2 || 0, {
                    removeSymbol: true
                  })}
                </RenderIf>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

TWInfoCash.propTypes = {
  isCardLess: PropTypes.bool,
  title: PropTypes.any,
  className: PropTypes.string,
};

export default TWInfoCash;
