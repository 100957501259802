/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';
import APIService from 'services/api.service';
import { dayjsLocal, dayjsUnixLocal } from 'utils/formatter/date';
import { getMarkStyle } from 'utils/chart/mark';
import { uniqBy, uniqueId } from 'lodash';
import historyProvider from './historyProvider';
import stream from './stream';
import { getCookie } from 'utils/cookie';

let currentTimeScaleResolution = {};

const supportedResolutions = [
  '1',
  '3',
  '5',
  '15',
  '30',
  'D',
  '1D',
  '3D',
  '1W',
  '1M',
  '3M',
  '6M'
];

const config = {
  supported_resolutions: supportedResolutions,
  supports_search: true,
  supports_group_request: false,
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true
};

function getSymbols(keyword) {
  return window?.LIST_SYMBOL?.filter(
    (item) =>
      item?.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      item?.symbol.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  );
}

export default function DataFeed(idWidget) {
  return {
    onReady: (cb) => {
      setTimeout(() => cb(config), 0);
    },
    searchSymbols: async (
      userInput,
      exchange,
      symbolType,
      onResultReadyCallback
    ) => {
      const symbols = getSymbols(userInput);
      onResultReadyCallback(symbols);
    },
    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback
      // onResolveErrorCallback
    ) => {
      const symbolItem = window?.LIST_SYMBOL?.find(
        ({ symbol, full_name }) =>
          symbol === symbolName ||
          full_name === symbolName ||
          symbol === symbolName?.replace('IDX:', '')
      );
      window.countAdvancedtRender[`${symbolItem?.symbol}-${idWidget}`] = 0;
      currentTimeScaleResolution[`${symbolItem?.symbol}-${idWidget}`] = false;
  
      var symbol_stub = {
        ticker: symbolItem?.symbol,
        name: symbolItem?.symbol,
        description: symbolItem?.description,
        type: symbolItem?.type,
        exchange: symbolItem?.exchange,
        session: '0859-1200,1330-1615',
        // subsession_id: 'session_1',
        // subsessions: [
        //   {
        //     description: 'Pre Opening',
        //     id: 'preopening',
        //     session: '0845-0859'
        //   },
        //   {
        //     description: 'Pasar Buka Sesi 1',
        //     id: 'session_1',
        //     session: '0900-1200'
        //   },
        //   {
        //     description: 'Istirahat',
        //     id: 'lunch',
        //     session: '1201-1259'
        //   },
        //   {
        //     description: 'Pasar Buka Sesi 2',
        //     id: 'session_2',
        //     session: '1330-1550'
        //   },
        //   {
        //     description: 'Pre Closed',
        //     id: 'preclosed',
        //     session: '1551-1600'
        //   },
        //   {
        //     description: 'Post Closing',
        //     id: 'postclosing',
        //     session: '1601-1615'
        //   },
        // ],
        timezone: 'Asia/Jakarta',
        minmov: 1,
        pricescale: 2,
        has_intraday: true,
        intraday_multipliers: ['1', '60'],
        supported_resolution: supportedResolutions,
        supports_search: true,
        supports_group_request: false,
        supports_marks: true,
        supports_timescale_marks: true,
        supports_time: true,
        volume_precision: 8,
        data_status: 'streaming'
      };
  
      setTimeout(function () {
        onSymbolResolvedCallback(symbol_stub);
      }, 0);
    },
    getBars: async (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback
    ) => {
      const { from, to, firstDataRequest, countBack } = periodParams;
      historyProvider(idWidget)
        .getBars(symbolInfo, resolution, {
          from,
          to,
          firstDataRequest,
          countBack
        })
  
        .then((bars) => {
          if (bars.length) {
            onHistoryCallback(bars, { noData: false });
          } else {
            onHistoryCallback([], { noData: true });
          }
        })
        .catch((err) => {
          console.log({ err });
          onErrorCallback(err);
        });
    },
    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ) => {
      stream(idWidget).subscribeBars(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
      );
    },
    unsubscribeBars: (subscriberUID) => {
      stream(idWidget).unsubscribeBars(subscriberUID);
    },
    calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
      return resolution < 60
        ? { resolutionBack: 'D', intervalBack: '1' }
        : undefined;
    },
    getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
      // onDataCallback([
      //   {
      //     id: 1,
      //     time: endDate,
      //     color: "red",
      //     text: ["This is the mark pop-up text."],
      //     label: "M",
      //     labelFontColor: "blue",
      //     minSize: 25,
      //   },
      //   {
      //     id: 2,
      //     time: endDate + 5260000, // 2 months
      //     color: "red",
      //     text: ["Second marker"],
      //     label: "S",
      //     labelFontColor: "green",
      //     minSize: 25,
      //   },
      // ]);
    },
    getTimescaleMarks: async (symbolInfo, from, to, onDataCallback, resolution) => {
      if (currentTimeScaleResolution[`${symbolInfo?.ticker}-${idWidget}`] != resolution) {
        let token = getCookie('pn_trade_auth_token');
        APIService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        const dataCalendar = await APIService.get(`/stocks/cpca`, {
          params: {
            startDate: dayjsUnixLocal(from).format('MM/DD/YYYY'),
            endDate: dayjsUnixLocal(to).format('MM/DD/YYYY')
          }
        });
    
        const filterData = dataCalendar?.filter(c => c?.code === symbolInfo?.ticker);
        const marks = [];
        
        filterData?.map((item, key) => {
          const tooltip = [item?.type?.toUpperCase()];
    
          if (item?.description) {
            tooltip.push('------------------------------');
            tooltip.push(item?.description);
            tooltip.push('------------------------------');
          }
    
          if (item?.distribution_date) {
            marks.push({
              id: key + uniqueId(),
              time: dayjs(item?.distribution_date).unix(),
              ...getMarkStyle(item?.type),
              tooltip: [
                ...tooltip,
                `Distribution Date: ${dayjsLocal(item?.distribution_date).format('DD MMMM YYYY HH:mm')}`
              ],
              // imageUrl: 'https://pina.id/favicon.ico'
            });
          }
    
          if (item?.ex_date) {
            marks.push({
              id: key + uniqueId(),
              time: dayjs(item?.ex_date).unix(),
              ...getMarkStyle(item?.type),
              tooltip: [
                ...tooltip,
                `Ex Date: ${dayjsLocal(item?.ex_date).format('DD MMMM YYYY HH:mm')}`
              ],
              // imageUrl: 'https://pina.id/favicon.ico'
            });
          }
    
          if (item?.maturity_date) {
            marks.push({
              id: key + uniqueId(),
              time: dayjs(item?.maturity_date).unix(),
              ...getMarkStyle(item?.type),
              tooltip: [
                ...tooltip,
                `Maturity Date: ${dayjsLocal(item?.maturity_date).format('DD MMMM YYYY HH:mm')}`
              ],
              // imageUrl: 'https://pina.id/favicon.ico'
            });
          }
    
          if (item?.trading_start) {
            marks.push({
              id: key + uniqueId(),
              time: dayjs(item?.trading_start).unix(),
              ...getMarkStyle(item?.type),
              tooltip: [
                ...tooltip,
                `Trading Start Date: ${dayjsLocal(item?.trading_start).format('DD MMMM YYYY HH:mm')}`
              ],
              // imageUrl: 'https://pina.id/favicon.ico'
            });
          }
    
          if (item?.trading_end) {
            marks.push({
              id: key + uniqueId(),
              time: dayjs(item?.trading_end).unix(),
              ...getMarkStyle(item?.type),
              tooltip: [
                ...tooltip,
                `Trading End Date: ${dayjsLocal(item?.trading_end).format('DD MMMM YYYY HH:mm')}`
              ],
              // imageUrl: 'https://pina.id/favicon.ico'
            });
          }
    
          if (item?.split_date) {
            marks.push({
              id: key + uniqueId(),
              time: dayjs(item?.split_date).unix(),
              ...getMarkStyle(item?.type),
              tooltip: [
                ...tooltip,
                `Split Date: ${dayjsLocal(item?.split_date).format('DD MMMM YYYY HH:mm')}`
              ],
              // imageUrl: 'https://pina.id/favicon.ico'
            });
          }
    
          if (item?.cum_date) {
            marks.push({
              id: key + uniqueId(),
              time: dayjs(item?.cum_date).unix(),
              ...getMarkStyle(item?.type),
              tooltip: [
                ...tooltip,
                `Cumulative Date: ${dayjsLocal(item?.cum_date).format('DD MMMM YYYY HH:mm')}`
              ],
              // imageUrl: 'https://pina.id/favicon.ico'
            });
          }
        });
        
        onDataCallback(uniqBy(marks, 'time'));
        currentTimeScaleResolution[`${symbolInfo?.ticker}-${idWidget}`] = resolution;
      }
    },
    getServerTime: (cb) => {}
  };
}