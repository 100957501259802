import { useEffect, useState } from "react";
import useBrowserWakeUp from "./useBrowserWakeUp";


const useSSE = (isOpen, endpoint) => {
  const [data, setData] = useState({});
  const [counter, setCounter] = useState(0);
  const isBrowserWakeUp = useBrowserWakeUp();

  useEffect(() => {
    if (isOpen && endpoint && isBrowserWakeUp) {
      const sse = new EventSource(`${process.env.REACT_APP_API_URL}/../../${endpoint}`);

      sse.onmessage = (e) => {
        const res = JSON.parse(e.data);
        setData(res);
      };

      sse.onerror = () => {
        setTimeout(() => {
          setCounter(prev => prev + 1);
        }, 1500);
        sse.close();
      };

      return () => {
        sse.close();
      };
    }
  }, [isOpen, endpoint, counter, isBrowserWakeUp]);

  return data;
};

export default useSSE;
