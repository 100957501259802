import PropTypes from 'prop-types';

function IconWithdrawOrder({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.41809 3.02441C2.72832 2.93313 3.05526 3.10579 3.14832 3.41008L5.2933 10.423L7.43828 3.41008C7.5127 3.16677 7.74102 3.00015 8 3.00015C8.25898 3.00015 8.4873 3.16677 8.56172 3.41008L10.7067 10.423L12.8517 3.41008C12.9447 3.10579 13.2717 2.93313 13.5819 3.02441C13.8921 3.1157 14.0682 3.43637 13.9751 3.74065L11.2684 12.5901C11.194 12.8334 10.9657 13 10.7067 13C10.4477 13 10.2194 12.8334 10.145 12.5901L8 5.57716L5.85502 12.5901C5.7806 12.8334 5.55228 13 5.2933 13C5.03432 13 4.806 12.8334 4.73158 12.5901L2.02489 3.74065C1.93182 3.43637 2.10786 3.1157 2.41809 3.02441Z"
        stroke="currentColor"
        strokeWidth="0.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconWithdrawOrder.propTypes = {
  className: PropTypes.string
};

export default IconWithdrawOrder;
