import PropTypes from 'prop-types';
import ListStatus from 'components/basic/list-status';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import Card from 'components/basic/card';
import SimpleSpinner from 'components/common/spinner/spinner';
import { useContext, useEffect, useState } from 'react';
import IconImage from 'components/svg/icon-image';
import IconCamera from 'components/svg/icon-camera';
import RenderIf from 'components/basic/render-if';
import { useCreateUploadKTPMutation } from 'services/rtk-query/upload';
import { ModalContext } from 'components/modal/context/modalContext';
import InputCamera from 'components/input/input-camera';
import IconImageRemove from 'components/svg/icon-image-remove';
import ModalDataAman from '../../../../../components/modal/template/modal-data-aman';
import { useGetUserProfileQuery } from 'services/rtk-query/user';
import { useNavigate } from 'react-router-dom';

import ImageFrameKTP from 'assets/images/background/bg-frame-ktp.png';
import ImageFrameKTPMobile from 'assets/images/background/bg-frame-ktp-mobile.png';
import { triggerSegmentEvent } from 'lib/segment';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useToastr from 'hooks/useToastr';
import HeaderOnboarding from '../../header-onboarding';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function RDNStep02UploadKTP({ onSuccess = () => {}}) {
  const navigate = useNavigate();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { user } = useBrowserTabAuth();
  const username = user?.username;

  const [createUploadKTP] = useCreateUploadKTPMutation();
  const { data: userDetail } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  
  const [isValidKTP, setIsValidKTP] = useState(false);
  const [isRequestUploadKTP, setIsRequestUploadKTP] = useState(false);

  const [ktpImage, setKTPImage] = useState(null);
  const [ktpImageURL, setKTPImageURL] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const { handleModal } = useContext(ModalContext);

  const handleKTPUpload = async () => {
    if (ktpImage) {
      setLoadingUpload(true);
      setIsRequestUploadKTP(false);
      try {
        const formData = new FormData();
        formData.append('file', ktpImage);
        
        const { data } = await createUploadKTP(formData);
        setIsRequestUploadKTP(true);
        if (data?.data?.error) throw new Error(data?.data?.error);
        onSuccess(data);
        setIsValidKTP(true);
        
        triggerSegmentEvent({
          event: ktpImageURL ? 'TR KTP Uploaded via Camera' : 'TR KTP Uploaded via File'
        });

        triggerSegmentEvent({
          event: 'TR KTP Inputted'
        });
      } catch (error) {
        getErrorMessage(error);
        setIsValidKTP(false);
      } finally {
        setLoadingUpload(false);
      }
    }
  };

  const handleRequestCamera = async () => {
    triggerSegmentEvent({
      event: 'TR KTP Uploaded'
    });

    navigator.permissions.query({ name: "camera" }).then(res => {
        if(res.state == "granted") {
          handleModal(
            <InputCamera
              frameCameraDesktop={ImageFrameKTP}
              frameCameraMobile={ImageFrameKTPMobile}
              onSaveImage={async (base64) => {
                const base64Response = await fetch(base64);
                const blob = await base64Response.blob();
                const file = new File([blob], `${username}.png`, { type: 'image/png' });
                
                setKTPImageURL(base64);
                setKTPImage(file);
              }}
            />
          );
        } else {
          toastr.warn('Please Activate Your Camera Permission!');
        }
    });
  };

  useEffect(() => {
    if (userDetail && userDetail?.isKtpVerified) navigate('/onboarding/informasi-tambahan');
  }, [navigate, userDetail])

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:py-16 text-white relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />
        <div className="relative z-10 max-w-2xl mx-auto">
          <HeaderOnboarding activeStep={2} />

          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] my-3 text-white">
            Siapkan KTP kamu
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Dokumen yang kamu unggah tersimpan dan terlindungi dengan aman.
          </p>

          <button
            type="button"
            onClick={() => handleModal(<ModalDataAman />)}
            className="text-sm lg:text-base px-3 py-1 border rounded-3xl border-white mb-6">
            Apakah data saya aman?
          </button>

          <ListStatus
            data={[
              {
                title: 'Upload/foto KTP',
                subtitle: null,
                status: false
              },
              {
                title: 'Informasi pribadi',
                subtitle: null,
                status: false
              },
              {
                title: 'Selfie dengan KTP',
                subtitle: null,
                status: false
              },
            ]}
          />
        </div>
      </div>

      <div className="p-0 lg:p-10">
        <Card className="px-6 lg:px-8 lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto py-8 mb-24 lg:mb-0">
          <form autoComplete="off" noValidate>
            <h5 className="text-2xl lg:text-3xl font-quicksand-regular text-main-gold-d mb-6">
              Upload atau Foto KTP
            </h5>

            <RenderIf isTrue={ktpImage}>
              <div className='mb-36'>
                <div className="border-2 border-main-gold-d rounded-xl flex justify-center aspect-[323/204] items-center overflow-hidden relative">
                  <img
                    className="w-full h-full object-cover object-left-top"
                    src={ktpImageURL}
                    alt="KTP"
                  />
                  <div onClick={() => {
                    setKTPImage(null);
                    setKTPImageURL(null);
                    setIsRequestUploadKTP(false);
                    setIsValidKTP(false);
                  }} className="absolute h-full w-full top-0 left-0 bg-black-base-d bg-opacity-0 hover:bg-opacity-70 opacity-0 hover:opacity-100 transition-all ease-in-out duration-150 cursor-pointer flex justify-center items-center">
                    <div className='text-center'>
                      <button
                        type="button"
                        className="bg-main-gold-d flex items-center justify-center rounded-full p-2 mx-auto">
                        <IconImageRemove className="h-8 w-8" />
                      </button>
                      <span className="text-main-gold-d text-sm font-quicksand-semibold">Hapus Foto</span>
                    </div>
                  </div>
                </div>

                <RenderIf isTrue={!isValidKTP && isRequestUploadKTP}>
                  <div className='text-red-base-l mt-4 text-xs'>
                    <p className='mb-1'>Foto KTP tidak terlihat jelas, pastikan:</p>
                    <ul className='list-disc pl-4'>
                      <li>Foto dalam keadaan tajam dan jelas</li>
                      <li>Semua tulisan dalam KTP kamu dapat terlihat jelas</li>
                      <li>Foto KTP didalam kotak kuning</li>
                    </ul>
                  </div>
                </RenderIf>
              </div>

            </RenderIf>

            <RenderIf isTrue={!ktpImage}>
              <div className="border-2 p-10 border-main-gold-d rounded-xl flex justify-center mb-36 aspect-[323/204] items-center">
                <div className="hover:opacity-75">
                  <label
                    htmlFor="ktpImageUpload"
                    className="h-10 w-10 lg:h-14 lg:w-14 bg-main-gold-d flex items-center justify-center rounded-full mx-10 cursor-pointer">
                    <IconImage className="text-white h-6 w-6 lg:h-8 lg:w-8" />
                    <input
                      className="opacity-0 h-0 w-0 absolute"
                      id="ktpImageUpload"
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      name="ktpImageUpload"
                      onClick={() => triggerSegmentEvent({ event: 'TR KTP Photo Uploaded' })}
                      onChange={(e) => {
                        const file = e?.target?.files?.[0];
                        setKTPImage(file);

                        const reader = new FileReader();
                        reader.onload = function (e) {
                          setKTPImageURL(e.target.result);
                        };

                        reader.readAsDataURL(file);
                      }}
                    />
                  </label>
                  <p className="text-sm lg:text-base text-center mt-3 text-main-gold-d">Upload KTP</p>
                </div>

                <div className="hover:opacity-75">
                  <button
                    type="button"
                    onClick={handleRequestCamera}
                    className="h-10 w-10 lg:h-14 lg:w-14 bg-main-gold-d flex items-center justify-center rounded-full mx-10 cursor-pointer">
                    <IconCamera className="text-white h-6 w-6 lg:h-8 lg:w-8" />
                  </button>
                  <p className="text-sm lg:text-base text-center mt-3 text-main-gold-d">Foto KTP</p>
                </div>
              </div>
            </RenderIf>

            <div className="mt-10 fixed lg:relative w-full bottom-0 left-0 p-6 lg:p-0 bg-white border-t lg:border-none">
              <button
                type="button"
                onClick={() => handleKTPUpload()}
                disabled={!ktpImage || (!isValidKTP && isRequestUploadKTP) || loadingUpload}
                className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 flex px-10 w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden focus:outline-none ${
                  !ktpImage || (!isValidKTP && isRequestUploadKTP)
                    ? 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                    : 'bg-main-gold-d text-white'
                }`}>
                {loadingUpload && <SimpleSpinner />}
                Selanjutnya
              </button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

RDNStep02UploadKTP.propTypes = {
  onSuccess: PropTypes.func,
};

export default RDNStep02UploadKTP;
