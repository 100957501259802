import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import IconClose from 'components/svg/icon-close';
import { useEffect, useState } from 'react';
import SmartBuyForm from './smart-buy';
import TWWatchlist from 'pages/trading/_partials/tw-watchlist';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SmartSellForm from './smart-sell';
import { useGetPortfolioListQuery } from 'services/rtk-query/trade';
import CardStockItem from 'components/cards/card-stock-item';
import Tabs from 'components/tabs/tabs';
import Tooltip from 'components/basic/tooltip';
import IconInfo from 'components/svg/icon-info';

function SmartOrderForm({
  formType = 'Smart Order',
  orderDetail = {},
  initialForms = {},
  disabledStock = false,
  onClose = () => {},
  onSuccess = () => {},
}) {
  const [tempOrderDetail, setTempOrderDetail] = useState(orderDetail);
  const [activeTab, setActiveTab] = useState(0);
  const tabsSmartPortfolio = [
    {
      index: 0,
      text: (
        <div className='flex justify-center'>
          <span>Stop Loss</span>

          <Tooltip
            isZoomOut
            tooltipHTML={
              <div className='max-w-[300px] min-w-[300px]'>
                <h5 className='text-sm font-quicksand-medium text-left mb-1'>Stop Loss</h5>
                <p className="text-xs font-quicksand-regular text-left">
                  Adalah order yang telah ditentukan oleh kita untuk menjual saham jika harga mencapai level tertentu. 
                  <br/>
                  <br/>
                  Dengan menggunakan stop loss, Kamu dapat mengontrol risiko investasi dengan cara menetapkan batasan kerugian yang telah ditentukan.
                </p>
              </div>
            }
            position="bottom-right">
            <button type="button" className="hover:text-main-base-l mx-1">
              <IconInfo className='h-5 w-5' />
            </button>
          </Tooltip>
        </div>
      )
    },
    {
      index: 1,
      text: (
        <div className='flex justify-center'>
          <span>Take Profit</span>

          <Tooltip
            isZoomOut
            tooltipHTML={
              <div className='max-w-[300px] min-w-[300px]'>
                <h5 className='text-sm font-quicksand-medium text-left mb-1'>Take Profit</h5>
                <p className="text-xs font-quicksand-regular text-left">
                  Adalah order yang ditentukan oleh kita untuk menjual saham ketika harga mencapai level tertentu yang telah ditentukan sebelumnya. 
                  <br/>
                  <br/>
                  Dengan menggunakan take profit, Kamu dapat mengendalikan dan merealisasikan keuntungan yang telah dicapai. Saat harga mencapai level take profit yang ditetapkan.
                </p>
              </div>
            }
            position="bottom-right">
            <button type="button" className="hover:text-main-base-l mx-1">
              <IconInfo className='h-5 w-5' />
            </button>
          </Tooltip>
        </div>
      )
    }
  ];

  useEffect(() => {
    if (orderDetail?.BuySell === 'TakeProfit') setActiveTab(1);
  }, [orderDetail]);

  return (
    <Card className="p-4 lg:p-8 dark:border dark:border-gray-base-d dark:text-black-light-1-d max-w-3xl mx-auto relative zoom-2">
      <button
        type="button"
        className="absolute top-4 right-4 text-black-base-l hover:text-main-base-l text-sm items-center cursor-pointer dark:text-main-base-l"
        onClick={onClose}>
        <IconClose />
      </button>

      <h5 className="font-quicksand-semibold text-base text-center mb-2">
        {formType}
      </h5>

      <RenderIf isTrue={formType === 'Smart Portfolio'}>
        <Tabs
          initialOpenIndex={activeTab}
          className="grid grid-cols-2 font-quicksand-semibold text-gray-light-0-d text-sm -mx-4 lg:-mx-8"
          data={tabsSmartPortfolio}
          onChange={(index) => {
            setActiveTab(index);
            if (index === 0) {
              setTempOrderDetail({
                ...tempOrderDetail,
                BuySell: 'StopLoss'
              });
            }

            if (index === 1) {
              setTempOrderDetail({
                ...tempOrderDetail,
                BuySell: 'TakeProfit'
              });
            }
        }}/>
      </RenderIf>

      <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-8 mt-4 relative">
        <div>
          <OverlayScrollbarsComponent
            className={`overlayscrollbars-host-small -mx-4 px-4 ${formType === 'Smart Order' ? 'lg:max-h-[597px] lg:min-h-[597px]' : 'lg:max-h-[500px] lg:min-h-[500px]'}`}
            element="span"
            options={{
              resize: 'horizontal',
              scrollbars: {
                autoHide: 'leave',
                autoHideDelay: 50
              }
            }}
            >
            <RenderIf isTrue={formType === 'Smart Order'}>
              <div className="-mt-4">
                <TWWatchlist
                  isCardLess
                  showAddWatchlist={false}
                  title="Watchlist"
                  activeSymbol={tempOrderDetail?.stockId}
                  onClickStock={(symbol) =>
                    setTempOrderDetail({ ...tempOrderDetail, stockId: symbol })
                  }
                />
              </div>
            </RenderIf>

            <RenderIf isTrue={formType === 'Smart Portfolio'}>
              <ListPortfolio onSelectStock={(payload) => setTempOrderDetail(payload)} tempOrderDetail={tempOrderDetail} />
            </RenderIf>
          </OverlayScrollbarsComponent>
        </div>

        <div>
          <RenderIf isTrue={formType === 'Smart Order'}>
            <SmartBuyForm
              initialForms={initialForms}
              symbol={tempOrderDetail?.stockId}
              disabledStock={disabledStock}
              defaultBotName={tempOrderDetail?.BuySell}
              onChangeSymbol={(symbol) =>
                setTempOrderDetail({ ...tempOrderDetail, stockId: symbol })
              }
              onClose={onClose}
              onSuccess={onSuccess}
            />
          </RenderIf>

          <RenderIf isTrue={formType === 'Smart Portfolio'}>
            <SmartSellForm
              initialForms={initialForms}
              symbol={tempOrderDetail?.stockId}
              disabledStock={disabledStock}
              defaultBotName={tempOrderDetail?.BuySell}
              onChangeSymbol={(symbol) =>
                setTempOrderDetail({ ...tempOrderDetail, stockId: symbol })
              }
              onChangeBotName={(BotName) =>
                {
                  if (BotName === 'TakeProfit') {
                    setActiveTab(1);
                  } else {
                    setActiveTab(0);
                  }
                }
              }
              onClose={onClose}
              onSuccess={onSuccess}
            />
          </RenderIf>
        </div>
      </div>
    </Card>
  );
}

SmartOrderForm.propTypes = {
  onSuccess: PropTypes.func,
  formType: PropTypes.string,
  orderDetail: PropTypes.object,
  initialForms: PropTypes.object,
  disabledStock: PropTypes.bool,
};

export default SmartOrderForm;


function ListPortfolio({ onSelectStock = () => {}, tempOrderDetail}) {
  const { data: portFolio, isFetching } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const [stockPortoList, setStockPortoList] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      setStockPortoList(
        portFolio?.stock?.items?.map((porto) => {
          return {
            code: porto?.stID,
            name: porto?.sName
          };
        })
      );
    }
  }, [portFolio, isFetching]);

  return (
    <>
      <h5 className="lg:px-2 mb-4">Saham Kamu</h5>
        <ul>
          {stockPortoList?.map((item, key) => (
            <li key={key}>
              <CardStockItem
                {...item}
                isActive={tempOrderDetail?.stockId === item?.code}
                data-link-qa-id="linkTrendingListStock"
                data-img-qa-id="imgTrendingListStock"
                isLastItem={key === stockPortoList?.length - 1}
                onClick={() => {
                  onSelectStock({
                    ...tempOrderDetail,
                    stockId: item?.code
                  });
                }}
              />
            </li>
          ))}
        </ul>
    </>
  )
}
