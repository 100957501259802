import PropTypes from 'prop-types';

function IconSort({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2929 17.7071C12.9024 17.3166 12.9024 16.6834 13.2929 16.2929C13.6834 15.9024 14.3166 15.9024 14.7071 16.2929L16 17.5858V9C16 8.44772 16.4477 8 17 8C17.5523 8 18 8.44772 18 9V17.5858L19.2929 16.2929C19.6834 15.9024 20.3166 15.9024 20.7071 16.2929C21.0976 16.6834 21.0976 17.3166 20.7071 17.7071L17.7071 20.7071C17.3166 21.0976 16.6834 21.0976 16.2929 20.7071L13.2929 17.7071Z"
      />
      <path
        d="M10.7071 6.29289C11.0976 6.68342 11.0976 7.31658 10.7071 7.70711C10.3166 8.09763 9.68342 8.09763 9.29289 7.70711L8 6.41421L8 15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15L6 6.41421L4.70711 7.70711C4.31658 8.09763 3.68342 8.09763 3.29289 7.70711C2.90237 7.31658 2.90237 6.68342 3.29289 6.29289L6.29289 3.29289C6.68342 2.90237 7.31658 2.90237 7.70711 3.29289L10.7071 6.29289Z"
      />
    </svg>
  );
}

IconSort.propTypes = {
  className: PropTypes.string
};

export default IconSort;
