import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import SkeletonTheme from 'components/basic/skeleton-theme';
import getAllFilterChart from 'utils/chart/filter';

function TVFilter({
  className,
  defaultFilter,
  handleChartFilter,
  isLoading = true,
  size = 'small'
}) {
  const sizes = {
    small: 'py-[2px] px-3 text-2xs',
    medium: 'py-[3px] px-3 text-xs'
  };

  const filters = getAllFilterChart();

  return (
    <OverlayScrollbarsComponent
      className={`overlayscrollbars-host-small font-quicksand-semibold px-2 my-2 max-w-full ${className}`}
      element="span"
      options={{
        resize: 'horizontal',
        scrollbars: {
          autoHide: 'leave',
          autoHideDelay: 50
        }
      }}>
      <div className="flex gap-1 items-center justify-between my-1">
        {filters?.map((item, key) =>
          isLoading ? (
            <SkeletonTheme
              key={key}
              containerClassName="leading-none"
              width="40px"
              height="18px"
              borderRadius="4px"
            />
          ) : (
            <button
              key={key}
              onClick={() => handleChartFilter(item)}
              className={`${
                sizes[size]
              } rounded-md flex-grow appearance-none outline-none border dark:border-black-light-1-l hover:opacity-75 transition-all ease-out duration-150 ${
                item.text === defaultFilter
                  ? 'bg-main-base-l text-white dark:text-black-base-d !border-main-base-l'
                  : 'bg-white text-gray-light-0-d dark:bg-black-base-d dark:text-black-light-1-d'
              }`}>
              {item.text}
            </button>
          )
        )}
      </div>
    </OverlayScrollbarsComponent>
  );
}

TVFilter.propTypes = {
  defaultFilter: PropTypes.string,
  handleChartFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default TVFilter;
