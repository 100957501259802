import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetPortfolioListQuery } from 'services/rtk-query/trade';
import useSSE from './useSSE';
import { useGetAlgoBOTQuery } from 'services/rtk-query/trading';
import useBrowserTabAuth from './useBrowserTabAuth';

const usePortfolio = () => {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { user } = useBrowserTabAuth();
  const username = user?.username;

  const { data: portfolio, isFetching: loadingPortfolio, isSuccess } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const { data: algoData, isFetching: loadingAlgo, error: errorAlgoBot } = useGetAlgoBOTQuery(username, { refetchOnMountOrArgChange: true });

  const [portfolioList, setPortfolioList] = useState(null);
  const [totalInvested, setTotalInvested] = useState(0);
  const [totalMktValue, setTotalMktValue] = useState(0);
  const [totalUnrealized, setTotalUnrealized] = useState(0);
  const [totalPotGainPercent, setTotalPotGainPercent] = useState(0);
  const [totalPotGain, setTotalPotGain] = useState(0);
  const [endpoint, setEnpoint] = useState(false);
  const resSSE = useSSE(isBursaOpen && endpoint, endpoint);

  const handleEndpoint = (stocks) => {
    let ep = 'ticker?';
    stocks?.map(({ code }, key) => {
      ep += `stock[]=${code}`;
      if (key < stocks?.length - 1) ep += '&';
    });

    setEnpoint(ep);
  };

  useEffect(() => {
    if (portfolio?.stock?.items?.length > 0 && !loadingAlgo && !loadingPortfolio && isSuccess) {
      let datas = Object.assign([], portfolio?.stock?.items);
      
      handleEndpoint(datas);

      let _totalInvest = 0;
      let _totalMktValue = 0;
      let _totalPotG = 0;
      let _unrealized = 0;

      datas = datas?.map((item, key) => {
        let _mktValue = item?.marketValue;

        const _livePrice = Number(resSSE[item?.code]?.lastTradedPrice);

        if (_livePrice && (_livePrice != item?.marketValue / item?.netDone * 100)) {
          _mktValue = Number(resSSE[item?.code]?.lastTradedPrice) * item?.netDone;
        }

        let _algoTakeProfit = [];
        let _algoStopLoss = [];

        algoData?.forEach(algo => {
          let _botNameArr = algo?.BotName?.split('@');
          if (algo?.StockId === item?.stID && _botNameArr?.length < 3 && _botNameArr?.[0] === 'TakeProfit') {
            _algoTakeProfit.push(algo);
          }

          if (algo?.StockId === item?.stID && _botNameArr?.length < 3 && _botNameArr?.[0] === 'StopLoss') {
            _algoStopLoss.push(algo);
          }
        });

        _totalInvest += item?.stockValue;
        _totalMktValue += _mktValue;
        _totalPotG += (_mktValue - item?.stockValue);
        _unrealized += item?.unrealized;

        return {
          ...item,
          algoTakeProfit: _algoTakeProfit,
          algoStopLoss: _algoStopLoss,
          marketValue: _mktValue,
          lastTradedPrice: _mktValue / item?.netDone * 100,
          totalGain: _mktValue - item?.stockValue,
          totalGainPercentage: (_mktValue - item?.stockValue) / item?.stockValue * 100,
          id: String(key)
        }
      });

      setTotalUnrealized(_unrealized);
      setTotalPotGain(_totalPotG);
      setTotalPotGainPercent(((_totalPotG / _totalInvest) * 100).toFixed(2));
      setTotalInvested(_totalInvest);
      setTotalMktValue(_totalMktValue);
      setPortfolioList(datas?.length > 0 ? datas : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio, loadingAlgo, resSSE, algoData, loadingPortfolio, isSuccess]);

  return { totalInvested, totalMktValue, totalUnrealized, totalPotGainPercent, totalPotGain, portfolio, isLoading: loadingPortfolio, portfolioList, errorAlgoBot};
};

export { usePortfolio };
