import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSlot, withSlot } from 'hooks/slot'

const AccordionSimpleItem = ({ children, expandable, expanded, isChangeHeight, className, onToggle, style }) => {
  const ref = useRef(null);
  const contentRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [contentHeight, setContentHeight] = useState('0px');

  const Header = useSlot({ name: 'Header', children })
  const Content = useSlot({ name: 'Content', children })

  useEffect(() => {
    if (contentRef?.current && isExpanded) {
      setContentHeight(`${contentRef?.current?.scrollHeight + 50}px`)
    } else {
      setContentHeight('0px')
    }
  }, [isExpanded, isChangeHeight])

  useEffect(() => {
    if (!expandable) setIsExpanded(expanded);
  }, [expanded, expandable])


  // methods
  const handleToggle = () => {
    if (expandable) {
      const isOpen = !isExpanded;
      setIsExpanded(isOpen)
      if (onToggle) {
        onToggle(isOpen)
      }
    } else {
      onToggle(isExpanded);
    }
  };

  
  return (
    <li
      ref={ref}
      className={`${className}`}
      style={style}
    >
      <header className="cursor-pointer" onClick={handleToggle}>
        <Header />
      </header>

      <main
        ref={contentRef}
        className={`transition-all duration-[400ms] ease-in-out ${isExpanded ? `opacity-100` : ' opacity-0 overflow-hidden' }`}
        style={{
          maxHeight: isExpanded ? contentHeight : '0px'
      }}>
        <Content />
      </main>
    </li>
  )
}

AccordionSimpleItem.propTypes = {
  children: PropTypes.node,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  isChangeHeight: PropTypes.any,
  className: PropTypes.string,
  onToggle: PropTypes.func,
  style: PropTypes.object,
}

AccordionSimpleItem.defaultProps = {
  expandable: true,
  expanded: false,
  isChangeHeight: false,
  className: '',
  onToggle: () => {},
  style: {}
}

export default withSlot(AccordionSimpleItem)