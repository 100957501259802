import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconEllipsisVertical from '../svg/icon-ellipsis-vertical';
import RenderIf from './render-if';
import { Link } from 'react-router-dom';
import IconSearch from 'components/svg/icon-search';
import IconClose from 'components/svg/icon-close';
import { triggerSegmentEvent } from 'lib/segment';

function ActionList({ data = [], onClick, children, className = '-right-8 w-48', placeholderSearch = 'Cari' }) {
  const ref = useRef(null);

  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <RenderIf isTrue={data.length > 0}>
      <div ref={ref} className="relative" onMouseLeave={() => setOpen(false)}>
        <div
          className="relative cursor-pointer"
          onMouseOver={() => setOpen(true)}
          >
          <RenderIf isTrue={!children}>
            <IconEllipsisVertical />
          </RenderIf>

          <RenderIf isTrue={children}>{children}</RenderIf>
        </div>

        <RenderIf isTrue={open}>
          <div
            className={`absolute top-full font-quicksand-semibold ${className}`}>
            <ul className="relative text-sm max-h-500 overflow-auto transform translate-y-4 bg-white dark:bg-black-dark-d shadow-pro border dark:border-gray-base-d rounded-xl z-30 filter">
              <RenderIf isTrue={data?.length > 10}>
                <li className='p-3 border-b sticky top-0 bg-white dark:bg-black-dark-d dark:border-gray-base-d'>
                  <div className="relative z-20">
                    <input
                      data-qa-id="txtSearchStockField"
                      className="appearance-none outline-none bg-gray-light-l py-3 px-3 text-sm rounded-lg w-full font-quicksand-semibold"
                      placeholder={placeholderSearch}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <button
                      data-qa-id="btnSearchStockClear"
                      className={`appearance-none absolute right-3 top-0 h-full text-gray-light-0-d ${
                        keyword?.length > 1 ? 'cursor-pointer' : 'cursor-default'
                      }`}
                      onClick={() => {
                        if (keyword?.length > 1) setKeyword('');
                      }}>
                      <RenderIf isTrue={keyword?.length > 0}>
                        <IconClose />
                      </RenderIf>

                      <RenderIf isTrue={keyword?.length === 0}>
                        <IconSearch />
                      </RenderIf>
                    </button>
                  </div>
                </li>
              </RenderIf>

              {data.filter((item) =>item?.title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)?.map((item, key) => (
                <li
                  key={key}
                  onClick={() => {
                    setOpen(false);
                    triggerSegmentEvent({ event: item?.analyticEventName });
                  }}>
                  <RenderIf isTrue={item.link && !item?.externalLink}>
                    <Link
                      data-qa-id={item?.['data-qa-id']}
                      className={`p-3 ${item?.iconRight ? 'flex justify-between' : 'block'} dark:text-black-light-1-d dark:hover:text-main-base-l hover:text-main-base-l cursor-pointer transition-all ease-in-out duration-200 
                        ${key < data.length - 1 && 'border-b dark:border-gray-base-d'}`}
                      to={item.link}>
                      {item.title}

                      <RenderIf isTrue={item?.iconRight}>
                        {item?.iconRight}
                      </RenderIf>
                    </Link>
                  </RenderIf>

                  <RenderIf isTrue={item.link && item?.externalLink}>
                    <a
                      data-qa-id={item?.['data-qa-id']}
                      className={`p-3 ${item?.iconRight ? 'flex justify-between' : 'block'} dark:text-black-light-1-d dark:hover:text-main-base-l hover:text-main-base-l cursor-pointer transition-all ease-in-out duration-200 
                        ${key < data.length - 1 && 'border-b dark:border-gray-base-d'}`}
                      href={item.link}>
                      {item.title}

                      <RenderIf isTrue={item?.iconRight}>
                        {item?.iconRight}
                      </RenderIf>
                    </a>
                  </RenderIf>

                  <RenderIf isTrue={!item.link}>
                    <button
                      onClick={() => onClick(item)}
                      className={`p-3 w-full ${item?.iconRight ? 'flex justify-between' : 'block'} text-left hover:text-main-base-l cursor-pointer transition-all ease-in-out duration-200 
                        ${key < data.length - 1 && 'border-b dark:border-gray-base-d'}`}>
                      {item.title}

                      <RenderIf isTrue={item?.iconRight}>
                        {item?.iconRight}
                      </RenderIf>
                    </button>
                  </RenderIf>

                </li>
              ))}
            </ul>

            <span
              className="absolute w-4 h-4 rotate-45 top-6 right-8 bg-white border-t border-l dark:border-gray-base-d"
              style={{ clipPath: 'polygon(0 0, 0% 100%, 100% 0)' }}
            />
          </div>
        </RenderIf>
      </div>
    </RenderIf>
  );
}

ActionList.propTypes = {
  data: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  placeholderSearch: PropTypes.string,
  onClick: PropTypes.func,
};

export default ActionList;
