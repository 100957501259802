import PropTypes from 'prop-types';

function IconRotateRight({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5882_90587)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6602 8.75C21.1384 8.47386 20.9363 7.47819 20.6602 6.9999C17.8987 2.21697 11.7828 0.578223 6.9999 3.33965C2.21698 6.10107 0.578224 12.217 3.33965 16.9999C6.10107 21.7828 12.217 23.4216 16.9999 20.6602C17.4782 20.384 17.6421 19.7724 17.3659 19.2941C17.0898 18.8158 16.4782 18.652 15.9999 18.9281C12.1736 21.1372 7.28084 19.8262 5.0717 15.9999C2.86256 12.1736 4.17356 7.28084 7.9999 5.0717C11.8262 2.86256 16.719 4.17356 18.9281 7.9999C19.2042 8.47819 20.1819 9.02614 20.6602 8.75Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.6405 5.76732C21.2694 5.64504 20.9041 5.85597 20.8245 6.23843L20.357 8.48399L18.1785 7.76608C17.8075 7.6438 17.4422 7.85473 17.3625 8.23719C17.2829 8.61965 17.5191 9.02883 17.8902 9.1511L20.7405 10.0904C21.1115 10.2127 21.4768 10.0018 21.5565 9.6193L22.1681 6.68123C22.2477 6.29876 22.0115 5.88959 21.6405 5.76732Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5882_90587">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

IconRotateRight.propTypes = {
  className: PropTypes.string
};

export default IconRotateRight;
