import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import InputText from 'components/input/input-text';
import IconClose from 'components/svg/icon-close';
import { useFormik } from 'formik';
import useToastr from 'hooks/useToastr';
import { useState } from 'react';
import { useCreateWorkSpaceByUserIdMutation, useUpdateWorkSpaceByIdMutation } from 'services/rtk-query/workspace';
import slugify from 'utils/formatter/slugify';
import { WorkspaceSchema } from './workspace.form.validation';
import SimpleSpinner from 'components/common/spinner/spinner';
import { find } from 'lodash';
import dayjs from 'dayjs';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function WorkSpaceForm({ onClose = () => {}, onSuccess = () => {}, layout = "", initialValues, workspaces = [] }) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { user } = useBrowserTabAuth();
  const [createWorkspace] = useCreateWorkSpaceByUserIdMutation();
  const [updateWorkspace] = useUpdateWorkSpaceByIdMutation();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: initialValues?.name,
      workspaces: workspaces
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: WorkspaceSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        let _slug = slugify(values.name);
        const isSameSlug = find(workspaces, ['slug', _slug]);
        if (isSameSlug) _slug = slugify(`${values.name} ${dayjs().valueOf().toString().substring(8, 13)}`);

        if (!initialValues?.id) {
          const payload = { ...values, slug: _slug, is_active: true, layout: layout };
          const { data } = await createWorkspace({ body: payload, userId: user?.id }).unwrap();
          onSuccess(data);
        } else{
          const payload = { ...initialValues, ...values, slug: _slug, is_active: true, layout: layout };
          const { data } = await updateWorkspace({ body: payload, id: initialValues?.id}).unwrap();
          onSuccess(data);
        }
        toastr.dismiss();
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
      <Card className="p-6 relative max-w-lg mx-4 lg:mx-auto mt-20 dark:text-black-light-1-d dark:border dark:border-gray-base-d">
        <div className="mb-6">
          <h5 className="text-sm">Beri nama workspacemu</h5>

          <button
            onClick={onClose}
            type="button"
            className="absolute right-4 top-4 hover:text-main-base-l dark:text-main-base-d dark:hover:opacity-75">
            <IconClose />
          </button>
        </div>

        <InputText
          value={formik?.values?.name}
          name="name"
          placeholder="Ketik Nama Workspace"
          label=""
          className="text-sm"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.errors.name ? formik.errors.name : ''}
          maxLength="30"
        />
        <span className='text-main-base-l text-xs'>Maks. 30 Karakter</span>

        <div className="pt-4 mt-1 relative">
          <button
            type="submit"
            disabled={isLoading || !formik.isValid}
            className="bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-64 mr-0 ml-auto flex justify-center items-center transition-all ease-in-out duration-100">
            {isLoading && <SimpleSpinner/>} Simpan
          </button>
        </div>
      </Card>
    </form>
  );
}

WorkSpaceForm.propTypes = {
  onSuccess: PropTypes.object,
  onClose: PropTypes.object,
  layout: PropTypes.string,
  initialValues: PropTypes.object,
  workspaces: PropTypes.array,
};


export default WorkSpaceForm;
