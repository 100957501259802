import PropTypes from 'prop-types';
import IconMediaPrev from 'components/svg/icon-media-prev';
import IconMediaPrevSkip from 'components/svg/icon-media-prev-skip';
import RenderIf from './render-if';

function Pagination({
  className,
  totalPage,
  page,
  onChangePage = () => {}
}) {
  if (totalPage < 2) return <></>;
  return (
    <div className={`inline-flex items-center gap-2 ${className}`}>
      <button
        disabled={page === 0}
        type="button"
        onClick={() => onChangePage(0)}
        className="outline-none disabled:text-gray-light-0-d text-main-base-l hover:opacity-80 duration-100 transition-all">
        <IconMediaPrevSkip />
      </button>

      <button
        disabled={page === 0}
        type="button"
        onClick={() => onChangePage(page - 1 <= 0 ? 0 : page - 1)}
        className="outline-none disabled:text-gray-light-0-d text-main-base-l hover:opacity-80 duration-100 transition-all">
        <IconMediaPrev />
      </button>

      <ul className="flex items-center gap-1">
        <RenderIf isTrue={(page >= 3)}>
          <li className='flex items-center'>...</li>
        </RenderIf>

        {Array(totalPage)
          .fill(1)
          .map((el, key) => (
            <RenderIf key={key} isTrue={key < (page + (key < 5 ? 5 : 3)) && key >= (page - 2)}>
              <li key={key}>
                <button
                  type="button"
                  onClick={() => onChangePage(key)}
                  className={`outline-none text-xs h-5 w-5 flex items-center justify-center p-[4px] rounded-full duration-100 transition-all
                  ${page === key ? 'bg-main-base-l text-white' : 'text-gray-dark-d hover:opacity-80 hover:text-main-base-l dark:text-black-light-0-d'}`}>
                  {key + 1}
                </button>
              </li>
            </RenderIf>
          ))}

          <RenderIf isTrue={(totalPage > 5) && (page + 3 < totalPage)}>
            <li className='flex items-center'>...</li>
          </RenderIf>
      </ul>

      <button
        disabled={page + 1 >= totalPage}
        type="button"
        onClick={() => onChangePage(page + 1 >= totalPage ? totalPage - 1 : page + 1)}
        className="outline-none transform rotate-180 disabled:text-gray-light-0-d text-main-base-l hover:opacity-80 duration-100 transition-all">
        <IconMediaPrev />
      </button>

      <button
        disabled={page + 1 >= totalPage}
        type="button"
        onClick={() => onChangePage(totalPage - 1)}
        className="outline-none transform rotate-180 disabled:text-gray-light-0-d text-main-base-l hover:opacity-80 duration-100 transition-all">
        <IconMediaPrevSkip />
      </button>
    </div>
  );
}

Pagination.propTypes = {
  className: PropTypes.string,
  totalPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangePage: PropTypes.func,
};

export default Pagination;
