import PropTypes from 'prop-types';
import styles from './css/input-range.module.css';
import { useEffect, useState } from 'react';

function InputRange({
  value,
  min = 0,
  max = 10000000,
  step = 1000000,
  color = 'orange',
  className,
  onChange = () => {}
}) {
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    setProgressWidth(((Number(value) - min) * 100) / (max - min));
  }, [value, min, max]);

  return (
    <div className={`relative ${className}`}>
      <input
        className={`focus:outline-none rounded-md overflow-hidden appearance-none bg-gray-light-2-d00 h-3 w-full ${styles['input-range']} ${styles[color]}`}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        style={{
          backgroundSize: progressWidth + '%'
        }}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
}

InputRange.propTypes = {
  value: PropTypes.any,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  color: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
};

export default InputRange;
