import SkeletonTheme from 'components/basic/skeleton-theme';

function LoaderStockGroup() {
  return (
    <div>
      <SkeletonTheme className='mx-2' width="50%" height="1rem" borderRadius="6px" />
      <SkeletonTheme className='mx-2' width="90%" height="1rem" borderRadius="6px" />
      <div className='flex mx-2 gap-2'>
        <SkeletonTheme width="40px" height="1rem" borderRadius="6px" />
        <SkeletonTheme width="40px" height="1rem" borderRadius="6px" />
      </div>
    </div>
  );
}

export default LoaderStockGroup;
