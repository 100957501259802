import { triggerSegmentEvent } from 'lib/segment';
import { Link, useLocation } from 'react-router-dom';

function TabsStockMarket() {
  const { pathname } = useLocation();

  const features = [
    {
      name: 'Stock Market',
      slug: 'stock-market',
      url: ['/stock-market', '/stock-market/'],
      analyticEventName: 'TR Stock Market Tab Clicked'
    },
    {
      name: 'Stock Screener',
      slug: 'stock-screener',
      url: ['/stock-market/stock-screener'],
      analyticEventName: 'TR Stock Screener Tab Clicked'
    }
  ];

  return (
    <div className="px-3 mb-3 text-sm zoom-2">
      <ul className="flex justify-start -mx-2 border-b dark:border-gray-base-d">
        {features?.map((item, key) => (
          <li
            key={key}
            className={`border-r last:border-r-0 dark:border-gray-base-d ${
              item?.url?.includes(pathname) &&
              'font-quicksand-semibold border-b-2 border-main-base-l dark:border-main-base-l'
            }`}>
            <Link
              className="py-1 px-3 block dark:text-black-light-1-d"
              to={item?.url?.[0]}
              onClick={() =>
                triggerSegmentEvent({ event: item?.analyticEventName })
              }>
              {item?.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TabsStockMarket;
