import TransitionModal from 'components/basic/transition-modal';
import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { ModalContext } from '../context/modalContext';

const Modal = () => {
  const { modalContent, handleModal, modal, modalPosition, modalSize, modalBackdrop } = useContext(ModalContext);

  if (modal) {
    return createPortal(
      <div className="fixed w-full h-full left-0 top-0 z-50 max-h-screen overflow-auto">
        <div className="fixed w-full h-full left-0 top-0" style={{ background: 'rgba(0, 0, 0, 0.5)' }} onClick={() => { modalBackdrop ? handleModal(false) : ''}}></div>
        <TransitionModal isOpen={true} position={modalPosition} size={modalSize}>
          {modalContent}
        </TransitionModal>
      </div>,
      document.querySelector('#modal-root')
    );
  } else return null;
};

export default Modal;
