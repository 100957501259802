import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';

const useTableSorting = (
  list,
  defaultSortingColumns = [],
  defaultSortingOrders = [],
  defaultPagination = {
    page: 0,
    perPage: 99999999,
    totalPage: 1,
    totalContent: 0
  }
) => {
  const [data, setData] = useState([]);
  const [sortingColumns, setSortingColumns] = useState([]);
  const [sortingOrders, setSortingOrders] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [pagination, setPagination] = useState(defaultPagination);

  const getColorStatus = (value) => {
    if (value > 0) return 'text-green-base-l';
    if (value < 0) return 'text-red-base-l';

    return '';
  };

  const getSorting = (obj) => {
    if (obj === sortingColumns?.[0] && sortingOrders?.[0] === 'asc') return 1;
    if (obj === sortingColumns?.[0] && sortingOrders?.[0] === 'desc') return 2;

    return null;
  };

  const handleSorting = (obj) => {
    let tSortingColumns = sortingColumns;
    let tSortingOrders = sortingOrders;

    if (!tSortingColumns.includes(obj)) {
      tSortingColumns[0] = obj;
      tSortingOrders[0] = tSortingOrders?.[0] === 'asc' ? 'desc' : 'asc';
    } else if (tSortingColumns.includes(obj)) {
      tSortingOrders[0] = tSortingOrders[0] === 'asc' ? 'desc' : 'asc';
    }

    setSortingColumns(tSortingColumns);
    setSortingOrders(tSortingOrders);

    const orderData = orderBy(list, tSortingColumns, tSortingOrders);

    setData(orderData?.filter((_, index) => index >= (pagination?.page * pagination?.perPage) && index < ((pagination?.page + 1) * pagination?.perPage)));
    setPagination((prev) => {
      return {
        ...prev,
        totalContent: orderData?.length,
        totalPage: Math.ceil(orderData?.length / prev?.perPage)
      };
    });
  };

  useEffect(() => {
    const _tempData = orderBy(list, sortingColumns, sortingOrders);
    setData(_tempData?.filter((_, index) => index >= (pagination?.page * pagination?.perPage) && index < ((pagination?.page + 1) * pagination?.perPage)));
  }, [list, sortingColumns, sortingOrders, pagination]);
  
  useEffect(() => {
    const _tempData = orderBy(list, sortingColumns, sortingOrders);
    setPagination((prev) => {
      return {
        ...prev,
        totalContent: _tempData?.length,
        totalPage: Math.ceil(_tempData?.length / prev?.perPage)
      };
    });
  }, [list, sortingColumns, sortingOrders]);

  useEffect(() => {
    if (pagination?.page != defaultPagination?.page) {
      setPagination((prev) => {
        return {
          ...prev,
          page: defaultPagination?.page,
        };
      });
    }
  }, [defaultPagination, pagination]);

  useEffect(() => {
    if (defaultSortingColumns?.length > 0 && defaultSortingOrders?.length > 0 && firstRender) {
      setSortingColumns(defaultSortingColumns);
      setSortingOrders(defaultSortingOrders);
      setFirstRender(false);
    }
  }, [defaultSortingColumns, defaultSortingOrders, firstRender])

  return { data, sortingColumns, sortingOrders, pagination, getSorting, handleSorting, getColorStatus };
};

export { useTableSorting };
