import RenderIf from 'components/basic/render-if';
import LoginPinForm from 'components/form/login-pin';
import NewTradingHeader from 'components/header/new-trading-header';
import PropTypes from 'prop-types';
import SideMenu from 'components/menu/side-menu';
import { Link } from 'react-router-dom';
import IconInfo from 'components/svg/icon-info';
import CountDownExploreTrade from 'components/template/countdown-explore-trade';
import { triggerSegmentEvent } from 'lib/segment';
import useDarkMode from 'hooks/useDarkMode';
import ModalTradingProvider from 'components/modal/components/modal-trading-provider';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import SettingsBar from 'pages/trading/_partials/settings-bar';

export const LAYOUT_TRADING = 'trading';

function TradingLayout({ children }) {
  const { removeDarkMode } = useDarkMode();
  const { user } = useBrowserTabAuth();
  const realAccount = user?.realAccount;

  return (
    <>
      <div className="grow z-50">
        <div className="flex">
          <div className="flex-none lg:w-20">
            <SideMenu />
          </div>

          <div className="flex-auto">
            <NewTradingHeader />

            <RenderIf isTrue={!realAccount}>
              <div className="relative">
                <div className="bg-gray-light-l dark:bg-black-dark-d left-20 z-40 w-full pt-4 px-4">
                  <div className="flex flex-col lg:flex-row gap-2 lg:gap-0 z-40 justify-between bg-gray-base-l dark:bg-main-light-1-d p-3 rounded-2xl text-xs lg:text-sm font-quicksand-semibold left-0 w-full">
                    <div className="flex flex-col lg:flex-row gap-2 lg:gap-0 items-center justify-start">
                      <p className="flex items-center lg:mr-4 flex-none w-full lg:w-auto">
                        <IconInfo className="text-red-base-l w-6 h-6 mr-1 flex-none" />{' '}
                        Waktu Kamu menggunakan PINA Trade tersisa
                      </p>

                      <div className="flex w-full lg:w-auto justify-between gap-2">
                        <CountDownExploreTrade />

                        <div className="flex items-center lg:hidden">
                          <Link
                            to="/onboarding/phone-number"
                            onClick={() => {
                              triggerSegmentEvent({
                                event: 'TR Become Member From Top Nav Clicked'
                              });
                              removeDarkMode();
                            }}
                            className="text-main-base-l bg-gray-base-l dark:bg-main-light-1-d py-2 px-2 border-2 border-main-base-l rounded-xl text-xs transition-all ease-linear duration-150 hover:opacity-75">
                            <span className="line-clamp-1">
                              Jadi Member PINA
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="flex-none lg:flex items-center mx-auto lg:mr-0 hidden">
                      <Link
                        to="/onboarding/phone-number"
                        onClick={() => {
                          triggerSegmentEvent({
                            event: 'TR Become Member From Top Nav Clicked'
                          });
                          removeDarkMode();
                        }}
                        className="text-main-base-l bg-gray-base-l dark:bg-main-light-1-d py-2 px-4 border-2 border-main-base-l rounded-xl text-xs transition-all ease-linear duration-150 hover:opacity-75">
                        Jadi Member PINA & Buat RDN
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </RenderIf>

            <main className='pb-20'>{children}</main>
          </div>
        </div>
      </div>

      <SettingsBar />
      <ModalTradingProvider />
      <LoginPinForm onSuccess={() => {}} />
    </>
  );
}

TradingLayout.propTypes = {
  children: PropTypes.element
};

export default TradingLayout;
