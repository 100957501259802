import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import InputText from 'components/input/input-text';
import IconClose from 'components/svg/icon-close';
import { useFormik } from 'formik';
import useToastr from 'hooks/useToastr';
import { useState } from 'react';
import { useUpdatePortfolioTermsMutation } from 'services/rtk-query/workspace';
import slugify from 'utils/formatter/slugify';
import { PortfolioTermsSchema } from './portfolio-terms.form.validation';
import Spinner from 'components/common/spinner';
import useGetErrorMessage from 'hooks/useGetErrorMessage';

function PortfolioTermsForm({ onClose = () => {}, tempValue = {}, columns = {}, id = '', onSuccess = () => {}}) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const [updatePortfolioTerms] = useUpdatePortfolioTermsMutation();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: tempValue?.title
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: PortfolioTermsSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        let payload = {};
        Object?.entries?.(columns || [])?.map((obj) => {
          if (tempValue.name != obj[0]) {
            payload[obj[0]] = { ...obj[1] };
          } else {
            let _newTerms = slugify(values.title);
            payload[_newTerms] = { name: _newTerms, title: values.title, items: tempValue?.items }
          }
        })

        await updatePortfolioTerms({ payload, id}).unwrap();
        onSuccess(payload);
        toastr.success('Portfolio Terms berhasil diupdate')
        onClose();
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
      <Card className="p-6 relative max-w-lg mx-auto dark:border dark:border-gray-base-d dark:text-black-light-1-d">
        <div className="mb-6">
          <h5 className="text-sm">Edit Portfolio Terms</h5>

          <button
            onClick={onClose}
            type="button"
            className="absolute right-4 top-4 hover:text-main-base-l dark:text-main-base-l">
            <IconClose />
          </button>
        </div>

        <InputText
          value={formik?.values?.title}
          name="title"
          placeholder="Ketik Nama Portfolio Terms"
          label=""
          className="text-sm"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <RenderIf isTrue={!formik?.isValid}>
          <div className="text-red-base-l font-quicksand-semibold py-3 text-xs rounded-lg pb-0">
            {Object.keys(formik?.errors)?.map((key, idx) => (
              <span key={key} className="flex items-center w-full">
                {idx === 0 && formik?.errors[key]}
              </span>
            ))}
          </div>
        </RenderIf>

        <div className="pt-4 mt-1 relative">
          <button
            type="submit"
            disabled={isLoading || !formik.isValid}
            className="bg-main-base-l disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d dark:text-black-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-64 mr-0 ml-auto flex items-center justify-center transition-all ease-in-out duration-100">
              {isLoading && <Spinner className='text-white -ml-1 mr-3 h-5 w-5' />}
              Simpan
          </button>
        </div>
      </Card>
    </form>
  );
}

PortfolioTermsForm.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  tempValue: PropTypes.object,
  columns: PropTypes.object,
  id: PropTypes.string,
};

export default PortfolioTermsForm;
