import PropTypes from 'prop-types';

import styles from './css/input-pin.module.css';

function InputPin({
  value,
  name,
  label,
  placeholder,
  className,
  autoComplete,
  disabled,
  onChange,
  onBlur,
  onKeyDown,
  type = 'password',
  inputStyle = 'circle',
  ...props
}) {
  return (
    <div className={`${className}`}>
      <div className={`${styles[`input-pin-${inputStyle}`]} ${value && styles['input-pin-filled']}`}>
        <span className="grow h-full">
          <input
            {...props}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur(e)}
            onKeyDown={(e) => onKeyDown(e)}
            className={`w-full block outline-none bg-transparent text-center h-full rounded-full`}
            autoComplete={'new-password'}
          />
        </span>
      </div>
    </div>
  );
}

InputPin.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  inputStyle: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.bool,
  disabled: PropTypes.bool
};

InputPin.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  autoComplete: true,
  disabled: false,
  className: 'text-sm'
};

export default InputPin;
