import PropTypes from 'prop-types';
import InputStock from 'components/input/input-stock';
import { useFormik } from 'formik';
import InputCounter from 'components/input/input-counter';
import { currency, generateOrderId } from 'utils/format';
import { marginPlusShare } from 'utils/formula/margin';
import RenderIf from 'components/basic/render-if';
import { useDispatch, useSelector } from 'react-redux';
import { useGetBuyingPowerQuery, useCreateAlgoBOTMutation, invalidateSmartOrderList } from 'services/rtk-query/trading';
import { useGetStockByCodeQuery } from 'services/rtk-query/stock';
import InputRadio from 'components/input/input-radio';
import { useEffect, useState } from 'react';
import InputCheckBox from 'components/input/input-check-box';
import { find, isEmpty, isEqual, range, uniqueId } from 'lodash';
import useToastr from 'hooks/useToastr';
import { SmartOrderFormValidation } from './smart-order.form.validation';
import { useGetPortfolioListQuery } from 'services/rtk-query/trade';
import ModalConfirmationSmartOrder from '../../_partials/modal-confirmation-smart-order';
import IconWarning from 'components/svg/icon-warning';
import InputDatePickerSimple from 'components/input/input-date-picker-simple';
import { addDays, subDays } from 'date-fns';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ModalAgreementSmartOrder from 'components/modal/template/modal-agreement-smart-order';
import { triggerSegmentEvent } from 'lib/segment';
import { dayjsLocal } from 'utils/formatter/date';
import { getYear } from 'date-fns';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import SimpleSpinner from 'components/common/spinner/spinner';
import { setQuickOrderSettingsConfirmation } from 'store/settings';
import IconSort from 'components/svg/icon-sort';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function SmartSellForm({
  onClose = () => {},
  onSuccess = () => {},
  onChangeSymbol = () => {},
  onChangeBotName = () => {},
  initialForms = {},
  symbol = '',
  defaultBotName = 'SmartBuy',
  disabledStock = false,
  formId = 'smart-order-form'
}) {
  const dispatch = useDispatch();
  const toastr = useToastr();
  const { user, dealerUseClientId } = useBrowserTabAuth();
  const username = user?.username;
  
  const quickOrderConfirmation = useSelector((state) => state?.settings?.quickOrderSettings?.confirmation);
  const { getErrorMessage } = useGetErrorMessage();

  const [isNotRealAccount, setIsNotRealAccount] = useState(false);
  const [openModalConfirmationSell, setOpenModalConfirmationSell] = useState(false);
  const [openModalAgreement, setOpenModalAgreement] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [tempSymbol, setTempSymbol] = useState(initialForms?.StockId || symbol);

  const { data: trading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { data: stockDetail } = useGetStockByCodeQuery(tempSymbol, false);
  const { data: portFolio } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const [createAlgoBOT] = useCreateAlgoBOTMutation();
  const [portfolio, setPortfolio] = useState(find(portFolio?.stock?.items, ['stID', tempSymbol]));

  const [tempTrading, setTempTrading] = useState(trading);

  const formik = useFormik({
    initialValues: {
      amount: 0,
      lotType: true,
      portfolio: initialForms?.portfolio || (portfolio?.net || 0) / 100,
      buyingPower: initialForms?.buyingPower || trading?.buyingpower || 0,
      BotName: initialForms?.BotName || defaultBotName,
      ClientId: dealerUseClientId || initialForms?.ClientId || username,
      ClientOrderId: initialForms?.ClientOrderId || generateOrderId(dealerUseClientId || username, 'SB', Date.now().toString().slice(7, 13)),
      StockId: initialForms?.StockId || symbol,
      BuySell: initialForms?.BuySell || 'B',
      Price: initialForms?.Price || stockDetail?.lastTradedPrice || 0,
      Rule: initialForms?.Rule || (defaultBotName === 'TakeProfit' ? '>=' : defaultBotName === 'StopLoss' ? '<=' : '<='),
      PriceRule: initialForms?.PriceRule || stockDetail?.lastTradedPrice || 0,
      Lot: initialForms?.Lot || 0,
      BotExpire: initialForms?.BotExpire || dayjsLocal().add(1, 'day').toDate(),
      FireAt: initialForms?.FireAt || 190,
      EntryBy: initialForms?.EntryBy || username,
      takeProfit: initialForms?.takeProfit || false,
      stopLoss: initialForms?.stopLoss || false,
      agreement: initialForms?.agreement || false,
      State: initialForms?.State || 1,
      initialPrice: stockDetail?.lastTradedPrice
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: SmartOrderFormValidation,
    onSubmit: (values) => {
      if (values.BotName === 'StopLoss') {
        handleStopLossOrder(values);   
      }

      if (values.BotName === 'TakeProfit') {
        handleTakeProfitOrder(values);   
      }
    }
  });

  const handleStopLossOrder = async (values) => {
    try {
      setIsSubmitting(true)
      let _clientOrderId = generateOrderId(dealerUseClientId || username, 'SL', Date.now().toString().slice(7, 13));
      let params = {
        BotName: `${values?.BotName}@${_clientOrderId}`,
        ClientId: dealerUseClientId || values?.ClientId,
        ClientOrderId: _clientOrderId,
        StockId: values?.StockId,
        BuySell: 'S',
        Price: values?.Price,
        Lot: values?.Lot,
        EntryBy: values?.EntryBy,
        State: values?.State,
        Rule: values?.Rule,
        FireAt: values?.FireAt,
        PriceFire: values?.PriceRule,
        BotExpire: dayjsLocal(values?.BotExpire).format('DD-MMM-YYYY') + ' 16:00:00',
      }

      if (values?.bestPrice) params.Price = `Q.${values?.StockId}.39`;

      if (values?.Rule === '>=') {
        params.Rule = `Q.${values?.StockId}.56${values?.Rule}${values?.PriceRule}`;
      }

      if (values?.Rule === '<=') {
        params.Rule = `Q.${values?.StockId}.56${values?.Rule}${values?.PriceRule}%26%26Q.${values?.StockId}.56>0`;
      }

      const { success, message } = await createAlgoBOT(params).unwrap();
      if (!success) throw new Error(message);

      toastr.success('Smart Order berhasil dibuat');
      formik.setFieldValue('initialPrice', stockDetail?.lastTradedPrice)
      setOpenModalConfirmationSell(false);
      onSuccess();
      triggerSegmentEvent({
        event: 'TR Smart Portfolio Created',
        properties: { mode: 'Stop Loss', stock_name: values?.StockId }
      });

      setTimeout(() => dispatch(invalidateSmartOrderList()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTakeProfitOrder = async (values) => {
    try {
      setIsSubmitting(true);
      let _clientOrderId = generateOrderId(dealerUseClientId || username, 'TP', Date.now().toString().slice(7, 13));
      let params = {
        BotName: `${values?.BotName}@${_clientOrderId}`,
        ClientId: dealerUseClientId || values?.ClientId,
        ClientOrderId: _clientOrderId,
        StockId: values?.StockId,
        BuySell: 'S',
        Price: values?.Price,
        Lot: values?.Lot,
        EntryBy: values?.EntryBy,
        State: values?.State,
        Rule: values?.Rule,
        FireAt: values?.FireAt,
        PriceFire: values?.PriceRule,
        BotExpire: dayjsLocal(values?.BotExpire).format('DD-MMM-YYYY') + ' 16:00:00',
      }

      if (values?.bestPrice) params.Price = `Q.${values?.StockId}.39`;

      if (values?.Rule === '>=') {
        params.Rule = `Q.${values?.StockId}.56${values?.Rule}${values?.PriceRule}`;
      }

      if (values?.Rule === '<=') {
        params.Rule = `Q.${values?.StockId}.56${values?.Rule}${values?.PriceRule}%26%26Q.${values?.StockId}.56>0`;
      }

      const { success, message } = await createAlgoBOT(params).unwrap();
      if (!success) throw new Error(message);

      toastr.success('Smart Order berhasil dibuat');
      formik.setFieldValue('initialPrice', stockDetail?.lastTradedPrice)

      triggerSegmentEvent({
        event: 'TR Smart Portfolio Created',
        properties: { mode: 'Take Profit', stock_name: values?.StockId }
      });
      setOpenModalConfirmationSell(false);
      onSuccess();
      setTimeout(() => dispatch(invalidateSmartOrderList()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();

    if (!user?.realAccount) return setIsNotRealAccount(true);

    if (isEmpty(errors)) {
      if (!quickOrderConfirmation) {
        return setOpenModalConfirmationSell(true);
      } else{
        return formik.submitForm();
      }
    }
  };

  const generateDefaultStocks = (BotName) => {
    let _stocks = [];
    if (['SmartSell', 'TakeProfit', 'StopLoss'].includes(BotName)) {
      portFolio?.stock?.items?.map((porto) => {
        _stocks.push({
          "code": porto?.stID,
          "name": porto?.sName,
        })
      })
    }

    return _stocks;
  }

  useEffect(() => {
    const _portfolio = find(portFolio?.stock?.items, ['stID', tempSymbol]);
    setPortfolio(_portfolio);

    if (stockDetail) {
      formik.setValues({
        ...formik.values,
        portfolio: (_portfolio?.net || 0) / 100,
        buyingPower: tempTrading?.buyingpower,
        initialPrice: stockDetail?.lastTradedPrice,
        StockId: tempSymbol,
        BotName: defaultBotName,
        Price: stockDetail?.lastTradedPrice,
        PriceRule: stockDetail?.lastTradedPrice,
      })

      setTimeout(() => formik.setErrors({}), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockDetail, defaultBotName, portFolio, tempTrading, tempSymbol]);

  useEffect(() => {
    if (symbol != tempSymbol) {
      setTempSymbol(symbol);
    }
  }, [symbol, tempSymbol]);

  useEffect(() => {
    if (!isEqual(tempTrading?.buyingpower, trading?.buyingpower)) {
      setTempTrading(trading);
    }
  }, [tempTrading, trading]);

  return (
    <div className="pb-[70px] mb-[90px]">
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small lg:max-h-[500px] lg:min-h-[500px] -mx-4 px-4 relative"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        >
        <form
          className=""
          autoComplete="off"
          id={formId}
          noValidate
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => e.key === 'Enter' && handleConfirmation()}
          >
          <RenderIf isTrue={['TakeProfit', 'StopLoss'].includes(formik.values.BotName)}>
            <div className='flex justify-between items-center'>
              <div className='flex-none'>
                <span className="text-2xs text-gray-light-0-d block font-quicksand-semibold">
                  Lot tersedia
                </span>
                <h5 className="mb-2 text-sm">
                  {currency(formik.values.portfolio || 0, { removeSymbol: true })} lot
                </h5>
              </div>

              <div className='flex-auto text-right'>
                <span className="text-2xs text-gray-light-0-d block font-quicksand-semibold">
                  Average Buy
                </span>
                <h5 className="mb-2 text-sm">
                  {currency((parseInt(portfolio?.avgPrice) || 0) || 0, { removeSymbol: true })}
                </h5>
              </div>
            </div>
          </RenderIf>

          <div className='relative z-20'>
            <InputStock
              size='small'
              disabled={disabledStock}
              value={formik.values.StockId}
              defaultStocks={generateDefaultStocks(formik.values.BotName)}
              onChange={({ code }) => code && onChangeSymbol(code)}
            />
          </div>

          <RenderIf isTrue={formik.values.BotName === 'TakeProfit'}>
            <h5 className='text-xs font-quicksand-semibold my-3'>Pengiriman Take Profit</h5>
          </RenderIf>

          <RenderIf isTrue={formik.values.BotName === 'StopLoss'}>
            <h5 className='text-xs font-quicksand-semibold my-3'>Pengiriman Stop Loss</h5>
          </RenderIf>

          <InputCounter
            isAutoFocus
            size='small'
            className='my-2'
            onBlur={formik.handleBlur}
            data-qa-id="txtStockBuyPriceField"
            value={formik.values.Price}
            name="Price"
            label={<span className='text-2xs text-gray-light-0-d'>Target Harga</span>}
            counterStep={marginPlusShare(formik.values.Price)}
            disabled={formik?.values?.bestPrice}
            onChange={(val) => {
              formik.setFieldValue('Price', val);
              if (['TakeProfit', 'StopLoss'].includes(formik.values.BotName)) {
                if (val >= (portfolio?.avgPrice || 0)) {
                  onChangeBotName('TakeProfit')
                } else {
                  onChangeBotName('StopLoss')
                }
              }

              setTimeout(() => formik.setTouched({ Price: true }), 100);
            }}
            error={formik.errors.Price ? formik.errors.Price : ''}
            showError={false}
          />

          <div className='flex justify-end'>
            <InputCheckBox
              id={`${uniqueId('bestPrice-')}`}
              value={formik?.values?.bestPrice}
              name="bestPrice"
              label={<span className='text-2xs font-quicksand-semibold text-black-base-l dark:text-black-light-1-d transform translate-y-[2px]'>Best Offer Price</span>}
              isError={formik?.errors?.bestPrice}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('Price', stockDetail?.lastTradedPrice)
              }}
              onBlur={formik.handleBlur}
              className='mb-0'
            />
          </div>

          <InputCounter
              size='small'
              className='my-2'
              onBlur={formik.handleBlur}
              data-qa-id="txtStockBuyLotField"
              value={formik.values.lotType ? formik.values.Lot : formik.values.amount}
              value2={formik.values.lotType ? formik.values.amount : formik.values.Lot}
              name="Lot"
              label={
                <span className='text-2xs text-gray-light-0-d'>
                  <button tabIndex={-1} onClick={() => formik.setFieldValue('lotType', !formik.values.lotType)} type='button' className='flex items-center hover:text-main-base-l'>
                    <IconSort className='h-4 w-4 mr-1 transform rotate-90 text-main-base-l' /> {formik.values.lotType ? 'Jumlah Lot' : 'Amount'}
                  </button>
                </span>}
              label2={<span className='text-2xs text-gray-light-0-d'>{formik.values.lotType ? 'Amount' : 'Lot'}</span>}
              showShortcut={formik.values.lotType}
              counterStep={formik.values.lotType ? 1 : formik.values.Price * 100}
              max={formik.values.lotType ? formik.values.portfolio || 0 : formik.values.portfolio * 100 * formik.values.Price}
              maxValue={formik.values.lotType ? formik.values.portfolio || 0 : formik.values.portfolio * 100 * formik.values.Price}
              onChange={(val) => {
                if (formik.values.lotType) {
                  formik.setValues({...formik.values, Lot: val, amount: Number(val) * formik.values.Price * 100 });
                } else {
                  formik.setValues({...formik.values, amount: val, Lot: Math.floor(Number(val) / (formik.values.Price * 100)) });
                }
                // setDefaultPercent(null);
                setTimeout(() => formik.setTouched({ Lot: true }), 100);
              }}
              error={formik.errors.Lot ? formik.errors.Lot : ''}
              showError={false}
            />



          <RenderIf isTrue={formik.values.BotName === 'TakeProfit'}>
            <h5 className='text-xs font-quicksand-semibold my-3'>Syarat Take Profit</h5>
          </RenderIf>

          <RenderIf isTrue={formik.values.BotName === 'StopLoss'}>
            <h5 className='text-xs font-quicksand-semibold my-3'>Syarat Stop Loss</h5>
          </RenderIf>

          <InputDatePickerSimple
            size='small'
            years={range(getYear(new Date()), getYear(new Date()) + 2, 1)}
            value={formik.values.BotExpire}
            name="BotExpire"
            format='dd/MM/yyyy'
            className='mb-2'
            label={<span className='text-2xs text-gray-light-0-d'>Berakhir Hingga</span>}
            placeholder='Pilih Expiry Date'
            onChange={(_date) => {
              formik.setFieldValue('BotExpire', _date);
            }}
            error={formik.errors.BotExpire ? formik.errors.BotExpire : ''}
            showError={false}
            includeDateIntervals={[ { start: subDays(new Date(), 1), end: addDays(new Date(), 365) }]}
          />

          <div className="grid grid-cols-12 gap-3 relative my-2">
            <div className="col-span-4">
              <InputRadio
                size='p-[5.5px] first:rounded-l-lg last:rounded-r-lg border'
                label={<span className='text-2xs text-gray-light-0-d'>Harga Mencapai</span>}
                value={formik.values.Rule}
                name="Rule"
                className='mb-0'
                options={[
                  {
                    value: '<=',
                    title: '',
                    node: <div className="text-xl font-quicksand-semibold leading-4">{'≤'}</div>
                  },
                  {
                    value: '>=',
                    title: '',
                    node: <div className="text-xl font-quicksand-semibold leading-4">{'≥'}</div>
                  }
                ]}
                onChange={formik.handleChange}
                error={formik.errors.Rule ? formik.errors.Rule : ''}
                showError={false}
              />
            </div>

            <div className='col-span-8'>
              <InputCounter
                size='small'
                onBlur={formik.handleBlur}
                data-qa-id="txtStockBuyPriceField"
                value={formik.values.PriceRule}
                name="PriceRule"
                label={<span className='text-2xs text-gray-light-0-d'>&nbsp;</span>}
                className='mt-0'
                counterStep={marginPlusShare(formik.values.PriceRule)}
                onChange={(val) => {
                  formik.setFieldValue('PriceRule', val);
                  setTimeout(() => formik.setTouched({ PriceRule: true }), 100);
                }}
                error={formik.errors.PriceRule ? formik.errors.PriceRule : ''}
                showError={false}
              />
            </div>
          </div>

          <p className='text-2xs'>Jika harga pasar <b>{formik.values.Rule === '<=' ? 'kurang': 'lebih'} dari atau sama dengan</b> <b>IDR&nbsp;{currency(formik.values.PriceRule, { removeSymbol: true})}</b>, order jual dikirim di harga <b>IDR&nbsp;{currency(formik.values.Price, { removeSymbol: true})}</b> sebanyak <b>{currency(formik.values.Lot, { removeSymbol: true})} Lot</b></p>

          <div className='sticky bottom-0 card-widget-gradient pt-4'>
            <InputCheckBox
              id={`${uniqueId('splitOrder-')}`}
              value={formik?.values?.agreement}
              name="agreement"
              className='mb-3'
              label={
                <span className="font-quicksand-regular text-2xs dark:text-black-light-1-d"> Saya menyetujui pembelian saham di halaman ini sesuai dengan{' '}
                  <a role='button' onClick={() => setOpenModalAgreement(true)} className="text-main-base-l font-quicksand-semibold">
                    Syarat dan Ketentuan.
                  </a>
                </span>
              }
              isError={formik?.errors?.agreement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <InputCheckBox
              id={`${uniqueId('confirmation-')}`}
              value={quickOrderConfirmation}
              name="confirmation"
              checked={quickOrderConfirmation}
              label={<span className='text-xs font-quicksand-regular text-black-base-l dark:text-black-light-1-d transform'>Confirm Order</span>}
              onChange={() => {
                dispatch(setQuickOrderSettingsConfirmation(!quickOrderConfirmation))
              }}
              onBlur={formik.handleBlur}
              className='mb-4'
            />

            <RenderIf isTrue={!formik?.isValid}>
              <div className="font-quicksand-semibold py-3 px-4 flex items-center text-red-base-l text-2xs rounded-xl bg-red-base-l bg-opacity-10">
                <IconWarning className="h-5 w-5 mr-1 flex-none" />
                {Object.keys(formik?.errors)?.map((key, idx) => (
                  <RenderIf key={key} isTrue={idx === 0}>
                    <span
                      key={key}
                      className="block w-full text-left">
                      {idx === 0 && formik?.errors[key]}
                    </span>
                  </RenderIf>
                ))}
              </div>
            </RenderIf>
          </div>
        </form>
      </OverlayScrollbarsComponent>


      <div className='absolute left-0 w-full bottom-0'>
        <div className='border-y my-2 py-2 dark:border-gray-base-d'>
          <div className="flex items-center py-1">
            <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
              Total Transaksi
            </span>
            <h5 className="flex-grow text-right text-sm">
              {currency(Number(formik.values.Lot) * Number(formik.values.Price) * 100 || 0)}
            </h5>
          </div>

          <RenderIf isTrue={Number(formik.values.Lot) * Number(formik.values.Price - (portfolio?.avgPrice || 0)) * 100 >= 0}>
            <div className="flex items-center py-1">
              <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
                Take Profit at
              </span>
              <h5 className="flex-grow text-right text-xs text-green-base-l">
                {currency(Number(formik.values.Lot) * Number(formik.values.Price - (portfolio?.avgPrice || 0)) * 100, { removeSymbol : true })}
              </h5>
            </div>
          </RenderIf>

          <RenderIf isTrue={Number(formik.values.Lot) * Number(formik.values.Price - (portfolio?.avgPrice || 0)) * 100 < 0}>
            <div className="flex items-center py-1">
              <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
                Stop Loss at
              </span>
              <h5 className="flex-grow text-right text-xs text-red-base-l">
                {currency(Number(formik.values.Lot) * Number(formik.values.Price - (portfolio?.avgPrice || 0)) * 100, { removeSymbol : true })}
              </h5>
            </div>
          </RenderIf>
        </div>

        <div className="grid grid-cols-2 gap-6 p-3 px-5">
          <button
            data-qa-id="btnStockBuySubmit"
            onClick={onClose}
            type="button"
            className={`card-widget-gradient text-main-base-l border-2 border-main-base-l py-3 px-3 hover:opacity-75 text-xs font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100`}>
            Kembali
          </button>

          <button
            data-qa-id="btnStockBuySubmit"
            disabled={!formik.isValid && user?.realAccount || isSubmitting}
            onClick={handleConfirmation}
            type="button"
            className={`bg-green-base-d text-white disabled:bg-opacity-[30%] hover:bg-opacity-75 py-3 px-3 text-xs font-quicksand-semibold rounded-lg w-full flex items-center justify-center transition-all ease-in-out duration-100`}>
            {isSubmitting && <SimpleSpinner />}
            Selanjutnya
          </button>
        </div>
      </div>

      <RenderIf isTrue={isNotRealAccount}>
        <Modal onClose={() => setIsNotRealAccount(false)}>
          <ModalExploreExpired
            showClose
            eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
            title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
            description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
            onClose={() => setIsNotRealAccount(false)}
          />
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openModalAgreement}>
        <Modal size='large' onClose={() => setOpenModalAgreement(false)}>
          <ModalAgreementSmartOrder onClose={() => setOpenModalAgreement(false)} />
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openModalConfirmationSell}>
        <Modal onClose={() => setOpenModalConfirmationSell(false)}>
          <ModalConfirmationSmartOrder
            isSubmitting={isSubmitting}
            data={formik?.values}
            onSubmit={() => formik.submitForm()}
            onClose={() => setOpenModalConfirmationSell(false)}
          />
        </Modal>
      </RenderIf>
    </div>
  );
}

SmartSellForm.propTypes = {
  onChangeStock: PropTypes.func,
  symbol: PropTypes.string,
  defaultBotName: PropTypes.string,
  formId: PropTypes.string,
  modes: PropTypes.array,
  isSticky: PropTypes.bool,
  disabledStock: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onChangeSymbol: PropTypes.func,
  onChangeBotName: PropTypes.func,
  initialForms: PropTypes.object,
};

export default SmartSellForm;
