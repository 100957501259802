import PropTypes from 'prop-types';

function IconChevronSingle({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.673 3.33131C17.109 3.77305 17.109 4.48925 16.673 4.93099L9.67042 12.0254L16.2126 19.0952C16.6343 19.551 16.6115 20.2668 16.1617 20.6941C15.7118 21.1213 15.0053 21.0982 14.5835 20.6425L7.30197 12.7736C6.88982 12.3282 6.9009 11.6319 7.32702 11.2002L15.094 3.33131C15.53 2.88956 16.237 2.88956 16.673 3.33131Z"
      />
    </svg>
  );
}

IconChevronSingle.propTypes = {
  className: PropTypes.string
};

export default IconChevronSingle;
