import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import EmptyPP from '../../../assets/images/dummy/photo-profile.webp';
import RenderIf from 'components/basic/render-if';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import { NavLink, useLocation } from 'react-router-dom';
import { useGetUserProfileQuery } from 'services/rtk-query/user';
import SkeletonTheme from 'components/basic/skeleton-theme';
import IconCheck from 'components/svg/icon-check';
import { useEffect, useState } from 'react';
import { useGetImageQuery } from 'services/rtk-query/image';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function SectionProfileTrading({ className }) {
  const { pathname } = useLocation();
  const { user } = useBrowserTabAuth();
  const { data: trading, isLoading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { data: profile } = useGetUserProfileQuery();

  const [avatarUrl, setAvatarUrl] = useState(EmptyPP);
  const AvPP = (user?.firstName?.charAt(0) || '');

  const { data: avatarURL} = useGetImageQuery(user?.imgAvatarUrl?.replace('BASE_URL/images/', ''));

  useEffect(() => {
    if (avatarURL) setAvatarUrl(avatarURL);
  }, [avatarURL]);

  return (
    <Card className={`p-5 dark:text-black-light-1-d ${className}`}>
      <div className="flex items-center">
        <RenderIf isTrue={avatarUrl}>
          <img
            className="w-12 h-12 rounded-full mr-3"
            src={avatarUrl}
            alt="photo profil"
          />
        </RenderIf>

        <RenderIf isTrue={!avatarUrl}>
          <div
            style={{ minHeight: '48px', minWidth: '48px' }}
            className="rounded-full mr-3 bg-orange-1 flex items-center justify-center">
            <span className="font-quicksand-bold text-lg text-white">{AvPP || 'A'}</span>
          </div>
        </RenderIf>

        <div className="flex items-center justify-between w-full">
          <div>
            <h5 className="capitalize text-base">
              {user?.firstName
                ? `${user?.firstName?.toLowerCase() || ''} ${
                    user?.lastName?.toLowerCase() || ''
                  }`
                : 'Anonymouse'}
            </h5>
            <RenderIf isTrue={user?.realAccount}>
              <span className="flex items-center text-main-base-l font-quicksand-regular text-xs">
                <IconCheck className='h-4 w-4 mr-1'/> Terverifikasi
              </span>
            </RenderIf>

            <RenderIf isTrue={!user?.realAccount}>
              <span className="flex items-center text-gray-light-0-d font-quicksand-regular text-xs">
                Belum Terverifikasi
              </span>
            </RenderIf>
          </div>
        </div>
      </div>

      <RenderIf isTrue={user?.realAccount}>
        <div className={`border-y dark:border-gray-base-d py-2 mt-5 -mx-4 px-4 ${pathname === '/profile' && 'border-b-0 pb-0'}`}>
          <div className="grid grid-cols-2 gap-2 text-sm font-quicksand-semibold">
            <div className="col-span-1 font-quicksand-regular text-black-light-0-d">Client Id</div>
            <span className="text-right">
              {isLoading ? <SkeletonTheme height="1rem" width="70px" borderRadius="8px"/> : trading?.clientId}
            </span>
            <div className="col-span-1 font-quicksand-regular text-black-light-0-d">SRE</div>
            <span className="text-right">
              {isLoading ? <SkeletonTheme height="1rem" width="100px" borderRadius="8px"/> : profile?.sre}
            </span>
            <div className="col-span-1 font-quicksand-regular text-black-light-0-d">SID</div>
            <span className="text-right">
              {isLoading ? <SkeletonTheme height="1rem" width="100px" borderRadius="8px"/> : profile?.sid}
            </span>
          </div>
        </div>
      </RenderIf>

      {
        pathname != '/profile' &&
        <NavLink
          to="/profile"
          className="card-widget-gradient dark:text-main-base-l border-main-base-l border mt-4 text-center p-3 text-xs font-quicksand-semibold rounded-xl transition-all ease-in-out duration-100 block text-gray-light-0-d">
          Atur Profile
        </NavLink>
      }
    </Card>
  );
}

SectionProfileTrading.propTypes = {
  className: PropTypes.string
};

export default SectionProfileTrading;
