// eslint-disable-next-line react/prop-types
function IconImageRemove({ className }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.724 21.7245C22.2447 21.2038 23.0889 21.2038 23.6096 21.7245L25.3335 23.4484L27.0574 21.7245C27.5781 21.2038 28.4223 21.2038 28.943 21.7245C29.4637 22.2452 29.4637 23.0894 28.943 23.6101L27.2191 25.334L28.943 27.0578C29.4637 27.5785 29.4637 28.4228 28.943 28.9435C28.4223 29.4642 27.5781 29.4642 27.0574 28.9435L25.3335 27.2196L23.6096 28.9435C23.0889 29.4642 22.2447 29.4642 21.724 28.9435C21.2033 28.4228 21.2033 27.5785 21.724 27.0578L23.4479 25.334L21.724 23.6101C21.2033 23.0894 21.2033 22.2452 21.724 21.7245Z"
      />
      <path d="M12 14.6673C13.4728 14.6673 14.6667 13.4734 14.6667 12.0007C14.6667 10.5279 13.4728 9.33398 12 9.33398C10.5272 9.33398 9.33333 10.5279 9.33333 12.0007C9.33333 13.4734 10.5272 14.6673 12 14.6673Z" />
      <path d="M19.8798 17.3508C19.9831 17.3232 20.0916 17.3209 20.196 17.3441C20.2097 17.3471 20.2863 17.364 20.6257 17.5767C21.2497 17.9676 22.0725 17.7786 22.4635 17.1546C22.8544 16.5305 22.6654 15.7077 22.0414 15.3168C21.6718 15.0853 21.2659 14.8499 20.7734 14.7407C20.2513 14.6249 19.709 14.6364 19.1922 14.7743C18.5989 14.9326 18.0848 15.3015 17.5724 15.669L17.4483 15.758L14.1074 18.1433C13.4686 18.5995 13.3893 18.636 13.3351 18.6527C13.2436 18.6808 13.1471 18.6889 13.0522 18.6764C12.9959 18.669 12.9118 18.6461 12.2059 18.3026L11.5014 17.9598L11.3906 17.9056C10.9257 17.6783 10.4582 17.4497 9.94973 17.3724C9.50589 17.3049 9.05299 17.3278 8.61821 17.4396C8.12011 17.5677 7.67799 17.8423 7.2384 18.1152L7.13354 18.1802L4.6323 19.7268C4.00598 20.1141 3.8122 20.9358 4.19947 21.5621C4.58674 22.1884 5.40842 22.3822 6.03474 21.9949L8.53598 20.4483C9.156 20.065 9.23078 20.0355 9.28254 20.0222C9.3695 19.9998 9.46008 19.9953 9.54885 20.0088C9.60169 20.0168 9.67905 20.0386 10.3345 20.3576L11.039 20.7004L11.1587 20.7588C11.6579 21.003 12.1597 21.2485 12.7038 21.3202C13.1784 21.3827 13.6609 21.3423 14.1185 21.2017C14.6431 21.0404 15.0971 20.715 15.5487 20.3911L15.657 20.3136L18.9978 17.9282C19.7265 17.4079 19.8191 17.367 19.8798 17.3508Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8096 4H18.1904C19.6355 3.99999 20.801 3.99998 21.7448 4.07709C22.7166 4.15649 23.5702 4.32424 24.3599 4.72662C25.6144 5.36578 26.6342 6.38565 27.2734 7.64006C27.6758 8.42979 27.8435 9.28337 27.9229 10.2552C28 11.199 28 12.3645 28 13.8096V16C28 16.7364 27.403 17.3333 26.6667 17.3333C25.9303 17.3333 25.3333 16.7364 25.3333 16V13.8667C25.3333 12.3512 25.3323 11.2948 25.2651 10.4723C25.1992 9.66543 25.0763 9.20185 24.8974 8.85071C24.5139 8.09806 23.9019 7.48614 23.1493 7.10264C22.7982 6.92373 22.3346 6.80083 21.5277 6.7349C20.7052 6.6677 19.6488 6.66667 18.1333 6.66667H13.8667C12.3512 6.66667 11.2948 6.6677 10.4723 6.7349C9.66543 6.80083 9.20185 6.92373 8.85071 7.10264C8.09806 7.48614 7.48614 8.09806 7.10264 8.85071C6.92373 9.20185 6.80083 9.66543 6.7349 10.4723C6.6677 11.2948 6.66667 12.3512 6.66667 13.8667V18.1333C6.66667 19.6488 6.6677 20.7052 6.7349 21.5277C6.80083 22.3346 6.92373 22.7982 7.10264 23.1493C7.48614 23.9019 8.09806 24.5139 8.85071 24.8974C9.20185 25.0763 9.66543 25.1992 10.4723 25.2651C11.2948 25.3323 12.3512 25.3333 13.8667 25.3333H16C16.7364 25.3333 17.3333 25.9303 17.3333 26.6667C17.3333 27.403 16.7364 28 16 28H13.8096C12.3645 28 11.199 28 10.2552 27.9229C9.28337 27.8435 8.42979 27.6758 7.64006 27.2734C6.38565 26.6342 5.36578 25.6144 4.72662 24.3599C4.32424 23.5702 4.15649 22.7166 4.07709 21.7448C3.99998 20.801 3.99999 19.6355 4 18.1904V13.8096C3.99999 12.3645 3.99998 11.199 4.07709 10.2552C4.15649 9.28337 4.32424 8.42979 4.72662 7.64006C5.36578 6.38565 6.38565 5.36578 7.64006 4.72662C8.42979 4.32424 9.28337 4.15649 10.2552 4.07709C11.199 3.99998 12.3645 3.99999 13.8096 4Z"
      />
    </svg>
  );
}

export default IconImageRemove;
