import PropTypes from 'prop-types';

function IconChevronDouble({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.673 3.33131C13.109 3.77305 13.109 4.48925 12.673 4.93099L5.67042 12.0254L12.2126 19.0952C12.6343 19.551 12.6115 20.2668 12.1617 20.6941C11.7118 21.1213 11.0053 21.0982 10.5835 20.6425L3.30197 12.7736C2.88982 12.3282 2.9009 11.6319 3.32702 11.2002L11.094 3.33131C11.53 2.88956 12.237 2.88956 12.673 3.33131Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.673 3.33131C20.109 3.77305 20.109 4.48925 19.673 4.93099L12.6704 12.0254L19.2126 19.0952C19.6343 19.551 19.6115 20.2668 19.1617 20.6941C18.7118 21.1213 18.0053 21.0982 17.5835 20.6425L10.302 12.7736C9.88982 12.3282 9.9009 11.6319 10.327 11.2002L18.094 3.33131C18.53 2.88956 19.237 2.88956 19.673 3.33131Z"
      />
    </svg>
  );
}

IconChevronDouble.propTypes = {
  className: PropTypes.string
};

export default IconChevronDouble;
