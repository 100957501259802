import Card from 'components/basic/card';

function SectionDeviceSetting() {
  return (
    <Card className="p-6">
      <h5 className="mb-5 text-lg">Perangkat yang Terhubung</h5>
    </Card>
  );
}

export default SectionDeviceSetting;
