import ActionButton from 'components/basic/action-button';
import RenderIf from 'components/basic/render-if';
import BlinkText from 'components/basic/text-blink';
import Tooltip from 'components/basic/tooltip';
import SparkLineChart from 'components/chart/spark-line-chart';
import InputTextArea from 'components/input/input-text-area';
import IconBuy from 'components/svg/icon-buy';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import IconFile from 'components/svg/icon-file';
import IconNotes from 'components/svg/icon-notes';
import IconSearchPage from 'components/svg/icon-search-page';
import IconStar from 'components/svg/icon-star';
import IconStarDelete from 'components/svg/icon-star-delete';
import { useCompareStock } from 'hooks/useCompareStock';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import useToastr from 'hooks/useToastr';
import { triggerSegmentEvent } from 'lib/segment';
import { max, min } from 'lodash';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useGetStockByCodeQuery, useGetStockInfoByCodeQuery, useLazyGetStockChartByCodeQuery } from 'services/rtk-query/stock';
import { useGetIntraDayMinuteOHLCQuery } from 'services/rtk-query/trade';
import { useCreateWatchlistMutation, useDeleteWatchlistMutation, useGetWatchlistQuery, useUpdateWatchlistNotesByIdMutation } from 'services/rtk-query/watchlist';
import { currency, formatIndonesianKMBT } from 'utils/format';
import { dayjsLocal } from 'utils/formatter/date';
import { checkCodeNotation } from 'utils/stock/notation';
import { getStockRemarkSymbol } from 'utils/stock/remark';

function StockLogo({ code }) {
  const { data: stockDetail } = useGetStockByCodeQuery(code);

  return (
    <>
      <RenderIf isTrue={!stockDetail?.logo}>
        <div
          className="rounded-full mr-3 flex-none bg-gray-light-l border text-black-base-d bg-opacity-50 text-sm flex items-center justify-center font-quicksand-bold h-[32px] w-[32px]">
          {code?.charAt(0)}
        </div>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.logo}>
        <img
          className="rounded-full mr-3 flex-none h-[32px] w-[32px]"
          src={stockDetail?.logo}
        />
      </RenderIf>
    </>
  )
}

StockLogo.propTypes = {
  code: PropTypes.string,
};

function PriceItem({ code, tempPrice, isRealtime = true }) {
  const { stockDetail, isLoadingStock } = useRealtimeStock(code, isRealtime);
  const [tempStockDetail, setTempStockDetail] = useState(null);
  const [toggleActive, setToggleActive] = useState(false);
  
  useEffect(() => {
    if (Number(tempStockDetail?.lastTradedPrice) != Number(stockDetail?.lastTradedPrice)) {
      setTempStockDetail(stockDetail);
      setToggleActive(tempStockDetail);
    }
  }, [stockDetail, tempStockDetail]);

  return (
    <div
      className={`col-span-5 text-right ${toggleActive && 'animate-blink'}`}
      onAnimationEnd={() => { setToggleActive(false)}}>
      <h5 className="text-xs font-quicksand-semibold dark:text-black-light-1-d">
        {currency(stockDetail?.lastTradedPrice || tempPrice, { removeSymbol: true })}
      </h5>

      <RenderIf isTrue={stockDetail?.score?.type === 'plus'}>
        <p className="text-2xs text-green-base-l">
          +{currency(stockDetail?.score?.diff || 0, { removeSymbol: true })} ({parseFloat(stockDetail?.score?.value || 0).toFixed(2)}%)
        </p>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.score?.type === 'minus'}>
        <p className="text-2xs text-red-base-l">
          {currency(stockDetail?.score?.diff || 0, { removeSymbol: true })} ({parseFloat(stockDetail?.score?.value || 0).toFixed(2)}%)
        </p>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.score?.type === 'neutral' || isLoadingStock}>
        <p className="text-2xs text-gray-light-0-d dark:text-black-light-1-d">0 (0,00%)</p>
      </RenderIf>
    </div>
  )
}

PriceItem.propTypes = {
  code: PropTypes.string,
  isRealtime: PropTypes.bool,
  tempPrice: PropTypes.number,
};

function Chart({ code }) {
  const { data, isFetching: loadingIntraDay } = useGetIntraDayMinuteOHLCQuery(code);
  const [getStockChartByCode] = useLazyGetStockChartByCodeQuery();

  const [series, setSeries] = useState([]);
  const [colors, setColors] = useState([]);
  const [annotations, setAnnotations] = useState({});
  const [maxY, setMaxY] = useState(0);
  const [minY, setMinY] = useState(0);
  const { stockDetail } = useRealtimeStock(code);

  useEffect(() => {
    if (data?.length > 0 && stockDetail) {
      let _prices = [];
      data?.map((item) => _prices.push(item?.Close))

      setMaxY(max([..._prices, stockDetail?.previousLastTradedPrice]));
      setMinY(min([..._prices, stockDetail?.previousLastTradedPrice]));

      if (_prices?.length < 190) {
        for (let index = 0; index < 90; index++) {
          _prices.push(null)
        }  
      }

      setSeries([{ data: _prices }]);
      setAnnotations({
        position: "front",
        yaxis: [
          {
            y: stockDetail?.previousLastTradedPrice,
            strokeDashArray: 3,
            borderColor: '#AB9753',
            fillColor: '#AB9753',
            width: '100%',
            label: {
              offsetX: 0,
              offsetY: 6,
              show: true,
              borderWidth: 0,
              position: "right",
              style: {
                color: '#AB9753',
                background: "transparent",
                fontFamily: "QuickSandSemiBold",
                fontSize: "10px",
                padding: {
                  left: 2,
                  right: 2,
                  top: 4,
                  bottom: 2,
                },
              },
            },
          },
        ]
      });
    }
  }, [data, stockDetail]);

  useEffect(() => {
    if (stockDetail?.score?.type === 'plus') {
      setColors(['#7BCC29']);
    }

    if (stockDetail?.score?.type === 'minus') {
      setColors(['#F16528']);
    }

    if (stockDetail?.score?.type === 'neutral') {
      setColors(['#9E9E9E']);
    }
  }, [stockDetail])

  useEffect(() => {
    const fetch1MData = async () => {
      const { data: data1M } = await getStockChartByCode({ code: code, query: {
        startDate: dayjsLocal().subtract(1, 'month').format('MM/DD/YYYY'),
        endDate: dayjsLocal().subtract(1, 'day').format('MM/DD/YYYY'),
        dayRecord: 'daily'
      }});

      let _prices = [];
      data1M?.map((item) => _prices.push(item?.closePrice));

      setMaxY(max([..._prices, stockDetail?.previousLastTradedPrice]));
      setMinY(min([..._prices, stockDetail?.previousLastTradedPrice]));
      
      setSeries([{ data: _prices }]);
      setAnnotations({
        position: "front",
        yaxis: [
          {
            y: stockDetail?.previousLastTradedPrice,
            strokeDashArray: 3,
            borderColor: '#AB9753',
            fillColor: '#AB9753',
            width: '100%',
            label: {
              offsetX: 0,
              offsetY: 6,
              show: true,
              borderWidth: 0,
              position: "right",
              style: {
                color: '#AB9753',
                background: "transparent",
                fontFamily: "QuickSandSemiBold",
                fontSize: "10px",
                padding: {
                  left: 2,
                  right: 2,
                  top: 4,
                  bottom: 2,
                },
              },
            },
          },
        ]
      });
    }

    if (!loadingIntraDay && data?.length === 0 && stockDetail) {
      fetch1MData();
    }
  }, [loadingIntraDay, data, code, getStockChartByCode, stockDetail])
  
  return (
    <div className='grid grid-cols-2 pb-2 gap-4'>
      <div className='flex items-center'>
        <div className='w-full'>
          <div className='flex justify-between text-2xs'>
            <span className='text-gray-light-0-d dark:text-black-light-0-d'>Freq</span>
            <span className='font-quicksand-semibold dark:text-black-light-1-d'>
              <BlinkText value={currency(stockDetail?.frequency || 0, { removeSymbol : true })} />
            </span>
          </div>

          <div className='flex justify-between text-2xs'>
            <span className='text-gray-light-0-d dark:text-black-light-0-d'>Val</span>
            <span className='font-quicksand-semibold dark:text-black-light-1-d'>
              <BlinkText value={formatIndonesianKMBT(stockDetail?.tradableValue || 0)} />
            </span>
          </div>
        </div>
      </div>
      <div className='h-10'>
        <SparkLineChart series={series} colors={colors} annotations={annotations} min={minY} max={maxY} />
      </div>
    </div>
  )
}

Chart.propTypes = {
  code: PropTypes.string,
};

function StockRemark ({ symbol, isZoomOut }) {
  const { data: stockInfo, isLoading: isLoadingStockInfo } = useGetStockInfoByCodeQuery(symbol, { refetchOnMountOrArgChange: false });

  return (
    <RenderIf
      isTrue={(getStockRemarkSymbol(stockInfo?.remarks2) || [])?.length > 0 && !isLoadingStockInfo}>
      <div className="flex items-center font-quicksand-bold text-2xs p-1 px-2 rounded-md">
        {(getStockRemarkSymbol(stockInfo?.remarks2) || [])?.map((item, key) => (
          <div key={key} className="flex items-center">
            <RenderIf isTrue={key === 0}>
              <span className="w-2 h-2 bg-orange-1 rounded-full mr-[2px]"></span>
            </RenderIf>
            
            <Tooltip
              isZoomOut={isZoomOut}
              tooltipHTML={<p className="text-2xs max-w-[250px]">{checkCodeNotation(item)}</p>}
              >
              <span className="text-black-base-l dark:text-black-light-1-d">{item}</span>
            </Tooltip>
          </div>
        ))}
      </div>
    </RenderIf>
  )
}

function CardStockItem({
  code = '',
  name = '',
  remarks = '',
  isLastItem = false,
  isActive = false,
  isZoomOut = false,
  isRealtime = true,
  onClick = null,
  onClickBuy = null,
  onBlur = null,
  isShowChart = false,
  actionButton = true,
  id = '',
  showPriceStock = true,
  actions = [],
  ...props
}) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { navigate, customNavigate } = useCustomNavigate();
  const { data: watchlist } = useGetWatchlistQuery();
  const { addToCompareStock } = useCompareStock();

  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();
  const [updateWatchlistNotesById] = useUpdateWatchlistNotesByIdMutation();
  
  const [showNotes, setShowNotes] = useState(false);
  const [defaultRemarks, setDefaultRemarks] = useState(remarks);
  const [clicked, setClicked] = useState(false);

  const actionsList = actions?.length > 0 ? actions : [
    {
      title: 'Beli',
      children: (
        <IconBuy className="h-4 w-4" />
      ),
      onClick: () => {
        if (onClickBuy) {
          onClickBuy(code);
        } else {
          customNavigate({ query: { 'simple-order': code }});
        }
      }
    },
    {
      title: 'Stock Detail',
      children: <IconSearchPage className="h-4 w-4" />,
      onClick: () => {
        triggerSegmentEvent({ event: 'TR Search Feature Clicked' });
        customNavigate({ query: { 'stock-detail': code }});
      }
    },
    {
      title: 'Bandingkan',
      children: <IconCompare className="h-4 w-4" />,
      onClick: () => {
        triggerSegmentEvent({ event: 'TR Compare Stock Clicked' });
        addToCompareStock({ code: code, name: name }, () => {
          navigate(`/compare/fundamental`);
        })
      }
    },
    {
      title: remarks ? 'Edit Catatan' : 'Tulis Catatan',
      children: (
        <IconFile className="h-4 w-4" />
      ),
      onClick: () => {
        setShowNotes(!showNotes);
      }
    },
    {
      title: 'Trading View',
      children: <IconChartCandleStick className="h-4 w-4" />,
      onClick: () => {
        customNavigate({ query: { 'advanced-chart': code }});
      }
    },
    {
      title: `${watchlist?.find((w) => w?.code === code) ? 'Hapus Watchlist' : 'Tambahkan ke Watchlist'}`,
      children: watchlist?.find((w) => w?.code === code) ? <IconStarDelete className="h-4 w-4" /> : <IconStar className="h-4 w-4" />,
      onClick: async () => {
        triggerSegmentEvent({ event: 'TR Watchlist Clicked' });
        if (watchlist?.find((w) => w?.code === code)) {
          try {
            await deleteWatchlist({ stockCode: code }).unwrap();
            toastr.success(`${code} berhasil dihapus dari watchlist`);
          } catch (error) {
            getErrorMessage(error);
          }
        } else {
          try {
            await createWatchlist({ stockCode: code, type: 'stock'}).unwrap();
            toastr.success(`${code} berhasil ditambahkan kedalam watchlist`);
          } catch (error) {
            getErrorMessage(error);
          }
        }
      }
    }
  ]

  const handleSubmitNotes = async () => {
    try {
      await updateWatchlistNotesById({ id: id, body: { remarks: defaultRemarks } }).unwrap();
      toastr.success('Berhasil menambahkan notes');
    } catch (error) {
      getErrorMessage(error);
    }
  }

  useEffect(() => {
    setDefaultRemarks(remarks);
  }, [remarks]);

  const Wrapper = ({ isLink = true, children }) => {

    if (!isLink) {
      return (
        <button
          type='button'
          data-qa-id={props?.['data-link-qa-id']}
          onMouseLeave={() => setClicked(false)}
          onClick={() => {
            customNavigate({ query: { 'stock-detail': code }});
            setClicked(true)
          }}
          className={`block rounded-xl px-4 transition-all ease-linear duration-200 ${clicked && 'pb-5'} ${actionButton && 'group-hover:pb-5'}`}>
            <RenderIf isTrue={isActive}>
              <span className='absolute left-0 top-0 h-full w-[5px] rounded-r-xl bg-main-base-l'></span>
            </RenderIf>
          {children}
        </button>
      );
    }

    return (
      <div className="relative group">
        <RenderIf isTrue={actionButton}>
          <ActionButton actions={actionsList} isZoomOut={isZoomOut} position='right-1/2 transform translate-x-1/2 -bottom-3'/>
        </RenderIf>

        <button
          type='button'
          data-qa-id={props?.['data-link-qa-id']}
          onMouseLeave={() => setClicked(false)}
          onClick={() => {
            setClicked(true);
            onClick(code);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setClicked(true);
              onClick(code);
            }
          }}
          className={`block rounded-xl px-4 transition-all ease-linear duration-200 text-left w-full ${clicked && 'pb-5'} ${actionButton && 'group-hover:pb-5'}`}>
          {children}
        </button>
      </div>
    );
  };
  
  Wrapper.propTypes = {
    isLink: PropTypes.any,
    children: PropTypes.any,
  };
  

  return (
    <div onBlur={onBlur} className={`relative card-widget-gradient dark:hover:bg-black-dark-d hover:drop-shadow-md hover:z-20 hover:relative hover:bg-gray-light-l transition-all ease-linear duration-200 ${isActive && 'drop-shadow-md relative z-10'} ${remarks || showNotes ? 'pb-2' : 'py-0'} ${ !isLastItem ? 'border-b dark:border-gray-base-d/10' : 'rounded-b-xl' }`}>

      <Wrapper isLink={onClick}>
        <div>
          <div
            className={`flex items-center py-[10px]`}>
            <StockLogo code={code}/>

            <div className={`grid grid-cols-12 flex-grow`}>
              <div className="col-span-7">
                <h5 className="text-xs text-black-dark-d dark:text-black-light-1-d flex items-center">
                  <span>{code}</span>
                  <StockRemark symbol={code} isZoomOut={isZoomOut} />
                </h5>
                <p className="text-2xs text-gray-light-0-d dark:text-black-light-0-d line-clamp-1">{name}</p>
                
              </div>
              <RenderIf isTrue={showPriceStock}>
                <PriceItem code={code} tempPrice={Number(props?.value)} isRealtime={isRealtime} />
              </RenderIf>
            </div>
          </div>

          <RenderIf isTrue={isShowChart}>
            <Chart code={code} />
          </RenderIf>
        </div>
      </Wrapper>

      <RenderIf isTrue={remarks}>
        <div className={`px-4 mt-2`}>
          <div className='text-2xs flex p-2 bg-main-base-l text-main-base-l bg-opacity-10 rounded-md overflow-hidden break-words'>
            <IconNotes className='h-3 w-3 mr-1 flex-none'/>
            {remarks}
          </div>
        </div>
      </RenderIf>

      <RenderIf isTrue={showNotes}>
        <div className={`px-4 transition-all ease-in-out duration-200 overflow-hidden ${showNotes ? 'max-h-60 mt-2' : 'max-h-0'}`}>
          <InputTextArea rows={6} value={defaultRemarks} onChange={(e) => { setDefaultRemarks(e.target.value)}} />
          <div className='grid grid-cols-2 gap-2 mt-3'>
            <button type='button' className='bg-red-base-l text-white dark:text-black-base-d p-3 hover:bg-opacity-75 ease-linear duration-100 transition-all rounded-md text-xs font-quicksand-semibold' onClick={() => setShowNotes(false)}>Batalkan</button>
            <button type='button' className='bg-main-base-l text-white dark:text-black-base-d p-3 hover:bg-opacity-75 ease-linear duration-100 transition-all rounded-md text-xs font-quicksand-semibold' onClick={handleSubmitNotes}>Simpan</button>
          </div>
        </div>
      </RenderIf>

      <RenderIf isTrue={isActive}>
        <span className='absolute left-0 top-0 h-full w-[5px] rounded-r-xl bg-main-base-l z-10'></span>
      </RenderIf>
    </div>
  );
}

CardStockItem.propTypes = {
  code: PropTypes.string,
  name: PropTypes.string,
  remarks: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  previousLastTradedPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  score: PropTypes.object,
  isLastItem: PropTypes.bool,
  isActive: PropTypes.any,
  isRealtime: PropTypes.bool,
  isShowChart: PropTypes.any,
  onClick: PropTypes.func,
  onClickBuy: PropTypes.func,
  onBlur: PropTypes.func,
  actionButton: PropTypes.bool,
  actions: PropTypes.array,
  id: PropTypes.string,
  'data-link-qa-id': PropTypes.string,
  'data-img-qa-id': PropTypes.string
};

export default memo(CardStockItem);
