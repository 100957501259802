import RenderIf from 'components/basic/render-if';
import PropTypes from 'prop-types';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { currency, formatIndonesianKMBT } from 'utils/format';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { useEffect, useState } from 'react';

function TVLegend({ symbol }) {
  const { stockDetail, isLoadingStock: isLoading  } = useRealtimeStock(symbol);
  const [legend, setLegend] = useState(null);

  useEffect(() => {
    if (stockDetail) {
      setLegend({
        high: stockDetail?.highTradedPrice,
        low: stockDetail?.lowTradedPrice,
        avg: stockDetail?.averagePrice,
        lot: stockDetail?.tradableLot,
        val: stockDetail?.tradableValue
      })
    }
  }, [stockDetail]);

  return (
    <div className="flex items-center w-full text-2xs text-[9px] font-quicksand-regular justify-between py-2 px-3">
      <div className="flex items-center">
        <RenderIf isTrue={isLoading}>
          <SkeletonTheme containerClassName="leading-none" width="40px" height="12px" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!isLoading}>
          <span className="text-gray-light-0-d">HI</span>
          <span className="ml-1 dark:text-black-light-1-d">
            {currency(legend?.high || 0, { removeSymbol: true })}
          </span>
        </RenderIf>

      </div>
      <div className="flex items-center">
        <RenderIf isTrue={isLoading}>
          <SkeletonTheme containerClassName="leading-none" width="40px" height="12px" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!isLoading}>
          <span className="text-gray-light-0-d">LO</span>
          <span className="ml-1 dark:text-black-light-1-d">
            {currency(legend?.low || 0, { removeSymbol: true })}
          </span>
        </RenderIf>
      </div>
      <div className="flex items-center">
        <RenderIf isTrue={isLoading}>
          <SkeletonTheme containerClassName="leading-none" width="40px" height="12px" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!isLoading}>
          <span className="text-gray-light-0-d">AVG</span>
          <span className="ml-1 dark:text-black-light-1-d">
            {currency(legend?.avg || 0, { removeSymbol: true })}
          </span>
        </RenderIf>
      </div>
      <div className="flex items-center">
        <RenderIf isTrue={isLoading}>
          <SkeletonTheme containerClassName="leading-none" width="40px" height="12px" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!isLoading}>
          <span className="text-gray-light-0-d">LOT</span>
          <span className="ml-1 dark:text-black-light-1-d">{formatIndonesianKMBT(legend?.lot || 0)}</span>
        </RenderIf>
      </div>
      <div className="flex items-center">
        <RenderIf isTrue={isLoading}>
          <SkeletonTheme containerClassName="leading-none" width="40px" height="12px" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!isLoading}>
          <span className="text-gray-light-0-d">VAL</span>
          <span className="ml-1 dark:text-black-light-1-d">{formatIndonesianKMBT(legend?.val || 0)}</span>
        </RenderIf>
      </div>
    </div>
  );
}

TVLegend.propTypes = {
  values: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default TVLegend;
