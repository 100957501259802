import PropTypes from 'prop-types';

const IconTrashCan = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83341 6.66669V15C5.83341 15.9205 6.57961 16.6667 7.50008 16.6667H12.5001C13.4206 16.6667 14.1667 15.9205 14.1667 15V6.66669H15.8334V15C15.8334 16.841 14.341 18.3334 12.5001 18.3334H7.50008C5.65913 18.3334 4.16675 16.841 4.16675 15V6.66669H5.83341Z"
        fill="currentColor"
      />
      <path
        d="M8.33333 9.16669C7.8731 9.16669 7.5 9.53978 7.5 10V13.3334C7.5 13.7936 7.8731 14.1667 8.33333 14.1667C8.79357 14.1667 9.16667 13.7936 9.16667 13.3334V10C9.16667 9.53978 8.79357 9.16669 8.33333 9.16669Z"
        fill="currentColor"
      />
      <path
        d="M11.6667 9.16669C11.2064 9.16669 10.8333 9.53978 10.8333 10V13.3334C10.8333 13.7936 11.2064 14.1667 11.6667 14.1667C12.1269 14.1667 12.5 13.7936 12.5 13.3334V10C12.5 9.53978 12.1269 9.16669 11.6667 9.16669Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66659 5.00002V4.16669C6.66659 2.78598 7.78587 1.66669 9.16658 1.66669H10.8333C12.214 1.66669 13.3333 2.78598 13.3333 4.16669V5.00002H15.8333C16.2935 5.00002 16.6666 5.37312 16.6666 5.83335C16.6666 6.29359 16.2935 6.66669 15.8333 6.66669H4.16659C3.70635 6.66669 3.33325 6.29359 3.33325 5.83335C3.33325 5.37312 3.70635 5.00002 4.16659 5.00002H6.66659ZM8.33325 4.16669C8.33325 3.70645 8.70635 3.33335 9.16658 3.33335H10.8333C11.2935 3.33335 11.6666 3.70645 11.6666 4.16669V5.00002H8.33325V4.16669Z"
        fill="currentColor"
      />
    </svg>
  );
};

IconTrashCan.propTypes = {
  className: PropTypes.string,
};

IconTrashCan.defaultProps = {
  className: '',
};

export default IconTrashCan;
