const TRANSLATE_DIGIT_5_REMARKS = {
  '1': '1',
  '2': '2',
  '3': 'ACCEL'
}

const TRANSLATE_DIGIT_3_REMARKS = {
  'M': 'Margin',
  'S': 'Short',
  'U': 'U',
  'D': 'Designated'
}

export const getStockRemarkSymbol = (remarks2) => {
  let notations = [];
  let generalNotations = remarks2?.slice(18, 30).split('-').join('').split('') || [];

  const digit1and2 = remarks2?.slice(0, 2);
  if ([
    // '--', 
    'CD', 
    'CB',
    'CR',
    'CA',
    'XD',
    'XB',
    'XR', 
    'XA', 
    'SS', 
    'RS'].includes(digit1and2)) notations.push(digit1and2);

  const digit3 = remarks2?.slice(2, 3);
  if ([
    'M', 
    // 'S', 
    // 'U', 
    // 'D'
  ].includes(digit3)) notations.push(TRANSLATE_DIGIT_3_REMARKS[digit3]);

  const digit4 = remarks2?.slice(3, 4);
  if ([
    // '-', 
    // 'O'
  ].includes(digit4)) notations.push(digit4);

  const digit5 = remarks2?.slice(4, 5);
  if ([
    // '1', 
    // '2', 
    '3'
  ].includes(digit5)) notations.push(TRANSLATE_DIGIT_5_REMARKS[digit5]);

  const allNotations = [...notations, ...generalNotations]
  if (!allNotations?.length) return false

  return allNotations;
};
