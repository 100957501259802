import PropTypes from 'prop-types';
import InputStock from 'components/input/input-stock';
import { useFormik } from 'formik';
import InputCounter from 'components/input/input-counter';
import { currency, generateOrderId } from 'utils/format';
import { marginPlusShare } from 'utils/formula/margin';
import RenderIf from 'components/basic/render-if';
import { useDispatch, useSelector } from 'react-redux';
import InputSwitch from 'components/input/input-switch';
import { useGetBuyingPowerQuery, useCreateAlgoBOTMutation, invalidateSmartOrderList } from 'services/rtk-query/trading';
import { useGetStockByCodeQuery } from 'services/rtk-query/stock';
import InputRadio from 'components/input/input-radio';
import { useEffect, useState } from 'react';
import InputCheckBox from 'components/input/input-check-box';
import { find, isEmpty, isEqual, range, uniqueId } from 'lodash';
import useToastr from 'hooks/useToastr';
import { SmartOrderFormValidation } from './smart-order.form.validation';
import { useGetPortfolioListQuery } from 'services/rtk-query/trade';
import ModalConfirmationSmartOrder from '../../_partials/modal-confirmation-smart-order';
import InputDatePickerSimple from 'components/input/input-date-picker-simple';
import { addDays, getYear, subDays } from 'date-fns';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Tooltip from 'components/basic/tooltip';
import IconInfo from 'components/svg/icon-info';
import ModalAgreementSmartOrder from 'components/modal/template/modal-agreement-smart-order';
import { triggerSegmentEvent } from 'lib/segment';
import { dayjsLocal } from 'utils/formatter/date';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import SimpleSpinner from 'components/common/spinner/spinner';
import { setQuickOrderSettingsConfirmation } from 'store/settings';
import IconSort from 'components/svg/icon-sort';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function SmartBuyForm({
  onSuccess = () => {},
  onClose = () => {},
  onChangeSymbol = () => {},
  initialForms = {},
  symbol = '',
  defaultBotName = 'SmartBuy',
  disabledStock = false,
  formId = 'smart-order-form',
}) {
  
  const dispatch = useDispatch();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();

  const { user, dealerUseClientId } = useBrowserTabAuth();
  const username = user?.username;

  const quickOrderConfirmation = useSelector((state) => state?.settings?.quickOrderSettings?.confirmation);

  const [tempSymbol, setTempSymbol] = useState(initialForms?.StockId || symbol);
  const [isNotRealAccount, setIsNotRealAccount] = useState(false);
  const [openModalConfirmationBuy, setOpenModalConfirmationBuy] = useState(false);
  const [openModalAgreement, setOpenModalAgreement] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: trading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { data: stockDetail } = useGetStockByCodeQuery(tempSymbol, false);
  const { data: portFolio } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const [createAlgoBOT] = useCreateAlgoBOTMutation();
  const [portfolio, setPortfolio] = useState(find(portFolio?.stock?.items, ['stID', tempSymbol]));

  const [tempTrading, setTempTrading] = useState(trading);

  const formik = useFormik({
    initialValues: {
      lotType: true,
      portfolio: initialForms?.portfolio || (portfolio?.net || 0) / 100,
      buyingPower: initialForms?.buyingPower || trading?.buyingpower || 0,
      BotName: initialForms?.BotName || defaultBotName,
      ClientId: dealerUseClientId || initialForms?.ClientId || username,
      ClientOrderId: initialForms?.ClientOrderId || generateOrderId(dealerUseClientId || username, 'SB', Date.now().toString().slice(7, 13)),
      StockId: initialForms?.StockId || symbol,
      BuySell: initialForms?.BuySell || 'B',
      Price: initialForms?.Price || stockDetail?.lastTradedPrice || 0,
      PriceStop: initialForms?.PriceStop || stockDetail?.lastTradedPrice || 0,
      PriceProfit: initialForms?.PriceProfit || stockDetail?.lastTradedPrice || 0,
      Rule: initialForms?.Rule || (defaultBotName === 'TakeProfit' ? '>=' : defaultBotName === 'StopLoss' ? '<=' : '<='),
      RuleStop: initialForms?.RuleStop || '<=',
      RuleProfit: initialForms?.RuleProfit || '>=',
      PriceRule: initialForms?.PriceRule || stockDetail?.lastTradedPrice || 0,
      PriceRuleStop: initialForms?.PriceRuleStop || stockDetail?.lastTradedPrice || 0,
      PriceRuleProfit: initialForms?.PriceRuleProfit || stockDetail?.lastTradedPrice || 0,
      Amount: initialForms?.Lot * initialForms?.Price * 100 || 0,
      AmountStop: initialForms?.LotStop * initialForms?.PriceStop * 100 || 0,
      AmountProfit: initialForms?.LotProfit * initialForms?.PriceProfit * 100 || 0,
      Lot: initialForms?.Lot || 0,
      LotStop: initialForms?.LotStop || 0,
      LotProfit: initialForms?.LotProfit || 0,
      BotExpire: initialForms?.BotExpire || dayjsLocal().add(1, 'day').toDate(),
      BotExpireStop: initialForms?.BotExpireStop || dayjsLocal().add(1, 'day').toDate(),
      BotExpireProfit: initialForms?.BotExpireProfit || dayjsLocal().add(1, 'day').toDate(),
      FireAt: initialForms?.FireAt || 190,
      EntryBy: initialForms?.EntryBy || username,
      takeProfit: initialForms?.takeProfit || false,
      stopLoss: initialForms?.stopLoss || false,
      agreement: initialForms?.agreement || false,
      State: initialForms?.State || 1,
      initialPrice: stockDetail?.lastTradedPrice
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: SmartOrderFormValidation,
    onSubmit: (values) => handleSmartOrder(values)
  });

  const handleSmartOrder = async (values) => {
    try {
      setIsSubmitting(true)
      let params = {
        BotName: `${values?.BotName}@${values?.ClientOrderId}`,
        ClientId: dealerUseClientId || values?.ClientId,
        ClientOrderId: values?.ClientOrderId,
        StockId: values?.StockId,
        BuySell: 'B',
        Price: values?.Price,
        Lot: values?.Lot,
        EntryBy: values?.EntryBy,
        State: values?.State,
        Rule: values?.Rule,
        FireAt: values?.FireAt,
        PriceFire: values?.PriceRule,
        BotExpire: dayjsLocal(values?.BotExpire).format('DD-MMM-YYYY') + ' 16:00:00',
      }

      if (values?.Rule === '>=') {
        params.Rule = `Q.${values?.StockId}.56${values?.Rule}${values?.PriceRule}`;
      }

      if (values?.Rule === '<=') {
        params.Rule = `Q.${values?.StockId}.56${values?.Rule}${values?.PriceRule}&&Q.${values?.StockId}.56>0`;
      }
      
      let payloads = [params];
      let _paramsStopLoss = { ...params };
      let _paramsTakeProfit = { ...params };

      if (values?.BotName === 'SmartBuy') {
        if (values?.bestPrice) params.Price = `Q.${values?.StockId}.24`;

        if (values?.stopLoss) {
          let _stopLossOrderId  = generateOrderId(dealerUseClientId || username, 'SL', Date.now().toString().slice(7, 13));
          _paramsStopLoss.BotName = `StopLoss@${_stopLossOrderId}@${params.ClientOrderId}`;
          _paramsStopLoss.Lot = values?.LotStop;
          _paramsStopLoss.Price = values?.PriceStop;
          _paramsStopLoss.ClientOrderId = _stopLossOrderId;
          _paramsStopLoss.BuySell = 'S';
          _paramsStopLoss.BotExpire = dayjsLocal(values?.BotExpireStop).format('DD-MMM-YYYY') + ' 16:00:00';

          if (values?.RuleStop === '>=') {
            _paramsStopLoss.Rule = `Q.${values?.StockId}.56${values?.RuleStop}${values?.PriceRuleStop}&&CO.${dealerUseClientId || values?.ClientId}.${params.ClientOrderId}.DONE>0`;
          }

          if (values?.RuleStop === '<=') {
            _paramsStopLoss.Rule = `Q.${values?.StockId}.56${values?.RuleStop}${values?.PriceRuleStop}&&Q.${values?.StockId}.56>0&&CO.${dealerUseClientId || values?.ClientId}.${params.ClientOrderId}.DONE>0`;
          }

          _paramsStopLoss.VolumeFire = `CO.${dealerUseClientId || values?.ClientId}.${values?.ClientOrderId}.DONE`;
          payloads.push(_paramsStopLoss);
        }

        if (values?.takeProfit) {
          let _takePorfitOrderId  = generateOrderId(dealerUseClientId || username, 'TP', Date.now().toString().slice(7, 13));
          _paramsTakeProfit.BotName = `TakeProfit@${_takePorfitOrderId}@${params.ClientOrderId}`;
          _paramsTakeProfit.Lot = values?.LotProfit;
          _paramsTakeProfit.Price = values?.PriceProfit;
          _paramsTakeProfit.ClientOrderId = _takePorfitOrderId;
          _paramsTakeProfit.BuySell = 'S';
          _paramsTakeProfit.BotExpire = dayjsLocal(values?.BotExpireProfit).format('DD-MMM-YYYY') + ' 16:00:00';

          if (values?.RuleProfit === '>=') {
            _paramsTakeProfit.Rule = `Q.${values?.StockId}.56${values?.RuleProfit}${values?.PriceRuleProfit}&&CO.${dealerUseClientId || values?.ClientId}.${params.ClientOrderId}.DONE>0`;
          }

          if (values?.RuleProfit === '<=') {
            _paramsTakeProfit.Rule = `Q.${values?.StockId}.56${values?.RuleProfit}${values?.PriceRuleProfit}&&Q.${values?.StockId}.56>0&&CO.${dealerUseClientId || values?.ClientId}.${params.ClientOrderId}.DONE>0`;
          }

          _paramsTakeProfit.VolumeFire = `CO.${dealerUseClientId || values?.ClientId}.${values?.ClientOrderId}.DONE`;
          payloads.push(_paramsTakeProfit);
        }
      }

      let promises = [];
      payloads.map((_params_) => {
        promises.push(createAlgoBOT(_params_).unwrap());
      });

      await Promise.all(promises);

      toastr.success('Smart Order berhasil dibuat');
      triggerSegmentEvent({
        event: 'TR Smart Order Created',
        properties: {
          total_amount: Number(values?.Price) * Number(values?.Lot) * 100,
          stock_name: values?.StockId,
          lot: values?.Lot
        }
      });
      setOpenModalConfirmationBuy(false);
      onSuccess();
      setTimeout(() => dispatch(invalidateSmartOrderList()), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();

    if (!user?.realAccount) return setIsNotRealAccount(true);

    if (isEmpty(errors)) {
      if (!quickOrderConfirmation) {
        return setOpenModalConfirmationBuy(true);
      } else {
        return formik.submitForm();
      }
    }
  };

  const generateDefaultStocks = (BotName) => {
    let _stocks = [];
    if (['TakeProfit', 'StopLoss'].includes(BotName)) {
      portFolio?.stock?.items?.map((porto) => {
        _stocks.push({
          "code": porto?.stID,
          "name": porto?.sName,
        })
      })
    }

    return _stocks;
  }

  useEffect(() => {
    const _portfolio = find(portFolio?.stock?.items, ['stID', tempSymbol]);
    setPortfolio(_portfolio);

    if (stockDetail) {
      formik.setValues({
        ...formik.values,
        portfolio: (_portfolio?.net || 0) / 100,
        buyingPower: tempTrading?.buyingpower,
        initialPrice: stockDetail?.lastTradedPrice,
        StockId: tempSymbol,
        Price: initialForms?.Price || stockDetail?.lastTradedPrice,
        PriceStop: initialForms?.PriceStop || stockDetail?.lastTradedPrice,
        PriceProfit: initialForms?.PriceProfit || stockDetail?.lastTradedPrice,
        PriceRule: initialForms?.PriceRule || stockDetail?.lastTradedPrice,
        PriceRuleStop: initialForms?.PriceRuleStop || stockDetail?.lastTradedPrice,
        PriceRuleProfit: initialForms?.PriceRuleProfit || stockDetail?.lastTradedPrice
      })

      setTimeout(() => formik.setErrors({}), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockDetail, defaultBotName, portFolio, tempTrading, tempSymbol, initialForms]);

  useEffect(() => {
    if (symbol != tempSymbol) {
      setTempSymbol(symbol);
    }
  }, [symbol, tempSymbol]);

  useEffect(() => {
    if (!isEqual(tempTrading?.buyingpower, trading?.buyingpower)) {
      setTempTrading(trading);
    }
  }, [tempTrading, trading]);


  const paddingClass = () => {
    let className = 'pb-[80px] mb-[80px]';
    if ((formik.values.stopLoss && !formik.values.takeProfit) || (!formik.values.stopLoss && formik.values.takeProfit)) {
      className = 'pb-[90px] mb-[90px]';
    }

    if (formik.values.stopLoss && formik.values.takeProfit) {
      className = 'pb-[100px] mb-[100px]';
    }

    return className;
  }

  return (
    <div className={`${paddingClass()}`}>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small lg:max-h-[597px] lg:min-h-[597px] -mx-4 px-4 relative"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        >
        <form
          className=""
          autoComplete="off"
          id={formId}
          noValidate
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => e.key === 'Enter' && handleConfirmation()}
          >
          <RenderIf isTrue={formik.values.BotName === 'SmartBuy'}>
            <div className='flex justify-between'>
              <span className="text-xs text-gray-light-0-d block font-quicksand-semibold">
                Limit
              </span>
              <h5 className="mb-2 text-sm">
                IDR&nbsp;{currency(trading?.buyingpower || 0, { removeSymbol: true })}
              </h5>
            </div>
          </RenderIf>

          <div className='relative z-20'>
            <InputStock
              size='small'
              disabled={disabledStock}
              value={formik.values.StockId}
              defaultStocks={generateDefaultStocks(formik.values.BotName)}
              onChange={({ code }) => code && onChangeSymbol(code)}
            />
          </div>

          <h5 className='text-xs font-quicksand-semibold my-3'>Pengiriman Smart Buy</h5>

          <InputCounter
            isAutoFocus
            size="small"
            className='my-2'
            onBlur={formik.handleBlur}
            data-qa-id="txtStockBuyPriceField"
            value={formik.values.Price}
            name="Price"
            label={<span className='text-2xs text-gray-light-0-d'>Target Harga</span>}
            counterStep={marginPlusShare(formik.values.Price)}
            disabled={formik?.values?.bestPrice}
            onChange={(val) => {
              formik.setFieldValue('Price', val);
              if (['TakeProfit', 'StopLoss'].includes(formik.values.BotName)) {
                if (val >= (portfolio?.avgPrice || 0)) {
                  formik.setFieldValue('BotName', 'TakeProfit');
                } else {
                  formik.setFieldValue('BotName', 'StopLoss');
                }
              }

              setTimeout(() => formik.setTouched({ Price: true }), 100);
            }}
            error={formik.errors.Price ? formik.errors.Price : ''}
            showError={false}
          />

          <InputCounter
            size="small"
            className='my-2'
            onBlur={formik.handleBlur}
            data-qa-id="txtStockBuyLotField"
            value={formik.values.lotType ? formik.values.Lot : formik.values.Amount}
            value2={formik.values.lotType ? formik.values.Amount : formik.values.Lot}
            label={
              <span className='text-2xs text-gray-light-0-d'>
                <button tabIndex={-1} onClick={() => formik.setFieldValue('lotType', !formik.values.lotType)} type='button' className='flex items-center hover:text-main-base-l'>
                  <IconSort className='h-4 w-4 mr-1 transform rotate-90 text-main-base-l' /> {formik.values.lotType ? 'Jumlah Lot' : 'Amount'}
                </button>
              </span>}
            label2={<span className='text-2xs text-gray-light-0-d'>{formik.values.lotType ? 'Amount' : 'Lot'}</span>}
            showShortcut={formik.values.lotType}
            showValue2
            counterStep={formik.values.lotType ? 1 : formik.values.Price * 100}
            maxValue={Math.floor(trading?.buyingpower / (formik.values.Price * 100)) || 0}
            name="Lot"
            onChange={(val) => {
              if (formik.values.lotType) {
                formik.setValues({
                  ...formik.values,
                  Lot: val,
                  LotProfit: val,
                  LotStop: val,
                  Amount: Number(val) * formik.values.Price * 100,
                  AmountStop: Number(val) * formik.values.Price * 100,
                  AmountProfit: Number(val) * formik.values.Price * 100,
                });
              } else {
                formik.setValues({
                  ...formik.values,
                  Amount: val,
                  AmountProfit: val,
                  AmountStop: val,
                  Lot: Math.floor(Number(val) / (formik.values.Price * 100)),
                  LotStop: Math.floor(Number(val) / (formik.values.Price * 100)),
                  LotProfit: Math.floor(Number(val) / (formik.values.Price * 100)),
                });
              }
              setTimeout(() => formik.setTouched({ Lot: true }), 100);
            }}
            error={formik.errors.Lot ? formik.errors.Lot : ''}
            showError={false}
          />

          <div className='flex justify-end mt-2'>
            <RenderIf isTrue={formik.values.BotName === 'SmartBuy'}>
              <InputCheckBox
                id={`${uniqueId('bestPrice-')}`}
                value={formik?.values?.bestPrice}
                name="bestPrice"
                label={<span className='text-2xs font-quicksand-semibold text-black-base-l dark:text-black-light-1-d transform translate-y-[2px]'>Best Bid Price</span>}
                isError={formik?.errors?.bestPrice}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue('Price', stockDetail?.lastTradedPrice)
                }}
                onBlur={formik.handleBlur}
                className='mb-0'
              />
            </RenderIf>

            <RenderIf isTrue={formik.values.BotName != 'SmartBuy'}>
              <InputCheckBox
                id={`${uniqueId('bestPrice-')}`}
                value={formik?.values?.bestPrice}
                name="bestPrice"
                label={<span className='text-2xs font-quicksand-semibold text-black-base-l dark:text-black-light-1-d transform translate-y-[2px]'>Best Offer Price</span>}
                isError={formik?.errors?.bestPrice}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue('Price', stockDetail?.lastTradedPrice)
                }}
                onBlur={formik.handleBlur}
                className='mb-0'
              />
            </RenderIf>
          </div>

          <h5 className='text-xs font-quicksand-semibold my-3'>Syarat Smart Buy</h5>

          <InputDatePickerSimple
            size='small'
            years={range(getYear(new Date()), getYear(new Date()) + 2, 1)}
            value={formik.values.BotExpire}
            name="BotExpire"
            format='dd/MM/yyyy'
            label={<span className='text-2xs text-gray-light-0-d'>Berakhir Hingga</span>}
            placeholder='Pilih Expiry Date'
            className='my-2'
            onChange={(_date) => {
              formik.setFieldValue('BotExpire', _date);
              formik.setFieldValue('BotExpireStop', _date);
              formik.setFieldValue('BotExpireProfit', _date);
              setTimeout(() => formik.setTouched({ BotExpire: true, BotExpireStop: true, BotExpireProfit: true }), 100);
            }}
            error={formik.errors.BotExpire ? formik.errors.BotExpire : ''}
            showError={false}
            includeDateIntervals={[ { start: subDays(new Date(), 1), end: addDays(new Date(), 365) }]}
          />

          <div className="grid grid-cols-12 gap-3 relative mb-4">
            <div className="col-span-4">
              <InputRadio
                label={<span className='text-2xs text-gray-light-0-d'>Harga Mencapai</span>}
                value={formik.values.Rule}
                name="Rule"
                className='mb-0'
                size='p-[5.5px] first:rounded-l-lg last:rounded-r-lg border'
                options={[
                  {
                    value: '<=',
                    title: '',
                    node: <div className="text-xl font-quicksand-semibold leading-4">{'≤'}</div>
                  },
                  {
                    value: '>=',
                    title: '',
                    node: <div className="text-xl font-quicksand-semibold leading-4">{'≥'}</div>
                  }
                ]}
                onChange={formik.handleChange}
                error={formik.errors.Rule ? formik.errors.Rule : ''}
                showError={false}
              />
            </div>

            <div className='col-span-8'>
              <InputCounter
                size="small"
                onBlur={formik.handleBlur}
                data-qa-id="txtStockBuyPriceField"
                value={formik.values.PriceRule}
                name="PriceRule"
                label={<span className='text-2xs text-gray-light-0-d'>&nbsp;</span>}
                className='mt-0'
                counterStep={marginPlusShare(formik.values.PriceRule)}
                onChange={(val) => {
                  formik.setFieldValue('PriceRule', val);
                  setTimeout(() => formik.setTouched({ PriceRule: true }), 100);
                }}
                error={formik.errors.PriceRule ? formik.errors.PriceRule : ''}
                showError={false}
              />
            </div>
          </div>

          <p className='text-xs -mt-2'>Jika harga pasar <b>{formik.values.Rule === '<=' ? 'kurang': 'lebih'} dari atau sama dengan</b> <b>IDR&nbsp;{currency(formik.values.PriceRule, { removeSymbol: true})}</b>, order beli dikirim di harga <b>IDR&nbsp;{currency(formik.values.Price, { removeSymbol: true})}</b> sebanyak <b>{currency(formik.values.LotProfit, { removeSymbol: true})} Lot</b></p>
          <div className="py-4 border-b dark:border-gray-base-d">
            <div className="flex justify-between items-center">
              <label className="flex font-quicksand-semibold text-xs items-center">
                Stop Loss

                <Tooltip
                  isZoomOut
                  tooltipHTML={
                    <div className="max-w-[300px] min-w-[300px] p-2">
                      <h5 className='text-sm font-quicksand-medium text-left mb-1'>Stop Loss</h5>
                      <p className="text-xs font-quicksand-regular text-left">
                        Adalah order yang telah ditentukan oleh kita untuk menjual saham jika harga mencapai level tertentu. 
                        <br/>
                        <br/>
                        Dengan menggunakan stop loss, Kamu dapat mengontrol risiko investasi dengan cara menetapkan batasan kerugian yang telah ditentukan.
                      </p>
                    </div>
                  }>
                  <button tabIndex={-1} type="button" className="hover:text-main-base-l mx-1">
                    <IconInfo className='h-5 w-5' />
                  </button>
                </Tooltip>
              </label>
              <InputSwitch
                name="stopLoss"
                size="small"
                value={formik?.values?.stopLoss}
                onChange={formik.handleChange}
                color='gold'
              />
            </div>

            <RenderIf isTrue={formik?.values?.stopLoss}>
              <InputCounter
                size="small"
                onBlur={formik.handleBlur}
                data-qa-id="txtStockBuyPriceField"
                disabled={!formik?.values?.stopLoss}
                value={formik.values.PriceStop}
                name="PriceStop"
                label={<span className='text-2xs text-gray-light-0-d'>Target Harga</span>}
                className="my-2"
                max={formik.values.Price}
                counterStep={marginPlusShare(formik.values.PriceStop)}
                onChange={(val) => {
                  formik.setFieldValue('PriceStop', val);
                  setTimeout(() => formik.setTouched({ PriceStop: true }), 100);
                }}
                error={formik.errors.PriceStop ? formik.errors.PriceStop : ''}
                showError={false}
              />

              <InputCounter
                size="small"
                className='my-2'
                onBlur={formik.handleBlur}
                data-qa-id="txtStockBuyLotField"
                value={formik.values.lotType ? formik.values.LotStop : formik.values.AmountStop}
                value2={formik.values.lotType ? formik.values.AmountStop : formik.values.LotStop}
                label={
                  <span className='text-2xs text-gray-light-0-d'>
                    <button onClick={() => formik.setFieldValue('lotType', !formik.values.lotType)} type='button' className='flex items-center hover:text-main-base-l'>
                      <IconSort className='h-4 w-4 mr-1 transform rotate-90 text-main-base-l' /> {formik.values.lotType ? 'Jumlah Lot Stop' : 'Amount Stop'}
                    </button>
                  </span>}
                label2={<span className='text-2xs text-gray-light-0-d'>{formik.values.lotType ? 'Amount Stop' : 'Lot Stop'}</span>}
                showShortcut={formik.values.lotType}
                max={formik.values.lotType ? formik.values.Lot : formik.values.Lot * formik.values.Price * 100}
                counterStep={formik.values.lotType ? 1 : formik.values.Price * 100}
                name="LotStop"
                onChange={(val) => {
                  if (formik.values.lotType) {
                    formik.setValues({
                      ...formik.values,
                      LotStop: val,
                      AmountStop: Number(val) * formik.values.Price * 100,
                    });
                  } else {
                    formik.setValues({
                      ...formik.values,
                      AmountStop: val,
                      LotStop: Math.floor(Number(val) / (formik.values.Price * 100)),
                    });
                  }
                  setTimeout(() => formik.setTouched({ LotStop: true }), 100);
                }}
                error={formik.errors.LotStop ? formik.errors.LotStop : ''}
                showError={false}
              />

              {/* <h5 className='text-xs font-quicksand-semibold mt-2 mb-4'>Syarat Stop Loss</h5> */}

              {/* <InputDatePickerSimple
                size='small'
                value={formik.values.BotExpireStop}
                name="BotExpireStop"
                format='dd/MM/yyyy'
                label={<span className='text-2xs text-gray-light-0-d'>Berakhir Hingga</span>}
                placeholder='Pilih Expiry Date'
                onChange={(_date) => {
                  formik.setFieldValue('BotExpireStop', _date);
                }}
                error={formik.errors.BotExpireStop ? formik.errors.BotExpireStop : ''}
                showError={false}
                includeDateIntervals={[ { start: subDays(new Date(), 1), end: addDays(new Date(), 365) }]}
              /> */}

              <div className="grid grid-cols-12 gap-3 relative mb-4">
                <div className="col-span-4">
                  <InputRadio
                    label={<span className='text-2xs text-gray-light-0-d'>Harga Mencapai</span>}
                    value={formik.values.RuleStop}
                    name="RuleStop"
                    className='mb-0'
                    size='p-[5.5px] first:rounded-l-lg last:rounded-r-lg border'
                    options={[
                      {
                        value: '<=',
                        title: '',
                        node: <div className="text-xl font-quicksand-semibold leading-4">{'≤'}</div>
                      },
                      {
                        value: '>=',
                        title: '',
                        node: <div className="text-xl font-quicksand-semibold leading-4">{'≥'}</div>
                      }
                    ]}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className='col-span-8'>
                  <InputCounter
                    size="small"
                    onBlur={formik.handleBlur}
                    data-qa-id="txtStockBuyPriceField"
                    value={formik.values.PriceRuleStop}
                    name="PriceRuleStop"
                    label="&nbsp;"
                    className='mt-0'
                    counterStep={marginPlusShare(formik.values.PriceRuleStop)}
                    max={formik.values.Price}
                    onChange={(val) => {
                      formik.setFieldValue('PriceRuleStop', val);
                      setTimeout(() => formik.setTouched({ PriceRuleStop: true }), 100);
                    }}
                    error={formik.errors.PriceRuleStop ? formik.errors.PriceRuleStop : ''}
                    showError={false}
                  />
                </div>
              </div>

              <p className='text-xs -mt-2'>Jika harga pasar <b>{formik.values.RuleStop === '<=' ? 'kurang': 'lebih'} dari atau sama dengan</b> <b>IDR&nbsp;{currency(formik.values.PriceRuleStop, { removeSymbol: true})}</b>, order jual dikirim di harga <b>IDR&nbsp;{currency(formik.values.PriceStop, { removeSymbol: true})}</b> sebanyak <b>{currency(formik.values.LotStop, { removeSymbol: true})} Lot</b></p>
            </RenderIf>
          </div>

          <div className="py-4 border-b dark:border-gray-base-d">
            <div className="flex justify-between items-center">
            <label className="flex font-quicksand-semibold text-xs items-center">
                Take Profit

                <Tooltip
                  isZoomOut
                  tooltipHTML={
                    <div className="max-w-[300px] min-w-[300px] p-2">
                      <h5 className='text-sm font-quicksand-medium text-left mb-1'>Take Profit</h5>
                      <p className="text-xs font-quicksand-regular text-left">
                        Adalah order yang ditentukan oleh kita untuk menjual saham ketika harga mencapai level tertentu yang telah ditentukan sebelumnya. 
                        <br/>
                        <br/>
                        Dengan menggunakan take profit, Kamu dapat mengendalikan dan merealisasikan keuntungan yang telah dicapai. Saat harga mencapai level take profit yang ditetapkan.
                      </p>
                    </div>
                  }>
                  <button tabIndex={-1} type="button" className="hover:text-main-base-l mx-1">
                    <IconInfo className='h-5 w-5' />
                  </button>
                </Tooltip>
              </label>
              <InputSwitch
                name="takeProfit"
                size="small"
                value={formik?.values?.takeProfit}
                onChange={formik.handleChange}
                color='gold'
              />
            </div>

            <RenderIf isTrue={formik?.values?.takeProfit}>
              <InputCounter
                size="small"
                onBlur={formik.handleBlur}
                data-qa-id="txtStockBuyPriceField"
                disabled={!formik?.values?.takeProfit}
                value={formik.values.PriceProfit}
                min={formik.values.Price}
                name="PriceProfit"
                label={<span className='text-2xs text-gray-light-0-d'>Target Harga</span>}
                className="my-2"
                counterStep={marginPlusShare(formik.values.PriceProfit)}
                onChange={(val) => {
                  formik.setFieldValue('PriceProfit', val);
                  setTimeout(() => formik.setTouched({ PriceProfit: true }), 100);
                }}
                error={formik.errors.PriceProfit ? formik.errors.PriceProfit : ''}
                showError={false}
              />

              <InputCounter
                size="small"
                className='my-2'
                onBlur={formik.handleBlur}
                data-qa-id="txtStockBuyLotField"
                value={formik.values.lotType ? formik.values.LotProfit : formik.values.AmountProfit}
                value2={formik.values.lotType ? formik.values.AmountProfit : formik.values.LotProfit}
                label={
                  <span className='text-2xs text-gray-light-0-d'>
                    <button onClick={() => formik.setFieldValue('lotType', !formik.values.lotType)} type='button' className='flex items-center hover:text-main-base-l'>
                      <IconSort className='h-4 w-4 mr-1 transform rotate-90 text-main-base-l' /> {formik.values.lotType ? 'Jumlah Lot Profit' : 'Amount Profit'}
                    </button>
                  </span>}
                label2={<span className='text-2xs text-gray-light-0-d'>{formik.values.lotType ? 'Amount Profit' : 'Lot Profit'}</span>}
                showShortcut={formik.values.lotType}
                max={formik.values.lotType ? formik.values.Lot : formik.values.Lot * formik.values.Price * 100}
                counterStep={formik.values.lotType ? 1 : formik.values.Price * 100}
                name="LotProfit"
                onChange={(val) => {
                  if (formik.values.lotType) {
                    formik.setValues({
                      ...formik.values,
                      LotProfit: val,
                      AmountProfit: Number(val) * formik.values.Price * 100,
                    });
                  } else {
                    formik.setValues({
                      ...formik.values,
                      AmountProfit: val,
                      LotProfit: Math.floor(Number(val) / (formik.values.Price * 100)),
                    });
                  }
                  setTimeout(() => formik.setTouched({ LotProfit: true }), 100);
                }}
                error={formik.errors.LotProfit ? formik.errors.LotProfit : ''}
                showError={false}
              />

              {/* <h5 className='text-xs font-quicksand-semibold mt-2 mb-4'>Syarat Take Profit</h5> */}

              {/* <InputDatePickerSimple
                size='small'
                value={formik.values.BotExpireProfit}
                name="BotExpireProfit"
                format='dd/MM/yyyy'
                label={<span className='text-2xs text-gray-light-0-d'>Berakhir Hingga</span>}
                placeholder='Pilih Expiry Date'
                onChange={(_date) => {
                  formik.setFieldValue('BotExpireProfit', _date);
                }}
                error={formik.errors.BotExpireProfit ? formik.errors.BotExpireProfit : ''}
                showError={false}
                includeDateIntervals={[ { start: subDays(new Date(), 1), end: addDays(new Date(), 365) }]}
              /> */}

              <div className="grid grid-cols-12 gap-3 relative mb-4">
                <div className="col-span-4">
                  <InputRadio
                    label={<span className='text-2xs text-gray-light-0-d'>Harga Mencapai</span>}
                    value={formik.values.RuleProfit}
                    name="RuleProfit"
                    className='mb-0'
                    size='p-[5.5px] first:rounded-l-lg last:rounded-r-lg border'
                    options={[
                      {
                        value: '<=',
                        title: '',
                        node: <div className="text-xl font-quicksand-semibold leading-4">{'≤'}</div>
                      },
                      {
                        value: '>=',
                        title: '',
                        node: <div className="text-xl font-quicksand-semibold leading-4">{'≥'}</div>
                      }
                    ]}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className='col-span-8'>
                  <InputCounter
                    size="small"
                    onBlur={formik.handleBlur}
                    data-qa-id="txtStockBuyPriceField"
                    value={formik.values.PriceRuleProfit}
                    name="PriceRuleProfit"
                    label="&nbsp;"
                    className='mt-0'
                    min={formik.values.Price}
                    counterStep={marginPlusShare(formik.values.PriceRuleProfit)}
                    onChange={(val) => {
                      formik.setFieldValue('PriceRuleProfit', val);
                      setTimeout(() => formik.setTouched({ PriceRuleProfit: true }), 100);
                    }}
                    error={formik.errors.PriceRuleProfit ? formik.errors.PriceRuleProfit : ''}
                    showError={false}
                  />
                </div>
              </div>

              <p className='text-xs -mt-2'>Jika harga pasar <b>{formik.values.RuleProfit === '<=' ? 'kurang': 'lebih'} dari atau sama dengan</b> <b>IDR&nbsp;{currency(formik.values.PriceRuleProfit, { removeSymbol: true})}</b>, order jual dikirim di harga <b>IDR&nbsp;{currency(formik.values.PriceProfit, { removeSymbol: true})}</b> sebanyak <b>{currency(formik.values.LotProfit, { removeSymbol: true})} Lot</b></p>
            </RenderIf>
          </div>

          <div className="sticky bottom-0 card-widget-gradient pt-4">
            <InputCheckBox
              id={`${uniqueId('splitOrder-')}`}
              value={formik?.values?.agreement}
              name="agreement"
              className='mb-3'
              label={
                <span className="font-quicksand-regular text-xs dark:text-black-light-1-d"> Saya menyetujui pembelian saham di halaman ini sesuai dengan{' '}
                  <a role='button' onClick={() => setOpenModalAgreement(true)} className="text-main-base-l font-quicksand-semibold">
                    Syarat dan Ketentuan.
                  </a>
                </span>
              }
              isError={formik?.errors?.agreement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <InputCheckBox
              id={`${uniqueId('confirmation-')}`}
              value={quickOrderConfirmation}
              name="confirmation"
              checked={quickOrderConfirmation}
              label={<span className='text-xs font-quicksand-regular text-black-base-l transform dark:text-black-light-1-d'>Confirm Order</span>}
              onChange={() => {
                dispatch(setQuickOrderSettingsConfirmation(!quickOrderConfirmation))
              }}
              onBlur={formik.handleBlur}
              className='mb-0'
            />
          </div>
        </form>
      </OverlayScrollbarsComponent>

      <div className='absolute left-0 w-full bottom-0'>
        <div className='border-y my-4 py-2 dark:border-gray-base-d'>
          <div className="flex items-center py-1">
            <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
              Total Transaksi
            </span>
            <h5 className="flex-grow text-right text-sm">
              {currency(Number(formik.values.Lot) * Number(formik.values.Price) * 100 || 0)}
            </h5>
          </div>

          <RenderIf isTrue={formik?.values?.stopLoss}>
            <div className="flex items-center py-1">
              <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
                Stop Loss at
              </span>
              <h5 className="flex-grow text-right text-xs text-red-base-l">
                {currency( Number(formik.values.LotStop) * (Number(formik.values.Price) - Number(formik.values.PriceStop)) * 100 , { removeSymbol: true })}
              </h5>
            </div>
          </RenderIf>

          <RenderIf isTrue={formik?.values?.takeProfit}>
            <div className="flex items-center py-1">
              <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
                Take Profit at
              </span>
              <h5 className="flex-grow text-right text-xs text-green-base-l">
                +{currency(Number(formik.values.LotStop) * (Number(formik.values.PriceProfit) - Number(formik.values.Price)) * 100 , { removeSymbol: true })}
              </h5>
            </div>
          </RenderIf>
        </div>

        <div className="grid grid-cols-2 gap-6 p-3 px-5">
          <button
            data-qa-id="btnStockBuySubmit"
            onClick={onClose}
            type="button"
            className={`card-widget-gradient text-main-base-l border-2 border-main-base-l py-3 px-3 hover:opacity-75 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100`}>
            Kembali
          </button>

          <button
            data-qa-id="btnStockBuySubmit"
            disabled={!formik.isValid && user?.realAccount}
            onClick={handleConfirmation}
            type="button"
            className={`bg-red-base-d text-white disabled:bg-opacity-[30%] hover:bg-opacity-75 py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex items-center justify-center transition-all ease-in-out duration-100`}>
            {isSubmitting && <SimpleSpinner />}
            Selanjutnya
          </button>
        </div>
      </div>

      <RenderIf isTrue={isNotRealAccount}>
        <Modal onClose={() => setIsNotRealAccount(false)}>
          <ModalExploreExpired
            showClose
            eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
            title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
            description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
            onClose={() => setIsNotRealAccount(false)}
          />
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openModalAgreement}>
        <Modal size='large' onClose={() => setOpenModalAgreement(false)}>
          <ModalAgreementSmartOrder onClose={() => setOpenModalAgreement(false)} />
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openModalConfirmationBuy}>
        <Modal onClose={() => setOpenModalConfirmationBuy(false)}>
          <ModalConfirmationSmartOrder
            isSubmitting={isSubmitting}
            data={formik?.values}
            onSubmit={() => formik.submitForm()}
            onClose={() => setOpenModalConfirmationBuy(false)}
          />
        </Modal>
      </RenderIf>
    </div>
  );
}

SmartBuyForm.propTypes = {
  onChangeStock: PropTypes.func,
  symbol: PropTypes.string,
  defaultBotName: PropTypes.string,
  formId: PropTypes.string,
  isSticky: PropTypes.bool,
  disabledStock: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onChangeSymbol: PropTypes.func,
  initialForms: PropTypes.object,
};

export default SmartBuyForm;
