import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import IconClose from 'components/svg/icon-close';
import SimpleSpinner from 'components/common/spinner/spinner';

function ModalDialog({
  header,
  children,
  footer,
  isSubmitting,
  backTitle = 'Back',
  submitTitle = 'Oke',
  onClose = () => {},
  onSubmit = () => {}
}) {
  return (
    <Card className="max-w-md mx-auto p-6 relative dark:border dark:border-gray-base-d dark:text-black-light-1-d">
      <button
        onClick={onClose}
        type="button"
        className="absolute right-4 top-4 hover:transition-all ease-linear duration-200 hover:text-main-base-l dark:text-main-base-l">
        <IconClose className="h-5 w-5" />
      </button>

      <div className="p-2">
        {header || (
          <h5 className="font-quicksand-semibold text-base">Modal Title</h5>
        )}
      </div>

      <div className="p-2">
        {children || (
          <p className="text-sm font-quicksand-semibold">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        )}
      </div>

      <div className="p-2 mt-2">
        {footer || (
          <div className="grid grid-cols-12 gap-4">
            <button
              type="button"
              onClick={onClose}
              className="col-span-5 bg-red-base-d disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d disabled:bg-opacity-100 text-white dark:text-black-base-d text-sm font-quicksand-semibold px-3 py-[10px] rounded-lg transition-all ease-linear duration-200 hover:opacity-75">
              {backTitle}
            </button>
            <button
              onClick={onSubmit}
              type="button"
              disabled={isSubmitting}
              className="col-span-7 text-white dark:text-black-base-d disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d disabled:bg-opacity-100 bg-main-base-l text-sm font-quicksand-semibold px-3 py-[10px] rounded-lg transition-all ease-linear duration-200 hover:opacity-75 flex justify-center items-center">
                {isSubmitting && <SimpleSpinner />} {submitTitle}
            </button>
          </div>
        )}
      </div>
    </Card>
  );
}

ModalDialog.propTypes = {
  header: PropTypes.any,
  children: PropTypes.node,
  footer: PropTypes.node,
  isSubmitting: PropTypes.bool,
  backTitle: PropTypes.string,
  submitTitle: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default ModalDialog;
