import PropTypes from 'prop-types';
import RenderIf from "components/basic/render-if";
import IconPlus from "components/svg/icon-plus";
import Menus from "constants/menus";
import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetAllWorkSpaceByUserIdQuery } from "services/rtk-query/workspace";
import LogoTrust from 'assets/images/logo/trust.png';
import IconChevronSingle from "components/svg/icon-chevron-single";
import IconMenu from "components/basic/icon-menu";
import { useGetOrderListQuery } from "services/rtk-query/trade";
import { triggerSegmentEvent } from 'lib/segment';
import useOuterClick from 'hooks/useOuterClick';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import useFontSize from 'hooks/useFontSize';
import cn from 'utils/classname';
import IconLightMode from 'components/svg/icon-light-mode';
import useDarkMode from 'hooks/useDarkMode';
import IconDarkMode from 'components/svg/icon-dark-mode';
import IconPinaGreenCircle from 'components/svg/icon-pina-green-cricle';

function SideMenu() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const location = useLocation();
  const { user } = useBrowserTabAuth();
  const { toggleFontSize } = useFontSize();
  const { theme, toggleTheme } = useDarkMode();

  const { data: workspaces, isSuccess: isSuccessGetWorkspace } = useGetAllWorkSpaceByUserIdQuery(user?.id);
  const { data: orderList } = useGetOrderListQuery(null, { refetchOnMountOrArgChange: true });

  const refWorkspace = useRef(null);
  const refStockMarket = useRef(null);
  const refTradingPlanner = useRef(null);
  const refCompareStock = useRef(null);
  const refPortfolio = useRef(null);
  const refOrderList = useRef(null);

  const ref = useOuterClick(() => {
    if (fullWidth) {

    }
  });

  const refCoachmarkArr = [refWorkspace, refStockMarket, refTradingPlanner, refCompareStock, refPortfolio, refOrderList];

  const [listMenu, setListMenu] = useState(Menus);
  const [fullWidth, setFullWidth] = useState(false);

  const activeClassLink = (_slug) => {
    const pathArr = location?.pathname?.split('/');
    if (pathArr?.[1] === _slug || (pathArr?.[1] === '' && !slug && _slug === 'workspace')) return 'bg-main-base-l text-white dark:text-black-base-d group-hover:text-white dark:group-hover:text-black-base-d';
    return 'bg-white dark:bg-black-base-d text-gray-light-0-d dark:text-black-light-0-d group-hover:text-white dark:group-hover:text-black-base-d';
  };

  const activeClassText = (_slug) => {
    const pathArr = location?.pathname?.split('/');
    if (pathArr?.[1] === _slug || (pathArr?.[1] === '' && !slug && _slug === 'workspace')) return 'text-main-base-l';
    return 'text-gray-light-0-d dark:text-black-light-0-d';
  };

  const activeChevronColor = (_slug) => {
    const pathArr = location?.pathname?.split('/');
    if (pathArr?.[1] === _slug || (pathArr?.[1] === '' && !slug && _slug === 'workspace')) return fullWidth ? 'dark:text-black-base-d' : 'text-main-base-l dark:text-main-base-l group-hover:text-main-base-l';
    return fullWidth ? 'dark:group-hover:text-black-base-d' : 'dark:text-black-light-0-d group-hover:text-main-base-l';
  }

  useEffect(() => {
    if (isSuccessGetWorkspace) {
      const yes = Menus?.filter((item) => item?.slug === 'workspace');
      const no = Menus?.filter((item) => item?.slug !== 'workspace');
      setListMenu([
        {
          ...yes[0],
          children: [
            {
              name: 'Default Workspace',
              slug: 'default-workspace',
              isDevide: true,
            },
            {
              name: 'Absolute Workspace',
              slug: 'absolute-workspace',
              isDevide: true,
            },
            ...workspaces,
            {
              name: 'Workspace Baru',
              slug: 'new-workspace',
              isDevide: true,
              icon: <IconPlus className="h-4 w-4" />,
              textColor: 'text-main-base-l'
            },
          ]
        },
        ...no?.map((item) => {
          if (item?.slug === 'theme') {
            return {
              ...item,
              icon: theme === 'dark' ? <IconDarkMode className="h-5 w-5" /> : <IconLightMode className="h-5 w-5" />,
              name: theme === 'dark' ? 'Dark Mode' : 'Light Mode',
            }
          }

          return item
        })
      ]);
    }
  }, [isSuccessGetWorkspace, workspaces, theme]);

  useEffect(() => setFullWidth(false), [location]);

  useEffect(() => {
    const bodyEl = document.querySelector('body');

    if (fullWidth) {
      bodyEl.classList.add('overflow-hidden');
    } else {
      bodyEl.classList.remove('overflow-hidden');
    }
  }, [fullWidth]);

  return (
    <>
      {fullWidth && <div onClick={() => setFullWidth(false)} className='h-screen w-screen fixed top-0 left-0 bg-transparent z-50'></div>}
      <nav ref={ref} className={`lg:h-screen side-menu z-50 fixed top-0 left-0 transition-all ease-linear duration-100 ${fullWidth ? 'w-64 shadow-pro h-screen side-menu-active' : 'w-16 lg:w-20 side-menu-non-active'}`}>
        <div className={`flex cursor-pointer p-3 ${!fullWidth ? 'justify-center' : 'mb-4 lg:mb-6'}`}>
          <NavLink className={cn('hidden lg:block lg:min-h-[54px]', fullWidth && '!block')} to="/">
            <div className='flex items-center justify-center'>
              <IconPinaGreenCircle className='h-9 w-auto' />
            </div>

            <div className='mx-2'>
              <h5 className='font-quicksand-bold text-2xs text-[6px] mt-1 dark:text-black-light-1-d text-center'>Powered&nbsp;By</h5>
              <img
                className="h-4 w-auto mx-auto object-contain"
                src={LogoTrust}
                alt="Trust Sekuritas"
              />
            </div>
          </NavLink>

          <RenderIf isTrue={!fullWidth}>
            <div onClick={() => setFullWidth(!fullWidth)} className='lg:hidden'>
              <IconMenu />
            </div>
          </RenderIf>
        </div>

        <ul className={`transform lg:block lg:translate-x-0 max-h-[calc(100vh-6rem)] lg:overflow-visible mt-4 ${fullWidth ? 'block translate-x-0' : 'hidden -translate-x-20'}`}>
          {listMenu?.map((item, key) => (
            <li key={key} className={`py-1.5 px-3 transition-all ease-linear duration-100 ${fullWidth && 'pr-0'}`}>
              <div className="relative group text-xs lg:text-sm" ref={refCoachmarkArr?.[key] || null}>
                <RenderIf isTrue={item?.children?.length > 0}>
                  <button
                    type="button" 
                    className={`block mx-auto focus-within:ring-0 ${fullWidth && 'w-full'}`}
                    onClick={() => {
                      if (item?.slug === 'workspace') {
                        navigate(`/default-workspace`);
                      } else {
                        navigate(`/${item?.slug}`);
                      }
                      if (item?.analyticEventName) triggerSegmentEvent({ event: item?.children?.[0]?.analyticEventName });
                    }}>
                    <div className={`h-8 rounded-full relative flex items-center group-hover:bg-main-base-l transition-all ease-linear duration-100 ${!fullWidth ? 'mx-auto w-8 justify-center': 'w-full px-2 rounded-r-none' } ${activeClassLink(item?.slug)}`}>
                      <span className="h-5 w-5 flex-none">{item?.icon}</span>
                      <span className={`ml-2 font-quicksand-semibold flex-auto transition-all ease-linear duration-100 line-clamp-1 ${!fullWidth ? 'w-0 opacity-0 absolute' : 'w-auto opacity-100 text-left'}`} dangerouslySetInnerHTML={{ __html: item?.name }}></span>

                      <RenderIf isTrue={item?.children?.length > 0}>
                        <span className={`transform -rotate-90 lg:-rotate-180 group-hover:rotate-180 absolute ${fullWidth ? 'right-2 ml-3' : '-right-5'}`}>
                          <IconChevronSingle className={`${fullWidth ? 'h-5 w-5' : 'h-4 w-4'} ${activeChevronColor(item?.slug)}`}/>
                        </span>
                      </RenderIf>
                      {item?.slug === 'order' && orderList?.items?.length > 0 ? <span className={`absolute h-[10px] w-[10px] lg:h-[12px] lg:w-[12px] bg-red-base-l border-[1px] border-white dark:border-gray-base-d rounded-full ${fullWidth ? 'left-6 lg:left-[unset] lg:right-4 top-0 lg:top-3' : '-right-[2px] -top-[2px]'}`}></span> : ''}
                    </div>
                  </button>
                </RenderIf>

                <RenderIf isTrue={item?.children?.length === 0}>
                  <Link
                    to={item?.url}
                    onClick={() => {
                      if (item?.analyticEventName) triggerSegmentEvent({ event: item?.analyticEventName });

                      if (item?.slug === 'theme') {
                        toggleTheme()
                      }

                      if (item?.slug === 'font-size') {
                        toggleFontSize()
                      }
                    }}>
                    <div className={`h-8 rounded-full relative flex items-center group-hover:bg-main-base-l group-hover:text-white dark:group-hover:text-black-base-d transition-all ease-linear duration-100 ${!fullWidth ? 'mx-auto w-8 justify-center': 'w-full px-2 rounded-r-none' } ${activeClassLink(item?.slug)}`}>
                      <span className="h-5 w-5 flex-none">{item?.icon}</span>
                      <span className={`ml-2 font-quicksand-semibold flex-auto transition-all ease-linear duration-100 line-clamp-1 ${!fullWidth ? 'w-0 opacity-0 absolute' : 'w-auto opacity-100 text-left'}`} dangerouslySetInnerHTML={{ __html: item?.name }}></span>

                      <RenderIf isTrue={item?.children?.length > 0}>
                        <span className={`transform -rotate-180 group-hover:rotate-180 absolute ${fullWidth ? 'right-2 ml-3' : '-right-5'}`}>
                          <IconChevronSingle className={`${fullWidth ? 'h-5 w-5' : 'h-4 w-4'} ${activeChevronColor(item?.slug)}`}/>
                        </span>
                      </RenderIf>
                      {item?.slug === 'order' && orderList?.items?.length ? <span className={`absolute h-[10px] w-[10px] lg:h-[12px] lg:w-[12px] bg-red-base-l border-[1px] border-white dark:border-gray-base-d rounded-full ${fullWidth ? 'left-6 lg:left-[unset] lg:right-4 top-0 lg:top-3' : '-right-[2px] -top-[2px]'}`}></span> : ''}
                    </div>
                  </Link>
                </RenderIf>

                <span className={`font-quicksand-semibold flex-auto transition-all ease-linear duration-100 text-[8px] text-center ${fullWidth ? 'hidden' : 'block'} ${activeClassText(item?.slug)}`} dangerouslySetInnerHTML={{ __html: item?.name }}></span>

                <RenderIf isTrue={item?.children?.length > 0}>
                  <div className='lg:absolute top-0 left-full z-20'>
                    <ul className={`lg:shadow-pro rounded-r-xl z-10 lg:hidden group-hover:block max-h-[400px] overflow-auto transform ${!fullWidth && 'translate-x-3'}`}>
                      {
                        item?.children?.map((chItem, chKey) => (
                          <li key={chKey} className='group bg-white dark:bg-black-base-d text-xs lg:text-sm'>
                            <Link
                              className={`bg-white dark:bg-black-base-d hover:text-main-base-l dark:hover:text-black-light-0-d font-quicksand-medium px-3 min-w-[200px] flex items-center transition-all ease-linear duration-150 ${(chItem?.slug === slug) || (!chItem?.slug && !slug) ? 'text-main-base-l bg-gray-light-l' : chItem?.textColor || 'text-gray-light-0-d dark:text-black-light-0-d dark:hover:text-main-base-l'} ${chItem?.isDevide ? 'py-[10px] border-t dark:border-gray-base-d text-main-base-l' : 'py-[8px]'}`}
                              to={['default-workspace', 'absolute-workspace'].includes(chItem?.slug) ? `/${chItem?.slug}` : `/${item?.slug}/${chItem?.slug}`}
                              onClick={() => {
                                if (item?.analyticEventName) triggerSegmentEvent({ event: chItem?.analyticEventName });
                              }}>
                              <RenderIf isTrue={chItem?.icon}>
                                {chItem?.icon}
                              </RenderIf>
                              {chItem?.name}
                            </Link>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </RenderIf>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}

SideMenu.propTypes = {
  onInitCoachMark: PropTypes.func,
};

export default SideMenu;