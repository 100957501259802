import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { currency } from 'utils/format';
import RenderIf from 'components/basic/render-if';

import styles from "./css/input-counter.module.css";
import IconPlus from 'components/svg/icon-plus';
import IconMinus from 'components/svg/icon-minus';
import useOnClickOutside from 'hooks/useOnClickOutside';
import filterNumber from 'utils/formatter/filter-number';
import clsx from 'clsx';

const InputCounter = forwardRef(({
  isAutoFocus = false,
  label = '',
  label2 = '',
  name= '',
  value = 0,
  value2 = 0,
  counterStep = 1,
  removeSymbol = true,
  disabled,
  onChange = () => {},
  max = 9999999999,
  maxValue = 9999999999,
  min = 0,
  className = 'mb-4 mt-3',
  error,
  showError = true,
  showShortcut = false,
  showValue2 = false,
  size = 'normal',
  ...props
}, refInput) => {
  const timer = useRef(null);
  const [initialValue] = useState(value);
  const [textValue, setTextValue] = useState('');
  const [focus, setFocus] = useState(false);

  const refWrapper = useRef(null);

  useOnClickOutside(refWrapper, () => setFocus(false));

  const handleChange = (e) => {
    let newVal = filterNumber(e.target.value, min);
    let formatedValue = currency(newVal, { removeSymbol });
    if (newVal < max) {
      onChange(newVal);
      setTextValue(formatedValue);
    } else {
      onChange(max);
      setTextValue(currency(max, { removeSymbol }));
    }
  };

  const handleKeyDown = (e) => {
    if (e?.key === 'Tab') setFocus(false);
    if (e?.key === 'Enter') e.preventDefault();
    if (e?.key === 'ArrowUp') handleClickButton(1);
    if (e?.key === 'ArrowDown') handleClickButton(-1);
  }

  const handleClickButton = (type, step) => {
    let newVal = filterNumber(textValue, min);
    if (type < 0 && newVal > min) newVal = newVal - (step || counterStep);
    if (type > 0 && newVal < max) newVal = newVal + (step || counterStep);

    if (newVal > max) {
      newVal = max;
    }

    let formatedValue = currency(newVal, { removeSymbol });
    onChange(newVal);
    setTextValue(formatedValue);
  };

  const handleMaxButton = () => {
    let formatedValue = currency(maxValue, { removeSymbol });
    onChange(maxValue);
    setTextValue(formatedValue);
  }

  const handleClear = () => {
    let formatedValue = currency(0);
    onChange(0);
    setTextValue(formatedValue);
  };

  // eslint-disable-next-line no-unused-vars
  const handleMouseDown = (type) => {
    // let newVal = filterNumber(textValue, min);
    // timer.current = setInterval(() => {
    //   if (type < 0 && newVal > min) newVal = newVal - counterStep;
    //   if (type > 0 && newVal < max) newVal = newVal + counterStep;

    //   let formatedValue = currency(newVal, { removeSymbol });
    //   onChange(newVal);
    //   setTextValue(formatedValue);
    // }, 250);
  };

  const handleFocus = () => {
    refInput?.current?.setSelectionRange(0, 9999999);
    setFocus(true);
  }

  const handleBlur = (e) => {
    if (props?.onBlur) {
      props?.onBlur(e);
    }
  }

  const timeoutClear = () => clearInterval(timer.current);

  useEffect(() => {
    setTextValue(currency(value, { removeSymbol }))
  }, [value, initialValue, removeSymbol]);

  useEffect(() => {
    if (isAutoFocus) {
      setTimeout(() => refInput?.current?.focus(), 500);
    }
  }, [isAutoFocus]);

  return (
    <div ref={refWrapper} className={`relative ${className} ${styles[size]}`}>
      <RenderIf isTrue={label}>
        <label htmlFor={name} className={`${styles['input-counter-label']}`}>
          {label}
        </label>
      </RenderIf>

      <div className={`grid gap-4 ${showValue2 ? 'grid-cols-2' : 'grid-cols-1'}`}>
        <div className={`${styles['input-counter-wrapper']} dark:border-black-light-1-l ${error && styles['input-counter-error']}`}>
          <button
            type="button"
            disabled={disabled || value <= min}
            className={clsx(styles['input-counter-icon-left'], 'appearance-none')}
            onClick={() => handleClickButton(-1)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleClickButton(-1)
              }
            }}
            tabIndex={-1}
            onMouseLeave={timeoutClear}
            onMouseUp={timeoutClear}
            onMouseDown={() => handleMouseDown(-1)}>
            <IconMinus className={`h-5 w-5`} />
          </button>

          <input
            ref={refInput}
            disabled={disabled}
            {...props}
            pattern="[0-9]*"
            inputMode="numeric"
            type="text"
            className={clsx(styles['input-counter-field'], 'dark:text-black-light-1-d rounded-xl')}
            placeholder="0"
            value={textValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />

          <button
            type="button"
            disabled={disabled || value >= max}
            className={styles['input-counter-icon-right']}
            onClick={() => handleClickButton(1)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleClickButton(1)
              }
            }}
            tabIndex={-1}
            onMouseLeave={timeoutClear}
            onMouseUp={timeoutClear}
            onMouseDown={() => handleMouseDown(1)}>
            <IconPlus className={`h-5 w-5`} />
          </button>
        </div>

        <RenderIf isTrue={showValue2}>
          <div className='relative'>
            <label htmlFor={name} className={`absolute -top-7 font-quicksand-semibold pointer-events-none`}>
              {label2}
            </label>
            <input disabled value={currency(value2, { removeSymbol })} type='text' className='h-full border rounded-lg outline-none bg-gray-light-l text-gray-light-0-d dark:bg-black-dark-d dark:bg-gray-base-d dark:border-gray-base-d font-quicksand-semibold px-4 w-full text-center' readOnly />
          </div>
        </RenderIf>
      </div>
      
      <RenderIf isTrue={focus && showShortcut}>
        <div className='absolute z-10 left-0 top-full w-full card-widget-gradient py-2 grid grid-cols-5 text-2xs gap-2 font-quicksand-semibold text-gray-light-0-d'>
          <button tabIndex={-1} onClick={() => handleClickButton(1, 1)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>+1</button>
          <button tabIndex={-1} onClick={() => handleClickButton(1, 10)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>+10</button>
          <button tabIndex={-1} onClick={() => handleClickButton(1, 100)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>+100</button>
          <button tabIndex={-1} onClick={() => handleClickButton(1, 1000)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>+1.000</button>
          <button tabIndex={-1} onClick={() => handleClear()} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>Clear</button>
          <button tabIndex={-1} onClick={() => handleClickButton(1, 5)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>+5</button>
          <button tabIndex={-1} onClick={() => handleClickButton(1, 50)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>+50</button>
          <button tabIndex={-1} onClick={() => handleClickButton(1, 500)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>+500</button>
          <button tabIndex={-1} onClick={() => handleClickButton(1, 5000)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>+5.000</button>
          <button tabIndex={-1} onClick={() => handleMaxButton()} onBlur={() => setFocus(false)} type='button' className='card-widget-gradient dark:text-black-light-1-d dark:border-black-light-1-l rounded-lg py-[4px] px-2 border hover:opacity-75 transition-all ease-linear duration-100 hover:text-main-base-l'>Max</button>
        </div>
      </RenderIf>

      <RenderIf isTrue={error && showError}>
        <p className="text-xs text-red-base-l font-quicksand-regular mt-1">
          {error}
        </p>
      </RenderIf>
    </div>
  );
})

InputCounter.propTypes = {
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  label2: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  value: PropTypes.number,
  value2: PropTypes.number,
  max: PropTypes.number,
  maxValue: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  counterStep: PropTypes.number,
  removeSymbol: PropTypes.bool,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  showShortcut: PropTypes.bool,
  showValue2: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default InputCounter;
