import { object, string } from 'yup';

export const NPWPValidation = object().shape({
  npwpNo: string().test('npwpNo', 'Nomor NPWP tidak valid', function (value) {
    if (value) return (value.length === 15 || value.length === 16) && /^\d+$/.test(value)
    return true;
  }),
  npwpRegistrationDate: string().test('npwpRegistrationDate', 'Tanggal Registrasi NPWP tidak boleh kosong', function (value, { parent }) {
    if (parent.npwpNo) return value;
    return true
  }),
});
