import PropTypes from 'prop-types';
import { createContext } from 'react';
import useModal from '../useModal';
import Modal from '../components/modal';

let ModalContext;
const { Provider } = (ModalContext = createContext());

const ModalProvider = ({ children }) => {
  const { modal, modalPosition, modalBackdrop, modalSize, modalLoading,  handleModal, modalContent, handleModalLoading } = useModal();
  return (
    <Provider value={{ modal, modalLoading, modalPosition, modalBackdrop,  modalSize, handleModal, modalContent, handleModalLoading }}>
      <Modal />
      {children}
    </Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
};


export { ModalContext, ModalProvider };
