import useTabActive from 'hooks/useTabActive';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setBrowserWakeUp } from 'store/pina-pro';
import { resetLoginPin } from 'store/trading';
import { getCookie } from 'utils/cookie';

export default function SessionTrading() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTabActive = useTabActive();
  const { pathname } = useLocation();

  const [countDownBrowserSleep, setCountDownBrowserSleep] = useState(60000);

  const [, setCookie] = useCookies(['login_session_expired', 'isValidPin']);

  useIdleTimer({
    timeout: 14400000,
    leaderElection: true,
    syncTimers: 200,
    crossTab: true,
    name: 'pina-pro-tab' + uniqueId(),
    onIdle: () => {
      if (!isTabActive && pathname != '/login') {
        setCookie('login_session_expired', true, { path: '/', maxAge: 10 });
        navigate('/logout');
      }
    },
    onAction: () => {
      if (getCookie('isValidPin'))
        setCookie('isValidPin', true, { path: '/', maxAge: 7200 });
    },
    debounce: 500
  });

  useEffect(() => {
    setInterval(() => {
      if (!getCookie('isValidPin')) dispatch(resetLoginPin());
    }, 1000);
  }, []);

  useEffect(() => {
    if (!isTabActive) {
      const interval = setInterval(() => {
        setCountDownBrowserSleep(countDownBrowserSleep - 1000);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setCountDownBrowserSleep(60000);
      dispatch(setBrowserWakeUp(true));
    }
  }, [countDownBrowserSleep, isTabActive]);

  useEffect(() => {
    if (countDownBrowserSleep <= 0) {
      dispatch(setBrowserWakeUp(false));
    }
  } , [countDownBrowserSleep]);
  
  return <></>;
}
