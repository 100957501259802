import PropTypes from 'prop-types';
import { isEmpty, isEqual, isNull } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currency } from 'utils/format';
import dayjs from 'dayjs';
import InputStockMultiple from 'components/input/input-stock-multiple';
import IconFilter from 'components/svg/icon-filter';
import InputSelect from 'components/input/input-select';
import InputCounter from 'components/input/input-counter';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import {
  useGetStockThematicByIdQuery,
  useGetStockThematicsQuery
} from 'services/rtk-query/stock';
import useOuterClick from 'hooks/useOuterClick';
import IconFilterActive from 'components/svg/icon-filter-active';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

let tempRunningKey = 0;

function TWRunningTrade({
  rows = 30,
  text = 'Running Trade',
  isCardLess = false,
  widgetSettings = {
    stocks: [],
    sector: widgetSettings?.sector || '99',
    price: widgetSettings?.price || 0,
    priceCondition: widgetSettings?.priceCondition || 2,
    lot: widgetSettings?.lot || 0,
    lotCondition: widgetSettings?.lotCondition || 2
  },
  onChangeWidgetSettings = () => {}
}) {
  const [firstRender, setFirstRender] = useState(true);
  const [watchStock, setWatchStock] = useState(widgetSettings?.stocks || []);
  const [options, setOptions] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [endpoint, setEnpoint] = useState(`ticker/trade`);

  const innerRef = useOuterClick(() => {
    setShowFilter(false);
  });

  const { data: thematics } = useGetStockThematicsQuery({
    type: 'sector',
    keyword: ''
  });

  const [tempFilter, setTempFilter] = useState({
    sector: widgetSettings?.sector || '99',
    price: widgetSettings?.price || 0,
    priceCondition: widgetSettings?.priceCondition || 2,
    lot: widgetSettings?.lot || 0,
    lotCondition: widgetSettings?.lotCondition || 2
  });

  const [filter, setFilter] = useState({
    sector: widgetSettings?.sector || '99',
    price: widgetSettings?.price || 0,
    priceCondition: widgetSettings?.priceCondition || 2,
    lot: widgetSettings?.lot || 0,
    lotCondition: widgetSettings?.lotCondition || 2
  });

  const isHasCustomFilter =
    !isEqual(filter, {
      sector: '99',
      price: 0,
      priceCondition: 2,
      lot: 0,
      lotCondition: 2
    }) || watchStock?.length > 0;

  const handleEndpoint = (stocks) => {
    let ep = 'ticker/trade?';
    stocks?.map((item, key) => {
      ep += `code[]=${item?.code}`;
      if (key < stocks?.length - 1) ep += '&';
    });

    setEnpoint(ep);
  };

  useEffect(() => {
    if (thematics?.length > 0) {
      const _options = thematics?.map((item) => {
        return { id: item?._id, name: item?.name };
      });

      setOptions([{ id: '99', name: 'All' }, ..._options]);
    }
  }, [thematics]);

  useEffect(() => {
    if (widgetSettings?.stocks?.length > 0 && firstRender) {
      setFirstRender(false);
      handleEndpoint(widgetSettings?.stocks);
    }
  }, [widgetSettings?.stocks, firstRender]);

  useKeyboardShortcut({
    keys: ['Dead', 'e'],
    combine: 'altKey',
    onKeyPressed: () => {
      setTempFilter({
        sector: '99',
        price: 0,
        priceCondition: 2,
        lot: 0,
        lotCondition: 2
      });

      setFilter({
        sector: '99',
        price: 0,
        priceCondition: 2,
        lot: 0,
        lotCondition: 2
      });
    }
  });

  return (
    <section
      className={`h-full ${
        !isCardLess && 'card-widget-gradient rounded-xl p-4'
      }`}>
      {text && <h5 className="mb-3 text-sm">{text}</h5>}

      <div className="flex items-center gap-2 mb-2 sticky top-0 left-0 pb-2 pt-3 card-widget-gradient -mx-4 px-4 z-10">
        <div className="flex-auto">
          <InputStockMultiple
            label={false}
            color="gold"
            className="text-xs"
            value={watchStock}
            onChange={(_stocks) => {
              setWatchStock(_stocks);
              handleEndpoint(_stocks);
              onChangeWidgetSettings({ stocks: _stocks, ...tempFilter });
            }}
          />
        </div>

        <div ref={innerRef} className="flex-none w-[48px] relative group gap-2">
          <button
            type="button"
            onClick={() => setShowFilter(!showFilter)}
            className={`flex items-center text-xs h-[32px] w-full font-quicksand-medium hover:opacity-75 relative focus-within:ring-0 text-main-base-l`}>
            {isHasCustomFilter ? (
              <IconFilterActive className={`mr-1`} />
            ) : (
              <IconFilter className={`mr-1`} />
            )}{' '}
            Filter
          </button>

          <div
            className={`min-w-[250px] z-10 p-4 -mt-2 pt-4 absolute right-0 top-12 card-widget-gradient dark:border dark:border-gray-base-d shadow-pro rounded-xl ${
              showFilter ? 'block' : 'hidden'
            }`}>
            <h5
              onClick={() => setShowFilter(false)}
              className="text-main-base-l text-sm mb-3 flex items-center cursor-pointer hover:opacity-75">
              <IconChevronSingle className="h-4 w-4 mr-1" /> Filter
            </h5>
            <InputSelect
              value={tempFilter.sector}
              label={
                <span className="text-black-base-l dark:text-black-light-0-d text-2xs">
                  Sektor
                </span>
              }
              placeholder=""
              className="mb-0"
              size="small"
              options={options}
              onChange={(e) => {
                setTempFilter({ ...tempFilter, sector: e.target.value });
              }}
            />

            <div className="flex items-center gap-4">
              <div className="flex-none w-[64px]">
                <InputSelect
                  value={tempFilter.priceCondition}
                  label={
                    <span className="text-black-base-l dark:text-black-light-0-d text-2xs">
                      Harga
                    </span>
                  }
                  placeholder=""
                  className="mb-0"
                  size="small"
                  options={[
                    { id: 0, name: '≤' },
                    { id: 1, name: '=' },
                    { id: 2, name: '≥' }
                  ]}
                  onChange={(e) => {
                    setTempFilter({
                      ...tempFilter,
                      priceCondition: e.target.value
                    });
                  }}
                />
              </div>

              <div className="flex-auto">
                <InputCounter
                  size="small"
                  data-qa-id="txtStockBuyLotField"
                  value={tempFilter.price}
                  name="price"
                  className="mb-0"
                  label={
                    <span className="text-2xs h-[32px] block">&nbsp;</span>
                  }
                  onChange={(val) =>
                    setTempFilter({ ...tempFilter, price: val })
                  }
                  showError={false}
                />
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="flex-none w-[64px]">
                <InputSelect
                  value={tempFilter.lotCondition}
                  label={
                    <span className="text-black-base-l dark:text-black-light-0-d text-2xs">
                      Jumlah Lot
                    </span>
                  }
                  placeholder=""
                  className="mb-0"
                  size="small"
                  options={[
                    { id: 0, name: '≤' },
                    { id: 1, name: '=' },
                    { id: 2, name: '≥' }
                  ]}
                  onChange={(e) => {
                    setTempFilter({
                      ...tempFilter,
                      lotCondition: e.target.value
                    });
                  }}
                />
              </div>

              <div className="flex-auto">
                <InputCounter
                  size="small"
                  data-qa-id="txtStockBuyLotField"
                  value={tempFilter.lot}
                  name="lot"
                  className="mb-0"
                  label={
                    <span className="text-2xs h-[32px] block">&nbsp;</span>
                  }
                  onChange={(val) => setTempFilter({ ...tempFilter, lot: val })}
                  showError={false}
                />
              </div>
            </div>

            <button
              type="button"
              onClick={() => {
                setFilter(tempFilter);
                setShowFilter(false);
                onChangeWidgetSettings({ stocks: watchStock, ...tempFilter });
              }}
              className="mt-4 bg-main-base-l py-[10px] px-4 w-full text-xs text-white dark:text-black-base-d font-quicksand-semibold rounded-lg hover:opacity-75">
              Terapkan
            </button>
          </div>
        </div>
      </div>

      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small overflow-hidden relative -mx-3 px-3"
        element="span"
        options={{ resize: 'horizontal' }}
        style={{ height: 'calc(100% - 50px)' }}>
        <SectionTable endpoint={endpoint} rows={rows} filter={filter} />
      </OverlayScrollbarsComponent>
    </section>
  );
}

TWRunningTrade.propTypes = {
  symbol: PropTypes.string,
  className: PropTypes.string,
  rows: PropTypes.number,
  text: PropTypes.string,
  isCardLess: PropTypes.bool,
  widgetSettings: PropTypes.object,
  onChangeWidgetSettings: PropTypes.func
};

export default TWRunningTrade;

function SectionTable({ endpoint, rows, filter }) {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const [runningTrade, setRunningTrade] = useState([]);
  const [runningKey, setRunningKey] = useState(null);
  const [counter, setCounter] = useState(0);
  const { data: sectors } = useGetStockThematicByIdQuery(filter?.sector, {
    refetchOnMountOrArgChange: true,
    skip: filter?.sector === '99'
  });
  const isBrowserWakeUp = useBrowserWakeUp();

  const isValidPrice = (price, { priceTarget, priceCondition }) => {
    if (priceCondition === 0) {
      return price <= priceTarget;
    }

    if (priceCondition === 2) {
      return price >= priceTarget;
    }

    return price === priceTarget;
  };

  const isValidLot = (lot, { lotTarget, lotCondition }) => {
    if (lotCondition === 0) {
      return lot <= lotTarget;
    }

    if (lotCondition === 2) {
      return lot >= lotTarget;
    }

    return lot === lotTarget;
  };

  useEffect(() => {
    if (isBursaOpen && endpoint && isBrowserWakeUp) {
      const sse = new EventSource(
        `${process.env.REACT_APP_API_URL}/../../${endpoint}`
      );

      sse.onmessage = (e) => {
        const resSSE = JSON.parse(e.data);

        if (
          !isEmpty(resSSE) &&
          isValidPrice(Number(resSSE?.price), {
            priceTarget: Number(filter?.price),
            priceCondition: Number(filter?.priceCondition)
          }) &&
          isValidLot(Number(resSSE?.volume) / 100, {
            lotTarget: Number(filter?.lot),
            lotCondition: Number(filter?.lotCondition)
          }) &&
          (sectors?.data?.find((item) => item?.code === resSSE?.code) ||
            filter?.sector == '99')
        ) {
          if (tempRunningKey || tempRunningKey === 0) {
            tempRunningKey++;
            setRunningKey(tempRunningKey);
          }

          setRunningTrade((prev) => {
            let prevRunningTrade = prev;

            if (prev?.length >= 30) {
              prevRunningTrade.pop();

              if (tempRunningKey > rows) {
                tempRunningKey = null;
                setRunningKey(null);
              }
            }

            return [resSSE, ...prevRunningTrade];
          });
        }
      };

      sse.onerror = () => {
        setCounter((prev) => prev + 1);
        sse.close();
      };

      return () => {
        sse.close();
      };
    }
  }, [isBursaOpen, endpoint, isBrowserWakeUp, rows, filter, sectors, counter]);

  return (
    <table className="text-2xs w-full text-center font-quicksand-semibold -mt-2">
      <thead className="text-gray-light-0-d dark:text-black-light-1-d">
        <tr>
          <td
            className="border-b border-gray-light-l dark:border-gray-base-d p-2 px-1 text-left"
            style={{ minWidth: '60px' }}>
            Times
          </td>
          <td
            className="border-b border-gray-light-l dark:border-gray-base-d p-2 px-1 text-left"
            style={{ minWidth: '70px' }}>
            Stock
          </td>
          <td
            className="border-b border-gray-light-l dark:border-gray-base-d p-2 px-1 text-left"
            style={{ minWidth: '60px' }}>
            Price
          </td>
          <td
            className="border-b border-gray-light-l dark:border-gray-base-d p-2 px-1 text-left"
            style={{ minWidth: '60px' }}>
            Lot
          </td>
        </tr>
      </thead>
      <tbody style={{ fontSize: '10px' }}>
        {Array(rows)
          .fill(1)
          .map((el, key) => (
            <tr
              className="cursor-pointer group dark:text-black-light-1-d"
              key={key}
              onClick={() => {
                tempRunningKey = key;
                setRunningKey(key);
              }}>
              <td
                className={`border-b p-1 border-gray-light-l dark:border-gray-base-d py-1 group-last:border-b-0 w-28 font-quicksand-regular text-left ${
                  runningKey === key &&
                  !isNull(runningKey) &&
                  'bg-main-base-l bg-opacity-50'
                }`}>
                {runningTrade?.[key]?.time ? (
                  <>
                    {dayjs(runningTrade?.[key]?.time, 'HHmmss').format(
                      'HH:mm:ss'
                    )}
                  </>
                ) : (
                  '-'
                )}
              </td>
              <td
                className={`border-b p-1 border-gray-light-l dark:border-gray-base-d py-1 group-last:border-b-0 w-20 text-left ${
                  runningKey === key &&
                  !isNull(runningKey) &&
                  'bg-main-base-l bg-opacity-50'
                }`}>
                {runningTrade?.[key]?.code || '-'}
              </td>
              <td
                className={`border-b p-1 border-gray-light-l dark:border-gray-base-d py-1 group-last:border-b-0 w-20 text-left ${
                  runningKey === key &&
                  !isNull(runningKey) &&
                  'bg-main-base-l bg-opacity-50'
                }`}>
                {runningTrade?.[key]?.price ? (
                  <>
                    {currency(runningTrade?.[key]?.price, {
                      removeSymbol: true
                    })}
                  </>
                ) : (
                  '-'
                )}
              </td>
              <td
                className={`border-b p-1 border-gray-light-l dark:border-gray-base-d py-1 group-last:border-b-0 w-20 text-left ${
                  runningKey === key &&
                  !isNull(runningKey) &&
                  'bg-main-base-l bg-opacity-50'
                }`}>
                {runningTrade?.[key]?.volume ? (
                  <>
                    {currency(runningTrade?.[key]?.volume / 100, {
                      removeSymbol: true
                    })}
                  </>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

SectionTable.propTypes = {
  endpoint: PropTypes.string,
  rows: PropTypes.number,
  filter: PropTypes.object
};
