import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { ProfileSchema } from './profile-form.validation';
import IconEmail from 'components/svg/icon-email';
import IconCamera from 'components/svg/icon-camera';
import RenderIf from 'components/basic/render-if';
import IconCheck from 'components/svg/icon-check';
import InputText from 'components/input/input-text';
import IconPhone from 'components/svg/icon-phone';
import IconMenuProfile from 'components/svg/icon-menu-profile';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from 'store/auth';
import { useState } from 'react';
import IconLock from 'components/svg/icon-lock';
import InputCropImage from 'components/input/input-crop-image';
import { useUpdateUserMutation } from 'services/rtk-query/user';
import { useCreateUploadAvatarMutation } from 'services/rtk-query/upload';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useWindowSize from 'hooks/useWindowSize';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import { useBrowserTabId } from 'hooks/useBrowserTabId';

function ProfileForm({ initialValues }) {
  const dispatch = useDispatch();
  const screenSize = useWindowSize();
  const { getErrorMessage } = useGetErrorMessage();

  const auth = useBrowserTabAuth();
  const browserTabID = useBrowserTabId();

  const [updateUser] = useUpdateUserMutation();
  const [uploadAvatar] = useCreateUploadAvatarMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openInputCropImage, setOpenInputCropImage] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: initialValues?.email,
      phoneNo: initialValues?.phoneNo || '',
      fullName: initialValues?.fullName
    },
    validateOnMount: false,
    validationSchema: ProfileSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const names = values?.fullName.split(' ');
      let firstName = names[0] || '';
      let lastName = names[1] || '';

      if (names.length > 2) {
        names.shift();
        lastName = names.join(' ');
      }

      await updateUser({ firstName, lastName });

      const newAuth = {
        ...auth,
        user: { ...auth.user, firstName: firstName, lastName: lastName }
      };
      
      dispatch(
        setAuth({
          ...newAuth,
          [browserTabID]: newAuth
        })
      );
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNewImage = (e) => {
    if (e.target.files[0]) {
      setOpenInputCropImage(e.target.files[0]);
    }
  };

  const handleSubmitImage = async (imgFile) => {
    setIsSubmitting(true);
    try {
      let formData = new FormData();
      formData.append('file', imgFile);
      let data = await uploadAvatar(formData).unwrap();

      const newAuth = {
        ...auth,
        user: { ...auth.user, imgAvatarUrl: data?.fileUrl }
      }

      dispatch(
        setAuth({
          ...newAuth,
          [browserTabID]: newAuth
        })
      );
      setOpenInputCropImage(false);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-5 flex justify-center">
          <div className="inline-block mx-auto">
            <label
              htmlFor="imageProfile"
              className="cursor-pointer group relative inline-block">
              <RenderIf isTrue={initialValues?.image}>
                <img
                  className="w-24 h-24 rounded-full mx-auto"
                  src={initialValues?.image}
                  alt="photo profil"
                />
              </RenderIf>

              <RenderIf isTrue={!initialValues?.image}>
                <div
                  style={{ minHeight: '96px', minWidth: '96px' }}
                  className="rounded-full bg-turquoise-2 bg-opacity-75 flex items-center justify-center h-24 w-24 mx-auto">
                  <span className="font-quicksand-bold text-4xl text-white">
                    {initialValues?.AvPP || 'A'}
                  </span>
                </div>
              </RenderIf>

              <div className="flex justify-center opacity-0 group-hover:opacity-100 absolute w-24 h-24 left-0 top-0 items-center bg-black-base-d bg-opacity-50 rounded-full transition-all ease-linear duration-100">
                <div className="block text-white text-center font-quicksand-semibold text-xs w-full">
                  <span className="flex justify-center mx-auto">
                    <IconCamera className="mr-1" />
                  </span>
                  <span className="block">Ganti Foto</span>
                </div>
              </div>

              <input
                className="opacity-0 h-0 w-0 absolute"
                id="imageProfile"
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                name="imageProfile"
                onChange={handleNewImage}
              />
            </label>

            <h5 className="text-center capitalize mt-2">
              {initialValues?.fullName?.toLowerCase()}
            </h5>

            <div className="flex justify-center">
              <RenderIf isTrue={initialValues?.realAccount}>
                <span className="flex items-center text-main-base-l font-quicksand-reguler text-xs">
                  <IconCheck className="mr-1 h-4 w-4" /> Terverifikasi
                </span>
              </RenderIf>

              <RenderIf isTrue={!initialValues?.realAccount}>
                <span className="flex items-center text-gray-light-0-d font-quicksand-reguler text-xs justify-center">
                  Belum Terverifikasi
                </span>
              </RenderIf>
            </div>
          </div>
        </div>

        <div className="p-5 text-sm">
          <InputText
            value={formik?.values?.fullName}
            name="fullName"
            placeholder="Tulis nama anda"
            label="Nama"
            icon={<IconMenuProfile className="text-gray-light-0-d" />}
            iconRight={
              initialValues?.realAccount && (
                <IconLock className="text-gray-light-0-d" />
              )
            }
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            disabled={initialValues?.realAccount}
          />

          <InputText
            value={formik?.values?.email}
            placeholder="Tulis email anda"
            label="Email"
            name="email"
            icon={<IconEmail className="text-gray-light-0-d" />}
            iconRight={<IconLock className="text-gray-light-0-d" />}
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            disabled
          />

          <RenderIf isTrue={initialValues?.realAccount}>
            <InputText
              value={formik?.values?.phoneNo}
              name="phoneNo"
              placeholder="Masukkan Nomor Handphone Kamu"
              label="No Telepon"
              icon={<IconPhone className="text-gray-light-0-d" />}
              iconRight={<IconLock className="text-gray-light-0-d" />}
              className="mb-1"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              disabled
            />
          </RenderIf>
        </div>

        <div className="p-5 pt-0 flex justify-end">
          <button
            type="submit"
            disabled={
              !formik.isValid || isSubmitting || initialValues?.realAccount
            }
            className={`flex px-10 w-full bg-main-base-l disabled:bg-opacity-75 disabled:bg-gray-base-l disabled:text-gray-dark-d disabled:cursor-not-allowed text-white items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden focus:outline-none`}>
            {isSubmitting && <Spinner />}
            Simpan
          </button>
        </div>
      </form>

      <RenderIf isTrue={openInputCropImage}>
        <Modal onClose={() => setOpenInputCropImage(false)}>
          <InputCropImage
            isSubmitting={isSubmitting}
            defaultImage={openInputCropImage}
            onSubmit={handleSubmitImage}
            loading={isSubmitting}
            onCancel={() => setOpenInputCropImage(false)}
            fileName={initialValues?.fullName}
            defaultOptions={{
              disableBoundaryChecks: false,
              allowZoomOut: false,
              position: { x: 0.5, y: 0.5 },
              scale: 1,
              rotate: 0,
              preview: null,
              borderRadius: screenSize?.width < 400 ? 250 : 350,
              width: screenSize?.width < 400 ? 250 : 350,
              height: screenSize?.width < 400 ? 250 : 350
            }}
          />
        </Modal>
      </RenderIf>
    </>
  );
}

ProfileForm.propTypes = {
  initialValues: PropTypes.object
};

export default ProfileForm;
