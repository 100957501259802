import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useEffect, useRef, useState } from 'react';
import IconSorting from 'components/svg/icon-sorting';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { currency } from 'utils/format';
import { useTableSorting } from 'hooks/useTableSorting';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { dayjsLocal } from 'utils/formatter/date';
import { useGetDoneDetailByCodeQuery } from 'services/rtk-query/stock';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import { useSelector } from 'react-redux';

function TWDoneDetail({ symbol = 'AALI', maxHeight = '350px' }) {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { stockDetail } = useRealtimeStock(symbol);
  const isBrowserWakeUp = useBrowserWakeUp();
  const refScroll = useRef();
  const [offset, setOffset] = useState(10);
  const [firstRender, setFirstRender] = useState(true);

  const {
    data: tempDoneDetails,
    isFetching,
    isLoading,
    refetch
  } = useGetDoneDetailByCodeQuery(
    {
      symbol,
      params: {
        start_date: dayjsLocal().format('MM/DD/YYYY'),
        end_date: dayjsLocal().format('MM/DD/YYYY'),
        page: 1,
        offset: offset
      }
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: isBursaOpen ? 5000 : 36000
    }
  );

  const [doneDetails, setDoneDetails] = useState([]);
  const { data, sortingColumns, handleSorting, getSorting } =
    useTableSorting(doneDetails, ['trxDate'], ['desc']);

  useEffect(() => {
    if (tempDoneDetails?.data?.length > 0) {
      let _doneDetails = [];
      tempDoneDetails?.data.map((item) => {
        _doneDetails.push({
          ...item,
          changePercent: Number((item?.change / item?.lastPrice) * 100).toFixed(
            2
          )
        });
      });

      setDoneDetails(_doneDetails);
      setFirstRender(false);
    }
  }, [tempDoneDetails?.data]);

  useEffect(() => {
    if (isBrowserWakeUp && !firstRender) refetch();
  }, [isBrowserWakeUp, firstRender]);

  return (
    <div className="px-3 pb-3">
      <OverlayScrollbarsComponent
        ref={refScroll}
        className="overlayscrollbars-host-small max-w-[82vw] lg:max-w-none"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        events={{
          scroll: async (e) => {
            const state = e.state();
            const { viewport } = refScroll.current.osInstance().elements();
            const { overflowAmount } = state;
            const { scrollTop } = viewport;

            if (scrollTop + 5 >= overflowAmount?.y && !isFetching) {
              setOffset((prev) => prev + prev);
            }
          }
        }}
        style={{ maxHeight }}>
        <table className="text-2xs w-full text-center font-quicksand-semibold dark:text-black-light-1-d">
          <thead className="text-gray-light-0-d dark:text-black-light-1-d">
            <tr className="relative">
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l sticky left-0 card-widget-gradient ${
                  sortingColumns?.[0] === 'trxDate' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('trxDate')}>
                <span className="flex items-center gap-1 justify-center">
                  <RenderIf isTrue={sortingColumns?.[0] === 'trxDate'}>
                    <IconSorting
                      type={getSorting('trxDate')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  Time{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'lastPrice' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('lastPrice')}>
                <span className="flex items-center gap-1 justify-end">
                  <RenderIf isTrue={sortingColumns?.[0] === 'lastPrice'}>
                    <IconSorting
                      type={getSorting('lastPrice')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  Price{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'change' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('change')}>
                <span className="flex items-center gap-1 justify-end">
                  <RenderIf isTrue={sortingColumns?.[0] === 'change'}>
                    <IconSorting
                      type={getSorting('change')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  Chg{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'changePercent' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('changePercent')}>
                <span className="flex items-center gap-1 justify-end">
                  <RenderIf isTrue={sortingColumns?.[0] === 'changePercent'}>
                    <IconSorting
                      type={getSorting('changePercent')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  Chg %
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'lastVolume' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('lastVolume')}>
                <span className="flex items-center gap-1 justify-end">
                  <RenderIf isTrue={sortingColumns?.[0] === 'lastVolume'}>
                    <IconSorting
                      type={getSorting('lastVolume')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  Lot
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'buyerType' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('buyerType')}>
                <span className="flex items-center gap-1 justify-end">
                  <RenderIf isTrue={sortingColumns?.[0] === 'buyerType'}>
                    <IconSorting
                      type={getSorting('buyerType')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  BT{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'buyerCode' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('buyerCode')}>
                <span className="flex items-center gap-1 justify-end">
                  <RenderIf isTrue={sortingColumns?.[0] === 'buyerCode'}>
                    <IconSorting
                      type={getSorting('buyerCode')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  B{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'sellerCode' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('sellerCode')}>
                <span className="flex items-center gap-1 justify-end">
                  <RenderIf isTrue={sortingColumns?.[0] === 'sellerCode'}>
                    <IconSorting
                      type={getSorting('sellerCode')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  S{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'sellerType' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('sellerType')}>
                <span className="flex items-center gap-1 justify-end">
                  <RenderIf isTrue={sortingColumns?.[0] === 'sellerType'}>
                    <IconSorting
                      type={getSorting('sellerType')}
                      className="h-3 w-3"
                    />
                  </RenderIf>
                  ST{' '}
                </span>
              </td>
            </tr>
          </thead>
          <tbody className="text-2xs">
            <RenderIf isTrue={data.length === 0 && !isLoading}>
              {Array(5)
                .fill(1)
                .map((el, key) => (
                  <tr key={key} className="relative">
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-center sticky left-0 card-widget-gradient">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                  </tr>
                ))}
            </RenderIf>

            <RenderIf isTrue={isLoading}>
              {Array(5)
                .fill(1)
                .map((el, key) => (
                  <tr key={key} className="relative">
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-center sticky left-0 card-widget-gradient">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                  </tr>
                ))}
            </RenderIf>

            <RenderIf isTrue={!isLoading}>
              {data?.map((item, key) => (
                <tr key={key} className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d z-10 transition-all ease-in-out duration-100 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-center sticky left-0 card-widget-gradient">
                    <span className="w-full border-r block h-full p-2 border-gray-light-l dark:border-gray-base-d">
                      {dayjsLocal(item?.trxDate)
                        .subtract(7, 'hour')
                        .format('HH:mm:ss')}
                    </span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    <span
                      className={
                        item?.lastPrice > stockDetail?.lastTradedPrice
                          ? 'text-green-base-l'
                          : item?.lastPrice < stockDetail?.lastTradedPrice
                          ? 'text-red-base-l'
                          : ''
                      }>
                      {currency(item?.lastPrice, { removeSymbol: true })}
                    </span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    <span
                      className={
                        item?.change > 0
                          ? 'text-green-base-l'
                          : item?.change < 0
                          ? 'text-red-base-l'
                          : ''
                      }>
                      {currency(item?.change, { removeSymbol: true })}
                    </span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    <span
                      className={
                        item?.changePercent > 0
                          ? 'text-green-base-l'
                          : item?.changePercent < 0
                          ? 'text-red-base-l'
                          : ''
                      }>
                      {currency(item?.changePercent, { removeSymbol: true })}%
                    </span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.lastVolume / 100, { removeSymbol: true })}
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {item?.buyerType}
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    -
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    -
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {item?.sellerType}
                  </td>
                </tr>
              ))}
            </RenderIf>
          </tbody>
        </table>
      </OverlayScrollbarsComponent>
    </div>
  );
}

TWDoneDetail.propTypes = {
  symbol: PropTypes.string,
  maxHeight: PropTypes.string
};

export default TWDoneDetail;
