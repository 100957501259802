import Card from 'components/basic/card';
import DeleteAccountForm from 'components/form/delete-account-form';
import { Helmet } from 'react-helmet-async';

function SectionHapusAkun() {
  return (
    <Card className="p-6">
      <Helmet>
        <title>Hapus Akun | Pina Trade</title>
      </Helmet>

      <h5 className="mb-5 text-lg">Hapus Akun</h5>

      <div className="text-sm">
        <p>
          Sebelum kamu melakukan Hapus Akun, PINA menganjurkan kamu untuk
          membaca beberapa ketentuan dari penghapusan akun yang kamu lakukan.
        </p>
        <br />

        <p>Hal yang perlu diperhatikan pada penghapusan akunmu antara lain:</p>
        <ol className="list-decimal ml-4">
          <li>
            Pengapusan akun yang kamu lakukan tidak akan menghapus data kamu di
            PINA secara keseluruhan. Seluruh investasi dan tujuan keuangan yang
            telah kamu buat akan tetap tersimpan.
          </li>
          <li>
            Kamu perlu melakukan registrasi ulang dengan nomor KTP yang sama
            saat upload dokumen agar dapat mengakses akun yang pernah terhubung
            sebelumnya.
          </li>
          <li>
            Akun Rekening Investasi tetap akan tersimpan dan dapat diakses jika
            melakukan registrasi dengan nomor KTP yang sama.
          </li>
          <li>
            Saldo RDN tidak akan berkurang atau hilang meskipun kamu telah
            menghapus akun.
          </li>
          <li>
            Jika kamu ingin mengapus Rekening Investasi (Rekening Dana Nasabah),
            kamu harus menghubungi atau mengunjungi Customer Support dari BNI
            secara langsung.
          </li>

          <li>
            Setelah kamu menyentang pernyataan dibawah, kamu perlu melakukan
            konfirmasi ulang dan memasukkan PIN.
          </li>
        </ol>

        <div className="mt-5">
          <DeleteAccountForm />
        </div>
      </div>
    </Card>
  );
}

export default SectionHapusAkun;
