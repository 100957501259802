import { useEffect } from 'react';
import { createPortal } from 'react-dom';

function Portal({ children, isZoomOut }) {
  const portal = document.getElementById('tooltip-root-portal');
  const el = document.createElement('div');

  if (isZoomOut) {
    portal.classList.add('zoom-2');
  } else {
    portal.classList.remove('zoom-2');
  }

  useEffect(() => {
    portal.appendChild(el);
    return () => portal.removeChild(el);
  }, [el, portal, isZoomOut]);

  return createPortal(children, el);
}

export { Portal };
