import PropTypes from 'prop-types';
import ActionButton from 'components/basic/action-button';
import Breadcrumb from 'components/basic/breadcrumb';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import SmartOrderForm from 'components/form/smart-order-form';
import IconBuy from 'components/svg/icon-buy';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import IconListBox from 'components/svg/icon-list-box';
import IconSearchPage from 'components/svg/icon-search-page';
import IconStar from 'components/svg/icon-star';
import IconStarDelete from 'components/svg/icon-star-delete';
import dayjs from 'dayjs';
import { useCompareStock } from 'hooks/useCompareStock';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import useToastr from 'hooks/useToastr';
import { triggerSegmentEvent } from 'lib/segment';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useDispatch } from 'react-redux';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { useGetDetailTradingIdeasQuery } from 'services/rtk-query/classroom';
import {
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistQuery
} from 'services/rtk-query/watchlist';
import { setSymbolAdvancedChart } from 'store/pina-pro';
import { currency } from 'utils/format';
import IconClose from 'components/svg/icon-close';

import "components/basic/css/html-render.css";
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import { useLazyGetStockByCodeQuery } from 'services/rtk-query/stock';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function PriceWithReturn({ code }) {
  const { stockDetail } = useRealtimeStock(code);

  return (
    <div className="flex items-center font-quicksand-regular">
      <span className="mx-2 block text-black-base-l dark:text-black-light-1-d font-quicksand-semibold">
        {currency(stockDetail?.lastTradedPrice || 0, { removeSymbol: true })}
      </span>
      <RenderIf isTrue={stockDetail?.score?.type === 'plus'}>
        <p className="text-sm text-green-base-l">
          +{currency(stockDetail?.score?.diff, { removeSymbol: true })} (
          {parseFloat(stockDetail?.score?.value || 0).toFixed(2)}%)
        </p>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.score?.type === 'minus'}>
        <p className="text-sm text-red-base-l">
          {currency(stockDetail?.score?.diff, { removeSymbol: true })} (
          {parseFloat(stockDetail?.score?.value || 0).toFixed(2)}%)
        </p>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.score?.type === 'neutral'}>
        <p className="text-sm text-gray-light-0-d dark:text-black-light-1-d">0 (0,00%)</p>
      </RenderIf>
    </div>
  );
}

PriceWithReturn.propTypes = {
  code: PropTypes.string
};

function StockName({ code }) {
  const { stockDetail } = useRealtimeStock(code, false);
  const { customNavigate } = useCustomNavigate();

  return (
    <>
      <button type='button' onClick={() => customNavigate({ query: { 'stock-detail': code }})} className='flex items-center mt-4 hover:opacity-75'>
        <div className="flex-none">
          <RenderIf isTrue={stockDetail?.logo}>
            <img
              src={stockDetail?.logo}
              className="h-8 w-8 rounded-full mr-2 border"
            />
          </RenderIf>

          <RenderIf isTrue={!stockDetail?.logo}>
            <span className="h-8 w-8 rounded-full mr-2 bg-gray-light-l uppercase text-sm border border-gray-base-l flex items-center justify-center text-gray-light-0-d dark:text-black-light-1-d font-quicksand-semibold">
              {code?.charAt(0)}
            </span>
          </RenderIf>
        </div>

        <div>
          <p className="text-sm text-main-base-l font-quicksand-semibold">
            {code}
          </p>

          <p className="text-xs text-gray-light-0-d dark:text-black-light-1-d">{stockDetail?.name}</p>
        </div>
      </button>
    </>
  );
}

StockName.propTypes = {
  code: PropTypes.string
};

function PinaProIdeasDetail() {
  const toastr = useToastr();
  const { user} = useBrowserTabAuth();
  const { getErrorMessage } = useGetErrorMessage();
  const { navigate, customNavigate } = useCustomNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { addToCompareStock } = useCompareStock();

  const { hash: anchorSymbol } = useLocation();
  const refAnchor = useRef();

  const { data: watchlist } = useGetWatchlistQuery();
  const { data: content, isLoading } = useGetDetailTradingIdeasQuery(slug);
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();
  const [getStockDetail] = useLazyGetStockByCodeQuery();

  const [firstRender, setFirsRender] = useState(true);
  const [openSmartOrderForm, setOpenSmartOrderForm] = useState(false);
  const [openFullImage, setOpenFullImage] = useState(false);

  const onClickSmartBuy = (orderDetail, disabledStock, initialForms = {}) => {
    setOpenSmartOrderForm({ orderDetail, disabledStock, initialForms });
  };

  const onClickImage = (title, image) => {
    setOpenFullImage({ title, image });
  };

  useEffect(() => {
    if (!anchorSymbol) {
      window.scrollTo(0, 0);
    }
  }, [slug, anchorSymbol]);

  useEffect(() => {
    if (firstRender && content) {
      triggerSegmentEvent({
        event: 'Trading Ideas Viewed',
        properties: {
          page_title: content?.title
        }
      });
      setFirsRender(false);
    }
  }, [firstRender, content]);

  useEffect(() => {
    if (content && anchorSymbol) {
      window.scrollTo({ top: 0, behavior: 'instant' });
      setTimeout(() => {
        window.scrollTo({ top: refAnchor?.current?.offsetTop - 150 });
      }, 500);
    }
  }, [content, anchorSymbol, slug]);

  if (!user?.realAccount) {
    return <Navigate to="/" />
  }

  return (
    <div className="p-4 zoom-2">
      <Helmet>
        <title>{content?.title || 'Trading Ideas'} | Pina Trade</title>
      </Helmet>

      <div className="mx-auto">
        <Breadcrumb
          data={[
            {
              link: '/ideas',
              title: 'Trading Ideas',
              icon: null
            },
            {
              link: '#',
              title: content?.title,
              icon: null
            }
          ]}
        />
      </div>

      <Card className="p-6 py-10 mt-4 pb-20">
        <div className="max-w-3xl mx-auto">
          <RenderIf isTrue={isLoading}>
            <div className="flex flex-none w-[40%] mb-4">
              <SkeletonTheme
                className="rounded-full flex-none mr-2"
                width="40px"
                height="40px"
              />
              <div className="flex-auto">
                <SkeletonTheme
                  className="rounded-md mb-1"
                  width="80%"
                  height="1rem"
                />
                <SkeletonTheme
                  className="rounded-md mb-1"
                  width="70%"
                  height="1rem"
                />
              </div>
            </div>

            <SkeletonTheme className="rounded-md mb-4 w-[50%]" height="1.5rem" />
            <SkeletonTheme className="rounded-xl mb-4 h-[300px]" />
            <SkeletonTheme className="rounded-md mb-4 w-[50%]" height="1.5rem" />
            <SkeletonTheme className="rounded-md mb-4 w-[90%]" height="1.5rem" />
          </RenderIf>

          <RenderIf isTrue={!isLoading}>
            <Link
              to={`/ideas/profile/${content?.author?._id}`}
              className="flex mb-4 dark:text-black-light-1-d">
              <div className="flex-none">
                <RenderIf isTrue={content?.author?.avatar}>
                  <img
                    src={content?.author?.avatar}
                    className="h-10 w-10 rounded-full mr-2 mt-[3px] border"
                  />
                </RenderIf>

                <RenderIf isTrue={!content?.author?.avatar}>
                  <span className="h-10 w-10 rounded-full mr-2 bg-gray-light-l font-quicksand-semibold uppercase text-sm border border-gray-light-0-d flex items-center justify-center mt-[3px]">
                    {content?.author?.name?.charAt(0)}
                  </span>
                </RenderIf>
              </div>
              <div>
                <h5 className="text-sm">{content?.author?.name}</h5>
                <p className="text-xs text-gray-light-0-d dark:text-black-light-1-d">
                  {content?.author?.title || 'Trader Expert'}
                </p>
              </div>
            </Link>

            <h5 className="text-base mb-0 dark:text-black-light-1-d">Market Update</h5>
            <p className="text-gray-light-0-d dark:text-black-light-1-d text-xs mb-3">
              {dayjs(content?.published_at).format('DD MMM YYYY')}
            </p>

            <h1 className="text-gray-dark-d dark:text-black-light-1-d my-3 text-xl dark:text-main-base-l">{content?.title}</h1>

            <img
              src={content?.featured_image?.public_url}
              className="w-full h-auto aspect-[724/300] rounded-2xl overflow-hidden border object-cover hover:opacity-75 hover:cursor-zoom-in"
              onClick={() =>
                onClickImage(
                  content?.title,
                  content?.featured_image?.public_url
                )
              }
            />

            <div
              className="flex-auto text-sm text-gray-light-0-d dark:text-black-light-1-d my-3 leading-6 html-render"
              dangerouslySetInnerHTML={{ __html: content?.content }}></div>

            <h2 className="mt-10 mb-7 dark:text-black-light-1-d">
              Rekomendasi Saham ({content?.trading_ideas?.length})
            </h2>

            <ul className="flex justify-start bg-gray-light-l dark:bg-black-dark-d p-4 pb-0 rounded-md my-3 flex-wrap">
              {content?.trading_ideas?.map((item, key) => (
                <li
                  key={key}
                  className="hover:text-main-base-l cursor-pointer group transition-all ease-linear duration-100 pb-4 px-8">
                  <h5 className="text-sm dark:text-main-base-l">{item?.code}</h5>
                  <p className="text-gray-light-0-d dark:text-black-light-1-d text-xs group-hover:text-main-base-l">
                    Buy : {currency(item?.buy, { removeSymbol: true })}
                  </p>
                  <p className="text-gray-light-0-d dark:text-black-light-1-d text-xs group-hover:text-main-base-l">
                    TP : {currency(item?.tp, { removeSymbol: true })}
                  </p>
                  <p className="text-gray-light-0-d dark:text-black-light-1-d text-xs group-hover:text-main-base-l">
                    SL : {currency(item?.sl, { removeSymbol: true })}
                  </p>

                  <div className="relative">
                    <ActionButton
                      isZoomOut
                      isLast={false}
                      position="-left-4 -bottom-9"
                      actions={[
                        {
                          title: 'Beli',
                          children: <IconBuy className="h-4 w-4" />,
                          onClick: () => {
                            customNavigate({ query: { 'simple-order': item?.code }});
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Simple Buy'
                              }
                            });
                          }
                        },
                        {
                          title: 'Smart Order',
                          children: <IconListBox className="h-4 w-4" />,
                          onClick: async () => {
                            const sd = await getStockDetail(item?.code).unwrap();

                            onClickSmartBuy(
                              { stockId: item?.code, BuySell: 'SmartBuy' },
                              false,
                              {
                                Price: item?.buy,
                                Rule: Number(sd?.lastTradedPrice) <= Number(item?.buy) ? '>=' : '<=',
                                PriceStop: item?.sl,
                                PriceProfit: item?.tp,
                                PriceRule: item?.buy,
                                PriceRuleStop: item?.sl,
                                PriceRuleProfit: item?.tp,
                                stopLoss: true,
                                takeProfit: true
                              }
                            );
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Smart Order'
                              }
                            });
                          }
                        },
                        {
                          title: 'Stock Detail',
                          children: <IconSearchPage className="h-4 w-4" />,
                          onClick: () => {
                            customNavigate({ query: { 'stock-detail': item?.code }});
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Stock Detail'
                              }
                            });
                          }
                        },
                        {
                          title: 'Trading View',
                          children: (
                            <IconChartCandleStick className="h-4 w-4" />
                          ),
                          onClick: () => {
                            customNavigate({ query: { 'advanced-chart': item?.code }});
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Advanced Chart'
                              }
                            });
                          }
                        },
                        {
                          title: 'Bandingkan',
                          children: <IconCompare className="h-4 w-4" />,
                          onClick: () => {
                            addToCompareStock(
                              { code: item?.code, name: item?.name },
                              () => {
                                navigate(`/compare/fundamental`);
                              }
                            );

                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Compare'
                              }
                            });
                          }
                        },
                        {
                          title: `${
                            watchlist?.find((w) => w?.code === item?.code)
                              ? 'Hapus Watchlist'
                              : 'Tambahkan ke Watchlist'
                          }`,
                          children: watchlist?.find(
                            (w) => w?.code === item?.code
                          ) ? (
                            <IconStarDelete className="h-4 w-4" />
                          ) : (
                            <IconStar className="h-4 w-4" />
                          ),
                          onClick: async () => {
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Watchlist'
                              }
                            });
                            if (
                              watchlist?.find((w) => w?.code === item?.code)
                            ) {
                              try {
                                await deleteWatchlist({
                                  stockCode: item?.code
                                }).unwrap();
                                toastr.success(
                                  `${item?.code} berhasil dihapus dari watchlist`
                                );
                              } catch (error) {
                                getErrorMessage(error);
                              }
                            } else {
                              try {
                                await createWatchlist({
                                  stockCode: item?.code,
                                  type: 'stock'
                                }).unwrap();
                                toastr.success(
                                  `${item?.code} berhasil ditambahkan kedalam watchlist`
                                );
                              } catch (error) {
                                getErrorMessage(error);
                              }
                            }
                          }
                        }
                      ]}
                    />
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-5 border-dashed dark:border-gray-base-d" />

            {content?.trading_ideas?.map((item, key) => (
              <div key={key} className="my-3 mb-14">
                <StockName code={item?.code} />
                
                <h2
                  ref={anchorSymbol === `#${item?.code}` ? refAnchor : null}
                  className="text-gray-dark-d dark:text-black-light-1-d text-lg mt-1">
                  {item?.title}
                </h2>

                <div className="group my-4">
                  <img
                    className="w-full h-auto group-hover:opacity-75 rounded-2xl overflow-hidden hover:cursor-zoom-in"
                    src={item?.image?.public_url}
                    onClick={() =>
                      onClickImage(item?.title, item?.image?.public_url)
                    }
                  />
                  <div className="relative h-6 mb-10">
                    <ActionButton
                      isZoomOut
                      isLast={false}
                      position="left-0 -bottom-4"
                      defaultActiveClass="flex"
                      className="shadow-none"
                      actions={[
                        {
                          title: 'Beli',
                          children: <IconBuy className="h-4 w-4" />,
                          onClick: () => {
                            customNavigate({ query: { 'simple-order': item?.code }});
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Simple Buy'
                              }
                            });
                          }
                        },
                        {
                          title: 'Smart Order',
                          children: <IconListBox className="h-4 w-4" />,
                          onClick: async () => {
                            const sd = await getStockDetail(item?.code).unwrap();

                            onClickSmartBuy(
                              { stockId: item?.code, BuySell: 'SmartBuy' },
                              false,
                              {
                                Price: item?.buy,
                                Rule: Number(sd?.lastTradedPrice) <= Number(item?.buy) ? '>=' : '<=',
                                PriceStop: item?.sl,
                                PriceProfit: item?.tp,
                                PriceRule: item?.buy,
                                PriceRuleStop: item?.sl,
                                PriceRuleProfit: item?.tp,
                                stopLoss: true,
                                takeProfit: true
                              }
                            );
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Smart Order'
                              }
                            });
                          }
                        },
                        {
                          title: 'Stock Detail',
                          children: <IconSearchPage className="h-4 w-4" />,
                          onClick: () => {
                            customNavigate({ query: { 'stock-detail': item?.code }});
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Stock Detail'
                              }
                            });
                          }
                        },
                        {
                          title: 'Trading View',
                          children: (
                            <IconChartCandleStick className="h-4 w-4" />
                          ),
                          onClick: () => {
                            dispatch(setSymbolAdvancedChart(item?.code));
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Advanced Chart'
                              }
                            });
                          }
                        },
                        {
                          title: 'Bandingkan',
                          children: <IconCompare className="h-4 w-4" />,
                          onClick: () => {
                            addToCompareStock(
                              { code: item?.code, name: item?.name },
                              () => {
                                navigate(`/compare/fundamental`);
                              }
                            );

                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Compare'
                              }
                            });
                          }
                        },
                        {
                          title: `${
                            watchlist?.find((w) => w?.code === item?.code)
                              ? 'Hapus Watchlist'
                              : 'Tambahkan ke Watchlist'
                          }`,
                          children: watchlist?.find(
                            (w) => w?.code === item?.code
                          ) ? (
                            <IconStarDelete className="h-4 w-4" />
                          ) : (
                            <IconStar className="h-4 w-4" />
                          ),
                          onClick: async () => {
                            triggerSegmentEvent({
                              event: 'Trading idea hover state clicked',
                              properties: {
                                action: 'Watchlist'
                              }
                            });
                            if (
                              watchlist?.find((w) => w?.code === item?.code)
                            ) {
                              try {
                                await deleteWatchlist({
                                  stockCode: item?.code
                                }).unwrap();
                                toastr.success(
                                  `${item?.code} berhasil dihapus dari watchlist`
                                );
                              } catch (error) {
                                getErrorMessage(error);
                              }
                            } else {
                              try {
                                await createWatchlist({
                                  stockCode: item?.code,
                                  type: 'stock'
                                }).unwrap();
                                toastr.success(
                                  `${item?.code} berhasil ditambahkan kedalam watchlist`
                                );
                              } catch (error) {
                                getErrorMessage(error);
                              }
                            }
                          }
                        }
                      ]}
                    />
                  </div>
                </div>

                <div className="text-sm mt-4 text-gray-light-0-d dark:text-black-light-1-d font-quicksand-semibold flex items-center mb-1">
                  <button type='button' onClick={() => customNavigate({ query: { 'stock-detail': item?.code }})} className="text-main-base-l pr-2 border-r-2 block hover:opacity-75">
                    {item?.code}
                  </button>{' '}
                  <PriceWithReturn code={item?.code} />
                </div>

                <p className="flex items-center text-sm mb-4 text-gray-light-0-d dark:text-black-light-1-d">
                  <span className="mr-3">
                    Buy:{' '}
                    <b className="text-black-base-l dark:text-black-light-1-d">
                      {currency(item?.buy, { removeSymbol: true })}
                    </b>
                  </span>
                  <span className="mr-3">
                    Take Profit:{' '}
                    <b className="text-black-base-l dark:text-black-light-1-d">
                      {currency(item?.tp, { removeSymbol: true })}
                    </b>
                  </span>
                  <span className="mr-3">
                    Stop Loss:{' '}
                    <b className="text-black-base-l dark:text-black-light-1-d">
                      {currency(item?.sl, { removeSymbol: true })}
                    </b>
                  </span>
                </p>

                <div
                  className="flex-auto text-sm text-gray-light-0-d dark:text-black-light-1-d leading-6 html-render"
                  dangerouslySetInnerHTML={{ __html: item?.content }}></div>
              </div>
            ))}
          </RenderIf>
        </div>
      </Card>

      <RenderIf isTrue={openFullImage}>
        <Modal size='full' onClose={() => setOpenFullImage(false)}>
          <Card className="p-6 relative dark:border dark:border-gray-base-d mb-20">
            <button
              type="button"
              className="absolute right-4 top-4 hover:text-main-base-l dark:text-main-base-l"
              onClick={() => setOpenFullImage(false)}>
              <IconClose />
            </button>
            
            <h1 className="my-3 text-xl dark:text-black-light-1-d">{openFullImage?.title}</h1>

            <img
              src={openFullImage?.image}
              className="w-full h-auto rounded-2xl overflow-hidden border object-cover"
            />
          </Card>
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openSmartOrderForm}>
        <Modal size='full' onClose={() => setOpenSmartOrderForm(false)}>
          <SmartOrderForm
            formType="Smart Order"
            initialForms={openSmartOrderForm?.initialForms}
            orderDetail={openSmartOrderForm?.orderDetail}
            disabledStock={openSmartOrderForm?.disabledStock}
            onClose={() => setOpenSmartOrderForm(false)}
            onSuccess={() => setOpenSmartOrderForm(false)}
          />
        </Modal>
      </RenderIf>
    </div>
  );
}

export default PinaProIdeasDetail;
