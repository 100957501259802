import PropTypes from 'prop-types';

function IconChartPieHover({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.15372 15.5606C1.84504 14.0088 2.00347 12.4003 2.60897 10.9384C3.21447 9.47663 4.23985 8.2272 5.55544 7.34815C6.71227 6.57518 8.04779 6.11913 9.42905 6.02031C10.3309 5.95578 11 6.69211 11 7.49991V12.9999L16.5 12.9999C17.3078 12.9999 18.0441 13.669 17.9796 14.5709C17.8808 15.9521 17.4247 17.2876 16.6518 18.4445C15.7727 19.7601 14.5233 20.7854 13.0615 21.3909C11.5997 21.9964 9.99113 22.1549 8.43928 21.8462C6.88743 21.5375 5.46197 20.7756 4.34315 19.6568C3.22433 18.5379 2.4624 17.1125 2.15372 15.5606ZM4.45673 11.7038C4.0026 12.8002 3.88378 14.0066 4.11529 15.1705C4.3468 16.3343 4.91825 17.4034 5.75736 18.2425C6.59648 19.0817 7.66558 19.6531 8.82946 19.8846C9.99335 20.1161 11.1997 19.9973 12.2961 19.5432C13.3925 19.0891 14.3295 18.32 14.9888 17.3333C15.4611 16.6265 15.7757 15.8307 15.9161 14.9999H11C9.89543 14.9999 9 14.1045 9 12.9999L9 8.08382C8.16919 8.22426 7.37341 8.53881 6.66658 9.01109C5.67989 9.67038 4.91085 10.6075 4.45673 11.7038Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3.49991C13 2.69213 13.6691 1.95578 14.5709 2.02031C15.4261 2.08149 16.2671 2.27984 17.0615 2.60887C18.0321 3.01091 18.914 3.60018 19.6569 4.34305C20.3997 5.08592 20.989 5.96783 21.391 6.93844C21.7201 7.73279 21.9184 8.57382 21.9796 9.42896C22.0441 10.3308 21.3078 10.9999 20.5 10.9999H15C13.8954 10.9999 13 10.1045 13 8.99991L13 3.49991ZM15 4.08383L15 8.99991H19.9161C19.8411 8.55628 19.7163 8.12144 19.5433 7.70381C19.2417 6.97585 18.7998 6.31442 18.2426 5.75727C17.6855 5.20011 17.0241 4.75816 16.2961 4.45663C15.8785 4.28364 15.4436 4.15881 15 4.08383Z"
      />
    </svg>
  );
}

IconChartPieHover.propTypes = {
  className: PropTypes.string
};

export default IconChartPieHover;
