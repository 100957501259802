import baseApi from '../base';
import { TAG_WORKSPACE, TAG_PORTFOLIO_TERMS } from '../tags';

export const WorkspaceAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllWorkSpaceByUserId: build.query({
      query: (userId) => {
        return {
          url: `../pina-admin/api/v1/user-workspace?userId=${userId}`
        };
      },
      transformResponse: (res) => {
        let _tempData = [];
        res?.data?.map((item) => {
          let _layout = JSON.parse(item?.layout);
          Object.keys(_layout)?.map((breakPoint) => {
            _layout?.[breakPoint]?.map(function(x) { 
              x.static = false; 
              x.minW = x?.minW || 4;
              x.w = x.w < 4 ? 4 : x.w;
              x.minH = x?.minH || 16;
              // TODO: Revemo Soon
              x.widget = { ...x.widget, title: x?.widget?.title === 'Multi Order' ? 'Fast Order' : x?.widget?.title }
              // ENDTODO
              return x
            });
          })

          _tempData?.push({...item, layout: JSON.stringify(_layout)});
        })

        return _tempData || [];
      },
      providesTags: () => {
        return [{ type: TAG_WORKSPACE, id: 'LIST' }];
      }
    }),
    getAllWorkSpaceByUserId2: build.query({
      query: (userId) => {
        return {
          url: `../pina-admin/api/v1/user-workspace?userId=${userId}`
        };
      },
      transformResponse: (res) => res?.data || [],
      providesTags: () => {
        return [{ type: TAG_WORKSPACE, id: 'LIST' }];
      }
    }),
    createWorkSpaceByUserId: build.mutation({
      query: ({ body, userId }) => {
        return {
          url: `../pina-admin/api/v1/user-workspace?userId=${userId}`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: [{ type: TAG_WORKSPACE, id: 'LIST' }]
    }),
    updateWorkSpaceById: build.mutation({
      query: ({ body, id }) => {
        return {
          url: `../pina-admin/api/v1/user-workspace/${id}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: [{ type: TAG_WORKSPACE, id: 'LIST' }]
    }),
    updateWorkSpaceByIdSkipInvalidate: build.mutation({
      query: ({ body, id }) => {
        return {
          url: `../pina-admin/api/v1/user-workspace/${id}`,
          method: 'PUT',
          body
        };
      }
    }),
    deleteWorkSpaceById: build.mutation({
      query: (id) => {
        return {
          url: `../pina-admin/api/v1/user-workspace/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: [{ type: TAG_WORKSPACE, id: 'LIST' }]
    }),
    createPortfolioTerms: build.mutation({
      query: ({ userId, payload }) => {
        return {
          url: `../pina-admin/api/v1/portfolio-terms?userId=${userId}`,
          method: 'POST',
          body: { payload: JSON.stringify(payload) }
        };
      },
      invalidatesTags: [{ type: TAG_PORTFOLIO_TERMS, id: 'LIST' }]
    }),
    getPortfolioTerms: build.query({
      query: (userId) => {
        return {
          url: `../pina-admin/api/v1/portfolio-terms`,
          params: { userId }
        };
      },
      transformResponse: (res) => {
        return res?.data
          ? { id: res?.data?.id, payload: JSON.parse(res?.data?.payload) }
          : {
              id: null,
              payload: {
                ['short-term']: {
                  title: 'Short Term',
                  name: 'short-term',
                  items: []
                },
                ['medium-term']: {
                  title: 'Medium Term',
                  name: 'medium-term',
                  items: []
                },
                ['long-term']: {
                  title: 'Long Term',
                  name: 'long-term',
                  items: []
                }
              }
            };
      },
      providesTags: () => {
        return [{ type: TAG_PORTFOLIO_TERMS, id: 'LIST' }];
      }
    }),
    updatePortfolioTerms: build.mutation({
      query: ({ payload, id }) => {
        return {
          url: `../pina-admin/api/v1/portfolio-terms/${id}`,
          method: 'PUT',
          body: { payload: JSON.stringify(payload) }
        };
      },
      invalidatesTags: [{ type: TAG_PORTFOLIO_TERMS, id: 'LIST' }]
    })
  })
});

export const {
  useGetAllWorkSpaceByUserIdQuery,
  useGetAllWorkSpaceByUserId2Query,
  useCreateWorkSpaceByUserIdMutation,
  useUpdateWorkSpaceByIdMutation,
  useUpdateWorkSpaceByIdSkipInvalidateMutation,
  useDeleteWorkSpaceByIdMutation,
  useCreatePortfolioTermsMutation,
  useGetPortfolioTermsQuery,
  useUpdatePortfolioTermsMutation
} = WorkspaceAPI;
