export const checkCodeNotation = (code) => {
  switch (code) {
    case 'A':
      return 'Adverse opinion of the financial report from Public Accountant';
    case 'B':
      return 'Permohonan Pailit kepada Perusahaan Tercatat';
    case 'C':
      return 'Lawsuit against Listed Company, its subsidiary, and/or member of Board of Directors and Board of Commissioners of Listed Company which has Material impact';
    case 'D':
      return 'Opini laporan keuangan Tidak Menyatakan Pendapat (Disclaimer)';
    case 'E':
      return 'Ekuitas negatif';
    case 'F':
      return 'Administrative sanction from OJK due to minor offense';
    case 'G':
      return 'Administrative sanction from OJK due to moderate offense';
    case 'I':
      return 'Perusahaan Tercatat yang tidak menerapkan Saham Dengan Hak Suara Multipel dan tercatat di Papan Ekonomi Baru';
    case 'K':
      return 'Perusahaan Tercatat yang menerapkan Saham Dengan Hak Suara Multipel dan tercatat di Papan Ekonomi Baru';
    case 'L':
      return 'Belum menyampaikan laporan keuangan';
    case 'M':
      return 'Penundaan Kewajiban Pembayaran Utang (PKPU)';
    case 'N':
      return 'Perusahaan Tercatat merupakan Emiten yang menerapkan Saham Dengan Hak Suara Multipel';
    case 'Q':
      return 'Restriction of business activity of Listed Company and/or its subsidiary by regulator';
    case 'S':
      return 'Tidak memiliki pendapatan usaha';
    case 'V':
      return 'Administrative sanction from OJK due to serious offense';
    case 'X':
      return 'Efek Bersifat Ekuitas Dalam Pemantauan Khusus';
    case 'Y':
      return 'Listed Company has not held Annual General Meeting of Shareholders until 6 (six) months after the end of previous year';
    case 'CD':
      return 'Cum Dividen';
    case 'CB':
      return 'Cum Bonus';
    case 'CR':
      return 'Cum HMETD';
    case 'CA':
      return 'Kombinasi dari lebih masa cum (dividen tunai, dividen saham dan /atau cum bonus)';
    case 'XD':
      return 'Ex Dividen';
    case 'XB':
      return 'Ex Bonus';
    case 'XR':
      return 'Ex HMETD';
    case 'XA':
      return 'Kombinasi dari masa Ex (dividen tunai, dividen saham dan /atau cum bonus)';
    case 'SS':
      return 'Pemecahan nilai nominal saham/Stock Split';
    case 'RS':
      return 'Penggabungan nilai nominal saham/Reverse Stock Spllit';
    case 'Margin':
      return 'Saham yang bisa diperdagangkan dengan fasilitas marjin';
    case 'Short':
      return 'Saham yang bisa diperdagangkan secara marjin dan dapat mengakibatkan posisi short';
    case 'U':
      return 'Saham yang tidak dapat diperdagangkan dengan menggunakan fasilitas marjin';
    case 'Designated':
      return 'Saham yang masuk dalam daftar Efek Tidak Dijamin/Designated Stock';
    case '-':
      return 'Saham tidak dapat diperdagangkan melalui sesi Pre-Opening';
    case 'O':
      return 'Saham dapat diperdagangkan melalui sesi Pre-Opening';
    case '1':
      return 'Saham dicatatkan di Papan Utama dan masuk dalam penghitungan Indeks Papan Utama';
    case '2':
      return 'Saham dicatatkan di Papan Pengembangan dan masuk dalam penghitungan Indeks Papan Pengembangan';
    case 'ACCEL':
      return 'Saham dicatatkan di Papan Akselerasi dan masuk dalam penghitungan Indeks Papan Akselerasi';
    default:
      return '';
  }
};
