import RegisterForm from 'components/form/register-form';
import { Helmet } from 'react-helmet-async';

function RegisterPage() {
  return (
    <>
      <Helmet>
        <title>Pendaftaran Akun | Pina Trade</title>
      </Helmet>

      <div className="flex items-center justify-center lg:min-h-screen bg-white lg:bg-transparent">
        <div className="p-0 lg:p-10">
          <RegisterForm />
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
