import PropTypes from 'prop-types';
import ActionButton from 'components/basic/action-button';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import InputSelectDropDown from 'components/input/input-select-dropdown';
import IconBuy from 'components/svg/icon-buy';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import IconPlus from 'components/svg/icon-plus';
import IconSearchPage from 'components/svg/icon-search-page';
import IconSorting from 'components/svg/icon-sorting';
import IconStar from 'components/svg/icon-star';
import IconStarDelete from 'components/svg/icon-star-delete';
import { ListParameterScreener } from 'constants/list-parameter-screener';
import { useCompareStock } from 'hooks/useCompareStock';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import { useTableSorting } from 'hooks/useTableSorting';
import useToastr from 'hooks/useToastr';
import { triggerSegmentEvent } from 'lib/segment';
import { map, uniqBy } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistQuery
} from 'services/rtk-query/watchlist';
import { automatedNumberFormat } from 'utils/format';
import IconClose from 'components/svg/icon-close';
import { findParamStockScreenerByFieldName } from 'utils/stock/screener';
import Pagination from 'components/basic/pagination';
import { useLazyGetStockScreenerQuery } from 'services/rtk-query/stock';
import {
  checkFundamentalSuffix,
  checkMultiplier
} from 'utils/stock/fundamental';
import { setAdditionalMetrics } from 'store/stock';
import Modal from 'components/modal/modal';
import useCustomNavigate from 'hooks/useCustomNavigate';

function ResultScreener({ payloads = null, results = [] }) {
  const dispatch = useDispatch();
  const { navigate, customNavigate } = useCustomNavigate();
  const toastr = useToastr();
  const { addToCompareStock } = useCompareStock();
  const { getErrorMessage } = useGetErrorMessage();
  const { activeScreener, additionalMetrics } = useSelector(
    ({ stock }) => stock?.stockScreener
  );

  const [tempResults, setTempResults] = useState(results);
  const [group_metrics, setGroupMatrics] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [openSearchParameter, setOpenSearchParameter] = useState(false);

  const [getStockScreener] = useLazyGetStockScreenerQuery();
  const { data, sortingColumns, pagination, handleSorting, getSorting } =
    useTableSorting(tempResults, [], [], { perPage: 15, page: activePage });

  const { data: watchlist } = useGetWatchlistQuery();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();

  const handleAddParams = () => {
    triggerSegmentEvent({
      event: 'TR Add More Parameter from Screener Result'
    });
    setOpenSearchParameter(true);
  };

  useEffect(() => {
    if (payloads) {
      let _metrics = [];
      payloads?.group_metrics?.forEach((metric) => {
        metric?.metrics?.forEach((param) => {
          _metrics?.push({
            name: param?.param,
            paramCompare: param?.paramCompare
          });
          if (param?.paramCompareType === 'Parameter') {
            _metrics?.push({
              ...param,
              name: param?.paramCompare,
              paramCompare: param?.paramCompare
            });
          }
        });
      });

      setGroupMatrics(
        uniqBy([..._metrics, ...(additionalMetrics || [])], 'name')
      );
    }
  }, [payloads, additionalMetrics]);

  useEffect(() => {
    if (group_metrics?.length > 0) {
      handleSorting(`${group_metrics?.[0]?.name}`);
      handleSorting(`${group_metrics?.[0]?.name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_metrics]);

  useEffect(() => {
    setTempResults(results);
    setActivePage(0);

    if (results?.length === 0) setGroupMatrics([]);
  }, [results]);

  return (
    <Card className="p-6 dark:text-black-light-1-d">
      <h2 className="text-sm mb-6">
        Screener Result{' '}
        {activeScreener?.id && (
          <span className="font-quicksand-regular text-gray-light-0-d">
            - {activeScreener?.name}
          </span>
        )}
      </h2>

      <div className="flex justify-between gap-4 text-xs mb-3">
        <p className="font-quicksand-semibold">
          {pagination?.totalContent} Saham
        </p>
        <RenderIf isTrue={data?.length > 0}>
          <button
            type="button"
            onClick={handleAddParams}
            className="text-main-base-l hover:opacity-75 flex items-center font-quicksand-semibold">
            <IconPlus className="h-4 w-4 mr-1" />
            Tambah Kolom Parameter
          </button>
        </RenderIf>
      </div>

      <Pagination
        className="my-2"
        {...pagination}
        onChangePage={setActivePage}
      />

      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}>
        <table className="w-full text-2xs">
          <thead className="text-gray-light-0-d font-quicksand-semibold">
            <tr>
              <td
                className={`min-w-[200px] border-b border-gray-light-l dark:border-gray-base-d sticky left-0 card-widget-gradient hover:text-main-base-l ${
                  sortingColumns?.[0] === 'stock_code' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('stock_code')}>
                <span className="flex items-center gap-1 cursor-pointer w-full py-[4px]">
                  {sortingColumns?.[0] === 'stock_code' && (
                    <IconSorting
                      type={getSorting('stock_code')}
                      className="h-3 w-3"
                    />
                  )}
                  Emiten
                </span>
              </td>

              {group_metrics?.map((item, key) => (
                <td
                  key={key}
                  className={`p-2 py-[6px] min-w-[100px] border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === `${item?.name}` &&
                    'text-main-base-l'
                  }`}
                  onClick={() => handleSorting(`${item?.name}`)}>
                  <span className="flex items-center justify-between">
                    <div className="flex items-center gap-1 justify-end cursor-pointer flex-auto">
                      {sortingColumns?.[0] === `${item?.name}` && (
                        <IconSorting
                          type={getSorting(`${item?.name}`)}
                          className="h-3 w-3 flex-none"
                        />
                      )}
                      <span className="flex-auto whitespace-nowrap">
                        {findParamStockScreenerByFieldName(item?.name)?.name}
                      </span>
                    </div>

                    <RenderIf isTrue={item?.isAddParams}>
                      <button
                        type="button"
                        className="appearance-none outline-none"
                        onClick={() => {
                          setGroupMatrics(
                            group_metrics?.filter((m) => m?.name != item?.name)
                          );
                          dispatch(
                            setAdditionalMetrics(
                              additionalMetrics?.filter(
                                (m) => m?.name != item?.name
                              )
                            )
                          );
                        }}>
                        <IconClose className="ml-1 h-4 w-4 flex-none text-gray-light-0-d hover:text-main-base-l" />
                      </button>
                    </RenderIf>
                  </span>
                </td>
              ))}
              <td className="w-full border-b border-gray-light-l dark:border-gray-base-d"></td>
            </tr>
          </thead>

          <tbody className="font-quicksand-semibold">
            <RenderIf isTrue={data?.length > 0}>
              {data?.map((item, key) => (
                <tr
                  key={key}
                  className="border-b dark:border-gray-base-d last:border-b-0 group dark:text-black-light-1-d">
                  <td className="sticky left-0 card-widget-gradient group-hover:z-10">
                    <div className="py-1">
                      <div className="flex items-center mx-auto w-full">
                        <RenderIf isTrue={item?.logo}>
                          <img
                            src={item?.logo}
                            className="h-6 w-6 border rounded-full mr-2"
                          />
                          {item?.stock_code}
                        </RenderIf>

                        <RenderIf isTrue={!item?.logo}>
                          <div className="rounded-full mr-1 flex-none bg-gray-light-l dark:bg-black-base-d bg-opacity-50 text-2xs flex items-center justify-center h-6 w-6">
                            {item?.stock_code?.charAt(0)}
                          </div>
                          {item?.stock_code}
                        </RenderIf>
                      </div>
                      <p
                        className="text-gray-light-0-d mt-[1px] font-quicksand-regular text-2xs line-clamp-1"
                        title={item?.name}>
                        {item?.name}
                      </p>
                    </div>
                    <div className="relative z-20">
                      <ActionButton
                        isZoomOut
                        isLast={true}
                        position={`left-0 ${
                          key >= data?.length - 1 ? 'bottom-10' : '-bottom-8'
                        }`}
                        actions={[
                          {
                            title: 'Beli',
                            children: <IconBuy className="h-4 w-4" />,
                            onClick: () =>
                              customNavigate({
                                query: { 'simple-order': item?.stock_code }
                              })
                          },
                          {
                            title: 'Stock Detail',
                            children: <IconSearchPage className="h-4 w-4" />,
                            onClick: () => {
                              triggerSegmentEvent({
                                event: 'TR Search Feature Clicked'
                              });
                              customNavigate({
                                query: { 'stock-detail': item?.stock_code }
                              });
                            }
                          },
                          {
                            title: 'Trading View',
                            children: (
                              <IconChartCandleStick className="h-4 w-4" />
                            ),
                            onClick: () =>
                              customNavigate({
                                query: { 'advanced-chart': item?.stock_code }
                              })
                          },
                          {
                            title: 'Bandingkan',
                            children: <IconCompare className="h-4 w-4" />,
                            onClick: () => {
                              triggerSegmentEvent({
                                event: 'TR Compare Stock Clicked'
                              });
                              addToCompareStock(
                                { code: item?.stock_code, name: item?.name },
                                () => {
                                  navigate(`/compare/fundamental`);
                                }
                              );
                            }
                          },
                          {
                            title: `${
                              watchlist?.find(
                                (w) => w?.code === item?.stock_code
                              )
                                ? 'Hapus Watchlist'
                                : 'Tambahkan ke Watchlist'
                            }`,
                            children: watchlist?.find(
                              (w) => w?.code === item?.stock_code
                            ) ? (
                              <IconStarDelete className="h-4 w-4" />
                            ) : (
                              <IconStar className="h-4 w-4" />
                            ),
                            onClick: async () => {
                              triggerSegmentEvent({
                                event: 'TR Watchlist Clicked'
                              });
                              if (
                                watchlist?.find(
                                  (w) => w?.code === item?.stock_code
                                )
                              ) {
                                try {
                                  await deleteWatchlist({
                                    stockCode: item?.stock_code
                                  }).unwrap();
                                  toastr.success(
                                    `${item?.stock_code} berhasil dihapus dari watchlist`
                                  );
                                } catch (error) {
                                  getErrorMessage(error);
                                }
                              } else {
                                try {
                                  await createWatchlist({
                                    stockCode: item?.stock_code,
                                    type: 'stock'
                                  }).unwrap();
                                  toastr.success(
                                    `${item?.stock_code} berhasil ditambahkan kedalam watchlist`
                                  );
                                } catch (error) {
                                  getErrorMessage(error);
                                }
                              }
                            }
                          }
                        ]}
                      />
                    </div>
                  </td>

                  {group_metrics?.map((metric, key) => (
                    <td
                      key={key}
                      className={`py-2 text-right px-4 pl-2 ${
                        Number(item?.[metric?.name]) < 0 && 'text-red-base-l'
                      }`}>
                      {metric?.name &&
                        automatedNumberFormat(
                          item?.[metric?.name] *
                            (checkFundamentalSuffix(
                              findParamStockScreenerByFieldName(metric?.name)
                                ?.name
                            ) === '%'
                              ? checkMultiplier(
                                  findParamStockScreenerByFieldName(
                                    metric?.name
                                  )?.name
                                )
                              : 1)
                        )}
                      {checkFundamentalSuffix(
                        findParamStockScreenerByFieldName(metric?.name)?.name
                      ) === '%'
                        ? '%'
                        : checkFundamentalSuffix(
                            findParamStockScreenerByFieldName(metric?.name)
                              ?.name
                          ) === 'x'
                        ? 'x'
                        : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </RenderIf>
          </tbody>
        </table>
      </OverlayScrollbarsComponent>

      <Pagination
        className="mt-4"
        {...pagination}
        onChangePage={setActivePage}
      />

      <RenderIf isTrue={openSearchParameter}>
        <Modal onClose={() => setOpenSearchParameter(false)}>
          <InputSelectDropDown
            placeholder="Cari Parameter"
            options={ListParameterScreener}
            onChange={async (value) => {
              const newMetrics = uniqBy(
                [
                  ...group_metrics,
                  ...(additionalMetrics || []),
                  { ...value, name: value?.fieldName, isAddParams: true }
                ],
                'name'
              );
              const response = await getStockScreener({
                ...payloads,
                filters: map(newMetrics, 'name')
              }).unwrap();
              setTempResults(response);
              dispatch(setAdditionalMetrics(newMetrics));
              setOpenSearchParameter(false);
            }}
            onClose={() => setOpenSearchParameter(false)}
            onValidate={(data) => {
              return group_metrics?.find((i) => i?.name === data?.fieldName);
            }}
          />
        </Modal>
      </RenderIf>
    </Card>
  );
}

ResultScreener.propTypes = {
  payloads: PropTypes.any,
  results: PropTypes.any
};

export default ResultScreener;
