import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Datafeed from './tv-api-chart-idx';
import { widget } from 'assets/library/charting_library';
import getAllFilterChart from 'utils/chart/filter';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { LIST_SYMBOL_IDX } from 'constants/list-symbol';
import useDarkMode from 'hooks/useDarkMode';
import { useSelector } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

let tvWidget = {};

function TVRealtimeChartIDX({
  symbol = 'COMPOSITE',
  interval = '1D',
  libraryPath = '/assets/scripts/trading-view/charting_library/',
  // chartsStorageUrl = process.env.REACT_APP_API_URL,
  chartsStorageApiVersion = 'light',
  clientId = '',
  userId = '',
  fullscreen = false,
  autosize = true,
  disabled_features = ['use_localstorage_for_settings'],
  studiesOverrides = {},
  overrides = {
    priceScaleSelectionStrategyName: 'auto',
    'paneProperties.topMargin': 10,
    'timeScale.rightOffset': -50,
    'paneProperties.crossHairProperties.color': '#9E9E9E',
    'paneProperties.vertGridProperties.color': 'transparent',
    'paneProperties.horzGridProperties.color': 'transparent',
    'scalesProperties.fontSize': 10,
    'paneProperties.backgroundType': 'solid',
    // 'scalesProperties.textColor': '#000',
    'mainSeriesProperties.priceLineWidth': 2,
    'mainSeriesProperties.style': 3,
    'mainSeriesProperties.areaStyle.color1': 'rgba(158, 158, 158, 0.3)',
    'mainSeriesProperties.areaStyle.color2': '#9E9E9E',
    'mainSeriesProperties.areaStyle.linecolor': '#9E9E9E',
    'mainSeriesProperties.showPrevClosePriceLine': true,
    'mainSeriesProperties.highLowAvgPrice.highLowPriceLinesColor': true,
    'mainSeriesProperties.highLowAvgPrice.highLowPriceLinesVisible': true,
    'mainSeriesProperties.highLowAvgPrice.highLowPriceLabelsVisible': true,
    'mainSeriesProperties.highLowAvgPrice.averageClosePriceLineVisible': true,
    'mainSeriesProperties.highLowAvgPrice.averageClosePriceLabelVisible': true,
    'scalesProperties.showSeriesPrevCloseValue': true,
    'scalesProperties.showStudyLastValue': true,
    'scalesProperties.showStudyPlotLabels': true,
    'sessions.vertlines.sessBreaks.color': '#9E9E9E',
    'sessions.vertlines.sessBreaks.visible': true,
    'sessions.vertlines.sessBreaks.width': 1
  },
  isRerender = false,
  filter = null,
  onChangeSymbol = () => {}
}) {
  const { isLogin } = useBrowserTabAuth();
  const ref = useRef(null);
  const { theme } = useDarkMode();
  const { stockDetail, isLoadingStock } = useRealtimeStock(symbol);
  const [firstRender, setFirstRender] = useState(false);

  let widgetOptions = {
    debug: false,
    symbol: symbol,
    theme,
    datafeed: Datafeed,
    interval: interval,
    library_path: libraryPath,
    locale: 'id_ID',
    timezone: 'Asia/Jakarta',
    disabled_features: disabled_features,
    enabled_features: ['study_templates'],
    // charts_storage_url: chartsStorageUrl,
    charts_storage_url: 'https://api.pina.id/api/tradingview',
    charts_storage_api_version: theme === 'dark' ? 'dark' : chartsStorageApiVersion,
    client_id: clientId,
    user_id: userId,
    custom_css_url: '/assets/styles/custom-tradingview-themed.css',
    custom_font_family: "'Quicksand', sans-serif",
    fullscreen: fullscreen,
    autosize: autosize,
    studies_overrides: studiesOverrides,
    overrides: overrides,
    load_last_chart: true,
    auto_save_delay: 5,
    time_frames: [
      {
        text: '15y',
        resolution: '1W'
      },
      {
        text: '10y',
        resolution: '3D'
      },
      {
        text: '5y',
        resolution: '1D'
      },
      {
        text: '3y',
        resolution: '1D'
      },
      {
        text: '1y',
        resolution: '1D'
      },
      {
        text: '6m',
        resolution: '1D'
      },
      {
        text: '3m',
        resolution: '1D'
      },
      {
        text: '1m',
        resolution: '1D'
      },
      {
        text: '5d',
        resolution: '1D'
      },
      {
        text: '1d',
        resolution: '1'
      }
    ],
    symbol_search_complete: (_symbol, searchResultItem) => {
      return new Promise((resolve) => {
        onChangeSymbol(searchResultItem?.symbol);
        resolve(searchResultItem);
      });
    },
  };

  if (theme === 'dark') {
    widgetOptions.overrides = {
      ...widgetOptions.overrides,
      'paneProperties.background': '#1C1C1E',
    }
  } else {
    widgetOptions.overrides = {
      ...widgetOptions.overrides,
      'paneProperties.background': '#FFFFFF',
    }
  }

  const upColorSchema = {
    'mainSeriesProperties.areaStyle.color1': 'rgba(123, 204, 41, 0.3)',
    'mainSeriesProperties.areaStyle.color2': '#7BCC29',
    'mainSeriesProperties.areaStyle.linecolor': 'rgba(123, 204, 41, 0.7)',
    // 'scalesProperties.textColor': '#000',
    'mainSeriesProperties.style': 3
  };

  const drawColorSchema = {
    'mainSeriesProperties.areaStyle.color1': 'rgba(158, 158, 158, 0.3)',
    'mainSeriesProperties.areaStyle.color2': '#9E9E9E',
    'mainSeriesProperties.areaStyle.linecolor': '#9E9E9E',
    // 'scalesProperties.textColor': '#000',
    'mainSeriesProperties.style': 3
  };

  const downColorSchema = {
    'mainSeriesProperties.areaStyle.color1': 'rgba(241, 100, 40, 0.3)',
    'mainSeriesProperties.areaStyle.color2': '#F16528',
    'mainSeriesProperties.areaStyle.linecolor': 'rgba(241, 100, 40, 0.8)',
    // 'scalesProperties.textColor': '#000',
    'mainSeriesProperties.style': 3
  };

  useEffect(() => {
    const options = {
      ...widgetOptions,
      container: ref.current
    };

    if (window?.LIST_SYMBOL?.length > 0 && isLogin && ref.current) {
      setFirstRender(false);
      if (isRerender && !firstRender) {
        setTimeout(() => {
          tvWidget[symbol] = null;
          window.countRender[symbol] = 0;
          tvWidget[symbol] = new widget(options);
  
          tvWidget?.[symbol]?.onChartReady(() => {
            setFirstRender(true);
            console.log('Chart Loaded!');
          });
        }, 500);
      } else {
        tvWidget[symbol] = null;
        tvWidget[symbol] = new widget(options);
  
        tvWidget?.[symbol]?.onChartReady(() => {
          setFirstRender(true);
          console.log('Chart Loaded!');
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol, isLogin, window?.LIST_SYMBOL, theme, isLoadingStock]);

  useEffect(() => {
    if (filter && firstRender && stockDetail && ref.current) {
      let colorSchema = drawColorSchema;
      if (stockDetail?.score?.type === 'plus') colorSchema = upColorSchema;
      if (stockDetail?.score?.type === 'minus') colorSchema = downColorSchema;

      try {
        tvWidget?.[symbol]?.onChartReady(() => {
          const filters = getAllFilterChart();
          let _filter = filters?.find((item) => item?.text === filter);

          tvWidget?.[symbol]?.activeChart()?.setResolution(_filter?.resolution);
          tvWidget?.[symbol]?.activeChart()?.setVisibleRange({
            from: _filter?.startDate,
            to: _filter?.endDate
          });
          tvWidget?.[symbol]?.applyOverrides({ ...widgetOptions.overrides, ...colorSchema });

          setTimeout(() => {
            tvWidget?.[symbol]
              ?.activeChart()
              ?.setResolution(_filter?.resolution);
            tvWidget?.[symbol]?.activeChart()?.setVisibleRange({
              from: _filter?.startDate,
              to: _filter?.endDate
            });
          }, 100);
        });
      } catch (error) {
        console.log(error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, firstRender, symbol, stockDetail, isLogin, isLoadingStock]);

  if (isLoadingStock) {
    return (
      <div className='p-2'>
        <SkeletonTheme
          width="100%"
          height="100%"
          borderRadius="6px"
        />
      </div>
    )
  }

  return <div ref={ref} className="h-full" />;
}

TVRealtimeChartIDX.propTypes = {
  symbol: PropTypes.string,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  libraryPath: PropTypes.string,
  chartsStorageUrl: PropTypes.string,
  chartsStorageApiVersion: PropTypes.string,
  clientId: PropTypes.string,
  userId: PropTypes.string,
  fullscreen: PropTypes.bool,
  autosize: PropTypes.bool,
  disabled_features: PropTypes.array,
  studiesOverrides: PropTypes.object,
  overrides: PropTypes.object,
  isRerender: PropTypes.bool,
  filter: PropTypes.string,
  onChangeSymbol: PropTypes.func,
};

export default TVRealtimeChartIDX;
