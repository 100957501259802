import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isBursaOpen: false,
  isShowIepIev: false,
  symbolAdvancedChart: null,
  symbolPriceAlert: null,
  watchStockList: [
    {
      code: '',
      name: ''
    },
    {
      code: '',
      name: ''
    },
    {
      code: '',
      name: ''
    }
  ],
  symbolQuickOrders: [],
  recentSymbol: [],
  unreadNotifLength: 0,
  favoriteWidgets: [],
  coachMarkWorkspace: 0,
  coachMarkPortfolio: 0,
  coachMarkPortfolioList: [],
  showNPSSurvicate: false,
  theme: 'light',
  isSetTheme: false,
  browserWakeUp: true,
};

export const pinaPro = createSlice({
  name: 'pinaPro',
  initialState,
  reducers: {
    setBursaOpen: (state, { payload }) => {
      state.isBursaOpen = payload;
    },
    setShowIepIev: (state, { payload }) => {
      state.isShowIepIev = payload;
    },
    setWatchStockList: (state, { payload }) => {
      state.watchStockList = payload;
    },
    setSymbolAdvancedChart: (state, { payload }) => {
      state.symbolAdvancedChart = payload;
    },
    setSymbolPriceAlert: (state, { payload }) => {
      state.symbolPriceAlert = payload;
    },
    setSymbolQuickOrders: (state, { payload }) => {
      state.symbolQuickOrders = payload;
    },
    setRecentSymbol: (state, { payload }) => {
      state.recentSymbol = payload;
    },
    setUnreadNotifLength: (state, { payload }) => {
      state.unreadNotifLength = payload;
    },
    setFavoriteWidgets: (state, { payload }) => {
      state.favoriteWidgets = payload;
    },
    setCoachMarkWorkspace: (state, { payload }) => {
      state.coachMarkWorkspace = payload;
    },
    setCoachMarkPortfolio: (state, { payload }) => {
      state.coachMarkPortfolio = payload;
    },
    setCoachMarkPortfolioList: (state, { payload }) => {
      state.coachMarkPortfolioList = payload;
    },
    setShowNPSSurvicate: (state, { payload }) => {
      state.showNPSSurvicate = payload;
    },
    setTheme: (state, { payload }) => {
      state.theme = payload;
    },
    setIsSetTheme: (state) => {
      state.isSetTheme = true;
    },
    setBrowserWakeUp: (state, { payload }) => {
      state.browserWakeUp = payload;
    },
  }
});

export const pinaProPersistConfig = {
  key: 'pn-trade-pina-pro',
  whitelist: [
    'watchList',
    'stockTrendingList',
    'stockRankList',
    'symbolQuickOrders',
    'recentSymbol',
    'watchStockList',
    'favoriteWidgets',
    'showNPSSurvicate',
    'theme',
    'isSetTheme',
  ]
};

export const {
  setBursaOpen,
  setShowIepIev,
  setWatchStockList,
  setSymbolAdvancedChart,
  setSymbolPriceAlert,
  setSymbolQuickOrders,
  setRecentSymbol,
  setUnreadNotifLength,
  setFavoriteWidgets,
  setCoachMarkWorkspace,
  setCoachMarkPortfolio,
  setCoachMarkPortfolioList,
  setShowNPSSurvicate,
  setTheme,
  setIsSetTheme,
  setBrowserWakeUp
} = pinaPro.actions;
export default pinaPro.reducer;
