import PropTypes from 'prop-types';
import Breadcrumb from 'components/basic/breadcrumb';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { triggerSegmentEvent } from 'lib/segment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { Link, useParams } from 'react-router-dom';
import {
  useGetAuthorByIdQuery,
  useGetTradingIdeasQuery
} from 'services/rtk-query/classroom';
import { currency } from 'utils/format';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function PriceWithReturn({ code }) {
  const { stockDetail } = useRealtimeStock(code);

  return (
    <div className="flex items-center font-quicksand-regular">
      <span className="mx-2 block text-black-base-l font-quicksand-semibold">
        {currency(stockDetail?.lastTradedPrice || 0, { removeSymbol: true })}
      </span>
      <RenderIf isTrue={stockDetail?.score?.type === 'plus'}>
        <p className="text-sm text-green-base-l">
          +{currency(stockDetail?.score?.diff, { removeSymbol: true })} (
          {parseFloat(stockDetail?.score?.value || 0).toFixed(2)}%)
        </p>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.score?.type === 'minus'}>
        <p className="text-sm text-red-base-l">
          {currency(stockDetail?.score?.diff, { removeSymbol: true })} (
          {parseFloat(stockDetail?.score?.value || 0).toFixed(2)}%)
        </p>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.score?.type === 'neutral'}>
        <p className="text-sm text-gray-light-0-d dark:text-black-light-1-d">0 (0,00%)</p>
      </RenderIf>
    </div>
  );
}

PriceWithReturn.propTypes = {
  code: PropTypes.string
};

function StockName({ code }) {
  const { stockDetail } = useRealtimeStock(code, false);

  return (
    <>
      <p className="text-sm mt-4 text-main-base-l font-quicksand-semibold">{code}</p>

      <p className="text-sm text-gray-light-0-d dark:text-black-light-1-d">{stockDetail?.name}</p>
    </>
  );
}

StockName.propTypes = {
  code: PropTypes.string
};

function PinaProIdeasProfileDetail() {
  const { slug } = useParams();
  const { user} = useBrowserTabAuth();
  const [firstRender, setFirsRender] = useState(true);
  const [isNotRealAccount, setIsNotRealAccount] = useState(false);

  const [params] = useState({
    page: 1,
    limit: 100,
    'c[author]': slug,
    populate: `populate=category,featured_image,featured_image_square,author`
  });

  const { data: response, isLoading } = useGetTradingIdeasQuery(params);
  const { data: author } = useGetAuthorByIdQuery(slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  useEffect(() => {
    if (firstRender && response && author) {
      triggerSegmentEvent({
        event: 'Trading Ideas Profile Viewed',
        properties: {
          page_title: `Profile - ${author?.name}`
        }
      });
      setFirsRender(false);
    }
  }, [firstRender, response, author]);

  return (
    <div className="p-4 zoom-2">
      <Helmet>
        <title>{`Profile - ${author?.name}`} | Pina Trade</title>
      </Helmet>

      <div className="mx-auto">
        <Breadcrumb
          data={[
            {
              link: '/ideas',
              title: 'Trading Ideas',
              icon: null
            },
            {
              link: '#',
              title: `Profile - ${author?.name || 'Trader'}`,
              icon: null
            }
          ]}
        />
      </div>

      <Card className="p-6 py-10 mt-4 pb-20">
        <div className="max-w-[630px] mx-auto">
          <RenderIf isTrue={isLoading}>
            <div className="flex flex-none w-[40%] mb-4 items-center">
              <SkeletonTheme
                className="rounded-full flex-none mr-2"
                width="80px"
                height="80px"
              />
              <div className="flex-auto">
                <SkeletonTheme
                  className="rounded-md mb-1"
                  width="80%"
                  height="1rem"
                />
                <SkeletonTheme
                  className="rounded-md mb-1"
                  width="70%"
                  height="1rem"
                />
              </div>
            </div>

            <SkeletonTheme className="rounded-md mb-4 w-[50%]" height="1.5rem" />
            <SkeletonTheme className="rounded-xl mb-4 h-[1.5rem]" />
            <SkeletonTheme className="rounded-xl mb-4 h-[1.5rem]" />
            <SkeletonTheme className="rounded-xl mb-4 h-[1.5rem]" />
            <SkeletonTheme className="rounded-md mb-4 w-[50%]" height="1.5rem" />
            <SkeletonTheme className="rounded-md mb-4 w-[90%]" height="1.5rem" />
          </RenderIf>

          <RenderIf isTrue={!isLoading}>
            <div className="flex mb-4 items-center dark:text-black-light-1-d">
              <div className="flex-none">
                <RenderIf isTrue={author?.avatar}>
                  <img
                    src={author?.avatar}
                    className="h-20 w-20 rounded-full mr-4 mt-[3px] border"
                  />
                </RenderIf>

                <RenderIf isTrue={!author?.avatar}>
                  <span className="h-20 w-20 rounded-full mr-4 bg-gray-light-l font-quicksand-semibold uppercase text-2xl border border-gray-light-0-d flex items-center justify-center mt-[3px]">
                    {author?.name?.charAt(0)}
                  </span>
                </RenderIf>
              </div>
              <div>
                <h5 className="text-xl">{author?.name}</h5>
                <p className="text-sm text-gray-light-0-d dark:text-black-light-1-d">
                  {author?.title || 'Trader Expert'}
                </p>
              </div>
            </div>
            

            <h5 className="text-base mb-0 dark:text-black-light-1-d">Deskripsi</h5>
            <p className="text-gray-light-0-d dark:text-black-light-1-d text-sm mb-3 leading-6">
              {author?.bio || <>&nbsp;</>}
            </p>

            <h1 className="mt-8 mb-3 dark:text-black-light-1-d">
              Ide Trading ({response?.data?.length || 0})
            </h1>
          </RenderIf>

          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 flex-auto">
            {response?.data?.map((item, key) => (
              <Card
                key={key}
                className="overflow-hidden rounded-xl shadow-none border dark:border-gray-base-d">
                <Link
                  to={user?.realAccount ? `/ideas/${item?._id}` : '#'}
                  className="transition-all ease-linear duration-100 hover:opacity-75 cursor-pointer block"
                  onClick={() => {
                    if (!user?.realAccount) setIsNotRealAccount(true);
                    triggerSegmentEvent({
                      event: 'Trading Ideas Clicked',
                      properties: {
                        page_title: item?.title
                      }
                    });
                  }}>
                  <img
                    src={item?.featured_image?.public_url}
                    className="w-full h-auto aspect-[588/242] object-cover rounded-lg overflow-hidden border"
                  />

                  <div className="px-4 pb-4">
                    <h3 className="text-main-base-l my-3 text-sm">{item?.title}</h3>

                    <div className="flex mb-3">
                      <div className="flex-none">
                        <RenderIf isTrue={author?.avatar}>
                          <img
                            src={author?.avatar}
                            className="h-6 w-6 rounded-full mr-2 mt-[3px] border"
                          />
                        </RenderIf>

                        <RenderIf isTrue={!author?.avatar}>
                          <span className="h-6 w-6 rounded-full mr-2 bg-gray-light-l font-quicksand-semibold uppercase text-2xs border border-gray-light-0-d flex items-center justify-center mt-[3px]">
                            {author?.name?.charAt(0)}
                          </span>
                        </RenderIf>
                      </div>
                      <div>
                        <h5 className="text-sm dark:text-black-light-1-d">{author?.name}</h5>
                        <p className="text-xs text-gray-light-0-d dark:text-black-light-1-d">
                          {author?.title || 'Trader Expert'}
                        </p>
                      </div>
                    </div>

                    <div className={`flex-auto text-xs text-justify text-gray-light-0-d dark:text-black-light-1-d ${user?.realAccount ? 'line-clamp-4': 'line-clamp-2'}`}>
                      {item?.excerpt}
                    </div>
                  </div>
                </Link>
              </Card>
            ))}

            <RenderIf isTrue={isLoading}>
              {[0, 1, 2, 3]?.map((item) => (
                <div key={item}>
                  <SkeletonTheme className="rounded-md mb-2" height="120px" />
                  <SkeletonTheme
                    className="rounded-md mb-2 w-[90%]"
                    height="1.2rem"
                  />
                  <SkeletonTheme
                    className="rounded-md mb-2 w-[40%]"
                    height="1.2rem"
                  />

                  <div className="flex flex-none my-4">
                    <SkeletonTheme
                      className="rounded-full flex-none mr-2"
                      width="32px"
                      height="32px"
                    />
                    <div className="flex-auto">
                      <SkeletonTheme
                        className="rounded-md mb-1"
                        width="80%"
                        height="1rem"
                      />
                      <SkeletonTheme
                        className="rounded-md mb-1"
                        width="50%"
                        height="1rem"
                      />
                    </div>
                  </div>

                  <SkeletonTheme
                    className="rounded-md mb-2 w-[90%]"
                    height="1.2rem"
                  />
                  <SkeletonTheme
                    className="rounded-md mb-2 w-[90%]"
                    height="1.2rem"
                  />
                </div>
              ))}
            </RenderIf>
          </div>
        </div>
      </Card>

      <RenderIf isTrue={isNotRealAccount}>
        <Modal onClose={() => setIsNotRealAccount(false)}>
          <ModalExploreExpired
            showClose
            eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
            title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
            description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
            onClose={() => setIsNotRealAccount(false)}
          />
        </Modal>
      </RenderIf>
    </div>
  );
}

export default PinaProIdeasProfileDetail;
