import { PinaTradeFirstTimeExplore, PinaTradeFirstTimeRDN, PinaTradeTradingIdeasCoachmark, PinaTradeWorkspaceAfterCreated, PinaTradeWorkspaceBeforeCreated } from 'constants/coachmark';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import { useGetUserCoachMarkByIdQuery } from 'services/rtk-query/user';
import useBrowserTabAuth from './useBrowserTabAuth';
import useBrowserTabTrading from './useBrowserTabTrading';

const useCoachMark = () => {
  const { user } = useBrowserTabAuth();
  const { isLoginPin: isLoginTradingPin} = useBrowserTabTrading();

  const { data: coachMarks, isError: errorCoachMark } = useGetUserCoachMarkByIdQuery(user?.id, { skip: !user?.id});
  const { data: trading, isError: errorTradingPin } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });

  const [cmRDN, setShowCMRDN] = useState(false);
  const [cmFirstTimeExplore, setCMFirstTimeExplore] = useState(false);
  const [cmWorkspaceInit, setCMWorkspaceInit] = useState(false);
  const [cmWorkspaceCreated, setCMWorkspaceCreated] = useState(false);
  const [cmTradingIdeas, setCMTradingIdeas] = useState(false);

  useEffect(() => {
    if (isLoginTradingPin && !errorCoachMark && !errorTradingPin && coachMarks?.length && trading) {
      // SHOW MODAL FIRST TIME RDN
      setShowCMRDN(!coachMarks?.includes(PinaTradeFirstTimeRDN) && !trading?.isTopup && user?.realAccount);

      // SHOW MODAL FIRST TIME EXPLORE
      setCMFirstTimeExplore(!coachMarks?.includes(PinaTradeFirstTimeExplore) && !user?.realAccount);

      // SHOW MODAL FIRST TIME WORKSPACE BEFORE CREATED
      setCMWorkspaceInit(!coachMarks?.includes(PinaTradeWorkspaceBeforeCreated) && (coachMarks?.includes(PinaTradeFirstTimeRDN) || (trading?.isTopup || isEmpty(trading) && coachMarks?.includes(PinaTradeFirstTimeExplore))));
      
      // SHOW MODAL FIRST TIME WORKSPACE AFTER CREATED
      setCMWorkspaceCreated(!coachMarks?.includes(PinaTradeWorkspaceAfterCreated) && coachMarks?.includes(PinaTradeWorkspaceBeforeCreated));
      
      // SHOW MODAL FIRST TIME TRADING IDEAS
      setCMTradingIdeas(!coachMarks?.includes(PinaTradeTradingIdeasCoachmark) && coachMarks?.includes(PinaTradeWorkspaceAfterCreated));
    }
  }, [coachMarks, trading, isLoginTradingPin, errorCoachMark, errorTradingPin, user]);

  return { coachMarks, cmRDN, cmWorkspaceInit, cmWorkspaceCreated, cmTradingIdeas, cmFirstTimeExplore };
};

export { useCoachMark };
