import RenderIf from 'components/basic/render-if';
import PriceAlertForm from 'components/form/price-alert-form';
import IconBell from 'components/svg/icon-bell';
import IconBellDelete from 'components/svg/icon-bell-delete';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import {
  useDeletePriceAlertMutation,
  useGetAllPriceAlertQuery
} from 'services/rtk-query/price-alert';
import { currency } from 'utils/format';
import Modal from 'components/modal/modal';

function ListPriceAlert() {
  const { getErrorMessage } = useGetErrorMessage();
  const { data: priceAlerts, isFetching } = useGetAllPriceAlertQuery();
  const [deletePriceAlert] = useDeletePriceAlertMutation();

  const [data, setData] = useState([]);
  const [openPriceAlertForm, setOpenPriceAlertForm] = useState(false);

  const handleClickPriceAlert = async (priceAlert) => {
    try {
      await deletePriceAlert({ id: priceAlert?._id }).unwrap();
    } catch (error) {
      getErrorMessage(error);
    }
  };

  useEffect(() => {
    if (priceAlerts?.length > 0) {
      setData(filter(priceAlerts, (item) => item?.is_active));
    }
  }, [priceAlerts]);

  return (
    <>
      <RenderIf isTrue={data?.length === 0 && !isFetching}>
        <p className="text-sm text-gray-light-0-d text-center">Belum ada price alert</p>
      </RenderIf>

      <RenderIf isTrue={isFetching}>
        <SkeletonTheme className='mb-4' width="100%" height="3rem" borderRadius="10px" />
        <SkeletonTheme className='mb-4' width="100%" height="3rem" borderRadius="10px" />
        <SkeletonTheme className='mb-4' width="100%" height="3rem" borderRadius="10px" />
      </RenderIf>

      <ul className="-mx-6 px-6 pb-6">
        {data?.map((item, key) => (
          <li
            key={key}
            className="flex justify-between items-center border-b dark:border-gray-base-d hover:bg-gray-light-l dark:hover:bg-black-dark-d border rounded-xl cursor-pointer hover:bg-opacity-50 p-4 py-3 mb-4">
            <div
              className="flex flex-auto justify-between"
              onClick={() => setOpenPriceAlertForm(item)}>
              <div className="flex items-center">
                <RenderIf isTrue={item?.logo}>
                  <img
                    className="h-10 w-10 rounded-full border border-gray-light-l"
                    src={item?.logo}
                    alt={item?.code}
                  />
                </RenderIf>

                <RenderIf isTrue={!item?.logo}>
                  <div className="flex items-center justify-center bg-gray-base-l rounded-full h-10 w-10 border border-gray-light-l font-quicksand-semibold">
                    {item?.code?.charAt(0)}
                  </div>
                </RenderIf>

                <span className="ml-2 font-quicksand-semibold text-sm">
                  {item?.code}
                </span>
              </div>

              <div className="font-quicksand-semibold text-right">
                <h5 className="text-gray-light-0-d font-quicksand-regular text-xs">
                  Target Harga
                  {item?.when === 'upper' && ' Lebih Besar'}
                  {item?.when === 'lower' && ' Lebih Kecil'}
                  {item?.when === 'equal' && ' Sama Dengan'}
                </h5>
                <p>{currency(item?.price, { removeSymbol: true })}</p>
              </div>
            </div>

            <div className="flex-none w-10">
              <a role="button" onClick={() => handleClickPriceAlert(item)} >
                {item?.is_active ? <IconBell className="h-7 w-7 text-main-base-l cursor-pointer ml-3" /> : <IconBellDelete className="h-7 w-7 text-main-base-l cursor-pointer ml-3" />}
              </a>
            </div>
          </li>
        ))}
      </ul>

      <RenderIf isTrue={openPriceAlertForm}>
        <Modal onClose={() => setOpenPriceAlertForm(false)}>
          <PriceAlertForm
            symbol={openPriceAlertForm?.code}
            initialValues={{
              when: openPriceAlertForm?.when,
              price: openPriceAlertForm?.price,
              id: openPriceAlertForm?._id
            }}
            onSuccess={() => setOpenPriceAlertForm(false)}
            onClose={() => setOpenPriceAlertForm(false)}
          />
        </Modal>
      </RenderIf>
    </>
  );
}

export default ListPriceAlert;
