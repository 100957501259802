import Card from 'components/basic/card';
import { Helmet } from 'react-helmet-async';

function SectionKebijakanPrivasi() {
  return (
    <Card className="p-6">
      <Helmet>
        <title>Kebijakan Privasi | Pina Trade</title>
      </Helmet>

      <h5 className="mb-5 text-lg">Kebijakan Privasi</h5>

      <div className="text-sm">
        <p>
          Terima kasih sudah menjadi bagian dari komunitas Kami di PT TRUST
          SEKURITAS {`("PINA" atau "Kami")`}. PINA berkomitmen untuk melindungi
          Informasi Pribadi dan privasi kamu. Jika ada pertanyaan seputar
          kebijakan privasi atau cara kerja PINA berkaitan dengan Informasi
          Pribadi kamu, silakan hubungi PINA di{' '}
          <a
            className="hover:text-orange-100 underline"
            href="mailto:cs@pina.id">
            cs@pina.id
          </a>
        </p>

        <br />

        <p>
          Kebijakan Privasi ini menjelaskan bagaimana PINA menggunakan informasi
          dan data pribadi saat kamu:
        </p>

        <ul style={{ listStyleType: 'disc' }} className="ml-8">
          <li>
            mengunduh (<span className="italic">download</span>) aplikasi{' '}
            <span className="italic">mobile</span> PINA.
          </li>
          <li>
            berinteraksi dengan Kami dalam berbagai cara termasuk melakukan
            transaksi jual-beli atau terlibat dalam aktivitas lainnya.
          </li>
        </ul>

        <br />

        <p>
          Dalam Kebijakan Privasi ini, berikut beberapa definisi yang perlu kamu
          ketahui:
        </p>

        <ul style={{ listStyleType: 'disc' }} className="ml-8">
          <li>
            {`"Aplikasi"`} merujuk pada aplikasi apa pun milik PINA yang merujuk
            pada atau dikaitkan dengan Kebijakan Privasi ini, termasuk yang
            telah disebutkan di atas.
          </li>
          <li>
            {`"Layanan"`} merujuk pada Aplikasi dan layanan PINA lainnya,
            termasuk transaksi jual-beli, kelas PINA, dan aktivitas lainnya
          </li>
          <li>
            {`"Informasi Pribadi"`} merujuk pada data milik pengguna yang
            mengidentifikasi diri pengguna yang bersangkutan.
          </li>
        </ul>
        <br />
        <p>
          Kebijakan Privasi ini bertujuan untuk menjabarkan sejelas mungkin
          informasi yang Kami kumpulkan, bagaimana Kami menggunakannya, serta
          hak dan kendali kamu berkaitan dengan informasi tersebut. Jika ada hal
          apa pun yang tidak kamu setujui dalam Kebijakan Privasi ini, silakan
          segera hentikan penggunaan Layanan Kami.
        </p>
        <br />
        <p className="font-quicksand-bold">
          Silakan baca Kebijakan Privasi ini dengan teliti untuk membantu kamu
          memahami apa yang Kami lakukan dengan informasi yang Kami dapatkan.
        </p>

        <br />

        <h5 className="text-base mb-2">DAFTAR ISI</h5>
        <ol style={{ listStyleType: 'decimal' }} className="ml-8">
          <li className="hover:text-orange-100">
            <a href="#kp-1">Informasi apa yang PINA kumpulkan?</a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-2"> Bagaimana PINA menggunakan informasi kamu?</a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-3"> Apakah informasi kamu dibagi ke pihak lain?</a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-4"> Dengan siapa informasi kamu dibagi?</a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-5"> Berapa lama PINA menyimpan informasi kamu?</a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-6"> Bagaimana PINA melindungi informasi kamu?</a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-7">
              Apakah PINA mengumpulkan informasi dari anak di bawah umur?
            </a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-8"> Apa saja hak privasi kamu?</a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-9">
              {' '}
              Apa kamu bisa menggunakan fitur{' '}
              <span className="italic">Do-Not-Track?</span>
            </a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-10">Apakah PINA memperbarui Kebijakan Privasi ini?</a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-11">
              Bagaimana cara menghubungi PINA tentang Kebijakan Privasi ini?
            </a>
          </li>
          <li className="hover:text-orange-100">
            <a href="#kp-12">
              Bagaimana kamu bisa memeriksa, memperbarui, atau menghapus data
              yang PINA kumpulkan?
            </a>
          </li>
        </ol>
        <br />

        <ol
          style={{ listStyleType: 'decimal' }}
          className="ml-4 text-base font-quicksand-bold">
          <li id="kp-1">
            <h5 className="text-base">Informasi apa yang PINA kumpulkan?</h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="font-quicksand-bold">
                Informasi Pribadi yang kamu berikan secara sukarela
              </span>
              <br />
              <br />
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya,</span> PINA
                mengumpulkan Informasi Pribadi yang kamu sediakan.
              </span>
              <br />
              <br />
              PINA mengumpulkan Informasi Pribadi yang disediakan dengan
              sukarela saat kamu melakukan pendaftaran di Aplikasi, saat kamu
              bersedia menerima informasi tentang PINA atau produk dan Layanan
              Kami, saat kamu berpartisipasi dalam berbagai aktivitas di dalam
              Aplikasi, dan saat kamu menghubungi PINA. Informasi Pribadi yang
              PINA kumpulkan tergantung dari interaksi yang kamu lakukan dengan
              Kami dan Aplikasi, pilihan yang kamu ambil, dan produk yang kamu
              gunakan.
              <br />
              <br />
              Data tersebut merupakan informasi yang kamu sediakan secara
              sukarela. PINA dapat mengumpulkan nama, nomor telepon, alamat
              email, alamat surat-menyurat, pekerjaan, username, kata kunci, dan
              informasi serupa lainnya yang diberikan secara sukarela oleh kamu.
              <br />
              <br />
              Seluruh informasi yang kamu sediakan harus dipastikan benar,
              lengkap, dan akurat. Kamu perlu memberitahukan pada Kami jika ada
              perubahan apapun dalam informasi tersebut.
              <br />
              <br />
              <span className="font-quicksand-bold">
                Informasi Pribadi yang dikumpulkan secara otomatis
              </span>
              <br />
              <br />
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span>
                sejumlah informasi seperti alamat IP{' '}
                <span className="italic">(Internet Protocol)</span> dan/atau
                browser, serta spesifikasi perangkat dikumpulkan secara otomatis
                ketika kamu membuka Aplikasi.
              </span>
              <br />
              <br />
              PINA secara otomatis mengumpulkan informasi tertentu saat kamu
              membuka, menggunakan, dan menjelajahi Aplikasi. Data ini tidak
              membuka identitas pribadi (seperti nama atau informasi kontak),
              tapi dapat termasuk informasi perangkat dan penggunaannya, seperti
              alamat IP, spesifikasi browser dan perangkat, sistem operasi,
              pilihan bahasa, URL terkait, nama perangkat, negara, lokasi,
              informasi seputar bagaimana dan kapan kamu menggunakan Aplikasi,
              serta informasi teknis lainnya. Informasi ini diperlukan terutama
              untuk menjaga keamanan dan pengelolaan Aplikasi, serta untuk
              mendukung analisis dan laporan internal Kami.
              <br />
              <br />
              Informasi yang PINA kumpulkan termasuk:
              <br />
              <br />
              <ul style={{ listStyleType: 'disc' }} className="ml-8">
                <li>
                  <span className="font-quicksand-bold">
                    Data log dan penggunaan.
                  </span>{' '}
                  Yaitu segala informasi penggunaan dan kinerja yang berkaitan
                  dengan Layanan dan dikumpulkan secara otomatis oleh server
                  ketika kamu mengakses atau menggunakan Aplikasi, berikut
                  dengan rekaman data di dalam log files. Tergantung dari
                  interaksi kamu dengan PINA, data log ini dapat termasuk alamat
                  IP, informasi perangkat, tipe dan setelan browser, informasi
                  aktivitas di dalam Aplikasi, dan informasi kerja perangkat
                  (seperti aktivitas sistem, laporan eror atau crash dumps, dan
                  setelan perangkat keras).
                </li>
                <li>
                  <span className="font-quicksand-bold">Data perangkat.</span>{' '}
                  Yaitu informasi seputar komputer, ponsel, tablet, atau
                  perangkat lain yang digunakan untuk mengakses Aplikasi.
                  Tergantung dari penggunaan perangkat, data perangkat ini dapat
                  termasuk alamat IP atau proxy server, nomor identifikasi
                  perangkat dan aplikasi, lokasi, tipe browser, perangkat keras
                  milik penyedia layanan internet dan/atau jaringan{' '}
                  <span className="italic">mobile</span>, informasi sistem
                  operasi, dan konfigurasi sistem.
                </li>
              </ul>
              <br />
              <span className="font-quicksand-bold">
                Informasi yang dikumpulkan melalui Aplikasi
              </span>
              <br />
              <br />
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span>
                PINA mengumpulkan informasi seputar perangkat{' '}
                <span className="italic">mobile</span> dan{' '}
                <span className="italic">push notification</span>
                ketika kamu menggunakan Aplikasi.
              </span>
              <br />
              <br />
              Saat kamu menggunakan Aplikasi, PINA juga mengumpulkan informasi
              berikut:
              <br />
              <br />
              Data perangkat <span className="italic">mobile</span>. PINA
              otomatis mengumpulkan informasi perangkat (seperti identitas,
              model, dan perusahaan pembuat perangkat{' '}
              <span className="italic">mobile</span>
              ), sistem operasi, informasi versi dan konfigurasi sistem, nomor
              identifikasi perangkat dan aplikasi, jenis dan versi browser,
              model perangkat keras, penyedia layanan internet dan/atau jaringan{' '}
              <span className="italic">mobile</span>, serta alamat IP atau proxy
              server. Jika kamu menggunakan Aplikasi, PINA juga bisa
              mengumpulkan informasi tentang jaringan telepon yang terkoneksi
              dengan perangkat <span className="italic">mobile</span>, platform
              atau sistem operasi perangkat{' '}
              <span className="italic">mobile</span>, tipe perangkat{' '}
              <span className="italic">mobile</span>, identitas unik perangkat{' '}
              <span className="italic">mobile</span>, dan fitur-fitur yang
              diakses dalam Aplikasi.
              <br />
              <br />
              <span className="italic">Push notification</span>. PINA dapat
              meminta kesediaan kamu untuk pengiriman{' '}
              <span className="italic">push notification</span> yang berkaitan
              dengan akun atau fitur tertentu dalam Aplikasi. Jika kamu
              memutuskan untuk tidak menerima komunikasi seperti ini, kamu dapat
              mematikan notifikasi ini pada bagian setelan atau settings di
              perangkat kamu.
              <br />
              <br />
              <span className="font-quicksand-bold">
                Informasi pembayaran dan kredensial perbankan{' '}
                <span className="italic">online</span>
              </span>
              <br />
              <br />
              Saat kamu melakukan transaksi melalui PINA, informasi perbankan
              dan data lainnya tidak akan dikumpulkan dan disimpan oleh PINA,
              tapi hanya oleh prosesor pembayaran pihak ketiga. Kecuali kamu
              mengaktifkan fitur sinkronisasi rekening pembayaran, kredensial
              perbankan <span className="italic">online</span> kamu hanya
              digunakan di awal masuk dan tidak akan disimpan oleh PINA sebagai
              pemroses data. Namun, kamu dapat secara sukarela memilih untuk
              menyimpan kredensial tersebut di PINA.
              <br />
              <br />
              Selama kamu menggunakan fitur penghitungan (budgeting) dan
              pelacakan (<span className="italic">tracking</span>) keuangan di
              Aplikasi, PINA akan memproses Informasi Pribadi (seperti
              pemasukan, pengeluaran, bujet, serta detail lainnya) berdasarkan
              data yang dimasukkan. Jika kamu akan menggunakan fitur
              sinkronisasi rekening pembayaran, PINA juga akan memproses
              kredensial untuk mengakses rekening pembayaran, detail transaksi
              (seperti jumlah, deskripsi, catatan, tanggal, dan mata uang), dan
              detail rekening pembayaran (seperti nomor rekening, nama rekening,
              saldo, mata uang, dan tipe rekening). Untuk mendapatkan pengalaman
              yang lebih relevan dan personal, serta saran keuangan yang lebih
              baik, kamu juga dapat melengkapi profil akun PINA milik kamu. PINA
              akan memproses Informasi Pribadi kamu, termasuk foto profil,
              tanggal lahir, jenis kelamin, status keluarga, alamat tempat
              tinggal, status tempat tinggal, detail pendidikan dan pekerjaan,
              detail properti, dan situasi ekonomi.
              <br />
              <br />
              Kamu selanjutnya mengakui bahwa sebagai bagian dari pemrosesan
              Informasi Pribadi untuk tujuan penyediaan Layanan, PINA dapat
              menggunakan profil kamu untuk menganalisis atau memperkirakan
              berbagai aspek berkaitan dengan status keluarga, status tempat
              tinggal, pendidikan dan pekerjaan, detail properti dan situasi
              ekonomi, serta kebiasaan kamu. Tujuannya adalah untuk membantu
              memberikan pengalaman dan saran keuangan terbaik yang
              dipersonalisasi sesuai kebutuhan kamu. Proses profiling (analisis
              profil) ini tidak memungkinkan adanya proses pengambilan keputusan
              otomatis yang dapat memiliki efek hukum atau konsekuensi serupa
              lainnya untuk kamu. Informasi Pribadi kamu diproses oleh PINA
              dengan tujuan untuk memberikan penyediaan Layanan hingga kamu
              menghapusnya atau hingga kamu atau PINA memutuskan kerja sama.
              <br />
              <br />
            </p>
          </li>
          <li id="kp-2">
            <h5 className="text-base">
              Bagaimana PINA menggunakan informasi kamu?
            </h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span>
                PINA memproses informasi untuk berbagai tujuan, dengan
                memperhatikan kepentingan bisnis sesuai hukum yang berlaku,
                pemenuhan kerja sama dengan kamu, pemenuhan kewajiban hukum,
                dan/atau persetujuan kamu.
              </span>
              <br />
              <br />
              PINA menggunakan Informasi Pribadi yang dikumpulkan melalui
              Aplikasi untuk berbagai keperluan bisnis di bawah ini. Kami
              memproses Informasi Pribadi untuk tujuan tersebut dalam rangka
              menjalankan bisnis sesuai hukum yang berlaku, membuka dan menjalin
              kerja sama dengan kamu dengan persetujuan kamu, dan/atau untuk
              memenuhi kewajiban hukum PINA. Kami menjelaskan ketentuan
              pemrosesan informasi untuk setiap tujuan di bawah ini.
              <br />
              <br />
              PINA menggunakan informasi yang dikumpulkan dan terkirim:
              <br />
              <br />
              <ul style={{ listStyleType: 'disc' }} className="ml-8">
                <li>
                  <span className="font-quicksand-bold">
                    Untuk memudahkan pembukaan akun dan proses login.{' '}
                  </span>
                  Jika kamu memilih untuk menghubungkan akun PINA milik kamu
                  dengan akun pihak ketiga (seperti akun Google atau Facebook),
                  PINA dapat menggunakan informasi yang kamu sediakan dari pihak
                  ketiga, untuk memudahkan pembukaan akun dan proses login.
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    Untuk menjalankan fungsi dan fitur utama aplikasi.{' '}
                  </span>
                  Pina mengolah data informasi untuk menjalankan fitur dan
                  fungsi aplikasi sebagaimana mestinya, dengan tetap menjaga
                  keamanan dan efisiensi penggunaan data.
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    Untuk menayangkan testimoni.{' '}
                  </span>
                  PINA menayangkan testimoni di Aplikasi yang mungkin mengandung
                  Informasi Pribadi kamu. Sebelum penayangan testimoni, Kami
                  akan meminta persetujuan untuk menggunakan nama dan isi
                  testimoni kamu. Jika kamu memutuskan untuk memperbarui atau
                  menghapus testimoni, silakan hubungi kami di{' '}
                  <a
                    className="hover:text-orange-100 underline"
                    href="mailto:cs@pina.id">
                    cs@pina.id
                  </a>
                  . Pastikan untuk mencantumkan nama, tempat testimoni
                  ditayangkan, dan informasi kontak kamu.
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    Untuk meminta saran dan masukan.{' '}
                  </span>
                  PINA dapat menggunakan informasi kamu untuk meminta saran dan
                  menghubungi kamu mengenai penggunaan Aplikasi Kami.
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    Untuk memungkinkan pembukaan rekening dana nasabah secara{' '}
                    <span className="italic">online</span>.
                  </span>{' '}
                  PINA dapat menggunakan informasi kamu untuk memungkinkan
                  pembukaan rekening dana nasabah (RDN) di instansi Bank yang
                  bekerjasama dengan PINA dengan persetujuan dari kamu.
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    Untuk menyampaikan informasi promosi dan marketing.{' '}
                  </span>
                  PINA dan/atau partner marketing dari pihak ketiga dapat
                  menggunakan informasi kamu untuk keperluan marketing, jika hal
                  ini sesuai dengan preferensi marketing kamu. Misalnya, PINA
                  akan mengumpulkan Informasi Pribadi jika kamu menyatakan
                  ketertarikan untuk mendapatkan informasi tentang PINA atau
                  Aplikasi Kami, berlangganan berbagai aktivitas marketing PINA,
                  atau jika kamu menghubungi PINA. Kamu bisa memilih untuk
                  menghentikan email marketing dari PINA kapan saja. Lihat poin{' '}
                  <a className="hover:text-orange-100 italic" href="#kp-9">
                    “Apa saja hak privasi kamu?”
                  </a>{' '}
                  di bawah.
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    untuk mengirimkan iklan yang relevan untuk kamu.{' '}
                  </span>
                  PINA dapat menggunakan informasi kamu untuk mengembangkan dan
                  menampilkan konten dan iklan (melalui kerja sama dengan pihak
                  ketiga terkait) yang disesuaikan dengan minat dan/atau lokasi
                  kamu, serta untuk mengukur efektivitas iklan tersebut.
                </li>
              </ul>
              <br />
            </p>
          </li>
          <li id="kp-3">
            <h5 className="text-base">
              Apakah informasi kamu dibagi ke pihak lain?
            </h5>
            <br />

            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span> PINA
                hanya membagi informasi atas persetujuan kamu dalam rangka
                mematuhi hukum yang berlaku, menyediakan Layanan terbaik untuk
                kamu, melindungi hak kamu, atau memenuhi kewajiban bisnis PINA.
              </span>
              <br />
              <br />
              PINA tidak akan dengan sengaja mengekspos atau membagikan
              informasi yang dikumpulkan dan memberikan kepada pihak ketiga
              tanpa persetujuan kamu. PINA dapat memproses dan membagi data kamu
              dengan ketentuan dasar berikut ini:
              <br />
              <br />
              <ul style={{ listStyleType: 'disc' }} className="ml-8">
                <li>
                  <span className="font-quicksand-bold">Persetujuan.</span> PINA
                  dapat memproses data jika kamu memberikan persetujuan untuk
                  menggunakan Informasi Pribadi untuk tujuan tertentu.
                </li>
                <li>
                  Kepentingan sesuai hukum yang berlaku. PINA dapat memproses
                  data kamu jika dibutuhkan secara logis untuk memenuhi
                  keperluan bisnis sesuai hukum yang berlaku.
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    Pemenuhan kerja sama.
                  </span>{' '}
                  Saat menjalin kerja sama dengan kamu, PINA dapat memproses
                  Informasi Pribadi untuk memenuhi perjanjian kerja sama dengan
                  kamu.
                </li>
                <li>
                  <span className="font-quicksand-bold">Kewajiban hukum. </span>
                  PINA dapat menggunakan Informasi Pribadi kamu untuk mematuhi
                  aturan hukum yang berlaku, permintaan pemerintah, proses
                  peradilan, perintah pengadilan, atau proses hukum, misalnya
                  untuk menanggapi panggilan pengadilan atau subpoena (termasuk
                  menanggapi otoritas publik demi keamanan nasional dan
                  penegakan hukum).
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    Kepentingan vital lainnya.
                  </span>{' '}
                  PINA dapat membuka Informasi Pribadi kamu jika dirasa perlu
                  untuk menginvestigasi, mencegah, atau mengambil tindakan saat
                  ada potensi pelanggaran kebijakan, dugaan penipuan, situasi
                  yang mengancam keamanan siapa pun, dan aktivitas ilegal
                  lainnya, serta untuk menjadi bukti dalam proses pengadilan
                  yang melibatkan PINA.
                </li>
              </ul>
              <br />
              Lebih spesifik lagi, PINA dapat memproses dan membagi Informasi
              Pribadi kamu pada situasi berikut:
              <br />
              <br />
              <ul style={{ listStyleType: 'disc' }} className="ml-8">
                <li>
                  <span className="font-quicksand-bold">Pengalihan bisnis. </span>
                  PINA dapat membagi atau mengalihkan informasi kamu sehubungan
                  dengan, atau saat proses negosiasi dari merger, penjualan aset
                  perusahaan, pendanaan, atau akuisisi seluruh atau sebagian
                  bisnis PINA ke perusahaan lain.
                </li>
                <li>
                  <span className="font-quicksand-bold">
                    Vendor, konsultan, dan penyedia jasa lainnya dari pihak
                    ketiga.{' '}
                  </span>
                  PINA dapat membagi data kamu pada vendor, penyedia jasa,
                  kontraktor, atau agen pihak ketiga yang menjalankan servis
                  untuk PINA atau atas nama PINA, dan membutuhkan informasi
                  tersebut untuk menyelesaikan pekerjaannya. Contohnya adalah
                  penyelesaian pembayaran, analisis data, pengiriman email,
                  layanan hosting, layanan analisis dan{' '}
                  <span className="italic">tracking</span>, layanan
                  personalisasi, layanan pelanggan, dan jasa marketing. Kami
                  dapat mengizinkan pihak ketiga yang ditunjuk untuk menggunakan
                  teknologi pelacakan data di dalam Aplikasi, yang memungkinkan
                  pihak tersebut untuk mengumpulkan data atas nama PINA seputar
                  interaksi kamu di dalam Aplikasi dari waktu ke waktu.
                  Informasi ini dapat digunakan antara lain untuk menganalisis
                  dan melacak data, menentukan popularitas konten, halaman, atau
                  fitur tertentu, dan untuk lebih memahami berbagai aktivitas{' '}
                  <span className="italic">online</span>. Kecuali dijelaskan di
                  Kebijakan Privasi ini, PINA tidak membagi, menjual,
                  menyewakan, atau menukar informasi apa pun dengan pihak ketiga
                  untuk kepentingan promosi mereka.
                </li>
              </ul>
              <br />
            </p>
          </li>
          <li id="kp-4">
            <h5 className="text-base">Dengan siapa informasi kamu dibagi?</h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span>
                PINA hanya membagi informasi dengan pihak ketiga berikut ini.
                <br />
                PINA hanya membagi dan membuka informasi kamu dengan pihak
                ketiga berikut ini.
              </span>
              <br />
              <br />
              <ul style={{ listStyleType: 'disc' }} className="ml-8">
                <li>
                  Terkait{' '}
                  <span className="italic">
                    advertising, direct marketing,{' '}
                  </span>
                  dan
                  <span className="italic"> lead generation </span>
                  Google AdSense dan Facebook Audience Network
                </li>
                <li>
                  Terkait optimasi penggunaan dan infrastruktur Amazon Web
                  Services
                </li>
                <li>
                  Terkait analisis aktivitas web dan{' '}
                  <span className="italic">mobile</span> Amplitude dan Google
                  Analytics
                </li>
                <li>
                  Terkait personalisasi dan layanan analisis data Segment &
                  Customer.io
                </li>
                <li>
                  Terkait layanan kontak support pengguna Intercom & Mailchimp
                </li>
                <li>
                  Terkait dengan persyaratan hukum, peraturan, perintah
                  pengadilan yang mengikat atau berlaku dari pemerintah.
                </li>
              </ul>
              <br />
              Jika PINA sudah memproses data atas persetujuan kamu, dan kamu
              ingin membatalkan persetujuan tersebut, silakan hubungi Kami
              melalui kontak yang ada di poin{' '}
              <a className="hover:text-orange-100 italic" href="#kp-11">
                “Bagaimana cara menghubungi PINA tentang Kebijakan Privasi ini?”
              </a>{' '}
              di bawah.
              <br />
              <br />
            </p>
          </li>
          <li id="kp-5">
            <h5 className="text-base">
              Berapa lama PINA menyimpan informasi kamu?
            </h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span> PINA
                menyimpan informasi kamu selama diperlukan untuk memenuhi
                berbagai tujuan yang sudah dijelaskan dalam Kebijakan Privasi
                ini, kecuali diperlukan sebaliknya oleh hukum.
              </span>
              <br />
              <br />
              PINA hanya menyimpan Informasi Pribadi selama diperlukan untuk
              berbagai tujuan yang disebutkan di Kebijakan Privasi ini, kecuali
              perpanjangan periode penyimpanan diminta atau diperbolehkan oleh
              hukum (misalnya untuk keperluan perpajakan, akuntansi, dan
              persyaratan hukum lainnya). Kami hanya menyimpan Informasi Pribadi
              selama kamu masih memiliki akun di PINA. Tidak ada hal di
              Kebijakan Privasi ini yang mengharuskan PINA menyimpan Informasi
              Pribadi kamu lebih lama dari itu. Saat PINA tidak memiliki
              kepentingan bisnis yang sah untuk memproses Informasi Pribadi
              kamu, Kami akan menghapus atau membuat informasi tersebut menjadi
              anonim. Atau, jika hal itu tidak memungkinkan (misalnya karena
              Informasi Pribadi kamu disimpan di dalam cadangan arsip), PINA
              akan menyimpan dengan aman dan memisahkan informasi tersebut dari
              proses apa pun, hingga akhirnya penghapusan data dapat dilakukan.
              <br />
              <br />
            </p>
          </li>
          <li id="kp-6">
            <h5 className="text-base">
              Bagaimana PINA melindungi informasi kamu?
            </h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span> PINA
                berkomitmen untuk melindungi Informasi Pribadi kamu melalui
                sistem pengaturan dan teknik keamanan yang dirancang sedemikian
                rupa.
              </span>
              <br />
              <br />
              Informasi Pribadi kamu disimpan dan diproses secara otomatis dalam
              format elektronik di dalam server yang aman, dilengkapi dengan
              langkah keamanan fisik dan proteksi firewall. Server database
              milik PINA berada di pusat pengelolaan data di wilayah Indonesia.
              PINA dan penyedia layanan pusat data patuh dan menerapkan standar
              keamanan data yang ketat. Informasi Pribadi kamu ditransfer dari
              kamu atau dari server perbankan{' '}
              <span className="italic">online</span> ke server pemrosesan data,
              menggunakan koneksi SSL yang aman.
              <br />
              <br />
              PINA menerapkan berbagai langkah pengaturan dan teknik keamanan
              yang diperlukan dan didesain untuk melindungi Informasi Pribadi
              apa pun yang diproses. Meskipun penjagaan ketat dan usaha keras
              PINA terhadap Informasi Pribadi kamu, tidak ada pengiriman
              elektronik di internet atau teknologi penyimpanan informasi yang
              terjamin aman 100%. Jadi, PINA tidak dapat berjanji atau menjamin
              bahwa peretas (<span className="italic">hacker</span>), penjahat
              siber (<span className="italic">cybercriminal</span>), atau pihak
              ketiga yang tidak berwenang, tidak akan menembus pertahanan
              keamanan Kami, dan secara ilegal mengumpulkan, mengakses, mencuri,
              atau memodifikasi informasi kamu. PINA akan melakukan yang terbaik
              untuk melindungi Informasi Pribadi kamu, namun perlu dicatat bahwa
              pengiriman informasi dari dan ke Aplikasi dilakukan atas risiko
              sendiri. Kamu juga disarankan untuk hanya mengakses Aplikasi di
              dalam lingkungan yang aman.
              <br />
              <br />
            </p>
          </li>
          <li id="kp-7">
            <h5 className="text-base">
              Apakah PINA mengumpulkan informasi dari anak di bawah umur?
            </h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span> PINA
                secara sadar tidak mengumpulkan data dari atau memasarkan produk
                pada anak usia di bawah 18 tahun.
              </span>
              <br />
              <br />
              PINA secara sadar tidak mengumpulkan data dari atau memasarkan
              produk pada anak usia di bawah 18 tahun. Dengan menggunakan
              Aplikasi, kamu menyatakan bahwa kamu berusia minimal 18 tahun,
              atau kamu adalah orang tua/wali dari anak di bawah umur dan
              membolehkan anak tersebut mengakses Aplikasi ini. Jika PINA
              mengetahui bahwa ada Informasi Pribadi yang dikumpulkan dari
              pengguna berusia di bawah 18 tahun, PINA akan menonaktifkan akun
              tersebut dan mengambil langkah yang diperlukan untuk segera
              menghapus data tersebut dari arsip Kami. Jika kamu mengetahui
              bahwa ada data yang dikumpulkan dari anak usia di bawah 18 tahun,
              mohon hubungi Kami di{' '}
              <a
                className="hover:text-orange-100 underline"
                href="mailto:cs@pina.id">
                cs@pina.id
              </a>
              .
              <br />
              <br />
            </p>
          </li>
          <li id="kp-8">
            <h5 className="text-base">Apa saja hak privasi kamu?</h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span> kamu
                dapat mengecek, mengubah, dan menghentikan akun kapan saja.
              </span>
              <br />
              <br />
              Jika kamu adalah warga negara Indonesia dan menilai bahwa PINA
              sudah memproses Informasi Pribadi tidak sesuai dengan hukum yang
              berlaku, kamu berhak untuk mengajukan keluhan ke otoritas pengawas
              perlindungan data setempat. Silakan lihat detail infonya di sini.
              <br />
              <br />
              Jika ada pertanyaan atau saran seputar hak privasi, kamu juga bisa
              menghubungi Kami via{' '}
              <a
                className="hover:text-orange-100 underline"
                href="mailto:cs@pina.id">
                cs@pina.id
              </a>
              .
              <br />
              <br />
              <span className="font-quicksand-bold">Informasi Akun</span>
              <br />
              <br />
              Kamu bisa mengecek, mengubah informasi, atau mengakhiri akun kamu
              kapan saja.
              <br />
              <br />
              Jika ada permintaan untuk menghapus akun, kami akan menonaktifkan
              dan menghapus akun dan informasi kamu dari database aktif.
              Meskipun demikian, kami dapat menyimpan sejumlah informasi untuk
              menghindari penipuan, memecahkan isu troubleshoot, membantu
              penyelidikan, menerapkan Syarat Penggunaan, dan/atau mematuhi
              persyaratan hukum yang berlaku.
              <br />
              <br />
              Kamu dapat menghentikan langganan email marketing PINA kapan saja.
              <br />
              <br />
              Caranya, cukup klik tautan stop berlangganan yang ada di email
              yang dikirimkan, atau hubungi PINA melalui kontak yang disediakan
              di bawah. Kamu akan dikeluarkan dari daftar email marketing namun
              PINA masih bisa berkomunikasi dengan kamu, misalnya untuk
              mengirimkan email berkaitan dengan Layanan untuk memproses
              administrasi dan penggunaan akun kamu, untuk menjawab permintaan
              Layanan tertentu, atau untuk menyampaikan kepentingan
              non-marketing lainnya.
              <br />
              <br />
            </p>
          </li>
          <li id="kp-9">
            <h5 className="text-base">
              Apa kamu bisa menggunakan fitur{' '}
              <span className="italic">Do-Not-Track?</span>
            </h5>
            <br />

            <p className="font-quicksand-regular -ml-4 text-sm">
              Sejumlah web browser, sistem operasi{' '}
              <span className="italic">mobile</span>, dan aplikasi{' '}
              <span className="italic">mobile</span> memasukkan fitur atau
              setelan <span className="italic">Do-Not-Track</span> (DNT) yang
              bisa diaktifkan untuk mengirimkan sinyal mengenai pilihan privasi
              kamu, sehingga data aktivitas{' '}
              <span className="italic">online</span> kamu tidak dimonitor dan
              dikumpulkan. Saat ini, belum ada penetapan standar teknologi yang
              dapat seragam digunakan untuk mengenali dan menerapkan sinyal DNT.
              Oleh karena itu, saat ini PINA tidak merespon sinyal dari DNT
              browser atau sejenisnya yang secara otomatis menyampaikan pilihan
              kamu untuk tidak dilacak secara{' '}
              <span className="italic">online</span>. Jika di masa depan standar
              pelacakan <span className="italic">online</span> sudah
              diberlakukan dan perlu diikuti oleh PINA, Kami akan
              menginformasikan penerapannya pada Kebijakan Privasi versi
              terbaru.
              <br />
              <br />
            </p>
          </li>
          <li id="kp-10">
            <h5 className="text-base">
              Apakah PINA memperbarui Kebijakan Privasi ini?
            </h5>
            <br />

            <p className="font-quicksand-regular -ml-4 text-sm">
              <span className="italic">
                <span className="font-quicksand-bold">Singkatnya, </span> iya, PINA
                memperbarui Kebijakan Privasi ini selama diperlukan untuk tetap
                patuh pada hukum yang berlaku.
              </span>
              <br />
              <br />
              PINA dapat memperbarui Kebijakan Privasi dari waktu ke waktu agar
              tetap relevan dan up to date dengan perubahan teknologi dan hukum
              yang berlaku. Versi terbaru ditandai dengan tanggal pembaruan yang
              sudah direvisi. Kebijakan Privasi terbaru berlaku efektif
              secepatnya sejak dipublikasikan. Jika ada perubahan isi dalam
              Kebijakan Privasi ini, PINA dapat menginformasikannya dengan
              menayangkan pengumuman perubahan tersebut atau mengirimkan
              notifikasi langsung pada kamu. PINA mengimbau kamu untuk mengecek
              Kebijakan Privasi ini dari waktu ke waktu, agar mendapatkan info
              terbaru seputar perlindungan informasi kamu.
              <br />
              <br />
            </p>
          </li>
          <li id="kp-11">
            <h5 className="text-base">
              Bagaimana cara menghubungi PINA tentang Kebijakan Privasi ini?
            </h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              Jika ada pertanyaan atau saran mengenai Kebijakan Privasi ini,
              kamu bisa mengirimkan email ke{' '}
              <a
                className="hover:text-orange-100 underline"
                href="mailto:cs@pina.id">
                cs@pina.id
              </a>{' '}
              atau surat ke:
              <br />
              <br />
              PT Trust Sekuritas
              <br />
              JL. Jenderal Sudirman Kav.52-53,
              <br />
              Prosperity Tower Lantai 2E,
              <br />
              District 8, SCBD Lot 28,
              <br />
              Jakarta, 12190
              <br />
              Indonesia
              <br />
              <br />
            </p>
          </li>
          <li id="kp-12">
            <h5 className="text-base">
              Bagaimana kamu bisa memeriksa, memperbarui, atau menghapus data
              yang PINA kumpulkan?
            </h5>
            <br />
            <p className="font-quicksand-regular -ml-4 text-sm">
              Berdasarkan hukum yang berlaku di Indonesia, kamu berhak untuk
              meminta akses dari Informasi Pribadi yang PINA kumpulkan, mengubah
              informasi tersebut, atau menghapusnya pada situasi tertentu. Untuk
              mengajukan pengecekan, pembaruan, dan penghapusan Informasi
              Pribadi, silakan kirim permintaan dengan melalui email atau kontak
              layanan kami.
            </p>
          </li>
        </ol>
      </div>
    </Card>
  );
}

export default SectionKebijakanPrivasi;
