import { useFormik } from 'formik';

import { DeleteAccountSchema } from './delete-account-form.validation';
import { isEmpty } from 'lodash';
import InputCheckBox from 'components/input/input-check-box';
import { useState } from 'react';
import DeleteAccountConfirmation from './modal-confirmation';
import LoginPinForm from '../login-pin';
import RenderIf from 'components/basic/render-if';
import { useDeleteUserMutation } from 'services/rtk-query/user';
import useToastr from 'hooks/useToastr';
import useGetErrorMessage from 'hooks/useGetErrorMessage';

function DeleteAccountForm() {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [openLoginPin, setOpenLoginPin] = useState(false);

  const [deleteUser] = useDeleteUserMutation();

  const formik = useFormik({
    initialValues: {
      agreement: false
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: DeleteAccountSchema,
    onSubmit: (values) => {
      handlePreSubmit(values);
    }
  });

  const handlePreSubmit = async () => {
    setOpenLoginPin(true);
    setOpenModalConfirmation(false);
  };

  const handleSubmitDeleteAccount = async (pin) => {
    try {
      await deleteUser({ pin }).unwrap();
      toastr.success('Your account has been deleted!');
      window.location.href = '/logout';
    } catch (error) {
      getErrorMessage(error);
    }
  };

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      setIsSubmitting(false);
      setOpenModalConfirmation(true);
    }
  };

  return (
    <>
      <form
        className="mt-3"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        noValidate>
        <InputCheckBox
          value={formik?.values?.agreement}
          name="agreement"
          label={
            <span className="font-quicksand-semibold">
              Dengan menyentang, kamu telah menyetujui terhadap ketentuan dan
              resiko dari hapus akun
            </span>
          }
          isError={formik?.errors?.agreement}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <div className="pt-3 mt-1 relative flex justify-end">
          <button
            data-qa-id="btnStockBuySubmit"
            disabled={!formik.isValid}
            onClick={handleConfirmation}
            type="button"
            className={`w-full block mr-0 bg-red-base-l disabled:bg-opacity-30 hover:bg-opacity-75 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg transition-all ease-in-out duration-100`}>
            Hapus Akun
          </button>
        </div>
        <DeleteAccountConfirmation
          isOpen={isOpenModalConfirmation}
          isSubmitting={isSubmitting}
          onClose={handleConfirmation}
        />
      </form>

      <RenderIf isTrue={openLoginPin}>
        <LoginPinForm
          isConfirmation={true}
          onSuccess={handleSubmitDeleteAccount}
          onCancel={() => {
            setOpenLoginPin(false);
            setOpenModalConfirmation(true);
          }}
        />
      </RenderIf>
    </>
  );
}

DeleteAccountForm.propTypes = {};

export default DeleteAccountForm;
