import PropTypes from 'prop-types';
import ActionButton from 'components/basic/action-button';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import SmartOrderForm from 'components/form/smart-order-form';
import IconBuy from 'components/svg/icon-buy';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import IconListBox from 'components/svg/icon-list-box';
import IconSearchPage from 'components/svg/icon-search-page';
import IconStar from 'components/svg/icon-star';
import IconStarDelete from 'components/svg/icon-star-delete';
import dayjs from 'dayjs';
import { useCompareStock } from 'hooks/useCompareStock';
import useToastr from 'hooks/useToastr';
import { triggerSegmentEvent } from 'lib/segment';
import { useEffect, useState } from 'react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { Link } from 'react-router-dom';
import { useGetTradingIdeasQuery } from 'services/rtk-query/classroom';
import {
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistQuery
} from 'services/rtk-query/watchlist';
import { currency } from 'utils/format';

import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import { useLazyGetStockByCodeQuery } from 'services/rtk-query/stock';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import useCustomNavigate from 'hooks/useCustomNavigate';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import useElementSize from 'hooks/useElementSize';
import cn from 'utils/classname';

function TWTradingIdeasFull({
  isZoomOut,
  title = <h5 className="mb-1 text-sm dark:text-black-light-1-d">Trading Ideas</h5>
}) {
  const isBrowserWakeUp = useBrowserWakeUp();
  const { navigate, customNavigate } = useCustomNavigate();
  const { user} = useBrowserTabAuth();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();

  const { ref, dimensions } = useElementSize();
  const { width } = dimensions ?? {};

  const [params] = useState({ page: 1, limit: 6 });
  const [featured, setFeatured] = useState([]);

  const { addToCompareStock } = useCompareStock();
  const [getStockDetail] = useLazyGetStockByCodeQuery();
  const { data: response, isFetching, refetch } = useGetTradingIdeasQuery(params);

  const { data: watchlist } = useGetWatchlistQuery();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();

  const [openExcerpt, setOpenExcerpt] = useState([true, true]);
  const [openRecommendation, setOpenRecommendation] = useState([true, true]);
  const [openSmartOrderForm, setOpenSmartOrderForm] = useState(false);
  const [isNotRealAccount, setIsNotRealAccount] = useState(false);

  const onClickSmartBuy = (orderDetail, disabledStock, initialForms = {}) => {
    setOpenSmartOrderForm({ orderDetail, disabledStock, initialForms });
  };

  useEffect(() => {
    if (!isFetching && response?.data?.length > 0) {
      if (response?.data?.length > 2) {
        setFeatured([response?.data[0], response?.data[1]]);
      } else {
        setFeatured(response?.data);
      }
    }
  }, [response, isFetching]);

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  return (
    <div ref={ref}>
      <Card className="p-4">
        <RenderIf isTrue={isFetching}>
          <SkeletonTheme className="h-[1.5rem] w-[60%]" />
          <SkeletonTheme className="h-[.7rem] w-[20%] mb-4" />
          <SkeletonTheme className="h-[.7rem] w-[70%] mb-4" />

          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div>
              <SkeletonTheme className="h-[32px] w-[32px] rounded-full" />
              <SkeletonTheme className="h-[.7rem] w-[70%]" />
              <SkeletonTheme className="h-[.7rem] w-[50%]" />
              <SkeletonTheme className="h-[.7rem] w-[40%]" />
            </div>
            <div>
              <SkeletonTheme className="h-[32px] w-[32px] rounded-full" />
              <SkeletonTheme className="h-[.7rem] w-[70%]" />
              <SkeletonTheme className="h-[.7rem] w-[50%]" />
              <SkeletonTheme className="h-[.7rem] w-[40%]" />
            </div>
          </div>
        </RenderIf>

        <RenderIf isTrue={!isFetching}>
          <div className="flex justify-between mb-6 ">
            <div className="flex-none ">
              {title}
              <Link
                to="/ideas"
                className="text-main-base-l text-xs block font-quicksand-semibold hover:opacity-75">
                Lihat Selengkapnya
              </Link>
            </div>
            <span className="text-gray-light-0-d text-xs font-quicksand-semibold">
              {featured?.length > 0 &&
                dayjs(featured?.[0]?.published_at).format('DD MMM YYYY')}
            </span>
          </div>

          <RenderIf isTrue={featured?.length > 0}>
            <div className={cn('grid grid-cols-1 -mx-6 gap-4', width > 600 && 'grid-cols-2 gap-0')}>
              {featured?.map((item, key) => (
                <div key={key} className="flex-none first:border-r px-6 dark:border-gray-base-d">
                  <Link
                    to={`/ideas/profile/${item?.author?._id}`}
                    className="ease-linear transition-all hover:opacity-75 flex items-center">
                    <div className="flex-none">
                      <RenderIf isTrue={item?.author?.avatar}>
                        <img
                          src={item?.author?.avatar}
                          className="h-8 w-8 rounded-full mr-2 mt-[3px] border"
                        />
                      </RenderIf>

                      <RenderIf isTrue={!item?.author?.avatar}>
                        <span className="h-8 w-8 rounded-full mr-2 bg-gray-light-l font-quicksand-semibold uppercase text-sm border border-gray-light-0-d flex items-center justify-center mt-[3px]">
                          {item?.author?.name?.charAt(0)}
                        </span>
                      </RenderIf>
                    </div>

                    <div className="flex-auto">
                      <h5
                        className="text-xs mt-2 line-clamp-1 dark:text-black-light-1-d"
                        title={item?.author?.name}>
                        {item?.author?.name}
                      </h5>
                      <p
                        className="text-xs text-gray-light-0-d line-clamp-1"
                        title={item?.author?.title}>
                        {item?.author?.title || 'Trader Expert'}
                      </p>
                    </div>
                  </Link>

                  <Accordion className="relative my-3">
                    <AccordionItem
                      expandable={true}
                      expanded={openExcerpt[key]}
                      onToggle={() => {
                        let _openExcerpt = [...openExcerpt];
                        _openExcerpt[key] = !_openExcerpt[key];
                        setOpenExcerpt(_openExcerpt);
                      }}
                      className="relative border-b dark:border-gray-base-d pb-3">
                      <AccordionItem.Slot name="Header">
                        <div className="flex mb-1">
                          <h5 className="text-xs flex-auto dark:text-main-base-l">{item?.title}</h5>
                          <IconChevronSingle className={`h-5 w-5 ml-2 flex-none transform text-main-base-l transition-all ease-linear duration-150 ${openExcerpt[key] ? 'rotate-90' : '-rotate-90'}`} />
                        </div>
                      </AccordionItem.Slot>

                      <AccordionItem.Slot name="Content">
                        <p
                          className={`text-xs text-gray-light-0-d dark:text-black-light-0-d ${!user?.realAccount && 'filter blur-sm'}`}
                          dangerouslySetInnerHTML={{ __html: item?.excerpt }}></p>
                      </AccordionItem.Slot>
                    </AccordionItem>
                  </Accordion>

                  <Accordion className="relative mt-3">
                    <AccordionItem
                      expandable={true}
                      expanded={openRecommendation[key]}
                      onToggle={() => {
                        let _openRecommendation = [...openRecommendation];
                        _openRecommendation[key] = !_openRecommendation[key];
                        setOpenRecommendation(_openRecommendation);
                      }}
                      className="relative">
                      <AccordionItem.Slot name="Header">
                        <div className="flex mb-1">
                          <h5 className="text-xs flex-auto dark:text-black-light-1-d">Rekomendasi Saham</h5>
                          <IconChevronSingle className={`h-5 w-5 ml-2 transform text-main-base-l flex-none transition-all ease-linear duration-150 ${openRecommendation[key] ? 'rotate-90' : '-rotate-90'}`} />
                        </div>
                      </AccordionItem.Slot>

                      <AccordionItem.Slot name="Content">
                        <ul className="flex flex-wrap justify-start pt-2 gap-2">
                          {item?.trading_ideas?.map((stock, key) => (
                            <li
                              key={key}
                              className="cursor-pointer transition-all ease-linear duration-100 mb-3 min-w-[70px]">
                              <div className="group hover:pb-10 transition-all ease-linear duration-100">
                                <h5 className="text-xs dark:text-main-base-l">{stock?.code}</h5>
                                <p className="text-gray-light-0-d text-xs mb-[2px] group-hover:text-main-base-l flex items-center">
                                  Buy :{' '} <span className={`ml-[2px] ${!user?.realAccount && 'filter blur-sm'}`}>{currency(stock?.buy, { removeSymbol: true })}</span>
                                </p>
                                <p className="text-gray-light-0-d text-xs mb-[2px] group-hover:text-main-base-l flex items-center">
                                  TP :{' '} <span className={`ml-[2px] ${!user?.realAccount && 'filter blur-sm'}`}>{currency(stock?.tp, { removeSymbol: true })}</span>
                                </p>
                                <p className="text-gray-light-0-d text-xs mb-[2px] group-hover:text-main-base-l flex items-center">
                                  SL :{' '} <span className={`ml-[2px] ${!user?.realAccount && 'filter blur-sm'}`}>{currency(stock?.sl, { removeSymbol: true })}</span>
                                </p>
                                
                                <div className="relative">
                                  <ActionButton
                                    isZoomOut={isZoomOut}
                                    isLast={false}
                                    position="-left-1 -bottom-9"
                                    actions={[
                                      {
                                        title: 'Beli',
                                        children: <IconBuy className="h-4 w-4" />,
                                        onClick: () => {
                                          customNavigate({ query: { 'simple-order': stock?.code }});
                                          triggerSegmentEvent({
                                            event:
                                              'Trading idea hover state clicked',
                                            properties: {
                                              action: 'Simple Buy'
                                            }
                                          });
                                        }
                                      },
                                      {
                                        title: 'Smart Order',
                                        children: (
                                          <IconListBox className="h-4 w-4" />
                                        ),
                                        onClick: async () => {
                                          const sd = await getStockDetail(stock?.code).unwrap();

                                          onClickSmartBuy(
                                            {
                                              stockId: stock?.code,
                                              BuySell: 'SmartBuy'
                                            },
                                            false,
                                            {
                                              Price: stock?.buy,
                                              Rule: Number(sd?.lastTradedPrice) <= Number(stock?.buy) ? '>=' : '<=',
                                              PriceStop: stock?.sl,
                                              PriceProfit: stock?.tp,
                                              PriceRule: stock?.buy,
                                              PriceRuleStop: stock?.sl,
                                              PriceRuleProfit: stock?.tp,
                                              stopLoss: true,
                                              takeProfit: true
                                            }
                                          );
                                          triggerSegmentEvent({
                                            event:
                                              'Trading idea hover state clicked',
                                            properties: {
                                              action: 'Smart Order'
                                            }
                                          });
                                        }
                                      },
                                      {
                                        title: 'Stock Detail',
                                        children: (
                                          <IconSearchPage className="h-4 w-4" />
                                        ),
                                        onClick: () => {
                                          customNavigate({ query: { 'stock-detail': stock?.code }});
                                          triggerSegmentEvent({
                                            event:
                                              'Trading idea hover state clicked',
                                            properties: {
                                              action: 'Stock Detail'
                                            }
                                          });
                                        }
                                      },
                                      {
                                        title: 'Trading View',
                                        children: (
                                          <IconChartCandleStick className="h-4 w-4" />
                                        ),
                                        onClick: () => {
                                          customNavigate({ query: { 'advanced-chart': stock?.code }});
                                          triggerSegmentEvent({
                                            event:
                                              'Trading idea hover state clicked',
                                            properties: {
                                              action: 'Advanced Chart'
                                            }
                                          });
                                        }
                                      },
                                      {
                                        title: 'Bandingkan',
                                        children: (
                                          <IconCompare className="h-4 w-4" />
                                        ),
                                        onClick: () => {
                                          addToCompareStock(
                                            {
                                              code: stock?.code,
                                              name: stock?.name
                                            },
                                            () => {
                                              navigate(`/compare/fundamental`);
                                            }
                                          );

                                          triggerSegmentEvent({
                                            event:
                                              'Trading idea hover state clicked',
                                            properties: {
                                              action: 'Compare'
                                            }
                                          });
                                        }
                                      },
                                      {
                                        title: `${
                                          watchlist?.find(
                                            (w) => w?.code === stock?.code
                                          )
                                            ? 'Hapus Watchlist'
                                            : 'Tambahkan ke Watchlist'
                                        }`,
                                        children: watchlist?.find(
                                          (w) => w?.code === stock?.code
                                        ) ? (
                                          <IconStarDelete className="h-4 w-4" />
                                        ) : (
                                          <IconStar className="h-4 w-4" />
                                        ),
                                        onClick: async () => {
                                          triggerSegmentEvent({
                                            event:
                                              'Trading idea hover state clicked',
                                            properties: {
                                              action: 'Watchlist'
                                            }
                                          });
                                          if (
                                            watchlist?.find(
                                              (w) => w?.code === stock?.code
                                            )
                                          ) {
                                            try {
                                              await deleteWatchlist({
                                                stockCode: stock?.code
                                              }).unwrap();
                                              toastr.success(
                                                `${stock?.code} berhasil dihapus dari watchlist`
                                              );
                                            } catch (error) {
                                              getErrorMessage(error);
                                            }
                                          } else {
                                            try {
                                              await createWatchlist({
                                                stockCode: stock?.code,
                                                type: 'stock'
                                              }).unwrap();
                                              toastr.success(
                                                `${stock?.code} berhasil ditambahkan kedalam watchlist`
                                              );
                                            } catch (error) {
                                              getErrorMessage(error);
                                            }
                                          }
                                        }
                                      }
                                    ]}
                                  />
                                </div>
                              </div>

                              <Link
                                to={user?.realAccount ? `/ideas/${item?._id}/#${stock?.code}` : '#'}
                                onClick={() => {
                                  if (!user?.realAccount) setIsNotRealAccount(true);
                                  triggerSegmentEvent({
                                    event: 'Trading Ideas Clicked',
                                    properties: {
                                      page_title: item?.title
                                    }
                                  });
                                }}
                                className="text-main-base-l text-xs block font-quicksand-semibold hover:opacity-75">
                                Lihat Detail
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </AccordionItem.Slot>
                    </AccordionItem>
                  </Accordion>
                </div>
              ))}
            </div>
          </RenderIf>

          <RenderIf isTrue={featured?.length === 0}>
            <p className="text-gray-light-0-d text-xs">Belum ada data</p>
          </RenderIf>
        </RenderIf>

        <RenderIf isTrue={openSmartOrderForm}>
          <Modal size='full' onClose={() => setOpenSmartOrderForm(false)}>
            <SmartOrderForm
              formType="Smart Order"
              initialForms={openSmartOrderForm?.initialForms}
              orderDetail={openSmartOrderForm?.orderDetail}
              disabledStock={openSmartOrderForm?.disabledStock}
              onClose={() => setOpenSmartOrderForm(false)}
              onSuccess={() => setOpenSmartOrderForm(false)}
            />
          </Modal>
        </RenderIf>

        <RenderIf isTrue={isNotRealAccount}>
          <Modal onClose={() => setIsNotRealAccount(false)}>
            <ModalExploreExpired
              showClose
              eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
              title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
              description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
              onClose={() => setIsNotRealAccount(false)}
            />
          </Modal>
        </RenderIf>
      </Card>
    </div>
  );
}

TWTradingIdeasFull.propTypes = {
  title: PropTypes.any
};

export default TWTradingIdeasFull;
