// import { marginPlusShare } from 'utils/formula/margin';
import { object, number, string } from 'yup';

export const StockSellSchema = object().shape({
  portfolio: number()
    .min(1, 'Anda belum mempunyai Lot')
    .required('Anda belum mempunyai Lot'),
  amount: number()
  .test(
    'amount',
    'Jumlah amount tidak cukup',
    function (value, { parent }) {
      if (!parent.lotType) {
        return Math.floor(value / (parent.price * 100)) > 0;
      }

      return true;
    }
  )
  .required('Jumlah amount harus diisi'),
  lot: number()
    .min(1, 'Jumlah lot harus diisi.')
    .test('lot', 'Jumlah lot tidak sesuai', function (value, { parent }) {
      return value <= parent.portfolio;
    })
    .required('Jumlah lot harus diisi'),
  price: number()
    // .test('price', 'Harga terlalu rendah', function (value, { parent }) {
    //   return !(
    //     value <
    //     parent?.initialPrice - marginPlusShare(parent?.initialPrice) * 10
    //   );
    // })
    // .test('price', 'Harga terlalu tinggi', function (value, { parent }) {
    //   return !(
    //     value >
    //     parent?.initialPrice + marginPlusShare(parent?.initialPrice) * 10
    //   );
    // })
    .min(1, 'Harga saham tidak boleh kosong.')
    .required('Harga saham tidak boleh kosong.'),
  expiryDate: string()
    .nullable(true)
    .test(
      'expiryDate',
      'Expiry date harus diisi',
      function (value, { parent }) {
        if (parent?.mockOnly && parent?.expiry === 'GTD') {
          return value;
        }

        return true;
      }
    )
});
