import PropTypes from 'prop-types';

import IconArrowLeft from "components/svg/icon-arrow-left"

function InputNumpad({ onPress = () => {} }) {
  return (
    <div className="grid grid-cols-3 gap-9 mb-10">
      <button onClick={() => onPress('1')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">1</button>
      <button onClick={() => onPress('2')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">2</button>
      <button onClick={() => onPress('3')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">3</button>
      <button onClick={() => onPress('4')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">4</button>
      <button onClick={() => onPress('5')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">5</button>
      <button onClick={() => onPress('6')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">6</button>
      <button onClick={() => onPress('7')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">7</button>
      <button onClick={() => onPress('8')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">8</button>
      <button onClick={() => onPress('9')} type="button" className="appearance-none py-1 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">9</button>

      <button type="button" className="p-5 pb-0 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]"></button>
      <button onClick={() => onPress('0')} type="button" className="p-5 pb-0 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">0</button>
      <button onClick={() => onPress('backspace')} type="button" className="p-5 pb-0 font-quicksand-semibold hover:opacity-75 transition-all ease-in-out duration-150 text-[28px]">
        <IconArrowLeft className="mx-auto" />
      </button>
    </div>
  )
}

InputNumpad.propTypes = {
  onPress: PropTypes.func,
};

export default InputNumpad