import { automatedNumberFormat } from 'utils/format';
import { object, number } from 'yup';

export const cashWithdrawSchema = object().shape({
  amount: number()
    .test(
      'amount',
      'Jumlah saldo yang ditarik minimal 10.000',
      function (value) {
        return value >= 10000;
      }
    )
    .test(
      'amount',
      'Penarikan dana kurang dari 25jt ke Bank tujuan selain (Bank BNI) dikenakan biaya 6.500',
      function (value, { parent }) {
        if (![3]?.includes(parent?.bankAccountId)) {
          if (value < 25000000 && value > (parent?.withdrawableCash - 6500)) {
            return this.createError({ message: `${`Anda akan menarik dana ke Bank ${parent?.bankName} dan dikenakan biaya sebesar Rp6.500, maksimal penarikan dana kamu sejumlah Rp${automatedNumberFormat(Math.floor(parent?.withdrawableCash - 6500))}`}` });
          }

          if (value >= 25000000 && value > parent?.withdrawableCash - 2900 && value < 100000000 ) {
            return this.createError({ message: `${`Anda akan menarik dana ke Bank ${parent?.bankName} dan dikenakan biaya sebesar Rp2.900, maksimal penarikan dana kamu sejumlah Rp${automatedNumberFormat(Math.floor(parent?.withdrawableCash - 2900))}`}` });
          }

          if (value >= 100000000 && value > parent?.withdrawableCash - 30000) {
            return this.createError({ message: `${`Anda akan menarik dana ke Bank ${parent?.bankName} dan dikenakan biaya sebesar Rp30.000, maksimal penarikan dana kamu sejumlah Rp${automatedNumberFormat(Math.floor(parent?.withdrawableCash - 30000))}`}` });
          }
        }
        return value;
      }
    )
    .required('Jumlah saldo yang ditarik tidak boleh kosong')
});
