import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import RedirectBasedOnRole from './redirect-based-on-role'
import useBrowserTabAuth from 'hooks/useBrowserTabAuth'

const GuestGuard = ({ children }) => {
  const { isLogin, roles } = useBrowserTabAuth()
  const { pathname, state } = useLocation();

  if (isLogin && !pathname.includes('logout')) {
    return <RedirectBasedOnRole roles={roles} state={state} />
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
