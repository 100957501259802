// eslint-disable-next-line react/prop-types
function IconGrid({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3C8.44772 3 8 3.44772 8 4V8H4C3.44772 8 3 8.44771 3 9C3 9.55228 3.44772 10 4 10H8V14H4C3.44772 14 3 14.4477 3 15C3 15.5523 3.44772 16 4 16H8V20C8 20.5523 8.44772 21 9 21C9.55228 21 10 20.5523 10 20V16L14 16V20C14 20.5523 14.4477 21 15 21C15.5523 21 16 20.5523 16 20V16H20C20.5523 16 21 15.5523 21 15C21 14.4477 20.5523 14 20 14H16V10L20 10C20.5523 10 21 9.55229 21 9C21 8.44772 20.5523 8 20 8L16 8V4C16 3.44772 15.5523 3 15 3C14.4477 3 14 3.44772 14 4V8H10V4C10 3.44772 9.55228 3 9 3ZM14 14V10H10V14L14 14Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8ZM8 5C6.34315 5 5 6.34315 5 8V16C5 17.6569 6.34315 19 8 19H16C17.6569 19 19 17.6569 19 16V8C19 6.34315 17.6569 5 16 5H8Z"
      />
    </svg>
  );
}

export default IconGrid;
