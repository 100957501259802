import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import Tooltip from 'components/basic/tooltip';
import IconBell from 'components/svg/icon-bell';
import IconBellDelete from 'components/svg/icon-bell-delete';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import IconSearchPage from 'components/svg/icon-search-page';
import IconStar from 'components/svg/icon-star';
import IconStarDelete from 'components/svg/icon-star-delete';
import { useCompareStock } from 'hooks/useCompareStock';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { triggerSegmentEvent } from 'lib/segment';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
  useDeletePriceAlertMutation,
  useGetPriceAlertByCodeQuery
} from 'services/rtk-query/price-alert';
import { useCreateWatchlistMutation, useDeleteWatchlistMutation } from 'services/rtk-query/watchlist';
import useCustomNavigate from 'hooks/useCustomNavigate';

function TVHeaderMobile({ symbol }) {
  const { navigate, customNavigate } = useCustomNavigate();
  const { getErrorMessage } = useGetErrorMessage();
  const { addToCompareStock } = useCompareStock();

  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { stockDetail } = useRealtimeStock(symbol, false);
  const { data: priceAlert } = useGetPriceAlertByCodeQuery(symbol);
  const [deletePriceAlert] = useDeletePriceAlertMutation();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();

  const handleClickPriceAlert = async (priceAlert) => {
    if (isEmpty(priceAlert) && !priceAlert?.is_active) {
      customNavigate({ query: { 'price-alert': symbol }, isPushQuery: true });
    } else {
      try {
        await deletePriceAlert(priceAlert).unwrap();
      } catch (error) {
        getErrorMessage(error);
      }
    }
  };

  const handleClickWatchlist = async (stock) => {
    triggerSegmentEvent({ event: 'TR Watchlist Clicked' });
    if (!stock?.isWatched) {
      try {
        await createWatchlist({
          stockCode: stock?.id,
          type: 'stock'
        }).unwrap();
      } catch (error) {
        getErrorMessage(error);
      }
    } else {
      try {
        await deleteWatchlist({ stockCode: stock?.id }).unwrap();
      } catch (error) {
        getErrorMessage(error);
      }
    }
  };

  const getConditionPriceAlert = (priceAlert) => {
    if (priceAlert?.when === 'upper') {
      return stockDetail?.lastTradedPrice > priceAlert?.price;
    }

    if (priceAlert?.when === 'lower') {
      return stockDetail?.lastTradedPrice < priceAlert?.price;
    }

    if (priceAlert?.when === 'equal') {
      return stockDetail?.lastTradedPrice === priceAlert?.price;
    }
  };

  return (
    <div className="flex lg:hidden items-center justify-between my-3">
      <Tooltip
        tooltipHTML={
          <p className="text-2xs font-quicksand-semibold">Tradingview</p>
        }>
        <button
          type="button"
          onClick={() => {
            triggerSegmentEvent({
              event: 'TR Stock Detail Trading View Clicked'
            });
            customNavigate({ query: { 'advanced-chart': symbol }});
          }}
          className="cursor-pointer group">
          <IconChartCandleStick className="h-4 w-4 mr-2 text-main-base-l" />
        </button>
      </Tooltip>

      <Tooltip
        tooltipHTML={
          <p className="text-2xs font-quicksand-semibold">Stock Detail</p>
        }>
        <button
          type='button'
          onClick={() => customNavigate({ query: { 'stock-detail': symbol }})}
          className="cursor-pointer rounded-md group inline-block">
          <IconSearchPage className="h-4 w-4 text-main-base-l mr-2" />
        </button>
      </Tooltip>

      <Tooltip
        tooltipHTML={
          <p className="text-2xs font-quicksand-semibold">
            {isEmpty(priceAlert)
              ? 'Price Alert Tidak Aktif'
              : 'Price Alert Aktif'}
          </p>
        }>
        <button
          type="button"
          className={`${
            getConditionPriceAlert(priceAlert) &&
            isBursaOpen &&
            'animate-blink-infinite'
          }`}
          onClick={() => handleClickPriceAlert(priceAlert)}>
          <RenderIf isTrue={isEmpty(priceAlert)}>
            <IconBellDelete className="h-4 w-4 mr-2 text-main-base-l cursor-pointer" />
          </RenderIf>

          <RenderIf isTrue={!isEmpty(priceAlert)}>
            <IconBell className="h-4 w-4 mr-2 text-main-base-l cursor-pointer" />
          </RenderIf>
        </button>
      </Tooltip>

      <Tooltip
        tooltipHTML={
          <p className="text-2xs font-quicksand-semibold">Bandingkan</p>
        }>
        <button
          type="button"
          onClick={() => {
            triggerSegmentEvent({ event: 'TR Compare Stock Clicked' });
            addToCompareStock({ code: symbol, name: stockDetail?.name }, () => {
              navigate(`/compare/fundamental`);
            });
          }}>
          <IconCompare className="h-4 w-4 mr-2 text-main-base-l cursor-pointer" />
        </button>
      </Tooltip>

      <Tooltip
        tooltipHTML={
          <p className="text-2xs font-quicksand-semibold">
            {stockDetail?.isWatched
              ? 'Watchlist Aktif'
              : 'Watchlist Tidak Aktif'}
          </p>
        }>
        <button type="button" onClick={() => handleClickWatchlist(stockDetail)}>
          <RenderIf isTrue={stockDetail?.isWatched}>
            <IconStar className="h-4 w-4 text-main-base-l cursor-pointer" />
          </RenderIf>

          <RenderIf isTrue={!stockDetail?.isWatched}>
            <IconStarDelete className="h-4 w-4 text-main-base-l cursor-pointer" />
          </RenderIf>
        </button>
      </Tooltip>
    </div>
  );
}

TVHeaderMobile.propTypes = {
  symbol: PropTypes.string
};

export default TVHeaderMobile;
