import PropTypes from 'prop-types';

function IconDeposit({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16667 2.5H15.8333V14.1667C15.8333 16.0076 14.341 17.5 12.5 17.5H7.50001C5.65906 17.5 4.16667 16.0076 4.16667 14.1667V2.5ZM5.83334 4.16667V14.1667C5.83334 15.0871 6.57953 15.8333 7.50001 15.8333H12.5C13.4205 15.8333 14.1667 15.0871 14.1667 14.1667V4.16667H5.83334Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41074 6.07739C9.73618 5.75195 10.2638 5.75195 10.5893 6.07739L12.2559 7.74406C12.5814 8.06949 12.5814 8.59713 12.2559 8.92257C11.9305 9.24801 11.4028 9.24801 11.0774 8.92257L10.8333 8.67849V13.3333C10.8333 13.7936 10.4602 14.1666 10 14.1666C9.53976 14.1666 9.16667 13.7936 9.16667 13.3333V8.67849L8.92259 8.92257C8.59715 9.24801 8.06951 9.24801 7.74408 8.92257C7.41864 8.59713 7.41864 8.06949 7.74408 7.74406L9.41074 6.07739Z"
      />
      <path d="M2.5 3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5H16.6667C17.1269 2.5 17.5 2.8731 17.5 3.33333C17.5 3.79357 17.1269 4.16667 16.6667 4.16667H3.33333C2.8731 4.16667 2.5 3.79357 2.5 3.33333Z" />
    </svg>
  );
}

IconDeposit.propTypes = {
  className: PropTypes.string
};

export default IconDeposit;
