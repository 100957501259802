import RenderIf from 'components/basic/render-if';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputText from 'components/input/input-text';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { RDNStep01Validation } from './rdn-step-01.validation';
import { FocusError } from 'focus-formik-error';
import { useCreateRequestPhoneCallMutation, useCreateRequestPhoneSMSMutation, useCreateVerifyAccountStep01Mutation, useCreateVerifyPhoneSMSMutation } from 'services/rtk-query/verify';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { invalidateUserProfile, useGetUserProfileQuery } from 'services/rtk-query/user';
import Card from 'components/basic/card';
import IconPhone from 'components/svg/icon-phone';
import InputPin from 'components/input/input-pin';
import { useCountdown } from 'hooks/useCountDown';
import dayjs from 'dayjs';
import ListStatus from 'components/basic/list-status';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import { triggerSegmentEvent, triggerSegmentIdentify } from 'lib/segment';
import { useDispatch } from 'react-redux';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import HeaderOnboarding from '../header-onboarding';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function RDNStep01() {
  const dispatch = useDispatch();
  const { getErrorMessage } = useGetErrorMessage();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['requestOTP']);
  const { user } = useBrowserTabAuth();

  const { data: userDetail } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const [createRequestPhoneCall] = useCreateRequestPhoneCallMutation();
  const [createRequestPhoneSMS] = useCreateRequestPhoneSMSMutation();

  const [createVerifyPhoneSMS] = useCreateVerifyPhoneSMSMutation();
  const [createVerifyAccountStep01] = useCreateVerifyAccountStep01Mutation();

  const [loadingVerifyOTP, setLoadingVerifyOTP] = useState(false);
  const [loadingRequestOTP, setLoadingRequestOTP] = useState(false);
  const [isSendOTP, setIsSendOtp] = useState(false);
  const [targetDate, setTargetDate] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      otp: '',
      otpType: 'sms',
      agreement: true,
      digitOne: '',
      digitTwo: '',
      digitThree: '',
      digitFour: '',
      isSendOTP: false
    },
    validateOnMount: false,
    validationSchema: RDNStep01Validation,
    onSubmit: async (values) => {
      if (!isSendOTP) {
        requestOTP(values);
      } else if (isSendOTP) {
        verifyOTP(values);
      }
    }
  });

  const requestOTP = async (values) => {
    setLoadingRequestOTP(true);
    try {
      const msisdn = values?.phoneNumber.substring(3, 20);
      let currentRequestOTP = null;
      let otp = null;

      if (cookies.requestOTP) currentRequestOTP = cookies.requestOTP;

      if (currentRequestOTP?.count > 2) {
        const error = new Error();
        error.message = 'Pengiriman kode OTP hanya bisa dilakukan 3x dalam 24 jam';
        throw error;
      }

      if (values.otpType === 'misscall') {
        const { token } = await createRequestPhoneCall({ msisdn: `62${msisdn}`, gateway: Math.floor(Math.random() * 5) }).unwrap();
        otp = token.substr(token.length - 4);
      } else if (values.otpType === 'sms') {
        await createRequestPhoneSMS({ msisdn : `62${msisdn}` }).unwrap();
      }

      setCookie('requestOTP', JSON.stringify({ otp: otp, count: 1 }), { path: '/', maxAge: currentRequestOTP?.count > 2 ? 3600 : 600 });
      setTargetDate(dayjs().add(1, 'minute'));
      formik.setFieldValue('isSendOTP', true);
      setIsSendOtp(true);

      if (currentRequestOTP) {
        triggerSegmentEvent({
          event: 'TR Resend Verification Code Requested'
        });
      }
      
      triggerSegmentEvent({
        event: 'TR Phone Number Inputted',
        properties: {
          phoneNumber: `62${msisdn}`
        }
      });

      triggerSegmentIdentify({
        userId: user?.id,
        properties: {
          phoneNumber: `62${msisdn}`
        }
      });
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setLoadingRequestOTP(false);
    }
  }

  const verifyOTP = async (values) => {
    setLoadingVerifyOTP(true);
    try {
      let msisdn = values?.phoneNumber.substring(3, 100);

      if (values.otpType === 'misscall') {
        const currentRequestOTP = cookies.requestOTP;
        let tokenOTP = currentRequestOTP.otp;

        if (tokenOTP != values.otp) {
          const error = new Error();
          error.message = 'OTP yang anda masukan tidak valid';
          throw error;
        }
      } else if (values.otpType === 'sms') {
        await createVerifyPhoneSMS({ msisdn : `62${msisdn}`, otp: values.otp }).unwrap();
      }

      await createVerifyAccountStep01({ phoneNo: `0${msisdn}`, motherName: '', isNonFatca: 1 }).unwrap();

      dispatch(invalidateUserProfile());
      navigate('/onboarding/create-pin');
      setIsSendOtp(true);

      triggerSegmentEvent({
        event: 'TR Verification Code Inputted'
      });
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setLoadingVerifyOTP(false);
    }
  }

  const handleChange = (e, index) => {
    let isBackSpace = false;
    if (e.target.value === '') {
      index = index - 1;
      isBackSpace = true;
    }

    let nextEl = null;
    if (isBackSpace) {
      if (index === 0) {
        nextEl = document.getElementsByName('digitOne');
      } else if (index === 1) {
        nextEl = document.getElementsByName('digitTwo');
      } else if (index === 2) {
        nextEl = document.getElementsByName('digitThree');
      }
    } else {
      if (index === 0) {
        nextEl = document.getElementsByName('digitTwo');
      } else if (index === 1) {
        nextEl = document.getElementsByName('digitThree');
      } else if (index === 2) {
        nextEl = document.getElementsByName('digitFour');
      }
    }

    if (nextEl) {
      nextEl[0].focus();
    }

    if (index === 3) {
      setTimeout(() => {
        const ell = document.getElementById('btnSubmit');
        ell.focus();
        formik.handleSubmit(`${formik.values.digitOne}${formik.values.digitTwo}${formik.values.digitThree}${e.target.value}`)
      }, 500);
    }

    formik.handleChange(e);
    formik.setFieldValue('otp', `${formik.values.digitOne}${formik.values.digitTwo}${formik.values.digitThree}${e.target.value}`)
  };

  const handleKeyDown = (e, index) => {
    let nextEl = null;

    if (e.key === 'Enter' && formik.isValid) {
      return formik.handleSubmit(`${formik.values.digitOne}${formik.values.digitTwo}${formik.values.digitThree}${formik.values.digitFour}`);
    }

    if (e.key === 'Backspace' && e.target.value === '') {
      if (index === 1) {
        nextEl = document.getElementsByName('digitOne');
      } else if (index === 2) {
        nextEl = document.getElementsByName('digitTwo');
      } else if (index === 3) {
        nextEl = document.getElementsByName('digitThree');
      }
    }

    if (nextEl) {
      nextEl[0].focus();
    }

    if (e.target.value.length > 0 && e.key != 'Backspace') {
      if (index === 0) {
        nextEl = document.getElementsByName('digitTwo');
      } else if (index === 1) {
        nextEl = document.getElementsByName('digitThree');
      } else if (index === 2) {
        nextEl = document.getElementsByName('digitFour');
      }

      if (nextEl) {
        nextEl[0].focus();
      }

      e.preventDefault();
    }

    const patt = /^\d+\.?\d*$/;
    if (!patt.test(e.key) && e.key != 'Backspace') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (userDetail && userDetail?.phoneNo) navigate('/onboarding/create-pin');
  }, [userDetail, navigate]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:py-16 text-white relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto">
          <HeaderOnboarding activeStep={1} />

          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] mb-3 text-white">
            Selamat datang <br className='lg:hidden'/> di PINA Trade
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Trading menjadi lebih mudah dan cepat dengan PINA Trade
          </p>

          <ListStatus
            data={[
              {
                title: 'Daftarkan email kamu',
                subtitle: null,
                status: true
              },
              {
                title: 'Daftarkan no handphonemu',
                subtitle: null,
                status: isSendOTP
              },
              {
                title: 'Verifikasi No HP',
                subtitle: null,
                status: false
              }
            ]}
          />
        </div>
      </div>

      <div className="p-0 lg:p-16">
        <Card className="px-6 lg:px-8 w-full lg:w-4/12 max-w-lg min-h-[300px] lg:min-w-[450px] mx-auto py-8">
          <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
            <FocusError formik={formik} />
            <RenderIf isTrue={!isSendOTP}>
              <div className="lg:mb-72">
                <h5 className="text-2xl lg:text-3xl mb-4 font-quicksand-regular text-main-gold-d">Masukkan nomor <br className='hidden lg:block'/> handphone</h5>
                <InputText
                  value={formik?.values?.phoneNumber}
                  name="phoneNumber"
                  placeholder="+62"
                  label="No. Handphone"
                  iconLeft={<IconPhone className='text-main-gold-d' />}
                  className="mb-4"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (!['+62', '+6', '+', ''].includes(e.target.value.substring(0, 3))) {
                      formik.handleChange(e);
                      formik.setFieldValue('phoneNumber', '+62' + (e.target.value.substring(0, 3) != '0' ? e.target.value : ''));
                    } else {
                      formik.handleChange(e);
                    }
                  }}
                  error={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : ''}
                />
              </div>
            </RenderIf>

            <RenderIf isTrue={isSendOTP}>
              <RenderIf isTrue={formik.values.otpType === 'misscall'}>
                <h5 className="mb-4 text-xl lg:text-3xl text-main-gold-d font-quicksand-regular">Tunggu Misscall dari Pina</h5>
                <p className="text-gray-light-0-d">
                  Masukan <b>4 angka terakhir</b> nomor telepon yang menelepon kamu. Nomor
                  yang anda masukan :
                </p>
              </RenderIf>

              <RenderIf isTrue={formik.values.otpType === 'sms'}>
                <h5 className="mb-4 text-xl lg:text-3xl text-main-gold-d font-quicksand-regular">Cek SMS dari Pina</h5>
                <p className="text-gray-light-0-d">
                  Masukan <b>4 angka OTP</b> yang dikirim melalui SMS.
                </p>
              </RenderIf>

              <h5 className='text-center mt-4 mb-6 font-quicksand-semibold text-xl'>{formik.values.phoneNumber.substring(0,3)}-{formik.values.phoneNumber.substring(3,7)}-{formik.values.phoneNumber.substring(7,11)}-{formik.values.phoneNumber.substring(11,20)}</h5>

              <div className="grid grid-cols-4 mb-6 w-[270px] mx-auto gap-3">
                <InputPin
                  variant="gold"
                  data-qa-id="txtPinField"
                  name="digitOne"
                  type="text"
                  className="text-lg lg:text-2xl text-center"
                  value={formik.values.digitOne}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => handleKeyDown(e, 0)}
                  onChange={(e) => handleChange(e, 0)}
                  autoComplete={false}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  inputStyle='square'
                />
                <InputPin
                  variant="gold"
                  data-qa-id="txtPinField"
                  name="digitTwo"
                  type="text"
                  className="text-lg lg:text-2xl text-center"
                  value={formik.values.digitTwo}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => handleKeyDown(e, 1)}
                  autoComplete={false}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  inputStyle='square'
                  onChange={(e) => handleChange(e, 1)}
                />
                <InputPin
                  variant="gold"
                  data-qa-id="txtPinField"
                  name="digitThree"
                  type="text"
                  className="text-lg lg:text-2xl text-center"
                  value={formik.values.digitThree}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => handleKeyDown(e, 2)}
                  autoComplete={false}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  inputStyle='square'
                  onChange={(e) => handleChange(e, 2)}
                />
                <InputPin
                  variant="gold"
                  data-qa-id="txtPinField"
                  name="digitFour"
                  type="text"
                  className="text-lg lg:text-2xl text-center"
                  value={formik.values.digitFour}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => handleKeyDown(e, 3)}
                  autoComplete={false}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  inputStyle='square'
                  onChange={(e) => handleChange(e, 3)}
                />
              </div>

              <div className='flex items-center justify-center text-main-gold-d mb-40'>
                <RenderIf isTrue={seconds > 0}>
                  <h5 className="font-quicksand-regular text-gray-light-0-d text-sm">
                    Kirim Ulang OTP
                  </h5>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <span className='font-quicksand-semibold'>00:{seconds > 10 ? seconds : '0' + seconds}</span>
                </RenderIf>

                <RenderIf isTrue={seconds <= 0}>
                  <h5 onClick={() => requestOTP(formik.values)} className="hover:underline font-quicksand-regular cursor-pointer text-main-gold-d text-sm flex items-center">
                    {loadingRequestOTP && <SimpleSpinner className='text-main-gold-d h-4 w-4 mx-2' />}
                    Kirim Ulang OTP
                  </h5>
                </RenderIf>
              </div>
            </RenderIf>

            <div className="mt-10 fixed lg:relative w-full bottom-0 left-0 p-6 lg:p-0 bg-white border-t lg:border-none">
              <div className='-mx-2 flex justify-center'>
                <RenderIf isTrue={isSendOTP}>
                  <button
                    type="button"
                    onClick={() => {
                      setIsSendOtp(false);
                      formik.setFieldValue('isSendOTP', false);
                    }}
                    className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 flex px-10 mx-2 w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden focus:outline-none border-2 border-main-gold-d bg-white text-main-gold-d`}>
                    Kembali
                  </button>
                </RenderIf>

                <button
                  id="btnSubmit"
                  type="submit"
                  disabled={!formik.isValid || loadingVerifyOTP || loadingRequestOTP}
                  className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 flex px-10 mx-2 w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden focus:outline-none ${
                    formik.isValid
                      ? 'bg-main-gold-d text-white'
                      : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                  }`}>
                  {loadingVerifyOTP || loadingRequestOTP  && <SimpleSpinner />}
                  {isSendOTP ? 'Selanjutnya' : 'Kirim OTP'}
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default RDNStep01;
