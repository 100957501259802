import PropTypes from 'prop-types';

function IconCheckShield({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4316 4.11558C12.1581 3.98933 11.8421 3.98933 11.5685 4.11557L5.59187 6.87373C5.26881 7.02282 5.09439 7.28997 5.07614 7.54418C4.89712 10.0374 5.42256 13.2398 6.65814 15.7864C7.90384 18.3539 9.71371 19.9791 12.0001 19.9791C14.3634 19.9791 16.2206 18.2388 17.4646 15.5264C18.6898 12.8549 19.1549 9.54724 18.8828 7.0928L12.4316 4.11558ZM10.7055 2.28404C11.5261 1.90532 12.4741 1.90532 13.2947 2.28405L19.7613 5.26834C20.3651 5.54703 20.8265 6.11787 20.9076 6.82609C21.2303 9.64593 20.709 13.342 19.3242 16.3616C17.9515 19.3545 15.5602 22 12.0001 22C8.55819 22 6.20606 19.5237 4.81723 16.6612C3.41827 13.7778 2.83663 10.2217 3.03916 7.40095C3.11679 6.31973 3.82578 5.45893 4.7288 5.0422L10.7055 2.28404Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
      />
    </svg>
  );
}

IconCheckShield.propTypes = {
  className: PropTypes.string
};

export default IconCheckShield;
