import RenderIf from 'components/basic/render-if';
import { useState } from 'react';
import RDNStep03UploadSelfie from './upload-selfie';
import RDNStep03NPWP from './npwp';
import RDNStep03OtherInfo from './informasi-tambahan';

function RDNStep03() {
  const [selfieImage, setSelfieImage] = useState(null);
  const [npwpData, setNpwpData] = useState(null);
  const [skipNPWP, setSkipNPWP] = useState(false);

  return (
    <>
      <RenderIf isTrue={!selfieImage}>
        <RDNStep03UploadSelfie onSuccess={(_selfieImage) => setSelfieImage(_selfieImage)} />
      </RenderIf>

      <RenderIf isTrue={selfieImage && !npwpData && !skipNPWP}>
        <RDNStep03NPWP
          onSuccess={(npwp) => setNpwpData(npwp)}
          onSkip={() => setSkipNPWP(true)}
          onClickBack={() => setSelfieImage(null)}
        />
      </RenderIf>

      <RenderIf isTrue={selfieImage && (npwpData || skipNPWP)}>
        <RDNStep03OtherInfo
          npwpData={npwpData}
          selfieImage={selfieImage}
          onSuccess={(npwp) => setNpwpData(npwp)}
          onClickBack={() => {
            setSkipNPWP(false);
            setNpwpData(null);
          }}
        />
      </RenderIf>
    </>
  );
}

export default RDNStep03;
