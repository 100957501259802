import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { useEffect, useState } from 'react';
import { currency } from 'utils/format';
import { isArray, sumBy } from 'lodash';
import useFocus from 'hooks/useFocus';

function StockWithdrawConfirmation({
  onClose = () => {},
  onSubmit = () => {},
  isSubmitting,
  isMultiple = false,
  data,
  symbol
}) {
  const [refFocus, setSubmitFocus] = useFocus();
  const [open, setOpen] = useState(false);

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  useEffect(() => {
    setTimeout(() => setSubmitFocus(), 100);
  }, [open]);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div
      className="bg-white dark:bg-black-dark-d rounded-lg dark:border dark:border-gray-base-d dark:text-black-light-1-d p-6 z-10 relative transform max-w-md mx-auto"
      style={{ transform: 'translateY(-3px)' }}>
      <div className="text-center pb-2 border-b dark:border-gray-base-d">
        <h5>Mohon cek kembali order kamu</h5>
      </div>

      <RenderIf isTrue={!isMultiple}>
        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Kode Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {data?.code}
          </span>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Tipe Transaksi
          </span>
          <span className="flex-grow text-right font-quicksand-bold text-orange-2">
            Withdraw
          </span>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Harga Per Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.price || 0, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jumlah Lot
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.qty / 100 || 0, { removeSymbol: true })}
          </span>
        </div>
      </RenderIf>

      <RenderIf isTrue={isMultiple}>
        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Kode Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {symbol}
          </span>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Tipe Transaksi
          </span>
          <span className="flex-grow text-right font-quicksand-bold text-orange-2">
            Withdraw
          </span>
        </div>

        <table className="w-full border dark:border-gray-base-d">
          <thead className="font-quicksand-semibold">
            <tr className="border-b dark:border-gray-base-d">
              <td className="text-center py-1">Harga Saham</td>
              <td className="text-center py-1">Jumlah LOT</td>
              <td className="text-center py-1">Subtotal</td>
            </tr>
          </thead>
          <tbody>
            {(isArray(data) ? data : [])?.map((item, key) => (
              <tr key={key} className="border-b dark:border-gray-base-d">
                <td className="text-center py-1">
                  {currency(item?.price, { removeSymbol: true })}
                </td>
                <td className="text-center py-1">
                  {currency(item?.qty / 100, { removeSymbol: true })}
                </td>
                <td className="text-center py-1 font-quicksand-semibold">
                  {currency(item?.qty * item?.price, {
                    removeSymbol: true
                  })}
                </td>
              </tr>
            ))}
            <tr className="border-b font-quicksand-semibold dark:border-gray-base-d">
              <td className="text-center py-1">Total</td>
              <td className="text-center py-1">
                {currency(sumBy(data, 'qty') / 100, { removeSymbol: true })}
              </td>
              <td className="text-center py-1">
                {currency(
                  sumBy(data, (i) => i?.qty * i?.price),
                  { removeSymbol: true }
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </RenderIf>

      <div className="flex my-3 items-center border-y py-2 dark:border-gray-base-d">
        <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
          Jumlah Investasi
        </span>
        <RenderIf isTrue={!isMultiple}>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.price * data?.qty, { removeSymbol: true })}
          </span>
        </RenderIf>
        <RenderIf isTrue={isMultiple}>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(
              sumBy(data, (i) => i?.qty * i?.price),
              { removeSymbol: true }
            )}
          </span>
        </RenderIf>
      </div>

      <div className="pt-3 mt-1">
        <div className="grid grid-cols-12 gap-4">
          <button
            data-qa-id="btnStockBuyFinishConfirmation"
            type="button"
            onClick={onClose}
            className="col-span-4 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75">
            Batal
          </button>

          <button
            ref={refFocus}
            type="button"
            onClick={onSubmit}
            disabled={isSubmitting}
            className={`col-span-8 bg-orange-2 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75`}>
            {isSubmitting && <Spinner />}
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
}

StockWithdrawConfirmation.propTypes = {
  data: PropTypes.object,
  symbol: PropTypes.string,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isFinished: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isMultiple: PropTypes.bool
};

export default StockWithdrawConfirmation;
