export const CONDITIONAL_OPERATOR = [
  {
    name: '<',
    id: '<'
  },
  {
    name: '≤',
    id: '<='
  },
  {
    name: '=',
    id: '='
  },
  {
    name: '≥',
    id: '>='
  },
  {
    name: '>',
    id: '>'
  }
];
