import PropTypes from 'prop-types';
import IconCheck from 'components/svg/icon-check';

import styles from './css/list-status.module.css';

function ListStatus({ data = [], className, color = 'white', verticalOnly }) {
  return (
    <ul className={`${styles['stepper-container']} ${styles[`stepper-color-${color}`]} ${verticalOnly && styles['stepper-vertical']} ${className}`}>
      {data?.map((item, key) => (
        <li key={key} className={`${styles['stepper-list']} group`}>
          <div className={styles['stepper-list-container']}>
            <IconCheck className={`${styles['stepper-icon']} ${!item?.status && styles['stepper-icon-disabled']}`} />
            <h5 className={`${styles['stepper-title']}`}>{item?.title}</h5>
            <p className={`${styles['stepper-subtitle']}`}>{item?.subtitle}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}

ListStatus.propTypes = {
  colorSchema: PropTypes.object,
  data: PropTypes.array,
  className: PropTypes.string,
  color: PropTypes.string,
  verticalOnly: PropTypes.bool,
};

export default ListStatus;
