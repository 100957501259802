// eslint-disable-next-line react/prop-types
function IconGoogle({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 12.2398C22 11.5645 21.9333 10.8673 21.8222 10.2137H12.1973V14.0699H17.7099C17.4876 15.3117 16.7541 16.401 15.6649 17.0981L18.9547 19.6035C20.8886 17.8389 22 15.2681 22 12.2398Z"
        fill="#4280EF"
      />
      <path
        d="M12.1973 22C14.9536 22 17.2653 21.1068 18.9547 19.5817L15.6649 17.0981C14.7535 17.7081 13.5754 18.0567 12.1973 18.0567C9.52987 18.0567 7.2848 16.292 6.46235 13.9391L3.08363 16.4881C4.81745 19.865 8.32953 22 12.1973 22Z"
        fill="#34A353"
      />
      <path
        d="M6.46235 13.9173C6.04001 12.6755 6.04001 11.3248 6.46235 10.083L3.08363 7.51224C1.63879 10.3444 1.63879 13.6777 3.08363 16.4881L6.46235 13.9173Z"
        fill="#F6B704"
      />
      <path
        d="M12.1973 5.96545C13.6421 5.94366 15.0647 6.48831 16.1095 7.46869L19.0214 4.59292C17.1764 2.89361 14.7313 1.97859 12.1973 2.00038C8.32953 2.00038 4.81745 4.13542 3.08363 7.51226L6.46235 10.083C7.2848 7.70833 9.52987 5.96545 12.1973 5.96545Z"
        fill="#E54335"
      />
    </svg>
  );
}

export default IconGoogle;
