import { clone, reverse } from 'lodash';
import { marginMinusShare, marginPlusShare } from 'utils/formula/margin';

export const generateOrderbook = (
  resOrderbook,
  lastTradedPrice,
  lengthOrderbook = 10
) => {
  const resOrderbookLength = (resOrderbook?.bid?.length > resOrderbook?.offer?.length ? resOrderbook?.bid?.length : resOrderbook?.offer?.length) || 0;

  const bid = [];
  const ask = [];
  let totalBidLot = 0;
  let totalAskLot = 0;

  if (resOrderbookLength === 0) {
    let priceBid = lastTradedPrice;
    let priceAsk = lastTradedPrice + marginPlusShare(lastTradedPrice);

    for (let index = 0; index < lengthOrderbook; index++) {
      bid.push({
        price: priceBid,
        lot: 0
      });

      ask.push({
        price: priceAsk,
        lot: 0
      });

      priceBid -= marginMinusShare(priceBid);
      priceAsk += marginPlusShare(priceAsk);
    }
  } else {
    for (let index = 0; index < (resOrderbookLength >= lengthOrderbook ? resOrderbookLength : lengthOrderbook); index++) {
      const bids = resOrderbook?.bid?.[index] || [false, false, false];
      const offers = resOrderbook?.offer?.[index] || [false, false, false];
      
      totalBidLot += bids[1];
      totalAskLot += offers[1];

      bid.push({
        price: bids[0] ? bids[0] : 0,
        lot: bids[1] ? bids[1] : 0
      });

      ask.push({
        price: offers[0] ? offers[0] : 0,
        lot: offers[1] ? offers[1] : 0
      });
    }
  }

  return {
    bid: bid?.filter((item) => item?.price),
    ask: ask?.filter((item) => item?.price),
    totalBidLot,
    totalAskLot
  };
};

export const generateFastOrderbook = (
  resOrderbook,
  lastTradedPrice,
  lengthOrderbook = 10
) => {
  const resOrderbookLength = (resOrderbook?.bid?.length > resOrderbook?.offer?.length ? resOrderbook?.bid?.length : resOrderbook?.offer?.length) || 0;
  
  const bid = [];
  const ask = [];
  let totalBidLot = 0;
  let totalAskLot = 0;
  
  if (resOrderbookLength === 0) {
    let priceBid = lastTradedPrice;
    let priceAsk = lastTradedPrice + marginPlusShare(lastTradedPrice);

    for (let index = 0; index < lengthOrderbook; index++) {
      bid.push({
        price: priceBid,
        lot: 0
      });

      ask.push({
        price: priceAsk,
        lot: 0
      });

      priceBid -= marginMinusShare(priceBid);
      priceAsk += marginPlusShare(priceAsk);
    }
  } else {
    for (let index = 0; index < (resOrderbookLength >= lengthOrderbook ? resOrderbookLength : lengthOrderbook); index++) {
      const bids = resOrderbook?.bid?.[index] || [false, false, false];
      const offers = resOrderbook?.offer?.[index] || [false, false, false];
      
      totalBidLot += bids[1];
      totalAskLot += offers[1];

      bid.push({
        price: bids[0] ? bids[0] : 0,
        lot: bids[1] ? bids[1] : 0
      });

      ask.push({
        price: offers[0] ? offers[0] : 0,
        lot: offers[1] ? offers[1] : 0
      });
    }
  }

  return {
    list: [...reverse(clone(ask?.filter(item => item?.price))), ...bid?.filter(item => item?.price) || []],
    totalBidLot,
    totalAskLot,
    firstBidPrice: bid?.[0]?.price,
    firstAskPrice: ask?.[0]?.price,
  };
};
