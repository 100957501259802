import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { currency } from 'utils/format';
import { ORDER_COST } from 'constants';
import Spinner from 'components/common/spinner';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getStampCostBuy, getStampCostSell } from 'utils/calculation';
import useFocus from 'hooks/useFocus';

function ModalConfirmationSmartOrder({ isSubmitting, onSubmit = () => {}, onClose = () => {}, data = {} }) {
  const [refFocus, setSubmitFocus] = useFocus();
  const [refCancelFocus, setCancelFocus] = useFocus();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setSubmitFocus(), 100);
  }, [open]);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div className="max-w-md mx-auto">
      <div
        className="bg-white dark:bg-black-dark-d rounded-lg dark:border dark:border-gray-base-d dark:text-black-light-1-d p-6 z-10 relative transform"
        style={{ transform: 'translateY(-3px)' }}>
        <div className="text-center pb-2 border-b dark:border-gray-base-d">
          <h5>Mohon cek kembali order kamu</h5>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Kode Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {data?.StockId}
          </span>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Tipe Transaksi
          </span>

          <RenderIf isTrue={data?.BotName === 'SmartBuy'}>
            <span className="flex-grow text-right font-quicksand-bold text-main-base-l">
              {data?.BotName}
            </span>
          </RenderIf>

          <RenderIf isTrue={data?.BotName === 'SmartSell'}>
            <span className="flex-grow text-right font-quicksand-bold text-red-base-l">
              {data?.BotName}
            </span>
          </RenderIf>

          <RenderIf isTrue={data?.BotName === 'StopLoss'}>
            <span className="flex-grow text-right font-quicksand-bold text-red-base-l">
              {data?.BotName}
            </span>
          </RenderIf>

          <RenderIf isTrue={data?.BotName === 'TakeProfit'}>
            <span className="flex-grow text-right font-quicksand-bold text-main-base-l">
              {data?.BotName}
            </span>
          </RenderIf>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Harga Per Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.Price || 0, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jumlah Lot
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.Lot || 0, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex my-3 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Periode Berakhir
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {dayjs(data?.BotExpire).format('DD-MMM-YYYY') + ' 16:00:00'}
          </span>
        </div>

        <RenderIf isTrue={data?.stopLoss}>
          <div className='border rounded-md p-2 px-4 my-3 dark:border-gray-base-d'>
            <div className="flex my-2 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Jumlah Lot Stop Loss
              </span>
              <span className="flex-grow text-right font-quicksand-bold">
                {currency(data?.LotStop || 0, { removeSymbol: true })}
              </span>
            </div>

            <div className="flex my-2 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Harga Stop Loss
              </span>
              <span className="flex-grow text-right font-quicksand-bold">
                {currency(data?.PriceStop || 0, { removeSymbol: true })}
              </span>
            </div>

            <div className="flex my-2 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Periode Berakhir
              </span>
              <span className="flex-grow text-right font-quicksand-bold">
                {dayjs(data?.BotExpireStop).format('DD-MMM-YYYY') + ' 16:00:00'}
              </span>
            </div>
          </div>
        </RenderIf>

        <RenderIf isTrue={data?.takeProfit}>
          <div className='border rounded-md p-2 px-4 my-3 dark:border-gray-base-d'>
            <div className="flex my-2 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Jumlah Lot Take Profit
              </span>
              <span className="flex-grow text-right font-quicksand-bold">
                {currency(data?.LotProfit || 0, { removeSymbol: true })}
              </span>
            </div>

            <div className="flex my-2 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Harga Take Profit
              </span>
              <span className="flex-grow text-right font-quicksand-bold">
                {currency(data?.PriceProfit || 0, { removeSymbol: true })}
              </span>
            </div>

            <div className="flex my-2 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
                Periode Berakhir
              </span>
              <span className="flex-grow text-right font-quicksand-bold">
                {dayjs(data?.BotExpireProfit).format('DD-MMM-YYYY') + ' 16:00:00'}
              </span>
            </div>
          </div>
        </RenderIf>

        <RenderIf isTrue={data?.BotName === 'SmartBuy'}>
          <div className="flex my-3 items-center">
            <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
              Biaya Beli ({ORDER_COST?.buy * 100}%)
            </span>
            <span className="flex-grow text-right font-quicksand-bold text-gray-light-0-d">
              {currency(Math.ceil(ORDER_COST?.buy * data?.Price * data?.Lot), {
                removeSymbol: true
              })}
            </span>
          </div>

          <RenderIf isTrue={getStampCostBuy(data?.Price * data?.Lot * 100) > 0}>
            <div className="flex my-3 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">Biaya Materai</span>
              <span className="flex-grow text-right font-quicksand-bold">
                {currency(Math.ceil(ORDER_COST?.stampCost), { removeSymbol: true })}
              </span>
            </div>
          </RenderIf>

          <div className="flex my-3 items-center border-y py-2 dark:border-gray-base-d">
            <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
              Jumlah Investasi (+Biaya)
            </span>
            <span className="flex-grow text-right font-quicksand-bold">
              {currency(
                data?.Price * data?.Lot * 100 +
                  Math.ceil(ORDER_COST?.buy * data?.Price * data?.Lot) + getStampCostBuy(data?.Price * data?.Lot * 100),
                { removeSymbol: true }
              )}
            </span>
          </div>
        </RenderIf>

        <RenderIf isTrue={['SmartSell', 'TakeProfit', 'StopLoss'].includes(data?.BotName)}>
          <div className="flex my-3 items-center">
            <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
              Biaya ({ORDER_COST?.sell * 100}%)
            </span>
            <span className="flex-grow text-right font-quicksand-bold text-gray-light-0-d">
              {currency(Math.ceil(ORDER_COST?.sell * data?.Price * data?.Lot), {
                removeSymbol: true
              })}
            </span>
          </div>

          <RenderIf isTrue={getStampCostSell(data?.Price * data?.Lot * 100) > 0}>
            <div className="flex my-3 items-center">
              <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">Biaya Materai</span>
              <span className="flex-grow text-right font-quicksand-bold">
                {currency(Math.ceil(ORDER_COST?.stampCost), { removeSymbol: true })}
              </span>
            </div>
          </RenderIf>

          <div className="flex my-3 items-center border-y py-2 dark:border-gray-base-d">
            <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
              Jumlah Investasi (+Biaya)
            </span>
            <span className="flex-grow text-right font-quicksand-bold">
              {currency(
                data?.Price * data?.Lot * 100 -
                  Math.ceil(ORDER_COST?.sell * data?.Price * data?.Lot) - getStampCostSell(data?.Price * data?.Lot * 100),
                {
                  removeSymbol: true
                }
              )}
            </span>
          </div>
        </RenderIf>

        <div className="pt-3 mt-1">
          <div className="grid grid-cols-12 gap-4">
            <button
              data-qa-id="btnStockBuyFinishConfirmation"
              type="button"
              onClick={() => onClose()}
              ref={refCancelFocus}
              onKeyDown={(event) => {
                if (event.key === 'ArrowRight') {
                  event.preventDefault();
                  setSubmitFocus();
                }
              }}
              disabled={isSubmitting}
              className="col-span-6 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75">
              Batal
            </button>

            <button
              ref={refFocus}
              data-qa-id="btnStockBuySubmitConfirmation"
              type="button"
              onClick={() => onSubmit()}
              onKeyDown={(event) => {
                if (event.key === 'ArrowLeft') {
                  event.preventDefault();
                  setCancelFocus();
                }
              }}
              disabled={isSubmitting}
              className="col-span-6 bg-main-base-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75">
              {isSubmitting && <Spinner />}
              Konfirmasi
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ModalConfirmationSmartOrder.propTypes = {
  data: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default ModalConfirmationSmartOrder;
