// eslint-disable-next-line react/prop-types
function IconGrab({ className }) {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.70571" cy="1.41176" r="1.41176" />
      <circle cx="8.2936" cy="1.41176" r="1.41176" />
      <circle cx="1.70571" cy="8.23598" r="1.41176" />
      <circle cx="8.2936" cy="8.23598" r="1.41176" />
      <circle cx="1.70571" cy="15.0582" r="1.41176" />
      <circle cx="8.2936" cy="15.0582" r="1.41176" />
    </svg>
  );
}

export default IconGrab;
