import PropTypes from 'prop-types';

function IconChartWaterfall({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 13.3334C2 12.9652 2.29848 12.6667 2.66667 12.6667H13.3333C13.7015 12.6667 14 12.9652 14 13.3334C14 13.7016 13.7015 14.0001 13.3333 14.0001H2.66667C2.29848 14.0001 2 13.7016 2 13.3334Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 2C11.6317 2 11.3333 2.29848 11.3333 2.66667V6C11.3333 6.36819 11.6317 6.66667 11.9999 6.66667C12.3681 6.66667 12.6666 6.36819 12.6666 6V2.66667C12.6666 2.29848 12.3681 2 11.9999 2ZM6.66659 4.66667C6.2984 4.66667 5.99992 4.96514 5.99992 5.33333V8C5.99992 8.36819 6.2984 8.66667 6.66659 8.66667C7.03478 8.66667 7.33325 8.36819 7.33325 8V5.33333C7.33325 4.96514 7.03478 4.66667 6.66659 4.66667ZM3.99992 8.66667C3.63173 8.66667 3.33325 8.96514 3.33325 9.33333V10.6667C3.33325 11.0349 3.63173 11.3333 3.99992 11.3333C4.36811 11.3333 4.66659 11.0349 4.66659 10.6667V9.33333C4.66659 8.96514 4.36811 8.66667 3.99992 8.66667ZM8.66659 5.33333C8.66659 4.96514 8.96506 4.66667 9.33325 4.66667C9.70144 4.66667 9.99992 4.96514 9.99992 5.33333V6C9.99992 6.36819 9.70144 6.66667 9.33325 6.66667C8.96506 6.66667 8.66659 6.36819 8.66659 6V5.33333Z"
      />
    </svg>
  );
}

IconChartWaterfall.propTypes = {
  className: PropTypes.string
};

export default IconChartWaterfall;
