import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import IconSorting from 'components/svg/icon-sorting';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { currency } from 'utils/format';
import { useTableSorting } from 'hooks/useTableSorting';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { useGetDoneSummaryByCodeQuery } from 'services/rtk-query/stock';
import { useSelector } from 'react-redux';

function TWDoneSummary({ symbol = 'AALI' }) {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);

  const { data: summaries, isLoading } = useGetDoneSummaryByCodeQuery(symbol, {
    refetchOnMountOrArgChange: true,
    pollingInterval: isBursaOpen ? 5000 : 36000
  });
  const { stockDetail } = useRealtimeStock(symbol);
  const { data, sortingColumns, handleSorting, getSorting } = useTableSorting(
    summaries,
    ['Price'],
    ['desc']
  );

  return (
    <div className="px-3 pb-3">
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        style={{ maxHeight: '350px' }}>
        <table className="text-2xs w-full text-center font-quicksand-semibold dark:text-black-light-1-d">
          <thead className="text-gray-light-0-d dark:text-black-light-1-d">
            <tr className="relative">
              <td
                className={`border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l sticky left-0 card-widget-gradient ${
                  sortingColumns?.[0] === 'Price' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('Price')}>
                <span className="flex items-center gap-1 justify-center cursor-pointer border-r border-gray-light-l dark:border-gray-base-d w-full h-full p-2">
                  {sortingColumns?.[0] === 'Price' && (
                    <IconSorting
                      type={getSorting('Price')}
                      className="h-3 w-3"
                    />
                  )}
                  Price{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'Volume' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('Volume')}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'Volume' && (
                    <IconSorting
                      type={getSorting('Volume')}
                      className="h-3 w-3"
                    />
                  )}
                  T.Lot{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'Frequency' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('Frequency')}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'Frequency' && (
                    <IconSorting
                      type={getSorting('Frequency')}
                      className="h-3 w-3"
                    />
                  )}
                  T.Freq{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'ReleasedVolume' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('ReleasedVolume')}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'ReleasedVolume' && (
                    <IconSorting
                      type={getSorting('ReleasedVolume')}
                      className="h-3 w-3"
                    />
                  )}
                  S.Lot{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'ReleasedFrequency' &&
                  'text-main-base-l'
                }`}
                onClick={() => handleSorting('ReleasedFrequency')}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'ReleasedFrequency' && (
                    <IconSorting
                      type={getSorting('ReleasedFrequency')}
                      className="h-3 w-3"
                    />
                  )}
                  S.Freq{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'BLot' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('BLot')}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'BLot' && (
                    <IconSorting
                      type={getSorting('BLot')}
                      className="h-3 w-3"
                    />
                  )}
                  B.Lot{' '}
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'BLot' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('BFreq')}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'BLot' && (
                    <IconSorting
                      type={getSorting('BFreq')}
                      className="h-3 w-3"
                    />
                  )}
                  B.Freq{' '}
                </span>
              </td>
            </tr>
          </thead>
          <tbody className="text-2xs">
            <RenderIf isTrue={data.length === 0 && !isLoading}>
              {Array(5)
                .fill(1)
                .map((el, key) => (
                  <tr key={key} className="relative">
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-center sticky left-0 card-widget-gradient">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      -
                    </td>
                  </tr>
                ))}
            </RenderIf>

            <RenderIf isTrue={isLoading}>
              {Array(5)
                .fill(1)
                .map((el, key) => (
                  <tr key={key} className="relative">
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-center sticky left-0 card-widget-gradient">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    {/* <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-right">
                    <SkeletonTheme  width="50px" height="1rem" borderRadius="6px" />
                  </td> */}
                  </tr>
                ))}
            </RenderIf>

            <RenderIf isTrue={!isLoading}>
              {data?.map((item, key) => (
                <tr key={key} className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-center sticky left-0 card-widget-gradient">
                    <span
                      className={`p-2 block w-full h-full border-r border-gray-light-l dark:border-gray-base-d ${
                        item?.Price > stockDetail?.lastTradedPrice
                          ? 'text-green-base-l'
                          : item?.Price < stockDetail?.lastTradedPrice
                          ? 'text-red-base-l'
                          : ''
                      }`}>
                      {currency(item?.Price, { removeSymbol: true })}
                    </span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.Volume / 100, { removeSymbol: true })}
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.Frequency, { removeSymbol: true })}
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.ReleasedVolume / 100, {
                      removeSymbol: true
                    })}
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.ReleasedFrequency, { removeSymbol: true })}
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.BLot, { removeSymbol: true })}
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.BFreq, { removeSymbol: true })}
                  </td>
                  {/* <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.ForeignSellVolume, { removeSymbol: true })}
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-right">
                    {currency(item?.ForeignSellFrequency, { removeSymbol: true })}
                  </td> */}
                </tr>
              ))}
            </RenderIf>
          </tbody>
        </table>
      </OverlayScrollbarsComponent>
    </div>
  );
}

TWDoneSummary.propTypes = {
  symbol: PropTypes.string
};

export default TWDoneSummary;
