export const triggerSegmentIdentify = (_opts = { userId: '', properties: {} }) => {
  if (!_opts?.userId) return false;
  
  if (process.env.NODE_ENV === 'production') {
    window?.analytics.identify(_opts?.userId, { ..._opts?.properties });
  }
};

export const triggerSegmentEvent = (_opts = { event: '', properties: {} }) => {
  if (!_opts?.event) return false;
  
  if (process.env.NODE_ENV === 'production') {
    window?.analytics.track(_opts?.event, { ..._opts?.properties });
  }
};

export const triggerSegmentPage = (_opts = { category: '', name: '' }) => {
  if (!_opts?.name) return false;
  
  if (process.env.NODE_ENV === 'production') {
    window?.analytics.page(_opts?.category, _opts?.name);
  }
};
