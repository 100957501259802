import clsx from 'clsx';
import RenderIf from 'components/basic/render-if';
import { currency } from 'utils/format';
import ReturnTextValue from './done-detail-return-value';
import { sumBy } from 'lodash';
import cn from 'utils/classname';
import IconSorting from 'components/svg/icon-sorting';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export default function DoneDetailAll({
  sortBy,
  data = [],
  handleSorting = () => {},
  getSorting = () => {}
}) {
  return (
    <div className='h-full'>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}
        style={{ maxHeight: 'calc(100%)' }}>
        {data?.map((item, kGroup) => (
          <table
            key={kGroup}
            className="w-full text-center font-quicksand-regular text-2xs border-gray-base-l dark:border-gray-base-d mb-0 border-t">
            <thead className="text-gray-light-0-d dark:text-black-light-0-d">
              <tr className="border-b border-gray-base-l dark:border-gray-base-d">
                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'time' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('time')}>
                  <span className="flex items-center gap-1">
                    <RenderIf isTrue={kGroup === 0}>
                      {sortBy?.s === 'time' && (
                        <IconSorting
                          type={getSorting('time')}
                          className="h-3 w-3"
                        />
                      )}
                    </RenderIf>
                    Time
                  </span>
                </th>

                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'stID' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('stID')}>
                  <span className="flex items-center gap-1">
                    <RenderIf isTrue={kGroup === 0}>
                      {sortBy?.s === 'stID' && (
                        <IconSorting
                          type={getSorting('stID')}
                          className="h-3 w-3"
                        />
                      )}
                    </RenderIf>
                    StockID
                  </span>
                </th>

                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'side' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('side')}>
                  <span
                    className={clsx(
                      'flex items-center gap-1 cursor-pointer',
                      kGroup > 0 && 'invisible'
                    )}>
                    {sortBy?.s === 'side' && (
                      <IconSorting
                        type={getSorting('side')}
                        className="h-3 w-3"
                      />
                    )}
                    B/S
                  </span>
                </th>

                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'price' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('price')}>
                  <span
                    className={clsx(
                      'flex items-center gap-1 justify-end cursor-pointer',
                      kGroup > 0 && 'invisible'
                    )}>
                    {sortBy?.s === 'price' && (
                      <IconSorting
                        type={getSorting('price')}
                        className="h-3 w-3"
                      />
                    )}
                    Price
                  </span>
                </th>

                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'qty' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('qty')}>
                  <span
                    className={clsx(
                      'flex items-center gap-1 justify-end cursor-pointer',
                      kGroup > 0 && 'invisible'
                    )}>
                    {sortBy?.s === 'qty' && (
                      <IconSorting
                        type={getSorting('qty')}
                        className="h-3 w-3"
                      />
                    )}
                    LOT
                  </span>
                </th>

                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'tradeValue' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('tradeValue')}>
                  <span
                    className={clsx(
                      'flex items-center gap-1 justify-end cursor-pointer',
                      kGroup > 0 && 'invisible'
                    )}>
                    {sortBy?.s === 'tradeValue' && (
                      <IconSorting
                        type={getSorting('tradeValue')}
                        className="h-3 w-3"
                      />
                    )}
                    Trade&nbsp;Value
                  </span>
                </th>

                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'amount' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('amount')}>
                  <span
                    className={clsx(
                      'flex items-center gap-1 justify-end cursor-pointer',
                      kGroup > 0 && 'invisible'
                    )}>
                    {sortBy?.s === 'amount' && (
                      <IconSorting
                        type={getSorting('amount')}
                        className="h-3 w-3"
                      />
                    )}
                    Amount
                  </span>
                </th>

                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'mktTID' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('mktTID')}>
                  <span
                    className={clsx(
                      'flex items-center gap-1 justify-end cursor-pointer',
                      kGroup > 0 && 'invisible'
                    )}>
                    {sortBy?.s === 'mktTID' && (
                      <IconSorting
                        type={getSorting('mktTID')}
                        className="h-3 w-3"
                      />
                    )}
                    MktTID
                  </span>
                </th>

                <th
                  className={cn(
                    'p-1 py-1.5 cursor-pointer',
                    sortBy?.s === 'mktOID' && 'text-main-base-d'
                  )}
                  onClick={() => handleSorting('mktOID')}>
                  <span
                    className={clsx(
                      'flex items-center gap-1 justify-end cursor-pointer',
                      kGroup > 0 && 'invisible'
                    )}>
                    {sortBy?.s === 'mktOID' && (
                      <IconSorting
                        type={getSorting('mktOID')}
                        className="h-3 w-3"
                      />
                    )}
                    MktOID
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="dark:text-black-light-1-d">
              {item?.listSingle?.map((trade, key) => (
                <tr key={key} className="text-left dark:border-gray-base-d group">
                  <td className="p-1 group-first:pt-2 font-quicksand-regular text-black-base-d dark:text-black-light-1-d">
                    {trade?.time?.split('-')?.[1].replace('.000', '')}
                  </td>
                  <td className="p-1 group-first:pt-2 font-quicksand-semibold text-black-base-d dark:text-black-light-1-d">
                    {trade?.stID}
                  </td>
                  <td className="p-2]">
                    {trade?.side === 'B' ? (
                      <span className="text-red-base-l">BUY</span>
                    ) : (
                      <span className="text-green-base-l">SELL</span>
                    )}
                  </td>
                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold]">
                    {currency(trade?.price, { removeSymbol: true })}
                  </td>
                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold]">
                    {currency(trade?.qty / 100, { removeSymbol: true })} LOT
                  </td>
                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold">
                    {currency(trade?.tradeValue, {
                      removeSymbol: true
                    })}
                  </td>
                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold">
                    {currency(trade?.amount, {
                      removeSymbol: true
                    })}
                  </td>
                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold">
                    {trade?.mktTID}
                  </td>
                  <td className="p-1 group-first:pt-2 text-right font-quicksand-semibold">
                    {trade?.mktOID}
                  </td>
                </tr>
              ))}

              <tr>
                <td
                  className="p-1 py-2 text-right font-quicksand-semibold"
                  colSpan={4}>
                  Nett Of {item?.stID}
                </td>
                <td className="p-1 py-2 text-right font-quicksand-semibold">
                  <ReturnTextValue
                    reverseColor
                    suffix=" LOT"
                    className="flex items-center justify-end"
                    buyValue={
                      sumBy(
                        item?.listSingle?.filter((tr) => tr?.side === 'B'),
                        'qty'
                      ) / 100
                    }
                    sellValue={
                      sumBy(
                        item?.listSingle?.filter((tr) => tr?.side === 'S'),
                        'qty'
                      ) / 100
                    }
                  />
                </td>
                <td className="p-1 py-2 text-right font-quicksand-semibold">
                  <ReturnTextValue
                    prefix=""
                    buyValue={sumBy(
                      item?.listSingle?.filter((tr) => tr?.side === 'B'),
                      'tradeValue'
                    )}
                    sellValue={sumBy(
                      item?.listSingle?.filter((tr) => tr?.side === 'S'),
                      'tradeValue'
                    )}
                  />
                </td>
                <td className="p-1 py-2 text-right font-quicksand-semibold">
                  <ReturnTextValue
                    prefix=""
                    buyValue={sumBy(
                      item?.listSingle?.filter((tr) => tr?.side === 'B'),
                      'amount'
                    )}
                    sellValue={sumBy(
                      item?.listSingle?.filter((tr) => tr?.side === 'S'),
                      'amount'
                    )}
                  />
                </td>
                <td className="p-1 py-2">
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        ))}
      </OverlayScrollbarsComponent>
    </div>
  );
}
