import Card from 'components/basic/card';
import { triggerSegmentEvent } from 'lib/segment';
import TWOrderlistSmartOrder from 'pages/trading/_partials/tw-order-list-smart-order';
import TWOrderlistSmartPortfolio from 'pages/trading/_partials/tw-orderlist-smart-portfolio';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

function PageTradingPlan() {
  const [openSmartOrder, setOpenSmartOrder] = useState(false);
  const [firstRender, setFirsRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      setFirsRender(false);
      triggerSegmentEvent({ event: 'TR Stock Planner Screen Viewed' });
    }
  }, [firstRender])

  return (
    <>
      <Helmet>
        <title>Auto Trading Planner | Pina Trade</title>
      </Helmet>

      <div className="p-4 dark:text-black-light-1-d zoom-2">
        <h1 className='font-quicksand-semibold text-base mb-3'>Trading Planner</h1>

        <Card className="p-4 mb-3">
          <TWOrderlistSmartPortfolio title="" />
        </Card>

        <div className="grid grid-cols-1 gap-3">
          <Card className="p-4">
            <TWOrderlistSmartOrder title="" openSmartOrder={openSmartOrder} onClose={() => setOpenSmartOrder(false)} />
          </Card>
        </div>
      </div>
    </>
  );
}

export default PageTradingPlan;
