import PropTypes from 'prop-types';

function IconCheckPlain({ className }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4142 18.5858C32.1953 19.3668 32.1953 20.6332 31.4142 21.4142L23.4142 29.4142C22.6332 30.1953 21.3668 30.1953 20.5858 29.4142L16.5858 25.4142C15.8047 24.6332 15.8047 23.3668 16.5858 22.5858C17.3668 21.8047 18.6332 21.8047 19.4142 22.5858L22 25.1716L28.5858 18.5858C29.3668 17.8047 30.6332 17.8047 31.4142 18.5858Z"
      />
    </svg>
  );
}


IconCheckPlain.propTypes = {
  className: PropTypes.string
};

export default IconCheckPlain;
