// import { marginPlusShare } from 'utils/formula/margin';
import { ORDER_COST } from 'constants';
import { getStampCostBuy } from 'utils/calculation';
import { object, number, string } from 'yup';

export const StockBuySchema = object().shape({
  amount: number()
    .test('amount', 'Jumlah amount tidak cukup', function (value, { parent }) {
      if (!parent.lotType) {
        return Math.floor(value / (parent.price * 100)) > 0;
      }

      return true;
    })
    .required('Jumlah amount harus diisi'),
  lot: number()
    .min(1, 'Jumlah Lot tidak boleh kosong.')
    .required('Jumlah Lot tidak boleh kosong.'),
  price: number()
    // .test('price', 'Harga terlalu rendah', function (value, { parent }) {
    //   return !(value < parent?.initialPrice - marginPlusShare(parent?.initialPrice) * 10);
    // })
    // .test('price', 'Harga terlalu tinggi', function (value, { parent }) {
    //   return !(value > parent?.initialPrice + marginPlusShare(parent?.initialPrice) * 10);
    // })
    .min(1, 'Harga saham harus diisi.')
    .required('Harga saham harus diisi.'),
  buyingPower: number().test(
    'buyingPower',
    'Maaf, saldo anda tidak mencukupi.',
    function (value, { parent }) {
      return (
        value >=
        parent?.lot * 100 * parent?.price +
          Math.ceil(ORDER_COST?.buy * parent?.price * parent?.lot) +
          getStampCostBuy(value)
      );
    }
  ),
  expiryDate: string()
    .nullable(true)
    .test(
      'expiryDate',
      'Expiry date harus diisi',
      function (value, { parent }) {
        if (parent?.mockOnly && parent?.expiry === 'GTD') {
          return value;
        }

        return true;
      }
    )
});
