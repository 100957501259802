export const marginPlusShare = (pricePerShare = 0) => {
  let margin = 1;

  if (pricePerShare < 200) margin = 1;
  else if (pricePerShare >= 200 && pricePerShare < 500) margin = 2;
  else if (pricePerShare >= 500 && pricePerShare < 2000) margin = 5;
  else if (pricePerShare >= 2000 && pricePerShare < 5000) margin = 10;
  else if (pricePerShare >= 5000) margin = 25;

  return margin;
};

export const marginMinusShare = (pricePerShare = 0) => {
  let margin = 1;

  if (pricePerShare <= 200) margin = 1;
  else if (pricePerShare > 200 && pricePerShare <= 500) margin = 2;
  else if (pricePerShare > 500 && pricePerShare <= 2000) margin = 5;
  else if (pricePerShare > 2000 && pricePerShare <= 5000) margin = 10;
  else if (pricePerShare > 5000) margin = 25;

  return margin;
};
