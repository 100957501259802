import { triggerSegmentEvent } from 'lib/segment';
import { Link } from 'react-router-dom';

function BreadcrumbNew({ acivePath, features = [] }) {
  return (
    <div className="px-3 mb-3 text-sm flex items-center overflow-x-auto max-w-[95vw] lg:max-w-[90vw]">
      <ul className="flex justify-start -mx-2 border-b dark:border-gray-base-d">
        {features?.map((item, key) => (
          <li
            key={key}
            className={`border-r last:border-r-0 dark:border-gray-base-d min-w-[150px] ${
              item?.url?.includes(acivePath) &&
              'font-quicksand-semibold border-b-2 border-main-base-l dark:border-main-base-l'
            }`}>
            <Link
              className="py-1 px-3 block dark:text-black-light-1-d"
              to={item?.url?.[0]}
              onClick={() =>
                triggerSegmentEvent({ event: item?.analyticEventName })
              }>
              <span className="line-clamp-1">{item?.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BreadcrumbNew;
