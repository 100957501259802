import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import { useFormik } from 'formik';
import { ForgotPasswordSchema } from './forgot-password-form.validation';
import IconEmail from 'components/svg/icon-email';
import { useCreateForgotPasswordMutation } from 'services/rtk-query/user';
import { useState } from 'react';
import { triggerSegmentEvent } from 'lib/segment';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import InputText from 'components/input/input-text';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import Pineaple from 'components/svg/pineaple';

function ForgotPasswordForm({ onSuccess = () => {} }) {
  const { getErrorMessage } = useGetErrorMessage();
  const [createForgotPassword] = useCreateForgotPasswordMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validateOnMount: false,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true)
      try {
        const { payloads } = await createForgotPassword(values).unwrap();
        onSuccess(payloads)

        triggerSegmentEvent({
          event: 'TR Request Forgot Password',
          properties: {
            email: values?.email
          }
        });
      } catch (error) {
        getErrorMessage(error);
      } finally {
        setIsSubmitting(false)
      }
    }
  });

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:pb-28 text-white flex lg:py-16 relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto text-left">
          <div className="flex justify-between mb-0 lg:mb-10 items-start">
            <div className="flex-none">
              <a
                className="text-sm lg:text-xl hover:opacity-75 flex items-center mb-4"
                href="/login">
                <IconChevronSingle className="h-5 w-5 mr-1" />
                Kembali Halaman Login
              </a>
            </div>
          </div>

          <Pineaple className="lg:ml-0 w-5 lg:w-12 h-auto" />
          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] my-3 text-white">
            Lupa Password
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Kami akan mengirimkan tautan untuk menganti password ke email kamu.
          </p>
        </div>
      </div>

      <div className="p-0 lg:p-16">
        <Card className="w-full px-6 lg:px-8 py-8 lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto">
          <h2 className='text-xl lg:text-2xl text-center lg:text-left font-quicksand-semibold mb-6 text-main-base-l'>Masukkan Alamat Email</h2>
          <form className="mt-5" onSubmit={formik.handleSubmit}>
            <div className="mb-5">
              <InputText
                type="email"
                name="email"
                placeholder="Email"
                label={'Email'}
                icon={<IconEmail className="text-gray-light-0-d" />}
                className="mb-2"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
              />
            </div>

            <div className="mt-10 lg:mt-64 fixed lg:relative w-full bottom-0 left-0 p-6 lg:p-0 bg-white border-t lg:border-none">
              <button
                type="submit"
                disabled={!formik.isValid}
                className={`transition-all ease-in-out duration-150 hover:bg-opacity-75 h-11 flex w-full items-center justify-center font-quicksand-semibold font-bold text-sm rounded-[10px] overflow-hidden focus:outline-none ${
                  formik.isValid
                    ? 'bg-main-base-l text-white'
                    : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                }`}>
                {isSubmitting && <Spinner />}
                Kirim Email
              </button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

ForgotPasswordForm.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func.isRequired
};

ForgotPasswordForm.defaultProps = {
  className: '',
  onSuccess: () => {}
};

export default ForgotPasswordForm;
