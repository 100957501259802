import IMG_ADVANCED_CHART from 'assets/images/widgets/ss-advanced-chart.png';
import IMG_IHSG_CHART from 'assets/images/widgets/ss-ihsg-chart.png';
import IMG_INTRA_DAY_CHART from 'assets/images/widgets/ss-intraday-chart.png';
import IMG_WATCHLIST from 'assets/images/widgets/ss-watchlist.png';
import IMG_QUICK_ORDER from 'assets/images/widgets/ss-quick-order.png';
import IMG_NEWS from 'assets/images/widgets/ss-news.png';
import IMG_CALENDAR from 'assets/images/widgets/ss-calendar.png';
import IMG_INFO_CASH from 'assets/images/widgets/ss-info-cash.png';
import IMG_FUNDAMENTAL from 'assets/images/widgets/ss-fundamental.png';
import IMG_CORP_ACTION from 'assets/images/widgets/ss-corp-action.png';
import IMG_COMPANY_PROFILE from 'assets/images/widgets/ss-company-profile.png';
import IMG_ORDERLIST_PORTFOLIO from 'assets/images/widgets/ss-orderlist-portfolio.png';
import IMG_ORDERLIST_HISTORY from 'assets/images/widgets/ss-orderlist-history.png';
import IMG_ORDERBOOK from 'assets/images/widgets/ss-orderbook.png';
import IMG_FAST_BUY from 'assets/images/widgets/ss-fast-buy.png';
import IMG_RUNNING_TRADE from 'assets/images/widgets/ss-running-trade.png';
import IMG_ORDERBOOK_CHART from 'assets/images/widgets/ss-orderbook-chart.png';
import IMG_DONE_DETAILS from 'assets/images/widgets/ss-done-details.png';
import IMG_DONE_SUMMARY from 'assets/images/widgets/ss-done-summary.png';
import IMG_TRADING_IDEAS from 'assets/images/widgets/ss-trading-ideas.png';
import IMG_BANDARMOLOGY from 'assets/images/widgets/ss-bandarmology.png';
import IMG_TOP_TRAND from 'assets/images/widgets/ss-top-trend.png';

export const LIST_WIDGET_2 = [
  {
    groupName: 'Charts',
    title: 'Advanced Chart',
    name: 'advanced-chart',
    imgSrc: IMG_ADVANCED_CHART,
    state: {
      width: '500px',
      height: '380px',
      x: 0,
      y: 0
    },
    description: `Dapatkan pandangan mendalam tentang pergerakan harga, pola, dan indikator teknis yang dapat membantu Kamu mengidentifikasi peluang perdagangan yang menarik.`
  },
  {
    groupName: 'Charts',
    title: 'IHSG Chart',
    name: 'IHSG',
    imgSrc: IMG_IHSG_CHART,
    state: {
      width: '240px',
      height: '310px',
      x: 0,
      y: 0
    },
    description: `Grafik yang menggambarkan pergerakan Indeks Harga Saham Gabungan (IHSG) dalam bentuk visual. IHSG merupakan indeks saham yang mewakili pergerakan pasar modal Indonesia secara keseluruhan`
  },
  {
    groupName: 'Charts',
    title: 'Chart Intraday',
    name: 'chart',
    imgSrc: IMG_INTRA_DAY_CHART,
    state: {
      width: '210px',
      height: '330px',
      x: 0,
      y: 0
    },
    description: `Grafik yang menampilkan gambaran visual tentang bagaimana harga saham berfluktuasi sepanjang hari trading.`
  },
  {
    groupName: 'Charts',
    title: 'Chart & Orderbook',
    name: 'chart-orderbook',
    imgSrc: IMG_ORDERBOOK_CHART,
    state: {
      width: '400px',
      height: '310px',
      x: 0,
      y: 0
    },
    description: `Analisis kombinasi ini memberikan wawasan yang lebih mendalam tentang kondisi pasar dan membantu Kamu dalam mengambil tindakan yang sesuai`
  },
  {
    groupName: 'Info',
    title: 'Company Profile',
    name: 'company-profile',
    imgSrc: IMG_COMPANY_PROFILE,
    state: {
      width: '240px',
      height: '464px',
      x: 0,
      y: 0
    },
    description: `Pahami lebih baik tentang identitas, fokus, dan kinerja perusahaan tersebut. Sebagai dasar untuk melakukan analisis dan pengambilan keputusan investasi yang lebih terinformasi`
  },
  {
    groupName: 'Info',
    title: 'Corp Action',
    name: 'corp-action',
    imgSrc: IMG_CORP_ACTION,
    state: {
      width: '240px',
      height: '464px',
      x: 0,
      y: 0
    },
    description: `Mengetahui dan memahami tindakan Perusahaan yang terjadi membantu Kamu dalam mengambil keputusan investasi yang lebih tepat`
  },
  {
    groupName: 'Info',
    title: 'Done Summary',
    name: 'done-summary',
    imgSrc: IMG_DONE_SUMMARY,
    state: {
      width: '240px',
      height: '464px',
      x: 0,
      y: 0
    },
    description: `Ringkasan informasi mengenai jumlah lot dan harga yang telah ditransaksikan dari saham hari ini dalam tampilan yang ringkas dan informatif.`
  },
  {
    groupName: 'Info',
    title: 'Done Details',
    name: 'done-detail',
    imgSrc: IMG_DONE_DETAILS,
    state: {
      width: '240px',
      height: '464px',
      x: 0,
      y: 0
    },
    description: `Catatan singkat yang memperlihatkan informasi terkait aktivitas yang telah diselesaikan`
  },
  {
    groupName: 'Info',
    title: 'Fundamental',
    name: 'fundamental',
    imgSrc: IMG_FUNDAMENTAL,
    state: {
      width: '240px',
      height: '464px',
      x: 0,
      y: 0
    },
    description: `Pendekatan yang digunakan untuk mempelajari faktor-faktor fundamental sebuah perusahaan yang dapat mempengaruhi kinerja sahamnya di pasar`
  },
  {
    groupName: 'Info',
    title: 'Kalender Saham',
    name: 'calendar',
    imgSrc: IMG_CALENDAR,
    state: {
      width: '240px',
      height: '470px',
      x: 650.0000228881836,
      y: 20
    },
    description: `Informasi tentang jadwal penting yang berhubungan dengan saham. Jadwal ini mencakup kejadian, seperti laporan keuangan perusahaan, pembagian dividen, atau pengumuman penting lainnya`
  },
  {
    groupName: 'Info',
    title: 'News',
    name: 'news',
    imgSrc: IMG_NEWS,
    state: {
      width: '240px',
      height: '464px',
      x: 0,
      y: 0
    },
    description: `Informasi terkini tentang peristiwa dan pengumuman yang berhubungan dengan perusahaan, industri, atau faktor-faktor ekonomi yang dapat mempengaruhi harga dan aktivitas perdagangan saham`
  },
  {
    groupName: 'Info',
    title: 'Top Trending',
    name: 'top-tranding',
    imgSrc: IMG_TOP_TRAND,
    state: {
      width: '240px',
      height: '464px',
      x: 0,
      y: 0
    },
    description: `Melacak tren pasar yang sedang trending dapat membantu mengidentifikasi peluang perdagangan potensial atau membuat keputusan investasi yang lebih baik. `
  },
  {
    groupName: 'Info',
    title: 'Trading Ideas',
    name: 'trading-ideas',
    imgSrc: IMG_TRADING_IDEAS,
    state: {
      width: '440px',
      height: '400px',
      x: 0,
      y: 4
    },
    description: `Ide-ide dan analisis pasar yang dapat membantu Kamu dalam mengidentifikasi peluang perdagangan yang menarik`
  },
  {
    groupName: 'Info',
    title: 'Broker Summary',
    name: 'bandarmology',
    imgSrc: IMG_BANDARMOLOGY,
    isPopular: true,
    state: {
      width: '400px',
      height: '460px',
      x: 0,
      y: 0
    },
    description: `Dapatkan informasi tentang aktivitas perdagangan dari broker, termasuk transaksi yang telah dilakukan, keuntungan atau kerugian yang telah dihasilkan.`
  },
  {
    groupName: 'Orders',
    title: 'Fast Order',
    name: 'fast-order',
    imgSrc: IMG_FAST_BUY,
    isPopular: true,
    state: {
      width: '400px',
      height: '460px',
      x: 0,
      y: 0
    },
    description: `Fitur yang memungkinkan untuk melakukan transaksi perdagangan secara cepat dan efisien. Dengan Fast Order, Kamu dapat dengan mudah membeli atau menjual saham dalam waktu singkat`
  },
  {
    groupName: 'Orders',
    title: 'Quick Order',
    name: 'order-form',
    imgSrc: IMG_QUICK_ORDER,
    state: {
      width: '210px',
      height: '430px',
      x: 0,
      y: 0
    },
    description: `Kamu dapat dengan cepat merespon pergerakan pasar atau kesempatan perdagangan yang muncul. Fitur ini memudahkan untuk masuk dan keluar dari posisi perdagangan dengan cepat`
  },
  {
    groupName: 'Orders',
    title: 'Quick Order Advanced',
    name: 'order-form-mock-only',
    imgSrc: IMG_QUICK_ORDER,
    isPopular: true,
    state: {
      width: '400px',
      height: '500px',
      x: 0,
      y: 0
    },
    description: `Kamu dapat dengan cepat merespon pergerakan pasar atau kesempatan perdagangan yang muncul. Fitur ini memudahkan untuk masuk dan keluar dari posisi perdagangan dengan cepat`
  },
  {
    groupName: 'Orders',
    title: 'Orderbook',
    name: 'orderbook',
    imgSrc: IMG_ORDERBOOK,
    isPopular: true,
    state: {
      width: '210px',
      height: '380px',
      x: 0,
      y: 0
    },
    description: `Pantau aktivitas perdagangan saham secara real-time dan peroleh wawasan tentang tingkat minat dan kebutuhan pasar terhadap saham tertentu`
  },
  {
    groupName: 'Orders',
    title: 'Order & Portfolio',
    name: 'bundle-orderlist',
    imgSrc: IMG_ORDERLIST_PORTFOLIO,
    isPopular: true,
    state: {
      width: '1000px',
      height: '470px',
      x: 0,
      y: 0
    },
    description: `Lihat daftar pesanan yang Kamu tempatkan dalam perdagangan saham, lalu lacak, kelola, dan pantau status pesanan yang Kamu buat.`
  },
  {
    groupName: 'Orders',
    title: 'Order Match History',
    name: 'order-list-history',
    imgSrc: IMG_ORDERLIST_HISTORY,
    state: {
      width: '1120px',
      height: '432px',
      x: 0,
      y: 0
    },
    description: `Lihat informasi seperti waktu pencocokan, harga saham, volume pesanan, dan detail pesanan lain yang terlibat.`
  },
  {
    groupName: 'Orders',
    title: 'Running Trade',
    name: 'running-trade',
    imgSrc: IMG_RUNNING_TRADE,
    isPopular: true,
    state: {
      width: '220px',
      height: '310px',
      x: 0,
      y: 0
    },
    description: `Fitur yang memungkinkan untuk melacak dan mengelola perdagangan aktif Kamu. Ini mencakup perdagangan yang sedang berjalan atau masih terbuka`
  },
  {
    groupName: 'Others',
    title: 'Info Cash',
    name: 'info-cash',
    state: {
      width: '210px',
      height: '360px',
      x: 0,
      y: 0
    },
    imgSrc: IMG_INFO_CASH,
    description: `Lihat informasi tentang saldo kas dan transaksi keuangan Kamu, dapatkan gambaran lengkap tentang keuangan Kamu, termasuk jumlah uang yang tersedia, transaksi masuk dan keluar, serta riwayat transaksi lainnya.`
  },
  {
    groupName: 'Others',
    title: 'Watchlist',
    name: 'watchlist',
    imgSrc: IMG_WATCHLIST,
    state: {
      width: '240px',
      height: '310px',
      x: 0,
      y: 0
    },
    description: `Kamu dapat dengan mudah melacak dan memantau saham-saham yang menarik minatmu.  Ikuti pergerakan harga, pantau performa saham-saham pilihan, dan memudahkan dalam pengambilan keputusan investasi Kamu`
  }
];

const LIST_WIDGET = [
  {
    groupName: 'Charts',
    title: 'Advanced Chart',
    name: 'advanced-chart',
    w: 4,
    h: 30,
    imgSrc: IMG_ADVANCED_CHART,
    description: `Dapatkan pandangan mendalam tentang pergerakan harga, pola, dan indikator teknis yang dapat membantu Kamu mengidentifikasi peluang perdagangan yang menarik.`
  },
  {
    groupName: 'Charts',
    title: 'IHSG Chart',
    name: 'IHSG',
    w: 4,
    h: 40,
    imgSrc: IMG_IHSG_CHART,
    description: `Grafik yang menggambarkan pergerakan Indeks Harga Saham Gabungan (IHSG) dalam bentuk visual. IHSG merupakan indeks saham yang mewakili pergerakan pasar modal Indonesia secara keseluruhan`
  },
  {
    groupName: 'Charts',
    title: 'Chart Intraday',
    name: 'chart',
    w: 4,
    h: 40,
    imgSrc: IMG_INTRA_DAY_CHART,
    description: `Grafik yang menampilkan gambaran visual tentang bagaimana harga saham berfluktuasi sepanjang hari trading.`
  },
  {
    groupName: 'Charts',
    title: 'Chart & Orderbook',
    name: 'chart-orderbook',
    w: 7,
    h: 36,
    imgSrc: IMG_ORDERBOOK_CHART,
    description: `Analisis kombinasi ini memberikan wawasan yang lebih mendalam tentang kondisi pasar dan membantu Kamu dalam mengambil tindakan yang sesuai`
  },
  // {
  //   groupName: 'Charts',
  //   title: 'Heatmap Chart',
  //   name: 'chart-heatmap',
  //   w: 4,
  //   h: 40,
  //   imgSrc: IMG_HEATMAP_CHART,
  //   description: `Visualisasi grafis yang digunakan untuk mewakili data saham secara intuitif. Chart ini memberikan tampilan matriks warna yang menampilkan perbandingan kinerja saham dalam satu tampilan komprehensif`
  // },
  {
    groupName: 'Info',
    title: 'Company Profile',
    name: 'company-profile',
    w: 4,
    h: 40,
    imgSrc: IMG_COMPANY_PROFILE,
    description: `Pahami lebih baik tentang identitas, fokus, dan kinerja perusahaan tersebut. Sebagai dasar untuk melakukan analisis dan pengambilan keputusan investasi yang lebih terinformasi`
  },
  {
    groupName: 'Info',
    title: 'Corp Action',
    name: 'corp-action',
    w: 4,
    h: 40,
    imgSrc: IMG_CORP_ACTION,
    description: `Mengetahui dan memahami tindakan Perusahaan yang terjadi membantu Kamu dalam mengambil keputusan investasi yang lebih tepat`
  },
  {
    groupName: 'Info',
    title: 'Done Summary',
    name: 'done-summary',
    w: 4,
    h: 40,
    imgSrc: IMG_DONE_SUMMARY,
    description: `Ringkasan informasi mengenai jumlah lot dan harga yang telah ditransaksikan dari saham hari ini dalam tampilan yang ringkas dan informatif.`
  },
  {
    groupName: 'Info',
    title: 'Done Details',
    name: 'done-detail',
    w: 4,
    h: 40,
    imgSrc: IMG_DONE_DETAILS,
    description: `Catatan singkat yang memperlihatkan informasi terkait aktivitas yang telah diselesaikan`
  },
  {
    groupName: 'Info',
    title: 'Fundamental',
    name: 'fundamental',
    w: 4,
    h: 40,
    imgSrc: IMG_FUNDAMENTAL,
    description: `Pendekatan yang digunakan untuk mempelajari faktor-faktor fundamental sebuah perusahaan yang dapat mempengaruhi kinerja sahamnya di pasar`
  },
  {
    groupName: 'Info',
    title: 'Kalender Saham',
    name: 'calendar',
    w: 4,
    h: 40,
    imgSrc: IMG_CALENDAR,
    description: `Informasi tentang jadwal penting yang berhubungan dengan saham. Jadwal ini mencakup kejadian, seperti laporan keuangan perusahaan, pembagian dividen, atau pengumuman penting lainnya`
  },
  {
    groupName: 'Info',
    title: 'News',
    name: 'news',
    w: 4,
    h: 40,
    imgSrc: IMG_NEWS,
    description: `Informasi terkini tentang peristiwa dan pengumuman yang berhubungan dengan perusahaan, industri, atau faktor-faktor ekonomi yang dapat mempengaruhi harga dan aktivitas perdagangan saham`
  },
  {
    groupName: 'Info',
    title: 'Top Trending',
    name: 'top-tranding',
    w: 4,
    h: 40,
    imgSrc: IMG_TOP_TRAND,
    description: `Melacak tren pasar yang sedang trending dapat membantu mengidentifikasi peluang perdagangan potensial atau membuat keputusan investasi yang lebih baik. `
  },
  {
    groupName: 'Info',
    title: 'Trading Ideas',
    name: 'trading-ideas',
    w: 4,
    h: 40,
    imgSrc: IMG_TRADING_IDEAS,
    description: `Ide-ide dan analisis pasar yang dapat membantu Kamu dalam mengidentifikasi peluang perdagangan yang menarik`
  },
  {
    groupName: 'Info',
    title: 'Broker Summary',
    name: 'bandarmology',
    w: 4,
    h: 45,
    imgSrc: IMG_BANDARMOLOGY,
    isPopular: true,
    description: `Dapatkan informasi tentang aktivitas perdagangan dari broker, termasuk transaksi yang telah dilakukan, keuntungan atau kerugian yang telah dihasilkan.`
  },
  // {
  //   groupName: 'Orders',
  //   title: 'Multi Order',
  //   name: 'multi-order',
  //   w: 4,
  //   h: 53,
  //   imgSrc: IMG_FAST_BUY,
  //   description: `Fitur yang memungkinkan untuk melakukan transaksi perdagangan secara cepat dan efisien. Dengan Fast Order, Kamu dapat dengan mudah membeli atau menjual saham dalam waktu singkat`
  // },
  {
    groupName: 'Orders',
    title: 'Fast Order',
    name: 'fast-order',
    w: 6,
    h: 53,
    imgSrc: IMG_FAST_BUY,
    isPopular: true,
    description: `Fitur yang memungkinkan untuk melakukan transaksi perdagangan secara cepat dan efisien. Dengan Fast Order, Kamu dapat dengan mudah membeli atau menjual saham dalam waktu singkat`
  },
  {
    groupName: 'Orders',
    title: 'Quick Order',
    name: 'order-form',
    w: 4,
    h: 46,
    imgSrc: IMG_QUICK_ORDER,
    description: `Kamu dapat dengan cepat merespon pergerakan pasar atau kesempatan perdagangan yang muncul. Fitur ini memudahkan untuk masuk dan keluar dari posisi perdagangan dengan cepat`
  },
  {
    groupName: 'Orders',
    title: 'Quick Order Advanced',
    name: 'order-form-mock-only',
    w: 8,
    h: 57,
    imgSrc: IMG_QUICK_ORDER,
    isPopular: true,
    description: `Kamu dapat dengan cepat merespon pergerakan pasar atau kesempatan perdagangan yang muncul. Fitur ini memudahkan untuk masuk dan keluar dari posisi perdagangan dengan cepat`
  },
  {
    groupName: 'Orders',
    title: 'Orderbook',
    name: 'orderbook',
    w: 4,
    h: 42,
    imgSrc: IMG_ORDERBOOK,
    isPopular: true,
    description: `Pantau aktivitas perdagangan saham secara real-time dan peroleh wawasan tentang tingkat minat dan kebutuhan pasar terhadap saham tertentu`
  },
  {
    groupName: 'Orders',
    title: 'Order & Portfolio',
    name: 'bundle-orderlist',
    w: 8,
    h: 40,
    miH: 40,
    imgSrc: IMG_ORDERLIST_PORTFOLIO,
    isPopular: true,
    description: `Lihat daftar pesanan yang Kamu tempatkan dalam perdagangan saham, lalu lacak, kelola, dan pantau status pesanan yang Kamu buat.`
  },
  {
    groupName: 'Orders',
    title: 'Order Match History',
    name: 'order-list-history',
    w: 4,
    h: 40,
    imgSrc: IMG_ORDERLIST_HISTORY,
    description: `Lihat informasi seperti waktu pencocokan, harga saham, volume pesanan, dan detail pesanan lain yang terlibat.`
  },
  {
    groupName: 'Orders',
    title: 'Running Trade',
    name: 'running-trade',
    w: 4,
    h: 40,
    imgSrc: IMG_RUNNING_TRADE,
    isPopular: true,
    description: `Fitur yang memungkinkan untuk melacak dan mengelola perdagangan aktif Kamu. Ini mencakup perdagangan yang sedang berjalan atau masih terbuka`
  },
  // {
  //   groupName: 'Orders',
  //   title: 'Trade List',
  //   name: 'trade-list',
  //   w: 4,
  //   h: 40,
  //   imgSrc: IMG_TRADELIST,
  //   description: `Fitur ini berguna untuk melacak aktivitas perdagangan Kamu, melihat histori transaksi, dan menghitung performa investasi`
  // },
  {
    groupName: 'Others',
    title: 'Info Cash',
    name: 'info-cash',
    w: 4,
    h: 30,
    imgSrc: IMG_INFO_CASH,
    description: `Lihat informasi tentang saldo kas dan transaksi keuangan Kamu, dapatkan gambaran lengkap tentang keuangan Kamu, termasuk jumlah uang yang tersedia, transaksi masuk dan keluar, serta riwayat transaksi lainnya.`
  },
  // {
  //   groupName: 'Others',
  //   title: 'Portfolio',
  //   name: 'portfolio',
  //   w: 4,
  //   h: 40,
  //   imgSrc: IMG_PORTFOLIO,
  //   description: `Lihat bagaimana investasi Kamu berkembang seiring waktu. Kamu dapat mengevaluasi kinerja investasi, mengidentifikasi saham atau aset yang berperforma baik atau buruk`
  // },
  {
    groupName: 'Others',
    title: 'Watchlist',
    name: 'watchlist',
    w: 4,
    h: 40,
    imgSrc: IMG_WATCHLIST,
    description: `Kamu dapat dengan mudah melacak dan memantau saham-saham yang menarik minatmu.  Ikuti pergerakan harga, pantau performa saham-saham pilihan, dan memudahkan dalam pengambilan keputusan investasi Kamu`
  }
];

export default LIST_WIDGET;
