import PropTypes from 'prop-types';

function IconFilterActive({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6.00259 5C5.15101 5 4.68913 5.99649 5.23953 6.64632L9.05236 11.1479C9.66427 11.8703 10.0001 12.7864 10.0001 13.7332V17.9978C10.0001 18.7271 10.7555 19.2111 11.418 18.9063L13.418 17.9863C13.7728 17.8231 14.0001 17.4683 14.0001 17.0778L14 12.5C14 11.9477 14.4477 11.5 15 11.5C15.5523 11.5 16 11.9477 16 12.5L16.0001 17.0778C16.0001 18.2493 15.3181 19.3137 14.2538 19.8033L12.2538 20.7233C10.2663 21.6376 8.00009 20.1855 8.00009 17.9978V13.7332C8.00009 13.2598 7.83218 12.8018 7.52623 12.4405L3.71339 7.93896C2.06219 5.98949 3.44782 3 6.00259 3H11C11.5523 3 12 3.44772 12 4C12 4.55228 11.5523 5 11 5H6.00259Z" />
      <circle cx="16.5" cy="6.5" r="3.5" fill="#F16528" />
    </svg>
  );
}

IconFilterActive.propTypes = {
  className: PropTypes.string
};

export default IconFilterActive;
