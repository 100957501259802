
export const getOrderStatus = (state) => {
  // S: Saved, Order sukses terekam di sistem
  // E: Need Approval, Order memperlukan approval dari RM
  // B: Basket, Order menunggu waktu/trigger untuk dikirim ke bursa
  // D: Delivered, Order sedang dikirim ke bursa
  // R: Reject, Order tereject dari sistem atau bursa
  // O: Open, Order open
  // P: Partial, Order partial
  // M: Matched, Order matched
  // A: Amended, Order amended
  // W: Withdrawn, Order dibatalkan

  switch (state) {
    case 'S':
    case 'E':
    case 'B':
    case 'D':
      return 'basket';
    case 'O':
      return 'open';
    case 'P':
      return 'partial';
    case 'M':
      return 'match';
    case 'a':
      return 'waiting for amend';
    case 'A':
      return 'amend';
    case 'W':
      return 'withdraw';
    case 'R':
      return 'reject';
    default:
      return 'open';
  }
}

export const getWithdrawStatus = (state) => {
  switch (state) {
    case 'B':
      return 'Basket';
    case 'E':
      return 'Need approval';
    case 'D':
      return 'Request Received';
    case 'O':
      return 'Check By Admin';
    case 'M':
      return 'Request Approved';
    case 'W':
      return 'withdraw';
    case 'R':
      return 'Request Rejected';
    default:
      return 'Check By Admin';
  }
}

export const getColorStatusOrder = (status) => {
  let color = 'text-turquoise-2 border-turquoise-2';

  switch (status) {
    case '99':
      color = 'text-gray-light-0-d border-gray-light-0-d';
      break;
    case 'o':
    case 'O':
    case 'a':
      color = 'text-turquoise-2 border-turquoise-2';
      break;
    case 'M':
    case 'A':
    case 'P':
      color = 'text-green-base-l border-green-base-l';
      break;
    case 'R':
    case 'W':
      color = 'text-red-base-l border-red-base-l';
      break;
    default:
      break;
  }

  return color;
};