import PropTypes from 'prop-types';
import IconLegendChart from 'components/svg/icon-legend-chart';
import { useRef } from 'react';
import Chart from 'react-apexcharts';
import { automatedNumberFormat, formatIndonesianKMBT } from 'utils/format';
import ReactDOMServer from "react-dom/server";

import './styles/profit-loss-chart.css';
import { dayjsLocal } from 'utils/formatter/date';
import useDarkMode from 'hooks/useDarkMode';

function ProfitLossChart({
  profitOrLoss = {},
  categories = [],
  series = [],
  annotations = {},
  colors = [],
  maxY = 999999999,
}) {
  const { theme } = useDarkMode();
  const refChart = useRef();
  const refTooltip = useRef();
  const refLegend = useRef();

  const chartData = {
    series: series,
    options: {
      annotations: annotations,
      chart: {
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: false
        },
        type: 'area',
        // height: 170,
        zoom: {
          enabled: false
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 350,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        events: {
          mouseLeave: () => {
            resetTooltip();
          },
          click: () => {
            resetTooltip();
          },
          mounted: () => {},
          animationEnd: () => {}
        }
      },
      colors: colors,
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        lineCap: 'butt',
        dashArray: [0, 0, 0],
        width: 2,
        curve: 'straight',
        // colors: ['transparent', 'transparent', 'transparent', 'transparent', 'transparent'],
      },
      markers: {
        size: 0,
        // colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
        // strokeColors: colors,
        strokeWidth: 4,
        hover: {
          size: 0
        }
      },
      yaxis: {
        show: true,
        floating: false,
        tickAmount: 3,
        opposite: true,
        max: maxY,
        min: 0,
        labels: {
          trim: true,
          show: categories.length > 0,
          align: 'left',
          offsetX: -4,
          style: {
            colors: ['#9E9E9E'],
            fontSize: '10px',
            fontFamily: 'QuickSandSemiBold, sans-serif'
          },
          formatter: (value) => {
            return formatIndonesianKMBT(Math.abs(value));
          }
        }
      },
      xaxis: {
        categories: categories,
        floating: true,
        show: true,
        showAlways: true,
        tickPlacement: 'on',
        tickAmount: 6,
        labels: {
          show: true,
          rotate: 0,
          offsetY: -2,
          hideOverlappingLabels: false,
          style: {
            colors: ['#000'],
            fontSize: '10px',
            fontFamily: 'QuickSandSemiBold, sans-serif'
          },
          formatter: function (value) {
            if (value) {
              let formatDate = 'DD MMM';
              const diffYear = dayjsLocal(categories[0], 'MM/DD/YYYY').diff(dayjsLocal(categories[categories.length - 1], 'MM/DD/YYYY'),'year');
              if (Math.abs(diffYear) > 0) formatDate = 'MMM YYYY';
              return dayjsLocal(value, 'MM/DD/YYYY').format(formatDate);
            }
          }
        },
        tooltip: {
          enabled: false
        },
        axisTicks: {
          show: false
        }
      },
      grid: {
        show: true,
        borderColor: '#E6EEEF',
        strokeDashArray: 0,
        position: 'back',
        yaxis: {
          lines: {
              show: true
          }
        }, 
        xaxis: {
          lines: {
              show: true
          }
        }, 
        padding: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        // eslint-disable-next-line no-unused-vars
        custom: ({ series, seriesIndex, dataPointIndex}) => {
          const head = dayjsLocal(categories[dataPointIndex], 'MM/DD/YYYY').format('DD MMM YYYY');

          const chartWidth = refChart.current.clientWidth;

          let position = (dataPointIndex / (categories.length - 1)) * chartWidth - 60;
          if (position <= 0) position = 0;
          if (position + 120 >= chartWidth) position = chartWidth - 120;

          const ct = refTooltip.current;
          const cth = refTooltip.current.querySelector('#tooltip-title');

          const ctd1 = refLegend.current.querySelector('#legend-0');
          const ctd2 = refLegend.current.querySelector('#legend-1');
          const ctd3 = refLegend.current.querySelector('#legend-2');
          const ctd4 = refLegend.current.querySelector('#legend-3');

          let profitValue = series[1][dataPointIndex] - series[0][dataPointIndex];

          ct.style.left = position + 'px';
          ct.style.opacity = 1;
          cth.innerHTML = head;

          ctd1.innerHTML = profitValue >= 0 ? automatedNumberFormat(profitValue) : '-' + automatedNumberFormat(Math.abs(profitValue));
          ctd2.innerHTML = automatedNumberFormat(series[2][dataPointIndex]);
          ctd3.innerHTML = automatedNumberFormat(series[0][dataPointIndex]);
          ctd4.innerHTML = profitValue === 0 ? '0%' : ((profitValue / series[2][dataPointIndex]) * 100).toFixed(2) + '%';

          if (profitValue === 0) {
            ctd1.classList.remove('text-green-base-l');
            ctd1.classList.remove('text-red-base-l');
            ctd4.classList.remove('text-green-base-l');
            ctd4.classList.remove('text-red-base-l');
            ctd1.classList.add('text-gray-light-0-d');
            ctd4.classList.add('text-gray-light-0-d');
          } else if (profitValue < 0) {
            ctd1.classList.remove('text-green-base-l');
            ctd1.classList.remove('text-gray-light-0-d');
            ctd4.classList.remove('text-green-base-l');
            ctd4.classList.remove('text-gray-light-0-d');
            ctd1.classList.add('text-red-base-l');
            ctd4.classList.add('text-red-base-l');
          } else {
            ctd1.classList.remove('text-red-base-l');
            ctd1.classList.remove('text-gray-light-0-d');
            ctd4.classList.remove('text-red-base-l');
            ctd4.classList.remove('text-gray-light-0-d');
            ctd1.classList.add('text-green-base-l');
            ctd4.classList.add('text-green-base-l');
          }

          const elCrossHair = document.querySelector('.apexcharts-xcrosshairs');
          const elImagesAnnotations = document.querySelectorAll(
            '.apexcharts-point-annotations image'
          );
          const elCircleAnnotations = document.querySelectorAll(
            '.apexcharts-point-annotations circle'
          );
          const elAnnImg = document.querySelector(
            `.apexcharts-point-annotations :nth-child(${
              7 * (dataPointIndex + 1)
            })`
          );
          const elAnnCircle2 = document.querySelector(
            `.apexcharts-point-annotations :nth-child(${
              1 * (dataPointIndex + 3) + dataPointIndex * 6
            })`
          );

          elImagesAnnotations.forEach((element) => element.style.opacity = 0);
          elCircleAnnotations.forEach((element) => element.style.opacity = 0);

          if (elCrossHair) elCrossHair.style.opacity = 1;
          if (elAnnImg) elAnnImg.style.opacity = 1;
          if (elAnnCircle2) elAnnCircle2.style.opacity = 1;

          return ReactDOMServer.renderToString(
            <div>
              <div className='mb-1'>
                <h5 className='text-xs font-quicksand-medium dark:text-black-light-1-d'>{head}</h5>
              </div>
              <div className='mb-1'>
                <h5 className='text-gray-light-0-d text-xs font-quicksand-medium'>Total Nilai Investasi</h5>
                <span style={{fontFamily : 'QuickSandBold'}} className='block dark:text-black-light-1-d text-base font-quicksand-bold'>{automatedNumberFormat(Math.round(series[0][dataPointIndex]))}</span>
              </div>
              <div className='mb-1'>
                <h5 className='text-gray-light-0-d text-xs font-quicksand-medium'>P/L {profitValue === 0 ? '0%' : ((profitValue / series[2][dataPointIndex]) * 100).toFixed(2) + '%'}</h5>
                <span style={{fontFamily : 'QuickSandBold'}} className='block dark:text-black-light-1-d text-base font-quicksand-bold'>{automatedNumberFormat(Math.round(profitValue))}</span>
              </div>
              <div>
                <h5 className='text-gray-light-0-d text-xs font-quicksand-medium'>Nilai Investasi</h5>
                <span style={{fontFamily : 'QuickSandBold'}} className='block dark:text-black-light-1-d text-base font-quicksand-bold'>{automatedNumberFormat(Math.round(series[2][dataPointIndex]))}</span>
              </div>
            </div>
          );
        },
        marker: {
          show: true
        },
        // fixed: {
        //   enabled: true,
        //   position: 'topLeft',
        //   offsetX: 10,
        //   offsetY: -50
        // }
      },
      // fill
      // fill: {
      //   type: 'solid',
      //   colors: ['#1A73E8', '#B32824']
      // }
    }
  };

  const resetTooltip = () => {
    const ct = refTooltip.current;
    const elCrossHair = refChart.current.querySelector(".apexcharts-xcrosshairs");
    const lastIndex = categories?.length - 1;
    const elAnnCircle2 = refChart.current.querySelector(`.apexcharts-point-annotations :nth-child(${1 * (lastIndex + 3) + lastIndex * 6})`);
    const elCircleAnnotations = refChart.current.querySelectorAll(".apexcharts-point-annotations circle");
    const ctd1 = refLegend.current.querySelector("#legend-0");
    const ctd2 = refLegend.current.querySelector("#legend-1");
    const ctd3 = refLegend.current.querySelector("#legend-2");
    const ctd4 = refLegend.current.querySelector("#legend-3");
    
    elCircleAnnotations.forEach((element) => { element.style.opacity = 0;});
    ct.style.opacity = 0;

    elCrossHair.style.opacity = 0;
    if (elAnnCircle2) elAnnCircle2.style.opacity = 1;
    ctd1.innerHTML = !profitOrLoss?.isProfit ?  `-${automatedNumberFormat(Math.abs(profitOrLoss?.value))}` : automatedNumberFormat(Math.abs(profitOrLoss?.value))
    ctd2.innerHTML = automatedNumberFormat(profitOrLoss?.totalSaving)
    ctd3.innerHTML = automatedNumberFormat(profitOrLoss?.totalMoney);
    ctd4.innerHTML = profitOrLoss?.value === 0 ? '0%' : (profitOrLoss?.valuePercent * 100).toFixed(2) + '%';

    if (profitOrLoss?.value === 0) {
      ctd1.classList.remove('text-green-base-l');
      ctd1.classList.remove('text-red-base-l');
      ctd4.classList.remove('text-green-base-l');
      ctd4.classList.remove('text-red-base-l');
      ctd1.classList.add('text-gray-light-0-d');
      ctd4.classList.add('text-gray-light-0-d');
    } else if (profitOrLoss?.value < 0) {
      ctd1.classList.remove('text-green-base-l');
      ctd1.classList.remove('text-gray-light-0-d');
      ctd4.classList.remove('text-green-base-l');
      ctd4.classList.remove('text-gray-light-0-d');
      ctd1.classList.add('text-red-base-l');
      ctd4.classList.add('text-red-base-l');
    } else {
      ctd1.classList.remove('text-red-base-l');
      ctd1.classList.remove('text-gray-light-0-d');
      ctd4.classList.remove('text-red-base-l');
      ctd4.classList.remove('text-gray-light-0-d');
      ctd1.classList.add('text-green-base-l');
      ctd4.classList.add('text-green-base-l');
    }
  }

  return (
    <div className="relative pr-4 h-full min-h-[300px] xl:min-h-[230px]">
      <div ref={refLegend} className="flex px-4">
        <div className={`mr-6`}>
          <div className="flex items-center mb-1 h-4">
            <IconLegendChart className="text-red-base-l" />
            <span className="block text-xs ml-2 font-quicksand-semibold text-gray-light-0-d">
              Total Nilai Investasi
            </span>
          </div>
          <span
            id="legend-2"
            className={`hidden font-quicksand-semibold text-sm whitespace-nowrap`}>
            {automatedNumberFormat(profitOrLoss?.totalMoney || 0)}
          </span>
        </div>

        <div className={`mr-6`}>
          <div className="flex items-center mb-1 h-4">
            <span className="h-3 w-3 block bg-orange-1 rounded-sm" />

            <span className={`flex items-center ml-1 text-xs font-quicksand-semibold text-gray-light-0-d`}>
              P/L{' '}
              <span
                id="legend-3"
                className={`ml-1 whitespace-nowrap ${profitOrLoss?.isProfit ? 'text-green-base-l' : 'text-red-base-l'}`}>
                0%
              </span>
            </span>
          </div>

          <span className={`hidden font-quicksand-semibold text-sm`}>
            <span
              id="legend-0"
              className={`whitespace-nowrap ${profitOrLoss?.isProfit ? 'text-green-base-l' : 'text-red-base-l'}`}>
              {automatedNumberFormat(Math.abs(profitOrLoss?.value || 0))}
            </span>
          </span>
        </div>

        <div>
          <div className="flex items-center mb-1 h-4">
            <span className="h-3 w-3 block rounded-sm bg-[#6ACBCC] mr-1" />
            <span className="block text-xs font-quicksand-semibold text-gray-light-0-d">Nilai Investasi</span>
          </div>

          <span
            id="legend-1"
            className={`hidden font-quicksand-semibold text-sm whitespace-nowrap`}>
            {automatedNumberFormat(profitOrLoss?.totalSaving || 0)}
          </span>
        </div>
      </div>

      <div ref={refTooltip} className="absolute z-10 top-6 opacity-0 w-full hidden">
        <div
          className="inline-block bg-white py-2 px-3 rounded-md shadow-tooltip">
          <h5 id="tooltip-title" className="text-xs font-quicksand-semibold">
            17 Agustus 2022
          </h5>
        </div>
      </div>

      <div ref={refChart} className={`profit-loss-chart ${theme === 'dark' && 'dark'}`} style={{ height: 'calc(100% - 3rem)', minHeight: '250px' }}>
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height="100%"
        />
      </div>
    </div>
  );
}

ProfitLossChart.propTypes = {
  fsLoaded: PropTypes.bool,
  profitOrLoss: PropTypes.object,
  categories: PropTypes.array,
  series: PropTypes.array,
  annotations: PropTypes.object,
  colors: PropTypes.array,
  maxY: PropTypes.number,
  minY: PropTypes.number,
};

export default ProfitLossChart;
