import { useEffect, useState } from 'react';

export default () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [modalPosition, setModalPosition] = useState('center');
  const [modalSize, setModalSize] = useState('mx-auto max-w-3xl my-5');
  const [modalBackdrop, setModalBackdrop] = useState(() => {});

  const handleModal = (content = false, _position = false, _size = false, _modalBackDrop = true) => {
    if (modalLoading) return false;
    setModal(content ? true : false);
    setModalContent(content ? content : <></>);

    if (_position) {
      setModalPosition(_position);  
    } else {
      setModalPosition('center');  
    }

    if (_size) {
      setModalSize(_size);  
    } else {
      setModalSize('mx-auto max-w-3xl my-5');  
    }

    setModalBackdrop(_modalBackDrop);
  };

  const handleModalLoading = (isLoading) => {
    setModalLoading(isLoading);
  }

  useEffect(() => {
    const bodyEl = document.querySelector('body');
    if (modal) {
      bodyEl.classList.add('overflow-hidden');
    } else {
      bodyEl.classList.remove('overflow-hidden');
    }
  }, [modal])

  return { modal, modalPosition, modalBackdrop, modalSize, modalLoading, handleModal, modalContent, handleModalLoading };
};
