import PropTypes from 'prop-types';

function IconHistory({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2145_66640)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.78312 7.29171C2.38454 7.06159 2.553 6.23187 2.78312 5.83329C5.08431 1.84752 10.1809 0.481894 14.1667 2.78308C18.1524 5.08427 19.5181 10.1809 17.2169 14.1666C14.9157 18.1524 9.81911 19.518 5.83333 17.2168C5.43476 16.9867 5.29819 16.4771 5.52831 16.0785C5.75843 15.6799 6.26809 15.5433 6.66667 15.7735C9.85528 17.6144 13.9326 16.5219 15.7735 13.3333C17.6145 10.1447 16.522 6.0674 13.3333 4.22646C10.1447 2.38551 6.06745 3.47801 4.2265 6.66663C3.99638 7.0652 3.1817 7.52183 2.78312 7.29171Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.69659 9.05497C6.29801 8.82485 6.16145 8.3152 6.39157 7.91662C7.54216 5.92373 10.0905 5.24092 12.0833 6.39151C14.0762 7.5421 14.759 10.0904 13.6084 12.0833C13.3783 12.4819 12.8687 12.6184 12.4701 12.3883C12.0715 12.1582 11.935 11.6485 12.1651 11.25C12.8554 10.0542 12.4457 8.52524 11.25 7.83489C10.0543 7.14453 8.5253 7.55422 7.83494 8.74995C7.60482 9.14853 7.09516 9.28509 6.69659 9.05497Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.96607 4.80667C2.27527 4.70477 2.57971 4.88054 2.64606 5.19926L3.03565 7.07057L4.85104 6.4723C5.16023 6.37041 5.46467 6.54618 5.53103 6.8649C5.59738 7.18362 5.40052 7.52459 5.09132 7.62649L2.71608 8.40925C2.40689 8.51115 2.10245 8.33538 2.03609 8.01666L1.52637 5.56826C1.46001 5.24954 1.65687 4.90856 1.96607 4.80667Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2145_66640">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconHistory.propTypes = {
  className: PropTypes.string
};

export default IconHistory;
