import PropTypes from 'prop-types';

const IconEdit = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.33325 17.5C3.33325 17.0398 3.70635 16.6667 4.16659 16.6667H15.8333C16.2935 16.6667 16.6666 17.0398 16.6666 17.5C16.6666 17.9603 16.2935 18.3334 15.8333 18.3334H4.16659C3.70635 18.3334 3.33325 17.9603 3.33325 17.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.343 2.64519C11.6448 1.34345 13.7553 1.34345 15.0571 2.64519L15.6882 3.27632C16.9899 4.57807 16.9899 6.68863 15.6882 7.99038L9.93311 13.7454C9.70192 13.9766 9.40783 14.1347 9.08746 14.2L5.33447 14.9649C4.16401 15.2035 3.12999 14.1695 3.36852 12.999L4.13336 9.24593C4.19865 8.92554 4.35674 8.63143 4.58795 8.40023L10.343 2.64519ZM13.8785 3.82371C13.2277 3.17283 12.1724 3.17283 11.5215 3.8237L11.1785 4.16674L14.1666 7.15489L14.5097 6.81186C15.1606 6.16099 15.1606 5.10571 14.5097 4.45483L13.8785 3.82371ZM7.01181 8.33339L9.99998 5.34525L12.9881 8.3334L9.99997 11.3215L7.01181 8.33339ZM5.8333 9.5119L5.76646 9.57874L5.00162 13.3318L8.7546 12.5669L8.82145 12.5001L5.8333 9.5119Z"
        fill="currentColor"
      />
    </svg>
  );
};

IconEdit.propTypes = {
  className: PropTypes.string
};

IconEdit.defaultProps = {
  className: ''
};

export default IconEdit;
