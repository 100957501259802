import PropTypes from 'prop-types';
import styles from './css/input-switch.module.css';

function InputSwitch({ value, name, color = 'orange', size = 'default', onChange = () => {}, ...props }) {
  return (
    <label role='button' tabIndex={1} className={`${styles['switch']} ${styles[size]} ${styles[color]} group rounded-lg`}>
      <input {...props} checked={value} value={value} name={name} type="checkbox" onChange={onChange} />
      <span className={`${styles['slider']} ${styles['round']} group-focus-within:ring-1 group-focus-within:ring-main-base-l group-focus-within:outline-none group-focus-within:outline-transparent group-focus-within:ring-inset`}></span>
    </label>
  );
}

InputSwitch.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default']),
  onChange: PropTypes.func,
  color: PropTypes.string,
};

export default InputSwitch;
