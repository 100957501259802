import Draggable from 'components/draggable';
import {
  useGetAllWorkSpaceByUserId2Query,
  useUpdateWorkSpaceByIdSkipInvalidateMutation
} from 'services/rtk-query/workspace';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import HeaderWidget from 'components/header-widget';
import { ModalContext } from 'components/modal/context/modalContext';
import { triggerSegmentEvent } from 'lib/segment';
import WorkSpaceForm from 'components/form/workspace-form';
import RenderIf from 'components/basic/render-if';
import { isArray, isEqual, maxBy, orderBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { invalidateWatchlist } from 'services/rtk-query/watchlist';
import {
  invalidateBrokerSummaryByCode,
  invalidateStockByCode,
  invalidateStockCalendar,
  invalidateStockCorpActionByCode,
  invalidateStockDoneDetailByCode,
  invalidateStockDoneSummaryByCode,
  invalidateStockFundamentalByCode,
  invalidateStockInfoByCode,
  invalidateStockNewsByCode,
  invalidateStockTopTrending
} from 'services/rtk-query/stock';
import {
  invalidateAllOrder,
  invalidateIntraDataOrderBookByCode,
  invalidateOrderListHistory
} from 'services/rtk-query/trade';
import { invalidateTradingIdeas } from 'services/rtk-query/classroom';
import { invalidateTreeMap } from 'services/rtk-query/master-data';
import { invalidateBuyingPower } from 'services/rtk-query/trading';
import SkeletonTheme from 'components/basic/skeleton-theme';
import ImageEmptyState from 'assets/images/mascot/mascot-empty-orderlist.png';
import { setCoachMarkWorkspace } from 'store/pina-pro';
import { PinaTradeWorkspaceAfterCreated } from 'constants/coachmark';
import { CoachMark } from 'react-coach-mark';
import { useCoachMark } from 'hooks/useCoachmark';
import { isMobile } from 'react-device-detect';
import ModalFirstTimeWorkspace2 from 'components/modal/template/modal-first-time-workspace-2';
import { useCreateUserCoachMarkMutation } from 'services/rtk-query/user';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import ModalHelp from 'components/modal/template/modal-help';
import Modal from 'components/modal/modal';
import LIST_WIDGET from 'constants/list-widget';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import DynamicModule from 'pages/trading/_partials/dynamic-module';
import TabWorkspace from '../_partials/tab-worksace';
import ModalWidgetFast2 from '../_partials/modal-widget-fast-2';
import { setAbsoluteWorkspace } from 'store/settings';
import useFontSize from 'hooks/useFontSize';
import cn from 'utils/classname';
import IconWidgetList from 'components/svg/icon-widget-list';
import { Helmet } from 'react-helmet-async';

export default function PinaProAbsoluteWorkspace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { fontSize } = useFontSize();
  // const { slug } = useParams();
  const { user } = useBrowserTabAuth();
  const { coachMarkWorkspace } = useSelector(({ pinaPro }) => pinaPro);
  const { absoluteWorkspace } = useSelector(({ settings }) => settings);

  const addWidgetRef = useRef(null);
  const manageWidgetRef = useRef(null);

  const { handleModal } = useContext(ModalContext);
  const { cmWorkspaceInit, cmWorkspaceCreated } = useCoachMark();

  const [updateWorkspaceLazy] = useUpdateWorkSpaceByIdSkipInvalidateMutation();
  const [createUserCoachMark] = useCreateUserCoachMarkMutation();

  const {
    data: workspaces2,
    // isLoading: isLoadingWorkspaces,
    // isFetching: isFetchingWorkspace,
    refetch: refetchAllWorkspace
  } = useGetAllWorkSpaceByUserId2Query(user?.id, {
    refetchOnMountOrArgChange: true
  });

  const slug = useMemo(() => {
    return 'absolute-workspace';
  }, []);

  const isLoadingWorkspaces = useMemo(() => {
    return false;
  }, []);

  const isFetchingWorkspace = useMemo(() => {
    return false;
  }, []);

  const workspaces = useMemo(() => {
    return absoluteWorkspace || [];
  }, [absoluteWorkspace]);

  const [activeWorkspace, setActiveWorkspace] = useState({
    ...absoluteWorkspace?.[0],
    layout: JSON.parse(absoluteWorkspace?.[0]?.layout || '[]')
  });
  const [isCreateEmptyWorkspace, setIsCreateEmptyWorkspace] = useState(false);
  const [localCoachMarkList, setLocalCoachMarkList] = useState([]);
  const [openListHelp, setOpenListHelp] = useState(false);

  const defaultCoachmark = [
    {
      activate: true,
      reference: addWidgetRef,
      tooltip: { position: 'bottom' },
      component: (
        <div className="block p-2 max-w-[280px]">
          <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">
            Tambah Widget Baru
          </h5>
          <p className="text-gray-light-0-d text-xs mb-2">
            Tambah widget atau komponen yang Kamu butuhkan untuk membuat
            workspace yang sesuai dengan gaya tradingmu
          </p>

          <div className="flex justify-between">
            <button
              onClick={() => dispatch(setCoachMarkWorkspace(1))}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l mr-0 text-sm block font-quicksand-semibold">
              Kembali
            </button>
            <button
              onClick={() => dispatch(setCoachMarkWorkspace(3))}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l ml-auto mr-0 text-sm block font-quicksand-semibold">
              Selanjutnya
            </button>
          </div>
        </div>
      )
    },
    {
      activate: true,
      reference: manageWidgetRef,
      tooltip: { position: isMobile ? 'bottom' : 'bottom' },
      component: (
        <div className="block p-2 max-w-[280px]">
          <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">
            Atur Widget
          </h5>
          <p className="text-gray-light-0-d text-xs mb-2">
            Gunakan button ini untuk menambah widget sesuai dengan kebutuhan dan
            gaya tradingmu
          </p>

          <div className="flex justify-between">
            <button
              onClick={() => dispatch(setCoachMarkWorkspace(2))}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l mr-0 text-sm block font-quicksand-semibold">
              Kembali
            </button>
            <button
              onClick={async () => {
                await createUserCoachMark({
                  category: PinaTradeWorkspaceAfterCreated,
                  userId: user?.id
                }).unwrap();
                dispatch(setCoachMarkWorkspace(4));
              }}
              type="button"
              className="outline-none hover:opacity-75 text-main-base-l ml-auto mr-0 text-sm block font-quicksand-semibold">
              Tutup
            </button>
          </div>
        </div>
      )
    }
  ];

  const handleAddWidgets = (selectedWidgets) => {
    let xy = {
      x: 0,
      y: 0
    };

    let reverseLayout = orderBy(window.activeWorkspace?.layout, 'index', [
      'desc'
    ]);

    let findPosition = reverseLayout?.find(
      (item) =>
        item?.state?.x % 20 === 0 &&
        item?.state?.y % 20 === 0 &&
        item?.state?.x <= 600
    );

    if (findPosition) {
      let position = findPosition;
      xy = {
        x: position?.state?.x + 20,
        y: position?.state?.y + 20
      };
    }

    setActiveWorkspace((prev) => {
      const lastZIndex = maxBy(prev?.layout, 'zIndex')?.zIndex || 0;

      return {
        ...prev,
        layout: [
          ...(prev?.layout || []),
          {
            ...selectedWidgets?.[0],
            state: {
              ...selectedWidgets?.[0]?.state,
              ...xy
            },
            zIndex: lastZIndex + 1,
            symbol: 'AALI',
            id: uuidv4(),
            index: prev?.layout?.length + 1
          }
        ]
      };
    });
  };

  const handleSwapWidget = (selectedWidgets, widgetId) => {
    setActiveWorkspace((prev) => ({
      ...prev,
      layout: prev?.layout?.map((item) => {
        if (item?.id === widgetId) {
          return {
            ...item,
            ...selectedWidgets[0]
          };
        }

        return item;
      })
    }));
  };

  const handleCangeWidgetState = (newState, widgetId) => {
    setActiveWorkspace((prev) => ({
      ...prev,
      layout: prev?.layout?.map((item) => {
        if (item?.id === widgetId) {
          return {
            ...item,
            state: newState
          };
        }

        return item;
      })
    }));
  };

  const handleCangeWidgetZIndex = (widgetId) => {
    setActiveWorkspace((prev) => {
      const lastZIndex = maxBy(prev?.layout, 'zIndex')?.zIndex || 0;

      return {
        ...prev,
        layout: prev?.layout?.map((item) => {
          if (item?.id === widgetId) {
            return {
              ...item,
              zIndex: lastZIndex + 1
            };
          }

          return item;
        })
      };
    });
  };

  const handleExitWidget = (widgetId) => {
    setActiveWorkspace((prev) => ({
      ...prev,
      layout: prev?.layout?.filter((item) => item?.id !== widgetId)
    }));
  };

  const handleRefreshWidget = (widget) => {
    const module = widget?.name;
    const symbol = widget?.symbol;

    if (module === 'watchlist') {
      dispatch(invalidateWatchlist());
    }

    if (module === 'advanced-chart') {
      dispatch(invalidateStockByCode(symbol));
    }

    if (
      [
        'bundle-orderlist',
        'trade-list',
        'order-list',
        'portfolio',
        'order-form'
      ]?.includes(module)
    ) {
      dispatch(invalidateAllOrder());
    }

    if (['order-form-orderbook', 'order-form-mock-only']?.includes(module)) {
      dispatch(invalidateAllOrder());
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateIntraDataOrderBookByCode(symbol));
    }

    if (
      [
        'fast-order-buy',
        'fast-order-sell',
        'multi-order',
        'fast-order'
      ].includes(module)
    ) {
      dispatch(invalidateAllOrder());
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateIntraDataOrderBookByCode(symbol));
    }

    if (module === 'calendar') {
      dispatch(invalidateStockCalendar());
    }

    if (module === 'trading-ideas') {
      dispatch(invalidateTradingIdeas());
    }

    if (module === 'top-tranding') {
      dispatch(invalidateStockTopTrending());
    }

    if (module === 'bandarmology') {
      dispatch(invalidateBrokerSummaryByCode(symbol));
    }

    if (['chart-orderbook', 'orderbook'].includes(module)) {
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateIntraDataOrderBookByCode(symbol));
    }

    if (module === 'chart') {
      dispatch(invalidateStockByCode(symbol));
    }

    if (module === 'company-profile') {
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateStockInfoByCode(symbol));
    }

    if (module === 'corp-action') {
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateStockCorpActionByCode(symbol));
    }

    if (module === 'done-detail') {
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateStockDoneDetailByCode(symbol));
    }

    if (module === 'done-summary') {
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateStockDoneSummaryByCode(symbol));
    }

    if (module === 'fundamental') {
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateStockFundamentalByCode(symbol));
    }

    if (module === 'news') {
      dispatch(invalidateStockByCode(symbol));
      dispatch(invalidateStockNewsByCode(symbol));
    }

    if (module === 'order-list-history') {
      dispatch(invalidateOrderListHistory());
    }

    if (module === 'chart-heatmap') {
      dispatch(invalidateTreeMap('previousLastTradedPrice'));
    }

    if (module === 'IHSG') {
      dispatch(invalidateStockByCode('COMPOSITE'));
    }

    if (module === 'info-cash') {
      dispatch(invalidateBuyingPower());
    }
  };

  const handleConnectWidget = (widgetId) => {
    setActiveWorkspace((prev) => {
      let findConnectedSymbol = prev?.layout?.find(
        (item) => item?.isConnectSymbol
      )?.symbol;

      return {
        ...prev,
        layout: prev?.layout?.map((item) => {
          if (item?.id === widgetId) {
            return {
              ...item,
              isConnectSymbol: !item?.isConnectSymbol,
              symbol: findConnectedSymbol || item?.symbol
            };
          }

          return item;
        })
      };
    });
  };

  const handleChangeSymbolWidget = (newSymbol, widget) => {
    setActiveWorkspace((prev) => ({
      ...prev,
      layout: prev?.layout?.map((item) => {
        if (
          item?.id === widget?.id ||
          (widget?.isConnectSymbol === item?.isConnectSymbol &&
            widget?.isConnectSymbol)
        ) {
          return {
            ...item,
            symbol: newSymbol
          };
        }

        return item;
      })
    }));
  };

  const handleChangeWidgetSettings = (newSettings, widgetId) => {
    setActiveWorkspace((prev) => ({
      ...prev,
      layout: prev?.layout?.map((item) => {
        if (item?.id === widgetId) {
          return {
            ...item,
            settings: {
              ...(item?.settings || {}),
              ...newSettings
            }
          };
        }
        return item;
      })
    }));
  };

  const handleActiveWidget = (idWidget) => {
    const els = document.querySelectorAll('.draggable-pina');
    els?.forEach((el) => {
      el?.classList?.remove('z-10');
    });

    const activeEl = document.getElementById(idWidget);
    if (activeEl) {
      activeEl.classList.add('z-10');
    }
  };

  // ALT SHOW ORDERBOOK
  useKeyboardShortcut({
    keys: ['¢', '4'],
    combine: 'altKey',
    onKeyPressed: () => {
      const orderbookWidget = LIST_WIDGET.find(
        (item) => item?.name === 'orderbook'
      );
      handleAddWidgets([orderbookWidget]);
    }
  });

  // ALT SHOW ORDER LIST
  useKeyboardShortcut({
    keys: ['∞', '5'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderlistWidget = window?.activeWorkspace?.layout?.find(
        (item) => item?.name === 'bundle-orderlist'
      );
      if (currentOrderlistWidget) {
        handleChangeWidgetSettings(
          {
            ...currentOrderlistWidget?.settings,
            activeTabIndex: 0
          },
          currentOrderlistWidget?.id
        );
        handleActiveWidget(currentOrderlistWidget?.id);
      } else {
        const portfolio = LIST_WIDGET.find(
          (item) => item?.name === 'bundle-orderlist'
        );
        handleAddWidgets([{ ...portfolio, settings: { activeTabIndex: 0 } }]);
      }
    }
  });

  // ALT SHOW TRADE LIST
  useKeyboardShortcut({
    keys: ['¶', '7'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderlistWidget = window?.activeWorkspace?.layout?.find(
        (item) => item?.name === 'bundle-orderlist'
      );
      if (currentOrderlistWidget) {
        handleChangeWidgetSettings(
          {
            ...currentOrderlistWidget?.settings,
            activeTabIndex: 1
          },
          currentOrderlistWidget?.id
        );
        handleActiveWidget(currentOrderlistWidget?.id);
      } else {
        const portfolio = LIST_WIDGET.find(
          (item) => item?.name === 'bundle-orderlist'
        );
        handleAddWidgets([{ ...portfolio, settings: { activeTabIndex: 1 } }]);
      }
    }
  });

  // SHOW PORTFOLIO
  useKeyboardShortcut({
    key: 'F5',
    onKeyPressed: () => {
      const currentOrderlistWidget = window?.activeWorkspace?.layout?.find(
        (item) => item?.name === 'bundle-orderlist'
      );
      if (currentOrderlistWidget) {
        handleChangeWidgetSettings(
          {
            ...currentOrderlistWidget?.settings,
            activeTabIndex: 2
          },
          currentOrderlistWidget?.id
        );
        handleActiveWidget(currentOrderlistWidget?.id);
      } else {
        const portfolio = LIST_WIDGET.find(
          (item) => item?.name === 'bundle-orderlist'
        );
        handleAddWidgets([{ ...portfolio, settings: { activeTabIndex: 2 } }]);
      }
    }
  });

  // SHOW RUNNING TRADE
  useKeyboardShortcut({
    key: 'F8',
    onKeyPressed: () => {
      const runningTradeWidget = LIST_WIDGET.find(
        (item) => item?.name === 'running-trade'
      );
      handleAddWidgets([runningTradeWidget]);
    }
  });

  // SHOW ORDER LIST
  useKeyboardShortcut({
    key: 'F6',
    onKeyPressed: () => {
      const currentOrderlistWidget = window?.activeWorkspace?.layout?.find(
        (item) => item?.name === 'bundle-orderlist'
      );
      if (currentOrderlistWidget) {
        handleChangeWidgetSettings(
          {
            ...currentOrderlistWidget?.settings,
            activeTabIndex: 0
          },
          currentOrderlistWidget?.id
        );
        handleActiveWidget(currentOrderlistWidget?.id);
      } else {
        const portfolio = LIST_WIDGET.find(
          (item) => item?.name === 'bundle-orderlist'
        );
        handleAddWidgets([{ ...portfolio, settings: { activeTabIndex: 0 } }]);
      }
    }
  });

  // ALT SHOW BUY FORM
  useKeyboardShortcut({
    keys: ['∫', 'b'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderWidgets = window?.activeWorkspace?.layout?.find(
        (item) =>
          item?.name === 'order-form-mock-only' || item?.name === 'order-form'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(
          {
            ...currentOrderWidgets?.settings,
            initActiveTabIndex: 0
          },
          currentOrderWidgets?.id
        );
        handleActiveWidget(currentOrderWidgets?.id);
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 0 } }
        ]);
      }
    }
  });

  // SHOW BUY FORM
  useKeyboardShortcut({
    keys: ['F2'],
    onKeyPressed: () => {
      const currentOrderWidgets = window?.activeWorkspace?.layout?.find(
        (item) =>
          item?.name === 'order-form-mock-only' || item?.name === 'order-form'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(
          {
            ...currentOrderWidgets?.settings,
            initActiveTabIndex: 0
          },
          currentOrderWidgets?.id
        );
        handleActiveWidget(currentOrderWidgets?.id);
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 0 } }
        ]);
      }
    }
  });

  // ALT SHOW SELL FORM
  useKeyboardShortcut({
    keys: ['ß', 's'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderWidgets = window?.activeWorkspace?.layout?.find(
        (item) =>
          item?.name === 'order-form-mock-only' || item?.name === 'order-form'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(
          {
            ...currentOrderWidgets?.settings,
            initActiveTabIndex: 1
          },
          currentOrderWidgets?.id
        );
        handleActiveWidget(currentOrderWidgets?.id);
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 1 } }
        ]);
      }
    }
  });

  // SHOW SELL FORM
  useKeyboardShortcut({
    keys: ['F4'],
    onKeyPressed: () => {
      const currentOrderWidgets = window?.activeWorkspace?.layout?.find(
        (item) =>
          item?.name === 'order-form-mock-only' || item?.name === 'order-form'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(
          {
            ...currentOrderWidgets?.settings,
            initActiveTabIndex: 1
          },
          currentOrderWidgets?.id
        );
        handleActiveWidget(currentOrderWidgets?.id);
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 1 } }
        ]);
      }
    }
  });

  // ALT SHOW AMEND FORM
  useKeyboardShortcut({
    keys: ['å', 'a'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderWidgets = window?.activeWorkspace?.layout?.find(
        (item) =>
          item?.name === 'order-form-mock-only' || item?.name === 'order-form'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(
          {
            ...currentOrderWidgets?.settings,
            initActiveTabIndex: 2
          },
          currentOrderWidgets?.id
        );
        handleActiveWidget(currentOrderWidgets?.id);
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 2 } }
        ]);
      }
    }
  });

  // SHOW AMEND FORM
  useKeyboardShortcut({
    keys: ['F3'],
    onKeyPressed: () => {
      const currentOrderWidgets = window?.activeWorkspace?.layout?.find(
        (item) =>
          item?.name === 'order-form-mock-only' || item?.name === 'order-form'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(
          {
            ...currentOrderWidgets?.settings,
            initActiveTabIndex: 2
          },
          currentOrderWidgets?.id
        );
        handleActiveWidget(currentOrderWidgets?.id);
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 2 } }
        ]);
      }
    }
  });

  // ALT SHOW WITHDRAW FORM
  useKeyboardShortcut({
    keys: ['∑', 'w'],
    combine: 'altKey',
    onKeyPressed: () => {
      const currentOrderWidgets = window?.activeWorkspace?.layout?.find(
        (item) =>
          item?.name === 'order-form-mock-only' || item?.name === 'order-form'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(
          {
            ...currentOrderWidgets?.settings,
            initActiveTabIndex: 3
          },
          currentOrderWidgets?.id
        );
        handleActiveWidget(currentOrderWidgets?.id);
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 3 } }
        ]);
      }
    }
  });

  // SHOW WITHDRAW FORM
  useKeyboardShortcut({
    keys: ['F12'],
    onKeyPressed: () => {
      const currentOrderWidgets = window?.activeWorkspace?.layout?.find(
        (item) =>
          item?.name === 'order-form-mock-only' || item?.name === 'order-form'
      );
      if (currentOrderWidgets) {
        handleChangeWidgetSettings(
          {
            ...currentOrderWidgets?.settings,
            initActiveTabIndex: 3
          },
          currentOrderWidgets?.id
        );
        handleActiveWidget(currentOrderWidgets?.id);
      } else {
        const orderWidget = LIST_WIDGET.find(
          (item) => item?.name === 'order-form-mock-only'
        );
        handleAddWidgets([
          { ...orderWidget, settings: { initActiveTabIndex: 3 } }
        ]);
      }
    }
  });

  // SHOW WIDGET SIDEBAR
  useKeyboardShortcut({
    key: 'w',
    combine: 'ctrlKey',
    onKeyPressed: () => {
      handleModal(
        <ModalWidgetFast2
          onClose={() => handleModal(false)}
          onPickWidget={(widgets) => {
            handleAddWidgets(widgets);
          }}
        />,
        'right',
        'mx-auto max-w-5xl'
      );
      triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
    }
  });

  // ALT HELP POPUP
  useKeyboardShortcut({
    keys: ['˙', 'h'],
    combine: 'altKey',
    onKeyPressed: () => {
      setOpenListHelp(true);
    }
  });

  // HELP POPUP
  useKeyboardShortcut({
    keys: ['F1'],
    onKeyPressed: () => {
      setOpenListHelp(true);
    }
  });

  // useEffect(() => {
  //   const findWorkspace = workspaces?.find((item) => item?.slug === slug);
  //   if (findWorkspace) {
  //     const layout = JSON.parse(findWorkspace?.layout);
  //     setActiveWorkspace({
  //       ...findWorkspace,
  //       layout: isArray(layout) ? layout : []
  //     });
  //   }
  // }, [workspaces, slug]);

  // useEffect(() => {
  //   if (slug) refetchAllWorkspace();
  // }, [slug]);

  useEffect(() => {
    if (
      activeWorkspace?.id &&
      slug === activeWorkspace?.slug &&
      slug &&
      !isFetchingWorkspace
    ) {
      // updateWorkspaceLazy({
      //   body: {
      //     ...activeWorkspace,
      //     layout: JSON.stringify(activeWorkspace?.layout)
      //   },
      //   id: activeWorkspace?.id
      // }).unwrap();
      if (
        !isEqual(
          {
            ...activeWorkspace,
            layout: JSON.stringify(activeWorkspace?.layout)
          },
          absoluteWorkspace?.[0]
        )
      ) {
        dispatch(
          setAbsoluteWorkspace({
            ...activeWorkspace,
            layout: JSON.stringify(activeWorkspace?.layout)
          })
        );
      }

      triggerSegmentEvent({ event: 'TR Workspace Saved' });
      window.activeWorkspace = activeWorkspace;
    }
  }, [activeWorkspace, slug, isFetchingWorkspace, absoluteWorkspace]);

  // useEffect(() => {
  //   if (
  //     ((slug === 'new-workspace' && workspaces?.length) ||
  //       !workspaces?.length) &&
  //     !isFetchingWorkspace
  //   ) {
  //     triggerSegmentEvent({ event: 'TR New Workspace Added' });
  //     handleModal(
  //       <WorkSpacePresetForm2
  //         workspaces={workspaces}
  //         onClose={() => {
  //           handleModal(false);
  //           if (workspaces?.length) {
  //             navigate(-1);
  //           } else {
  //             navigate('/default-workspace')
  //           }
  //         }}
  //         onSuccess={(response) => {
  //           if (response?.name === 'My Workspace')
  //             setIsCreateEmptyWorkspace(true);
  //           triggerSegmentEvent({ event: 'TR Workspace Saved PINA Trade' });
  //         }}
  //       />,
  //       false,
  //       false,
  //       false
  //     );
  //   }

  //   if (!slug && workspaces?.length > 0) {
  //     navigate(`/workspace/${workspaces?.[0]?.slug}`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [slug, isFetchingWorkspace, workspaces]);

  useEffect(() => {
    if (isCreateEmptyWorkspace && !cmWorkspaceCreated) {
      handleModal(
        <ModalWidgetFast2
          onClose={() => {
            handleModal(false);
            setIsCreateEmptyWorkspace(false);
          }}
          onPickWidget={(widgets) => {
            handleAddWidgets(widgets);
            setIsCreateEmptyWorkspace(false);
          }}
        />,
        'right',
        'mx-auto max-w-5xl'
      );
    }
  }, [isCreateEmptyWorkspace, cmWorkspaceCreated]);

  useEffect(() => {
    if (cmWorkspaceInit) {
      handleModal(
        <ModalFirstTimeWorkspace2
          onPickSelfWorkspace={(_workspace) => {
            handleModal(
              <ModalWidgetFast2
                onClose={() => handleModal(false)}
                onPickWidget={(widgets) => {
                  handleAddWidgets(widgets, _workspace);
                }}
              />,
              'right',
              'mx-auto max-w-5xl'
            );
            triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
          }}
        />,
        false,
        'mx-auto max-w-5xl my-5',
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmWorkspaceInit]);

  useEffect(() => {
    if (activeWorkspace?.layout?.length && cmWorkspaceCreated) {
      setTimeout(() => {
        document.querySelector('.harsh-coach-mark')?.classList?.add('zoom-2');
        document.querySelector('.hcm-tooltip-base')?.classList?.add('zoom-2');
      }, 10);
    }
  }, [cmWorkspaceCreated, activeWorkspace, coachMarkWorkspace]);

  return (
    <>
      <Helmet>
        <title>Absolute Workspace | Pina Trade</title>
      </Helmet>
      <div className={`relative z-0`}>
        <div className="zoom-2 p-4 pb-0 pt-0">
          <div className={`transform py-4 pt-3 z-40 sticky top-[68px]`}>
            <div className="grid gap-4 lg:gap-0 lg:flex justify-between grid-cols-1 lg:grid-cols-none">
              <div className="flex-none w-full lg:w-[75vw]">
                <TabWorkspace
                  activeWorkspace={activeWorkspace}
                  isLoading={isLoadingWorkspaces}
                  workspaces={workspaces2}
                  onAddWorkspace={() => navigate('/workspace/new-workspace')}
                  onAddWidget={() => {
                    handleModal(
                      <ModalWidgetFast2
                        onClose={() => handleModal(false)}
                        onPickWidget={(widgets) => {
                          handleAddWidgets(widgets);
                        }}
                      />,
                      'right',
                      'mx-auto max-w-5xl'
                    );
                    triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
                  }}
                  onUpdateWorkspaceName={(_workspace) => {
                    handleModal(
                      <WorkSpaceForm
                        layout={_workspace?.layout}
                        workspaces={workspaces}
                        initialValues={_workspace}
                        onClose={() => handleModal(false)}
                        onSuccess={(response) => {
                          navigate(`/workspace/${response?.slug}`);
                          triggerSegmentEvent({
                            event: 'TR Workspace Saved PINA Trade'
                          });
                          handleModal(false);
                        }}
                      />
                    );
                  }}
                  onSetEmptyActiveWorkspace={() =>
                    setActiveWorkspace({ layout: [] })
                  }
                  onInitCoachMark={(_coachMarks) =>
                    setLocalCoachMarkList([..._coachMarks, ...defaultCoachmark])
                  }
                />
              </div>

              <RenderIf isTrue={slug && slug !== 'new-workspace'}>
                <div className="flex-auto ml-2 lg:flex justify-end hidden items-center">
                  <button
                    title="Tambah Widget Baru"
                    type="button"
                    ref={addWidgetRef}
                    onClick={() => {
                      handleModal(
                        <ModalWidgetFast2
                          onClose={() => handleModal(false)}
                          onPickWidget={(widgets) => {
                            handleAddWidgets(widgets);
                          }}
                        />,
                        'right',
                        'mx-auto max-w-5xl'
                      );
                      triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
                    }}
                    className="appearance-none text-main-base-d p-1 flex items-center gap-1 group focus-within:ring-0">
                    <IconWidgetList className="h-4 w-4" />

                    <span
                      className={cn(
                        'block text-xs w-0 overflow-hidden group-hover:w-[100px] line-clamp-1 transition-all ease-linear duration-150 h-4',
                        coachMarkWorkspace && 'w-[90px]'
                      )}>
                      Tambah Widget
                    </span>
                  </button>
                </div>
              </RenderIf>
            </div>
          </div>
        </div>

        <div className="p-4 py-0">
          <RenderIf isTrue={isLoadingWorkspaces}>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
              <SkeletonTheme
                containerClassName="leading-none"
                width="100%"
                height="400px"
                borderRadius="16px"
              />
              <SkeletonTheme
                containerClassName="leading-none"
                width="100%"
                height="400px"
                borderRadius="16px"
              />
              <SkeletonTheme
                containerClassName="leading-none"
                width="100%"
                height="400px"
                borderRadius="16px"
              />
            </div>
          </RenderIf>

          <RenderIf
            isTrue={
              !isLoadingWorkspaces &&
              !activeWorkspace?.layout?.length &&
              slug &&
              slug !== 'new-workspace'
            }>
            <div
              className="w-full bg-gray-base-l dark:bg-black-base-d rounded-2xl flex items-center justify-center min-h-[400px]"
              style={{ height: 'calc(100vh - 210px)' }}>
              <div className="text-center p-10 dark:text-black-light-0-d">
                <img
                  src={ImageEmptyState}
                  alt="empty"
                  className="h-14 w-14 mx-auto mb-4"
                />

                <h2 className="text-2xl mb-1 font-quicksand-semibold">
                  Tambah widget untuk di workspace ini
                </h2>

                <p className="text-sm">
                  Tambahkan widget sesuai kebutuhan kamu
                </p>

                <button
                  title="Tambah Widget Baru"
                  type="button"
                  onClick={() => {
                    handleModal(
                      <ModalWidgetFast2
                        onClose={() => handleModal(false)}
                        onPickWidget={(widgets) => {
                          handleAddWidgets(widgets);
                        }}
                      />,
                      'right',
                      'mx-auto max-w-5xl'
                    );
                    triggerSegmentEvent({ event: 'TR Add Widget Clicked' });
                  }}
                  className="mt-6 p-3 bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d w-64 mx-auto hover:bg-opacity-75 disabled:bg-opacity-100 text-white font-quicksand-semibold text-sm flex items-center rounded-lg justify-center hover:opacity-75 transition-all ease-in-out duration-200">
                  Tambah Widget
                </button>
              </div>
            </div>
          </RenderIf>

          <div className="zoom-2">
            <div
              className={cn(
                'container-workspace',
                fontSize === 'trade-font-large' && 'h-[calc(100vh-64px)]',
                fontSize === 'trade-font-small' && 'h-[calc(100vh)]',
                fontSize === 'trade-font-normal' && 'h-[calc(100vh-32px)]'
              )}>
              {activeWorkspace?.layout?.map((item, key) => (
                <Draggable
                  id={item?.id}
                  key={item?.id}
                  zIndex={item?.zIndex}
                  defaultState={item?.state}
                  onChangeWidgetZIndex={() => handleCangeWidgetZIndex(item?.id)}
                  onChangeWidgetState={(newState) => {
                    setTimeout(() => {
                      handleCangeWidgetState(newState, item?.id);
                    }, 500);
                  }}>
                  <div
                    ref={key === 0 ? manageWidgetRef : null}
                    className="zoom-2 drag-handler-widget">
                    <HeaderWidget
                      title={item?.title}
                      isConnectSymbol={item?.isConnectSymbol}
                      onExitWidget={() => handleExitWidget(item?.id)}
                      onSwapWidget={() => {
                        handleModal(
                          <ModalWidgetFast2
                            header={
                              <h5 className="font-quicksand-semibold mb-6 text-base dark:text-black-light-1-d">
                                Ganti Widget
                              </h5>
                            }
                            onClose={() => handleModal(false)}
                            isChangeWidget
                            onPickWidget={(widgets) => {
                              handleSwapWidget(widgets, item?.id);
                              handleModal(false);
                            }}
                          />,
                          'right',
                          'mx-auto max-w-5xl'
                        );
                        triggerSegmentEvent({
                          event: 'TR Change Widget Clicked'
                        });
                      }}
                      onRefreshWidget={() => handleRefreshWidget(item)}
                      onConnectWidget={() => handleConnectWidget(item?.id)}
                    />
                  </div>

                  <DynamicModule
                    idWidget={item?.id}
                    widgetSettings={item?.settings || {}}
                    module={item?.name}
                    symbol={item?.symbol}
                    onChangeSymbol={(newSymbol) =>
                      handleChangeSymbolWidget(newSymbol, item)
                    }
                    onExit={() => handleExitWidget(item?.id)}
                    onChangeWidgetSettings={(newSettings) =>
                      handleChangeWidgetSettings(newSettings, item?.id)
                    }
                  />
                </Draggable>
              ))}
            </div>
          </div>
        </div>

        <RenderIf
          isTrue={cmWorkspaceCreated && activeWorkspace?.layout?.length}>
          <div className="zoom-2">
            <CoachMark {...localCoachMarkList[coachMarkWorkspace]} />
          </div>
        </RenderIf>

        <RenderIf isTrue={openListHelp}>
          <Modal onClose={() => setOpenListHelp(false)}>
            <ModalHelp onClose={() => setOpenListHelp(false)} />
          </Modal>
        </RenderIf>
      </div>
    </>
  );
}
