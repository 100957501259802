import RenderIf from 'components/basic/render-if';
import IconSorting from 'components/svg/icon-sorting';
import dayjs from 'dayjs';
import { useTableSorting } from 'hooks/useTableSorting';
import { filter } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useEffect, useState } from 'react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetOrderListQuery } from 'services/rtk-query/trade';
import { currency } from 'utils/format';
import { getColorStatusOrder, getWithdrawStatus } from 'utils/stock/order';

function CashWithdrawalRequestHistory() {
  const { data: orderList, isFetching } = useGetOrderListQuery(null, { refetchOnMountOrArgChange: true });

  const [tempData, setTempData] = useState([]);
  const  { data, sortingColumns, handleSorting, getSorting } = useTableSorting(tempData);

  useEffect(() => {
    if (orderList?.items?.length > 0) {
      const datas = Object.assign([], filter(orderList?.items, ['side', 'WC']));
      setTempData(datas);
    }
  }, [orderList]);

  return (
    <OverlayScrollbarsComponent
      className="overlayscrollbars-host-small -mt-3"
      element="span"
      options={{
        resize: 'horizontal',
        scrollbars: {
          autoHide: 'leave',
          autoHideDelay: 50
        }
      }}
      >
      <table className="text-xs w-full text-center font-quicksand-semibold">
        <thead>
          <tr>
            <td
              className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'dtSave' && 'text-main-base-l'}`}
              onClick={() => handleSorting('dtSave')}>
              <span className="flex items-center justify-between cursor-pointer">
                Time{' '}
                <IconSorting
                  type={getSorting('dtSave')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            <td
              className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'clOID' && 'text-main-base-l'}`}
              onClick={() => handleSorting('clOID')}>
              <span className="flex items-center justify-between cursor-pointer">
                Client&nbsp;Order&nbsp;Id{' '}
                <IconSorting
                  type={getSorting('clOID')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            <td
              className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'qty' && 'text-main-base-l'}`}
              onClick={() => handleSorting('qty')}>
              <span className="flex items-center justify-between cursor-pointer">
                Amount{' '}
                <IconSorting
                  type={getSorting('qty')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            <td
              className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'state' && 'text-main-base-l'}`}
              onClick={() => handleSorting('state')}>
              <span className="flex items-center justify-between cursor-pointer">
                Status{' '}
                <IconSorting
                  type={getSorting('state')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            {/* <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d">Action</td> */}
          </tr>
        </thead>
        <tbody className="text-xs">
          <RenderIf isTrue={data?.length === 0 && !isFetching}>
            <tr className="group">
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                -
              </td>
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                -
              </td>
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                -
              </td>
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                -
              </td>
            </tr>
          </RenderIf>

          <RenderIf isTrue={isFetching}>
            <tr className="group">
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
            </tr>
          </RenderIf>
          {data?.map((item, key) => (
            <tr key={key} className="group">
              <td
                className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 w-40 font-quicksand-regular"
                style={{ minWidth: '120px' }}>
                {dayjs.unix(item?.dtSave / 1000).format('DD/MM/YYYY - HH:mm')}
              </td>
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-2xs">
                {item?.clOID}
              </td>
              <td className="p-2 text-left py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                {currency(item?.qty, { removeSymbol: true })}
              </td>
              <td
                className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 uppercase w-24`}>
                <span
                  className={`${getColorStatusOrder(
                    item?.state
                  )} p-1 opacity-75 inline-block rounded-md text-2xs`}>
                  {getWithdrawStatus(item?.state)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </OverlayScrollbarsComponent>
  );
}

export default CashWithdrawalRequestHistory;
