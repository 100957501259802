import PropTypes from 'prop-types';
import useWindowSize from 'hooks/useWindowSize';
import { useRef } from 'react';
import Chart from 'react-apexcharts';
import { automatedNumberFormat, currency, formatIndonesianKMBT } from 'utils/format';

import './styles/orderbook-chart.css';
import useDarkMode from 'hooks/useDarkMode';

function OrderbookChart({ series = [], categories = [], colors = ['#F16528', '#7BCC29'], totalBidLot, totalAskLot, onClickPrice = () => {} }) {
  const screenSize = useWindowSize();
  const refChart = useRef();
  const refTooltip = useRef();
  const { theme } = useDarkMode();

  const localFormat = (value) => {
    if (!value) return 0;
    let negatif = '';
    if (value < 0) negatif = '-';

    if (Math.abs(value) >= 50000) {
      return negatif + formatIndonesianKMBT(Math.abs(Number(value)).toFixed(2));
    }
    return negatif + currency(Number(value).toFixed(2), { removeSymbol: true });
  };

  const resetTooltip = () => {
    const elMarkers = refChart.current.querySelectorAll('.apexcharts-marker');
    const elCrossHairs = refChart.current.querySelectorAll(".apexcharts-xcrosshairs");
    
    refTooltip.current.style.opacity = 0;
    elCrossHairs.forEach(el => { 
      el.style.opacity = 0;
    });
    
    elMarkers?.forEach((el) => {
      el.style.opacity = 0;
    });
  };

  const chartData = {
    series: series,
    options: {
      //   annotations: annotations,
      chart: {
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: false
        },
        type: 'area',
        height: 164,
        zoom: {
          enabled: false
        },
        animations: {
          enabled: false,
          easing: 'linear',
          speed: 350,
          animateGradually: {
            enabled: false,
            delay: 150
          },
          dynamicAnimation: {
            enabled: false,
            speed: 350
          }
        },
        events: {
          mouseLeave: () => {
            resetTooltip();
          },
          mouseMove: () => {},
          click: (e, chartContext, { dataPointIndex }) => {
            resetTooltip();
            onClickPrice(categories[dataPointIndex])
          },
          mounted: () => {},
          animationEnd: () => {},
        }
      },
      colors: colors,
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        // lineCap: "butt",
        dashArray: [0, 0],
        width: 2,
        curve: 'smooth'
      },
      markers: {
        size: 0,
        colors: colors,
        strokeColors: colors,
        hover: {
          strokeWidth: 2,
          size: 4
        }
      },
      yaxis: {
        show: true,
        opposite: true,
        tickAmount: 4,
        // min: minY,
        // max: maxY,
        forceNiceScale: true,
        labels: {
          show: true,
          offsetX: -4,
          align: 'left',
          style: {
            fontSize: '10px',
            fontFamily: 'GothamRoundedBook, sans-serif'
          },
          formatter: (value) => {
            return localFormat(value);
          }
        }
      },
      xaxis: {
        categories: categories,
        floating: true,
        show: true,
        tickPlacement: 'on',
        tickAmount: 5,
        labels: {
          show: true,
          rotate: 0,
          offsetY: 10,
          rotateAlways: true,
          showAlways: true,
          trim: false,
          hideOverlappingLabels: false,
          formatter: function (value) {
            if (value) {
              return automatedNumberFormat(value);
            }
          }
        },
        tooltip: {
          enabled: false
        },
        axisTicks: {
          show: false
        }
      },
      grid: {
        show: true,
        borderColor: '#272a2d',
        // borderColor: '#E6EEEF',
        strokeDashArray: 0,
        position: 'back',
        yaxis: {
          lines: {
            show: true
          }
        },
        xaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          left: 0,
          right: 0,
          bottom: 0,
          top: 0
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        // eslint-disable-next-line no-unused-vars
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          if (categories[dataPointIndex] === 0) {
            resetTooltip();
            return '';
          }
          const elMarkers = refChart.current.querySelectorAll('.apexcharts-marker');
          const elCrossHairs = refChart.current.querySelectorAll(".apexcharts-xcrosshairs");
          const ctd = refTooltip.current.querySelector(".tooltip-data");
          const ag = refChart.current.querySelector(".apexcharts-grid");

          let price = series[1]?.[dataPointIndex];
          let colorIndex = 1;
          if (!price) {
            colorIndex = 0;
            price = series[0]?.[dataPointIndex]
          }

          ctd.innerHTML = currency(categories[dataPointIndex], { removeSymbol: true }) + ' - ' + currency(price, { removeSymbol: true }) + ' lot';

          let widthTooltip = (ctd.getBoundingClientRect().width / 2);
          let widthAg = ag.getBoundingClientRect().width;
          let widthYg = screenSize.width - widthAg;
          let widthChartArea = screenSize.width - widthYg;

          let position = ((dataPointIndex / (categories.length - 1)) * widthChartArea - widthTooltip);
          if (position <= 0) position = 0;
          if (position >= widthChartArea - widthTooltip) position = widthChartArea - widthTooltip;

          refTooltip.current.style.opacity = 1;
          refTooltip.current.style.left = position + 'px';

          elCrossHairs.forEach(el => { 
            el.style.opacity = 1;
            el.style.stroke = colors[colorIndex];
          });

          elMarkers?.forEach((el) => {
            el.style.opacity = 1;
          });

          return '';
        },
        marker: {
          show: true
        },
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetX: 10,
          offsetY: -30
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0.7,
          stops: [0, 90, 100]
        }
      }
    }
  };

  return (
    <>
      <div className="relative pb-1 dark:text-black-light-1-d overflow-hidden">
        {/* <h5 className='mb-3 text-sm'>Chart Orderbook</h5> */}
        <ul className='flex items-center text-2xs'>
          <li className='flex items-center mr-2 font-quicksand-semibold'>
            <span className='h-2 w-2 rounded-sm mr-1' style={{background: colors[0]}}></span>
            <span>Bid. {currency(totalBidLot, { removeSymbol: true })}</span>
          </li>
          <li className='flex items-center mr-2 font-quicksand-semibold'>
            <span className='h-2 w-2 rounded-sm mr-1' style={{background: colors[1]}}></span>
            <span>Ask. {currency(totalAskLot, { removeSymbol: true })}</span>
          </li>
        </ul>
        
        <div
          ref={refTooltip}
          className="absolute text-2xs top-6 font-quicksand-semibold left-0 w-full z-20 opacity-0">
          <span
            style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)' }}
            className="bg-white dark:bg-black-dark-d py-2 px-3 shadow-pina rounded-md inline-block text-center tooltip-data">
            117 - 10 lot
          </span>
        </div>

        <div ref={refChart} className={`orderbook-chart ${theme === 'dark' && 'dark'}`}>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height="164"
          />
        </div>
      </div>
    </>
  );
}

OrderbookChart.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
  colors: PropTypes.array,
  totalBidLot: PropTypes.number,
  totalAskLot: PropTypes.number,
  onClickPrice: PropTypes.func,
};

export default OrderbookChart;
