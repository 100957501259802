import BreadcrumbNew from 'components/basic/breadcrumb-new';
import { triggerSegmentEvent } from 'lib/segment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import TWOrderlist from '../_partials/tw-orderlist';
import RenderIf from 'components/basic/render-if';
import Card from 'components/basic/card';
import TWOrderlistDoneDetail from '../_partials/tw-orderlist-done-detail/tw-orderlist-done-detail';
import TWOrderlistHistory from '../_partials/tw-orderlist-history';

function PinaProOrderlist() {
  const { pathname } = useLocation();
  const { slug } = useParams();
  const [firstRender, setFirsRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      triggerSegmentEvent({ event: 'TR Orderlist Viewed' });
      setFirsRender(false);
    }
  }, [firstRender]);

  return (
    <div className="p-4 dark:text-black-light-1-d zoom-2">
      <Helmet>
        <title>Daftar Transaksi | Pina Trade</title>
      </Helmet>

      <BreadcrumbNew
        acivePath={pathname}
        features={[
          {
            name: 'Day Order List',
            slug: 'order',
            url: ['/order', '/order/'],
            analyticEventName: 'TR Orderlist Day List Tab Clicked'
          },
          {
            name: 'Day Done Detail',
            slug: 'day-done-detail',
            url: ['/order/day-done-detail'],
            analyticEventName: 'TR Orderlist Day Done Detail Tab Clicked'
          },
          {
            name: 'Riwayat Transaksi',
            slug: 'history',
            url: ['/order/history'],
            analyticEventName: 'TR Orderlist Riwayat Tab Clicked'
          }
        ]}
      />

      <RenderIf isTrue={!slug}>
        <Card className="p-2 pt-4">
          <TWOrderlist
            isZoomOut
            showQueue
            title={<h5 className="mb-0 text-sm">Day Order List</h5>}
            isInline
          />
        </Card>
      </RenderIf>

      <RenderIf isTrue={slug === 'day-done-detail'}>
        <Card className="p-2 pt-4">
          <TWOrderlistDoneDetail
            isZoomOut
            title={<h5 className="mb-0 text-sm p-1 pt-4">Day Done Details</h5>}
          />
        </Card>
      </RenderIf>

      <RenderIf isTrue={slug === 'history'}>
        <TWOrderlistHistory isCardLess isZoomOut />
      </RenderIf>
    </div>
  );
}

export default PinaProOrderlist;
