import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import IconCamera from 'components/svg/icon-camera';
import { useContext, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import ImageFrameKTP from 'assets/images/background/bg-frame-ktp.png';
import ImageFrameKTPMobile from 'assets/images/background/bg-frame-ktp-mobile.png';
import IconClose from 'components/svg/icon-close';
import { ModalContext } from 'components/modal/context/modalContext';
import RenderIf from 'components/basic/render-if';
import IconCheckPlain from 'components/svg/icon-check-plain';

import './css/input-camera.css';
import IconSync from 'components/svg/icon-sync';
import IconChevronSingle from 'components/svg/icon-chevron-single';

function InputCamera({
  header = (
    <>
      <h5 className="text-center text-main-base-l font-quicksand-bold lg:font-quicksand-regular text-xl lg:text-[28px] mb-4">
        Ambil Foto
      </h5>

      <p className="text-xs lg:text-sm text-left lg:text-center mb-6 font-quicksand-semibold">
        Posisikan KTP kamu di dalam kotak dan atur hingga gambar tajam. <br className='hidden lg:block' />
        Pastikan juga foto memiliki pencahayaan yang cukup.
      </p>
    </>
  ),
  onSaveImage = () => {},
  videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user'
  },
  frameCameraDesktop = ImageFrameKTP,
  frameCameraMobile = ImageFrameKTPMobile
}) {
  const { handleModal } = useContext(ModalContext);
  const webcamRef = useRef(null);
  const webcamRefMobile = useRef(null);

  const [image, setImage] = useState(null);
  const [devices, setDevices] = useState([]);
  const [options, setOptions] = useState(videoConstraints);

  const captureImage = () => {
    let imageSrc = null;
    if (webcamRef?.current) {
      let base64 = webcamRef.current.getScreenshot();
      if (base64 != 'data:,') imageSrc = base64;
    }
    
    if (webcamRefMobile?.current) {
      let base64 = webcamRefMobile.current.getScreenshot();
      if (base64 != 'data:,') imageSrc = base64;
    }

    if (imageSrc) setImage(imageSrc);
  }

  const handleCameraFacing = () => {
    if (options?.facingMode === 'user') {
      setOptions({...options, facingMode: { exact: "environment" } })
    } else {
      setOptions(videoConstraints);
    }
  }

  useEffect(() => {
    async function getDevices() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
    }

    getDevices();
  }, []);

  return (
    <>
      <Card className="p-6 lg:p-10 lg:mx-auto relative mx-6 hidden lg:block">
        <button
          type="button"
          className="h-6 w-6 absolute right-3 top-3 lg:right-6 lg:top-6 hover:opacity-75"
          onClick={() => handleModal(false)}>
          <IconClose />
        </button>

        {header}

        <div className={`${image && 'shutter-click'}`}>
          <RenderIf isTrue={!image && devices?.length > 0}>
            <div className="relative mb-4 rounded-2xl overflow-hidden aspect-[1280/720]">
              <Webcam
                className='input-camera'
                ref={webcamRef}
                audio={false}
                height={720}
                screenshotFormat="image/png"
                width={1280}
                videoConstraints={options}
              />

              <img
                src={frameCameraDesktop}
                className="h-full w-full absolute z-10 left-0 top-0 rounded-2xl overflow-hidden"
                alt="Frame Camera"
              />
            </div>

            <div className='flex justify-center'>
              <button
                onClick={handleCameraFacing}
                type="button"
                className="h-10 w-10 lg:h-14 lg:w-14 mt-5 mx-3 bg-main-base-l flex items-center justify-center rounded-full cursor-pointer hover:opacity-75 lg:hidden">
                <IconSync className="text-white h-4 w-4 lg:h-5 lg:w-5" />
              </button>

              <button
                onClick={captureImage}
                type="button"
                className="h-10 w-10 lg:h-14 lg:w-14 mt-5 mx-3 bg-main-base-l flex items-center justify-center rounded-full cursor-pointer hover:opacity-75">
                <IconCamera className="text-white h-5 w-5 lg:h-8 lg:w-8" />
              </button>
            </div>
          </RenderIf>

          <RenderIf isTrue={image}>
            <div className="relative rounded-2xl overflow-hidden aspect-[1280/720]">
              <img className="h-full w-full object-cover object-left-top" src={image} alt="Camera Result" />
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => setImage(null)}
                type="button"
                className="h-10 w-10 lg:h-14 lg:w-14 mt-5 bg-white border-2 border-main-base-l flex items-center justify-center rounded-full mx-3 cursor-pointer hover:opacity-75">
                <IconClose className="text-main-base-l h-4 w-4 lg:h-5 lg:w-5" />
              </button>

              <button
                onClick={() => {
                  onSaveImage(image);
                  handleModal(false);
                }}
                type="button"
                className="h-10 w-10 lg:h-14 lg:w-14 mt-5 bg-main-base-l flex items-center justify-center rounded-full mx-3 cursor-pointer hover:opacity-75">
                <IconCheckPlain className="text-white h-8 w-8 lg:h-12 lg:w-12" />
              </button>
            </div>
          </RenderIf>
        </div>
      </Card>

      <div className={`fixed h-screen lg:hidden w-screen bg-white -mt-5 ${image && 'shutter-click'}`}>

        <RenderIf isTrue={!image}>
          <Webcam
            className='input-camera'
            ref={webcamRefMobile}
            audio={false}
            height={720}
            screenshotFormat="image/png"
            width={1280}
            videoConstraints={options}
          />
          <img className='absolute h-auto w-full aspect-[688/1075] left-0 top-0' src={frameCameraMobile} alt="frame camera"/>
        </RenderIf>

        <RenderIf isTrue={image}>
          <img className='absolute h-auto w-full aspect-[688/1075] left-0 top-0 object-cover object-center' src={image} alt="Camera Result"/>
        </RenderIf>

        <div className='absolute w-full bottom-0 p-6 pb-32 bg-white rounded-t-3xl z-10'>
          {header}
          <div className='flex justify-between items-center border-t'>

            <RenderIf isTrue={!image}>
              <div>
                <button onClick={() => handleModal(false)} type='button' className="h-12 w-12 mt-5 mx-3 border-2 border-main-base-l flex items-center justify-center rounded-full cursor-pointer hover:opacity-75 lg:hidden">
                  <IconChevronSingle className="text-main-base-l h-5 w-5" />
                </button>
              </div>

              <button
                onClick={captureImage}
                type="button"
                className="h-16 w-16 mt-5 mx-3 bg-main-base-l flex items-center justify-center rounded-full cursor-pointer hover:opacity-75">
                <IconCamera className="text-white h-7 w-7" />
              </button>

              <button
                onClick={handleCameraFacing}
                type="button"
                className="h-12 w-12 mt-5 mx-3 border-2 border-main-base-l flex items-center justify-center rounded-full cursor-pointer hover:opacity-75 lg:hidden">
                <IconSync className="text-main-base-l h-5 w-5" />
              </button>
            </RenderIf>

            <RenderIf isTrue={image}>
              <button type='button' className="opacity-0 h-12 w-12 mt-5 mx-3 border-2 border-main-base-l flex items-center justify-center rounded-full cursor-pointer hover:opacity-75 lg:hidden">
                <IconChevronSingle className="text-main-base-l h-5 w-5" />
              </button>

              <button
                onClick={() => setImage(null)}
                type="button"
                className="h-16 w-16 mt-5 mx-3 border-2 bg-main-base-l flex items-center justify-center rounded-full cursor-pointer hover:opacity-75 lg:hidden">
                <IconClose className="text-white h-7 w-7" />
              </button>

              <button
                onClick={() => {
                  onSaveImage(image);
                  handleModal(false);
                }}
                type="button"
                className="h-12 w-12 mt-5 mx-3 border-2 bg-white border-main-base-l flex items-center justify-center rounded-full cursor-pointer hover:opacity-75 lg:hidden">
                <IconCheckPlain className="text-main-base-l h-14 w-14" />
              </button>
            </RenderIf>

          </div>
        </div>
      </div>
    </>
  );
}

InputCamera.propTypes = {
  onSaveImage: PropTypes.func,
  videoConstraints: PropTypes.object,
  header: PropTypes.node,
  frameCameraDesktop: PropTypes.any,
  frameCameraMobile: PropTypes.any,
};

export default InputCamera;
