// eslint-disable-next-line react/prop-types
function IconIdeas({ className }) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1162 27.2754H22.8862V30.1987C22.8862 31.8132 21.5774 33.1221 19.9629 33.1221H17.0395C15.425 33.1221 14.1162 31.8132 14.1162 30.1987V27.2754Z" />
      <path d="M16.6114 16.0101C16.0406 15.4393 15.1151 15.4393 14.5443 16.0101C13.9735 16.581 13.9735 17.5064 14.5443 18.0773L17.0395 20.5725V30.1987H19.9629V20.5725L22.4581 18.0773C23.0289 17.5064 23.0289 16.581 22.4581 16.0101C21.8873 15.4393 20.9618 15.4393 20.391 16.0101L18.5012 17.8999L16.6114 16.0101Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8864 23.3074L23.9813 22.4297C25.9922 20.8177 27.2714 18.3502 27.2714 15.582C27.2714 10.7385 23.345 6.81201 18.5014 6.81201C13.6579 6.81201 9.73144 10.7385 9.73144 15.582C9.73144 18.3502 11.0106 20.8177 13.0216 22.4297L14.1164 23.3074V25.8137C14.1164 26.6209 14.7709 27.2753 15.5781 27.2753H21.4248C22.232 27.2753 22.8864 26.6209 22.8864 25.8137V23.3074ZM25.8098 24.7107V25.8137C25.8098 28.2354 23.8465 30.1987 21.4248 30.1987H15.5781C13.1563 30.1987 11.1931 28.2354 11.1931 25.8137V24.7107C8.51983 22.5677 6.80811 19.2747 6.80811 15.582C6.80811 9.12396 12.0434 3.88867 18.5014 3.88867C24.9595 3.88867 30.1948 9.12396 30.1948 15.582C30.1948 19.2747 28.4831 22.5677 25.8098 24.7107Z"
      />
    </svg>
  );
}

export default IconIdeas;
