import PropTypes from 'prop-types';
import Card from 'components/basic/card';

import MascotFirstTimeRDN from 'assets/images/mascot/mascot-first-time-rdn.jpeg';

function ModalFirstTimeExplore({ onClose = () => {} }) {
  return (
    <Card className="p-5 max-w-[400px] md:mx-auto relative mx-4 dark:text-black-light-1-d dark:border dark:border-gray-base-d">
      <div className="text-center">
        <img
          src={MascotFirstTimeRDN}
          alt="first time rdn"
          className="w-full h-auto aspect-square mb-4 rounded-md"
        />

        <h5 className="text-lg lg:text-2xl text-center font-quicksand-semibold mb-2">
          Selamat Datang di PINA Trade!
        </h5>

        <p className="text-gray-light-0-d text-xs lg:text-base mb-2">
          Platform yang didedikasikan untuk memberikan pengalaman trading yang mudah, dan canggih. 
          <br/>
          <br/>
          Jelajahi fitur-fitur kami, termasuk <b className='text-gray-dark-d dark:text-black-light-0-d'>workspace</b>, <b className='text-gray-dark-d dark:text-black-light-0-d'>analisis teknikal</b>, <b className='text-gray-dark-d dark:text-black-light-0-d'>fundamental</b>, <b className='text-gray-dark-d dark:text-black-light-0-d'>chart interaktif</b>, dan <b className='text-gray-dark-d dark:text-black-light-0-d'>berita terkini</b>.
        </p>
      </div>

      <button
        type="button"
        onClick={onClose}
        className="px-4 py-3 text-center text-white dark:text-black-base-d bg-main-base-l rounded-xl w-full mt-6 text-sm hover:opacity-75 transition-all ease-in-out duration-150 font-quicksand-semibold">
        Mulai Menjelejah PINA Trade
      </button>
    </Card>
  );
}

ModalFirstTimeExplore.propTypes = {
  onClose: PropTypes.func
};

export default ModalFirstTimeExplore;
