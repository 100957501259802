import PropTypes from 'prop-types';

function IconClose({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7929 12.7929C13.1834 12.4024 13.8166 12.4024 14.2071 12.7929L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12.7929 14.2071C12.4024 13.8166 12.4024 13.1834 12.7929 12.7929Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289L14.2071 12.7929C14.5976 13.1834 14.5976 13.8166 14.2071 14.2071C13.8166 14.5976 13.1834 14.5976 12.7929 14.2071L7.29289 8.70711C6.90237 8.31658 6.90237 7.68342 7.29289 7.29289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L11.2071 9.79289C11.5976 10.1834 11.5976 10.8166 11.2071 11.2071C10.8166 11.5976 10.1834 11.5976 9.79289 11.2071L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2071 12.7929C10.8166 12.4024 10.1834 12.4024 9.79289 12.7929L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L11.2071 14.2071C11.5976 13.8166 11.5976 13.1834 11.2071 12.7929Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L9.79289 12.7929C9.40237 13.1834 9.40237 13.8166 9.79289 14.2071C10.1834 14.5976 10.8166 14.5976 11.2071 14.2071L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L12.7929 9.79289C12.4024 10.1834 12.4024 10.8166 12.7929 11.2071C13.1834 11.5976 13.8166 11.5976 14.2071 11.2071L19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289Z"
      />
    </svg>
  );
}

IconClose.propTypes = {
  className: PropTypes.string
};

export default IconClose;