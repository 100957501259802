import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import InputPin from 'components/input/input-pin';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetLoginPin, setLoginPin } from 'store/trading';
import { LoginPinSchema } from './login-pin.form.validation';
import { useCookies } from 'react-cookie';
import useToastr from 'hooks/useToastr'
import { useLoginPINMutation } from 'services/rtk-query/trading';
import IconLock from 'components/svg/icon-lock';
import ForgotPinForm from '../forgot-pin-form';
import SimpleSpinner from 'components/common/spinner/spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IconInfo from 'components/svg/icon-info';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import Modal from 'components/modal/modal';
import useBrowserTabTrading from 'hooks/useBrowserTabTrading';

function LoginPinForm({ onSuccess = () => {}, onCancel = () => {}, isConfirmation = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();

  const [loginPin] = useLoginPINMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isLoginPin: isLoginTradingPin, isRefreshPin } = useBrowserTabTrading();
  const [isLoginPin, setIsLoginPin] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [, setCookie] = useCookies(['isValidPin']);

  const formik = useFormik({
    initialValues: {
      digitOne: '',
      digitTwo: '',
      digitThree: '',
      digitFour: ''
    },
    validateOnMount: true,
    validationSchema: LoginPinSchema,
    onSubmit: (values) => {
      handleSubmit(
        `${values.digitOne}${values.digitTwo}${values.digitThree}${values.digitFour}`
      );
    }
  });

  const handleSubmit = async (pin) => {
    setIsSubmitting(true);
    toastr.dismiss();
    try {
      await loginPin({ pin }).unwrap();
      dispatch(setLoginPin());
      onSuccess(pin);
      setCookie('isValidPin', true, { path: '/', maxAge: 7200 });
      // toastr.success("Login Pin Berhasil");
      formik.resetForm();
    } catch (error) {
      if (!isConfirmation) dispatch(resetLoginPin());
      if (error?.data?.message) getErrorMessage(error);
      formik.resetForm();
      let nextEl = document.getElementsByName('digitOne');
      nextEl?.[0]?.focus();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e, index) => {
    let isBackSpace = false;
    if (e.target.value === '') {
      index = index - 1;
      isBackSpace = true;
    }

    let nextEl = null;
    if (isBackSpace) {
      if (index === 0) {
        nextEl = document.getElementsByName('digitOne');
      } else if (index === 1) {
        nextEl = document.getElementsByName('digitTwo');
      } else if (index === 2) {
        nextEl = document.getElementsByName('digitThree');
      }
    } else {
      if (index === 0) {
        nextEl = document.getElementsByName('digitTwo');
      } else if (index === 1) {
        nextEl = document.getElementsByName('digitThree');
      } else if (index === 2) {
        nextEl = document.getElementsByName('digitFour');
      }
    }

    if (nextEl) {
      nextEl[0].focus();
    }

    if (index === 3) {
      nextEl = document.getElementsByName('digitFour');
      nextEl[0].blur();
      handleSubmit(`${formik.values.digitOne}${formik.values.digitTwo}${formik.values.digitThree}${e.target.value}`)
    }

    formik.handleChange(e);
  };

  const handleKeyDown = (e, index) => {
    let nextEl = null;

    if (e.key === 'Enter' && formik.isValid) {
      return handleSubmit(`${formik.values.digitOne}${formik.values.digitTwo}${formik.values.digitThree}${formik.values.digitFour}`);
    }

    if (e.key === 'Backspace' && e.target.value === '') {
      if (index === 1) {
        nextEl = document.getElementsByName('digitOne');
      } else if (index === 2) {
        nextEl = document.getElementsByName('digitTwo');
      } else if (index === 3) {
        nextEl = document.getElementsByName('digitThree');
      }
    }

    if (nextEl) {
      nextEl[0].focus();
    }

    if (e.target.value.length > 0 && e.key != 'Backspace') {
      if (index === 0) {
        nextEl = document.getElementsByName('digitTwo');
      } else if (index === 1) {
        nextEl = document.getElementsByName('digitThree');
      } else if (index === 2) {
        nextEl = document.getElementsByName('digitFour');
      }

      if (nextEl) {
        nextEl[0].focus();
      }

      e.preventDefault();
    }

    const patt = /^\d+\.?\d*$/;
    if (!patt.test(e.key) && e.key != 'Backspace') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isConfirmation) {
      setIsLoginPin(false);
    } else {
      setIsLoginPin(isLoginTradingPin);
    }
  }, [isLoginTradingPin, isConfirmation]);

  useEffect(() => {
    const el = document.getElementsByTagName('body');
    if (!isLoginPin && el.length > 0) {
      el[0].classList.add('overflow-hidden');
      el[0].classList.remove('overflow-auto');
    } else {
      el[0].classList.add('overflow-auto');
      el[0].classList.remove('overflow-hidden');
    }
  }, [isLoginPin]);

  useEffect(() => {
    if (!isLoginPin) setFirstRender(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginPin, searchParams]);

  useEffect(() => {
    if (firstRender && !isLoginPin) {
      setTimeout(() => {
        let nextEl = document.getElementsByName('digitOne');
        nextEl?.[0]?.focus();
        setFirstRender(false);
      }, 1000);
    }
  }, [firstRender, isLoginPin]);

  if (isLoginPin) {
    return '';
  }

  if (!isLoginPin && searchParams.get('forgot-pin')) {
    return (
      <Modal>
        <ForgotPinForm onClose={() => navigate('', { replace: true })} />
      </Modal>
    );
  }

  return (
    <Modal>
      <Card className="w-full py-10 dark:bg-black-dark-d dark:border-gray-base-d dark:border max-w-[400px] mx-auto">
        <div className="px-6 lg:px-10">
          <h5 className="text-2xl text-center mb-3 dark:text-black-light-1-d">Masukkan PIN Kamu</h5>

          { isRefreshPin &&
            <div className='p-3 bg-main-light-1-d text-black-base-d font-quicksand-semibold text-xs lg:text-sm my-4 rounded-lg flex items-center justify-center'>
              <IconInfo className='h-5 w-5 mr-1'/> Sesi login PIN kamu sudah berakhir..
            </div>
          }
          
          <p className="text-center flex justify-center text-main-base-l my-3">
            {!isSubmitting ? <IconLock className='h-8 w-8' /> : <SimpleSpinner className='text-main-base-l h-8 w-8' />}
          </p>

          <form className="mt-5" onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-4 mb-5 max-w-[350px] mx-auto">
              <InputPin
                data-qa-id="txtPinField"
                name="digitOne"
                className="text-2xl text-center"
                value={formik.values.digitOne}
                onBlur={formik.handleBlur}
                onKeyDown={(e) => handleKeyDown(e, 0)}
                onChange={(e) => handleChange(e, 0)}
                autoComplete={false}
                pattern="[0-9]*"
                inputMode="numeric"
                inputStyle="circle"
              />
              <InputPin
                data-qa-id="txtPinField"
                name="digitTwo"
                className="text-2xl text-center"
                value={formik.values.digitTwo}
                onBlur={formik.handleBlur}
                onKeyDown={(e) => handleKeyDown(e, 1)}
                autoComplete={false}
                pattern="[0-9]*"
                inputMode="numeric"
                inputStyle="circle"
                onChange={(e) => handleChange(e, 1)}
              />
              <InputPin
                data-qa-id="txtPinField"
                name="digitThree"
                className="text-2xl text-center"
                value={formik.values.digitThree}
                onBlur={formik.handleBlur}
                onKeyDown={(e) => handleKeyDown(e, 2)}
                autoComplete={false}
                pattern="[0-9]*"
                inputMode="numeric"
                inputStyle="circle"
                onChange={(e) => handleChange(e, 2)}
              />
              <InputPin
                data-qa-id="txtPinField"
                name="digitFour"
                className="text-2xl text-center"
                value={formik.values.digitFour}
                onBlur={formik.handleBlur}
                onKeyDown={(e) => handleKeyDown(e, 3)}
                autoComplete={false}
                pattern="[0-9]*"
                inputMode="numeric"
                inputStyle="circle"
                onChange={(e) => handleChange(e, 3)}
              />
            </div>

            <div className='max-w-[350px] mx-auto mt-4'>
              <a
                onClick={() => navigate('?forgot-pin=1', { replace: true })}
                role="button"
                className="text-main-base-l text-center w-full block text-sm">
                Lupa PIN ?
              </a>
            </div>
          </form>
        </div>
      </Card>
    </Modal>
  );
}

LoginPinForm.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  isConfirmation: PropTypes.bool
};

export default LoginPinForm;
