import RenderIf from 'components/basic/render-if';
import SimpleSpinner from 'components/common/spinner/spinner';
import InputStock from 'components/input/input-stock';
import IconClose from 'components/svg/icon-close';
import IconSearch from 'components/svg/icon-search';
import IconTrashCan from 'components/svg/icon-trash-can';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

function StockSearch({
  item,
  _key,
  onHandlePickStock = () => {},
  onClearStock = () => {},
  onRemoveColumn = () => {}
}) {
  const { watchStockList } = useSelector(({ pinaPro }) => pinaPro);
  const [loadingSearch, setLoadingSearch] = useState(false);

  return (
    <>
      <InputStock
        showRemarkInput={false}
        isClearable={true}
        value={item?.code}
        maxHeight="500px"
        placeholder="Cari Saham"
        onChange={onHandlePickStock}
        excludeStocks={watchStockList}
        className={`customize-input-stock--small w-full ${
          _key === watchStockList?.length - 1 && 'right'
        }`}
        label={false}
        showPriceStock={false}
        iconRight={
          <>
            {loadingSearch ? (
              <SimpleSpinner className="right-2 absolute h-4 w-4 text-main-base-l top-1" />
            ) : (
              !item?.code && (
                <IconSearch className="right-2 absolute w-4 h-4 transform -translate-y-1/2 top-1/2 text-main-base-l" />
              )
            )}
          </>
        }
        onLoading={setLoadingSearch}
      />

      <RenderIf isTrue={item?.code && !loadingSearch}>
        <button
          type="button"
          onClick={onClearStock}
          className="text-main-base-l hover:opacity-75 transition-all ease-linear duration-155 mr-1">
          <IconClose className="h-4 w-4" />
        </button>
      </RenderIf>

      <RenderIf isTrue={_key > 2}>
        <button
          type="button"
          onClick={onRemoveColumn}
          className="text-main-base-l hover:opacity-75 transition-all ease-linear duration-155">
          <IconTrashCan className="h-4 w-4" />
        </button>
      </RenderIf>
    </>
  );
}

StockSearch.propTypes = {
  item: PropTypes.object,
  _key: PropTypes.any,
  onHandlePickStock: PropTypes.func,
  onClearStock: PropTypes.func,
  onRemoveColumn: PropTypes.func
};

export default StockSearch;
