import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSSE from './useSSE';
import { useGetStockByCodeQuery } from 'services/rtk-query/stock';
import useBrowserWakeUp from './useBrowserWakeUp';

const useRealtimeStock = (code, isRealtime = true) => {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const [isRefetch, setIsRefetch] = useState(isBursaOpen);
  const [tempCode, setTempCode] = useState(code);
  const isBrowserWakeUp = useBrowserWakeUp();
  const [tempBrowserWakeUp, setTempBrowserWakeUp] = useState(false);

  const { data: resStockDetail, isFetching: isLoadingStock, refetch } = useGetStockByCodeQuery(code);
  const [stockDetail, setStockDetail] = useState(null);
  const resSSE = useSSE(isBursaOpen && isRealtime, tempCode && `ticker?stock[]=${tempCode}`);

  useEffect(() => {
    if (resStockDetail) setStockDetail({...resStockDetail, scorePrice: {...(resStockDetail?.scorePrice || resStockDetail?.score), }});

    if (resSSE[tempCode] && stockDetail) {
      let response = resSSE[tempCode];
      let updateObj = {};

      Object.keys(response)?.map((key) => updateObj[key] = Number(response[key]));

      if (!isNaN(Number(response['lastTradedPrice']))) {
        updateObj.score = {
          type: updateObj?.lastTradedPrice > stockDetail?.previousLastTradedPrice ? 'plus' : updateObj?.lastTradedPrice < stockDetail?.previousLastTradedPrice ? 'minus' : 'neutral',
          value: ((updateObj?.lastTradedPrice - stockDetail?.previousLastTradedPrice) / stockDetail?.previousLastTradedPrice) * 100,
          diff: updateObj?.lastTradedPrice - stockDetail?.previousLastTradedPrice
        };

        updateObj.scorePrice = updateObj?.lastTradedPrice > stockDetail?.lastTradedPrice ? 'plus' : updateObj?.lastTradedPrice < stockDetail?.lastTradedPrice ? 'minus' : 'neutral';
      }

      setStockDetail({ ...stockDetail, ...updateObj, isWatched: resStockDetail?.isWatched });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resStockDetail, resSSE, tempCode]);

  useEffect(() => {
    if (!isRefetch && isBursaOpen) {
      setIsRefetch(true);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch, isBursaOpen]);

  useEffect(() => {
    if (tempCode != code) {
      setTempCode(code);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, tempCode]);

  useEffect(() => {
    if (isBrowserWakeUp && tempBrowserWakeUp) {
      setTempBrowserWakeUp(false);
      refetch();
    }
  }, [isBrowserWakeUp, tempBrowserWakeUp]);

  useEffect(() => {
    if (!isBrowserWakeUp) {
      setTempBrowserWakeUp(true);
    };
  }, [isBrowserWakeUp]);

  return { stockDetail , isLoadingStock};
};

export { useRealtimeStock };
