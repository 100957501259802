import PropTypes from 'prop-types';

function IconCompare({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3123 3.36914H11.355C11.6474 3.36913 11.8997 3.36913 12.1086 3.38175C12.3285 3.39504 12.5484 3.42428 12.7657 3.50402C13.2557 3.68387 13.6451 4.02882 13.8481 4.463C13.9381 4.6555 13.9711 4.85037 13.9861 5.04518C14.0003 5.2302 14.0003 5.45376 14.0003 5.71285V11.6571C14.0003 11.9162 14.0003 12.1398 13.9861 12.3248C13.9711 12.5196 13.9381 12.7145 13.8481 12.907C13.6451 13.3412 13.2557 13.6861 12.7657 13.866C12.5484 13.9457 12.3285 13.9749 12.1086 13.9882C11.8997 14.0009 11.6474 14.0008 11.355 14.0008H11.3123C11.0199 14.0008 10.7676 14.0009 10.5587 13.9882C10.3389 13.9749 10.1189 13.9457 9.90163 13.866C9.41157 13.6861 9.02222 13.3412 8.81923 12.907C8.72923 12.7145 8.69623 12.5196 8.68123 12.3248C8.66698 12.1398 8.66698 11.9162 8.66699 11.6571V5.71285C8.66698 5.45376 8.66698 5.23021 8.68123 5.04518C8.69623 4.85037 8.72923 4.6555 8.81923 4.463C9.02222 4.02882 9.41157 3.68387 9.90163 3.50402C10.1189 3.42428 10.3389 3.39504 10.5587 3.38175C10.7676 3.36913 11.0199 3.36913 11.3123 3.36914ZM10.6495 4.56031C10.4975 4.5695 10.4396 4.58522 10.4119 4.5954C10.2485 4.65535 10.1187 4.77033 10.0511 4.91506C10.0396 4.93964 10.0218 4.99093 10.0115 5.12559C10.0007 5.26556 10.0003 5.44845 10.0003 5.73174V11.6382C10.0003 11.9215 10.0007 12.1044 10.0115 12.2444C10.0218 12.3791 10.0396 12.4303 10.0511 12.4549C10.1187 12.5996 10.2485 12.7146 10.4119 12.7746C10.4396 12.7848 10.4975 12.8005 10.6495 12.8097C10.8075 12.8192 11.0139 12.8195 11.3337 12.8195C11.6534 12.8195 11.8598 12.8192 12.0178 12.8097C12.1698 12.8005 12.2277 12.7848 12.2554 12.7746C12.4188 12.7146 12.5486 12.5996 12.6162 12.4549C12.6277 12.4303 12.6455 12.3791 12.6559 12.2444C12.6666 12.1044 12.667 11.9215 12.667 11.6382V5.73174C12.667 5.44845 12.6666 5.26556 12.6559 5.12559C12.6455 4.99093 12.6277 4.93964 12.6162 4.91506C12.5486 4.77033 12.4188 4.65535 12.2554 4.5954C12.2277 4.58522 12.1698 4.5695 12.0178 4.56031C11.8598 4.55076 11.6534 4.55044 11.3337 4.55044C11.0139 4.55044 10.8075 4.55076 10.6495 4.56031Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64535 2H4.68799C4.98042 1.99999 5.23275 1.99999 5.44159 2.01261C5.66148 2.0259 5.88142 2.05514 6.0987 2.13488C6.58876 2.31472 6.97811 2.65968 7.18109 3.09385C7.2711 3.28636 7.3041 3.48123 7.3191 3.67604C7.33335 3.86106 7.33334 4.08462 7.33333 4.34371V10.288C7.33334 10.5471 7.33335 10.7706 7.3191 10.9557C7.3041 11.1505 7.2711 11.3453 7.18109 11.5378C6.97811 11.972 6.58876 12.317 6.0987 12.4968C5.88142 12.5766 5.66148 12.6058 5.44159 12.6191C5.23275 12.6317 4.98043 12.6317 4.68799 12.6317H4.64534C4.35291 12.6317 4.10058 12.6317 3.89175 12.6191C3.67186 12.6058 3.45192 12.5766 3.23463 12.4968C2.74458 12.317 2.35523 11.972 2.15224 11.5378C2.06224 11.3453 2.02924 11.1505 2.01423 10.9557C1.99998 10.7706 1.99999 10.5471 2 10.288V4.34371C1.99999 4.08462 1.99998 3.86107 2.01423 3.67604C2.02924 3.48123 2.06224 3.28636 2.15224 3.09385C2.35523 2.65968 2.74458 2.31472 3.23463 2.13488C3.45192 2.05514 3.67186 2.0259 3.89175 2.01261C4.10058 1.99999 4.35291 1.99999 4.64535 2ZM3.98251 3.19117C3.83051 3.20036 3.77262 3.21608 3.74488 3.22626C3.58153 3.28621 3.45174 3.40119 3.38408 3.54592C3.37259 3.5705 3.35485 3.62178 3.34447 3.75645C3.3337 3.89642 3.33333 4.07931 3.33333 4.3626V10.2691C3.33333 10.5524 3.3337 10.7353 3.34447 10.8752C3.35485 11.0099 3.37259 11.0612 3.38408 11.0858C3.45174 11.2305 3.58153 11.3455 3.74488 11.4054C3.77262 11.4156 3.83051 11.4313 3.98251 11.4405C4.14049 11.4501 4.34692 11.4504 4.66667 11.4504C4.98642 11.4504 5.19284 11.4501 5.35083 11.4405C5.50283 11.4313 5.56071 11.4156 5.58846 11.4054C5.75181 11.3455 5.88159 11.2305 5.94925 11.0858C5.96075 11.0612 5.97849 11.0099 5.98886 10.8752C5.99964 10.7353 6 10.5524 6 10.2691V4.3626C6 4.07931 5.99964 3.89642 5.98886 3.75645C5.97849 3.62178 5.96075 3.5705 5.94925 3.54592C5.88159 3.40119 5.75181 3.28621 5.58846 3.22626C5.56071 3.21608 5.50283 3.20036 5.35083 3.19117C5.19284 3.18162 4.98642 3.1813 4.66667 3.1813C4.34692 3.1813 4.14049 3.18162 3.98251 3.19117Z"
      />
    </svg>
  );
}

IconCompare.propTypes = {
  className: PropTypes.string
};

export default IconCompare;
