import IconOrderedList from 'components/svg/editor/ordered-list';
import IconBank from 'components/svg/icon-bank';
import IconChartPieHover from 'components/svg/icon-chart-pie-hover';
import IconCompare from 'components/svg/icon-compare';
import IconDarkMode from 'components/svg/icon-dark-mode';
import IconFontSize from 'components/svg/icon-font-size';
import IconGrid from 'components/svg/icon-grid';
import IconIdeas from 'components/svg/icon-ideas';
import IconListBox from 'components/svg/icon-list-box';

const Menus = [
  {
    name: 'Workspace',
    slug: 'workspace',
    url: '/workspace',
    icon: <IconGrid className="h-5 w-5" />,
    children: [],
    analyticEventName: 'TR Open Workspace Page',
  },
  {
    name: 'Market',
    slug: 'stock-market',
    url: '/stock-market',
    icon: <IconBank className="h-5 w-5" />,
    children: [
      {
        name: 'Stock Market',
        slug: '',
        url: '',
        icon: false,
        children: [],
        analyticEventName: 'TR Open Stock Market Page',
      },
      {
        name: 'Stock Screener',
        slug: 'stock-screener',
        url: '/stock-market/screener',
        icon: false,
        children: [],
        analyticEventName: 'TR Open Stock Screener Page',
      },
    ],
    analyticEventName: 'TR Open Stock Market Page',
  },
  {
    name: 'Ideas',
    slug: 'ideas',
    url: '/ideas',
    icon: <IconIdeas className="h-5 w-5" />,
    children: [],
    analyticEventName: 'TR Open Ideas Page',
  },
  {
    name: 'Planner',
    slug: 'auto-trading-planner',
    url: '/auto-trading-planner',
    icon: <IconListBox className="h-5 w-5" />,
    children: [],
    analyticEventName: 'TR Open Planner Page',
  },
  {
    name: 'Compare',
    slug: 'compare',
    url: '/compare/fundamental',
    icon: <IconCompare className="h-5 w-5" />,
    children: [],
    analyticEventName: 'TR Open Compare Page',
  },
  {
    name: 'Portfolio',
    slug: 'portfolio',
    url: '/portfolio',
    icon: <IconChartPieHover className="h-5 w-5" />,
    children: [],
    analyticEventName: 'TR Open Portfolio Page',
  },
  {
    name: 'Orderlist',
    slug: 'order',
    url: '/order',
    icon: <IconOrderedList className="h-5 w-5" />,
    children: [],
    analyticEventName: 'TR Open Orderlist Page',
  },
  // {
  //   name: 'Font Size',
  //   slug: 'font-size',
  //   url: '',
  //   icon: <IconFontSize className="h-5 w-5" />,
  //   children: [],
  //   analyticEventName: 'TR Toggle Font Size',
  // },
  // {
  //   name: 'Dark Mode',
  //   slug: 'theme',
  //   url: '',
  //   icon: <IconDarkMode className="h-5 w-5" />,
  //   children: [],
  //   analyticEventName: 'TR Toggle Theme',
  // },
];

export default Menus;
