import { toast } from 'react-toastify'
import IconToastrSuccess from 'components/svg/icon-toastr-success'
import IconToastrError from 'components/svg/icon-toastr-error'


/**
 * Wrapper for react-toastify
 * with default customization options
 */
const useToastr = () => {

  let customOptions = {
    position: "top-right",
  }

  const success = (text = "Berhasil", options) => {
    toast.dismiss();
    return toast.success(text, { ...options, ...customOptions, icon: IconToastrSuccess });
  }

  const error = (text = "Gagal", options) => {
    toast.dismiss();
    return toast.error(text, { ...options, ...customOptions, icon: IconToastrError });
  }

  const warn = (text = "Warning", options) => {
    toast.dismiss();
    return toast.warn(text, { ...options, ...customOptions });
  }

  const info = (text = "Info", options) => {
    toast.dismiss();
    return toast.info(text, { ...options, ...customOptions });
  }

  const dismiss = () => toast.dismiss();


  return { success, error, warn, info, dismiss };
};

export default useToastr;
