import cn from 'utils/classname';
import IconClose from './svg/icon-close';
import IconGrab from './svg/icon-grab';
import IconRefresh from './svg/icon-refresh';
import IconWidgetList from './svg/icon-widget-list';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { useRef } from 'react';

export default function HeaderWidget({
  title = '',
  isConnectSymbol = false,
  onExitWidget = () => {},
  onSwapWidget = () => {},
  onRefreshWidget = () => {},
  onConnectWidget = () => {}
}) {
  const ref = useRef();

  useKeyboardShortcut({
    keys: ['®', 'r'],
    combine: 'altKey',
    onKeyPressed: () => {
      onRefreshWidget();
    }
  });

  useKeyboardShortcut({
    keys: ['≈', 'x'],
    combine: 'altKey',
    onKeyPressed: () => {
      if (ref.current?.parentNode?.parentNode?.parentNode?.classList?.contains('z-10')) {
        onExitWidget();
      }
    }
  });

  return (
    <div ref={ref} className="flex p-2 justify-between cursor-move">
      <div className="flex-auto flex gap-2 items-center">
        <IconGrab className="h-[12px] w-auto text-main-base-d flex-none" />
        <h5 className="flex-auto text-xs line-clamp-1 dark:text-black-light-0-d font-quicksand-regular">
          {title}
        </h5>
      </div>

      <div className="flex-none flex items-center">
        <button
          onClick={onConnectWidget}
          type="button"
          className="appearance-none p-1 text-main-base-d hover:opacity-75">
          <span
            className={cn(
              'h-[12px] w-[12px] bg-gray-dark-0-l/50 block rounded-full',
              isConnectSymbol && '!bg-green-base-l'
            )}
          />
        </button>

        <button
          onClick={onRefreshWidget}
          type="button"
          className="appearance-none p-1 text-main-base-d hover:opacity-75">
          <IconRefresh className="h-[14px] w-[14px]" />
        </button>

        <button
          onClick={onSwapWidget}
          type="button"
          className="appearance-none p-1 text-main-base-d hover:opacity-75">
          <IconWidgetList className="h-[14px] w-[14px]" />
        </button>

        <button
          onClick={onExitWidget}
          type="button"
          className="appearance-none p-1 text-main-base-d hover:opacity-75">
          <IconClose className="h-[14px] w-[14px]" />
        </button>
      </div>
    </div>
  );
}
