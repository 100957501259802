import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import OrderFocusAmendForm from 'components/form/order-focus-amend-form';
import OrderFocusBuyForm from 'components/form/order-focus-buy-form';
import OrderFocusSellForm from 'components/form/order-focus-sell-form';
import Tabs from 'components/tabs/tabs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetStockByCodeQuery } from 'services/rtk-query/stock';
import { find } from 'lodash';
import OrderFocusWithdrawForm from 'components/form/order-focus-withdraw-form';
import {
  useGetOrderListQuery,
  useGetPortfolioListQuery
} from 'services/rtk-query/trade';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import SkeletonTheme from 'components/basic/skeleton-theme';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function TWOrderForm({
  symbol,
  // pickPrice,
  initActiveTabIndex = 0,
  onChangeStock = () => {},
  onChangeTab = () => {},
  initValues,
  isCardLess = false,
  isSticky = false,
  mockOnly = false,
  defaultSplitOrder = false,
  buyAnalyticEventName = 'TR Buy Stock from Widget',
  sellAnalyticEventName = 'TR Sell Stock from Widget',
  amendAnalyticEventName = 'TR Amend from Widget',
  withdrawAnalyticEventName = 'TR Withdraw from Widget'
}) {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { data: trading, isLoading: isLoadingBP } = useGetBuyingPowerQuery(
    null,
    { refetchOnMountOrArgChange: true }
  );
  const { user } = useBrowserTabAuth();

  const {
    data: stockDetail,
    isLoading: isLoadingStockDetail,
    isSuccess
  } = useGetStockByCodeQuery(symbol);
  const { data: orderList, isLoading: isLoadingOrderList } =
    useGetOrderListQuery(null, { refetchOnMountOrArgChange: true });
  const { data: portFolio, isLoading: isLoadingPortfolioList } =
    useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const portfolio = find(portFolio?.stock?.items, { stID: symbol });

  const [activeTabIndex, setActiveIndexTab] = useState(initActiveTabIndex);
  const [tabs] = useState([
    {
      index: 0,
      text: 'Buy',
      ['data-qa-id']: 'btnNavTradingBuy',
      customActiveClass:
        '!min-w-[unset] !py-2 border-red-base-d !bg-red-base-d hover:border-red-base-d hover:bg-red-base-d border-b !text-white'
    },
    {
      index: 1,
      text: 'Sell',
      ['data-qa-id']: 'btnNavTradingSell',
      customActiveClass:
        '!min-w-[unset] !py-2 border-green-base-d !bg-green-base-d hover:border-green-base-d hover:bg-green-base-d border-b !text-white'
    },
    {
      index: 2,
      text: 'Amend',
      ['data-qa-id']: 'btnNavTradingSell',
      customActiveClass:
        '!min-w-[unset] !py-2 border-main-gold-d !bg-main-gold-d hover:border-main-gold-d hover:bg-main-gold-d border-b !text-white'
    },
    {
      index: 3,
      text: 'Withdraw',
      ['data-qa-id']: 'btnNavTradingSell',
      customActiveClass:
        '!min-w-[unset] !py-2 border-orange-2 !bg-orange-2 hover:border-orange-2 hover:bg-orange-2 border-b !text-white'
    }
  ]);

  useEffect(() => {
    setActiveIndexTab(initActiveTabIndex);
  }, [initActiveTabIndex]);

  if (
    isLoadingStockDetail ||
    isLoadingOrderList ||
    isLoadingPortfolioList ||
    isLoadingBP
  ) {
    return (
      <div
        className={`p-4 ${
          !isCardLess && 'card-widget-gradient rounded-xl  mb-3 p-4'
        }`}>
        <div className="flex justify-between -mx-2 mb-3">
          <SkeletonTheme
            className="mx-2"
            width="80px"
            height="1.8rem"
            borderRadius="6px"
          />
          <SkeletonTheme
            className="mx-2"
            width="80px"
            height="1.8rem"
            borderRadius="6px"
          />
          <SkeletonTheme
            className="mx-2"
            width="80px"
            height="1.8rem"
            borderRadius="6px"
          />
        </div>
        <SkeletonTheme
          className="mb-3"
          width="100%"
          height="1.8rem"
          borderRadius="6px"
        />
        <SkeletonTheme
          className="mb-3"
          width="100%"
          height="1.8rem"
          borderRadius="6px"
        />

        <div className="flex justify-between -mx-2 mb-3">
          <SkeletonTheme
            className="mx-2"
            width="50px"
            height="1.8rem"
            borderRadius="6px"
          />
          <SkeletonTheme
            className="mx-2"
            width="50px"
            height="1.8rem"
            borderRadius="6px"
          />
          <SkeletonTheme
            className="mx-2"
            width="50px"
            height="1.8rem"
            borderRadius="6px"
          />
          <SkeletonTheme
            className="mx-2"
            width="50px"
            height="1.8rem"
            borderRadius="6px"
          />
        </div>

        <SkeletonTheme
          className="mb-3"
          width="100%"
          height="1.8rem"
          borderRadius="6px"
        />

        <div className="flex justify-between mb-3">
          <SkeletonTheme width="80px" height="2rem" borderRadius="6px" />
          <SkeletonTheme width="150px" height="2rem" borderRadius="6px" />
        </div>

        <SkeletonTheme
          className="mb-3"
          width="100%"
          height="2.8rem"
          borderRadius="6px"
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={`sticky top-0 z-20 card-widget-gradient ${
          !isCardLess && 'rounded-xl rounded-b-none'
        }`}>
        <Tabs
          initialOpenIndex={activeTabIndex}
          className="flex w-full font-quicksand-semibold text-gray-light-0-d text-xs overflow-hidden"
          classObject={{
            li: 'flex-none w-[42px]',
            liActive: '!flex-auto',
            active: 'border-main-base-l border-b-2 text-main-base-l',
            inactive: '!bg-opacity-[75%] hover:!border-b',
            span: 'uppercase'
          }}
          data={tabs?.map((item, key) => ({
            ...item,
            text: activeTabIndex === key ? item?.text : item?.text?.[0]
          }))}
          onChange={(index) => {
            setActiveIndexTab(index);
            onChangeTab(index);
          }}
        />
      </div>
      <div
        className={`${
          !isCardLess &&
          'card-widget-gradient rounded-xl rounded-t-none  mb-3 -mt-3 pt-3 pb-2'
        }`}>
        <RenderIf
          isTrue={
            trading && stockDetail && isSuccess && user && activeTabIndex === 0
          }>
          <OrderFocusBuyForm
            defaultSplitOrder={defaultSplitOrder}
            analyticEventName={buyAnalyticEventName}
            mockOnly={mockOnly}
            // pickPrice={pickPrice}
            buyingPower={trading?.buyingpower}
            stockDetail={stockDetail}
            user={user}
            isBursaOpen={isBursaOpen}
            onChangeStock={onChangeStock}
            isSticky={isSticky}
          />
        </RenderIf>

        <RenderIf
          isTrue={
            trading &&
            stockDetail &&
            isSuccess &&
            user &&
            !isLoadingPortfolioList &&
            activeTabIndex === 1
          }>
          <OrderFocusSellForm
            defaultSplitOrder={defaultSplitOrder}
            analyticEventName={sellAnalyticEventName}
            mockOnly={mockOnly}
            // pickPrice={pickPrice}
            stockDetail={stockDetail}
            user={user}
            isBursaOpen={isBursaOpen}
            onChangeStock={onChangeStock}
            portfolio={{ lot: (portfolio?.net || 0) / 100 }}
            isSticky={isSticky}
          />
        </RenderIf>

        <RenderIf
          isTrue={
            trading &&
            user &&
            orderList?.items &&
            !isLoadingOrderList &&
            activeTabIndex === 2
          }>
          <OrderFocusAmendForm
            analyticEventName={amendAnalyticEventName}
            stockDetail={stockDetail}
            initValues={initValues}
            // pickPrice={pickPrice}
            buyingPower={trading?.buyingpower}
            portFolio={portFolio}
            user={user}
            isBursaOpen={isBursaOpen}
            orderList={orderList?.items}
            isSticky={isSticky}
          />
        </RenderIf>

        <RenderIf
          isTrue={
            orderList?.items && !isLoadingOrderList && activeTabIndex === 3
          }>
          <OrderFocusWithdrawForm
            analyticEventName={withdrawAnalyticEventName}
            orderList={orderList?.items}
            initValues={initValues}
            isBursaOpen={isBursaOpen}
            isSticky={isSticky}
          />
        </RenderIf>
      </div>
    </>
  );
}

TWOrderForm.propTypes = {
  symbol: PropTypes.string,
  initValues: PropTypes.object,
  // pickPrice: PropTypes.any,
  onChangeStock: PropTypes.func,
  onChangeTab: PropTypes.func,
  initActiveTabIndex: PropTypes.any,
  isCardLess: PropTypes.bool,
  isSticky: PropTypes.bool,
  mockOnly: PropTypes.bool,
  buyAnalyticEventName: PropTypes.string,
  sellAnalyticEventName: PropTypes.string,
  amendAnalyticEventName: PropTypes.string,
  withdrawAnalyticEventName: PropTypes.string
};

export default TWOrderForm;
