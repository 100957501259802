import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import styles from './css/input-text.module.css';
import RenderIf from 'components/basic/render-if';

function InputText({
  value,
  name,
  type,
  label,
  placeholder,
  autoSelect,
  iconLeft,
  iconRight,
  className,
  autoComplete,
  disabled,
  onChange,
  onBlur,
  onBeforeInput,
  onKeyDown,
  onClickRightIcon,
  onClickLeftIcon,
  error,
  isAutoFocus,
  inputSize = 'medium',
  ...props
}) {
  const refInput = useRef(null);

  const handleFocus = (e) => {
    if (props?.onFocus) props?.onFocus(e);
    if (autoSelect) refInput?.current?.setSelectionRange(0, 9999999);
  };

  useEffect(() => {
    if (isAutoFocus) {
      setTimeout(() => refInput?.current?.focus(), 500);
    }
  }, [isAutoFocus]);

  return (
    <div className={`relative ${className} ${styles[inputSize]}`}>
      <RenderIf isTrue={label}>
        <label htmlFor={name} className={`${styles['input-text-label']}`}>
          {label}
        </label>
      </RenderIf>

      <div className={`wrappper ${value && 'wrapper-active'} ${styles['input-text-wrapper']} dark:border-black-light-1-l ${error && styles['input-text-error']}`}>
        <RenderIf isTrue={iconLeft}>
          <span className={styles['input-text-icon-left']}>{iconLeft}</span>
        </RenderIf>

        <span className="grow">
          <input
            ref={refInput}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur(e)}
            onKeyDown={(e) => onKeyDown(e)}
            className={`${styles['input-text-field']} appearance-none px-4 ${iconLeft && 'pl-10'} ${iconRight && 'pr-10'} dark:bg-black-base-d dark:text-black-light-1-d`}
            autoComplete={autoComplete ? 'on' : 'off'}
            disabled={disabled}
            onBeforeInput={onBeforeInput}
            {...props}
            onFocus={handleFocus}
          />
        </span>

        <RenderIf isTrue={iconRight}>
          <span onClick={onClickRightIcon} className={styles['input-text-icon-right']}>{iconRight}</span>
        </RenderIf>
      </div>

      <RenderIf isTrue={error}>
        <p className="text-xs text-red-base-l font-quicksand-regular mt-1">
          {error}
        </p>
      </RenderIf>
    </div>
  );
}

InputText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClickRightIcon: PropTypes.func,
  onClickLeftIcon: PropTypes.func,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  autoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  autoSelect: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
};

InputText.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
  onClickRightIcon: () => {},
  onClickLeftIcon: () => {},
  autoComplete: true,
  disabled: false,
  autoSelect: false,
  isAutoFocus: false,
  className: 'mb-4',
  type: 'text',
  error: '',
};

export default InputText;
