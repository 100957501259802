import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import TWPortfolio from '../_partials/tw-portfolio';
import Card from 'components/basic/card';
// import PortFolioDonutChart from '../_partials/portfolio-donut-chart';
import GridTermStock from './_partials/grid-term-stock';
import { triggerSegmentEvent } from 'lib/segment';
import PortfolioSummary from './_partials/portfolio-summary';
import { useDispatch, useSelector } from 'react-redux';
import { setCoachMarkPortfolio } from 'store/pina-pro';
import { CoachMark } from 'react-coach-mark';
import {
  useCreateUserCoachMarkMutation,
  useGetUserCoachMarkByIdQuery
} from 'services/rtk-query/user';
import TWInfoCash from '../_partials/tw-info-cash';
import PortfolioHistoryChart from '../_partials/portfolio-history-chart';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function PinaProPortfolio() {
  const dispatch = useDispatch();
  const refCoachMarkPorto = useRef();
  // const refCoachMarkPie = useRef();
  const refCoachMarkTerm = useRef();
  const { user} = useBrowserTabAuth();
  const { coachMarkPortfolio, coachMarkPortfolioList } = useSelector(
    ({ pinaPro }) => pinaPro
  );
  const [createUserCoachMark] = useCreateUserCoachMarkMutation();
  const { data: coachMarks, isFetching} = useGetUserCoachMarkByIdQuery(user?.id, { skip: !user?.id});

  const [firstRender, setFirstRender] = useState(true);
  const [coachMarkSummaryReady, setCoachMarkSummaryReady] = useState(false);
  const [localCoachMarkList, setLocalCoachMarkList] = useState([]);

  useEffect(() => {
    triggerSegmentEvent({ event: 'TR Portfolio Screen Viewed' });
  }, []);

  useEffect(() => {
    if (
      firstRender &&
      !isFetching &&
      coachMarkSummaryReady &&
      !coachMarks?.includes('PinaTradePortfolioCoachmark')
    ) {
      setFirstRender(false);
      let _newCoachMarkPortfolioList = [
        ...localCoachMarkList,
        // {
        //   activate: true,
        //   reference: refCoachMarkPie,
        //   tooltip: { position: 'right' },
        //   component: (
        //     <div className='block p-2 max-w-[280px]'>
        //       <h5 className='font-quicksand-semibold text-sm dark:text-main-base-l'>5/7</h5>
        //       <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">Alokasi Saham</h5>
        //       <p className="text-gray-light-0-d text-xs mb-2">
        //         Grafik yang menunjukan perbandingan persentase dari saham yang Kamu punya. Sehingga, memudahkan Kamu melihat dengan jelas proporsi masing-masing saham.
        //       </p>

        //       <div className='flex justify-between font-quicksand-semibold'>
        //         <button onClick={() => dispatch(setCoachMarkPortfolio(4))} type='button' className='text-main-base-l text-sm block'>Kembali</button>
        //         <button onClick={() => dispatch(setCoachMarkPortfolio(6))} type='button' className='text-main-base-l text-sm block'>Selanjutnya</button>
        //       </div>
        //     </div>
        //   )
        // },
        {
          activate: true,
          reference: refCoachMarkPorto,
          tooltip: { position: 'left' },
          component: (
            <div className='block p-2 max-w-[280px]'>
              <h5 className='font-quicksand-semibold text-sm dark:text-main-base-l'>5/6</h5>
              <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">Grafik Riwayat</h5>
              <p className="text-gray-light-0-d text-xs mb-2">
                Pantau dan lihat perkembangan nilai portofolio saham Kamu dari masa lalu sampe sekarang.
              </p>
  
              <div className='flex justify-between font-quicksand-semibold'>
                <button onClick={() => dispatch(setCoachMarkPortfolio(4))} type='button' className='text-main-base-l text-sm block'>Kembali</button>
                <button onClick={() => dispatch(setCoachMarkPortfolio(6))} type='button' className='text-main-base-l text-sm block'>Selanjutnya</button>
              </div>
            </div>
          )
        },
        {
          activate: true,
          reference: refCoachMarkTerm,
          tooltip: { position: 'top' },
          component: (
            <div className='block p-2 max-w-[280px]'>
              <h5 className='font-quicksand-semibold text-sm dark:text-main-base-l'>6/6</h5>
              <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">Planning</h5>
              <p className="text-gray-light-0-d text-xs mb-2">
                Rencanakan dan tentukan jangka waktu investasimu, Kamu dapat memindahkan saham ke jangka waktu yang sesuai dengan tujuan investasimu.
              </p>
  
              <div className='flex justify-between font-quicksand-semibold'>
                <button onClick={() => dispatch(setCoachMarkPortfolio(6))} type='button' className='text-main-base-l text-sm block'>Kembali</button>
                <button onClick={(async () => {
                  dispatch(setCoachMarkPortfolio(8));
                  await createUserCoachMark({ category: 'PinaTradePortfolioCoachmark' , userId: user?.id}).unwrap();
                })} type='button' className='text-main-base-l text-sm block'>Selesai</button>
              </div>
            </div>
          )
        }
      ];
      setLocalCoachMarkList(_newCoachMarkPortfolioList);
      dispatch(setCoachMarkPortfolio(1));
    }
  }, [
    dispatch,
    user,
    createUserCoachMark,
    firstRender,
    isFetching,
    coachMarks,
    coachMarkPortfolioList,
    coachMarkSummaryReady,
    localCoachMarkList
  ]);

  return (
    <div className="p-4 dark:text-black-light-1-d zoom-2">
      <Helmet>
        <title>Portfolio Kamu | Pina Trade</title>
      </Helmet>

      <h1 className="font-quicksand-semibold text-base mb-4">Portfolio</h1>

      <PortfolioSummary
        onInitCoachMark={(_coachMarks) => {
          setLocalCoachMarkList([...localCoachMarkList, ..._coachMarks]);
          setCoachMarkSummaryReady(true);
        }}
      />

      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 md:col-span-6 xl:col-span-3">
          <TWInfoCash className="h-full" />
        </div>

        {/* <div
          ref={refCoachMarkPie}
          className="col-span-12 md:col-span-6 xl:col-span-3">
          <PortFolioDonutChart />
        </div> */}

        <div ref={refCoachMarkPorto} className='col-span-12 md:col-span-6 xl:col-span-9 card-widget-gradient rounded-xl pb-6'>
          <Card className="pb-2">
            <div className="p-4 pb-3">
              <h2 className="text-base font-quicksand-semibold">Grafik Riwayat</h2>
            </div>
            <PortfolioHistoryChart />
          </Card>
        </div>

        <div className='col-span-12'>
          <Card className="pb-2">
            <div className="p-4 pb-3">
              <h2 className="text-base font-quicksand-semibold">
                Portfolio Kamu
              </h2>
            </div>
            <TWPortfolio title={false} isZoomOut />
          </Card>
        </div>

        <div ref={refCoachMarkTerm} className="col-span-12">
          <GridTermStock />
        </div>
      </div>

      <CoachMark {...localCoachMarkList[coachMarkPortfolio - 1]} />
    </div>
  );
}

export default PinaProPortfolio;
