import PropTypes from 'prop-types';

function IconWithdraw({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16667 2.5H15.8333V14.1667C15.8333 16.0076 14.341 17.5 12.5 17.5H7.50001C5.65906 17.5 4.16667 16.0076 4.16667 14.1667V2.5ZM5.83334 4.16667V14.1667C5.83334 15.0871 6.57953 15.8333 7.50001 15.8333H12.5C13.4205 15.8333 14.1667 15.0871 14.1667 14.1667V4.16667H5.83334Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5893 13.9226C10.2638 14.248 9.73618 14.248 9.41074 13.9226L7.74408 12.2559C7.41864 11.9305 7.41864 11.4028 7.74408 11.0774C8.06951 10.752 8.59715 10.752 8.92259 11.0774L9.16667 11.3215V6.66665C9.16667 6.20641 9.53976 5.83331 10 5.83331C10.4602 5.83331 10.8333 6.20641 10.8333 6.66665V11.3215L11.0774 11.0774C11.4028 10.752 11.9305 10.752 12.2559 11.0774C12.5814 11.4028 12.5814 11.9305 12.2559 12.2559L10.5893 13.9226Z"
      />
      <path d="M2.5 3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5H16.6667C17.1269 2.5 17.5 2.8731 17.5 3.33333C17.5 3.79357 17.1269 4.16667 16.6667 4.16667H3.33333C2.8731 4.16667 2.5 3.79357 2.5 3.33333Z" />
    </svg>
  );
}

IconWithdraw.propTypes = {
  className: PropTypes.string
};

IconWithdraw.defaultProps = {
  className: ''
};

export default IconWithdraw;
