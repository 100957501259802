import clsx from 'clsx';
import PropTypes from 'prop-types';
function Card({ as, children, className, style, ...props }) {
  const As = as;

  return (
    <As {...props} className={clsx('card-widget-gradient rounded-xl', className)} style={style}>
      {children}
    </As>
  );
}

Card.propTypes = {
  as: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.any,
  className: PropTypes.string
};

Card.defaultProps = {
  as: 'div',
};

export default Card;
