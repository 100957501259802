import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import IconClose from 'components/svg/icon-close';
import useToastr from 'hooks/useToastr';
import { useContext, useState } from 'react';
import { useCreateWorkSpaceByUserIdMutation } from 'services/rtk-query/workspace';
import slugify from 'utils/formatter/slugify';
import SimpleSpinner from 'components/common/spinner/spinner';
import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';

import {
  WORKSPACE_PINA_LONGTERM,
  WORKSPACE_PINA_SCALPING,
  WORKSPACE_PINA_SWING,
  DEFAULT_WORKSPACE
} from 'constants/workspace';

import IconChevronSingle from 'components/svg/icon-chevron-single';
import { ModalContext } from 'components/modal/context/modalContext';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { find } from 'lodash';
import { triggerSegmentEvent } from 'lib/segment';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function CardWorkspace({ data = [], value, onSelect = () => {} }) {
  const [opens, setOpens] = useState([]);

  return (
    <>
      {data?.map((item, key) => (
        <div key={key} className="mb-3">
          <Card
            className={`relative transition-all ease-linear duration-150 cursor-pointer`}>
            <button
              type="button"
              className="outline-none appearance-none flex w-full p-2 -mx-2 -my-2 rounded-md"
              onClick={() => onSelect(item)}>
              <span className="border-2 border-main-base-l h-4 w-4 rounded-full flex items-center justify-center flex-none mr-2">
                {value === item?.value && (
                  <span className="h-2 w-2 bg-main-base-l rounded-full"></span>
                )}
              </span>

              <div className="flex-auto -mt-[2px]">
                <h5 className="text-xs text-left">{item?.title}</h5>

                {item?.subTitle && (
                  <p className="text-gray-light-0-d text-2xs text-left my-1 mb-2">
                    {item?.subTitle}
                  </p>
                )}

                {item?.widgets?.length > 0 && (
                  <Accordion>
                    <AccordionItem
                      expandable={false}
                      expanded={opens?.includes(item?.value)}
                      onToggle={() => {
                        if (opens?.includes(item?.value)) {
                          setOpens(opens?.filter((o) => o != item?.value));
                        } else {
                          setOpens([...opens, item?.value]);
                        }
                      }}>
                      <AccordionItem.Slot name="Header">
                        <div className="flex justify-between">
                          <span className="text-main-base-l text-xs font-quicksand-semibold">
                            Widget Workpace
                          </span>
                          <IconChevronSingle
                            className={`h-4 w-4 transform text-main-base-l ${
                              opens?.includes(item?.value)
                                ? 'rotate-90'
                                : '-rotate-90'
                            }`}
                          />
                        </div>
                      </AccordionItem.Slot>
                      <AccordionItem.Slot name="Content">
                        <div className="mt-2">
                          <ul
                            className={`list-disc text-left text-2xs text-gray-dark-d dark:text-black-light-0-d pl-4 rounded-md`}>
                            {item?.widgets?.map((widgetName, key) => (
                              <li key={key}>{widgetName}</li>
                            ))}
                          </ul>
                        </div>
                      </AccordionItem.Slot>
                    </AccordionItem>
                  </Accordion>
                )}
              </div>
            </button>
          </Card>
        </div>
      ))}
    </>
  );
}

CardWorkspace.propTypes = {
  data: PropTypes.array,
  value: PropTypes.any,
  onSelect: PropTypes.func,
};

function WorkSpacePresetForm({ onClose = () => {}, workspaces = [], onSuccess = () => {} }) {
  const navigate = useNavigate();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { user } = useBrowserTabAuth();

  const { handleModal } = useContext(ModalContext);
  const [createWorkspace] = useCreateWorkSpaceByUserIdMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [workspacePina, setWorkspacePina] = useState(null);

  const listWorkspacePina = [
    {
      title: 'Day/Scalping Workspace',
      subTitle: 'Maksimalkan potensi keuntungan cepat dan tepat',
      description:
        'Workspace dirancang khusus untuk mendukung trader dalam gaya trading yang mengandalkan pergerakan harga yang cepat dan volatilitas pasar.',
      value: 'WORKSPACE_PINA_SCALPING',
      widgets: [
        'Running Trade',
        'Orderbook',
        'Fast Order',
        'Broker Summary',
        'Orderlist, Tradelist, Portfolio'
      ],
      layout: WORKSPACE_PINA_SCALPING
    },
    {
      title: 'Swing Workspace',
      subTitle: 'Maksimalkan peluang dan potensi keuntungan',
      description:
        'Workspace ini membantu menganalisis pergerakan harga jangka menengah dan mengidentifikasi peluang trading yang berpotensi menguntungkan.',
      value: 'WORKSPACE_PINA_SWING',
      widgets: [
        'News',
        'Broker Summary',
        'Watchlist',
        'Trading Ideas',
        'Orderlist, Tradelist, Portfolio'
      ],
      layout: WORKSPACE_PINA_SWING
    },
    {
      title: 'Longterm Workspace',
      subTitle: 'Pantau kinerja portofolio Kamu dengan lebih efektif',
      description:
        'Workspace ini memberikan Kamu alat dan informasi yang diperlukan untuk mengawasi investasi jangka panjang ',
      value: 'WORKSPACE_PINA_LONGTERM',
      widgets: [
        'Watchlist',
        'Chart & Orderbook',
        'Quick Order',
        'Orderlist, Tradelist, Portfolio',
        'Info Cash'
      ],
      layout: WORKSPACE_PINA_LONGTERM
    }
  ];

  const listWorkspaceCustom = [
    {
      title: 'Buat Workspacemu Sendiri',
      subTitle: 'Ciptakan tampilan yang sesua dengan kebutuhan tradingmu',
      description: '',
      value: 'WORKSPACE_PINA_CUSTOM',
      widgets: [],
      layout: DEFAULT_WORKSPACE
    }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      let _slug = slugify(workspacePina?.title);
      const isSameSlug = find(workspaces, ['slug', _slug]);
      if (isSameSlug) _slug = slugify(`${workspacePina?.title} ${dayjs().valueOf().toString().substring(8, 13)}`);

      const payload = {
        name: workspacePina?.title === 'Buat Workspacemu Sendiri' ? 'My Workspace' : workspacePina?.title,
        slug: _slug,
        is_active: true,
        unsaved: true,
        layout: JSON.stringify(workspacePina?.layout)
      };
      await createWorkspace({ body: payload, userId: user?.id }).unwrap();

      triggerSegmentEvent({
        event: 'TR Preset Workspace Selected',
        properties: { workspaceType: workspacePina?.title }
      });
      navigate(`/workspace/${_slug}`);
      toastr.dismiss();
      handleModal(false);
      onSuccess(payload);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit} noValidate>
      <Card className="p-6 lg:p-8 relative max-w-xl mx-4 lg:mx-auto mt-20 dark:border dark:border-gray-base-d dark:text-black-light-1-d">
        <div className="mb-6 pt-3">
          <h5 className="text-lg lg:text-center">Buat Workspace Baru</h5>
          <p className="text-sm text-gray-light-0-d lg:text-center">
            Pilih dan atur Workspace yang sesuai dengan gaya Tradingmu
          </p>

          <button
            onClick={onClose}
            type="button"
            className="absolute right-4 top-4 hover:text-main-base-l dark:text-main-base-l hover:bg-opacity-75">
            <IconClose />
          </button>
        </div>

        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 -mx-4">
          <div className="px-4 lg:border-r w-full py-1 dark:border-gray-base-d">
            <h5 className="text-sm mb-3">Pakai Workspace Dari PINA</h5>
            <CardWorkspace
              data={listWorkspacePina}
              value={workspacePina?.value}
              onSelect={(item) => setWorkspacePina(item)}
            />
          </div>
          <div className="px-4 w-full py-1">
            <h5 className="text-sm mb-3">Kustomisasi Workspace</h5>
            <CardWorkspace
              data={listWorkspaceCustom}
              value={workspacePina?.value}
              onSelect={(item) => setWorkspacePina(item)}
            />
          </div>
        </div>

        <div className="pt-4 mt-1 relative">
          <button
            type="submit"
            disabled={isLoading || !workspacePina}
            className="bg-main-base-l disabled:bg-[#D9DEE2] dark:text-black-base-d dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-[300px] mx-auto flex justify-center items-center transition-all ease-in-out duration-100">
            {isLoading && <SimpleSpinner />} Selanjutnya
          </button>
        </div>
      </Card>
    </form>
  );
}

WorkSpacePresetForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  layout: PropTypes.string,
  initialValues: PropTypes.object,
  workspaces: PropTypes.array
};

export default WorkSpacePresetForm;
