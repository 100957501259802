/* eslint-disable react/prop-types */
import { Draggable } from '@hello-pangea/dnd';
import ActionButton from 'components/basic/action-button';
import RenderIf from 'components/basic/render-if';
import IconBuy from 'components/svg/icon-buy';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import IconGrab from 'components/svg/icon-grab';
import IconSearchPage from 'components/svg/icon-search-page';
import IconSell from 'components/svg/icon-sell';
import IconStar from 'components/svg/icon-star';
import IconStarDelete from 'components/svg/icon-star-delete';
import { useCompareStock } from 'hooks/useCompareStock';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useToastr from 'hooks/useToastr';
import { triggerSegmentEvent } from 'lib/segment';
import { useDispatch } from 'react-redux';
import { useCreateWatchlistMutation, useDeleteWatchlistMutation, useGetWatchlistQuery } from 'services/rtk-query/watchlist';
import { setSymbolAdvancedChart } from 'store/pina-pro';
import { currency } from 'utils/format';

function TermStockItem({ item, index, last }) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { navigate, customNavigate } = useCustomNavigate();

  const dispatch = useDispatch();
  const { data: watchlist } = useGetWatchlistQuery();
  const [deleteWatchlist] = useDeleteWatchlistMutation();
  const [createWatchlist] = useCreateWatchlistMutation();

  const { addToCompareStock } = useCompareStock();

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <div className={`flex flex-row justify-between items-center text-2xs card-widget-gradient font-quicksand-semibold cursor-grab focus-within:cursor-grabbing group`}>
            <div className={`p-2 ${!last && ''}`} style={{width: '20%'}}>
              <IconGrab className="text-main-base-l h-[14px] w-auto"/>
            </div>

            <div className='relative' style={{width: '100%'}}>
              <div className={`p-2 ${!last && ''} font-quicksand-semibold flex items-center group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d rounded-lg`}>
                <RenderIf isTrue={item?.sLogo}>
                  <img
                    src={item?.sLogo}
                    className="h-5 w-5 rounded-full mr-1 border border-gray-light-l"
                  />
                  {item?.code}
                </RenderIf>

                <RenderIf isTrue={!item?.sLogo}>
                  <div className="rounded-full mr-1 flex-none bg-gray-light-l bg-opacity-50 text-2xs flex items-center justify-center h-5 w-5">
                    {item?.code?.charAt(0)}
                  </div>
                  {item?.code}
                </RenderIf>
              </div>

              <ActionButton isLast={true} position={`left-0 ${last ? '-bottom-8' : '-bottom-8'}`} actions={[
                {
                  title: 'Beli',
                  children: (
                    <IconBuy className="h-4 w-4" />
                  ),
                  onClick: () => customNavigate({ query: { 'simple-order': item?.code }})
                },
                {
                  title: 'Jual',
                  children: <IconSell className="h-4 w-4" />,
                  onClick: () => customNavigate({ query: { 'simple-order': item?.code, 'order-type': 'SimpleSell' }})
                },
                {
                  title: 'Stock Detail',
                  children: <IconSearchPage className="h-4 w-4" />,
                  onClick: () => {
                    triggerSegmentEvent({ event: 'TR Search Feature Clicked' });
                    customNavigate({ query: { 'stock-detail': item?.code }});
                  }
                },
                {
                  title: 'Trading View',
                  children: <IconChartCandleStick className="h-4 w-4" />,
                  onClick: () => {
                    dispatch(setSymbolAdvancedChart(item?.code));
                    customNavigate({ query: { 'advanced-chart': item?.code }});
                  }
                },
                {
                  title: 'Bandingkan',
                  children: <IconCompare className="h-4 w-4" />,
                  onClick: () => {
                    triggerSegmentEvent({ event: 'TR Compare Stock Clicked' });
                    addToCompareStock({ code: item?.code, name: item?.name }, () => {
                      navigate(`/compare/fundamental`);
                    })
                  }
                },
                {
                  title: `${watchlist?.find((w) => w.code === item?.code) ? 'Hapus Watchlist' : 'Tambahkan ke Watchlist'}`,
                  children: watchlist?.find((w) => w.code === item?.code) ? <IconStarDelete className="h-4 w-4" /> : <IconStar className="h-4 w-4" />,
                  onClick: async () => {
                    triggerSegmentEvent({ event: 'TR Watchlist Clicked' });
                    if (watchlist?.find((w) => w.code === item?.code)) {
                      try {
                        await deleteWatchlist({ stockCode: item?.code }).unwrap();
                        toastr.success(`${item?.code} berhasil dihapus dari watchlist`);
                      } catch (error) {
                        getErrorMessage(error);
                      }
                    } else {
                      try {
                        await createWatchlist({ stockCode: item?.code, type: 'stock'}).unwrap();
                        toastr.success(`${item?.code} berhasil ditambahkan kedalam watchlist`);
                      } catch (error) {
                        getErrorMessage(error);
                      }
                    }
                  }
                }
              ]}/>
            </div>
            <div className={`p-2 ${!last && ''}`} style={{width: '100%'}}>{currency(item?.stockValue, { removeSymbol: true })}</div>
            <div className={`p-2 ${!last && ''}`} style={{width: '100%'}}>{currency(item?.marketValue, { removeSymbol: true })}</div>
            <div className={`p-2 ${!last && ''} ${item?.totalGain > 0 ? 'text-green-base-l' : 'text-red-base-l'}`} style={{width: '100%'}}>{currency(item?.totalGain, { removeSymbol: true })}</div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default TermStockItem;
