import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSSE from './useSSE';
import { isEqual, uniqBy } from 'lodash';
import { useGetIntraDataOrderBookQuery } from 'services/rtk-query/trade';
import { generateOrderbook } from 'utils/stock/orderbook';
import useBrowserWakeUp from './useBrowserWakeUp';

const useRealtimeOrderbook = (code, lastTradedPrice) => {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const isBrowserWakeUp = useBrowserWakeUp();
  const resSSEOrderBooks = useSSE(isBursaOpen, code && `ticker/orderbook/${code}`);
  const { data: resOrderBooks, isFetching: loadingOrderBooks, refetch } = useGetIntraDataOrderBookQuery(code, { refetchOnMountOrArgChange: true });

  const [orderBooks, setOrderBooks] = useState({
    list: [],
    totalBidLot: 0,
    totalAskLot: 0
  });

  useEffect(() => {
    if (!loadingOrderBooks) {
      setOrderBooks({...generateOrderbook(resOrderBooks?.data?.[0], lastTradedPrice), symbol: code });
    };
  }, [resOrderBooks, loadingOrderBooks, lastTradedPrice, code]);
  
  useEffect(() => {
    if (resSSEOrderBooks?.type === 'B' && resSSEOrderBooks?.code === code) {
      let totalLot = 0;
      let subOrderbooks = orderBooks?.bid?.filter((item, key) => key > resSSEOrderBooks?.order_book?.length - 1 && item?.price) || [];
      let bids = [...resSSEOrderBooks?.order_book || [], ...subOrderbooks]?.map((item) => {
        return  {...item, lot: Number(item?.lot), price: Number(item?.price)}
      });

      let newOrderbooks = {
        ...orderBooks,
        bid: uniqBy(bids, 'price'),
      }

      newOrderbooks?.bid?.map((item) => {
        totalLot += Number(item?.lot);
      });

      newOrderbooks.totalBidLot = totalLot;

      if (!isEqual(orderBooks, newOrderbooks)) {
        setOrderBooks(newOrderbooks);
      }
    }

    if (resSSEOrderBooks?.type === 'S' && resSSEOrderBooks?.code === code) {
      let totalLot = 0;
      let subOrderbooks = orderBooks?.ask?.filter((item, key) => key > resSSEOrderBooks?.order_book?.length - 1 && item?.price) || [];
      let asks = [...resSSEOrderBooks?.order_book || [], ...subOrderbooks]?.map((item) => {
        return  {...item, lot: Number(item?.lot), price: Number(item?.price)}
      });

      let newOrderbooks = {
        ...orderBooks,
        ask: uniqBy(asks, 'price'),
      }

      newOrderbooks?.ask?.map((item) => {
        totalLot += Number(item?.lot);
      });

      newOrderbooks.totalAskLot = totalLot;

      if (!isEqual(orderBooks, newOrderbooks)) {
        setOrderBooks(newOrderbooks);
      }
    }
  }, [resSSEOrderBooks, orderBooks, code]);

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  return { orderBooks, loadingOrderBooks };
};

export { useRealtimeOrderbook };
