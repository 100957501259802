import PropTypes from 'prop-types';
import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';
import RenderIf from 'components/basic/render-if';
import dayjs from 'dayjs';
import { currency } from 'utils/format';
import { useEffect, useState } from 'react';
import { filter, groupBy, orderBy } from 'lodash';
import IconSorting from 'components/svg/icon-sorting';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import SmartOrderForm from 'components/form/smart-order-form';
import {
  invalidateSmartOrderList,
  useDeleteAlgoBOTMutation,
  useGetAlgoBOTQuery
} from 'services/rtk-query/trading';
import { useDispatch } from 'react-redux';
import IconTrashCan from 'components/svg/icon-trash-can';
import useToastr from 'hooks/useToastr';
import IconInfo from 'components/svg/icon-info';
import Tooltip from 'components/basic/tooltip';
import { triggerSegmentEvent } from 'lib/segment';
import IconPlus from 'components/svg/icon-plus';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import IconEye from 'components/svg/icon-eye';
import OrderDetail from './order-detail';
import { useGetOrderListQuery } from 'services/rtk-query/trade';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import Modal from 'components/modal/modal';
import ModalDialog from 'components/modal/components/modal-dialog';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function TWOrderlistSmartOrder({ title = 'Order List Trading Plan' }) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();

  const { user } = useBrowserTabAuth();
  const username = user?.username;

  const {
    data: orderList,
    isLoading,
    error: errorAlgoBot
  } = useGetAlgoBOTQuery(username, { refetchOnMountOrArgChange: true });
  const { data: orderListGeneral } = useGetOrderListQuery(null, {
    refetchOnMountOrArgChange: true
  });
  const [deleteAlgoBOT] = useDeleteAlgoBOTMutation();

  const [data, setData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [sortingColumns, setSortingColumns] = useState([]);
  const [sortingOrders, setSortingOrders] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);

  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [openSmartOrderForm, setOpenSmartOrderForm] = useState(false);
  const [openDeleteSmartOrder, setOpenDeleteSmartOrder] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const listFilterStatus = [
    {
      value: null,
      text: 'All'
    },
    {
      value: 'Terkirim',
      text: 'Terkirim'
    },
    {
      value: 'Aktif',
      text: 'Aktif'
    },
    {
      value: 'Berakhir Tanpa Match',
      text: 'Berakhir&nbsp;Tanpa&nbsp;Match'
    },
    {
      value: 'Tidak Aktif',
      text: 'Tidak Aktif'
    },
    {
      value: 'Dihapus',
      text: 'Dihapus'
    }
  ];

  const getSorting = (obj) => {
    if (obj === sortingColumns?.[0] && sortingOrders?.[0] === 'asc') {
      return 1;
    }

    if (obj === sortingColumns?.[0] && sortingOrders?.[0] === 'desc') {
      return 2;
    }

    return null;
  };

  const handleSorting = (obj) => {
    let tSortingColumns = sortingColumns;
    let tSortingOrders = sortingOrders;

    if (!tSortingColumns.includes(obj)) {
      tSortingColumns[0] = obj;
      tSortingOrders[0] = tSortingOrders?.[0] === 'asc' ? 'desc' : 'asc';
    } else if (tSortingColumns.includes(obj)) {
      tSortingOrders[0] = tSortingOrders[0] === 'asc' ? 'desc' : 'asc';
    }

    setSortingColumns(tSortingColumns);
    setSortingOrders(tSortingOrders);

    let _data = [];
    data?.forEach((item) => {
      let _items = orderBy(item?.items, tSortingColumns, tSortingOrders);
      _data.push({ ...item, items: _items });
    });

    setData(_data);
  };

  const getColorStatus = (State) => {
    let className = 'text-main-base-l';
    if (['Berakhir Tanpa Match', 'reject', 'withdraw'].includes(State)) {
      className = 'text-red-base-l';
    }

    if (['Terkirim', 'amend', 'partial', 'match'].includes(State)) {
      className = 'text-green-base-l';
    }

    if (['Dihapus', 'Tidak Aktif'].includes(State)) {
      className = 'text-gray-light-0-d';
    }

    if (['open', 'basket'].includes(State)) {
      className = 'text-turquoise-2';
    }

    return className;
  };

  const handleToggle = (_index) => {
    let _data = [...data];
    _data[_index].active = !_data[_index]?.active;
    setData(_data);
  };

  const onClickSmartBuy = (orderDetail, disabledStock, initialForms = {}) => {
    setOpenSmartOrderForm({ orderDetail, disabledStock, initialForms });
  };

  const handleDeleteSmartOrder = (BotId) => {
    setOpenDeleteSmartOrder(BotId);
  };

  const onClickOrderDetail = ({ RTOrderID }) => {
    let orderDetail = orderListGeneral?.items?.find(
      (item) => item?.id == Number(RTOrderID)
    );

    if (orderDetail) {
      setOpenOrderDetail(orderDetail);
    } else {
      toastr.error('Order tidak dapat ditemukan!');
    }
  };

  const renderDuration = (start, end) => {
    let _string =
      dayjs(start, 'DD-MMM-YYYY HH:mm:ss').format('DD MMM') +
      ' - ' +
      dayjs(end, 'DD-MMM-YYYY HH:mm:ss').format('DD MMM YY');
    let _date = dayjs(end, 'DD-MMM-YYYY HH:mm:ss');

    let suffix = ' Bulan';
    let countDuration = Math.abs(
      _date.diff(dayjs(start, 'DD-MMM-YYYY HH:mm:ss'), 'month')
    );

    if (countDuration === 0) {
      countDuration = Math.abs(
        _date.diff(dayjs(start, 'DD-MMM-YYYY HH:mm:ss'), 'day')
      );
      suffix = ' Hari';
    }

    if (countDuration === 0) {
      countDuration = Math.abs(
        _date.diff(dayjs(start, 'DD-MMM-YYYY HH:mm:ss'), 'hour')
      );
      suffix = ' Jam';
    }

    return _string + ' (' + countDuration + suffix + ')';
  };

  // const orderStatus = (item) => {
  // let _Condition = '';
  // let _State = item?.State;

  // if (item?.State === 'NonActive') {
  //   _State = 'Tidak Aktif'
  // }

  // if (item?.State === 'Deleted') {
  //   _State = 'Dihapus'
  // }

  // if (dayjs().valueOf() > dayjs(item?.BotExpire, 'DD-MMM-YYYY HH:mm:ss').valueOf() && item?.State === 'Active') {
  //   _State = 'Berakhir Tanpa Match';
  // }

  // if (dayjs(item?.BotExpire, 'DD-MMM-YYYY HH:mm:ss').valueOf() > dayjs().valueOf() && item?.State != 'Active' && item?.State != 'Deleted') {
  //   _State = 'Terkirim';
  // }

  // if (dayjs(item?.BotExpire, 'DD-MMM-YYYY HH:mm:ss').valueOf() > dayjs().valueOf() && item?.State === 'Active') {
  //   _State = 'Aktif';
  // }

  // if (item?.RTOrderStatus != '' && item?.RTOrderStatus) {
  //   _State = getOrderStatus(item?.RTOrderStatus);
  // }

  // let _priceFire = item?.PriceFire;
  // let _matchUpper = `Q.${item?.StockId}.56>`;

  // if (item?.Rule?.match(_matchUpper)) {
  //   _Condition = '>';
  //   let _ruleArr = item?.Rule?.split('&&');
  //   _priceFire = _ruleArr[0].replace(_matchUpper, '');
  // }

  // let _matchLower = `Q.${item?.StockId}.56<`;
  // if (item?.Rule?.match(_matchLower)) {
  //   _Condition = '<';
  //   let _ruleArr = item?.Rule?.split('&&');
  //   _priceFire = _ruleArr[0].replace(_matchLower, '');
  // }

  // let _orderType = '';
  // if (item?.BotName?.match('SmartBuy')) {
  //   _orderType = 'Smart Buy'
  // }

  // if (item?.BotName?.match('StopLoss')) {
  //   _orderType += ' Stop Loss'
  // }

  // if (item?.BotName?.match('TakeProfit')) {
  //   _orderType += ' Take Profit'
  // }

  // return {...item};
  // }

  useEffect(() => {
    if (!isLoading && orderList?.length > 0) {
      let _orderListObj = groupBy(orderList, 'StockId');
      let _data2 = [];

      Object.keys(_orderListObj)?.map((obj) => {
        let __data = [];
        let __children = [];

        _orderListObj[obj]?.map((item) => {
          let _botNameArr = item?.BotName?.split('@');
          if (_botNameArr?.length > 2) {
            __children.push(item);
          } else if (item?.BotName?.match('SmartBuy')) {
            __data.push(item);
          }
        });

        let _items = [];
        __data.forEach((parent) => {
          let _customObjParent = parent;
          let _objParent = { ..._customObjParent, children: [] };

          _items.push(_objParent);
          __children.forEach((children) => {
            if (children.BotName?.match(parent.ClientOrderId)) {
              let _customObjChildren = children;
              _items.push({ ..._customObjChildren, isChildren: true });
            }
          });
        });

        _data2.push({
          symbol: obj,
          logo: null,
          active: currentFilter ? true : false,
          items: currentFilter
            ? filter(_items, (i) => currentFilter === i?.State)
            : _items
        });
      });

      setData(_data2);
    }
  }, [isLoading, orderList, currentFilter]);

  useEffect(() => {
    let _isEmpty = true;

    data?.map((item) => {
      if (item?.items?.length > 0) _isEmpty = false;
    });

    setIsEmpty(_isEmpty && !currentFilter);
  }, [data, currentFilter]);

  return (
    <>
      <div className="md:flex justify-between items-start mb-3">
        <div className="flex-auto mb-3 lg:mb-0">
          <h1 className="font-quicksand-semibold flex items-center text-sm">
            <span>Smart Order</span>

            <Tooltip
              isZoomOut
              tooltipHTML={
                <div className="max-w-[300px] min-w-[300px]">
                  <h5 className="text-sm font-quicksand-medium text-left mb-1">
                    Smart Order
                  </h5>
                  <p className="text-xs font-quicksand-regular text-left">
                    Gunakan fitur ini untuk membuat strategi auto order saham
                    yang kamu incar dengan mengatur Target Harga yang ingin
                    dibeli, syarat harga untuk trigger, serta durasi order
                    sesuai analisa kamu.
                  </p>
                </div>
              }
              position="bottom-left">
              <button type="button" className="hover:text-main-base-l mx-1">
                <IconInfo className="h-4 w-4" />
              </button>
            </Tooltip>
          </h1>
        </div>

        <RenderIf isTrue={!isEmpty}>
          <div className="flex-none flex items-center">
            <button
              title="Buy"
              onClick={() => {
                onClickSmartBuy({ stockId: 'AALI', BuySell: 'SmartBuy' });
                triggerSegmentEvent({ event: 'TR Smart Buy Button Clicked' });
              }}
              className="p-3 py-2 justify-center flex items-center border-2 border-main-base-l rounded-lg bg-main-base-l text-white dark:text-black-base-d hover:opacity-75 transition-all ease-in-out duration-150 text-xs font-quicksand-semibold">
              <IconPlus className="h-4 w-4 mr-1" />
              Smart Order
            </button>
          </div>
        </RenderIf>
      </div>

      <div className="py-3 -mt-4">
        <RenderIf isTrue={title}>
          <h5 className="mb-3 text-sm">{title}</h5>
        </RenderIf>

        <RenderIf isTrue={!isEmpty}>
          <OverlayScrollbarsComponent
            className="overlayscrollbars-host-small mb-2"
            element="span"
            options={{
              resize: 'horizontal',
              scrollbars: {
                autoHide: 'leave',
                autoHideDelay: 50
              }
            }}>
            <ul className="flex text-xs -mx-1 max-w-full my-2">
              {listFilterStatus?.map((item, key) => (
                <li
                  key={key}
                  onClick={() => setCurrentFilter(item?.value)}
                  className={`
                    font-quicksand-semibold text-center py-[6px] cursor-pointer mx-1 rounded-lg border border-main-base-l px-4 hover:opacity-75 transition-all ease-in-out duration-200
                    ${
                      currentFilter === item?.value
                        ? 'bg-main-base-l text-white dark:text-black-base-d border-main-base-l'
                        : 'bg-white text-gray-light-1-d dark:bg-black-base-d'
                    }`}>
                  <span
                    className="line-clamp-1"
                    dangerouslySetInnerHTML={{ __html: item?.text }}></span>
                </li>
              ))}
            </ul>
          </OverlayScrollbarsComponent>
        </RenderIf>

        <OverlayScrollbarsComponent
          className="overlayscrollbars-host-small"
          element="span"
          options={{
            resize: 'horizontal',
            scrollbars: {
              autoHide: 'leave',
              autoHideDelay: 50
            }
          }}>
          <table className="text-xs w-full text-center font-quicksand-semibold">
            <thead className="font-quicksand-semibold text-gray-light-0-d">
              <tr>
                <td
                  className={`pl-0 p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'CreateAt' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('CreateAt')}
                  style={{ minWidth: '180px' }}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    {sortingColumns?.[0] === 'CreateAt' && (
                      <IconSorting
                        type={getSorting('CreateAt')}
                        className="h-3 w-3"
                      />
                    )}
                    Durasi{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'BotName' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('BotName')}
                  style={{ width: '200px' }}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    {sortingColumns?.[0] === 'BotName' && (
                      <IconSorting
                        type={getSorting('BotName')}
                        className="h-3 w-3"
                      />
                    )}
                    Bot&nbsp;Name{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'Lot' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('Lot')}
                  style={{ minWidth: '60px' }}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    {sortingColumns?.[0] === 'Lot' && (
                      <IconSorting
                        type={getSorting('Lot')}
                        className="h-3 w-3"
                      />
                    )}
                    Lot{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'Price' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('Price')}
                  style={{ minWidth: '60px' }}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    {sortingColumns?.[0] === 'Price' && (
                      <IconSorting
                        type={getSorting('Price')}
                        className="h-3 w-3"
                      />
                    )}
                    Harga{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'Condition' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('Condition')}
                  style={{ minWidth: '150px' }}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    {sortingColumns?.[0] === 'Condition' && (
                      <IconSorting
                        type={getSorting('Condition')}
                        className="h-3 w-3"
                      />
                    )}
                    Syarat{' '}
                  </span>
                </td>
                <td
                  className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                    sortingColumns?.[0] === 'State' && 'text-main-base-l'
                  }`}
                  onClick={() => handleSorting('State')}
                  style={{ minWidth: '220px' }}>
                  <span className="flex items-center gap-1 cursor-pointer">
                    {sortingColumns?.[0] === 'State' && (
                      <IconSorting
                        type={getSorting('State')}
                        className="h-3 w-3"
                      />
                    )}
                    Status{' '}
                  </span>
                </td>
              </tr>
            </thead>
            <tbody className="text-sm font-quicksand-regular">
              <RenderIf isTrue={isLoading}>
                <tr>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                  <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                    <SkeletonTheme
                      width="50px"
                      height="1rem"
                      borderRadius="6px"
                    />
                  </td>
                </tr>
              </RenderIf>

              {data?.map((item, key) => (
                <RenderIf key={key} isTrue={item?.items?.length > 0}>
                  <tr>
                    <td
                      className={`text-left font-quicksand-semibold align-bottom border-gray-light-l dark:border-gray-base-d ${
                        key < data?.length - 1 && 'border-b'
                      }`}
                      colSpan={7}>
                      <Accordion>
                        <AccordionItem
                          key={key}
                          expandable={false}
                          expanded={item?.active}
                          onToggle={() => handleToggle(key)}>
                          <AccordionItem.Slot name="Header">
                            <div className="flex justify-between items-center w-full py-2">
                              <div className="flex h-full items-center text-base font-quicksand-semibold">
                                {item?.symbol}&nbsp;
                                <RenderIf isTrue={!item?.active}>
                                  ({item?.items?.length})
                                </RenderIf>
                              </div>

                              <IconChevronSingle
                                className={`transform transition-all ease-linear duration-200 text-main-base-l ${
                                  item?.active ? 'rotate-90' : '-rotate-90'
                                }`}
                              />
                            </div>
                          </AccordionItem.Slot>

                          <AccordionItem.Slot name="Content">
                            <>
                              <button
                                onClick={() =>
                                  onClickSmartBuy(
                                    {
                                      stockId: item?.symbol,
                                      BuySell: 'SmartBuy'
                                    },
                                    true
                                  )
                                }
                                type="button"
                                className="bg-main-base-l text-white dark:text-black-base-d px-3 p-2 my-2 text-2xs rounded-md flex items-center transition-all ease-in-out duration-200 hover:bg-opacity-75">
                                <IconPlus className="h-4 w-4 mr-1" /> Tambah
                                Smart Order {item?.symbol}
                              </button>
                              <table
                                className="-mx-1 text-xs font-quicksand-semibold"
                                style={{ width: 'calc(100% + 4px)' }}>
                                <tbody>
                                  {item?.items?.map((cL, k) => (
                                    <>
                                      <tr key={k} className="group">
                                        <td
                                          className="border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d p-2 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d"
                                          style={{ minWidth: '180px' }}>
                                          <p
                                            className="font-quicksand-regular line-clamp-1 text-xs"
                                            key={k}>
                                            {renderDuration(
                                              cL?.CreateAt,
                                              cL?.BotExpire
                                            )}
                                          </p>
                                        </td>
                                        <td
                                          className="border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d p-2 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d"
                                          style={{ width: '200px' }}>
                                          <h5 className="font-quicksand-semibold max-w-[200px] overflow-auto">
                                            <span
                                              className={`${
                                                cL?.isChildren &&
                                                'border-l-2 px-1 border-main-base-l'
                                              }`}>
                                              {cL?.BotName}
                                            </span>
                                          </h5>
                                        </td>
                                        {/* <td className='border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d p-2 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d' style={{ width: '10%' }}>
                                            <p className={`line-clamp-1 ${cL?.BuySell === 'S' ? 'text-red-base-l' : 'text-green-base-l'}`} key={k}>{cL?.BuySell === 'B' ? 'Buy' : 'Sell'}</p>
                                          </td> */}
                                        <td
                                          className="border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d p-2 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d"
                                          style={{ minWidth: '60px' }}>
                                          <p className="line-clamp-1" key={k}>
                                            {cL?.Lot}
                                          </p>
                                        </td>
                                        <td
                                          className="border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d p-2 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d"
                                          style={{ minWidth: '60px' }}>
                                          <p className="line-clamp-1" key={k}>
                                            {cL?.Price ===
                                            `Q.${item?.symbol}.24`
                                              ? 'Best Bid'
                                              : currency(cL?.Price, {
                                                  removeSymbol: true
                                                })}
                                          </p>
                                        </td>
                                        <td
                                          className="border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d p-2 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d"
                                          style={{ minWidth: '150px' }}>
                                          <p className="line-clamp-1" key={k}>
                                            {' '}
                                            Harga {cL?.Condition}{' '}
                                            {currency(cL?.PriceFire, {
                                              removeSymbol: true
                                            })}
                                          </p>
                                        </td>
                                        <td
                                          className="border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d p-2 group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d relative group"
                                          style={{ minWidth: '220px' }}>
                                          <div className="flex justify-between items-center">
                                            <p
                                              className={`flex-none mr-2 line-clamp-1 uppercase ${getColorStatus(
                                                cL?.State
                                              )}`}
                                              key={k}>
                                              {cL?.State}
                                            </p>

                                            <div className="flex gap-2">
                                              <button
                                                onClick={() =>
                                                  handleDeleteSmartOrder(
                                                    cL?.BotId
                                                  )
                                                }
                                                disabled={cL?.State != 'Aktif'}
                                                type="button"
                                                className="p-1 rounded-md border border-main-base-l text-main-base-l hover:bg-main-base-l hover:text-white dark:hover:text-black-dark-d transition-all ease-linear duration-150 disabled:bg-gray-light-l dark:disabled:bg-gray-base-d dark:disabled:text-black-dark-d disabled:text-gray-base-l disabled:border-gray-base-l dark:disabled:border-gray-base-d">
                                                <IconTrashCan className="h-4 w-4" />
                                              </button>
                                              <button
                                                onClick={() =>
                                                  onClickOrderDetail(cL)
                                                }
                                                disabled={!cL?.RTOrderStatus}
                                                type="button"
                                                className="p-1 rounded-md border border-main-base-l text-main-base-l hover:bg-main-base-l hover:text-white dark:hover:text-black-dark-d transition-all ease-linear duration-150 disabled:bg-gray-light-l dark:disabled:bg-gray-base-d dark:disabled:text-black-dark-d disabled:text-gray-base-l disabled:border-gray-base-l dark:disabled:border-gray-base-d">
                                                <IconEye className="h-4 w-4" />
                                              </button>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </>
                          </AccordionItem.Slot>
                        </AccordionItem>
                      </Accordion>
                    </td>
                  </tr>
                </RenderIf>
              ))}
            </tbody>
          </table>
        </OverlayScrollbarsComponent>

        <RenderIf isTrue={errorAlgoBot?.data?.message && !isLoading}>
          <div className="p-3 bg-main-light-1-d text-black-base-d font-quicksand-semibold text-sm my-4 rounded-lg flex">
            <IconInfo className="h-4 w-4 mr-1 flex-none mt-[2px]" />{' '}
            {errorAlgoBot?.data?.message}
          </div>
        </RenderIf>

        <RenderIf
          isTrue={isEmpty && !isLoading && !errorAlgoBot?.data?.message}>
          <div className="lg:w-3/4 mx-auto py-3">
            <h2 className="text-center text-2xl my-2">
              Smart order kamu belum aktif
            </h2>
            <p className="text-sm font-quicksand-semibold dark:text-black-light-0-d text-center max-w-xl mx-auto">
              Buat strategi auto order saham yang Kamu incar dengan mengatur
              Target Harga yang ingin dibeli, syarat harga untuk trigger, serta
              durasi order sesuai dengan analisa Kamu.
            </p>

            <button
              onClick={() => {
                onClickSmartBuy({ stockId: 'AALI', BuySell: 'SmartBuy' });
                triggerSegmentEvent({
                  event: 'TR Smart Buy Button Clicked'
                });
              }}
              type="button"
              className="bg-main-base-l my-4 text-white dark:text-black-base-d hover:opacity-75 transition-all ease-linear duration-150 py-[10px] px-5 w-64 block mx-auto text-sm rounded-lg font-quicksand-semibold">
              Aktifkan Smart Order
            </button>
          </div>
        </RenderIf>
      </div>

      <RenderIf isTrue={openOrderDetail}>
        <Modal size="large" onClose={() => setOpenOrderDetail(false)}>
          <OrderDetail
            data={openOrderDetail}
            amendWithdraw={true}
            onClose={() => setOpenOrderDetail(false)}
          />
        </Modal>
      </RenderIf>

      {/* ORDER FORM */}
      <RenderIf isTrue={openSmartOrderForm}>
        <Modal size="full" onClose={() => setOpenSmartOrderForm(false)}>
          <SmartOrderForm
            formType="Smart Order"
            initialForms={openSmartOrderForm?.initialForms}
            orderDetail={openSmartOrderForm?.orderDetail}
            disabledStock={openSmartOrderForm?.disabledStock}
            onClose={() => setOpenSmartOrderForm(false)}
            onSuccess={() => setOpenSmartOrderForm(false)}
          />
        </Modal>
      </RenderIf>

      {/* DELETE FORM */}
      <RenderIf isTrue={openDeleteSmartOrder}>
        <Modal onClose={() => setOpenDeleteSmartOrder(false)}>
          <ModalDialog
            header={
              <h5 className="font-quicksand-semibold text-base">
                Konfirmasi Penghapusan Order
              </h5>
            }
            submitTitle="Hapus"
            backTitle="Batal"
            isSubmitting={isSubmitting}
            onClose={() => setOpenDeleteSmartOrder(false)}
            onSubmit={async () => {
              try {
                setIsSubmitting(true);
                const { success, message } = await deleteAlgoBOT({
                  botID: openDeleteSmartOrder
                }).unwrap();
                if (!success) throw new Error(message);

                setOpenDeleteSmartOrder(false);
                toastr.success('Smart Order berhasil dihapus');
              } catch (error) {
                getErrorMessage(error);
              } finally {
                setIsSubmitting(false);
                setTimeout(() => dispatch(invalidateSmartOrderList()), 1000);
              }
            }}>
            <p className="font-quicksand-semibold text-sm">
              Apakah kamu yakin ingin menghapus order ini?
            </p>
          </ModalDialog>
        </Modal>
      </RenderIf>
    </>
  );
}

TWOrderlistSmartOrder.propTypes = {
  title: PropTypes.any,
  openSmartOrder: PropTypes.any,
  onClose: PropTypes.func
};

export default TWOrderlistSmartOrder;
