import { ORDER_COST } from 'constants';
import { getStampCostBuy } from 'utils/calculation';
// import { marginPlusShare } from 'utils/formula/margin';
import { object, number } from 'yup';

export const SimpleOrderSchema = object().shape({
  portfolio: number().test(
    'portfolio',
    'Anda belum mempunyai Lot',
    function (value, { parent }) {
      if (parent.orderType === 'SimpleSell') {
        return value > 0;
      }

      return true;
    }
  ),
  amount: number()
    .test('amount', 'Jumlah amount tidak cukup', function (value, { parent }) {
      if (!parent.lotType) {
        return Math.floor(value / (parent.price * 100)) > 0;
      }

      return true;
    })
    .required('Jumlah amount harus diisi'),
  lot: number()
    .min(1, 'Jumlah lot harus diisi.')
    .test('lot', 'Jumlah lot tidak sesuai', function (value, { parent }) {
      if (parent.orderType === 'SimpleSell') {
        return value <= parent.portfolio;
      }

      return true;
    })
    .required('Jumlah lot harus diisi'),
  price: number()
    // .test('price', 'Harga terlalu rendah', function (value, { parent }) {
    //   return !(
    //     value <
    //     parent?.initialPrice - marginPlusShare(parent?.initialPrice) * 10
    //   );
    // })
    // .test('price', 'Harga terlalu tinggi', function (value, { parent }) {
    //   return !(
    //     value >
    //     parent?.initialPrice + marginPlusShare(parent?.initialPrice) * 10
    //   );
    // })
    .min(1, 'Harga saham harus diisi.')
    .required('Harga saham harus diisi'),
  buyingPower: number().test(
    'buyingPower',
    'Maaf, trading limit anda tidak mencukupi.',
    function (value, { parent }) {
      if (parent.orderType === 'SimpleBuy') {
        return (
          value >=
          parent?.lot * 100 * parent?.price +
            Math.ceil(ORDER_COST?.buy * parent?.price * parent?.lot) +
            getStampCostBuy(value)
        );
      }

      return true;
    }
  )
});
