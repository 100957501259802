import PropTypes from 'prop-types';
import IconSearch from 'components/svg/icon-search';
import InputSelect from './input-select';
import 'components/input/css/custom/customize-input-select.css';
import Card from 'components/basic/card';
import IconClose from 'components/svg/icon-close';
import { useEffect, useState } from 'react';
import RenderIf from 'components/basic/render-if';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { uniqueId } from 'lodash';

function InputSelectDropDown({
  options = [],
  name = 'input-dropdown',
  value,
  placeholder = 'Placeholder',
  title = <h1 className="mb-4 dark:text-black-light-1-d">Pilih Parameter</h1>,
  onChange = () => {},
  onClose = () => {},
  onValidate = () => false
}) {
  const [nestedOptions0, setNestedOptions0] = useState([]);
  const [nestedOptions1, setNestedOptions1] = useState([]);
  const [nestedOptions2, setNestedOptions2] = useState([]);

  const [nestedValue0, setNestedValue0] = useState('');
  const [nestedValue1, setNestedValue1] = useState('');
  const [nestedValue2, setNestedValue2] = useState('');

  const [optionsQuery, setOptionsQuery] = useState([]);

  const handleClickNestedOptions0 = (item, eventName) => {
    setNestedOptions1(item?.children);
    setNestedValue0(item?.children?.length > 0 ? item?.name : '');
    setNestedOptions2([]);

    if ((item?.children?.length === 0 || !item?.children?.find(i => !i?.disabled)) && eventName != 'hover') {
      onChange(item);
    }
  };

  const handleClickNestedOptions1 = (item, eventName) => {
    setNestedOptions2(item?.children);
    setNestedValue1(item?.children?.length > 0 ? item?.name : '');
    if (item?.children?.length === 0 && eventName != 'hover') {
      onChange(item);
    }
  };

  const handleClickNestedOptions2 = (item) => {
    setNestedValue2(item?.name);
    onChange(item);
  };

  useEffect(() => {
    if (value) {
      let _nested0 = [];
      let _nested1 = [];
      let _nested2 = [];

      let _nestedv0 = '';
      let _nestedv1 = '';
      let _nestedv2 = '';

      options?.forEach((it1) => {
        if (it1?.name === value) {
          _nestedv0 = it1?.name;
        }

        it1?.children?.forEach((it2) => {
          if (it2?.name === value) {
            _nested1 = it1?.children;
            _nestedv0 = it1?.name;
            _nestedv1 = it2?.name;
          }

          it2?.children?.forEach((it3) => {
            if (it3?.name === value) {
              _nestedv0 = it1?.name;
              _nestedv1 = it2?.name;
              _nestedv2 = it3?.name;
              _nested1 = it1?.children;
              _nested2 = it2?.children;
            }
          });
        });
      });

      setNestedOptions0(_nested0);
      setNestedOptions1(_nested1);
      setNestedOptions2(_nested2);
      setNestedValue0(_nestedv0);
      setNestedValue1(_nestedv1);
      setNestedValue2(_nestedv2);
    }

    setNestedOptions0(options);
  }, [value, options]);

  useEffect(() => {
    let _optionsQuery = [];
    options?.forEach((it1) => {
      if (it1?.children?.length > 0 && it1?.children?.filter(it => it?.disabled)?.length === it1?.children?.length) {
        _optionsQuery.push({ id: uniqueId(), name: it1?.name, fieldName: it1?.fieldName, value: it1?.value  });
      }

      if (it1?.children?.length === 0 && !it1?.disabled) {
        _optionsQuery.push({ id: uniqueId(), name: it1?.name, fieldName: it1?.fieldName, value: it1?.value  });
      }

      it1?.children?.forEach((it2) => {
        if (it2?.children?.length === 0 && !it2?.disabled) {
          _optionsQuery.push({ id: uniqueId(), name: it2?.name, fieldName: it2?.fieldName, value: it2?.value  });
        }

        it2?.children?.forEach((it3) => {
          if (it3?.children?.length === 0 && !it3?.disabled) {
            _optionsQuery.push({ id: uniqueId(), name: it3?.name, fieldName: it3?.fieldName, value: it3?.value  });
          }
        });
      });
    });

    setOptionsQuery(_optionsQuery);
  }, [options]);

  return (
    <Card className="max-w-2xl lg:mx-auto px-6 pt-10 relative mx-4 dark:border dark:border-gray-base-d">
      <button
        onClick={onClose}
        type="button"
        className="right-4 top-4 absolute hover:opacity-75 hover:text-main-base-l transition-all ease-linear duration-100 dark:text-main-base-l">
        <IconClose className="h-5 w-5" />
      </button>

      {title}

      <InputSelect
        name={name}
        value={optionsQuery?.find((item) => item?.name == value)?.id}
        label=""
        size='small'
        className="mb-3 text-xs text-gray-light-0-d w-full customize-input-select--round"
        options={optionsQuery}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(optionsQuery?.find((item) => item?.id == e.target.value));
        }}
        iconRight={<IconSearch className="h-5 w-5 text-main-base-l flex-none" />}
      />

      <div
        className={`grid border-t dark:border-gray-base-d -mx-6 px-6 gap-4 grid-cols-3 dark:text-black-light-1-d`}>
        <ul className="font-quicksand-semibold text-xs border-r dark:border-gray-base-d last:border-r-0 pt-4 pb-4  px-2 -mx-2 h-[350px] overflow-auto">
          {nestedOptions0?.map((item, key) => (
            <li key={key}>
              <button
                type="button"
                disabled={item?.disabled || onValidate(item)}
                // disabled={
                //   checkFundamentalSuffix(item?.name) !=
                //     checkFundamentalSuffix(valueToCompare) &&
                //   item?.children?.length === 0 &&
                //   valueToCompare
                // }
                onClick={() => handleClickNestedOptions0(item)}
                onMouseEnter={() => handleClickNestedOptions0(item, 'hover')}
                className={`py-[6px] text-left hover:text-main-base-l flex justify-between w-full items-center hover:bg-gray-light-l dark:hover:bg-black-dark-d rounded-md px-2 cursor-pointer disabled:text-gray-base-l dark:disabled:text-gray-base-d disabled:hover:bg-white disabled:cursor-not-allowed ${
                  nestedValue0 === item?.name && 'bg-gray-light-l text-main-base-l dark:bg-black-dark-d'
                }`}>
                <span>{item?.name}</span>
                <RenderIf isTrue={item?.children?.length > 0}>
                  <IconChevronSingle className="h-4 w-4 transform rotate-180 flex-none text-main-base-l" />
                </RenderIf>
              </button>
            </li>
          ))}
        </ul>

        <ul className="font-quicksand-semibold text-xs border-r dark:border-gray-base-d last:border-r-0 pt-4 pb-4  px-2 -mx-2 h-[350px] overflow-auto">
          {nestedOptions1?.map((item, key) => (
            <li key={`n1-${key}`}>
              <button
                type="button"
                disabled={item?.disabled || onValidate(item)}
                // disabled={
                //   checkFundamentalSuffix(item?.name) !=
                //     checkFundamentalSuffix(valueToCompare) &&
                //   item?.children?.length === 0 &&
                //   valueToCompare
                // }
                onClick={() => handleClickNestedOptions1(item)}
                onMouseEnter={() => handleClickNestedOptions1(item, 'hover')}
                className={`py-[6px] text-left hover:text-main-base-l flex justify-between w-full items-center hover:bg-gray-light-l dark:hover:bg-black-dark-d rounded-md px-2 cursor-pointer disabled:text-gray-base-l dark:disabled:text-gray-base-d disabled:hover:bg-white disabled:cursor-not-allowed ${
                  nestedValue1 === item?.name && 'bg-gray-light-l text-main-base-l dark:bg-black-dark-d'
                }`}>
                <span>{item?.name}</span>
                <RenderIf isTrue={item?.children?.length > 0}>
                  <IconChevronSingle className="h-4 w-4 transform rotate-180 flex-none text-main-base-l" />
                </RenderIf>
              </button>
            </li>
          ))}
        </ul>

        <ul className="font-quicksand-semibold text-xs border-r dark:border-gray-base-d last:border-r-0 pt-4 pb-4  px-2 -mx-2 h-[350px] overflow-auto">
          {nestedOptions2?.map((item, key) => (
            <li key={`n2-${key}`}>
              <button
                type="button"
                disabled={item?.disabled || onValidate(item)}
                // disabled={
                //   checkFundamentalSuffix(item?.name) !=
                //     checkFundamentalSuffix(valueToCompare) &&
                //   item?.children?.length === 0 &&
                //   valueToCompare
                // }
                onClick={() => handleClickNestedOptions2(item)}
                className={`py-[6px] text-left hover:text-main-base-l flex justify-between w-full items-center hover:bg-gray-light-l dark:hover:bg-black-dark-d rounded-md px-2 cursor-pointer disabled:text-gray-base-l dark:disabled:text-gray-base-d disabled:hover:bg-white disabled:cursor-not-allowed ${
                  nestedValue2 === item?.name && 'bg-gray-light-l text-main-base-l dark:bg-black-dark-d'
                }`}>
                <span>{item?.name}</span>
                <RenderIf isTrue={item?.children?.length > 0}>
                  <IconChevronSingle className="h-4 w-4 transform rotate-180 flex-none text-main-base-l" />
                </RenderIf>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
}


InputSelectDropDown.propTypes = {
  value: PropTypes.any,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  borderLess: PropTypes.bool,
  disabled: PropTypes.bool,
  onValidate: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  title: PropTypes.any,
};

export default InputSelectDropDown;
