import PropTypes from 'prop-types';

const IconMenuOrder = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.3783C4.44772 4.3783 4 4.82601 4 5.3783C4 5.93058 4.44772 6.3783 5 6.3783H9C9.55228 6.3783 10 5.93058 10 5.3783C10 4.82601 9.55228 4.3783 9 4.3783H5ZM6 9.3783C6 8.82601 6.44772 8.3783 7 8.3783H11C11.5523 8.3783 12 8.82601 12 9.3783C12 9.93058 11.5523 10.3783 11 10.3783H7C6.44772 10.3783 6 9.93058 6 9.3783ZM8 13.3783C8 12.826 8.44772 12.3783 9 12.3783H13C13.5523 12.3783 14 12.826 14 13.3783C14 13.9306 13.5523 14.3783 13 14.3783H9C8.44772 14.3783 8 13.9306 8 13.3783Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35719 0.378296H10.6428C11.7266 0.378286 12.6007 0.378278 13.3086 0.436114C14.0375 0.495664 14.6777 0.621476 15.27 0.923264C16.2108 1.40263 16.9757 2.16753 17.455 3.10834C17.7568 3.70064 17.8826 4.34082 17.9422 5.06967C18 5.77755 18 6.65169 18 7.73546V11.0211C18 12.1049 18 12.979 17.9422 13.6869C17.8826 14.4158 17.7568 15.056 17.455 15.6482C16.9757 16.5891 16.2108 17.354 15.27 17.8333C14.6777 18.1351 14.0375 18.2609 13.3086 18.3205C12.6008 18.3783 11.7266 18.3783 10.6428 18.3783H7.35717C6.27339 18.3783 5.39925 18.3783 4.69138 18.3205C3.96253 18.2609 3.32234 18.1351 2.73005 17.8333C1.78924 17.354 1.02433 16.5891 0.544968 15.6482C0.24318 15.056 0.117368 14.4158 0.0578183 13.6869C-1.77398e-05 12.979 -9.75112e-06 12.1049 2.62457e-07 11.0211V7.73549C-9.75112e-06 6.6517 -1.77398e-05 5.77755 0.0578183 5.06967C0.117368 4.34082 0.24318 3.70064 0.544968 3.10834C1.02433 2.16753 1.78924 1.40263 2.73005 0.923264C3.32234 0.621476 3.96253 0.495664 4.69138 0.436114C5.39926 0.378278 6.27341 0.378286 7.35719 0.378296ZM4.85424 2.42947C4.24907 2.47892 3.90138 2.57109 3.63803 2.70528C3.07354 2.9929 2.6146 3.45184 2.32698 4.01632C2.19279 4.27968 2.10062 4.62737 2.05118 5.23254C2.00078 5.84938 2 6.64169 2 7.7783V10.9783C2 12.1149 2.00078 12.9072 2.05118 13.5241C2.10062 14.1292 2.19279 14.4769 2.32698 14.7403C2.6146 15.3048 3.07354 15.7637 3.63803 16.0513C3.90138 16.1855 4.24907 16.2777 4.85424 16.3271C5.47108 16.3775 6.26339 16.3783 7.4 16.3783H10.6C11.7366 16.3783 12.5289 16.3775 13.1458 16.3271C13.7509 16.2777 14.0986 16.1855 14.362 16.0513C14.9265 15.7637 15.3854 15.3048 15.673 14.7403C15.8072 14.4769 15.8994 14.1292 15.9488 13.5241C15.9992 12.9072 16 12.1149 16 10.9783V7.7783C16 6.64169 15.9992 5.84938 15.9488 5.23254C15.8994 4.62737 15.8072 4.27968 15.673 4.01632C15.3854 3.45184 14.9265 2.9929 14.362 2.70528C14.0986 2.57109 13.7509 2.47892 13.1458 2.42947C12.5289 2.37907 11.7366 2.3783 10.6 2.3783H7.4C6.26339 2.3783 5.47108 2.37907 4.85424 2.42947Z"
      />
    </svg>
  );
};

IconMenuOrder.propTypes = {
  className: PropTypes.string
};

IconMenuOrder.defaultProps = {
  className: '',
};

export default IconMenuOrder;
