import { useEffect } from 'react';

export function useKeyboardShortcut({ key, onKeyPressed, keys = [], combine }) {
  useEffect(() => {
    function keyDownHandler(e) {
      if (keys?.includes(e.key) && !combine) {
        e.preventDefault();
        onKeyPressed();
      }

      if (e.key === key && !combine) {
        e.preventDefault();
        onKeyPressed();
      }

      if (e.key === key && e?.[combine] && combine) {
        e.preventDefault();
        onKeyPressed();
      }

      if (keys?.includes(e.key) && e?.[combine] && combine) {
        e.preventDefault();
        onKeyPressed();
      }
    }

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);
}
