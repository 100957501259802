import { Helmet } from 'react-helmet-async';
import SectionStockTrendingList from './_partials/section-stock-trending-list';
import SectionStockThematicList from './_partials/section-stock-thematic-list';
import { useContext, useEffect, useState } from 'react';
import TWStockCalendar from '../_partials/tw-stock-calendar';
import SectionCommodityList from './_partials/section-stock-commodity-list';
// import TVChartTreemap from 'components/chart/tradingview/TVChartTreemap';
import Card from 'components/basic/card';
import { ModalContext } from 'components/modal/context/modalContext';
import { triggerSegmentEvent } from 'lib/segment';
import SectionStockIndex from './_partials/section-stock-index';
import TWBandarmology from '../_partials/tw-bandarmology';
import TWTradingIdeasFull from '../_partials/tw-trading-ideas-full';
import TabsStockMarket from './_partials/tabs-stock-market';

function PageTradingSearch() {
  const [firstRender, setFirsRender] = useState(true);
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    if (firstRender) {
      triggerSegmentEvent({ event: `TR Stock Market Screen Viewed` });
      setFirsRender(false);
      window.scrollTo(0, 0);
    }
  }, [handleModal, firstRender]);

  return (
    <div>
      <div className="p-4">
        <Helmet>
          <title>Stock Market | Pina Trade</title>
        </Helmet>

        <TabsStockMarket />

        <div className="flex flex-col lg:flex-row">
          <div className="flex-none lg:w-[250px] xl:w-[300px] zoom-2">
            <div className="mb-3">
              <SectionStockThematicList />
            </div>

            <div className="mb-3">
              <SectionCommodityList />
            </div>
          </div>

          <div className="flex-auto p-4 px-0 lg:px-3 pt-0 zoom-2">
            <div className="grid grid-cols-1">
              <div className="mb-3">
                <Card className="rounded-md shadow-none relative">
                  <TWTradingIdeasFull isZoomOut />
                </Card>
              </div>

              <div className="mb-3">
                <SectionStockIndex />
              </div>

              <div className="mb-3">
                <TWBandarmology />
              </div>
            </div>
          </div>

          <div className="flex-none lg:w-[250px] xl:w-[300px]">
            <div className="mb-3 zoom-2">
              <SectionStockTrendingList isZoomOut />
            </div>

            <div className="mb-3 zoom-2">
              <TWStockCalendar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTradingSearch;
