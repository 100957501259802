/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { currency, formatIndonesianKMBT } from 'utils/format';
import { useEffect, useState } from 'react';
import { max, maxBy } from 'lodash';
import { useSelector } from 'react-redux';
import RenderIf from 'components/basic/render-if';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import IconChartWaterfall from 'components/svg/icon-chart-waterfall';
import IconOrderbook from 'components/svg/icon-orderbook';
import InputSelect from 'components/input/input-select';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { triggerSegmentEvent } from 'lib/segment';
import TVChartOrderbook from 'components/chart/tradingview/TVChartOrderbook';
import BlinkText from 'components/basic/text-blink';
import { useRealtimeOrderbook } from 'hooks/useRealtimeOrderbook';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetIntraDataOrderBookQuery } from 'services/rtk-query/trade';

function StockSummary({ symbol }) {
  const { isShowIepIev } = useSelector(({ pinaPro }) => pinaPro);
  const { stockDetail, isLoadingStock: loadingStockDetail } =
    useRealtimeStock(symbol);

  return (
    <div className="text-2xs grid grid-cols-2 gap-1.5 gap-x-4">
      <div className="flex justify-between">
        <span className="text-gray-light-0-d dark:text-black-light-0-d">
          Prev
        </span>
        <span className="font-quicksand-semibold dark:text-black-light-1-d">
          <RenderIf isTrue={loadingStockDetail}>
            <SkeletonTheme
              containerClassName="leading-none"
              width="50px"
              height=".8rem"
              borderRadius="4px"
            />
          </RenderIf>

          <RenderIf isTrue={!loadingStockDetail}>
            <BlinkText
              value={currency(stockDetail?.previousLastTradedPrice || 0, {
                removeSymbol: true
              })}
            />
          </RenderIf>
        </span>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-light-0-d dark:text-black-light-0-d">
          Open
        </span>
        <span className="font-quicksand-semibold dark:text-black-light-1-d">
          <RenderIf isTrue={loadingStockDetail}>
            <SkeletonTheme
              containerClassName="leading-none"
              width="50px"
              height=".8rem"
              borderRadius="4px"
            />
          </RenderIf>

          <RenderIf isTrue={!loadingStockDetail}>
            <BlinkText
              value={currency(stockDetail?.openPrice || 0, {
                removeSymbol: true
              })}
            />
          </RenderIf>
        </span>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-light-0-d dark:text-black-light-0-d">
          Lot
        </span>
        <span className="font-quicksand-semibold dark:text-black-light-1-d">
          <RenderIf isTrue={loadingStockDetail}>
            <SkeletonTheme
              containerClassName="leading-none"
              width="50px"
              height=".8rem"
              borderRadius="4px"
            />
          </RenderIf>

          <RenderIf isTrue={!loadingStockDetail}>
            <BlinkText
              value={formatIndonesianKMBT(stockDetail?.tradableLot || 0)}
            />
          </RenderIf>
        </span>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-light-0-d dark:text-black-light-0-d">
          Val
        </span>
        <span className="font-quicksand-semibold dark:text-black-light-1-d">
          <RenderIf isTrue={loadingStockDetail}>
            <SkeletonTheme
              containerClassName="leading-none"
              width="50px"
              height=".8rem"
              borderRadius="4px"
            />
          </RenderIf>

          <RenderIf isTrue={!loadingStockDetail}>
            <BlinkText
              value={formatIndonesianKMBT(stockDetail?.tradableValue || 0)}
            />
          </RenderIf>
        </span>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-light-0-d dark:text-black-light-0-d">
          High
        </span>
        <span className="text-green-base-l font-quicksand-semibold">
          <RenderIf isTrue={loadingStockDetail}>
            <SkeletonTheme
              containerClassName="leading-none"
              width="50px"
              height=".8rem"
              borderRadius="4px"
            />
          </RenderIf>

          <RenderIf isTrue={!loadingStockDetail}>
            <BlinkText
              value={currency(stockDetail?.highTradedPrice || 0, {
                removeSymbol: true
              })}
            />
          </RenderIf>
        </span>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-light-0-d dark:text-black-light-0-d">
          Low
        </span>
        <span className="text-red-base-l font-quicksand-semibold">
          <RenderIf isTrue={loadingStockDetail}>
            <SkeletonTheme
              containerClassName="leading-none"
              width="50px"
              height=".8rem"
              borderRadius="4px"
            />
          </RenderIf>

          <RenderIf isTrue={!loadingStockDetail}>
            <BlinkText
              value={currency(stockDetail?.lowTradedPrice || 0, {
                removeSymbol: true
              })}
            />
          </RenderIf>
        </span>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-light-0-d dark:text-black-light-0-d">
          Avg
        </span>
        <span className="font-quicksand-semibold dark:text-black-light-1-d">
          <RenderIf isTrue={loadingStockDetail}>
            <SkeletonTheme
              containerClassName="leading-none"
              width="50px"
              height=".8rem"
              borderRadius="4px"
            />
          </RenderIf>

          <RenderIf isTrue={!loadingStockDetail}>
            <BlinkText
              value={currency(stockDetail?.averagePrice || 0, {
                removeSymbol: true
              })}
            />
          </RenderIf>
        </span>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-light-0-d dark:text-black-light-0-d">
          Freq
        </span>
        <span className="font-quicksand-semibold dark:text-black-light-1-d">
          <RenderIf isTrue={loadingStockDetail}>
            <SkeletonTheme
              containerClassName="leading-none"
              width="50px"
              height=".8rem"
              borderRadius="4px"
            />
          </RenderIf>

          <RenderIf isTrue={!loadingStockDetail}>
            <BlinkText
              value={formatIndonesianKMBT(stockDetail?.frequency || 0)}
            />
          </RenderIf>
        </span>
      </div>

      <RenderIf isTrue={isShowIepIev}>
        <div className="flex justify-between">
          <span className="text-gray-light-0-d dark:text-black-light-0-d">
            IEP
          </span>
          <span className="font-quicksand-semibold dark:text-black-light-1-d">
            <RenderIf isTrue={loadingStockDetail}>
              <SkeletonTheme
                containerClassName="leading-none"
                width="50px"
                height=".8rem"
                borderRadius="4px"
              />
            </RenderIf>

            <RenderIf isTrue={!loadingStockDetail}>
              <BlinkText
                value={currency(stockDetail?.iep || 0, { removeSymbol: true })}
              />
            </RenderIf>
          </span>
        </div>

        <div className="flex justify-between">
          <span className="text-gray-light-0-d dark:text-black-light-0-d">
            IEV
          </span>
          <span className="font-quicksand-semibold dark:text-black-light-1-d">
            <RenderIf isTrue={loadingStockDetail}>
              <SkeletonTheme
                containerClassName="leading-none"
                width="50px"
                height=".8rem"
                borderRadius="4px"
              />
            </RenderIf>

            <RenderIf isTrue={!loadingStockDetail}>
              <BlinkText
                value={currency(stockDetail?.iev || 0, { removeSymbol: true })}
              />
            </RenderIf>
          </span>
        </div>
      </RenderIf>
    </div>
  );
}

function Orderbook({
  rows,
  initPrice,
  disableAsk,
  maxHeight,
  onInitPrice,
  onClickPrice,
  symbol
}) {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { stockDetail, isLoadingStock: loadingStockDetail } =
    useRealtimeStock(symbol);
  const { orderBooks, loadingOrderBooks } = useRealtimeOrderbook(
    symbol,
    isBursaOpen ? stockDetail?.bidPrice : stockDetail?.lastTradedPrice
  );

  const [firstInitPrice, setFirstInitPrice] = useState(true);
  const [maxLengthOrderbook, setMaxLengthOrderbook] = useState(rows);
  const [maxAsk, setMaxAsk] = useState(0);
  const [maxBid, setMaxBid] = useState(0);

  useEffect(() => {
    if (
      !initPrice &&
      firstInitPrice &&
      !loadingOrderBooks &&
      !loadingStockDetail &&
      orderBooks?.ask?.[0]?.price
    ) {
      setFirstInitPrice(false);
      onInitPrice(orderBooks?.ask?.[0]?.price || stockDetail?.lastTradedPrice);
    }
  }, [orderBooks, loadingStockDetail, firstInitPrice]);

  useEffect(() => {
    if (orderBooks && !loadingOrderBooks) {
      let maxBid = orderBooks?.bid?.filter((item) => item?.price)?.length;
      let maxAsk = orderBooks?.ask?.filter((item) => item?.price)?.length;

      setMaxLengthOrderbook(max([maxBid, maxAsk, rows]));
    }

    setMaxAsk(maxBy(orderBooks?.ask, 'lot'));
    setMaxBid(maxBy(orderBooks?.bid, 'lot'));
  }, [orderBooks, loadingOrderBooks, rows]);

  return (
    <OverlayScrollbarsComponent
      className="overlayscrollbars-host-small overflow-hidden relative"
      element="span"
      options={{ resize: 'horizontal' }}
      style={{ height: 'calc(100% - 8rem)' }}>
      <table className="w-full text-center text-2xs z-10 relative">
        <thead>
          <tr className="sticky top-0 font-quicksand-regular z-20 card-widget-gradient">
            <th className="text-black-light-0-d dark:text-black-light-1-d py-2 p-2">
              LOT
            </th>
            <th className="text-black-light-0-d dark:text-black-light-1-d py-2 p-2">
              BID
            </th>
            <th className="text-black-light-0-d dark:text-black-light-1-d py-2 p-2">
              OFFER
            </th>
            <th className="text-black-light-0-d dark:text-black-light-1-d py-2 p-2">
              LOT
            </th>
          </tr>
        </thead>

        <tbody>
          {Array(rows > maxLengthOrderbook ? maxLengthOrderbook : rows)
            .fill(1)
            .map((el, key) => (
              <tr key={key} className="font-quicksand-semibold">
                <td className="px-[6px] py-[3px] w-1/4 dark:border-gray-base-d dark:text-black-light-1-d">
                  <RenderIf isTrue={loadingOrderBooks || loadingStockDetail}>
                    <SkeletonTheme
                      containerClassName="leading-none"
                      width="50px"
                      height=".8rem"
                      borderRadius="4px"
                    />
                  </RenderIf>

                  <RenderIf isTrue={!loadingOrderBooks && !loadingStockDetail}>
                    <BlinkText
                      value={currency(orderBooks?.bid?.[key]?.lot || 0, {
                        removeSymbol: true
                      })}
                    />
                  </RenderIf>
                </td>
                <td
                  role="button"
                  title="Order di harga"
                  onClick={() => {
                    if (Number(orderBooks?.bid[key]?.price) > 0 || key === 0) {
                      onClickPrice(orderBooks?.bid[key]?.price);
                    }
                  }}
                  className={`px-[6px] py-[3px] w-1/4 dark:border-gray-base-d relative ${
                    Number(orderBooks?.bid?.[key]?.price) >
                    Number(stockDetail?.previousLastTradedPrice)
                      ? 'text-green-base-l'
                      : Number(orderBooks?.bid?.[key]?.price) <
                        Number(stockDetail?.previousLastTradedPrice)
                      ? 'text-red-base-l'
                      : 'dark:text-black-light-1-d'
                  }`}>
                  <RenderIf isTrue={!loadingOrderBooks && !loadingStockDetail}>
                    <div
                      className="bg-red-base-l h-[18px] bg-opacity-[8%] absolute right-[1px] top-[2px] rounded-l-md"
                      style={{
                        width:
                          (orderBooks?.bid?.[key]?.lot /
                            Number(maxBid?.lot || 0)) *
                            190 +
                          '%'
                      }}></div>
                  </RenderIf>
                  <span
                    className={`px-1 rounded-md relative z-10 ${
                      orderBooks?.bid?.[key]?.price || key === 0
                        ? 'cursor-pointer hover:font-quicksand-semibold'
                        : ''
                    }
                  ${
                    Number(initPrice) ===
                      Number(orderBooks?.bid?.[key]?.price) &&
                    orderBooks?.bid?.[key]?.price &&
                    'border border-main-base-l font-quicksand-semibold'
                  }
                `}>
                    <RenderIf isTrue={loadingOrderBooks || loadingStockDetail}>
                      <SkeletonTheme
                        containerClassName="leading-none"
                        width="50px"
                        height=".8rem"
                        borderRadius="4px"
                      />
                    </RenderIf>

                    <RenderIf
                      isTrue={!loadingOrderBooks && !loadingStockDetail}>
                      {currency(orderBooks?.bid?.[key]?.price || 0, {
                        removeSymbol: true
                      })}
                    </RenderIf>
                  </span>
                </td>
                <td
                  role="button"
                  title="Order di harga"
                  onClick={() => {
                    if (
                      (Number(orderBooks?.ask[key]?.price) > 0 &&
                        !disableAsk) ||
                      key === 0
                    ) {
                      onClickPrice(orderBooks?.ask[key]?.price);
                    }
                  }}
                  className={`px-[6px] py-[3px] w-1/4 dark:border-gray-base-d relative ${
                    Number(orderBooks?.ask?.[key]?.price) >
                    Number(stockDetail?.previousLastTradedPrice)
                      ? 'text-green-base-l'
                      : Number(orderBooks?.ask?.[key]?.price) <
                        Number(stockDetail?.previousLastTradedPrice)
                      ? 'text-red-base-l'
                      : 'dark:text-black-light-1-d'
                  }`}>
                  <RenderIf isTrue={!loadingOrderBooks && !loadingStockDetail}>
                    <div
                      className="bg-green-base-l h-[18px] bg-opacity-[8%] absolute left-[1px] top-[2px] rounded-r-md"
                      style={{
                        width:
                          (orderBooks?.ask?.[key]?.lot /
                            Number(maxAsk?.lot || 0)) *
                            190 +
                          '%'
                      }}></div>
                  </RenderIf>
                  <span
                    className={`px-1 rounded-md relative z-10 ${
                      (orderBooks?.ask?.[key]?.price && !disableAsk) ||
                      key === 0
                        ? 'cursor-pointer hover:font-quicksand-semibold'
                        : ''
                    } 
                  ${
                    Number(initPrice) ===
                      Number(orderBooks?.ask?.[key]?.price) &&
                    orderBooks?.ask?.[key]?.price &&
                    'border border-main-base-l font-quicksand-semibold'
                  }
                  `}>
                    <RenderIf isTrue={loadingOrderBooks || loadingStockDetail}>
                      <SkeletonTheme
                        containerClassName="leading-none"
                        width="50px"
                        height=".8rem"
                        borderRadius="4px"
                      />
                    </RenderIf>

                    <RenderIf
                      isTrue={!loadingOrderBooks && !loadingStockDetail}>
                      {currency(orderBooks?.ask?.[key]?.price || 0, {
                        removeSymbol: true
                      })}
                    </RenderIf>
                  </span>
                </td>
                <td className="px-[6px] py-[3px] w-1/4 dark:border-gray-base-d dark:text-black-light-1-d">
                  <RenderIf isTrue={loadingOrderBooks || loadingStockDetail}>
                    <SkeletonTheme
                      containerClassName="leading-none"
                      width="50px"
                      height=".8rem"
                      borderRadius="4px"
                    />
                  </RenderIf>

                  <RenderIf isTrue={!loadingOrderBooks && !loadingStockDetail}>
                    <BlinkText
                      value={currency(orderBooks?.ask?.[key]?.lot || 0, {
                        removeSymbol: true
                      })}
                    />
                  </RenderIf>
                </td>
              </tr>
            ))}
          <tr className="sticky bottom-0 z-20 font-quicksand-semibold dark:text-black-light-1-d card-widget-gradient">
            <td className="px-[6px] py-[3px]">
              <BlinkText
                value={currency(orderBooks?.totalBidLot || 0, {
                  removeSymbol: true
                })}
              />
            </td>
            <td className="px-[6px] py-[3px]"></td>
            <td className="px-[6px] py-[3px]"></td>
            <td className="px-[6px] py-[3px]">
              <BlinkText
                value={currency(orderBooks?.totalAskLot || 0, {
                  removeSymbol: true
                })}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </OverlayScrollbarsComponent>
  );
}

function TWOrderBook({
  symbol,
  onClickPrice = () => {},
  rows = 5,
  initPrice = false,
  isExpandRow = false,
  disableAsk = false,
  showSummary = true,
  maxHeight = 191,
  isShowChartOrderbook = false,
  widgetSettings = {},
  onInitPrice = () => {},
  onChangeWidgetSettings = () => {}
}) {
  const [showChart, setShowChart] = useState(
    widgetSettings?.showRrderbookChart || false
  );
  const [_rows, setRows] = useState(widgetSettings?.orderbookRows || rows);
  const { data: resOrderBooks, isLoading } = useGetIntraDataOrderBookQuery(
    symbol,
    { refetchOnMountOrArgChange: true }
  );
  const maxLengthOrderbook = max([
    resOrderBooks?.data?.[0]?.bid?.length,
    resOrderBooks?.data?.[0]?.offer?.length
  ]);

  return (
    <>
      {showSummary && <StockSummary symbol={symbol} />}

      <div className="flex items-center justify-between mb-2 relative z-20">
        <RenderIf isTrue={isShowChartOrderbook && isExpandRow}>
          <div className="flex justify-end mt-3 mr-2">
            <button
              onClick={() => {
                setShowChart(!showChart);
                onChangeWidgetSettings({
                  ...widgetSettings,
                  showRrderbookChart: !showChart
                });
                triggerSegmentEvent({
                  event: 'TR Stock Detail Chart Orderbook Clicked'
                });
              }}
              className="relative rounded-3xl px-1 py-[6px] flex items-center transition-all ease-linear duration-150 hover:opacity-75 ring-0 focus:ring-0"
              type="button"
              title={showChart ? 'Lihat Chart Orderbook' : 'Lihat Orderbook'}>
              <span
                className={`p-1 text-white dark:text-black-base-d bg-main-base-l rounded-full inline-flex justify-center items-center transition-all ease-linear duration-150 absolute transform left-0 ${
                  !showChart ? 'translate-x-[74px]' : 'translate-x-0'
                }`}>
                <RenderIf isTrue={!showChart}>
                  <IconOrderbook className="h-2 w-2" />
                </RenderIf>

                <RenderIf isTrue={showChart}>
                  <IconChartWaterfall className="h-2 w-2" />
                </RenderIf>
              </span>

              <span
                className={`text-2xs font-quicksand-semibold text-main-base-l transition-all ease-linear duration-150 ${
                  !showChart ? 'pl-1 pr-5' : 'pl-5 pr-1'
                }`}>
                {!showChart ? 'Orderbook' : 'Chart'}
              </span>
            </button>
          </div>
        </RenderIf>

        <RenderIf isTrue={isExpandRow && !showChart && !isLoading}>
          <div className="flex justify-end mt-3 items-center w-[64px] relative z-10">
            <InputSelect
              value={_rows}
              label={false}
              placeholder=""
              className="mb-0"
              size="small-2"
              options={[
                { id: 5, name: 5 },
                { id: 10, name: 10 },
                { id: 20, name: 20 },
                { id: 30, name: 30 },
                { id: maxLengthOrderbook, name: 'ALL' }
              ]}
              onChange={(e) => {
                setRows(Number(e?.target?.value || 5));
                onChangeWidgetSettings({
                  ...widgetSettings,
                  orderbookRows: Number(e?.target?.value || 5)
                });
              }}
            />
          </div>
        </RenderIf>
      </div>

      <RenderIf isTrue={showChart}>
        <TVChartOrderbook symbol={symbol} onClickPrice={onClickPrice} />
      </RenderIf>

      <RenderIf isTrue={!showChart}>
        <Orderbook
          symbol={symbol}
          rows={_rows}
          initPrice={initPrice}
          disableAsk={disableAsk}
          maxHeight={maxHeight}
          onClickPrice={onClickPrice}
          onInitPrice={onInitPrice}
        />
      </RenderIf>
    </>
  );
}

TWOrderBook.propTypes = {
  symbol: PropTypes.string,
  rows: PropTypes.number,
  onClickPrice: PropTypes.func,
  onInitPrice: PropTypes.func,
  initPrice: PropTypes.any,
  isExpandRow: PropTypes.bool,
  disableAsk: PropTypes.bool,
  isShowChartOrderbook: PropTypes.bool,
  expandColor: PropTypes.string,
  maxHeight: PropTypes.number,
  showSummary: PropTypes.bool,
  widgetSettings: PropTypes.object,
  onChangeWidgetSettings: PropTypes.func
};

export default TWOrderBook;
