import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import Tooltip from 'components/basic/tooltip';
import IconInfo from 'components/svg/icon-info';
import { usePortfolio } from 'hooks/usePortfolio';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useDispatch } from 'react-redux';
import { setCoachMarkPortfolio } from 'store/pina-pro';
import { currency } from 'utils/format';
import BlinkText from 'components/basic/text-blink';

function PortfolioSummary({ onInitCoachMark = () => {}}) {
  const dispatch = useDispatch();
  const ref0 = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  const { totalInvested, totalMktValue, totalUnrealized, totalPotGainPercent, isLoading } = usePortfolio();

  const [tempData, setTemData] = useState(null);
  const [firstRender, setFirstRender] = useState(true);

  const getClassColor = (value) => {
    let className = '';
    if (Number(value) > 0) className = 'text-green-base-l';
    if (Number(value) < 0) className = 'text-red-base-l';

    return className;
  };

  useEffect(() => {
    let newObj = { totalInvested, totalMktValue, totalUnrealized, totalPotGainPercent };
    if (!isEqual(newObj, tempData)) {
      setTemData(newObj);
    }
  }, [totalInvested, totalMktValue, totalUnrealized, totalPotGainPercent, tempData]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      onInitCoachMark([
        {
          activate: true,
          reference: ref0,
          tooltip: { position: 'bottom' },
          component: (
            <div className='block p-2 max-w-[280px]'>
              <h5 className='font-quicksand-semibold text-sm dark:text-main-base-l'>1/6</h5>
              <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">Total Nilai Investasi</h5>
              <p className="text-gray-light-0-d text-xs mb-2">
                Total nilai dari semua investasi saham kamu. Lihat perubahan dari nilai investasi kamu seiring waktu, termasuk keuntungan atau kerugian yang dihasilkan dari pergerakan harga saham.
              </p>
    
              <div className='flex justify-end font-quicksand-semibold'>
                <button onClick={() => dispatch(setCoachMarkPortfolio(2))} type='button' className='text-main-base-l text-sm block'>Selanjutnya</button>
              </div>
            </div>
          )
        },
        {
          activate: true,
          reference: ref1,
          tooltip: { position: 'bottom' },
          component: (
            <div className='block p-2 max-w-[280px]'>
              <h5 className='font-quicksand-semibold text-sm dark:text-main-base-l'>2/6</h5>
              <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">Nilai Investasi</h5>
              <p className="text-gray-light-0-d text-xs mb-2">
                Jumlah uang yang Kamu sudah keluarkan untuk membeli saham. Semakin tinggi nilainya, semakin banyak uang yang Kamu sudah tanamkan di pasar saham.
              </p>
    
              <div className='flex justify-between font-quicksand-semibold'>
                <button onClick={() => dispatch(setCoachMarkPortfolio(1))} type='button' className='text-main-base-l text-sm block'>Kembali</button>
                <button onClick={() => dispatch(setCoachMarkPortfolio(3))} type='button' className='text-main-base-l text-sm block'>Selanjutnya</button>
              </div>
            </div>
          )
        },
        {
          activate: true,
          reference: ref2,
          tooltip: { position: 'bottom' },
          component: (
            <div className='block p-2 max-w-[280px]'>
              <h5 className='font-quicksand-semibold text-sm dark:text-main-base-l'>3/6</h5>
              <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">Keuntungan Sementara</h5>
              <p className="text-gray-light-0-d text-xs mb-2">
                Adalah hasil positif yang Kamu dapatkan dari investasi Kamu tapi belum Kamu jual atau {"realisasikan"} keuangannya.
              </p>
    
              <div className='flex justify-between font-quicksand-semibold'>
                <button onClick={() => dispatch(setCoachMarkPortfolio(2))} type='button' className='text-main-base-l text-sm block'>Kembali</button>
                <button onClick={() => dispatch(setCoachMarkPortfolio(4))} type='button' className='text-main-base-l text-sm block'>Selanjutnya</button>
              </div>
            </div>
          )
        },
        {
          activate: true,
          reference: ref3,
          tooltip: { position: 'bottom' },
          component: (
            <div className='block p-2 max-w-[280px]'>
              <h5 className='font-quicksand-semibold text-sm dark:text-main-base-l'>4/6</h5>
              <h5 className="font-quicksand-semibold text-base mb-1 dark:text-black-light-1-d">% POTG/L</h5>
              <p className="text-gray-light-0-d text-xs mb-2">
                Suatu ukuran atau indikasi seberapa jauh Kamu berhasil mendapatkan untung atau rugi dibandingkan dengan target atau biaya beli.
              </p>
    
              <div className='flex justify-between font-quicksand-semibold'>
                <button onClick={() => dispatch(setCoachMarkPortfolio(3))} type='button' className='text-main-base-l text-sm block'>Kembali</button>
                <button onClick={() => dispatch(setCoachMarkPortfolio(5))} type='button' className='text-main-base-l text-sm block'>Selanjutnya</button>
              </div>
            </div>
          )
        },
      ])
    }
  }, [dispatch, onInitCoachMark, firstRender]);

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-3 mb-3">
      <div ref={ref0}>
        <Card className="p-4 relative">
          <h5 className="font-quicksand-semibold text-sm">Total Nilai Investasi</h5>

          <RenderIf isTrue={isLoading}>
            <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
          </RenderIf>

          <RenderIf isTrue={!isLoading}>
            <h2 className={`font-quicksand-bold text-2xl`}>
              <BlinkText value={currency(totalMktValue || 0, { removeSymbol: true })} />
            </h2>
          </RenderIf>

          <button type='button' className='absolute top-2 right-2'>
            <Tooltip
              tooltipHTML={
                <div className='max-w-[250px] min-w-[250px]'>
                  <h5 className='text-sm font-quicksand-medium text-left mb-1'>Total Nilai Investasi</h5>
                  <p className="text-xs font-quicksand-regular text-left">
                    Total nilai dari semua investasi saham kamu. Lihat perubahan dari nilai investasi kamu seiring waktu, termasuk keuntungan atau kerugian yang dihasilkan dari pergerakan harga saham.
                  </p>
                </div>
              }
              position="bottom-right">
              <IconInfo className='h-4 w-4 text-black-base-l dark:text-black-light-1-d hover:text-main-base-l'/>
            </Tooltip>
          </button>
        </Card>
      </div>

      <div ref={ref1}>
        <Card className="p-4 relative">
          <h5 className="font-quicksand-semibold text-sm">Nilai Investasi</h5>

          <RenderIf isTrue={isLoading}>
            <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
          </RenderIf>

          <RenderIf isTrue={!isLoading}>
            <h2 className={`font-quicksand-bold text-2xl`}>
              <BlinkText value={currency(totalInvested || 0, { removeSymbol: true })} />
            </h2>
          </RenderIf>

          <button type='button' className='absolute top-2 right-2'>
            <Tooltip
              tooltipHTML={
                <div className='max-w-[250px] min-w-[250px]'>
                  <h5 className='text-sm font-quicksand-medium text-left mb-1'>Nilai Investasi</h5>
                  <p className="text-xs font-quicksand-regular text-left">
                    Jumlah uang yang Kamu sudah keluarkan untuk membeli saham. Semakin tinggi nilainya, semakin banyak uang yang Kamu sudah tanamkan di pasar saham.
                  </p>
                </div>
              }
              position="bottom-right">
              <IconInfo className='h-4 w-4 text-black-base-l dark:text-black-light-1-d hover:text-main-base-l'/>
            </Tooltip>
          </button>
        </Card>
      </div>

      <div ref={ref2}>
        <Card className="p-4 relative">
          <h5 className="font-quicksand-semibold text-sm">Keuntungan Sementara</h5>

          <RenderIf isTrue={isLoading}>
            <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
          </RenderIf>

          <RenderIf isTrue={!isLoading}>
            <h2 className={`font-quicksand-bold text-2xl ${getClassColor(totalUnrealized)}`}>
              <BlinkText value={currency(totalUnrealized || 0, { removeSymbol: true })} />
            </h2>
          </RenderIf>

          <button type='button' className='absolute top-2 right-2'>
            <Tooltip
              tooltipHTML={
                <div className='max-w-[250px] min-w-[250px]'>
                  <h5 className='text-sm font-quicksand-medium text-left mb-1'>Keuntungan Sementara</h5>
                  <p className="text-xs font-quicksand-regular text-left">
                    Adalah hasil positif yang Kamu dapatkan dari investasi Kamu tapi belum Kamu jual atau {"realisasikan"} keuangannya. 
                  </p>
                </div>
              }
              position="bottom-right">
              <IconInfo className='h-4 w-4 text-black-base-l dark:text-black-light-1-d hover:text-main-base-l'/>
            </Tooltip>
          </button>
        </Card>
      </div>

      <div ref={ref3}>
        <Card className="p-4 relative">
          <h5 className="font-quicksand-semibold text-sm">% POTG/L</h5>
          
          <RenderIf isTrue={isLoading}>
            <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
          </RenderIf>

          <RenderIf isTrue={!isLoading}>
            <h2 className={`font-quicksand-bold text-2xl ${getClassColor(totalPotGainPercent)}`}>
              <BlinkText value={currency(totalPotGainPercent || 0, { removeSymbol: true })} />
            </h2>
          </RenderIf>

          <button type='button' className='absolute top-2 right-2'>
            <Tooltip
              tooltipHTML={
                <div className='max-w-[250px] min-w-[250px]'>
                  <h5 className='text-sm font-quicksand-medium text-left mb-1'>% POTG/L</h5>
                  <p className="text-xs font-quicksand-regular text-left">
                    Suatu ukuran atau indikasi seberapa jauh Kamu berhasil mendapatkan untung atau rugi dibandingkan dengan target atau biaya beli.
                  </p>
                </div>
              }
              position="bottom-right">
              <IconInfo className='h-4 w-4 text-black-base-l dark:text-black-light-1-d hover:text-main-base-l'/>
            </Tooltip>
          </button>
        </Card>
      </div>
    </div>
  );
}


PortfolioSummary.propTypes = {
  onInitCoachMark: PropTypes.func,
};

export default PortfolioSummary;
