import PropTypes from 'prop-types';
// import { Outlet } from 'react-router-dom'

export const LAYOUT_BLANK = 'blank';

const BlankLayout = ({ children }) => {
  return (
    <main>
      {/* <Outlet /> */}
      {children}
    </main>
  );
}

BlankLayout.propTypes = {
  children: PropTypes.element
};
export default BlankLayout;