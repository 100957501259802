import { object, string, bool } from 'yup';

export const RDNStep01Validation = object().shape({
  phoneNumber: string()
    .required('Nomor Handphone tidak boleh kosong')
    .test('phoneNumber', 'Nomor Handphone tidak sesuai', function (value) {
      const _value = '0' + value?.substring(3, 20);
      const regex = /^08([1-3]|[5-9])[0-9]*$/;
      return regex.test(_value) && _value.length > 9;
    }),
  otp: string().test('otp', 'OTP berisi 4 digit angka', function (value, { parent }) {
    if (!parent?.isSendOTP) return true;
    return String(value)?.length === 4
  }),
  agreement: bool().oneOf(
    [true],
    'Kamu harus menyetujui syarat dan ketentuan order ini.'
  )
});
