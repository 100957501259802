import { object, string, number } from 'yup';

export const RDNStep03Validation = object().shape({
  motherName: string().required('Nama Gadis Ibu Kandung tidak boleh kosong'),
  educationLevel: string().required('Pendidikan Terakhir tidak boleh kosong'),
  sourceFunds: string().required('Sumber Penghasilan tidak boleh kosong'),
  occupation: string().required('Jenis Pekerjaan tidak boleh kosong'),
  purposeOfAccount: string().required('Tujuan Buka Akun tidak boleh kosong'),
  monthlyIncome: number().required('Penghasilan Per Bulan tidak boleh kosong'),
  bankId: number().required('Nama Bank tidak boleh kosong'),
  bankAccountHolder: string().required('Nama Pemilik Rekening Bank tidak boleh kosong'),
  bankAccountNo: number().required('Nomor Rekening Bank tidak boleh kosong'),
  // npwpNo: string().matches(/^([\d]{2})[.]([\d]{3})[.]([\d]{3})[.][\d][-]([\d]{3})[.]([\d]{3})$/g, "Nomor NPWP tidak sesuai").required('Nomor NPWP tidak boleh kosong'),
  // npwpRegistrationDate: string().required('Tanggal Registrasi NPWP tidak boleh kosong'),
  imgFaceUrl: string().required('Foto Selfie tidak boleh kosong'),
});
