import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import { useParams } from 'react-router-dom';
import {
  useGetMultipleStockByCodesQuery,
  useGetStockThematicByIdQuery,
  useGetStockThematicsQuery
} from 'services/rtk-query/stock';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { automatedNumberFormat } from 'utils/format';
import IconSorting from 'components/svg/icon-sorting';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import ActionButton from 'components/basic/action-button';
import IconStarDelete from 'components/svg/icon-star-delete';
import IconStar from 'components/svg/icon-star';
import {
  useCreateWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistQuery
} from 'services/rtk-query/watchlist';
import useToastr from 'hooks/useToastr';
import InputSelect from 'components/input/input-select';
import 'components/input/css/custom/customize-input-select.css';
import IconSearchPage from 'components/svg/icon-search-page';
import IconChartCandleStick from 'components/svg/icon-chart-candlestick';
import IconCompare from 'components/svg/icon-compare';
import { getColorStatus } from 'utils/stock/score';
import { useTableSorting } from 'hooks/useTableSorting';
import { useCompareStock } from 'hooks/useCompareStock';
import { triggerSegmentEvent } from 'lib/segment';
import IconBuy from 'components/svg/icon-buy';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useCustomNavigate from 'hooks/useCustomNavigate';

function SectionThematicStockList() {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { navigate, customNavigate } = useCustomNavigate();
  const { code } = useParams();
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);

  const { data: watchlist } = useGetWatchlistQuery();
  const [createWatchlist] = useCreateWatchlistMutation();
  const [deleteWatchlist] = useDeleteWatchlistMutation();

  const { addToCompareStock } = useCompareStock();

  const { data: stocks, isLoading: loadingThematic } =
    useGetStockThematicByIdQuery(code);
  const { data: thematics, isLoading: loadingThematics } =
    useGetStockThematicsQuery({ type: 'thematic', keyword: '' });

  const [codes, setCodes] = useState([]);
  const [loadingMul, setloadingMul] = useState(true);
  const { data: tempMultiStocks, isLoading: loadingMultipleStock } =
    useGetMultipleStockByCodesQuery(codes, {
      skip: loadingMul,
      pollingInterval: isBursaOpen ? 10000 : 3600000
    });
  const activeThematic = find(thematics, ['_id', code]);

  const [multipleStocks, setMultiStocks] = useState([]);
  const [thematic, setThematic] = useState(code);
  const [options, setOptions] = useState([]);

  const { data, sortingColumns, handleSorting, getSorting } = useTableSorting(
    multipleStocks,
    code === '626768b8b06cc90027d8dd77' ? ['dividenYield'] : ['diff'],
    code === '626768b8b06cc90027d8dd77' ? ['desc'] : ['desc']
  );

  useEffect(() => {
    let _stocks = [];
    stocks?.data?.map((item) => {
      _stocks.push(item?.code);
    });

    if (_stocks?.length > 0) {
      setCodes(_stocks);
      setloadingMul(false);
    }
  }, [stocks]);

  useEffect(() => {
    if (thematics?.length > 0) {
      setOptions(
        thematics?.map((item) => {
          return { id: item?._id, name: item?.name };
        })
      );
    }
  }, [thematics]);

  useEffect(() => {
    setMultiStocks(
      tempMultiStocks?.map((item) => {
        return {
          ...item,
          diff: item?.score?.diff,
          chg: item?.score?.value,
          dividenYield: find(stocks?.data, ['code', item?.code])?.dividenYield,
          per: find(stocks?.data, ['code', item?.code])?.per
        };
      })
    );
  }, [tempMultiStocks, stocks]);

  return (
    <div className="mt-5 dark:text-black-light-1-d">
      {loadingThematic ||
      loadingMultipleStock ||
      loadingThematics ||
      loadingMul ? (
        <>
          <div className="flex mb-3 items-center">
            <SkeletonTheme
              className="mr-3"
              width="40px"
              height="40px"
              borderRadius="100%"
            />
            <SkeletonTheme
              className="mr-3"
              width="150px"
              height="1.5rem"
              borderRadius="6px"
            />
          </div>

          <SkeletonTheme
            className="mb-2"
            width="80%"
            height="1"
            borderRadius="6px"
          />
          <SkeletonTheme
            className="mb-2"
            width="50%"
            height="1"
            borderRadius="6px"
          />
          <SkeletonTheme
            className="mb-2"
            width="30%"
            height="1"
            borderRadius="6px"
          />
          <SkeletonTheme
            className="mb-2"
            width="10%"
            height="1"
            borderRadius="6px"
          />
        </>
      ) : (
        <>
          <div className="mb-2">
            <div className="max-w-[320px] relative z-10 customize-input-select">
              <InputSelect
                value={thematic}
                label=""
                className="mb-4"
                options={options}
                onChange={(e) => {
                  setThematic(e.target.value);
                  navigate(`/stock-market/thematic/${e.target.value}`);
                }}
              />
            </div>
          </div>
          <p className="text-sm lg:max-w-xl text-gray-light-0-d">
            {activeThematic?.description}
          </p>
          <h3 className="text-sm my-3">{activeThematic?.total} Saham</h3>
        </>
      )}

      <Card className="p-3">
        <div className="">
          <OverlayScrollbarsComponent
            className="overlayscrollbars-host-small"
            element="span"
            options={{
              resize: 'horizontal',
              scrollbars: {
                autoHide: 'leave',
                autoHideDelay: 50
              }
            }}>
            <table className="text-2xs w-full text-center font-quicksand-semibold border-t border-gray-light-l dark:border-gray-base-d">
              <thead className="text-gray-light-0-d">
                <tr>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d lg:sticky left-0 bg-[#FAFBF9] dark:bg-[#212124] hover:text-main-base-l min-w-[100px] lg:min-w-[200px] ${
                      sortingColumns?.[0] === 'code' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('code')}>
                    <span className="flex items-center gap-1 cursor-pointer">
                      {sortingColumns?.[0] === 'code' && (
                        <IconSorting
                          type={getSorting('code')}
                          className="h-3 w-3"
                        />
                      )}
                      Symbol
                    </span>
                  </td>

                  {code != '626768b8b06cc90027d8dd77' ? (
                    <>
                      <td
                        className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d left-24 lg:sticky bg-[#FAFBF9] dark:bg-[#212124] hover:text-main-base-l ${
                          sortingColumns?.[0] === 'value' && 'text-main-base-l'
                        }`}
                        onClick={() => handleSorting('value')}
                        style={{ minWidth: '72px' }}>
                        <span className="flex items-center gap-1 justify-end cursor-pointer">
                          {sortingColumns?.[0] === 'value' && (
                            <IconSorting
                              type={getSorting('value')}
                              className="h-3 w-3"
                            />
                          )}
                          Last
                        </span>
                      </td>
                      <td
                        className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d left-40 lg:sticky bg-[#FAFBF9] dark:bg-[#212124] hover:text-main-base-l ${
                          sortingColumns?.[0] === 'diff' && 'text-main-base-l'
                        }`}
                        onClick={() => handleSorting('diff')}
                        style={{ minWidth: '72px' }}>
                        <span className="flex items-center gap-1 justify-end cursor-pointer">
                          {sortingColumns?.[0] === 'diff' && (
                            <IconSorting
                              type={getSorting('diff')}
                              className="h-3 w-3"
                            />
                          )}
                          CHG
                        </span>
                      </td>
                      <td
                        className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d left-56 lg:sticky bg-[#FAFBF9] dark:bg-[#212124] hover:text-main-base-l ${
                          sortingColumns?.[0] === 'chg' && 'text-main-base-l'
                        }`}
                        onClick={() => handleSorting('chg')}
                        style={{ minWidth: '72px' }}>
                        <span className="flex items-center gap-1 justify-end cursor-pointer">
                          {sortingColumns?.[0] === 'chg' && (
                            <IconSorting
                              type={getSorting('chg')}
                              className="h-3 w-3"
                            />
                          )}
                          %CHG
                        </span>
                      </td>
                      <td
                        className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                          sortingColumns?.[0] === 'dividenYield' &&
                          'text-main-base-l'
                        }`}
                        onClick={() => handleSorting('dividenYield')}
                        style={{ minWidth: '72px' }}>
                        <span className="flex items-center gap-1 justify-end cursor-pointer">
                          {sortingColumns?.[0] === 'dividenYield' && (
                            <IconSorting
                              type={getSorting('dividenYield')}
                              className="h-3 w-3"
                            />
                          )}
                          Dividen&nbsp;Yield
                        </span>
                      </td>
                    </>
                  ) : (
                    <>
                      <td
                        className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d bg-[#FAFBF9] dark:bg-[#212124] left-24 lg:sticky hover:text-main-base-l ${
                          sortingColumns?.[0] === 'dividenYield' &&
                          'text-main-base-l'
                        }`}
                        onClick={() => handleSorting('dividenYield')}
                        style={{ minWidth: '72px' }}>
                        <span className="flex items-center gap-1 justify-end cursor-pointer">
                          {sortingColumns?.[0] === 'dividenYield' && (
                            <IconSorting
                              type={getSorting('dividenYield')}
                              className="h-3 w-3"
                            />
                          )}
                          Dividen&nbsp;Yield
                        </span>
                      </td>
                      <td
                        className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d left-40 lg:sticky bg-[#FAFBF9] dark:bg-[#212124] hover:text-main-base-l ${
                          sortingColumns?.[0] === 'value' && 'text-main-base-l'
                        }`}
                        onClick={() => handleSorting('value')}
                        style={{ minWidth: '72px' }}>
                        <span className="flex items-center gap-1 justify-end cursor-pointer">
                          {sortingColumns?.[0] === 'value' && (
                            <IconSorting
                              type={getSorting('value')}
                              className="h-3 w-3"
                            />
                          )}
                          Last
                        </span>
                      </td>
                      <td
                        className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d left-56 lg:sticky bg-[#FAFBF9] dark:bg-[#212124] hover:text-main-base-l ${
                          sortingColumns?.[0] === 'diff' && 'text-main-base-l'
                        }`}
                        onClick={() => handleSorting('diff')}
                        style={{ minWidth: '72px' }}>
                        <span className="flex items-center gap-1 justify-end cursor-pointer">
                          {sortingColumns?.[0] === 'diff' && (
                            <IconSorting
                              type={getSorting('diff')}
                              className="h-3 w-3"
                            />
                          )}
                          CHG
                        </span>
                      </td>
                      <td
                        className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d left-64 lg:sticky bg-[#FAFBF9] dark:bg-[#212124] hover:text-main-base-l ${
                          sortingColumns?.[0] === 'chg' && 'text-main-base-l'
                        }`}
                        onClick={() => handleSorting('chg')}
                        style={{ minWidth: '72px' }}>
                        <span className="flex items-center gap-1 justify-end cursor-pointer">
                          {sortingColumns?.[0] === 'chg' && (
                            <IconSorting
                              type={getSorting('chg')}
                              className="h-3 w-3"
                            />
                          )}
                          %CHG
                        </span>
                      </td>
                    </>
                  )}

                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'per' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('per')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'per' && (
                        <IconSorting
                          type={getSorting('per')}
                          className="h-3 w-3"
                        />
                      )}
                      PER
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'openPrice' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('openPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'openPrice' && (
                        <IconSorting
                          type={getSorting('openPrice')}
                          className="h-3 w-3"
                        />
                      )}
                      Open
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'closePrice' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('closePrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'closePrice' && (
                        <IconSorting
                          type={getSorting('closePrice')}
                          className="h-3 w-3"
                        />
                      )}
                      Close
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'highTradedPrice' &&
                      'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('highTradedPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'highTradedPrice' && (
                        <IconSorting
                          type={getSorting('highTradedPrice')}
                          className="h-3 w-3"
                        />
                      )}
                      High
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'lowTradedPrice' &&
                      'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('lowTradedPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'lowTradedPrice' && (
                        <IconSorting
                          type={getSorting('lowTradedPrice')}
                          className="h-3 w-3"
                        />
                      )}
                      Low
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'averagePrice' &&
                      'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('averagePrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'averagePrice' && (
                        <IconSorting
                          type={getSorting('averagePrice')}
                          className="h-3 w-3"
                        />
                      )}
                      Avg
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'bidVolume' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('bidVolume')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'bidVolume' && (
                        <IconSorting
                          type={getSorting('bidVolume')}
                          className="h-3 w-3"
                        />
                      )}
                      BidLot
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'bidPrice' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('bidPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'bidPrice' && (
                        <IconSorting
                          type={getSorting('bidPrice')}
                          className="h-3 w-3"
                        />
                      )}
                      BID
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'offerPrice' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('offerPrice')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'offerPrice' && (
                        <IconSorting
                          type={getSorting('offerPrice')}
                          className="h-3 w-3"
                        />
                      )}
                      OFF
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'offerVolume' &&
                      'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('offerVolume')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'offerVolume' && (
                        <IconSorting
                          type={getSorting('offerVolume')}
                          className="h-3 w-3"
                        />
                      )}
                      OffLot
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'frequency' && 'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('frequency')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'frequency' && (
                        <IconSorting
                          type={getSorting('frequency')}
                          className="h-3 w-3"
                        />
                      )}
                      Freq
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'tradableLot' &&
                      'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('tradableLot')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'tradableLot' && (
                        <IconSorting
                          type={getSorting('tradableLot')}
                          className="h-3 w-3"
                        />
                      )}
                      Tr.Lot
                    </span>
                  </td>
                  <td
                    className={`p-2 py-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                      sortingColumns?.[0] === 'tradableValue' &&
                      'text-main-base-l'
                    }`}
                    onClick={() => handleSorting('tradableValue')}
                    style={{ minWidth: '72px' }}>
                    <span className="flex items-center gap-1 justify-end cursor-pointer">
                      {sortingColumns?.[0] === 'tradableValue' && (
                        <IconSorting
                          type={getSorting('tradableValue')}
                          className="h-3 w-3"
                        />
                      )}
                      Tr.Val
                    </span>
                  </td>
                </tr>
              </thead>
              <tbody className="text-2xs">
                <RenderIf
                  isTrue={
                    data?.length === 0 &&
                    !loadingThematic &&
                    !loadingThematics &&
                    !loadingMultipleStock
                  }>
                  <tr className="group">
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                    <td className="text-left p-2 py-[6px]">-</td>
                  </tr>
                </RenderIf>

                <RenderIf
                  isTrue={
                    loadingThematic || loadingThematics || loadingMultipleStock
                  }>
                  <tr className="group">
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="text-left p-2 py-[6px]">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                  </tr>
                </RenderIf>

                <RenderIf
                  isTrue={
                    data?.length > 0 &&
                    !loadingThematic &&
                    !loadingThematics &&
                    !loadingMultipleStock
                  }>
                  {data?.map((item, key) => (
                    <tr
                      key={key}
                      className="relative group cursor-pointer hover:z-10">
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d bg-[#FAFBF9] dark:bg-[#212124] border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left w-20 lg:sticky left-0 font-quicksand-semibold transition-all ease-in-out duration-150">
                        <div className="flex items-center mx-auto">
                          <RenderIf isTrue={item?.logo}>
                            <img
                              src={item?.logo}
                              className="h-6 w-6 border rounded-full mr-2"
                            />
                            {item?.code}
                          </RenderIf>

                          <RenderIf isTrue={!item?.logo}>
                            <div className="rounded-full mr-1 flex-none bg-gray-light-l bg-opacity-50 text-2xs flex items-center justify-center h-6 w-6">
                              {item?.code.charAt(0)}
                            </div>
                            {item?.code}
                          </RenderIf>
                        </div>
                        <p
                          className="text-gray-light-0-d mt-[1px] font-quicksand-regular text-2xs line-clamp-1"
                          title={
                            find(stocks?.data, ['code', item?.code])?.name
                          }>
                          {find(stocks?.data, ['code', item?.code])?.name}
                        </p>
                        <div className="relative">
                          <ActionButton
                            isZoomOut
                            isLast={true}
                            position={`right-4 ${
                              key >= data?.length - 1 ? 'bottom-8' : '-bottom-8'
                            }`}
                            actions={[
                              {
                                title: 'Beli',
                                children: <IconBuy className="h-4 w-4" />,
                                onClick: () =>
                                  customNavigate({
                                    query: { 'simple-order': item?.code }
                                  })
                              },
                              {
                                title: 'Stock Detail',
                                children: (
                                  <IconSearchPage className="h-4 w-4" />
                                ),
                                onClick: () => {
                                  triggerSegmentEvent({
                                    event: 'TR Search Feature Clicked'
                                  });
                                  customNavigate({
                                    query: { 'stock-detail': item?.code }
                                  });
                                }
                              },
                              {
                                title: 'Trading View',
                                children: (
                                  <IconChartCandleStick className="h-4 w-4" />
                                ),
                                onClick: () =>
                                  customNavigate({
                                    query: { 'advanced-chart': item?.code }
                                  })
                              },
                              {
                                title: 'Bandingkan',
                                children: <IconCompare className="h-4 w-4" />,
                                onClick: () => {
                                  triggerSegmentEvent({
                                    event: 'TR Compare Stock Clicked'
                                  });
                                  addToCompareStock(
                                    { code: item?.code, name: item?.name },
                                    () => {
                                      navigate(`/compare/fundamental`);
                                    }
                                  );
                                }
                              },
                              {
                                title: `${
                                  watchlist?.find((w) => w?.code === item?.code)
                                    ? 'Hapus Watchlist'
                                    : 'Tambahkan ke Watchlist'
                                }`,
                                children: watchlist?.find(
                                  (w) => w?.code === item?.code
                                ) ? (
                                  <IconStarDelete className="h-4 w-4" />
                                ) : (
                                  <IconStar className="h-4 w-4" />
                                ),
                                onClick: async () => {
                                  triggerSegmentEvent({
                                    event: 'TR Watchlist Clicked'
                                  });
                                  if (
                                    watchlist?.find(
                                      (w) => w?.code === item?.code
                                    )
                                  ) {
                                    try {
                                      await deleteWatchlist({
                                        stockCode: item?.code
                                      }).unwrap();
                                      toastr.success(
                                        `${item?.code} berhasil dihapus dari watchlist`
                                      );
                                    } catch (error) {
                                      getErrorMessage(error);
                                    }
                                  } else {
                                    try {
                                      await createWatchlist({
                                        stockCode: item?.code,
                                        type: 'stock'
                                      }).unwrap();
                                      toastr.success(
                                        `${item?.code} berhasil ditambahkan kedalam watchlist`
                                      );
                                    } catch (error) {
                                      getErrorMessage(error);
                                    }
                                  }
                                }
                              }
                            ]}
                          />
                        </div>
                      </td>

                      {code != '626768b8b06cc90027d8dd77' ? (
                        <>
                          <td
                            className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d bg-[#FAFBF9] dark:bg-[#212124] transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right left-24 lg:sticky ${getColorStatus(
                              item?.closePrice - item?.value
                            )}`}>
                            {automatedNumberFormat(item?.value)}
                          </td>
                          <td
                            className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d bg-[#FAFBF9] dark:bg-[#212124] transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right left-40 lg:sticky ${getColorStatus(
                              item?.diff
                            )}`}>
                            {automatedNumberFormat(item?.diff)}
                          </td>
                          <td
                            className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d bg-[#FAFBF9] dark:bg-[#212124] transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right left-56 lg:sticky`}>
                            <div className="flex items-center relative justify-end">
                              <span className={`${getColorStatus(item?.chg)}`}>
                                {automatedNumberFormat(item?.chg)}%
                              </span>
                            </div>
                          </td>
                          <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                            {automatedNumberFormat(item?.dividenYield)}%
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d bg-[#FAFBF9] dark:bg-[#212124] transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right left-24 lg:sticky">
                            {automatedNumberFormat(item?.dividenYield)}%
                          </td>
                          <td
                            className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d bg-[#FAFBF9] dark:bg-[#212124] transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right left-40 lg:sticky ${getColorStatus(
                              item?.closePrice - item?.value
                            )}`}>
                            {automatedNumberFormat(item?.value)}
                          </td>
                          <td
                            className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d bg-[#FAFBF9] dark:bg-[#212124] transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right left-56 lg:sticky ${getColorStatus(
                              item?.diff
                            )}`}>
                            {automatedNumberFormat(item?.diff)}
                          </td>
                          <td
                            className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d bg-[#FAFBF9] dark:bg-[#212124] transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right left-64 lg:sticky`}>
                            <div className="flex items-center relative justify-end">
                              <span className={`${getColorStatus(item?.chg)}`}>
                                {automatedNumberFormat(item?.chg)}%
                              </span>
                            </div>
                          </td>
                        </>
                      )}
                      <td
                        className={`p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right ${getColorStatus(
                          item?.per
                        )}`}>
                        {automatedNumberFormat(item?.per)}x
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.openPrice)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.closePrice)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.highTradedPrice)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.lowTradedPrice)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.averagePrice)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.bidVolume)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.bidPrice)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.offerPrice)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.offerVolume)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.frequency)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.tradableLot)}
                      </td>
                      <td className="p-2 py-[6px] group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-150 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-right">
                        {automatedNumberFormat(item?.tradableValue)}
                      </td>
                    </tr>
                  ))}
                </RenderIf>
              </tbody>
            </table>
          </OverlayScrollbarsComponent>

          <RenderIf
            isTrue={
              data?.length === 0 &&
              !loadingThematic &&
              !loadingMultipleStock &&
              !loadingThematics &&
              !loadingMul
            }>
            <p className="text-sm px-6 text-gray-light-0-d">
              Belum ada list saham.
            </p>
          </RenderIf>
        </div>
      </Card>
    </div>
  );
}

export default SectionThematicStockList;
