import PropTypes from 'prop-types';
import IconPlus from 'components/svg/icon-plus';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Helmet } from 'react-helmet-async';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { automatedNumberFormat } from 'utils/format';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMultipleFundamentalByCodesQuery } from 'services/rtk-query/stock';
import RenderIf from 'components/basic/render-if';
import { setWatchStockList } from 'store/pina-pro';
import { triggerSegmentEvent } from 'lib/segment';
import useWindowSize from 'hooks/useWindowSize';
import SkeletonTheme from 'components/basic/skeleton-theme';
import CompareFilter from '../_partials/compare-filter';

import 'components/input/css/custom/customize-input-stock.css';
import SkeletonHeader from '../_partials/skeleton-header';
import ContentEmpty from '../_partials/content-empty';
import { isEqual, maxBy, minBy } from 'lodash';
import StockSearch from '../_partials/stock-search';
import { getQuarterOfYear } from 'utils/formatter/date';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function PinaProMultiFundamental() {
  const ref = useRef();
  const refScroll = useRef();
  const dispatch = useDispatch();
  const screenSize = useWindowSize();

  const { user} = useBrowserTabAuth();
  const { watchStockList } = useSelector(({ pinaPro }) => pinaPro);

  const [addPosition, setAddPosition] = useState(0);
  const [showAddText, setShowAddText] = useState(true);
  const [maxContentWidth, setMaxContentWidth] = useState('100%');
  const [periods, setPeriods] = useState(getQuarterOfYear(new Date()));

  const [loadingIndex, setLoadingIndex] = useState('x');
  const [data, setData] = useState([]);
  const {
    data: tempData,
    isLoading,
    isFetching
  } = useGetMultipleFundamentalByCodesQuery({
    codes: watchStockList,
    periods,
  }, { refetchOnMountOrArgChange: true });

  const handleAddStock = () => {
    dispatch(setWatchStockList([...watchStockList, { code: '', name: '' }]));
    const { viewport } = refScroll.current.osInstance().elements();
    setTimeout(() => (viewport.scrollLeft += 350), 100);
  };

  const handlePickStock = (_stock, _indexToReplace) => {
    dispatch(
      setWatchStockList(
        watchStockList?.map((item, key) => {
          if (key === _indexToReplace) return _stock;
          return item;
        })
      )
    );

    triggerSegmentEvent({
      event: 'TR Add Stock Button Clicked',
      userId: user?.id
    });

    setLoadingIndex(_indexToReplace);
  };

  const handleClearStock = (_stock, _indexToReplace) => {
    dispatch(
      setWatchStockList(
        watchStockList?.map((item, key) => {
          if (key === _indexToReplace) return { code: '', name: '' };
          return item;
        })
      )
    );

    setData(
      data?.filter((item, key) => {
        if (key === _indexToReplace) return { code: '', name: '' };
        return item;
      })
    );

    triggerSegmentEvent({
      event: 'TR Clear Stock Compare Button Clicked',
      userId: user?.id
    });

    setLoadingIndex('x');
  };

  const handleRemoveColumn = (_stock, _indexToReplace) => {
    dispatch(
      setWatchStockList(
        watchStockList?.filter((item, key) => key !== _indexToReplace)
      )
    );

    triggerSegmentEvent({
      event: 'TR Remove Column Compare Button Clicked',
      userId: user?.id
    });

    setLoadingIndex('x');
  };

  useEffect(() => {
    let widthContainer = ref.current.clientWidth - 48;
    let lengthContent = watchStockList?.length > 2 ? watchStockList?.length : 3;
    let _addPosition = 300 + 16 + lengthContent * 155;

    if (_addPosition + 120 + 16 > widthContainer) {
      _addPosition = 'calc(100% - 52px)';
      setShowAddText(false);
    } else {
      setShowAddText(true);
    }

    if (_addPosition > 0) _addPosition = _addPosition + 'px';

    setAddPosition(_addPosition);
    setMaxContentWidth(widthContainer + 'px');
  }, [watchStockList, screenSize]);

  useEffect(() => {
    if (!isFetching) setLoadingIndex('x');
  }, [isFetching]);

  useEffect(() => {
    if (!isEqual(tempData, data) && !isFetching) setData(tempData);
  }, [tempData, data, isFetching]);

  return (
    <>
      <Helmet>
        <title>Compare Fundamental | Pina Trade</title>
      </Helmet>

      <CompareFilter defaultPeriods={periods} onChangeFilter={(_filter) => setPeriods(_filter)} />

      <div ref={ref} className="grid grid-cols-1 relative pr-12 dark:text-black-light-1-d">
        <OverlayScrollbarsComponent
          ref={refScroll}
          className="overlayscrollbars-host-small relative"
          element="span"
          options={{
            resize: 'horizontal',
            scrollbars: {
              autoHide: 'leave',
              autoHideDelay: 50
            }
          }}
          >
          <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d">
            <div className="min-w-[150px] lg:min-w-[300px] text-black-base-l dark:text-black-light-1-d left-0 lg:sticky card-widget-gradient border-r dark:border-gray-base-d z-10">
              <div className="p-2 py-3 border-b dark:border-gray-base-d">Symbol</div>
              <div className="p-2 py-3 text-2xs lg:text-xs">Company Name</div>
            </div>

            <SkeletonHeader isLoading={isLoading} />

            <RenderIf
              isTrue={
                watchStockList?.length > 0 && !isLoading && data?.length > 0
              }>
              {watchStockList?.map((item, key) => (
                <div className="min-w-[100px] lg:min-w-[155px] max-w-[155px]" key={key}>
                  <div className="p-2 py-3 border-l dark:border-gray-base-d border-b flex justify-between">
                    <StockSearch
                      item={item}
                      _key={key}
                      onHandlePickStock={(stock) => handlePickStock(stock, key)}
                      onClearStock={() => handleClearStock(item, key)}
                      onRemoveColumn={() => handleRemoveColumn(item, key)}
                    />
                  </div>
                  <div className="p-2 py-3 border-l dark:border-gray-base-d text-2xs lg:text-xs">
                    <p className="line-clamp-1">{item?.name}</p>
                  </div>
                </div>
              ))}
            </RenderIf>
          </div>

          <div className="flex">
            <KeyInformation
              loadingIndex={loadingIndex}
              data={data}
              watchStockList={watchStockList}
              maxContentWidth={maxContentWidth}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </div>

          <div className="flex">
            <OthersInformation
              loadingIndex={loadingIndex}
              data={data}
              watchStockList={watchStockList}
              maxContentWidth={maxContentWidth}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </div>
        </OverlayScrollbarsComponent>

        <div className={`ml-4 absolute ${!addPosition && 'opacity-0'}`} style={{ left: addPosition }}>
          <button
            onClick={handleAddStock}
            type="button"
            disabled={watchStockList?.length > 5}
            className="disabled:bg-gray-light-l disabled:text-gray-base-l text-center dark:disabled:bg-gray-base-d bg-main-base-l text-white dark:text-black-base-d dark:disabled:text-black-light-0-d p-2 py-3 text-sm rounded-lg transition-all ease-linear duration-155 hover:bg-opacity-75 min-h-[80px]">
            <IconPlus className="mx-auto h-6 w-6" />
            <span className={`${!showAddText && 'hidden'}`}>
              Tambah&nbsp;Stock
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

function TextValue({ value, loadingIndex, index, isLoading }) {
  return (
    <>
      {value && loadingIndex != index && value != '-' ? (
        automatedNumberFormat(value)
      ) : isLoading || loadingIndex === index ? (
        <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
      ) : (
        '-'
      )}
    </>
  );
}

TextValue.propTypes = {
  loadingIndex: PropTypes.number,
  value: PropTypes.any,
  index: PropTypes.number,
  isLoading: PropTypes.bool,
};

function KeyInformation({
  data = [],
  watchStockList = [],
  maxContentWidth = '1005',
  isLoading = true,
  loadingIndex
}) {
  const [showKeyInformation, setShowInforation] = useState(true);

  const getColorFundamental = (field, stock, inverseColor = false) => {
    let className = '';
    const stockDetails = data?.map((data, key) => {
      return {
        ...data?.data?.[0]?.details,
        dividen_yield: data?.data?.[0]?.details?.dividen_yield || (data?.data?.[0]?.details?.dps / data?.data?.[0]?.details?.harga_saham) * 100,
        stock: {...data?.data?.[0]?.stock}
      }
    });
    
    const findStockMax = maxBy(stockDetails, field);
    const findStockMin = minBy(stockDetails, field);

    if (findStockMax?.stock?.code === stock?.code) className = !inverseColor ? 'text-green-base-l' : 'text-red-base-l';
    if (findStockMin?.stock?.code === stock?.code) className = !inverseColor ? 'text-red-base-l' : 'text-green-base-l';
    return className;
  }

  return (
    <div>
      <button
        onClick={() => setShowInforation(!showKeyInformation)}
        type="button"
        style={{ maxWidth: maxContentWidth }}
        className="flex sticky left-0 font-quicksand-semibold text-base bg-main-base-l text-white dark:text-black-base-d p-3 px-4 rounded-lg justify-between my-3 transition-all ease-linear duration-155 hover:opacity-75 w-full">
        <span className="left-4 sticky text-xs lg:text-base">Key Information</span>
        <IconChevronSingle
          className={`${
            showKeyInformation ? 'rotate-90' : '-rotate-90'
          } h-4 w-4 lg:h-6 lg:w-6`}
        />
      </button>

      <div
        className={`${showKeyInformation ? 'h-auto' : 'h-0 overflow-hidden'}`}>
        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Market Cap</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('market_cap', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.market_cap &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.market_cap != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.market_cap
                    )
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Dividend Yield</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('dividen_yield', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.dps &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.dps != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.dividen_yield || (data?.[key]?.data?.[0]?.details?.dps / data?.[key]?.data?.[0]?.details?.harga_saham) * 100) + '%'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Price Earning Ratio (PER) *</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('per', item, true)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.per &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.per != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.per
                    ) + 'x'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Price Book Value (PBV)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('pbv', item, true)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.pbv &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.pbv != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.pbv
                    ) + 'x'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Return on Asset (RoA) *</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('roa', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.roa &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.roa != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.roa * 100
                    ) + '%'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Return on Equity (RoE) *</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('roe_equity_to_parent_entity', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.roe_equity_to_parent_entity &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.roe_equity_to_parent_entity != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.roe_equity_to_parent_entity * 100
                    ) + '%'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Debt Equity Ratio (DER)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('der_equity_to_parent_entity', item, true)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.der_equity_to_parent_entity &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.der_equity_to_parent_entity !=
                    '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.der_equity_to_parent_entity * 100
                    ) + '%'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Earning Per Share (EPS) *</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('earning_per_share', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.earning_per_share &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.earning_per_share != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.earning_per_share
                    )
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}

KeyInformation.propTypes = {
  data: PropTypes.array,
  watchStockList: PropTypes.array,
  maxContentWidth: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingIndex: PropTypes.number
};

function OthersInformation({
  data = [],
  watchStockList = [],
  maxContentWidth = '100%',
  isLoading = true,
  loadingIndex
}) {
  const [showOthers, setShowOthers] = useState(true);

  const getColorFundamental = (field, stock, inverseColor = false) => {
    let className = '';
    const stockDetails = data?.map((data, key) => {
      return {
        ...data?.data?.[0]?.details,
        dividen_yield: data?.data?.[0]?.details?.dividen_yield || (data?.data?.[0]?.details?.dps / data?.data?.[0]?.details?.harga_saham) * 100,
        stock: {...data?.data?.[0]?.stock}
      }
    });

    const findStockMax = maxBy(stockDetails, field);
    const findStockMin = minBy(stockDetails, field);

    if (findStockMax?.stock?.code === stock?.code) className = !inverseColor ? 'text-green-base-l' : 'text-red-base-l';
    if (findStockMin?.stock?.code === stock?.code) className = !inverseColor ? 'text-red-base-l' : 'text-green-base-l';
    return className;
  }

  return (
    <div>
      <button
        onClick={() => {
          setShowOthers(!showOthers);
        }}
        style={{ maxWidth: maxContentWidth }}
        type="button"
        className="sticky left-0 flex font-quicksand-semibold text-base bg-main-base-l text-white dark:text-black-base-d p-3 px-4 rounded-lg justify-between my-3 transition-all ease-linear duration-155 hover:opacity-75 w-full">
        <span className="left-4 text-xs lg:text-base">Others Information</span>
        <IconChevronSingle
          className={`${
            showOthers ? 'rotate-90' : '-rotate-90'
          } h-4 w-4 lg:h-6 lg:w-6`}
        />
      </button>

      <div className={`${showOthers ? 'h-auto' : 'h-0 overflow-hidden'}`}>
        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Account Receivable</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.account_receivable && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.account_receivable != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.account_receivable
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Assets Turnover</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.assets_turnover && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.assets_turnover != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.assets_turnover
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Book Value Per Share</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('book_value_per_share', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.book_value_per_share &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.book_value_per_share !=
                    '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.book_value_per_share
                    )
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">BVPS Equity To Parent Entity</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details
                    ?.bvps_equity_to_parent_entity &&
                  data?.[key]?.data?.[0]?.details
                    ?.bvps_equity_to_parent_entity != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details
                          ?.bvps_equity_to_parent_entity
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Cash Equivalent</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('cash_equivalent', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.cash_equivalent &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.cash_equivalent != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.cash_equivalent
                    )
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Cash Ratio (CR)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('cash_ratio', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.cash_ratio && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.cash_ratio != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.cash_ratio * 100
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Cash Equivalent Per Share (CEPS)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('cash_equivalent_per_share', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.cash_equivalent_per_share && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.cash_equivalent_per_share !=
                    '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details
                          ?.cash_equivalent_per_share
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Cash Flow Per Share (CFPS)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('cash_flow_per_share', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.cash_flow_per_share &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.cash_flow_per_share !=
                    '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.cash_flow_per_share
                    )
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current Ratio (CRR)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('current_ratio', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_ratio && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_ratio != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_ratio * 100
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Cash From Financing Act</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.cash_from_financing_act && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.cash_from_financing_act !=
                    '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.cash_from_financing_act
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Cash From Investing Act</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.cash_from_investing_act && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.cash_from_investing_act !=
                    '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.cash_from_investing_act
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Cash From Operating Act</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.cash_from_operating_act && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.cash_from_operating_act !=
                    '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.cash_from_operating_act
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Cost Of Goods Sold</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.cost_of_goods_sold && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.cost_of_goods_sold != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.cost_of_goods_sold
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current Asset</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_asset && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_asset != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_asset
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current BV</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_bv && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_bv != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_bv
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current CEPS</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_ceps && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_ceps != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_ceps
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current CFPS</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_cfps && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_cfps != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_cfps
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current EPS</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_eps && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_eps != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_eps
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current EPS TTM</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_eps_ttm && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_eps_ttm != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_eps_ttm
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current Liabilities</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_liabilities && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_liabilities != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_liabilities
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current Navs</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_navs && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_navs != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_navs
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Current RPS</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.current_rps && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.current_rps != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.current_rps
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Der Equity To Parent Entity</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details
                    ?.der_equity_to_parent_entity &&
                  data?.[key]?.data?.[0]?.details
                    ?.der_equity_to_parent_entity != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details
                          ?.der_equity_to_parent_entity
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Dividend Payout Ratio (DPR)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('dpr', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.dpr &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.dpr != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.dpr * 100
                    ) + '%'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Dividend Per Share (DPS)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('dps', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.dps &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.dps != '-' ? (
                    automatedNumberFormat(data?.[key]?.data?.[0]?.details?.dps)
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Earning Per Share</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.earning_per_share && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.earning_per_share != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.earning_per_share
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Ebit Margin</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.ebit_margin && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.ebit_margin != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.ebit_margin
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">EPS Quarter</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.eps_quater && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.eps_quater != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.eps_quater
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Equity Multiplier</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.equity_multiplier && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.equity_multiplier != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.equity_multiplier
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">EV Ebitda</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.ev_ebitda && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.ev_ebitda != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.ev_ebitda
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Fixed Asset</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.fixed_asset && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.fixed_asset != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.fixed_asset
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Gross Profit</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.gross_profit && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.gross_profit != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.gross_profit
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Gross Profit Margin (GPM)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('gross_profit_margin', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.gross_profit_margin &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.gross_profit_margin !=
                    '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.gross_profit_margin * 100
                    ) + '%'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">ICR</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.icr && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.icr != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.icr
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Income Before Tax</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.income_before_tax && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.income_before_tax != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.income_before_tax
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Inventories</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.inventories && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.inventories != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.inventories
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Long Term Debt</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.long_term_debt && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.long_term_debt != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.long_term_debt
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Minority Interest</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.minority_interest && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.minority_interest != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.minority_interest
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Net Assets Per Share (NAVS)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('net_assets_per_share', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.net_assets_per_share && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.net_assets_per_share != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.net_assets_per_share
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Net Cash Flow Activities</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.net_cash_flow_activities && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.net_cash_flow_activities !=
                    '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details
                          ?.net_cash_flow_activities
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Net Income</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.net_income && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.net_income != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.net_income
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Net Income Growth</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.net_income_growth && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.net_income_growth != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.net_income_growth
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Net Income Minority Interest</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details
                    ?.net_income_minority_interest &&
                  data?.[key]?.data?.[0]?.details
                    ?.net_income_minority_interest != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details
                          ?.net_income_minority_interest
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Net Profit Margin (NPM)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('net_profit_margin', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.net_profit_margin &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.net_profit_margin != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.net_profit_margin * 100
                    ) + '%'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Earnings Before <br/> Interest & Tax Margin (EBITM)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('ebit_margin', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.ebit_margin && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.ebit_margin != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.ebit_margin * 100
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Operating Expense</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.operating_expense && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.operating_expense != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.operating_expense
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Operating Profit</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.operating_profit && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.operating_profit != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.operating_profit
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">
              Operating Profit Margin (OPM)
            </div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('operating_profit_margin', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.operating_profit_margin &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.operating_profit_margin !=
                    '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.operating_profit_margin * 100
                    ) + '%'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Operation Income Growth</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.opertaion_income_growth && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.opertaion_income_growth !=
                    '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.opertaion_income_growth
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Other Current Asset</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.other_current_asset && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.other_current_asset != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.other_current_asset
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Other Income Expense</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.other_income_expense && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.other_income_expense != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.other_income_expense
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Outstading Share</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.outstanding_share && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.outstanding_share != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.outstanding_share
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Price Cash Flow Ratio (PCFR) *</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('price_cash_flow_ratio', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.price_cash_flow_ratio &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.price_cash_flow_ratio !=
                    '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.price_cash_flow_ratio
                    ) + 'x'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Price Sales Ratio (PSR) *</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('price_sales_ratio', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.price_sales_ratio &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.price_sales_ratio != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.price_sales_ratio
                    ) + 'x'
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Quick Ratio (QR)</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('quick_ratio', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.quick_ratio && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.quick_ratio != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.quick_ratio * 100
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Revenue Per Share (RPS) *</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div
                className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`}
                key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${getColorFundamental('revenue_per_share', item)} ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.revenue_per_share &&
                  loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.revenue_per_share != '-' ? (
                    automatedNumberFormat(
                      data?.[key]?.data?.[0]?.details?.revenue_per_share
                    )
                  ) : isLoading || loadingIndex === key ? (
                    <SkeletonTheme width="40%" height="1rem" borderRadius="6px" />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Total Asset</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.total_assets && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.total_assets != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.total_assets
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Total Sales</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.total_sales && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.total_sales != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.total_sales
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Total Liabilities</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.total_liabilities && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.total_liabilities != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.total_liabilities
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Total Equity</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.total_equity && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.total_equity != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.total_equity
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}

        {/* <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Roa Equity To Parent Entity</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details
                    ?.roe_equity_to_parent_entity &&
                  data?.[key]?.data?.[0]?.details
                    ?.roe_equity_to_parent_entity != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details
                          ?.roe_equity_to_parent_entity
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Sales Growth</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.sales_growth && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.sales_growth != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.sales_growth
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Subordinasi</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.subordinasi && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.subordinasi != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.subordinasi
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Syirkah Temporer Fund</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.syirkah_temporer_fund && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.syirkah_temporer_fund != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.syirkah_temporer_fund
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Tabaru Fund</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.tabaru_fund && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.tabaru_fund != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.tabaru_fund
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Total Asset Growth</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.total_asset_growth && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.total_asset_growth != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.total_asset_growth
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">
              Total Equity To Parent Equity
            </div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details
                    ?.total_equity_equity_to_parent_entity &&
                  data?.[key]?.data?.[0]?.details
                    ?.total_equity_equity_to_parent_entity != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details
                          ?.total_equity_equity_to_parent_entity
                      )
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Total Equity Growth</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.total_equity_growth && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.total_equity_growth != '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details?.total_equity_growth
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div>

        <div className="flex font-quicksand-semibold text-sm text-gray-light-0-d dark:text-black-light-0-d odd:bg-gray-light-l even:bg-white dark:odd:bg-black-base-d dark:even:bg-black-dark-d group">
          <div className="min-w-[150px] lg:min-w-[300px] text-gray-light-0-d dark:text-black-light-0-d left-0 lg:sticky group-odd:bg-gray-light-l group-even:bg-white dark:group-odd:bg-black-base-d dark:group-even:bg-black-dark-d border-r dark:border-gray-base-d">
            <div className="p-2 py-3 text-2xs lg:text-xs">Total Liabilities Growth</div>
          </div>

          <RenderIf isTrue={watchStockList?.length > 0}>
            {watchStockList?.map((item, key) => (
              <div className={`min-w-[100px] lg:min-w-[155px] max-w-[155px] text-black-base-l dark:text-black-light-1-d`} key={key}>
                <div
                  className={`p-2 py-3 text-2xs lg:text-xs text-right ${
                    key === 0 ? '' : 'border-l dark:border-gray-base-d'
                  }`}>
                  {data?.[key]?.data?.[0]?.details?.total_liabilities_growth && loadingIndex != key &&
                  data?.[key]?.data?.[0]?.details?.total_liabilities_growth !=
                    '-'
                    ? automatedNumberFormat(
                        data?.[key]?.data?.[0]?.details
                          ?.total_liabilities_growth
                      ) + '%'
                    : isLoading || loadingIndex === key ? <SkeletonTheme width="40%" height="1rem" borderRadius="6px" /> : '-'}
                </div>
              </div>
            ))}
          </RenderIf>

          <ContentEmpty isLoading={isLoading} />
        </div> */}
      </div>
    </div>
  );
}

OthersInformation.propTypes = {
  data: PropTypes.array,
  watchStockList: PropTypes.array,
  maxContentWidth: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingIndex: PropTypes.number
};

export default PinaProMultiFundamental;
