import dayjs from 'dayjs';
import { object, string } from 'yup';

export const RDNStep02Validation = object().shape({
  ktpNo: string()
    .required('Nomor KTP tidak boleh kosong')
    .test('ktpNo', 'Nomor KTP tidak sesuai', function (value) {
      let tgl = parseInt(value?.substring(6, 2), 10);
      if (tgl >= 40) tgl -= 40;
      if (tgl < 10) tgl = `0${tgl}`;
      const date = tgl.toString() + value?.substring(8, 4);
      const bornCount = parseInt(value?.substring(12), 10);
      return dayjs(date, 'DDMMYY').isValid() && bornCount > 0
    }),
  ktpIssuer: string().required('Tempat Pembuatan KTP tidak boleh kosong'),
  fullName: string().required('Nama Lengkap tidak boleh kosong'),
  birthPlace: string().required('Tempat Lahir tidak boleh kosong'),
  birthDate: string().required('Tanggal Lahir tidak boleh kosong').test('birthDate', 'Tanggal Lahir tidak sesuai', function(value) {
    return dayjs(value, 'DD-MM-YYYY').isValid()
  }),
  gender: string().required('Jenis Kelamin tidak boleh kosong'),
  statusKawin: string().required('Status Perkawinan tidak boleh kosong'),
  spouseName: string().test('spouseName', 'Nama Pasangan tidak boleh kosong', function (value, { parent }) {
    if (Number(parent?.statusKawin) === 53) return value;
    return true;
  }),
  agama: string().required('Agama tidak boleh kosong'),
  address: string().required('Alamat Tempat Tinggal tidak boleh kosong'),
  province: string().required('Provinsi tidak boleh kosong'),
  city: string().required('Kota/Kabupaten tidak boleh kosong'),
  kecamatan: string().required('Kecamatan tidak boleh kosong'),
  desa: string().required('Desa/Kelurahan tidak boleh kosong'),
  rtrw: string().required('RT/RW tidak boleh kosong'),
  zipCode: string().required('Kode Pos tidak boleh kosong'),
  imgKtpUrl: string().required('Foto KTP tidak boleh kosong'),
});
