import Card from 'components/basic/card';
import IconCopy from 'components/svg/icon-copy';
import { formatAccountNumber } from 'utils/formatter/bank';
import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';
import { useGetDepositQuery } from 'services/rtk-query/trading';
import { useGetContentByTypesQuery } from 'services/rtk-query/master-data';
import { useEffect, useState } from 'react';
import LogoBNI from 'assets/images/logo/bni.png';
import './css/styles.css';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { Helmet } from 'react-helmet-async';

function SectionDeposit() {
  const { data: deposit, isLoading } = useGetDepositQuery(null, { refetchOnMountOrArgChange: true });
  const { data: faqDeposit } = useGetContentByTypesQuery(['deposit']);

  const [faqs, setFaqs] = useState([]);

  const handleToggle = (index) => {
    let fL = [];

    faqs.forEach((faq, key) => {
      fL.push({
        ...faq,
        active: index === key ? !faq?.active : faq?.active
      });
    });

    setFaqs(fL);
  };

  useEffect(() => {
    if (faqDeposit?.length > 0) setFaqs(faqDeposit);
  }, [faqDeposit]);

  return (
    <Card className="p-4 lg:p-6 dark:text-black-light-1-d">
      <Helmet>
        <title>Top-up Saldo | Pina Trade</title>
      </Helmet>

      <h5 className="mb-3 text-xs lg:text-sm">
        Transfer dana kamu ke sini untuk deposit saldo
      </h5>

      <div className="border-2 border-orange-6 rounded-xl overflow-hidden grid-cols-1 grid lg:grid-cols-3 p-4 gap-3 lg:gap-0">
        <div>
          <p className="text-gray-light-0-d text-2xs lg:text-xs">Nama Pemilik Akun</p>
          <h5 className='capitalize'>
            {isLoading ? <SkeletonTheme height="1rem" width="100px" borderRadius="8px"/> : deposit?.bankAccountName?.toLowerCase()}
          </h5>
        </div>
        <div>
          <p className="text-gray-light-0-d text-2xs lg:text-xs">Rekening Bank Tujuan</p>
          <h5 className="flex items-center uppercase">
            {isLoading ? <SkeletonTheme height="1rem" width="100px" borderRadius="8px"/> : deposit?.bankName?.replace('BANK ', '')}
          </h5>
        </div>
        <div>
          <p className="text-gray-light-0-d text-2xs lg:text-xs">No. Rekening Bank Tujuan</p>
          <h5 className="flex items-center">
          {isLoading ? <SkeletonTheme height="1rem" width="100px" borderRadius="8px"/> : 
            <>
              {formatAccountNumber(deposit?.bankAccountNo)}
              <a
                role="button"
                title="Salin No. Rekening"
                className="ml-1 text-main-base-l"
                onClick={() => navigator?.clipboard?.writeText(deposit?.bankAccountNo)}>
                <IconCopy className="h-5 w-5" />
              </a>
            </>
          }
            
          </h5>
        </div>
      </div>

      <h5 className="text-sm mt-3 mb-2 font-quicksand-semibold">
        Ketentuan saat Deposit Dana
      </h5>
      <ol className="list-disc pl-4 text-xs font-quicksand-regular">
        <li className="mb-1">
          Setelah Top Up, Saldo Rekening kamu akan bertambah dalam maksimal{' '}
          <b>1 jam</b>, tergantung ketentuan dari masing-masing bank.
        </li>
        <li className="mb-1">
          Silakan hubungi CS kami jika Top Up yang kamu lakukan tidak masuk
          dalam <b>1 jam</b>, kami akan menghubungi bank terkait untuk melakukan
          pemeriksaan.
        </li>
        <li className="mb-1">
          Deposit Dana yang dilakukan menggunakan selain Bank BNI atau
          menggunakan E-Wallet akan dikenakan biaya tambahan sesuai kebijakan
          masing-masing Bank maupun E-Wallet tersebut.
        </li>
        <li className="mb-1">
          Nasabah akan menerima notifikasi apabila transfer telah dilakukan.
        </li>
        <li className="mb-1">
          Dana yang telah ada dalam deposit akan di proses selama <b>1x24</b>{' '}
          jam untuk menjadi <b>Dana yang Tersedia</b> atau <b>Buying Power</b>{' '}
          untuk pembelian Saham maupun Reksadana.
        </li>
      </ol>

      <h5 className="text-sm my-4 font-quicksand-semibold">
        Cara Melakukan Deposit Dana
      </h5>

      <Accordion className="relative">
        {faqs?.map((item, key) => (
          <AccordionItem
            key={key}
            expandable={true}
            expanded={item.active}
            className={`relative pb-4 ${key > 0 ? 'pt-4' : ''} ${
              key != faqs?.length - 1 ? 'border-b dark:border-gray-base-d' : ''
            }`}
            onToggle={() => handleToggle(key)}>
            <AccordionItem.Slot name="Header">
              <div className="flex justify-between">
                <div className="flex items-center">
                  <img
                    className="h-6 w-6 mr-2"
                    src={item?.logoUrl}
                    alt={item?.logoTitle}
                  />
                  <h5>{item?.logoTitle}</h5>
                </div>

                <span className="flex-none h-5 w-5">
                  <IconChevronSingle
                    className={`w-6 h-6 text-main-base-l ${
                      item.active ? 'rotate-90' : '-rotate-90'
                    }`}
                  />
                </span>
              </div>
            </AccordionItem.Slot>
            <AccordionItem.Slot name="Content">
              <div>
                <p
                  className="mt-2 text-2xs lg:text-xs faq-deposit"
                  dangerouslySetInnerHTML={{
                    __html: item?.content
                  }}></p>
              </div>
            </AccordionItem.Slot>
          </AccordionItem>
        ))}
      </Accordion>
    </Card>
  );
}

export default SectionDeposit;
