import RenderIf from 'components/basic/render-if';
import Modal from 'components/modal/modal';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import { useCountdown } from 'hooks/useCountDown';
import useDarkMode from 'hooks/useDarkMode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dayjsLocal } from 'utils/formatter/date';
import formatZeroLeading from 'utils/formatter/zero-leading';

function CountDownExploreTrade() {
  const navigate = useNavigate();
  const { removeDarkMode } = useDarkMode();
  const auth = useBrowserTabAuth();
  const [targetDate, setTargetDate] = useState(dayjsLocal(auth?.user?.createdAt, 'YYYY-MM-DD HH:mm:ss').add(14, 'day'));
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
    setTargetDate(dayjsLocal(auth?.user?.createdAt, 'YYYY-MM-DD HH:mm:ss').add(14, 'day'));
  }, [auth]);

  return (
    <>
      <div className="bg-red-base-l px-6 py-2 text-white flex justify-center gap-2 font-quicksand-medium rounded-[28px]">
        <div className="text-center">
          <p>{formatZeroLeading(days || 0, 2)}</p>
          <p className="text-xs">Hari</p>
        </div>
        <div>
          <p>:</p>
        </div>
        <div className="text-center">
          <p>{formatZeroLeading(hours || 0, 2)}</p>
          <p className="text-xs">Jam</p>
        </div>
        <div>
          <p>:</p>
        </div>
        <div className="text-center">
          <p>{formatZeroLeading(minutes || 0, 2)}</p>
          <p className="text-xs">Menit</p>
        </div>
        <div>
          <p>:</p>
        </div>
        <div className="text-center">
          <p>{formatZeroLeading(seconds || 0, 2)}</p>
          <p className="text-xs">Detik</p>
        </div>
      </div>

      <RenderIf isTrue={seconds < 0 && targetDate}>
        <Modal 
          onClose={() => {
            navigate('/onboarding/phone-number');
            removeDarkMode();
          }}
        >
          <ModalExploreExpired 
            onClose={() => {
              navigate('/onboarding/phone-number');
              removeDarkMode();
            }} 
          />
        </Modal>
      </RenderIf>
    </>
  );
}

export default CountDownExploreTrade;
