import PropTypes from 'prop-types';

function IconArrowLeft({ className }) {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0.000224344 9.99994C0.0126298 10.3249 0.143575 10.7005 0.361356 10.9374L8.36135 19.574C8.82839 20.1401 9.72711 20.1288 10.2522 19.6187C10.7774 19.1086 10.8206 18.2055 10.2917 17.6991L4.43069 11.3637H22.6666C23.403 11.3637 24 10.7531 24 10.0001C24 9.24702 23.403 8.6364 22.6666 8.6364H4.43069L10.2917 2.30102C10.8203 1.7947 10.7772 0.887547 10.2518 0.377493C9.72661 -0.132592 8.81526 -0.134703 8.36112 0.425893L0.361133 9.06253C0.117852 9.32708 0.00367566 9.63534 0 10L0.000224344 9.99994Z" />
    </svg>
  );
}

IconArrowLeft.propTypes = {
  className: PropTypes.string
};

export default IconArrowLeft;
