import { Helmet } from 'react-helmet-async';
import {  useEffect, useRef, useState } from 'react';
import { triggerSegmentEvent } from 'lib/segment';
import TabsStockMarket from '../_partials/tabs-stock-market';
import FormScreener from './_partials/form-screener';
import ResultScreener from './_partials/result-screener';

function PageStockScreener() {
  const [firstRender, setFirsRender] = useState(true);
  const [result, setResult] = useState(null);
  const refAnchor = useRef();

  useEffect(() => {
    if (firstRender) {
      triggerSegmentEvent({ event: `TR Stock Screener Screen Viewed` });
      setFirsRender(false);
    }
  }, [firstRender]);

  return (
    <div className='zoom-2'>
      <div className="p-4 pb-20">
        <Helmet>
          <title>Stock Screener | Pina Trade</title>
        </Helmet>

        <TabsStockMarket />

        <div className="grid grid-cols-1 gap-3">
          <FormScreener
            onNewScreener={() => setResult(null)}
            onSuccess={(res) => {
              setResult(res);
              setTimeout(() => {
                window.scrollTo({ top: refAnchor?.current?.offsetTop - 150 });
              }, 500);
            }}
          />

          <div ref={refAnchor}>
            <ResultScreener payloads={result?.payloads} results={result?.response || []} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageStockScreener;
