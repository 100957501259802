import PropTypes from 'prop-types';
import SimpleBuySellForm from './simple-buy-sell-form';
import { useState } from 'react';

function SimpleOrderForm({
  symbol,
  initPrice,
  simpleBuyAnalyticEventName,
  simpleSellAnalyticEventName,
  typeOrder = 'SimpleBuy',
  onClose = () => {},
  onSuccess = () => {},
}) {

  const [tempSymbol, setTempSymbol] = useState(symbol);

  return (
    <SimpleBuySellForm
      defaultOrderType={typeOrder}
      symbol={tempSymbol}
      simpleBuyAnalyticEventName={simpleBuyAnalyticEventName}
      simpleSellAnalyticEventName={simpleSellAnalyticEventName}
      initPrice={initPrice ? Number(initPrice) : 0}
      onClose={onClose}
      onSuccess={onSuccess}
      onChangeSymbol={(newSymbol) => setTempSymbol(newSymbol)}
    />
  );
}

SimpleOrderForm.propTypes = {
  symbol: PropTypes.string,
  defaultOrderType: PropTypes.string,
  initPrice: PropTypes.number,
  simpleBuyAnalyticEventName: PropTypes.string,
  simpleSellAnalyticEventName: PropTypes.string,
  typeOrder: PropTypes.string,
  onChangeSymbol: PropTypes.func
};

export default SimpleOrderForm;
