import PropTypes from 'prop-types';
import Tooltip from './tooltip';
import RenderIf from './render-if';
import cn from 'utils/classname';

function ActionButton({ actions, isLast, type = 'horizontal', position = 'right-4', defaultActiveClass = 'hidden', className, isZoomOut }) {
  return (
    <ul className={cn(['bg-turquoise-1 rounded-md dark:bg-black-dark-d dark:border dark:border-gray-base-d absolute transform items-center justify-end shadow-pro z-30', isLast ? 'bottom-5' : '-bottom-5', defaultActiveClass, position, type === 'vertical' && 'group-hover:block', type === 'horizontal' && 'group-hover:flex', className])}>
      {actions?.map((item, key) => (
        <li className='group' key={key} onClick={item?.onClick}>

          <RenderIf isTrue={item?.title}>
            <Tooltip
              isZoomOut={isZoomOut}
              tooltipHTML={
                <p className="text-2xs font-quicksand-semibold text-center">{item?.title}</p>
              }>
              <div className={`bg-white dark:bg-black-dark-d ${key === 0 && 'rounded-l-md'} ${key === actions?.length - 1 && 'rounded-r-md'} p-1 border-gray-light-l flex items-center justify-center`}>
                <span className={`block ${item?.disabled ? 'bg-gray-light-l dark:bg-black-dark-d text-gray-base-l dark:text-gray-base-d cursor-not-allowed' : 'hover:bg-main-base-l hover:text-white dark:hover:text-black-base-d text-main-base-l cursor-pointer'} transition-all ease-in-out duration-150 p-1 rounded-full`}>
                  {item?.children}
                </span>
              </div>
            </Tooltip>
          </RenderIf>

          <RenderIf isTrue={!item?.title}>
            {item?.children}
          </RenderIf>
        </li>
      ))}
    </ul>
  );
}

ActionButton.propTypes = {
  actions: PropTypes.array,
  isLast: PropTypes.bool,
  position: PropTypes.string,
  type: PropTypes.string,
  defaultActiveClass: PropTypes.string,
  className: PropTypes.string,
};

export default ActionButton;
