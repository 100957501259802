/* eslint-disable no-useless-escape */
import { object, string } from 'yup';

export const RegisterSchema = object().shape({
  email: string()
    .email('Email tidak valid, harap periksa kembali email Anda.')
    .required('Email tidak boleh kosong'),
  password: string()
    .min(8, 'Password minimal 6 karakter')
    .test(
      'password',
      'Password tidak sesuai',
      (value) =>
        /[0-9]/.test(value) &&
        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value) &&
        /[A-Z]/.test(value)
    )
    .required('Password tidak boleh kosong'),
  passwordConfirmation: string()
    .min(8, 'Password minimal 6 karakter')
    .test(
      'passwordConfirmation',
      'Konfirmasi Password tidak sesuai',
      (value, { parent }) => parent?.password === value
    )
    .required('Konfirmasi Password tidak boleh kosong')
});

export const RegisterReferralCodeSchema = object().shape({
  referrerCode: string().test('referrerCode', 'Kode Referal tidak valid', (value, { parent }) => {
    if (value && value != '') return parent?.isValidReferrerCode
    return true;
  })
});
