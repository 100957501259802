import Card from 'components/basic/card';
import { ModalContext } from 'components/modal/context/modalContext';
import IconCheckShield from 'components/svg/icon-check-shield';
import IconClose from 'components/svg/icon-close';
import { triggerSegmentEvent } from 'lib/segment';
import { useContext, useEffect } from 'react';

function ModalDataAman() {
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    triggerSegmentEvent({
      event: 'TR Data Security Information Clicked'
    });
  }, [])

  return (
    <Card className="p-6 lg:p-10 max-w-lg lg:mx-auto relative mx-6">
      <button onClick={() => handleModal(false)} type="button" className="right-4 top-4 absolute hover:opacity-75">
        <IconClose className="h-6 w-6" />
      </button>

      <h5 className="text-xl lg:text-2xl font-quicksand-regular mb-6">
        Data kamu aman, karena:
      </h5>
      <ul className='text-xs lg:text-sm'>
        <li className="flex mb-2">
          <IconCheckShield className="text-green-base-l mr-1 h-6 w-6 flex-none" />
          <span>Terdaftar dan diawasi OJK.</span>
        </li>
        <li className="flex mb-2">
          <IconCheckShield className="text-green-base-l mr-1 h-6 w-6 flex-none" />
          <span>Tergabung dalam Asosiasi Fintech Indonesia (AFTECH).</span>
        </li>
        <li className="flex mb-2">
          <IconCheckShield className="text-green-base-l mr-1 h-6 w-6 flex-none" />
          <span>
            Dana nasabah disimpan di Rekening Dana Nasabah (RDN) untuk saham dan
            Bank Kustodian untuk Reksa Dana, sehingga selalu aman.
          </span>
        </li>
        <li className="flex mb-2">
          <IconCheckShield className="text-green-base-l mr-1 h-6 w-6 flex-none" />
          <span>
            PINA tidak pernah menyimpan data login bank milik nasabah. Koneksi
            dilindungi dengan enkripsi 256-bit.
          </span>
        </li>
        <li className="flex mb-2">
          <IconCheckShield className="text-green-base-l mr-1 h-6 w-6 flex-none" />
          <span>
            PINA Menggunakan rekening investasi yang disediakan oleh Bank Negara
            Indonesia (BNI).
          </span>
        </li>
        <li className="flex mb-2">
          <IconCheckShield className="text-green-base-l mr-1 h-6 w-6 flex-none" />
          <span>
            Menggunakan enkripsi data (Sectigo RSA Domain Validation Secure
            Server CA) yang melindungi transaksi dan pertukaran data.
          </span>
        </li>
      </ul>

      <button
        type="button"
        onClick={() => handleModal(false)}
        className="px-4 py-3 text-center text-white bg-main-base-l rounded-xl w-full mt-6 text-sm hover:opacity-75 transition-all ease-in-out duration-150">
        Oke, Mengerti
      </button>
    </Card>
  );
}

export default ModalDataAman;
