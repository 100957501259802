import PropTypes from 'prop-types';
import InputSelectDropDown from 'components/input/input-select-dropdown';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { useEffect, useState } from 'react';
import { useGetStockThematicsQuery } from 'services/rtk-query/stock';
import { useGetWatchlistQuery } from 'services/rtk-query/watchlist';
import RenderIf from 'components/basic/render-if';
import Modal from 'components/modal/modal';

function InputUniverse({ value, onSelect = () => {}, borderLess, disabled }) {
  const [active, setActive] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  const { data: tempIDXList, isLoading: loadingIDX } = useGetStockThematicsQuery({ type: 'idx', keyword: '' });
  const { data: tempSectorList, isLoading: loadingSectors } = useGetStockThematicsQuery({ type: 'sector', keyword: '' });
  const { data: tempWatchList, isLoading: loadingWatchlist } = useGetWatchlistQuery();

  const [listUniverse, setListUniverse] = useState([]);

  const handleFocus = () => {
    setActive(true);
  };

  useEffect(() => {
    setTempValue(value || '');
  }, [value]);

  useEffect(() => {
    if (!loadingIDX && !loadingSectors && !loadingWatchlist) {
      setListUniverse([
        {
          name: 'IHSG',
          children: [
            { name: 'Semua IHSG', value: 'all', children: [] },
            ...(tempIDXList?.map((item) => {
              return { name: item?.name, value: item?.name, fieldName: item?.name, children: [] };
            }) || [])
          ]
        },
        {
          name: 'Sector',
          children: [
            { name: 'Semua Sector', value: 'all', children: [] },
            ...(tempSectorList?.map((item) => {
              return { name: item?.name, value: item?.name, fieldName: item?.name, children: [] };
            }) || [])
          ]
        },
        {
          name: 'Watchlist Saya',
          value: 'watchlist',
          children: tempWatchList?.map((item) => {
            return { name: item?.code, value: item?.code, fieldName: item?.code, children: [], disabled: true }
          }) || []
        }
      ]);
    }
  }, [tempIDXList, loadingIDX, loadingSectors, tempSectorList, loadingWatchlist, tempWatchList]);

  return (
    <div
      className={`card-widget-gradient w-full ${
        !borderLess && 'border border-gray-base-l dark:border-black-light-1-l'
      } text-xs font-quicksand-semibold rounded-xl flex justify-between relative`}>
      <span className='absolute right-2 top-1/2 -translate-y-1/2 transform border-l pl-[6px] dark:border-black-light-1-l'>
        <IconChevronSingle
          className={` h-4 w-4 text-main-base-l transform flex-none ${
            active ? 'rotate-90' : '-rotate-90'
          }`}
        />
      </span>
      <input
        name="parameter"
        value={tempValue}
        disabled={disabled || loadingIDX || loadingSectors || loadingWatchlist}
        onFocus={handleFocus}
        placeholder="Parameter"
        autoComplete="off"
        className="appearance-none flex-auto outline-none h-full w-full z-10 bg-transparent pr-8 cursor-pointer py-[8px] pl-3 dark:text-black-light-1-d"
        readOnly
      />

      <RenderIf isTrue={active}>
        <Modal onClose={() => setActive(false)}>
          <InputSelectDropDown
            value={value}
            placeholder="Cari Universe"
            title={<h1 className="mb-4 dark:text-black-light-1-d">Pilih Universe</h1>}
            options={listUniverse}
            onChange={(value) => {
              onSelect(value);
              setActive(false);
            }}
            onClose={() => {
              setActive(false);
            }}
          />
        </Modal>
      </RenderIf>
    </div>
  );
}

InputUniverse.propTypes = {
  value: PropTypes.any,
  onSelect: PropTypes.func,
  borderLess: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputUniverse;
