import PropTypes from 'prop-types';
import IconClose from 'components/svg/icon-close';
import IconSearch from 'components/svg/icon-search';
import { useEffect, useRef, useState } from 'react';
import { filter, groupBy, sortBy } from 'lodash';
import ImageEmptyState from 'assets/images/mascot/mascot-empty-orderlist.png';
import IconPlus from 'components/svg/icon-plus';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import Accordion from 'components/basic/accordion-simple/accordion';
import AccordionItem from 'components/basic/accordion-simple/accordion-item';
import IconSort from 'components/svg/icon-sort';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { LIST_WIDGET_2 } from 'constants/list-widget';

function ModalWidgetFast2({
  onClose = () => {},
  onPickWidget = () => {},
  isChangeWidget = false,
  header = (
    <h5 className="font-quicksand-semibold mb-4 text-base dark:text-black-light-1-d">
      Tambah Widget
    </h5>
  )
}) {
  useKeyboardShortcut({
    key: 'Escape',
    onKeyPressed: () => {
      onClose();
    }
  });

  const groupWidgetList = Object.keys(groupBy(LIST_WIDGET_2, 'groupName'));

  const [data, setData] = useState(LIST_WIDGET_2);
  const [currentFilter, setCurrenFilter] = useState('All');
  const [keyword, setKeyword] = useState('');
  const refKeyword = useRef();

  const handleFilterByCategories = (groupName) => {
    setCurrenFilter(groupName);
    if (groupName === 'All') {
      setKeyword('');
      setData(LIST_WIDGET_2);
    } else {
      setData(filter(LIST_WIDGET_2, ['groupName', groupName]));
    }
  };

  useEffect(() => {
    setData(
      LIST_WIDGET_2.filter(
        (item) =>
          item?.title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    refKeyword?.current?.focus();
  }, []);

  return (
    <div className="card-widget-gradient shadowdefault p-4 mx-4 lg:mx-auto relative dark:border rounded-xl lg:rounded-none my-4 lg:my-0 lg:min-h-screen dark:border-gray-base-d">
      {header}
      <button
        onClick={onClose}
        type="button"
        className="outline-none appearance-none absolute top-4 right-4 hover:text-main-base-l dark:text-main-base-l hover:opacity-75 transition-all duration-200 ease-in-out">
        <IconClose />
      </button>

      <div className="flex flex-col lg:flex-row justify-start lg:justify-between mb-4 items-center gap-4 flex-wrap">
        <div className="flex-none w-full">
          <div className="relative z-20">
            <input
              ref={refKeyword}
              data-qa-id="txtSearchStockField"
              className="appearance-none outline-none card-widget-gradient dark:text-black-light-1-d py-2 px-3 text-sm rounded-3xl border dark:border-black-light-1-l w-full font-quicksand-semibold text-black-base-l"
              placeholder={'Cari Widget'}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                setCurrenFilter('All');
              }}
            />
            <button
              data-qa-id="btnSearchStockClear"
              className={`appearance-none absolute right-3 top-0 h-full text-gray-light-0-d ${
                keyword?.length > 1 ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={() => {
                if (keyword?.length > 1) {
                  setKeyword('');
                  setData(LIST_WIDGET_2);
                }
              }}>
              <IconSearch className='text-main-base-d' />
            </button>
          </div>
        </div>

        <div className="flex-auto">
          <ul className="flex w-full -mx-1 overflow-x-auto justify-start flex-row gap-2 max-w-[320px] lg:max-w-[330px]">
            <li
              onClick={() => handleFilterByCategories('All')}
              className={`first:ml-0 rounded-lg w-[90px] flex-wrap lg:min-w-[unset] py-[6px] px-3 text-xs font-quicksand-semibold cursor-pointer flex items-center border dark:border-black-light-1-l hover:opacity-75 transition-all ease-linear duration-200 justify-center ${
                currentFilter === 'All'
                  ? 'bg-main-base-l text-white dark:text-black-base-d !border-main-base-l'
                  : 'card-widget-gradient dark:text-white'
              }`}>
              Semua
            </li>
            {groupWidgetList?.map((obj, key) => (
              <li
                onClick={() => handleFilterByCategories(obj)}
                key={key}
                className={`group w-[90px] lg:min-w-[unset] justify-center rounded-lg py-[6px] px-3 text-xs font-quicksand-semibold cursor-pointer flex items-center border dark:border-black-light-1-l hover:opacity-75 transition-all ease-linear duration-200 ${
                  currentFilter === obj
                    ? 'bg-main-base-l text-white dark:text-black-base-d !border-main-base-l'
                    : 'card-widget-gradient dark:text-black-light-1-d'
                }`}>
                <span
                  className={`${
                    currentFilter === obj
                      ? 'text-white dark:text-main-base-l'
                      : 'text-main-base-l dark:text-black-base-d'
                  }`}>
                  {groupWidgetList?.[obj]?.[key]?.icon}
                </span>
                <span className="line-clamp-1">{obj}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="overflow-auto relative">
        <ul className="grid grid-cols-1 gap-2 max-h-[calc(100vh-175px)] overflow-y-auto -mx-4 px-4">
          {sortBy(data, 'title')?.map((widget, wKey) => (
            <li
              key={wKey}
              className={`relative group rounded-xl bg-white hover:bg-gray-light-l hover:dark:bg-black-dark-d dark:bg-black-base-d cursor-pointer transition-all ease-linear duration-200`}>
              <div
                role="button"
                onClick={() => onPickWidget([widget])}
                className="absolute left-2 -top-[10px] p-2 pl-0 group">
                <div className="flex items-center gap-2 py-2 flex-auto">
                  {isChangeWidget ? (
                    <IconSort className="h-4 w-4 mr-1 transform rotate-90 text-main-base-l" />
                  ) : (
                    <IconPlus className="text-main-base-l flex-none h-5 w-5" />
                  )}
                  <h5 className="text-xs font-quicksand-semibold dark:text-black-light-1-d flex items-center gap-2">
                    {widget?.title}
                    {widget?.isPopular && (
                      <span className="p-1 border rounded-lg text-2xs dark:border-main-base-d">
                        🔥 Populer
                      </span>
                    )}
                  </h5>
                </div>
              </div>

              <Accordion>
                <AccordionItem expandable={true} expanded={false}>
                  <AccordionItem.Slot name="Header">
                    <div
                      role="button"
                      className="flex justify-end gap-2 items-center pl-[70%] p-2">
                      <div className="flex-none">
                        <IconChevronSingle className="text-main-base-l dark:text-black-light-1-d flex-none h-4 w-4 -rotate-90" />
                      </div>
                    </div>
                  </AccordionItem.Slot>

                  <AccordionItem.Slot name="Content">
                    <div className="grid grid-cols-12 gap-2 transition-all ease-linear duration-100 overflow-hidden p-2">
                      <div className="col-span-5 rounded-md overflow-hidden flex-none aspect-[3/2] object-cover object-left-top border dark:border-gray-base-d relative">
                        <img
                          className="w-full h-full object-cover object-left-top"
                          src={widget?.imgSrc}
                          alt="Pina Widget"
                        />
                      </div>

                      <div className="col-span-7">
                        <p className="font-quicksand-regular text-2xs text-gray-light-0-d line-clamp-6">
                          {widget?.description}
                        </p>
                      </div>
                    </div>
                  </AccordionItem.Slot>
                </AccordionItem>
              </Accordion>
            </li>
          ))}
        </ul>

        {data?.length === 0 && (
          <div className="h-full w-full flex justify-center items-center absolute left-0 top-0 dark:text-black-light-0-d">
            <div>
              <img
                src={ImageEmptyState}
                alt="empty"
                className="h-14 w-14 mx-auto mb-4"
              />
              <h2 className="text-2xl mb-1 font-quicksand-semibold text-center">
                Whoops!
              </h2>
              <p className="text-sm text-center">
                Widget tidak ditemukan dengan kata kunci <b>{keyword}</b>.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ModalWidgetFast2.propTypes = {
  onClose: PropTypes.func,
  onPickWidget: PropTypes.func,
  isMultiple: PropTypes.bool,
  header: PropTypes.any
};

export default ModalWidgetFast2;
