import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

function InputRadio({
  value = '',
  label = '',
  name = 'name',
  options = [],
  onChange = () => {},
  className = 'mb-4',
  activeClass = 'border-main-base-l text-main-base-l bg-main-base-l bg-opacity-10 bg-white dark:text-main-base-l dark:border-main-base-l',
  size = 'px-2 py-3 first:rounded-l-xl last:rounded-r-xl border dark:border-gray-base-d'
}) {
  const id = uniqueId();

  return (
    <div className={className}>
      {label && (
        <label className="block font-quicksand-regular text-black-base-l text-xs mb-1">
          {label}
        </label>
      )}

      <div className="flex justify-between items-center">
        {options?.map((item, key) => (
          <label
            role='button'
            tabIndex={0}
            title={item?.title}
            key={key}
            htmlFor={`${name}-${id}-${key}`}
            className={`flex justify-center w-full cursor-pointer relative font-quicksand-bold ${size} text-center
          ${
            value === item?.value
              ? item?.activeClass ? item?.activeClass : activeClass
              : 'text-gray-light-0-d dark:border-gray-base-d'
          }`}
          >
            <input
              id={`${name}-${id}-${key}`}
              type="radio"
              name={name}
              value={item?.value}
              defaultChecked={item?.value === value}
              onClick={(e) => onChange(e)}
              className="h-0 w-0 absolute appearance-none"
            />
            {item?.node}
          </label>
        ))}
      </div>
    </div>
  );
}

InputRadio.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  activeClass: PropTypes.string,
  size: PropTypes.string,
};

export default InputRadio;
