import baseApi from '../base';

export const ImageApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getImage: build.query({
      query: (path) => {
        return {
          url: `../images/${path}`,
          responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
          cache: "no-cache",
        };
      },
    }),
  })
});

export const {
  useGetImageQuery,
  useLazyGetImageQuery
} = ImageApi;
