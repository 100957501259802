import RenderIf from 'components/basic/render-if';
import Tabs from 'components/tabs/tabs';
import {  useState } from 'react';
// import { useParams } from 'react-router-dom';
import StockDetailFinancial from './stock-detail-financial';
import StockDetailStatistic from './stock-detail-statistic';
import TWBrokerSummary from 'pages/trading/_partials/tw-broker-summary';
// import TWBrokerActivity from 'pages/trading/_partials/tw-broker-activity';
import TWCorpActionRHUPS from 'pages/trading/_partials/tw-corp-action-rhups';
import TWTechnical from 'pages/trading/_partials/tw-technical';
import TWFundamental from 'pages/trading/_partials/tw-fundamental';
import TWCompanyProfile from 'pages/trading/_partials/tw-company-profile';
import TWDoneSummary from 'pages/trading/_partials/tw-done-summary';

function SectionStockDetail({ symbol, defaultActiveTab = 0 }) {
  // const { code } = useParams();
  const [activeIndexTab, setActiveIndexTab] = useState(defaultActiveTab);
  // const [tempCode, setTempCode] = useState(null);
  // const [tempBroker, setTempBroker] = useState(null);

  const tabs = [
    {
      index: 0,
      text: 'Teknikal',
      analyticEventName: 'TR Stock Detail Teknikal Clicked'
    },
    {
      index: 1,
      text: 'Broker Summ',
      analyticEventName: 'TR Stock Detail Broker Summary Clicked'
    },
    {
      index: 2,
      text: 'Corp Action',
      analyticEventName: 'TR Stock Detail Corp Action Clicked'
    },
    {
      index: 3,
      text: 'Statistik',
      analyticEventName: 'TR Stock Detail Statistik Clicked'
    },
    {
      index: 4,
      text: 'Keuangan',
      analyticEventName: 'TR Stock Detail Financial Clicked'
    },
    {
      index: 5,
      text: 'Com. Profile',
      analyticEventName: 'TR Stock Detail ComPro Clicked'
    },
    {
      index: 6,
      text: 'Done Summ',
      analyticEventName: 'TR Stock Detail Done Summary Clicked'
    }
  ];

  const handleChangeTab = (index) => {
    setActiveIndexTab(index);
  };

  // useEffect(() => {
  //   if (code != tempCode) {
  //     setTempCode(code);
  //   }
  // }, [code, tempCode]);

  return (
    <section className="card-widget-gradient p-6 py-1 rounded-xl ">
      <Tabs
        className="overflow-auto max-w-[88vw] md:max-w-none lin flex md:grid md:grid-cols-7 font-quicksand-semibold text-xs text-gray-light-0-d"
        data={tabs}
        onChange={handleChangeTab}
        initialOpenIndex={activeIndexTab}>
        <RenderIf isTrue={activeIndexTab === 0}>
          <TWTechnical symbol={symbol} />
        </RenderIf>

        <RenderIf isTrue={activeIndexTab === 1}>
          <TWBrokerSummary symbol={symbol} showSearchStock={false} maxHeight='270px' />
        </RenderIf>

        <RenderIf isTrue={activeIndexTab === 2}>
          <div className="mt-3">
            <TWCorpActionRHUPS symbol={symbol} twoColumns maxHeight="auto" />
          </div>
        </RenderIf>

        <RenderIf isTrue={activeIndexTab === 3}>
          <StockDetailStatistic symbol={symbol} />
        </RenderIf>

        <RenderIf isTrue={activeIndexTab === 4}>
          <StockDetailFinancial symbol={symbol} />

          <div className="mt-4 -mx-3">
            <TWFundamental symbol={symbol} isSingle maxHeight="auto" />
          </div>
        </RenderIf>

        <RenderIf isTrue={activeIndexTab === 5}>
          <div className="mt-3">
            <TWCompanyProfile symbol={symbol} maxHeight="auto" />
          </div>
        </RenderIf>

        <RenderIf isTrue={activeIndexTab === 6}>
          <div className="-mx-3">
            <TWDoneSummary symbol={symbol} />
          </div>
        </RenderIf>
      </Tabs>
    </section>
  );
}

export default SectionStockDetail;
