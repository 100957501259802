import baseApi from '../base';
import { TAG_TRADINGVIEW_LIST } from '../tags';

export const TradingViewAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTradingViewCharts: build.query({
      query: (params = { client: '', user: ''}) => {
        return {
          url: `/tradingview/light/charts`,
          params
        };
      },
      transformResponse: (res) => res?.data || [],
      providesTags: () => {
        return [{ type: TAG_TRADINGVIEW_LIST, id: 'LIST' }];
      },
    }),
  })
});

export const invalidateTradingviewCharts = () => {
  return TradingViewAPI.util.invalidateTags([{ type: TAG_TRADINGVIEW_LIST, id: 'LIST' }]);
}

export const {
  useGetTradingViewChartsQuery,
} = TradingViewAPI;
