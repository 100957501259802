import PropTypes from 'prop-types';

function IconMediaPrevSkip({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.00362C14 2.35797 12.1233 1.41654 10.8042 2.40045L5.48341 6.36911C4.39323 7.18225 4.39322 8.81571 5.48339 9.62886L10.8042 13.5976C12.1233 14.5816 14 13.6401 14 11.9945V4.00362ZM11.6014 3.46923C12.0411 3.14126 12.6666 3.45507 12.6666 4.00362V11.9945C12.6666 12.543 12.0411 12.8568 11.6014 12.5289L6.28058 8.5601C5.90528 8.28016 5.90528 7.71782 6.28059 7.43789L11.6014 3.46923Z"
      />
      <path d="M3.33333 2.66667C3.33333 2.29848 3.03486 2 2.66667 2C2.29848 2 2 2.29848 2 2.66667V13.3333C2 13.7015 2.29848 14 2.66667 14C3.03486 14 3.33333 13.7015 3.33333 13.3333V2.66667Z" />
    </svg>
  );
}

IconMediaPrevSkip.propTypes = {
  className: PropTypes.string
};

IconMediaPrevSkip.defaultProps = {
  className: ''
};

export default IconMediaPrevSkip;
