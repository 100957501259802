import { filter, max, maxBy, min, minBy, orderBy } from 'lodash';
import baseApi from '../base';
import { TAG_CITIES, TAG_DISTRICTS, TAG_PROVINCES, TAG_VILLAGES, TAG_BANKS, TAG_STOCK_TREEMAP } from '../tags';
import { setSectorDetails, setSectors } from 'store/stock';
import { currency } from 'utils/format';

export const MasterDataApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOptionsByTypes: build.query({
      query: (types = []) => {
        return {
          url: '/master-data/options',
          params: {
            type: (types || []).join(',')
          }
        };
      },
      transformResponse: (res) => {
        return res?.data || [];
      }
    }),
    getContentByTypes: build.query({
      query: (types = []) => {
        return {
          url: '/master-data/content',
          params: {
            type: (types || []).join(',')
          }
        };
      },
      transformResponse: (res) => {
        return res?.data || [];
      }
    }),
    getMasterDataBanks: build.query({
      query: (params = { size : 200 }) => {
        return {
          url: '/rdnbank',
          params
        };
      },
      transformResponse: (res) => {
        return res?.payloads?.content || [];
      },
      providesTags: () => {
        return [{ type: TAG_BANKS, id: 'LIST' }];
      },
    }),
    getMasterDataProvinces: build.query({
      query: (params = { size : 99}) => {
        return {
          url: '/master-data/province',
          params
        };
      },
      transformResponse: (res) => {
        return res?.data?.content || [];
      },
      providesTags: () => {
        return [{ type: TAG_PROVINCES, id: 'LIST' }];
      },
    }),
    getMasterDataCityByProvinceId: build.query({
      query: (provinceId) => {
        return {
          url: `master-data/province/v2/${provinceId}`,
        };
      },
      transformResponse: (res) => {
        return res?.data?.cities || [];
      },
      providesTags: (_result, _error, provinceId) => {
        return [{ type: TAG_CITIES, id: provinceId }];
      },
    }),
    getMasterDataDistrictByCityId: build.query({
      query: (cityId) => {
        return {
          url: `master-data/city/v2/${cityId}`,
        };
      },
      transformResponse: (res) => {
        return res?.data?.kecamatans || [];
      },
      providesTags: (_result, _error, cityId) => {
        return [{ type: TAG_DISTRICTS, id: cityId }];
      },
    }),
    getMasterDataVillageByDistrictId: build.query({
      query: (districtId) => {
        return {
          url: `master-data/kecamatan/${districtId}`,
        };
      },
      transformResponse: (res) => {
        return res?.data?.kelurahans || [];
      },
      providesTags: (_result, _error, districtId) => {
        return [{ type: TAG_VILLAGES, id: districtId }];
      },
    }),
    getTreeMap: build.query({
      async queryFn(category, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const state = getState();

        let sectors = state?.stock?.sectors;
        let sectorDetails = state?.stock?.sectorDetails;
        let _data = [];
        let _max = [];
        let _min = [];
        let promises = [];

        if (sectors?.length === 0) {
          const { data } = await fetchWithBQ('/stocks/thematic?type=sector&page=1');
          sectors = data;
          dispatch(setSectors(sectors));
        }

        sectors?.map((_sector) => {
          _data.push({
            fillcolor: '#5F646F',
            fontcolor: '#FFF',
            label: _sector.name,
            value: _sector.total,
            svalue: _sector.total,
            data: []
          });

          if (sectorDetails?.length === 0) {
            promises.push(fetchWithBQ(`/stocks/thematic/${_sector?._id}/list`));
          }
        });

        if (sectorDetails?.length === 0) {
          sectorDetails = await Promise.all(promises);
          dispatch(setSectorDetails(sectorDetails));
        }

        const getFillColor = (_scoreValue) => {
          const schemaColor = {
            red: ['#BB2649', '#CF385B', '#ef0037'],
            green: ["#3A6E62", '#26BB98', "#0CF0BB"],
            gray: ['#304B5F']
          }

          let _color = 'gray';
          let _indexColor = 0;

          if (_scoreValue < 0) _color = 'red';
          if (_scoreValue > 0) _color = 'green';

          if (_scoreValue > 2) _indexColor = 2;
          if (_scoreValue > 1 && _scoreValue <= 2) _indexColor = 1;
          if (_scoreValue > 0 && _scoreValue < 1) _indexColor = 0;
          
          if (_scoreValue < -2) _indexColor = 2;
          if (_scoreValue >= -2 && _scoreValue < -1) _indexColor = 1;
          if (_scoreValue < 0 && _scoreValue > -1) _indexColor = 0;

          return schemaColor[_color][_indexColor];
        }

        let _totalSectorSize = 0;
        sectorDetails?.map(({ data }, key) => {
          let { data: _stocks} = data;
          let _totalSize = 0;

          _stocks = filter(_stocks, (i) => i[category])
          _stocks = orderBy(_stocks, [category], 'desc')
          _stocks = filter(_stocks, (i, key) => key < 20)

          const findMax = maxBy(_stocks, [`${category}`]);
          const findMin = minBy(_stocks, [`${category}`]);
          
          let tMax = findMax?.[category];
          let tMin = findMin?.[category];
          
          if (category === 'deviden_yield') {
            tMax = Number(Number(findMax?.[category] * 100).toFixed(2));
            tMin = Number(Number(findMin?.[category] * 100).toFixed(2));
          }

          _max.push(tMax);
          _min.push(tMin);
          

          _stocks?.map((stock) => {
            let _value = stock?.[category];
            if (category === 'deviden_yield') {
              _value = Number(_value * 100).toFixed(2);
            }

            const obj = {
              label: `<b>${stock.code} <br/>${currency(stock?.previousLastTradedPrice, { removeSymbol: true })}</b><br/> ${Number(stock?.score?.value).toFixed(2)}%`,
              fillcolor: getFillColor(Number(stock?.score?.value)),
              fontcolor: '#FFFFFF',
              value: _value,
              sValue: _value
            };

            _data[key]?.data?.push(obj);
            _totalSize += Number(_value || 0);
          });

          _data[key].value = _totalSize;
          _data[key].svalue = _totalSize;

          _totalSectorSize += _totalSize;
        });

        return {
          data: {
            list: [
              {
                fillcolor: '#5F646F',
                fontcolor: '#FFF',
                label: 'Sector',
                value: _totalSectorSize,
                svalue: _totalSectorSize,
                data: _data
              }
            ],
            max: max(_max) + 1,
            min: min(_min) - 1
          }
        };
      },
      transformResponse: (res) => res || {},
      providesTags: (_result, _error, category) => {
        return [{ type: TAG_STOCK_TREEMAP, id: category }];
      },
    }),
    getMaintenanceMode: build.query({
      query: () => {
        return {
          url: '/master-data/maintenance-mode'
        };
      },
      transformResponse: (res) => {
        return res?.data;
      }
    }),
  })
});

export const invalidateTreeMap = (category) => {
  return MasterDataApi.util.invalidateTags([{ type: TAG_STOCK_TREEMAP, id: category }]);
}

export const {
  useGetOptionsByTypesQuery,
  useGetContentByTypesQuery,
  useGetTreeMapQuery,
  useGetMasterDataBanksQuery,
  useGetMasterDataProvincesQuery,
  useLazyGetMasterDataCityByProvinceIdQuery,
  useLazyGetMasterDataDistrictByCityIdQuery,
  useLazyGetMasterDataVillageByDistrictIdQuery,
  useGetMaintenanceModeQuery
} = MasterDataApi;
