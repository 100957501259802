import RenderIf from 'components/basic/render-if';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { currency } from 'utils/format';
import { ORDER_COST } from 'constants';
import { getStampCostSell } from 'utils/calculation';
import useFocus from 'hooks/useFocus';
import Modal from 'components/modal/modal';

function SimpleStockSellConfirmation({
  isOpen,
  isSubmitting,
  data = {},
  onClose = () => {},
  onSubmit = () => {}
}) {
  const [refSubmitFocus, setSubmitFocus] = useFocus();
  const [refCancelFocus, setCancelFocus] = useFocus();
  const [open, setOpen] = useState(isOpen);

  const handleCloseConfirmation = () => {
    if (isSubmitting) return false;

    setOpen(!open);
    onClose();
  };

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
    setTimeout(() => setSubmitFocus(), 100);
  }, [isOpen]);

  if (!isOpen) return '';

  return (
    <Modal onClose={handleCloseConfirmation}>
      <div
        className={`max-w-md mx-auto bg-white dark:bg-black-dark-d rounded-lg dark:border dark:border-gray-base-d dark:text-black-light-1-d p-6 z-10 relative transform zoom-2`}
        style={{ transform: 'translateY(-3px)' }}>
        <div className="text-center pb-2 border-b dark:border-gray-base-d">
          <h5>Mohon cek kembali order kamu</h5>
          <p className="text-xs text-gray-light-0-d font-quicksand-semibold">
            No Order: {data?.orderNumber}
          </p>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Kode Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {data?.code}
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Tipe Transaksi
          </span>
          <span className="flex-grow text-right font-quicksand-bold text-green-base-l">
            Sell
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jenis Order
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            Limit Order
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Order Expiry
          </span>
          <span className="flex-grow text-right font-quicksand-bold">DAY</span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Harga Per Saham
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.price, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jumlah Lot
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(data?.lot, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex my-1 items-center">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Biaya ({ORDER_COST?.sell * 100}%)
          </span>
          <span className="flex-grow text-right font-quicksand-bold text-gray-light-0-d">
            {currency(
              Math.round(ORDER_COST?.sell * data?.price * data?.lot * 100),
              { removeSymbol: true }
            )}
          </span>
        </div>

        <RenderIf isTrue={getStampCostSell(data?.price * data?.lot * 100) > 0}>
          <div className="flex my-1 items-center">
            <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
              Biaya Materai
            </span>
            <span className="flex-grow text-right font-quicksand-bold">
              {currency(Math.ceil(ORDER_COST?.stampCost), {
                removeSymbol: true
              })}
            </span>
          </div>
        </RenderIf>

        <div className="flex my-1 items-center border-y py-2 dark:border-gray-base-d">
          <span className="flex-none text-gray-light-0-d text-sm font-quicksand-semibold">
            Jumlah Investasi (-Biaya)
          </span>
          <span className="flex-grow text-right font-quicksand-bold">
            {currency(
              data?.price * data?.lot * 100 -
                Math.round(ORDER_COST?.sell * data?.price * data?.lot * 100) -
                getStampCostSell(data?.price * data?.lot * 100),
              {
                removeSymbol: true
              }
            )}
          </span>
        </div>

        <div className="pt-3 mt-1">
          <div className="grid grid-cols-12 gap-4">
            <button
              data-qa-id="btnStockBuyFinishConfirmation"
              type="button"
              onClick={handleCloseConfirmation}
              disabled={isSubmitting}
              ref={refCancelFocus}
              onKeyDown={(event) => {
                if (event.key === 'ArrowRight') {
                  event.preventDefault();
                  setSubmitFocus();
                }
              }}
              className="col-span-6 bg-black-light-0-l text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full block transition-all ease-in-out duration-100 hover:bg-opacity-75">
              Batal
            </button>

            <button
              ref={refSubmitFocus}
              data-qa-id="btnStockSellSubmitConfirmation"
              type="button"
              onClick={onSubmit}
              onKeyDown={(event) => {
                if (event.key === 'ArrowLeft') {
                  event.preventDefault();
                  setCancelFocus();
                }
              }}
              disabled={isSubmitting}
              className="col-span-6 bg-green-base-d text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75">
              {isSubmitting && <Spinner />}
              Konfirmasi
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

SimpleStockSellConfirmation.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func
};

export default SimpleStockSellConfirmation;
