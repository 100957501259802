import { useEffect, useState } from 'react';
import useToastr from 'hooks/useToastr';
import {
  invalidateUserProfile,
  useCreatePINMutation,
  useLazyGetUserMeQuery
} from 'services/rtk-query/user';
import { useNavigate } from 'react-router-dom';
import BGOnboarding from 'assets/images/background/bg-onboarding.jpeg';
import BGOnboardingOrnament from 'assets/images/background/bg-onboarding-ornament.png';
import PinForm from 'components/form/pin-form';
import RenderIf from 'components/basic/render-if';
import { useDispatch } from 'react-redux';
import { setAuth } from 'store/auth';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ListStatus from 'components/basic/list-status';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import { useBrowserTabId } from 'hooks/useBrowserTabId';

function RDNCreatePin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();

  const [createPin] = useCreatePINMutation();
  const [getUserMe] = useLazyGetUserMeQuery();
  const auth = useBrowserTabAuth();
  const browserTabID = useBrowserTabId();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationPIN, setConfirmationPIN] = useState(false);

  const handleSubmit = async (pin) => {
    setIsSubmitting(true);
    toastr.dismiss();
    try {
      if (pin != confirmationPIN) {
        toastr.error('Konfirmasi PIN tidak sesuai');
        throw new Error();
      }

      await createPin({ pin }).unwrap();
      const { data: responseMe } = await getUserMe(auth?.token);
      toastr.success('Berhasil membuat PIN');
      dispatch(invalidateUserProfile());

      const newAuth = {
        ...auth,
        user: {
          ...auth.user,
          realAccountActive: responseMe?.realAccountActive,
          pinCreated: true
        }
      };

      dispatch(
        setAuth({
          ...newAuth,
          [browserTabID]: newAuth
        })
      );

      setTimeout(() => navigate('/onboarding/status'), 1000);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (auth?.user?.pinCreated) navigate('/onboarding/initialization');
  }, [auth, navigate]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:min-h-screen">
      <div className="bg-onboarding p-6 lg:px-28 lg:py-16 text-white relative rounded-b-2xl lg:rounded-b-none overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={BGOnboarding}
          alt="Pina Onboarding"
        />

        <img
          className="absolute bottom-0 left-0 h-[150px] lg:h-[300px] w-auto"
          src={BGOnboardingOrnament}
          alt="Pina Onboarding"
        />

        <div className="relative z-10 max-w-2xl mx-auto">
          <h1 className="font-quicksand-bold text-[24px] lg:text-[52px] leading-[28px] lg:leading-[60px] mb-3 text-white">
            Buat PIN Kamu untuk keamanan ekstra!
          </h1>

          <p className="text-sm lg:text-xl mb-6">
            Dengan membuat PIN, Kamu dapat meningkatkan lapisan perlindungan
            untuk akunmu. <br /> Hal ini akan mencegah akses tidak sah ke akun
            Kamu, sehingga memberikan ketenangan dalam bertransaksi.
          </p>

          <ListStatus
            data={[
              {
                title: 'Daftarkan email kamu',
                subtitle: null,
                status: true
              },
              {
                title: 'Nomor handphone kamu',
                subtitle: null,
                status: true
              },
              {
                title: 'Buat PIN',
                subtitle: null,
                status: false
              }
            ]}
          />
        </div>
      </div>

      <div className="p-0 lg:p-16">
        <RenderIf isTrue={!confirmationPIN}>
          <PinForm
            onSubmit={(_pin) => setConfirmationPIN(_pin)}
            isSubmitting={isSubmitting}
            header={
              <h5 className="my-4 text-2xl text-main-gold-d font-quicksand-semibold text-center">
                Masukkan PIN
              </h5>
            }
          />
        </RenderIf>

        <RenderIf isTrue={confirmationPIN}>
          <PinForm
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            header={
              <h5 className="my-4 text-2xl text-main-gold-d font-quicksand-semibold text-center">
                Konfirmasi PIN
              </h5>
            }
          />
        </RenderIf>
      </div>
    </div>
  );
}

export default RDNCreatePin;
