import PropTypes from 'prop-types';
import { ORDER_COST } from 'constants';
import { find } from 'lodash';
import { useDispatch } from 'react-redux';
import { useGetPortfolioListQuery } from 'services/rtk-query/trade';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import { setFastOrderSettingsBySymbol } from 'store/settings';
import { automatedNumberFormat, currency } from 'utils/format';
import InputSelect from 'components/input/input-select';
import RenderIf from 'components/basic/render-if';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { getStampCostBuy } from 'utils/calculation';

function OrderSettingsMobile({
  symbol,
  price,
  settings = { activeLotIndex: 0, lots: [] },
}) {
  const { activeLotIndex, lots } = settings;
  const dispatch = useDispatch();

  const optionLots = lots?.map((item, key) => {
    if (key === 0) {
      return { id: 9999999, name: item };
    }

    return { id: item, name: item };
  });
  const selectedLot = optionLots?.find((_, key) => key === Number(activeLotIndex))?.id;

  const { data: trading, isLoading: loadingBuyingPower } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { data: portFolio, isLoading: loadingPortfolio  } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const portfolio = find(portFolio?.stock?.items, { stID: symbol });

  return (
    <div className="flex items-center justify-between mb-2 dark:text-black-light-1-d">
      <div className="py-1">
        <h5 className="text-2xs font-quicksand-regular text-gray-light-0-d py-1">Limit</h5>
        <p className="font-quicksand-semibold text-xs">
          <RenderIf isTrue={loadingBuyingPower}>
            <SkeletonTheme containerClassName="leading-none" width="50%" height="1rem" borderRadius="4px" />
          </RenderIf>

          <RenderIf isTrue={!loadingBuyingPower}>
            {currency(trading?.buyingpower || 0, { removeSymbol: true })}
          </RenderIf>
        </p>
      </div>

      <div className="py-1">
        <RenderIf isTrue={loadingBuyingPower}>
          <SkeletonTheme containerClassName="leading-none" width="50%" height="1rem" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!loadingBuyingPower}>
          <p className="font-quicksand-semibold text-xs">
            <small className='text-2xs text-black-light-0-d font-quicksand-regular'>Max Buy</small> <br/>
            {Math.floor(
              (trading?.buyingpower - getStampCostBuy(trading?.buyingpower)) / (price * 100 + ORDER_COST?.buy * price * 100)
            ) || 0}{' '}
            Lot
          </p>
        </RenderIf>
      </div>

      <div className="py-1">
        <RenderIf isTrue={loadingPortfolio}>
          <SkeletonTheme containerClassName="leading-none" width="50%" height="1rem" borderRadius="4px" />
        </RenderIf>

        <RenderIf isTrue={!loadingPortfolio}>
          <p className="font-quicksand-semibold text-xs">
            <small className='text-2xs text-black-light-0-d font-quicksand-regular'>Max Sell</small> <br/>
            {automatedNumberFormat(portfolio?.net / 100 || 0)} Lot
          </p>
        </RenderIf>
      </div>

      <div className="pt-1 w-[100px]">
        <h5 className="text-2xs font-quicksand-regular text-gray-light-0-d mb-[2px]">Set Lot</h5>

        <InputSelect
          value={selectedLot}
          label={false}
          placeholder=""
          className="mb-0 "
          size="small"
          options={optionLots}
          onChange={(e) => {
            dispatch(
              setFastOrderSettingsBySymbol({
                symbol,
                settings: { ...settings, activeLotIndex: optionLots?.findIndex((item) => Number(item?.id) === Number(e?.target?.value))}
              })
            )
          }}
        />
      </div>
    </div>
  );
}

OrderSettingsMobile.propTypes = {
  symbol: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  settings: PropTypes.object,
};

export default OrderSettingsMobile;
