import ProfitLossChart from 'components/chart/profit-loss-chart';
import { max } from 'lodash';
import { useEffect, useState } from 'react';
import { useGetInvestChartQuery } from 'services/rtk-query/invest';

function PortfolioHistoryChart() {
  const { data } = useGetInvestChartQuery();
  const [annotations, setAnnotations] = useState({});
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [colors, setColors] = useState([]);
  const [maxY, setMaxY] = useState(undefined);
  const [profitOrLoss, setProfitOrLoss] = useState({});

  useEffect(() => {
    if (data) {
      let tPoints = [];
      let tColors = ["#6ACBCC", "#FC2626", "#FEC432", "#FC2626"];
      let valueEndSaving = data?.series[0].data[data?.series[0].data.length - 1];
      let valueEndProfit = data?.series[1].data[data?.series[1].data.length - 1];
      
      let isProfit = valueEndProfit >= valueEndSaving;
      
      setProfitOrLoss({
        isProfit: isProfit,
        totalMoney: valueEndProfit,
        totalSaving: valueEndSaving,
        value: valueEndProfit - valueEndSaving,
        valuePercent: (valueEndProfit - valueEndSaving) / valueEndSaving,
      });

      if (data?.categories.length === 0) {
        setProfitOrLoss({
          isProfit: true,
          totalMoney: 0,
          totalSaving: 0,
          value: 0,
          valuePercent: 0,
        });
      }
  
      data?.series[0].data.forEach((item, key) => {
        tPoints.push({
          x: key + 1,
          y: data?.series[0].data[key],
          marker: {
            size: 5,
            fillColor: '#fff',
            strokeColor: tColors[1],
            radius: 2,
            strokeWidth: 3
          }
        });
  
        tPoints.push({
          x: key + 1,
          y: data?.series[1].data[key],
          marker: {
            size: 5,
            fillColor: '#fff',
            strokeColor: tColors[1],
            radius: 2,
            strokeWidth: 3
          }
        });
  
        tPoints.push({
          x: key + 1,
          y: data?.series[0].data[data?.series[0].data.length - 1],
          marker: {
            size: 0
          },
          image: {
            path: '/assets/images/chart-checkpoint.png',
            width: 58,
            height: 20,
            offsetX: 0,
            offsetY: -30
          }
        });
      });

      const _maxYAxis = max([...data.series[0].data, ...data.series[1].data]);
      setMaxY(_maxYAxis + (_maxYAxis * 0.1));
      setSeries(data?.series);
      setCategories(data?.categories);
      setAnnotations({ points: tPoints });
      setColors(tColors);
    }
  }, [data]);

  return (
    <ProfitLossChart
      annotations={annotations}
      series={series}
      colors={colors}
      categories={categories}
      profitOrLoss={profitOrLoss}
      maxY={maxY}
    />
  );
}

export default PortfolioHistoryChart;
