import { createSlice } from '@reduxjs/toolkit';
import { signIn } from './auth.action';
import { useBrowserTabId } from 'hooks/useBrowserTabId';

let initialState = {
  isLogin: false,
  isActiveWeb: true,
  isAuthenticated: false,
  lastLogin: null,
  token: null,
  refreshToken: null,
  user: null,
  roles: [],
  loading: false,
  error: null,
  fcmToken: null,
  dealerUseClientId: null
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    setFCMToken: (state, action) => {
      return { ...state, fcmToken: action?.payload };
    },
    setActiveWeb: (state, { payload }) => {
      return {
        ...state,
        isActiveWeb: payload
      };
    },
    setDealerUseClientId: (state, action) => {
      const browserTabID = useBrowserTabId();
      return {
        ...state,
        [browserTabID]: {
          ...state?.[browserTabID],
          dealerUseClientId: action?.payload
        },
        dealerUseClientId: action?.payload
      };
    },
    reset: (state) => {
      const browserTabID = useBrowserTabId();
      return {
        ...state,
        [browserTabID]: initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        const token = action.payload?.payloads?.accessToken;

        state.loading = false;
        state.isLogin = !!token;
        state.token = token;
        state.lastLogin = action.payload?.payloads?.lastLogin;
        state.error = null;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.isLogin = false;
        state.token = null;
        state.user = null;
        state.lastLogin = null;
        state.error = action.payload || action.error.message;
      });
  }
});

export const authPersistConfig = {
  key: 'pn-trade-auth',
  blacklist: ['loading', 'error']
};

export const {
  setAuth,
  reset,
  setFCMToken,
  setActiveWeb,
  setDealerUseClientId
} = auth.actions;
export * from './auth.action';
export default auth.reducer;
