import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import rtkQueryBaseApi from '../services/rtk-query/base';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import auth, { authPersistConfig } from 'store/auth';
import trading, { tradingPersistConfig } from 'store/trading';
import stock, { stockPersistConfig } from 'store/stock';
import pinaPro, { pinaProPersistConfig } from 'store/pina-pro';
import settings, { settingsPersistConfig } from 'store/settings';

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_REDUX_SECRET_KEY || '1paNteYHEgVebTnjGCLHRtNfLpLiw3Z2',
  onError: function (error) {
    console.log(error)
  }
});

const rootReducer = combineReducers({
  auth: persistReducer({ ...{...authPersistConfig, transforms: [encryptor]}, storage }, auth),
  trading: persistReducer({ ...{...tradingPersistConfig, transforms: [encryptor]}, storage }, trading),
  stock: persistReducer({ ...{...stockPersistConfig, transforms: [encryptor]}, storage }, stock),
  pinaPro: persistReducer({ ...{...pinaProPersistConfig, transforms: [encryptor]}, storage }, pinaPro),
  settings: persistReducer({ ...{...settingsPersistConfig, transforms: [encryptor]}, storage }, settings),
  [rtkQueryBaseApi.reducerPath]: rtkQueryBaseApi.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware, rtkQueryBaseApi.middleware]
});

export const persistor = persistStore(store);
