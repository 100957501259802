import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import { useEffect } from 'react';
import { useSearchStockQuery } from 'services/rtk-query/stock';

export default function StockProvider() {
  const { isLogin } = useBrowserTabAuth()

  const { data: LIST_STOCK } = useSearchStockQuery(
    { all: 'all', all_query: true },
    { skip: !isLogin }
  );

  useEffect(() => {
    if (!window?.LIST_SYMBOL) {
      window.LIST_SYMBOL = LIST_STOCK?.map((item) => {
        return {
          ...item,
          description: item?.description || item?.symbol,
          name: item?.name || item?.symbol,
          full_name: item?.full_name || item?.symbol,
          exchange: 'IDX',
          type: 'stock'
        };
      });
    }
  }, [LIST_STOCK]);

  return <></>;
}
