// eslint-disable-next-line react/prop-types
function IconLegendChart({ className }) {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 9H22.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="13" cy="9" r="6" fill="currentColor" />
    </svg>
  );
}

export default IconLegendChart;
