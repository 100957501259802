import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { cashWithdrawSchema } from './cash-withdraw.validation';
import {
  useCreateWithdrawCashMutation,
  useGetBuyingPowerQuery,
  useGetWithdrawQuery
} from 'services/rtk-query/trading';
import InputCounter from 'components/input/input-counter';
import InputPercent from 'components/input/input-percent';
import WithdrawConfirmation from './modal-confirmation';
import RenderIf from 'components/basic/render-if';
import { formatAccountNumber } from 'utils/formatter/bank';
import IconCopy from 'components/svg/icon-copy';
import Card from 'components/basic/card';

import "./css/styles.css";
import SkeletonTheme from 'components/basic/skeleton-theme';
import IconWarning from 'components/svg/icon-warning';
import { Helmet } from 'react-helmet-async';
import useGetErrorMessage from 'hooks/useGetErrorMessage';

function CashWithdrawForm() {
  const { data, isLoading } = useGetWithdrawQuery(null, { refetchOnMountOrArgChange: true });
  const { data: trading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const [createWithdrawCash] = useCreateWithdrawCashMutation();
  const { getErrorMessage } = useGetErrorMessage();
  
  const [defaultPercent, setDefaultPercent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isOpenModalConfirmation, setOpenModalConfirmation] = useState(false);

  const formik = useFormik({
    initialValues: {
      amount: 0,
      withdrawableCash: 0,
      bankName: null,
      bankAccountId: null
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: cashWithdrawSchema,
    onSubmit: (values) => {
      handleSubmitWithdrawCash(values);
    }
  });

  const handleSubmitWithdrawCash = async () => {
    setIsSubmitting(true);

    try {
      let payloads = {
        amount: formik?.values?.amount,
        bankAccountName: data?.bankAccountName,
        bankAccountNo: data?.bankAccountNo,
        bankBranch: data?.bankName,
        bankId: data?.bankAccountId
      };
      const { success, message, err } = await createWithdrawCash(
        payloads
      ).unwrap();

      if (!success) {
        let error = new Error();
        Object.assign(error, { message, code: err });
        throw error;
      }

      setTimeout(() => {
        setOpenModalConfirmation(true);
        setIsFinished(true);
      }, 300);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
      setOpenModalConfirmation(false);
    }
  };

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      setIsSubmitting(false);
      setOpenModalConfirmation(true);
    }
  };

  useEffect(() => {
    if (trading && data) {
      formik.setFieldValue('withdrawableCash', trading?.withdrawableCash);
      formik.setFieldValue('bankName', data?.bankName);
      formik.setFieldValue('bankAccountId', data?.bankAccountId);
      setTimeout(() => formik.setErrors({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trading, data]);

  return (
    <Card className='p-6'>
      <Helmet>
        <title>Tarik Saldo | Pina Trade</title>
      </Helmet>

      <h5 className="text-xs lg:text-sm">
        Masukkan nominal yang ingin kamu tarik dari Saldo
      </h5>
      <form autoComplete="off" onSubmit={formik.handleSubmit} noValidate>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2 mb-4">
          <div>
            <InputCounter
              value={formik.values.amount}
              label=""
              counterStep={10000}
              max={Math.floor(Number(trading?.withdrawableCash))}
              className="mb-0"
              onChange={(val) => {
                formik.setFieldValue('amount', val);
                setTimeout(() => formik.setTouched({ amount: true }), 100);
              }}
            />
          </div>

          <div className="flex items-center">
            <InputPercent
              value={defaultPercent}
              className="mb-0 w-full customize-input-percent"
              options={[
                {
                  text: '25%',
                  value: 0.25
                },
                {
                  text: '50%',
                  value: 0.5
                },
                {
                  text: '75%',
                  value: 0.75
                },
                {
                  text: '100%',
                  value: 1
                },
              ]}
              onChange={({ value }) => {
                let charge = 0;
                if (![3]?.includes(data?.bankAccountId)) {
                  if (value < 25000000) {
                    charge = 6500;
                  } else if (value >= 25000000 && value < 100000000) {
                    charge = 2900;
                  } else if (value >= 100000000) {
                    charge = 30000;;
                  }
                }

                let newVal = Math.floor(value * trading?.withdrawableCash) - charge;
                if (newVal < 0) newVal =  Math.floor(value * trading?.withdrawableCash);
                formik.setFieldValue('amount', newVal);
                setDefaultPercent(value);
              }}
            />
          </div>
        </div>

        <h5 className="font-quicksand-semibold text-xs lg:text-sm mb-2">
          Informasi rekening tujuan Tarik Saldo
        </h5>

        <div className="border border-gray-base-l dark:border-gray-base-d rounded-xl overflow-hidden grid grid-cols-1 lg:grid-cols-3 p-4 gap-3 lg:gap-0">
          <div>
            <p className="text-gray-light-0-d text-2xs lg:text-xs">Nama Pemilik Akun</p>
            <h5 className='capitalize'>
              {isLoading ? <SkeletonTheme height="1rem" width="100px" borderRadius="8px"/> : data?.bankAccountName?.toLowerCase()}
            </h5>
          </div>
          <div>
            <p className="text-gray-light-0-d text-2xs lg:text-xs">Rekening Bank Tujuan</p>
            <h5 className="flex items-center uppercase">
              {isLoading ? <SkeletonTheme height="1rem" width="100px" borderRadius="8px"/> : data?.bankName?.replace('BANK ', '')}
            </h5>
          </div>
          <div>
            <p className="text-gray-light-0-d text-2xs lg:text-xs">No. Rekening Bank Tujuan</p>
            <h5 className="flex items-center">
            {isLoading ? <SkeletonTheme height="1rem" width="100px" borderRadius="8px"/> :
              <>
                {formatAccountNumber(data?.bankAccountNo)}
                <a
                  role="button"
                  title="Salin No. Rekening"
                  className="ml-1 text-main-base-l"
                  onClick={() => navigator?.clipboard?.writeText(data?.bankAccountNo)}>
                  <IconCopy className="h-5 w-5" />
                </a>
              </>
            }
              
            </h5>
          </div>
        </div>

        <RenderIf isTrue={!formik?.isValid}>
          <div className="font-quicksand-semibold py-3 px-4 flex items-center text-red-base-l text-xs rounded-xl my-3 bg-red-base-l bg-opacity-10">
            <IconWarning className="h-5 w-5 mr-1 flex-none" />
            {Object.keys(formik?.errors)?.map((key, idx) => (
              <RenderIf key={key} isTrue={idx === 0}>
                <span
                  key={key}
                  className="flex items-center w-full flex-auto">
                  {idx === 0 && formik?.errors[key]}
                </span>
              </RenderIf>
            ))}
          </div>
        </RenderIf>

        <div className="pt-3 mt-1 relative flex justify-end">
          <button
            data-qa-id="btnStockBuySubmit"
            onClick={handleConfirmation}
            type="button"
            disabled={isSubmitting || !formik.isValid}
            className={`block w-full text-white bg-main-base-l dark:text-black-base-d disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 py-3 px-3 text-sm font-quicksand-semibold rounded-lg transition-all ease-in-out duration-200`}>
            Tarik Dana
          </button>
        </div>

        <h5 className="text-sm my-4 mb-2 font-quicksand-semibold">
          Ketentuan Saat Tarik Dana
        </h5>

        <ol className="list-disc pl-4 text-xs font-quicksand-regular">
          <li className="mb-1">
            Penarikan dana akan diproses maksimal <b>1x24</b> jam dan{' '}
            <b>di hari bursa.</b>
          </li>
          <li className="mb-1">
            Penarikan dana yang melebihi jumlah dana yang tersedia pada RDN akan
            ditolak secara otomatis oleh sistem.
          </li>
          <li className="mb-1">
            Dana akan ditransfer ke rekening bank yang telah didaftarkan pada
            saat pembukaan rekening efek. PINA tidak akan melakukan transfer
            dana pada rekening dana selain rekening dana yang telah terdaftar
            tersebut.
          </li>
          <li className="mb-1">
            Nasabah akan menerima notifikasi apabila transfer telah dilakukan.
          </li>
          <li className="mb-1">
            Penarikan dana ke rekening tujuan jika berbeda bank maka dikenakan
            biaya sesuai kebijakan masing-masing bank.
          </li>
          <li className="mb-1">
            Silakan menghubungi CS jika dana belum masuk ke rekening kamu.
          </li>
        </ol>

        <WithdrawConfirmation
          amount={formik?.values?.amount}
          isOpen={isOpenModalConfirmation}
          isSubmitting={isSubmitting}
          isFinished={isFinished}
          onClose={() => {
            setOpenModalConfirmation(false);
            formik.resetForm();
          }}
        />
      </form>
    </Card>
  );
}

CashWithdrawForm.propTypes = {};

export default CashWithdrawForm;
