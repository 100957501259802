import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { useEffect, useState } from 'react';
import TransitionModal from 'components/basic/transition-modal';
import dayjs from 'dayjs';
import { useGetWithdrawQuery } from 'services/rtk-query/trading';
import { currency } from 'utils/format';
import IconWarning from 'components/svg/icon-warning';

function WithdrawConfirmation({
  isOpen,
  amount,
  onClose = () => {},
  isSubmitting,
  isFinished
}) {
  const { data } = useGetWithdrawQuery(null, { refetchOnMountOrArgChange: true });

  const [open, setOpen] = useState(isOpen);

  const handleCloseConfirmation = () => {
    if (isSubmitting) return false;
    setOpen(false);
    onClose();
  };

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  console.log('isOpen', isOpen);
  console.log('open', open);

  return (
    <div
      className={`fixed w-full h-full left-0 top-0 z-[60] ${
        open ? 'block' : 'hidden'
      }`}>
      <div
        className="fixed w-full h-full left-0 top-0"
        style={{
          background: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(5px)'
        }}
        onClick={handleCloseConfirmation}></div>
      <TransitionModal isOpen={open} size="mx-auto max-w-md my-20">
        <div
          className="bg-white dark:bg-black-dark-d rounded-lg dark:border dark:border-gray-base-d dark:text-black-light-1-d p-6 z-10 relative -mt-9 transform"
          style={{ transform: 'translateY(-3px)' }}>
          <div className="text-center pb-2 border-b dark:border-gray-base-d">
            <RenderIf isTrue={!isFinished}>
              <h5>Konfirmasi Penarikan Dana</h5>
            </RenderIf>

            <RenderIf isTrue={isFinished}>
              <h5>Kami telah menerima permintaan anda</h5>
            </RenderIf>
          </div>

          <div className="grid grid-cols-2 my-3 text-sm gap-2">
            <div className="text-gray-light-0-d font-quicksand-semibold">Date</div>
            <div className="text-right text-base font-quicksand-bold">
              {dayjs().format('DD MMMM YYYY')}
            </div>

            <div className="text-gray-light-0-d font-quicksand-semibold">Bank Penerima</div>
            <div className="text-right text-base font-quicksand-bold">
              {data?.bankName}
            </div>

            <div className="text-gray-light-0-d font-quicksand-semibold">Nama Penerima</div>
            <div className="text-right text-base font-quicksand-bold">
              {data?.bankAccountName}
            </div>

            <div className="text-gray-light-0-d font-quicksand-semibold">Nomor Rekening</div>
            <div className="text-right text-base font-quicksand-bold">
              {data?.bankAccountNo}
            </div>
            
            <div className="text-gray-light-0-d font-quicksand-semibold">
              Biaya Transaksi
            </div>
            <div className="text-right text-base font-quicksand-bold">
              {[3].includes(data?.bankAccountId) ? 'Free': <>IDR&nbsp;{currency(amount < 25000000 ? 6500 : amount >= 25000000 && amount < 100000000 ? 2900 : amount >= 100000000 ? 30000 : 0, { removeSymbol: true })}</>}
            </div>

            <div className="text-gray-light-0-d font-quicksand-semibold">
              Jumlah Yang Akan terkirim
            </div>
            <div className="text-right text-base font-quicksand-bold">
              IDR&nbsp;{currency(amount, { removeSymbol: true })}
            </div>
          </div>

          <div className="flex bg-gray-light-l dark:bg-gray-base-d rounded-lg p-3 text-xs font-quicksand-semibold items-center">
            <div className="mr-2">
              <IconWarning />
            </div>
            <div>
              <p>
                Proses ini akan memakan waktu maksimal 24 jam. Mohon hubungi
                kami jika belum menerima dana dalam 24 jam.
              </p>
            </div>
          </div>

          <div className="pt-3 mt-1 grid grid-cols-2 gap-2">
            <RenderIf isTrue={isFinished}>
              <button
                data-qa-id="btnStockBuyFinishConfirmation"
                type="button"
                onClick={handleCloseConfirmation}
                className="bg-main-base-l text-white dark:text-black-base-d w-full py-3 px-3 text-sm font-quicksand-semibold rounded-lg col-span-2 flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75">
                Selesai
              </button>
            </RenderIf>

            <RenderIf isTrue={!isFinished}>
              <button
                data-qa-id="btnDeleteAccountCancelConfirmation"
                type="button"
                onClick={handleCloseConfirmation}
                disabled={isSubmitting}
                className="bg-red-base-l text-white dark:text-black-base-d py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:bg-opacity-75">
                Batalkan
              </button>

              <button
                data-qa-id="btnDeleteAccountPreSubmitConfirmation"
                type="submit"
                disabled={isSubmitting}
                className="bg-main-base-l text-white dark:text-black-base-d disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-100 py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full flex justify-center items-center transition-all ease-in-out duration-100 hover:border-opacity-75">
                {isSubmitting && <Spinner />}
                Konfirmasi
              </button>
            </RenderIf>
          </div>
        </div>
      </TransitionModal>
    </div>
  );
}

WithdrawConfirmation.propTypes = {
  amount: PropTypes.number,
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isFinished: PropTypes.bool,
  onClose: PropTypes.func
};

export default WithdrawConfirmation;
