import Card from 'components/basic/card';
import { triggerSegmentEvent } from 'lib/segment';
import { NavLink } from 'react-router-dom';
import TWOrderBook from './tw-orderbook';
import TWNews from './tw-news';
import TWDoneDetail from './tw-done-detail';
import RenderIf from 'components/basic/render-if';
import SkeletonTheme from 'components/basic/skeleton-theme';
import {
  invalidateStockByCode,
  invalidateStockDoneDetailByCode,
  invalidateStockDoneSummaryByCode,
  useGetStockInfoByCodeQuery
} from 'services/rtk-query/stock';
import TWIntradayChart from './tw-intraday-chart';
import SectionStockDetail from '../search/_partials/section-stock-detail';
import IconClose from 'components/svg/icon-close';
import TWRunningTrade from './tw-running-trade';
import IconRefresh from 'components/svg/icon-refresh';
import { useDispatch } from 'react-redux';
import useCustomNavigate from 'hooks/useCustomNavigate';

export default function TWStockDetail({ symbol }) {
  const { customNavigate } = useCustomNavigate();
  const { data: stockDetailInfo, isFetching } = useGetStockInfoByCodeQuery(
    symbol,
    { refetchOnMountOrArgChange: true }
  );

  return (
    <Card className="!bg-gray-base-l dark:!bg-black-dark-d dark:border-gray-base-d dark:border relative p-3 zoom-2">
      <h5 className="dark:text-white mb-4 text-sm ml-1">Stock Detail</h5>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-[300px] flex-none order-3 lg:order-1">
          <div className="mb-3">
            <Card className="p-4 pb-1">
              <h5 className="mb-3 font-quicksand-semibold text-sm dark:text-black-light-1-d">
                Karakteristik
              </h5>
              <ul className="flex items-center flex-wrap">
                <RenderIf isTrue={isFetching}>
                  <SkeletonTheme
                    className="mr-2 mb-3"
                    width="100px"
                    height="1.5rem"
                    borderRadius="1rem"
                  />
                  <SkeletonTheme
                    className="mr-2 mb-3"
                    width="100px"
                    height="1.5rem"
                    borderRadius="1rem"
                  />
                  <SkeletonTheme
                    className="mr-2 mb-3"
                    width="100px"
                    height="1.5rem"
                    borderRadius="1rem"
                  />
                  <SkeletonTheme
                    className="mr-2 mb-3"
                    width="100px"
                    height="1.5rem"
                    borderRadius="1rem"
                  />
                </RenderIf>

                {stockDetailInfo?.thematic?.map((item, key) => (
                  <RenderIf key={key} isTrue={item?.logo && item?.logo != ''}>
                    <li className="">
                      <NavLink
                        to={`/stock-market/thematic/${item?.id}`}
                        className="mr-2 mb-2 text-2xs font-quicksand-semibold p-1 px-2 border dark:border-black-light-1-l text-gray-light-0-d dark:text-black-light-1-d rounded-lg flex items-center transition-all ease-in-out duration-200 hover:opacity-70">
                        <span>{item?.name}</span>
                      </NavLink>
                    </li>
                  </RenderIf>
                ))}

                {stockDetailInfo?.thematic?.length === 0 && !isFetching && (
                  <p className="text-gray-light-0-d dark:text-black-light-1-d text-xs pb-2">
                    Belum ada data.
                  </p>
                )}
              </ul>
            </Card>
          </div>

          <div>
            <Card className="p-4 pb-1 mb-3">
              <h5 className="text-sm dark:text-black-light-1-d">
                Done Details
              </h5>

              <div className="-mx-3">
                <TWDoneDetail symbol={symbol} />
              </div>
            </Card>

            <Card className="p-4 pb-1">
              <h5 className="text-sm dark:text-black-light-1-d">
                Running Trade
              </h5>

              <RenderIf isTrue={isFetching}>
                <table>
                  {Array(5)
                    .fill(1)
                    .map((el, key) => (
                      <tr key={key} className="relative mt-4">
                        <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left sticky left-0 card-widget-gradient">
                          <SkeletonTheme
                            width="50px"
                            height="1rem"
                            borderRadius="6px"
                          />
                        </td>
                        <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                          <SkeletonTheme
                            width="50px"
                            height="1rem"
                            borderRadius="6px"
                          />
                        </td>
                        <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                          <SkeletonTheme
                            width="50px"
                            height="1rem"
                            borderRadius="6px"
                          />
                        </td>
                        <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                          <SkeletonTheme
                            width="50px"
                            height="1rem"
                            borderRadius="6px"
                          />
                        </td>
                      </tr>
                    ))}
                </table>
              </RenderIf>

              <RenderIf isTrue={!isFetching}>
                <div>
                  <TWRunningTrade
                    widgetSettings={{ stocks: [{ code: symbol }] }}
                    text=""
                    symbol={symbol}
                    className="p-0"
                    rows={15}
                    isCardLess
                  />
                </div>
              </RenderIf>
            </Card>
          </div>
        </div>
        <div className="flex-auto lg:px-3 order-1 lg:order-2">
          <div className="grid grid-cols-1 gap-3">
            <div>
              <QuickAction className="lg:hidden" symbol={symbol} />
              <div>
                <TWIntradayChart
                  symbol={symbol}
                  filterSize="medium"
                  showStockDetail={false}
                  typeHeader="LONG"
                  views={{
                    orderBook: false,
                    buyOrSell: false,
                    customFilter: true,
                    customLegend: true,
                    customHeader: true,
                    chart: true
                  }}
                  onChangeSymbol={(newSymbol) => {
                    triggerSegmentEvent({ event: 'TR Search Feature Clicked' });
                    customNavigate({ query: { 'stock-detail': newSymbol } });
                  }}
                  height={250}
                />
              </div>
            </div>

            <div>
              <SectionStockDetail symbol={symbol} />
            </div>
          </div>
        </div>

        <div className="flex-none lg:w-[300px] order-2 lg:order-3 mt-3 lg:mt-0">
          <QuickAction className="hidden lg:block" symbol={symbol} />

          <Card className="p-4 mb-3">
            <h5 className="text-sm font-quicksand-semibold mb-2 dark:text-black-light-1-d">
              Orderbook
            </h5>
            <TWOrderBook
              isExpandRow
              rows={5}
              isShowChartOrderbook
              symbol={symbol}
              onClickPrice={(price) =>
                customNavigate({
                  query: { 'simple-order': symbol, price: price }
                })
              }
            />
          </Card>

          <Card className="p-4">
            <h5 className="text-sm font-quicksand-semibold mb-2 dark:text-black-light-1-d">
              Berita Terkait
            </h5>
            <div className="-mx-2">
              <TWNews symbol={symbol} />
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
}

export function QuickAction({ symbol, className, showRefreshTool = true }) {
  const dispatch = useDispatch();
  const { customNavigate } = useCustomNavigate();

  const handleRefresh = () => {
    dispatch(invalidateStockByCode(symbol));
    dispatch(invalidateStockDoneDetailByCode(symbol));
    dispatch(invalidateStockDoneSummaryByCode(symbol));
  };

  return (
    <div className={className}>
      <RenderIf isTrue={showRefreshTool}>
        <div className="flex justify-end mb-3 -mt-9">
          <button
            type="button"
            className="outline-none appearance-none flex items-center mx-4 justify-center hover:text-main-base-l dark:text-main-base-l dark:hover:opacity-75 transition-all duration-200"
            onClick={handleRefresh}
            title="Refresh Widget">
            <IconRefresh className="h-5 w-5" />
          </button>

          <button
            type="button"
            onClick={() => customNavigate()}
            className="outline-none appearance-none flex items-center justify-center hover:text-main-base-l dark:text-main-base-l dark:hover:opacity-75 transition-all duration-200">
            <IconClose className="h-6 w-6" />
          </button>
        </div>
      </RenderIf>

      <Card className="p-4 mb-3">
        <h5 className="font-quicksand-semibold text-sm mb-3 dark:text-black-light-1-d">
          Quick Action
        </h5>
        <div className="grid gap-3 grid-cols-2 text-xs font-quicksand-semibold">
          <button
            title="Simple Buy"
            onClick={() => {
              customNavigate({
                query: { 'simple-order': symbol },
                isPushQuery: true
              });
              triggerSegmentEvent({ event: 'TR Simple Buy Clicked' });
            }}
            className="p-2 rounded-xl border-2 text-main-base-l font-quicksand-semibold border-main-base-l hover:opacity-75 transition-all ease-in-out duration-150">
            Simple Order
          </button>
          <button
            title="Multi Buy"
            className="p-2 rounded-xl border-2 text-main-base-l font-quicksand-semibold border-main-base-l hover:opacity-75 transition-all ease-in-out duration-150"
            onClick={() => {
              customNavigate({
                query: { 'fast-order': symbol },
                isPushQuery: true
              });
              triggerSegmentEvent({ event: 'TR Multi Buy Clicked' });
            }}>
            Fast Order
          </button>
        </div>
      </Card>
    </div>
  );
}
