import PropTypes from 'prop-types';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import Tabs from 'components/tabs/tabs';
import { useEffect, useMemo, useState } from 'react';
import TWBrokerActivity from './tw-broker-activity';
import TWBrokerSummary from './tw-broker-summary';
import { isEmpty, isEqual } from 'lodash';

function TWBandarmology({
  broker = {},
  widgetSettings = {},
  maxHeight = '270px',
  onChangeWidgetSettings = () => {},
  onClickSearch = () => {},
}) {
  const [activeTabIndex, setActiveIndexTab] = useState(widgetSettings?.activeTabIndex || 0);
  const [tempSymbol, setTempSymbol] = useState(broker);
  const [tempBroker, setTempBroker] = useState(widgetSettings?.broker);

  const newSymbol = useMemo(() => {
    return broker;
  }, [broker]);

  const newBroker = useMemo(() => {
    return widgetSettings?.broker;
  }, [widgetSettings]);
  
  useEffect(() => {
    if (!isEqual(newSymbol, tempSymbol) && !isEmpty(newSymbol)) {
      setTempSymbol(newSymbol)
    }
  }, [newSymbol, tempSymbol]);

  useEffect(() => {
    if (!isEqual(newBroker, tempBroker) && !isEmpty(newBroker)) {
      setTempBroker(newBroker)
    }
  }, [newBroker, tempBroker]);

  return (
    <Card className="h-full">
      <div className="top-0 z-30">
        <Tabs
          initialOpenIndex={activeTabIndex}
          className="grid grid-cols-2 font-quicksand-semibold text-gray-light-0-d dark:text-black-light-0-d text-xs"
          data={[
            {
              index: 0,
              text: 'Broker Summary'
            },
            {
              index: 1,
              text: 'Broker Activity'
            }
          ]}
          onChange={(index) => {
            setActiveIndexTab(index);
            onChangeWidgetSettings({ activeTabIndex: index });
          }}
        />
      </div>

      <RenderIf isTrue={activeTabIndex === 0}>
        <TWBrokerSummary
          symbol={tempSymbol?.symbol}
          maxHeight={maxHeight}
          onChangeSymbol={(symbol) => {
            setTempSymbol({ ...tempSymbol, symbol });
            onClickSearch(symbol);
            onChangeWidgetSettings({ activeTabIndex: 1, broker: { ...tempSymbol, symbol } });
          }}
          onClickBroker={(_broker) => {
            setActiveIndexTab(1);
            setTempSymbol(_broker);
            onChangeWidgetSettings({ activeTabIndex: 1, broker: _broker });
          }}
        />
      </RenderIf>

      <RenderIf isTrue={activeTabIndex === 1}>
        <TWBrokerActivity
          broker={tempBroker}
          maxHeight={maxHeight}
          onChangeBroker={(broker) => {
            setTempBroker(broker);
            onChangeWidgetSettings({ activeTabIndex: 1, broker});
          }}
        />
      </RenderIf>
    </Card>
  );
}

TWBandarmology.propTypes = {
  broker: PropTypes.object,
  onClickSearch: PropTypes.func,
  maxHeight: PropTypes.string,
  onChangeWidgetSettings: PropTypes.func,
  widgetSettings: PropTypes.object,
};

export default TWBandarmology;
