import RenderIf from 'components/basic/render-if';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetTechnicalStockQuery } from 'services/rtk-query/stock';
import { automatedNumberFormat } from 'utils/format';

function TWTechnical({ symbol }) {
  const { data: tempData, isFetching, isError } = useGetTechnicalStockQuery({
    symbol,
    interval: '1d'
  }, { refetchOnMountOrArgChange: true });

  const [data, setData] = useState(null);
  const [status, setStatus] = useState({ buy: 0, sell: 0, neutral: 0});
  const [summary, setSummary] = useState({
    status: 'Hold',
    color: 'text-gray-light-0-d',
    backgroundColor: 'bg-gray-base-l'
  });

  const getPercentValue = (_value) => {
    if (!_value) return '0%';
    return _value / (status?.buy + status?.sell + status?.neutral) * 100 + '%';
  }

  useEffect(() => {
    if (data) {
      let lengthBuy = Object?.keys(data)?.filter(_obj => data[_obj]?.signal === 'BUY').length;
      let lengthSell = Object?.keys(data)?.filter(_obj => data[_obj]?.signal === 'SELL').length;
      let lengthNeutral = Object?.keys(data)?.filter(_obj => data[_obj]?.signal === 'NEUTRAL').length;

      let _summary = 'Hold';
      let _color = 'text-gray-light-0-d';
      let _backgroundColor = 'bg-gray-base-l';
      
      if (lengthBuy > lengthSell && lengthBuy > lengthNeutral) {
        _summary = 'Strong Buy'
        _color = 'text-[#3d7800]';
        _backgroundColor = 'bg-[#baed84]';
      }
      
      if (lengthSell > lengthBuy && lengthSell >  lengthNeutral) {
        _summary = 'Strong Sell'
        _color = 'text-red-base-l';
        _backgroundColor = 'bg-[#FBD1BE]';
      }

      setSummary({ status: _summary, color: _color, backgroundColor: _backgroundColor });

      setStatus({
        buy: lengthBuy,
        sell: lengthSell,
        neutral: lengthNeutral,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!isError && tempData) setData(tempData);
    if (isError) {
      setData(null);
      setSummary({
        status: 'Hold',
        color: 'text-gray-light-0-d',
        backgroundColor: 'bg-gray-base-l'
      });

      setStatus({ buy: 0, sell: 0, neutral: 0});
    };
  }, [tempData, isError]);

  return (
    <div className="py-4 dark:text-black-light-1-d">
      <h5 className="mb-3">Kesimpulan</h5>
      <div className="flex">
        <div className="flex-none">
          {
            isFetching ?
             <SkeletonTheme className='h-[72px] w-[72px] rounded-full' />
            :
            <div className={`h-[72px] w-[72px] text-white rounded-full flex items-center justify-center font-quicksand-semibold p-1 ${summary?.backgroundColor}`}>
              <span className={`text-center text-xs w-[40px] ${summary?.color}`}>{summary?.status}</span>
            </div>
          }
        </div>

        <div className="flex-auto mx-4">
          <div className="h-3 w-full bg-[#F7F8F9] dark:bg-black-dark-d relative rounded-md overflow-hidden mb-4">
            {!isFetching && <span className="absolute left-0 w-[80%] top-0 block h-full rounded-md bg-green-base-l" style={{width: getPercentValue(status?.buy)}}></span> }
          </div>
          <div className="h-3 w-full bg-[#F7F8F9] dark:bg-black-dark-d relative rounded-md overflow-hidden mb-4">
            {!isFetching && <span className="absolute left-0 w-[80%] top-0 block h-full rounded-md bg-red-base-l" style={{width: getPercentValue(status?.sell)}}></span> }
          </div>
          <div className="h-3 w-full bg-[#F7F8F9] dark:bg-black-dark-d relative rounded-md overflow-hidden mb-4">
            {!isFetching && <span className="absolute left-0 w-[80%] top-0 block h-full rounded-md bg-gray-light-0-d" style={{width: getPercentValue(status?.neutral)}}></span> }
          </div>
        </div>

        <div className="flex-none text-xs -mt-1 font-quicksand-semibold">
          <p className="mb-[13px]">Buy ({status?.buy})</p>
          <p className="mb-[13px]">Sell ({status?.sell})</p>
          <p className="mb-[13px]">Neutral ({status?.neutral})</p>
        </div>
      </div>

      <p className="text-xs">
        <b>Disclaimer:</b> Investasi saham mengandung risiko dan seluruhnya
        menjadi tanggung jawab pribadi.
      </p>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 lg:gap-6">
        <div>
          <div className='flex items-center justify-between my-3 mb-1'>
            <h5 className='text-sm'>Teknikal Indikator</h5>
          </div>

          <table className="text-xs w-full text-center font-quicksand-semibold">
            <thead className="text-gray-light-0-d">
              <tr className="relative">
                <td className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l w-[40%]`}>
                  <span className="flex items-center justify-between">Name</span>
                </td>
                <td className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l w-[30%]`}>
                  <span className="flex items-center justify-between">Value</span>
                </td>
                <td className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l w-[20%]`}>
                  <span className="flex items-center justify-between">Action</span>
                </td>
              </tr>
            </thead>
            <tbody className="text-xs">
              <RenderIf isTrue={!data && !isFetching}>
                {Array(5)
                  .fill(1)
                  .map((el, key) => (
                    <tr key={key} className="relative">
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">-</td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">-</td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">-</td>
                    </tr>
                  ))}
              </RenderIf>

              <RenderIf isTrue={isFetching}>
                {Array(5)
                  .fill(1)
                  .map((el, key) => (
                    <tr key={key} className="relative">
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                        <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                        <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                        <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                      </td>
                    </tr>
                  ))}
              </RenderIf>

              <RenderIf isTrue={data && !isFetching}>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">ADX</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.adx?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.adx?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.adx?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.adx?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">AO</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.ao?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.ao?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.ao?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.ao?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">ATR</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.atr?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.atr?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.atr?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.atr?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">BB Lower</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.bblower?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.bblower?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.bblower?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.bblower?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">BB Upper</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.bbupper?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.bbupper?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.bbupper?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.bbupper?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">CCI</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.cci?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.cci?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.cci?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.cci?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">MACD</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.macd?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.macd?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.macd?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.macd?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">MOM</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.mom?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.mom?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.mom?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.mom?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">ROC</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.roc?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.roc?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.roc?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.roc?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">RSI</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.rsi?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.rsi?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.rsi?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.rsi?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">PSAR</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.psar?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.psar?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.psar?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.psar?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">Stockhastic %D</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.stochd?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.stochd?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.stochd?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.stochd?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">Stockhastic %K</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.stochk?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.stochk?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.stochk?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.stochk?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">Stockhastic RSI %K</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.stochrsik?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.stochrsik?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.stochrsik?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.stochrsik?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">Williams %R</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.willr?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.willr?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.willr?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.willr?.signal || '-'}
                    </span>
                  </td>
                </tr>
              </RenderIf>

            </tbody>
          </table>
        </div>

        <div>
          <div className='flex items-center justify-between my-3 mb-1'>
            <h5 className='text-sm'>Moving Averages</h5>
          </div>

          <table className="text-xs w-full text-center font-quicksand-semibold">
            <thead className="text-gray-light-0-d">
              <tr className="relative">
                <td className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l w-[40%]`}>
                  <span className="flex items-center justify-between">Name</span>
                </td>
                <td className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l w-[30%]`}>
                  <span className="flex items-center justify-between">Value</span>
                </td>
                <td className={`p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l w-[20%]`}>
                  <span className="flex items-center justify-between">Action</span>
                </td>
              </tr>
            </thead>
            <tbody className="text-xs">
              <RenderIf isTrue={!data && !isFetching}>
                {Array(5)
                  .fill(1)
                  .map((el, key) => (
                    <tr key={key} className="relative">
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">-</td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">-</td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">-</td>
                    </tr>
                  ))}
              </RenderIf>

              <RenderIf isTrue={isFetching}>
                {Array(5)
                  .fill(1)
                  .map((el, key) => (
                    <tr key={key} className="relative">
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                        <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                        <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                      </td>
                      <td className="p-2 border-b border-gray-light-l dark:border-gray-base-d text-left">
                        <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
                      </td>
                    </tr>
                  ))}
              </RenderIf>

              <RenderIf isTrue={data && !isFetching}>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">EMA-5</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.ema_5?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.ema_5?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.ema_5?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.ema_5?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">EMA-10</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.ema_10?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.ema_10?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.ema_10?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.ema_10?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">EMA-20</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.ema_20?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.ema_20?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.ema_20?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.ema_20?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">EMA-50</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.ema_50?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.ema_50?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.ema_50?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.ema_50?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">EMA-100</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.ema_100?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.ema_100?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.ema_100?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.ema_100?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">EMA-200</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.ema_200?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.ema_200?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.ema_200?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.ema_200?.signal || '-'}
                    </span>
                  </td>
                </tr>

                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">SMA-5</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.sma_5?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.sma_5?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.sma_5?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.sma_5?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">SMA-10</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.sma_10?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.sma_10?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.sma_10?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.sma_10?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">SMA-20</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.sma_20?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.sma_20?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.sma_20?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.sma_20?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">SMA-50</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.sma_50?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.sma_50?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.sma_50?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.sma_50?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">SMA-100</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.sma_100?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.sma_100?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.sma_100?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.sma_100?.signal || '-'}
                    </span>
                  </td>
                </tr>
                <tr className="group">
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span className="uppercase">SMA-200</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span>{automatedNumberFormat(data?.sma_200?.value)}</span>
                  </td>
                  <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d transition-all ease-in-out duration-100 p-2 border-b group-last:border-b-0 border-gray-light-l dark:border-gray-base-d text-left">
                    <span
                      className={`${
                        data?.sma_200?.signal === 'BUY'
                          ? 'text-green-base-l'
                          : data?.sma_200?.signal === 'SELL'
                          ? 'text-red-base-l'
                          : 'text-gray-light-0-d'
                      }`}>
                      {data?.sma_200?.signal || '-'}
                    </span>
                  </td>
                </tr>
              </RenderIf>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

TWTechnical.propTypes = {
  symbol: PropTypes.string
};

export default TWTechnical;
