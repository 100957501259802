import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { useGetStockCommoditiesQuery } from 'services/rtk-query/stock';
import { currency } from 'utils/format';
import dayjs from 'dayjs';
import SkeletonTheme from 'components/basic/skeleton-theme';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import { useEffect } from 'react';

function SectionCommodityList({ isCardLess }) {
  const isBrowserWakeUp = useBrowserWakeUp();
  const { data: commodities, isLoading, refetch } = useGetStockCommoditiesQuery();

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  return (
    <section
      className={`card-widget-gradient dark:text-black-light-1-d ${!isCardLess ? 'rounded-xl ' : ''}`}>
      <div className="p-4 pb-0">
        {!isCardLess && (
          <h3 className="font-quicksand-semibold text-sm">Komoditas</h3>
        )}
      </div>

      <div className="pt-3">
        <RenderIf isTrue={isLoading}>
          <div className="p-3">
            {Array(5)
              .fill(1)
              .map((el, key) => (
                <div key={key} className="flex justify-between mb-5">
                  <div className="flex">
                    <SkeletonTheme
                      containerClassName="mr-3"
                      width="40px"
                      height="40px"
                      borderRadius="100%"
                    />

                    <div>
                      <SkeletonTheme
                        containerClassName="mr-1"
                        width="70px"
                        height="16px"
                        borderRadius="6px"
                      />

                      <SkeletonTheme
                        containerClassName="mr-1"
                        width="100px"
                        height="16px"
                        borderRadius="6px"
                      />
                    </div>
                  </div>

                  <div className="text-right">
                    <SkeletonTheme width="40px" height="16px" borderRadius="6px" />

                    <SkeletonTheme width="50px" height="16px" borderRadius="6px" />
                  </div>
                </div>
              ))}
          </div>
        </RenderIf>

        <RenderIf isTrue={commodities?.length > 0 && !isLoading}>
          <ul>
            {commodities?.map((item, key) => (
              <li
                key={key}
                className="flex px-4 justify-between items-center py-3 border-b dark:border-gray-base-d last:border-b-0 hover:bg-gray-light-l dark:hover:bg-black-dark-d">
                <div className="font-quicksand-semibold text-xs">
                  <span className="block">{item?.macro_item}</span>
                  <span className="block text-xs text-gray-light-0-d font-quicksand-medium">
                    {dayjs(item?.date).format('DD MMM YYYY')}
                  </span>
                </div>
                <div
                  className={`font-quicksand-semibold text-xs text-right ${
                    item?.daily_return < 0
                      ? 'text-red-base-l'
                      : item?.daily_return > 0
                      ? 'text-green-base-l'
                      : 'text-gray-light-0-d'
                  }`}>
                  <span className="text-black-base-l dark:text-black-light-1-d mr-1 block">
                    USD&nbsp;{currency(Math.round(item?.value), { removeSymbol: true })}
                  </span>
                  <span className="block font-quicksand-regular text-2xs">
                    (
                    {currency(item?.daily_return * 100, { removeSymbol: true })}
                    %)
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </RenderIf>

        <RenderIf isTrue={commodities?.length === 0 && !isLoading}>
          <p className="text-sm text-gray-light-0-d px-3">Belum ada data.</p>
        </RenderIf>
      </div>
    </section>
  );
}

SectionCommodityList.propTypes = {
  isCardLess: PropTypes.bool,
};

export default SectionCommodityList;
