import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { ProfilePersonalDataSchema } from './profile-personal-data-form.validation';
import InputText from 'components/input/input-text';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import InputSelect from 'components/input/input-select';
import { me } from 'store/auth';
import { useUpdateUserMutation } from 'services/rtk-query/user';
import useToastr from 'hooks/useToastr';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function ProfilePersonalDataForm({ initialValues, master }) {
  const dispatch = useDispatch();
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const { token } = useBrowserTabAuth();
  const [updateUser] = useUpdateUserMutation();

  const [isSubmitting, setisSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      // Infromasi Pribadi
      firstName: initialValues?.firstName,
      lastName: initialValues?.lastName,
      gender: initialValues?.gender,
      birthPlace: initialValues?.birthPlace,
      birthDate: initialValues?.birthDate,
      ktpNo: initialValues?.ktpNo,
      npwpNo: initialValues?.npwpNo,
      motherName: initialValues?.motherName,

      // Informasi Alamat
      phoneNo: initialValues?.phoneNo,
      email: initialValues?.email,
      address: initialValues?.address,
      nationality: initialValues?.nationality,
      provinceId: initialValues?.provinceId,
      cityId: initialValues?.cityId,
      kecId: initialValues?.kecId,
      kelId: initialValues?.kelId,
      zipCode: initialValues?.zipCode,

      // Informasi Pekerjaan
      occupation: initialValues?.occupation,
      companyName: initialValues?.companyName,
      industry: initialValues?.industry,
      position: initialValues?.position,
      employmentLength: initialValues?.employmentLength,
      companyPhone: initialValues?.companyPhone,
      companyAddress: initialValues?.companyAddress,
      companyZipCode: initialValues?.companyZipCode,

      // Informasi Finansial
      sourceFunds: initialValues?.sourceFunds,
      monthlyIncome: initialValues?.monthlyIncome,
      monthlySpending: initialValues?.monthlySpending,

      // Informasi Rekening
      bankAccountId: initialValues?.bankAccountId,
      bankAccountHolder: initialValues?.bankAccountHolder,
      bankAccountNo: initialValues?.bankAccountNo,
      rdnbankId: initialValues?.rdnbankId,
      rdnbankAccountHolder: initialValues?.rdnbankAccountHolder,
      rdnbankAccountNo: initialValues?.rdnbankAccountNo
    },
    validateOnMount: false,
    validationSchema: ProfilePersonalDataSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleSubmit = async (values) => {
    setisSubmitting(true);
    try {
      await updateUser(values).unwrap();
      dispatch(me(token));
      toastr.success('Data pribadi berhasil diupdate!');
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setisSubmitting(false);
    }
  };

  const Spinner = () => {
    return (
      <svg
        className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h5 className="mb-3 text-lg">Informasi Pribadi</h5>
      <div className="text-sm">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled
            value={formik?.values?.firstName}
            name="firstName"
            placeholder="Nama depan Anda"
            label="Nama Depan"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled
            value={formik?.values?.lastName}
            name="lastName"
            placeholder="Nama belakang Anda"
            label="Nama Belakang"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled={isSubmitting}
            value={formik?.values?.birthPlace}
            name="birthPlace"
            placeholder="Tempat Lahir"
            label="Tempat Lahir"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.birthDate}
            name="birthDate"
            placeholder="Tanggal Lahir"
            label="Tanggal Lahir"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled={isSubmitting}
            value={formik?.values?.ktpNo}
            name="ktpNo"
            placeholder="No Identitas"
            label="No Identitas"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.npwpNo}
            name="npwpNo"
            placeholder="No. NPWP"
            label="No. NPWP"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputSelect
            value={formik?.values?.gender}
            label="Jenis Kelamin"
            className="mb-4"
            options={master?.options?.filter(({ type }) => type === 'sex')}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.motherName}
            name="motherName"
            placeholder="Nama Ibu Kandung"
            label="Nama Ibu Kandung"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <br />
        <br />
        <h5 className="mb-3 text-lg">Informasi Alamat</h5>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            value={formik?.values?.phoneNo}
            name="phoneNo"
            placeholder="Masukkan Nomor Handphone Kamu"
            label="No Telepon"
            className="mb-4 text-gray-light-0-d"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            disabled
          />

          <InputText
            value={formik?.values?.email}
            placeholder="Tulis email anda"
            label="Email"
            name="email"
            className="mb-4 text-gray-light-0-d"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            disabled
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled={isSubmitting}
            value={formik?.values?.nationality}
            name="nationality"
            placeholder="Kewarganegaraan"
            label="Kewarganegaraan"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputSelect
            value={formik?.values?.provinceId}
            label="Provinsi"
            className="mb-4"
            options={master?.provinces}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputSelect
            value={formik?.values?.cityId}
            label="Kota/Kabupaten"
            className="mb-4"
            options={master?.cities}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputSelect
            value={formik?.values?.kecId}
            label="Kecamatan"
            className="mb-4"
            options={master?.districts}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputSelect
            value={formik?.values?.kelId}
            label="Kelurahan"
            className="mb-4"
            options={master?.villages}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.zipCode}
            name="zipCode"
            placeholder="Kode Pos"
            label="Kode Pos"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <InputText
          disabled={isSubmitting}Area
          value={formik?.values?.address}
          name="address"
          placeholder="Alamat"
          label="Alamat"
          className="mb-4"
          rows={4}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <br />
        <br />
        <h5 className="mb-3 text-lg">Informasi Pekerjaan</h5>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputSelect
            value={formik?.values?.occupation}
            label="Pekerjaan"
            className="mb-4"
            options={master?.options?.filter(
              ({ type }) => type === 'occupation'
            )}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.companyName}
            name="companyName"
            placeholder="Nama Perusahaan"
            label="Nama Perusahaan"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled={isSubmitting}
            value={formik?.values?.industry}
            name="industry"
            placeholder="Bidang Usaha"
            label="Bidang Usaha"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.position}
            name="position"
            placeholder="Position"
            label="Position"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled={isSubmitting}
            value={formik?.values?.employmentLength}
            name="employmentLength"
            placeholder="Lama Bekerja"
            label="Lama Bekerja"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.companyPhone}
            name="companyPhone"
            placeholder="No Telepon Perusahaan"
            label="No Telepon Perusahaan"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <InputText
          disabled={isSubmitting}
          value={formik?.values?.companyZipCode}
          name="companyZipCode"
          placeholder="Kode Pos Perusahaan"
          label="Kode Pos Perusahaan"
          className="mb-4"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <InputText
          disabled={isSubmitting}Area
          rows={4}
          value={formik?.values?.companyAddress}
          name="companyAddress"
          placeholder="Alamat Perusahaan"
          label="Alamat Perusahaan"
          className="mb-4"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <br />
        <br />
        <h5 className="mb-3 text-lg">Informasi Finansial</h5>

        <InputSelect
          value={formik?.values?.sourceFunds}
          label="Jenis Penghasilan"
          className="mb-4"
          options={master?.options?.filter(
            ({ type }) => type === 'source_income'
          )}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled={isSubmitting}
            value={formik?.values?.monthlyIncome}
            name="monthlyIncome"
            placeholder="Penghasilan"
            label="Penghasilan"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.monthlySpending}
            name="monthlySpending"
            placeholder="Pengeluaran Per Bulan"
            label="Pengeluaran Per Bulan"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <br />
        <br />
        <h5 className="mb-3 text-lg">Informasi Rekening</h5>

        <InputSelect
          value={formik?.values?.bankAccountId}
          label="Bank"
          className="mb-4"
          options={master?.banks}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled={isSubmitting}
            value={formik?.values?.bankAccountHolder}
            name="bankAccountHolder"
            placeholder="Nama Pemilik Rekening Bank"
            label="Nama Pemilik Rekening Bank"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled={isSubmitting}
            value={formik?.values?.bankAccountNo}
            name="bankAccountNo"
            placeholder="No Rekening Bank"
            label="No Rekening Bank"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>

        <InputSelect
          value={formik?.values?.rdnbankId}
          label="RDN"
          className="mb-4"
          options={master?.banks}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputText
            disabled
            value={formik?.values?.rdnbankAccountHolder}
            name="rdnbankAccountHolder"
            placeholder="Nama Pemilik Rekening RDN"
            label="Nama Pemilik Rekening RDN"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />

          <InputText
            disabled
            value={formik?.values?.rdnbankAccountNo}
            name="rdnbankAccountNo"
            placeholder="Nomor Rekening RDN"
            label="Nomor Rekening RDN"
            className="mb-4"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>
      </div>

      <div className="pt-5 flex justify-end">
        <button
          type="submit"
          disabled={!formik.isValid || isSubmitting}
          className={`bg-main-base-l text-white disabled:cursor-not-allowed disabled:bg-gray-base-l disabled:text-gray-dark-d flex px-10 w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden focus:outline-none`}>
          {isSubmitting && <Spinner />}
          Ubah Data Personal
        </button>
      </div>
    </form>
  );
}

ProfilePersonalDataForm.propTypes = {
  initialValues: PropTypes.object,
  master: PropTypes.object
};

export default ProfilePersonalDataForm;
