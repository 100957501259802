import PropTypes from 'prop-types';
const { useState, useEffect } = require('react');

function TransitionModal({
  isOpen,
  children,
  position = 'center',
  size = 'mx-auto max-w-3xl my-5'
}) {
  const [isTransition, setIsTransition] = useState(false);

  const getTransitionPosition = (_position) => {
    let transition = 'translate-y-[2rem] opacity-0 scale-75';

    if (_position === 'right') {
      transition = 'translate-x-full opacity-0';
    }

    if (_position === 'left') {
      transition = '-translate-x-full opacity-0';
    }

    return transition;
  };

  const getModalPosition = (_position) => {
    let _modalPosition = '';
    if (_position === 'right') {
      _modalPosition = 'mx-auto mr-0 md:w-1/3 max-w-[370px] xl:w-1/4 mt-0 mb-0';
    }

    if (_position === 'left') {
      _modalPosition = 'mx-auto ml-0 md:w-1/3 max-w-[370px] xl:w-1/4 mt-0 mb-0';
    }

    return _modalPosition;
  };

  useEffect(() => {
    setTimeout(() => {
      setIsTransition(isOpen);
    }, 100);
  }, [isOpen]);
  return (
    <div
      className={`relative transform transition-all ease-in-out duration-200 
      ${getModalPosition(position)}
      ${size}
      ${isTransition ? 'translate-y-0 opacity-100 scale-100' : getTransitionPosition(position)}`}>
      {children}
    </div>
  );
}

TransitionModal.propTypes = {
  isOpen: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  size: PropTypes.string
};

export default TransitionModal;
