import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import styles from "./css/input-select.module.css";
import { useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'hooks/useOnClickOutside'
import clsx from 'clsx';

function InputSelect({
  value,
  label,
  name,
  options,
  placeholder = 'Klik untuk memilih',
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  className = 'mb-5',
  disabled,
  error,
  size = 'medium',
  showError = true,
  iconRight,
  iconLeft,
  isAutoFocus = false
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const [tempOptions, setTempOptions] = useState(options);

  const [keyword, setKeyword] = useState("");

  const ref = useRef(null);
  const refInput = useRef(null);
  const refWrapper = useRef(null);

  useOnClickOutside(refWrapper, () => {
    setShowOptions(false);
    if (value) {
      const findName = options?.find(item => String(item?.id) == String(value))?.name || "";
      setKeyword(findName);
    }
  });

  const handleFocus = (e) => {
    setShowOptions(true);
    refInput?.current?.setSelectionRange(0, 9999999);
    onFocus(e);
  }

  const handleSelect = (selectedVal) => {
    const input = ref.current;
    Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(input, selectedVal);
    input.dispatchEvent(new Event('change', { bubbles: true }));
    setShowOptions(false);

    const findName = options?.find(item => String(item?.id) == String(selectedVal))?.name || "";
    setKeyword(findName);
  }

  useEffect(() => {
    if (value != tempValue) {
      setTempValue(value);
    }

    const findName = options?.find(item => String(item?.id) == String(value))?.name || "";
    setKeyword(findName);
  }, [value, tempValue, options]);

  useEffect(() => {
    setTempOptions(options);
  }, [options]);

  useEffect(() => {
    if (isAutoFocus) {
      setTimeout(() => ref?.current?.focus(), 500);
      setShowOptions(true);
    }
  }, [isAutoFocus]);

  return (
    <div ref={refWrapper} className={`${className} ${styles[size]}`}>
      <RenderIf isTrue={label}>
        <label htmlFor={name} className={`${styles['input-select-label']}`}>
          {label}
        </label>
      </RenderIf>

      <div className={`custom-input-select--wrapper ${styles['input-select-wrapper']} dark:bg-black-base-d dark:border-black-light-1-l ${error && styles['input-select-error']} overflow-visible`}>
        {
          iconLeft &&
          <span className={styles['input-select-icon--left']}>
            { iconLeft }
          </span>
        }

        <input
          ref={ref}
          className={styles['input-select-field']}
          name={name}
          value={tempValue}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          readOnly
        />

        <input
          ref={refInput}
          className={`absolute pr-10 ${iconLeft ? 'pl-10' : 'pl-3'} py-3 line-clamp-1 block text-black-base-l rounded-xl dark:text-black-light-1-d top-0 w-full bg-transparent z-10 appearance-none outline-none keyword ${styles['input-select-field--keyword']}`}
          value={keyword}
          onChange={(e) => {
            let _val = e.target.value;
            setKeyword(_val);
            const newOptions = options?.filter((item) => item?.name.toLowerCase().indexOf(_val.toLowerCase()) !== -1) || [];
            setTempOptions(newOptions);
          }}
          onFocus={handleFocus}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={!showOptions ? true : false}
        />

        <span className={clsx(styles['input-select-icon--right'], 'dark:border-black-light-1-l')}>
          { iconRight || <IconChevronSingle className={`${styles['input-select-icon']} ${showOptions ? 'rotate-90' : '-rotate-90'}`} />}
        </span>

        <div className={`absolute top-full z-20 w-full left-0 overflow-hidden pt-2 rounded-b-lg ${showOptions && tempOptions?.length > 0 && 'shadow-pro'}`}>
          <ul className={`overflow-auto max-h-0 ${showOptions && tempOptions?.length > 0 && 'max-h-[250px]'}`}>
            {tempOptions?.map((item, key) => (
              <li className='group' role='button' title={item?.name} onClick={() => handleSelect(item?.id)} key={key}>
                <button onBlur={() => {
                  if (key === tempOptions?.length - 1) setShowOptions(false);
                }} type='button' className={`text-xs text-left appearance-none py-3 mb-0 bg-white dark:bg-black-dark-d dark:text-black-light-1-d dark:hover:text-main-base-l px-4 w-full overflow-hidden transition-all ease-linear duration-100 hover:bg-gray-light-l dark:hover:bg-gray-base-d hover:text-main-base-l cursor-pointer ${value === item?.id ? 'text-main-base-l bg-gray-light-l' : 'text-black-base-l'}`}>{item?.customName ? item?.customName : item?.name}</button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <RenderIf isTrue={error && showError}>
        <p className="text-xs text-red-base-l font-quicksand-regular mt-1">
          {error}
        </p>
      </RenderIf>
    </div>
  );
}

InputSelect.propTypes = {
  value: PropTypes.any,
  iconRight: PropTypes.any,
  iconLeft: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  showError: PropTypes.bool,
};

export default InputSelect;
