import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import { Link } from 'react-router-dom';
import { useGetStockThematicsQuery } from 'services/rtk-query/stock';
import LoaderStockGroup from './loader/loader-stock-group';
import IconTriangle from 'components/svg/icon-triangle';
import { triggerSegmentEvent } from 'lib/segment';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function SectionStockThematicList() {
  const isBrowserWakeUp = useBrowserWakeUp();
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { data: thematics, isLoading, refetch } = useGetStockThematicsQuery({ type: 'thematic', keyword: '' }, { pollingInterval: isBursaOpen ? 60000 : 3600000 });

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  if (isLoading) {
    return (
      <Card className="py-4 px-4">
        <div className="mt-3 grid gap-3 grid-cols-1">
          <LoaderStockGroup />
          <LoaderStockGroup />
          <LoaderStockGroup />
          <LoaderStockGroup />
          <LoaderStockGroup />
          <LoaderStockGroup />
          <LoaderStockGroup />
        </div>
      </Card>
    );
  }

  return (
    <Card className="py-4 pb-0">
      <div className="flex justify-between items-center px-4">
        <h3 className="flex-none font-quicksand-semibold text-sm dark:text-black-light-1-d">Saham Tematik</h3>
      </div>

      <RenderIf isTrue={!isLoading}>
        <ul className="px-4 -mx-4">
          {thematics?.map((item, key) => (
            <Link className='group' key={key} to={`/stock-market/thematic/${item?._id}`} onClick={() => triggerSegmentEvent({ event: 'TR Thematic Stock Clicked' })}>
              <li data-qa-id="linkThematic" className="border-b dark:border-gray-base-d group-last:border-b-0 py-3 px-4 hover:bg-gray-light-l dark:hover:bg-black-dark-d transition-all ease-linear duration-200">
                <h5 className="text-xs font-quicksand-semibold line-clamp-2 dark:text-black-light-1-d">
                  {item?.name}
                </h5>

                <p className="text-xs text-gray-light-0-d dark:text-black-light-0-d line-clamp-2 mb-1">
                  {item?.description}
                </p>

                <div className="flex items-center text-2xs">
                  <div className="flex items-center mr-2">
                    <IconTriangle className="h-2 w-2 text-green-base-l flex-none mr-1" />
                    <span className='dark:text-black-light-0-d'>{item?.score?.up}</span>
                  </div>
                  <div className="flex items-center mr-2">
                    <IconTriangle className="h-2 w-2 text-red-base-l flex-none mr-1 transform rotate-180" />
                    <span className='dark:text-black-light-0-d'>{item?.score?.down}</span>
                  </div>
                </div>
              </li>
            </Link>
          ))}
        </ul>

        <RenderIf isTrue={thematics?.length === 0}>
          <p className="text-xs text-gray-light-0-d px-4">Belum ada data.</p>
        </RenderIf>
      </RenderIf>
    </Card>
  );
}

export default SectionStockThematicList;