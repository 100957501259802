import PropTypes from 'prop-types';
import InputText from 'components/input/input-text';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { find, findIndex, isEqual, maxBy, range, sumBy } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import PriceComponent from './price-component';
// import DoneTrade from './done-trade';
import { useRealtimeOrderbookFast } from 'hooks/useRealtimeOrderbookFast';
import InputFastOrder from './input-fast-order';
import {
  useGetOrderListQuery,
  useGetPortfolioListQuery
} from 'services/rtk-query/trade';
import { getOrderStatus } from 'utils/stock/order';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import { ORDER_COST } from 'constants';
import useToastr from 'hooks/useToastr';
import { automatedNumberFormat } from 'utils/format';
import { useDispatch, useSelector } from 'react-redux';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { setFastOrderSettingsBySymbol } from 'store/settings';
import { ModalContext } from 'components/modal/context/modalContext';
import RenderIf from 'components/basic/render-if';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { getStampCostBuy } from 'utils/calculation';
import IconChevronDouble from 'components/svg/icon-chevron-double';

function OrderbookTable({
  symbol,
  initPrice = 0,
  settings = { lots: [], activeLotIndex: 0 },
  onBuyAction = () => {},
  onSellAction = () => {},
  onDragEndOrderIndexAction = () => {},
  onEditAction = () => {},
  onWithdrawAction = () => {},
  onClickPrice = () => {},
  onWithdrawAllBuyAction = () => {},
  onWithdrawAllSellAction = () => {},
  orderlistBuy = [],
  orderlistSell = []
}) {
  const dispatch = useDispatch();
  const toastr = useToastr();
  const refBidLot = useRef(null);
  const refAskLot = useRef(null);
  const refScroll = useRef();
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const { modalLoading } = useContext(ModalContext);
  const [row, setRow] = useState(5);

  const [tempSymbol, setTempSymbol] = useState(null);
  const [filteredOrderlist, setFilteredOrderlist] = useState([]);
  const [buyListOrderOpen, setBuyListOrderOpen] = useState([]);
  const [sellListOrderOpen, setSellListOrderOpen] = useState([]);
  const [orderBookFast, setOrderBookFast] = useState({ symbol, list: [], totalAskLot: 0, totalBidLot: 0 });

  const { stockDetail } = useRealtimeStock(symbol, false);
  const { data: resOrderlist } = useGetOrderListQuery(null, { refetchOnMountOrArgChange: true });
  const { data: trading } = useGetBuyingPowerQuery(null, { refetchOnMountOrArgChange: true });
  const { data: portFolio } = useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const portfolio = find(portFolio?.stock?.items, { stID: symbol });

  const toggleVolumeType = () => {
    dispatch(
      setFastOrderSettingsBySymbol({
        symbol,
        settings: { ...settings, volumeType: settings?.volumeType === 1 ? 100 : 1 }
      })
    )
  }

  const summarizeLots = (lots) => {
    let sum = 0;
    lots?.forEach(arr => {
      sum += sumBy(arr, 'qty')
    });

    return sum / 100;
  }

  const handleScrollToMiddle = (positionPercent) => {
    setTimeout(() => {
      const state = refScroll?.current?.osInstance().state();
      const overflowAmount = state?.overflowAmount;
      const { viewport } = refScroll.current.osInstance().elements();
      viewport.scrollTo({ top: overflowAmount?.y * positionPercent });
    }, 500);
  };

  useEffect(() => {
    if (resOrderlist?.items?.length > 0) {
      setFilteredOrderlist((resOrderlist?.items || [])?.filter((item) => !['amend', 'match', 'reject', 'withdraw'].includes(getOrderStatus(item?.state))));
    }
  }, [resOrderlist]);

  useEffect(() => {
    let tempBuyListOrderOpen = [];
    orderBookFast?.list?.forEach((orderbook) => {
      let filterOrder = filteredOrderlist?.filter((orderOpen) => Number(orderOpen?.price) === Number(orderbook?.price) && orderOpen?.side === 'B');
      if (filterOrder?.length > 0) {
        tempBuyListOrderOpen.push(filterOrder);
      } else {
        tempBuyListOrderOpen.push(null);
      }
    });

    setBuyListOrderOpen(tempBuyListOrderOpen);
  }, [filteredOrderlist, orderBookFast]);

  useEffect(() => {
    let tempSellListOrderOpen = [];
    orderBookFast?.list?.forEach((orderbook) => {
      let filterOrder = filteredOrderlist?.filter((orderOpen) => Number(orderOpen?.price) === Number(orderbook?.price) && orderOpen?.side === 'S');
      if (filterOrder?.length > 0) {
        tempSellListOrderOpen.push(filterOrder);
      } else {
        tempSellListOrderOpen.push(null);
      }
    });

    setSellListOrderOpen(tempSellListOrderOpen);
  }, [filteredOrderlist, orderBookFast?.list]);

  useEffect(() => {
    if (orderBookFast?.list?.length > 0 && tempSymbol != symbol && orderBookFast?.symbol === symbol) {
      const findListIndex = findIndex(orderBookFast?.list, (item) => Number(item?.price) === Number(initPrice));
      handleScrollToMiddle(findListIndex / orderBookFast?.list?.length);
      setTempSymbol(symbol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBookFast, initPrice, tempSymbol, symbol]);

  return (
    <>
      <div className='flex justify-between border-r dark:border-gray-base-d border-dashed pt-2'>
        <OverlayScrollbarsComponent
          ref={refScroll}
          className="overlayscrollbars-host-small max-h-[350px] flex-auto relative"
          element="span"
          options={{
            resize: 'vertical',
            scrollbars: {
              autoHide: 'never'
            }
          }}>
          <div className="flex text-2xs text-gray-light-0-d gap-2 pr-4">
            <div className="flex-auto">
              <p className="py-2 text-center sticky top-0 card-widget-gradient z-10">Buy</p>
              <InputFastOrder
                type="buy"
                symbol={symbol}
                activeOrders={buyListOrderOpen}
                checkMaxValue={(price) => Math.floor((trading?.buyingpower - getStampCostBuy(trading?.buyingpower)) / (price * 100 + ORDER_COST?.buy * price * 100)) || 0}
                initPrice={initPrice}
                listOrderbook={orderBookFast?.list}
                settings={settings}
                onEditAction={onEditAction}
                onOrderAction={onBuyAction}
                onWithdrawAction={onWithdrawAction}
                onDragEndOrderIndexAction={onDragEndOrderIndexAction}
                onClickPrice={onClickPrice}
                onErrorAction={(msg) => toastr?.error(msg || 'Trading limit tidak mencukupi')}
              />
            </div>

            <div className="flex justify-center">
              <div className="w-[72px]">
                <button type='button' onClick={toggleVolumeType} className="py-2 text-center sticky top-0 card-widget-gradient z-10 flex justify-center items-center w-full outline-none hover:text-main-base-l">
                  Bid&nbsp;{settings.volumeType === 1 ? 'Lot' : 'Vol'}

                  <IconChevronSingle className={`h-3 w-3 text-main-base-l transform ml-1 ${settings.volumeType === 1 ? '-rotate-90' : 'rotate-90'}`}/>
                </button>

                <ListOrderBookBid
                  settings={settings}
                  row={row}
                  refTotal={refBidLot}
                  symbol={symbol}
                  initPrice={initPrice}
                  stockDetail={stockDetail}
                  onCompleteRender={(payload) => {
                    if (((orderBookFast?.list?.length != payload?.list?.length || orderBookFast?.symbol != payload?.symbol) && isBursaOpen) || (!isEqual(payload, orderBookFast) && !isBursaOpen)) {
                      setOrderBookFast({...orderBookFast, list: payload?.list, symbol: payload?.symbol });
                    }
                  }}
                />
              </div>

              <div className="px-2 w-[72px]">
                <p className="py-2 text-center sticky top-0 card-widget-gradient z-10">Price</p>
                <ListPriceOrderBook
                  symbol={symbol}
                  row={row}
                  onClickPrice={onClickPrice}
                  orderBookFast={orderBookFast}
                  initPrice={initPrice}
                />
              </div>

              <div className="w-[72px]">
                <button type='button' onClick={toggleVolumeType} className="py-2 text-center sticky top-0 card-widget-gradient z-10 flex justify-center items-center w-full outline-none hover:text-main-base-l">
                  Offer&nbsp;{settings.volumeType === 1 ? 'Lot' : 'Vol'}

                  <IconChevronSingle className={`h-3 w-3 text-main-base-l transform ml-1 ${settings.volumeType === 1 ? '-rotate-90' : 'rotate-90'}`}/>
                </button>

                <ListOrderBookAsk
                  settings={settings}
                  row={row}
                  refTotal={refAskLot}
                  symbol={symbol}
                  stockDetail={stockDetail}
                  initPrice={initPrice}
                />
              </div>
            </div>

            <div className="flex-auto">
              <p className="py-2 text-center sticky top-0 card-widget-gradient z-10">Sell</p>
              <InputFastOrder
                type="sell"
                symbol={symbol}
                activeOrders={sellListOrderOpen}
                checkMaxValue={(lot) => {
                  if (lot <= (portfolio?.net / 100 || 0)) return lot;
                  return portfolio?.net / 100 || 0;
                }}
                initPrice={initPrice}
                listOrderbook={orderBookFast?.list}
                settings={settings}
                onEditAction={onEditAction}
                onOrderAction={onSellAction}
                onWithdrawAction={onWithdrawAction}
                onDragEndOrderIndexAction={onDragEndOrderIndexAction}
                onClickPrice={onClickPrice}
                onErrorAction={(msg) => toastr?.error(msg || 'Lot tidak mencukupi')}
              />
            </div>
          </div>

          <RenderIf isTrue={orderBookFast?.list?.length > 5}>
            <button type='button' onClick={() => setRow(row === 5 ? 100 : 5 )} className='sticky bg-white z-10 dark:bg-black-base-d left-0 bottom-0 text-main-base-l text-2xs text-center py-[3px] flex items-center justify-center appearance-none w-full group'>
              <span className='flex items-center justify-center group-hover:opacity-75'>
                <IconChevronDouble className={`h-3 w-3 mr-1 transform ${row === 5 ? '-rotate-90' : 'rotate-90'}`}/>
                {row === 5 ? 'Expand Row' : 'Collapse Row'}
              </span>
            </button>
          </RenderIf>
        </OverlayScrollbarsComponent>

        {/* <div className="flex-none min-w-[64px] lg:sticky lg:top-0 lg:right-0 text-2xs">
          <p className="pb-2 text-center lg:sticky lg:top-0 card-widget-gradient z-10 text-gray-light-0-d">Done</p>

          <OverlayScrollbarsComponent
            className="overlayscrollbars-host-small max-h-[306px]"
            element="span"
            options={{
              resize: 'vertical',
              scrollbars: {
                autoHide: 'leave'
              }
            }}>

            <DoneTrade symbol={symbol} />
          </OverlayScrollbarsComponent>
        </div> */}
      </div>

      <div className='sticky bottom-0 z-10'>
        <div className="flex text-2xs text-gray-light-0-d gap-2 py-1 border-t dark:border-gray-base-d border-dashed card-widget-gradient pr-4">
          <div className="flex-auto">
            <InputText
              autoSelect={false}
              readOnly
              value={summarizeLots(buyListOrderOpen?.filter(item => item?.length > 0), 'qty')}
              name={`totalBuy`}
              className={`customize-input-text--fast-order read-only text-black-base-l !cursor-default py-[1px]`}
            />
          </div>

          <div className="flex justify-center">
            <div className="w-[72px] mt-[2px]">
              <span ref={refBidLot} className='font-quicksand-semibold text-red-base-l py-[4px] px-2 block text-center'>0</span>
            </div>

            <div className="px-2 w-[72px]">
              <span className="text-gray-light-0-d font-quicksand-semibold block text-center mt-[7px]">
                SUM
              </span>
            </div>

            <div className="w-[72px] mt-[2px]">
              <span ref={refAskLot} className='font-quicksand-semibold text-green-base-l py-[4px] px-2 block text-center'>0</span>
            </div>
          </div>

          <div className="flex-auto">
            <InputText
              autoSelect={false}
              readOnly
              value={summarizeLots(sellListOrderOpen?.filter(item => item?.length > 0), 'qty')}
              name={`totalSell`}
              className={`customize-input-text--fast-order read-only text-black-base-l !cursor-default py-[1px]`}
            />
          </div>

          {/* <div className="flex-none min-w-[64px]"></div> */}
        </div>

        <div className="flex lg:hidden text-2xs text-gray-light-0-d gap-2 py-1 border-t dark:border-gray-base-d border-dashed card-widget-gradient">
          <div className="flex-auto">
            <button
              type="button"
              title='Withdraw semua order Beli'
              disabled={orderlistBuy?.length === 0 || modalLoading}
              onClick={onWithdrawAllBuyAction}
              className="p-2 py-1 bg-main-base-l rounded-md outline-none text-white dark:text-black-base-d font-quicksand-medium text-xs w-full transition-all ease-linear duration-100 hover:bg-opacity-75 disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d disabled:bg-opacity-100">
              W
            </button>
          </div>

          <div className="flex justify-center">
            <div className="w-[72px] mt-[2px]"></div>
            <div className="px-2 w-[72px]"></div>
            <div className="w-[72px] mt-[2px]"></div>
          </div>

          <div className="flex-auto">
            <button
              type="button"
              title='Withdraw semua order Jual'
              disabled={orderlistSell?.length === 0 || modalLoading}
              onClick={onWithdrawAllSellAction}
              className="p-2 py-1 bg-main-base-l rounded-md outline-none text-white dark:text-black-base-d font-quicksand-medium text-xs w-full transition-all ease-linear duration-100 hover:bg-opacity-75 disabled:bg-[#D9DEE2] dark:disabled:bg-gray-base-d disabled:text-black-light-0-d disabled:bg-opacity-100">
              W
            </button>
          </div>

          <div className="flex-none min-w-[64px]"></div>
        </div>
      </div>
    </>
  );
}

export default OrderbookTable;

OrderbookTable.propTypes = {
  symbol: PropTypes.string,
  initPrice: PropTypes.number,
  settings: PropTypes.object,
  onBuyAction: PropTypes.func,
  onSellAction: PropTypes.func,
  onDragEndOrderIndexAction: PropTypes.func,
  onEditAction: PropTypes.func,
  onWithdrawAction: PropTypes.func,
  onCompleteRender: PropTypes.func,
  onClickPrice: PropTypes.func,
  onWithdrawAllBuyAction: PropTypes.func,
  onWithdrawAllSellAction: PropTypes.func,
  orderlistBuy: PropTypes.array,
  orderlistSell: PropTypes.array
};

function ListOrderBookBid({ symbol, onCompleteRender = () => {}, refTotal, settings, initPrice, row = 5 }) {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const [maxBid, setMaxBid] = useState(0);
  const [orderBooks, setOrderBooks] = useState(null);

  const { stockDetail, isLoadingStock } = useRealtimeStock(symbol);
  const isEmptyBidPrice = !stockDetail?.bidPrice;
  const { orderBooks: tempOrderBooks, loadingOrderBooks } = useRealtimeOrderbookFast(symbol, Number(isBursaOpen ? stockDetail?.bidPrice : stockDetail?.lastTradedPrice));
  const findIndexLastTradedPrice = tempOrderBooks?.list?.findIndex(item => item?.price === tempOrderBooks?.firstBidPrice);
  const isLoading  = isLoadingStock || loadingOrderBooks;

  useEffect(() => {
    if (orderBooks?.list?.length > 0) {
      setMaxBid(maxBy(orderBooks?.list?.filter((item) => item?.price <= Number(orderBooks?.firstBidPrice || stockDetail?.lastTradedPrice)), 'lot'));
      if (refTotal?.current) {   
        refTotal.current.innerHTML = automatedNumberFormat(orderBooks?.totalBidLot * settings?.volumeType);
      }
    }
  }, [orderBooks, stockDetail, refTotal, settings]);

  useEffect(() => {
    if (orderBooks?.list?.length > 0 && orderBooks?.symbol === symbol) onCompleteRender(orderBooks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBooks, symbol, findIndexLastTradedPrice]);

  useEffect(() => {
    setOrderBooks({...tempOrderBooks, list: tempOrderBooks?.list?.filter((item, key) => [...range(findIndexLastTradedPrice - row, findIndexLastTradedPrice), ...range(findIndexLastTradedPrice, findIndexLastTradedPrice + row + 1) ].includes(key + 1) )});
  }, [tempOrderBooks, row, findIndexLastTradedPrice]);

  return (
    <ul>
      <RenderIf isTrue={orderBooks?.list?.length === 0 || isLoading}>
        {Array(20)
        .fill(1)
        .map((el, key) => (
          <li key={key} className="my-1">
            <SkeletonTheme containerClassName="leading-none" width="100%" height="1.2rem" borderRadius="4px" />
          </li>
        ))}  
      </RenderIf>

      <RenderIf isTrue={!isLoading}>
        {orderBooks?.list?.map((item, key) => (
          <li key={key} className={`${Number(initPrice) === Number(item?.price) && 'bg-gray-light-l dark:bg-black-dark-d rounded-md'}`}>
            <PriceComponent
              disabled
              value={isEmptyBidPrice ? 0 : (item?.price <= Number(orderBooks?.firstBidPrice || stockDetail?.lastTradedPrice) ? item?.lot * settings?.volumeType : 0)}
              className={item?.lot === 0 ? 'text-gray-light-2-d dark:text-gray-base-d' : 'text-black-base-l dark:text-black-light-1-d'}
              maxLot={maxBid?.lot * settings?.volumeType}
              position="RIGHT"
              barColor="bg-red-base-l"
            />
          </li>
        ))}
      </RenderIf>
    </ul>
  );
}

ListOrderBookBid.propTypes = {
  symbol: PropTypes.string,
  onCompleteRender: PropTypes.func,
  refTotal: PropTypes.any,
  settings: PropTypes.object,
  stockDetail: PropTypes.object,
  initPrice: PropTypes.number,
  row: PropTypes.number,
};

function ListOrderBookAsk({ symbol, onCompleteRender = () => {}, refTotal, settings, initPrice, row = 5 }) {
  const { isBursaOpen } = useSelector(({ pinaPro }) => pinaPro);
  const [maxAsk, setMaxAsk] = useState(0);
  const [orderBooks, setOrderBooks] = useState(null);
  
  const { stockDetail, isLoadingStock } = useRealtimeStock(symbol);
  const isEmptyBidPrice = !stockDetail?.bidPrice;
  const { orderBooks: tempOrderBooks, loadingOrderBooks } = useRealtimeOrderbookFast(symbol, Number(isBursaOpen ? stockDetail?.bidPrice : stockDetail?.lastTradedPrice));
  const findIndexLastTradedPrice = tempOrderBooks?.list?.findIndex(item => item?.price === tempOrderBooks?.firstBidPrice);
  const isLoading = isLoadingStock || loadingOrderBooks;

  useEffect(() => {
    if (orderBooks?.list?.length > 0) {
      if (isEmptyBidPrice) {
        setMaxAsk(maxBy(orderBooks?.list?.filter((item) => item?.price >= Number(orderBooks?.firstBidPrice || stockDetail?.lastTradedPrice)), 'lot'));
      } else {
        setMaxAsk(maxBy(orderBooks?.list?.filter((item) => item?.price > Number(orderBooks?.firstBidPrice || stockDetail?.lastTradedPrice)), 'lot'));
      }
      if (refTotal?.current) {   
        refTotal.current.innerHTML = automatedNumberFormat(orderBooks?.totalAskLot * settings?.volumeType);
      }
    }
  }, [orderBooks, stockDetail, refTotal, settings, isEmptyBidPrice]);

  useEffect(() => {
    if (orderBooks?.list?.length > 0 && orderBooks?.symbol === symbol) onCompleteRender(orderBooks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBooks, symbol, findIndexLastTradedPrice]);

  useEffect(() => {
    setOrderBooks({...tempOrderBooks, list: tempOrderBooks?.list?.filter((item, key) => [...range(findIndexLastTradedPrice - row, findIndexLastTradedPrice), ...range(findIndexLastTradedPrice, findIndexLastTradedPrice + row + 1) ].includes(key + 1) )});
  }, [tempOrderBooks, row, findIndexLastTradedPrice]);

  return (
    <ul>
      <RenderIf isTrue={orderBooks?.list?.length === 0 || isLoading}>
        {Array(20)
        .fill(1)
        .map((el, key) => (
          <li key={key} className="my-1">
            <SkeletonTheme containerClassName="leading-none" width="100%" height="1.2rem" borderRadius="4px" />
          </li>
        ))}  
      </RenderIf>

      <RenderIf isTrue={!isLoading}>
        {orderBooks?.list?.map((item, key) => (
          <li key={key} className={`${Number(initPrice) === Number(item?.price) && 'bg-gray-light-l dark:bg-black-dark-d rounded-md'}`}>
            <PriceComponent
              disabled
              value={isEmptyBidPrice ? (item?.price >= Number(orderBooks?.firstBidPrice || stockDetail?.lastTradedPrice) ? item?.lot * settings?.volumeType : 0) : (item?.price > Number(orderBooks?.firstBidPrice || stockDetail?.lastTradedPrice) ? item?.lot * settings?.volumeType : 0)}
              className={item?.lot === 0 ? 'text-gray-light-2-d dark:text-gray-base-d' : 'text-black-base-l dark:text-black-light-1-d'}
              maxLot={maxAsk?.lot * settings?.volumeType}
              position="LEFT"
              barColor="bg-green-base-l"
            />
          </li>
        ))}
      </RenderIf>
    </ul>
  );
}

ListOrderBookAsk.propTypes = {
  symbol: PropTypes.string,
  onCompleteRender: PropTypes.func,
  refTotal: PropTypes.any,
  settings: PropTypes.object,
  stockDetail: PropTypes.object,
  initPrice: PropTypes.number,
  row: PropTypes.number,
};

function ListPriceOrderBook ({ symbol, orderBookFast, initPrice, onClickPrice = () => {} }) {
  const { stockDetail } = useRealtimeStock(symbol, false);

  return (
    <ul>
      <RenderIf isTrue={orderBookFast?.list?.length === 0}>
        {Array(20)
        .fill(1)
        .map((el, key) => (
          <div key={key} className="my-1">
            <SkeletonTheme containerClassName="leading-none" width="100%" height="1.2rem" borderRadius="4px" />
          </div>
        ))}  
      </RenderIf>

      {orderBookFast?.list?.map((item, key) => (
        <li key={key} className={`rounded-lg ${Number(initPrice) === Number(item?.price) && 'bg-gray-light-l dark:bg-black-dark-d'}`}>
          <PriceComponent
            active={initPrice === item?.price}
            value={item?.price}
            className={`${
              Number(stockDetail?.previousLastTradedPrice) ===
              item?.price
                ? 'text-black-base-l dark:text-black-light-1-d'
                : Number(stockDetail?.previousLastTradedPrice) <
                  item?.price
                ? 'text-green-base-l'
                : 'text-red-base-l'
            }`}
            onClick={() => onClickPrice(item?.price)}
          />
        </li>
      ))}
    </ul>
  )
}

ListPriceOrderBook.propTypes = {
  symbol: PropTypes.string,
  orderBookFast: PropTypes.object,
  initPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClickPrice: PropTypes.func,
};