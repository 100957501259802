import PropTypes from 'prop-types';
import RenderIf from 'components/basic/render-if';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { useEffect, useState } from 'react';
import { currency } from 'utils/format';
import TVFilter from './_partials/tv-filter';
import TVLegend from './_partials/tv-legend';
import TVRealtimeChartIDX from './_partials/tv-realtime-chart-idx';

function TVCompositehartContainer({ symbol = 'COMPOSITE', isRerender = true }) {
  const [filter, setFilter] = useState('1D');

  return (
    <div className="px-3 h-full">
      <div className="grid grid-cols-2 mb-4">
        <div>
          <div className="flex items-center">
            <h5 className="text-2xl font-quicksand-semibold dark:text-black-light-1-d">
              IHSG
            </h5>
            <div className="h-4 w-6 rounded-sm border border-gray-base-l overflow-hidden mx-2">
              <span
                className="w-full h-2 block"
                style={{ background: '#FF0000' }}></span>
              <span className="w-full h-2 bg-white block"></span>
            </div>
          </div>
          <p className="text-xs dark:text-black-light-1-d">IHSG, Indonesia</p>
        </div>

        <div>
          <PriceItem code={symbol} />
        </div>
      </div>

      <div className="-mx-3">
        <TVFilter
          size="small"
          isLoading={false}
          defaultFilter={filter}
          handleChartFilter={(fltr) => setFilter(fltr?.text)}
        />

        <TVLegend symbol={symbol} />
      </div>

      <div style={{ height: 'calc(100% - (200px))' }}>
        <TVRealtimeChartIDX
          isRerender={isRerender}
          filter={filter}
          symbol={symbol}
          interval="1"
          disabled_features={[
            'left_toolbar',
            'header_widget',
            'control_bar',
            'timeframes_toolbar',
            'legend_widget',
            'use_localstorage_for_settings'
          ]}
        />
      </div>

      <StockSummary symbol={symbol} />
    </div>
  );
}

TVCompositehartContainer.propTypes = {
  symbol: PropTypes.string,
  isRerender: PropTypes.bool
};

function StockSummary({ symbol }) {
  const { stockDetail } = useRealtimeStock(symbol);

  return (
    <div className="py-4">
      <div className="text-xs grid grid-cols-2 font-quicksand-semibold border dark:border-gray-base-d rounded-xl p-3">
        <div className="flex justify-between pr-2">
          <span className="dark:text-black-light-0-d">Open</span>
          <span className="dark:text-black-light-1-d">
            {currency(stockDetail?.openPrice || 0, { removeSymbol: true })}
          </span>
        </div>
        <div className="flex justify-between pl-2">
          <span className="dark:text-black-light-0-d">Close</span>
          <span className="dark:text-black-light-1-d">
            {currency(stockDetail?.closePrice || 0, { removeSymbol: true })}
          </span>
        </div>

        <div className="flex justify-between pr-2">
          <span className="dark:text-black-light-0-d">High</span>
          <span className="text-green-base-l">
            {currency(stockDetail?.highTradedPrice || 0, {
              removeSymbol: true
            })}
          </span>
        </div>
        <div className="flex justify-between pl-2">
          <span className="dark:text-black-light-0-d">Low</span>
          <span className="text-red-base-l">
            {currency(stockDetail?.lowTradedPrice || 0, {
              removeSymbol: true
            })}
          </span>
        </div>
      </div>
    </div>
  );
}

function PriceItem({ code }) {
  const { stockDetail } = useRealtimeStock(code);
  const [tempStockDetail, setTempStockDetail] = useState(null);
  const [toggleActive, setToggleActive] = useState(false);

  useEffect(() => {
    if (
      Number(tempStockDetail?.lastTradedPrice) !=
      Number(stockDetail?.lastTradedPrice)
    ) {
      setTempStockDetail(stockDetail);
      setToggleActive(tempStockDetail);
    }
  }, [stockDetail, tempStockDetail]);

  return (
    <div
      className={`col-span-5 text-right ${toggleActive && 'animate-blink'}`}
      onAnimationEnd={() => {
        setToggleActive(false);
      }}>
      <h5
        className={`text-2xl font-quicksand-regular ${
          stockDetail?.score?.type === 'plus'
            ? 'text-green-base-l'
            : stockDetail?.score?.type === 'minus'
            ? 'text-red-base-l'
            : 'text-gray-light-0-d'
        }`}>
        {currency(stockDetail?.lastTradedPrice || 0, { removeSymbol: true })}
      </h5>

      <RenderIf isTrue={stockDetail?.score?.type === 'plus'}>
        <p className="text-xs text-green-base-l">
          +{currency(stockDetail?.score?.diff, { removeSymbol: true })} (
          {parseFloat(stockDetail?.score?.value || 0).toFixed(2)}%)
        </p>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.score?.type === 'minus'}>
        <p className="text-xs text-red-base-l">
          {currency(stockDetail?.score?.diff, { removeSymbol: true })} (
          {parseFloat(stockDetail?.score?.value || 0).toFixed(2)}%)
        </p>
      </RenderIf>

      <RenderIf isTrue={stockDetail?.score?.type === 'neutral'}>
        <p className="text-xs text-gray-light-0-d">0 (0,00%)</p>
      </RenderIf>
    </div>
  );
}

PriceItem.propTypes = {
  code: PropTypes.string
};

export default TVCompositehartContainer;
