import clsx from "clsx";
import { currency } from "utils/format";

export default function ReturnTextValue({
  sellValue,
  buyValue,
  suffix,
  prefix,
  reverseColor
}) {
  const value = sellValue - buyValue;
  let className = clsx(
    value > 0 && 'text-green-base-l',
    value < 0 && 'text-red-base-l'
  );

  if (reverseColor) {
    className = clsx(
      value > 0 && 'text-red-base-l',
      value < 0 && 'text-green-base-l'
    );
  }

  return (
    <span className={className}>
      {prefix}
      {currency(Math.abs(value), { removeSymbol: true })}
      {suffix}
    </span>
  );
}
