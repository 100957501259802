import PropTypes from 'prop-types';

export const LAYOUT_ONBOARDING = 'onboarding';

function OnboardingLayout({ children }) {
  return (
    <>
      <main className="min-h-screen lg:min-h-[auto] bg-white lg:bg-transparent">{children}</main>
    </>
  );
}

OnboardingLayout.propTypes = {
  children: PropTypes.element
};

export default OnboardingLayout;
