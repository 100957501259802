// eslint-disable-next-line react/prop-types
function IconImage({ className }) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4.77199 23.0842C4.67495 22.6617 4.61553 22.2176 4.57696 21.7455C4.53744 21.2617 4.51818 20.7197 4.50879 20.1117L7.63298 18.1799L7.73784 18.1149C8.17743 17.8419 8.61955 17.5674 9.11765 17.4393C9.55243 17.3274 10.0053 17.3046 10.4492 17.3721C10.9576 17.4494 11.4252 17.678 11.89 17.9053L12.0009 17.9594L12.7053 18.3023C13.4112 18.6458 13.4954 18.6686 13.5516 18.676C13.6465 18.6886 13.7431 18.6805 13.8346 18.6523C13.8888 18.6357 13.968 18.5991 14.6069 18.143L17.9477 15.7576L18.0719 15.6687C18.5842 15.3012 19.0984 14.9323 19.6917 14.774C20.2084 14.6361 20.7507 14.6246 21.2729 14.7404C21.8724 14.8733 22.4017 15.22 22.9292 15.5655L23.057 15.649L28.4988 19.1952C28.4953 20.1891 28.4812 21.0309 28.4228 21.7455C28.4076 21.9318 28.3891 22.1138 28.3663 22.2918L21.6011 17.8832C20.8509 17.3943 20.7567 17.3574 20.6954 17.3438C20.591 17.3206 20.4825 17.3229 20.3792 17.3505C20.3186 17.3667 20.226 17.4076 19.4973 17.9279L16.1564 20.3133L16.0481 20.3908C15.5965 20.7146 15.1425 21.0401 14.6179 21.2014C14.1604 21.342 13.6778 21.3824 13.2032 21.3199C12.6591 21.2482 12.1573 21.0027 11.6581 20.7585L11.5384 20.7001L10.834 20.3572C10.1785 20.0382 10.1011 20.0165 10.0483 20.0084C9.95952 19.9949 9.86894 19.9995 9.78198 20.0219C9.73022 20.0352 9.65544 20.0646 9.03542 20.448L4.77199 23.0842Z" />
      <path d="M15.1665 12.0007C15.1665 13.4734 13.9726 14.6673 12.4999 14.6673C11.0271 14.6673 9.83321 13.4734 9.83321 12.0007C9.83321 10.5279 11.0271 9.33398 12.4999 9.33398C13.9726 9.33398 15.1665 10.5279 15.1665 12.0007Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3096 4H18.6904C20.1355 3.99999 21.301 3.99998 22.2448 4.07709C23.2166 4.15649 24.0702 4.32424 24.8599 4.72662C26.1144 5.36578 27.1342 6.38565 27.7734 7.64006C28.1758 8.42979 28.3435 9.28337 28.4229 10.2552C28.5 11.199 28.5 12.3645 28.5 13.8096V18.1904C28.5 19.6355 28.5 20.801 28.4229 21.7448C28.3435 22.7166 28.1758 23.5702 27.7734 24.3599C27.1342 25.6144 26.1144 26.6342 24.8599 27.2734C24.0702 27.6758 23.2166 27.8435 22.2448 27.9229C21.301 28 20.1355 28 18.6904 28H14.3096C12.8645 28 11.699 28 10.7552 27.9229C9.78337 27.8435 8.92979 27.6758 8.14006 27.2734C6.88565 26.6342 5.86578 25.6144 5.22662 24.3599C4.82424 23.5702 4.65649 22.7166 4.57709 21.7448C4.49998 20.801 4.49999 19.6355 4.5 18.1904V13.8096C4.49999 12.3645 4.49998 11.199 4.57709 10.2552C4.65649 9.28337 4.82424 8.42979 5.22662 7.64006C5.86578 6.38565 6.88565 5.36578 8.14006 4.72662C8.92979 4.32424 9.78337 4.15649 10.7552 4.07709C11.699 3.99998 12.8645 3.99999 14.3096 4ZM10.9723 6.7349C10.1654 6.80083 9.70185 6.92373 9.35071 7.10264C8.59806 7.48614 7.98614 8.09806 7.60264 8.85071C7.42373 9.20185 7.30083 9.66543 7.2349 10.4723C7.1677 11.2948 7.16667 12.3512 7.16667 13.8667V18.1333C7.16667 19.6488 7.1677 20.7052 7.2349 21.5277C7.30083 22.3346 7.42373 22.7982 7.60264 23.1493C7.98614 23.9019 8.59806 24.5139 9.35071 24.8974C9.70185 25.0763 10.1654 25.1992 10.9723 25.2651C11.7948 25.3323 12.8512 25.3333 14.3667 25.3333H18.6333C20.1488 25.3333 21.2052 25.3323 22.0277 25.2651C22.8346 25.1992 23.2982 25.0763 23.6493 24.8974C24.4019 24.5139 25.0139 23.9019 25.3974 23.1493C25.5763 22.7982 25.6992 22.3346 25.7651 21.5277C25.8323 20.7052 25.8333 19.6488 25.8333 18.1333V13.8667C25.8333 12.3512 25.8323 11.2948 25.7651 10.4723C25.6992 9.66543 25.5763 9.20185 25.3974 8.85071C25.0139 8.09806 24.4019 7.48614 23.6493 7.10264C23.2982 6.92373 22.8346 6.80083 22.0277 6.7349C21.2052 6.6677 20.1488 6.66667 18.6333 6.66667H14.3667C12.8512 6.66667 11.7948 6.6677 10.9723 6.7349Z"
      />
    </svg>
  );
}

export default IconImage;
