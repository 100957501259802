import { getYear } from 'date-fns';
import dayjs from 'dayjs';

/**
 * Date formatter
 * @see {@links https://day.js.org/docs/en/display/format}
 * 
 * @param {Date|string} date 
 * @param {string} format
 * @returns {string}
 */
export const formatter = (date, format, config = { locale: 'id' }) => {
  const fmt = format || "DD MMM YYYY";
  
  if (!date) return date
  if (config?.locale) return dayjs(date).locale(config.locale).format(fmt)

  return dayjs(date).format(fmt);
}

/**
 * Humanize datetime difference from start date to end date
 * @see {@links https://day.js.org/docs/en/plugin/relative-time}
 * 
 * @param {Date|string} startDate 
 * @param {Date|string} endDate 
 * @returns {string}
 */
export const humanizeDiff = (startDate, endDate) => {
  const rt = require('dayjs/plugin/relativeTime');
  dayjs.extend(rt);

  return dayjs(startDate).to(dayjs(endDate), true)
} 

export const dateFromNow = (startDate) => {
  const rt = require('dayjs/plugin/relativeTime');
  dayjs.extend(rt);

  return dayjs(startDate).fromNow();
} 

export const getQuarterOfYear = (date = new Date()) => {
  const qoy = require('dayjs/plugin/quarterOfYear');
  dayjs.extend(qoy);

  let _quarterlyOptions = [getYear(new Date()) + '03', getYear(new Date()) + '06', getYear(new Date()) + '09', getYear(new Date()) + '12'];
  return _quarterlyOptions[dayjs(date).quarter() - 1];
}

export const dayjsLocal = (date = new Date(), customFormat) => {
  const utc = require('dayjs/plugin/utc')
  const timezone = require('dayjs/plugin/timezone')
  const customParseFormat = require('dayjs/plugin/customParseFormat');
  
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  if (customFormat) {
    return dayjs(date, customFormat).tz('Asia/Jakarta');
  }

  return dayjs(date).tz('Asia/Jakarta');
}

export const dayjsUTCLocal = (date = new Date(), customFormat) => {
  const utc = require('dayjs/plugin/utc')
  const timezone = require('dayjs/plugin/timezone')
  const customParseFormat = require('dayjs/plugin/customParseFormat');
  
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  if (customFormat) {
    return dayjs.utc(date, customFormat).tz('Asia/Jakarta');
  }

  return dayjs.utc(date).tz('Asia/Jakarta');
}

export const dayjsUnixLocal = (date = dayjsLocal().valueOf()) => {
  const utc = require('dayjs/plugin/utc')
  const timezone = require('dayjs/plugin/timezone')
  const customParseFormat = require('dayjs/plugin/customParseFormat');
  
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return dayjs.unix(date).tz('Asia/Jakarta');
}

export default formatter;
