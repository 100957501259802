// eslint-disable-next-line react/prop-types
function IconNotes({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.00006H9.8C8.94342 4.00006 8.36113 4.00084 7.91104 4.03761C7.47262 4.07343 7.24842 4.13836 7.09202 4.21805C6.7157 4.4098 6.40973 4.71576 6.21799 5.09208C6.1383 5.24848 6.07337 5.47269 6.03755 5.9111C6.00078 6.36119 6 6.94348 6 7.80006V16.2001C6 17.0566 6.00078 17.6389 6.03755 18.089C6.07337 18.5274 6.1383 18.7516 6.21799 18.908C6.40973 19.2844 6.7157 19.5903 7.09202 19.7821C7.24842 19.8618 7.47262 19.9267 7.91104 19.9625C8.36113 19.9993 8.94342 20.0001 9.8 20.0001H12C12.5523 20.0001 13 20.4478 13 21.0001C13 21.5523 12.5523 22.0001 12 22.0001H9.75868C8.95372 22.0001 8.28936 22.0001 7.74818 21.9559C7.18608 21.9099 6.66937 21.8114 6.18404 21.5641C5.43139 21.1806 4.81947 20.5687 4.43598 19.816C4.18868 19.3307 4.09012 18.814 4.04419 18.2519C3.99998 17.7107 3.99999 17.0463 4 16.2414V7.75876C3.99999 6.9538 3.99998 6.28943 4.04419 5.74824C4.09012 5.18614 4.18868 4.66944 4.43598 4.1841C4.81947 3.43145 5.43139 2.81953 6.18404 2.43604C6.66937 2.18875 7.18608 2.09018 7.74818 2.04426C8.28937 2.00004 8.95373 2.00005 9.7587 2.00006L13.1137 2.00003C13.7487 1.99973 14.2284 1.9995 14.6911 2.11058C15.0993 2.20857 15.4895 2.37019 15.8474 2.5895C16.2531 2.83813 16.5922 3.17756 17.041 3.62678L18.3733 4.95909C18.8225 5.40788 19.1619 5.74699 19.4106 6.15271C19.6299 6.5106 19.7915 6.90078 19.8895 7.30892C20.0006 7.77161 20.0003 8.2514 20 8.88638L20 12.0001C20 12.5523 19.5523 13.0001 19 13.0001C18.4477 13.0001 18 12.5523 18 12.0001V9.00006H16C14.3431 9.00006 13 7.65692 13 6.00006V4.00006ZM17.56 7.00006C17.4398 6.85796 17.2479 6.66216 16.887 6.30128L15.6988 5.11306C15.3379 4.75218 15.1421 4.56026 15 4.44009V6.00006C15 6.55235 15.4477 7.00006 16 7.00006H17.56Z"
      />
      <path d="M15.9239 19.1824L15.0375 21.3084C14.8656 21.7206 15.2792 22.1342 15.6914 21.9624L17.8175 21.076C17.938 21.0258 18.0475 20.9525 18.1398 20.8601L21.7029 17.297C22.0934 16.9065 22.0934 16.2733 21.7029 15.8828L21.1171 15.297C20.7266 14.9065 20.0934 14.9065 19.7029 15.297L16.1398 18.8602C16.0475 18.9525 15.9741 19.0619 15.9239 19.1824Z" />
    </svg>
  );
}

export default IconNotes;
