/* eslint-disable no-unused-vars */
import historyProvider from './historyProvider';
import stream from './stream';

const supportedResolutions = [
  '1',
  '3',
  '5',
  '15',
  '30',
  '60',
  '120',
  '240',
  'D',
  '1D',
  '3D',
  '1W',
  '1M',
  '3M',
  '6M'
];
const config = {
  supported_resolutions: supportedResolutions
};

function getSymbols(keyword) {
  return window?.LIST_SYMBOL?.filter(
    (item) =>
      item?.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
      item?.symbol.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  );
}

export default function DataFeed(idWidget) {
  return {
    onReady: (cb) => {
      setTimeout(() => cb(config), 0);
    },
    searchSymbols: async (
      userInput,
      exchange,
      symbolType,
      onResultReadyCallback
    ) => {
      const symbols = getSymbols(userInput);
      onResultReadyCallback(symbols);
    },
    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback
    ) => {
      const symbolItem = window?.LIST_SYMBOL?.find(
        ({ symbol, full_name }) =>
          symbol === symbolName ||
          full_name === symbolName ||
          symbol === symbolName?.replace('IDX:', '')
      );

      window.countRender[`${symbolItem?.symbol}-${idWidget}`] = 0;

      var symbol_stub = {
        ticker: symbolItem?.symbol,
        name: symbolItem?.symbol,
        description: symbolItem?.description,
        type: symbolItem?.type,
        exchange: symbolItem?.exchange,
        session: '0859-1200,1330-1615',
        timezone: 'Asia/Jakarta',
        minmov: 1,
        pricescale: 2,
        has_intraday: true,
        intraday_multipliers: ['1', '60'],
        supported_resolution: supportedResolutions,
        volume_precision: 8,
        data_status: 'streaming'
      };

      setTimeout(function () {
        onSymbolResolvedCallback(symbol_stub);
      }, 0);

      // onResolveErrorCallback('Not feeling it today')
    },
    getBars: async (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback
    ) => {
      const { from, to, firstDataRequest, countBack } = periodParams;
      historyProvider(idWidget)
        .getBars(symbolInfo, resolution, {
          from,
          to,
          firstDataRequest,
          countBack
        })

        .then((bars) => {
          if (bars.length) {
            onHistoryCallback(bars, { noData: false });
          } else {
            onHistoryCallback(bars, { noData: true });
          }
        })
        .catch((err) => {
          console.log({ err });
          onErrorCallback(err);
        });
    },
    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ) => {
      stream(idWidget).subscribeBars(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
      );
    },
    unsubscribeBars: (subscriberUID) => {
      stream(idWidget).unsubscribeBars(subscriberUID);
    },
    calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
      return resolution < 60
        ? { resolutionBack: 'D', intervalBack: '1' }
        : undefined;
    },
    getMarks: (
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      resolution
    ) => {},
    getTimeScaleMarks: (
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      resolution
    ) => {},
    getServerTime: (cb) => {}
  };
}
