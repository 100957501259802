import PropTypes from 'prop-types';

function InputTextArea({
  value,
  name,
  label,
  placeholder,
  className,
  autoComplete,
  disabled,
  onChange,
  onBlur,
  ...props
}) {
  return (
    <div className={`card-widget-gradient ${className}`}>
      <label className="font-quicksand-semibold text-black-base-l mb-1 block">
        {label}
      </label>
      <div className="border flex items-center w-full rounded-md overflow-hidden text-sm font-quicksand-semibold px-3 dark:border-gray-base-d">
        <span className="grow">
          <textarea
            type="text"
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur(e)}
            className="w-full block rounded-md pr-3 py-3 outline-none card-widget-gradient dark:text-black-light-1-d"
            autoComplete={autoComplete ? 'on' : 'off'}
            disabled={disabled}
            {...props}
          />
        </span>
      </div>
    </div>
  );
}

InputTextArea.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  autoComplete: PropTypes.bool,
  disabled: PropTypes.bool
};

InputTextArea.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  autoComplete: true,
  disabled: false
};

export default InputTextArea;
