/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { SimpleOrderSchema } from './simple-order.form.validation';
import Card from 'components/basic/card';
import RenderIf from 'components/basic/render-if';
import { automatedNumberFormat, currency, generateOrderId } from 'utils/format';
import { useEffect, useMemo, useState } from 'react';
import IconClose from 'components/svg/icon-close';
import InputCounter from 'components/input/input-counter';
import { marginPlusShare } from 'utils/formula/margin';
import { useDispatch, useSelector } from 'react-redux';
import { find, isEmpty, uniqueId } from 'lodash';
import SimpleStockBuyConfirmation from './modal-confirmation-buy';
import TVHeader from 'components/chart/tradingview/_partials/tv-header';
import {
  useCreateOrderBuyMutation,
  useCreateOrderSellMutation,
  useGetPortfolioListQuery
} from 'services/rtk-query/trade';
import { useGetBuyingPowerQuery } from 'services/rtk-query/trading';
import SimpleStockSellConfirmation from './modal-confirmation-sell';
import { triggerSegmentEvent } from 'lib/segment';
import IconWarning from 'components/svg/icon-warning';
import { useRealtimeStock } from 'hooks/useRealtimeStock';
import { invalidateUserNotification } from 'services/rtk-query/user';
import TWOrderBook from 'pages/trading/_partials/tw-orderbook';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import ModalExploreExpired from 'components/modal/template/modal-explore-expired';
import InputCheckBox from 'components/input/input-check-box';
import useToastr from 'hooks/useToastr';
import SimpleSpinner from 'components/common/spinner/spinner';
import { setQuickOrderSettingsConfirmation } from 'store/settings';
import IconSort from 'components/svg/icon-sort';
import Modal from 'components/modal/modal';
import Tabs from 'components/tabs/tabs';
import clsx from 'clsx';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';

function SimpleBuySellForm({
  onClose = () => {},
  onSuccess = () => {},
  onChangeSymbol = () => {},
  initPrice = 0,
  symbol,
  defaultOrderType = 'SimpleBuy',
  buyAnalyticEventName = 'TR Buy Stock from Simple Buy',
  sellAnalyticEventName = 'TR Sell Stock from Simple Sell'
}) {
  const toastr = useToastr();
  const { getErrorMessage } = useGetErrorMessage();
  const dispatch = useDispatch();
  const { user, dealerUseClientId } = useBrowserTabAuth();
  const quickOrderConfirmation = useSelector(
    (state) => state?.settings?.quickOrderSettings?.confirmation
  );
  const { data: trading } = useGetBuyingPowerQuery(null, {
    refetchOnMountOrArgChange: true
  });
  const { data: portFolio, isLoading: isLoadingPortfolio } =
    useGetPortfolioListQuery(null, { refetchOnMountOrArgChange: true });
  const portfolio = find(portFolio?.stock?.items, { stID: symbol });

  const { stockDetail, isLoadingStock } = useRealtimeStock(symbol, false);

  const [isNotRealAccount, setIsNotRealAccount] = useState(false);
  const [createOrderBuy] = useCreateOrderBuyMutation();
  const [createOrderSell] = useCreateOrderSellMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenModalConfirmation, setOpenModalConfirmation] = useState(false);

  const clearRGStockID = useMemo(() => {
    let zymbol = stockDetail?.id;
    let isTN = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'TN';
    let isNG = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'NG';

    if (isTN || isNG) {
      zymbol = zymbol?.slice(0, -2)
    }

    return zymbol;
  }, [stockDetail]);

  const clearBoardID = useMemo(() => {
    let boardID = 'RG';
    let zymbol = stockDetail?.id;
    
    let isTN = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'TN';
    let isNG = zymbol?.replaceAll(zymbol?.slice(0, -2), '') === 'NG';

    if (isTN) boardID = 'TN';
    if (isNG) boardID = 'NG';

    return boardID;
  }, [stockDetail]);

  const formik = useFormik({
    initialValues: {
      price: initPrice ? initPrice : stockDetail?.lastTradedPrice,
      amount: 0,
      lot: 0,
      lotType: true,
      portfolio: (portfolio?.net || 0) / 100,
      orderType: defaultOrderType || 'SimpleBuy',
      orderNumber: generateOrderId(
        dealerUseClientId || user?.username,
        defaultOrderType === 'SimpleBuy' ? 'B' : 'S'
      ),
      buyingPower: trading?.buyingpower,
      initialPrice: stockDetail?.lastTradedPrice,
      code: stockDetail?.id
    },
    validateOnMount: false,
    validateOnChange: true,
    validationSchema: SimpleOrderSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleChange = (val, obj) => {
    formik.setFieldValue(obj, val);
  };

  const handleChangeDefaultPrice = (price) => {
    formik.setFieldValue(`price`, price);
    formik.setFieldValue(`amount`, price * formik.values.lot * 100);
    setTimeout(() => formik.setTouched({ price: true, amount: true }), 100);
  };

  const handleInitDefaultPrice = (price) => {
    if (!initPrice && price) {
      formik.setFieldValue(`price`, price);
      formik.setFieldValue(`amount`, price * formik.values.lot * 100);
      setTimeout(() => formik.setTouched({ price: true, amount: true }), 100);
    }
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      let payloads = {
        clientID: user?.username,
        stockID: clearRGStockID,
        clOrdID: values?.orderNumber,
        price: values?.price,
        qty: values?.lot,
        expiry: 'DAY',
        boardID: clearBoardID,
        expiryAmount: '5'
      };

      if (values.orderType === 'SimpleBuy') {
        const { success, msg, err } = await createOrderBuy(payloads).unwrap();
        if (!success) {
          let error = new Error();
          Object.assign(error, { msg, code: err });
          throw error;
        }

        triggerSegmentEvent({
          event: buyAnalyticEventName,
          properties: {
            stock_name: symbol,
            total_amount: Number(values?.lot) * Number(values?.price) * 100,
            lot: values?.lot
          }
        });
      } else {
        const { success, msg, err } = await createOrderSell(payloads).unwrap();
        if (!success) {
          let error = new Error();
          Object.assign(error, { msg, code: err });
          throw error;
        }

        triggerSegmentEvent({
          event: sellAnalyticEventName,
          properties: {
            stock_name: symbol,
            total_amount: Number(values?.lot) * Number(values?.price) * 100,
            lot: values?.lot
          }
        });
      }

      toastr.success('Order telah terkirim');

      setTimeout(() => dispatch(invalidateUserNotification()), 1000);
      setOpenModalConfirmation(false);
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
      onSuccess();
    }
  };

  const handleConfirmation = async () => {
    const errors = await formik.validateForm();
    if (!user?.realAccount) return setIsNotRealAccount(true);

    if (isEmpty(errors)) {
      formik.setValues({
        ...formik.values,
        orderNumber: generateOrderId(
          dealerUseClientId || user?.username,
          defaultOrderType === 'SimpleBuy' ? 'B' : 'S'
        )
      });

      if (!quickOrderConfirmation) {
        setIsSubmitting(false);
        return setOpenModalConfirmation(true);
      }

      setTimeout(() => formik.submitForm(), 100);
    }
  };

  useEffect(() => {
    if (!formik.values.price && stockDetail) {
      formik.setValues({
        ...formik.values,
        price: initPrice ? initPrice : stockDetail?.lastTradedPrice,
        initialPrice: stockDetail?.lastTradedPrice
      });

      setTimeout(() => formik.setErrors({}), 300);
    }
  }, [stockDetail, initPrice]);

  useEffect(() => {
    if (
      formik.values.code != stockDetail?.id &&
      !isLoadingStock &&
      !isLoadingPortfolio
    ) {
      formik.setValues({
        ...formik.values,
        portfolio: (portfolio?.net || 0) / 100,
        price: initPrice ? initPrice : stockDetail?.lastTradedPrice,
        initialPrice: stockDetail?.lastTradedPrice,
        code: stockDetail?.id
      });

      setTimeout(() => formik.setErrors({}), 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockDetail, portfolio, isLoadingStock, isLoadingPortfolio]);

  return (
    <form
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      onKeyDown={(e) => e.key === 'Enter' && handleConfirmation()}
      noValidate className='zoom-2 max-w-[720px] mx-auto'>
      <Card className="p-4 py-8 lg:p-8 relative lg:mx-auto dark:border dark:border-gray-base-d">
        <button
          type="button"
          onClick={onClose}
          className="absolute right-4 top-3 hover:text-main-base-l dark:text-main-base-l hover:opacity-75">
          <IconClose />
        </button>

        <Tabs
          initialOpenIndex={formik.values.orderType === 'SimpleBuy' ? 0 : 1}
          className="grid grid-cols-2 font-quicksand-semibold text-gray-light-0-d text-sm -mx-4 lg:-mx-8 my-3"
          classObject={{
            li: '',
            liActive: '',
            active: 'border-main-base-l border-b-2 text-main-base-l',
            inactive: '!bg-opacity-[30%]',
            span: ''
          }}
          data={[
            {
              index: 0,
              text: 'Simple Buy',
              customActiveClass:
                'border-red-base-d !bg-red-base-d hover:border-red-base-d hover:bg-red-base-d border-b !text-white'
            },
            {
              index: 1,
              text: 'Simple Sell',
              customActiveClass:
                'border-green-base-d !bg-green-base-d hover:border-green-base-d hover:bg-green-base-d border-b !text-white'
            }
          ]}
          onChange={(index) => {
            if (index === 0) {
              formik.setFieldValue('orderType', 'SimpleBuy');
              formik.setFieldValue(
                'orderNumber',
                generateOrderId(dealerUseClientId || user?.username, 'B')
              );
            }

            if (index === 1) {
              formik.setFieldValue('orderType', 'SimpleSell');
              formik.setFieldValue(
                'orderNumber',
                generateOrderId(dealerUseClientId || user?.username, 'S')
              );
            }
          }}
        />

        <div className="mb-3 border-b border-gray-base-l dark:border-gray-base-d relative z-20">
          <div className="-mx-3">
            <TVHeader
              symbol={symbol}
              onChangeSymbol={(newSymbol) => onChangeSymbol(newSymbol)}
              type="LONG"
              showLogo
              advancedChart
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <TWOrderBook
              isExpandRow
              initPrice={formik?.values?.price}
              symbol={symbol}
              rows={10}
              showSummary={false}
              isShowChartOrderbook
              expandColor="bg-main-base-l text-white"
              onClickPrice={handleChangeDefaultPrice}
              onInitPrice={handleInitDefaultPrice}
              maxHeight={326}
            />
          </div>

          {/* INPUT */}
          <div>
            <div className="max-w-md ml-auto">
              <RenderIf isTrue={formik.values.orderType === 'SimpleBuy'}>
                <div className="flex justify-between">
                  <span className="text-xs text-gray-light-0-d block font-quicksand-semibold">
                    Limit
                  </span>
                  <h5 className="font-quicksand-semibold text-sm dark:text-black-light-1-d">
                    {currency(formik.values.buyingPower || 0, {
                      removeSymbol: true
                    })}
                  </h5>
                </div>
              </RenderIf>

              <RenderIf isTrue={formik.values.orderType === 'SimpleSell'}>
                <div className="flex justify-between">
                  <span className="text-xs text-gray-light-0-d block font-quicksand-semibold">
                    Lot tersedia
                  </span>
                  <h5 className="font-quicksand-semibold text-sm dark:text-black-light-1-d">
                    {currency(formik.values.portfolio || 0, {
                      removeSymbol: true
                    })}{' '}
                    Lot
                  </h5>
                </div>
              </RenderIf>

              <InputCounter
                isAutoFocus
                size="small"
                onBlur={formik.handleBlur}
                data-qa-id="txtStockBuyPriceField"
                value={formik.values.price}
                name="price"
                className="mb-3"
                label={
                  <span className="text-2xs text-gray-light-0-d">
                    Harga Per Saham
                  </span>
                }
                counterStep={marginPlusShare(formik.values.price)}
                onChange={(val) => {
                  handleChange(val, 'price');
                  setTimeout(() => formik.setTouched({ price: true }), 100);
                }}
                error={formik.errors.price ? formik.errors.price : ''}
                showError={false}
              />

              <InputCounter
                size="small"
                onBlur={formik.handleBlur}
                data-qa-id="txtStockBuyLotField"
                value={
                  formik.values.lotType
                    ? formik.values.lot
                    : formik.values.amount
                }
                value2={
                  formik.values.lotType
                    ? formik.values.amount
                    : formik.values.lot
                }
                name="lot"
                maxValue={
                  formik.values.orderType === 'SimpleSell'
                    ? formik.values.lotType
                      ? formik.values.portfolio
                      : formik.values.portfolio * formik.values.price * 100
                    : formik.values.lotType
                    ? Math.floor(
                        trading?.buyingpower / (formik.values.price * 100)
                      ) || 0
                    : (Math.floor(
                        trading?.buyingpower / (formik.values.price * 100)
                      ) || 0) *
                      formik.values.price *
                      100
                }
                label={
                  <span className="text-2xs text-gray-light-0-d">
                    <button
                      tabIndex={-1}
                      onClick={() =>
                        formik.setFieldValue('lotType', !formik.values.lotType)
                      }
                      type="button"
                      className="flex items-center hover:text-main-base-l">
                      <IconSort className="h-4 w-4 mr-1 transform rotate-90 text-main-base-l" />{' '}
                      {formik.values.lotType ? 'Jumlah Lot' : 'Amount'}
                    </button>
                  </span>
                }
                label2={
                  <span className="text-2xs text-gray-light-0-d">
                    {formik.values.lotType ? 'Amount' : 'Lot'}
                  </span>
                }
                showShortcut={formik.values.lotType}
                showValue2
                counterStep={
                  formik.values.lotType ? 1 : formik.values.price * 100
                }
                className="my-3"
                onChange={(val) => {
                  if (formik.values.lotType) {
                    handleChange(val, 'lot');
                    handleChange(
                      Number(val) * formik.values.price * 100,
                      'amount'
                    );
                  } else {
                    handleChange(val, 'amount');
                    handleChange(
                      Math.floor(Number(val) / (formik.values.price * 100)),
                      'lot'
                    );
                  }
                  // setDefaultPercent(null);
                  setTimeout(() => formik.setTouched({ lot: true }), 100);
                }}
                error={formik.errors.lot ? formik.errors.lot : ''}
                showError={false}
              />

              <div className="flex border-y py-2 items-center dark:border-gray-base-d">
                <span className="flex-none text-xs text-gray-light-0-d font-quicksand-semibold">
                  {formik.values.orderType === 'SimpleBuy'
                    ? 'Total Pembelian'
                    : 'Total Penjualan'}
                </span>
                <h5 className="flex-grow text-right text-sm dark:text-black-light-1-d">
                  {currency(
                    parseInt(formik.values.lot) *
                      parseInt(formik.values.price) *
                      100
                  )}
                </h5>
              </div>

              <RenderIf isTrue={!formik?.isValid}>
                <div className="font-quicksand-semibold py-3 px-4 flex items-center text-red-base-l text-xs rounded-xl my-3 bg-red-base-l bg-opacity-10">
                  <IconWarning className="h-5 w-5 mr-1 flex-none" />
                  {Object.keys(formik?.errors)?.map((key, idx) => (
                    <RenderIf key={key} isTrue={idx === 0}>
                      <span
                        key={key}
                        className="flex items-center w-full flex-auto">
                        {idx === 0 && formik?.errors[key]}
                      </span>
                    </RenderIf>
                  ))}
                </div>
              </RenderIf>

              <div className="mt-6 relative">
                <button
                  data-qa-id="btnStockBuySubmit"
                  disabled={
                    (!formik.isValid && user?.realAccount) || isSubmitting
                  }
                  onClick={handleConfirmation}
                  type="button"
                  className={clsx(
                    'disabled:text-black-light-0-d hover:bg-opacity-75 disabled:bg-opacity-[30%] text-white py-3 px-3 text-sm font-quicksand-semibold rounded-lg w-full mr-0 ml-auto flex items-center justify-center transition-all ease-in-out duration-100',
                    formik.values.orderType === 'SimpleBuy' && 'bg-red-base-d',
                    formik.values.orderType === 'SimpleSell' &&
                      'bg-green-base-d'
                  )}>
                  {isSubmitting && <SimpleSpinner />}
                  <RenderIf isTrue={formik.values.orderType === 'SimpleBuy'}>
                    Beli{' '}
                    {formik.values.lot
                      ? automatedNumberFormat(formik.values.lot) + ' Lot'
                      : ''}{' '}
                    {symbol} @{automatedNumberFormat(formik.values.price)}
                  </RenderIf>

                  <RenderIf isTrue={formik.values.orderType === 'SimpleSell'}>
                    Jual{' '}
                    {formik.values.lot
                      ? automatedNumberFormat(formik.values.lot) + ' Lot'
                      : ''}{' '}
                    {symbol} @{automatedNumberFormat(formik.values.price)}
                  </RenderIf>
                </button>
              </div>

              <div className="mt-4">
                <InputCheckBox
                  id={`${uniqueId('confirmation-')}`}
                  value={quickOrderConfirmation}
                  name="confirmation"
                  checked={quickOrderConfirmation}
                  label={
                    <span className="text-xs font-quicksand-regular text-black-base-l dark:text-black-light-0-d transform">
                      Saya menyetujui untuk tidak menampilkan konfirmasi
                      transaksi
                    </span>
                  }
                  onChange={() => {
                    dispatch(
                      setQuickOrderSettingsConfirmation(!quickOrderConfirmation)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  className="mb-0"
                />
              </div>
            </div>
          </div>
        </div>
      </Card>

      <RenderIf isTrue={formik.values.orderType === 'SimpleBuy'}>
        <SimpleStockBuyConfirmation
          isOpen={isOpenModalConfirmation}
          isSubmitting={isSubmitting}
          data={{ ...formik?.values, code: symbol }}
          onClose={() => setOpenModalConfirmation(false)}
          onSubmit={() => formik?.submitForm()}
        />
      </RenderIf>

      <RenderIf isTrue={formik.values.orderType === 'SimpleSell'}>
        <SimpleStockSellConfirmation
          isOpen={isOpenModalConfirmation}
          isSubmitting={isSubmitting}
          data={{ ...formik?.values, code: symbol }}
          onClose={() => setOpenModalConfirmation(false)}
          onSubmit={() => formik?.submitForm()}
        />
      </RenderIf>

      <RenderIf isTrue={isNotRealAccount}>
        <Modal onClose={() => setIsNotRealAccount(false)}>
          <ModalExploreExpired
            showClose
            eventTracking="TR Become Member From Feature Limit Modal Window Clicked"
            title="Mulai Gabung dan Nikmati Fitur serta Benefitnya"
            description="Daftar sekarang dan nikmati kemudahan berinvestasi dengan platform PINA yang mudah dan canggih"
            onClose={() => setIsNotRealAccount(false)}
          />
        </Modal>
      </RenderIf>
    </form>
  );
}

SimpleBuySellForm.propTypes = {
  onClose: PropTypes.func,
  onChangeSymbol: PropTypes.func,
  initPrice: PropTypes.number,
  symbol: PropTypes.string,
  defaultOrderType: PropTypes.string,
  buyAnalyticEventName: PropTypes.string,
  sellAnalyticEventName: PropTypes.string
};

export default SimpleBuySellForm;
