import RenderIf from 'components/basic/render-if';
import IconSorting from 'components/svg/icon-sorting';
import dayjs from 'dayjs';
import { useTableSorting } from 'hooks/useTableSorting';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import { useGetDepositQuery } from 'services/rtk-query/trading';
import { currency } from 'utils/format';

function CashDepositHistory() {
  const { data: deposit, isFetching } = useGetDepositQuery(null, { refetchOnMountOrArgChange: true });
  const  { data, sortingColumns, handleSorting, getSorting } = useTableSorting(deposit?.depositHistory);

  return (
    <OverlayScrollbarsComponent
      className="overlayscrollbars-host-small -mt-3"
      element="span"
      options={{
        resize: 'horizontal',
        scrollbars: {
          autoHide: 'leave',
          autoHideDelay: 50
        }
      }}
      >
      <table className="text-xs w-full text-center font-quicksand-semibold">
        <thead>
          <tr>
            <td
              className={`p-2 py-3 border-b dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'timestamp' && 'text-main-base-l'}`}
              onClick={() => handleSorting('timestamp')}
              style={{ minWidth: '120px' }}>
              <span className="flex items-center justify-between cursor-pointer">
                Time{' '}
                <IconSorting
                  type={getSorting('timestamp')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            <td
              className={`p-2 py-3 border-b dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'cif' && 'text-main-base-l'}`}
              onClick={() => handleSorting('cif')}>
              <span className="flex items-center justify-between cursor-pointer">
                CIF{' '}
                <IconSorting
                  type={getSorting('cif')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            <td
              className={`p-2 py-3 border-b dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'tranType' && 'text-main-base-l'}`}
              onClick={() => handleSorting('tranType')}>
              <span className="flex items-center justify-between cursor-pointer">
                Type{' '}
                <IconSorting
                  type={getSorting('tranType')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            <td
              className={`p-2 py-3 border-b dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'amount' && 'text-main-base-l'}`}
              onClick={() => handleSorting('amount')}>
              <span className="flex items-center justify-between cursor-pointer">
                Amount{' '}
                <IconSorting
                  type={getSorting('amount')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            <td
              className={`p-2 py-3 border-b dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'balance' && 'text-main-base-l'}`}
              onClick={() => handleSorting('balance')}>
              <span className="flex items-center justify-between cursor-pointer">
                Balance{' '}
                <IconSorting
                  type={getSorting('balance')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
            <td
              className={`p-2 py-3 border-b dark:border-gray-base-d hover:text-main-base-l ${sortingColumns?.[0] === 'narrative' && 'text-main-base-l'}`}
              onClick={() => handleSorting('narrative')}>
              <span className="flex items-center justify-between cursor-pointer">
                Narrative{' '}
                <IconSorting
                  type={getSorting('narrative')}
                  className="ml-1 h-6 w-6"
                />
              </span>
            </td>
          </tr>
        </thead>
        <tbody className="text-xs">
          <RenderIf isTrue={data?.length === 0 && !isFetching}>
            <tr className="group">
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                -
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                -
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                -
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                -
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                -
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                -
              </td>
            </tr>
          </RenderIf>

          <RenderIf isTrue={isFetching}>
            <tr className="group">
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                <SkeletonTheme width="50px" height="1rem" borderRadius="6px" />
              </td>
            </tr>
          </RenderIf>

          {data?.map((item, key) => (
            <tr key={key} className="group">
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left font-quicksand-regular">
                {dayjs(item?.timestamp).format('DD/MM/YYYY - HH:mm')}
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                {item?.cif}
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                {item?.tranType}
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                {currency(item?.amount, { removeSymbol: true })}
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left">
                {currency(item?.balance, { removeSymbol: true })}
              </td>
              <td className="p-2 py-3 border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 text-left font-quicksand-regular">
                <p className="text-2xs">{item?.narrative}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </OverlayScrollbarsComponent>
  );
}

export default CashDepositHistory;
