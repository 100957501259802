import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function BlinkText({ value, className }) {
  const [tempValue, setTempValue] = useState(value);
  const [toggleActive, setToggleActive] = useState(false);

  useEffect(() => {
    if (tempValue != value) {
      setTempValue(value);
      setToggleActive(true);
    }
  }, [value, tempValue]);

  return (
    <span
      className={`${className} ${toggleActive && 'animate-blink'}`}
      onAnimationEnd={() => setToggleActive(false)}>
      {value}
    </span>
  );
}

BlinkText.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string
};

export default BlinkText;
