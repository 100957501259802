import PropTypes from 'prop-types';

function IconRefresh({ className }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_446_424)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9127 5.46881C13.2116 5.29622 13.0852 4.67393 12.9127 4.375C11.1868 1.38567 7.36433 0.361451 4.375 2.08734C1.38567 3.81323 0.361451 7.63567 2.08734 10.625C3.81323 13.6143 7.63567 14.6385 10.625 12.9127C10.9239 12.7401 11.0264 12.3578 10.8538 12.0589C10.6812 11.76 10.2989 11.6575 10 11.8301C7.60854 13.2108 4.55058 12.3915 3.16987 10C1.78916 7.60854 2.60854 4.55058 5 3.16987C7.39146 1.78916 10.4494 2.60854 11.8301 5C12.0027 5.29893 12.6137 5.6414 12.9127 5.46881Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5254 3.605C13.2935 3.52858 13.0652 3.66041 13.0154 3.89945L12.7232 5.30292L11.3617 4.85423C11.1298 4.7778 10.9015 4.90963 10.8517 5.14867C10.8019 5.38771 10.9496 5.64344 11.1815 5.71987L12.9629 6.30694C13.1948 6.38336 13.4231 6.25153 13.4729 6.01249L13.8552 4.17619C13.905 3.93715 13.7573 3.68142 13.5254 3.605Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_446_424">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="matrix(-1 0 0 1 15 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

IconRefresh.propTypes = {
  className: PropTypes.string
};

export default IconRefresh;
