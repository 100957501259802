import PropTypes from 'prop-types';

function IconStarDelete({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7.25901 7.25903L4.29305 7.50744C2.11554 7.68982 1.20347 10.3814 2.82155 11.85L5.76453 14.521L4.80122 18.9571C4.31452 21.1984 6.85053 22.8574 8.70911 21.5135L12 19.134L15.291 21.5135C17.0689 22.799 19.4667 21.3368 19.2454 19.2455L16.6516 16.6516L17.2444 19.3815C17.3417 19.8298 16.8345 20.1616 16.4628 19.8928L12.8789 17.3015C12.3544 16.9222 11.6457 16.9222 11.1211 17.3015L7.53725 19.8928C7.16553 20.1616 6.65833 19.8298 6.75567 19.3815L7.78027 14.6631C7.89404 14.1392 7.71952 13.5944 7.32252 13.2341L4.16567 10.369C3.84206 10.0753 4.02447 9.53694 4.45998 9.50047L8.60892 9.15297C8.76209 9.14015 8.90983 9.10419 9.04803 9.04806L7.25901 7.25903Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.69915 3.51857C10.5615 1.4886 13.4387 1.4886 14.3011 3.51857L15.8587 7.18501L19.707 7.50733C21.8845 7.6897 22.7966 10.3813 21.1785 11.8498L19.4741 13.3968C19.0651 13.768 18.4327 13.7373 18.0615 13.3284C17.6904 12.9194 17.721 12.287 18.1299 11.9158L19.8344 10.3688C20.158 10.0751 19.9756 9.53682 19.5401 9.50035L15.3912 9.15286C14.8367 9.10641 14.3534 8.75678 14.1358 8.2446L12.4603 4.30059C12.2878 3.89459 11.7124 3.8946 11.5399 4.30059L11.3335 4.78652C11.1175 5.29484 10.5304 5.53185 10.0221 5.3159C9.51378 5.09996 9.27676 4.51282 9.49271 4.00451L9.69915 3.51857Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"
      />
    </svg>
  );
}

IconStarDelete.propTypes = {
  className: PropTypes.string
};


export default IconStarDelete;
