import PropTypes from 'prop-types';
import styles from './css/input-check-box.module.css';
import { useState } from 'react';

function InputCheckBox({
  checked,
  value,
  name,
  label,
  disabled,
  onChange = () => {},
  onBlur = () => {},
  isError,
  color,
  className = 'mb-5',
  id = 'checkbox-default',
  ...props
}) {
  const [focus, setFocus] = useState(false);
  const handleFocus = (e) => {
    setFocus(true);
  }

  const handleBlur = (e) => {
    onBlur(e);
    setFocus(false);
  }

  return (
    <label
      className={`${styles['input-checkbox']}
      ${disabled && styles['input-checkbox-disabled']}
      ${styles[`input-checkbox-${color}`]} 
      ${isError && styles['input-checkbox-error']} ${className}`}
      htmlFor={id}>
      <input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        // defaultChecked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        role='button'
        {...props}
      />
      <span
        className={`${styles['checkmark']} ${focus && 'ring-1 ring-main-base-d outline-none outline-transparent ring-inset'}
        ${isError && styles['checkmark-error']} dark:bg-black-base-d`}
        style={{ marginRight: !label && '0px'}}
      />
      <span className={styles['text']}>{label}</span>
    </label>
  );
}

InputCheckBox.propTypes = {
  value: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isError: PropTypes.any,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default InputCheckBox;
