import dayjs from 'dayjs';
import baseApi from '../base';
import { TAG_PORTFOLIO_CHART, TAG_PORTFOLIO_CHART_PER_STOCK } from '../tags';
import { dayjsLocal } from 'utils/formatter/date';
import { findIndex, uniq } from 'lodash';

export const InvestApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInvestChart: build.query({
      query: () => {
        return {
          url: `pina-invest/invest/chart/portfolio`
        };
      },
      transformResponse: ({ payloads }) => {
        let _response = {
          series: [
            {
              name: 'Tabungan',
              type: 'area',
              data: []
            },
            {
              name: 'Tabungan + Keuntungan',
              type: 'line',
              data: []
            },
            {
              name: 'Tabungan + Keuntungan',
              type: 'area',
              data: []
            }
          ],
          categories: []
        };

        payloads?.map((item) => {
          _response.series[0].data.push(Number(item?.clientportfoliobalance));
          _response.series[1].data.push(
            Number(item?.clientportfoliobalance) +
              Number(item?.clientportfolioprofit)
          );
          _response.series[2].data.push(
            Number(item?.clientportfoliobalance) +
              Number(item?.clientportfolioprofit)
          );
          _response.categories.push(
            dayjs(item?.clientportfoliodate?.replace('+0000', '')).format(
              'MM/DD/YYYY'
            )
          );
        });

        return { series: _response.series, categories: _response.categories };
      },
      providesTags: () => {
        return [{ type: TAG_PORTFOLIO_CHART, id: 'LIST' }];
      }
    }),
    getInvestChartPerStock: build.query({
      // eslint-disable-next-line no-unused-vars
      async queryFn(_arg, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const { data: stockChart } = await fetchWithBQ('/pina-invest/invest/chart/stockportfolio');
        let { data: clientStocks } = await fetchWithBQ('/trade/clientStocks');

        let { arr: portfolio } = clientStocks;
        // let stockCurrentPrice = 0;
        // let stockAvgPrice = 0;

        const getStockList = portfolio?.filter(item => {
          // stockCurrentPrice += item.marketValue;
          // stockAvgPrice += item.stockValue;
          return item.marketValue > 0;
        }).map(async item => {
          const { data: stockDetail } = await fetchWithBQ(`/stocks/${item.stID}`);
  
          return({
            ...item,
            sLogo: stockDetail?.logo,
            sName: stockDetail?.name,
            code: item.stID,
            totalGain: item.marketValue - item.stockValue,
            totalGainPercentage: ((item.marketValue - item.stockValue) / item.stockValue) * 100,
          })
        });

        const stockList = await Promise.all(getStockList || [])  


        let { payloads } = stockChart;

        let series = [];
        let categories = [];

        payloads?.forEach((stock) => {
          if (stockList?.find((item) => item?.code === stock?.[0])) {
            let dates = stock?.[4]?.split(',');
            dates = dates?.map((date) => dayjsLocal(date)?.format('DD/MM/YYYY'));
            categories = [...categories, ...dates];
  
            series.push({
              name: stock?.[0],
              type: 'line',
              data: stock?.[2]?.split(','),
              profit: stock?.[3]?.split(','),
              dates
            });
          }
        });

        categories = uniq(categories);
        categories = categories?.map((date) => dayjsLocal(date, 'DD/MM/YYYY').valueOf());
        categories = categories.sort();
        categories = categories?.map((date) => dayjsLocal(date).format('DD/MM/YYYY'));

        series.forEach((seriesItem, seriesIndex) => {
          let datas = [];
          let profits = [];
          categories.forEach((date) => {
            let foundIndex = findIndex(
              seriesItem.dates,
              (sDate) => sDate === date
            );

            // if (seriesItem?.dates[0] === date) {
            if (foundIndex >= 1) {
              datas.push(seriesItem?.data[foundIndex]);
              profits.push(seriesItem?.profit[foundIndex]);
              // datas = [...datas, ...seriesItem.data];
              // profits = [...profits, ...seriesItem.profit];
            } else {
              datas.push(datas?.[foundIndex - 1] || 0);
              profits.push(profits?.[foundIndex - 1] || 0);
              // datas.push(null);
              // profits.push(null);
            }
          });
          series[seriesIndex].data = datas;
          series[seriesIndex].profit = profits;
        });
        
        return { data: { series, categories }}
      },
      transformResponse: (res) => res || {},
      providesTags: () => {
        return [{ type: TAG_PORTFOLIO_CHART_PER_STOCK, id: 'LIST' }];
      }
    })
  })
});

export const { useGetInvestChartQuery, useGetInvestChartPerStockQuery } =
  InvestApi;
