import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fastOrderSettings: {},
  quickOrderSettings: {
    confirmation: false
  },
  fontSize: 'trade-font-normal',
  absoluteWorkspace: [
    {
      id: 'bf4b408e-248e-4483-b25a-1216cd61e947',
      name: 'Absolute Workpsace',
      slug: 'absolute-workspace',
      layout: '[]',
      created_at: 1724039337550,
      updated_at: 1724039372987,
      updated_by: null,
      created_by: null,
      is_active: true,
      is_deleted: false,
      user_id: 'c2c52f4d-99e1-4d03-ac9e-2438e863fe37'
    }
  ]
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setFastOrderSettingsBySymbol: (state, action) => {
      const symbol = action?.payload?.symbol;
      const settings = action?.payload?.settings;

      return {
        ...state,
        fastOrderSettings: {
          ...state.fastOrderSettings,
          [symbol]: settings
        }
      };
    },
    setQuickOrderSettingsConfirmation: (state, { payload }) => {
      state.quickOrderSettings.confirmation = payload;
    },
    setFontSize: (state, { payload }) => {
      state.fontSize = payload;
    },
    setAbsoluteWorkspace: (state, { payload }) => {
      state.absoluteWorkspace = state.absoluteWorkspace?.map((item) => {
        if (item?.slug === payload?.slug) {
          return payload
        }

        return item
      });
    },
  }
});

export const settingsPersistConfig = {
  key: 'pn-trade-settings',
  whitelist: ['fastOrderSettings', 'quickOrderSettings', 'fontSize', 'absoluteWorkspace']
};

export const {
  setFastOrderSettingsBySymbol,
  setQuickOrderSettingsConfirmation,
  setFontSize,
  setAbsoluteWorkspace
} = settings.actions;
export default settings.reducer;
