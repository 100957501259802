import ReactDOMServer from "react-dom/server";
import IconMenuHome from "../components/svg/icon-menu-home";
import IconMenuProfile from "../components/svg/icon-menu-profile";
import IconMenuOrder from "components/svg/icon-menu-order";
import IconSearch from "components/svg/icon-search";

export const ListMenu = [
  {
    title: "Trading",
    link: "/trading",
    isExternalLink: false,
    separator: false,
    icon: ReactDOMServer.renderToString(<IconMenuHome className="w-6 md:w-4 h-6 md:h-4" />),
    ['data-qa-id'] : 'linkNavTrading'
  },
  {
    title: "Portfolio",
    link: "/portfolio",
    isExternalLink: false,
    separator: false,
    icon: ReactDOMServer.renderToString(<IconMenuHome className="w-6 md:w-4 h-6 md:h-4" />),
    ['data-qa-id'] : 'linkNavPortfolio'
  },
  {
    title: "Order",
    link: "/order",
    isExternalLink: false,
    separator: false,
    icon: ReactDOMServer.renderToString(<IconMenuOrder className="w-6 md:w-4 h-6 md:h-4" />),
    ['data-qa-id'] : 'linkNavOrder'
  },
  {
    title: "Search",
    link: "/stock-market/stock",
    isExternalLink: false,
    separator: false,
    icon: ReactDOMServer.renderToString(<IconSearch className="w-6 md:w-4 h-6 md:h-4" />),
    ['data-qa-id'] : 'linkNavSearch'
  },
  {
    title: "Account",
    link: "/profile",
    isExternalLink: false,
    separator: true,
    icon: ReactDOMServer.renderToString(<IconMenuProfile className="w-6 md:w-4 h-6 md:h-4" />),
    ['data-qa-id'] : 'linkNavProfile'
  },
];


export const ListMenuPinaPro = [
  {
    title: "Auto Trading Planner",
    link: "/auto-trading-planner",
    isExternalLink: false,
    separator: false,
    ['data-qa-id'] : 'linkNavTrading'
  },
  {
    title: "Stock Market",
    link: "/stock-market",
    isExternalLink: false,
    separator: false,
    ['data-qa-id'] : 'linkNavTrading'
  },
  {
    title: "Order List",
    link: "/order",
    isExternalLink: false,
    separator: false,
    ['data-qa-id'] : 'linkNavSearch'
  },
  {
    title: "Portfolio",
    link: "/portfolio",
    isExternalLink: false,
    separator: false,
    ['data-qa-id'] : 'linkNavSearch'
  }
];

export const ListMenuHeader = [
  {
    link: '/profile/deposit',
    title: 'Top-up Saldo',
    'data-qa-id': 'linkHeaderProfile'
  },
  {
    link: '/profile/cash-withdrawal',
    title: 'Tarik Saldo',
    'data-qa-id': 'linkHeaderProfile'
  },
  {
    link: '/profile/cash-history',
    title: 'Cash History',
    'data-qa-id': 'linkHeaderProfile'
  },
  {
    link: '/profile',
    title: 'Profile',
    'data-qa-id': 'linkHeaderProfile'
  },
  {
    link: 'https://help.pina.id/id/collections/4123815-pina-trade',
    title: 'Pusat Bantuan',
    externalLink: true,
    'data-qa-id': 'linkHeaderProfile',
  },
  {
    link: '/logout',
    title: 'Logout',
    'data-qa-id': 'linkHeaderLogout'
  }
]

