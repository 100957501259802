import PropTypes from 'prop-types';

function IconAmend({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1 13.1671V4.78684C11.1 4.00164 10.8473 3.39735 10.3294 2.99289C9.81608 2.59211 9.05778 2.4 8.07127 2.4C7.46785 2.4 6.9388 2.47316 6.48593 2.62179C6.0269 2.76272 5.64743 3.01999 5.34992 3.39234C5.04762 3.76095 4.9 4.22848 4.9 4.78684V13.1671C4.9 13.3088 4.95786 13.4253 5.07555 13.5009C5.18567 13.5716 5.33808 13.6 5.51836 13.6C5.69864 13.6 5.85105 13.5716 5.96116 13.5009C6.07886 13.4253 6.13672 13.3088 6.13672 13.1671V9.34474H9.86328V13.1671C9.86328 13.3088 9.92114 13.4253 10.0388 13.5009C10.149 13.5716 10.3014 13.6 10.4816 13.6C10.6619 13.6 10.8143 13.5716 10.9244 13.5009C11.0421 13.4253 11.1 13.3088 11.1 13.1671ZM9.86328 8.13158H6.13672V4.64211C6.13672 4.30155 6.28069 4.05232 6.57589 3.88201C6.87942 3.7069 7.34759 3.61316 7.99352 3.61316C8.64383 3.61316 9.11536 3.70691 9.42112 3.88215C9.71853 4.05261 9.86328 4.30187 9.86328 4.64211V8.13158Z"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  );
}

IconAmend.propTypes = {
  className: PropTypes.string
};

export default IconAmend;
