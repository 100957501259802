import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import { useBrowserTabId } from 'hooks/useBrowserTabId';
import { isEmpty, isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUserMeQuery } from 'services/rtk-query/user';
import { setAuth } from 'store/auth';

function CheckUserProvider() {
  const dispatch = useDispatch();
  const auth = useBrowserTabAuth();
  const browserTabID = useBrowserTabId();

  const { data, refetch, isFetching } = useGetUserMeQuery(auth?.token, {
    skip: !auth?.token,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    // jika ada perubahan data user dari API, update store user
    if (!isEqual(auth?.user, data) && !isEmpty(data) && !isFetching) {
      const newAuth = { ...auth, user: data };
      dispatch(
        setAuth({
          ...newAuth,
          [browserTabID]: newAuth
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, data, isFetching]);

  useEffect(() => {
    refetch();
  }, [auth]);

  return <></>;
}

export default CheckUserProvider;
