import PropTypes from 'prop-types';

function InputPercent({ options, onChange, className, value, color, title = '' }) {
  return (
    <ul
      className={`flex items-center justify-between text-2xs font-quicksand-bold gap-2 max-w-full overflow-auto ${className}`}>
      {options?.map((item, key) => (
        <li
          role='button'
          tabIndex="0"
          title={`${item?.text}${title}`}
          key={key}
          onClick={() => onChange(item)}
          onKeyDown={(e) => e.key === 'Enter' && onChange(item)}
          className={`border py-[4px] px-2 hover:bg-main-base-l dark:hover:text-black-base-d hover:text-white transition-all ease-in-out duration-150 rounded-md cursor-pointer w-full text-center
          ${value === item?.value ? `bg-main-base-l text-white dark:text-black-base-d border-main-base-l dark:border-gray-base-d` : 'text-gray-light-0-d border-gray-base-l dark:border-main-base-l dark:text-main-base-l'}`}>
          {item?.text}
        </li>
      ))}
    </ul>
  );
}

InputPercent.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
};

InputPercent.defaultProps = {
  color: 'gold-1',
  options: [
    {
      text: '25%',
      value: 0.25
    },
    {
      text: '50%',
      value: 0.5
    },
    {
      text: '75%',
      value: 0.75
    },
    {
      text: '100%',
      value: 1
    }
  ],
  onChange: () => {},
  className: '-mt-2 mb-5',
  value: null
};

export default InputPercent;
