/* eslint-disable no-useless-escape */
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { RegisterReferralCodeSchema, RegisterSchema } from './register-form.validation';
import Card from 'components/basic/card';
import Spinner from 'components/common/spinner';
import IconLock from 'components/svg/icon-lock';
import IconEmail from 'components/svg/icon-email';
import { Link, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useAuthLoginGoogleMutation, useAuthLoginMutation, useCreateSignUpGoogleMutation, useCreateSignUpMutation } from 'services/rtk-query/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetUserMeQuery, useLazyGetUserRolesQuery } from 'services/rtk-query/user';
import { triggerSegmentEvent, triggerSegmentIdentify } from 'lib/segment';
import { setAuth } from 'store/auth';
import IconGoogle from 'components/svg/icon-google';
import { parseJwt } from 'utils/jwt';
import IconCheck from 'components/svg/icon-check';
import InputText from 'components/input/input-text';
import { useLazyCheckReferralCodeQuery } from 'services/rtk-query/rewards';
import IconEyeOff from 'components/svg/icon-eye-off';
import IconEye from 'components/svg/icon-eye';
import IconClose from 'components/svg/icon-close';
import { useCookies } from 'react-cookie';
import { isEmpty } from 'lodash';
import useGetErrorMessage from 'hooks/useGetErrorMessage';
import RenderIf from 'components/basic/render-if';
import Modal from 'components/modal/modal';
import useBrowserTabAuth from 'hooks/useBrowserTabAuth';
import { useBrowserTabId } from 'hooks/useBrowserTabId';
import LogoPinaTrade from 'assets/images/logo/logo-pinatrade.svg';


const RegisterForm = ({ className }) => {
  const dispatch = useDispatch();
  const { fcmToken } = useBrowserTabAuth();
  const [searchParams] = useSearchParams();
  const { getErrorMessage } = useGetErrorMessage(); 
  const browserTabID = useBrowserTabId();
  
  const [createSignUp] = useCreateSignUpMutation();
  const [createSignUpGoogle] = useCreateSignUpGoogleMutation()
  const [authLogin] = useAuthLoginMutation();
  const [authLoginGoogle] = useAuthLoginGoogleMutation();
  const [getUserMe] = useLazyGetUserMeQuery();
  const [getUserRoles] = useLazyGetUserRolesQuery();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [openModalReferralNormal, setOpenModalReferralNormal] = useState(false);
  const [openModalReferralGoogleAuth, setOpenModalReferralGoogleAuth] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['g_state', 'pn_trade_auth_token']);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validateOnMount: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      setOpenModalReferralNormal(values);
    }
  });

  const handleRegisterType = (type) => {
    if (type === 'NORMAL') formik.submitForm();

    if (type === 'SSO') {
      try {
        setCookie('g_state', true, { path: '/', maxAge: 0 });

        setTimeout(() => {
          window?.google?.accounts?.id?.initialize({
            client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
            callback: ({ credential }) => {
              setOpenModalReferralGoogleAuth(credential);
            },
            native_callback: () => alert(`This browser is doesn't support, for google auth`)
          });
    
          window?.google?.accounts?.id?.prompt();
        }, 100);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleRegister  = async (values) => {
    setIsSubmitting(true);

    triggerSegmentEvent({
      event: 'TR Email & Password Inputtted',
      properties: {
        email: values?.email,
        method: 'Email'
      }
    });

    try {
      const username = values?.email.split('@')[0].replace(/[^a-zA-Z ]/g, '').slice(0, 8);
      const uniqueId = Math.floor(10 + Math.random() * 99);

      const { payloads } = await createSignUp({
        userName: username + uniqueId,
        starterState: '-1',
        referrerCode: '',
        fcmToken: '',
        deviceId: '',
        devicePlatform: 'web',
        ...values
      }).unwrap();

      triggerSegmentEvent({
        event: 'TR Sign Up for PINA Trade',
        properties: {
          email: values?.email,
          method: 'Email',
          userId: payloads?.id,
          username: payloads?.username,
          registerFrom: 'TR'
        }
      });

      triggerSegmentEventFromOthersCampaign({ email: values?.email, method: 'Email'});

      const responseLogin = await authLogin({ email: values?.email, password: values?.password, fcmToken, devicePlatform: 'web' }).unwrap();
      const { data: responseMe } = await getUserMe(responseLogin?.accessToken);
      const { data: responseRoles } = await getUserRoles(responseLogin?.accessToken);

      let { birthDate, imgAvatarUrl, ...filteredUserData } = responseMe;
      triggerSegmentIdentify({
        userId: responseMe?.id,
        properties: {
          ...filteredUserData,
          name: responseMe?.firstName + ' ' + responseMe?.lastName,
          email: responseMe?.email,
        }
      });

      const auth = {
        isLogin: true,
        token: responseLogin?.accessToken,
        refreshToken: null,
        user: responseMe,
        roles: responseRoles
      }

      dispatch(
        setAuth({
          ...auth,
          [browserTabID]: auth
        })
      );

      setCookie('pn_trade_auth_token', auth?.token, { path: '/', maxAge: 999999999 });
    } catch (error) {
      setError(error?.data?.message);
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleRegisterWithGoogleOauth = async (accessToken, referrerCode) => {
    setIsSubmitting(true);

    let { email } = parseJwt(accessToken);

    triggerSegmentEvent({
      event: 'TR Email & Password Inputtted',
      properties: {
        email: email,
        method: 'Google'
      }
    });

    try {
      const username = email.split('@')[0].replace(/[^a-zA-Z ]/g, '').slice(0, 8);
      const uniqueId = Math.floor(10 + Math.random() * 99);

      const { payloads } = await createSignUpGoogle({
        userName: username + uniqueId,
        email: email,
        idToken: accessToken,
        password: '',
        starterState: '-1',
        referrerCode: referrerCode,
        fcmToken: accessToken,
        deviceId: '',
        devicePlatform: 'web',
      }).unwrap();

      triggerSegmentEvent({
        event: 'TR Sign Up for PINA Trade',
        properties: {
          email: email,
          method: 'Google',
          userId: payloads?.id,
          username: payloads?.username,
          registerFrom: 'TR'
        }
      });

      triggerSegmentEventFromOthersCampaign({ email: email, method: 'Google'});

      const responseLogin = await authLoginGoogle({ email: email, password: '', fcmToken, idToken: accessToken, devicePlatform: 'web' }).unwrap();
      const { data: responseMe } = await getUserMe(responseLogin?.accessToken);
      const { data: responseRoles } = await getUserRoles(responseLogin?.accessToken);

      let { birthDate, imgAvatarUrl, ...filteredUserData } = responseMe;
      triggerSegmentIdentify({
        userId: responseMe?.id,
        properties: {
          ...filteredUserData,
          name: responseMe?.firstName + ' ' + responseMe?.lastName,
          email: responseMe?.email,
        }
      });

      const auth = {
        isLogin: true,
        token: responseLogin?.accessToken,
        refreshToken: null,
        user: responseMe,
        roles: responseRoles
      }

      dispatch(
        setAuth({
          ...auth,
          [browserTabID]: auth
        })
      );
      setCookie('pn_trade_auth_token', auth?.token, { path: '/', maxAge: 999999999 });
    } catch (error) {
      setError(error?.data?.message)
      getErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const triggerSegmentEventFromOthersCampaign = ({ email, method}) => {

    let _eventObj = {};
    for(let entry of searchParams.entries()) {
      _eventObj[entry[0]] = entry[1];
    }

    if (!isEmpty(_eventObj)) {
      triggerSegmentEvent({
        event: 'TR Sign up From Website',
        properties: {
          email: email,
          method: method,
          ..._eventObj
        }
      });
    }

  }

  return (
    <div
      className={`${className} lg:w-4/12 max-w-lg lg:min-w-[450px] mx-auto`}>
      <Card className="w-full py-8">
        <div className="px-6 md:px-10">
          <img
            className="h-8 w-auto mx-auto"
            src={LogoPinaTrade}
            alt="Pina Trade"
          />

          <h2 className='text-lg text-left font-quicksand-semibold my-6 text-main-base-d'>Register</h2>
          <form onSubmit={formik.handleSubmit}>
            <InputText
              data-qa-id="txtEmailField"
              type="email"
              name="email"
              placeholder="Masukkan alamat email kamu"
              label="Email"
              iconLeft={<IconEmail />}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            />

            <InputText
              data-qa-id="txtPasswordField"
              type={!showPassword ? 'password' : 'text'}
              name="password"
              placeholder="Masukkan password kamu"
              label="Password"
              iconLeft={<IconLock />}
              iconRight={!showPassword ? <IconEyeOff /> : <IconEye />}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
              onClickRightIcon={() => setShowPassword(!showPassword)}
            />

            <ul className='-mt-2 mb-3 text-xs text-gray-light-0-d flex flex-col gap-0.5'>
              <li className='flex items-center'>
                <IconCheck className={`h-4 w-4 mr-1 ${formik.values.password.length >= 8 ? 'text-green-base-l' : 'text-gray-base-l'}`}/>
                <p>Minimal 8 karakter</p>
              </li>
              <li className='flex items-center'>
                <IconCheck className={`h-4 w-4 mr-1 ${/[0-9]/.test(formik.values.password) ? 'text-green-base-l' : 'text-gray-base-l'}`}/>
                <p>Memiliki 1 angka</p>
              </li>
              <li className='flex items-center'>
                <IconCheck className={`h-4 w-4 mr-1 ${/[A-Z]/.test(formik.values.password) ? 'text-green-base-l' : 'text-gray-base-l'}`}/>
                <p>Memiliki 1 huruf kapital</p>
              </li>
              <li className='flex items-center'>
                <IconCheck className={`h-4 w-4 mr-1 ${/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(formik.values.password) ? 'text-green-base-l' : 'text-gray-base-l'}`}/>
                <p>Memiliki 1 karakter spesial</p>
              </li>
            </ul>

            <InputText
              data-qa-id="txtPasswordConfirmationField"
              type={!showPassword ? 'password' : 'text'}
              name="passwordConfirmation"
              placeholder="Masukkan kembali password kamu"
              label="Konfirmasi Password"
              iconLeft={<IconLock />}
              iconRight={!showPassword ? <IconEyeOff /> : <IconEye />}
              value={formik.values.passwordConfirmation}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? formik.errors.passwordConfirmation : ''}
              onClickRightIcon={() => setShowPassword(!showPassword)}
            />

            {
              error &&
              <div className="text-xs text-red-base-l mb-5 overflow-hidden">
                {error}
              </div>
            }

            <div className="mb-5">
              <button
                data-qa-id="btnLogin"
                type="button"
                onClick={() => handleRegisterType('NORMAL')}
                disabled={!formik.isValid || isSubmitting}
                className={`flex w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden transition-all ease-in-out duration-150 hover:bg-opacity-75 focus:outline-none disabled:bg-gray-light-2-d disabled:bg-opacity-90 disabled:cursor-not-allowed ${
                  formik.isValid
                    ? 'bg-main-base-d text-white'
                    : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
                }`}>
                {isSubmitting && <Spinner />}
                Selanjutnya
              </button>
            </div>

            <div className='flex items-center justify-center relative mt-8'>
              <span className='block p-2 px-3 text-xs text-gray-light-0-d transform -translate-y-4'>Atau daftar dengan</span>
            </div>

            <div className="mb-5">
              <button
                data-qa-id="btnLogin"
                type="button"
                onClick={() => handleRegisterType('SSO')}
                disabled={isSubmitting}
                className={`flex w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden transition-all ease-in-out duration-150 hover:bg-opacity-75 focus:outline-none disabled:bg-gray-light-2-d disabled:bg-opacity-90 disabled:cursor-not-allowed bg-white hover:bg-gray-light-l border-2 border-main-base-d text-main-base-d relative`}>
                {isSubmitting && <Spinner />}
                <IconGoogle className="mr-2" />
                Google
              </button>
            </div>

          </form>
          <div className="flex mb-5 text-gray-light-0-d dark:bg-black-dark-d py-3 px-4 rounded-[10px]">
            <p className="font-quicksand-regular font-bold text-sm flex-auto text-center">
              Sudah Punya Akun PINA?&nbsp;
              <Link
                onClick={() =>
                  triggerSegmentEvent({
                    event: 'TR Login Disini Clicked PINA Trade'
                  })
                }
                className="font-quicksand-semibold hover:opacity-75 text-main-base-d transition-all ease-in-out duration-150"
                to={'/login'}>
                Login disini
              </Link>
            </p>
          </div>
        </div>
      </Card>

      <RenderIf isTrue={openModalReferralNormal}>
        <Modal>
          <ReferralForm
            onClose={() => setOpenModalReferralNormal(false)}
            onSuccess={(_values) =>
              handleRegister({ ...openModalReferralNormal, ..._values })
            }
          />
        </Modal>
      </RenderIf>

      <RenderIf isTrue={openModalReferralGoogleAuth}>
        <Modal>
          <ReferralForm
            onClose={() => setOpenModalReferralGoogleAuth(false)}
            onSuccess={(_values) =>
              handleRegisterWithGoogleOauth(
                openModalReferralGoogleAuth,
                _values?.referrerCode
              )
            }
          />
        </Modal>
      </RenderIf>
    </div>
  );
};

RegisterForm.propTypes = {
  className: PropTypes.string
};

RegisterForm.defaultProps = {
  className: ''
};

export default RegisterForm;


const ReferralForm = ({ onSuccess = () => {}, onClose = () => {}}) => {
  const { getErrorMessage } = useGetErrorMessage();
  const [checkReferralCode] = useLazyCheckReferralCodeQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      referrerCode: '',
      isValidReferrerCode: false
    },
    validateOnMount: true,
    validationSchema: RegisterReferralCodeSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const { data } = await checkReferralCode({ referralCode: values.referrerCode });
        if (!data?.success) throw new Error('');
        formik.setFieldValue('isValidReferrerCode', true);
        onSuccess(values);

        triggerSegmentEvent({
          event: 'TR Referral Code Inputted PINA Trade'
        });
      } catch (error) {
        getErrorMessage(error);
        formik.setFieldValue('isValidReferrerCode', false);
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    }
  });

  return (
    <Card className='p-6 lg:p-8 max-w-lg lg:mx-auto relative'>
      <form onSubmit={formik.handleSubmit}>
        <button onClick={onClose} type="button" className="right-4 top-4 absolute hover:opacity-75">
          <IconClose className="h-6 w-6" />
        </button>

        <h5 className='mb-2'>Kode Referal (Opsional)</h5>
        <InputText
          isAutoFocus
          data-qa-id="txtReferralCodeField"
          name="referrerCode"
          placeholder="Masukkan Kode Referal (Opsional)"
          iconRight={formik.values.referrerCode && formik.values.isValidReferrerCode ? <IconCheck className='text-green-base-l h-6 w-6' /> : ''}
          className="mb-2"
          value={formik.values.referrerCode}
          onBlur={formik.handleBlur}
          onChange={async (e) => {
            formik.handleChange(e);
            try {
              const { data } = await checkReferralCode({ referralCode: e.target.value });
              formik.setFieldValue('isValidReferrerCode', data?.success);
            } catch (error) {
              getErrorMessage(error);
              formik.setFieldValue('isValidReferrerCode', false);
            }
          }}
          error={formik.touched.referrerCode && formik.errors.referrerCode ? formik.errors.referrerCode : ''}
        />

        <div className='mt-5 justify-center grid grid-cols-2 gap-4'>
          <button
            type="button"
            onClick={() => {
              onSuccess({ referrerCode: '' });
              onClose();
              triggerSegmentEvent({
                event: 'TR Referral Code Skipped PINA Trade'
              });
            }}
            className={`hover:bg-opacity-75 transition-all ease-in-out duration-150 border-2 bg-white border-main-base-d text-main-base-d flex px-10 items-center justify-center font-quicksand-medium font-bold text-sm h-11 w-full rounded-[10px] overflow-hidden focus:outline-none`}>
            Skip
          </button>

          <button
            data-qa-id="btnLogin"
            type="submit"
            disabled={!formik.isValid || isSubmitting || !formik.values.referrerCode}
            className={`flex w-full items-center justify-center font-quicksand-semibold font-bold text-sm h-11 rounded-[10px] overflow-hidden transition-all ease-in-out duration-150 hover:bg-opacity-75 focus:outline-none disabled:bg-gray-light-2-d disabled:bg-opacity-90 disabled:cursor-not-allowed ${
              formik.isValid && formik.values.referrerCode
                ? 'bg-main-base-d text-white'
                : 'bg-gray-base-l text-gray-dark-d opacity-80 cursor-not-allowed'
            }`}>
            {isSubmitting && <Spinner />}
            Selanjutnya
          </button>
        </div>
      </form>
    </Card>
  )
}

ReferralForm.propTypes = {
  onSuccess: PropTypes.object
};
