import { bool, object, string } from 'yup';

export const RDNStep04Validation = object().shape({
  isUserInvesting: bool().oneOf([true, false], 'Pertanyaan ini harus dijawab').required('Pertanyaan ini harus dijawab'),
  hasSidNumber: bool().oneOf([true, false], 'Pertanyaan ini harus dijawab').required('Pertanyaan ini harus dijawab'),
  sidNumber: string().test('sidNumber', 'Nomor SID tidak boleh kosong', function(value, { parent }) {
    if (parent?.hasSidNumber) return value?.length > 0;
    return true
  }),
  hasFivePercentStock: bool().oneOf([true, false], 'Pertanyaan ini harus dijawab').required('Pertanyaan ini harus dijawab'),
  fivePercentStockName: string().test('fivePercentStockName', 'Nama Perusahaan Publik tidak boleh kosong', function(value, { parent }) {
    if (parent?.hasFivePercentStock) return value?.length > 0;
    return true
  }),
  isPolitician: bool().oneOf([true, false], 'Pertanyaan ini harus dijawab').required('Pertanyaan ini harus dijawab'),
  isMonetaryInstitutionRelative: bool().oneOf([true, false], 'Pertanyaan ini harus dijawab').required('Pertanyaan ini harus dijawab'),
  isRelativePolitician: bool().oneOf([true, false], 'Pertanyaan ini harus dijawab').required('Pertanyaan ini harus dijawab'),
  isPublicCompanyRelative: bool().oneOf([true, false], 'Pertanyaan ini harus dijawab').required('Pertanyaan ini harus dijawab'),
  userInvestment: object().shape({
    otherInvestment: string().test('otherInvestment', 'Investasi Lainnya tidak boleh kosong', function (value, { parent }) {
      if (parent?.hasOtherInvestment) return value?.length > 0;
      return true
    })
  })
});
