import AdminHeader from 'components/header/admin-header';
import AdminMenu from 'components/menu/admin-menu';
import SettingsBar from 'pages/trading/_partials/settings-bar';
import PropTypes from 'prop-types';

export const LAYOUT_ADMIN = 'admin';

const AdminLayout = ({ children }) => {
  return (
    <>
      <div className="grow z-50">
        <div className="flex">
          <div className="flex-none lg:w-20">
            <AdminMenu />
          </div>

          <div className="flex-auto">
            <AdminHeader />
            <main className='pb-10'>{children}</main>
          </div>
        </div>
        <SettingsBar />
      </div>
    </>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.element
};

export default AdminLayout;
