import PropTypes from 'prop-types';
import InputSelectDropDown from 'components/input/input-select-dropdown';
import IconChevronSingle from 'components/svg/icon-chevron-single';
import { ListParameterScreener } from 'constants/list-parameter-screener';
import { useEffect, useState } from 'react';
import { checkFundamentalSuffix } from 'utils/stock/fundamental';
import RenderIf from 'components/basic/render-if';
import Modal from 'components/modal/modal';

function InputParameter({ value, onSelect = () => {}, borderLess, disabled, valueToCompare, error }) {
  const [active, setActive] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  const handleFocus = () => {
    setActive(true);
  };

  useEffect(() => {
    setTempValue(value || '');
  }, [value]);

  return (
    <div
      className={`card-widget-gradient w-full ${
        !borderLess && `border ${error ? 'border-red-base-l' : 'border-gray-base-l dark:border-black-light-1-l' }`
      } text-xs font-quicksand-semibold rounded-xl flex justify-between relative`}>
      <span className='absolute right-2 top-1/2 -translate-y-1/2 transform border-l pl-[6px] dark:border-black-light-1-l'>
        <IconChevronSingle
          className={` h-4 w-4 text-main-base-l transform flex-none ${
            active ? 'rotate-90' : '-rotate-90'
          }`}
        />
      </span>
      <input
        name="parameter"
        value={tempValue}
        disabled={disabled}
        onFocus={handleFocus}
        placeholder="Pilih Parameter"
        autoComplete="off"
        className="appearance-none flex-auto outline-none h-full w-full z-10 bg-transparent pr-8 cursor-pointer pl-3 py-[8px] dark:text-black-light-1-d"
        readOnly
      />

      <RenderIf isTrue={active}>
        <Modal onClose={() => setActive(false)}>
          <InputSelectDropDown
            value={value}
            placeholder="Cari Parameter"
            options={ListParameterScreener}
            onChange={(value) => {
              onSelect(value);
              setActive(false);
            }}
            onValidate={(data) => {
              return checkFundamentalSuffix(data?.name) != checkFundamentalSuffix(valueToCompare) && data?.children?.length === 0 && valueToCompare || valueToCompare === data?.name
            }}
            onClose={() => {
              setActive(false);
            }}
          />
        </Modal>
      </RenderIf>
    </div>
  );
}

InputParameter.propTypes = {
  value: PropTypes.any,
  error: PropTypes.any,
  onSelect: PropTypes.func,
  borderLess: PropTypes.bool,
  disabled: PropTypes.bool,
  valueToCompare: PropTypes.any,
};

export default InputParameter;
