import RenderIf from 'components/basic/render-if';
import { useGetStockGlobalIndexQuery } from 'services/rtk-query/stock';
import IconSorting from 'components/svg/icon-sorting';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import SkeletonTheme from 'components/basic/skeleton-theme';
import dayjs from 'dayjs';
import { useTableSorting } from 'hooks/useTableSorting';
import { automatedNumberFormat } from 'utils/format';
import { getColorStatus } from 'utils/stock/score';
import useBrowserWakeUp from 'hooks/useBrowserWakeUp';
import { useEffect } from 'react';

function SectionStockGlobal() {
  const isBrowserWakeUp = useBrowserWakeUp();
  const {
    data: globalStocks,
    isLoading,
    refetch
  } = useGetStockGlobalIndexQuery();
  const { data, sortingColumns, handleSorting, getSorting } =
    useTableSorting(globalStocks, ['macro_item'], ['asc']);

  useEffect(() => {
    if (isBrowserWakeUp) refetch();
  }, [isBrowserWakeUp]);

  return (
    <>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-host-small"
        element="span"
        options={{
          resize: 'horizontal',
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 50
          }
        }}>
        <table className="text-2xs w-full text-center font-quicksand-semibold">
          <thead>
            <tr className="text-gray-light-0-d dark:text-black-light-0-d">
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l sticky left-0 card-widget-gradient ${
                  sortingColumns?.[0] === 'macro_item' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('macro_item')}
                style={{ minWidth: '150px' }}>
                <span className="flex items-center gap-1 cursor-pointer">
                  {sortingColumns?.[0] === 'macro_item' && (
                    <IconSorting
                      type={getSorting('macro_item')}
                      className="h-3 w-3"
                    />
                  )}
                  Name
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'change' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('change')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'change' && (
                    <IconSorting
                      type={getSorting('change')}
                      className="h-3 w-3"
                    />
                  )}
                  CHG
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'value' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('value')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'value' && (
                    <IconSorting
                      type={getSorting('value')}
                      className="h-3 w-3"
                    />
                  )}
                  Value
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'open' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('open')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'open' && (
                    <IconSorting
                      type={getSorting('open')}
                      className="h-3 w-3"
                    />
                  )}
                  Open
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'low' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('low')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'low' && (
                    <IconSorting type={getSorting('low')} className="h-3 w-3" />
                  )}
                  Low
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'high' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('high')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'high' && (
                    <IconSorting
                      type={getSorting('high')}
                      className="h-3 w-3"
                    />
                  )}
                  High
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'daily_return' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('daily_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'daily_return' && (
                    <IconSorting
                      type={getSorting('daily_return')}
                      className="h-3 w-3"
                    />
                  )}
                  1D&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'weekly_return' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('weekly_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'weekly_return' && (
                    <IconSorting
                      type={getSorting('weekly_return')}
                      className="h-3 w-3"
                    />
                  )}
                  1W&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'mtd_return' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('mtd_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'mtd_return' && (
                    <IconSorting
                      type={getSorting('mtd_return')}
                      className="h-3 w-3"
                    />
                  )}
                  MTD&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'monthly_return' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('monthly_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'monthly_return' && (
                    <IconSorting
                      type={getSorting('monthly_return')}
                      className="h-3 w-3"
                    />
                  )}
                  1M&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'three_month_return' &&
                  'text-main-base-l'
                }`}
                onClick={() => handleSorting('three_month_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'three_month_return' && (
                    <IconSorting
                      type={getSorting('three_month_return')}
                      className="h-3 w-3"
                    />
                  )}
                  3M&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'six_month_return' &&
                  'text-main-base-l'
                }`}
                onClick={() => handleSorting('six_month_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'six_month_return' && (
                    <IconSorting
                      type={getSorting('six_month_return')}
                      className="h-3 w-3"
                    />
                  )}
                  6M&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'ytd_return' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('ytd_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'ytd_return' && (
                    <IconSorting
                      type={getSorting('ytd_return')}
                      className="h-3 w-3"
                    />
                  )}
                  YTD&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'annual_return' && 'text-main-base-l'
                }`}
                onClick={() => handleSorting('annual_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'annual_return' && (
                    <IconSorting
                      type={getSorting('annual_return')}
                      className="h-3 w-3"
                    />
                  )}
                  1Y&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'three_year_return' &&
                  'text-main-base-l'
                }`}
                onClick={() => handleSorting('three_year_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'three_year_return' && (
                    <IconSorting
                      type={getSorting('three_year_return')}
                      className="h-3 w-3"
                    />
                  )}
                  3Y&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'five_year_return' &&
                  'text-main-base-l'
                }`}
                onClick={() => handleSorting('five_year_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'five_year_return' && (
                    <IconSorting
                      type={getSorting('five_year_return')}
                      className="h-3 w-3"
                    />
                  )}
                  5Y&nbsp;Return
                </span>
              </td>
              <td
                className={`p-2 border-b border-gray-light-l dark:border-gray-base-d hover:text-main-base-l ${
                  sortingColumns?.[0] === 'ten_year_return' &&
                  'text-main-base-l'
                }`}
                onClick={() => handleSorting('ten_year_return')}
                style={{ minWidth: '72px' }}>
                <span className="flex items-center gap-1 justify-end cursor-pointer">
                  {sortingColumns?.[0] === 'ten_year_return' && (
                    <IconSorting
                      type={getSorting('ten_year_return')}
                      className="h-3 w-3"
                    />
                  )}
                  10Y&nbsp;Return
                </span>
              </td>
            </tr>
          </thead>
          <tbody className="text-2xs dark:text-black-light-1-d">
            <RenderIf isTrue={data?.length === 0 && !isLoading}>
              <tr className="group">
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
                <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                  -
                </td>
              </tr>
            </RenderIf>

            <RenderIf isTrue={isLoading}>
              {Array(5)
                .fill(1)
                .map((el, key) => (
                  <tr key={key} className="group">
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                    <td className="p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0">
                      <SkeletonTheme
                        width="50px"
                        height="1rem"
                        borderRadius="6px"
                      />
                    </td>
                  </tr>
                ))}
            </RenderIf>

            {data?.map((item, key) => (
              <tr key={key} className="group">
                <td className="group-hover:bg-gray-light-l dark:group-hover:bg-black-dark-d sticky left-0 card-widget-gradient transition-all ease-in-out duration-150 p-2 text-left border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 font-quicksand-semibold">
                  <div
                    className="flex items-center text-xs line-clamp-2"
                    title={item?.macro_item}>
                    {item?.macro_item}
                  </div>
                  <p className="text-gray-light-0-d font-quicksand-medium">
                    {dayjs(item?.date).format('DD MMM YYYY')}
                  </p>
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.change
                  )}`}>
                  {automatedNumberFormat(item?.change)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.value
                  )}`}>
                  {automatedNumberFormat(item?.value)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.open)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.low)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0`}>
                  {automatedNumberFormat(item?.high)}
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.daily_return
                  )}`}>
                  {automatedNumberFormat(item?.daily_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.weekly_return
                  )}`}>
                  {automatedNumberFormat(item?.weekly_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.mtd_return
                  )}`}>
                  {automatedNumberFormat(item?.mtd_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.monthly_return
                  )}`}>
                  {automatedNumberFormat(item?.monthly_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.three_month_return
                  )}`}>
                  {automatedNumberFormat(item?.three_month_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.six_month_return
                  )}`}>
                  {automatedNumberFormat(item?.six_month_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.ytd_return
                  )}`}>
                  {automatedNumberFormat(item?.ytd_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.annual_return
                  )}`}>
                  {automatedNumberFormat(item?.annual_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.three_year_return
                  )}`}>
                  {automatedNumberFormat(item?.three_year_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.five_year_return
                  )}`}>
                  {automatedNumberFormat(item?.five_year_return)}%
                </td>
                <td
                  className={`group-hover:bg-gray-base-l dark:group-hover:bg-black-dark-d dark:group-hover:bg-opacity-100 transition-all ease-in-out duration-150 group-hover:bg-opacity-20 p-2 text-right border-b border-gray-light-l dark:border-gray-base-d group-last:border-b-0 ${getColorStatus(
                    item?.ten_year_return
                  )}`}>
                  {automatedNumberFormat(item?.ten_year_return)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </OverlayScrollbarsComponent>

      <RenderIf isTrue={globalStocks?.length === 0 && !isLoading}>
        <p className="text-xs text-gray-light-0-d">Belum ada data.</p>
      </RenderIf>
    </>
  );
}

export default SectionStockGlobal;
