import { Children, cloneElement, forwardRef } from 'react'

export const useSlot = ({ children, name, fallback }) => {
  const _children = Children.toArray(children)
  const predicate = (child) => {
    return child?.props?.name === name 
  }

  if (_children.some(predicate)) {
    const child = _children.find(predicate).props.children

    // eslint-disable-next-line react/display-name
    return forwardRef((props, ref) => {
      return cloneElement(child, {
        ref: ref,
        ...props,
      })
    })
  }

  if (fallback) {
    return () => fallback
  }

  return null
}

export const withSlot = (Component) => {
  const Slot = () => null
  Component.Slot = Slot

  return Component
}


export default useSlot