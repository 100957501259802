import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api.service';
import { setAuth } from '.';
import { triggerSegmentEvent } from 'lib/segment';

export const me = createAsyncThunk('auth/me', async (token, { dispatch }) => {
  try {
    const res = await api.get('/user/me', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const resRoles = await api.get('/user/roles');

    window?.analytics.identify(res?.id, {
      name: res?.firstName + ' ' + res?.lastName,
      email: res?.email,
    });
    
    triggerSegmentEvent({
      event: 'TR Login PinaPro',
      properties: {
        email: res?.email,
        userid: res?.id
      }
    });

    dispatch(
      setAuth({
        isLogin: true,
        token: token,
        // TODO: ADD REFESH TOKEN HERE!
        refreshToken: null,
        user: res,
        roles: resRoles?.payloads
      })
    );

    return res;
  } catch (error) {
    throw new Error(error);
  }
});

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password, fcmToken = "", devicePlatform = "web" }, { dispatch, rejectWithValue }) => {
    try {
      const deviceId = ((window?.navigator?.userAgent || '')?.replace(/[^\w\s]/gi, '') + '-001').toUpperCase().replaceAll(' ', '');
      const res = await api.post('/auth/signin', { email, password, fcmToken, deviceId, devicePlatform });
      await dispatch(me(res?.payloads?.accessToken));

      return res;
    } catch (e) {
      return rejectWithValue(e?.message);
    }
  }
);

export const forgotPassword = async ({ email }) => {
  try {
    return await api.post('/user/reset-password-request', { email });
  } catch (e) {
    throw { ...e };
  }
};

export const verifyResetPassword = async (token) => {
  try {
    return await api.get(`/user/reset-password/verify?token=${token}`);
  } catch (e) {
    throw { ...e };
  }
};

export const resetPassword = async ({
  token,
  newPassword,
  confirmPassword
}) => {
  try {
    return await api.post('/user/reset-password', {
      token,
      newPassword,
      confirmPassword
    });
  } catch (e) {
    throw { ...e };
  }
};

export const updateAvatar = async (image) => {
  try {
    const formData = new FormData();
    formData.append('file', image);
    return await api.post('/upload/avatar', formData, { headers: {'Content-Type': 'multipart/form-data'} });
  } catch (e) {
    throw { ...e };
  }
};
