import baseApi from '../base';

export const RewardsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    checkReferralCode: build.query({
      query: (params = { referralCode: '' }) => {
        return {
          url: `/v1/rewards/referral/code`,
          method: 'GET',
          params
        };
      }
    })
  })
});

export const { useLazyCheckReferralCodeQuery } = RewardsApi;
